<template>
  <div class="custom-input-item__wrapper">
    <v-autocomplete
        v-model="value"
        :items="items"
        :menu-props="{ maxHeight: '400', rounded:'lg', transition:'slide-x-transition' }"
        :label="label"
        multiple
        clearable
        :item-text="itemText"
        :item-value="itemValue"
        :chips="chips"
        class="custom-input-item"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    modelValue: {
      type: Array,
      default() {
        return []
      }
    },
    chips: {
      type:Boolean,
      default: false,
    },
    label: {
      type:String,
      default: '',
    },
    itemText: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    }
  },
}
</script>