import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "link-planning-by-urls/fetchData"(ctx, payload) {
      const url = "/ltt/urls_planing";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "link-planning-by-site/fetchData"(ctx, payload) {
      const url = "/ltt/urls_planing_sites";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "link-planning-by-urls/uploadUrlsPlaning"(ctx, payload) {
      const url = "/ltt/urls_planing_upload";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
  },
  getters: {},
};
