<template>
  <v-menu
    v-model="isMenuActive"
    bottom
    offset-y
    content-class="my-shadow--e2"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-chip :label="rounded" v-on="on" outlined>
        <slot name="prepend" />
        <template v-if="label">
          {{ label }}
        </template>
        <template v-else>
          <template v-if="value">
            {{ value }}
          </template>
          <template v-else>
            <span style="opacity: 0.5">Empty</span>
          </template>
        </template>
        <v-icon right small>mdi-chevron-down</v-icon>
      </v-chip>
    </template>
    <v-card class="styled-card--light">
      <v-text-field
        v-model="searchModel"
        hide-details
        dense
        filled
        clearable
        placeholder="Type to search"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-divider />
      <v-card-text class="pa-0" style="overflow-y: scroll; max-height: 500px">
        <v-list dense>
          <template v-if="computedGetItems && computedGetItems.length > 0">
            <v-list-item
              v-for="item in computedGetItems"
              :key="item.id"
              @click="handleSelectItem(item)"
            >
              <v-icon v-if="value === item[itemValue]" small class="mr-2">
                mdi-check
              </v-icon>
              {{ getItemText(item) }}
            </v-list-item>
          </template>
          <template v-else>
            <div class="text-center py-2">
              <span style="opacity: 0.5"> No items... </span>
            </div>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { deepClone, uid } from "@/utils/functions";

export default {
  props: {
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    items: {
      type: Array,
      default: () => [],
    },
    rounded: Boolean,
    label: String,
    modelValue: [Array, String, Object],
  },
  data: () => ({
    isMenuActive: false,
    searchModel: null,
    initialItems: [],
  }),
  created() {
    this.initialItems = this.fixItems(this.items);
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    computedGetItems() {
      if (!this.searchModel) return this.initialItems;

      return this.initialItems.filter((v) => {
        const text =
          String(v[this.itemValue]).toLowerCase() +
          String(v[this.itemText]).toLowerCase();

        return String(text).includes(String(this.searchModel).toLowerCase());
      });
    },
  },
  methods: {
    getItemText(item) {
      if (typeof item === "object") return item[this.itemText];
      return item;
    },
    handleSelectItem(item) {
      if (typeof item === "object") {
        this.value = item[this.itemValue];
      } else {
        this.value = item;
      }

      this.isMenuActive = false;
    },
    fixItems(items) {
      const tmpr = deepClone(items);

      tmpr.forEach((item) => {
        item._id = uid();
      });

      return tmpr;
    },
  },
};
</script>
