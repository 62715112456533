var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"content-class":"my-shadow--4","width":"420","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"styled-card--default"},[_c('v-card-title',[_vm._v(" Close task "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Before closing the task ")]),_vm._l((_vm.checkboxes),function(checkbox){return _c('v-col',{key:checkbox.id,attrs:{"cols":"12"}},[_c('my-card-checkbox',{attrs:{"model-value":_vm.closeTaskForm.checkboxes,"multiple":"","value":{
              value: checkbox.id,
              text: checkbox.name,
            },"label":checkbox.name,"text-model":_vm.closeTaskForm.refactoring_description,"show-textfield":checkbox.id === _vm.CHECKBOXES.IS_BUG ||
              checkbox.id === _vm.CHECKBOXES.IS_REFACTORED},on:{"update@modelValue":function($event){_vm.closeTaskForm.checkboxes = $event},"update@textModel":function($event){_vm.closeTaskForm.refactoring_description = $event}}})],1)}),(
            _vm.closeTaskForm &&
            _vm.closeTaskForm.checkboxes &&
            _vm.closeTaskForm.checkboxes.includes(_vm.CHECKBOXES.IS_BUG)
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"styled-card--light"},[_c('v-card-title',[_c('v-icon',{attrs:{"left":"","small":"","color":"error"}},[_vm._v("mdi-alert-outline")]),_vm._v("Bug detected ")],1),_c('v-divider'),_c('v-card-text',[_c('v-textarea',{attrs:{"rows":"3","dense":"","placeholder":"Write description about bug cause","hide-details":"auto","filled":""},model:{value:(_vm.closeTaskForm.bug_cause_description),callback:function ($$v) {_vm.$set(_vm.closeTaskForm, "bug_cause_description", $$v)},expression:"closeTaskForm.bug_cause_description"}})],1)],1)],1):_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[(!_vm.closeTaskValidator.isValid)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","content-class":"my-shadow--e3","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticClass:"px-0 d-flex justify-center mr-2",staticStyle:{"height":"44px","width":"54px"},attrs:{"label":"","outlined":""}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert")])],1)]}}],null,false,705466365)},[_c('v-card',{staticClass:"styled-card--default"},[_c('v-card-title',[_vm._v(" Errors ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',_vm._l((_vm.closeTaskValidator.messages),function(error){return _c('v-col',{key:error.message,attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","small":""}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(error.message)+" ")],1)}),1)],1)],1)],1):_vm._e(),_c('v-chip',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%","height":"44px"},attrs:{"disabled":!_vm.closeTaskValidator.isValid,"label":"","color":"primary"},on:{"click":_vm.handleCloseTask}},[_vm._v(" Close task ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }