<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          Content dashboard
        </page-name>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model="filters.executive"
          label="Executive"
          :items="executive"
          item-text="text"
          item-value="value"
          outlined
          dense
          clearable
          hide-details
          @change="_$handleFilterChange($event, 'executive')"
          prepend-inner-icon="mdi-account-outline"
          :menu-props="menuProps"
          :loading="loadings.filters"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.niche"
          label="Niche"
          :items="niches"
          item-value="id"
          item-text="name"
          outlined
          dense
          clearable
          @change="_$handleFilterChange($event, 'niche')"
          hide-details
          :menu-props="menuProps"
        ></v-autocomplete
      ></v-col>
      <v-col>
        <range-filter
          title="Need Top"
          :model-value="filters.need_top"
          @update:modelValue="
            _$handleRangeChange($event, 'need_top', 'filters')
          "
          :presets="rangePresets"
          :min="0"
          :max="255"
          style="text-align: left"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.site"
          color="gray"
          label="Site"
          outlined
          :items="_$getProjects"
          item-value="id"
          item-text="name"
          dense
          clearable
          hide-details
          multiple
          @change="_$handleFilterChange($event, 'site')"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.page_type"
          label="Page type"
          :items="page_type"
          multiple
          dense
          clearable
          outlined
          :loading="loadings.filters"
          hide-details
          @change="_$handleFilterChange($event, 'page_type')"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-btn
          :loading="loadings.table"
          @click="fetchData"
          color="primary"
          block
          style="height: 100%"
          >Get data</v-btn
        ></v-col
      >
    </v-row>
    <v-row dense class="mt-5">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
          class="align-end"
          style="max-width: 250px"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col style="position: relative">
        <Loader :show="loadings.table" />
        <template v-if="items && items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            multi-sort
            :sort-by="['need_top', 'priority']"
            :custom-sort="_$smartCustomTableSort"
            :sort-desc="[false, false]"
            class="elevation-1"
            :footer-props="{ itemsPerPageOptions: [25, 50, 100, 200, 500, -1] }"
            :items-per-page="200"
            :loading="loadings.table"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items.filter((v) => v.json !== null)"
                  :key="item.id_url"
                  style="white-space: nowrap"
                >
                  <td>
                    <div class="d-flex justify-space-between align-center">
                      <span>{{ item.url }}</span>
                      <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              mdi-key-chain
                            </v-icon>
                          </template>
                          <span>
                            <div
                              v-for="li in item.json.top_keys"
                              :key="li.phrase"
                            >
                              {{ li.phrase }} {{ li.volume }}
                            </div>
                            <br />
                          </span>
                        </v-tooltip>
                        <v-btn icon @click="goToOPA(item.url)">
                          <v-icon>mdi-poll</v-icon>
                        </v-btn>
                        <v-btn icon @click="goTo(item.url)">
                          <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </td>
                  <td-sw-deadline
                    :value="item.workplace_deadline"
                    :status="item.status"
                  />
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <td
                        class="text-center"
                        v-on="on"
                        @mouseenter="handleFetchCompetitors(item)"
                        :style="{
                          'background-color': getUrlLColor(
                            item.json.url_length,
                            item.json.median_length
                          ),
                        }"
                      >
                        {{ item.json.url_length }}
                      </td>
                    </template>
                    <div>
                      <v-row dense>
                        <v-col
                          cols="12"
                          class="d-flex align-center justify-center"
                        >
                          {{ item.json.median_length }}
                        </v-col>
                        <v-col
                          v-if="!item._loading"
                          class="d-flex align-center justify-center"
                        >
                          <template
                            v-if="
                              item._competitors && item._competitors.length > 0
                            "
                          >
                            {{ item._competitors }}
                          </template>
                          <template v-else> - </template>
                        </v-col>
                        <v-col
                          v-else
                          cols="12"
                          class="d-flex align-center justify-center"
                        >
                          <v-btn :loading="true"></v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tooltip>
                  <td
                    class="text-center"
                    :style="
                      columnStyle(columnHandler(item, 'title'), {
                        item,
                        name: 'tl',
                      })
                    "
                  >
                    {{ columnHandler(item, "title")
                    }}<sup v-if="item.checkboxes.indexOf('tl') !== -1"
                      ><v-icon color="success" size="15">mdi-check</v-icon></sup
                    >
                  </td>
                  <td
                    class="text-center"
                    :style="
                      columnStyle(item.json.title.word_p, { item, name: 't' })
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ item.json.title.word_p }}
                          <sup v-if="item.checkboxes.indexOf('t') !== -1"
                            ><v-icon color="success" size="15"
                              >mdi-check</v-icon
                            ></sup
                          >
                        </span>
                      </template>
                      <span>
                        {{ item.json.title.content }}
                        <div
                          v-for="li in item.json.title.intersect_words"
                          :key="li.word"
                        >
                          {{ li.used ? "+" : "-" }} {{ li.word }}
                          {{ li.volume }}
                        </div>
                        <br />
                      </span>
                    </v-tooltip>
                  </td>
                  <td
                    class="text-center"
                    :style="
                      columnStyle(item.json.title.word_p_normalize, {
                        item,
                        name: 'tn',
                      })
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ item.json.title.word_p_normalize }}
                          <sup v-if="item.checkboxes.indexOf('tn') !== -1"
                            ><v-icon color="success" size="15"
                              >mdi-check</v-icon
                            ></sup
                          >
                        </span>
                      </template>
                      <span>
                        {{ item.json.title.content_normalize }}
                        <div
                          v-for="li in item.json.title
                            .intersect_words_normalize"
                          :key="li.word"
                        >
                          {{ li.used ? "+" : "-" }} {{ li.word }}
                          {{ li.volume }}
                        </div>
                        <br />
                      </span>
                    </v-tooltip>
                  </td>
                  <td
                    class="text-center"
                    :style="
                      columnStyle(columnHandler(item, 'desc'), {
                        item,
                        name: 'dl',
                      })
                    "
                  >
                    {{ columnHandler(item, "desc") }}
                    <sup v-if="item.checkboxes.indexOf('dl') !== -1"
                      ><v-icon color="success" size="15">mdi-check</v-icon></sup
                    >
                  </td>
                  <td
                    class="text-center"
                    :style="
                      columnStyle(item.json.desc.word_p_normalize, {
                        item,
                        name: 'dn',
                      })
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ item.json.desc.word_p_normalize }}
                          <sup v-if="item.checkboxes.indexOf('dn') !== -1"
                            ><v-icon color="success" size="15"
                              >mdi-check</v-icon
                            ></sup
                          >
                        </span>
                      </template>
                      <span>
                        {{ item.json.desc.content }}
                        <div
                          v-for="li in item.json.desc.intersect_words_normalize"
                          :key="li.word"
                        >
                          {{ li.used ? "+" : "-" }} {{ li.word }}
                          {{ li.volume }}
                        </div>
                        <br />
                      </span>
                    </v-tooltip>
                  </td>
                  <td
                    class="text-center"
                    :style="
                      columnStyle(item.json.h1.word_p_normalize, {
                        item,
                        name: 'h1n',
                      })
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ item.json.h1.word_p_normalize }}
                          <sup v-if="item.checkboxes.indexOf('h1n') !== -1"
                            ><v-icon color="success" size="15"
                              >mdi-check</v-icon
                            ></sup
                          >
                        </span>
                      </template>
                      <span>
                        {{ item.json.h1.content }}
                        <div
                          v-for="li in item.json.h1.intersect_words_normalize"
                          :key="li.word"
                        >
                          {{ li.used ? "+" : "-" }} {{ li.word }}
                          {{ li.volume }}
                        </div>
                        <br />
                      </span>
                    </v-tooltip>
                  </td>

                  <td class="text-center">
                    {{ item.json.title.top_key_direct ? "yes" : "no" }}
                  </td>
                  <td class="text-center">{{ item.internal_links }}</td>
                  <td class="text-center">{{ item.unique_anchors }}</td>
                  <td class="text-center">{{ item.json.top_keys.length }}</td>
                  <td class="text-center">{{ item.need_top }}</td>
                  <td>{{ item.priority }}</td>
                </tr>
                <tr
                  v-for="item in items.filter(
                    (v) => v.json === null && v.updated_at === null
                  )"
                  :key="item.id_url"
                  style="white-space: nowrap; background-color: #e0f1ff"
                >
                  <td>{{ item.url }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-for="item in items.filter(
                    (v) => v.json === null && v.updated_at !== null
                  )"
                  :key="item.id_url"
                  style="white-space: nowrap; background-color: #ffe9e9"
                >
                  <td>{{ item.url }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </template>
        <template v-else>
          <div class="table-no-items-placeholders">
            Select filters and try again... 🤷
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  DASHBOARDS_CONTENT_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import Location from "@/mixins/Location";
import Loader from "@/components/Main/Loader";
import PageName from "@/components/DashboardDev/PageName";
import RangeFilter from "@/components/PositionsDashboard/Header/RangeFilter";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import TdSwDeadline from "@/components/Dashboards/Content/UI/TdSwDeadline";
import Project from "@/mixins/Project";
import TableSorting from "@/mixins/TableSorting";

export default {
  name: "ContentIndex",
  components: {
    TdSwDeadline,
    RangeFilter,
    PageName,
    Loader,
  },
  mixins: [Location, FiltersHandling, Project, TableSorting],
  data: () => ({
    menuProps: DEFAULT_MENU_PROPS,
    filterMenuModel: null,
    loadings: {
      filters: false,
      table: false,
    },
    search: "",
    executive: [],
    page_type: [],
    filters: {
      niche: null,
      executive: null,
      site: [],
      need_top: [0, 0],
    },
    FILTERS_QUERY_IDS: {
      NICHE: "niche",
      EXECUTIVE: "executive",
      NEED_TOP: "need_top",
    },
    rangePresets: [
      ["1-3", [1, 3]],
      ["1-5", [1, 5]],
      ["1-10", [1, 10]],
      ["1-50", [1, 50]],
      ["1-100", [1, 100]],
    ],
    headers: DASHBOARDS_CONTENT_TABLE_HEADERS,
    items: [],
  }),
  watch: {
    "filters.site"(value = []) {
      if (value.length > 0) {
        this._$setActiveProject(value[0], false);
      }
    },
  },
  computed: {
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    async handleFetchCompetitors(item) {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty("_competitors")) {
        return;
      }

      item._loading = true;
      const payload = {
        filter: {
          url: item.id,
        },
      };

      const resp = await this.$store.dispatch(
        "contentDashboard/fetchCompetitors",
        payload
      );

      if (resp) {
        item._competitors = resp.items || [];
      }

      item._loading = false;
    },
    isDefaultRange(data) {
      const defaultArr = JSON.stringify([0, 0]);
      return JSON.stringify(data) === defaultArr;
    },
    handleFilterChange(newValue, type) {
      this._$setQueryParams(type, newValue);
    },
    goTo(url) {
      window.open(url, "_blank");
    },
    goToOPA(url) {
      url = `/one-page-analyzer/show?url=${url}`;
      window.open(url, "_blank");
    },
    getUrlLColor(urlLength = 0, medLength = 0) {
      const compareValue = 0.75 * medLength;
      // Fix urlLength and medLength
      urlLength === null || urlLength === undefined ? (urlLength = 0) : null;
      medLength === null || medLength === undefined ? (medLength = 0) : null;

      if (urlLength < compareValue) {
        return "#f9bfbf";
      }
    },
    columnStyle(value, { item, name }) {
      if (item.checkboxes.indexOf(name) !== -1) {
        return "background-color: #b6f3b6; color: #458145;";
      }

      if (value === 100) {
        return "background-color: #b6f3b6; color: #458145;";
      } else if (value <= 60) {
        return "background-color: #f9bfbf; font-weight: bold; color: #652525;";
      }
      return "";
    },
    columnHandler(item, name) {
      if (name === "title") {
        if (item.json.title.content === "none") {
          return -1;
        }

        let i_len = item.json.title.content.length;

        let len = Math.round((1 - Math.abs(((60 - i_len) / 60) * 2)) * 100);

        return len >= 83 ? 100 : len;
      } else if (name === "desc") {
        if (item.json.desc.content === "none") {
          return -1;
        }

        let i_len = item.json.desc.content.length;

        let len = Math.round((1 - Math.abs(((155 - i_len) / 155) * 2)) * 100);

        return len >= 93 ? 100 : len;
      }

      return "";
    },
    async fetchData() {
      this.loadings.table = true;

      const formData = {
        filter: this.filters,
      };

      const resp = await this.$store.dispatch(
        "contentDashboard/fetchTableData",
        formData
      );

      if (resp) {
        this.items = resp.items;
      }

      this.loadings.table = false;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filterNames = ["executive", "page_type"];
      const payload = {
        type: "/dashboards/content/length-competitors",
        filter: { projectID: this.$store.getters.active_project },
        take: filterNames,
      };
      const filters = await this.$store.dispatch("global/getFilters", payload);
      this.executive = filters.executive;
      this.page_type = filters.page_type;
      this.loadings.filters = false;
    },
    collectParamsFromQuery() {
      const keys = Object.keys(this.$route.query);
      keys.forEach((key) => {
        const value = this.$route.query[key];
        const numeric = !isNaN(value);

        if (key === this.FILTERS_QUERY_IDS.NEED_TOP) {
          const result = value.split(",").map((item) => Number(item));
          return (this[key] = result);
        }

        if (numeric) {
          return (this.filters[key] = Number(value));
        }
        return (this.filters[key] = value);
      });
      if (keys.length > 0) {
        this.fetchData();
      }
    },
  },
  async created() {
    this._$collectParams(["page_type", "need_top", "site"], "filters");

    if (this.filters.site.length > 0) {
      this._$setActiveProject(this.filters.site[0], false);
    }

    await this.fetchFilters();
    this.fetchData();
  },
};
</script>

<style scoped></style>
