<template>
  <v-container style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <h2>Export Queue</h2>
            <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          </v-col>
          <v-col class="d-flex justify-end align-end">
            <v-btn
              class="text-normal"
              color="primary"
              @click="addNewTaskDialogModel = true"
            >
              <v-icon small left>mdi-file-document-outline</v-icon>
              Add new task to export
              <v-icon small right>mdi-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-text class="px-0">
            <v-data-table
              :headers="tableData.headers"
              :items="tableData.items"
              :loading="loadings.table"
              :items-per-page="25"
              :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
            >
              <template #[`item.status`]="{ value }">
                <template v-if="value">
                  <span :class="`${String(value).toLocaleLowerCase()}--text`">{{
                    value
                  }}</span>
                </template>
              </template>
              <template #[`item.file`]="{ item }">
                <v-menu content-class="shadow-e2-bordered" left>
                  <template #activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense nav>
                    <v-list-item @click="goTo(item.file)">
                      <v-list-item-icon>
                        <v-icon>mdi-download</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Download </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="doAction(item, 'restart')">
                      <v-list-item-icon>
                        <v-icon>mdi-restart</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Restart </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="doAction(item, 'continue')">
                      <v-list-item-icon>
                        <v-icon>mdi-skip-next</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Continue </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-task-export-dialog
      :model-value="addNewTaskDialogModel"
      @update:modelValue="addNewTaskDialogModel = $event"
    />
  </v-container>
</template>

<script>
import service from "@/plugins/service";
import CreateTaskExportDialog from "@/components/UI/CreateTaskExportDialog.vue";

export default {
  components: { CreateTaskExportDialog },
  data: () => ({
    addNewTaskDialogModel: false,
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Export Queue",
        disabled: true,
      },
    ],
    filters: {},
    export_type: null,
    tableData: {
      headers: [],
      items: [],
    },
    filterGroups: [],
    loadings: {
      table: false,
      savingNewTask: false,
      filters: false,
      filtersConfig: false,
    },
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async doAction({ id }, action) {
      try {
        const url = "/export/do-action";

        const payload = {
          id,
          action,
        };

        const resp = await service.post(url, payload);

        if (resp && resp.status) {
          return this.$message.notification({
            title: "Action",
            text: "Successful",
            type: "success",
          });
        }

        this.$message.notification({
          title: "Action",
          text: "Something went wrong",
          type: "error",
        });
      } catch (e) {
        console.error(e);
      }
    },
    goTo(path) {
      window.open(path, "_blank");
    },
    async fetchData() {
      try {
        this.loadings.table = true;

        const resp = await this.$store.dispatch("export-queue/fetchData", {});

        if (resp) return (this.tableData = resp);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>
