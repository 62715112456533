<template>
  <div class="info-fields__item">
    <textarea
      ref="text"
      style="opacity: 0; width: 1px; height: 1px; position: absolute"
    ></textarea>
    <div
      v-if="
        ['email', 'text', 'num', 'textarea', 'select'].indexOf(item.type) !== -1
      "
    >
      <v-list-item-subtitle
        class="mb-4 d-flex justify-space-between align-center"
      >
        {{ item.title }}
        <v-btn small icon @click="handleCopy(item.value.value)">
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </v-list-item-subtitle>
      <v-list-item-title
        class="mb-0 d-flex justify-space-between align-center"
        style="white-space: pre-wrap; word-break: break-word"
        >{{ item.value.value }}
      </v-list-item-title>
    </div>
    <div v-else-if="item.type === 'cost_base'">
      <v-list-item-subtitle class="mb-4">
        {{ item.title }}
      </v-list-item-subtitle>
      <v-list-item-title
        class="mb-0"
        style="white-space: pre-wrap"
        v-for="cost in item.value.value"
        :key="cost"
      >
        {{ cost }}
        <v-btn small icon @click="handleCopy(cost)">
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </v-list-item-title>
    </div>
    <div v-else-if="item.type === 'friendly_themes'">
      <v-list-item-subtitle class="mb-4">
        {{ item.title }}
      </v-list-item-subtitle>
      <v-list-item-title
        class="mb-0"
        v-for="theme in item.value.value"
        :key="theme"
      >
        {{ theme }}
      </v-list-item-title>
    </div>
    <div v-else-if="item.type === 'price'">
      <price-field :item="item" />
    </div>
    <div v-else-if="item.type === 'content_price'">
      <v-list-item-subtitle
        class="mb-4 d-flex justify-space-between align-center"
      >
        {{ item.title }}
        <v-btn
          small
          icon
          @click="handleCopy(item.value.value + ' ' + item.value.currency)"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </v-list-item-subtitle>
      <v-list-item-title class="mb-0">
        <div>{{ item.value.value }} {{ item.value.currency }}</div>
        <div class="d-flex flex-column">
          <div title="Creator">
            <v-icon small left>mdi-account</v-icon>{{ item.value.creator }}
          </div>
          <div title="Nickname" class="body-1">{{ item.value.nickname }}</div>
        </div>
      </v-list-item-title>
    </div>
    <div v-else-if="item.type === 'editor'">
      <v-list-item-subtitle class="mb-4">
        {{ item.title }}
      </v-list-item-subtitle>
      <v-list-item-title class="mb-0">
        <v-btn
          color="info"
          text
          link
          outlined
          target="_blank"
          :href="getDownloadDocUrl(item.id)"
        >
          Download
          <v-icon class="ml-2" size="20">mdi-cloud-download-outline</v-icon>
        </v-btn>
      </v-list-item-title>
    </div>
    <div v-else-if="item.type === 'fin_template'">
      <v-list-item-subtitle class="mb-4">
        {{ item.title }}
      </v-list-item-subtitle>
      <v-list-item-title class="mb-0">
        <v-btn color="info" text outlined @click="viewFinTemplates()">
          View Templates
          <v-icon class="ml-2" size="20">mdi-eye</v-icon>
        </v-btn>
      </v-list-item-title>
      <v-dialog v-model="fin_templates_dialog" max-width="500">
        <v-card>
          <v-card-title>Fin Templates</v-card-title>
          <v-card-text>
            <div v-for="t in fin_templates" :key="t.title" class="mb-4">
              <v-btn small icon @click="handleCopy(t.body, 2)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
              <h2 class="subtitle-2 mb-2">{{ t.title }}</h2>
              <span v-html="t.body"></span>
              <textarea
                ref="text"
                style="opacity: 0; width: 1px; height: 1px; position: absolute"
              ></textarea>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-else-if="item.type === 'link'">
      <v-list-item-subtitle
        class="mb-4 d-flex justify-space-between align-center"
      >
        {{ item.title }}
        <v-btn small icon @click="handleCopy(item.value.value)">
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </v-list-item-subtitle>
      <v-list-item-title class="mb-0">
        <a :href="item.value.value" target="_blank">
          <v-chip small color="info" outlined @click="() => {}">
            Go to
            <v-icon small right>mdi-open-in-new</v-icon>
          </v-chip>
        </a>
      </v-list-item-title>
    </div>
    <div v-else-if="item.type === 'checklist'">
      <v-list-item-subtitle class="mb-4">
        {{ item.title }}
      </v-list-item-subtitle>
      <v-list-item
        class="pa-0"
        dense
        v-for="item_checklist in item.json.values"
        :key="item_checklist"
      >
        <v-list-item-icon class="mr-3">
          <v-icon
            v-if="item.value.value.indexOf(item_checklist) !== -1"
            color="success"
            >mdi-check
          </v-icon>
          <v-icon v-else color="error">mdi-close</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ item_checklist }}
        </v-list-item-content>
      </v-list-item>
    </div>
    <div
      v-else-if="item.type === 'url_anchor' || item.type === 'url_anchor_all'"
    >
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-list-item-subtitle class="mb-4">
            {{ item.title }}
          </v-list-item-subtitle>
          <div v-for="i in 5" :key="i">
            <v-row dense v-if="formatUrlAnchor('url_anchors', i) !== null">
              <v-col>
                {{ formatUrlAnchor("url_anchors", i) }}
                <div>
                  <v-chip
                    small
                    label
                    outlined
                    class="pl-2"
                    @click="
                      handleCopy(
                        formatUrlAnchor('url_anchors', i).split('\t')[0].trim()
                      )
                    "
                    >Url
                    <v-icon right size="14">mdi-content-copy</v-icon>
                  </v-chip>
                  <v-chip
                    small
                    outlined
                    class="ml-2 pl-2"
                    label
                    @click="
                      handleCopy(
                        formateAnchors(formatUrlAnchor('url_anchors', i))
                          .anchors
                      )
                    "
                    >Anchors
                    <v-icon right size="14">mdi-content-copy</v-icon>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'url_anchor_simple'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-list-item-subtitle class="mb-4">
            {{ item.title }}
          </v-list-item-subtitle>
          <div v-for="i in 5" :key="i">
            <v-row dense v-if="item.value.value.urls[i]">
              <v-col>
                {{ item.value.value.urls[i] }} {{ item.value.value.anchors[i] }}
                <div>
                  <v-chip
                    outlined
                    small
                    label
                    class="pl-2"
                    @click="handleCopy(item.value.value.urls[i])"
                    >Url
                    <v-icon right size="14">mdi-content-copy</v-icon>
                  </v-chip>
                  <v-chip
                    outlined
                    small
                    label
                    class="ml-2 pl-2"
                    @click="handleCopy(item.value.value.anchors[i])"
                    >Anchors
                    <v-icon right size="14">mdi-content-copy</v-icon>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'url_anchor_simple_extended'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-list-item-subtitle class="mb-4">
            {{ item.title }}
          </v-list-item-subtitle>
          <div v-for="i in 5" :key="i">
            <v-row dense v-if="formatUrlAnchor('extended', i) !== null">
              <v-col>
                {{ formatUrlAnchor("extended", i).value }}
                <v-btn
                  small
                  icon
                  @click="handleCopy(formatUrlAnchor('extended', i).copyUrl)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  small
                  icon
                  @click="handleCopy(formatUrlAnchor('extended', i).copyDonor)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import copy from "copy-to-clipboard";
import GpmApiService from "../../../services/gpm/GpmApiService";
import PriceField from "./PriceField.vue";

export default {
  name: "InfoFieldManager",
  components: { PriceField },
  props: ["item"],
  data() {
    return {
      gpmApiService: null,
      fin_templates_dialog: false,
      fin_templates: [],
    };
  },
  created() {
    this.gpmApiService = new GpmApiService();
  },
  methods: {
    formateAnchors(inputString) {
      const regex = /\((.*?)\)/gm;

      const results = inputString
        .match(regex)
        .map((v) => v.replaceAll("(", "").replaceAll(")", ""));

      if (results.length > 2) {
        const [status, anchors, id] = results;
        return { status, anchors, id };
      }

      const [anchors, id] = results;
      return { anchors, id };
    },
    handleCopy(data) {
      copy(data);
      this.$message.notification({
        title: "Copied",
        text: `Text <mark>${data}</mark> successfully coppied.`,
        type: "success",
        duration: 10000,
        position: "lb",
      });
    },
    getDownloadDocUrl(id) {
      return `${this.$store.state.server_url}/gpm/entity-view/download-doc?entityID=${this.$route.params.id}&fieldID=${id}`;
    },
    viewFinTemplates() {
      let self = this;

      self.fin_templates_dialog = true;
      self.fin_templates = [];

      let url = `${self.$store.state.server_url}/gpm/task-view/get-fin-templates`;

      let config = {
        params: {
          entityID: this.$route.params.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.fin_templates = r.data.templates;
      });
    },
    formatUrlAnchor(type, i) {
      const urlAnchorID = this.item.value.value.url_anchors[i];
      const urlID = this.item.value.value.urls[i];
      const anchorID = this.item.value.value.anchors[i];

      if (type === "url_anchors") {
        const match = this.item.json.url_anchors.filter(
          (v) => v.value !== undefined && v.value === urlAnchorID
        )[0];

        return match !== undefined ? match.text.replace(" (", "\t(") : null;
      }

      if (type === "urls") {
        const match = this.item.json.urls.filter((v) => v.value === urlID)[0];

        return match !== undefined ? match.text : null;
      }

      if (type === "anchors" && this.item.json.anchors[urlID] !== undefined) {
        const match = this.item.json.anchors[urlID].filter(
          (v) => v.value === anchorID
        )[0];

        return match !== undefined ? match.text : null;
      }

      if (type === "extended") {
        const url = this.item.value.value.urls[i];
        const anchors = this.item.value.value.anchors[i];
        const urlAnchors = this.item.json.url_anchors.filter(
          (v) => v.value !== undefined && v.value === urlAnchorID
        )[0];

        if (url && anchors) {
          return {
            value: `${url} ( ${anchors} )`,
            copyUrl: url,
            copyDonor: anchors,
          };
        }

        if (urlAnchors) {
          return {
            value: urlAnchors.text.replace(" (", "\t("),
            copyDonor: urlAnchors.text
              .replace(" (", "\t(")
              .split("\t")[1]
              .replace(/[()]|PREV/gi, ""),
            copyUrl: urlAnchors.text.replace(" (", "\t(").split("\t")[0],
          };
        }

        return null;
      }
    },
  },
};
</script>

<style scoped></style>
