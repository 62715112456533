<template>
  <div
    :class="`expansion-block ${
      isExpandFunctionDisabled ? 'expansion-block--disabled' : ''
    }`"
  >
    <div ref="body" class="expansion-block__body">
      <div ref="content" class="expansion-block__content-wrapper">
        <slot name="default" />
      </div>
      <v-expand-transition>
        <div v-if="!expanded && !withoutHider" class="expansion-block__hider" />
      </v-expand-transition>
    </div>
    <template v-if="!isExpandFunctionDisabled">
      <slot
        name="toggle"
        v-bind:toggle="toggleExpanded"
        v-bind:isExpanded="expanded"
      ></slot>
      <div
        v-if="!this.$scopedSlots.toggle"
        class="expansion-block__action mt-4"
      >
        <div class="d-flex justify-center align-center">
          <v-chip
            @click="toggleExpanded"
            :color="color"
            label
            :outlined="!filled"
          >
            {{ expanded ? "Hide content" : "Show more" }}
            <div class="ml-2 d-flex align-center">
              <template v-if="expanded">
                <v-icon small>mdi-eye-off-outline</v-icon>
              </template>
              <template v-else>
                <v-icon small>mdi-eye-outline</v-icon>
              </template>
            </div>
          </v-chip>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    maxHeight: {
      type: String,
      default: "600px",
    },
    withoutHider: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    expanded: false,
    isExpandFunctionDisabled: false,
  }),
  watch: {
    expanded(isExpanded) {
      if (isExpanded) return this.expandWrapper();
      this.collapseWrapper();
    },
    maxHeight(height) {
      this.$refs.body.style.maxHeight = height || "600px";
    },
  },
  mounted() {
    if (this.disabled) return (this.isExpandFunctionDisabled = true);

    const maxHeight = Number(String(this.maxHeight).replaceAll("px", ""));
    if (this.$refs.content.scrollHeight <= maxHeight && maxHeight !== 0)
      return (this.isExpandFunctionDisabled = true);

    this.$refs.body.style.maxHeight = this.maxHeight || "600px";
  },
  methods: {
    expandWrapper() {
      this.$refs.body.style.maxHeight = this.$refs.content.scrollHeight + "px";
    },
    collapseWrapper() {
      this.$refs.body.style.maxHeight = this.maxHeight;
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss">
.expansion-block {
  $class: &;
  &__hider {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    pointer-events: none;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(26, 86, 219, 0) 100%
    );
  }
  &__body {
    position: relative;
    overflow: hidden;
    transition: 150ms ease-in-out;
  }
  &--disabled {
    #{$class}__hider,
    #{$class}__actions {
      display: none;
    }
  }
}
</style>
