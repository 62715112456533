<template>
  <v-card>
    <apexcharts
      type="bar"
      height="300"
      :options="options"
      :series="series"
    ></apexcharts>
  </v-card>
</template>

<script>
// import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";
// import events from "../../../events/components/OnePageAnalyzer/events";

export default {
  name: "KeywordEntries",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {};
  },
  computed: {
    series() {
      let exact = [];
      let broad = [];
      let other = [];

      let self = this;

      this.$store.state.OnePageAnalyzer.common_table_items.forEach(function (
        item
      ) {
        exact.push(self.getInt(item.keyword_entry_EXACT));
        broad.push(self.getInt(item.keyword_entry_BROAD));
        other.push(
          self.getInt(item.num_of_links_bl) -
            self.getInt(item.keyword_entry_BROAD) -
            self.getInt(item.keyword_entry_EXACT)
        );
      });

      return [
        {
          name: "Exact",
          data: exact,
        },
        {
          name: "Broad",
          data: broad,
        },
        {
          name: "Other",
          data: other,
        },
      ];
    },
    options() {
      let categories = [];

      this.$store.state.OnePageAnalyzer.common_table_items.forEach(function (
        item
      ) {
        let url = new URL(item.default_url);
        categories.push(url.host);
      });

      return {
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Keyword Entries",
        },
        xaxis: {
          categories: categories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>

<style scoped></style>
