<template>
  <v-chip
    v-if="data"
    :class="getColorClass(name, data.percent)"
    label
    :title="title"
  >
    <span class="mr-1">{{ prefix }}:</span>
    <b>
      {{ data.percent }}
    </b>
    <sup>
      {{ data.value }}
    </sup>
  </v-chip>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
  },
  methods: {
    getColorClass(name, value) {
      try {
        const classes = {
          success: "flat-chip flat-chip--success",
          warning: "flat-chip flat-chip--warning",
          error: "flat-chip flat-chip--error",
        };

        const isPositive = ["water_index"].includes(name);

        const isFlex = ["flesch", "average_sentence_length"].includes(name);

        if (isFlex) {
          if (value >= 6 && value <= 15) return classes.warning;
          if (value >= 16) return classes.error;
          if (value <= -6 && value >= -15) return classes.warning;
          if (value <= -16) return classes.error;

          return "transparent";
        }

        if (isPositive) {
          if (value >= 6 && value <= 15) return classes.warning;
          if (value >= 16) return classes.error;

          return "transparent";
        }

        if (value <= -30 && value >= -10) return classes.warning;
        if (value <= -30) return classes.error;

        return "transparent";
      } catch {
        return "transparent";
      }
    },
  },
};
</script>
