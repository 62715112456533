<template>
  <v-dialog
    :retain-focus="false"
    v-model="inputVal"
    content-class="remove-dialog-shadow"
    max-width="1100px"
  >
    <v-card rounded="lg" class="shadow-e1-bordered styled-card">
      <v-card-title v-if="alert.shown">
        <v-alert
          v-for="message in alert.messages"
          :key="message"
          text
          :type="alert.type"
          class="mb-0"
          dense
          style="width: 100%"
          border="left"
          dismissible
        >
          {{ message }}
        </v-alert>
      </v-card-title>
      <v-card-title class="d-flex">
        Disavow
        <v-spacer />
        <v-chip-group v-model="level" active-class="primary--text" column>
          <v-chip filter outlined label :value="0">
            For project
            <v-icon right small>mdi-web</v-icon>
          </v-chip>

          <v-chip filter outlined label :value="1">
            For user
            <v-icon right small>mdi-account</v-icon>
          </v-chip>
        </v-chip-group>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-card rounded="lg" class="styled-card--default">
              <v-card-title> Import from text </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    1. Put the columns to import in the order in which you make
                    the data
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="text.headers"
                      filled
                      label="Headers"
                      hide-details
                      dense
                      :placeholder="'domain'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">2. Insert data (without headers)</v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="text.rows"
                      filled
                      label="Rows"
                      hide-details
                      dense
                      :placeholder="'domain\ndomain\ndomain\ndomain\ndomain\n'"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="flat-chip flat-chip--primary"
                  width="160"
                  :loading="loadings.upload"
                  @click="upload_data(1)"
                  >Upload
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card rounded="lg" class="styled-card--default">
              <v-card-title class="d-flex">
                Import from file
                <v-spacer />
                <a href="/file/AnchorsPlanExample.csv" target="_blank">
                  <v-chip @click="() => {}" small label>
                    Download Example
                    <v-icon right small>mdi-download</v-icon>
                  </v-chip>
                </a>
              </v-card-title>
              <v-card-text class="pt-4">
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="file.names"
                      counter
                      dense
                      label="File input"
                      multiple
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      accept=".csv"
                      filled
                      hide-details
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ file.names.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  class="flat-chip flat-chip--primary"
                  width="130"
                  :loading="loadings.upload"
                  @click="upload_data(2)"
                  >Upload
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-row>
              <v-col cols="12">
                <v-card elevation="0">
                  <v-card-title> Allowed columns </v-card-title>
                  <v-card-text class="pa-0">
                    <v-simple-table dense>
                      <tbody>
                        <tr>
                          <td>R</td>
                          <td>required</td>
                        </tr>
                        <tr>
                          <td>domain [R]</td>
                          <td>example.com</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadDisavowDonors",
  props: ["value", "project"],
  data() {
    return {
      loadings: {
        upload: false,
      },
      alert: {
        data: [],
        shown: false,
        type: "",
      },
      file: { names: [] },
      text: {
        headers: "domain",
        row: "",
      },
      level: 0,
      domains: [],
    };
  },
  created() {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    userId() {
      return this.$store.state.auth.user.id;
    },
  },
  methods: {
    upload_data(type) {
      let self = this;

      self.alert.messages = [];
      self.alert.type = "";
      self.alert.shown = false;

      let url = `${self.$store.state.server_url}/donor-base/clean/${
        self.id_project ?? 0
      }/upload-disavow/${self.level}`;
      let data = {};
      if (type === 1) {
        data = {
          type: type,
          headers: self.text.headers,
          data: self.text.rows,
          level: self.level,
          project: self.project,
        };
      } else {
        data = new FormData();
        data.append("file", self.file.names[0]);
        data.append("type", type);
        data.append("level", self.level);
        data.append("project", self.project);
      }

      let config = {
        headers: {
          "Content-Type":
            type === 1 ? "application/json" : "multipart/form-data",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.loadings.upload = true;

      axios
        .post(url, data, config)
        .then(function (response) {
          self.alert.messages = response.data.messages;
          self.alert.type = response.data.type;
          self.alert.shown = true;
          self.file.names = [];
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            title: "Something gone wrong",
            type: "error",
            text: "Try again.",
          });
        })
        .finally(() => {
          this.loadings.upload = false;
        });
    },
  },
};
</script>

<style scoped></style>
