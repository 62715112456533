<template>
  <div
    :style="{ color: getColor, fontWeight: 600, cursor: 'pointer' }"
    class="clicked"
  >
    <a
      :href="`/one-page-analyzer/show?url=${data?.url}`"
      target="_blank"
      class="reset-link-styles"
    >
      {{ value }}
    </a>
  </div>
</template>

<script>
import { URL_OVERVIEW_COLORS } from "@/utils/defaultData";
import QueryBuilder from "@/mixins/QueryBuilder";

export default {
  props: ["value", "data", "seo"],
  mixins: [QueryBuilder],
  computed: {
    getColor() {
      const { green, yellow, red } = URL_OVERVIEW_COLORS;
      if (this.value === 100) {
        return green;
      }
      if (this.value >= 60 && this.value <= 99) {
        return yellow;
      }
      if (this.value >= 0 && this.value <= 59) {
        return red;
      }

      return "transparent";
    },
  },
};
</script>
