<template>
  <div>
    <v-text-field
      v-model="searchQuery"
      outlined
      dense
      placeholder="Search by Signature"
      hide-details
      prepend-inner-icon="mdi-magnify"
      class="mx-4 mb-3 mt-3"
      clearable
    />
    <cron-table :items="componentData()?.items" :search="searchQuery" />
  </div>
</template>

<script>
import CronTable from "../../DashboardDev/components/CronTable.vue";

export default {
  components: { CronTable },

  inject: ["componentData"],

  data: () => ({
    searchQuery: null,
  }),
};
</script>
