<template>
  <div>
    <div class="px-0 py-2 d-flex">
      <v-text-field
        v-model="searchQuery"
        hide-details
        dense
        solo
        class="rounded-lg"
        flat
        placeholder="Type to search"
        append-icon="mdi-magnify"
        style="max-width: 320px"
      />
      <v-spacer />
      <v-menu bottom nudge-bottom="40" content-class="my-shadow--e2">
        <template #activator="{ on }">
          <v-btn dark v-on="on" class="px-4 text-normal">
            Table settings
            <v-icon small right>mdi-cog</v-icon>
          </v-btn>
        </template>
        <template #default>
          <v-card flat outlined rounded="lg" class="pa-0">
            <v-list dense>
              <v-list-item>
                <v-switch
                  v-model="settings.isDense"
                  dense
                  hide-details
                  class="ma-0"
                />
                Dense variant
              </v-list-item>
            </v-list>
          </v-card>
        </template>
      </v-menu>
    </div>
    <v-data-table
      :search="searchQuery"
      :headers="getFixedHeaders"
      :items="items"
      :items-per-page="50"
      :custom-sort="customSort"
      multi-sort
      :dense="settings.isDense"
      :sort-by="['pr', 'nt']"
      :sort-desc="[false, false]"
      :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
      class="urls-overview__overview-table"
    >
      <template v-for="h in headers" #[`header.${h.value}`]>
        <div style="white-space: nowrap" :key="h.value">
          <template v-if="h.title">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ h.text }}
                  <v-icon small color="rgba(0,0,0,0.3)" class="mr-1">
                    mdi-information-outline
                  </v-icon>
                </span>
              </template>
              <div style="max-width: 150px">{{ h.title }}</div>
            </v-tooltip>
          </template>
          <template v-else>
            <span>{{ h.text }}</span>
          </template>
        </div>
      </template>
      <template
        v-for="header in headers"
        #[`item.${header.value}`]="{ item, value }"
      >
        <template v-if="header.value === 'url'">
          <uo-td-url :value="value" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'status'">
          <uo-td-status :value="value" :data="item" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'l_sc'">
          <uo-td-l-sc :value="value" :data="item" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'sc_sc'">
          <uo-td-sc-sc :value="value" :data="item" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'sw_sc'">
          <uo-td-sw-sc :value="value" :data="item" :key="header.value" />
        </template>
        <template v-else-if="header.value === 't_sc'">
          <uo-td-t-sc :value="value" :data="item" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'status_code'">
          <status-code-cell :value="value" :data="item" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'nt_percent'">
          <uo-td-nt-percent :value="value" :data="item" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'pos'">
          <uo-td-pos :data="value" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'dynamic'">
          <uo-td-dynamic :value="value" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'kd'">
          <div
            class="main-value d-flex align-center justify-center"
            :key="header.value"
          >
            <uo-kd :value="value" :text="true" :opacity="1" />
          </div>
        </template>
        <template v-else-if="header.value === '1'">
          <uo-td-delta :data="item['1']" :key="header.value" />
        </template>
        <template v-else-if="header.value === '3'">
          <uo-td-delta :data="item['3']" :key="header.value" />
        </template>
        <template v-else-if="header.value === '10'">
          <uo-td-delta :data="item['10']" :key="header.value" />
        </template>
        <template v-else-if="header.value === '20'">
          <uo-td-delta :data="item['20']" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'trends_imp'">
          <uo-td-trends-imp :data="value" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'trends_cl'">
          <uo-td-trends-cl :data="value" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'trends_k'">
          <uo-td-trends-cl :data="value" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'trends_kr'">
          <uo-td-trends-cl :data="value" :key="header.value" />
        </template>
        <template v-else-if="header.value === 'trends_kd'">
          <uo-td-trends-cl :data="value" :key="header.value" />
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import UoTdPos from "../Dashboards/UrlsOverview/Td/UoTdPos.vue";
import UoTdNtPercent from "../Dashboards/UrlsOverview/Td/UoTdNtPercent.vue";
import UoTdScSc from "../Dashboards/UrlsOverview/Td/UoTdScSc.vue";
import UoTdUrl from "../Dashboards/UrlsOverview/Td/UoTdUrl.vue";
import UoTdStatus from "../Dashboards/UrlsOverview/Td/UoTdStatus.vue";
import UoTdSwSc from "../Dashboards/UrlsOverview/Td/UoTdSwSc.vue";
import UoTdLSc from "../Dashboards/UrlsOverview/Td/UoTdLSc.vue";
import UoTdTSc from "../Dashboards/UrlsOverview/Td/UoTdTSc.vue";
import UoTdDynamic from "../Dashboards/UrlsOverview/Td/UoTdDynamic.vue";
import UoTdTrendsImp from "../Dashboards/UrlsOverview/Td/UoTdTrendsImp.vue";
import UoTdTrendsCl from "../Dashboards/UrlsOverview/Td/UoTdTrendsCl.vue";
import UoTdDelta from "../Dashboards/UrlsOverview/Td/UoTdDelta.vue";
import StatusCodeCell from "../Dashboards/UrlsOverview/Td/StatusCodeCell.vue";
import UoKd from "../Dashboards/UrlsOverview/Td/UoKd.vue";
import SettingsMixin from "../../mixins/SettingsMixin";

export default {
  components: {
    StatusCodeCell,
    UoKd,
    UoTdUrl,
    UoTdDelta,
    UoTdTrendsCl,
    UoTdTrendsImp,
    UoTdPos,
    UoTdDynamic,
    UoTdNtPercent,
    UoTdTSc,
    UoTdSwSc,
    UoTdScSc,
    UoTdLSc,
    UoTdStatus,
  },
  mixins: [SettingsMixin],
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    localStorageId: "overview-table-settings",
    searchQuery: "",
    settings: {
      isDense: false,
    },
  }),
  computed: {
    getFixedHeaders() {
      return this.headers.map((header) => {
        if (header.value === "url") header.width = "300px";

        return header;
      });
    },
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          let aVal, bVal;
          if (a[sortBy[i]] === null) aVal = 0;
          if (b[sortBy[i]] === null) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
};
</script>
