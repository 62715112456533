import service from "../../../plugins/service";

export const researchHubApiService = () => {
  const deleteDomain = async (domainId) => {
    try {
      const url = `/gpm/research-hub/delete/${domainId}`;

      const { data } = await service.post(url);

      return data.success;
    } catch (e) {
      console.error("Error while deleting selected.", e);
    }
  };

  const removeTagFromDomain = async (domainId, tagId) => {
    try {
      const url = `/gpm/task-view/delete-domain-tags/${domainId}/${tagId}`;

      const { data } = await service.delete(url);

      return data;
    } catch (e) {
      console.error("Error while removing tag from domain.", e);
    }
  };

  return {
    deleteDomain,
    removeTagFromDomain,
  };
};
