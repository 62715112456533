<template>
  <svg
    width="1309"
    height="337"
    viewBox="0 0 1309 337"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="1309" height="337" :fill="getChartColor" />
    <path
      d="M63 226C181.5 228.167 420.3 231.2 427.5 226C436.5 219.5 677 156 683.5 157C690 158 913 185.5 918.5 183.5C922.9 181.9 1167 89.1667 1288.5 43"
      :stroke="getInnerColor"
      stroke-width="10"
    />
    <path
      d="M64 268.5C79.2 267.7 291 192.833 395 155.5C518.333 149.167 767.5 136.5 777.5 136.5C787.5 136.5 1106.67 128.5 1265 124.5"
      :stroke="getInnerColor"
      stroke-width="10"
    />
    <rect
      x="47"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="23"
      y="273"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="23"
      y="216"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="23"
      y="159"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="23"
      y="102"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect x="23" y="45" width="31" height="13" rx="6.5" :fill="getInnerColor" />
    <rect
      x="168"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="289"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="410"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="531"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="652"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="773"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="894"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="1015"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="1136"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="1257"
      y="298"
      width="31"
      height="13"
      rx="6.5"
      :fill="getInnerColor"
    />
    <rect
      x="410"
      y="321"
      width="158"
      height="16"
      rx="8"
      :fill="getInnerColor"
    />
    <rect
      x="586"
      y="321"
      width="158"
      height="16"
      rx="8"
      :fill="getInnerColor"
    />
    <rect
      x="762"
      y="321"
      width="158"
      height="16"
      rx="8"
      :fill="getInnerColor"
    />
  </svg>
</template>

<script>
export default {
  computed: {
    getChartColor() {
      if (this.$vuetify.theme.dark) {
        return "none";
      }
      return "#F5F5F5";
    },
    getInnerColor() {
      if (this.$vuetify.theme.dark) {
        return "#343434";
      }
      return "#d9d9d9";
    },
  },
};
</script>
