<template>
  <v-container style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h2>Pages</h2>
            <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="styled-card--default">
          <v-card-title> All pages in app </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <parent :tree="routes" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="detailsDialogModel"
      content-class="remove-dialog-shadow"
      width="600"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Details about page
          <v-spacer />
          <v-btn icon @click="detailsDialogModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Variable</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody v-if="activeItem">
              <tr>
                <td>title</td>
                <td>{{ activeItem.title }}</td>
              </tr>
              <tr>
                <td>name</td>
                <td>{{ activeItem.name }}</td>
              </tr>
              <tr>
                <td>path</td>
                <td>{{ activeItem.path }}</td>
              </tr>
              <tr>
                <td>maintainer</td>
                <td>{{ activeItem.meta.maintainer }}</td>
              </tr>
              <tr>
                <td>domain</td>
                <td>{{ activeItem.meta.domain }}</td>
              </tr>
              <tr>
                <td>worksection id</td>
                <td>{{ activeItem.meta.workSectionData }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Parent from "@/components/Pages/components/Parent.vue";

export default {
  components: { Parent },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Pages",
        disabled: true,
      },
    ],
    detailsDialogModel: false,
  }),
  watch: {
    activeItem(value) {
      if (!value) return;

      console.log(value);

      this.detailsDialogModel = true;
    },
  },
  computed: {
    activeItem() {
      return this.$store.getters["pages/activeItem"];
    },
    routes() {
      return this.$store.getters["base/menu_items"];
    },
  },
};
</script>
