export default {
  data: () => ({
    copyComponentId: "default",
  }),
  created() {
    window.addEventListener("keydown", this.handleCopyComponentId);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleCopyComponentId);
  },
  methods: {
    handleCopyComponentId(e) {
      if (e.key === "ScrollLock") {
        this.$copyText(this.copyComponentId);
        this.$message.notification({
          title: "Done",
          text: "Component id coppied.",
          type: "success",
        });
      }
    },
  },
};
