<template>
  <label :for="id" style="cursor: pointer">
    <v-card
      flat
      rounded="lg"
      outlined
      class="px-2 pr-4 d-flex align-center"
      height="40"
      :color="checked ? 'rgba(0,50,230,0.05)' : ''"
    >
      <v-row dense>
        <v-col cols="auto">
          <v-checkbox
            dense
            hide-details
            class="ma-0 mb-1"
            :id="id"
            v-model="checked"
            :true-value="trueValue"
            :false-value="falseValue"
            @click.prevent.stop
          ></v-checkbox>
        </v-col>
        <v-col cols="fill" class="d-flex align-center">
          {{ label }}
        </v-col>
      </v-row>
    </v-card>
  </label>
</template>

<script>
import { uid } from "../../utils/functions";

export default {
  props: ["value", "label", "trueValue", "falseValue"],

  data: () => ({
    id: null,
  }),

  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  created() {
    this.id = `checkbox-${uid()}`;
  },
};
</script>
