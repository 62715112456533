<template>
  <div ref="parent">
    <apex-chart
      ref="easyLineChart"
      height="35"
      width="100"
      :options="options"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["data"],
  components: {
    ApexChart: VueApexCharts,
  },
  data: () => ({
    options: {
      chart: {
        type: "line",
        width: 100,
        height: 35,
        sparkline: {
          enabled: true,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          return `
                <div class="easy-line-chart-tooltip">
                    <span>${series[seriesIndex][dataPointIndex]}</span>
                </div>
                `;
        },
      },
      animations: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      title: {
        enabled: false,
      },
      grid: {
        padding: {
          bottom: 10,
          top: 10,
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
        line: {
          show: false,
          height: 0,
          color: "transparent",
        },
        axisTicks: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "Rows",
        data: [],
        color: "#a90d0d",
      },
    ],
  }),
  watch: {
    data() {
      this.rerenderChart();
    },
  },
  computed: {
    getChartColor() {
      const lastElement = this.data[this.data.length - 1];

      const equalZero = (el) => el === 0;
      const biggerThenZeroButEqualFirstElem = (el, i, arr) => {
        return el > 0 && el === arr[0];
      };

      if (this.data.every(equalZero)) {
        return "rgba(0,0,0,0.15)";
      }

      if (this.data.every(biggerThenZeroButEqualFirstElem)) {
        return "rgb(227,125,11)";
      }

      if (this.data[0] > this.data[this.data.length - 1]) {
        return "#19d50c";
      }

      if (!this.data.every(equalZero) && lastElement === 0) {
        return "#19d50c";
      }

      return "#a90d0d";
    },
  },
  created() {
    this.series = [
      {
        name: "Rows",
        data: [...this.data],
        color: this.getChartColor,
      },
    ];
  },
  methods: {
    rerenderChart() {
      this.$refs.parent.style.display = "none";
      this.series = [
        {
          name: "Rows",
          data: [...this.data],
          color: this.getChartColor,
        },
      ];
      this.$refs.easyLineChart.refresh();

      this.$refs.parent.style.display = "block";
    },
  },
};
</script>
