import Service from "@/plugins/service";

export default {
  state: {
    sValueP: null,
  },
  mutations: {
    "conversion-7/setSValueP"(state, payload) {
      state.sValueP = payload;
    },
  },
  actions: {
    async "conversion-7/fetchData"(ctx, { payload, projectId }) {
      const url = "/analytics/conversion/report7/" + projectId;

      const resp = await Service.post(url, payload, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
  },
  getters: {
    "conversion-7/sValueP": (state) => state.sValueP,
  },
};
