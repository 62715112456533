<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" class="d-flex justify-space-between">
        <h2>NDC / Money / Expances Manage - Charts</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model="filters.seo"
          :items="filtersData.users"
          dense
          item-text="name"
          item-value="id"
          outlined
          clearable
          :loading="loadings.filters"
          hide-details
          multiple
          label="Seo"
          @change="_$handleFilterChange($event, 'seo')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.projects"
          :items="_$getProjects"
          dense
          clearable
          :loading="loadings.filters"
          item-text="name"
          multiple
          item-value="id"
          outlined
          hide-details
          label="Projects"
          @change="_$handleFilterChange($event, 'projects')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.team"
          :items="filtersData.teams"
          dense
          clearable
          :loading="loadings.filters"
          multiple
          outlined
          hide-details
          label="Team"
          @change="_$handleFilterChange($event, 'team')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.niche"
          :items="filtersData.niches"
          dense
          clearable
          :loading="loadings.filters"
          item-text="name"
          item-value="id"
          multiple
          outlined
          hide-details
          label="Niche"
          @change="_$handleFilterChange($event, 'niche')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <smart-date-filter
          :model-value="filters.date"
          @update:modelValue="handleChangeDateFilter"
        ></smart-date-filter>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col>
        <v-btn
          block
          @click="fetchData"
          color="primary"
          :loading="loadings.charts"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="4">
        <v-autocomplete
          v-model="renderedCards"
          outlined
          dense
          hide-details
          multiple
          label="Select a chart"
          :items="chartsData"
          item-value="name"
          item-text="name"
          chips
          clearable
          deletable-chips
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="chart in chartsData">
        <v-col v-if="chart" :key="chart.name" cols="6" ref="chartCol">
          <v-card rounded="lg" class="styled-card--default">
            <v-card-title>
              {{ chart.name }}
            </v-card-title>
            <v-divider />
            <v-card-text>
              <apexcharts
                height="300"
                :options="getChartOptions(chart)"
                :series="getFixedSeries(chart.series)"
              ></apexcharts>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import Location from "../../../mixins/Location";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import LazyDomElementRenderingMixin from "../../../mixins/LazyDomElementRenderingMixin";

export default {
  components: { SmartDateFilter, apexcharts: VueApexCharts },
  mixins: [FiltersHandling, Project, Location, LazyDomElementRenderingMixin],
  data: () => ({
    renderedCards: [],
    chartsData: [],
    loadings: {
      charts: false,
      filters: false,
    },
    chartOptions: {
      chart: {
        type: "line",
      },
      animations: {
        enabled: false,
      },
      stroke: {
        width: [0, 2],
        dashArray: [0, 5],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
    },
    filtersData: {
      niches: [],
      teams: [],
      users: [],
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 30, 49],
      },
    ],
    filters: {
      seo: [],
      team: [],
      projects: [],
      niche: [2],
      date: [],
    },
  }),
  created() {
    const haveParams = this._$collectParams(
      ["seo", "projects", "niche", "date", "team"],
      "filters"
    );

    if (!this.$route.query.date) this.setDefaultDate();

    if (haveParams) this.fetchData();
    this.fetchFilters();
    this.fetchData();
  },
  methods: {
    async fetchFilters() {
      try {
        this.loadings.filters = true;

        const resp = await this.$store.dispatch("ndc-manage/fetchFilters", {});

        if (resp) {
          this.filtersData = resp;
        }
      } catch (e) {
        console.error("Error while loadings filters.", e);
      } finally {
        this.loadings.filters = false;
      }
    },
    setDefaultDate() {
      const defaultDate = [];
      defaultDate[0] = this.$moment()
        .startOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      defaultDate[1] = this.$moment().format("YYYY-MM-DD");
      this.filters.date = defaultDate;
    },
    getChartOptions({ categories, name }) {
      const symbol = !String(name).includes("NDC") ? "$" : "";
      const tooltip = {
        custom: function (value) {
          const currentIdx = value.dataPointIndex;
          const series = value.w.config.series;

          let output = `<div class="ndc-chart__tooltip">`;

          series.forEach((series) => {
            output += getElem(series);
          });

          output += "</div>";

          function getElem(series) {
            return `
            <div class="tooltip__item">
                <div class="tooltip__color" style="background: ${series.color}"></div>
              <div class="tooltip__label">${series.name}:</div>
              <div class="tooltip__value">${series.data[currentIdx]} ${symbol}</div>
            </div>
            `;
          }

          return output;
        },
      };
      return { ...this.chartOptions, xaxis: { categories }, tooltip };
    },
    handleChangeDateFilter(event) {
      this.filters.date = event;
      this._$handleFilterChange(event, "date");
    },
    getFixedSeries(series) {
      const data = series;

      data.forEach((item) => {
        const isPlan = String(item.name).toLowerCase().includes("plan");

        if (isPlan) {
          item.color = "#4369e7";
        } else {
          item.color = "#45c48a";
        }
      });

      return data;
    },
    async fetchData() {
      try {
        const payload = { filter: this.filters };

        this.loadings.charts = true;

        const resp = await this.$store.dispatch(
          "ndc-manage/fetchCharts",
          payload
        );

        if (resp) this.chartsData = resp.charts;

        this.$nextTick(() => {
          if (!Array.from(this.$refs.chartCol).length) return;
          this.initializeLazyRender(this.$refs.chartCol);
        });
      } catch (e) {
        console.error("Error while loading charts.", e);
      } finally {
        this.loadings.charts = false;
      }
    },
  },
};
</script>
