<template>
  <div>
    <v-btn
        fixed
        dark
        fab
        bottom
        right
        color="pink"
        @click="dialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title>Create task</v-card-title>
        <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-text-field
                v-model="name"
                :rules="urlsRules"
                label="Name"
                solo
            ></v-text-field>
            <v-textarea
                v-model="urls"
                :rules="urlsRules"
                label="Urls"
                solo
                no-resize
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="info"
              text
              @click="upload_data"
              :disabled="status_uploading"
              :loading="status_uploading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateTask",
  data() {
    return {
      valid: false,
      name: undefined,
      urls: undefined,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      urlsRules: [
        v => !!v || 'Urls is required',
      ],
      dialog: false,
      status_uploading: false
    }
  },
  methods: {
    upload_data() {
      let self = this

      if (!self.$refs.form.validate()) {
        return
      }

      self.status_uploading = true

      let url = `${self.$store.state.server_url}/textrazor/task-manager/create_task`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      let post_data = {
        name: self.name,
        urls: self.urls.split("\n").map((v) => v.trim()),
      }

      axios.post(url, post_data, config)
          .then((response) => {
            if (!response.data.success) {
              self.sheet = true
              alert(JSON.stringify(response.data.errors))
            }
          })
          .catch(() => self.sheet = true)
          .finally(() => {
            self.status_uploading = false
            self.dialog = false
          })
    }
  }
}
</script>

<style scoped>

</style>