<template>
  <div>

    <v-row>
      <v-col v-if="gpm.items.length>0" style="min-width: 40%;">
        <h4>GP ToDo Steps</h4>
        <v-card>
          <v-data-table
              :headers="gpm.headers"
              :items="gpm.items"
              class="elevation-1"
              dense
          >
          </v-data-table>
        </v-card>
      </v-col>
      <v-col v-if="anchor_plan.items.length>0" style="min-width: 60%;">
        <h4>Progress Table by type</h4>
        <v-card>
          <v-data-table
              :headers="anchor_plan.headers"
              :items="anchor_plan.items"
              :sort-by="['priority']"
              :sort-desc="[true]"
              class="elevation-1"
              dense
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr
                  style="white-space: nowrap"
                  :style="get_style_row(item.lag_on_page)"
                  v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ item.type }}
                </td>
                <td class="text-center">
                  {{ item.plan }}
                </td>

                <td class="text-center">
                                                  <span><a
                                                      :href="get_link_to_ltt(item.id,item.subproject_id,item.type_id)"
                                                      target="_blank">{{ item.fact }}</a><sup
                                                      v-if="item.fact_nofollow>0"
                                                  >{{ item.fact_nofollow }}</sup></span><span
                    v-if="item.fact_on_page>0"> ({{ item.fact_on_page }}<sup
                    v-if="item.fact_on_page_nofollow>0"
                >{{ item.fact.fact_on_page_nofollow }}</sup><span
                    v-if="item.fact_gi>0">/{{ item.fact_gi }}<sup
                    v-if="item.fact_gi_nofollow>0"
                >{{ fact_gi_nofollow}}</sup></span>)</span>
                </td>
                <td class="text-center">
                  {{ item.done }}% ({{ item.done_on_page }}%)
                </td>
                <td class="text-center">
                          <span v-if="item.department &&item.department.toLowerCase().includes('la')">
                              <span v-if="item.type_id !== 6 && item.type_id !== 13 && item.performer>0">
                                  <span v-if="item.task_id>0"><a target="_blank"
                                                                 :href="'/anchors-plan/placement-links/' + item.task_id">{{
                                      item.left
                                    }}</a></span><span v-else>{{ item.plan }}</span>- {{
                                  item.new_donors
                                }}<sup>{{ item.all_donors }}</sup> <span v-if="item.plan>item.new_donors"
                              ><v-icon style="color: #f10707" size="20">mdi-alert-circle</v-icon></span>
                              </span>
                            </span>
                  <span v-else><v-icon small color="red">mdi-crop</v-icon></span>
                </td>

                <td>
                  <span v-if="item.check_link > 0">{{ item.check_link }}</span>
                </td>

              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Table",
  props: ['id', 'subproject_id'],
  data() {
    return {
      anchor_plan: {
        headers: [
          {text: 'type', title: 'priority', value: 'priority', class: 'text-center'},
          {text: 'plan', title: 'plan', value: 'plan', class: 'text-center'},
          {text: 'fact', title: 'fact', value: 'fact', class: 'text-center'},
          {text: 'done', title: 'done', value: 'done', class: 'text-center'},
          {text: 'left', title: 'left', value: 'left', class: 'text-center'},
          {text: 'no check', title: 'check_link', value: 'check_link', class: 'text-center'},
        ],
        items: []
      },
      gpm: {
        headers: [
          {text: 'status', title: 'status', value: 'status', class: 'text-center'},
          {text: 'count', title: 'count', value: 'count', class: 'text-center'},
        ],
        items: []
      }
    }
  },
  methods: {
    fetchData() {
      let self = this

      let url = `${self.$store.state.server_url}/ltt/types-dynamics/json`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        type: 'anchor-plan-details',
        project_id: self.id,
        subproject_id: self.subproject_id
      }

      axios
          .post(url, data, config)
          .then((r) => {
            self.anchor_plan.items = r.data.data.anchors_plan.items
            self.gpm.items = r.data.data.gpm.items
          })
    },
    get_style_row(val) {
      let color = ''
      if (val > 50) {
        color = 'rgba(255,112,112,0.14)'
      } else if (val > 20) {
        color = 'rgba(255,244,131,0.14)'
      }
      return 'background-color:' + color;
    },
    get_link_to_ltt(project, subproject, type = false) {
      let url = '/ltt/index?project=' + project + '&subproject=' + subproject;

     /* if (date) {
        url += "&date_range=" + date + "," + date;
      } else {
        url += "&date_range=" + this.filter.date_range;
      }*/

      if (type) {
        url += "&type=" + type;
      }
      return url;
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    getProjects() {
      return this.$store.state.project.allowed_projects
    },
  },
  created() {
    let self = this

    self.fetchData();
  },
}
</script>

<style scoped>

</style>