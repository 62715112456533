import { SITE_ANALYSIS_SECTION_DATA } from "../../../components/SiteAnalysis/defaultData";

export default {
  props: {
    sectionName: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    SECTIONS: SITE_ANALYSIS_SECTION_DATA,
  }),
  computed: {
    successfullyUploaded() {
      return (
        this.$store.getters["site-analysis/successfullyUploadedSections"] || []
      ).includes(this.sectionName);
    },
  },
};
