<template>
  <v-list-item @click.prevent="handleClick">
    <v-list-item-icon>
      <v-icon>mdi-priority-high</v-icon>
    </v-list-item-icon>
    <v-list-item-content> Highest priority project </v-list-item-content>
  </v-list-item>
</template>

<script>
import Project from "@/mixins/Project";

export default {
  mixins: [Project],
  data: () => ({
    counter: 0,
    timer: null,
  }),
  methods: {
    goTo(routeName) {
      try {
        const subproject = this.getMostPrioritySubproject();

        const routeData = this.$router.resolve({
          name: routeName,
          query: {
            ws_project_id: subproject.worksection_id,
            task_desc: `<p></p><p><a href="${window.location.href}" target="_blank">${window.location.href}</a></p>`,
          },
        });
        window.open(routeData.href, "_blank");
      } catch {
        this.$message.notification({
          title: "Wrong project",
          text: "Please, select valid project.",
          type: "error",
        });
      }
    },
    getMostPrioritySubproject() {
      const subprojects = this._$getSubprojects;

      return subprojects.reduce((prev, curr) => {
        if (curr.priority > prev.priority) {
          return curr;
        }
        return prev;
      }, subprojects[0]);
    },
    generateLink(type) {
      const path = type === "watchTasks" ? "/priority/" : "/new/";

      const subproject = this.getMostPrioritySubproject();

      this.$copyText(window.location.href);

      return `https://ra.worksection.com/project/${subproject.worksection_id}${path}`;
    },
    handleDoubleClick() {
      this.goTo("similar-task-creating.to-those-responsible");
    },
    handleSingleClick() {
      window.open(this.generateLink("watchTasks"), "_blank");
    },
    handleClick() {
      this.counter++;

      // single click
      if (this.counter === 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
          this.handleSingleClick();
        }, 300);

        return;
      }

      // double click
      clearTimeout(this.timer);
      this.counter = 0;
      this.handleDoubleClick();
    },
  },
};
</script>
