<template>
  <v-chip :class="`flat-chip flat-chip--${getChipColor}`">
    {{ data }}
  </v-chip>
</template>

<script>
export default {
  props: {
    data: {},
  },
  computed: {
    getChipColor() {
      if (this.data === "done") return "success";

      return "transparent";
    },
  },
};
</script>
