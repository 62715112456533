import service from "../../plugins/service";

export const ContentTeamPerformanceApiService = () => {
  const ENDPOINTS = {
    GET_PERFORMANCE: "/site-workplace/content-team-performance",
  };

  const getPerformance = (formData) => {
    return service.post(ENDPOINTS.GET_PERFORMANCE, { filter: formData });
  };

  return {
    getPerformance,
  };
};
