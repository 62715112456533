<template>
  <v-card flat color="rgba(155,155,155,0.1)" rounded="lg">
    <v-card-title class="pa-3 text-body-2">
      <b>Overall by month</b>
    </v-card-title>
    <div class="px-3 pb-3 text-body-2">
      <v-row dense>
        <v-col v-for="month in data?.months" cols="12" :key="month?.name">
          <v-row dense>
            <v-col>
              {{ month?.name }}
            </v-col>
            <v-col class="d-flex justify-end">
              <all-done-percent-value :data="month?.data" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-divider />
    <v-card-actions class="pa-3 text-body-2">
      <v-row dense>
        <v-col> Total </v-col>
        <v-col class="d-flex justify-end" style="gap: 0.25rem">
          <all-done-percent-value :data="data?.total" tooltip />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import AllDonePercentValue from "./AllDonePercentValue.vue";

export default {
  components: { AllDonePercentValue },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
