var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"content-class":"my-shadow--e4","scrollable":"","max-width":"1220"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"styled-card--light site-analysis__feedback"},[_c('v-card-title',[_vm._v(" Feedback "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0 d-flex"},[_c('div',{staticClass:"hide-scrollbar",staticStyle:{"flex":"1","position":"sticky","top":"0"}},[_c('v-card-text',{staticClass:"pt-5"},[_c('v-card',{staticClass:"styled-card--light"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.themeItems,"label":"Select theme","placeholder":"Type to search","filled":"","hide-details":"","dense":""},on:{"change":_vm.handleChangeTheme},model:{value:(_vm.form.theme_id),callback:function ($$v) {_vm.$set(_vm.form, "theme_id", $$v)},expression:"form.theme_id"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"px-2 mr-1 d-flex justify-center",staticStyle:{"min-width":"38px"},attrs:{"color":_vm.getRatingColor(-10),"label":""}},[_vm._v("-10")]),_c('v-slider',{attrs:{"min":"-10","max":"10","hide-details":"","thumb-label":"always","thumb-color":_vm.getRatingColor(_vm.form.rating),"dense":""},model:{value:(_vm.form.rating),callback:function ($$v) {_vm.$set(_vm.form, "rating", $$v)},expression:"form.rating"}}),_c('v-chip',{staticClass:"px-2 mr-1 d-flex justify-center",staticStyle:{"min-width":"38px"},attrs:{"color":_vm.getRatingColor(10),"label":""}},[_vm._v("10")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('tip-tap-editor',{attrs:{"model-value":_vm.form.comment},on:{"update:modelValue":function($event){_vm.form.comment = $event}}}),(
                      _vm.form.comment &&
                      _vm.form.comment.length <= _vm.commentSymbolsLimit
                    )?_c('small',[_vm._v(" characters left: "+_vm._s(_vm.commentSymbolsLimit - _vm.form.comment.length)+" ")]):(
                      _vm.form.comment &&
                      _vm.form.comment.length > _vm.commentSymbolsLimit
                    )?_c('small',{staticClass:"error--text"},[_vm._v(" You've reached characters limit. ")]):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.validate.error)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","content-class":"my-shadow--e3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticClass:"mr-2 px-2",attrs:{"outlined":"","label":""}},on),[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-alert ")])],1)]}}],null,false,818083467)},[_c('v-card',{staticClass:"styled-card--error"},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","small":""}},[_vm._v("mdi-alert")]),_vm._v(" Errors ")],1),_c('v-divider'),_c('v-card-text',[_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.validate.message)+" ")])])],1)],1):_vm._e(),_c('v-chip',{attrs:{"label":"","color":"primary","disabled":_vm.validate.error || _vm.savingLoading},on:{"click":_vm.handleSaveFeedback}},[_vm._v(" Send feedback "),(!_vm.savingLoading)?_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-send")]):_c('v-progress-circular',{attrs:{"size":"18","width":"2","indeterminate":""}})],1)],1)],1)],1)],1),_c('div',{staticStyle:{"flex":"1"}},[_c('v-card-text',{staticClass:"pt-5 pb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('h3',[_vm._v("Feedback on this topic from other users")]),(!_vm.commentLoading)?_c('small',{staticClass:"ml-2"},[_vm._v("("+_vm._s(_vm.comments.length)+")")]):_c('v-progress-circular',{attrs:{"size":"12","width":"2","indeterminate":""}})],1)]),(
            (_vm.comments.length === 0 || !_vm.comments) && !_vm.commentLoading && _vm.dirty
          )?[_c('v-card-text',[_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(" There are no comments for this topic, feel free to add yours. ")])])]:((_vm.comments.length === 0 || !_vm.comments) && !_vm.commentLoading)?[_c('v-card-text',[_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(" Nothing here. Select theme to see comments. ")])])]:_vm._e(),(
            Array.isArray(_vm.comments) && _vm.comments.length > 0 && !_vm.commentLoading
          )?[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.comments),function(comment,idx){return [_c('v-col',{key:idx,attrs:{"cols":"12"}},[_c('comment-item',{attrs:{"can-delete":"","comment":comment,"after-remove-callback":_vm.handleAfterRemoveComment}})],1)]})],2)],1)]:_vm._e(),(_vm.commentLoading)?[_c('v-card-text',_vm._l(([0, 1, 2]),function(idx){return _c('div',{key:idx,staticClass:"mt-6 d-flex"},[_c('div',{staticClass:"skeleton mr-2",staticStyle:{"width":"38px","height":"38px","border-radius":"100px"}}),_c('div',[_c('div',[_c('div',{staticClass:"skeleton",staticStyle:{"width":"160px","height":"18px"}})]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"skeleton",staticStyle:{"width":"560px","height":"48px"}})]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"skeleton",staticStyle:{"width":"40px","height":"18px"}})])])])}),0)]:_vm._e()],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }