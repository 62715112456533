<template>
  <td>
    <div :class="`colored-td${getColor} td-content nowrap`">
      {{
        data.last_activity_at
          ? $moment(data.last_activity_at).format("DD/MM")
          : ""
      }}
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      try {
        const currDate = this.$moment(new Date());
        const fixedDate = this.data.last_activity_at
          .split(" ")[0]
          .split("-")
          .map((i) => parseInt(i));

        const date = this.$moment(fixedDate);
        const diff = currDate.diff(date, "days");

        if (this.data.task_closed) return "";

        if (diff >= 5 && diff <= 8) return " yellow";
        if (diff > 10) return " red";

        return "";
      } catch (e) {
        return "";
      }
    },
  },
};
</script>
