<template>
  <v-card :class="`${!dense ? 'styled-card--default' : ''}`">
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-divider />
    <v-card-text class="px-0">
      <template v-if="!loading && data.length === 0">
        <div style="text-align: center">Press "get data"</div>
      </template>
      <template v-else-if="!loading">
        <v-data-table
          :headers="headers"
          :items="data"
          class="positions-table divide-dynamic"
          :items-per-page="-1"
          :dense="dense"
          hide-default-footer
        >
          <template #body="{ items }">
            <tbody>
              <tr
                v-for="(row, index) in items"
                :key="index"
                :class="{
                  'divider-border': row.name.divider === 2,
                  divider: row.name.divider === 1,
                }"
              >
                <td :class="{ bold: row.name.text.includes(',%') }">
                  {{ row.name.text }}
                </td>
                <td>
                  <v-sparkline
                    v-if="!everyZero(getArrayData(row))"
                    :value="getArrayData(row)"
                    width="145"
                    height="45"
                    :color="getColor(getArrayData(row))"
                    smooth
                    auto-draw
                  ></v-sparkline>
                </td>
                <td class="text-center">{{ row.t }}</td>
                <td
                  v-for="(td, idx) in getItems(row)"
                  :key="idx"
                  class="text-center"
                >
                  {{ td }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-skeleton-loader type="table-row-divider@6" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { OYR_POSITION_TABLE_HEADERS } from "@/utils/defaultData";

export default {
  props: ["data", "title", "loading", "dense"],
  data: () => ({
    headers: OYR_POSITION_TABLE_HEADERS,
  }),
  methods: {
    getArrayData(data) {
      const output = [];

      Object.keys(data).forEach((key) => {
        if (key[0] === "$") {
          let value = isNaN(data[key]) ? 0 : parseInt(data[key]);
          isNaN(value) ? (value = 0) : null;
          output.push(value);
        }
      });

      return output;
    },
    everyZero(data) {
      const equalZero = (el) => el === 0;

      if (data.every(equalZero)) {
        return true;
      }
      return false;
    },
    getColor(data) {
      if (data.length === 0) {
        return "black";
      }
      if (data[0] < data[data.length - 1]) {
        return "red";
      }
      return "green";
    },
    getItems(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (key[0] === "$") output.push(items[key]);
      });

      return output;
    },
  },
};
</script>
