<template>
  <div
    :class="{
      'd-flex relative align-center flex-wrap': true,
      'justify-center': header.align === 'center',
    }"
  >
    <v-tooltip bottom content-class="remove-dialog-shadow pa-0">
      <template #activator="{ on }">
        <div v-on="on">
          {{ trimDomain ? getClearDomainName(header.text) : header.text }}
        </div>
      </template>
      <v-card
        v-if="trimDomain || header.title"
        class="styled-card--default pa-2"
      >
        {{ header.title || header.text }}
      </v-card>
    </v-tooltip>
    <v-menu :close-on-content-click="false" bottom content-class="pa-0">
      <template #activator="{ on }">
        <div v-on="on">
          <v-icon
            :color="!!multiSearch[header.value] ? 'error' : ''"
            @click.stop
            small
            v-on="on"
            >mdi-magnify</v-icon
          >
        </div>
      </template>
      <v-card class="styled-card--default pa-2">
        <v-text-field
          :value="multiSearch[header.value]"
          @input="handleChangeMultiSearch"
          hide-details
          dense
          filled
          clearable
          :loading="loading"
          type="text"
          :placeholder="header.value"
          @click:clear.prevent.stop="multiSearch[header.value] = ''"
        ></v-text-field>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { deepClone } from "../../../utils/functions";

export default {
  props: {
    header: {},
    trimDomain: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Function,
      default: () => {},
    },
    gradientsModel: {},
    multiSearchModel: {},
  },
  data: () => ({
    loading: false,
    multiSearchChangingTimeout: null,
  }),
  computed: {
    gradients: {
      get() {
        return this.gradientsModel;
      },
      set(value) {
        this.$emit("update:gradientsModel", value);
      },
    },
    multiSearch: {
      get() {
        return this.multiSearchModel;
      },
      set(value) {
        this.$emit("update:multiSearchModel", value);
      },
    },
  },
  methods: {
    getClearDomainName(domainString) {
      try {
        return String(new URL(domainString).host).replace("www.", "");
      } catch {
        return domainString;
      }
    },
    handleChangeMultiSearch(value) {
      clearTimeout(this.multiSearchChangingTimeout);

      this.loading = true;

      this.multiSearchChangingTimeout = setTimeout(() => {
        const clone = deepClone(this.multiSearch);

        clone[this.header.value] = value;

        this.multiSearch = clone;

        this.loading = false;
      }, 750);
    },
  },
};
</script>
