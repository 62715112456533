<template>
  <v-dialog v-model="dialog" width="350" content-class="my-shadow--e4">
    <v-card rounded="lg">
      <v-card-title class="text-body-1">
        <slot name="title" />
        <v-spacer />
        <v-icon @click="dialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <slot />
      </v-card-text>
      <v-card-actions class="pa-4 pt-0">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";

export default {
  mixins: [Dialog],
};
</script>
