<template>
  <div>
    <v-card :loading="loading" elevation="5">
      <v-card-actions>
        <div><span class="text-h6">TR Site Topics</span><br /></div>
        <v-spacer></v-spacer>

        <force-update-button
          :loading="loading"
          @click="_$forceUpdateData"
          :is-force-update-enabled="dataRelevance === 7"
        />
      </v-card-actions>
      <v-card-text class="pt-0">
        <v-data-table
          fixed-header
          :headers="tableHeaders"
          :items="series"
          :height="maxHeight"
          dense
          hide-default-footer
          :items-per-page="-1"
          multi-sort
          :custom-sort="_$smartCustomTableSort"
        >
          <template
            v-for="h in tableHeaders"
            #[`header.${h.value}`]="{ header }"
          >
            <span
              :key="h.value"
              class="text-center"
              :title="'Updated ' + last_updated.day_diff + ' days ago'"
            >
              {{ header.text }}
            </span>
          </template>
          <template #body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.topic">
                <td style="word-break: break-all">{{ item.topic }}</td>
                <td class="text-center text-no-wrap">
                  <span>{{ item.sw_keys }}</span>
                </td>
                <td class="text-center text-no-wrap">
                  <span>{{ item.mp }}</span>
                </td>
                <td class="text-center text-no-wrap">
                  <span>{{ item.ah_keys }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ViewInfoBySiteIndex } from "../../../mixins/components/ViewInfoBySite/Index";
import axios from "axios";
import updateButtonMixin from "@/mixins/components/ViewInfoBySite/updateButtonMixin";
import ForceUpdateButton from "@/components/ViewInfoBySite/UI/ForceUpdateButton";
import TableSorting from "../../../mixins/TableSorting";

export default {
  name: "TRTopics",
  components: { ForceUpdateButton },
  mixins: [ViewInfoBySiteIndex, updateButtonMixin, TableSorting],
  props: ["custom-domain", "custom-height"],
  data() {
    return {
      tableHeaders: [
        { text: "Topics", value: "topic" },
        { text: "SW Keys", value: "sw_keys" },
        { text: "Main Page", value: "mp" },
        { text: "AH Keys", value: "ah_keys" },
      ],
      loading: false,
      isFirstFetch: true,
      refresh_loading: false,
      ws_type: "tr:topics",
      series: [],
      soft_cosine: undefined,
      last_updated: {
        day_diff: 365,
        updated_at: "",
      },
    };
  },
  computed: {
    maxHeight() {
      return /*(this.customHeight ?? 400) + */ "440px";
    },
  },
  methods: {
    getTRTopics() {
      this.$emit("getTRTopics", {
        mp: this.series.filter((v) => v.mp > 0).slice(0, 5),
        sw_keys: this.series.filter((v) => v.sw_keys > 0).slice(0, 5),
        soft_cosine: this.soft_cosine,
      });
    },
    getFixedSeries(items) {
      return items.map((v) => {
        const keys = Object.keys(v);
        keys.forEach((key) => {
          if (key === "topic") return;
          if (String(v[key]) === "0") return (v[key] = null);

          v[key] = Number(v[key]);
        });
        return v;
      });
    },
    fetchData(_, force = false) {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/view-info-by-site/get-data`;

      let config = {
        params: {
          force,
          domain: self.domain,
          type: "tr_topics",
          country: self.$route.query["country"] ?? 200,
          source: "view-info",
          first: this.isFirstFetch,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.series = this.getFixedSeries(r.data.data.data);
          self.soft_cosine = r.data.data.soft_cosine;
          self.last_updated = r.data.data.last_updated;
          self.getTRTopics();
          // if (!self.isValidRelevance && refresh_data) {
          //   self.refreshData()
          // }
        })
        .finally(() => {
          if (this.last_updated.day_diff > this.dataRelevance) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.fetchData(true, force);
            }, 30000);
          }

          this.loading = false;
        });

      this.isFirstFetch = false;
    },
    forceRefreshData() {
      this.data_relevance = 7;
      this.fetchData(true);
    },
  },
  created() {
    this.fetchData();
    this.createdAt();
  },
};
</script>

<style scoped></style>
