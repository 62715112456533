import eventPipe from "../events/eventPipe";

export default function auth({ to, from, next, store }) {
  if (!store.getters.isAuthenticated && from.fullPath !== "/login") {
    return next({
      name: "login",
    });
  }

  eventPipe.$emit("trackEvent", {
    module_name: to?.meta?.domain || null,
    page_name: to?.name || null,
    page_title: to?.meta?.title || null,
    active_project: store?.state?.project.domain || null,
  });

  return next();
}
