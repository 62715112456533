import Service from "@/plugins/service";

export default {
  state: {
    'linkDynamicDashbaord/report_type': null,
  },
  mutations: {
    'setLinkDynamicDashbaord/report_type'(state, payload) {
      state['linkDynamicDashbaord/report_type'] = payload
    }
  },
  actions: {
    async "links-dynamic-dashboard/fetchData"(ctx, payload) {
      const url = "/one-page-analyzer/links-dynamic/main";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
    async "competitor-urls-dynamic/fetchData"(ctx, payload) {
      const url = "/one-page-analyzer/competitors-links-dynamic/main";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
    async "competitor-urls-dynamic/collectBacklinksAhrefs"(ctx, payload) {
      const url = "competitors-links-dynamic/collect-backlinks-ahrefs";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
    async "links-dynamic-dashboard/getNotes"(ctx, payload) {
      const url = "/one-page-analyzer/links-dynamic/notes";
      const response = await Service.get(url, true, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
    async "links-dynamic-dashboard/saveNotes"(ctx, payload) {
      const url = "/one-page-analyzer/links-dynamic/notes";
      return await Service.post(url, payload);
    },
    async "links-dynamic-dashboard/unload"(ctx, payload) {
      const url = "/one-page-analyzer/links-dynamic/collect-backlinks-ahrefs";
      return await Service.post(url, payload);
    },
    async "links-dynamic-dashboard/collectParameters"(ctx, payload) {
      const url = "/one-page-analyzer/links-dynamic/collect-parameters";
      return await Service.post(url, payload);
    },
    async "competitors-links-dynamic/collectParameters"(ctx, payload) {
      const url =
        "/one-page-analyzer/competitors-links-dynamic/collect-backlinks-ahrefs";
      return await Service.post(url, payload);
    },
  },
  getters: {
    'linkDynamicDashbaord/report_type':state => state['linkDynamicDashbaord/report_type'],
  },
};
