import service from "../../../plugins/service";
import { PERFORMANCE_ENDPOINTS } from "../defaults";

export const performanceApiServices = () => {
  /** @param payload {import('../types.js').GetPerformancePayload} */
  const getPerformance = async (payload) => {
    return await service.post(PERFORMANCE_ENDPOINTS.GET_PERFORMANCE, {
      filter: payload,
    });
  };

  return { getPerformance };
};
