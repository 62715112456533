<template>
  <div>
    <v-card
        class="mx-auto"
        :loading="loading"
    >
      <v-card-text class="headline font-weight-bold">
        <v-row
            align="center"
            justify="center"
        >
          <v-col class="text-center">
            <v-data-table
                :headers="headers"
                :items="items"
                :sort-by="['id']"
                :sort-desc="[true]"
                multi-sort
                class="elevation-1"
            >
              <template v-slot:item.topics="{ item }">
                <v-btn
                    color="primary"
                    depressed
                    icon
                    @click="download(item.id,1)"
                >
                  <v-icon>
                    mdi-cloud-download
                  </v-icon>
                </v-btn>

              </template>
              <template v-slot:item.categories="{ item }">
                <v-btn
                    color="primary"
                    depressed
                    icon
                    @click="download(item.id,2)"
                >
                  <v-icon>
                    mdi-cloud-download
                  </v-icon>
                </v-btn>

              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <CreateTask/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CreateTask from "@/components/Textrazor/TaskManager/Dialogs/CreateTask";
import axios from "axios";

export default {
  name: "Index",
  components: {CreateTask},
  data() {
    return {
      loading: false,
      headers: [
        {text: 'Id', value: 'id', align: 'left', sortable: false},
        {text: 'Name', value: 'name', align: 'left', sortable: false},
        {text: 'All', value: 'c_all', align: 'left', sortable: false},
        {text: 'Collected', value: 'c_collected', align: 'left', sortable: false},
        // {text: 'Created At', value: 'created_at', align: 'center', width: 200, sortable: false},
        {text: 'Topics', value: 'topics', align: 'center', width: 50, sortable: false},
        {text: 'Categories', value: 'categories', align: 'center', width: 50, sortable: false},
      ],
      items: [],
    }
  },
  created() {
    let self = this;

    self.load_data()
  },
  methods: {
    load_data() {
      let self = this;

      self.loading = true

      let url = `${self.$store.state.server_url}/textrazor/task-manager/get_tasks`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.get(url, config)
          .then(function (response) {
            if (response.data.success) {
              self.items = response.data.tasks
              return
            }
            self.sheet = true
          })
          .catch(() => self.sheet = true)
          .finally(() => self.loading = false);
    },
    download(id, type) {
      let self = this

      let url = `${self.$store.state.server_url}/textrazor/task-manager/download/${id}/${type}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>

</style>