export default {
  methods: {
    goToSpiderLink(item, url) {
      try {
        const query = {};
        let queryString = "";
        const bannedKeys = ["url", "date"];
        const params = new URLSearchParams(window.location.search);

        for (const key of params.keys()) {
          if (bannedKeys.includes(key)) continue;
          query[key] = params.get(key);
        }

        Object.keys(item.filter).forEach((key) => {
          query[key] = item.filter[key];
        });

        Object.keys(query).forEach((key) => {
          queryString += `&${key}=${query[key]}`;
        });

        const taskId =
          this._$getActiveProject.projects_settings.spiderlink.task_id;

        const queryUrl = this.$route.query.sl_url;

        let fixedUrl = encodeURIComponent(url.value ? url.value : url);

        if (queryUrl) {
          fixedUrl = encodeURIComponent(queryUrl);
        }

        const path = `http://new.spiderlink.pro/dashboards/all-links/?url=${fixedUrl}${queryString}&task_id=${taskId}`;

        window.open(path, "_blank");
      } catch(e) {
        console.error(e)
        this.$message.notification({
          title: "Access denied",
          text: 'You do not have access to this task.',
          type: 'error',
        })
      }
    },
  },
};
