<template>
  <v-card class="styled-card--default">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="form.theme_id"
            :items="themes"
            label="Select theme"
            placeholder="Type to search"
            filled
            hide-details
            dense
            clearable
            @change="handleChangeTheme"
          />
        </v-col>
        <v-col cols="12">
          <v-slider
            v-model="form.rating"
            min="0"
            max="10"
            hide-details
            thumb-label="always"
            thumb-size="22"
            dense
          />
        </v-col>
        <v-col cols="12">
          <tip-tap-editor
            :model-value="form.comment"
            @update:modelValue="form.comment = $event"
          />
          <small
            v-if="form.comment && form.comment.length <= commentSymbolsLimit"
          >
            characters left:
            {{ commentSymbolsLimit - form.comment.length }}
          </small>
          <small
            v-else-if="
              form.comment && form.comment.length > commentSymbolsLimit
            "
            class="error--text"
          >
            You've reached characters limit.
          </small>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-menu
        v-if="validate.error"
        bottom
        offset-y
        content-class="my-shadow--e3"
      >
        <template #activator="{ on }">
          <v-chip v-on="on" outlined label class="mr-2 px-2">
            <v-icon small color="error">mdi-alert </v-icon>
          </v-chip>
        </template>
        <v-card class="styled-card--error">
          <v-card-title>
            <v-icon color="error" small class="mr-2">mdi-alert</v-icon>
            Errors
          </v-card-title>
          <v-divider />
          <v-card-text>
            <span class="error--text">
              {{ validate.message }}
            </span>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-chip
        label
        color="primary"
        @click="save"
        :disabled="validate.error || loading"
      >
        Send comment
        <v-icon v-if="!loading" small right>mdi-send</v-icon>
        <v-progress-circular v-else size="18" width="2" indeterminate />
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
import TipTapEditor from "@/components/UI/TipTapEditor.vue";
import eventPipe from "@/events/eventPipe";

export default {
  components: { TipTapEditor },
  props: {
    modelValue: {},
    loading: Boolean,
    themes: Array,
  },
  data: () => ({
    commentSymbolsLimit: 500,
  }),
  emits: ["update:modelValue"],
  computed: {
    validate() {
      if (this.form.rating === 0) {
        return {
          message: "Rating cant be 0.",
          error: true,
        };
      }

      return {
        error: false,
      };
    },
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  mounted() {
    eventPipe.$on("site-analysis-guide-success-saved", () => {
      this.partiallyClearForm();
    });
  },
  methods: {
    handleChangeTheme() {
      if (!this.form.theme_id) return;

      eventPipe.$emit("update-guide-comments", this.form);
    },
    partiallyClearForm() {
      this.form.rating = 0;
      this.form.comment = "";
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>
