export default {
  methods: {
    handleUpdateDimension(value, item) {
      if (
        Array.isArray(value) &&
        value.length > 0 &&
        !this.filters.view_dimension.includes(item)
      ) {
        this.filters.view_dimension = [...this.filters.view_dimension, item];
      }
    },
  },
};
