<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense class="justify-center">
        <v-col cols="4" v-if="data.data[0]">
          <apexchart
            type="radialBar"
            height="350"
            :options="getPieOptions()"
            :series="data.data[0].series"
          />
        </v-col>
        <v-col v-else cols="4">
          <no-items-placeholder />
        </v-col>
        <v-col cols="8" v-if="data.data[1]">
          <apexchart
            type="line"
            height="350"
            :options="getChartOptions()"
            :series="data.data[1].series"
          />
        </v-col>
        <v-col v-else cols="8">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { deepClone } from "../../../utils/functions";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  methods: {
    getPieOptions() {
      return {
        chart: {
          height: 350,
          type: "radialBar",
          offsetY: -10,
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
              },
              value: {
                offsetY: 16,
                fontSize: "22px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 0,
        },
        labels: this.data.data[0].categories,
      };
    },
    getChartOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          animations: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "12px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "datetime",
          categories: this.data.data[1].categories,
        },
        tooltip: {
          enabled: true,
          // eslint-disable-next-line no-unused-vars
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let seriesHtml = "";

            // console.log(deepClone(w.config));

            const sortedByNameUsers = deepClone(w.config.series).sort(
              (a, b) => {
                if (a.data[dataPointIndex] > b.data[dataPointIndex]) return -1;
                if (a.data[dataPointIndex] < b.data[dataPointIndex]) return 1;
                return 0;
              }
            );

            sortedByNameUsers
              .filter((v) => v.name !== "p, %")
              .forEach((user) => {
                const currentData = user.data[dataPointIndex];

                if (String(currentData) === "0") return;

                seriesHtml += `<tr><td>${user.name}</td> <td style="font-weight: 600">${currentData}</td></tr>`;
              });

            try {
              seriesHtml += `<tr style="font-weight: 600;"><td style="border-top:1px solid rgba(155,155,155,0.3)">p, %</td><td style="border-top:1px solid rgba(155,155,155,0.3)">${
                sortedByNameUsers.filter((v) => v.name === "p, %")[0].data[
                  dataPointIndex
                ]
              }</td></tr>`;
            } catch {
              // console.error("Cannot contain p, % ", e);
            }

            return `
<div class="main-4dx-dashboard__chart-tooltip">
<table>
<tbody>
${seriesHtml}
</tbody>
</table>
</div>
            `;
          },
        },
        stroke: {
          lineCap: "butt",
          curve: "straight",
          width: 2,
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>
