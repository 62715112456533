<template>
  <v-tooltip bottom max-width="320">
    <template #activator="{ on }">
      <v-icon v-on="on" style="opacity: 0.5" small
        >mdi-information-outline</v-icon
      >
    </template>
    <template #default>
      If enabled, you get access to a new design for project selection input.

      <div class="mt-4">
        The input for selecting projects is in the top left corner of the app.
      </div>
    </template>
  </v-tooltip>
</template>

<script>
export default {};
</script>
