export default {
    state: {
        error: null,
    },
    mutations: {
        set_error(state, payload) {
            state.error = payload
        }
    },
    actions: {},
    getters: {
        get_error: state => state.error,
    },
}