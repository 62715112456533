<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            max-width="320px"
            rounded="xl"
            class="shadow-e1-bordered"
            style="text-align: center"
          >
            <v-card-title class="pb-0 pt-0 justify-center">
              <div style="max-width: 240px">
                <lohness-montser />
              </div>
            </v-card-title>
            <v-card-title class="justify-center pt-0">
              <span style="font-size: 48px; font-weight: 600">404</span>
            </v-card-title>
            <v-card-text>
              Sorry, the page you’re looking for can’t be found
            </v-card-text>
            <v-card-actions class="justify-center pb-6">
              <v-btn
                width="160px"
                color="primary"
                rounded
                @click="$router.push('/')"
                >Main page</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import LohnessMontser from "../UI/Icons/LohnessMontser";
export default {
  name: "PageNotFound",
  components: { LohnessMontser },
  methods: {
    go_to_login() {
      let self = this;
      self.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
