export default {
  state: {
    activeItem: null,
  },
  mutations: {
    async "pages/setActiveItem"(state, payload) {
      state.activeItem = payload;
    },
  },
  actions: {},
  getters: {
    "pages/activeItem": (state) => state.activeItem,
  },
};
