<template>
  <div>
    <v-row>
      <v-col v-if="worksection_daily.items && worksection_daily.items.length>0">
        <h4>Tasks Daily</h4>
        <v-card>
          <v-data-table
              :headers="worksection_daily.headers"
              :items="worksection_daily.items"
              class="elevation-1"
              :sort-by="['status','l_p','priority','user_to']"
              :sort-desc="[false,false,true,false]"
              dense
          >
            <template v-slot:item.user_from="{ item }">
              {{ item.user_from.name }}
            </template>

            <template v-slot:item.name="{ item }">
              <a target="_blank" :href="'https://ra.worksection.com/'+item.page">{{ item.name }}</a>
            </template>

            <template v-slot:item.user_to="{ item }">
              {{ item.user_to.name }}
            </template>

            <template v-slot:item.l_p="{ item }">
              {{ item.l }}
            </template>

          </v-data-table>
        </v-card>
      </v-col>
      <v-col v-if="worksection_late.items && worksection_late.items.length>0">
        <h4>Tasks Overdue</h4>
        <v-card>
          <v-data-table
              :headers="worksection_late.headers"
              :items="worksection_late.items"
              class="elevation-1"
              :sort-by="['status','l_p','priority','user_to']"
              :sort-desc="[false,false,true,false]"
              dense
          >
            <template v-slot:item.user_from="{ item }">
              {{ item.user_from.name }}
            </template>

            <template v-slot:item.name="{ item }">
              <a target="_blank" :href="'https://ra.worksection.com/'+item.page">{{ item.name }}</a>
            </template>

            <template v-slot:item.user_to="{ item }">
              {{ item.user_to.name }}
            </template>

            <template v-slot:item.l_p="{ item }">
              {{ item.l }}
            </template>

          </v-data-table>
        </v-card>
      </v-col>
      <!-- <v-col v-if="worksection_user.items.length>0">
         <h4>Tasks User</h4>
         <v-card>
           <v-data-table
               :headers="worksection_user.headers"
               :items="worksection_user.items"
               class="elevation-1"
               :sort-by="['status','l_p','priority','user_to']"
               :sort-desc="[false,false,true,false]"
               dense
           >
             <template v-slot:item.user_from="{ item }">
               {{ item.user_from.name }}
             </template>

             <template v-slot:item.name="{ item }">
               <a target="_blank" :href="'https://ra.worksection.com/'+item.page">{{ item.name }}</a>
             </template>

             <template v-slot:item.user_to="{ item }">
               {{ item.user_to.name }}
             </template>

             <template v-slot:item.l_p="{ item }">
               {{ item.l }}
             </template>

           </v-data-table>
         </v-card>
       </v-col>-->
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Table",
  props: ['id', 'subproject_id', 'worksection_id', 'filter'],
  data() {
    return {
      worksection_daily: {
        headers: [
          {text: 'd', title: 'status', value: 'status', class: 'text-center'},
          {text: 'name', title: 'name', value: 'name', class: 'text-center'},
          {text: 'l', title: 'tags', value: 'l_p', class: 'text-center'},
          {text: 'pr', title: 'priority', value: 'priority', class: 'text-center'},
          {text: 'time', title: 'time', value: 'time', class: 'text-center'},
          {text: 'est', title: 'max_time', value: 'max_time', class: 'text-center'},
          {text: 'executive', title: 'user_to', value: 'user_to', class: 'text-center'},
          {text: 'labels', title: 'tags', value: 'labels', class: 'text-center'},
          {text: 'finish', title: 'date_end', value: 'date_end', class: 'text-center'},
          {text: 'C,d', title: 'days', value: 'days', class: 'text-center'},
          {text: 'author', title: 'user_from', value: 'user_from', class: 'text-center'},
        ],
        items: []
      },
      worksection_late: {
        headers: [
          {text: 'd', title: 'status', value: 'status', class: 'text-center'},
          {text: 'name', title: 'name', value: 'name', class: 'text-center'},
          {text: 'l', title: 'tags', value: 'l_p', class: 'text-center'},
          {text: 'pr', title: 'priority', value: 'priority', class: 'text-center'},
          {text: 'time', title: 'time', value: 'time', class: 'text-center'},
          {text: 'est', title: 'max_time', value: 'max_time', class: 'text-center'},
          {text: 'executive', title: 'user_to', value: 'user_to', class: 'text-center'},
          {text: 'labels', title: 'tags', value: 'labels', class: 'text-center'},
          {text: 'finish', title: 'date_end', value: 'date_end', class: 'text-center'},
          {text: 'C,d', title: 'days', value: 'days', class: 'text-center'},
          {text: 'author', title: 'user_from', value: 'user_from', class: 'text-center'},
        ],
        items: []
      },
     /* worksection_user: {
        headers: [
          {text: 'd', title: 'status', value: 'status', class: 'text-center'},
          {text: 'name', title: 'name', value: 'name', class: 'text-center'},
          {text: 'l', title: 'tags', value: 'l_p', class: 'text-center'},
          {text: 'pr', title: 'priority', value: 'priority', class: 'text-center'},
          {text: 'time', title: 'time', value: 'time', class: 'text-center'},
          {text: 'est', title: 'max_time', value: 'max_time', class: 'text-center'},
          {text: 'executive', title: 'user_to', value: 'user_to', class: 'text-center'},
          {text: 'labels', title: 'tags', value: 'labels', class: 'text-center'},
          {text: 'finish', title: 'date_end', value: 'date_end', class: 'text-center'},
          {text: 'C,d', title: 'days', value: 'days', class: 'text-center'},
          {text: 'author', title: 'user_from', value: 'user_from', class: 'text-center'},
        ],
        items: []
      },*/
    }
  },
  methods: {
    fetchData() {
      let self = this

      let url = `${self.$store.state.server_url}/ltt/types-dynamics/json`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        type: 'worksection',
        project_id: self.id,
        subproject_id: self.subproject_id,
        worksection_id: self.worksection_id,
        user: self.filter.seo
      }

      axios
          .post(url, data, config)
          .then((r) => {
            self.worksection_daily.items = r.data.data.worksection_daily.items
            self.worksection_late.items = r.data.data.worksection_late.items
           // self.worksection_user.items = r.data.data.worksection_user.items

          })
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    getProjects() {
      return this.$store.state.project.allowed_projects
    },
  },
  created() {
    let self = this

    self.fetchData();
  },
}
</script>

<style scoped>

</style>