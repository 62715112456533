<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12">
          <v-banner>
            <v-menu
              offset-y
              class="float-left"
              v-if="['not_code_200', 'noindex'].indexOf(select_item_cat) !== -1"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  outlined
                  color="primary"
                  class="white--text"
                  v-bind="attrs"
                  v-on="on"
                  :loading="menu_loading"
                >
                  Actions
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link>
                  <v-list-item-title @click="mark_links_as_correct">
                    I know about this problem
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-select
                style="max-width: 150px"
                dense
                v-model="select_item_cat"
                :items="select_items_cat"
                label="Category"
                hide-details
              ></v-select>
              <v-select
                style="max-width: 150px"
                dense
                v-model="select_item_type"
                :items="select_items_types"
                label="Type"
                hide-details
              ></v-select>
              <v-select
                style="max-width: 150px"
                dense
                v-model="select_item_pattern"
                :items="select_items_patterns"
                label="Pattern"
                hide-details
              ></v-select>
              <div
                style="width: 186px; display: inline-block; margin-right: 10px"
              >
                <v-menu
                  v-model="dates_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_range_text"
                      label="Date range"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    :allowed-dates="get_allowed_dates"
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      :loading="global_loading"
                      @click="fetch_data"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <v-btn icon :loading="global_loading" @click="fetch_data">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
          </v-banner>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="1">
          <v-select
            dense
            v-model="select_item_cat"
            :items="select_items_cat"
            label="Category"
          ></v-select>
        </v-col>
        <v-col cols="12" md="1">
          <v-select
            dense
            v-model="select_item_type"
            :items="select_items_types"
            label="Type"
          ></v-select>
        </v-col>
        <v-col cols="12" md="1">
          <v-select
            dense
            v-model="select_item_pattern"
            :items="select_items_patterns"
            label="Pattern"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <div style="width: 186px; display: inline-block; margin-right: 10px">
            <v-menu
              v-model="dates_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_range_text"
                  label="Date range"
                  dense
                  v-bind="attrs"
                  v-on="on"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                :allowed-dates="get_allowed_dates"
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  :loading="global_loading"
                  @click="fetch_data"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <v-btn icon :loading="global_loading" @click="fetch_data">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            solo
          ></v-text-field>
          <v-data-table
            v-model="items_selected"
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            :custom-sort="custom_sort"
            :single-select="false"
            :search="search"
            :custom-filter="filter_datatables"
            item-key="url.now"
            class="elevation-1 mt-4"
            show-select
          >
            <template v-slot:item.date="{ item }">
              {{ item.date.now }}
            </template>
            <template v-slot:item.url="{ item }">
              {{ get_pathname(item.url.now) }}
              <v-btn icon @click="open_url(item.url.now)">
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.title="{ item }">
              <div style="width: 600px">
                <div v-if="item.title.now === 'none'" class="error--text">
                  <b style="font-size: 1rem">Missing title</b>
                </div>
                <div v-else>
                  <b
                    :class="item.title.changed ? 'primary--text' : ''"
                    style="font-size: 1rem"
                  >
                    {{ item.title.now }}
                  </b>
                </div>
                <div v-if="item.title.changed" class="grey--text">
                  <b>{{ item.title.prev }}</b>
                </div>
                <br />
                <div v-if="item.desc.now === 'none'" class="error--text">
                  <span>Missing description</span>
                </div>
                <div v-else :class="item.desc.changed ? 'primary--text' : ''">
                  {{ item.desc.now }}
                </div>
                <div v-if="item.desc.changed" class="grey--text">
                  {{ item.desc.prev }}
                </div>
              </div>
            </template>
            <template v-slot:item.h1="{ item }">
              <div v-if="item.h1.now === 'none'" class="error--text">
                <span>Missing H1</span>
              </div>
              <div v-else>
                <b :class="item.h1.changed ? 'primary--text' : ''">
                  {{ item.h1.now }}
                </b>
              </div>
              <div v-if="item.h1.changed" class="grey--text mt-1">
                {{ item.h1.prev }}
              </div>
            </template>
            <template v-slot:item.canonical="{ item }">
              <div>
                <v-icon
                  v-if="item.canonical.now === item.url.now"
                  color="success"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.canonical.now !== item.url.now"
                  color="error"
                >
                  mdi-close
                </v-icon>
                <v-icon v-else-if="item.canonical.now === 'none'" color="grey">
                  mdi-cancel
                </v-icon>
              </div>
              <div v-if="item.canonical.changed" class="mt-1">
                <v-icon
                  v-if="item.canonical.prev === item.url.now"
                  color="success"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.canonical.prev !== item.url.now"
                  color="error"
                >
                  mdi-close
                </v-icon>
                <v-icon v-else-if="item.canonical.prev === 'none'" color="grey">
                  mdi-cancel
                </v-icon>
              </div>
            </template>
            <template v-slot:item.code="{ item }">
              <v-chip
                v-if="item.code.now === 200"
                color="success"
                label
                outlined
              >
                {{ item.code.now }}
              </v-chip>
              <v-chip
                v-else-if="item.code.now > 200 && item.code.now < 300"
                color="warning"
                label
              >
                <v-icon left> mdi-alert-outline </v-icon>
                {{ item.code.now }}
              </v-chip>
              <v-chip
                v-else-if="item.code.now > 300 && item.code.now < 400"
                color="info"
                label
              >
                {{ item.code.now }}
              </v-chip>
              <v-chip
                v-else-if="item.code.now > 400 && item.code.now < 500"
                color="warning"
                label
              >
                {{ item.code.now }}
              </v-chip>
              <v-chip v-else-if="item.code.now > 500" color="error" label>
                {{ item.code.now }}
              </v-chip>
              <div v-if="item.code.changed" class="mt-1">
                <v-chip color="grey" label outlined>
                  {{ item.code.prev }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.depth="{ item }">
              <v-chip v-if="item.depth.now < 3" color="success" label outlined>
                {{ item.depth.now }}
              </v-chip>
              <v-chip
                v-else-if="item.depth.now < 4"
                color="warning"
                label
                outlined
              >
                {{ item.depth.now }}
              </v-chip>
              <v-chip v-else color="error" label outlined>
                {{ item.depth.now }}
              </v-chip>
              <div v-if="item.depth.changed" class="mt-1">
                <v-chip color="grey" label outlined>
                  {{ item.depth.prev }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.noindex="{ item }">
              <div>
                <v-icon
                  v-if="item.noindex.now === 0 && item.robots.now === 1"
                  color="success"
                >
                  mdi-check-all
                </v-icon>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" dark v-bind="attrs" v-on="on">
                        mdi-close
                      </v-icon>
                    </template>
                    <span v-if="item.noindex.now === 1">NI</span>
                    <span v-else>R</span>
                  </v-tooltip>
                </div>
              </div>
              <div v-if="item.noindex.changed">
                <v-icon
                  v-if="item.noindex.prev === 0 && item.robots.prev === 1"
                  color="success"
                >
                  mdi-check-all
                </v-icon>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" dark v-bind="attrs" v-on="on">
                        mdi-close
                      </v-icon>
                    </template>
                    <span v-if="item.noindex.prev === 1">NI</span>
                    <span v-else>R</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template v-slot:item.text="{ item }">
              <span v-if="item.blocks.changed">
                <v-btn
                  icon
                  link
                  :to="{
                    name: 'content.checker.compare-blocks',
                    query: { dates: dates, url: item.url.now },
                    params: { id: item.id.now },
                  }"
                  target="_blank"
                >
                  <v-icon
                    size="14"
                    :color="item.blocks.add > 0 ? 'success' : 'info'"
                    v-html="
                      item.blocks.add > 0
                        ? 'mdi-arrow-up-thick'
                        : 'mdi-arrow-up-down'
                    "
                  ></v-icon>
                  {{ item.blocks.add > 0 ? item.blocks.add : "" }}
                </v-btn>
                <v-btn
                  icon
                  link
                  :to="{
                    name: 'content.checker.compare-blocks',
                    query: { dates: dates, url: item.url.now },
                    params: { id: item.id.now },
                  }"
                  target="_blank"
                >
                  <v-icon
                    size="14"
                    :color="item.blocks.remove > 0 ? 'error' : 'info'"
                    v-html="
                      item.blocks.remove > 0
                        ? 'mdi-arrow-down-thick'
                        : 'mdi-arrow-up-down'
                    "
                  ></v-icon>
                  {{ item.blocks.remove > 0 ? item.blocks.remove : "" }}
                </v-btn>
              </span>
            </template>
            <template v-slot:item.uniq_text="{ item }">
              <span v-if="item.uniq_blocks.changed">
                <v-btn
                  icon
                  link
                  :to="{
                    name: 'content.checker.compare-blocks',
                    query: { dates: dates, uniq: true, url: item.url.now },
                    params: { id: item.id.now },
                  }"
                  target="_blank"
                >
                  <v-icon
                    size="14"
                    :color="item.uniq_blocks.add > 0 ? 'success' : 'info'"
                    v-html="
                      item.uniq_blocks.add > 0
                        ? 'mdi-arrow-up-thick'
                        : 'mdi-arrow-up-down'
                    "
                  ></v-icon>
                  {{ item.uniq_blocks.add > 0 ? item.uniq_blocks.add : "" }}
                </v-btn>
                <v-btn
                  icon
                  link
                  :to="{
                    name: 'content.checker.compare-blocks',
                    query: { dates: dates, uniq: true, url: item.url.now },
                    params: { id: item.id.now },
                  }"
                  target="_blank"
                >
                  <v-icon
                    size="14"
                    :color="item.uniq_blocks.remove > 0 ? 'error' : 'info'"
                    v-html="
                      item.uniq_blocks.remove > 0
                        ? 'mdi-arrow-down-thick'
                        : 'mdi-arrow-up-down'
                    "
                  ></v-icon>
                  {{
                    item.uniq_blocks.remove > 0 ? item.uniq_blocks.remove : ""
                  }}
                </v-btn>
              </span>
              <!--              <v-btn icon link-->
              <!--                     :to="{ name: 'content.checker.compare-blocks', query: {dates: dates}, params: {'id': item.id.now} }"-->
              <!--                     target="_blank">-->
              <!--                <v-icon>mdi-calendar-clock</v-icon>-->
              <!--              </v-btn>-->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "@/events/eventPipe";

export default {
  name: "ContentCheckerChanges",
  data() {
    return {
      search: "",
      menu_loading: false,
      allowed_dates: [],
      dates: [],
      dates_menu: false,
      skip_noindex: false,
      select_item_cat: "num_of_pages",
      select_item_type: "all",
      select_item_pattern: "All",
      select_items_cat: [
        { text: "Num of pages", value: "num_of_pages" },
        { text: "Code 200", value: "code_200" },
        { text: "Code not 200", value: "not_code_200" },
        { text: "Noindex", value: "noindex" },
        { text: "Index to noindex", value: "index_to_noindex" },
        { text: "Noindex to index", value: "noindex_no_index" },
        { text: "Non ascii url", value: "non_ascii_url" },
        { text: "Non ascii text", value: "non_ascii_text" },
        { text: "TDH", value: "tdh" },
      ],
      select_items_types: [
        { text: "All", value: "all" },
        { text: "New", value: "new" },
        { text: "Removed", value: "removed" },
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "H1", value: "h1" },
      ],
      select_items_patterns: [],
      headers: [
        { text: "Date", value: "date", width: 105 },
        { text: "Url", value: "url" },
        {
          text: "Title / Description",
          value: "title",
          align: "left",
          sortable: false,
        },
        // {text: 'Description', value: 'desc', align: 'left', sortable: false},
        { text: "H1", value: "h1", align: "left", sortable: false },
        { text: "Depth", value: "depth", align: "center", sortable: false },
        { text: "Code", value: "code", align: "center", sortable: false },
        { text: "Text", value: "text", align: "center", sortable: false },
        { text: "UText", value: "uniq_text", align: "center", sortable: false },
        { text: "I", value: "noindex", align: "center", sortable: false },
        { text: "Can", value: "canonical", align: "center", sortable: false },
        { text: "", value: "actions", align: "center", sortable: false },
        // {text: 'R', value: 'robots', align: 'center', sortable: false},
      ],
      items: [],
      items_selected: [],
    };
  },
  computed: {
    date_range_text() {
      return this.dates.join(" ~ ");
    },
    global_loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    filter_datatables(value, search) {
      if (value !== undefined) {
        if (
          (value.now !== undefined &&
            value.now !== null &&
            value.now.toString().toLowerCase().indexOf(search.toLowerCase()) !==
              -1) ||
          (value.prev !== undefined &&
            value.prev !== null &&
            value.prev
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1)
        ) {
          return true;
        }
      }

      return false;
    },
    mark_links_as_correct() {
      let self = this;

      if (self.items_selected.length === 0) {
        return;
      }

      let config = {
        params: {
          type: "mark_correct_urls",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let post_data = {
        urls: self.items_selected.map((v) => v.id.now),
      };

      self.menu_loading = true;

      axios
        .post(
          `${self.$store.state.server_url}/content-checker/main`,
          post_data,
          config
        )
        .then((r) => {
          self.select_items_patterns = r.data.categories;
          self.allowed_dates = r.data.allowed_dates;

          self.items_selected = [];
          self.fetch_data();
        })
        .catch(() => {
          eventPipe.$emit("snackbar", {
            type: "error",
            message: "Hello dolly",
          });
        })
        .finally(() => (self.menu_loading = false));
    },
    open_url(url) {
      window.open(url, "_blank");
    },
    get_pathname(url) {
      const myURL = new URL(url);
      return myURL.pathname + myURL.search;
    },
    custom_sort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortDesc) {
          return this.compare(a, b);
        } else {
          return this.compare(b, a);
        }
      });
      return items;
    },
    compare(a, b) {
      if (a.code.now < b.code.now) return -1;
      if (a.code.now > b.code.now) return 1;

      if (a.depth.now < b.depth.now) return 1;
      if (a.depth.now > b.depth.now) return -1;

      if (a.noindex.now < b.noindex.now) return 1;
      if (a.noindex.now > b.noindex.now) return -1;

      if (a.url.now < b.url.now) return 1;
      if (a.url.now > b.url.now) return -1;

      return 0;
    },
    get_allowed_dates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    fetch_static_data(callback) {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/content-checker/dashboard/static-data/${self.$route.params.id}`,
          config
        )
        .then((r) => {
          self.select_items_patterns = r.data.categories;
          self.allowed_dates = r.data.allowed_dates;
        })
        .finally(() => {
          self.$store.state.loading = false;
          callback();
        });
    },
    fetch_data() {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          cat: self.select_item_cat,
          type: self.select_item_type,
          category: self.select_item_pattern,
          dates: self.dates,
          skip_noindex: self.skip_noindex,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/content-checker/changes/${self.$route.params.id}`,
          config
        )
        .then((r) => {
          self.items = r.data.items;
        })
        .finally(() => {
          self.$store.state.loading = false;
          self.dates_menu = false;
        });
    },
  },
  created() {
    const project_id = this.$route.query.project_id;
    if (project_id) {
      this.$store.dispatch("update_active_project", project_id);
      this.$store.dispatch("update_domain");
      eventPipe.$emit("update-active-project");
    }

    let self = this;

    self.dates = self.$route.query.dates;
    self.select_item_cat = self.$route.query.cat;
    self.select_item_type = self.$route.query.type;
    self.select_item_pattern = self.$route.query.category;
    self.skip_noindex = self.$route.query.skip_noindex !== undefined;

    eventPipe.$on("update-active-project", () => {
      self.fetch_static_data(() => {
        self.fetch_data();
      });
    });

    self.fetch_static_data(() => {
      self.fetch_data();
    });
  },
};
</script>

<style scoped></style>
