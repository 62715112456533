<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageData.TITLE"
          :breadcrumbs="pageData.BREADCRUMBS"
        />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader v-if="pending" type="table" />
        <v-data-table
          v-else
          dense
          :items="tableData.items"
          :headers="tableData.headers"
          class="border rounded-lg"
          :page.sync="pagination.current"
          :items-per-page.sync="pagination.per_page"
          :server-items-length="pagination.total"
          @update:page="onPageChange"
          @update:items-per-page="onRowsPerPageChange"
        >
          <template
            v-for="key in ['created_at', 'updated_at', 'redirect_date']"
            #[`item.${key}`]="{ value }"
          >
            {{ value ? $moment(value).format("ll hh:mm") : "-" }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "../../../components/UI/PageHeader.vue";
import { REDIRECTS_RESULTS_PAGE_DATA } from "./utils/defaults";
import { redirectsApiService } from "../services/redirectsApiService";

export default {
  components: { PageHeader },

  data: () => ({
    pending: false,
    apiService: null,
    tableData: {
      items: [],
      headers: REDIRECTS_RESULTS_PAGE_DATA.DATA_TABLE_HEADERS,
    },
    pagination: {
      current: 1, // текущая страница
      per_page: 50, // количество элементов на странице
      total: 0, // общее количество элементов
    },
  }),

  computed: {
    pageData() {
      return REDIRECTS_RESULTS_PAGE_DATA;
    },
  },

  created() {
    this.apiService = redirectsApiService();
  },

  mounted() {
    this.initializeDashboard();
  },

  methods: {
    async initializeDashboard() {
      try {
        this.pending = true;

        const { data } = await this.apiService.getResults({
          page: this.pagination.current,
          rowsPerPage: this.pagination.per_page,
        });

        this.tableData.items = data.data;
        this.pagination.total = data.total;
        this.pagination.current = data.current_page;
        this.pagination.per_page = data.per_page;

        console.log(data);
      } catch (e) {
        console.error("Error while initializing page.", e);
      } finally {
        this.pending = false;
      }
    },
    onPageChange(newPage) {
      this.pagination.current = newPage;
      this.initializeDashboard();
    },
    onRowsPerPageChange(newRowsPerPage) {
      this.pagination.per_page = newRowsPerPage;
      this.initializeDashboard();
    },
  },
};
</script>
