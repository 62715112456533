import { checkRole } from "@/middleware/helpers";

export default function RoleMiddleware({ next, store, to }) {
  const admins = [24, 971];
  if (
    store.getters["auth/user"] &&
    admins.includes(store.getters["auth/user"].id)
  )
    return next();

  if (checkRole(store.state.base.flat_permissions, to)) {
    return next();
  }
  return next({
    name: "access-denied",
  });
}
