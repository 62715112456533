<template>
  <v-card class="site-analysis-table-section styled-card--default">
    <v-card-title :data-page-navigation="SECTIONS.top_pages.sectionTitle">
      <!--      AH Top Pages-->
      {{ SECTIONS.top_pages.sectionTitle }}
      <force-update-button :section-name="sectionName" />
      <link-to-ahrefs
        type="top-pages"
        :domain="$store.getters['site-analysis/activeDomain']"
      />
      <v-spacer />
      <type-switcher
        :section-name="sectionName"
        :default-prefix="SECTIONS.top_pages.prefix"
      />
      <v-spacer />
      <v-autocomplete
        v-if="successfullyUploaded"
        v-model="selectedNicheFilter"
        :items="nicheItems"
        placeholder="Niche"
        hide-details
        dense
        clearable
        filled
        style="max-width: 130px"
      ></v-autocomplete>
    </v-card-title>
    <v-card-subtitle class="d-flex align-center">
      <last-update-info
        :data="data"
        :successfully-uploaded="successfullyUploaded"
      />
      <v-spacer />

      <table-search
        v-if="successfullyUploaded"
        class="mt-2"
        :model-value="search"
        @update:modelValue="search = $event"
        placeholder="Type to search in table"
        style="max-width: 320px"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="px-0">
      <div v-if="loading" class="skeleton loading" style="height: 300px" />
      <template v-else>
        <template v-if="successfullyUploaded">
          <v-data-table
            :search="search"
            :items="filteredByNicheTableItems"
            :headers="data?.headers"
            multi-sort
            dense
            :items-per-page="-1"
            :sort-by="['traffic_percent']"
            :sort-desc="[true]"
            :custom-sort="_$smartCustomTableSort"
          >
            <template
              v-for="col in data.headers"
              #[`item.${col.value}`]="{ item }"
            >
              <template v-if="col.value === 'traffic'">
                <div :key="col.value">
                  <b :style="getTextStyles(item.traffic)">{{ item.traffic }}</b>
                  <sup class="ml-1" style="color: rgba(155, 155, 155, 1)"
                    >{{ item.traffic_percent }}%</sup
                  >
                </div>
              </template>
              <template v-else-if="col.value === 'traffic_value'">
                <span
                  :key="col.value"
                  :style="getTextStyles(item.traffic_value)"
                >
                  {{ item.traffic_value }}</span
                >
              </template>
              <template v-else-if="col.value === 'keywords_count'">
                <span
                  :key="col.value"
                  :style="getTextStyles(item.keywords_count)"
                >
                  {{ item.keywords_count }}
                </span>
              </template>
              <template v-else-if="col.value === 'url'">
                <div
                  :key="col.value"
                  class="text-wrap"
                  style="word-break: break-all; max-width: 500px"
                >
                  <a :href="item.url" target="_blank">{{
                    item.path || item.url
                  }}</a>
                </div>
              </template>
              <template v-else-if="col.value === 'top_keyword_volume'">
                <span
                  :key="col.value"
                  :style="getTextStyles(item.top_keyword_volume)"
                >
                  {{ item.top_keyword_volume }}
                </span>
              </template>
              <template v-else-if="col.value === 'top_keyword_position'">
                <span
                  :key="col.value"
                  :style="getTextStyles(item.top_keyword_position)"
                >
                  {{ item.top_keyword_position }}
                </span>
              </template>
              <template v-else>
                {{ item[col.value] }}
              </template>
            </template>
            <template #[`item.traffic_percent`]="{ value }">
              {{ toFixed(value) }}
            </template>
          </v-data-table>
        </template>
        <template v-else>
          <not-collected-placeholder />
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import TableSorting from "../../../mixins/TableSorting";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import DataTableFilterByNicheMixin from "../../../mixins/components/SiteAnalysis/DataTableFilterByNicheMixin";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";
import LinkToAhrefs from "../UI/LinkToAhrefs.vue";
import TypeSwitcher from "@/components/SiteAnalysis/UI/TypeSwitcher.vue";
import TableSearch from "@/components/UI/TableSearch.vue";

export default {
  components: {
    TableSearch,
    TypeSwitcher,
    LinkToAhrefs,
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
  },
  mixins: [TableSorting, DataTableFilterByNicheMixin, SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.data.data;
    },
  },
  data: () => ({
    search: null,
    headers: [
      {
        text: "Traffic",
        title: "Traffic",
        value: "traffic",
        align: "center",
      },
      {
        text: "Value",
        title: "Value",
        value: "traffic_value",
        align: "center",
      },
      {
        text: "Keywords",
        title: "Keywords",
        value: "keywords_count",
        align: "center",
      },
      {
        text: "KD",
        title: "Keywords difficulty",
        value: "difficulty",
        class: "text-left",
      },
      {
        text: "Page URL",
        title: "Page URL",
        value: "url",
        class: "text-center",
      },
      {
        text: "Top keyword",
        title: "Top keyword",
        value: "top_keyword",
        class: "text-center",
      },
      {
        text: "Its volume",
        title: "Its volume",
        value: "top_keyword_volume",
        align: "center",
      },
      {
        text: "Pos",
        title: "Pos",
        value: "top_keyword_position",
        align: "center",
      },
    ],
  }),
  methods: {
    toFixed(value) {
      return Math.abs(Number(value)) >= 0.01
        ? Math.round(Number(value) * 100) / 100
        : Math.abs(Number(value)) < 0.01 && Math.abs(Number(value)) > 0
        ? 0
        : value;
    },
    getTextStyles(value) {
      if (Number(value) === 0) return "opacity: 0.3;";
      return "";
    },
  },
};
</script>
