<template>
  <v-list-item @click="handleClick">
    <v-list-item-icon>
      <v-icon>mdi-map-marker-outline</v-icon>
    </v-list-item-icon>

    <v-list-item-content> Current module </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  data: () => ({
    counter: null,
    timer: null,
  }),
  methods: {
    handleDoubleClick(id) {
      window.open(`https://ra.worksection.com/project/${id}/new`, "_blank");
    },
    handleSingleClick(id, postfix) {
      window.open(
        `https://ra.worksection.com/project/${id}/priority/${postfix}`,
        "_blank"
      );
    },
    throwError() {
      this.$message.notification({
        title: "Error",
        text: "This module not connected to any project.",
        type: "error",
        duration: 6000,
      });
    },
    handleClick() {
      const wsData = this.$route.meta.workSectionData;
      let id = null;
      let postfix = "";

      if (!wsData) {
        this.throwError();
      }

      // eslint-disable-next-line no-prototype-builtins
      const hasIdProp = wsData.hasOwnProperty("id");

      if (typeof wsData === "object" && hasIdProp) {
        id = wsData.id;
      } else {
        id = wsData;
      }

      if (typeof wsData === "object") {
        postfix = wsData.postfix;
      }

      this.$copyText(window.location.href);

      if (!id) {
        return this.throwError();
      }

      this.counter++;

      // single click
      if (this.counter === 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
          this.handleSingleClick(id, postfix);
        }, 300);

        return;
      }

      // double click
      clearTimeout(this.timer);
      this.counter = 0;
      this.handleDoubleClick(id);
    },
  },
};
</script>
