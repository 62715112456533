import service from "../../plugins/service";

class SemanticToolService {
  /**
   * @param keys {string}
   * @param project {{id: number}}
   * @returns {Promise<void>}
   */
  static async uploadBaseKeys(keywords, project) {
    if (!project.id === undefined)
      throw new Error("Cant upload keys without project id.");

    const url = `/semantic-tool/task/upload-keys/${project.id}`;
    const response = await service.post(url, { keywords }, true, {});

    if (response) return response.data;

    return null;
  }
}

export default SemanticToolService;
