import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'gp-tracker/fetchTableData'(ctx, payload){
            let url = `/dashboards/gpm/tracker?is_mock=${payload.isMock}`
            const response  = await Service.post(url, payload.body)
            if(response) {
                return response.data
            }
            return null
        },
    },
    getters: {}
}