import Vue from "vue";

import App from "@/App";
import VueRouter from "vue-router";
import vuetify from "@/plugins/vuetify";
import router from "@/plugins/router";
import VueMoment from "vue-moment";
import meta from "@/plugins/meta";
import store from "@/store/index";
import * as Sentry from "@sentry/vue";
import VueClipboard from "vue-clipboard2";
import "@/theme/index.scss";
import filters from "@/plugins/filters";
import Notification from "@/plugins/notifications/index";
import VueGtag from "vue-gtag";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import dotenv from "dotenv";

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: "G-QQPJXWVK3H" },
  },
  router
);

Sentry.init({
  Vue,
  dsn: "https://372bf210ef46812748decab297106215@o4505754180911104.ingest.sentry.io/4505754183467008",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://api.serpinsider.com/api/s",
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

filters.forEach((filter) => Vue.filter(filter[0], filter[1]));

Vue.prototype.$message = {
  notification: Notification,
};

Vue.use(VueMoment);
Vue.use(VueRouter);
Vue.use(VueClipboard);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

dotenv.config({ path: "../.env" });

new Vue({
  vuetify,
  meta,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
