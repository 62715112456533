var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"centered-content ldd__default-table",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.defaultFooterProps,"items-per-page":-1,"hide-default-footer":"","custom-sort":_vm.customSort},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:`header.${h.value}`,fn:function({ header }){return [(header.title)?[_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"150px"}},[_vm._v(_vm._s(header.title))])])]:[_c('span',{key:h.value},[_vm._v(_vm._s(header.text))])]]}}}),{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(row,rowIdx){return _c('tr',{key:rowIdx},_vm._l((Object.keys(row)),function(key,i){return _c('td',{key:key + i,style:(`background-color: ${rowIdx !== 0 ? _vm.getRgb(
            row[key].value,
            _vm.getMax(items, key),
            50,
            233,
            153,
            key
          ) : ''}`)},[(i === 0)?[_c('span',{style:(`${row[key].value && row[key].value.length < 30 ? 'white-space:nowrap;' : ''}`)},[_vm._v(" "+_vm._s(row[key].value)+" ")])]:[(row[key] && row[key].value)?_c('div',{staticClass:"colored-td colored-td--clickable",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToSpiderLink(row[key], _vm.activeUrl)}}},[_vm._v(" "+_vm._s(row[key].value)+" ")]):_vm._e()]],2)}),0)}),0)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }