<template>
  <div>
    <v-card rounded="lg" elevation="0" class="border">
      <v-card-title style="font-size: 14px" class="d-flex">
        Set CSS selectors
        <v-spacer />
        <v-chip
          label
          class="flat-chip flat-chip--primary"
          @click="handleAddNewCol"
        >
          Add selector <v-icon right small>mdi-plus</v-icon>
        </v-chip>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <template v-if="data && data.selectors && data.selectors.length > 0">
            <v-col cols="12" v-for="(col, idx) in data.selectors" :key="idx">
              <v-row dense class="align-center">
                <v-col cols="3">
                  <v-autocomplete
                    v-model="col.page_type_id"
                    :items="data.page_type"
                    dense
                    hide-details
                    filled
                    :menu-props="menuProps"
                    label="Page type"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="col.selector"
                    filled
                    dense
                    hide-details
                    label="Selector"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn icon @click="handleDeleteSelector(idx)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="data && data.selectors && data.selectors.length > 6"
              class="d-flex justify-center align-center"
            >
              <v-chip
                label
                class="flat-chip flat-chip--primary"
                @click="handleAddNewCol"
              >
                Add selector <v-icon right small>mdi-plus</v-icon>
              </v-chip>
            </v-col>
          </template>
          <template v-else>
            <v-col
              cols="12"
              class="d-flex justify-center"
              style="text-align: center"
            >
              <v-row dense>
                <v-col cols="12">
                  <div style="max-width: 220px; margin: auto">
                    <css-selector-icon :fill="`rgba(141,141,141,0.15)`" />
                  </div>
                </v-col>
                <v-col cols="12">
                  <span style="opacity: 0.5">
                    You do not have any selected selectors...
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { DEFAULT_MENU_PROPS, PAGE_TYPES } from "../../utils/defaultData";
import CssSelectorIcon from "../UI/Icons/CssSelectorIcon";
import Notifications from "../../mixins/Notification";

export default {
  components: { CssSelectorIcon },
  mixins: [Notifications],
  props: {
    modelValue: {
      type: Object,
      default() {
        return { selectors: [] };
      },
    },
  },
  data: () => ({
    pageTypes: PAGE_TYPES,
    menuProps: DEFAULT_MENU_PROPS,
  }),
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleDeleteSelector(idx) {
      try {
        const clone = JSON.parse(JSON.stringify(this.data));

        clone.selectors = clone.selectors.filter((v, i) => i !== idx);

        this.data = clone;
      } catch {
        this.mError({ title: "Something wrong", text: "Try again later." });
      }
    },
    handleAddNewCol() {
      try {
        const clone = JSON.parse(JSON.stringify(this.data));

        clone.selectors.push({
          page_type_id: null,
          selector: null,
        });

        this.data = clone;
      } catch {
        this.mError({ title: "Something wrong", text: "Try again later." });
      }
    },
  },
};
</script>
