<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col v-if="urls_with_bad_performance.length > 0" cols="12">
        <v-alert border="right" colored-border type="error" elevation="2">
          You have {{ urls_with_bad_performance.length }} urls with performance
          less than 50%.
        </v-alert>
      </v-col>
      <v-col v-if="upload.alert.shown" cols="12">
        <v-alert
          v-for="message in upload.alert.messages.list"
          :key="message"
          outlined
          :type="upload.alert.type"
          prominent
          border="left"
          dismissible
        >
          {{ upload.alert.messages.name }}: {{ message }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <page-header title="LTT Pro" :breadcrumbs="breadcrumbs" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="d-flex" style="gap: 8px">
        <v-chip outlined label @click="upload.modal = true">
          <v-icon small left>mdi-upload</v-icon>
          Add new links
          <v-icon small right>mdi-link-variant</v-icon>
        </v-chip>
        <filters-chip
          :filters="filter"
          clearable
          @click:clear="handleClearFilters"
          @click="filtersDialogModel = true"
        />
        <v-chip
          label
          class="px-0 d-flex justify-center"
          style="width: 32px"
          color="primary"
          title="refresh data"
          @click="fetch_data"
        >
          <v-icon v-if="!loadings.fetch_data" small>mdi-refresh</v-icon>
          <v-progress-circular v-else size="18" width="2" indeterminate />
        </v-chip>
      </v-col>
      <v-col v-if="!$store.getters['project/active_project']" cols="12">
        <v-alert icon="mdi-shield-lock-outline" prominent text type="info" dark>
          <div class="fz-14">
            <b>Selected project is required</b>
          </div>
          <div>To view the page, you must select a project.</div>
          <v-chip
            color="primary"
            label
            class="px-6 mt-4"
            @click="$store.dispatch('toggle_select_project_menu')"
          >
            Select project
          </v-chip>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <template v-if="!loadings.fetch_data && items?.length > 0">
          <Links
            v-for="item in items"
            v-bind:key="item.id"
            :items="item.data"
            :search_active="true"
            :settings="item.settings"
            :name="item.name"
            :filters="filter"
            :additional-data="data"
            :id_project="project_id"
            :min_parameters="min_parameters"
          ></Links>
        </template>
        <template
          v-else-if="!loadings.fetch_data && dirty && items?.length === 0"
        >
          <div class="d-flex justify-center my-6">
            <nothing-found-by-filters />
          </div>
        </template>
        <template v-else-if="loadings.fetch_data">
          <v-card class="styled-card--light">
            <v-skeleton-loader type="table" />
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-dialog
      v-model="filtersDialogModel"
      content-class="my-shadow--e3"
      width="660px"
      scrollable
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Filters
          <v-spacer />
          <v-btn icon @click="filtersDialogModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.subproject"
                    :items="data.subprojects"
                    item-text="name"
                    item-value="id"
                    label="Subproject"
                    hide-details
                    filled
                    clearable
                    @change="_$handleRangeChange($event, 'subproject')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.type"
                    :items="data.types_items"
                    item-text="name"
                    item-value="id"
                    label="Type"
                    filled
                    hide-details
                    multiple
                    clearable
                    @change="_$handleRangeChange($event, 'type')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.google_index"
                    :items="data.google_index"
                    item-text="name"
                    item-value="id"
                    hide-details
                    label="Google Index"
                    filled
                    clearable
                    @change="_$handleRangeChange($event, 'google_index')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.links_checking"
                    label="Links Checking"
                    filled
                    clearable
                    dense
                    hide-details
                    item-text="name"
                    item-value="value"
                    :items="linksCheckingItems"
                    @change="_$handleRangeChange($event, 'links_checking')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.color"
                    :items="data.colors"
                    item-text="name"
                    item-value="id"
                    hide-details
                    label="Link status"
                    chips
                    filled
                    multiple
                    deletable-chips
                    small-chips
                    @change="_$handleRangeChange($event, 'color')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.code"
                    :items="data.codes"
                    item-text="name"
                    hide-details
                    item-value="id"
                    label="Code error"
                    filled
                    clearable
                    @change="_$handleRangeChange($event, 'code')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.users"
                    :items="data.users"
                    item-text="name"
                    hide-details
                    item-value="id"
                    label="User"
                    filled
                    append-icon="mdi-account"
                    clearable
                    @change="_$handleRangeChange($event, 'users')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="filter.acceptor_url"
                    hide-details
                    filled
                    label="Acceptor url"
                    clearable
                    :items="filtersData.acceptor_urls"
                    :loading="loadings.filters"
                    item-text="url"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <smart-date-filter
                    :model-value="filter.date_range"
                    @update:modelValue="handleDateChange"
                    filled
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="filter.anchor_plan_task_id"
                    filled
                    label="Anchor Plan Task Id"
                    hide-details
                    @change="_$handleRangeChange($event, 'anchor_plan_task_id')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="filter.domains_search"
                    filled
                    label="Domains search"
                    hint="Separation - new line"
                    auto-grow
                    hide-details="auto"
                    @change="_$handleRangeChange($event, 'domains_search')"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-card class="styled-card--light pa-4">
                    <v-row>
                      <v-col> On Page </v-col>
                      <v-col class="d-flex justify-end">
                        <v-checkbox
                          v-model="filter.onPage"
                          @change="_$handleRangeChange($event, 'onPage')"
                          hide-details
                          class="ma-0"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>Hide disavowed links</v-col>
                      <v-col class="d-flex justify-end">
                        <v-checkbox
                          v-model="filter.disavowedLinks"
                          @change="
                            _$handleRangeChange($event, 'disavowedLinks')
                          "
                          hide-details
                          class="ma-0"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>Check Traffic</v-col>
                      <v-col class="d-flex justify-end">
                        <v-checkbox
                          hide-details
                          class="ma-0"
                          v-model="filter.check_traffic"
                          @change="
                            _$handleFilterChange($event, 'check_traffic')
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>Temporary GP</v-col>
                      <v-col class="d-flex justify-end">
                        <v-checkbox
                          hide-details
                          class="ma-0"
                          v-model="filter.temporary_gp"
                          @change="_$handleFilterChange($event, 'temporary_gp')"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <extended-chip
            class="mr-2 px-6"
            color="transparenr"
            label
            large
            @click="export_data"
            icon
          >
            Export data
            <v-icon small right> mdi-cloud-download-outline </v-icon>
          </extended-chip>
          <extended-chip
            color="primary"
            large
            label
            class="px-6"
            @click="fetch_data"
            :loading="loadings.fetch_data"
            :disabled="loadings.fetch_data"
          >
            Get data
          </extended-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :retain-focus="false" v-model="upload.modal" max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="headline">LTT PRO: add new links</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-card elevation="4">
                <v-card-title> Import from text </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      1. Put the columns to import in the order in which you
                      make the data</v-col
                    >
                    <v-col cols="12">
                      <v-text-field
                        v-model="upload.text.headers"
                        solo
                        dense
                        :placeholder="'acceptor_url;donor_url;type;date_add'"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">2. Insert data (without headers)</v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="upload.text.rows"
                        solo
                        :placeholder="'acceptor_url <tab> donor_url <tab> type <tab> date_add \n acceptor_url <tab> donor_url <tab> type <tab> date_add '"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div class="text-center">
                    <v-btn
                      class="text-center align-center justify-center center"
                      color="success"
                      dark
                      rounded
                      @click="upload_data(1)"
                      >Upload
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card elevation="4">
                <v-card-title>
                  Import from file ->
                  <a
                    href="/file/Ltt2LevelExample.csv"
                    target="_blank"
                    style="font-size: 15px"
                  >
                    Download Example</a
                  >
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="upload.file.names"
                        color="deep-purple accent-4"
                        counter
                        label="File input"
                        multiple
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        accept=".csv"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ upload.file.names.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div class="text-center">
                    <v-btn
                      class="text-center align-center justify-center center"
                      color="success"
                      dark
                      rounded
                      @click="upload_data(2)"
                      >Upload
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>

              <v-row>
                <v-col cols="12">
                  <v-card elevation="4">
                    <v-card-title> Allowed columns </v-card-title>
                    <v-card-text>
                      <p><b>R</b> - required</p>
                      <p><b>acceptor_url [R]</b> - https://site.com/page</p>
                      <p><b>donor_url [R]</b> - https://site.com/page</p>
                      <p><b>type [R]</b> - forum</p>
                      <p><b>date_add</b> - 2014-04-05</p>
                      <p><b>fl_name</b> - Vadim L</p>
                      <p>
                        <b>price</b> -
                        {"content_price":"12","currency_cp":"usd",
                        "price":"323","currency_p":"eur",
                        "lb_work_price":"13.6","currency_lwp":"usd"}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import eventPipe from "../../events/eventPipe";
import Links from "@/components/Ltt/Child/Links";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import { PROJECT_ID_IDTF } from "@/utils/defaultData";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../mixins/Location";
import { deepClone } from "@/utils/functions";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import FiltersChip from "@/components/UI/FiltersChip.vue";
import NothingFoundByFilters from "@/components/UI/Placeholders/NothingFoundByFilters.vue";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "Index",
  components: {
    NothingFoundByFilters,
    FiltersChip,
    PageHeader,
    ExtendedChip,
    SmartDateFilter,
    Links,
  },
  mixins: [FiltersHandling, Project, Location, Notification],
  created() {
    this._$collectParams(["type", "date_range", "color"], "filter");

    if (this.$route.query[PROJECT_ID_IDTF])
      this._$setActiveProject(this.$route.query[PROJECT_ID_IDTF]);

    this.fetchDashboardData("mounted");

    this.update_filter();
  },
  mounted() {
    eventPipe.$on("update-active-project", () => {
      if (
        this.isAlreadyFetching ||
        !this.$store.getters["project/active_project"]
      )
        return;
      this.fetchDashboardData("event");
    });
  },
  data() {
    return {
      filtersDialogModel: false,
      breadcrumbs: [
        { text: "Home", disabled: false, to: "/" },
        {
          text: "LTT Pro",
          disabled: true,
        },
      ],
      loading: false,
      filtersData: {
        acceptor_urls: [],
      },
      project_id: this.$store.state.project.active_project,
      loadings: {
        fetch_data: false,
        types_list: false,
        users_list: false,
        filters: false,
      },
      upload: {
        modal: false,
        text: {
          headers: "acceptor_url;donor_url;type;date_add",
          rows: "",
        },
        file: {
          names: [],
        },
        alert: {
          messages: {
            list: [],
            name: "",
            type: 0,
          },
          shown: false,
          type: "info",
        },
      },
      data: {
        types_items: [],
        users: [],
        google_index: [
          {
            id: 1,
            name: "in Index",
          },
          {
            id: 0,
            name: "not In Index",
          },
        ],
        colors: [
          {
            id: 1,
            name: "если всё ок: страница есть в индексе, есть на странице и не закрытаот индексации",
            color: "rgba(100, 255, 11, 0.20)",
          },
          {
            id: 2,
            name: "если страницы закрыта от индексации",
            color: "rgba(255, 21, 0, 0.20)",
          },
          {
            id: 3,
            name: "нет ссылки на странице",
            color: "rgba(0, 149, 255, 0.20)",
          },
          {
            id: 4,
            name: "если всё есть, кроме индексации",
            color: "rgba(255, 189, 0, 0.20)",
          },
          {
            id: 5,
            name: "disavowed links",
            color: "#E399B1",
          },
        ],
        codes: [
          {
            id: 3,
            name: "3xx",
          },
          {
            id: 4,
            name: "4xx",
          },
          {
            id: 5,
            name: "5xx",
          },
        ],
        subprojects: [],
      },
      date_modal: false,
      linksCheckingItems: [
        {
          name: "All",
          value: null,
        },
        {
          name: "Checked by SEO",
          value: 1,
        },
        {
          name: "Non-Checked By SEO",
          value: 2,
        },
        {
          name: "Waiting for Indexation",
          value: 3,
        },
      ],
      isAlreadyFetching: false,
      dirty: false,
      filter: {
        check_traffic: false,
        temporary_gp: false,
        type: undefined,
        links_checking: null,
        google_index: undefined,
        color: undefined,
        domains_search: undefined,
        subproject: undefined,
        code: undefined,
        users: undefined,
        onPage: undefined,
        anchor_plan_task_id: undefined,
        acceptor_url: undefined,
        disavowedLinks: true,
        date_range: [
          dateFormat(new Date().setMonth(date.getMonth() - 2), "yyyy-mm-dd"),
          dateFormat(date, "yyyy-mm-dd"),
        ],
      },
      items: [],
      urls_with_bad_performance: [],
      // problems_types: [],
      // problems_priorities: [],
      min_parameters: {
        crowd: [],
        guest_post: [],
      },
    };
  },
  methods: {
    handleClearFilters() {
      this.filter = {
        check_traffic: false,
        temporary_gp: false,
        type: undefined,
        links_checking: null,
        google_index: undefined,
        color: undefined,
        domains_search: undefined,
        subproject: undefined,
        code: undefined,
        users: undefined,
        onPage: undefined,
        anchor_plan_task_id: undefined,
        acceptor_url: undefined,
        disavowedLinks: false,
        date_range: [null, null],
      };
    },
    fetchDashboardData(source) {
      this.fetchFilters();
      this.fetch_min_parameters(source);
      this.fetch_types_list();
      this.fetch_users_list();
      this.fetch_data();
      this.fetch_subprojects();
    },
    async fetchFilters() {
      try {
        const payload = {
          type: "/ltt/base",
          take: ["acceptor_urls"],
          filter: {
            projectID: this.$store.getters.active_project || this.project_id,
          },
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        this.loadings.filters = false;

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } catch (e) {
        console.error("Error while loading filters for Ltt pro dashboard.", e);
        throw e;
      } finally {
        this.loadings.filters = false;
      }
    },
    handleDateChange(event) {
      this.filter.date_range = event;
      this._$handleFilterChange(event, "date_range");
    },
    async export_data() {
      try {
        const url = "/export/base/get-hash";
        const payload = {
          ...this.filter,
        };

        const response = await service.post(url, payload);

        if (response.data.success) {
          this.dialog_export = false;
          location.href = `${this.$store.state.server_url}/export/ltt/index/${this.id_project}?hash=${response.data.hash}`;
        }
      } catch (e) {
        console.error("Error while export data.", e);
      }
    },
    async fetch_min_parameters(source = "none") {
      console.log("source:", source);
      try {
        if (!this.id_project)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        const url = `/ltt/project/min-parameters/${this.id_project}`;

        this.update_loading("fetch_min_parameters", true);

        const response = await service.post(url, {});

        this.min_parameters.crowd = response.data.crowd;
        this.min_parameters.guest_post = response.data.guest_post;
      } catch (e) {
        console.error("Error while loading min parameters.", e);
      } finally {
        this.update_loading("fetch_min_parameters", false);
      }
    },
    async fetch_data() {
      this.isAlreadyFetching = true;
      this.dirty = true;

      try {
        if (!this.id_project)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        const url = `/ltt/base/${this.id_project}`;
        const payload = {
          filter: this.filter,
        };
        const tmprSettings = deepClone({
          check_traffic: this.filter.check_traffic,
          temporary_gp: this.filter.temporary_gp,
        });

        this.update_loading("fetch_data", true);

        const response = await service.post(url, payload);

        const newItems = response.data;

        Object.keys(newItems).forEach((key) => {
          newItems[key].settings = { ...tmprSettings };
        });

        this.items = Object.values(newItems);
      } catch (e) {
        console.error("Error while loading main data.", e);
      } finally {
        this.update_loading("fetch_data", false);
        this.isAlreadyFetching = false;
      }
    },
    async upload_data(type) {
      try {
        if (!this.id_project)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        this.update_loading("upload_data", true);

        this.upload.alert.messages = [];
        this.upload.alert.type = "";
        this.upload.alert.shown = false;

        const url = `/ltt/upload/main/links/${this.id_project}`;
        let payload = {};
        if (type === 1) {
          payload = {
            type: type,
            headers: this.upload.text.headers,
            data: this.upload.text.rows,
          };
        } else {
          payload = new FormData();
          payload.append("file", self.upload.file.names[0]);
          payload.append("type", type);
        }
        const config = {
          headers: {
            "Content-Type":
              type === 1 ? "application/json" : "multipart/form-data",
            Authorization: this.$store.state.auth.token,
          },
        };

        const response = await service.post(url, payload, true, config);

        this.upload.alert.messages = response.data.messages;
        this.upload.alert.type = response.data.type;
        this.upload.alert.shown = true;
        this.update_loading("upload_data", false);
        this.upload.modal = false;
        this.upload.file.names = [];
      } catch (e) {
        this.upload.alert.messages = {
          list: [e],
          name: "Axios",
          type: 0,
        };
        this.upload.alert.type = "error";
        this.upload.alert.shown = true;
        this.upload.modal = false;
        this.upload.file.names = [];

        this.update_loading("upload_data", false);
        console.error("Error while uploading data.", e);
      }
    },
    async fetch_types_list() {
      try {
        if (!this.id_project)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        this.update_loading("types_list", true);

        const url = "/ltt/links-type";

        const response = await service.get(url);

        this.data.types_items = response.data;
      } catch (e) {
        console.error("Error while loading types list.", e);
      } finally {
        this.update_loading("types_list", false);
      }
    },
    async fetch_subprojects() {
      try {
        if (!this.id_project)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        const url = `/get-subprojects/${this.id_project}`;

        this.update_loading("fetch_subprojects", true);

        const response = await service.get(url);

        this.data.subprojects = response.data;
      } catch (e) {
        console.error("Error while loading subprojects.", e);
      } finally {
        this.update_loading("fetch_subprojects", false);
      }
    },
    async fetch_users_list() {
      try {
        this.update_loading("users_list", true);
        const url = `/ltt/users/${this.id_project}`;

        const response = await service.get(url);

        this.data.users = response.data;
      } catch (e) {
        console.error("Error while fetching users list.", e);
      } finally {
        this.update_loading("users_list", false);
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
    update_filter() {
      let data = this.$route.query;
      if (data.project) {
        this.$store.state.project.active_project = data.project;

        if (data.date_range)
          this.filter.date_range = data.date_range.split(",");
        if (data.type) this.filter.type = Number(data.type);
        if (data.subproject) this.filter.subproject = Number(data.subproject);

        this.$router.replace({ query: null });
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
};
</script>
