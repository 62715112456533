<template>
  <v-dialog
    v-model="dialog"
    max-width="750"
    content-class="remove-dialog-shadow"
  >
    <v-card rounded="lg">
      <v-card-title><h3>All notes</h3></v-card-title>
      <v-card-text class="px-0">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>Your notes</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-if="data && data.all">
                <v-card
                  v-for="key in Object.keys(data.all)"
                  :key="key"
                  class="mb-5 shadow-e1-bordered"
                  rounded="lg"
                >
                  <v-card-title class="d-flex justify-space-between">
                    <span>{{ data.all[key].user_name }}</span>
                  </v-card-title>
                  <v-card-subtitle>
                    <div>{{ data.all[key].url }}</div>

                    <span>
                      updated —
                      {{
                        $moment(data.all[key].updated_at).format("YYYY MM DD")
                      }}
                    </span>
                    <span class="mx-2">|</span>
                    <span>
                      created —
                      {{
                        $moment(data.all[key].created_at).format("YYYY MM DD")
                      }}
                    </span>
                  </v-card-subtitle>
                  <v-card-text v-html="data.all[key].notes"></v-card-text>
                  <v-card-actions />
                </v-card>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><h4>Other user notes</h4></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <template v-if="data && data.guys">
                <v-card
                  v-for="key in Object.keys(data.guys)"
                  :key="key"
                  class="mb-5 shadow-e1-bordered"
                  rounded="lg"
                >
                  <v-card-title class="d-flex justify-space-between">
                    <span>{{ data.guys[key].user_name }}</span>
                  </v-card-title>
                  <v-card-subtitle>
                    <div>{{ data.guys[key].url }}</div>
                    <span>
                      updated —
                      {{
                        $moment(data.guys[key].updated_at).format("YYYY MM DD")
                      }}
                    </span>
                    <span class="mx-2">|</span>
                    <span>
                      created —
                      {{
                        $moment(data.guys[key].created_at).format("YYYY MM DD")
                      }}
                    </span>
                  </v-card-subtitle>
                  <v-card-text v-html="data.guys[key].notes"></v-card-text>
                  <v-card-actions />
                </v-card>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";

export default {
  mixins: [Dialog],
  data: () => ({
    data: {},
  }),
  created() {
    this.fetchContent();
  },
  watch: {
    dialog() {
      this.fetchContent();
    },
  },
  methods: {
    async fetchContent() {
      const payload = {
        params: {
          url: 0,
          project: parseInt(this.$store.state.project.active_project),
          acceptor: 0,
        },
      };
      const resp = await this.$store.dispatch(
        "links-dynamic-dashboard/getNotes",
        payload
      );
      if (resp) {
        this.data = resp;
      }
    },
  },
};
</script>
