<template>
  <div class="ldd" style="position: relative">
    <v-row>
      <v-col cols="11">
        <h2>Competitor Url's Dynamic</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-4">
      <v-col cols="12">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title class="d-flex">
            Filters
            <v-tooltip v-if="loadings.filters" bottom>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    class="ml-4"
                    small
                    :disabled="loadings.filters"
                    :loading="loadings.filters"
                    icon
                  />
                </div>
              </template>
              <div>Please wait. Filters are being loaded now</div>
            </v-tooltip>
            <v-spacer />
            <small style="opacity: 0.5; font-size: 12px">v 1.8</small>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="1">
                <v-autocomplete
                  v-model="filters.gi"
                  label="GI"
                  disabled
                  :items="filtersData.gi"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  @change="_$handleFilterChange($event, 'gi')"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-autocomplete
                  v-model="filters.do_follow"
                  label="Do Follow"
                  :items="filtersData.dofollow"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  :disabled="loadings.filters"
                  @change="_$handleFilterChange($event, 'do_follow')"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="filters.link_type"
                  label="Links Type"
                  :items="filtersData.link_type"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  :disabled="loadings.filters"
                  @change="_$handleFilterChange($event, 'link_type')"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="filters.anchor_type"
                  label="Anchor Type"
                  :items="filtersData.anchor_type"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  :disabled="loadings.filters"
                  @change="_$handleFilterChange($event, 'anchor_type')"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <range-filter
                  title="AH DR"
                  :model-value="filters.ah_dr"
                  :max="100"
                  :presets="presets.ahDrPresets"
                  @update:modelValue="
                    _$handleRangeChange($event, 'ah_dr', 'filters')
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH UR"
                  :model-value="filters.ah_ur"
                  :max="100"
                  :presets="presets.ahDrPresets"
                  @update:modelValue="
                    _$handleRangeChange($event, 'ah_ur', 'filters')
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH Traffic"
                  :model-value="filters.ah_traffic"
                  :max="1000000"
                  @update:modelValue="_$handleRangeChange($event, 'ah_traffic')"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                <v-autocomplete
                  v-model="filters.title_thematic"
                  label="Title Thematic"
                  :items="['yes', 'no']"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  clearable
                  @change="_$handleFilterChange($event, 'title_thematic')"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-autocomplete
                  v-model="filters.gt"
                  label="GT"
                  :items="[2, 1, 0]"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  clearable
                  @change="_$handleFilterChange($event, 'gt')"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <range-filter
                  title="AH URL Traffic"
                  :model-value="filters.ah_url_traffic"
                  :max="10000"
                  :presets="presets.ahUrlTrafficPresets"
                  @update:modelValue="
                    _$handleRangeChange($event, 'ah_url_traffic', 'filters')
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH 2nd Level"
                  :model-value="filters.ah_2nd_level"
                  :max="1000"
                  :presets="presets.ah2ndLevelPresets"
                  @update:modelValue="
                    _$handleRangeChange($event, 'ah_2nd_level', 'filters')
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="SW Rank"
                  :model-value="filters.sw_rank"
                  :max="3000000"
                  :presets="presets.swRankPresets"
                  @update:modelValue="
                    _$handleRangeChange($event, 'sw_rank', 'filters')
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="Num of Pages"
                  :model-value="filters.nums_of_pages"
                  :max="10000"
                  :presets="presets.numOfPagesPresets"
                  @update:modelValue="
                    _$handleRangeChange($event, 'nums_of_pages', 'filters')
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH Tr RD"
                  :model-value="filters.ah_tr_rd"
                  :max="10000"
                  :presets="presets.numOfPagesPresets"
                  @update:modelValue="
                    _$handleRangeChange($event, 'ah_tr_rd', 'filters')
                  "
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" class="d-flex">
                <v-autocomplete
                  v-model="filters.url"
                  label="Acceptor Url"
                  :items="filtersData.acceptor_url"
                  hide-details
                  dense
                  clearable
                  :loading="loadings.filters"
                  :disabled="loadings.filters"
                  @change="_$handleFilterChange($event, 'url')"
                  outlined
                ></v-autocomplete>
                <v-autocomplete
                  v-model="filters.domain"
                  label="Acceptor Domain"
                  :items="filtersData.acceptor_domain"
                  hide-details
                  dense
                  clearable
                  :loading="loadings.filters"
                  :disabled="loadings.filters"
                  @change="_$handleFilterChange($event, 'domain')"
                  class="pl-2"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="filters.link_type_hidden"
                  multiple
                  :items="filtersData.link_type"
                  dense
                  hide-details
                  small-chips
                  deletable-chips
                  :loading="loadings.filters"
                  :disabled="loadings.filters"
                  outlined
                  label="Hidden Link Types"
                  placeholder="Type to search"
                  @change="_$handleFilterChange($event, 'link_type_hidden')"
                >
                  <template #prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            filters.link_type_hidden.length > 0
                              ? 'primary darken-4'
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template #selection="{ item, index }">
                    <span v-if="index === 0">{{ item.text }}</span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ filters.link_type_hidden.length - 1 }} more)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <div class="fix-button-height">
                  <smart-date-filter
                    :model-value="filters.date"
                    @update:modelValue="handleUpdateDateFilter"
                    :custom-presets="presets.customDatePresets"
                  />
                </div>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="filters.links_data"
                  label="Links Data"
                  hide-details
                  dense
                  outlined
                  :menu-props="menuProps"
                  :items="[
                    'best link by ur',
                    'first link by date',
                    'all links',
                  ]"
                  @change="_$handleFilterChange($event, 'links_data')"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col> </v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col
                ><v-btn
                  block
                  color="primary"
                  @click="handleGetData"
                  :loading="loadings.table"
                >
                  Get data
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div style="opacity: 0.5" class="mt-2">
          <small>Select filters and press "get data".</small>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ldd__cards">
        <div v-if="cardsLength > 0 && !loadings.table" class="cards__wrapper">
          <div
            v-for="(card, index) in cards"
            :key="card.sectionName + index"
            class="ldd__card"
          >
            <v-card class="shadow-e1-bordered" rounded="lg">
              <v-card-title class="d-flex">
                <div class="d-flex align-center">
                  <div class="mr-4">All Links</div>
                  <v-text-field
                    v-model="tableSearchModel"
                    dense
                    hide-details
                    label="Search"
                    append-icon="mdi-magnify"
                    style="max-width: 250px"
                  ></v-text-field>
                </div>
                <v-spacer />
                <unload-data-button
                  :urls-amount="unloadUrlsAmount"
                  :loading="loadings.unload"
                  :success="successUnload"
                  :data="cards[0].table"
                  main-button-text="get links data and traffic from ahrefs"
                  upload-button-text="unload"
                  @update:urlsAmount="unloadUrlsAmount = $event"
                  @unload="handleUnloadData"
                />
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="card.table.headers"
                      :items="card.table.items"
                      :items-per-page="20"
                      :footer-props="{ itemsPerPageOptions: [20, 50, 100, -1] }"
                      :custom-sort="customSort"
                      :search="tableSearchModel"
                      multi-sort
                      class="card__table"
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr
                            v-for="item in items"
                            :key="item.url.value"
                            :class="{
                              current: item.url.value.includes(
                                getSelectedURLFilter.text
                              ),
                            }"
                          >
                            <td class="d-flex align-center">
                              <td-domain
                                :data="item.url"
                                :row="item"
                                :note="true"
                                @note="showIndividualNote"
                              />
                            </td>
                            <td
                              :style="`background-color: ${getRgb(
                                item.vision,
                                getMax(items, 'vision'),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item.vision }}
                            </td>
                            <td
                              :style="`background-color: ${getRgb(
                                item.ah_dr,
                                getMax(items, 'ah_dr'),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item.ah_dr }}
                            </td>
                            <td
                              :style="`background-color: ${getRgb(
                                item.ah_rd,
                                getMax(items, 'ah_rd'),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item.ah_rd }}
                            </td>
                            <td
                              :style="`background-color: ${getRgb(
                                item.ah_tr,
                                getMax(items, 'ah_re'),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item.ah_tr }}
                            </td>
                            <td>{{ item.wa }}</td>
                            <td
                              :style="`background-color: ${getRgb(
                                item.gi,
                                getMax(items, 'gi'),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item.gi }}
                            </td>
                            <td
                              :style="`background-color: ${getRgb(
                                item.total_all,
                                getMax(items, 'total_all'),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item.total_all }}
                            </td>
                            <td
                              :style="`background-color: ${getRgb(
                                item.total_period,
                                getMax(items, 'total_period'),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item.total_period }}
                            </td>
                            <td
                              v-for="(dateKey, i) in getDates(item)"
                              :key="dateKey + i"
                              :style="`background-color: ${getRgb(
                                item[dateKey],
                                getMax(items, dateKey),
                                50,
                                233,
                                153
                              )}`"
                            >
                              {{ item[dateKey] }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="shadow-e1-bordered mt-4" rounded="lg">
              <v-card-title class="d-flex">
                <div class="d-flex align-center">Dinamic by URL</div>
                <v-spacer />
                <unload-data-button
                  :urls-amount="unloadUrlsAmount"
                  :loading="loadings.unload"
                  :success="successUnload"
                  :data="cards[0].table"
                  main-button-text="get links data and traffic from ahrefs"
                  upload-button-text="unload"
                  @update:urlsAmount="unloadUrlsAmount = $event"
                  @unload="handleUnloadData"
                />
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div v-if="cards[0].charts">
                      <apexchart
                        type="line"
                        height="27"
                        :options="trafficChartOptions"
                        :series="trafficChartSeries"
                      ></apexchart>
                      <apexchart
                        type="line"
                        height="425"
                        :options="linkTypesChartOptions"
                        :series="linkTypesChartSeries"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-title> Dynamic by DOMAIN </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div v-if="cards[0].charts">
                      <apexchart
                        type="line"
                        height="275"
                        :options="trafficDomainChartOptions"
                        :series="trafficDomainChartSeries"
                      ></apexchart>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loadings.table">
      <v-col cols="12">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-subtitle class="d-flex justify-center">
            Loading tables
          </v-card-subtitle>
          <v-card-text>
            <v-progress-linear indeterminate :active="true"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="cardsLength > 0 && !loadings.table">
      <v-col cols="12" class="section-name">
        <h4># Referral Link Parameters</h4>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_LINK_TYPES.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Urls * Link Types </v-card-title>
          <v-card-text>
            <!--Дефолтная таблица-->
            <competitors-default-table
              :headers="cards[0].urls_link_types.headers"
              :items="cards[0].urls_link_types.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.ULRS_ANCHOR_TYPES.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Urls * Anchor Types </v-card-title>
          <v-card-text>
            <!--Дефолтная таблица-->
            <competitors-default-table
              :headers="cards[0].urls_anchor_types.headers"
              :items="cards[0].urls_anchor_types.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_SUM_OF_T_PAGE.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> URLs Summary </v-card-title>
          <v-card-text>
            <competitors-simple-table
              :items-per-page="20"
              :data="cards[0].urls_summary_of_the_page"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_UR.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Urls * UR </v-card-title>
          <v-card-text>
            <!--Дефолтная таблица-->
            <competitors-default-table
              :headers="cards[0].urls_ur.headers"
              :items="cards[0].urls_ur.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
              filter-name="ah_ur"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_AH_2ND_LEVEL.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> URLs * AH 2nd Level </v-card-title>
          <v-card-text>
            <!--Дефолтная таблица-->
            <competitors-default-table
              :headers="cards[0].urls_ah_2nd_level.headers"
              :items="cards[0].urls_ah_2nd_level.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
              filter-name="ah_url_traffic"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_AH_URL_TRAFFIC.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> URLs * AH URL Traffic </v-card-title>
          <v-card-text>
            <!--Дефолтная таблица-->
            <competitors-default-table
              :headers="cards[0].urls_ah_traffic.headers"
              :items="cards[0].urls_ah_traffic.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
              filter-name="ah_2nd_level"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_ANCHOR_TYPES_LINK_TYPES.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Urls * Anchor Types * Link Types </v-card-title>
          <v-card-text>
            <competitors-simple-table
              :items-per-page="20"
              :data="cards[0].urls_anchor_links_types"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_LINK_TYPES_UR.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Urls * Link Types * UR </v-card-title>
          <v-card-text>
            <competitors-simple-table
              :items-per-page="20"
              :data="cards[0].urls_link_types_ur"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="section-name">
        <h4># Referral Domain Parameters</h4>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_DR.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Urls * DR </v-card-title>
          <v-card-text>
            <!--Дефолтная таблица-->
            <competitors-default-table
              :headers="cards[0].urls_dr.headers"
              :items="cards[0].urls_dr.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
              filter-name="ah_dr"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.URLS_LINK_TYLES_DR.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Urls * Link Types * DR </v-card-title>
          <v-card-text>
            <competitors-simple-table
              :items-per-page="20"
              :data="cards[0].urls_link_types_dr"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.GT_SW_RANK_DIST.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> GT & SW Rank distribution </v-card-title>
          <v-card-text>
            <competitors-simple-table
              :items-per-page="20"
              :data="cards[0].domains_common_trust_ref_domains"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.AH_TRAFF_F_REF_DMNS.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> AH Traffic for Ref Domains </v-card-title>
          <v-card-text>
            <competitors-default-table
              :headers="cards[0].domains_ah_traffic_ref_domains.headers"
              :items="cards[0].domains_ah_traffic_ref_domains.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.TOP_SW_CAT_F_CURR_URLS.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Top SW Category </v-card-title>
          <v-card-text>
            <competitors-vertical-table
              :headers="cards[0].top_sw_category_for_url.headers"
              :items="cards[0].top_sw_category_for_url.items"
              :custom-sort="customSort"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.SW_CAT_F_CURR_NICHE.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> SW Category for current Niche </v-card-title>
          <v-card-text>
            <competitors-vertical-table
              :headers="cards[0].sw_cateogory_for_niche.headers"
              :items="cards[0].sw_cateogory_for_niche.items"
              :custom-sort="customSort"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        v-if="cards[0] && cards[0].tr_theme_for_niche"
        :id="SECTIONS_IDS.TR_THEME_F_NICHE.id"
      >
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> TR Theme for current Niche </v-card-title>
          <v-card-text>
            <competitors-default-table
              :headers="cards[0].tr_theme_for_niche.headers"
              :items="cards[0].tr_theme_for_niche.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        :id="SECTIONS_IDS.DIST_OF_T_PERC_TRAFF_B_T_COUNTRY_SW_PRO.id"
      >
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title>
            Distribution of the percentage traffic by Top Country - SW Pro
          </v-card-title>
          <v-card-text>
            <competitors-simple-table
              :items-per-page="20"
              :data="
                cards[0].domains_distribution_percentage_traffic_top_country
              "
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :id="SECTIONS_IDS.TOP_CNTRY_DISTRIBUTION.id">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> TOP Country Distribution - SW Pro </v-card-title>
          <v-card-text>
            <competitors-simple-table
              :items-per-page="20"
              :data="cards[0].domains_top_countries_distribution"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        v-if="cards[0] && cards[0].main_lang_for_domains"
        :id="SECTIONS_IDS.MAIN_LANG_FOR_DOMAINS.id"
      >
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Language of Main Page for Domains </v-card-title>
          <v-card-text>
            <competitors-default-table
              :headers="cards[0].main_lang_for_domains.headers"
              :items="cards[0].main_lang_for_domains.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        v-if="cards[0] && cards[0].num_of_pages_by_gi_rd"
        :id="SECTIONS_IDS.NUM_OF_PAGES_BY_GI_RD.id"
      >
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> Num of pages by GI for Ref Domains </v-card-title>
          <v-card-text>
            <competitors-default-table
              :headers="cards[0].num_of_pages_by_gi_rd.headers"
              :items="cards[0].num_of_pages_by_gi_rd.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        v-if="cards[0] && cards[0].wa_for_rd"
        :id="SECTIONS_IDS.WA_FOR_RD.id"
      >
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title> WebArchive for Ref Domains </v-card-title>
          <v-card-text>
            <competitors-default-table
              :headers="cards[0].wa_for_rd.headers"
              :items="cards[0].wa_for_rd.items"
              :custom-sort="customSortDeeper"
              :active-url="getSelectedURLFilter"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        v-if="cards[0] && cards[0].by_page_type_url"
        :id="SECTIONS_IDS.BY_PAGE_URL.id"
      >
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title>
            {{ SECTIONS_IDS.BY_PAGE_URL.name }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="cards[0].by_page_type_url.headers"
              :items="cards[0].by_page_type_url.items"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="cardsLength === 0 && !loadings.table && firstEnter">
      <v-col cols="12" class="d-flex justify-center">
        <v-card
          class="shadow-e1-bordered text-center"
          rounded="lg"
          width="220px"
        >
          <v-card-title class="pb-0 pt-0 justify-center">
            <div style="max-width: 140px">
              <ResultNotFound />
            </div>
          </v-card-title>
          <v-card-text class="mt-4">
            Nothing. No results were found using these filters.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-menu left content-class="shadow-e2-bordered" rounded="lg">
      <template #activator="{ on }">
        <v-btn fab fixed right bottom color="primary" v-model="fab" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list nav dense>
        <v-list-item>
          <fast-travel />
        </v-list-item>
        <v-list-item>
          <v-btn text block @click="scrollToTop">
            To top
            <v-icon class="ml-2">mdi-arrow-up</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Location from "@/mixins/Location";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import {
  COMPETITOR_URL_CHART_OPTIONS,
  DEFAULT_MENU_PROPS,
  LINK_DYNAMIC_DASHBOARD_SECTIONS_IDS,
  LINK_TYPES_CHART_OPTIONS,
  LINKS_DYNAMIC_DASHBOARD_PRESETS,
  PROJECT_ID_IDTF,
  SIMPLE_TABLE_MOCK,
} from "@/utils/defaultData";
import RangeFilter from "@/components/PositionsDashboard/Header/RangeFilter";
import VueApexCharts from "vue-apexcharts";
import eventPipe from "@/events/eventPipe";
import Project from "@/mixins/Project";
import FastTravel from "@/components/OnePageAnalyzer/LinksDynamicDashboard/FastTravel";
import GetRgb from "@/mixins/GetRgb";
import Notification from "@/mixins/Notification";
import CompetitorsDefaultTable from "@/components/OnePageAnalyzer/CompetitorUrlsDynamic/UI/CompetitorsDefaultTable";
import CompetitorsSimpleTable from "@/components/OnePageAnalyzer/CompetitorUrlsDynamic/UI/CompetitorsSimpleTable";
import CompetitorsVerticalTable from "@/components/OnePageAnalyzer/CompetitorUrlsDynamic/UI/CompetitorsVerticalTable";
import TdDomain from "@/components/OnePageAnalyzer/LinksDynamicDashboard/td/TdDomain";
import UnloadDataButton from "@/components/OnePageAnalyzer/LinksDynamicDashboard/UnloadDataButton";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import ResultNotFound from "@/components/UI/Icons/ResultNotFound.vue";

export default {
  components: {
    ResultNotFound,
    SmartDateFilter,
    UnloadDataButton,
    CompetitorsVerticalTable,
    CompetitorsSimpleTable,
    CompetitorsDefaultTable,
    // UnloadDataButton,
    FastTravel,
    // CustomVerticalHeadersTable,
    // CustomSimpleTable,
    TdDomain,
    // ChartSkeleton,
    RangeFilter,
    // Loader,
    // eslint-disable-next-line vue/no-unused-components
    apexchart: VueApexCharts,
  },
  mixins: [Location, FiltersHandling, Project, GetRgb, Notification],
  data: () => ({
    breadcrumbs: [
      {
        text: "One Page Analyzer",
        disabled: true,
      },
      {
        text: "Competitor Url's Dynamic",
        disabled: true,
      },
    ],
    successUnload: false,
    unloadUrlsAmount: 15,
    fab: false,
    tableSearchModel: "",
    acceptor: null,
    individualUrl: "",
    notesDialogModel: false,
    individualUrlNoteDialogModel: false,
    defaultFooterProps: { "items-per-page-options": [15, 30, -1] },
    urlFilter: null,
    content: "",
    filters: {
      project_id: null, // filter
      link_type_hidden: [94, 93, 95], // filter
      gi: [], // filter
      link_type: [], // filter
      anchor_type: [], // filter
      do_follow: [], // filter
      ah_dr: [0, 0], // filter
      ah_traffic: [0, 0], // filter
      title_thematic: null, // filter
      gt: null, // filter
      ah_url_traffic: [0, 0], // filter
      ah_2nd_level: [0, 0], // filter
      sw_rank: [0, 0], // filter
      nums_of_pages: [0, 0], // filter
      ah_tr_rd: [0, 0], // filter
      ah_ur: [0, 0], // filter
      date: [], // filter
      url: null, // filter
      links_data: "best link by ur", // filter
      domain: null,
      parent_url: null, // filter
    },
    SECTIONS_IDS: LINK_DYNAMIC_DASHBOARD_SECTIONS_IDS,
    filtersData: {
      url: [],
      link_type: [],
      gi: [],
      anchor_type: [],
      dofollow: [],
      acceptor_url: [],
      acceptor_domain: [],
    },
    presets: LINKS_DYNAMIC_DASHBOARD_PRESETS,
    loadings: {
      filters: false,
      unload: false,
      table: false,
    },
    menuProps: DEFAULT_MENU_PROPS,
    cards: [],
    cardsLength: 0,
    selectedProject: null,
    trafficChartOptions: {},
    trafficChartSeries: [],
    trafficDomainChartOptions: {},
    trafficDomainChartSeries: [],
    linkTypesChartOptions: LINK_TYPES_CHART_OPTIONS,
    linkTypesChartSeries: [],
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    firstEnter: false,
    simpleTableMock: SIMPLE_TABLE_MOCK,
  }),
  async created() {
    eventPipe.$on("update-active-project", () => {
      const project_id = this.$store.getters.active_project;
      this._$handleFilterChange(project_id, PROJECT_ID_IDTF);
      this.fetchFilters();
    });

    const queryProjectId = this.$route.query[PROJECT_ID_IDTF];
    if (this.$route.query[PROJECT_ID_IDTF]) {
      eventPipe.$emit("replace-project", queryProjectId);
    }

    this.initSelectedProject();

    this.fetchFilters();

    this.filters.date = this.getDefaultDate;

    const haveParams = this._$collectParams(
      [
        "link_type_hidden",
        "gi",
        "link_type",
        "anchor_type",
        "do_follow",
        "ah_dr",
        "ah_traffic",
        "ah_url_traffic",
        "ah_2nd_level",
        "sw_rank",
        "nums_of_pages",
        "ah_tr_rd",
        "ah_ur",
        "date",
      ],
      "filters"
    );

    if (haveParams) {
      return this.fetchData();
    }
  },

  watch: {
    "filters.url"(val) {
      this.urlFilter = val;
    },
  },
  computed: {
    icon() {
      if (
        this.filters.link_type_hidden.length ===
        this.filtersData.link_type.length
      )
        return "mdi-close-box";
      if (
        this.filters.link_type_hidden.length > 0 &&
        this.filters.link_type_hidden.length < this.filtersData.link_type.length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getDefaultDate() {
      const start = this.$moment(new Date())
        .subtract(200, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).format("YYYY-MM-DD");
      return [start, end];
    },
    getSelectedURLFilter() {
      return this.filters.url;
    },
  },
  methods: {
    async fetchLinksAndTrafficFromAh() {
      try {
        this.loadings.linksTrafficAh = true;

        const payload = {
          url_id: this.filters.url,
          urls: [this.cards[0].table.items[0].url.value],
        };

        const resp = await this.$store.dispatch(
          "competitor-urls-dynamic/collectBacklinksAhrefs",
          payload
        );

        if (resp) {
          console.log("check:", resp);
        }

        this.loadings.linksTrafficAh = false;
      } catch {
        this.$message.notification({
          type: "error",
          title: "Error",
          text: "Error in method: 'fetchLinksAndTrafficFromAh'.",
        });
      }
    },
    toggle() {
      if (
        this.filters.link_type_hidden.length ===
        this.filtersData.link_type.length
      ) {
        this.filters.link_type_hidden = [];
      } else {
        this.filters.link_type_hidden = this.filtersData.link_type.slice();
      }
    },
    async handleUnloadData() {
      if (this.unloadUrlsAmount === 0) {
        this.mWarning({
          title: "Unload failure",
          text: "The number of uploaded links must be greater than zero.",
        });
        return;
      }

      this.loadings.unload = true;

      let links = this.cards[0].table.items.slice(0, this.unloadUrlsAmount);

      links = links.map((item) => item.url.value);

      links = links.filter((v, i, a) => a.indexOf(v) === i);

      const selectedLink = this.filtersData.url.find(
        (item) => item.value === this.filters.url
      );

      if (selectedLink !== undefined && !links.includes(selectedLink.text)) {
        links = [...links, selectedLink.text];
      }

      const payload = {
        link_id: this.filters.url,
        links,
        project_id: this.$store.getters.active_project,
      };

      const resp = await this.$store.dispatch(
        "competitors-links-dynamic/collectParameters",
        payload
      );

      if (resp) {
        this.successUnload = true;
        setTimeout(() => (this.successUnload = false), 3500);
      }

      this.loadings.unload = false;
    },
    showIndividualNote(urlData) {
      this.individualUrl = urlData.value;
      this.acceptor = urlData.id;
      this.individualUrlNoteDialogModel = true;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleSaveNotes(content) {
      this.savePost(content);
    },
    savePost(content) {
      console.log("publish", content);
    },
    getMax(items, key) {
      let bigger = 0;

      items.forEach((item) => {
        const newValue = parseInt(item[key]);
        if (newValue > bigger) {
          bigger = newValue;
        }
      });

      return bigger;
    },
    getDates(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (!isNaN(key[0])) {
          output.push(key);
        }
      });

      return output;
    },
    initSelectedProject() {
      this.selectedProject = this.$store.state.project.allowed_projects;
    },
    customSortDeeper(items, sortBy, sortDesc) {
      const text = ["url"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i].toLowerCase())) {
            let aVal, bVal;
            if (a[sortBy[i]] === null) aVal = 0;
            if (b[sortBy[i]] === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]];
            const val2 = b[sortBy[i]];
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let aVal, bVal;
          if (!a[sortBy[i]].value && a[sortBy[i]].value !== 0) aVal = 0;
          if (!b[sortBy[i]].value && b[sortBy[i]].value !== 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]].value) > parseInt(b[sortBy[i]].value))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]].value) < parseInt(b[sortBy[i]].value))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    customSort(items, sortBy, sortDesc) {
      const text = ["url"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i])) {
            let aVal, bVal;
            if (a[sortBy[i]] === null) aVal = 0;
            if (b[sortBy[i]] === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]];
            const val2 = b[sortBy[i]];
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let aVal, bVal;
          if (!a[sortBy[i]] && a[sortBy[i]] !== 0) aVal = 0;
          if (!b[sortBy[i]] && b[sortBy[i]] !== 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = [
        "url",
        "link_type",
        "gi",
        "anchor_type",
        "dofollow",
        "acceptor_domain",
        "acceptor_url",
      ];
      const payload = {
        type: "/one-page-analyzer/competitors-links-dynamic/main",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    handleFilterChange(event, type) {
      this._$handleFilterChange(event, type);
    },
    handleRangeChange(event, type) {
      this._$handleRangeChange(event, type);
    },
    handleUpdateDateFilter(event) {
      this.filters.date = event;
      if (!event.length) {
        event = null;
      }
      this._$handleFilterChange(event, "date");
    },
    validate() {
      let errors = 0;
      if (!this.$store.getters.active_project) {
        this.mWarning({
          title: "Validation",
          text: 'The "Project" filter is mandatory.',
        });
        errors++;
      }
      if (!this.filters.url && !this.filters.domain) {
        this.mWarning({
          title: "Validation",
          text: 'One of the filters must be selected: "Acceptor Url" or "Acceptor Domain"',
        });
        errors++;
      }

      return errors >= 1;
    },
    async fetchData() {
      if (this.validate()) return;

      const payload = {
        filter: {
          ...this.filters,
          project_id: this.project_id || this.$store.getters.active_project,
        },
      };

      this.firstEnter = true;
      this.loadings.table = true;

      const resp = await this.$store.dispatch(
        "competitor-urls-dynamic/fetchData",
        payload
      );
      if (resp) {
        this.linkTypesChartSeries = resp.items[0].charts.link_types.items;
        this.trafficChartSeries = resp.items[0].charts.traffic.series;
        this.trafficDomainChartSeries =
          resp.items[0].charts.traffic_domain.series;

        this.trafficChartOptions = {
          ...COMPETITOR_URL_CHART_OPTIONS,
          xaxis: {
            categories: resp.items[0].charts.traffic.categories,
          },
        };
        this.trafficDomainChartOptions = {
          ...COMPETITOR_URL_CHART_OPTIONS,
          xaxis: {
            type: "datetime",
            categories: resp.items[0].charts.traffic_domain.categories,
          },
        };
        this.linkTypesChartOptions = {
          ...LINK_TYPES_CHART_OPTIONS,
          xaxis: {
            categories: resp.items[0].charts.link_types.categories || [],
          },
        };

        this.cards = resp.items;
      }
      this.cardsLength = this.cards.length;
      setTimeout(() => (this.loadings.table = false), 500);
    },
    handleGetData() {
      this.fetchData();
    },
  },
};
</script>
