<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="400px"
  >
    <v-card class="shadow-e1-bordered" rounded="lg">
      <v-card-title> Export Filtered Domains </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item>
            <smart-date-filter
              :model-value="filter_filtered_domains.date_range"
              @update:modelValue="filter_filtered_domains.date_range = $event"
            />
          </v-list-item>
          <v-list-item>
            <v-autocomplete
              dense
              outlined
              v-model="filter_filtered_domains.projects"
              :items="_$getProjects"
              item-value="id"
              item-text="name"
              label="Projects"
              chips
              small-chips
              deletable-chips
              clearable
              multiple
              hide-details
            ></v-autocomplete>
          </v-list-item>
          <v-list-item>
            <v-autocomplete
              dense
              outlined
              v-model="filter_filtered_domains.statuses"
              :items="filtersData.status"
              label="Statuses"
              hide-details
              chips
              small-chips
              deletable-chips
              clearable
              multiple
            ></v-autocomplete>
          </v-list-item>
          <v-list-item>
            <v-container fluid>
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Email GP"
                value="email_gp"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Email WM"
                value="email_wm"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Price"
                value="price"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Currency"
                value="currency"
                hide-details
              ></v-checkbox>
            </v-container>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          class="mr-2"
          width="140px"
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn color="primary" width="180px" :loading="export_loading" @click="exportFilteredDomains">
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import axios from "axios";
import dateFormat from "dateformat";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import Project from "@/mixins/Project";

const date = new Date();

export default {
  components: { SmartDateFilter },
  mixins: [Dialog, Project],
  props: {
    filtersData: {},
  },
  data: () => ({
    export_loading: false,
    filter_filtered_domains: {
      date_range: [
        dateFormat(
          new Date(date.getFullYear(), date.getMonth(), 1),
          "yyyy-mm-dd"
        ),
        dateFormat(
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
          "yyyy-mm-dd"
        ),
      ],
      projects: [],
      statuses: [],
      columns: [],
      responsible: [],
    },
  }),
  methods: {
    exportFilteredDomains() {
      this.export_loading = true;

      let url = `${this.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, this.filter_filtered_domains, config)
        .then((response) => {
          if (response.data.success) {
            location.href =
              `${this.$store.state.server_url}/export/gpm/filtered-domains/0?hash=` +
              response.data.hash;
          }
        })
        .finally(() => {
          this.dialog = false
          this.export_loading = false;
          this.filter_filtered_domains = {
            date_range: [
              dateFormat(
                new Date(date.getFullYear(), date.getMonth(), 1),
                "yyyy-mm-dd"
              ),
              dateFormat(
                new Date(date.getFullYear(), date.getMonth() + 1, 0),
                "yyyy-mm-dd"
              ),
            ],
            projects: [],
            statuses: [],
            columns: [],
            responsible: [],
          }
        });
    },
  },
};
</script>