<template>
  <v-card :loading="loading">
    <v-card-title>Ltt</v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        solo
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1"
      :items-per-page="15"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            :style="getRowColor(item)"
            style="white-space: nowrap"
          >
            <td>{{ item.date }}</td>
            <td>{{ item.donor_url }}</td>
            <td>{{ item.anchor_type }}</td>
            <td>{{ item.anchor }}</td>
            <td>{{ item.link_type }}</td>
            <td>{{ item.islink }}</td>
            <td>{{ item.donorUrIActualGI }}</td>
            <td>{{ item.nofollow }}</td>
            <td>{{ item.noindex }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "@/mixins/Common";
import events from "@/events/components/OnePageAnalyzer/events";
import axios from "axios";

export default {
  name: "Ltt",
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Date", value: "date", class: "text-center" },
        { text: "Donor Url", value: "donor_url" },
        { text: "Anchor Type", value: "anchor_type", class: "text-center" },
        { text: "Anchor", value: "anchor", class: "text-center" },
        { text: "Link Type", value: "link_type", class: "text-center" },
        { text: "OP", value: "islink", class: "text-center" },
        { text: "GI", value: "donorUrIActualGI", class: "text-center" },
        { text: "NF", value: "nofollow", class: "text-center" },
        { text: "NI", value: "noindex", class: "text-center" },
      ],
      items: [],
    };
  },
  methods: {
    getRowColor(item) {
      if (item.disavow === 1) {
        return "background-color: #E399B1 !important;";
      } else {
        if (item.noindex === 1 || item.robotsclose === 1)
          return " background-color: rgba(255, 21, 0, 0.20) !important;";

        if (item.islink === 1) {
          if (item.donorUrIActualGI === 1) {
            return "background-color: rgba(100, 255, 11, 0.20) !important;";
          } else
            return " background-color: rgba(255, 189, 0, 0.20) !important;";
        } else return "background-color: rgba(0, 149, 255, 0.20) !important;";
      }
    },
    resetData() {
      this.items = [];
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/ltt`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedUrl,
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        filter: self.filterData,
      };

      axios
        .post(url, data, config)
        .then((r) => {
          self.items = r.data.items;
        })
        .finally(() => {
          self.loading = false;
        });
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.fetchData();
    });

    events.$on("update-main-filter", function () {
      self.resetData();
      self.fetchData();
    });
  },
};
</script>

<style scoped></style>
