<template>
  <div>
    <CreateTaskThemes/>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :loading="loading"
              :headers="headers"
              :items="items"
              class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                  color="primary"
                  depressed
                  icon
                  link
                  :to="{ name: 'gtt.show', params: { id: item.id }}"
              >
                <v-icon>
                  mdi-login-variant
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CreateTaskThemes from "@/components/ViewInfoBySite/Dialogs/CreateTaskThemes";
import axios from "axios";

export default {
  name: "Themes",
  components: {CreateTaskThemes},
  data() {
    return {
      loading: false,
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Name', value: 'name'},
        {text: 'Created At', value: 'created_at'},
        {text: '', value: 'actions', align: 'center', width: 50, sortable: false},
      ],
      items: []
    }
  },
  methods: {
    fetchData() {
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/gtt`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.items = r.data.items
          })
          .finally(() => self.loading = false)
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>