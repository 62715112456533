<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e4"
    scrollable
    max-width="1220"
  >
    <v-card class="styled-card--light site-analysis__feedback">
      <v-card-title>
        Feedback
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0 d-flex">
        <div style="flex: 1; position: sticky; top: 0" class="hide-scrollbar">
          <v-card-text class="pt-5">
            <v-card class="styled-card--light">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.theme_id"
                      :items="themeItems"
                      label="Select theme"
                      placeholder="Type to search"
                      filled
                      hide-details
                      dense
                      @change="handleChangeTheme"
                    />
                  </v-col>
                  <v-col cols="12" class="d-flex align-center">
                    <v-chip
                      :color="getRatingColor(-10)"
                      label
                      class="px-2 mr-1 d-flex justify-center"
                      style="min-width: 38px"
                      >-10</v-chip
                    >
                    <v-slider
                      v-model="form.rating"
                      min="-10"
                      max="10"
                      hide-details
                      thumb-label="always"
                      :thumb-color="getRatingColor(form.rating)"
                      dense
                    />
                    <v-chip
                      :color="getRatingColor(10)"
                      label
                      class="px-2 mr-1 d-flex justify-center"
                      style="min-width: 38px"
                      >10</v-chip
                    >
                  </v-col>
                  <v-col cols="12">
                    <tip-tap-editor
                      :model-value="form.comment"
                      @update:modelValue="form.comment = $event"
                    />
                    <small
                      v-if="
                        form.comment &&
                        form.comment.length <= commentSymbolsLimit
                      "
                    >
                      characters left:
                      {{ commentSymbolsLimit - form.comment.length }}
                    </small>
                    <small
                      v-else-if="
                        form.comment &&
                        form.comment.length > commentSymbolsLimit
                      "
                      class="error--text"
                    >
                      You've reached characters limit.
                    </small>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-menu
                  v-if="validate.error"
                  bottom
                  offset-y
                  content-class="my-shadow--e3"
                >
                  <template #activator="{ on }">
                    <v-chip v-on="on" outlined label class="mr-2 px-2">
                      <v-icon small color="error">mdi-alert </v-icon>
                    </v-chip>
                  </template>
                  <v-card class="styled-card--error">
                    <v-card-title>
                      <v-icon color="error" small class="mr-2"
                        >mdi-alert</v-icon
                      >
                      Errors
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <span class="error--text">
                        {{ validate.message }}
                      </span>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-chip
                  label
                  color="primary"
                  @click="handleSaveFeedback"
                  :disabled="validate.error || savingLoading"
                >
                  Send feedback
                  <v-icon v-if="!savingLoading" small right>mdi-send</v-icon>
                  <v-progress-circular
                    v-else
                    size="18"
                    width="2"
                    indeterminate
                  />
                </v-chip>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </div>
        <div style="flex: 1">
          <v-card-text class="pt-5 pb-2">
            <div class="d-flex align-center">
              <h3>Feedback on this topic from other users</h3>
              <small v-if="!commentLoading" class="ml-2"
                >({{ comments.length }})</small
              >
              <v-progress-circular v-else size="12" width="2" indeterminate />
            </div>
          </v-card-text>
          <template
            v-if="
              (comments.length === 0 || !comments) && !commentLoading && dirty
            "
          >
            <v-card-text>
              <span style="opacity: 0.5">
                There are no comments for this topic, feel free to add yours.
              </span>
            </v-card-text>
          </template>
          <template
            v-else-if="(comments.length === 0 || !comments) && !commentLoading"
          >
            <v-card-text>
              <span style="opacity: 0.5">
                Nothing here. Select theme to see comments.
              </span>
            </v-card-text>
          </template>
          <template
            v-if="
              Array.isArray(comments) && comments.length > 0 && !commentLoading
            "
          >
            <v-card-text>
              <v-row dense>
                <template v-for="(comment, idx) in comments">
                  <v-col cols="12" :key="idx">
                    <comment-item
                      can-delete
                      :comment="comment"
                      :after-remove-callback="handleAfterRemoveComment"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </template>
          <template v-if="commentLoading">
            <v-card-text>
              <div v-for="idx in [0, 1, 2]" :key="idx" class="mt-6 d-flex">
                <div
                  class="skeleton mr-2"
                  style="width: 38px; height: 38px; border-radius: 100px"
                ></div>
                <div>
                  <div>
                    <div
                      class="skeleton"
                      style="width: 160px; height: 18px"
                    ></div>
                  </div>
                  <div class="mt-2">
                    <div
                      class="skeleton"
                      style="width: 560px; height: 48px"
                    ></div>
                  </div>
                  <div class="mt-3">
                    <div
                      class="skeleton"
                      style="width: 40px; height: 18px"
                    ></div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TipTapEditor from "@/components/UI/TipTapEditor.vue";
import eventPipe from "@/events/eventPipe";
import CommentItem from "@/components/SiteAnalysis/UI/CommentItem.vue";
import SiteAnalysisFeedbackMixin from "@/mixins/SiteAnalysisFeedbackMixin";
import Notification from "@/mixins/Notification";

export default {
  components: { CommentItem, TipTapEditor },
  mixins: [SiteAnalysisFeedbackMixin, Notification],
  props: {
    modelValue: {},
    themeItems: Array,
    comments: Array,
    commentLoading: Boolean,
    savingLoading: Boolean,
  },
  emits: ["update:modelValue", "update:form", "themeChange"],
  data: () => ({
    dirty: false,
    commentSymbolsLimit: 500,
    form: {
      theme_id: null,
      rating: 0,
      comment: null,
    },
  }),
  computed: {
    validate() {
      if (this.form.rating === 0) {
        return {
          message: "Rating cant be 0.",
          error: true,
        };
      }

      return {
        error: false,
      };
    },
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  created() {
    eventPipe.$on("site-analysis-feedback-success-saved", () => {
      this.handleSuccessSavedFeedback();
    });
    eventPipe.$on("site-analysis-handle-click-get-data", () => {
      this.dirty = false;
      this.clearForm();
    });
  },
  mounted() {
    eventPipe.$on("open-feedback-with-selected-theme", (themeName) => {
      const success = this.selectThemeByName(themeName);

      if (!success) return;
      this.handleChangeTheme();
    });
  },
  methods: {
    handleAfterRemoveComment() {
      eventPipe.$emit("update-feedback-list", {});
      eventPipe.$emit("update-pros-cons");
    },
    async selectThemeByName(themeName) {
      try {
        const themeItem = this.themeItems.find(
          (theme) => theme.text === themeName
        );

        if (!themeItem) {
          this.mWarning({
            text: "Not able to create a review for this topic because this topic doesn't exist in the uploaded list of themes.",
            title: "Theme not exist",
          });

          return false;
        }

        this.form.theme_id = themeItem.value;
        await this.partiallyClearForm();
        this.dialog = true;

        return true;
      } catch (e) {
        console.error("Error while selecting theme by name.", e);

        return false;
      }
    },
    clearForm() {
      this.form = {
        theme_id: null,
        rating: 0,
        comment: null,
      };
    },
    async handleSuccessSavedFeedback() {
      await this.partiallyClearForm();
      this.handleUpdateComments();
    },
    partiallyClearForm() {
      return new Promise((resolve, reject) => {
        try {
          this.form = {
            ...this.form,
            rating: 0,
            comment: null,
          };
          resolve();
        } catch (e) {
          console.error("Error while clearing form.", e);
          reject();
        }
      });
    },
    handleUpdateComments() {
      eventPipe.$emit("update-feedback-list", { theme_id: this.form.theme_id });
    },
    handleSaveFeedback() {
      this.$emit("save", {
        form: this.form,
      });
    },
    handleChangeTheme() {
      this.dirty = true;
      eventPipe.$emit("update-feedback-list", { theme_id: this.form.theme_id });
    },
  },
};
</script>
