<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-banner
              @click:icon="open_url()">
            <v-icon
                slot="icon"
                color="primary"
                size="25"
                style="cursor: pointer"
            >
              mdi-open-in-new
            </v-icon>
            {{ url }}
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <div style="width: 186px; display: inline-block; margin-right: 10px;">
                <v-menu
                    v-model="dates_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date_range_text"
                        label="Date range"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dates"
                      :allowed-dates="get_allowed_dates"
                      range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        :loading="global_loading"
                        @click="fetch_data"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <v-switch
                  class="mt-0"
                  v-model="diffWords"
                  dense
                  inset
                  :label="diffWords ? 'Diff words' : 'Diff sentences'"
                  hide-details
              ></v-switch>
              <v-switch
                  class="mt-0"
                  v-model="unique_blocks"
                  dense
                  inset
                  label="Unique blocks"
                  hide-details
              ></v-switch>
              <v-switch
                  class="mt-0"
                  v-model="only_changes"
                  dense
                  inset
                  label="Only changes"
                  hide-details
              ></v-switch>
              <v-btn icon :loading="global_loading" @click="fetch_data">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
          </v-banner>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" offset="3">
          <div v-if="!diffWords">
            <p v-for="(m, i) in getDiff" :key="i">
              <v-alert icon="mdi-plus" v-if="m.added" color="success" text v-html="m.value"></v-alert>
              <v-alert icon="mdi-minus" v-else-if="m.removed" color="error" text v-html="m.value"></v-alert>
              <span v-else v-html="m.value"></span>
            </p>
          </div>
          <div v-else>
            <span v-for="(m, i) in getDiff" :key="i">
              <span v-if="m.added" class="green--text lighten-1" v-html="` ${m.value} `"></span>
              <span v-else-if="m.removed" class="red--text lighten-1" v-html="` ${m.value} `"></span>
              <span v-else v-html="` ${m.value} `"></span>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6">
          <div
              v-for="item in items[dates[0]]"
              :key="item"
          >
            <v-alert
                dense
                text
                :color="changes[dates[0]].indexOf(item) !== -1 ? 'error' : 'grey'"
                v-if="only_changes ? changes[dates[0]].indexOf(item) !== -1 : true"
            >
              <v-row>
                <v-col class="flex-grow-0 flex-shrink-0">
                  <v-icon v-if="changes[dates[0]].indexOf(item) !== -1" color="error">mdi-minus</v-icon>
                  <v-icon v-else color="grey">mdi-circle-small</v-icon>
                  <v-icon v-if="has_ascii(item)" color="error">mdi-comment-alert-outline</v-icon>
                </v-col>
                <v-col class="flex-grow-1 flex-shrink-0">
                  <div style="word-break: break-all;" v-html="highlight_ascii(item)"></div>
                </v-col>
              </v-row>
            </v-alert>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div
              v-for="(item, i) in items[dates[1]]"
              :key="item"
          >
            <v-alert
                dense
                text
                color="info"
                v-if="items[dates[0]][i] !== item"
            >
              <v-row>
                <v-col class="flex-grow-0 flex-shrink-0">
                  <v-icon v-if="items[dates[0]].indexOf(item) > i" color="info">mdi-chevron-up</v-icon>
                  <v-icon v-else color="info">mdi-chevron-down</v-icon>
                  <v-icon v-if="has_ascii(item)" color="error">mdi-comment-alert-outline</v-icon>
                </v-col>
                <v-col class="flex-grow-1 flex-shrink-0">
                  <div style="word-break: break-all;" v-html="highlight_ascii(item)"></div>
                </v-col>
              </v-row>
            </v-alert>
            <v-alert
                dense
                text
                :color="changes[dates[1]].indexOf(item) !== -1 ? 'success' : 'grey'"
                v-else-if="only_changes ? changes[dates[1]].indexOf(item) !== -1 : true"
            >
              <v-row>
                <v-col class="flex-grow-0 flex-shrink-0">
                  <v-icon v-if="changes[dates[1]].indexOf(item) !== -1" color="success">mdi-plus</v-icon>
                  <v-icon v-else color="grey">mdi-circle-small</v-icon>
                  <v-icon v-if="has_ascii(item)" color="error">mdi-comment-alert-outline</v-icon>
                </v-col>
                <v-col class="flex-grow-1 flex-shrink-0">
                  <div style="word-break: break-all;" v-html="highlight_ascii(item)"></div>
                </v-col>
              </v-row>
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

const Diff = require('diff');

export default {
  name: "CompareBlocks",
  data() {
    return {
      diffWords: true,
      url: undefined,
      unique_blocks: false,
      only_changes: false,
      dates_menu: false,
      dates: [],
      allowed_dates: [],
      items: {},
      changes: {},
      texts: {
        from: '',
        to: ''
      }
    }
  },
  computed: {
    getDiff() {
      return this.diffWords ? Diff.diffWords(this.texts.from, this.texts.to) : Diff.diffSentences(this.texts.from, this.texts.to)
    },
    date_range_text() {
      return this.dates.join(' ~ ')
    },
    global_loading() {
      return this.$store.state.loading
    }
  },
  methods: {
    has_ascii(s) {
      let match_arr = s.match(/[а-яА-Я]/g)

      return match_arr !== null
    },
    highlight_ascii(s) {
      let match_arr = s.match(/[а-яА-Я]/g)

      if (match_arr === null) {
        return s
      }

      let match_arr_uniq = match_arr.filter((value, index, self) => self.indexOf(value) === index)

      for (let i = 0; i < match_arr_uniq.length; i++) {
        s = s.replaceAll(match_arr_uniq[i], '' + match_arr_uniq[i] + '<sup>?</sup>')
      }

      return s
    },
    open_url() {
      window.open(this.url, '_blank')
    },
    get_allowed_dates(val) {
      return this.allowed_dates.indexOf(val) !== -1
    },
    fetch_static_data(callback) {
      let self = this

      self.$store.state.loading = true

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios
          .get(`${self.$store.state.server_url}/content-checker/dashboard/static-data/0`, config)
          .then((r) => {
            self.allowed_dates = r.data.allowed_dates
          })
          .finally(() => {
            self.$store.state.loading = false
            callback()
          })
    },
    fetch_data() {
      let self = this

      self.$store.state.loading = true

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        },
        params: {
          dates: self.dates,
          unique_blocks: self.unique_blocks
        }
      }

      axios
          .get(`${self.$store.state.server_url}/content-checker/compare-blocks/${self.$route.params.id}`, config)
          .then((r) => {
            self.items = r.data.items
            self.changes = r.data.changes
            self.texts = r.data.texts
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    }
  },
  created() {
    let self = this

    self.dates = self.$route.query.dates
    self.unique_blocks = self.$route.query.uniq !== undefined
    self.url = self.$route.query.url

    self.fetch_static_data(() => {
      self.fetch_data()
    })
  }
}
</script>

<style scoped>

</style>