<template>
  <v-menu
      v-model="menu"
      offset-y
      bottom
      origin="center center"
      transition="slide-x-transition"
      :close-on-content-click="false"
      rounded="lg"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
          v-on="on"
          v-bind="attrs"
          outlined
          color="transparent"
          :class="`custom-range-button${menu ? ' focused' : ''}`"
          block
      >
        <label :class="`${range[0] >= 0 && range[1] > 0 ? 'active' : ''}`">{{title}}</label>
        <span style="text-transform: none">
          <template
              v-if="range[0] >= 0 && range[1] > 0"
          >
            {{range[0]}}
            <v-icon small class="mx-1" style="opacity: 0.5;">mdi-arrow-left-right</v-icon>
            {{range[1]}}
          </template>
          <template v-else>{{title}}</template>
        </span>
      </v-btn>
    </template>
    <div class="positions-dropdown__wrapper">
      <div class="calendar-wrapper section">
        <v-row style="max-width: 220px;">
          <v-col cols="6">
            <v-text-field
                v-model="range[0]"
                type="number"
                counter
                label="From"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="range[1]"
                type="number"
                label="To"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-range-slider
                v-model="range"
                dense
                hide-details
                :max="max"
                :min="min"
            ></v-range-slider>
          </v-col>
        </v-row>
        <v-row v-if="presets.length > 0">
          <v-col>
            <div class="range-presets">
              <v-list>
                <v-subheader>Presets</v-subheader>
                <v-list-item>
                  <v-radio-group v-model="range">
                    <v-radio
                        v-for="preset in presets"
                        :key="preset[0]"
                        :label="preset[0]"
                        :value="preset[1]"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <div class="calendar-wrapper section">
        <v-btn block plain small color="error" @click="clearRange">Clear {{title}}</v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default() {return []},
    },
    title: {
      type: String,
      default: '',
    },
    presets:{
      type: Array,
      default() {return []},
    },
    min:{
      type: Number,
      default:0,
    },
    max:{
      type: Number,
      default: 100,
    },
  },
  computed:{
    range: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    }
  },
  data:() => ({
    menu: false,
  }),
  methods: {
    clearRange() {
      this.range = [0,0]
      this.menu = false
    },
  },
}
</script>