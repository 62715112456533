export default {
  data: () => ({
    loading: false,
    dataRelevance: 60,
    last_updated: {
      day_diff: null,
      updated_at: null,
    },
    timeout: null,
  }),
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    _$forceUpdateData() {
      if (this.dataRelevance === 7) {
        this.dataRelevance = 60;
      } else {
        this.dataRelevance = 7;
      }
      const isForce = this.dataRelevance === 7;

      if (this.last_updated.day_diff >= this.dataRelevance) {
        this.fetchData(true, isForce);
      }
    },
  },
};
