<template>
  <td style="cursor: pointer">
    <a
      v-if="!isGp"
      :href="`https://app.serpinsider.com/anchors-plan/placement-links/${data.ap_task_id}`"
      target="_blank"
    >
      {{ data.ap_task_id }}
    </a>
    <a
      v-else
      :href="`http://app.serpinsider.com/gpm/view-entity/${data.gpm_task_id}`"
      target="_blank"
    >
      {{ data.gpm_task_id }}
    </a>
  </td>
</template>

<script>
export default {
  props: ["data", "isGp"],
};
</script>
