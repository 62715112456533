<template>
  <v-container fluid class="tdh1">
    <v-row>
      <v-col cols="12">
        <h2>TDH1 Dashboard</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="d-flex">
        <v-autocomplete
          v-model="filters.url"
          dense
          :items="filtersData.url"
          outlined
          clearable
          :loading="loadings.filters"
          hide-details
          :menu-props="menuProps"
          placeholder="Select url"
          label="Url"
          @change="_$handleFilterChange($event, 'url')"
        >
          <template #item="{ item }">
            <v-row dense class="align-center">
              <v-col cols="8">
                {{ item.text }}
              </v-col>
              <v-col class="d-flex justify-end" cols="4">
                {{ item.value }}
              </v-col>
            </v-row>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-menu
          bottom
          offset-y
          max-width="290px"
          :close-on-content-click="false"
          content-class="my-shadow--e3"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filters.date"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              hide-details
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filters.date" scrollable> </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-btn
          width="140px"
          style="height: 40px"
          color="primary"
          @click="fetchContent"
        >
          Get Data</v-btn
        >
      </v-col>
      <v-col></v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          outlined
          class="mr-2"
          @click="clearCache"
          color="error"
          :loading="loadings.cache"
        >
          Clear cache <v-icon small right>mdi-close</v-icon>
        </v-btn>
        <v-btn
          :loading="loadings.export"
          class="mr-2"
          outlined
          color="primary"
          @click="createExport"
          >Create export <v-icon class="ml-2">mdi-export-variant</v-icon></v-btn
        >
        <v-btn
          @click="openInNew(filters.url)"
          class="ml-2"
          icon
          color="primary"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-tooltip v-if="filters.url" bottom>
          <template #activator="{ on }">
            <v-btn @click="goToSW" v-on="on" class="ml-2" icon>
              <v-icon>mdi-application-cog-outline</v-icon>
            </v-btn>
          </template>
          <div>Open Site Workplace with selected url</div>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn class="ml-2" icon @click="goTo" v-on="on">
              <v-icon>mdi-console</v-icon>
            </v-btn>
          </template>
          <div>Open Search Console with selected url</div>
        </v-tooltip></v-col
      >
    </v-row>
    <v-expand-transition>
      <v-row v-if="oldCollectedData.length > 0">
        <v-col cols="12">
          <v-card
            rounded="xl"
            class="shadow-e1-bordered border--error"
            max-width="800px"
          >
            <v-card-title>
              <v-icon class="mr-2" color="error">mdi-alert-outline</v-icon>
              Data Collected more than 2 weeks ago for these URLs
            </v-card-title>
            <v-card-text>
              <v-data-table
                :sort-by="['vision']"
                :sort-desc="[true]"
                dense
                :loading="loadings.table"
                hide-default-footer
                :items-per-page="-1"
                :items="oldCollectedData"
                :headers="oldCollectedHeaders"
              >
                <template #[`item.url`]="{ value }">
                  <a :href="value" target="_blank">{{ value }}</a>
                </template>
                <template #[`item.date`]="{ value }">
                  {{ String(value) === "1970-01-01" ? "-" : value }}
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions />
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-row>
      <v-col cols="12">
        <v-card
          v-for="key in Object.keys(cards)"
          rounded="xl"
          class="shadow-e1-bordered mb-6"
          :key="key"
        >
          <v-progress-linear
            indeterminate
            v-if="loadings.sections.length > 0"
          ></v-progress-linear>
          <template>
            <v-card-title class="justify-center" style="overflow: hidden">
              <h2 style="text-transform: capitalize">
                {{ key }}
              </h2>
            </v-card-title>
            <v-card rounded="lg" elevation="0">
              <template v-if="hasCompetitors(cards[key])">
                <v-card-title>
                  <v-chip label class="mr-2" small>{{ key }}</v-chip>
                  Competitors
                </v-card-title>
                <v-card-text>
                  <competitor-table
                    :type="key"
                    :parent-url="filters.url"
                    :filters="filters"
                    v-if="cards[key].competitors.status"
                    :items="cards[key].competitors.items"
                  />
                </v-card-text>
              </template>
              <v-card-title>
                <v-chip label class="mr-2" small>{{ key }}</v-chip> Optimization
                by Semantics
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :sort-by="['pr', 'volume', 'cl_28d', 'imp_28d', 'sum_of_use']"
                  :sort-desc="[false, true, true, true, true]"
                  dense
                  multi-sort
                  :items-per-page="30"
                  :custom-sort="_$smartCustomTableSort"
                  :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                  :items="cards[key].semantic.items"
                  :headers="cards[key].semantic.headers"
                  :class="`card-table cols-${cards[key].semantic.headers.length}`"
                >
                  <template #[`item.phrase`]="{ item }">
                    <phrase-smart-td :item="item" />
                  </template>

                  <template #[`item.new`]="{ value }">
                    <span
                      :class="`colored-td full-size ${value ? 'green' : ''}`"
                    >
                      {{ value ? 1 : "" }}
                    </span>
                  </template>
                  <template #[`item.cl_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.imp_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.sum_of_use`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.pos`]="{ value }">
                    <span
                      :class="`colored-td full-size ${getPositionsColor(
                        value
                      )}`"
                    >
                      {{ value }}
                    </span>
                  </template>
                  <template
                    v-for="key in getIteratedCols(cards[key].semantic.headers)"
                    #[`item.${key}`]="{ value }"
                  >
                    <div
                      :class="`colored-td full-size ${
                        value === null ? 'red' : ''
                      }`"
                      :key="key"
                    >
                      {{ value ? 1 : null }}
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card rounded="lg" elevation="0">
              <v-card-title>
                <v-chip label class="mr-2" small>{{ key }}</v-chip> Optimization
                by Words From Semantic
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :sort-by="['pr', 'volume', 'cl_28d', 'imp_28d', 'sum_of_use']"
                  :sort-desc="[false, true, true, true, true]"
                  dense
                  multi-sort
                  :items-per-page="30"
                  :custom-sort="_$smartCustomTableSort"
                  :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                  :items="cards[key].byWord.items"
                  :headers="cards[key].byWord.headers"
                  :class="`card-table cols-${cards[key].byWord.headers.length}`"
                >
                  <template #[`item.phrase`]="{ item }">
                    <phrase-smart-td :item="item" />
                  </template>
                  <template #[`item.new`]="{ value }">
                    <span
                      :class="`colored-td full-size ${value ? 'green' : ''}`"
                    >
                      {{ value ? 1 : "" }}
                    </span>
                  </template>
                  <template #[`item.cl_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.imp_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.sum_of_use`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.pos`]="{ value }">
                    <span
                      :class="`colored-td full-size ${getPositionsColor(
                        value
                      )}`"
                    >
                      {{ value }}
                    </span>
                  </template>
                  <template
                    v-for="key in getIteratedCols(cards[key].byWord.headers)"
                    #[`item.${key}`]="{ value }"
                  >
                    <div
                      :class="`colored-td full-size ${
                        value === null ? 'red' : ''
                      }`"
                      :key="key"
                    >
                      {{ value ? 1 : null }}
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card rounded="lg" elevation="0">
              <v-card-title>
                <v-chip label class="mr-2" small>{{ key }}</v-chip>
                Optimization by Top Competitors
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :sort-by="getSort(cards[key].topByCompetitors.headers).by"
                  :sort-desc="getSort(cards[key].topByCompetitors.headers).desc"
                  dense
                  multi-sort
                  :items-per-page="30"
                  :custom-sort="_$smartCustomTableSort"
                  :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                  :items="cards[key].topByCompetitors.items"
                  :headers="cards[key].topByCompetitors.headers"
                  :class="`card-table cols-${cards[key].topByCompetitors.headers.length}`"
                >
                  <template #[`item.phrase`]="{ item }">
                    <phrase-smart-td :item="item" />
                  </template>
                  <template #[`item.new`]="{ value }">
                    <span
                      :class="`colored-td full-size ${value ? 'green' : ''}`"
                    >
                      {{ value ? 1 : "" }}
                    </span>
                  </template>
                  <template #[`item.cl_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.imp_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.sum_of_use`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.pos`]="{ value }">
                    <span
                      :class="`colored-td full-size ${getPositionsColor(
                        value
                      )}`"
                    >
                      {{ value }}
                    </span>
                  </template>
                  <template
                    v-for="key in getIteratedCols(
                      cards[key].topByCompetitors.headers
                    )"
                    #[`item.${key}`]="{ value }"
                  >
                    <div
                      :class="`colored-td full-size ${
                        value === null ? 'red' : ''
                      }`"
                      :key="key"
                    >
                      {{ value ? 1 : null }}
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card rounded="lg" elevation="0">
              <v-card-title>
                <v-chip label class="mr-2" small>{{ key }}</v-chip> Optimization
                by Top 10
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :sort-by="['pr', 'volume', 'cl_28d', 'imp_28d', 'sum_of_use']"
                  :sort-desc="[false, true, true, true, true]"
                  dense
                  multi-sort
                  :items-per-page="30"
                  :custom-sort="_$smartCustomTableSort"
                  :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                  :items="cards[key].byTop10.items"
                  :headers="cards[key].byTop10.headers"
                  :class="`card-table cols-${cards[key].byTop10.headers.length}`"
                >
                  <template #[`item.phrase`]="{ item }">
                    <phrase-smart-td :item="item" />
                  </template>
                  <template #[`item.new`]="{ value }">
                    <span
                      :class="`colored-td full-size ${value ? 'green' : ''}`"
                    >
                      {{ value ? 1 : "" }}
                    </span>
                  </template>
                  <template #[`item.cl_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.imp_28d`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.sum_of_use`]="{ value }">
                    {{ String(value) === "0" ? "" : value }}
                  </template>
                  <template #[`item.pos`]="{ value }">
                    <span
                      :class="`colored-td full-size ${getPositionsColor(
                        value
                      )}`"
                    >
                      {{ value }}
                    </span>
                  </template>
                  <template
                    v-for="key in getIteratedCols(cards[key].byTop10.headers)"
                    #[`item.${key}`]="{ value }"
                  >
                    <div
                      :class="`colored-td full-size ${
                        value === null ? 'red' : ''
                      }`"
                      :key="key"
                    >
                      {{ value ? 1 : null }}
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card-actions />
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "@/plugins/service";
import {
  COMP_MOCK,
  DEFAULT_MENU_PROPS,
  TDH1_OLD_COLLECTED_DATA_HEADERS,
  TDH1_SECTION_INTERFACE,
} from "../../utils/defaultData";
import md5 from "md5";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import PhraseSmartTd from "../../components/TDH1/UI/PhraseSmartTd";
import CompetitorTable from "./UI/CompetitorTable";
import Project from "../../mixins/Project";
import eventPipe from "../../events/eventPipe";
import ExportTaskService from "@/services/ExportTaskService";

export default {
  components: { CompetitorTable, PhraseSmartTd },
  mixins: [FiltersHandling, Project],
  data: () => ({
    loadings: {
      table: false,
      export: false,
      cache: false,
      filters: false,
      sections: [],
    },
    tableData: {
      headers: [],
      items: [],
    },
    filters: {
      url: null,
      date: null,
    },
    oldCollectedData: [],
    showOldCollectedData: false,
    dateFilter: [],
    oldCollectedHeaders: TDH1_OLD_COLLECTED_DATA_HEADERS,
    filtersData: {
      url: [],
    },
    mock: COMP_MOCK,
    menuProps: DEFAULT_MENU_PROPS,
    cards: {
      title: {
        ...TDH1_SECTION_INTERFACE,
      },
      description: {
        ...TDH1_SECTION_INTERFACE,
      },
      h1: {
        ...TDH1_SECTION_INTERFACE,
      },
    },
  }),
  created() {
    const haveParams = this._$collectParamsTo(this, "filters");

    if (this.$route.query.project_id) {
      this._$setActiveProject(this.$route.query.project_id, false);
    }

    eventPipe.$on("update-active-project", () => {
      this.fetchFilters();
    });

    this.fetchFilters();

    if (!haveParams || !this.$route.query.url) return;

    this.fetchContent();
  },
  methods: {
    async clearCache() {
      try {
        const url = `/tdh1/clear-cache/${this.filters.url}`;

        this.loadings.cache = true;

        const { data } = await service.post(url);

        if (data.success) {
          this.$message.notification({
            title: "Done",
            text: "Successfully cleared.",
            type: "success",
          });
        }
      } catch (e) {
        console.error("Error while clearing cache.", e);
      } finally {
        this.loadings.cache = false;
      }
    },
    async createExport() {
      try {
        const exportService = new ExportTaskService({
          json: {},
          type: 12,
          context: this,
        });

        this.loadings.export = true;

        await exportService.createExport();
      } finally {
        this.loadings.export = false;
      }
    },
    openInNew(urlId) {
      try {
        const url = this.filtersData.url.find((v) => v.value === urlId);
        window.open(url.text, "_blank");
      } catch {
        return this.$message.notification({
          title: "Warning",
          text: "Please, select URL.",
          type: "warning",
        });
      }
    },
    hasNewValue(items) {
      const exist = items.find((v) => v.vision === "OUR NEW") !== undefined;
      return exist;
    },
    getSort(headers) {
      const hasNewHeader =
        (headers.find((v) => v.value === "new") || []).length !== 0;
      if (hasNewHeader) {
        return {
          by: ["new", "sum_of_use", "pr", "volume", "cl_28d", "imp_28d"],
          desc: [true, true, false, true, true, true],
        };
      }
      return {
        by: ["entry", "sum_of_use", "pr", "volume", "cl_28d", "imp_28d"],
        desc: [true, true, false, true, true, true],
      };
    },
    goToSW() {
      const url = `/site-workplace/dashboard?url_id=${this.filters.url}`;
      window.open(url, "_blank");
    },
    goTo() {
      if (!this.filters.url) {
        return this.$message.notification({
          title: "Warning",
          text: "Please, select URL.",
          type: "warning",
        });
      }
      const url = `/search-console/url/${md5(this.filters.url)}`;
      window.open(url, "_blank");
    },
    hasCompetitors(data) {
      return (
        data.competitors !== undefined && data.competitors.items !== undefined
      );
    },
    getPositionsColor(value) {
      const v = isNaN(value) ? 0 : Number(value);

      if (!value) return "";

      if (v === 101) return "gray";

      if (v >= 1 && v <= 10) return "green";

      if (v >= 11 && v <= 30) return "yellow";

      if (v >= 31 && v <= 50) return "orange";

      if (v >= 51 && v <= 100) return "red";
    },
    _$smartCustomTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          let aVal, bVal;
          if (
            a[sortBy[i]] === null ||
            a[sortBy[i]] === "" ||
            a[sortBy[i]] === "0" ||
            a[sortBy[i]] === 0
          )
            aVal = 0;
          if (
            b[sortBy[i]] === null ||
            b[sortBy[i]] === "" ||
            b[sortBy[i]] === "0" ||
            b[sortBy[i]] === 0
          )
            bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["url"];
      const payload = {
        type: "/tdh1-dashboard",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    fetchContent() {
      this.fetchData();

      const types = ["h1", "title", "description"];

      const sections = [
        {
          name: "semantic",
          endpoint: "/tdh1/optimization-by-semantic",
        },
        {
          name: "byWord",
          endpoint: "/tdh1/optimization-by-words-from-semantic",
        },
        {
          name: "topByCompetitors",
          endpoint: "/tdh1/optimization-by-top-competitors-words-by-semantic",
        },
        {
          name: "byTop10",
          endpoint: "/tdh1/optimization-by-top10",
        },
      ];

      types.forEach((type) => {
        sections.forEach((sectionOptions) => {
          this.fetchSectionsData(type, sectionOptions);
        });
        this.fetchCompetitors(type);
      });
    },
    async fetchData() {
      try {
        const url = `/tdh1/urls-relevance`;
        const config = {
          params: {
            ...this.filters,
          },
        };

        this.loadings.table = true;

        const resp = await service.get(url, true, config);

        if (resp && resp.data) {
          this.oldCollectedData = resp.data.items;
          this.showOldCollectedData = resp.data.status;
        }
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    getIteratedCols(items) {
      const output = [];

      items.forEach((header) => {
        if (header.value[0] === "$" || header.value === "entry") {
          output.push(header.value);
        }
      });

      return output;
    },
    async fetchCompetitors(type) {
      try {
        const url = "/tdh1/urls-values";

        const configs = {
          params: {
            ...this.filters,
            type,
          },
        };

        const resp = await service.get(url, true, configs);

        if (resp && resp.data) {
          this.cards[type].competitors = resp.data;
        }
      } catch {
        //
      }
    },
    async fetchSectionsData(type, options) {
      const id = type + options.name;

      try {
        const url = options.endpoint;
        const config = {
          params: {
            ...this.filters,
            type,
          },
        };

        this.loadings.sections.push(id);

        const resp = await service.get(url, true, config);

        this.loadings.sections = this.loadings.sections.filter((v) => v !== id);

        if (resp && resp.data) {
          this.cards[type][options.name] = resp.data;
        }
      } catch {
        this.loadings.sections = this.loadings.sections.filter((v) => v !== id);
      }
    },
  },
};
</script>

<style lang="scss">
.tdh1 {
  table {
    overflow: hidden;
    td:first-child {
      position: relative;
    }
  }
  .card-table {
    &.cols-19 {
      tbody {
        td:nth-child(1),
        td:nth-child(3),
        td:nth-child(5),
        td:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
      thead {
        th:nth-child(1),
        th:nth-child(3),
        th:nth-child(5),
        th:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
    &.cols-18 {
      tbody {
        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(4),
        td:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
      thead {
        th:nth-child(1),
        th:nth-child(2),
        th:nth-child(4),
        th:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
    &.cols-17 {
      tbody {
        td:nth-child(1),
        td:nth-child(2),
        td:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
      thead {
        th:nth-child(1),
        th:nth-child(2),
        th:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
.v-application.theme--dark {
  .tdh1 {
    .card-table {
      &.cols-19 {
        tbody {
          td:nth-child(1),
          td:nth-child(3),
          td:nth-child(5),
          td:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
        thead {
          th:nth-child(1),
          th:nth-child(3),
          th:nth-child(5),
          th:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
      }
      &.cols-18 {
        tbody {
          td:nth-child(1),
          td:nth-child(2),
          td:nth-child(4),
          td:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
        thead {
          th:nth-child(1),
          th:nth-child(2),
          th:nth-child(4),
          th:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
      }
      &.cols-17 {
        tbody {
          td:nth-child(1),
          td:nth-child(2),
          td:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
        thead {
          th:nth-child(1),
          th:nth-child(2),
          th:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
      }
    }
  }
}
</style>
