<template>
  <v-card
    v-if="!loading"
    rounded="lg"
    class="site-analysis-table-section styled-card--default"
  >
    <v-card-title :data-page-navigation="SECTIONS.sw_keys.sectionTitle">
      <!--      SW Keys-->
      {{ SECTIONS.sw_keys.sectionTitle }}
      <force-update-button :section-name="sectionName" />
      <v-spacer />
      <v-autocomplete
        v-if="successfullyUploaded"
        v-model="selectedNicheFilter"
        :items="nicheItems"
        placeholder="Niche"
        hide-details
        dense
        clearable
        filled
        style="max-width: 130px"
      ></v-autocomplete>
    </v-card-title>
    <v-card-subtitle class="d-flex align-center">
      <last-update-info
        :data="data"
        :successfully-uploaded="successfullyUploaded"
      />
      <v-spacer />

      <table-search
        v-if="successfullyUploaded"
        class="mt-2"
        :model-value="search"
        @update:modelValue="search = $event"
        placeholder="Type to search in table"
        style="max-width: 320px"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pa-0">
      <template v-if="successfullyUploaded">
        <v-data-table
          :search="search"
          :items="filteredByNicheTableItems"
          :headers="headers"
          multi-sort
          dense
          :items-per-page="50"
          :footer-props="{ itemsPerPageOptions: [50, 100, 200, 300, 500, -1] }"
          :custom-sort="_$smartCustomTableSort"
        >
          <template #[`item.traffic_share`]="{ value }">
            <span :style="getTextStyles(value)">{{ value }}</span>
          </template>
          <template #[`item.volume`]="{ value }">
            <span :style="getTextStyles(value)">{{ value }}</span>
          </template>
          <template #[`item.position_organic`]="{ value }">
            <span :style="getTextStyles(value)">{{ value }}</span>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <not-collected-placeholder />
      </template>
    </v-card-text>
  </v-card>
  <div v-else class="skeleton loading" style="height: 300px"></div>
</template>

<script>
import TableSorting from "../../../mixins/TableSorting";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import DataTableFilterByNicheMixin from "../../../mixins/components/SiteAnalysis/DataTableFilterByNicheMixin";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";
import TableSearch from "@/components/UI/TableSearch.vue";

export default {
  components: {
    TableSearch,
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
  },
  mixins: [TableSorting, DataTableFilterByNicheMixin, SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.data.data;
    },
  },
  data: () => ({
    search: null,
    headers: [
      {
        text: "keyword",
        title: "keyword",
        value: "keyword",
        class: "text-center",
      },
      {
        text: "traffic_share",
        title: "traffic_share",
        value: "traffic_share",
        class: "text-center",
      },
      {
        text: "volume",
        title: "volume",
        value: "volume",
        class: "text-left",
      },
      {
        text: "pos org",
        title: "pos org",
        value: "position_organic",
        class: "text-left",
      },
    ],
  }),
  methods: {
    getTextStyles(value) {
      if (Number(value) === 0) return "opacity: 0.3;";
      return "";
    },
  },
};
</script>
