import service from "../../../plugins/service";

export const BackgroundTasksApiService = () => {
  const ENDPOINTS = {
    BACKGROUND_TASKS: "/background-task",
  };

  const addBackgroundTask = (formData) => {
    return service.post(ENDPOINTS.BACKGROUND_TASKS, formData);
  };

  const getTasks = () => {
    return service.get(ENDPOINTS.BACKGROUND_TASKS);
  };

  return {
    addBackgroundTask,
    getTasks,
  };
};
