var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticStyle:{"height":"32px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%","font-size":"16px"}},[_c('div',{staticClass:"d-flex"},[_c('overall-label-row-factory',{attrs:{"data":_vm.data}}),_c('v-spacer'),(
          _vm.showAlertWhenNoValue.includes(String(_vm.data.name).toLowerCase()) &&
          !_vm.hasValue(_vm.data.value)
        )?[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-alert")])]:[(_vm.type === _vm.TYPES.PERCENT)?[(_vm.hasValue(_vm.data.value))?[_c('span',{style:({
                color: _vm.getStatusColor(_vm.data, true),
              })},[_vm._v(" "+_vm._s(_vm.data.value)+"% ")])]:[_c('v-icon',{attrs:{"color":"rgba(155,155,155,0.3)"}},[_vm._v("mdi-minus")])]]:(_vm.type === _vm.TYPES.NUMBER)?[(_vm.hasValue(_vm.data.value))?[_c('overall-number-row-factory',{attrs:{"data":_vm.data,"get-status-color":_vm.getStatusColor}})]:[_c('v-icon',{attrs:{"color":"rgba(155,155,155,0.3)"}},[_vm._v("mdi-minus")])]]:(_vm.type === _vm.TYPES.DATE)?[(_vm.hasValue(_vm.data.value, true))?[_c('v-tooltip',{attrs:{"bottom":"","content-class":"pa-0 shadow-e1-bordered"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({attrs:{"outlined":"","label":"","small":""}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.data.value ? _vm.$moment(_vm.data.value).format("YYYY-MM-DD") : "")+" "),(
                      _vm.datesWhoHasColorIndicator.includes(
                        String(_vm.data.name).toLowerCase()
                      )
                    )?_c('v-icon',{attrs:{"small":"","right":"","color":_vm.getStatusColor(_vm.data)}},[_vm._v("mdi-circle")]):_vm._e()],1)]}}],null,false,716730462)},[_c('v-card',{staticClass:"pa-3"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.getDateDiff(_vm.data.value, "days")))]),_vm._v(" days ago ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.getDateDiff(_vm.data.value, "months")))]),_vm._v(" months ago ")])])],1)]:[_c('v-icon',{attrs:{"color":"rgba(155,155,155,0.3)"}},[_vm._v("mdi-minus")])]]:(_vm.type === _vm.TYPES.BUTTON)?[(_vm.hasValue(_vm.data.value, true))?[_c('overall-button-factory',{attrs:{"data":_vm.data.value}})]:[_c('v-icon',{attrs:{"color":"rgba(155,155,155,0.3)"}},[_vm._v("mdi-minus")])]]:[_c('span',[_vm._v(_vm._s(_vm.data.value))])]]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }