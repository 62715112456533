<template>
  <v-tooltip bottom max-width="320">
    <template #activator="{ on }">
      <v-icon v-on="on" style="opacity: 0.5" small
        >mdi-information-outline</v-icon
      >
    </template>
    <template #default>
      <div>Function for developers.</div>
      If this feature is enabled, some dashboards will use "plug data" instead
      of downloading actual data from the server.
    </template>
  </v-tooltip>
</template>

<script>
export default {};
</script>
