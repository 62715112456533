<template>
  <div>
    <img src="https://100k-faces.glitch.me/random-image" alt="" />
  </div>
</template>

<script>
export default {
  name: "AnchorPlanLbHelper"
}
</script>

<style scoped>

</style>