<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title class="d-flex">
      <section-title :title="data.title" :data="data">
        <template #append>
          <v-menu content-class="my-shadow--e2">
            <template #activator="{ on }">
              <v-chip v-on="on" label outlined>
                Details by month
                <v-icon small right> mdi-chevron-down </v-icon>
              </v-chip>
            </template>
            <v-list dense class="rounded-lg border">
              <v-list-item
                v-for="(item, idx) in computedDates"
                :key="idx"
                @click="handleRedirect(item)"
              >
                {{ item.title }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </section-title>
    </v-card-title>
    <v-divider />
    <v-card-text v-if="data.data[0]">
      <apexchart
        type="bar"
        height="350"
        :options="getOptions()"
        :series="data.data[0].series"
      />
    </v-card-text>
    <v-card-text v-else>
      <no-items-placeholder />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["filters"],
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  computed: {
    computedDates() {
      const arr = this.getOptions().xaxis.categories || [];

      return arr.map((dateString) => {
        const startDate = this.$moment(dateString, "YYYY-MM").startOf("month");
        const endDate = this.$moment(dateString, "YYYY-MM").endOf("month");

        return {
          title: startDate.format("YYYY MMMM"),
          dateRange: [
            startDate.format("YYYY-MM-DD"),
            endDate.format("YYYY-MM-DD"),
          ],
        };
      });
    },
  },
  methods: {
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          animations: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "datetime",
          categories: this.data.data[0].categories,
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      };
    },
    handleRedirect(data) {
      console.log(data);
      const routeData = this.$router.resolve({
        name: "gpm.outreach-problems",
        query: {
          performer: this.filters().executive,
          date: data.dateRange.join(","),
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
