<template>
  <v-card rounded="lg" class="shadow-e2-bordered">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <template v-if="!loading && data.length === 0">
        <div style="text-align: center">Press "get data"</div>
      </template>
      <template v-else-if="!loading">
        <v-data-table
          :headers="headers"
          :items="data"
          class="positions-table"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #body="{ items }">
            <tbody>
              <tr
                v-for="(row, index) in items"
                :key="index"
                :class="{
                  'divider-border': row.name.divider === 2,
                  divider: row.name.divider === 1,
                }"
              >
                <td :class="{ bold: row.name.text.includes(',%') }">
                  {{ row.name.text }}
                </td>
                <td
                  v-for="(td, idx) in getItems(row)"
                  :key="idx"
                  class="text-center"
                >
                  {{ td.value }}
                  <sup :style="{ color: getColor(td.comp) }">{{
                    getComp(td.comp)
                  }}</sup>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-skeleton-loader type="table-row-divider@6" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["data", "title", "loading", "headers"],
  methods: {
    getComp(number) {
      return String(number).replaceAll("-", "");
    },
    getColor(number) {
      if (number < 0) return "red";
      if (number > 0) return "green";
      return "transparent";
    },
    getItems(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (key[0] === "$") output.push(items[key]);
      });

      return output;
    },
  },
};
</script>
