<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text v-if="data.data[0]">
      <apexchart
        type="line"
        height="350"
        :options="getOptions()"
        :series="data.data[0].series"
      />
    </v-card-text>
    <v-card-text v-else>
      <no-items-placeholder />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  methods: {
    formatNumber(number) {
      if (isNaN(number)) return 0;

      const suffixes = ["", "k", "m", "b", "t"];

      const sign = Math.sign(number);
      const absNumber = Math.abs(number);

      const suffixIndex = Math.floor(Math.log10(absNumber) / 3);

      const formattedNumber = (absNumber / Math.pow(1000, suffixIndex)).toFixed(
        1
      );

      if (sign === 0) return 0;

      return sign * formattedNumber + suffixes[suffixIndex];
    },
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          height: 350,
          type: "line",
          animations: {
            enabled: false,
          },
        },
        stroke: {
          width: [0, 4],
          curve: "straight",
          dashArray: [0, 8],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
        },
        labels: this.data.data[0].categories,
        xaxis: {
          type: "datetime",
        },
        yaxis: [
          {
            title: {},
            labels: {
              formatter: (number) => {
                return this.formatNumber(number);
              },
            },
          },
          // {
          //   opposite: true,
          //   title: {
          //     text: "",
          //   },
          //   labels: {
          //     formatter: (val) => {
          //       if (val > 10000)
          //         return Number(val / 1000).toFixed(2) + " K " + "$";
          //       return val + " $";
          //     },
          //   },
          // },
        ],
      };
    },
  },
};
</script>
