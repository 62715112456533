<template>
  <v-row dense>
    <v-col cols="4" v-for="item in items" :key="item.id">
      <v-alert :type="item.type" outlined class="mb-0">
        <div class="d-flex align-center">
          <b>
            {{ item.value }}
          </b>
          <div class="ml-2">
            {{ item.name }}
          </div>
        </div>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
