<template>
  <div>
    <v-container fluid class="user-activity">
      <v-row dense>
        <v-col cols="12">
          <h2>User Activity</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card elevation="0">
            <v-card-title class="pa-0">
              <v-row dense>
                <v-col>
                  <smart-date-filter
                    :model-value="filter.dateRange"
                    @update:modelValue="handleDateChange"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    dense
                    outlined
                    v-model="filter.userID"
                    :items="user_items"
                    label="User"
                    hide-details
                    multiple
                    clearable
                    @change="_$handleFilterChange($event, 'userID')"
                    ><template #selection="{ item, index }">
                      <span v-if="index === 0">{{ item.text }}</span>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.userID.length - 1 }} more)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="filter.site"
                    color="gray"
                    label="Site"
                    outlined
                    :items="_$getProjects"
                    item-value="id"
                    item-text="name"
                    dense
                    multiple
                    clearable
                    hide-details
                    @change="_$handleFilterChange($event, 'site')"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="filter.interval"
                    dense
                    hide-details
                    outlined
                    label="Interval"
                    :items="intervalItems"
                  ></v-autocomplete>
                </v-col>
                <v-col></v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    @click="fetchData"
                    block
                    color="primary"
                    :loading="loadings.tables"
                  >
                    Get Data
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense class="user-activity__tables">
        <loader :show="loadings.tables" />
        <v-col cols="12">
          <v-row dense v-if="gpm_mistakes.length > 0">
            <v-col>
              <v-card :loading="loading">
                <v-card-title> GPM Mistakes </v-card-title>
                <v-card-text>
                  <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="gpm_mistakes"
                    class="elevation-1"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td v-for="key in Object.keys(item)" :key="key">
                            <template v-if="key === 'name' || key === 'all'">
                              {{ item[key] }}
                            </template>
                            <template v-else>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn text v-on="on">
                                    {{ item[key][0] }}
                                  </v-btn>
                                </template>
                                <span>
                                  <div
                                    v-for="(errorKey, index) in Object.keys(
                                      item[key][1]
                                    )"
                                    :key="index"
                                  >
                                    {{ errorKey }} —
                                    {{ item[key][1][errorKey] }}
                                  </div>
                                </span>
                              </v-tooltip>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-if="links_problem_items.length > 0">
            <v-col>
              <v-card :loading="loading">
                <v-card-title> Anchors plan (mistakes) </v-card-title>
                <v-card-text>
                  <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="links_problem_items"
                    class="elevation-1"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template
                      v-for="h in headers"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <span :key="h" :class="+header.class">{{
                        header.text
                      }}</span>
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item">
                          <td v-for="h in headers" :key="h">
                            <span v-if="item['p' + h.value.replace('d', '')]">
                              <span
                                @click="
                                  openProblems(
                                    item['p' + h.value.replace('d', '')]
                                  )
                                "
                                >{{ item[h.value] }}</span
                              >
                            </span>
                            <span v-else>{{ item[h.value] }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <section>
            <h2 v-if="haveContentData">Content</h2>
            <v-row dense v-if="cb_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> ContentBall </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="cb_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="site_workplace_url.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Site Workplace Pages</v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="site_workplace_url"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template #body="{ items }">
                        <tbody>
                          <tr v-for="(row, index) in items" :key="index">
                            <td class="text-start">{{ row.name }}</td>
                            <td class="text-center">{{ row.all }}</td>
                            <td
                              v-for="(td, i) in getDateItems(row)"
                              :key="i"
                              class="text-center"
                            >
                              {{ td.value }}<sup>{{ td.degree }}</sup>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="site_workplace_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Site Workplace </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="site_workplace_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template #body="{ items }">
                        <tbody>
                          <tr v-for="(row, index) in items" :key="index">
                            <td class="text-start">{{ row.name }}</td>
                            <td class="text-center">{{ row.all }}</td>
                            <td
                              v-for="(td, i) in getDateItems(row)"
                              :key="i"
                              class="text-center"
                            >
                              {{ td.value }}<sup>{{ td.degree }}</sup>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="sl_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> SpiderLink </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="sl_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template
                        v-for="h in headers"
                        v-slot:[`header.${h.value}`]="{ header }"
                      >
                        <span :key="h" :class="+header.class">{{
                          header.text
                        }}</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <section>
            <h2 v-if="haveLinksData">Links</h2>
            <v-row dense v-if="gpm_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> GPM </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="[...headers, { value: 'hours', text: 'Hours' }]"
                      :items="gpm_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template #[`item.hours`]="{ item }">
                        <gpm-table-hours-cell :item="item" />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="ltt_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title>
                    LTT
                    <v-spacer />
                    <v-checkbox
                      v-model="lttShowGradient"
                      hide-details
                      dense
                      class="ma-0 mr-2"
                      label="Gradient"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="lttShowPercentHighlight"
                      hide-details
                      dense
                      class="ma-0"
                      label="Highlight percent"
                    ></v-checkbox>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="ltt_items"
                      hide-default-footer
                      class="relative-tr user-activity__ltt-table"
                      :items-per-page="-1"
                    >
                      <template
                        v-for="h in headers"
                        #[`item.${h.value}`]="{ item }"
                      >
                        <div
                          :key="h.value"
                          :style="`background-color:${
                            lttShowGradient
                              ? bggc_getBgColor(
                                  item[h.value],
                                  h.value,
                                  ltt_items
                                )
                              : ''
                          };width: 100%; height:100%;left:0;top:0; position: absolute;`"
                          class="px-4 d-flex justify-center align-center"
                        >
                          <template v-if="h.value === 'name'">
                            {{ item[h.value] }}
                          </template>
                          <template v-else>
                            <span
                              v-if="
                                ['0', '0/0'].includes(String(item[h.value]))
                              "
                              style="opacity: 0.5"
                            >
                              {{ item[h.value] }}
                            </span>
                            <template v-else-if="item.name.includes('%')">
                              <span
                                :key="h.value"
                                :class="`colored-td bold colored-td--contrast colored-td--inline ${
                                  lttShowPercentHighlight
                                    ? perc2color(item[h.value])
                                    : ''
                                }`"
                              >
                                {{ item[h.value] ? item[h.value] + "%" : "" }}
                              </span>
                            </template>
                            <template v-else> {{ item[h.value] }} </template>
                          </template>
                        </div>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="check_link_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Check link </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="check_link_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="anchor_plan_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Anchors plan </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="anchor_plan_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="anchor_plan_x_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Anchors plan (Х links) </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="anchor_plan_x_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="no_answer_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title>
                    GP Emails Conversion
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-icon v-on="on" color="gray" class="ml-2"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <div>
                        <div class="gp-em-conv-tooltip">
                          <div>ALL</div>
                          <div>
                            <span>NEW</span>
                            <span>LTT</span>
                            <span>RESERVED</span>
                          </div>
                        </div>
                      </div>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="no_answer_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template #body="{ items }">
                        <tbody>
                          <tr v-for="(row, index) in items" :key="index">
                            <td>{{ row.name }}</td>
                            <user-activity-smart-td :data="row.all" />
                            <user-activity-smart-td
                              v-for="(td, idx) in getUsers(row)"
                              :key="idx"
                              :data="td"
                            >
                            </user-activity-smart-td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="tables?.site_analysis_feedback?.length > 0">
              <v-col>
                <v-card class="styled-card--default">
                  <v-card-title> Site analysis feedback </v-card-title>
                  <v-divider />
                  <v-card-text class="px-0">
                    <v-data-table
                      :items="tables.site_analysis_feedback"
                      :headers="headers"
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="tables?.site_analysis_likes?.length > 0">
              <v-col>
                <v-card class="styled-card--default">
                  <v-card-title> Site analysis likes </v-card-title>
                  <v-divider />
                  <v-card-text class="px-0">
                    <v-data-table
                      :items="tables.site_analysis_likes"
                      :headers="headers"
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <section>
            <h2 v-if="haveOthersData">Others</h2>
            <v-row dense v-if="dct_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> DCT </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="dct_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <v-row dense v-if="project_200_items.length > 0">
            <v-col>
              <v-card :loading="loading">
                <v-card-title> Project 200 </v-card-title>
                <v-card-text>
                  <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="project_200_items"
                    class="elevation-1"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template
                      v-for="h in headers"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <span :key="h" :class="+header.class">{{
                        header.text
                      }}</span>
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item">
                          <td
                            v-for="(h, idx) in headers"
                            :key="h"
                            :class="`${
                              ![0, 1].includes(idx) ? 'text-center' : ''
                            }`"
                          >
                            <span v-if="item['p' + h.value.replace('d', '')]">
                              <span
                                @click="
                                  openProblems(
                                    item['p' + h.value.replace('d', '')]
                                  )
                                "
                                >{{ item[h.value] }}</span
                              >
                            </span>
                            <span v-else>{{ item[h.value] }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog_problems" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Mistakes</span>
          </v-card-title>
          <v-card-text>
            <p v-for="problem in problems" :key="problem">{{ problem }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_problems = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import Loader from "@/components/Main/Loader";
import UserActivitySmartTd from "@/components/UserActivityStatic/UI/UserActivitySmartTd";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../mixins/Location";
import GetTableColBgGradientColorMixin from "../../mixins/getTableColBgGradientColorMixin";
import service from "@/plugins/service";
import GpmTableHoursCell from "@/components/UserActivity/components/GpmTableHoursCell.vue";

const dateFormat = require("dateformat");

export default {
  name: "Index",
  components: {
    GpmTableHoursCell,
    SmartDateFilter,
    UserActivitySmartTd,
    Loader,
  },
  mixins: [FiltersHandling, Project, Location, GetTableColBgGradientColorMixin],
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "User activity",
          disabled: true,
        },
      ],
      menu_filter: false,
      dialog_problems: false,
      problems: undefined,
      loading: false,
      loadings: {
        tables: false,
      },
      lttShowGradient: false,
      lttShowPercentHighlight: true,
      tables: {},
      headers: [],
      no_answer_items: [],
      gpm_items: [],
      gpm_mistakes: [],
      site_workplace_items: [],
      site_workplace_url: [],
      cb_items: [],
      sl_items: [],
      dct_items: [],
      ltt_items: [],
      anchor_plan_items: [],
      anchor_plan_x_items: [],
      links_problem_items: [],
      project_200_items: [],
      check_link_items: [],
      user_items: [],
      date_modal: false,
      intervalItems: ["week", "month", "quarter", "half_year", "year", "day"],
      filter: {
        site: null,
        interval: "day",
        dateRange: [
          dateFormat(
            new Date().setDate(new Date().getDate() - 7),
            "yyyy-mm-dd"
          ),
          dateFormat(new Date(), "yyyy-mm-dd"),
        ],
        userID: undefined,
      },
    };
  },
  computed: {
    haveLinksData() {
      return (
        this.gpm_items.length > 0 ||
        this.ltt_items.length > 0 ||
        this.check_link_items.length > 0 ||
        this.anchor_plan_items.length > 0 ||
        this.anchor_plan_x_items.length > 0 ||
        this.no_answer_items.length > 0
      );
    },
    haveOthersData() {
      return this.dct_items.length > 0;
    },
    haveContentData() {
      return (
        this.cb_items.length > 0 ||
        this.site_workplace_items.length > 0 ||
        this.site_workplace_url.length > 0 ||
        this.sl_items.length > 0
      );
    },
  },
  methods: {
    perc2color(perc) {
      if (perc === null || perc === undefined) return "";

      const val = parseInt(perc);

      if (val >= 0 && val <= 20) return "red";
      if (val >= 21 && val <= 60) return "orange";
      if (val >= 61) return "green";
      return "";
    },
    handleDateChange(event) {
      this.filter.dateRange = event;
      this._$handleFilterChange(event, "dateRange");
    },
    getUsers(data) {
      const output = [];

      Object.keys(data).forEach((key) => {
        if (key !== "name" && key !== "all") output.push(data[key]);
      });

      return output;
    },
    getDateItems(items = {}) {
      try {
        const output = [];

        Object.keys(items).forEach((key) => {
          if (key !== "name" && key !== "all") {
            let value, degree;

            if (items[key] !== null && items[key] !== undefined) {
              if (typeof items[key] === "number") {
                items[key] = "" + items[key];
              }
              const splitted = items[key].split(" ");
              value = splitted[0];
              degree = splitted[1]
                ? splitted[1].replaceAll("(", "").replaceAll(")", "")
                : "";
            }

            output.push({
              value,
              degree,
            });
          }
        });
        return output;
      } catch (e) {
        console.log("error : ", items);
        return [];
      }
    },
    openProblems(item) {
      this.problems = item.split(",");
      this.dialog_problems = true;
    },
    fetchUsers() {
      let self = this;

      this.loading = true;

      let url = `${self.$store.state.server_url}/user-activity/get-users`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.user_items = r.data.items;
        })
        .finally(() => (self.loading = false));
    },
    processUserActivityData(data) {
      this.no_answer_items = data.data.no_answer;
      this.gpm_items = data.data.gpm;
      this.gpm_mistakes = data.data.gpm_mistakes;
      this.site_workplace_items = data.data.site_workplace;
      this.site_workplace_url = data.data.site_workplace_url;
      this.cb_items = data.data.cb;
      this.dct_items = data.data.dct;
      this.sl_items = data.data.sl;
      this.ltt_items = data.data.ltt;
      this.anchor_plan_items = data.data.ap;
      this.anchor_plan_x_items = data.data.ap_x;
      this.check_link_items = data.data.cl;
      this.links_problem_items = data.data.links_problem;
      this.project_200_items = data.data.project200;
      this.tables = data.data;
      this.headers = this.fixHeaders(data.data.headers);
    },
    async fetchData() {
      try {
        const url = "/user-activity/index";
        const config = {
          params: {
            userID: this.filter.userID,
            dates: this.filter.dateRange,
            site: this.filter.site,
            interval: this.filter.interval,
            is_mock: localStorage.getItem("is_mock") === "1" ? 1 : 0,
          },
        };

        this.loadings.tables = true;

        const response = await service.get(url, true, config);

        if (response) {
          this.processUserActivityData(response.data);
        }
      } catch (e) {
        console.error("Error while loading user activity data.", e);
      } finally {
        this.loadings.tables = false;
      }
    },
    fixHeaders(headers) {
      console.log(headers);
      const NAME = 0;

      headers[NAME].width = "150px";

      headers.forEach((item) => {
        if (item.value !== "name") {
          item.align = "center";
          item.sortable = false;
        }
      });

      return headers;
    },
  },
  created() {
    const haveParams = this._$collectParams(
      ["dateRange", "site", "userID"],
      "filter"
    );

    this.fetchUsers();

    if (haveParams) {
      this.fetchData();
    }
  },
};
</script>

<style lang="scss">
.weekend {
  font-weight: 600;
  color: rgba(255, 0, 0, 0.49) !important;
}
.v-application.theme--dark {
  .user-activity {
    .v-data-table {
      tbody {
        tr {
          td {
            &:nth-child(1) {
              background-color: #1e1e1e !important;
            }
          }
        }
      }
    }
  }
}
.user-activity {
  &__ltt-table {
    table {
      td {
        min-width: 140px;
      }
    }
  }
  &__tables {
    min-height: 250px;
    position: relative;
    h2 {
      text-align: left;
      font-size: 2em;
    }
    .v-card {
      box-shadow: none !important;
      &__title {
        justify-content: flex-start;
        opacity: 0.8;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      &__text {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
  .v-data-table {
    tbody {
      tr {
        td {
          &:nth-child(1) {
            position: sticky;
            left: 0;
            background-color: #fff !important;
            z-index: 1;
          }
          &:nth-child(1),
          &:nth-child(2) {
            border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
          }
        }
      }
    }
  }
}
</style>
