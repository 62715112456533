<template>
  <div>
    <v-card
        v-for="item in data"
        :key="item.id"
        elevation="2"
        class="mb-5"
    >
      <v-card-title>
        Score: {{ item.score }}
      </v-card-title>
      <v-card-text>
        <p><strong>Default keys:</strong> {{ item.default_keys }}</p>
        <p><strong>Favorite keys:</strong> {{ item.favorite_keys }}</p>
        {{ item.text }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EssayHubShow",
  data() {
    return {
      data: []
    }
  },
  created() {
    this.fetch_data()
  },
  methods: {
    fetch_data() {
      let self = this;

      self.loading = true

      let url = `${self.$store.state.server_url}/essay-hub/${self.$route.params.id}`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.get(url, config)
          .then(function (response) {
            if (response.data.success) {
              self.data = response.data.data
            } else {
              alert('Error')
            }
          })
    },
  }
}
</script>

<style scoped>

</style>