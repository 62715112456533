<template>
  <v-dialog
    v-model="dialog"
    width="440"
    content-class="my-shadow--e4"
    scrollable
  >
    <v-card rounded="lg" outlined>
      <v-card-title class="pa-4 text-body-2">
        Add new tag
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="form" class="pa-4 pt-5">
        <v-row>
          <v-col cols="12">
            <div class="mb-1 text-body-2 opacity-70">Tag name</div>
            <v-text-field
              v-model="form.name"
              placeholder="Enter tag name"
              outlined
              hide-details
              clearable
            >
            </v-text-field>
            <div class="mt-4">
              <label for="isActiveAdd" style="cursor: pointer">
                <v-card
                  flat
                  outlined
                  rounded="lg"
                  class="pa-4"
                  :color="form.active ? 'rgba(0,50,230,0.05)' : ''"
                >
                  <v-row>
                    <v-col cols="auto">
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0"
                        id="isActiveAdd"
                        v-model="form.active"
                        @click.prevent.stop
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="fill" class="d-flex align-center">
                      Active
                    </v-col>
                  </v-row>
                </v-card>
              </label>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="mb-1 text-body-2 opacity-70">Select color</div>
            <v-card class="d-flex rounded-lg" flat>
              <v-color-picker v-model="form.color" mode="hexa" />
            </v-card>
          </v-col>
          <v-col cols="12">
            <div class="mb-1 text-body-2 opacity-70">Expected result</div>
            <v-card
              flat
              class="rounded-lg d-flex flex-wrap align-center"
              style="gap: 0.25rem"
            >
              <template v-if="!form.name">
                Enter tag name to see the results.
              </template>
              <template v-else>
                <v-chip :color="computedColor" label>
                  {{ form.name }}
                </v-chip>
                <v-chip :color="computedColor" label outlined>
                  {{ form.name }}
                </v-chip>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          @click="dialog = false"
          large
          text
          class="px-6 text-normal"
          :disabled="loading"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="handleEdit"
          color="success"
          :loading="loading"
          large
          class="px-6 text-normal"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import { GpmTagsApiService } from "../services/GpmTagsApiService";

export default {
  mixins: [Dialog],

  data: () => ({
    gpmTagsApiService: null,
    loading: false,
    form: {
      active: 0,
      name: "",
      color: {},
    },
  }),

  mounted() {
    this.gpmTagsApiService = GpmTagsApiService();
  },

  computed: {
    computedColor() {
      if (
        this.form.color &&
        typeof this.form.color === "object" &&
        "hexa" in this.form.color
      )
        return this.form.color.hexa;

      if (typeof this.form.color === "string") return this.form.color;

      return "";
    },
  },

  methods: {
    async handleEdit() {
      try {
        const fixedFormData = {
          ...this.form,
          color: this.computedColor,
        };

        this.loading = true;

        const response = await this.gpmTagsApiService.createTag(fixedFormData);

        this.dialog = false;

        this.$emit("success", response.data);
        this.form = null;
      } catch (e) {
        console.error("Error while updating tag.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
