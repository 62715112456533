<template>
  <v-card v-if="data" class="styled-card--default">
    <v-card-title>
      Links for TA
      <v-spacer />
      <v-chip
        label
        class="flat-chip flat-chip--primary"
        @click="handleAddLinkField(data.links_for_ta)"
      >
        Add link <v-icon small right>mdi-plus</v-icon>
      </v-chip>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row
        dense
        v-if="Array.isArray(data.links_for_ta) && data.links_for_ta.length > 0"
      >
        <v-col v-for="(field, idx) in data.links_for_ta" :key="idx" cols="12">
          <v-row dense>
            <v-col style="flex: 1">
              <v-text-field
                v-model="field.link"
                hide-details
                dense
                placeholder="Enter link for TA"
                filled
              >
              </v-text-field>
            </v-col>
            <v-col style="flex: 1">
              <single-date-picker
                :model-value="field.date"
                @update:modelValue="field.date = $event"
                filled
                placeholder="Select day"
                hide-label
              />
            </v-col>
            <v-col
              style="flex: 0; flex-basis: 46px"
              class="d-flex justify-center align-center"
            >
              <v-btn
                icon
                @click="handleRemoveLinkField(data.links_for_ta, field.id)"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="d-flex justify-center align-center">
          <span style="opacity: 0.5">
            You do not have any added links for TA yet.
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SingleDatePicker from "@/components/UI/SingleDatePicker.vue";
import { uid } from "@/utils/functions";

export default {
  components: { SingleDatePicker },
  props: {
    data: {},
  },
  methods: {
    handleRemoveLinkField(links, id) {
      this.data.links_for_ta = this.data.links_for_ta.filter(
        (link) => link.id !== id,
      );
    },
    handleAddLinkField() {
      if (!this.data.links_for_ta) {
        this.data.links_for_ta = [];
      }

      this.data.links_for_ta.push({
        link: null,
        date: null,
        id: uid(),
      });
    },
  },
};
</script>
