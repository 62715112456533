<template>
  <div class="achievement-card">
    <div class="achievement-card__prepend-icon">
      <medal-vector />
    </div>
    <div class="achievement-card__content">
      <div class="achievement-card__title">Achievement unlocked!</div>
      <div class="achievement-card__text">
        Ensure that there are no overdue tasks today
      </div>
    </div>
    <div class="achievement-card__shine-animation"></div>
  </div>
</template>

<script>
import MedalVector from "@/components/UI/Icons/MedalVector.vue";

export default {
  components: { MedalVector },
};
</script>
