<template>
  <v-row v-if="!loading" dense>
    <v-col v-for="(row, idx) in data" :key="row.total_name + idx" cols="12">
      <v-row dense>
        <v-col class="d-flex">
          <v-card
            class="shadow-e1-bordered d-flex flex-column"
            width="100%"
            rounded="lg"
          >
            <v-card-title
              class="d-flex justify-center"
              :data-page-navigation="row.total_name"
            >
              {{ row.total_name }}
            </v-card-title>
            <v-card-text
              v-if="
                !bannedTotalScores.includes(
                  String(row.total_name).toLowerCase()
                )
              "
              class="d-flex justify-center align-center"
              style="flex-grow: 1"
            >
              <v-progress-circular
                v-if="exist(row.total_value)"
                :rotate="-90"
                :size="120"
                :width="15"
                :value="row.total_value"
                :color="getColorOfPercent(row.total_value)"
              >
                {{ row.total_value }}%
              </v-progress-circular>
              <div v-else>
                <v-icon class="mr-2">mdi-calculator-variant</v-icon>
                Not calculated yet.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <template v-for="card in row.items">
          <v-col v-if="!card.placeholder" :key="card.id" class="d-flex">
            <v-card class="shadow-e1-bordered" style="width: 100%" rounded="lg">
              <v-card-text>
                <v-row dense>
                  <template v-for="progress in card.items">
                    <overall-statuses-row-factory
                      :type="progress.type"
                      :data="progress"
                      :key="progress.name"
                    />
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-else :key="card.id"></v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else dense>
    <v-col cols="12">
      <v-row dense>
        <v-col v-for="i in [0, 1, 2, 3]" :key="i">
          <div
            style="height: 400px; border-radius: 8px"
            class="skeleton loading"
          ></div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import OverallStatusesRowFactory from "./OverallStatusesRowFactory.vue";
import { getColorOfPercent } from "../../../utils/functions";

export default {
  components: { OverallStatusesRowFactory },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      return: () => [],
    },
  },
  data: () => ({
    bannedTotalScores: ["behavioral", "possibility"],
  }),
  methods: {
    getColorOfPercent,
    exist(value) {
      return value !== null && value !== "";
    },
  },
};
</script>
