<template>
  <div>
    <template v-if="component?.type === SECTION_TYPES.CARDS">
      <action-section-info-card-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.TABLE">
      <actions-section-table-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.CHART_TABLE">
      <actions-section-chart-table-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.SIGNATURE_TABLE">
      <actions-section-signature-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.APARSER_TASK_TABLE">
      <actions-section-aparser-task-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.TOP_100_TABLE">
      <actions-section-top-100-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.GRAPHS">
      <actions-section-graphs-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.HORIZONTAL_COLUMN">
      <actions-section-horizontal-columns-component />
    </template>
    <template v-else-if="component?.type === SECTION_TYPES.PROGRESS">
      <actions-section-progress-component />
    </template>
    <template v-else>
      No component for type <u>{{ component?.type }}</u>
    </template>
  </div>
</template>

<script>
import ActionSectionInfoCardComponent from "./ActionSectionInfoCardComponent.vue";
import ActionsSectionTableComponent from "./ActionsSectionTableComponent.vue";
import ActionsSectionChartTableComponent from "./ActionsSectionChartTableComponent.vue";
import ActionsSectionSignatureComponent from "./ActionsSectionSignatureComponent.vue";
import ActionsSectionAparserTaskComponent from "./ActionsSectionAparserTaskComponent.vue";
import ActionsSectionTop100Component from "./ActionsSectionTop100Component.vue";
import ActionsSectionGraphsComponent from "./ActionsSectionGraphsComponent.vue";
import ActionsSectionHorizontalColumnsComponent from "./ActionsSectionHorizontalColumnsComponent.vue";
import ActionsSectionProgressComponent from "./ActionsSectionProgressComponent.vue";

export default {
  components: {
    ActionsSectionProgressComponent,
    ActionsSectionHorizontalColumnsComponent,
    ActionsSectionGraphsComponent,
    ActionsSectionAparserTaskComponent,
    ActionsSectionSignatureComponent,
    ActionsSectionChartTableComponent,
    ActionsSectionTableComponent,
    ActionSectionInfoCardComponent,
    ActionsSectionTop100Component,
  },
  props: {
    component: {
      type: Object,
      default: () => ({}),
    },
  },

  provide() {
    return {
      componentData: () => this.component,
    };
  },

  data: () => ({
    SECTION_TYPES: {
      CARDS: "cards",
      TABLE: "table",
      CHART_TABLE: "chart_table",
      SIGNATURE_TABLE: "signature_table",
      APARSER_TASK_TABLE: "aparser_task_table",
      TOP_100_TABLE: "top_100_table",
      GRAPHS: "graphs",
      HORIZONTAL_COLUMN: "horizontal_column",
      PROGRESS: "progress",
    },
  }),
};
</script>
