<template>
  <td style="white-space: nowrap">
    <v-chip :color="getBgColor">{{ data.price.lower }}</v-chip>
    <sup v-if="haveLowerPrice" :style="{ color: getPriceColor }">
      {{ getTextContent }}
    </sup>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getTextContent() {
      return this.data.price.text.replaceAll(" ", "");
    },
    haveLowerPrice() {
      const prevPrice = parseInt(this.data.price.lower);
      const currPrice = parseInt(this.data.price.text);
      if (prevPrice < currPrice) {
        return true;
      }
      return false;
    },
    getPriceColor() {
      if (!this.data.price.approve) {
        return "#bd9b35";
      }
      return "";
    },
    getBgColor() {
      if (parseInt(this.data.price.lower) > 400) return "error";
      return "transparent";
    },
  },
  methods: {},
};
</script>
