<template>
  <td class="text-center">
    <v-progress-circular
      :value="value"
      size="50"
      :color="getColor"
      style="font-weight: 600; font-size: 18px"
    >
      {{ value }}
    </v-progress-circular>
  </td>
</template>

<script>
import {URL_OVERVIEW_COLORS} from "@/utils/defaultData";

export default {
  props: ["value"],
  computed: {
    getColor() {
      const { green, yellow, red } = URL_OVERVIEW_COLORS;
      if (this.value === 100) {
        return green;
      }
      if (this.value >= 70 && this.value <= 99) {
        return yellow;
      }

      return red;
    },
  },
};
</script>
