<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Type
                </th>
                <th class="text-center">
                  Planned
                </th>
                <th class="text-center">
                  Collect
                </th>
                <th class="text-center">
                  Progress
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in planned_collect"
                  :key="item.name"
              >
                <td>{{ item.type }}</td>
                <td class="text-center">{{ item.planned }}</td>
                <td class="text-center">{{ item.collect }}</td>
                <td class="text-center"><v-progress-circular size="20" :value="item.collect * 100 / item.planned"></v-progress-circular></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SpiderLinkIndex",
  data() {
    return {
      loading: false,
      planned_collect: [
        {type: 'Auto Import Backlinks from Ahrefs', planned: 10, collect: 5},
      ]
    }
  },
  methods: {
    fetchData() {
      let self = this;

      self.loading = true

      let url = `${self.$store.state.server_url}/status/spiderlink`;

      let config = {
        params: {

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.get(url, config)
          .then(function (response) {
            self.items = response.data.items
            self.loading = false
          })
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>