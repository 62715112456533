<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e4" max-width="675px">
    <v-card class="rounded-lg border" flat>
      <v-card-title class="pa-4 text-body-2">
        Details
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <table-search
          :model-value="search"
          @update:modelValue="search = $event"
          placeholder="Search by phrase"
        />
      </v-card-text>
      <v-card-text>
        <v-card class="styled-card--primary-header">
          <v-card-title>
            <span class="primary--text" style="line-height: 100%"
              >Positive</span
            >
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="getPositiveItems"
              :search="search"
              :sort-by="['p']"
              :sort-desc="[true]"
              dense
              hide-default-footer
              :items-per-page="-1"
            ></v-data-table>
          </v-card-text>
        </v-card>
        <v-card class="styled-card--error mt-4">
          <v-card-title>
            <span class="error--text" style="line-height: 100%"> Negative</span>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="getNegativeItems"
              :search="search"
              :sort-by="['p']"
              :sort-desc="[true]"
              dense
              hide-default-footer
              :items-per-page="-1"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TableSearch from "@/components/UI/TableSearch.vue";
import Dialog from "@/mixins/Dialog";
import { DETAIL_TABLE_HEADERS } from "@/utils/defaultData";

export default {
  props: {
    items: Array,
  },
  components: { TableSearch },
  mixins: [Dialog],
  data: () => ({
    headers: DETAIL_TABLE_HEADERS,
    search: null,
  }),
  computed: {
    getPositiveItems() {
      return this.items.filter((v) => v.p >= 0);
    },
    getNegativeItems() {
      return this.items.filter((v) => v.p <= 0);
    },
  },
};
</script>
