<template>
  <p class="week-report__day-chip">
    <span :style="{ background: getColor }">
      {{ value === "00:00" ? "-" : getTime }}
    </span>
  </p>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: String,
      default: "no time",
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    getTime() {
      const timeString = this.value ? this.value : "";
      const [hours, minutes] = timeString.split(":");

      if (parseInt(hours, 10) === 0) {
        return `${minutes}m`;
      }

      const formattedHours = parseInt(hours, 10).toString();
      return `${formattedHours}h ${minutes}m`;
    },
    getColor() {
      const color = this.data.day_color && this.data.day_color[this.name];
      if (color) {
        return color;
      }
      return "inherit";
    },
  },
};
</script>
