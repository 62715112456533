<template>
  <td>
    <div class="ltt-deleted-links__price-table">
      <div v-for="item in content" :key="item.id" class="price-table__col">
        <div class="price-table__row">
          {{ item.name }}
        </div>
        <div class="price-table__row">
          <template v-if="item.name === 'price'">
            {{ Number(item.value).toFixed(0) }}
          </template>
          <template v-else>
            {{ item.value }}
          </template>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
import {uid} from "@/utils/functions";

export default {
  props: ["value"],
  data: () => ({
    content: [],
  }),
  created() {
    if (!this.value || this.value === "") {
      return (this.content = []);
    }

    const value = JSON.parse(this.value);

    const keys = Object.keys(value);
    let output = [];

    keys.forEach((key) => {
      if (!value[key]) {
        return;
      }
      if (key === "lb_work_price" || key === "currency_lwp") {
        return;
      }
      output.push({ name: key, value: value[key], id: uid() });
    });

    this.content = output;
  },
};
</script>
