<template>
  <div
    :style="{ color: getColor, fontWeight: 600, cursor: 'pointer' }"
    class="clicked"
  >
    <a
      :href="`/contentball-analytic?url=${data?.url}`"
      target="_blank"
      class="reset-link-styles"
    >
      {{ value }}
    </a>
  </div>
</template>

<script>
import { URL_OVERVIEW_COLORS } from "@/utils/defaultData";
import QueryBuilder from "@/mixins/QueryBuilder";

export default {
  props: ["value", "data", "needTop"],
  mixins: [QueryBuilder],
  computed: {
    getColor() {
      const { green, yellow, red } = URL_OVERVIEW_COLORS;
      if (this.value >= 80) {
        return green;
      }
      if (this.value >= 60 && this.value <= 79) {
        return yellow;
      }
      if (this.value < 60) {
        return red;
      }

      return "transparent";
    },
  },
  methods: {},
};
</script>
