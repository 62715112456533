<template>
  <apexcharts
    :series="computedSeries"
    :options="computedOptions"
    height="350"
  />
</template>

<script>
import apexcharts from "vue-apexcharts";
export default {
  components: { apexcharts },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    computedSeries() {
      return this.data?.series || [];
    },
    computedOptions() {
      return {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          // bar: {
          //   columnWidth: "100%",
          //   endingShape: "rounded",
          // },
        },
        grid: {
          padding: {
            top: 0,
            right: 80,
            bottom: 0,
            left: 80,
          },
        },
        xaxis: {
          categories: this.data?.categories || [],
        },
        yaxis: {
          title: {
            text: "Values",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
        },
      };
    },
  },
};
</script>
