<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1200px"
    content-class="remove-dialog-shadow"
  >
    <v-card class="shadow-e1-bordered" rounded="lg">
      <v-card-title> Anchor plan </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          class="relative-tr overflow-hidden"
        >
          <template #body="{ items }">
            <tbody>
              <tr
                v-for="(item, idx) in items"
                :key="idx"
                :style="'background-color: ' + item.main_color"
              >
                <td>{{ item.url }}</td>
                <td>{{ item.anchor }}</td>
                <td>
                  <a
                    :href="'/gpm/view-entity/' + item.entity_id"
                    target="_blank"
                    >{{ item.donor }}</a
                  >
                </td>
                <td>{{ item.status_name }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-spacer />
        <v-btn width="180" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";

export default {
  mixins: [Dialog],
  props: {
    items: {},
  },
  data: () => ({
    headers: [
      { text: "Url", value: "url" },
      { text: "Anchor", value: "anchor" },
      { text: "Donor", value: "donor" },
      { text: "Status", value: "status_name" },
    ],
  }),
};
</script>
