<template>
  <div :class="`colored-td ${getColor}`" style="white-space: nowrap">
    {{ value && value !== null ? $moment(value).format("YYYY-MM-DD") : value }}
  </div>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    getColor() {
      const currentDate = new Date();
      const monthsDiff = this.$moment(currentDate).diff(
        this.$moment(this.value),
        "months"
      );
      if (isNaN(monthsDiff)) {
        return "transparent";
      }
      if (monthsDiff <= 12) {
        return "green";
      }
      if (monthsDiff > 12 && monthsDiff <= 24) {
        return "lightgreen";
      }
      if (monthsDiff > 24 && monthsDiff <= 48) {
        return "yellow";
      }
      return "transparent";
    },
  },
};
</script>
