<template>
  <div>
    <section v-for="section in items" :key="section.id">
      <section-name>
        {{ section.sectionName }}
      </section-name>
      <v-row v-if="section.cards.length > 0">
        <v-col v-for="card in section.cards" :key="card.id">
          <info-card :data="card" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-for="graph in section.graphs"
          :key="graph.id"
          lg="6"
          md="12"
          sm="12"
          xs="12"
          cols="12"
        >
          <time-chart
            :series="graph.series"
            :title="graph.name"
            :categories="graph.categories"
            :footer-title="graph.legend"
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import SectionName from "@/components/DashboardDev/SectionName";
import TimeChart from "@/components/DashboardDev/TimeChart";
import InfoCard from "@/components/DashboardDev/InfoCard";

export default {
  components: { InfoCard, TimeChart, SectionName },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
