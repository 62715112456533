<template>
  <div class="serp-dynamics">
    <v-row>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          URL's vision & keywords SERP Dynamics
        </page-name>
      </v-col>
      <v-col cols="12">
        <div class="custom-bordered-wrapper">
          <v-row dense>
            <v-col cols="3">
              <v-autocomplete
                v-model="url"
                label="URL"
                :items="filtersData.url"
                item-text="text"
                item-value="value"
                outlined
                dense
                clearable
                :loading="loadings.filters"
                @change="handleFilterChange($event, FILTERS_QUERY_IDS.url)"
                :menu-props="menuProps"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <div class="fix-button-height">
                <date-filter
                  :model-value="date"
                  @update:modelValue="handleUpdateDateFilter"
                />
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div class="fix-button-height">
                <v-btn
                  @click="handleGetData"
                  :loading="loadings.data"
                  color="primary"
                >
                  Get Data
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loadings.data">
      <v-col cols="12" style="position: relative">
        <loader :show="true" />
        <chart-skeleton />
        <v-spacer style="height: 50px" />
        <v-skeleton-loader type="table-row-divider@6"></v-skeleton-loader>
        <v-spacer style="height: 50px" />
        <chart-skeleton />
        <v-spacer style="height: 50px" />
        <v-skeleton-loader type="table-row-divider@6"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <template v-if="!loadings.data && Object.keys(data).length === 0">
        <v-col cols="12">
          <div
            class="placeholder-text__wrapper d-flex justify-center align-center"
          >
            <div class="placeholder">
              <span
                v-html="
                  firstEnter ? tooltips.itemsPlaceholder : tooltips.firstEnter
                "
              ></span>
            </div>
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12">
          <div class="serp-dynamics__chart-wrapper">
            <apexchart
              type="line"
              height="550"
              :options="chartOptions"
              :series="data.chart.series"
            ></apexchart>
          </div>
        </v-col>
        <v-col cols="12">
          <domains-position-tables
            :model-value="domainsPosTablesDates"
            :tables-data="domainsPosTableItems"
            :tables-headers="domainsPosTableMock.domains.headers"
            :loading="this.loadings.domainsTable"
            @update:modelValue="handleChangeDatesDomainPosTable"
          >
            <template v-slot:items="{ items }">
              <td>{{ items.url }}</td>
              <td>{{ items.traffic }}</td>
            </template>
          </domains-position-tables>
        </v-col>
        <div class="bottom__wrapper">
          <v-col cols="12">
            <div class="custom-bordered-wrapper">
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="keyword"
                    label="Keyword"
                    :items="filtersData.keyword"
                    item-text="phrase"
                    item-value="id"
                    outlined
                    dense
                    clearable
                    :loading="loadings.filters"
                    @change="
                      handleFilterChange($event, FILTERS_QUERY_IDS.keyword)
                    "
                    :menu-props="menuProps"
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="serp-dynamics__domains-chart"
            v-if="keywordPosChart"
          >
            <loader :show="loadings.keywordTables" />
            <domains-chart
              :data="keywordPosChart"
              :options="keywordsChartOptions"
            />
          </v-col>
          <v-col cols="12">
            <domains-position-tables
              :model-value="keywordTablesDates"
              :tables-data="keywordPosTableItems"
              :tables-headers="domainsPosTableMock.second_domains.headers"
              @update:modelValue="handleUpdateKeywordDates"
              :loading="loadings.keywordTables"
              sccClass="with-places"
            >
              <template v-slot:items="{ items }">
                <td>{{ items.url }}</td>
                <td>{{ items.pos }}</td>
              </template>
            </domains-position-tables>
          </v-col>
        </div>
      </template>
    </v-row>
  </div>
</template>

<script>
import PageName from "@/components/DashboardDev/PageName";
import Location from "@/mixins/Location";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import {
  DEFAULT_MENU_PROPS,
  SERP_DYNAMIC_CHART_OPTIONS,
  SERP_DYNAMIC_DOMAINS_CHART_OPTIONS,
  SERP_DYNAMIC_MOCK,
} from "@/utils/defaultData";
import Loader from "@/components/Main/Loader";
import VueApexCharts from "vue-apexcharts";
import ChartSkeleton from "@/assets/svg/chart-skeleton";
import DomainsPositionTables from "@/components/SERPDynamic/DomainsPositionTables";
import DomainsChart from "@/components/SERPDynamic/DomainsChart";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import eventPipe from "@/events/eventPipe";

export default {
  components: {
    DateFilter,
    DomainsChart,
    DomainsPositionTables,
    ChartSkeleton,
    PageName,
    Loader,
    apexchart: VueApexCharts,
  },
  mixins: [Location, FiltersHandling],
  async created() {
    eventPipe.$on("update-active-project", () => {
      this.fetchFilters();
    });

    this.fetchFilters();
    const haveParams = this.collectParamsFromQuery();
    if (haveParams) {
      return this.fetchData();
    }
  },
  data: () => ({
    loadings: {
      data: false,
      domainsTable: false,
      keywordTables: false,
    },
    domainsPosTablesDates: [],
    keywordTablesDates: [],
    domainsPosTableItems: {},
    keywordPosTableItems: {},
    keywordPosChart: {},
    cards: [],
    cardsLength: 0,
    chartOptions: SERP_DYNAMIC_CHART_OPTIONS,
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    data: {},
    firstEnter: false,
    menuProps: DEFAULT_MENU_PROPS,
    filtersData: {
      url: [],
      keyword: [],
    },
    url: null, // filter
    date: [], // filter
    keyword: null, // filter
    FILTERS_QUERY_IDS: {
      date: "date",
      url: "url",
      keyword: "keyword",
    },
    domainsPosTableMock: SERP_DYNAMIC_MOCK,
    keywordsChartOptions: {},
    keywordHandleTimeout: null,
    domainsHandleTimeout: null,
  }),
  computed: {},
  methods: {
    async handleUpdateKeywordDates(event) {
      this.keywordTablesDates = event;
      clearTimeout(this.keywordHandleTimeout);
      this.keywordHandleTimeout = setTimeout(
        () => this.handleUpdateKeyword(),
        250
      );
    },
    async handleChangeDatesDomainPosTable(event) {
      clearTimeout(this.domainsHandleTimeout);
      this.domainsHandleTimeout = setTimeout(async () => {
        this.domainsPosTablesDates = event;

        this.loadings.domainsTable = true;

        const { url, domainsPosTablesDates: date } = this;
        const payload = {
          filter: {
            url,
            date,
          },
        };
        const resp = await this.$store.dispatch(
          "serp-dynamics/getDomainTableData",
          payload
        );
        if (resp) {
          this.domainsPosTableItems = resp;
        }

        this.loadings.domainsTable = false;
      }, 250);
    },
    handleUpdateDateFilter(event) {
      this.date = event;
      if (!event.length) {
        event = null;
      }
      this.handleFilterChange(event, this.FILTERS_QUERY_IDS.date);
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["url"];
      const payload = {
        type: "/one-page-analyzer/serp-dynamic/main",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    collectParamsFromQuery() {
      const keys = Object.keys(this.$route.query);
      keys.forEach((key) => {
        const value = this.$route.query[key];

        if (key === this.FILTERS_QUERY_IDS.date) {
          const newValue = value.split(",");
          return (this[key] = newValue);
        }

        return (this[key] = Number(value));
      });

      if (keys.length > 0) {
        return true;
      }
      return false;
    },
    handleFilterChange(event, type) {
      this._$handleFilterChange(event, type);
      if (type === this.FILTERS_QUERY_IDS.keyword) {
        this.handleUpdateKeyword(event);
      }
    },
    async handleUpdateKeyword() {
      this.loadings.keywordTables = true;
      const { url, keywordTablesDates: dates, keyword: phrase, date } = this;
      if (!phrase) {
        return (this.loadings.keywordTables = false);
      }
      const payload = {
        filter: {
          url,
          date,
          phrase,
          dates,
        },
      };
      const resp = await this.$store.dispatch(
        "serp-dynamics/getKeywordTableData",
        payload
      );
      if (resp) {
        this.keywordPosTableItems = resp.table;
        this.keywordPosChart = resp.chart;
        this.keywordsChartOptions = {
          ...SERP_DYNAMIC_DOMAINS_CHART_OPTIONS,
          xaxis: { categories: resp.chart.categories },
        };
      }
      this.loadings.keywordTables = false;
    },
    handleRangeChange(event, type) {
      this._$handleRangeChange(event, type);
    },
    async fetchData() {
      try {
        this.firstEnter = true;
        this.loadings.data = true;

        const { url, date } = this;
        const payload = {
          filter: { url, date },
        };

        const resp = await this.$store.dispatch(
          "serp-dynamics/fetchData",
          payload
        );
        if (resp) {
          this.data = resp;
          this.domainsPosTablesDates = resp.dates;
          this.keywordTablesDates = resp.dates;
          this.filtersData.keyword = resp.keys;
          this.handleChangeDatesDomainPosTable(resp.dates);
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: { categories: resp.chart.categories },
          };
        }
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.loadings.data = false;
      }
    },
    handleGetData() {
      this.fetchData();
    },
  },
};
</script>
