<template>
  <td>
    <template v-if="haveDiff">
      <v-badge
          :color="getColor"
      >
        <template v-slot:badge>
          {{getBadgeContent}}
        </template>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
          <span style="cursor: default" v-on="on">
            {{getTextValue}}
          </span>
          </template>
          <span v-html="getHtml">
        </span>
        </v-tooltip>
      </v-badge>
    </template>
    <template v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <span style="cursor: default" v-on="on">
            {{getTextValue}}
          </span>
        </template>
        <span v-html="getHtml">
        </span>
      </v-tooltip>
    </template>
  </td>
</template>

<script>
export default {
  name: 'by-page-type-diff-table-item',
  props: {
    item: {
      type: Object,
      default() {return {}}
    },
  },
  computed: {
    haveDiff() {
      const {diff, value} = this.item
      return diff !== 0 && value
    },
    getColor() {
      const {diff, value} = this.item
      const positive = diff > 0
      const noChange = diff === 0

      if(noChange || !value && !diff) return '#fffff00';

      if(noChange) return '#fffff00';

      return positive ? '#6fcb5e' : '#f14052'
    },
    getBadgeContent() {
      const {diff, value} = this.item

      if(!value && value !== 0) {
        return null
      }

      if(diff !== 0 && diff !== null) {
        return diff
      }

      return diff
    },
    getTextValue() {
      const {value} = this.item

      if(!value && value !== 0) {
        return null
      }
      return value
    },
    getHtml() {
      const {diff, prev, value} = this.item
      return (`<div>diff : <b>${diff ? diff : 0}</b></div><div>prev : <b>${prev ? prev : 0}</b></div><div>value : <b> ${value ? value : 0}</b></div>`)
    },
  },
}
</script>