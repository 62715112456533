<template>
  <div :class="`loader ${show ? ' show' : ''}`">
      <v-btn icon loading x-large/>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  }
}
</script>