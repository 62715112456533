<template>
  <div @click="goToWS" class="colored-td colored-td--clickable">
    {{ data.today_plan === 0 ? "" : data.today_plan }}
  </div>
</template>

<script>
export default {
  props: ["data", "getWorkSectionUrl"],
  methods: {
    goToWS() {
      const url = this.getWorkSectionUrl.todaysPlan(this.data.user_id);

      window.open(url, "_blank");
    },
  },
};
</script>
