import { render, staticRenderFns } from "./LinkDynamicsReportIndex.vue?vue&type=template&id=5610a068&scoped=true&"
import script from "./LinkDynamicsReportIndex.vue?vue&type=script&lang=js&"
export * from "./LinkDynamicsReportIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5610a068",
  null
  
)

export default component.exports