import service from "../../plugins/service";

export const WorksectionApiService = () => {
  const ENDPOINTS = {
    CREATE_TASK: "/worksection/task/create",
  };

  const createTask = (formData) => {
    return service.post(ENDPOINTS.CREATE_TASK, formData);
  };

  return {
    createTask,
  };
};
