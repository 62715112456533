<template>
  <v-container class="similar-task-creating" style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <h2 class="d-flex">
          <span>Create Task By File</span>
        </h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12" class="mt-8">
        <v-card width="420" class="shadow-e1-bordered" rounded="lg">
          <v-card-text>
            <drop-file-zone
              :model-value="file"
              @update:modelValue="file = $event"
            />
            <div class="d-flex mt-2 justify-end">
              <a
                href="https://docs.google.com/document/d/1o22IjqZhk1heU3O1-yfSGOdxRppi7Gh9HV2qCpwQiE0/edit"
                target="_blank"
                class="reset-link-styles mr-1"
              >
                <v-chip
                  outlined
                  label
                  style="border-style: dashed"
                  @click="() => {}"
                >
                  Instruction
                  <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                </v-chip>
              </a>
              <v-spacer />
              <a
                href="https://api.serpinsider.com/WS Import Example.csv"
                target="_blank"
                class="reset-link-styles"
              >
                <v-chip
                  outlined
                  label
                  style="border-style: dashed"
                  @click="() => {}"
                >
                  Download Example
                  <v-icon small class="ml-1">mdi-download</v-icon>
                </v-chip>
              </a>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <extended-chip
              :color="isSuccessImport ? 'success' : 'primary'"
              :loading="loadings.import"
              :disabled="loadings.import || !file"
              large
              label
              class="px-8"
              @click="handleCreateTask"
            >
              Import <v-icon right small>mdi-import</v-icon>
            </extended-chip>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card width="420" rounded="lg" class="shadow-e1-bordered">
          <v-card-subtitle> Uploaded tasks list </v-card-subtitle>
          <v-card-text>
            <template v-if="createdTasks.length > 0">
              <v-row>
                <v-col v-for="task in createdTasks" :key="task.id" cols="12">
                  <v-row dense>
                    <v-col>
                      <div v-if="task.name">
                        <v-icon small class="mr-2">mdi-tag-outline</v-icon
                        >{{ task.name }}
                      </div>
                      <div v-if="task.email" class="mt-2">
                        <v-icon small class="mr-2">mdi-account-outline</v-icon
                        >{{ task.email }}
                      </div>
                      <div
                        v-if="task.status === 'error' && task.content"
                        class="error--text"
                      >
                        {{ task.content }}
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <a
                        v-if="task.url"
                        :href="task.url"
                        target="_blank"
                        class="reset-link-styles"
                      >
                        <v-chip outlined label @click="() => {}"
                          >Open
                          <v-icon small class="ml-2"
                            >mdi-open-in-new</v-icon
                          ></v-chip
                        >
                      </a>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-else-if="loadings.import">
              <div class="d-flex justify-center">
                <v-progress-circular
                  indeterminate
                  size="22"
                  width="2"
                  color="primary"
                />
              </div>
            </template>
            <template v-else>
              <div style="opacity: 0.5" class="d-flex flex-column align-center">
                <div>
                  <v-icon x-large>mdi-list-box-outline</v-icon>
                </div>
                <div style="max-width: 180px" class="text-center">
                  To see the list of tasks here, upload the file.
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import service from "../../../plugins/service";
import { uid } from "../../../utils/functions";
import DropFileZone from "../../UI/DropFileZone.vue";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";

export default {
  components: { ExtendedChip, DropFileZone },
  data: () => ({
    file: null,
    loadings: {
      import: false,
    },
    isSuccessImport: false,
    createdTasks: [],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Choose type",
        to: "/similar-task-creating/choose-type",
        exact: true,
      },
      {
        text: "By File",
        disabled: true,
      },
    ],
    menuProps: DEFAULT_MENU_PROPS,
  }),
  created() {},
  computed: {},
  methods: {
    taskAdapter(taskItems) {
      try {
        return taskItems.map((v) => {
          v.id = uid();
          return v;
        });
      } catch {
        return [];
      }
    },
    async handleCreateTask() {
      try {
        const url = "/worksection/task/create";

        const formData = new FormData();

        formData.append("file", this.file);
        formData.append("type", "CREATE_TASK_BY_FILE");

        this.loadings.import = true;

        const resp = await service.post(url, formData);

        this.loadings.import = false;
        if (resp && resp.data.success) {
          this.$message.notification({
            title: "Successfull",
            text: "File successfully exported.",
            type: "success",
            duration: 7000,
          });
          this.createdTasks = this.taskAdapter(resp.data.tasks);
          this.isSuccessImport = true;
          setTimeout(() => {
            this.isSuccessImport = false;
          }, 1500);
        }
      } catch (e) {
        console.error(e);
        this.loadings.import = false;
      }
    },
  },
};
</script>
