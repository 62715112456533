<template>
  <v-dialog
    v-model="dialog"
    max-width="760px"
    scrollable
    content-class="my-shadow--e3"
  >
    <v-card class="price-history-dialog">
      <v-card-title class="text-body-2 pa-4">
        <b>Price history</b>
        <v-spacer />
        <v-chip
          label
          @click="dialog = false"
          style="width: 32px"
          class="d-flex justify-center pa-0"
        >
          <v-icon small>mdi-close</v-icon>
        </v-chip>
      </v-card-title>
      <v-divider />
      <v-card-text class="d-flex flex-column pa-4" style="gap: 1rem">
        <template v-if="prices.length === 0">
          <div style="opacity: 0.7">No prices was found...</div>
        </template>
        <template v-for="(prices, key, idx) in prices">
          <v-card class="rounded-lg" flat outlined :key="`${key}${idx}`">
            <v-card-title class="text-body-2">
              <div class="d-flex" style="gap: 0.25rem">
                <span>
                  {{ $moment(key).format("ll") }}
                </span>
                -
                <span style="opacity: 0.5">
                  {{ $moment(key).format("hh:mm:ss") }}
                </span>
              </div>
            </v-card-title>
            <v-card-text class="pa-4 pt-0">
              <div class="d-flex flex-column" style="gap: 0.5rem">
                <template v-for="(price, idx) in prices">
                  <div
                    class="price-element"
                    :key="`price-element-${price.id}-${idx}`"
                  >
                    <v-hover v-slot="{ hover }">
                      <div>
                        <v-avatar
                          size="46"
                          @click="handleCopy(price.email)"
                          :color="hover ? 'primary' : ''"
                          :title="price.email"
                        >
                          <v-icon v-if="hover" small dark>
                            {{
                              copyStatus[price.email]
                                ? "mdi-check"
                                : "mdi-content-copy"
                            }}
                          </v-icon>
                          <v-img v-else :src="price.photo"></v-img>
                        </v-avatar>
                      </div>
                    </v-hover>
                    <v-card flat class="rounded-lg">
                      <v-card-title class="text-body-2">
                        <div class="d-flex" style="gap: 0.25rem">
                          <span title="Source">
                            <v-icon size="14"> mdi-source-branch </v-icon>
                            {{ price.source }}
                          </span>
                          •
                          <span title="Name">{{ price.title }}</span>
                          •
                          <span title="Created">{{
                            $moment(price.created_at).format("ll")
                          }}</span>
                        </div>
                        <v-spacer />
                        <div class="d-flex align-center" style="gap: 0.5rem">
                          <span v-if="price.max_links" title="Max links">
                            <span style="opacity: 0.8">
                              <v-icon size="16" class="mr-1">
                                mdi-link-variant
                              </v-icon>
                            </span>
                            <b>{{ price.max_links }}</b>
                          </span>
                          <span v-if="price.period" title="Period">
                            <span style="opacity: 0.5">
                              <v-icon size="16">mdi-update</v-icon>
                            </span>
                            {{ price.period }}
                          </span>
                          <span
                            v-if="
                              price.value !== 'null' &&
                              price.currency !== 'null'
                            "
                            title="Price"
                          >
                            <v-chip
                              label
                              :class="`flat-chip--auto ${processCreatedAtColor(
                                price.created_at
                              )}--text`"
                            >
                              {{ price.value !== "null" ? price.value : ""
                              }}{{
                                price.currency !== "null" ? price.currency : ""
                              }}
                            </v-chip>
                          </span>
                        </div>
                      </v-card-title>
                      <v-card-text
                        v-if="price.comment && price.comment !== 'none'"
                        class="pt-0"
                      >
                        «{{ price.comment }}»
                      </v-card-text>
                    </v-card>
                  </div>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import copy from "copy-to-clipboard";

export default {
  props: {
    prices: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [Dialog],

  data: () => ({
    copyStatus: {},
  }),

  methods: {
    processCreatedAtColor(date) {
      const diffMonths = Number(
        String(this.$moment(date).diff(this.$moment(), "months")).replaceAll(
          "-",
          ""
        )
      );

      if (diffMonths <= 3) return "success";

      if (diffMonths > 3 && diffMonths <= 6) return "warning";

      if (diffMonths > 6) return "error";

      return "";
    },
    handleCopy(content) {
      copy(content);
      this.$set(this.copyStatus, content, true);

      setTimeout(() => {
        this.$set(this.copyStatus, content, false);
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
.price-history-dialog {
  .price-element {
    display: flex;
    gap: 1rem;
    .v-avatar {
      cursor: pointer;
      margin-top: 0.25rem;
    }
    .v-card {
      border: thin solid color-mix(in srgb, currentColor 10%, transparent) !important;
    }
  }
}

.theme--dark {
  .price-history-dialog {
    .price-element {
      display: flex;
      gap: 1rem;
      .v-avatar {
        cursor: pointer;
        margin-top: 0.25rem;
      }
      .v-card {
        background: color-mix(in srgb, currentColor 3%, transparent) !important;
        border-color: transparent !important;
      }
    }
  }
}
</style>
