<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <h2>Settings</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-tabs v-model="tabs">
          <v-tab :key="1"> Research Hub </v-tab>
          <v-tab :key="2"> Project 200 </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" class="settings__tab">
          <v-tab-item :key="1">
            <v-row dense>
              <v-col cols="4">
                <research-hub-settings-main :fetch-data="tabs === 0" />
              </v-col>
              <v-col cols="10"></v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-row dense>
              <v-col cols="4">
                <project200-settings-main />
              </v-col>
              <v-col cols="10"></v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResearchHubSettingsMain from "./Components/ResearchHubSettingsMain.vue";
import Project200SettingsMain from "./Components/Project200SettingsMain.vue";

export default {
  components: {
    Project200SettingsMain,
    ResearchHubSettingsMain,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Global settings",
        disabled: true,
      },
    ],
    tabs: 0,
    minAhTraffic: 10000,
    filtersData: {
      research_hub: {},
      project_200: {},
    },
    loadings: {
      filters: false,
      project_200: false,
      research_hub: false,
    },
    timeout: null,
  }),
  watch: {
    minAhTraffic() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchFilters("project_200");
      }, 1500);
    },
  },
  created() {
    // const types = ["research_hub", "project_200"];
    //
    // types.forEach((type) => {
    //   this.fetchFilters(type);
    // });
  },
  methods: {
    async fetchFilters(type) {
      try {
        const filters = ["config", "responsible", "limit"];
        const payload = {
          type: "/admin/research-hub",
          take: filters,
          filter: {
            projectID: this.$store.state.project.active_project,
            type,
            min_ah_traffic: this.minAhTraffic,
          },
        };

        this.loadings[type] = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData[type] = resp;

        console.log(this.filtersData);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings[type] = false;
      }
    },
  },
};
</script>
