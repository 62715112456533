<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>Survival Dashboard</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <v-autocomplete
              v-model="filters.project"
              hide-details
              dense
              outlined
              label="Projects"
              multiple
              item-text="name"
              item-value="id"
              :items="_$getAllowedProjects"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <smart-autocomplete
              :model-value="filters.type"
              name="show"
              @update:modelValue="filters.type = $event"
              :items="filtersData.link_type"
              label="Type"
              item-text="text"
              item-value="value"
            />
            <v-autocomplete
              v-if="false"
              v-model="filters.type"
              hide-details
              dense
              outlined
              label="Type"
              multiple
              clearable
              :loading="loadings.filters"
              :items="filtersData.link_type"
            >
              <template #selection="{ item, index }">
                <span v-if="index === 0">{{ item.text }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filters.type.length - 1 }} more)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.date"
              hide-details
              dense
              outlined
              label="Month"
              multiple
              clearable
              :loading="loadings.filters"
              :items="filtersData.date"
            >
              <template #selection="{ item, index }">
                <span v-if="index === 0">{{ item.text }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filters.date.length - 1 }} more)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col>
            <v-btn
              block
              style="height: 40px"
              color="primary"
              @click="fetchData"
              :loading="loadings.sections"
            >
              Get Data
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          v-for="(section, idx) in sections"
          :key="idx"
          class="shadow-e1-bordered mb-6"
          rounded="lg"
        >
          <v-card-title>
            {{ section.title ? section.title : `Section #${idx}` }}
            <v-chip small @click="goToLtt(section.filters)" class="ml-2"
              >LTT</v-chip
            >
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="section.headers"
              :items="section.body"
              :items-per-page="-1"
              :footer-props="{ itemsPerPageOptions: [50, 100, 200, 500, -1] }"
              group-by="groupBy"
            >
              <template #[`group.header`]="{ isOpen, toggle, group }">
                <td>
                  <v-btn icon @click="toggle" class="mr-2">
                    <v-icon>
                      {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                    </v-icon>
                  </v-btn>
                  {{ group }}
                </td>
                <td :colspan="section.headers.length - 1"></td>
              </template>
              <template
                v-for="(key, idx) in Object.keys(section.body[0])"
                #[`item.${key}`]="{ item }"
              >
                <div
                  :key="key"
                  :class="`colored-td ${idx === 0 && 'text-start'} ${getBgColor(
                    item[key],
                    String(item[key]).includes('%'),
                    item
                  )}`"
                >
                  {{ item[key] }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Project from "@/mixins/Project";
import service from "@/plugins/service";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import QueryBuilder from "@/mixins/QueryBuilder";
import SmartAutocomplete from "../../UI/SmartAutocomplete.vue";

export default {
  components: { SmartAutocomplete },
  mixins: [Project, FiltersHandling, Project, QueryBuilder],
  data: () => ({
    filters: {
      project: [],
      type: [],
      date: [],
    },
    loadings: {
      filters: false,
      sections: false,
    },
    filtersData: {
      link_type: [],
      date: [],
    },
    breadcrumbs: [
      {
        text: "Dashboards",
        disabled: true,
      },
      {
        text: "Survival Dashboard",
        disabled: true,
      },
    ],
    sections: [],
  }),
  watch: {
    filters: {
      handler(value) {
        this._$handleFiltersChange(value);
      },
      deep: true,
    },
  },
  async created() {
    const haveParams = this._$collectParams(
      ["project", "type", "date"],
      "filters"
    );

    await this.fetchFilters();

    if (this.filters.type.length === 0)
      this.filters.type = this.filtersData.link_type.map((v) => v.value);
    if (this.filters.date.length === 0)
      this.filters.date = this.filtersData.date.map((v) => v.value);

    if (this.filters.project.length === 0)
      this.filters.project = [this._$getActiveProject.id];

    if (haveParams) this.fetchData();
  },
  methods: {
    getBgColor(value, isPercent, data) {
      if (
        data.name &&
        typeof data.name === "string" &&
        data.name.includes("% IsGI")
      ) {
        if (!value || !isPercent) return "";

        const val = parseInt(value);

        if (val === 0) return "red";

        if (val < 60) return "red";
        if (val >= 61 && val <= 80) return "orange";
        if (val >= 81) return "green";

        return "";
      }

      if (
        data.name &&
        typeof data.name === "string" &&
        data.name.includes("% IsLink")
      ) {
        if (!value || !isPercent) return "";

        const val = parseInt(value);

        if (val === 0) return "green";

        if (val >= 76 && val <= 100) return "green";
        if (val >= 50 && val <= 75) return "orange";
        if (val < 50) return "red";

        return "";
      }

      return "";
    },
    goToLtt(filters) {
      window.open("/ltt/index?" + this._$buildQuery(filters), "_blank");
    },
    async fetchData() {
      try {
        const url = "/ltt/dashboard-survival";

        const payload = {
          ...this.filters,
        };
        this.loadings.sections = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data) this.sections = resp.data;

        this.loadings.sections = false;
      } catch (e) {
        console.error(e);
        this.loadings.sections = false;
      }
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["link_type", "date"];
      const payload = {
        type: " /ltt/dashboard-survival",
        take: filters,
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        this.filtersData = resp;
      }

      this.loadings.filters = false;
    },
  },
};
</script>
