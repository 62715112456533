<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row class="mb-4">
      <v-col class="d-flex align-center">
        <h2>Flex Donors Finder</h2>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-chip outlined>
          Unprocessed: <b class="pl-2">{{ number_of_unprocessed }}</b>
          <v-icon class="pl-1" small>mdi-link</v-icon>
        </v-chip>
        <v-chip outlined class="ml-2">
          Added to check:
          <b class="pl-2">{{ number_of_added_phrases_to_check }}</b>
          <v-icon class="pl-1" small>mdi-bookmark-plus-outline</v-icon>
        </v-chip>
      </v-col>
      <v-col class="d-flex justify-end">
        <vue-json-to-csv
          :json-data="getJsonToCsvData"
          csv-title="flex_donor_finders"
          :separator="'\t'"
        >
          <v-chip
            label
            outlined
            color="success"
            :disabled="tableData.items.length === 0"
          >
            Export CSV <v-icon class="ml-2" small>mdi-export-variant</v-icon>
          </v-chip>
        </vue-json-to-csv>
        <v-chip label @click="goToAcceptorDomains" class="ml-2">
          Acceptor Domains <v-icon class="ml-2" small>mdi-open-in-new</v-icon>
        </v-chip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model="filters.link_type"
          :items="filtersData.link_type"
          outlined
          dense
          hide-details
          clearable
          :menu-props="menuProps"
          label="Link Type"
          @change="_$handleFilterChange($event, 'link_type')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.sw_category"
          :items="filtersData.sw_category"
          outlined
          dense
          hide-details
          clearable
          :menu-props="menuProps"
          label="SW Category"
          @change="_$handleFilterChange($event, 'sw_category')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <easy-range-input
          label="Pos Growth"
          :model-value="filters.position_growth"
          @update:modelValue="
            _$handleRangeChange($event, 'position_growth', 'filters')
          "
        />
      </v-col>
      <v-col>
        <smart-date-filter
          :model-value="filters.date"
          @update:modelValue="handleDateChange"
          :custom-presets="customDatePresets"
        ></smart-date-filter>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.type"
          :items="['h1', 'phrase']"
          dense
          outlined
          hide-details
          :menu-props="menuProps"
          label="Type"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <easy-range-input
          label="Custom Comparison"
          :model-value="filters.custom_comparison"
          @update:modelValue="
            _$handleRangeChange($event, 'custom_comparison', 'filters')
          "
        />
      </v-col>
      <v-col>
        <v-btn
          @click="fetchData"
          block
          color="primary"
          :loading="loadings.table"
          >Get data</v-btn
        >
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model="filters.country"
          dense
          outlined
          clearable
          hide-details
          label="Country"
          :items="filtersData.country"
          @change="_$handleFilterChange($event, 'country')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.language"
          dense
          clearable
          outlined
          hide-details
          label="Language"
          :items="filtersData.language"
          @change="_$handleFilterChange($event, 'language')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <easy-range-input
          label="AH DR"
          :model-value="filters.ah_dr"
          @update:modelValue="_$handleRangeChange($event, 'ah_dr', 'filters')"
        />
      </v-col>
      <v-col>
        <easy-range-input
          label="AH TR"
          :model-value="filters.ah_tr"
          @update:modelValue="_$handleRangeChange($event, 'ah_tr', 'filters')"
        />
      </v-col>
      <v-col>
        <easy-range-input
          label="Start Position"
          :model-value="filters.start_position"
          @update:modelValue="
            _$handleRangeChange($event, 'start_position', 'filters')
          "
        />
      </v-col>
      <v-col class="d-flex justify-center">
        <v-autocomplete
          v-model="filters.on_page"
          dense
          hide-details
          label="On Page"
          outlined
          :items="[0, 1]"
          @change="_$handleFilterChange($event, 'on_page')"
        ></v-autocomplete>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <easy-range-input
          label="Growth"
          :model-value="filters.growth"
          @update:modelValue="_$handleRangeChange($event, 'growth', 'filters')"
        ></easy-range-input>
      </v-col>
      <v-col>
        <easy-range-input
          label="Drop"
          :model-value="filters.drop"
          @update:modelValue="_$handleRangeChange($event, 'drop', 'filters')"
        ></easy-range-input>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
    <v-row class="mt-6" dense>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          dense
          hide-details
          clearable
          label="Search"
          append-icon="mdi-magnify"
          style="max-width: 250px"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :sort-by="['position_growth']"
          :sort-desc="[true]"
          :search="search"
          :custom-sort="customSort"
          :loading="loadings.table"
          :headers="tableData.headers"
          multi-sort
          :items="tableData.items"
          :items-per-page="50"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100, 250, 500, -1] }"
        >
          <template #[`item.url_donor`]="{ item }">
            <div>
              {{ item.url_donor }}
              <v-chip
                v-if="item.domain_growth || item.domain_drop"
                small
                outlined
                :color="getDomainChipColor(item)"
              >
                <span
                  v-if="item.domain_growth !== 0"
                  class="success--text mr-2"
                >
                  <v-icon small>mdi-arrow-up-thin</v-icon>
                  {{ item.domain_growth }}
                </span>
                <span v-if="item.domain_drop !== 0" class="error--text">
                  <v-icon small>mdi-arrow-down-thin</v-icon>
                  {{ item.domain_drop }}
                </span>
              </v-chip>
            </div>
          </template>
          <template
            v-for="key in getWeeks(tableData.headers)"
            #[`item.${key}`]="{ value }"
          >
            <div
              v-if="key[0] === '$'"
              :key="key"
              style="white-space: nowrap"
              :class="`colored-td ${
                String(value.link_posted) === '1' ? 'blue' : ''
              }`"
            >
              {{ value.pos }}
              <sup v-if="String(value.diff) !== '0'">
                {{ value.diff }}
              </sup>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FiltersHandling from "../../mixins/components/FiltersHandling";
import service from "@/plugins/service";
import EasyRangeInput from "@/components/UI/EasyRangeInput";
import {
  DEFAULT_MENU_PROPS,
  DEFAULT_MOMENT_DATE_FORMAT,
  FLEX_FINDER_TABLE_HEADERS,
} from "@/utils/defaultData";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../mixins/Location";
import VueJsonToCsv from "vue-json-to-csv";
import { uid } from "@/utils/functions";

export default {
  components: { SmartDateFilter, EasyRangeInput, VueJsonToCsv },
  mixins: [FiltersHandling, Location],
  data: function () {
    return {
      customDatePresets: [
        {
          id: uid(),
          name: "Last 200 days",
          range: [
            this.$moment()
              .subtract(200, "day")
              .startOf("day")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
      ],
      uploadDialogModel: false,
      search: null,
      upload: {
        headers: "domain;country;country_lang;niche",
        rows: "",
      },
      menuProps: DEFAULT_MENU_PROPS,
      filters: {
        url_donor: null,
        custom_comparison: [null, null],
        link_type: 6,
        country: null,
        date: [],
        sw_category: null,
        position_growth: [null, null],
        ah_dr: [null, null],
        ah_tr: [null, null],
        start_position: [null, null],
        on_page: null,
        growth: [null, null],
        drop: [null, null],
      },
      filtersData: {
        link_type: [],
        sw_category: [],
        country: [],
        language: [],
      },
      headers: [],
      tableData: {
        headers: FLEX_FINDER_TABLE_HEADERS,
        items: [],
      },
      number_of_unprocessed: 0,
      number_of_added_phrases_to_check: 0,
      loadings: {
        table: false,
        upload: false,
      },
    };
  },
  created() {
    const haveParams = this._$collectParams(
      [
        "date",
        "custom_comparison",
        "ah_dr",
        "ah_tr",
        "position_growth",
        "start_position",
      ],
      "filters"
    );

    if (!this.$route.query.date) {
      this.filters.date = [
        this.$moment().startOf("month").format("YYYY-MM-DD"),
        this.$moment().format("YYYY-MM-DD"),
      ];
    }

    this.fetchFilters();
    if (haveParams) this.fetchData();
  },
  watch: {
    filters: {
      handler(value) {
        this._$handleFiltersChange(value);
      },
      deep: true,
    },
  },
  computed: {
    getJsonToCsvData() {
      try {
        const output = JSON.parse(JSON.stringify(this.tableData.items));

        output.map((item) => {
          Object.keys(item).forEach((key) => {
            if (key[0] === "$") delete item[key];
          });
          return item;
        });

        return output;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
  },
  methods: {
    getDomainChipColor(data) {
      const growth = Number(data.domain_growth);
      const drop = Number(data.domain_drop);

      if (growth !== 0 && drop === 0) return "success";
      if (growth === 0 && drop !== 0) return "error";
      if (drop > growth) return "warning";
      return "";
    },
    customSort(items, sortBy, sortDesc) {
      return items.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i++) {
          const valA =
            typeof a[sortBy[i]] === "object" && a[sortBy[i]] !== null
              ? a[sortBy[i]].pos
              : a[sortBy[i]];
          const valB =
            typeof b[sortBy[i]] === "object" && b[sortBy[i]] !== null
              ? b[sortBy[i]].pos
              : b[sortBy[i]];

          const string = isNaN(Number(valA)) && isNaN(Number(valB));

          if (string) {
            let aVal, bVal;
            if (valA === null || valA === "") aVal = 0;
            if (valB === null || valB === "") bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (valA > valB) return sortDesc[i] ? -1 : 1;
            if (valA < valB) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(Number(valA)) || isNaN(parseInt(valA))) aVal = 0;
          if (isNaN(Number(valB)) || isNaN(parseInt(valB))) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (Number(valA) > Number(valB)) return sortDesc[i] ? -1 : 1;
          if (Number(valA) < Number(valB)) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
    },
    goToAcceptorDomains() {
      window.open("/flex-donors-finder/acceptor-domains", "_blank");
    },
    getWeeks(items) {
      const output = [];

      items.forEach((header) => {
        if (header.value[0] === "$") {
          output.push(header.value);
        }
      });

      return output;
    },
    async fetchFilters() {
      console.log("fetch filters");
      this.loadings.filters = true;
      const filters = ["link_type", "sw_category", "country", "language"];
      const payload = {
        type: "/df/donors",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);

      this.loadings.filters = false;

      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
    },
    handleDateChange(event) {
      this.filters.date = event;
      this._$handleFilterChange(event, "date");
    },
    async fetchData() {
      this.loadings.table = true;

      const url = "/df/donors";
      const payload = {
        filter: this.filters,
      };

      this.tableData.items = [];

      const resp = await service.post(url, payload);

      if (resp.data && resp.data.success) {
        this.tableData.items = resp.data.items;
        this.tableData.headers = resp.data.headers;
        this.number_of_unprocessed = resp.data.number_of_unprocessed;
        this.number_of_added_phrases_to_check =
          resp.data.number_of_added_phrases_to_check;
      }

      this.loadings.table = false;
    },
  },
};
</script>
