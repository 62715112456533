<template>
  <v-menu
    :close-on-content-click="false"
    content-class="my-shadow--e3"
    offset-y
  >
    <template #activator="{ on }">
      <div v-on="on">
        <slot />
      </div>
    </template>
    <v-card class="styled-card--default px-0">
      <v-data-table
        :headers="headers"
        :items="data.name_history"
      ></v-data-table>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    data: {},
  },
  data: () => ({
    headers: [
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Deadline",
        value: "deadline",
      },
    ],
  }),
};
</script>
