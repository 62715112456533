<template>
  <v-container fluid class="content-checker__dashboard">
    <v-row dense>
      <v-col cols="12">
        <h2>Anchor info by Pages</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <easy-range-input
          label="Amount"
          :model-value="amount"
          @update:modelValue="_$handleRangeChange($event, 'amount')"
          :presets="rangePresets"
        />
      </v-col>
      <v-col>
        <easy-range-input
          label="Sensitive Amount"
          :model-value="sensitive_amount"
          @update:modelValue="_$handleRangeChange($event, 'sensitive_amount')"
          :presets="rangePresets"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="urlID"
          label="Url ID"
          placeholder="Enter ID"
          type="number"
          @change="_$handleFilterChange($event, 'urlID')"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          block
          color="primary"
          @click="handleGetData"
          :loading="loadings.table"
        >
          Get data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card rounded="xl" class="shadow-e1-bordered">
          <v-card-title>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="searchModel"
                  append-icon="mdi-magnify"
                  label="Search"
                  placeholder="Search"
                  dense
                  hide-details
                  style="max-width: 340px"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex justify-end" style="align-items: flex-end">
                <v-tooltip v-if="lastDateCrawl" bottom>
                  <template v-slot:activator="{ on }">
                    <v-chip label small v-on="on">
                      {{ lastDateCrawl }}
                    </v-chip>
                  </template>
                  <span>Last date of crawling</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :search="searchModel"
              :page.sync="tablePage"
              :headers="tableHeaders"
              :items="tableItems"
              :loading="loadings.table"
              :custom-sort="customSort"
              :sort-by="['need_top', 'priority', 'depth', 'links', 'cl', 'imr']"
              :sort-desc="[false, false, false, true, false, false]"
              :footer-props="{
                itemsPerPageOptions: [15, 25, 50, 100, 200, 500, -1],
              }"
              :items-per-page="100"
              multi-sort
            >
              <template #[`item.url`]="{ item: { url } }">
                <span style="white-space: nowrap">
                  <span>{{ clearDomen(url) }} </span>
                  <v-btn @click="goTo(url)" icon x-small class="mr-3">
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </span>
              </template>

              <template #[`item.phrase`]="{ value }">
                <div style="max-width: 340px">
                  {{ value }}
                </div>
              </template>

              <template #[`item.sa`]="{ value }">
                <div :class="`colored-td ${getSAColor(value)}`">
                  {{ value }}
                </div>
              </template>

              <template #[`item.a`]="{ value }">
                <div :class="`colored-td ${getAmountColor(value)}`">
                  {{ value }}
                </div>
              </template>

              <template #[`item.code`]="{ item }">
                <template v-if="item.code !== '200'">
                  <a
                    :href="`https://bertal.ru/index.php?url=${item.url}`"
                    target="_blank"
                  >
                    <div :class="`colored-td ${getCodeColor(item.code)}`">
                      {{ item.code }}
                    </div>
                  </a>
                </template>
                <template v-else>
                  <div :class="`colored-td ${getCodeColor(item.code)}`">
                    {{ item.code }}
                  </div>
                </template>
              </template>

              <template #[`item.depth`]="{ item: { depth } }">
                <div :class="`colored-td ${getDepthColor(depth)}`">
                  {{ depth }}
                </div>
              </template>

              <template #[`item.uniq_anchors`]="{ item }">
                <div
                  @click="goToLinkUrlControl(item)"
                  :class="`colored-td ${getAnchorColor(item.uniq_anchors)}`"
                  style="cursor: pointer"
                >
                  {{ item.uniq_anchors }}
                </div>
              </template>

              <template #[`item.url_length`]="{ item: { url_length } }">
                <template v-if="url_length === null || url_length === 'null'">
                </template>
                <template v-else>
                  <div :class="`colored-td ${getUrlLengthColor(url_length)}`">
                    {{ url_length }}
                  </div>
                </template>
              </template>

              <template #[`item.crawled_at`]="{ item: { crawled_at } }">
                <div
                  :class="`colored-td ${getDateColor(crawled_at)}`"
                  style="white-space: nowrap"
                >
                  {{ crawled_at ? $moment(crawled_at).format("MMMM DD") : "" }}
                </div>
              </template>
              <template #[`item.first_seen`]="{ value }">
                <div
                  :class="`colored-td ${getFirstSeenColor(value)}`"
                  style="white-space: nowrap"
                >
                  {{ value ? $moment(value).format("MMM DD YYYY") : "" }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  ANCHOR_INFO_BY_PAGES_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
  PROJECT_ID_IDTF,
} from "@/utils/defaultData";
import Location from "@/mixins/Location";
import eventPipe from "@/events/eventPipe";
import EasyRangeInput from "@/components/UI/EasyRangeInput";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import service from "@/plugins/service";

export default {
  components: {
    EasyRangeInput,
  },
  mixins: [Location, FiltersHandling, Project],
  data: () => ({
    tableHeaders: ANCHOR_INFO_BY_PAGES_TABLE_HEADERS,
    menuProps: DEFAULT_MENU_PROPS,
    searchModel: "",
    tableItems: [],
    lastDateCrawl: null,
    loadings: {
      table: false,
      filters: false,
      sub_project: false,
    },
    filtersData: {},
    tablePage: 1,
    priorityModel: [],
    rangePresets: [
      ["1 - 3", [1, 3]],
      ["1 - 5", [1, 5]],
      ["1 - 10", [1, 10]],
      ["1 - 50", [1, 50]],
      ["1 - 100", [1, 100]],
    ],
    amount: [null, null],
    sensitive_amount: [null, null],
    urlID: null,
  }),
  async created() {
    this._$collectParams(["amount", "sensitive_amount"]);

    if (this.$route.query[PROJECT_ID_IDTF]) {
      this._$setActiveProject(this.$route.query[PROJECT_ID_IDTF], true);
    }

    this.fetchFilters();

    eventPipe.$on("update-active-project", () => {
      this.handleUpdateActiveProject();
    });

    this.fetchTableData();
  },
  computed: {
    subprojects() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      );
      if (project && project[0]) {
        return project[0]["subprojects"];
      }
      return [];
    },
  },
  methods: {
    goToLinkUrlControl(item) {
      const url = `/ltt/link-url-control/${item.id}`;
      window.open(url, "_blank");
    },
    clearDomen(url = "") {
      let fixed = String(url);
      fixed = fixed.replaceAll("https://", "");
      fixed = fixed.replaceAll("http://", "");
      fixed = fixed.split("/");
      fixed = fixed.slice(1);
      return fixed.join("/");
    },
    clearTablePagination() {
      this.tablePage = 1;
    },
    async handleUpdateActiveProject() {
      this.fetchTableData();

      this.clearTablePagination();
      await this.fetchFilters();
    },
    goTo(path) {
      window.open(path, "_blank");
    },
    log() {
      console.log(this.code);
    },
    handleGetData() {
      this.fetchTableData();
    },
    getDateColor(date) {
      const currDate = this.$moment(new Date());
      date = this.$moment(date);
      const diff = currDate.diff(date, "days");
      if (diff >= 1 && diff <= 7) return "yellow";
      if (diff > 8) return "red";
      return "";
    },
    getFirstSeenColor(date) {
      const today = this.$moment(new Date());
      const diffMonths = this.$moment(date).diff(today, "months");

      if (diffMonths <= -2) {
        return "blue";
      }

      return "transparent";
    },
    getUrlLengthColor(value) {
      if (value < 500) return "red";
      if (value >= 501 && value <= 1000) return "yellow";
      return "";
    },
    getNeedTopColor(value) {
      if (value >= 1 && value <= 3) return "green";
      return "";
    },
    getCodeColor(value) {
      if (!value) return "";
      if (value[0] === "3" || value[0] === "5") return "yellow";
      if (value[0] === "4") return "red";
      return "";
    },
    getSAColor(value) {
      const fixed = parseInt(value);
      if (fixed >= 0 && fixed <= 3) return "green";
      if (fixed >= 4 && fixed <= 10) return "red";
      return "";
    },
    getAmountColor(value) {
      const fixed = parseInt(value);
      if (fixed >= 0 && fixed <= 3) return "green";
      if (fixed >= 4 && fixed <= 10) return "red";
      return "";
    },
    getDepthColor(value) {
      const fixed = parseInt(value);
      if (fixed >= 0 && fixed <= 3) return "green";
      if (fixed >= 4 && fixed <= 10) return "red";
      return "";
    },
    getAnchorColor(value) {
      let cssClass = "default";
      if (value >= 1 && value <= 3) {
        cssClass = "red";
      }
      if (value >= 4 && value <= 5) {
        cssClass = "yellow";
      }
      if (value >= 6) {
        cssClass = "green";
      }
      return cssClass;
    },
    async fetchFilters() {
      this.loadings.filters = true;

      const filters = ["sub_project", "page_type", "code", "ni"];

      const payload = {
        type: "/content-checker/all-pages",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project || this.project_id,
        },
      };

      const resp = await this.$store.dispatch("global/getFilters", payload);

      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    async fetchTableData() {
      // a
      // clicks
      // code
      // crawled_at
      // depth
      // id
      // impressions
      // links
      // na
      // ni
      // pa
      // page_type
      // phrase
      // priority
      // sa
      // uniq_link
      // url

      try {
        this.loadings.table = true;

        const formData = {
          filter: {
            project: parseInt(this.$store.state.project.active_project),
            url: this.urlID,
            amount: this.amount,
            sensitive_amount: this.sensitive_amount,
          },
        };

        const url = "/content-checker/anchors-info-pages";

        const resp = await service.post(url, formData);

        this.loadings.table = false;

        if (resp) {
          console.log(resp);
          this.tableItems = resp.data.items;
        }
      } catch {
        this.loadings.table = false;
      }
    },
    customSort(items, sortBy, sortDesc) {
      const sortedKeys = ["priority", "need_top", "cl", "imr"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (sortedKeys.includes(sortBy[i])) {
            let aVal, bVal;
            if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
            if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;
          }

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
};
</script>
