<template>
  <v-select
    v-model="selected"
    :items="compItems"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    multiple
    chips
    deletable-chips
    clearable
    solo
    v-on:change="$emit('selectChange', compSelected)"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggle">
        <v-list-item-action>
          <v-icon
            :color="
              model !== undefined && model.length > 0 ? 'indigo darken-4' : ''
            "
          >
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Select All </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "SelectMultiple",
  props: ["items", "model", "label", "itemText", "itemValue"],
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    toggle() {
      if (this.likesAllTag) {
        this.selected = [];
      } else {
        this.selected = this.items.slice();
      }

      this.$emit("selectChange", this.compSelected);
    },
  },
  watch: {
    items() {
      this.selected = [];
    },
    model() {
      this.selected = this.model;
    },
  },
  computed: {
    compItems() {
      return this.items;
    },
    compSelected() {
      return this.selected;
    },
    likesAllTag() {
      let length = 0;
      if (this.selected !== undefined) {
        length = this.selected.length;
      }
      return length === this.items.length;
    },
    likesSomeTag() {
      let length = 0;
      if (this.selected !== undefined) {
        length = this.selected.length;
      }
      return length > 0 && !this.likesAllTag;
    },
    icon() {
      if (this.likesAllTag) return "mdi-close-box";
      if (this.likesSomeTag) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  created() {
    this.selected = this.model;
  },
};
</script>
