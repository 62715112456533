<template>
  <v-card v-if="!loading" class="styled-card--default">
    <v-card-title
      :data-page-navigation="SECTIONS.age_distribution.sectionTitle"
    >
      {{ SECTIONS.age_distribution.sectionTitle }}
      <force-update-button :section-name="sectionName" />
    </v-card-title>
    <v-card-subtitle>
      <last-update-info
        :data="data"
        :successfully-uploaded="successfullyUploaded"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <template v-if="successfullyUploaded">
        <apexcharts
          height="273"
          :options="getOptions"
          :series="getSeries"
        ></apexcharts>
      </template>
      <template v-else>
        <not-collected-placeholder />
      </template>
    </v-card-text>
  </v-card>
  <div v-else class="skeleton loading" style="height: 300px"></div>
</template>

<script>
import apexcharts from "vue-apexcharts";
import LastUpdateInfo from "@/components/SiteAnalysis/UI/LastUpdateInfo.vue";
import SectionLoadingStateMixin from "@/mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "@/components/SiteAnalysis/UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "@/components/SiteAnalysis/UI/ForceUpdateButton.vue";

export default {
  components: {
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
    apexcharts,
  },
  mixins: [SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    //
  }),
  computed: {
    getSeries() {
      return [
        {
          name: "Amount",
          data: this.data?.series || [],
        },
      ];
    },
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "bar",
          height: 200,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        xaxis: {
          categories: this?.data?.labels || [],
        },
      };
    },
  },
  methods: {
    //
  },
};
</script>
