<template>
  <tbody>
    <tr
      v-for="(row, index) in items"
      :key="index"
      :class="`${row.name.includes(',%') ? 'percent-tr' : ''}`"
    >
      <td v-for="(key, i) in Object.keys(row)" :key="key + i">
        <template v-if="key === 'name'">
          <span>{{ row[key] }}</span>
        </template>
        <template v-else-if="key === 'value'">
          <v-sparkline
            v-if="!everyZero(row.value)"
            :value="getArrayData(row.value)"
            width="145"
            height="45"
            :color="getColor(row.value)"
            smooth
            auto-draw
          ></v-sparkline>
        </template>
        <template v-else>
          <conversion7-td-smart
            :data="row[key]"
            :is-percent="row.name.includes(',%')"
          />
        </template>
      </td>
    </tr>
  </tbody>
</template>

<script>
import GetRgb from "@/mixins/GetRgb";
import Conversion7TdSmart from "@/components/Analytics/Conversion/UI/Conversion7/Td/conversion7TdSmart";

export default {
  mixins: [GetRgb],
  components: { Conversion7TdSmart },
  props: ["items"],
  data: () => ({
    green: "rgba(50,136,44,0.21)",
  }),
  methods: {
    everyZero(data) {
      const equalZero = (el) => el === 0;

      if (data.every(equalZero)) {
        return true;
      }
      return false;
    },
    getArrayData(data) {
      if (Array.isArray(data)) {
        return data.map((v) => parseInt(v));
      }
      return data;
    },
    getColor(data) {
      data = this.getArrayData(data);
      if (!data || data.length === 0) {
        return "transparent";
      }
      if (data[0] < data[data.length - 1]) {
        return "red";
      }
      return "green";
    },
  },
};
</script>
