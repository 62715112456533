<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="computedPageConfig.title"
          :breadcrumbs="computedPageConfig.breadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <v-btn
              class="text-normal"
              color="primary"
              @click="isAddTagDialogVisible = true"
            >
              Add new tag <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-data-table
          :loading="loadings.table"
          class="border rounded-lg"
          :items="tableData.items"
          :headers="tableData.headers"
        >
          <template #[`item.active`]="{ value }">
            <v-icon :color="value ? 'success' : 'error'">
              {{ value ? "mdi-check" : "mdi-close" }}
            </v-icon>
          </template>
          <template #[`item._actions`]="{ item }">
            <v-btn icon @click="handleEditTag(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="handleDeleteTag(item)" color="error">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <template #[`item.color`]="{ value }">
            <v-avatar tile :color="value || 'transparent'" size="14" />
            {{ value }}
          </template>
          <template #[`item.created_at`]="{ value }">
            {{ value ? $moment(value).format("ll") : "" }}
          </template>
          <template #[`item.updated_at`]="{ value }">
            {{ value ? $moment(value).format("ll") : "" }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-tag-dialog
      v-model="isAddTagDialogVisible"
      @success="handleTagSuccessfullyAdded"
    />
    <edit-tag-dialog
      v-model="isEditTagDialogVisible"
      :data="editTagData"
      @update:data="editTagData = $event"
      @success="handleSuccessEdit"
    />
    <delete-tag-dialog
      v-model="isDeleteTagDialogVisible"
      :data="deleteTagData"
      @success="handleSuccessfullyDeleteTag"
    />
  </v-container>
</template>

<script>
import PageHeader from "../../../components/UI/PageHeader.vue";
import { GPM_TAGS_TABLE_HEADERS, PAGE_CONFIG } from "./defaults";
import AddTagDialog from "./components/AddTagDialog.vue";
import { GpmTagsApiService } from "./services/GpmTagsApiService";
import EditTagDialog from "./components/EditTagDialog.vue";
import DeleteTagDialog from "./components/DeleteTagDialog.vue";

export default {
  components: { DeleteTagDialog, EditTagDialog, AddTagDialog, PageHeader },

  data: () => ({
    isAddTagDialogVisible: false,
    isEditTagDialogVisible: false,
    isDeleteTagDialogVisible: false,
    tableData: {
      items: [],
      headers: GPM_TAGS_TABLE_HEADERS,
    },
    deleteTagData: null,
    editTagData: null,
    gpmTagsApiService: null,
    loadings: {
      table: false,
    },
  }),

  mounted() {
    this.gpmTagsApiService = GpmTagsApiService();

    this.initializeDashboard();
  },

  computed: {
    computedPageConfig() {
      return PAGE_CONFIG;
    },
  },

  methods: {
    handleSuccessfullyDeleteTag(deletedTagData) {
      this.tableData.items = this.tableData.items.filter(
        (item) => item.id !== deletedTagData.id
      );
    },
    async handleEditTag(tagData) {
      this.editTagData = tagData;

      await this.$nextTick();

      this.isEditTagDialogVisible = true;
    },
    // This method updating table item field with saving reactive rendering
    updateField(source, newData) {
      Object.keys(source).forEach(async (key) => {
        source[key] = newData[key];
        await this.$nextTick();
      });
    },
    handleTagSuccessfullyAdded(newTagData) {
      this.tableData.items.unshift(newTagData);
    },
    handleDeleteTag(tagData) {
      this.deleteTagData = tagData;
      this.isDeleteTagDialogVisible = true;
    },
    async handleSuccessEdit(editedTagData) {
      const idx = this.tableData.items.findIndex(
        (i) => i.id === editedTagData.id
      );

      this.updateField(this.tableData.items[idx], editedTagData);

      await this.$nextTick();

      this.$forceUpdate();
    },
    async initializeDashboard() {
      try {
        this.loadings.table = true;

        const response = await this.gpmTagsApiService.getTags();

        this.tableData.items = response.data?.items || [];
      } catch (e) {
        console.error("Error while fetching tags.", e);
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>
