<template>
  <div
    :class="`colored-td colored-td--clickable ${getColor}`"
    @click="handleOpenWS(getWorkSectionUrl.overdue(data.user_id))"
  >
    {{ data.overdue === 0 ? "" : data.overdue }}
  </div>
</template>

<script>
export default {
  props: ["data", "getWorkSectionUrl"],
  computed: {
    getColor() {
      if (this.data.overdue >= 1 && this.data.overdue <= 10) {
        return "yellow";
      }
      if (this.data.overdue >= 11) {
        return "red";
      }
      return "";
    },
  },
  methods: {
    handleOpenWS(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
