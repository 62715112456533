export function parseNumber(number) {
  return Number(number).toLocaleString("en-US", { maximumFractionDigits: 2 });
}
export function strToRGB(string) {
  let hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    rgb[i] = (hash >> (i * 8)) & 255;
  }
  return {
    red: rgb[0],
    green: rgb[1],
    blue: rgb[2],
  };
}

export function stringToAvatarData(
  string,
  opacity = 1,
  isDark = false,
  options = {}
) {
  if (!string) return "transparent";

  const { red, green, blue } = strToRGB(string);

  let text;

  if (opacity < 0.6) {
    text = isDark ? "white" : `rgba(${red},${green},${blue},1)`;
  } else {
    text = "white";
  }

  if (options?.contrastText) {
    text = isDark ? "white" : "black";
  }

  return {
    rgba: `rgba(${red},${green},${blue},${opacity})`,
    text,
    bg: `linear-gradient(45deg, rgba(${red},${green},${blue},${opacity}) -25%, rgba(${red},${green},${blue},${
      opacity / 2
    }) 125%)`,
  };
}

export function getNameInitials(name) {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
}
