<template>
  <v-container fluid>
    <v-row style="height: 100vh">
      <v-col cols="12" class="d-flex flex-column justify-center text-center">
        <h2>Authorization completed</h2>
        <div class="mt-6">
          <div style="display: inline-block" class="skeleton pa-4">
            Redirection to the application...
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("googleAuth", {
      formData: {
        token: this.$route.query.google_token,
      },
      options: {
        then: () => {
          this.$store.dispatch("fetch_countries_list");
          this.$store.dispatch("fetch_languages_list");
          this.$store.dispatch("fetch_niches_list");

          this.$gtag.config({
            user_id: this.$store.state.auth.user.id,
          });

          const startLocation = this.$store.getters["getStartLocation"];

          if (
            !["/", "/login", "/oauth/login", undefined].includes(startLocation)
          ) {
            return this.$router.push(startLocation);
          }

          this.$router.push("/");
        },
      },
    });
  },
};
</script>
