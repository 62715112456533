import { uid } from "../utils/functions";

export default {
  data: () => ({
    daAnimationClassPrefix: "use-animation",
    daAnimations: {
      wobble: {
        classList: "use-animation--wobble",
      },
      jello: {
        classList: "use-animation--jello",
      },
      highlight: {
        classList: "use-animation--highlight",
      },
    },
    daSessions: {},
  }),

  methods: {
    stopAnimation(id) {
      const session = this.daSessions[id];
      if (session) {
        const { ref, handleAnimationEnd } = session;
        ref.removeEventListener("animationend", handleAnimationEnd);

        // Принудительно удаляем только анимационный класс
        const animationClasses = Array.from(ref.classList).filter((v) =>
          v.includes(this.daAnimationClassPrefix)
        );
        if (animationClasses?.length) {
          ref.classList.remove(...animationClasses);
        }

        // Принудительно сбрасываем стили элемента, чтобы анимация сбросилась
        void ref.offsetWidth; // Это приведёт к перерисовке элемента

        delete this.daSessions[id];
      }
    },

    async animateDom(refElement, type, config = {}) {
      if (!refElement) return console.error("animateDom: refElement is empty.");
      if (!type) return console.error("animateDom: type is empty.");

      const id = config?.id || uid();

      // Если анимация уже проигрывается
      if (this.daSessions[id]) {
        this.stopAnimation(id); // Останавливаем текущую анимацию
      }

      const session = { id, type };
      this.$set(this.daSessions, id, session);

      const animationData = this.daAnimations[type];

      const handleAnimationEnd = () => {
        refElement.removeEventListener("animationend", handleAnimationEnd);

        // Принудительно удаляем только анимационный класс
        const animationClasses = Array.from(refElement.classList).filter((v) =>
          v.includes(this.daAnimationClassPrefix)
        );
        if (animationClasses?.length) {
          refElement.classList.remove(...animationClasses);
        }

        delete this.daSessions[id];
      };

      refElement.addEventListener("animationend", handleAnimationEnd);

      this.$set(session, "ref", refElement);
      this.$set(session, "handleAnimationEnd", handleAnimationEnd);

      await this.$nextTick();

      // Добавляем класс анимации для запуска
      refElement.classList.add(animationData.classList);
    },
  },
};
