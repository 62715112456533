<template>
  <v-card :loading="loading">
    <v-card-title>Percentage of main link types to total</v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        solo
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1"
      :custom-sort="sortTables"
      :items-per-page="15"
    >
      <template v-slot:item="{ item }">
        <tr :class="{ 'indigo lighten-5': item.url === selectedUrl }">
          <td v-for="i in headers.length" :key="i">
            {{ item[Object.keys(item)[i - 1]] }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../mixins/Common";

export default {
  name: "PercentageLinkTypesToTotal",
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  data() {
    return {
      loading: false,
      search: "",
    };
  },
  computed: {
    headers() {
      return this.$store.state.OnePageAnalyzer.percentage_link_types_to_total
        .headers;
    },
    items() {
      return this.$store.state.OnePageAnalyzer.percentage_link_types_to_total
        .items;
    },
  },
  methods: {
    sortTables(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortDesc) {
          return this.sortTablesCompare(a, b);
        } else {
          return this.sortTablesCompare(b, a);
        }
      });
      return items;
    },
    sortTablesCompare(a, b) {
      let visibility_a, visibility_b;

      if (a.url === this.selectedUrl) {
        return 1;
      }

      if (b.url === this.selectedUrl) {
        return -1;
      }

      visibility_a = parseInt(a.visibility);
      visibility_b = parseInt(b.visibility);

      if (visibility_a < visibility_b) return -1;
      if (visibility_a > visibility_b) return 1;

      return 0;
    },
  },
};
</script>

<style scoped></style>
