<template>
  <div>
    <template v-if="data && data.type">
      <v-chip small label :class="`flat-chip flat-chip--${getTypeColor(data)}`">
        <v-icon small left>mdi-tag</v-icon>
        {{ data.type }}
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    getTypeColor(tr) {
      let color = "success";

      if (tr.type && tr.type.toLowerCase() === "current") color = "warning";

      return color;
    },
  },
};
</script>
