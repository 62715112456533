<template>
  <div>
    <v-menu
      v-model="menu"
      bottom
      nudge-left="105px"
      max-width="275"
      rounded="lg"
      transition="slide-y-transition"
      offset-y
      content-class="shadow-e2-bordered"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded icon v-bind="attrs" v-on="on">
          <v-icon>mdi-face-agent</v-icon>
        </v-btn>
      </template>

      <v-card v-if="maintainer" elevation="0">
        <v-card-text>
          <v-row dense>
            <v-col cols="auto">
              <v-avatar size="52">
                <v-img :src="maintainer.avatar" />
              </v-avatar>
            </v-col>
            <v-col cols="fill" class="text-body-2">
              <div>
                <b>{{ maintainer.name }}</b>
              </div>
              <div>
                {{ maintainer.bio }}
              </div>
            </v-col>
            <v-col cols="12" class="pt-4">
              <a :href="maintainer.slack" style="text-decoration: none">
                <v-btn color="primary" class="text-normal">
                  Contact support
                </v-btn>
              </a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { MAINTAINERS } from "@/utils/defaultData";

export default {
  name: "Maintainer",
  data() {
    return {
      menu: false,
      maintainers: MAINTAINERS,
    };
  },
  computed: {
    maintainer() {
      return this.maintainers[this.$route.meta.maintainer];
    },
  },
};
</script>

<style scoped></style>
