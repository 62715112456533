<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    rounded="lg"
    transition="slide-x-transition"
    max-height="450"
    content-class="shadow-e2-bordered"
  >
    <template #activator="{ on }">
      <v-btn v-on="on" color="primary" outlined>
        Fast Track
        <v-icon class="ml-2">mdi-airplane</v-icon>
      </v-btn>
    </template>
    <v-card elevation="0" max-width="300" class="fast-travel">
      <v-card-title
        style="position: sticky; top: 0; background-color: #fff; z-index: 1"
        class="justify-end"
      >
        <a @click="menu = false" class="error--text text-sm-body-1">Close</a>
        <v-text-field
          v-model="sortBy"
          label="Search"
          placeholder="Type to find section"
          append-icon="mdi-magnify"
          background-color="white"
          hide-details
          outlined
          clearable
          dense
          @click:clear="sortBy = ''"
          ref="search"
          autofocus
        >
        </v-text-field>
      </v-card-title>
      <v-list nav dense>
        <v-list-item v-if="getSectionsId.length === 0">
          <div class="placeholder">Nothing... 🙅‍♂️</div>
        </v-list-item>
        <v-list-item
          v-for="item in getSectionsId"
          :key="item.id"
          @click="scrollTo(item.id)"
        >
          {{ item.name }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { LINK_DYNAMIC_DASHBOARD_SECTIONS_IDS } from "@/utils/defaultData";

export default {
  props: ["data"],
  data: () => ({
    menu: false,
    sortBy: "",
    SECTIONS_IDS: LINK_DYNAMIC_DASHBOARD_SECTIONS_IDS,
  }),
  computed: {
    getSectionsId() {
      const output = [];
      const keys = Object.keys(this.SECTIONS_IDS);
      keys.forEach((key) => {
        const item = this.SECTIONS_IDS[key];
        if (this.sortBy !== "" && this.sortBy !== null) {
          const match = String(item.name)
            .toLowerCase()
            .includes(String(this.sortBy).toLowerCase());
          if (match) {
            output.push(item);
          }
          return;
        }
        output.push(item);
      });
      output.sort((a, b) => a.name.localeCompare(b.name));
      return output;
    },
  },
  methods: {
    scrollTo(sectionId) {
      try {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ block: "start", behavior: "smooth" });
      } catch (e) {
        this.$message.notification({
          title: "Can't find section.",
          text: "The section has not yet been added or an unexpected error has occurred. 😢",
          type: "warning",
          duration: 6000,
        });
      }
      this.menu = false;
    },
  },
};
</script>
