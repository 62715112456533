export const GPM_TAGS_ENDPOINTS = {
  GET_TAGS: "/gpm/tags",
  UPDATE_TAG: (id) => `/gpm/tags/${id}`,
  DELETE_TAG: (id) => `/gpm/tags/${id}`,
  CREATE_TAG: "/gpm/tags",
};

export const GPM_TAGS_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Color",
    value: "color",
  },
  {
    text: "is active",
    value: "active",
  },
  {
    text: "Created",
    value: "created_at",
  },
  {
    text: "Updated",
    value: "updated_at",
  },
  {
    text: "Actions",
    value: "_actions",
  },
];

export const PAGE_CONFIG = {
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "GPM",
      to: {
        name: "gpm.main-dashboard",
      },
    },
    {
      text: "Tags control",
      disabled: true,
    },
  ],
  title: "Tags control",
};
