<template>
  <div>
    <v-dialog :retain-focus="false" v-model="inputVal" max-width="1200px">
      <v-container>
        <v-card>
          <template>
            <div v-if="upload.alert.shown">
              <v-alert
                v-for="message in upload.alert.messages.list"
                :key="message"
                outlined
                :type="upload.alert.type"
                prominent
                border="left"
                dismissible
              >
                {{ upload.alert.messages.name }}: {{ message }}
              </v-alert>
            </div>
          </template>

          <v-card-title>
            <span class="headline"
              >&#11088;&#11088;&#11088;&#11088;&#11088; Loader NDC
              &#11088;&#11088;&#11088;&#11088;&#11088;</span
            >
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-card elevation="4">
                  <v-card-title> Import from text </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        1. Put the columns to import in the order in which you
                        make the data</v-col
                      >
                      <v-col cols="12">
                        <v-text-field
                          v-model="upload.text.headers"
                          solo
                          dense
                          :placeholder="'url;date;brand;ndc'"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">2. Insert data (without headers)</v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="upload.text.rows"
                          solo
                          :placeholder="'url <tab> date <tab> brand <tab> ndc'"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="text-center">
                      <v-btn
                        class="text-center align-center justify-center center"
                        color="success"
                        dark
                        rounded
                        @click="upload_data(1)"
                        >Upload
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card elevation="4">
                  <v-card-title>
                    Import from file ->
                    <a
                      href="/file/AnchorsPlanExample.csv"
                      target="_blank"
                      style="font-size: 15px"
                    >
                      Download Example</a
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-file-input
                          v-model="upload.file.names"
                          color="deep-purple accent-4"
                          counter
                          label="File input"
                          multiple
                          placeholder="Select your files"
                          prepend-icon="mdi-paperclip"
                          accept=".csv"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="overline grey--text text--darken-3 mx-2"
                            >
                              +{{ upload.file.names.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="text-center">
                      <v-btn
                        class="text-center align-center justify-center center"
                        color="success"
                        dark
                        rounded
                        @click="upload_data(2)"
                        >Upload
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>

                <v-row>
                  <v-col cols="12">
                    <v-card elevation="4">
                      <v-card-title> Allowed columns </v-card-title>
                      <v-card-text>
                        <p><b>R</b> - required</p>
                        <p><b>url [R]</b> - https://casinotop.co.nz/</p>
                        <p><b>date [R]</b> - 2021-09-01</p>
                        <p><b>brand [R]</b> - YG</p>
                        <p><b>ndc [R]</b> - 121</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadUDC",
  metaInfo: {
    title: "UploadUDC - LTT - SI",
  },
  props: ["value", "level", "task_id"],
  data() {
    return {
      loadings: {},
      upload: {
        modal: false,
        text: {
          headers: "url;date;brand;ndc",
          rows: "",
        },
        file: {
          names: [],
        },
        alert: {
          messages: {
            list: [],
            name: "",
            type: 0,
          },
          shown: false,
          type: "info",
        },
      },
      date_modal: false,
      status: [],
    };
  },
  methods: {
    upload_data(type) {
      let self = this;
      self.update_loading("upload_data", true);

      self.upload.alert.messages = [];
      self.upload.alert.type = "";
      self.upload.alert.shown = false;

      let url = `${self.$store.state.server_url}/analytics/conversion/upload/ndc`;
      let data = {};
      if (type === 1) {
        data = {
          type: type,
          headers: this.upload.text.headers,
          data: this.upload.text.rows,
        };
      } else {
        data = new FormData();
        data.append("file", self.upload.file.names[0]);
        data.append("type", type);
      }

      let config = {
        headers: {
          "Content-Type":
            type === 1 ? "application/json" : "multipart/form-data",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, data, config)
        .then(function (response) {
          self.upload.alert.messages = response.data.messages;
          self.upload.alert.type = response.data.type;
          self.upload.alert.shown = true;
          self.update_loading("upload_data", false);
          self.upload.modal = false;
          self.upload.file.names = [];
        })
        .catch((error) => {
          self.upload.alert.messages = {
            list: [error],
            name: "Axios",
            type: 0,
          };
          self.upload.alert.type = "error";
          self.upload.alert.shown = true;
          self.upload.modal = false;
          self.upload.file.names = [];

          self.update_loading("upload_data", false);
        });
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {},
};
</script>

<style scoped></style>
