import { inject, ref } from "vue";
import { useStore } from "@/store";
import moment from "moment";
import TaskService from "@/services/task/task.service";

export default function useTaskCreating() {
  const store = useStore();
  const message = inject("message");
  const form = ref({
    project_id: null, // required
    parent_task_id: null, // required
    reporter: null, // required
    task_title: null, // required
    task_desc: null,
    priority: 8,
    date: [],
    tags: [],
    estimated_time: null,
    estimated_money: null,
  });
  const presets = {
    need_details: {
      taskNamePrefix: "[Need Details] ",
      taskDescriptionPrefix: `<p>I need details in this task:</p>`,
      tags: [982082],
    },
    need_review: {
      taskNamePrefix: "[Need Review] ",
      taskDescriptionPrefix: `<p>I need review in this task:</p>`,
      tags: [382398],
    },
    incorrectly_created_task: {
      taskNamePrefix: "[!Critical] ",
      taskDescriptionPrefix: `<p>This task is incorrectly formatted:</p>`,
      date: [
        moment(new Date()).add(1, "d").startOf("day").format("YYYY-MM-DD"),
        moment(new Date()).add(1, "d").endOf("day").format("YYYY-MM-DD"),
      ],
      tags: [642378],
    },
    no_final_result_and_plan: {
      taskNamePrefix: "[!Critical] ",
      taskDescriptionPrefix: `<p>There is no final result and no plan in this task:</p>`,
      date: [
        moment(new Date()).add(1, "d").startOf("day").format("YYYY-MM-DD"),
        moment(new Date()).add(1, "d").endOf("day").format("YYYY-MM-DD"),
      ],
      tags: [642378],
    },
    testing_dev: {
      taskNamePrefix: "[!Testing: Dev] ",
      taskDescriptionPrefix: `<p>You can test this task on the dev. version:</p>`,
      date: [
        moment(new Date()).add(1, "d").startOf("day").format("YYYY-MM-DD"),
        moment(new Date()).add(1, "d").endOf("day").format("YYYY-MM-DD"),
      ],
      tags: [993995],
    },
    testing_prod: {
      taskNamePrefix: "[!Testing: Prod] ",
      taskDescriptionPrefix: `<p>You can test this task on the prod. version:</p>`,
      date: [
        moment(new Date()).add(1, "d").startOf("day").format("YYYY-MM-DD"),
        moment(new Date()).add(1, "d").endOf("day").format("YYYY-MM-DD"),
      ],
      tags: [994003],
    },
  };
  const dialog = ref(false);
  const loading = ref(false);

  function fillFormBy(data = {}, type) {
    const preset = presets[type] || {};
    const defaultDateStart = moment(new Date())
      .startOf("day")
      .format("YYYY-MM-DD");
    const defaultDateEnd = moment(new Date()).endOf("day").format("YYYY-MM-DD");
    const presetDateStart = preset?.date ? preset?.date[0] : null;
    const presetDateEnd = preset?.date ? preset?.date[1] : null;
    const start = presetDateStart || defaultDateStart;
    const end = presetDateEnd || defaultDateEnd;

    const email = data.creator_email || store.getters["auth/user"]?.email;

    form.value.task_title = null;
    form.value.tags = null;
    form.value.task_desc = null;
    form.value.project_id = data.project_id;
    form.value.parent_task_id = data.id;
    form.value.date = [start, end];
    form.value.reporter = email;
    form.value.page = data.page;

    if (type === "empty") return (dialog.value = true);

    const title = preset?.taskNamePrefix + data?.name;
    const description =
      presets[type]?.taskDescriptionPrefix +
      `<a href="${data?.task_url}" target="_blank">${data?.task_url}</a>`;

    form.value.task_title = title;
    form.value.tags = preset.tags;
    form.value.task_desc = description;
  }

  async function createTask() {
    try {
      loading.value = true;
      const { status, data } = await TaskService.createTask(form.value);

      if (!status) {
        message({
          title: "Something wronk",
          text: data,
          type: "error",
        });
      } else {
        message({
          title: "Successfully created",
          text: `<a href="${
            "https://ra.worksection.com/" + data.page
          }" target="_blank">${data.name}</a>`,
          type: "success",
        });
      }

      return status;
    } catch (e) {
      console.error("Error while creating task.", e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  async function changeTaskTags(payload) {
    try {
      loading.value = true;
      const resp = await TaskService.changeTags({
        page: form.value?.page,
        plus: payload?.plus,
        minus: payload?.minus,
      });

      if (resp?.data?.success) {
        message({
          title: "Parent task tags changed",
          text: `Changed to <mark>${payload.plus}</mark>`,
          type: "success",
        });
      }
    } catch (e) {
      console.error("Error while creating task.", e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  function handleOpenDialog({ data, type }) {
    fillFormBy(data, type);
    dialog.value = true;
  }

  return {
    handleOpenDialog,
    form,
    dialog,
    loading,
    createTask,
    changeTaskTags,
  };
}
