<template>
  <div
    v-if="value"
    :class="value < 0 ? 'error--text' : 'success--text'"
    style="white-space: nowrap"
  >
    <v-icon small :class="value < 0 ? 'error--text' : 'success--text'">
      {{ getIcon }}
    </v-icon>
    {{ String(value).replace("-", "") }}
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    getIcon() {
      return this.value < 0 ? "mdi-arrow-down-bold" : "mdi-arrow-up-bold";
    },
  },
};
</script>
