<template>
  <div class="time-chart">
    <ApexCharts
      height="275"
      :options="get_chart_options"
      :series="series"
      type="line"
    >
    </ApexCharts>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  props: {
    series: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: null,
    },
    footerTitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    get_chart_options() {
      return {
        legend: {
          position: "right",
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          toolbar: {
            show: false,
          },
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        grid: {
          padding: {
            bottom: -25,
          },
          row: {
            colors: ["rgba(155,155,155,0.25)", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return this.formatNumber(val);
            },
          },
        },
        xaxis: {
          type: this.footerTitle === "Day" ? "datetime" : null,
          categories: this.categories,
        },
      };
    },
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    formatNumber(number) {
      if (isNaN(number)) return 0;

      const suffixes = ["", "k", "m", "b", "t"];

      const sign = Math.sign(number);
      const absNumber = Math.abs(number);

      const suffixIndex = Math.floor(Math.log10(absNumber) / 3);

      const formattedNumber = (absNumber / Math.pow(1000, suffixIndex)).toFixed(
        1
      );

      if (sign === 0) return 0;

      return sign * formattedNumber + suffixes[suffixIndex];
    },
  },
};
</script>
