<template>
  <v-container fluid class="time-spent" style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <h2>Time Spent</h2>
      </v-col>
      <v-col cols="12">
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-8">
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="filter.task_id"
              type="number"
              label="Task ID"
            ></v-text-field>
          </v-col>
          <v-col>
            <smart-date-filter
              :model-value="filter.date_range"
              @update:modelValue="handleDateChange"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              outlined
              v-model="filter.projects"
              :items="projects"
              item-value="id"
              item-text="name"
              label="Projects"
              chips
              small-chips
              deletable-chips
              clearable
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              clearable
              outlined
              v-model="filter.performer"
              :items="performers"
              item-value="id"
              item-text="name"
              label="Performer"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              clearable
              outlined
              v-model="filter.niche"
              :items="niches"
              item-value="id"
              item-text="name"
              label="Niche"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-btn
              @click="fetchData"
              block
              style="height: 40px"
              color="primary"
              :loading="loading"
            >
              Get Data
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="15"
          :hide-default-header="true"
          fixed-header
          :class="`${items.length > 0 ? 'full-height' : ''}`"
        >
          <template #header="{ props }">
            <thead class="v-data-table-header">
              <th
                v-for="header in props.headers"
                :key="header.value"
                :style="
                  header.type === 'status'
                    ? 'background-color: ' +
                      hexToRgbA(
                        responsibilities_colors[header.responsible].bg,
                        0.25
                      )
                    : ''
                "
                :title="header.text"
              >
                {{ header.text }}
                <div
                  class="after"
                  :style="{
                    background:
                      responsibilities_colors[header.responsible] &&
                      responsibilities_colors[header.responsible].bg,
                  }"
                />
              </th>
            </thead>
          </template>
          <template #body="{ items, headers }">
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td
                  v-for="(header, idx) in headers"
                  :key="idx"
                  :style="
                    'background-color: ' + getOverdueColor(item[header.value])
                  "
                >
                  <div v-if="header.value === 'task_id'">
                    {{ item[header.value] }}
                  </div>
                  <div v-if="header.value === 'name'">
                    {{ item[header.value] }}
                    <v-btn small icon>
                      <v-icon small>mdi-calendar-month-outline</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="header.value === 'donor'">
                    <div class="nowrap">
                      <v-btn
                        small
                        icon
                        @click="goTo('/gpm/view-entity/' + item['id'])"
                      >
                        <v-icon small>mdi-login-variant</v-icon>
                      </v-btn>
                      {{ item[header.value] }}
                    </div>
                    <v-chip v-if="item.used" color="warning" small>
                      Last Month
                    </v-chip>
                  </div>
                  <div
                    v-if="
                      item[header.value] !== undefined &&
                      item[header.value].work !== undefined
                    "
                  >
                    <div
                      title="work hours on this step"
                      v-html="getHtml(item[header.value].work)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { RESPONSIBILITIES_COLORS } from "@/utils/selectItems";
import axios from "axios";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import service from "@/plugins/service";

const dateFormat = require("dateformat");
const date = new Date();

export default {
  name: "TimeSpent",
  components: { SmartDateFilter },
  mixins: [FiltersHandling],
  data() {
    return {
      date_modal: false,
      modal: {
        shown: false,
      },
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "GPM",
          disabled: true,
        },
        {
          text: "Universal Dashboard",
          disabled: true,
        },
      ],
      filter: {
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        projects: [],
        statuses: [],
        performer: null,
        niche: null,
        task_id: "",
      },
      search: "",
      loading: false,
      items: [],
      headers: [],
      calendarMode: true,
      performers: [],
      responsibilities_colors: RESPONSIBILITIES_COLORS,
      niches: [],
      main_statuses: {},
      history_by_entity_status: [],
    };
  },
  computed: {
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler(newValue) {
        this._$handleFiltersChange(newValue);
      },
    },
  },
  methods: {
    toggleCalendarMode() {
      this.calendarMode = !this.calendarMode;
    },
    historyExist(historyData, id, headerData) {
      return (
        historyData[id] !== undefined &&
        historyData[id][headerData.status_id] !== undefined
      );
    },
    getHtml(text) {
      if (text === "-") return '<span style="opacity: 0.3">-</span>';

      return text;
    },
    hexToRgbA(hex, opacity = 0.5) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          `,${opacity})`
        );
      }
    },
    handleDateChange(event) {
      this.filter.date_range = event;
      this._$handleFilterChange(event, "date_range");
    },
    goTo(url) {
      window.open(url, "_blank");
    },
    getOverdueColor(item) {
      if (
        item === undefined ||
        item.overdue_percent === undefined ||
        item.overdue_percent <= 100
      ) {
        return "";
      }

      if (item.overdue_percent > 100 && item.overdue_percent <= 200) {
        return "rgba(217,175,8,0.3)";
      }

      return "rgba(192,11,11,0.3)";
    },
    fetchUserSettings() {
      this.loading = true;

      let url = `${this.$store.state.server_url}/user/get-settings`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        if (r.data.gpm !== undefined && r.data.gpm.dashboard !== undefined) {
          this.filter = r.data.gpm.dashboard.filter;
        }
        this.fetchData();
      });
    },
    async fetchData() {
      try {
        const url = "/gpm/task-view/get-dashboard-data";

        let configs = {
          params: {
            typeID: 1,
            filter: this.filter,
            filter_header: this.$route.query,
            dashboardType: "time_spent",
          },
        };

        this.loading = true;

        const resp = await service.get(url, true, configs);

        this.loading = false;

        if (resp && resp.data) {
          this.headers = resp.data.headers;
          this.items = resp.data.items;
          this.performers = resp.data.performers;
          this.niches = resp.data.niches;
          this.main_statuses = resp.data.main_statuses;
          this.history_by_entity_status = resp.data.history_by_entity_status;
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
  },
  created() {
    this._$collectParamsTo(this, "filter", [
      "projects",
      "statuses",
      "date_range",
    ]);

    this.fetchData();
  },
};
</script>

<style lang="scss">
.time-spent {
  .v-data-table {
    $class: &;
    &-header {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    &.full-height {
      .v-data-table__wrapper {
        height: 100vh !important;
      }
    }
    &__wrapper {
      th {
        white-space: break-spaces;
        max-height: 130px;
        padding: 10px 0;
        writing-mode: vertical-lr;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        position: relative;
        &:nth-child(1),
        &:nth-child(2) {
          writing-mode: initial !important;
          vertical-align: bottom;
          padding: 16px 0;
        }
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          width: 100%;
          height: 100%;
          background-color: #fff;
          z-index: -1;
        }

        & .after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 10px;
        }
      }
    }
  }
}

.theme--dark {
  .time-spent {
    .v-data-table {
      &__wrapper {
        th {
          color: #e8e8e8 !important;
          &:before {
            background-color: #2f2f2f;
          }
        }
      }
    }
  }
}
</style>
