<template>
  <td>
    <template v-if="value.length > 1">
      <div
        style="white-space: nowrap"
        class="d-flex align-center justify-center"
      >
        {{ value[1] }}
        <v-icon small color="rgba(0,0,0,0.5)">mdi-chevron-right</v-icon>
        {{ value[0] }}
      </div>
    </template>
    <template v-else>
      {{ value[0] }}
    </template>
  </td>
</template>

<script>
export default {
  props: ["value"],
};
</script>
