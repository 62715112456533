<template>
  <div>
    <v-container fluid v-if="isset_active_project" style="padding-bottom: 200px;">

      <v-row>
        <v-col cols="12">
          <h2>Link Url Control</h2>
        </v-col>
        <v-col cols="12" md="12">
          <v-card
              class="shadow-e1-bordered"
              rounded="lg"
              v-if="isset_active_project"
          >
            <v-card-title>
              <v-row dense>
                <v-col>
                  <v-checkbox label="On Page" @change="fetch_data" v-model="filter.on_page"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Index" @change="fetch_data" v-model="filter.google_index"/>
                </v-col>
                <v-col>
                  <v-checkbox label="Do Follow" @change="fetch_data" v-model="filter.do_follow"/>
                </v-col>
                <v-col>
                  <v-checkbox
                    :label="get_disallow_label"
                    :title="'Disallow file links declined for this URL'"
                    @change="fetch_data"
                    v-model="filter.disallow"
                  />
                </v-col>
                <v-col>
                  <smart-date-filter
                    :model-value="filter.date_range"
                    @update:modelValue="handleChangeDate"
                  />
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col class="d-flex justify-end">
                  <vue-json-to-csv
                    :json-data="table.items"
                    csv-title="link_url_control"
                    :separator="'\t'"
                  >
                    <v-btn width="180px" color="success" text :disabled="table.items.length === 0">
                      Export CSV <v-icon class="ml-2">mdi-export-variant</v-icon>
                    </v-btn>
                  </vue-json-to-csv>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="headline font-weight-bold">
              <v-row class="align-end">
                <v-col sm="12">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      style="max-width: 330px"
                      class="align-end"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                  align="center"
                  justify="center"
              >
                <v-col>
                  <v-data-table
                      :search="search"
                      :headers="table.headers"
                      :items="table.items"
                      :sort-by="['priority']"
                      :sort-desc="[true]"
                      :items-per-page="15"
                      :footer-props="{ itemsPerPageOptions: [50, 100, 200, 300, 500, -1] }"
                      dense
                  >
                    <template v-for="h in table.headers" v-slot:[`header.${h.value}`]="{ header }">
                      <v-tooltip bottom :key="h.text">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="text-center">{{ header.text }}</span>
                        </template>
                        <span>{{ header.title }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.url="{ item }">
                      <a target="_blank" :href="'/ltt/link-url-control/'+item.id">{{ item.url }}</a>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
            project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>

import axios from "axios";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import VueJsonToCsv from "vue-json-to-csv";


export default {
  name: "LinkUrlControl",
  components: { SmartDateFilter, VueJsonToCsv },
  metaInfo: {
    title: 'Link Url Control - LTT - SI'
  },
  data() {
    return {
      date_modal: false,
      search: '',
      modal: {
        shown: false,
      },
      loadings: {
        fetch_data: false,
      },
      links_declined_this_url: 0,
      filter: {
        date_range: [null, null],
        disallow:true,
        do_follow:false,
        on_page: true,
        google_index: false,
        follow: false,
        link_types: undefined,
        anchor_types: undefined,
      },
      table: {
        headers: [],
        items: [],
      },
      export_data_status: false,
      export_data: {
        items: [],
        dataFile: 'serp_export.csv',
        labels: {},
        fields: [],
      },
    }
  },
  methods: {
    handleChangeDate(event) {
      this.filter.date_range = event

      if(this.filter.date_range.length === 2) this.fetch_data()
    },
    fetch_data() {
      let self = this;
      self.modal.shown = false
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading('fetch_data', true)

        let url = `${self.$store.state.server_url}/ltt/link-url-control/${self.id_project}`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {
          filter: this.filter
        }

        self.table.headers = []
        self.table.items = []

        axios.post(url, data, config)
            .then(function (response) {
              self.links_declined_this_url = response.data.links_declined_this_url
              self.table.items = response.data.data
              self.table.headers = response.data.headers
              self.update_loading('fetch_data', false)
            }).catch(function (error) {
          alert(error);
        })
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false
      this.loadings[type] = value
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true
        }
      }
    },
  },
  computed: {
    get_disallow_label() {

      let count_disallow = ""
      if (this.links_declined_this_url > 0) {
        count_disallow = "("
            + this.links_declined_this_url + ")"
      }

      return 'Disallow' + count_disallow
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    niches() {
      return this.$store.state.reuse.niches
    },
  },
  created() {
    let self = this;

    if (self.niches.length === 0) {
      this.$store.dispatch('fetch_niches_list')
    }

    self.fetch_data()
  },
}
</script>

<style scoped>

</style>