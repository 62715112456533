<template>
  <v-dialog
    v-model="dialogValue"
    persistent
    content-class="remove-dialog-shadow"
    width="auto"
  >
    <v-card class="styled-card--default" rounded="lg">
      <v-card-title style="font-size: 14px">
        Create subtask
        <v-spacer />
        <v-btn icon @click="dialogValue = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="d-flex justify-center pt-4 px-0">
        <task-card
          :form="form"
          borderless
          :filters-data="{
            users: filtersData.executives,
            tags: filtersData.labels,
          }"
          :loadings="{ createTask: loading, filters: false }"
          disable-create-button
          @createTask="$emit('createTask')"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex">
        <v-spacer />
        <v-chip
          label
          color="transparent"
          class="error--text mr-2"
          @click="dialogValue = false"
        >
          Close</v-chip
        >
        <v-chip
          :disabled="loading"
          color="success"
          style="width: 130px"
          class="d-flex justify-center"
          label
          @click="$emit('createTask')"
        >
          Create
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="12"
            width="2"
            class="ml-1"
          />
        </v-chip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, defineComponent } from "vue";
import TaskCard from "@/components/SimilarTaskCreating/UI/TaskCard.vue";

export default defineComponent({
  components: { TaskCard },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    filtersData: {
      type: Object,
      default: () => ({}),
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dialogValue = computed({
      get: () => props.dialog,
      set: (value) => emit("update:dialog", value),
    });

    return {
      dialogValue,
    };
  },
});
</script>

<style lang="scss" scoped></style>
