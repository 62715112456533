<template>
  <div class="ma-4">
    <v-row dense>
      <v-col
        v-for="card in componentData()?.items || []"
        :key="card.id"
        cols="3"
      >
        <v-card
          flat
          rounded="lg"
          :color="card.color || 'rgba(0,0,0,0.05)'"
          class="pa-4 info-card"
        >
          <v-row dense>
            <v-col cols="fill" class="info-card__text-wrapper">
              <div class="info-card__value">{{ parseNumber(card.value) }}</div>
              <div v-if="card.text" class="info-card__label">
                {{ card.text }}
              </div>
            </v-col>
            <v-col v-if="card.icon" cols="auto" class="d-flex flex-row-reverse">
              <v-icon style="opacity: 0.4">
                {{ card.icon || "mdi-chart-areaspline" }}
              </v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { parseNumber } from "../../../functions";

export default {
  inject: ["componentData"],

  methods: {
    parseNumber(data) {
      return parseNumber(data);
    },
  },
};
</script>

<style lang="scss">
.dashboard-dev-detailed-page-v2 {
  .info-card {
    height: 100%;
    &__value {
      font-size: 2rem;
      font-weight: 600;
    }
    &__label {
      font-size: 0.875rem;
      margin-top: 0.875rem;
    }
  }
}
</style>
