export const pageConfig = {
  title: "Content team performance",
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Site Workplace",
      disabled: true,
    },
    {
      text: "Content team performance",
      disabled: true,
    },
  ],
};
