<template>
  <v-row dense>
    <v-col cols="12">
      <smart-date-filter
        label="Date"
        :model-value="date"
        @update:modelValue="date = $event"
        :custom-presets="presets"
        :disable-default-presets="true"
      />
    </v-col>
    <v-col cols="12">
      <div v-if="date.length > 0">
        <v-row dense class="d-flex align-center" style="font-size: 12px">
          <v-col v-if="date[0]">
            <v-icon>mdi-ray-start</v-icon>
            <b>{{ $moment(date[0]).calendar() }}</b>
          </v-col>
          <v-col v-if="date[1]" class="d-flex justify-end align-center">
            <b class="mr-1">{{ $moment(date[1]).calendar() }}</b>
            <v-icon>mdi-ray-end</v-icon>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";

export default {
  components: { SmartDateFilter },
  props: {
    modelValue: {},
  },
  data: () => ({
    tab: 0,
    menu: false,
    presets: [],
  }),
  created() {
    const dateFormat = "YYYY-MM-DD";
    const dateFormatWithTime = "YYYY-MM-DD hh:mm:ss";

    const today6pm = this.$moment().format(dateFormat) + " 18:00:00";
    const tomorrow12pm =
      this.$moment().add(1, "days").format(dateFormat) + " 12:00:00";
    const tomorrow18pm =
      this.$moment().add(1, "days").format(dateFormat) + " 18:00:00";

    const now = this.$moment().format(dateFormatWithTime);

    this.presets = [
      {
        id: 0,
        name: "Today. <small>Until the end of the day at 18:00</small>",
        range: [now, today6pm],
      },
      {
        id: 1,
        name: "Tomorrow. <small>Before noon 12:00</small>",
        range: [now, tomorrow12pm],
      },
      {
        id: 2,
        name: "Tomorrow. <small>Until the end of the day at 18:00</small>",
        range: [now, tomorrow18pm],
      },
      {
        id: 4,
        name: "7 days",
        range: [now, this.$moment().add(7, "days").format(dateFormatWithTime)],
      },
      {
        id: 5,
        name: "14 days",
        range: [now, this.$moment().add(14, "days").format(dateFormatWithTime)],
      },
      {
        id: 6,
        name: "Month",
        range: [
          now,
          this.$moment().add(1, "months").format(dateFormatWithTime),
        ],
      },
    ];
  },
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    date(value) {
      if (Array.isArray(value) && !value.length > 0) return;

      if (this.date[0] && !this.date[0].includes(":")) {
        this.date[0] = this.date[0] + " 08:00:00";
      }

      if (this.date[1] && !this.date[1].includes(":")) {
        this.date[1] = this.date[1] + " 18:00:00";
      }
    },
  },
  methods: {
    handleSetDate(data) {
      this.date = data;
    },
  },
};
</script>
