var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('div',[_c('div',[_c('b',[_vm._v("SW Category")])]),(
              !_vm.loadings.section.includes(_vm.sections.main_static.sectionName)
            )?_c('div',{staticStyle:{"font-size":"14px"}},[(
                _vm.sectionsData.main_static &&
                _vm.sectionsData.main_static.similar_web_category
              )?[_vm._v(" "+_vm._s(_vm.sectionsData.main_static.similar_web_category)+" ")]:[_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v("nothing...")])],(_vm.computedInTop25)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("in top 25")]):_vm._e()],2):_c('div',{staticClass:"loading skeleton",staticStyle:{"height":"26px","width":"220px"}})])]),_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('div',[_c('b',[_vm._v("TR Category")]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleNavigateToSection('TR Category')}}},[_vm._v(" mdi-navigation-variant-outline ")])],1),(
                !_vm.loadings.section.includes(_vm.sections.tr_categories.sectionName)
              )?_c('div',{staticStyle:{"font-size":"14px"}},[(_vm.computedTrCategory)?[_vm._v(" "+_vm._s(_vm.computedTrCategory)+" ")]:[_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v("nothing...")])]],2):_c('div',{staticClass:"loading skeleton",staticStyle:{"height":"26px","width":"160px"}})])])]),_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_c('b',[_vm._v("TR Themes")]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleNavigateToSection('TR Site Topics')}}},[_vm._v(" mdi-navigation-variant-outline ")])],1),(!_vm.loadings.section.includes(_vm.sections.tr_topics.sectionName))?_c('div',{staticClass:"overflow-chip"},[(
                Array.isArray(_vm.computedTrThemes) && _vm.computedTrThemes.length > 0
              )?_vm._l((_vm.computedTrThemes),function(theme){return _c('v-chip',{key:theme,attrs:{"small":""}},[_c('span',[_vm._v(" "+_vm._s(theme)+" ")])])}):[_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v("nothing...")])]],2):_c('div',{staticClass:"d-flex"},_vm._l(([0, 1, 2]),function(i){return _c('div',{key:i,staticClass:"skeleton loading mr-2",staticStyle:{"width":"85px","height":"26px"}})}),0)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }