<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="400px"
    content-class="my-shadow--e4"
  >
    <v-card rounded="lg">
      <v-card-title class="text-body-2">
        Bad Donor Status
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <div class="mb-1 text-body-2">Select tags</div>
        <v-autocomplete
          v-model="selectedTags"
          outlined
          clearable
          :items="tags"
          item-text="text"
          item-value="value"
          multiple
          placeholder="Type to search"
          chips
          deletable-chips
          hide-details="auto"
          :loading="filtersPending"
        >
        </v-autocomplete>
      </v-card-text>
      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text class="px-4 text-normal" large>
          Cancel
        </v-btn>
        <v-btn
          :disabled="selectedTags.length === 0"
          @click="sendDonorToBad"
          class="px-4 text-normal"
          color="error"
          large
          :loading="pending"
        >
          Decline
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { gpmDonorApiService } from "../services/GpmDonorApiService";

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    filtersPending: {
      type: Boolean,
      default: false,
    },
  },

  inject: ["headerData"],

  emits: ["callback"],

  mixins: [Dialog],

  data: () => ({
    selectedTags: [],
    gpmDonorApiService: null,
    pending: false,
  }),

  created() {
    this.gpmDonorApiService = gpmDonorApiService();

    // Pre-select already existed tags
    if (this.headerData()?.selected_bad_donor_statuses)
      this.selectedTags = this.headerData()?.selected_bad_donor_statuses.map(
        (v) => v.id
      );
  },

  methods: {
    async sendDonorToBad() {
      this.pending = true;

      await this.gpmDonorApiService.markAsBad(
        this.selectedTags,
        this.$route.params.id
      );

      this.pending = false;
      this.$emit("callback");
    },
  },
};
</script>
