<template>
  <v-menu
    content-class="my-shadow--e2"
    :close-on-content-click="false"
    offset-y
    transition="slide-y-transition"
    max-width="290"
  >
    <template #activator="{ on }">
      <div v-on="on">
        <v-text-field
          v-model="date"
          :label="hideLabel ? null : label || 'Pick day'"
          prepend-inner-icon="mdi-calendar"
          :outlined="!filled"
          :filled="filled"
          dense
          :placeholder="placeholder"
          hide-details="auto"
          :error-messages="errorMessages"
          readonly
        ></v-text-field>
      </div>
    </template>
    <v-card class="styled-card--default pa-0">
      <v-date-picker v-model="date" scrollable :min="min"> </v-date-picker>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    modelValue: {},
    errorMessages: Array,
    label: String,
    placeholder: String,
    filled: Boolean,
    hideLabel: Boolean,
    min: {},
  },
  emits: ["update:modelValue"],
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
