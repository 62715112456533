<template>
  <v-card v-if="!loading" class="styled-card--default">
    <v-card-title
      :data-page-navigation="SECTIONS.sr_dynamic_traffic_keywords.sectionTitle"
    >
      <!--      SR Dynamic Traffic Keywords-->
      {{ SECTIONS.sr_dynamic_traffic_keywords.sectionTitle }}
      <force-update-button :section-name="sectionName" />
    </v-card-title>
    <v-card-subtitle>
      <last-update-info
        :data="data"
        :successfully-uploaded="successfullyUploaded"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <template v-if="successfullyUploaded">
        <apexchart-zoom-buttons
          @zoom="handleZoom"
          :categories="data.categories"
        />

        <apexcharts
          type="area"
          ref="chart"
          height="273"
          :options="getOptions()"
          :series="data && data.series"
        ></apexcharts>
      </template>
      <template v-else>
        <not-collected-placeholder />
      </template>
    </v-card-text>
  </v-card>
  <div v-else class="skeleton loading" style="height: 300px"></div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";
import ApexchartZoomButtons from "@/components/UI/ApexchartZoomButtons.vue";

export default {
  components: {
    ApexchartZoomButtons,
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
    apexcharts: VueApexCharts,
  },
  mixins: [SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    //
  }),
  methods: {
    handleResetZoom() {
      this.$refs.chart;
    },
    handleZoom({ start, end }) {
      this.$refs.chart.zoomX(end, start);
    },
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          id: "area-datetime",
          type: "area",
          height: 283,
          // zoom: {
          //   autoScaleYaxis: true,
          // },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        xaxis: {
          type: "datetime",
          min: this.$moment(this.data?.categories[0]).valueOf(),
          tickAmount: 6,
          // categories: this.data?.categories || [],
        },
      };
    },
  },
};
</script>
