var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v("Compare Phrases")]),_c('v-card-subtitle',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","solo":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.isReady)?_c('v-data-table',{staticClass:"elevation-1 v-data-table-small",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":['volume', 'cl', 'imp'],"sort-desc":[true, true, true],"items-per-page":15},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.phrase},[_c('td',[_vm._v(_vm._s(item.phrase))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.volume))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.pos))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.pos_7d))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.cl))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.imp))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.ctr))]),_c('td',{staticClass:"text-center",style:('background-color: ' +
              (item.title === 0 &&
              (_vm.getVisionMedian(item.phrase, 'title') > 0 ||
                _vm.getTop10Median(item.phrase, 'title') > 0)
                ? '#ffeb3b47'
                : ''))},[_c('div',{domProps:{"innerHTML":_vm._s(item.title)}}),_vm._v(" "+_vm._s(_vm.getVisionMedian(item.phrase, "title"))+" / "+_vm._s(_vm.getTop10Median(item.phrase, "title"))+" ")]),_c('td',{staticClass:"text-center",style:('background-color: ' +
              (item.description === 0 &&
              (_vm.getVisionMedian(item.phrase, 'description') > 0 ||
                _vm.getTop10Median(item.phrase, 'description') > 0)
                ? '#ffeb3b47'
                : ''))},[_c('div',{domProps:{"innerHTML":_vm._s(item.description)}}),_vm._v(" "+_vm._s(_vm.getVisionMedian(item.phrase, "description"))+" / "+_vm._s(_vm.getTop10Median(item.phrase, "description"))+" ")]),_c('td',{staticClass:"text-center",style:('background-color: ' +
              (item.h1 === 0 &&
              (_vm.getVisionMedian(item.phrase, 'h1') > 0 ||
                _vm.getTop10Median(item.phrase, 'h1') > 0)
                ? '#ffeb3b47'
                : ''))},[_c('div',{domProps:{"innerHTML":_vm._s(item.h1)}}),_vm._v(" "+_vm._s(_vm.getVisionMedian(item.phrase, "h1"))+" / "+_vm._s(_vm.getTop10Median(item.phrase, "h1"))+" ")]),_c('td',{staticClass:"text-center",style:('background-color: ' +
              (item.text === 0 &&
              (_vm.getVisionMedian(item.phrase, 'text') > 0 ||
                _vm.getTop10Median(item.phrase, 'entry') > 0)
                ? '#ffeb3b47'
                : ''))},[_c('div',{domProps:{"innerHTML":_vm._s(item.text)}}),_vm._v(" "+_vm._s(_vm.getVisionMedian(item.phrase, "text"))+" / "+_vm._s(_vm.getTop10Median(item.phrase, "entry"))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.inner_anchor))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.links_anchor))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.links_anchor_broad))])])}),0)]}}],null,false,2579844216)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }