<template>
  <td class="text-center">
    <div v-if="getType === types.degree">
      <template v-if="data.errors || data.donors">
        <span> {{ data.donors }} </span>
        <sup>{{ data.errors }}</sup>
      </template>
    </div>
    <div
      v-else-if="getType === types.percent"
      :class="`colored-td colored-td--inline ${getColor}`"
    >
      <span v-if="data.errorsPercent > 0">{{ data.errorsPercent }}%</span>
      <span v-else>-</span>
    </div>
    <div v-else>
      <div v-if="data.donorsCurWeek > 0">
        {{ data.donorsCurWeek }}
      </div>
      <div v-else>-</div>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  data: () => ({
    types: {
      degree: "degree",
      percent: "percent",
      default: "default",
    },
  }),
  computed: {
    getColor() {
      const percent = this.data.errorsPercent;
      if (percent > 0 && percent <= 33) {
        return "green";
      }
      if (percent > 33 && percent <= 66) {
        return "yellow";
      }
      if (percent > 66 && percent <= 100) {
        return "red";
      }
      return "";
    },
    getType() {
      const data = this.data;
      const exist = this.exist;
      if (exist(data.errors) && exist(data.donors)) {
        return this.types.degree;
      }
      if (exist(data.errorsPercent)) {
        return this.types.percent;
      }
      return this.types.default;
    },
  },
  methods: {
    exist(data) {
      return data !== undefined && data !== null;
    },
  },
};
</script>
