<template>
  <b>{{ getData }}</b>
</template>

<script>
export default {
  props: ["filter", "data", "info"],

  computed: {
    getData() {
      const { key, value } = this.filter;
      const data = this.data[this.info[key]?.dataKey];

      if (data && data?.length && Array.isArray(value)) {
        return data
          .filter((item) => value.includes(item.value))
          .map((item) => item.text)
          .join(", ");
      }

      if (data && data?.length) {
        return data
          .filter((item) => item.value === value)
          .map((item) => item.text)
          .join(", ");
      }

      if (Array.isArray(value)) return value?.join(", ");

      return value;
    },
  },
};
</script>
