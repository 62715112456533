import service from "../../../plugins/service";

export const projectApiService = () => {
  const endpoints = {
    project: "/project",
  };

  const createProject = (formData) => {
    return service.post(endpoints.project, formData);
  };

  return {
    createProject,
  };
};
