<template>
  <div v-if="item" class="d-flex" style="gap: 0.25rem">
    <v-btn
      class="text-normal"
      @click="$emit('upload', { action: item, config: {} })"
      :loading="uploadedActionsStatuses[item.id] === 'pending'"
      width="120"
      :color="computedUploadButtonColor"
    >
      {{ computedUploadButtonText }}

      <v-icon small right>
        {{ computedUploadButtonIcon }}
      </v-icon>
    </v-btn>
    <!--    <v-btn-->
    <!--      @click="handleChangeLocation(item, 0)"-->
    <!--      class="text-normal px-0"-->
    <!--      :class="{-->
    <!--        'flat-chip flat-chip&#45;&#45;primary':-->
    <!--          uploadedActionsDetails[item.id]?.location === 0 &&-->
    <!--          uploadedActionsStatuses[item.id] !== 'error',-->
    <!--        'flat-chip flat-chip&#45;&#45;error':-->
    <!--          uploadedActionsDetails[item.id]?.location === 0 &&-->
    <!--          uploadedActionsStatuses[item.id] === 'error',-->
    <!--      }"-->
    <!--      style="min-width: 36px"-->
    <!--    >-->
    <!--      <v-icon small> mdi-arrow-bottom-left </v-icon>-->
    <!--    </v-btn>-->
    <!--    <v-btn-->
    <!--      @click="handleChangeLocation(item, 1)"-->
    <!--      class="text-normal px-0"-->
    <!--      style="min-width: 36px"-->
    <!--      :class="{-->
    <!--        'flat-chip flat-chip&#45;&#45;primary':-->
    <!--          uploadedActionsDetails[item.id]?.location === 1 &&-->
    <!--          uploadedActionsStatuses[item.id] !== 'error',-->
    <!--        'flat-chip flat-chip&#45;&#45;error':-->
    <!--          uploadedActionsDetails[item.id]?.location === 1 &&-->
    <!--          uploadedActionsStatuses[item.id] === 'error',-->
    <!--      }"-->
    <!--    >-->
    <!--      <v-icon small> mdi-arrow-bottom-right </v-icon>-->
    <!--    </v-btn>-->
  </div>
</template>

<script>
export default {
  emits: ["upload"],

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    uploadedActionsStatuses: {
      type: Object,
      default: () => ({}),
    },
    uploadedActionsDetails: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    computedUploadButtonText() {
      return {
        success: "Refresh",
        error: "Error",
        pending: "Pending",
        undefined: "Upload",
      }[String(this.uploadedActionsStatuses[this.item.id])];
    },
    computedUploadButtonIcon() {
      return {
        success: "mdi-refresh",
        error: "mdi-alert-outline",
        pending: "",
        undefined: "mdi-download",
      }[String(this.uploadedActionsStatuses[this.item.id])];
    },
    computedUploadButtonColor() {
      return {
        success: "secondary",
        error: "error",
        pending: "",
        undefined: "",
      }[String(this.uploadedActionsStatuses[this.item.id])];
    },
  },

  methods: {
    handleChangeLocation(action, location) {
      const isActionAlreadyUploaded =
        this.uploadedActionsStatuses[action.id] !== undefined;

      if (isActionAlreadyUploaded) {
        return this.$set(
          this.uploadedActionsDetails[action.id],
          "location",
          location
        );
      }

      this.$emit("upload", { action, config: { location } });
    },
  },
};
</script>
