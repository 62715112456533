<template>
  <div>
    <apexchart type="line" height="600" :options="options" :series="data.series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ['data', 'options'],
  components: {
    apexchart: VueApexCharts,
  },
}
</script>