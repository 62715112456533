<template>
  <td>
    <div :class="`colored-td ${getData.expired ? 'red' : ''}`">
      <span class="nowrap">
        {{getDate}}
      </span>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getDate() {
      let result;

      if(this.data && this.data.wm_response_at)
        result = this.data.wm_response_at

      if(this.data && this.data.wm_response_at && this.data.wm_response_at.text)
        result = this.data.wm_response_at.text

      if(result) return this.$moment(result).format('DD/MM')

      return ''

    },
    getData() {
      return this.data.wm_response_at ? this.data.wm_response_at : {};
    },
  },
};
</script>
