<template>
  <div>
    <v-btn fixed dark fab bottom right color="pink" @click="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title> Create Task </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="form.name"
              :rules="rules.name"
              label="Name"
              required
              solo
              no-resize
            ></v-text-field>
            <v-subheader class="pa-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </span>
              </v-tooltip>
            </v-subheader>
            <v-textarea
              v-model="form.domains"
              :rules="rules.domains"
              label="Domains"
              required
              solo
              no-resize
            ></v-textarea>
            <v-subheader class="pa-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </span>
              </v-tooltip>
            </v-subheader>
            <v-text-field
              v-model="form.primary_key"
              :rules="rules.primary_key"
              label="Primary key"
              required
              solo
              no-resize
            ></v-text-field>
          </v-form>
          <v-alert type="error" text v-if="error !== null">
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="primary"
            text
            @click="createTask"
            :disabled="status_importing"
            :loading="status_importing"
          >
            Create Task
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateTaskThemes",
  data() {
    return {
      dialog: false,
      valid: true,
      error: null,
      form: {
        name: null,
        primary_key: null,
        domains: "",
      },
      rules: {
        name: [(v) => !!v || "Name is required"],
        primary_key: [(v) => !!v || "Primary Key is required"],
        domains: [(v) => !!v || "Domains is required"],
      },
      status_importing: false,
      errors: [],
    };
  },
  methods: {
    createTask() {
      let self = this;

      if (!self.$refs.form.validate()) {
        return;
      }

      let domains = self.form.domains
        .split("\n")
        .map((v) => v.trim())
        .filter((v) => v.length > 0);

      self.status_importing = true;
      self.error = null;

      let url = `${self.$store.state.server_url}/gtt`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        name: self.form.name,
        domains: domains,
        primary_key: self.form.primary_key,
      };

      axios
        .post(url, data, config)
        .then(() => (self.dialog = false))
        .catch(() => (self.error = "Some Error"))
        .finally(() => (self.status_importing = false));
    },
  },
};
</script>

<style scoped></style>
