<template>
  <div :style="computedStyles">
    {{ value }}
  </div>
</template>

<script>
export default {
  props: ["value", "data"],

  computed: {
    computedStyles() {
      let color = "inherit";
      let value = this.value;

      if (value >= 200) {
        color = "green";
      }
      if (value >= 300) {
        color = "orange";
      }
      if (value >= 400) {
        color = "red";
      }

      return {
        color,
      };
    },
  },
};
</script>
