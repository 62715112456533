<template>
  <v-dialog v-model="dialog" width="420px">
    <v-card class="styled-card--default">
      <v-card-title>
        Edit url <v-chip small class="ml-2">{{ data.type }}</v-chip>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-textarea
          v-model="form.url"
          filled
          label="Url"
          auto-grow
          placeholder="Enter url"
        ></v-textarea>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <extended-chip
          large
          class="px-4 mr-2"
          label
          color="transparent"
          @click="dialog = false"
        >
          Back
        </extended-chip>
        <extended-chip
          large
          class="px-4"
          label
          color="success"
          :loading="loading"
          @click="save"
        >
          Save changes
        </extended-chip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";
import service from "@/plugins/service";

export default {
  components: { ExtendedChip },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [Dialog],
  data: () => ({
    loading: false,
    form: {
      url: "",
    },
  }),
  watch: {
    dialog(isOpen) {
      if (isOpen) this.setupDefaultData();
    },
  },
  methods: {
    setupDefaultData() {
      const path = this.data.type + "_url";

      this.form.url = this.data.urlData[path];
    },
    async save() {
      try {
        const url = `/ltt/update-url/${this.data.urlData.id}`;

        const payload = {
          url: this.form.url,
          type: this.data.type,
        };

        this.loading = true;

        const response = await service.post(url, payload);

        if (response.data.success) {
          this.$message.notification({
            title: "Successfully changed.",
            text: "Changes will be available after loading new data into the table.",
            type: "success",
          });
          this.dialog = false;
        }
      } catch (e) {
        console.error("Error while saving.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
