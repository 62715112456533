<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="1200px"
    content-class="my-shadow--e4"
  >
    <v-card class="styled-card--default">
      <element-edit-dialog-header
        :data="elementData"
        :types="TYPES"
        @closeDialog="dialog = false"
      />
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="6">
            <element-edit-configuration
              :data="elementData"
              :statuses="statuses"
              :filtersData="filtersData"
              @resetCheckboxes="handleResetCheckboxes"
            />
          </v-col>
          <v-col cols="6">
            <edit-element-checklist
              :data="elementData"
              :is-checklist-reset="isChecklistReset"
              :checkboxes-history="checkboxesHistory"
              :checkboxes="elementData?.checkboxes?.items"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <edit-element-links :data="elementData?.dialog_field_data" />
          </v-col>
          <template
            v-if="
              [TYPES.TITLE, TYPES.H1, TYPES.DESCRIPTION].includes(
                elementData?.dialog_field_data?.field_id
              )
            "
          >
            <v-col cols="12">
              <edit-element-info :data="elementData?.field_data" />
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="12">
            <edit-element-element-hisory :data="elementData" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-chip label class="mr-2" @click="dialog = false">Close</v-chip>
        <v-chip
          label
          class="mr-2"
          color="success"
          @click="handleSaveAndReloadPage"
          :disabled="loadings.saving"
        >
          Save and reload page
          <v-icon v-if="!loadings.saving" small right>mdi-refresh</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            size="16"
            width="2"
            class="ml-2"
          />
        </v-chip>
        <v-chip
          label
          color="success"
          @click="handleSave"
          :disabled="loadings.saving"
        >
          Save
          <v-icon v-if="!loadings.saving" small right>mdi-content-save</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            size="16"
            width="2"
            class="ml-2"
          />
        </v-chip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import ElementEditDialogHeader from "@/components/SiteWorkplace/UI/ElementEditDialogHeader.vue";
import ElementEditConfiguration from "../UI/ElementEditConfiguration.vue";
import EditElementInfo from "@/components/SiteWorkplace/UI/EditElementInfo.vue";
import EditElementLinks from "@/components/SiteWorkplace/UI/EditElementLinks.vue";
import EditElementChecklist from "@/components/SiteWorkplace/UI/EditElementChecklist.vue";
import EditElementElementHisory from "@/components/SiteWorkplace/UI/EditElementElementHisory.vue";
import { deepClone } from "@/utils/functions";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";

export default {
  components: {
    EditElementElementHisory,
    EditElementChecklist,
    EditElementLinks,
    EditElementInfo,
    ElementEditConfiguration,
    ElementEditDialogHeader,
  },
  props: {
    elementData: {},
    filtersData: {
      type: Object,
      default: () => ({}),
    },
    statuses: Array,
    history: Array,
    mode: String,
  },
  mixins: [Dialog, Notification],
  data: () => ({
    checkboxesHistory: null,
    TYPES: {
      TITLE: 5,
      H1: 6,
      DESCRIPTION: 7,
    },
    loadings: {
      saving: false,
    },
    isChecklistReset: false,
  }),
  computed: {
    getProjectId() {
      return this.$store.state.project.active_project;
    },
    getSavingPayload() {
      return {
        ...this.elementData.dialog_field_data,
        data: {
          ...this.elementData.field_data,
        },
        status: this.elementData.dialog_field_data.status_id,
      };
    },
  },
  methods: {
    handleSave() {
      this.saveField();
    },
    async handleSaveAndReloadPage() {
      await this.saveField();
      window.location.reload();
    },
    handleResetCheckboxes() {
      if (this.elementData?.dialog_field_data?.checkboxes?.length > 0) {
        this.checkboxesHistory = deepClone(
          this.elementData.dialog_field_data.checkboxes
        );
        this.elementData.dialog_field_data.checkboxes = [];

        this.isChecklistReset = true;

        setTimeout(() => {
          this.isChecklistReset = false;
        }, 500);
      }
    },
    async saveField() {
      try {
        if (!this.elementData?.dialog_field_data?.project_id)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        this.loadings.saving = true;

        const url = `/site-workplace/${this.elementData?.dialog_field_data?.project_id}/save-field`;
        const payload = this.getSavingPayload;

        const response = await service.post(url, payload);

        if (response?.data?.success) {
          this.dialog = false;
          return true;
        }

        this.fetchData();
      } catch (e) {
        console.error("Error while saving field.", e);
      } finally {
        this.loadings.saving = false;
      }
    },
    statusIcon(status) {
      switch (status) {
        case 0:
          return "mdi-flask-empty-outline";
        case 1:
          return "mdi-clock-outline";
        case 2:
          return "mdi-check";
        default:
          return "mdi-close";
      }
    },
  },
};
</script>
