<template>
  <div>
    <template v-if="data.deadline">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <span v-if="data.overdue === 0 || !data.overdue">
              {{ data.deadline ? $moment(data.deadline).format("MMM DD") : "" }}
            </span>
            <v-chip
              v-else
              :color="`${data.overdue === 1 ? 'error' : ''} ${
                data.overdue === 2 ? 'warning' : ''
              }`"
            >
              {{ data.deadline ? $moment(data.deadline).format("MMM DD") : "" }}
            </v-chip>
          </div>
        </template>
        <div>
          First deadline:
          <b>{{ data.first_deadline ? data.first_deadline : "None" }}</b>
        </div>
      </v-tooltip>
    </template>
    <template v-else>
      <v-chip class="flat-chip flat-chip--error"> No deadline </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
