<template>
  <v-card :loading="loading">
    <v-card-title class="justify-space-between">
      <span>Dynamic</span>
      <v-btn @click="goTo('/search-console/url/' + url_data.hash)" icon>
        <v-icon>mdi-chart-timeline-variant</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        solo
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :sort-by="['traffic_delta', 'volume', 'cl', 'imp']"
      :sort-desc="[true, true, true, true]"
      class="elevation-1 vertical-headers"
      dense
      :items-per-page="15"
    >
      <template v-slot:item.options="{ item }">
        <v-icon v-if="!item.options.isset_kc" color="error"
          >mdi-ghost-off-outline</v-icon
        >
      </template>
      <template v-slot:item.traffic_t="{ item }">
        {{ item.traffic_t }}
        <sup
          :class="getDelta(item.traffic_t, item.traffic_y).class"
          v-html="getDelta(item.traffic_t, item.traffic_y).text"
        ></sup>
      </template>
      <template v-slot:item.traffic_y="{ item }">
        {{ item.traffic_y }}
        <sup
          :class="getDelta(item.traffic_y, item.traffic_1w).class"
          v-html="getDelta(item.traffic_y, item.traffic_1w).text"
        ></sup>
      </template>
      <template v-slot:item.traffic_1w="{ item }">
        {{ item.traffic_1w }}
        <sup
          :class="getDelta(item.traffic_1w, item.traffic_2w).class"
          v-html="getDelta(item.traffic_1w, item.traffic_2w).text"
        ></sup>
      </template>
      <template v-slot:item.traffic_2w="{ item }">
        {{ item.traffic_2w }}
        <sup
          :class="getDelta(item.traffic_2w, item.traffic_3w).class"
          v-html="getDelta(item.traffic_2w, item.traffic_3w).text"
        ></sup>
      </template>
      <template v-slot:item.traffic_3w="{ item }">
        {{ item.traffic_3w }}
        <sup
          :class="getDelta(item.traffic_3w, item.traffic_1m).class"
          v-html="getDelta(item.traffic_3w, item.traffic_1m).text"
        ></sup>
      </template>
      <template v-slot:item.traffic_1m="{ item }">
        {{ item.traffic_1m }}
        <sup
          :class="getDelta(item.traffic_1m, item.traffic_2m).class"
          v-html="getDelta(item.traffic_1m, item.traffic_2m).text"
        ></sup>
      </template>
      <template v-slot:item.traffic_2m="{ item }">
        {{ item.traffic_2m }}
        <sup
          :class="getDelta(item.traffic_2m, item.traffic_3m).class"
          v-html="getDelta(item.traffic_2m, item.traffic_3m).text"
        ></sup>
      </template>
      <template v-slot:item.traffic_3m="{ item }">
        {{ item.traffic_3m }}
        <sup
          :class="getDelta(item.traffic_3m, item.traffic_6m).class"
          v-html="getDelta(item.traffic_3m, item.traffic_6m).text"
        ></sup>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../mixins/Common";
import axios from "axios";
import events from "../../../events/components/OnePageAnalyzer/events";

export default {
  name: "Dynamic",
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "", value: "options", align: "center", width: 50 },
        { text: "Phrase", value: "phrase", align: "left" },
        { text: "Volume", value: "volume", align: "center" },
        { text: "CL(90d)", value: "cl", align: "center" },
        { text: "Imp(90d)", value: "imp", align: "center" },
        { text: "Traffic, Δ", value: "traffic_delta", align: "center" },
        { text: "Pos, Δ", value: "position_delta", align: "center" },
        { text: "T", value: "traffic_t", align: "center" },
        { text: "Y", value: "traffic_y", align: "center" },
        { text: "1W", value: "traffic_1w", align: "center" },
        { text: "2W", value: "traffic_2w", align: "center" },
        { text: "3W", value: "traffic_3w", align: "center" },
        { text: "1M", value: "traffic_1m", align: "center" },
        { text: "2M", value: "traffic_2m", align: "center" },
        { text: "3M", value: "traffic_3m", align: "center" },
        { text: "6M", value: "traffic_6m", align: "center" },
      ],
      items: [],
    };
  },
  methods: {
    resetData() {
      this.items = [];
    },
    getDelta(a, b) {
      let text = "";
      let css_class = "";

      if (a !== b) {
        let delta = b - a;

        text = delta > 0 ? `+${delta}` : delta;
        css_class = delta > 0 ? "success--text" : "error--text";
      }

      return {
        text: text,
        class: css_class,
      };
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/dynamic`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedUrl,
          project: self.idProject,
          filter: self.filterData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.items = r.data.data;
        })
        .finally(() => {
          self.loading = false;
        });
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
      self.fetchData();
    });
  },
};
</script>

<style scoped></style>
