import service from "@/plugins/service";

class DctTaskService {
  endpoints = {
    ADD: "/dct/task",
  };
  async addTask(ctx, payload) {
    try {
      const response = await service.post(
        this.endpoints.ADD,
        payload,
        true,
        {},
        { prefix: "/api" }
      );

      if (!response?.data?.success) {
        ctx.$message.notification({
          type: "error",
          text: "Error while adding task",
        });
        throw new Error("Response success is false");
      }

      ctx.$message.notification({
        type: "success",
        text: "Task added successfully",
      });
    } catch (error) {
      console.error("Error while adding task", error);
    }
  }
}

export default new DctTaskService();
