<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mx-auto" v-if="isset_active_project">
            <v-card-text class="headline font-weight-bold">
              <v-row class="align-end">
                <v-col sm="11">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    solo
                    class="align-end"
                  ></v-text-field>
                </v-col>
                <v-col sm="1">
                  <v-menu
                    transition="slide-x-transition"
                    :close-on-content-click="false"
                    :nudge-right="200"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-fab-transition>
                        <v-btn
                          color="info"
                          dark
                          small
                          top
                          right
                          fab
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-list>
                          <v-list-item>
                            <v-select
                              v-model="filter.type_of_association"
                              :items="types_of_association"
                              item-text="name"
                              item-value="id"
                              label="Types of association"
                              solo
                              clearable
                            ></v-select>
                          </v-list-item>
                        </v-list>
                        <div class="text-center">
                          <v-btn @click="fetch_data" color="primary">
                            Apply
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <v-data-table
                    :search="search"
                    :headers="table.headers"
                    :items="table.items"
                    :sort-by="['value']"
                    :sort-desc="[true]"
                    class="elevation-1"
                    :footer-props="{
                      itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item.url">
                          <td>
                            <a
                              @click="
                                open_url('/search-console/url/' + item.md5)
                              "
                              >{{ item.url_path }}</a
                            >
                          </td>
                          <td class="text-center">
                            {{ item.impressions }}
                          </td>
                          <td class="text-center">
                            {{ item.clicks }}
                          </td>
                          <td
                            v-for="n in table.headers.length - 3"
                            :key="n"
                            class="text-center"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  label
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  :color="get_pos_td_class(item.data[n - 1])"
                                  v-if="item.data[n - 1] !== undefined"
                                >
                                  {{ item.data[n - 1].pos }}
                                </v-chip>
                                <sup
                                  v-html="get_pos_td(item.data[n - 1])[0]"
                                  :style="{
                                    position: 'static',
                                    right: '-22px',
                                    top: '-3px',
                                    'font-size': '90%',
                                    color: get_pos_td(item.data[n - 1])[1],
                                  }"
                                  v-if="
                                    item.data[n - 1] !== undefined &&
                                    item.data[n - 1].pos !== null
                                  "
                                >
                                </sup>
                              </template>
                              <span>{{ item.url }}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "@/events/eventPipe";

export default {
  name: "SearchConsoleIndex",
  data() {
    return {
      search: "",
      loadings: {
        fetch_data: false,
      },
      filter: {
        type_of_association: 1,
        day: 30,
      },
      types_of_association: [
        { id: 1, name: "day" },
        { id: 2, name: "week" },
        { id: 3, name: "month" },
      ],
      table: {
        base_headers: [
          { text: "Phrase", value: "phrase", align: "left" },
          { text: "Volume", value: "value", align: "center" },
          { text: "Trend", value: "trend", align: "center" },
        ],

        headers: [],
        items: [],
      },
    };
  },
  //components: {MultipleLineChart},
  // components: {SimpleLineChart, MultipleLineChart},
  methods: {
    fetch_data() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/search-console/url/${self.id_project}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          format: self.filter.type_of_association,
          day: self.filter.day,
        };

        axios.post(url, data, config).then(function (response) {
          self.table.items = response.data.data;
          self.table.headers = response.data.headers;
          self.update_loading("fetch_data", false);
        });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
    open_url(url) {
      window.open(url, "_blank");
    },
    get_sparkline(item) {
      let res = [];

      for (let i = this.table.count_days; i > 0; i--) {
        // for (let i = 1; i <= this.table.count_days; i++) {
        let pos = item["p" + (i - 1)].pos;

        if (pos !== undefined) {
          res.push(pos);
        }
      }

      return res;
    },
    get_sparkline_color(item) {
      let res = 0;

      for (let i = 1; i <= this.table.count_days; i++) {
        if (item["p" + (i - 1)].diff !== undefined) {
          res += item["p" + (i - 1)].diff;
        }
      }

      if (res === 0) {
        return "grey";
      }

      return res > 0 ? "success" : "error";
    },
    get_pos_td_class(item) {
      let css = {
        "background-color": "",
      };
      let tableGradient = true;

      if (item !== null && item !== undefined) {
        // if (item.another !== undefined && cellData.another !== null && cellData.another == true) {
        //   $(td).addClass('td-another-url');
        // }
        if (tableGradient === true) {
          if (item.diff !== undefined) {
            let diff = item.diff;
            let color = "";

            if (diff > 0) {
              color = "#82b54b";
            } else if (diff < 0) {
              color = "#ffb119";
            }
            css["background-color"] = color;
          }
        } else if (item.pos !== undefined && item.pos !== null) {
          css["background-color"] = this.get_position_color(item.pos);
        }
      }

      return css["background-color"];
      // return {}
    },
    get_pos_td(item) {
      if (item !== undefined && item.pos !== undefined) {
        var sup = "";
        let diff = 0;
        let diff_color = "";
        // var classSup = '';

        if (item.diff !== undefined) {
          diff = item.diff;
          var diffEl = diff;

          if (diff > 0) {
            // classSup = 'text-black';
            diff_color = "#7fca82";
            // diff_color = 'grey'
            diffEl = "+" + diff;
            // diffEl = '<i class="v-icon notranslate mdi mdi-arrow-up-thick theme--light success--text" style="font-size: 14px;"></i>' + diff;
          } else if (diffEl < 0) {
            diff_color = "#ff7f7f";
            // diff_color = 'grey'
            // classSup = 'text-black';
            // diffEl = '<i class="v-icon notranslate mdi mdi-arrow-down-thick theme--light error--text" style="font-size: 14px;"></i>' + Math.abs(diff);
          } else {
            // classSup = 'text-black';
            diffEl = "";
          }

          // sup = '<sup class="' + classSup + '" style="margin-left: 5px;">' + diffEl + '</sup>';
          sup = diffEl;
        }

        // return '<a href="" class="text-black" data-toggle="tooltip" data-position="bottom" title="">' + parseInt(item.pos) + sup + '</a>';
        // return parseInt(item.pos) + sup;
        return [sup, diff_color, diff_color];
      }

      return "";

      // $(td).css(css);
      // $(td).attr('data-order', pos);
    },
    get_position_color(value) {
      value = parseInt(value);
      if (value === null) return "";
      else if (value > 50)
        return (
          "rgba(255, " +
          Math.round(this.return_color(value, 100) / 2) +
          ", " +
          this.return_color(value, 100) +
          ", 0.3)"
        );
      else if (value > 10 && value <= 50)
        return "rgba(255, 220, " + this.return_color(value, 50) + ", 0.3)";
      else if (value >= 0 && value <= 10)
        return (
          "rgba(" +
          this.return_color(value, 10) +
          ", 240, " +
          this.return_color(value, 10) +
          ", 0.3)"
        );
      else return "";
    },
    return_color(val1, val2) {
      var percent = val1 / val2;
      return Math.round(200 * percent);
    },
    update_setting() {
      this.$store.dispatch("update_ls_serp_setting", this.ls_settings);
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {
    let self = this;

    eventPipe.$on("update-active-project", () => {
      self.fetch_data();
    });

    self.fetch_data();
  },
};
</script>

<style scoped></style>
