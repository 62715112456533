import eventPipe from "@/events/eventPipe";
import { PROJECT_ID_IDTF } from "@/utils/defaultData";
import Location from "@/mixins/Location";
import { strToRGB } from "@/functions";

export default {
  mixins: [Location],
  computed: {
    _$getProjects() {
      return this.$store.state.project.allowed_projects;
    },
    _$getSubprojects() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      );
      if (project && project[0]) return project[0]["subprojects"];
      else return [];
    },
    _$getAllowedProjects() {
      return this.$store.state.project.allowed_projects;
    },
    _$isActiveProjectAllowed() {
      const isProjectSelected =
        this.$store.state.project.active_project !== null &&
        this.$store.state.project.active_project !== undefined;

      const result =
        isProjectSelected &&
        this.$store.state.project.allowed_projects.filter(
          (v) => v.id === parseInt(this.$store.state.project.active_project)
        )[0];

      const isAllowedProjectsContainSelectedProject =
        result !== null && result !== undefined && result !== "undefined";

      return isProjectSelected && isAllowedProjectsContainSelectedProject;
    },
    /**
     * @returns {{id: number} | undefined}
     */
    _$getActiveProject() {
      return this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      )[0];
    },
  },
  methods: {
    _$getSubprojectsByProjectId(projectId) {
      if (!projectId) return [];
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(projectId)
      );
      if (project && project[0]) return project[0]["subprojects"];
      else return [];
    },
    _$projectGetAvatarColors(string, opacity = 1) {
      if (!string) return "warning";

      const { red, green, blue } = strToRGB(string);

      return {
        rgba: `rgba(${red},${green},${blue},${opacity})`,
        text: "white" || `rgba(${red},${green},${blue},1)`,
        bg: `linear-gradient(45deg, rgba(${red},${green},${blue},1) -25%, rgba(${red},${green},${blue},0.5) 125%)`,
      };
    },
    _$setProjectInURL() {
      const activeProjectId = this.$store.state.project.active_project;
      const queryProjectId = this.$route.query[PROJECT_ID_IDTF];

      if (
        activeProjectId &&
        queryProjectId &&
        activeProjectId === queryProjectId
      ) {
        return null;
      }

      if (
        queryProjectId &&
        activeProjectId &&
        activeProjectId !== queryProjectId
      ) {
        return this._$setActiveProject(queryProjectId);
      }

      if (activeProjectId && !queryProjectId) {
        return this._$setQueryParams(PROJECT_ID_IDTF, activeProjectId);
      }
    },
    _$setActiveProject(id, replaceQuery = true, throwEvent = true) {
      this.$store.dispatch("update_active_project", id);
      this.$store.dispatch("update_domain");
      eventPipe.$emit("update-active-project");
      if (throwEvent) eventPipe.$emit("update-active-project");
      if (replaceQuery) {
        this._$setQueryParams(PROJECT_ID_IDTF, id);
      }
    },
  },
};
