<template>
  <div class="task-priority-dashboard" style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <h2>Task Priority</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="d-flex align-center">
      <v-col>
        <v-autocomplete
          v-model="filters.executive"
          hide-details
          dense
          outlined
          clearable
          chips
          deletable-chips
          small-chips
          multiple
          label="Executive"
          :items="filtersData.executive"
          :loading="loadings.filters"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <smart-autocomplete
          :model-value="filters.labels"
          @update:modelValue="filters.labels = $event"
          :items="filtersData.labels"
          :loading="loadings.filters"
          label="Labels"
        />
      </v-col>
      <v-col>
        <pretty-checkbox-filter
          v-model="filters.fresh_data"
          label="Fresh data"
          :true-value="1"
          :false-value="null"
        />
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col>
        <div class="d-flex justify-end">
          <v-btn
            height="40"
            width="160"
            color="primary"
            class="text-normal"
            :loading="loadings.table"
            @click="fetchData"
          >
            Get Data
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card rounded="lg" flat outlined class="mt-3">
      <v-card-title class="pa-3">
        <v-row dense>
          <v-col cols="12" class="d-flex">
            <div class="d-flex" style="gap: 0.25rem">
              <v-text-field
                v-model="tableSearch"
                hide-details
                dense
                outlined
                label="Search"
                append-icon="mdi-magnify"
                style="max-width: 330px"
              ></v-text-field>
              <smart-autocomplete
                :model-value="filters.bannedLabels"
                @update:modelValue="filters.bannedLabels = $event"
                :items="filtersData.labels"
                :loading="loadings.filters"
                label="Hide labels"
                style="max-width: 280px"
              />
            </div>
            <v-spacer />
            <div class="d-flex" style="gap: 0.25rem">
              <v-btn
                class="text-normal"
                href="https://docs.google.com/document/d/1k0Ho3GNWJP8J1G5MgVoe3UMacnLNaVuKDs72lirkdog/edit?tab=t.0#heading=h.eujju2mdhyoe"
                target="_blank"
              >
                Estimating guide <v-icon right small>mdi-open-in-new</v-icon>
              </v-btn>
              <v-menu
                bottom
                min-width="280px"
                nudge-bottom="40"
                content-class="remove-dialog-shadow"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="text-normal px-2"
                    style="min-width: 38px"
                  >
                    <v-icon small>mdi-cog-outline</v-icon>
                  </v-btn>
                </template>
                <v-card flat outlined rounded="lg" class="pa-1">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-switch
                          v-model="isHighlightZero"
                          dense
                          class="ma-0 pa-0"
                          hide-details
                        ></v-switch>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Highlight zero
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <div class="pa-3">
        <v-row dense>
          <v-col
            class="d-flex"
            style="
              gap: 0.25rem;
              overflow-x: scroll;
              scrollbar-width: none;
              overflow-y: hidden;
            "
          >
            <!-- Код снизу заполняет массив плейсхолдерами, пока длина не будет 9 -->
            <template v-for="(tile, idx) in tiles">
              <v-alert
                v-if="tile.placeholder"
                text
                height="100%"
                width="200"
                rounded="lg"
                class="d-flex justify-center align-center"
                color="rgba(155, 155, 155, 0.7)"
                :key="`tile-${idx}`"
              >
                <div class="text-body-2">Void.</div>
              </v-alert>
              <v-alert
                v-else
                :key="tile.dataKey"
                rounded="lg"
                outlined
                min-width="180"
                :color="tile.color"
                class="ma-0"
                style="border: 1px solid var(--card-border) !important"
              >
                <div class="d-flex align-center" style="gap: 1rem">
                  <div>
                    <v-icon
                      size="26"
                      :color="tile?.iconConfig?.color || tile.color"
                      style="opacity: 0.5"
                    >
                      {{ tile.icon }}
                    </v-icon>
                  </div>
                  <div>
                    <div class="text-body-2">
                      {{ tile.title }}
                      <v-tooltip v-if="tile.tooltip" bottom max-width="320">
                        <template #activator="{ on }">
                          <v-icon :color="tile.color" v-on="on" small>
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <template>
                          <pre
                            v-html="tile.tooltip"
                            style="white-space: pre-wrap"
                          ></pre>
                        </template>
                      </v-tooltip>
                    </div>
                    <div class="text-body-1">
                      <div
                        v-if="tile.isPendingDependToData && loadings.table"
                        class="skeleton"
                        style="width: 52px; height: 22px"
                      />
                      <b v-else class="d-flex" style="gap: 0.25rem">
                        {{ tile.getValue(tableData.items, confirmedFilters) }}
                      </b>
                    </div>
                  </div>
                </div>
              </v-alert>
            </template>
          </v-col>
        </v-row>
      </div>
      <v-divider />
      <v-card-text class="px-0 pt-0 pb-3">
        <v-row dense>
          <v-col cols="12">
            <v-data-table
              :search="tableSearch"
              :items="getFilteredItems"
              :headers="getHeaders"
              multi-sort
              :custom-sort="_$smartCustomTableSort"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :loading="loadings.table"
              :options.sync="options"
              :items-per-page="-1"
              class="relative-tr"
              hide-default-footer
            >
              <template
                v-for="h in getHeaders"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                <span :title="header.title" :key="h.value">{{
                  header.text
                }}</span>
              </template>
              <template #[`item.labels`]="{ item }">
                <template
                  v-if="
                    item.labels_data && getLabels(item.labels_data).length > 0
                  "
                >
                  <div class="overflow-chip py-2">
                    <v-chip
                      v-for="[id, label] in getLabels(item.labels_data)"
                      small
                      :key="id"
                    >
                      {{ label }}
                    </v-chip>
                  </div>
                </template>
              </template>
              <template #[`item.week`]="{ value }">
                <div
                  :class="`colored-td rounded-lg colored-td--inline ${getWeekColor(
                    value
                  )}`"
                >
                  {{ value }}
                </div>
              </template>
              <template #[`item.task`]="{ item }">
                <template v-if="item.done">
                  <a
                    :href="item.link"
                    target="_blank"
                    style="text-decoration: line-through; color: inherit"
                  >
                    {{ item.task }}
                  </a>
                  <v-chip small class="flat-chip flat-chip--success ml-1">
                    <v-icon small left>mdi-check-circle</v-icon>
                    Closed
                  </v-chip>
                </template>
                <template v-else>
                  <a :href="item.link" target="_blank">
                    {{ item.task }}
                  </a>
                </template>
              </template>
              <template #[`item.project`]="{ item }">
                <div
                  :class="`mark ${
                    item.labels_data &&
                    Object.values(item.labels_data).includes(NEED_REVIEW_TAG)
                      ? 'mark--gray'
                      : ''
                  }`"
                />
                {{ item.project }}
              </template>
              <template
                v-for="col in [
                  'priority',
                  'rating',
                  'impact',
                  'urgency',
                  'peoples',
                  'estimate',
                  'created_at',
                  'deadline',
                ]"
                #[`item.${col}`]="{ item, items, value }"
              >
                <v-chip
                  :color="
                    bggc_getBgColor(
                      value,
                      col,
                      tableData.items,
                      isHighlightZero,
                      30
                    )
                  "
                  :key="col"
                  label
                  class="rounded-lg"
                >
                  {{
                    String(value).includes(".")
                      ? Number(value).toFixed(2)
                      : value
                  }}
                </v-chip>
              </template>
              <template #[`item._actions`]="{ item }">
                <v-menu
                  bottom
                  content-class="shadow-e2-bordered"
                  rounded="lg"
                  min-width="140"
                >
                  <template #activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-progress-circular
                        v-if="loadings.sendToReview.includes(item.id)"
                        indeterminate
                        size="22"
                        width="3"
                      />
                      <v-icon v-else>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="sendToReview(item)">
                      Send to review
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import service from "@/plugins/service";
import TableSorting from "@/mixins/TableSorting";
import { uid } from "@/utils/functions";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import PrettyCheckboxFilter from "../UI/PrettyCheckboxFilter.vue";
import SmartAutocomplete from "../UI/SmartAutocomplete.vue";
import getTableColBgGradientColorMixin from "../../mixins/getTableColBgGradientColorMixin";
import { deepClone } from "../../utils/functions";
import { TASK_PRIORITY_TABLE_HEADERS } from "../../utils/defaultData";
import { TASK_PRIORITY_TILES } from "./defaults";

export default {
  components: { SmartAutocomplete, PrettyCheckboxFilter },
  mixins: [FiltersHandling, TableSorting, getTableColBgGradientColorMixin],
  data: () => ({
    NEED_REVIEW_TAG: "⇵ need review",
    isHighlightZero: false,
    tableSearch: null,
    sortBy: ["week", "rating", "priority", "deadline"],
    sortDesc: [false, true, true, false],
    bannedLabels: [],
    options: {},
    confirmedFilters: {},
    tiles: TASK_PRIORITY_TILES,
    tableData: {
      items: [],
      headers: TASK_PRIORITY_TABLE_HEADERS,
    },
    loadings: {
      table: false,
      filters: false,
      sendToReview: [],
    },
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Task priority dashboard",
        disabled: true,
      },
    ],
    filters: {
      bannedLabels: [],
      executive: [],
      labels: [],
      fresh_data: null,
    },
    filtersData: {
      executive: [],
      labels: [],
    },
  }),
  watch: {
    options: {
      deep: true,
      handler(newValue) {
        this._$handleFilterChange(newValue.sortBy, "sortBy");
        this._$handleFilterChange(newValue.sortDesc, "sortDesc");
      },
    },
  },
  async mounted() {
    await this.parseQuery("filters");

    if (this.$route.query.sortBy) {
      this.options.sortBy = this.$route.query.sortBy.split(",");
    }

    if (this.$route.query.sortDesc) {
      this.options.sortDesc = this.$route.query.sortDesc
        .split(",")
        .map((v) => v === "true");
    }

    this.fetchFilters();

    if (Object.keys(this.$route.query)?.length) this.fetchData();
  },
  computed: {
    getFilteredItems() {
      return this.tableData.items.filter((v) => {
        if (
          Object.values(v.labels_data).find((i) =>
            this.filters.bannedLabels.includes(i)
          )
        ) {
          return false;
        }
        return true;
      });
    },
    getHeaders() {
      return [...this.tableData.headers, { text: "", value: "_actions" }];
    },
  },
  methods: {
    getWeekColor(weekString) {
      if (weekString === "A") return "red";
      if (weekString === "B") return "yellow";
      if (weekString === "C") return "green";

      return "";
    },
    async sendToReview(item) {
      try {
        const url = "/worksection/task-priority/create_task";

        const payload = {
          item: item,
        };

        this.loadings.sendToReview.push(item.id);

        const resp = await service.post(url, payload);

        this.loadings.sendToReview = this.loadings.sendToReview.filter(
          (v) => v !== item.id
        );

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "Successfully added to review list.",
            type: "success",
            duration: 8000,
          });
        } else {
          this.$message.notification({
            title: "Error",
            text: "Cannot be created.",
            type: "error",
            duration: 8000,
          });
        }
      } catch (e) {
        this.loadings.sendToReview = this.loadings.sendToReview.filter(
          (v) => v !== item.id
        );
        console.error(e);
      }
    },
    tableItemsAdapter(items) {
      return items.map((v) => {
        v.id = uid();
        v.labels_data = v.labels;
        v.labels = v.labels !== null ? JSON.stringify(v.labels) : null;
        return v;
      });
    },
    getLabels(objectOfLabels = {}) {
      if (typeof objectOfLabels !== "object") return [];

      return Object.entries(objectOfLabels);
    },
    async fetchFilters() {
      try {
        const filters = ["executive", "labels"];
        const payload = {
          type: "/worksection/task-priority",
          take: filters,
          filter: {
            projectID: this.$store.state.project.active_project,
          },
        };
        this.loadings.filters = true;
        const resp = await this.$store.dispatch("global/getFilters", payload);
        this.loadings.filters = false;

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } catch (e) {
        console.error(e);
        this.loadings.filters = false;
      }
    },
    async fetchData() {
      try {
        const url = "/worksection/task-priority";

        const payload = {
          filter: this.filters,
        };

        this.loadings.table = true;

        this.confirmedFilters = deepClone(this.filters);

        const resp = await service.post(url, payload);

        this.loadings.table = false;

        if (resp) {
          this.tableData.items = this.tableItemsAdapter(resp.data.items);
        }
      } catch (e) {
        this.loadings.table = false;
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.task-priority-dashboard {
  .v-data-table {
    tbody {
      tr {
        td {
          &:nth-last-child(2),
          &:nth-last-child(3),
          &:nth-last-child(7),
          &:nth-last-child(10) {
            border-left: 1px solid rgba(155, 155, 155, 0.25);
          }
        }
      }
    }
  }
}
</style>
