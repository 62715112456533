<template>
  <v-card
    elevation="0"
    :color="data.color || 'rgba(0,0,0,0.02)'"
    class="pa-2 px-4"
  >
    <v-row dense>
      <v-col cols="6" class="info-card__text-wrapper">
        <div class="info-card__value">{{ parseNumber(data.value) }}</div>
        <div v-if="data.text" class="info-card__label">{{ data.text }}</div>
      </v-col>
      <v-col cols="6" class="d-flex flex-row-reverse">
        <v-icon v-if="data.icon" style="opacity: 0.4">
          {{ data.icon || "mdi-chart-areaspline" }}
        </v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { parseNumber } from "@/functions";

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  methods: {
    parseNumber(data) {
      return parseNumber(data);
    },
  },
  computed: {
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
  },
};
</script>

<style lang="scss">
.dashboard-dev-detailed-page {
  .info-card {
    margin: 0;
    border-radius: 5px;
    border: 1px solid #e5e5e5 !important;
    height: 100%;
    box-shadow: #32304924 0 7px 13px 0 !important;
    .v-alert__wrapper {
      height: 100%;
    }
    &__text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &__value {
      font-size: 20px;
    }
    &__label {
      margin-top: 4px;
      font-size: 14px;
    }
    &.dark-mode {
      border-color: #1e1e1e !important;
    }
    &:hover {
      transform: scale(1.02);
      box-shadow: #32304924 0 4px 33px 0 !important;
    }
  }
}
</style>
