export default {
  methods: {
    mSuccess({ title, text, duration = 6000 }) {
      this.$message.notification({
        title,
        text,
        duration,
        type: "success",
      });
    },
    mError({ title, text, duration = 6000 }) {
      this.$message.notification({
        title,
        text,
        duration,
        type: "error",
      });
    },
    mWarning({ title, text, duration = 6000 }) {
      this.$message.notification({
        title,
        text,
        duration,
        type: "warning",
      });
    },
  },
};
