<template>
  <svg
    :width="width || 80"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12L20.8986 13.5L19.7973 15L18.6959 16.5001L17.6014 18L16.5 19.5001L15.3986 21L14.2973 22.5001L13.1959 24L11 24L8.7973 24L7.69594 22.5001L6.59459 21L8.7973 21L11 21L12.1014 19.5001L13.1959 18L14.2973 16.5001L15.3986 15L16.5 13.5L17.6014 12L16.5 10.5L15.3986 9L14.2973 10.5L13.1959 12L12.1014 13.5L11 15L9.89865 16.5001L8.7973 18L6.59459 18L4.39865 18L3.2973 16.5001L2.19594 15L1.09455 13.5L1.43099e-07 12L1.09455 10.5L2.19594 9L3.2973 7.5L4.39865 6L5.5 4.5L6.59459 3L7.69594 1.5L8.7973 1.57441e-07L11 1.31174e-07L13.1959 1.04987e-07L14.2973 1.5L15.3986 3L13.1959 3L11 3L9.89865 4.5L8.7973 6L7.69594 7.5L6.59459 9L5.5 10.5L4.39865 12L5.5 13.5L6.59459 15L7.69594 13.5L8.7973 12L9.89865 10.5L11 9L12.1014 7.5L13.1959 6L15.3986 6L17.6014 6L18.6959 7.5L19.7973 9L20.8986 10.5L22 12Z"
      fill="url(#paint0_linear_789_171)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_789_171"
        x1="11.0375"
        y1="1.53094e-07"
        x2="11.0375"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#58A5FF" />
        <stop offset="1" stop-color="#0025A8" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "80",
    },
  },
};
</script>
