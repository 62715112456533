<template>
  <div>
    <ApexCharts
      ref="chart"
      height="300"
      :options="options"
      :series="series"
      :class="`
        custom-chart
        no-outer-padding
        ${is_dark_mode ? ' dark-mode' : ''}
        `"
    >
    </ApexCharts>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  name: "MainChart",
  components: { ApexCharts },
  computed: {
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
  },
  data: () => ({
    series: [
      {
        name: "Session Duration",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
      },
      {
        name: "Page Views",
        data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
      },
      {
        name: "Total Visits",
        data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Product Trends by Month",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    },
  }),
};
</script>
