import service from "../plugins/service";

export const userPreferenceApiService = () => {
  const ENPOINTS = {
    SAVE: "/user/save-settings",
    GET: "/user/get-settings",
  };

  const set = (key, data) => {
    return service.post(ENPOINTS.SAVE, { type: key, data });
  };

  const get = () => {
    return service.get(ENPOINTS.GET);
  };

  return {
    set,
    get,
  };
};
