<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="420px"
    content-class="my-shadow--e4"
  >
    <v-card class="styled-card--default">
      <v-card-title>
        Change status
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-6">
        <v-autocomplete
          v-model="statusId"
          :items="statusItems"
          hide-details
          dense
          filled
          placeholder="Type to search"
          label="Select status"
        ></v-autocomplete>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-chip label class="mr-2" @click="dialog = false">back</v-chip>
        <v-chip label color="success" @click="handleSaveChanges"
          >Save changes</v-chip
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import Notification from "@/mixins/Notification";

export default {
  props: {
    statusItems: Array,
  },
  mixins: [Dialog, Notification],
  data: () => ({
    statusId: null,
  }),
  methods: {
    handleSaveChanges() {
      this.$emit("changeStatus", this.statusId);
    },
  },
};
</script>
