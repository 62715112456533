<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Blog admin</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex mb-2">
          <b>Filters factory</b>
        </div>
        <filters
          :model-value="filters"
          @update:modelValue="filters = $event"
          :groups="filterGroups"
        />
      </v-col>
      <v-col cols="12">
        <div class="d-flex align-end mb-2">
          <b>Posts</b>
          <v-spacer />
          <div>
            <v-chip
              outlined
              label
              @click="$router.push({ name: 'blog.new-article' })"
            >
              New post
              <v-icon right small>mdi-plus</v-icon>
            </v-chip>
          </div>
        </div>
        <v-card class="styled-card--default">
          <articles-table
            :model-value="selected"
            @update:modelValue="selected = $event"
            :headers="headers"
            :items="items"
            @action:edit="handleEditArticle"
            @action:delete="handleDeleteArticle"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticlesTable from "@/components/BlogAdmin/components/ArticlesTable.vue";
import Filters from "@/components/FiltersFactory/Filters.vue";
import FiltersFactoryMixin from "@/mixins/FiltersFactoryMixin";
import FiltersService from "@/services/FiltersService";

export default {
  components: { Filters, ArticlesTable },
  mixins: [FiltersFactoryMixin],
  data: () => ({
    headers: [
      {
        text: "Preview",
        value: "preview_image",
        width: "200px",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Created at",
        value: "created_at",
        width: "120px",
      },
      {
        text: "Last update",
        value: "updated_at",
        width: "120px",
      },
      {
        text: "",
        value: "_actions",
        width: "80px",
      },
    ],
    items: [
      {
        preview_image:
          "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg",
        title: "Introducing Anonymous - Who Am I And Why Should You Follow Me",
        created_at: new Date(),
        updated_at: new Date(),
        id: 0,
      },
      {
        preview_image:
          "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        title: "Can Monkeys Dance : An exploration of Memes",
        created_at: new Date(),
        updated_at: new Date(),
        id: 1,
      },
      {
        preview_image:
          "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8fDA%3D&w=1000&q=80",
        title: "How to Make Your Own Brave Tutu for less than £5",
        created_at: new Date(),
        updated_at: new Date(),
        id: 2,
      },
      {
        preview_image:
          "https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014_1280.jpg",
        title: "10 Things You've Always Wanted to Know About the Famous Yeti",
        created_at: new Date(),
        updated_at: new Date(),
        id: 3,
      },
      {
        preview_image:
          "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
        title:
          "Eric Cartman : Is there value in analysing the impact of fictional characters on hurricanes?",
        created_at: new Date(),
        updated_at: new Date(),
        id: 4,
      },
    ],
    selected: [],
    filters: {},
    filterGroups: [],
    filterItems: {
      executives: [1, 2, 3],
      project: [1, 2, 3],
    },
    mock: [
      {
        type: "date_range",
        label: "Deadline",
        value: "deadline",
        default_value: ["2023-10-04", "2023-10-15"],
      },
      {
        type: "multi_pick",
        label: "Executives",
        value: "executives",
      },
      {
        type: "single_pick",
        label: "Project",
        value: "project",
      },
      {
        type: "text",
        label: "Details",
        value: "details",
      },
    ],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Blog",
        disabled: true,
      },
      {
        text: "Admin",
        disabled: true,
      },
    ],
    configs: {
      columnSetting: {
        deadline: {
          col: 6,
        },
        project: {
          col: 6,
        },
      },
      groups: {
        mainFilters: {
          name: "Main filters",
          filters: ["deadline", "executives"],
        },
        project: {
          name: "Project",
          filters: ["project"],
        },
      },
    },
  }),
  created() {
    const filtersFactory = new FiltersService(this.mock, this.configs);

    this.filters = filtersFactory.filters;
    this.filterGroups = filtersFactory.groups;

    console.log("filters", this.filters);
    console.log("groups", this.filterGroups);
  },
  methods: {
    handleEditArticle(articleData) {
      this.$router.push({
        name: "blog.article-details",
        params: { id: articleData.id },
      });
    },
    handleDeleteArticle(articleData) {
      console.log(articleData);
    },
  },
};
</script>
