<template>
  <v-banner @click:icon="goTo(selectedUrl)">
    <v-icon slot="icon" color="primary" size="25" style="cursor: pointer">
      mdi-open-in-new
    </v-icon>
    <v-row dense class="align-center">
      <v-col cols="2" class="d-flex align-center">
        <v-btn icon @click="reload" v-if="selectedUrl !== null" class="mr-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-autocomplete
          :label="selectedUrl ? selectedUrl : 'Url'"
          v-model="selectedUrl"
          :items="urlsItems"
          clearable
          solo
          hide-details
          :disabled="selectedUrl !== null"
          @change="updateUrl"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="GI"
          v-model="googleIndexSelected"
          :items="googleIndexItems"
          clearable
          solo
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Follow"
          v-model="followSelected"
          :items="followItems"
          clearable
          solo
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Link Types"
          v-model="linkTypesSelected"
          :items="linkTypesItems"
          chips
          deletable-chips
          multiple
          small-chips
          solo
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Anchor Types"
          v-model="anchorTypesSelected"
          :items="anchorTypesItems"
          chips
          deletable-chips
          multiple
          small-chips
          solo
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-menu
          open-on-hover
          :close-on-content-click="false"
          bottom
          nudge-bottom="35px"
        >
          <template #activator="{ on }">
            <v-btn v-on="on" solo text left>
              <v-icon class="mr-2">mdi-eye-outline</v-icon>
              Visibility
            </v-btn>
          </template>
          <div>
            <v-list>
              <v-list-item>
                <v-switch v-model="show_on_page" label="onPage"></v-switch>
              </v-list-item>
              <v-list-item>
                <v-switch
                  v-model="hide_disavow"
                  label="Hide disavow"
                ></v-switch>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </v-col>
      <v-col>
        <v-checkbox v-model="targetKeys" label="Target Keys"></v-checkbox>
      </v-col>
      <v-col>
        <v-btn icon @click="updateFilter">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-banner>
</template>

<script>
import axios from "axios";
import eventPipe from "../../../events/eventPipe";
import events from "../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../mixins/Common";

export default {
  name: "MainFilter",
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  data() {
    return {
      urlsItems: [],
      googleIndexItems: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      followItems: [
        {
          text: "dF",
          value: "df",
        },
        {
          text: "nF",
          value: "nf",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.url) {
      this.selectedUrl = this.$route.query.url;
      this.updateUrl();
    }
  },
  computed: {
    linkTypesItems() {
      return this.$store.state.reuse.link_types;
    },
    anchorTypesItems() {
      return this.$store.state.reuse.anchor_types;
    },
    show_on_page: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.show_on_page;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.show_on_page = value;
      },
    },
    hide_disavow: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.hide_disavow;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.hide_disavow = value;
      },
    },
    googleIndexSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.googleIndex;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.googleIndex = value;
      },
    },
    followSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.follow;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.follow = value;
      },
    },
    linkTypesSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.linkTypes;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.linkTypes = value;
      },
    },
    anchorTypesSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.anchorTypes;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.anchorTypes = value;
      },
    },
    selectedUrl: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.selectedUrl;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.selectedUrl = value;
      },
    },
    targetKeys: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.targetKeys;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.targetKeys = value;
      },
    },
  },
  methods: {
    updateUrl() {
      let self = this;

      self.$store.dispatch("fetch_link_types_list", {
        params: {
          by_url_ltt: self.selectedUrl,
        },
      });

      if (self.selectedUrl !== null) {
        events.$emit("update-url");
      }

      // this.fetchData()
      // this.updateFilter()
      // this.fetchDynamic()
      // this.fetchVision()
    },
    updateFilter() {
      events.$emit("update-main-filter");
      // this.fetchLinks('default')
      // this.fetchSmallColumns('follow')
      // this.fetchSmallColumns('link_types')
      // this.fetchSmallColumns('anchor_types')
      // this.fetchFollow()
      // this.fetchGI()
      // this.fetchLinksTypesDistribution()
      // this.fetchAnchorTypes()
    },
    fetchUrls() {
      let self = this;

      let url = `${self.$store.state.server_url}/keyword-control/${self.idProject}/get-urls`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.urlsItems = r.data.items;
      });
    },
    reload() {
      location.reload();
    },
  },
  created() {
    let self = this;

    if (self.anchorTypesItems.length === 0) {
      self.$store.dispatch("fetch_anchor_types_list");
    }

    eventPipe.$on("update-active-project", () => {
      self.fetchUrls();
    });

    self.fetchUrls();
  },
};
</script>

<style scoped></style>
