<template>
  <div>
    <div :class="`mark ${getRowColor(item)}`" />
    <div
      @click="copy(item.phrase)"
      :class="` d-flex justify-space-between show-icons-on-hover global colored-td colored-td--clickable text--start ${getPhraseColor(
        item
      )}`"
    >
      <span>{{ item.phrase }}</span>
      <v-icon small>mdi-content-copy</v-icon>
    </div>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";
export default {
  props: ["item"],
  methods: {
    async copy(string) {
      copy(string);
      this.$message.notification({
        title: "Done",
        text: `Phrase <mark>${string}</mark> successfully coppied.`,
        type: "success",
      });
    },
    getRowColor(item) {
      let compareValue = String(item.entry);

      const sumOfUse = isNaN(item.sum_of_use) ? 0 : Number(item.sum_of_use);

      if (item.new !== undefined) {
        compareValue = String(item.new);
      }

      if (compareValue !== "true" && sumOfUse >= 0.7) {
        return "mark--error";
      }
      if (compareValue !== "true" && sumOfUse >= 0.45) {
        return "mark--warning";
      }

      return "";
    },
    getPhraseColor(item) {
      let compareValue = String(item.entry);

      if (item.exist_in_stop_words) {
        return "gray";
      }

      const sumOfUse = isNaN(item.sum_of_use) ? 0 : Number(item.sum_of_use);

      if (item.new !== undefined) {
        compareValue = String(item.new);
      }

      if (compareValue === "true" && sumOfUse === 0) {
        return "red";
      }

      if (compareValue === "true" && sumOfUse > 0 && sumOfUse <= 0.1) {
        return "orange";
      }

      return "";
    },
  },
};
</script>
