<template>
  <svg
    width="108"
    height="95"
    viewBox="0 0 108 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5263 0.75C27.5263 0.75 17.9076 34.9472 10.5332 57.7926C12.8822 58.6583 25.8895 68.6246 30.4035 71.8005C34.3151 74.5461 48.4935 85.0221 51.1733 88.1456C70.264 91.4059 87.7228 94.1447 87.7228 94.1447C87.7228 94.1447 107.644 15.5949 107.829 13.7184C108.022 11.8369 27.5314 0.75 27.5314 0.75"
      fill="#DFDFDF"
    />
    <path
      d="M24.8588 0.75C24.8588 0.75 3.87469 75.3544 0.116632 77.7928C-3.64143 80.238 85.0485 94.1413 85.0485 94.1413C85.0485 94.1413 106.694 15.1393 106.881 13.2594C107.07 11.3813 24.8537 0.75 24.8537 0.75"
      fill="#EDEDED"
    />
    <path
      d="M59.3496 14.385C59.7411 14.7883 60.1073 15.0803 60.3402 14.844C60.5713 14.601 60.2946 14.2365 59.8963 13.8366L52.338 5.19656C51.9448 4.79663 51.1264 4.962 50.8918 5.19656C50.659 5.43619 50.4868 6.26137 50.8918 6.66469L59.3462 14.385"
      fill="#AEAEAE"
    />
    <path
      d="M59.6698 14.1032L52.1166 5.47163C51.7757 5.11725 51.1075 5.19994 50.7852 5.3805C50.8153 5.31689 50.8558 5.25873 50.905 5.20838C51.1328 4.96875 51.9512 4.8 52.3461 5.20838L59.9044 13.845C60.301 14.2483 60.5777 14.6145 60.3482 14.8524C60.3089 14.8943 60.2566 14.9217 60.1997 14.9301C60.2132 14.7039 59.977 14.4154 59.6698 14.1032"
      fill="#7C7B7D"
    />
    <path
      d="M46.855 7.97081C47.9013 10.0684 50.7177 10.5949 53.1342 9.15544C55.5507 7.70925 56.6594 4.84219 55.6132 2.74969C54.5635 0.657187 51.7505 0.134063 49.3306 1.57013C46.9175 3.01125 45.8088 5.88506 46.855 7.97081Z"
      fill="#D5D5D5"
    />
    <path
      d="M54.2698 4.96875C53.2236 2.87625 50.4105 2.34806 47.994 3.79256C47.5019 4.08538 47.0527 4.44485 46.6592 4.86075C47.0591 3.57825 47.9839 2.35987 49.3136 1.56337C51.7301 0.12225 54.5432 0.64875 55.5945 2.74294C56.4231 4.40006 55.8966 6.53981 54.4453 8.07375C54.7761 7.01231 54.744 5.91712 54.2698 4.96875Z"
      fill="#959595"
    />
    <path
      d="M51.2729 8.99681C51.2729 8.99681 48.6101 9.68194 47.1706 7.2165C47.1706 7.2165 46.7842 6.33225 47.2938 6.59381C47.2938 6.59381 48.1679 8.45513 51.2729 8.99681Z"
      fill="#F4F5F5"
    />
  </svg>
</template>

<script>
export default {};
</script>
