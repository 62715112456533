<template>
  <div class="simple-table">
    <div class="simple-table__wrapper" @scroll="handleScroll">
      <table class="simple-table__table centered-content">
        <template v-if="!haveIndividualSubheaders">
          <tr class="simple-table__headers">
            <th class="simple-table__placeholder-cell"></th>
            <th
              v-for="header in getMainHeaders"
              :key="header.id"
              :colspan="header.colspan || calculateColspan"
              class="simple-table__headers"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr v-if="haveSubheaders" class="simple-table__sub-headers">
            <th
              class="simple-table__placeholder-cell url"
              style="text-align: left"
            >
              Url
            </th>
            <template v-for="subheaders in getSubheaders">
              <th v-for="header in subheaders" :key="header.id">
                {{ header.text }}
              </th>
            </template>
          </tr>
        </template>
        <template v-else>
          <tr class="simple-table__headers borderless">
            <th class="simple-table__placeholder-cell"></th>
            <th
              v-for="header in data.headers"
              :key="header.value"
              :colspan="header.colspan"
              class="simple-table__headers"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr v-if="haveSubheaders" class="simple-table__sub-headers">
            <th
              class="simple-table__placeholder-cell url"
              style="text-align: left"
            >
              Url
            </th>
            <th v-for="header in data.subheaders" :key="header.id">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <tr
          v-for="(row, rowIdx) in getItems"
          :key="row.id"
          :class="`simple-table__items ${scrolledX ? 'scrolled-x' : ''}`"
        >
          <td
            v-for="(col, i) in row"
            :key="col.id"
            :class="
              i % calculateColspan === 0 && !haveIndividualSubheaders
                ? 'bordered'
                : ''
            "
            :style="`background-color: ${ rowIdx !== 0 ? getRgb(
              col.value,
              getMax(getItems, i),
              50,
              233,
              153
            ) : ''}`"
          >
            <template v-if="i === 0">
              <span :style="`${col.value && col.value.length < 30 ? 'white-space:nowrap;' : ''}`">{{ col.value }}</span>
            </template>
            <template v-else>
              <div
                v-if="col && col.value"
                class="colored-td colored-td--clickable"
                @click="goToSpiderLink(col, activeUrl)"
                style="cursor: pointer;"
              >
                {{ col.value }}
              </div>
            </template>
          </td>
        </tr>
      </table>
    </div>
    <!--    <div class="simple-table__pagination">-->
    <!--      <v-pagination v-model="page" :length="items.length"></v-pagination>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Project from "@/mixins/Project";
import SpiderLink from "@/mixins/components/LinksDynamicDashboard/SpiderLink";
import GetRgb from "@/mixins/GetRgb";
import error from "@/store/modules/error";

export default {
  mixins: [Project, SpiderLink, GetRgb],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    itemsPerPage: {
      type: Number,
      default: 1000,
    },
    activeUrl: {},
  },
  data: () => ({
    scrolledX: false,
    page: 1,
    items: [],
  }),
  created() {
    try {
      let page = [];

      if(!this.data || !this.data.items || !Array.isArray(this.data.items)) return;

      this.data.items.forEach((item, index) => {
        if (index === this.data.items.length - 1) {
          this.items.push(page);
        }
        if (page.length >= this.itemsPerPage) {
          this.items.push(page);
          return (page = []);
        }
        page.push(item);
      });
    } catch (e) {
      console.error(e)
    }
  },
  computed: {
    error() {
      return error
    },
    haveIndividualSubheaders() {
      try {
        // data.headers must be Array
        if(!this.data || !this.data.headers || !Array.isArray(this.data.headers)) return false;

        // headers[0] must exist
        if(!this.data.headers[0]) return false;

        // headers[0] must be Object
        if(typeof this.data.headers[9] !== 'object') return false;

        // eslint-disable-next-line no-prototype-builtins
        return this.data.headers[0].hasOwnProperty("colspan");
      } catch (e) {
        console.error(e)
        return false
      }
    },
    getMainHeaders() {
      return this.fixMainHeaders(this.data.headers);
    },
    pageCount() {
      return this.items.length;
    },
    getItems() {
      // console.log('FIXED ITEMS : ', this.fixItems(this.items[this.page - 1]))
      console.log(this.data.items);
      if(!this.data || !this.data.items) return []

      return this.data.items;
    },
    calculateColspan() {
      return (this.data.items[0].length - 1) / this.data.headers.length;
    },
    haveSubheaders() {
      if (!this.data.subheaders) {
        return false;
      }
      return this.data.subheaders.length > 0;
    },
    getSubheaders() {
      const amountOfHeaders = this.data.headers.length;
      const output = [];

      for (let i = 0; i < amountOfHeaders; i++) {
        const fixedSubheaders = this.data.subheaders.map((subheader) => {
          subheader = { ...subheader, id: this.uniqueid() }

          return subheader;
        });
        output.push(fixedSubheaders);
      }
      return output;
    },
  },
  methods: {
    fixMainHeaders(headers) {
      try {
        if(!headers || !Array.isArray(headers)) return []

        return (headers = headers.map((header) => {
          header = { ...header, id: this.uniqueid() }

          return header;
        }));
      } catch (e) {
        console.error(e)
        return []
      }
    },
    uniqueid() {
      return String(
        Date.now().toString(32) + Math.random().toString(16)
      ).replace(/\./g, "");
    },
    fixItems(items = []) {
      try {
        if(!items || !Array.isArray(items)) return []

        items = items.map((row) => {
          const fixedRow = row.map((col) => {
            col = { value: col, id: this.uniqueid() }

            return col;
          });

          row = { items: fixedRow, id: this.uniqueid() }

          return row;
        });
        return items;
      } catch (e){
        console.error(e)
        return []
      }
    },
    getMax(items, key) {
      let bigger = 0;

      items.forEach((row, idx) => {
        if(idx === 0) return;

        const val = row[key].value;
        if (typeof val !== "string") {
          if (parseInt(val) > bigger) bigger = parseInt(val);
        }
      });

      return bigger;
    },
    handleScroll(e) {
      const left = e.target
        .querySelector(".simple-table__items")
        .getBoundingClientRect().left;
      if (left < 0) {
        return (this.scrolledX = true);
      }
      this.scrolledX = false;
    },
  },
};
</script>
