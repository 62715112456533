<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <h2>Top 5 Pages Conversion</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-4">
      <v-col>
        <v-autocomplete
          v-model="filters.project"
          label="Project"
          :items="filtersData.project"
          multiple
          outlined
          dense
          clearable
          hide-details
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.project.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.subproject"
          label="Subproject"
          :items="_$getSubprojects"
          item-value="id"
          item-text="name"
          multiple
          outlined
          dense
          clearable
          hide-details
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.project.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.page_type"
          multiple
          clearable
          :loading="loadings.filters"
          label="Page type"
          :items="filtersData.page_type"
          dense
          outlined
          hide-details
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.page_type.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.seo"
          multiple
          clearable
          :loading="loadings.filters"
          label="Seo"
          :items="filtersData.seo"
          dense
          outlined
          hide-details
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.seo.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.team"
          multiple
          clearable
          :loading="loadings.filters"
          label="Team"
          :items="filtersData.team"
          dense
          outlined
          hide-details
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.seo.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.project_status"
          multiple
          clearable
          :loading="loadings.filters"
          label="Project status"
          :items="filtersData.project_status"
          dense
          outlined
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.page"
          :items="[
            {
              text: 'Only Top 5',
              value: 'only_top_5',
            },
            {
              text: 'With Clicks',
              value: 'with_clicks',
            },
            {
              text: 'Only Top 5 or With Clicks',
              value: 'top_5_or_clicks',
            },
            {
              text: 'All',
              value: 'all',
            },
          ]"
          hide-details
          dense
          label="Only top 5"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.niche"
          :items="filtersData.niche"
          hide-details
          dense
          label="Niche"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-checkbox
          label="Ignored pages"
          v-model="filters.ignored_pages"
          hide-details
          dense
          class="mb-1"
          :true-value="1"
          :false-value="null"
        />
      </v-col>
      <v-col>
        <v-btn
          block
          color="primary"
          @click="fetchData"
          style="height: 40px"
          :loading="loadings.table"
          >Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <single-date-picker
          :model-value="filters.date"
          @update:modelValue="filters.date = $event"
        />
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
    <v-row dense class="mt-4">
      <v-col cols="12">
        <div class="d-flex mb-2">
          <v-text-field
            v-model="search"
            hide-details
            dense
            outlined
            placeholder="Type to search"
            append-icon="mdi-magnify"
            style="max-width: 320px"
          ></v-text-field>
          <v-spacer />
          <div class="d-flex" style="gap: 0.25rem">
            <v-menu
              :close-on-content-click="false"
              bottom
              offset-y
              content-class="my-shadow--e3 rounded-lg"
            >
              <template #activator="{ on }">
                <v-btn class="text-normal" v-on="on">
                  <v-icon small left>mdi-chevron-down</v-icon>
                  Export
                  <v-icon small right>mdi-download</v-icon>
                </v-btn>
              </template>
              <template #default>
                <v-card flat outlined rounded="lg" class="pa-0">
                  <v-list dense>
                    <vue-json-to-csv
                      :json-data="getTableDataItemsPreparedForCsvExport()"
                      csv-title="top_5_pages_conversion"
                      :separator="'\t'"
                      :disabled="!tableData?.length"
                    >
                      <v-list-item
                        :disabled="!tableData?.length"
                        @click="() => {}"
                      >
                        <v-list-item-title>
                          Export table to CSV
                        </v-list-item-title>
                      </v-list-item>
                    </vue-json-to-csv>
                  </v-list>
                </v-card>
              </template>
            </v-menu>
            <v-menu
              bottom
              offset-y
              content-class="my-shadow--e3"
              max-width="320"
            >
              <template #activator="{ on }">
                <v-btn v-on="on" class="text-normal">
                  <v-icon small left>mdi-chevron-down</v-icon>
                  icons guide <v-icon small right>mdi-information</v-icon>
                </v-btn>
              </template>
              <v-card flat outlined rounded="lg">
                <v-card-title class="text-body-2"> Icons guide </v-card-title>
                <v-divider />
                <v-card-text class="pa-4">
                  <v-row dense>
                    <template
                      v-for="(status, idx) in Object.values(smStatuses)"
                    >
                      <v-col v-if="status" :key="`status-${idx}`" cols="12">
                        <v-row>
                          <v-col cols="auto">
                            <v-icon :color="status.color">
                              {{ status.icon }}
                            </v-icon>
                          </v-col>
                          <v-col>
                            {{ status.name }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider
                        v-if="
                          status && idx !== Object.values(smStatuses).length - 1
                        "
                        :key="`divider-${idx}`"
                      />
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-menu bottom offset-y content-class="my-shadow--e3">
              <template #activator="{ on }">
                <v-btn v-on="on" class="text-normal">
                  <v-icon small left>mdi-chevron-down</v-icon>
                  Colors guide <v-icon small right>mdi-information</v-icon>
                </v-btn>
              </template>
              <v-card flat rounded="lg" outlined>
                <v-card-title class="text-body-2"> Colors guide </v-card-title>
                <v-divider />
                <v-card-text class="px-0">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>Meaning</th>
                        <th>Color</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          The day selected in the filter is earlier than the
                          last update day
                        </td>
                        <td>
                          <div
                            style="width: 40px; height: 36px"
                            class="colored-td red"
                          ></div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </div>
        <v-card rounded="lg" outlined flat>
          <v-card-text class="px-0 py-2">
            <template v-if="loadings.table">
              <v-skeleton-loader type="table" />
            </template>
            <v-data-table
              v-else
              :search="search"
              :headers="headers"
              :items="tableData"
              :loading="loadings.table"
              :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
              :custom-sort="_$smartCustomTableSort"
              :sort-by="['cl', 'imp']"
              :sort-desc="[true, true]"
              multi-sort
              class="top-5-page-conversion-table"
            >
              <template v-for="h in headers" #[`header.${h.value}`]>
                <div style="white-space: nowrap" :key="h.value">
                  <template v-if="h.title">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ h.text }}
                          <v-icon small color="rgba(0,0,0,0.3)" class="mr-1">
                            mdi-information-outline
                          </v-icon>
                        </span>
                      </template>
                      <div style="max-width: 150px">{{ h.title }}</div>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <span>{{ h.text }}</span>
                  </template>
                </div>
              </template>
              <template
                v-for="h in headers"
                #[`item.${h.value}`]="{ item, value }"
              >
                <template v-if="h.value === 'url'">
                  <div
                    :key="h.value"
                    class="d-flex justify-space-between align-center"
                  >
                    <a :href="item.url" target="_blank">
                      {{ item.url }}
                    </a>

                    <v-menu bottom offset-y content-class="my-shadow--e3">
                      <template #activator="{ on }">
                        <v-btn v-on="on" icon>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="styled-card pa-0" width="360">
                        <v-list dense>
                          <v-list-item @click.prevent="goToSW(item)">
                            <v-list-item-icon>
                              <v-icon small> mdi-open-in-new </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              Site workplace
                            </v-list-item-content>
                          </v-list-item>
                          <a
                            v-for="link in item.links_google"
                            :key="link"
                            :href="link"
                            target="_blank"
                            class="reset-link-styles"
                          >
                            <v-list-item @click="() => {}">
                              <v-list-item-icon>
                                <v-icon small> mdi-open-in-new </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                Google search
                                <small
                                  style="
                                    opacity: 0.6;
                                    max-width: 260px;
                                    word-break: break-all;
                                  "
                                >
                                  {{ getPathDetails(link) }}
                                </small>
                              </v-list-item-content>
                            </v-list-item>
                          </a>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                <template v-else-if="h.value[0] === '$'">
                  <div :key="h.value" :class="getCheckboxTdClass(value)">
                    <table-element-checkbox-factory :data="value" />
                  </div>
                </template>
                <template v-else-if="h.value === '_actions'">
                  <v-chip
                    :key="h.value"
                    label
                    color="transparent"
                    class="error--text"
                    @click="handleAddToIngone(item)"
                  >
                    <v-icon small left>mdi-close-circle-outline</v-icon>
                    ignore
                  </v-chip>
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </template>
              <!--              <template v-if="false" #body="{ items }">-->
              <!--                <tbody>-->
              <!--                  <tr-->
              <!--                    v-for="(row, index) in items"-->
              <!--                    :key="index"-->
              <!--                    :class="{ first: index === 0 }"-->
              <!--                  >-->
              <!--                    <td></td>-->
              <!--                    <td-->
              <!--                      :style="`${-->
              <!--                        String(row.cl) !== '0' ? 'font-weight: 600;' : ''-->
              <!--                      }`"-->
              <!--                      class="text-center"-->
              <!--                    >-->
              <!--                      {{ row.cl }}-->
              <!--                    </td>-->
              <!--                    <td class="text-center">{{ row.imp }}</td>-->
              <!--                    <td class="text-center">{{ row.top1 }}</td>-->
              <!--                    <td class="text-center">{{ row.top3 }}</td>-->
              <!--                    <td class="text-center">{{ row.top5 }}</td>-->
              <!--                    <td class="text-center">{{ row.todo_status }}</td>-->
              <!--                    <td-->
              <!--                      v-for="(td, idx) in getCheckboxes(row)"-->
              <!--                      :key="idx"-->
              <!--                      class="pa-0"-->
              <!--                    >-->
              <!--                      <div :class="getCheckboxTdClass(td)">-->
              <!--                        <table-element-checkbox-factory :data="td" />-->
              <!--                      </div>-->
              <!--                    </td>-->
              <!--                    <td class="text-center">{{ row.page_type }}</td>-->
              <!--                    <td class="text-center">{{ row.intent }}</td>-->
              <!--                    <td class="text-center">{{ row.need_top }}</td>-->
              <!--                    <td class="text-center">{{ row.pr }}</td>-->
              <!--                    <td>-->
              <!--                      <v-chip label outlined @click="handleAddToIngone(row)">-->
              <!--                        <v-icon small left>mdi-close-circle-outline</v-icon>-->
              <!--                        ignore-->
              <!--                      </v-chip>-->
              <!--                    </td>-->
              <!--                  </tr>-->
              <!--                </tbody>-->
              <!--              </template>-->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure?</template>
      <template #default>
        Are you sure you want to ignore this url in the top 5?
      </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="disagree" text color="grey">Cancel</v-btn>
            <v-btn @click="agree" color="error" class="ml-4 b-rb-shaped-4">
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
  </v-container>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import AgreeDialogMixin from "../../../../mixins/AgreeDialogMixin";
import AgreeDialog from "../../../../components/UI/AgreeDialog";
import Location from "../../../../mixins/Location";
import QueryBuilder from "@/mixins/QueryBuilder";
import TableElementCheckboxFactory from "./components/TableElementCheckboxFactory.vue";
import service from "@/plugins/service";
import SingleDatePicker from "@/components/UI/SingleDatePicker.vue";
import { getPreviousWeekday } from "@/utils/functions";
import Project from "@/mixins/Project";
import StatusesMixin from "../../../../mixins/StatusesMixin";
import { deepClone } from "../../../../utils/functions";

export default {
  components: {
    SingleDatePicker,
    TableElementCheckboxFactory,
    AgreeDialog,
    VueJsonToCsv,
  },
  mixins: [
    FiltersHandling,
    AgreeDialogMixin,
    Location,
    QueryBuilder,
    Project,
    StatusesMixin,
  ],
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Analytics",
        disabled: true,
      },
      {
        text: "Top 5 conversion",
        disabled: true,
      },
    ],
    filters: {
      project: [],
      subproject: [],
      seo: [],
      page_type: [],
      team: [],
      date: null,
      page: "top_5_or_clicks",
      project_status: [],
      ignored_pages: null,
      niche: 2,
    },
    filtersData: {
      seo: [],
      project: [],
      page_type: [],
      project_status: [],
      niche: [],
      team: [],
    },
    pageTypePriority: {
      payment: 100,
      review: 80,
      casino: 60,
      bonus: 50,
      games: 30,
      default: 10,
    },
    search: null,
    headers: [],
    tableData: [],
    loadings: {
      filters: false,
      table: false,
    },
  }),
  async created() {
    await this.initializeFiltersWatcher("filters");
    await this.collectParametersFromQuery("filters");
    this.fetchData();
    this.fetchFilters();

    if (!this.$route.query.date) {
      this.initDefaultDateValue();
    }
  },

  methods: {
    getTableDataItemsPreparedForCsvExport() {
      let result = deepClone(this.tableData);

      result = result.map((item) => {
        Object.keys(item).forEach((key) => {
          if (key[0] === "$") item[key] = item[key]?.status;
          if (key === "links_google") item[key] = item[key]?.join("\n");
          if (String(item[key]) === "null") item[key] = "";
        });

        return item;
      });

      return result;
    },
    getCheckboxTdClass(td) {
      let successCondition =
        this.$moment(this.filters.date).diff(td.date, "days") >= -1 &&
        this.$moment(this.filters.date).diff(td.date, "days") <= 0;

      return {
        "colored-td": true,
        "full-size": true,
        green: successCondition,
      };
    },
    initDefaultDateValue() {
      this.filters.date = getPreviousWeekday();
    },
    getPathDetails(link) {
      if (!link) return "";

      return new URL(link).search;
    },
    async handleAddToIngone(item) {
      if (!(await this.open())) return;

      try {
        const url = "/dashboards/summary/top-5-pages-conversation-ignore";

        const payload = {
          url_id: item.url_id,
        };

        const response = await service.post(url, payload);

        if (response && response.data) {
          return this.$message.notification({
            title: "Done",
            text: "Successfully ignored.",
            type: "success",
          });
        }

        this.$message.notification({
          title: "Something wrong.",
          text: "Error while trying to ignore url.",
          type: "error",
        });
      } catch (e) {
        console.error("Error while ignoring url.", e);
      }
    },
    _$smartCustomTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (sortBy[i] === "page_type") {
            if (
              this.pageTypePriority[a[sortBy[i]]] >
              this.pageTypePriority[b[sortBy[i]]]
            )
              return sortDesc[i] ? -1 : 1;
            if (
              this.pageTypePriority[a[sortBy[i]]] <
              this.pageTypePriority[b[sortBy[i]]]
            )
              return sortDesc[i] ? 1 : -1;
            continue;
          }
          if (sortBy[i][0] === "$") {
            const aVal = a[sortBy[i]]?.status || "";
            const bVal = b[sortBy[i]]?.status || "";
            console.log(sortBy[i], aVal, bVal);
            if (aVal > bVal) return sortDesc[i] ? -1 : 1;
            if (aVal < bVal) return sortDesc[i] ? 1 : -1;
            continue;
          }

          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          const string =
            isNaN(parseInt(a[sortBy[i]])) && isNaN(parseInt(b[sortBy[i]]));

          if (string) {
            let aVal, bVal;
            if (a[sortBy[i]] === null || a[sortBy[i]] === "") aVal = 0;
            if (b[sortBy[i]] === null || b[sortBy[i]] === "") bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
          if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    goTo(url) {
      window.open(url, "_blank");
    },
    goToSW(item) {
      const params = {
        url_id: item.url_id,
        project_id: item.project_id,
        page_type: item.page_type_id,
      };

      const query = this._$buildQuery(params);

      window.open("/site-workplace/dashboard" + query, "_blank");
    },
    getCheckboxes(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (key[0] === "$") output.push(items[key]);
      });

      return output;
    },
    async fetchData() {
      try {
        this.loadings.table = true;

        const payload = {
          filter: this.filters,
        };

        const resp = await this.$store.dispatch(
          "top-5-pages-conversion/fetchData",
          payload
        );

        this.loadings.table = false;

        if (resp) {
          this.tableData = resp.items;
          this.headers = [...resp.headers, { text: "", value: "_actions" }];
        }
      } catch {
        this.loadings.table = false;
      }
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = [
        "project",
        "seo",
        "page_type",
        "project_status",
        "niche",
        "team",
      ];
      const payload = {
        type: "/dashboards/summary/top-5-pages-conversation",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        this.filtersData = { ...this.filtersData, ...resp };
      }
      this.loadings.filters = false;
    },
  },
};
</script>

<style lang="scss">
.top-5-page-conversion-table {
  thead {
    tr {
      th {
        &:not(:first-child) {
          writing-mode: vertical-lr;
          transform: rotate(180deg);
          text-align: center;
          height: auto !important;
          padding: 8px !important;
        }
      }
    }
  }
  tbody,
  thead {
    tr {
      td {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(14),
        &:nth-child(20) {
          border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
        }

        &:nth-last-child(1),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          border-left: 1px solid rgba(0, 0, 0, 0.15) !important;
        }
      }
      th {
        &:first-child {
          border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
        }
      }
      th {
        border: 0 !important;
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(14),
        &:nth-child(20) {
          border-left: 1px solid rgba(0, 0, 0, 0.15) !important;
        }
        &:nth-last-child(1),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
        }
      }
    }
  }
}
</style>
