<template>
  <div>
    <template v-if="type === FFM_TYPES.text">
      <v-text-field
        v-model="value"
        :label="filter.label"
        :placeholder="filter.placeholder"
        hide-details="auto"
        clearable
        :error-messages="errors"
        dense
        filled
      />
    </template>
    <template v-else-if="type === FFM_TYPES.textarea">
      <v-textarea
        v-model="value"
        :label="filter.label"
        :placeholder="filter.placeholder"
        hide-details="auto"
        :error-messages="errors"
        clearable
        dense
        filled
      />
    </template>
    <template v-else-if="type === FFM_TYPES.number">
      <v-text-field
        v-model="value"
        :label="filter.label"
        :placeholder="filter.placeholder"
        type="number"
        hide-details="auto"
        :error-messages="errors"
        dense
        clearable
        filled
      />
    </template>
    <template v-else-if="type === FFM_TYPES.singlePick">
      <v-autocomplete
        v-model="value"
        :label="filter.label"
        :placeholder="filter.placeholder"
        :items="items || []"
        hide-details="auto"
        :error-messages="errors"
        clearable
        dense
        filled
      />
    </template>
    <template v-else-if="type === FFM_TYPES.multiPick">
      <v-autocomplete
        v-model="value"
        :label="filter.label"
        :placeholder="filter.placeholder"
        :items="items || []"
        multiple
        hide-details="auto"
        :error-messages="errors"
        clearable
        dense
        filled
      />
    </template>
    <template v-else-if="type === FFM_TYPES.dateRange">
      <smart-date-filter
        :model-value="value"
        @update:modelValue="value = $event"
        filled
        :label="filter.label"
        :placeholder="filter.placeholder"
        :error-messages="errors"
      />
    </template>
    <template v-else-if="type === FFM_TYPES.numbersRange">
      <easy-range-input
        :model-value="value"
        @update:modelValue="value = $event"
        :label="filter.label"
        filled
        :error-messages="errors"
      />
    </template>
    <template v-else-if="type === FFM_TYPES.singleDate">
      <single-date-picker
        :model-value="value"
        @update:modelValue="value = $event"
        :error-messages="errors"
      />
    </template>
    <template v-else-if="type === FFM_TYPES.checkbox">
      <v-checkbox
        v-model="value"
        hide-details
        dense
        class="ma-0"
        :label="filter.label"
        :error-messages="errors"
      />
    </template>
    <template v-else-if="!type">
      <v-chip label>
        <v-icon small left>mdi-alert</v-icon>
        Filter type not exist
      </v-chip>
    </template>
    <template v-else>
      <v-chip label>
        <v-icon small left>mdi-alert</v-icon>
        Filter type not supported <b class="ml-1">{{ type }}</b>
      </v-chip>
    </template>

    <template v-if="false">
      <div class="d-flex flex-column">
        <div v-for="(error, idx) in errors" :key="idx" class="error--text py-1">
          {{ error }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import FiltersFactoryMixin from "@/mixins/FiltersFactoryMixin";
import SingleDatePicker from "@/components/UI/SingleDatePicker.vue";
import EasyRangeInput from "@/components/UI/EasyRangeInput.vue";
import { set } from "@/utils/functions";

export default {
  components: {
    EasyRangeInput,
    SingleDatePicker,
    SmartDateFilter,
  },
  mixins: [FiltersFactoryMixin],
  props: {
    context: {},
    type: String,
    filter: Object,
    items: Array,
    errors: Array,
    modelValue: {},
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        set(this.context, this.filter.path, value);
      },
    },
  },
};
</script>
