export default {
  state: {
    filters: {},
  },
  mutations: {
    "fourDxMainDashboard/setFilters"(state, payload) {
      state.filters = payload;
    },
  },
  actions: {},
  getters: {
    "fourDxMainDashboard/getFilters": (state) => state.filters,
  },
};
