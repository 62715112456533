<template>
  <v-autocomplete
    v-model="value"
    outlined
    chips
    hide-details="auto"
    color="blue-grey lighten-2"
    multiple
    :items="fixedItems"
    :label="label"
    :placeholder="placeholder"
    :disabled="loading"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ data.item.text }}
      </v-chip>
    </template>
    <template #item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item" />
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.text" />
          <v-list-item-subtitle>
            {{ data.item.group }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { deepClone } from "@/utils/functions";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Type to search",
    },
  },

  data: () => ({
    fixedItems: [],
  }),

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },

  watch: {
    items: {
      immediate: true,
      handler(val) {
        if (!val) return;

        this.fixedItems = this.sortAndGroupArray(val);
        this.$forceUpdate();
      },
    },
  },

  methods: {
    sortAndGroupArray(arr) {
      const tmprArr = deepClone(arr);

      tmprArr.sort((a, b) => a.group.localeCompare(b.group));

      const result = [];

      let currentGroup = null;
      tmprArr.forEach((item) => {
        if (item.group !== currentGroup) {
          currentGroup = item.group;
          result.push({ header: currentGroup });
        }
        result.push(item);
      });

      return result;
    },
    remove(item) {
      const index = this.value.indexOf(item.value);
      if (index >= 0) this.value.splice(index, 1);
    },
  },
};
</script>
