<template>
  <span>
    <span v-if="false" class="d-inline-flex" style="gap: 4px">
      <span title="Number of competitors">
        C<sup>
          <b>{{ item.number_of_competitors }}</b>
        </sup>
      </span>
      <span
        :title="`${getValueByName('spam_essay')} Essay links found - ${
          item.spam_date
        }`"
      >
        e<sup>{{ getValueByName("spam_essay") }}</sup>
      </span>
      <span
        :title="`${getValueByName('spam_casino')} Casino links found - ${
          item.spam_date
        }`"
      >
        c<sup>{{ getValueByName("spam_casino") }}</sup>
      </span>
      <span :title="`porn - ${item.spam_date}`">
        p<sup>{{ getValueByName("spam_porn") }}</sup>
      </span>
      <span
        :title="`${getValueByName('spam_replica')} Replica links found - ${
          item.spam_date
        }`"
      >
        r<sup>{{ getValueByName("spam_replica") }}</sup>
      </span>
      =
      <div>
        <span title="spam external link">{{ getSpamExternalLink }}</span
        >/<span title="external link"
          >{{ getValueByName("external_link") }}
        </span>
      </div>
    </span>
    <div class="d-inline-flex align-center" style="gap: 2px">
      <spam-cell-item
        :label="labels.competitors"
        :value="item.number_of_competitors"
        found-message="$v competitors found"
      />
      <spam-cell-item
        :label="labels.essay"
        :value="getValueByName('spam_essay')"
        found-message="$v essay links found"
      />
      <spam-cell-item
        :label="labels.casino"
        :value="getValueByName('spam_casino')"
        found-message="$v casino links found"
      />
      <spam-cell-item
        :label="labels.porn"
        :value="getValueByName('spam_porn')"
        found-message="$v porn links found"
      />
      <spam-cell-item
        :label="labels.replica"
        :value="getValueByName('spam_replica')"
        found-message="$v replica links found"
      />
      <span class="px-1">=</span>
      <spam-cell-item
        :label="labels.result"
        hide-abb
        flat
        found-message="$v"
        :value="`${getSpamExternalLink}/${getValueByName('external_link')}`"
      >
        <template #foundMessage>
          {{ getSpamExternalLink }} spam external links <br />
          {{ getValueByName("external_link") }} external links
        </template>
      </spam-cell-item>
    </div>
  </span>
</template>

<script>
import SpamCellItem from "@/components/Ltt/DeletedLinks/SpamCellItem.vue";

export default {
  components: { SpamCellItem },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    emptySymbol: "?",
    labels: {
      competitors: {
        full: "Number of competitors",
        abb: "C",
      },
      essay: {
        full: "Essay links",
        abb: "e",
      },
      casino: {
        full: "Casino links",
        abb: "c",
      },
      porn: {
        full: "Porn links",
        abb: "p",
      },
      replica: {
        full: "Replica links",
        abb: "r",
      },
      result: {
        full: "Spam external links / External links",
        abb: "",
      },
    },
  }),
  computed: {
    isSpamEssayExist() {
      return this.item.spam_essay !== null;
    },
    getSpamExternalLink() {
      const item = this.item;
      const result =
        item.spam_replica + item.spam_porn + item.spam_essay + item.spam_casino;

      if (this.isSpamEssayExist) return result;

      return this.emptySymbol;
    },
  },
  methods: {
    getValueByName(name) {
      if (this.isSpamEssayExist) return this.item[name];

      return this.emptySymbol;
    },
  },
};
</script>
