<template>
  <v-container
    fluid
    class="similar-task-creating"
    style="padding-bottom: 200px"
  >
    <div class="d-flex justify-center">
      <v-row dense style="max-width: 900px">
        <v-col cols="12">
          <h2 class="mb-4 d-flex">
            <span>To Those Responsible</span>
            <v-spacer />
            <v-btn
              v-if="results && results.tasks && results.tasks.length > 0"
              color="primary"
              outlined
              @click="resultsDialogModel = true"
              width="180px"
            >
              Show Results
            </v-btn>
          </h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
        <v-col cols="12">
          <div style="opacity: 0.5">
            <span> Create </span>

            <span class="mx-2">/</span>

            <span> New Task</span>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-4 d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center">
        <to-those-responsibles-task-card
          :form="mainTask"
          :filters-data="filtersData"
          :loadings="loadings"
          :validate="validate"
          @createTask="handleCreateTask"
        />
      </v-col>
      <v-col cols="12" class="mt-4 d-flex justify-center">
        <v-card outlined rounded="xl" style="max-width: 1600px">
          <v-card-title class="d-flex">
            Select Projects
            <v-spacer />
            <v-text-field
              v-model="searchProjectsTable"
              hide-details
              dense
              label="Search"
              append-icon="mdi-magnify"
              style="max-width: 180px"
            ></v-text-field>
          </v-card-title>
          <v-card-subtitle>
            <v-row dense>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.niche"
                  label="Niche"
                  :handling="false"
                  @update:modelValue="sortTableBy.niche = $event"
                  :items="tableFiltersData.niche"
                ></smart-autocomplete>
              </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.team"
                  label="Team"
                  :handling="false"
                  @update:modelValue="sortTableBy.team = $event"
                  :items="tableFiltersData.team"
                ></smart-autocomplete>
              </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.main_seo"
                  label="Main SEO"
                  :handling="false"
                  @update:modelValue="sortTableBy.main_seo = $event"
                  :items="tableFiltersData.main_seo"
                ></smart-autocomplete>
              </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.group"
                  label="Group"
                  :handling="false"
                  @update:modelValue="sortTableBy.group = $event"
                  :items="tableFiltersData.group"
                ></smart-autocomplete>
              </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.email"
                  label="Responsible SEO"
                  :handling="false"
                  @update:modelValue="sortTableBy.email = $event"
                  :items="tableFiltersData.email"
                ></smart-autocomplete>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-data-table
              v-model="mainTask.projects"
              :search="searchProjectsTable"
              show-select
              :loading="loadings.filters"
              :headers="tableHeaders"
              group-by="group"
              :items-per-page="-1"
              :items="getFilteredTableItems"
            >
              <template #[`group.header`]="{ isOpen, toggle, group }">
                <td>
                  <v-btn icon @click="toggle" class="mr-2">
                    <v-icon>
                      {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                    </v-icon>
                  </v-btn>
                </td>
                <td>
                  {{ group }}
                </td>
                <td :colspan="tableHeaders.length - 1"></td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="resultsDialogModel"
      content-class="remove-dialog-shadow"
      persistent
      width="800"
    >
      <v-card class="shadow-e1-bordered" rounded="lg">
        <v-card-title> Results </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="results && results.tasks && results.tasks.length > 0"
            :headers="resultsHeaders"
            :items="results.tasks"
            hide-default-footer
            :items-per-page="-1"
          >
            <template #[`item.user_to`]="{ item }">
              {{ item.user_to.name }}
            </template>
            <template #[`item.name`]="{ item }">
              <div v-if="item.status === 'error'">
                {{ item.name }}
              </div>
              <a
                v-else
                :href="'https://ra.worksection.com/' + item.page"
                target="_blank"
                >{{ item.name }}</a
              >
            </template>
            <template #[`item.status`]="{ item }">
              <div v-if="item.status === 'error'" class="error--text">
                {{ item.message }}
              </div>
              <div v-else>
                {{ item.status }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-btn @click="resultsDialogModel = false" class="mr-2" width="160px"
            >Edit current</v-btn
          >
          <v-btn @click="refreshPage" color="primary" width="220px"
            >Create Another</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import { deepClone, uid } from "@/utils/functions";
import ToThoseResponsiblesTaskCard from "@/components/SimilarTaskCreating/UI/ToThoseResponsiblesTaskCard.vue";
import service from "@/plugins/service";
import SmartAutocomplete from "../../UI/SmartAutocomplete.vue";

export default {
  components: { SmartAutocomplete, ToThoseResponsiblesTaskCard },
  data: () => ({
    searchProjectsTable: null,
    resultsHeaders: [
      {
        text: "User",
        value: "user_to",
      },
      {
        text: "Task Name",
        value: "name",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
    results: {
      subtasks: [],
      task: {},
    },
    tableFiltersData: {
      niche: [],
      team: [],
      main_seo: [],
      email: [],
      group: [],
    },
    resultsDialogModel: false,
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Choose type",
        to: "/similar-task-creating/choose-type",
        exact: true,
      },
      {
        text: "To Those Responsible",
        disabled: true,
      },
    ],
    search: null,
    tableHeaders: [
      {
        text: "Domain",
        value: "project",
      },
      {
        text: "Subproject",
        value: "subproject",
      },
      {
        text: "WS id",
        value: "worksection_id",
      },
      {
        text: "Main SEO",
        value: "main_seo",
      },
      {
        text: "Resposible SEO",
        value: "email",
      },
      {
        text: "Team",
        value: "team",
      },
      {
        text: "Niche",
        value: "niche",
      },
    ],
    sortTableBy: {
      niche: [],
      email: [],
      main_seo: [],
      team: [],
      group: [],
    },
    mainTask: {
      task_title: "", // required
      task_desc: "",
      priority: 8,
      date: [],
      tags: [],
      parent_task_id: null,
      estimated_time: null,
      estimated_money: null,
      projects: [],
      seo: "responsible",
    },
    loadings: {
      createTask: false,
      filters: false,
    },
    filtersData: {
      tags: [],
      users: [],
      serpinsider_projects: [],
    },
    menuProps: DEFAULT_MENU_PROPS,
  }),
  created() {
    this.fetchData();
  },
  mounted() {
    const priority = this.$route.query.priority
      ? this.$route.query.priority
      : 8;
    const tags = this.$route.query.tags;

    const data = {
      ...this.$route.query,
      priority: Number(priority),
      tags,
    };

    // tags: 51283 to tags: [ 51283 ]
    if (typeof data.tags === "string")
      data.tags = data.tags.split(",").map((v) => Number(v));

    if (Object.keys(this.$route.query).length > 0) {
      this.mainTask = { ...this.mainTask, ...data };

      this.fetchFilters(null, () => {
        if (this.$route.query.ws_project_id) {
          this.selectProjectByWsId(this.$route.query.ws_project_id);
        }
      });
      return;
    }

    this.fetchFilters({
      niche: ["casino"],
      group: ["Active"],
    });
  },
  computed: {
    getFilteredTableItems() {
      const amountOfSelectedFilters = Object.values(this.sortTableBy).filter(
        (v) => v.length > 0
      ).length;

      if (amountOfSelectedFilters <= 0)
        return this.filtersData.serpinsider_projects;

      return this.filtersData.serpinsider_projects.filter((v) => {
        let match = 0;

        Object.keys(this.sortTableBy).forEach((key) => {
          if (match >= amountOfSelectedFilters) return;

          if (this.sortTableBy[key].includes(v[key])) match++;
        });

        return match === amountOfSelectedFilters;
      });
    },
    validate() {
      if (!this.mainTask.task_title) return true;
      if (!this.mainTask.projects.length > 0) return true;

      return false;
    },
  },
  methods: {
    selectProjectByWsId(wsId) {
      const match = this.filtersData.serpinsider_projects.filter(
        (v) => Number(v.worksection_id) === Number(wsId)
      );
      this.mainTask.projects.push(...match);
    },
    refreshPage() {
      this.$router.go();
    },
    handleCopyDataFromMainTask() {
      const main = this.mainTask;
      const tmpr = deepClone(this.subtaskForm);

      tmpr.task_title = main.task_title;
      tmpr.task_desc = main.task_desc;
      tmpr.priority = main.priority;
      tmpr.date = main.date;
      tmpr.tags = main.tags;
      tmpr.estimated_time = main.estimated_time;
      tmpr.estimated_money = main.estimated_money;

      this.subtaskForm = tmpr;
    },
    async fetchFilters(presets = null, callback = () => {}) {
      this.loadings.filters = true;
      const filters = ["tags", "users", "serpinsider_projects"];
      const payload = {
        type: "/similar-task-creating",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const tmpr = resp;

        tmpr.serpinsider_projects = tmpr.serpinsider_projects.map((v) => {
          v.id = uid();
          return v;
        });

        this.filtersData = tmpr;

        callback();
      }

      this.initTableFilters(this.filtersData.serpinsider_projects, presets);

      if (this.$route.query.project_id) {
        this.mainTask.projects = this.filtersData.serpinsider_projects.filter(
          (v) => v.project_id === Number(this.$route.query.project_id)
        );
      }

      this.loadings.filters = false;
    },
    initTableFilters(tableItems, presets) {
      ["niche", "team", "email", "group", "main_seo"].forEach((key) => {
        this.tableFiltersData[key] = [
          ...new Set(tableItems.map((obj) => obj[key])),
        ];

        this.sortTableBy[key] = [...this.tableFiltersData[key]];
      });

      if (!presets) return;

      Object.entries(presets).forEach(([key, value]) => {
        this.sortTableBy[key] = value;
      });
    },
    async handleCreateTask() {
      try {
        const url = "/worksection/task/create";

        const payload = {
          type: "CREATE_TO_THOSE_RESPONSIBLE",
          main_task: this.mainTask,
        };

        this.loadings.createTask = true;

        const resp = await service.post(url, payload);

        this.loadings.createTask = false;
        if (resp) {
          this.results = resp.data;
          this.resultsDialogModel = true;
        }
      } catch (e) {
        console.error(e);
        this.loadings.createTask = false;
      }
    },
    async fetchData() {
      const url = "";

      const payload = {
        filter: this.filters,
      };

      await axios.post(url, payload);
    },
  },
};
</script>

<style lang="scss">
.similar-task-creating {
  .v-select--chips {
    .v-select__selections {
      span {
        margin: 2px 4px;
      }
    }
  }
  &__editor {
    .v-sheet.v-toolbar {
      background-color: transparent !important;
    }

    .v-card {
      box-shadow: none !important;
    }

    .tiptap-vuetify-editor__content {
      min-height: 120px;
    }
  }
}
</style>
