<template>
  <div ref="svg">
    hello
  </div>
</template>

<script>
export default {
  name: "VtSparkline",
  props: [
      'items',
      'color',
      'class_css'
  ],
  methods: {
    draw() {
      let width = 100
      let height = 50

      let padding = 10

      let width_with_padding = width - padding * 2
      let height_with_padding = height - padding * 2
      let max_value = 101

      let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')

      svg.setAttribute('stroke-linecap', 'round')
      svg.setAttribute('class', this.class_css)
      svg.setAttribute('width', width)
      svg.setAttribute('height', height)

      let g = document.createElementNS('http://www.w3.org/2000/svg', 'g')

      let path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

      let d = ''

      let i_len = this.items.length - 1
      let w_c = width_with_padding / i_len

      for (let i = 0; i <= i_len; i++) {
        let x = i * w_c + padding
        let y = this.items[i] * height_with_padding / max_value + padding

        if (d === '') {
          d = `M ${x} ${y} `
        } else {
          d += `L ${x} ${y} `
        }
      }

      path.setAttribute('d', d)
      path.setAttribute('fill', 'none')
      path.setAttribute('stroke', this.color)
      path.setAttribute('stroke-width', '2')
      path.setAttribute('clip-path', 'url(#rablfilter0)')

      g.append(path)

      svg.append(g)

      this.$refs['svg'].innerHTML = '';
      this.$refs['svg'].append(svg);
    }
  },
  created() {
    let self = this

    document.addEventListener("DOMContentLoaded", function () {
      self.draw()
    });
  },
  mounted() {
    this.draw()
  },
}
</script>

<style scoped>

</style>