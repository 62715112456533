var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simple-table"},[_c('div',{staticClass:"simple-table__wrapper",on:{"scroll":_vm.handleScroll}},[_c('table',{staticClass:"simple-table__table centered-content"},[(!_vm.haveIndividualSubheaders)?[_c('tr',{staticClass:"simple-table__headers"},[_c('th',{staticClass:"simple-table__placeholder-cell"}),_vm._l((_vm.getMainHeaders),function(header){return _c('th',{key:header.id,staticClass:"simple-table__headers",attrs:{"colspan":_vm.calculateColspan}},[_vm._v(" "+_vm._s(header.text)+" ")])})],2),(_vm.haveSubheaders)?_c('tr',{staticClass:"simple-table__sub-headers"},[_c('th',{staticClass:"simple-table__placeholder-cell url",staticStyle:{"text-align":"left"}},[_vm._v(" Url ")]),_vm._l((_vm.getSubheaders),function(subheaders){return _vm._l((subheaders),function(header){return _c('th',{key:header.id},[_vm._v(" "+_vm._s(header.text)+" ")])})})],2):_vm._e()]:[_c('tr',{staticClass:"simple-table__headers borderless"},[_c('th',{staticClass:"simple-table__placeholder-cell"}),_vm._l((_vm.data.headers),function(header){return _c('th',{key:header.value,staticClass:"simple-table__headers",attrs:{"colspan":header.colspan}},[_vm._v(" "+_vm._s(header.text)+" ")])})],2),(_vm.haveSubheaders)?_c('tr',{staticClass:"simple-table__sub-headers"},[_c('th',{staticClass:"simple-table__placeholder-cell url",staticStyle:{"text-align":"left"}},[_vm._v(" Url ")]),_vm._l((_vm.data.subheaders),function(header){return _c('th',{key:header.id},[_vm._v(" "+_vm._s(header.text)+" ")])})],2):_vm._e()],_vm._l((_vm.getItems),function(items){return _c('tr',{key:items.id,class:`simple-table__items ${_vm.scrolledX ? 'scrolled-x' : ''}`},_vm._l((items.items),function(item,i){return _c('td',{key:item.id,class:i % _vm.calculateColspan === 0 && !_vm.haveIndividualSubheaders
              ? 'bordered'
              : '',style:(`background-color: ${_vm.getRgb(
            item.value.value,
            _vm.getMax(_vm.getItems, i),
            50,
            233,
            153
          )}`)},[(i === 0)?[_c('td-domain',{attrs:{"data":item.value}})]:[(item.value && item.value.value)?_c('div',{staticClass:"colored-td colored-td--clickable",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToSpiderLink(item.value, items.items[0].value)}}},[_vm._v(" "+_vm._s(item.value.value)+" ")]):_vm._e()]],2)}),0)})],2)]),_c('div',{staticClass:"simple-table__pagination"},[_c('v-pagination',{attrs:{"length":_vm.items.length},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }