<template>
  <div class="mx-4 my-4" ref="parent">
    <v-row dense>
      <v-col
        v-for="graph in componentData()?.items"
        :key="graph.id"
        :cols="graph.colspan"
        style="min-width: 460px"
      >
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            <b>{{ graph.legend }}</b>
          </v-card-title>
          <v-card-text>
            <time-chart
              :series="graph.series"
              :title="graph.name"
              :categories="graph.categories"
              :data="graph"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TimeChart from "../../DashboardDev/TimeChart.vue";
import LazyDomElementRenderingMixin from "../../../mixins/LazyDomElementRenderingMixin";

export default {
  mixins: [LazyDomElementRenderingMixin],

  components: { TimeChart },

  inject: ["componentData"],

  mounted() {
    this.initializeLazyRender([this.$refs.parent]);
  },
};
</script>
