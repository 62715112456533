import service from "../plugins/service";

export default {
  data: () => ({
    prm_permissions: [],
  }),
  async created() {
    this.prm_permissions = await this.fetchPermission();
  },
  methods: {
    async fetchPermission() {
      let url = "/user/get-permissions";

      const resp = await service.get(url);

      if (resp) {
        return resp.data;
      }
    },
  },
};
