<template>
  <v-card class="styled-card--default">
    <v-card-title>{{ title }}</v-card-title>
    <v-divider />
    <v-card-text class="px-0">
      <template v-if="!loading && data.length === 0">
        <div style="text-align: center">Press "get data"</div>
      </template>
      <template v-else-if="!loading">
        <v-data-table
          :headers="headers"
          :items="getFixedItems"
          class="positions-table"
          dense
          :items-per-page="-1"
          :custom-sort="_$smartCustomTableSort"
          hide-default-footer
        >
          <template #body="{ items }">
            <tbody>
              <tr
                v-for="(row, index) in items"
                :key="index"
                :class="{
                  'divider-border': row.name.divider === 2,
                  divider: row.name.divider === 1,
                }"
              >
                <td :class="{ bold: row.name.text.includes(',%') }">
                  {{ row.name.text || row.name }}
                </td>
                <td class="text-center">
                  {{ String(row.$t) === "0" ? "" : row.$t }}
                </td>
                <td class="text-center">
                  {{ String(row.$y) === "0" ? "" : row.$y }}
                </td>
                <td
                  v-for="(td, idx) in getItems(row)"
                  :key="idx"
                  class="text-center"
                >
                  {{ String(td) === "0" ? "" : td }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <v-skeleton-loader type="table-row-divider@6" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { ONE_YEAR_REPORT_LINKS_BY_PROJECT_TABLE_HEADERS } from "@/utils/defaultData";
import TableSorting from "@/mixins/TableSorting";

export default {
  props: ["data", "title", "loading"],
  mixins: [TableSorting],
  data: () => ({
    headers: ONE_YEAR_REPORT_LINKS_BY_PROJECT_TABLE_HEADERS,
  }),
  computed: {
    getFixedItems() {
      const data = JSON.parse(JSON.stringify(this.data));
      return data.map((item) => {
        const keys = Object.keys(item);

        keys.forEach((key) => {
          if (item[key] === 0) item[key] = null;
        });

        return item;
      });
    },
  },
  methods: {
    getItems(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (key === "$t") return;
        if (key === "$y") return;
        if (key[0] === "$") output.push(items[key]);
      });

      return output;
    },
  },
};
</script>
