import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import middlewarePipeline from "@/middleware/pipeline";
import routes from "@/utils/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes,
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  if (!middleware || middleware.length === 0) return next();

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export const APP_ROUTES = router.getRoutes();

export default router;
