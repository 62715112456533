<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-end">
        <div>
          <h2>Manage Users</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </div>
      </v-col>
      <v-col cols="12">
        <div class="mb-2 d-flex" style="gap: 0.25rem">
          <v-text-field
            v-model="searchQuery"
            hide-details
            dense
            append-icon="mdi-magnify"
            outlined
            placeholder="Type to search"
            style="max-width: 360px"
          />
          <v-spacer />

          <v-btn
            class="text-normal"
            height="40"
            outlined
            :loading="loadings.getUsers"
            @click="initializeUsersList"
          >
            Get data
          </v-btn>

          <v-btn
            color="primary"
            class="text-normal"
            height="40"
            @click="handleCreateNewUser"
          >
            Create new <v-icon small right>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-data-table
          :loading="loadings.getUsers"
          :headers="tableData.headers"
          :items="tableData.items"
          :search="searchQuery"
          dense
          :items-per-page="50"
          class="rounded-lg border"
          :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
        >
          <template #[`item._actions`]="{ item }">
            <v-menu
              :close-on-content-click="false"
              bottom
              content-class="shadow-e2-bordered"
            >
              <template #activator="{ on }">
                <v-icon small v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list dense>
                <v-list-item @click="handleOpenEditUserDialog(item)">
                  <v-list-item-icon>
                    <v-icon
                      v-if="!loadings.editUser.includes(item.id)"
                      size="16"
                    >
                      mdi-pencil-outline
                    </v-icon>
                    <v-progress-circular
                      v-else
                      size="16"
                      width="2"
                      indeterminate
                    />
                  </v-list-item-icon>
                  <v-list-item-content> Edit </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <edit-user-dialog
      :model-value="editUserDialogModelValue"
      @update:modelValue="editUserDialogModelValue = $event"
      :user-data="editUserData"
      :after-edit-callback="() => initializeUsersList()"
    ></edit-user-dialog>
    <create-user-dialog
      :model-value="createUserDialogModel"
      @update:modelValue="createUserDialogModel = $event"
      :after-create-callback="() => initializeUsersList()"
    />
  </v-container>
</template>

<script>
import CreateUserDialog from "./components/CreateUserDialog.vue";
import { UserManageApiService } from "./services/UserManageApiService";
import EditUserDialog from "./components/EditUserDialog.vue";
import { ACCOUNTS_TABLE_HEADERS } from "./defaultData";

export default {
  components: { EditUserDialog, CreateUserDialog },
  data: () => ({
    editUserData: {
      roles: [],
      user: {},
      userRoles: [],
    },
    editUserDialogModelValue: false,
    createUserDialogModel: false,
    tableData: {
      headers: ACCOUNTS_TABLE_HEADERS,
      items: [],
    },
    searchQuery: "",
    loadings: {
      editUser: [],
      getUsers: false,
    },
    breadcrumbs: [
      {
        text: "Access",
        disabled: true,
      },
      {
        text: "Manage Users",
        disabled: true,
      },
    ],
  }),

  mounted() {
    this.initializeUsersList();
  },

  methods: {
    async initializeUsersList() {
      try {
        this.loadings.getUsers = true;

        const { data } = await UserManageApiService().getUsers();

        this.tableData.items = data;
      } catch (e) {
        console.error("Error while initializing users list.", e);
      } finally {
        this.loadings.getUsers = false;
      }
    },
    handleCreateNewUser() {
      this.createUserDialogModel = true;
    },
    async handleOpenEditUserDialog(user) {
      this.editUserData = await this.getUserData(user);
      this.editUserDialogModelValue = true;
    },
    async getUserData(user) {
      try {
        console.log(user);
        this.loadings.editUser.push(user.id);

        const { data } = await UserManageApiService().getUser(user.id);

        return data;
      } catch (e) {
        console.error("Error while loading user data.", e);
      } finally {
        this.loadings.editUser = this.loadings.editUser.filter(
          (v) => v !== user.id
        );
      }
    },
  },
};
</script>
