<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="date-picker">
          <v-card>
          <v-card-text>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="Birthday date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  range
                  :active-picker.sync="activePicker"
                  @change="saveHandler"
              ></v-date-picker>
            </v-menu>
            <div class="date-picker__timelines">
              <v-btn x-small @click="setMonth('previous')">
                prev month
              </v-btn>
              <v-btn x-small @click="setMonth('current')">
                current month
              </v-btn>
              <v-btn x-small @click="setQuarter('previous')">
                prev quarter
              </v-btn>
              <v-btn x-small @click="setQuarter('current')">
                current quarter
              </v-btn>
              <v-btn x-small @click="setYear('previous')">
                prev year
              </v-btn>
              <v-btn x-small @click="setYear('current')">
                current year
              </v-btn>
            </div>
          </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelpIndex',
  data: () => ({
    date: null,
    activePicker: null,
    menu: false,
  }),
  methods: {
    setMonth(variant) {
      if(variant === 'previous') {
        const prevMonthStart = this.$moment(new Date()).subtract(1,'months').startOf('month').format('YYYY-MM-DD');
        const prevMonthEnd = this.$moment(new Date()).subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        return this.date = [prevMonthStart, prevMonthEnd]
      }
      const prevMonthStart = this.$moment(new Date()).startOf('month').format('YYYY-MM-DD');
      const prevMonthEnd = this.$moment(new Date()).endOf('month').format('YYYY-MM-DD');
      return this.date = [prevMonthStart, prevMonthEnd]
    },
    setQuarter(variant) {
      if (variant === 'previous') {
        const prevQuarterStart = this.date = this.$moment().subtract(1, 'Q').startOf('Q').format('YYYY-MM-DD')
        const prevQuarterEnd = this.date = this.$moment().subtract(1, 'Q').endOf('Q').format('YYYY-MM-DD')
        return this.date =  [prevQuarterStart, prevQuarterEnd]
      }
      const prevQuarterStart = this.date = this.$moment().startOf('Q').format('YYYY-MM-DD')
      const prevQuarterEnd = this.date = this.$moment().endOf('Q').format('YYYY-MM-DD')
      return this.date =  [prevQuarterStart, prevQuarterEnd]
    },
    setYear(variant) {
      if (variant === 'previous') {
        const prevYearStart = this.date = this.$moment().subtract(1, 'years').startOf('years').format('YYYY-MM-DD')
        const prevYearEnd = this.date = this.$moment().subtract(1, 'years').endOf('years').format('YYYY-MM-DD')
        return this.date =  [prevYearStart, prevYearEnd]
      }
      const prevYearStart = this.date = this.$moment().startOf('years').format('YYYY-MM-DD')
      const prevYearEnd = this.date = this.$moment().endOf('years').format('YYYY-MM-DD')
      return this.date =  [prevYearStart, prevYearEnd]
    },
    saveHandler() {

    },
  }
}
</script>
