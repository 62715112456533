import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'conversion-4/fetchTableData'(ctx, payload) {
            const url = '/content-checker/all-pages'

            const resp = await Service.post(url, payload,true )
            if(resp && resp.status === 200) {
                return resp.data
            }
            return false
        },
        async 'conversion-4/fetchVisits'(ctx, {payload, projectId}) {
            const url = `/analytics/conversion/report4/${projectId}`

            const resp = await Service.post(url, payload)
            if(resp && resp.status === 200) {
                return resp.data
            }
            return false
        },
        async 'conversion-4/fetchBrands'(ctx, {payload, projectId}) {
            const url = `/analytics/conversion/report4/brand/${projectId}`

            const resp = await Service.get(url, true, payload)
            if(resp && resp.status === 200) {
                return resp.data
            }
            return false
        },
    },
    getters: {},
}