<template>
  <v-dialog v-model="dialog" width="680" content-class="my-shadow--e2">
    <v-card elevation="0" class="rounded-lg" outlined>
      <v-card-title class="pa-4 text-body-2">
        Add to queue
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="domains"
              :placeholder="`domain.example.com\ndomain.example.com\n...`"
              hide-details
              outlined
              style="width: 100%"
              dense
              rows="24"
              :disabled="pending"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="handleAdd"
          class="text-normal px-6"
          color="primary"
          :loading="pending"
          large
        >
          Add to queue <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import { redirectsApiService } from "../../services/redirectsApiService";

export default {
  mixins: [Dialog],

  data: () => ({
    domains: "",
    pending: false,
    redirectsApiService: null,
  }),

  created() {
    this.redirectsApiService = redirectsApiService();
  },

  methods: {
    resetForm() {
      this.domains = "";
    },
    async handleAdd() {
      try {
        this.pending = true;

        const payload = {
          data: this.domains,
        };

        const { data } = await this.redirectsApiService.addToQueue(payload);

        if (data.success) {
          this.resetForm();
          this.$emit("dirty");
          this.dialog = false;
        }
      } catch (e) {
        console.error("Error while trying to add the domain to query.", e);
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>
