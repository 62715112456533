export default {
  data: () => ({
    selectedNicheFilter: "all",
    nicheItems: [
      { text: "All", value: "all" },
      { text: "Essay", value: "essay" },
      { text: "Casino", value: "casino" },
    ],
  }),
  computed: {
    filteredByNicheTableItems() {
      if (!this.selectedNicheFilter || this.selectedNicheFilter === "all")
        return this.items;

      return this.items.filter((v) => v.niche === this.selectedNicheFilter);
    },
  },
};
