<template>
  <v-card class="styled-card--default">
    <v-card-title> Element history </v-card-title>
    <v-divider />
    <v-card-text class="px-0">
      <v-data-table
        dense
        :headers="headers"
        :items="data?.field_history?.items"
      >
        <template #[`item.changes`]="{ item }">
          <div v-for="[key, value] in Object.entries(item.changes)" :key="key">
            {{ key }}: <b>{{ value }}</b>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {},
  },
  data: () => ({
    headers: [
      {
        text: "User",
        value: "email",
      },
      {
        text: "Field",
        value: "field_name",
      },
      {
        text: "Changes",
        value: "changes",
      },
      {
        text: "Date",
        value: "date",
      },
    ],
  }),
};
</script>
