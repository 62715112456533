<template>
  <td style="text-align: center">
    <div v-if="data.task_closed" class="mark mark--success" />
    <div :class="`colored-td${getAnchorColor} td-content`">
      <template v-if="data.pos_by_anchor">
        {{ data.pos_by_anchor }}
      </template>
      <template v-else></template>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getAnchorColor() {
      const anchor = parseInt(this.data.pos_by_anchor);

      if (anchor === 1) return "red";
      if (anchor > 2) return "yellow";

      return "";
    },
  },
};
</script>
