<template>
  <v-chip @click="dialog = !dialog" label outlined :disabled="!items">
    <v-progress-circular
      v-if="loading"
      indeterminate
      size="12"
      width="2"
      color="primary"
      class="mr-2"
    />
    <v-icon v-else left small>mdi-eye-outline</v-icon>
    Tasks
    <my-badge v-if="getAmountOfUnclosedTasks" class="success white--text ml-1">
      {{ getAmountOfUnclosedTasks }}
    </my-badge>
    <v-dialog v-model="dialog" content-class="my-shadow--e4">
      <v-card class="styled-card--default">
        <v-card-title>
          All pages problems
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-tabs v-model="tab">
          <v-tab key="closed"> Closed </v-tab>
          <v-tab key="not_closed"> Not closed </v-tab>
        </v-tabs>
        <v-divider />
        <v-card-text class="pa-0">
          <v-tabs-items v-model="tab">
            <v-tab-item key="closed">
              <template v-if="items?.closed && items.closed?.length > 0">
                <v-data-table :items="items?.closed" :headers="computedHeaders">
                  <template #[`item.user_from`]="{ value }">
                    <user-preview
                      hide-initials
                      :email="value.email"
                      :name="value.name"
                    />
                  </template>
                  <template #[`item.user_to`]="{ value }">
                    <user-preview
                      hide-initials
                      :email="value.email"
                      :name="value.name"
                    />
                  </template>
                  <template #[`item.page`]="{ value }">
                    <a
                      :href="`https://ra.worksection.com/${value}`"
                      target="_blank"
                      >{{ value }}</a
                    >
                  </template>
                  <template #[`item.tags`]="{ value }">
                    <div
                      v-if="value"
                      class="d-flex flex-wrap py-2"
                      style="gap: 4px"
                    >
                      <v-chip
                        v-for="chip in Object.values(value)"
                        :key="chip"
                        small
                        label
                        outlined
                        class="px-1"
                        @click="handleCopy(chip)"
                      >
                        <b class="mr-1">#</b> {{ chip }}
                      </v-chip>
                    </div>
                  </template>
                </v-data-table>
              </template>
              <template v-else>
                <div class="pa-4">Not items...</div>
              </template>
            </v-tab-item>
            <v-tab-item key="not_closed">
              <template
                v-if="items?.not_closed && items.not_closed?.length > 0"
              >
                <v-data-table
                  :items="items?.not_closed"
                  :headers="computedHeaders"
                >
                  <template #[`item.user_from`]="{ value }">
                    <user-preview
                      hide-initials
                      :email="value.email"
                      :name="value.name"
                    />
                  </template>
                  <template #[`item.user_to`]="{ value }">
                    <user-preview
                      hide-initials
                      :email="value.email"
                      :name="value.name"
                    />
                  </template>
                  <template #[`item.name`]="{ item }">
                    <a
                      :href="`https://ra.worksection.com/${item.page}`"
                      target="_blank"
                      >{{ item.name }}</a
                    >
                  </template>
                  <template #[`item.tags`]="{ value }">
                    <div
                      v-if="value"
                      class="d-flex flex-wrap py-2"
                      style="gap: 4px"
                    >
                      <v-chip
                        v-for="chip in Object.values(value)"
                        :key="chip"
                        small
                        label
                        outlined
                        class="px-1"
                        @click="handleCopy(chip)"
                      >
                        <b class="mr-1">#</b> {{ chip }}
                      </v-chip>
                    </div>
                  </template>
                </v-data-table>
              </template>
              <template v-else>
                <div class="pa-4">Not items...</div>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-chip>
</template>

<script>
import MyBadge from "@/components/UI/MyBadge.vue";
import UserPreview from "@/components/UI/UserPreview.vue";
import Notification from "@/mixins/Notification";
import copy from "copy-to-clipboard";

export default {
  components: { UserPreview, MyBadge },
  mixins: [Notification],
  props: {
    loading: Boolean,
    items: {},
  },
  data: () => ({
    dialog: false,
    tab: null,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Pr",
        value: "priority",
      },
      {
        text: "Creator",
        value: "user_from",
      },
      {
        text: "Executive",
        value: "user_to",
      },
      {
        text: "Created at",
        value: "date_added",
      },
      {
        text: "Deadline",
        value: "date_end",
      },
      {
        text: "Estimate",
        value: "max_time",
      },
      {
        text: "Labels",
        value: "tags",
      },
    ],
  }),
  computed: {
    getAmountOfUnclosedTasks() {
      try {
        return this.items?.closed?.filter((item) => item.status !== "done")
          .length;
      } catch (e) {
        console.error(e);
        return 0;
      }
    },
    computedHeaders() {
      return this.headers;
    },
  },
  methods: {
    handleCopy(data) {
      copy(data);
      this.mSuccess({
        title: "Copied to clipboard",
        text: `<mark>${data}</mark> coppied.`,
      });
    },
  },
};
</script>
