<template>
  <v-chip v-bind="$attrs" v-on="$listeners">
    <slot />
    <v-progress-circular
      v-if="loading"
      indeterminate
      size="12"
      width="2"
      class="ml-1"
    />
  </v-chip>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
