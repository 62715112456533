<template>
  <v-container style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12" class="d-flex">
        <v-row>
          <v-col>
            <h2>Niche Analyzer</h2>
            <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          </v-col>
          <v-col class="d-flex justify-end align-end">
            <v-chip
              label
              outlined
              class="ml-2"
              :to="{ name: 'niche.analyzer.add-new-niche-analyzer-project' }"
            >
              <v-icon small left>mdi-plus</v-icon>
              Add new project
            </v-chip>
            <v-chip
              label
              class="flat-chip--primary ml-2"
              @click="short_term.dialog = true"
            >
              <v-icon small left>mdi-plus</v-icon> Create short-term project
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="styled-card--default" :loading="loading.data">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search in table"
              style="max-width: 280px"
              dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              :search="search"
              :headers="headers"
              :items="items"
              :items-per-page="15"
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  v-if="item.status === 'in_progress'"
                  class="ma-2"
                  color="warning"
                  small
                >
                  In Progress
                </v-chip>
              </template>
              <template v-slot:item.active="{ item }">
                <v-switch
                  v-model="project_active[item.id]"
                  dense
                  hide-details
                  class="ma-0"
                  color="info"
                  @change="updateActive(item.id)"
                ></v-switch>
              </template>
              <template v-slot:item.actions="{ item }">
                <div style="white-space: nowrap">
                  <v-menu
                    bottom
                    :close-on-content-click="false"
                    content-class="remove-dialog-shadow"
                  >
                    <template #activator="{ on }">
                      <v-btn v-on="on" depressed icon>
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-card width="240" class="styled-card--default">
                      <v-card-title> Select page </v-card-title>
                      <v-divider />
                      <v-card-text class="pa-0">
                        <v-list dense>
                          <v-list-item
                            @click="
                              handleNavigateTo(
                                'niche.analyzer.project-dashboard',
                                item.id
                              )
                            "
                          >
                            Project Dashboard
                          </v-list-item>
                          <v-list-item
                            @click="
                              handleNavigateTo(
                                'niche.analyzer.detailed-static',
                                item.id
                              )
                            "
                          >
                            Detailed Static
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <v-btn icon @click="handleOpenShareDialog(item)">
                    <v-icon>mdi-email-plus-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-dialog v-model="short_term.dialog" persistent max-width="600px">
          <v-card class="styled-card--default">
            <v-card-title> Create Project </v-card-title>
            <v-divider />
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        filled
                        hide-details="auto"
                        v-model="short_term.name"
                        :rules="[
                          () => !!short_term.name || 'This field is required',
                        ]"
                        label="Name"
                        required
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        filled
                        clearable
                        label="Description"
                        v-model="short_term.description"
                        auto-grow
                        hide-details="auto"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        :loading="loading.countries"
                        v-model="short_term.country"
                        :items="countries"
                        item-value="id"
                        item-text="name"
                        hide-details="auto"
                        label="Country"
                        clearable
                        required
                        filled
                        :rules="[
                          () =>
                            !!short_term.country || 'This field is required',
                        ]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        :loading="loading.languages"
                        v-model="short_term.language"
                        clearable
                        :items="languages"
                        item-value="id"
                        item-text="name"
                        label="Language"
                        hide-details="auto"
                        required
                        filled
                        :rules="[
                          () =>
                            !!short_term.language || 'This field is required',
                        ]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        :loading="loading.main_data"
                        v-model="short_term.niche"
                        :items="niches"
                        item-value="id"
                        clearable
                        item-text="name"
                        label="Niche"
                        hide-details="auto"
                        required
                        filled
                        :rules="[
                          () => !!short_term.niche || 'This field is required',
                        ]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-chip class="mr-2" label @click="short_term.dialog = false">
                Close
              </v-chip>
              <v-chip
                color="primary"
                label
                style="width: 130px"
                class="d-flex justify-center"
                @click="short_term_create"
              >
                Create
              </v-chip>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-dialog
      v-model="shareDialogModel"
      content-class="remove-dialog-shadow"
      max-width="350px"
    >
      <v-card class="styled-card--default">
        <v-card-title>Share with</v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-text-field
            v-model="shareItem.email"
            filled
            dense
            label="Email"
            placeholder="Enter email"
            :rules="emailRules"
            hide-details="auto"
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="handleShare" block color="primary">Share</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import service from "@/plugins/service";

export default {
  name: "NicheAnalyzerIndex",
  data() {
    return {
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      shareDialogModel: false,
      shareItem: {
        id: null,
        email: null,
      },
      search: "",
      headers: [
        {
          text: "Active",
          value: "active",
          align: "center",
          sortable: false,
          width: "50",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "50",
        },
        { text: "ID", value: "id", width: 50 },
        { text: "Name", value: "name" },
        {
          text: "Parsed At",
          value: "parsed_at",
          align: "center",
          sortable: false,
          width: "180",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          width: "180",
        },
      ],
      project_active: {},
      items: [],
      valid: true,
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Niche Analyzer",
          disabled: true,
        },
      ],
      loading: {
        countries: false,
        languages: false,
        active: false,
        data: false,
      },
      short_term: {
        dialog: false,
        name: undefined,
        country: undefined,
        language: undefined,
        niche: undefined,
        description: undefined,
      },
    };
  },
  computed: {
    countries() {
      return this.$store.state.reuse.countries;
    },
    languages() {
      return this.$store.state.reuse.languages;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    handleNavigateTo(routeName, projectId) {
      this.$store.dispatch("update_active_na_project", projectId);
      this.$router.push({
        name: routeName,
        query: { na_project_id: projectId },
      });
    },
    async handleShare() {
      const url = `/niche-analyzer/share`;

      await service.post(url, this.shareItem);

      this.shareItem = { id: null, email: null };

      this.shareDialogModel = false;
    },
    handleOpenShareDialog(item) {
      this.shareItem.id = item.id;
      this.shareDialogModel = true;
    },
    updateActive(id) {
      let self = this;

      self.loading.active = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        active: self.project_active[id],
      };

      axios
        .post(
          `${self.$store.state.server_url}/niche-analyzer/project/${id}/update-active`,
          data,
          config
        )
        .finally(() => (self.loading.active = false));
    },
    short_term_create() {
      if (this.$refs.form.validate()) {
        let self = this;

        self.dialog_save = true;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: self.$store.state.auth.token,
          },
        };

        let data = self.short_term;

        axios
          .post(
            `${self.$store.state.server_url}/niche-analyzer/project`,
            data,
            config
          )
          .finally(() => (self.short_term.dialog = false));
      }
    },
    fetch_data() {
      let self = this;

      self.loading.data = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(`${self.$store.state.server_url}/niche-analyzer/project`, config)
        .then((r) => {
          r.data.items.forEach((v) => {
            self.project_active[v.id] = v.active;
          });
          self.items = r.data.items;
        })
        .finally(() => (self.loading.data = false));
    },
  },
  created() {
    if (this.countries.length === 0) {
      this.$store.dispatch("fetch_countries_list");
    }

    if (this.languages.length === 0) {
      this.$store.dispatch("fetch_languages_list");
    }

    if (this.niches.length === 0) {
      this.$store.dispatch("fetch_niches_list");
    }

    this.fetch_data();
  },
};
</script>

<style scoped></style>
