<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="anchor-distribution__links-table"
  >
    <template v-for="h in headers" #[`header.${h.value}`]="{ header }">
      <v-tooltip :key="h.value" bottom>
        <template #activator="{ on }">
          <div
            v-on="on"
            :style="{
              background:
                getSelectedUrl === header.text ? 'rgba(28,90,250,0.35)' : '',
            }"
          >
            {{ fixHeader(header.text) }}
          </div>
        </template>
        <div>{{ header.text }}</div>
      </v-tooltip>
    </template>
    <template #body="{ items }">
      <tbody>
        <tr v-for="(row, index) in items" :key="index">
          <td v-for="(key, i) in Object.keys(row)" :key="key + i">
            <div v-if="i !== 0">
              <div v-for="(i, idx) in row[key]" :key="idx">
                {{ i.text }} <sup>{{ i.value }}</sup>
              </div>
            </div>
            <div v-else>
              {{ row[key] }}
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["headers", "items", "selectedUrlId", "filters"],
  computed: {
    getSelectedUrl() {
      return (
        this.filters.url.find((i) => i.value === this.selectedUrlId).text || ""
      );
    },
  },
  methods: {
    fixHeader(string) {
      return string
        .replaceAll("https://", "")
        .replaceAll("http://")
        .split("/")[0];
    },
    getValue(data) {
      if (!data) {
        return data;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("value")) {
        return data.value;
      }
      return data;
    },
  },
};
</script>
