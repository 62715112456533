<template>
  <v-dialog
    v-model="dialog"
    persistent
    content-class="my-shadow--e2"
    width="auto"
    max-width="550"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title style="font-size: 14px">
        <b>Create task</b>
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="d-flex justify-center pa-0">
        <task-card
          :form="form"
          borderless
          :filters-data="filtersData()"
          :loadings="loadings"
          disable-create-button
        />
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex">
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          :disabled="loadings.createSubtask"
          large
          class="px-6 text-normal"
          color="success"
          @click="handleCreateSubtask"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import TaskCard from "../../SimilarTaskCreating/UI/TaskCard.vue";
import { WorksectionApiService } from "../../../services/worksection/WorksectionApiService";
import eventPipe from "../../../events/eventPipe";
import { DETAILED_PAGE_EVENTS } from "../defaults";

export default {
  components: { TaskCard },

  mixins: [Dialog],

  created() {
    eventPipe.$on(DETAILED_PAGE_EVENTS.CREATE_TASK, (sectionData) => {
      this.processEventTaskData(sectionData);

      this.dialog = true;
    });
  },

  inject: ["filtersData"],

  data: () => ({
    loadings: {
      createSubtask: false,
    },
    form: {
      project_id: 171238, // required
      parent_task_id: null, // required
      reporter: null, // required
      task_title: null, // required
      task_desc: null,
      priority: 8,
      date: [],
      tags: [641699],
      estimated_time: null,
      estimated_money: null,
    },
  }),

  methods: {
    processEventTaskData(sectionData) {
      this.form.task_title = sectionData.section_name;
      this.form.reporter = sectionData.executive;

      const start = this.$moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).endOf("day").format("YYYY-MM-DD");

      this.form.date = [start, end];
    },
    resetForm() {
      this.form = {
        project_id: 171238,
        parent_task_id: null,
        reporter: null,
        task_title: null,
        task_desc: null,
        priority: 8,
        date: [],
        tags: [641699],
        estimated_time: null,
        estimated_money: null,
      };
    },
    successMessage(taskData) {
      this.$message.notification({
        title: "Successfully created",
        text: `<a href="${
          "https://ra.worksection.com/" + taskData.page
        }" target="_blank">${taskData.name}</a>`,
        type: "success",
      });
    },
    errorMessage(responseData) {
      this.$message.notification({
        title: "Something wrong.",
        text: responseData.message,
        type: "error",
      });
    },
    async handleCreateFastSubtask() {},
    async handleCreateSubtask() {
      try {
        this.loadings.createSubtask = false;

        const { data } = await WorksectionApiService().createTask({
          type: "CREATE_TASKS",
          main_task: this.form,
        });

        if (data.success) {
          this.dialog = false;
          this.resetForm();
          this.successMessage(data.task);
        } else {
          this.errorMessage(data);
        }
      } catch (error) {
        console.error("Error while creating worksection subtask.", error);
      } finally {
        this.loadings.createSubtask = false;
      }
    },
  },
};
</script>
