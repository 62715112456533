export const REDIRECTS_QUERY_PAGE_DATA = {
  TITLE: "Redirects query",
  BREADCRUMBS: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Redirects",
      disabled: true,
    },
    {
      text: "Query",
      disabled: true,
    },
  ],
  DATA_TABLE_HEADERS: [
    {
      text: "Domain",
      value: "domain",
      sortable: false,
    },
    {
      text: "Email",
      value: "email",
      sortable: false,
    },
    {
      text: "Created",
      value: "created_at",
      sortable: false,
    },
    {
      text: "Status",
      value: "status_name",
      sortable: false,
    },
    {
      text: "Updated",
      value: "updated_at",
      sortable: false,
    },
  ],
};
