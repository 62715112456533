<template>
  <div class="anchors-plan__task-edit">
    <v-container fluid v-if="isset_active_project">
      <v-card v-if="isset_active_project" class="mx-auto" elevation="0">
        <v-row dense>
          <v-col cols="12">
            <v-row dense class="d-flex align-center">
              <v-col cols="2">
                <div class="task-edit__title">ANCHORS PLAN</div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="task.name"
                  label="Task Name"
                  hide-details
                  outlined
                  dense
                  class="align-end"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="task.link_task"
                  dense
                  hide-details
                  outlined
                  label="Task Link"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-btn
              color="success"
              dark
              right
              small
              absolute
              style="margin-right: 60px; margin-top: -43px"
              fab
              @click="upload.modal = true"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn></v-col
          >
        </v-row>
        <v-row dense class="mt-6">
          <v-col cols="12">
            <v-card
              class="mx-auto pa-3"
              elevation="1"
              rounded="lg"
              v-if="isset_active_project"
            >
              <v-row dense class="align-end">
                <v-col sm="12">
                  <v-text-field
                    v-model="search"
                    dense
                    append-icon="mdi-magnify"
                    label="Search"
                    hide-details
                    class="align-end"
                    style="max-width: 350px"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense align="center" justify="center">
                <v-col cols="12" class="mt-4">
                  <v-data-table
                    :search="search"
                    :headers="table.headers"
                    :items="table.items"
                    dense
                    class="show-icons-on-hover"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td>
                            <v-btn
                              color="red"
                              icon
                              width="30"
                              height="30"
                              @click="left_new_row(item.id)"
                              class="mr-2"
                            >
                              <v-icon size="20">mdi-trash-can</v-icon>
                            </v-btn>

                            <span>{{ item.id }}</span>
                          </td>
                          <td>
                            <v-menu bottom>
                              <template #activator="{ on }">
                                <v-icon v-on="on">mdi-dots-vertical</v-icon>
                              </template>
                              <v-list dense nav>
                                <v-list-item
                                  @click="handleMoveAcceptorAnchor(item)"
                                >
                                  <v-list-item-icon
                                    ><v-icon
                                      >mdi-truck-fast-outline</v-icon
                                    ></v-list-item-icon
                                  >
                                  <v-list-item-title
                                    >Move acceptor & anchor</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                          <td>
                            <span v-if="item.edit_url"
                              ><v-text-field
                                class="mx-auto"
                                v-model="item.url"
                                @change="edit.update.push(item.id)"
                                solo
                                full-width
                                hide-details
                                dense
                              ></v-text-field>
                            </span>
                            <span v-else
                              >{{ item.url }}
                              <v-icon @click="item.edit_url = true" size="20"
                                >mdi-pencil</v-icon
                              >
                            </span>
                          </td>
                          <td>
                            <span v-if="item.edit_phrase">
                              <v-text-field
                                class="mx-auto"
                                v-model="item.phrase"
                                @change="edit.update.push(item.id)"
                                solo
                                full-width
                                hide-details
                                dense
                              ></v-text-field>
                            </span>
                            <span v-else
                              >{{ item.phrase }}
                              <v-icon @click="item.edit_phrase = true" size="20"
                                >mdi-pencil</v-icon
                              ></span
                            >
                          </td>
                          <td class="text-center">
                            <span>{{ item.dateadd }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                  <v-btn
                    color="success"
                    absolute
                    bottom
                    right
                    fab
                    @click="save_data"
                    style="margin-right: 60px; margin-bottom: 100px"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon size="20" v-bind="attrs" v-on="on"
                          >mdi-content-save
                        </v-icon>
                      </template>
                      <span>Save changes</span>
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <upload-anchor-plan
      v-if="upload.modal === true"
      v-model="upload.modal"
      level="1"
      :task_id="this.$route.params.id"
    ></upload-anchor-plan>
    <v-dialog
      v-model="movingDialog"
      max-width="450"
      content-class="remove-dialog-shadow"
    >
      <v-card rounded="lg">
        <v-card-title>Moving acceptor & anchor</v-card-title>
        <v-card-text class="d-flex align-center">
          <b class="mr-2">TO</b>
          <v-autocomplete
            v-model="moveToModel"
            :items="tasksList"
            hide-details
            clearable
            dense
            item-text="name"
            return-object
            outlined
            label="Tasks"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn text @click="handleMove" color="success">Move</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure? </template>
      <template #default>
        <v-row dense>
          <v-col cols="12">
            <p>You want transfer acceptor & anchor from...</p>
          </v-col>
          <v-col cols="12" style="font-size: 16px">
            {{ task.name }}
          </v-col>
          <v-col cols="12">
            <v-icon>mdi-arrow-down-bold-outline</v-icon>
          </v-col>
          <v-col cols="12" style="font-size: 16px">
            <b>{{ moveToModel.name }}</b>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="disagree" text color="grey">Cancel</v-btn>
            <v-btn @click="agree" color="success" class="ml-4 b-rb-shaped-4"
              >Yes</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
  </div>
</template>

<script>
import axios from "axios";
import UploadAnchorPlan from "@/components/AnchorPlan/Child/UploadAnchorPlan";
import AgreeDialog from "@/components/UI/AgreeDialog";
import agreeDialogMixin from "@/mixins/AgreeDialogMixin";

export default {
  name: "AnchorPlanIndex",
  metaInfo: {
    title: "Anchor Plan Edit - LTT - SI",
  },
  components: { AgreeDialog, UploadAnchorPlan },
  mixins: [agreeDialogMixin],
  data() {
    return {
      loadings: {},
      search: undefined,
      date_modal: false,
      pathToTask: "",
      upload: {
        modal: false,
      },
      agreeDialogData: {
        text: "",
      },
      task: {},
      table: {
        headers: [
          {
            text: "id",
            title: "id",
            value: "id",
            class: "text-center",
            width: "150",
          },
          {
            text: "",
            title: "Actions",
            value: "_actions",
            class: "text-center",
            sortable: false,
          },
          {
            text: "Acceptor URL",
            title: "url",
            value: "url",
            class: "text-center",
          },
          {
            text: "Anchor",
            title: "phrase",
            value: "phrase",
            class: "text-left",
          },
          {
            text: "Date",
            title: "comment",
            value: "comment",
            class: "text-left",
          },
        ],
        items: [],
      },
      tasksList: [],
      moveToModel: {},
      movingDialog: false,
      agreeDialogModel: false,
      movableItem: {},
      edit: {
        delete: [],
        update: [],
      },
    };
  },
  methods: {
    async handleMove() {
      if (!(await this.open())) return;

      const url = `${this.$store.state.server_url}/anchor-plan/task/move`;

      if (!this.moveToModel.id) {
        return this.$message.notification({
          title: "Validation",
          text: "Select task.",
          type: "warning",
          duration: 3500,
        });
      }

      const data = {
        task_id: this.moveToModel.id,
        ap_id: this.movableItem.id,
      };

      const configs = this.getHeaders;

      axios
        .post(url, data, configs)
        .then(() => {
          this.movingDialog = false;
          this.$message.notification({
            title: "Success 👍",
            text: `"${this.task.name}" acceptor & anchor moving to "${this.moveToModel.name}"`,
            type: "success",
            duration: 6500,
          });
        })
        .catch(() => {
          this.moveToModel = {};
          this.$message.notification({
            title: "Something wrong",
            text: "Operation not complete, try again.",
            type: "warning",
            duration: 3500,
          });
        })
        .finally(() => {
          this.moveToModel = {};
        });
    },
    handleMoveAcceptorAnchor(item) {
      this.movingDialog = true;
      this.movableItem = item;
    },
    left_new_row(id) {
      this.table.items = this.table.items.filter((x) => x.id !== id);
      this.edit.delete.push(id);
    },
    save_data() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("save_data", true);
        self.loading = true;

        let url = `${self.$store.state.server_url}/anchor-plan/task/edit/${this.$route.params.id}/${this.$route.params.level}/update`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let change_items = self.edit.update.filter(
          (x, i, a) => a.indexOf(x) === i
        );
        let items_update = self.table.items.filter(function (params) {
          if (change_items.includes(params.id)) {
            return params;
          }
        });

        this.table.change_items = [];

        let data = {
          update: items_update,
          delete: self.edit.delete,
          task: { ...self.task },
        };
        axios
          .post(url, data, config)
          .then(function () {
            self.update_loading("save_data", false);
            self.fetch_data();
            self.loading = false;
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_task_info() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_task_info", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/task/${this.$route.params.id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {};

        axios
          .post(url, data, config)
          .then((response) => {
            self.task = response.data;
            self.update_loading("fetch_task_info", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_data() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/anchor-plan/task/edit/${this.$route.params.id}/${this.$route.params.level}/get`;
        let tasks_list_url = `${self.$store.state.server_url}/anchor-plan/task/edit/${this.$route.params.id}/${this.$route.params.level}/get-list-tasks`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {};

        self.table.items = [];

        axios.post(tasks_list_url, {}, config).then((r) => {
          this.tasksList = r.data;
        });

        axios
          .post(url, data, config)
          .then((response) => {
            self.table.items = response.data;
            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    getHeaders() {
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {
    let self = this;
    self.fetch_task_info();
    self.fetch_data();
  },
};
</script>

<style scoped></style>
