<template>
  <div style="padding-bottom: 200px">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <h2>Alex Dashboard</h2>

          <v-breadcrumbs
            class="pa-0"
            :items="items_breadcrumbs"
          ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex pb-0 align-end">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="Type to search"
            filled
            dense
            style="max-width: 280px"
            hide-details
          ></v-text-field>
          <v-spacer />
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="false"
            v-model="modal.shown"
            :value="modal.shown"
            offset-y
            content-class="my-shadow--e3"
          >
            <template v-slot:activator="{ on }">
              <filters-chip
                v-on="on"
                :filters="filter"
                clearable
                @click:clear="handleClearFilters"
              />
            </template>
            <v-card class="styled-card--default" width="380px">
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <smart-date-filter
                      :model-value="filter.date_range"
                      @update:modelValue="handleDateChange"
                    />
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      dense
                      outlined
                      v-model="filter.projects"
                      :items="projects"
                      item-value="id"
                      item-text="name"
                      label="Projects"
                      chips
                      small-chips
                      deletable-chips
                      clearable
                      multiple
                    ></v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      dense
                      outlined
                      v-model="filter.statuses"
                      :items="statuses.statuses"
                      label="Statuses"
                      chips
                      small-chips
                      deletable-chips
                      clearable
                      multiple
                      @change="updateFilter"
                    ></v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      dense
                      outlined
                      v-model="filter.responsible"
                      :items="responsibilities"
                      label="Responsible"
                      hide-details
                      clearable
                      @change="updateFilter"
                    ></v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-select
                      dense
                      outlined
                      v-model="filter.niche"
                      :items="niches"
                      item-value="id"
                      item-text="name"
                      label="Niche"
                      hide-details
                      clearable
                    ></v-select>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      dense
                      outlined
                      v-model="filter.performer"
                      :items="performers"
                      item-value="id"
                      item-text="name"
                      label="Performer"
                      hide-details
                      clearable
                    ></v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox
                      v-model="filter.critical_estimate"
                      label="Critical Estimate"
                      @change="updateFilter"
                    ></v-checkbox>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-btn
                  block
                  @click="fetchData"
                  color="primary"
                  width="140px"
                  :loading="loadings.tables"
                >
                  Apply
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
        <template v-if="loadings.tables">
          <v-col cols="12">
            <v-card class="styled-card--default">
              <v-card-title>
                <div class="skeleton" style="width: 80px; height: 30px" />
                <div class="skeleton ml-2" style="width: 30px; height: 30px" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-skeleton-loader type="table" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="styled-card--default">
              <v-card-title>
                <div class="skeleton" style="width: 80px; height: 30px" />
                <div class="skeleton ml-2" style="width: 30px; height: 30px" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-skeleton-loader type="table" />
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <v-col v-for="cat_project in cat_projects" :key="cat_project" cols="12">
          <v-card
            class="styled-card--default"
            rounded="lg"
            v-if="items_filtered[cat_project].length !== 0"
          >
            <v-card-title>
              {{ cat_project }}

              <v-progress-circular
                class="ml-4"
                :value="
                  (items[cat_project].filter((v) => v.status_id === 100)
                    .length *
                    100) /
                  items[cat_project].length
                "
                size="34"
                >{{
                  items[cat_project].filter((v) => v.status_id === 100).length
                }}/{{ items[cat_project].length }}
              </v-progress-circular>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-0 pt-0">
              <v-data-table
                :headers="headers"
                :items="items_filtered[cat_project]"
                :search="search"
                :custom-sort="customSort"
                item-key="name"
                :footer-props="{
                  'items-per-page-options': [10, 15, 50, -1],
                }"
                :items-per-page="50"
                hide-default-header
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      :style="
                        item.diff_in_hour >= item.critical_estimate
                          ? 'background-color:#ff00001c;'
                          : ''
                      "
                      v-for="(item, i) in items"
                      :key="item.domain + i"
                    >
                      <td>
                        <div style="white-space: nowrap">
                          <a :href="`http://${item.domain}`" target="_blank">{{
                            item.domain
                          }}</a>
                          <v-btn
                            icon
                            :to="`/gpm/view-entity/${item.id}`"
                            link
                            target="_blank"
                          >
                            <v-icon>mdi-login-variant</v-icon>
                          </v-btn>
                          <v-badge
                            v-if="item.messages.unread > 0"
                            :content="item.messages.unread"
                            :value="item.messages.unread > 0"
                            color="error"
                            overlap
                          >
                            <v-icon>mdi-email-outline</v-icon>
                          </v-badge>
                          <v-chip
                            v-if="item.ltt_used"
                            color="warning"
                            title="Ltt Used"
                            class="pa-1 ml-2"
                            x-small
                            >USED</v-chip
                          >
                          <v-chip
                            v-if="item.has_in_work"
                            @click="showOtherTasks(item.tasks_in_work)"
                            color="info"
                            title="In Work"
                            class="pa-1 ml-2"
                            x-small
                            >IN WORK</v-chip
                          >
                          <v-chip
                            v-if="item.has_in_other"
                            @click="showOtherTasks(item.tasks_in_other)"
                            color="info"
                            title="Has in other task"
                            class="pa-1 ml-2"
                            x-small
                            >OTHER</v-chip
                          >
                          <v-chip
                            v-if="item.has_in_hub"
                            color="info"
                            title="Has in Research HUB"
                            class="pa-1 ml-2"
                            x-small
                            >HUB</v-chip
                          >
                        </div>
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{ item.cost_task }}</span>
                          </template>
                          <span>Price</span> </v-tooltip
                        >`
                      </td>
                      <td>{{ item.time_in_work_stage }}</td>
                      <td>
                        <v-chip
                          small
                          :color="
                            responsibilities_colors[item.responsible.role].bg
                          "
                          :style="
                            'color: ' +
                            responsibilities_colors[item.responsible.role].color
                          "
                        >
                          {{ item.responsible.role }}
                        </v-chip>
                      </td>
                      <td>{{ item.project }}</td>
                      <td>{{ item.add_datetime }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title> Upload domains to {{ projectName }} </v-card-title>
        <v-divider />
        <v-card-text>
          <v-autocomplete
            solo
            label="Anchor Plan Task"
            v-model="form.anchor_plan_task"
            :items="anchor_plan_tasks"
            required
          ></v-autocomplete>
          <v-textarea
            v-model="form.domains"
            label="Domains"
            solo
            no-resize
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="primary"
            text
            @click="upload()"
            :disabled="dialog_loading"
            :loading="dialog_loading"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="other_tasks_dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card class="styled-card--default">
        <v-card-title> Tasks </v-card-title>
        <v-divider />
        <v-card-text class="px-0 pt-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Task Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in other_tasks" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    <a :href="'/gpm/view-entity/' + item.id" target="_blank">{{
                      item.name
                    }}</a>
                  </td>
                  <td>{{ item.status_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="other_tasks_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  RESPONSIBILITIES_COLORS,
  RESPONSIBILITIES_ITEMS,
} from "@/utils/selectItems";
import axios from "axios";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import { deepClone } from "@/utils/functions";
import FiltersChip from "@/components/UI/FiltersChip.vue";

const dateFormat = require("dateformat");
const date = new Date();

export default {
  name: "Alex",
  components: { FiltersChip, SmartDateFilter },
  mixins: [FiltersHandling],
  data() {
    return {
      date_modal: false,
      search: "",
      other_tasks: [],
      other_tasks_dialog: false,
      modal: {
        shown: false,
      },
      filter: {
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        projects: [],
        statuses: [],
        responsible: undefined,
        critical_estimate: false,
        performer: undefined,
        niche: undefined,
      },
      dialog: false,
      dialog_loading: false,
      loading: false,
      loadings: {
        tables: false,
      },
      form: {
        domains: "",
        anchor_plan_task: null,
      },
      anchor_plan_tasks: [],
      items_breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Gpm",
          disabled: true,
        },
        {
          text: "Alex Dashboard",
          disabled: true,
        },
      ],
      headers: [
        { text: "Domain", value: "domain" },
        { text: "CostTask", value: "cost_task", align: "center" },
        { text: "StageTime", value: "time_in_work_stage", align: "center" },
        { text: "Responsible", value: "responsible", align: "center" },
        { text: "Project", align: "start", value: "project" },
        { text: "CreatedAt", value: "add_datetime", align: "center" },
      ],
      items: [],
      items_filtered: [],
      cat_projects: [],
      responsibilities: RESPONSIBILITIES_ITEMS,
      responsibilities_colors: RESPONSIBILITIES_COLORS,
      performers: [],
      niches: [],
    };
  },
  watch: {
    filter: {
      handler(newValue) {
        this._$handleFiltersChange(newValue);
      },
      deep: true,
    },
  },
  computed: {
    projectName() {
      return this.$store.state.project.domain;
    },
    idProject() {
      return this.$store.state.project.active_project;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    statuses() {
      let self = this;

      if (self.items.length === 0) {
        return [];
      }

      let statuses = [];
      let used_statuses = [];

      self.cat_projects.forEach((v) => {
        self.items[v].forEach((i) => {
          if (used_statuses.indexOf(i.status) === -1) {
            used_statuses.push(i.status);
            statuses.push({
              text: i.status,
              value: i.status,
            });
          }
        });
      });

      return {
        statuses: statuses,
        used: used_statuses,
      };
    },
  },
  methods: {
    handleClearFilters() {
      Object.keys(this.filter).forEach((filterKey) => {
        if (
          Array.isArray(this.filter[filterKey]) &&
          filterKey === "date_range"
        ) {
          return (this.filter[filterKey] = [null, null]);
        }
        if (Array.isArray(this.filter[filterKey])) {
          this.filter[filterKey] = [];
        }
        this.filter[filterKey] = null;
      });
    },
    handleDateChange(event) {
      this.filter.date_range = event;
      this._$handleFilterChange(event, "date_range");
    },
    showOtherTasks(tasks) {
      this.other_tasks = tasks;
      this.other_tasks_dialog = true;
    },
    updateFilter() {
      let self = this;

      if (self.items.length === 0) {
        return [];
      }

      let result = {};

      let selected_statuses = deepClone(self.filter.statuses);

      self.cat_projects.forEach((v) => {
        result[v] = self.items[v].filter((i) => {
          let status_filter = true;
          let status_responsible = true;
          let status_critical_estimate = true;

          if (selected_statuses.length > 0) {
            status_filter = selected_statuses.indexOf(i.status) !== -1;
          }

          if (self.filter.responsible) {
            if (self.filter.responsible === "ME")
              status_responsible = i.responsible.status;
            else if (self.filter.responsible === "ALL")
              status_responsible =
                i.responsible.status || i.responsible.role === "ALL";
            else
              status_responsible =
                self.filter.responsible === i.responsible.role;
          }

          if (self.filter.critical_estimate) {
            status_critical_estimate = i.diff_in_hour >= i.critical_estimate;
          }

          return (
            status_filter && status_responsible && status_critical_estimate
          );
        });
      });

      self.items_filtered = result;
    },
    customSort(items) {
      return items.sort((a, b) => {
        let project_priority_a,
          project_priority_b,
          min_on_stage_a,
          min_on_stage_b;

        project_priority_a = parseInt(a.project_priority);
        project_priority_b = parseInt(b.project_priority);

        min_on_stage_a = parseInt(a.minutes_in_work_stage);
        min_on_stage_b = parseInt(b.minutes_in_work_stage);

        if (project_priority_a > project_priority_b) return -1;
        if (project_priority_a < project_priority_b) return 1;

        if (min_on_stage_a > min_on_stage_b) return -1;
        if (min_on_stage_a < min_on_stage_b) return 1;

        return 0;
      });
    },
    openUrl(id) {
      this.$router.push({ name: "gpm.view-entity", params: { id: id } });
    },
    fetchData() {
      let url = `${this.$store.state.server_url}/gpm/task-view/get-dashboard-data`;

      let config = {
        params: {
          typeID: 1,
          filter: this.filter,
          dashboardType: "alex",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.loadings.tables = true;

      axios
        .get(url, config)
        .then((r) => {
          this.cat_projects = r.data.projects;
          this.items = r.data.items;
          this.items_filtered = r.data.items;
          this.filter.critical_estimate = false;
          this.performers = r.data.performers;
          this.niches = r.data.niches;
        })
        .finally(() => {
          this.loadings.tables = false;
        });
    },
  },
  created() {
    this._$collectParamsTo(this, "filter", [
      "projects",
      "date_range",
      "statuses",
    ]);

    this.fetchData();
  },
};
</script>

<style scoped></style>
