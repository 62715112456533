<template>
  <v-tooltip bottom max-width="250">
    <template #activator="{ on }">
      <v-icon v-on="on" style="opacity: 0.6" small
        >mdi-information-outline</v-icon
      >
    </template>
    <div>
      <slot />
    </div>
  </v-tooltip>
</template>

<script>
export default {};
</script>
