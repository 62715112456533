<template>
  <v-text-field
    v-model="value"
    hide-details
    dense
    outlined
    :label="label"
    append-icon="mdi-plus"
    prepend-inner-icon="mdi-minus"
    @click:append="inc"
    @click:prepend-inner="dec"
    @change="change"
  >
  </v-text-field>
</template>

<script>
export default {
  props: ["modelValue", "min", "max", "label"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    change(value) {
      if (value >= this.max) {
        this.value = this.max;
      }

      if (value <= this.min) {
        this.value = this.min;
      }
    },
    dec() {
      const result = parseInt(this.value) - 1;

      if (result <= this.min) {
        return (this.value = this.min);
      }

      this.value = result;
    },
    inc() {
      const result = parseInt(this.value) + 1;

      if (result >= this.max) {
        return (this.value = this.max);
      }

      this.value = result;
    },
  },
};
</script>
