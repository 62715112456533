import Project from "@/mixins/Project";

export default {
  mixins: [Project],
  methods: {
    async _$fetchBrands() {
      this.loadings.brands = true;

      const activeProject = this._$getActiveProject;

      if (!activeProject) {
        return setTimeout(() => {
          this._$fetchBrands();
        }, 1000);
      }

      const resp = await this.$store.dispatch(
        "conversion-8/fetchBrands",
        activeProject.domain_id
      );

      if (resp) {
        this.brandItems = resp;
      }

      this.loadings.brands = false;
    },
    async _$fetchUrls() {
      this.loadings.url = true;

      const activeProject = this._$getActiveProject;

      if (!activeProject) {
        return setTimeout(() => {
          this._$fetchUrls();
        }, 1000);
      }

      const resp = await this.$store.dispatch(
        "conversion-8/fetchUrls",
        activeProject.domain_id
      );

      if (resp) {
        this.urlItems = resp;
      }

      this.loadings.url = false;
    },
  },
};
