export const RESPONSIBILITIES_ITEMS = [
    {text: 'GP', value: 'GP'},
    {text: 'SEO', value: 'SEO'},
    {text: 'CW', value: 'CW'},
    {text: 'CM', value: 'CM'},
    {text: 'WM', value: 'WM'},
    {text: 'OM', value: 'OM'},
    {text: 'DONE', value: 'ALL'}
]

export const RESPONSIBILITIES_COLORS = {
    'ALL': {
        'bg': '#98fb98',
        'color': '#000'
    },
    'GP': {
        'bg': '#0000ff',
        'color': '#fff'
    },
    'SEO/GP': {
        'bg': '#0000ff',
        'color': '#fff'
    },
    'SEO': {
        'bg': '#008000',
        'color': '#fff'
    },
    'CW': {
        'bg': '#ffebcd',
        'color': '#000'
    },
    'CM': {
        'bg': '#98dbfb',
        'color': '#000'
    },
    'WM': {
        'bg': '#f5f5dc',
        'color': '#000'
    },
    'OM': {
        'bg': '#98dbfb',
        'color': '#000'
    },
}