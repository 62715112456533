<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e4" width="360">
    <v-card rounded="lg" flat elevation="0">
      <v-card-title class="pa-4 text-body-2">
        Are you sure?
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        You want to delete tag <b>«{{ data.name }}»</b>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn text large class="text-normal px-6" @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn color="error" large class="text-normal px-6" @click="deleteTag">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import { GpmTagsApiService } from "../services/GpmTagsApiService";

export default {
  props: {
    data: {},
  },

  mixins: [Dialog],

  data: () => ({
    gpmTagsApiService: null,
    loading: false,
  }),

  mounted() {
    this.gpmTagsApiService = GpmTagsApiService();
  },

  methods: {
    async deleteTag() {
      try {
        await this.gpmTagsApiService.deleteTag(this.data.id);

        this.dialog = false;

        this.$emit("success", this.data);
      } catch (e) {
        console.error("Error while updating tag.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
