<template>
  <v-card class="shadow-e1-bordered" rounded="xl" width="900">
    <v-card-title>
      <v-row dense>
        <v-col cols="9">
          <v-text-field
            v-model="form.task_title"
            placeholder="What needs to be done?"
            hide-details="auto"
            solo-inverted
            :rules="taskTitleRules"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <priority-select
            :model-value="form.priority"
            @update:modelValue="form.priority = $event"
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            v-if="type === 'default'"
            @click="handleCreateTask"
            color="primary"
            block
            :loading="loadings.createTask"
            :disabled="validate"
            style="height: 38px"
          >
            Create Task
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-row dense>
        <v-col> </v-col>
        <v-col>
          <status-select
            :model-value="form.tags"
            @update:modelValue="form.tags = $event"
            :loading="loadings.filters"
            :items="filtersData.tags"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <date-select
            :model-value="form.date"
            @update:modelValue="form.date = $event"
          />
        </v-col>
        <v-col>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.estimated_time"
                outlined
                type="numbers"
                hide-details
                dense
                label="Hours"
                prepend-inner-icon="mdi-clock-time-eight-outline"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.estimated_money"
                outlined
                hide-details
                dense
                label="Money"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col></v-col>
        <v-col>
          <v-autocomplete
            v-model="form.seo"
            :items="['responsible', 'main']"
            hide-details
            dense
            outlined
            placeholder="Select"
            label="Select seo"
            :menu-props="menuProps"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="similar-task-creating__editor pa-0 pb-4">
      <tiptap-vuetify
        v-model="form.task_desc"
        placeholder="Task description"
        :extensions="extensions"
      />
    </v-card-text>
    <v-dialog
      v-model="usersModal"
      content-class="remove-dialog-shadow"
      width="900"
    >
      <v-card class="shadow-e1-bordered" rounded="xl">
        <v-card-title class="d-flex">
          Select Performers
          <v-spacer />
          <v-text-field
            v-model="search"
            hide-details
            dense
            label="Search"
            append-icon="mdi-magnify"
            style="max-width: 320px"
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="form.performers"
            :search="search"
            :headers="performersTableHeaders"
            :items="filtersData.users"
            :custom-sort="_$smartCustomTableSort"
            :loading="loadings.filters"
            show-select
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import PrioritySelect from "@/components/SimilarTaskCreating/UI/PrioritySelect.vue";
import DateSelect from "@/components/SimilarTaskCreating/UI/DateSelect.vue";
import StatusSelect from "@/components/SimilarTaskCreating/UI/StatusSelect.vue";
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
import TableSorting from "@/mixins/TableSorting";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";

export default {
  components: { DateSelect, StatusSelect, PrioritySelect, TiptapVuetify },
  mixins: [TableSorting],
  emits: ["createTask"],
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: "default",
    },
    loadings: {
      type: Object,
      default() {
        return {};
      },
    },
    filtersData: {
      type: Object,
      default() {
        return {};
      },
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    taskTitleRules: [(v) => !!v || "Title is required"],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
    usersModal: false,
    search: null,
    menuProps: DEFAULT_MENU_PROPS,
    performersTableHeaders: [
      { text: "Name", value: "name" },
      { text: "Role", value: "department" },
      { text: "Team", value: "group" },
      { text: "email", value: "email" },
    ],
  }),
  methods: {
    handleCreateTask() {
      this.$emit("createTask");
    },
  },
};
</script>
