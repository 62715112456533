<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>Donors Finder</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="styled-card--default">
          <v-card-title>
            Created tasks
            <v-spacer />

            <v-chip label outlined class="mr-2" @click="fetchStatusGuides">
              Status guide <v-icon small right>mdi-information-outline</v-icon>
            </v-chip>
            <v-chip label color="primary" @click="goToCreateTask">
              Add <v-icon small right>mdi-plus</v-icon>
            </v-chip>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loadings.table"
            >
              <template #[`item._actions`]="{ item }">
                <v-menu
                  bottom
                  offset-y
                  :close-on-content-click="false"
                  content-class="my-shadow--e3"
                >
                  <template #activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="styled-card--default pa-0" width="120">
                    <v-list dense>
                      <v-list-item @click="exportFile(item)">
                        Export
                        <v-progress-circular
                          v-if="loadings.export.includes(item.id)"
                          size="18"
                          width="2"
                          indeterminate
                          class="ml-2"
                        />
                      </v-list-item>
                      <v-list-item @click="deleteTask(item)">
                        Delete

                        <v-progress-circular
                          v-if="loadings.deleting.includes(item.id)"
                          size="18"
                          width="2"
                          indeterminate
                          class="ml-2"
                        />
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>
              <template #[`item.actions`]="{ item: { actions } }">
                <template v-if="actions && actions.export_file === null">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon icon v-on="on">mdi-progress-clock</v-icon>
                    </template>
                    <div>
                      {{
                        actions.export_file === null
                          ? actions.status
                          : "In Progress"
                      }}
                    </div>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-btn icon @click="exportItem(actions.export_file)">
                    <v-icon>mdi-export-variant</v-icon>
                  </v-btn>
                </template>
              </template>
              <template #[`item.status`]="{ value }">
                <v-tooltip bottom content-class="remove-dialog-shadow pa-0">
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      small
                      :color="value.color"
                      class="white--text"
                    >
                      {{ value.text }}
                    </v-chip>
                  </template>
                  <v-card v-if="value.info" class="styled-card--default pa-2">
                    {{ value.info }}
                  </v-card>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="statusGuideDialogModel"
      content-class="my-shadow--e3"
      width="860"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Statuses
          <v-spacer />
          <v-btn icon @click="statusGuideDialogModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <template v-if="loadings.statusGuide">
            <v-skeleton-loader type="table" />
          </template>
          <template v-else>
            <v-data-table :headers="statuses.headers" :items="statuses.items">
              <template #[`item.color`]="{ value }">
                <v-chip label :color="value" @click="handleCopy(value)">
                  {{ value }} <v-icon small right>mdi-content-copy</v-icon>
                </v-chip>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { GP_DONORS_FINDER_TABLE_HEADERS } from "@/utils/defaultData";
import service from "@/plugins/service";
import ExportTaskService from "@/services/ExportTaskService";
import Notification from "@/mixins/Notification";
import copy from "copy-to-clipboard";

export default {
  mixins: [Notification],
  data: () => ({
    statusGuideDialogModel: false,
    headers: GP_DONORS_FINDER_TABLE_HEADERS,
    items: [],
    loadings: {
      table: false,
      statusGuide: false,
      export: [],
      deleting: [],
    },
    statuses: [],
  }),
  created() {
    this.fetchData();
  },
  methods: {
    handleCopy(data) {
      copy(data);
      this.mSuccess({
        title: "Copied to clipboard",
        text: `<mark>${data}</mark> coppied.`,
      });
    },
    async fetchStatusGuides() {
      try {
        const url = "/gpf/status-info";

        this.loadings.statusGuide = true;

        const response = await service.get(url);

        this.statuses = response.data;
        this.statusGuideDialogModel = true;
      } catch (e) {
        console.log("Error while fetching status guildes.", e);
      } finally {
        this.loadings.statusGuide = false;
      }
    },
    async exportFile(item) {
      try {
        const config = {
          type: 9,
          name: item.name,
          json: { taskID: item.id },
          context: this,
        };
        const exportService = new ExportTaskService(config);

        this.loadings.export.push(item.id);

        await exportService.createExport();
      } catch (e) {
        console.error("Error while exporting task.", e);
      } finally {
        this.loadings.export = this.loadings.export.filter(
          (v) => v !== item.id
        );
      }
    },
    async deleteTask(item) {
      try {
        const url = `/gpf/task/${item.id}`;

        this.loadings.deleting.push(item.id);

        const resp = await service.delete(url);

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "Task deleted.",
            type: "success",
          });
          this.fetchData();
        } else {
          this.$message.notification({
            title: "Warning",
            text: "Something went wrong. Contact your manager",
            duration: 6000,
            type: "error",
          });
        }
      } catch (e) {
        console.error("Error while exporting task.", e);
      } finally {
        this.loadings.deleting = this.loadings.deleting.filter(
          (v) => v !== item.id
        );
      }
    },
    exportItem(link) {
      window.open(link, "_blank");
    },
    goToCreateTask() {
      this.$router.push({ name: "donors-finder.create" });
    },
    async fetchData() {
      this.loadings.table = true;

      const resp = await this.$store.dispatch("donors-finder/fetchTable");

      if (resp) this.items = resp.items;

      this.loadings.table = false;
    },
  },
};
</script>
