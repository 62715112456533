<template>
  <div>
    <div class="d-flex justify-lg-space-between px-3">
      <div>
        <v-subheader class="pa-0"> LTT PRO: REPORT </v-subheader>
      </div>
      <div
        v-if="
          filter.report_status === 1 ||
          filter.report_status === 3 ||
          filter.report_status === 4
        "
      >
        <v-btn color="info" dark fab @click="send_data">
          <v-icon>mdi-email-send</v-icon>
        </v-btn>
      </div>
      <div>
        <v-menu
          transition="slide-x-transition"
          :close-on-content-click="false"
          :nudge-right="200"
          min-width="360px"
          contet-class="shadow-e2-bordered"
          rounded="xl"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition>
              <v-btn color="info" dark fab v-bind="attrs" v-on="on">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <v-card>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-select
                    v-model="filter.report_status"
                    :items="report_status"
                    item-text="name"
                    item-value="id"
                    label="Status"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-select>
                </v-list-item>
                <v-list-item>
                  <v-autocomplete
                    v-model="filter.projects_id"
                    :items="projects"
                    item-text="name"
                    item-value="id"
                    label="Project"
                    outlined
                    hide-details
                    dense
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-list-item>
                <v-list-item>
                  <v-autocomplete
                    v-model="filter.reporter"
                    :items="users"
                    item-value="id"
                    item-text="email"
                    label="Reporter"
                    dense
                    outlined
                    clearable
                    hide-details
                  ></v-autocomplete>
                </v-list-item>
                <v-list-item>
                  <v-autocomplete
                    v-model="filter.linkbuilder"
                    :items="linkbuildersItems"
                    item-value="id"
                    item-text="name"
                    dense
                    label="Linkbuilder"
                    outlined
                    :disabled="lockLinkbuilder"
                    :clearable="!lockLinkbuilder"
                    hide-details
                  ></v-autocomplete>
                </v-list-item>
              </v-list>
              <div class="text-center">
                <v-btn @click="fetch_data" width="120px" color="primary">
                  Apply
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>
    <v-container fluid>
      <v-card class="mx-auto">
        <v-card-text class="headline font-weight-bold">
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                solo
                class="mb-4"
              ></v-text-field>

              <v-data-table
                :search="search"
                :headers="table_headers"
                :items="items"
                show-select
                :footer-props="{
                  'items-per-page-options': [10, 15, 50, -1],
                }"
                :items-per-page="50"
                class="elevation-1"
                dense
                @change="handleChange"
              >
                <template #header.data-table-select>
                  <div @click="handleChange">
                    <v-checkbox
                      v-model="tableSelect"
                      :indeterminate="isInterminate"
                      :value="isChecked"
                    ></v-checkbox>
                  </div>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td>
                        <v-checkbox v-model="item.checked"></v-checkbox>
                      </td>
                      <td :style="get_width_action(item)">
                        <span
                          v-if="item.status_id === 1 || item.status_id === 3"
                        >
                          <v-row>
                            <v-col
                              ><v-switch
                                v-if="item.email === null"
                                disabled
                                hide-details
                                label="lb"
                                v-model="item.lb_send"
                              ></v-switch>
                              <v-switch
                                v-else
                                hide-details
                                label="lb"
                                v-model="item.lb_send"
                              ></v-switch
                            ></v-col>
                            <v-col>
                              <v-switch
                                hide-details
                                label="seo"
                                v-model="item.seo_send"
                              ></v-switch
                            ></v-col>
                          </v-row>
                        </span>
                        <span v-else-if="item.status_id === 4">
                          <v-btn-toggle v-model="item.done" borderless>
                            <v-btn value="0">
                              <span class="hidden-sm-and-down">Reopen</span>
                              <v-icon right> mdi-backup-restore </v-icon>
                            </v-btn>

                            <v-btn value="1">
                              <span class="hidden-sm-and-down">Fixed</span>
                              <v-icon right> mdi-playlist-check </v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </span>
                        <span v-else>
                          {{ item.id }}
                        </span>
                        <v-btn
                          v-if="
                            item.status_id === 2 ||
                            item.status_id === 5 ||
                            item.status_id === 6
                          "
                          icon
                          @click="seeDetails(item)"
                        >
                          <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                      </td>
                      <td v-if="item.status_id === 4">
                        {{ item.status }}
                      </td>
                      <td>
                        {{ item.problem_type }}
                      </td>
                      <td>
                        {{ item.problem_priority }}
                      </td>
                      <td>
                        {{ item.url_donor }}
                      </td>
                      <td>
                        {{ item.url_acceptor }}
                      </td>
                      <td>
                        {{ item.type
                        }}<span title="level">({{ item.level }})</span>
                      </td>
                      <td>
                        {{ item.islink }}
                      </td>
                      <td>
                        <span v-if="item.email === null">
                          the link has no author
                        </span>
                        <span v-else>
                          {{ item.user }}
                        </span>
                      </td>
                      <td>
                        {{ item.comment }}
                      </td>
                      <td>
                        {{ item.comment_lb }}
                      </td>
                      <td>
                        {{ item.updated_at }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import service from "../../../plugins/service";

export default {
  name: "Index",
  created() {
    this.fetchLinkbuilders();

    if (this.$route.query.status) {
      this.filter.report_status = parseInt(this.$route.query.status);
    }
    if (this.$route.query.projectId) {
      this.filter.projects_id = [parseInt(this.$route.query.projectId)];
    }

    this.fetch_report_status();
    this.fetchUsers();
  },
  data() {
    return {
      selectedTableItems: [],
      search: "",
      loadings: {},
      headers: [
        { text: "action", title: "action", value: "id", class: "text-left" },
        {
          text: "status",
          title: "status",
          value: "status",
          class: "text-left",
          align: "d-none",
        },
        {
          text: "problem_type",
          title: "problem_type",
          value: "problem_type",
          class: "text-left",
        },
        {
          text: "problem_priority",
          title: "problem_priority",
          value: "problem_priority",
          class: "text-left",
        },
        {
          text: "url_donor",
          title: "url_donor",
          value: "url_donor",
          class: "text-left",
        },
        {
          text: "url_acceptor",
          title: "url_acceptor",
          value: "url_acceptor",
          class: "text-center",
        },
        { text: "type", title: "type", value: "type", class: "text-center" },
        {
          text: "islink",
          title: "islink",
          value: "islink",
          class: "text-center",
        },
        { text: "user", title: "user", value: "user", class: "text-center" },
        {
          text: "comment",
          title: "comment",
          value: "comment",
          class: "text-center",
        },
        {
          text: "comment_lb",
          title: "comment_lb",
          value: "comment_lb",
          class: "text-center",
        },
        {
          text: "updated_at",
          title: "updated_at",
          value: "updated_at",
          class: "text-center",
        },
      ],
      linkbuildersItems: [],
      headers_active: {
        status: false,
      },
      items: [],
      users: [],
      filter: {
        report_status: null,
        projects_id: [],
        linkbuilder: null,
        reporter: null,
      },
      report_status: [],
      tableSelect: null,
      lockLinkbuilder: false,
    };
  },
  methods: {
    async fetchLinkbuilders() {
      const url = "/ltt/reports/get/linkbuilder";

      const resp = await service.get(url);

      if (resp) {
        this.linkbuildersItems = resp.data.data;

        if (this.linkbuildersItems.length === 1) {
          this.lockLinkbuilder = true;
          this.filter.linkbuilder = this.linkbuildersItems[0].id;
        }
      }
    },
    seeDetails(item) {
      const url = `https://api.serpinsider.com/tools/ltt/lb/response/${item.links_id}?level=${item.level}`;
      window.open(url, "_blank");
    },
    handleChange() {
      const checkedLength = this.items.filter((v) => v.checked);

      if (this.items.length === checkedLength.length) {
        this.tableSelect = false;
        return this.items.forEach((v) => (v.checked = false));
      }

      this.tableSelect = true;
      this.items.forEach((v) => (v.checked = true));
    },
    async fetchUsers() {
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.users = resp;
      }
    },
    fetch_report_status() {
      this.update_loading("fetch_report_status", true);

      let url = `${this.$store.state.server_url}/ltt/reports/get/data-status`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then((response) => {
          this.report_status = response.data;
          this.filter.report_status = response.data[0].id;
          this.update_loading("fetch_report_status", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_data() {
      this.update_loading("fetch_data", true);

      if (this.id_project !== null && this.id_project !== undefined) {
        let url = `${this.$store.state.server_url}/ltt/reports/get/data`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        if (this.filter.report_status === 4) {
          this.headers_active["status"] = true;
        } else {
          this.headers_active["status"] = false;
        }

        let data = {
          status_id: this.filter.report_status,
          projects_id: this.filter.projects_id,
          reporter: this.filter.reporter,
          linkbuilder: this.filter.linkbuilder,
        };

        axios
          .post(url, data, config)
          .then((response) => {
            this.items = response.data;
            this.update_loading("fetch_data", false);
            this.$forceUpdate();
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    send_data() {
      let self = this;
      self.update_loading("send_data", true);

      let url = `${self.$store.state.server_url}/ltt/reports/set/data`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        data: this.items,
      };

      self.items = [];

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("send_data", false);
          self.fetch_data();
        })
        .catch(function (error) {
          alert(error);
        });
    },
    get_width_action(item) {
      let style = "";
      if (item.status_id === 1 || item.status_id === 3) {
        style = "min-width: 200px";
      } else if (item.status_id === 4) {
        style = "min-width: 210px";
      }
      return style;
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isInterminate() {
      const checkedLength = this.items.filter((v) => v.checked);

      return (
        checkedLength.length > 0 && this.items.length !== checkedLength.length
      );
    },
    isChecked() {
      const checkedLength = this.items.filter((v) => v.checked);

      return (
        this.items.length > 0 && this.items.length === checkedLength.length
      );
    },
    table_headers() {
      let headers = [];

      for (let i in this.headers) {
        if (
          this.headers_active[this.headers[i].value] === undefined ||
          this.headers_active[this.headers[i].value]
        ) {
          headers.push(this.headers[i]);
        }
      }
      return headers;
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
  },
};
</script>

<style scoped></style>
