import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "url-control/updateUrl"(ctx, payload) {
      // eslint-disable-next-line no-useless-catch
      try {
        const url = "/url-control/update-url";
        const response = await Service.post(url, payload);
        if (response) {
          return response.data;
        }
        return null;
      } catch (error) {
        throw error;
      }
    },
  },
  getters: {},
};
