<template>
  <v-card :loading="loading">
    <div id="chart-positions">
      <div class="toolbar text-center pt-3">
        <div class="d-flex justify-space-between">
          <div class="px-3">
            <v-btn
              id="positions_type"
              small
              :outlined="pos_type !== 'positions'"
              color="primary"
              :class="{ active: pos_type === 'positions', 'ma-1': true }"
              @click="updatePos('positions')"
            >
              KC
            </v-btn>
            <v-btn
              id="gsc_type"
              small
              :outlined="pos_type !== 'gsc'"
              color="primary"
              :class="{ active: pos_type === 'gsc', 'ma-1': true }"
              @click="updatePos('gsc')"
            >
              GSC
            </v-btn>
          </div>
          <div>
            <v-btn
              id="two_week"
              small
              :outlined="selection !== 'two_week'"
              color="primary"
              @click="updateChartDates('two_week')"
              :class="{ active: selection === 'two_week', 'ma-1': true }"
            >
              2W
            </v-btn>
            <v-btn
              id="one_month"
              small
              :outlined="selection !== 'one_month'"
              color="primary"
              @click="updateChartDates('one_month')"
              :class="{ active: selection === 'one_month', 'ma-1': true }"
            >
              1M
            </v-btn>
            <v-btn
              id="three_months"
              small
              :outlined="selection !== 'three_months'"
              color="primary"
              @click="updateChartDates('three_months')"
              :class="{ active: selection === 'three_months', 'ma-1': true }"
            >
              3M
            </v-btn>
            <v-btn
              id="six_months"
              small
              :outlined="selection !== 'six_months'"
              color="primary"
              @click="updateChartDates('six_months')"
              :class="{ active: selection === 'six_months', 'ma-1': true }"
            >
              6M
            </v-btn>
            <v-btn
              id="one_year"
              small
              :outlined="selection !== 'one_year'"
              color="primary"
              @click="updateChartDates('one_year')"
              :class="{ active: selection === 'one_year', 'ma-1': true }"
            >
              1Y
            </v-btn>
          </div>
          <div></div>
        </div>
      </div>
      <div id="chart-timeline-positions">
        <apexcharts
          ref="chart"
          height="300"
          :options="options"
          :series="series"
        ></apexcharts>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import events from "../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";

export default {
  name: "Positions",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {
      pos_type: "gsc",
      loading: false,
      series: [],
      dirty_series: {
        top1: { name: "top 1", data: [] },
        top3: { name: "top 3", data: [] },
        top10: { name: "top 10", data: [] },
        top20: { name: "top 20", data: [] },
        top50: { name: "top 50", data: [] },
        top100: { name: "top 100", data: [] },
        clicks: { name: "clicks", data: [] },
        impressions: { name: "impressions", data: [] },
      },
      options: {
        chart: {
          id: "chart-timeline-positions",
          type: "area",
          height: 300,
          zoom: {
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        colors: [
          "#e91e63",
          "#ff9800",
          "#1976d2",
          "#42a5f5",
          "#90caf9",
          "#ccc",
          "#673ab7",
          "#8bc34a",
          "#a80808",
        ],
        annotations: {
          xaxis: [],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min: new Date().setMonth(new Date().getMonth() - 12),
          tickAmount: 6,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: [
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "clicks",
            show: false,
          },
          {
            seriesName: "impressions",
            show: false,
          },
        ],
        stroke: {
          width: [3, 3, 3, 3, 3, 3, 2, 2],
          dashArray: [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [7, 5],
            [7, 5],
          ],
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
      },
    };
  },
  methods: {
    resetData() {
      this.dirty_series = {
        top1: { name: "top 1", data: [] },
        top3: { name: "top 3", data: [] },
        top10: { name: "top 10", data: [] },
        top20: { name: "top 20", data: [] },
        top50: { name: "top 50", data: [] },
        top100: { name: "top 100", data: [] },
        clicks: { name: "clicks", data: [] },
        impressions: { name: "impressions", data: [] },
      };

      this.$refs.chart.resetSeries();
    },
    fetchContent(type) {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/content`;

      let config = {
        params: {
          url: self.selectedUrl,
          type: type,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let colors = {
        title: "#775DD0",
        h1: "#f06292",
        description: "#fbc02d",
        text: "#03a9f4",
        disavow: "#a40909",
      };

      let offsetY;

      switch (type) {
        case "title":
          offsetY = 0;
          break;
        case "description":
          offsetY = 10;
          break;
        case "h1":
          offsetY = 20;
          break;
        case "text":
          offsetY = 30;
          break;
        case "disavow":
          offsetY = 40;
          break;
      }

      axios.get(url, config).then((r) => {
        let xaxis = self.options.annotations.xaxis;

        for (let i = 0; i < r.data.data.data.length; i++) {
          if (r.data.data.data[i][1] === 0) {
            continue;
          }

          xaxis.push({
            x: r.data.data.data[i][0],
            strokeDashArray: [2, 5],
            opacity: 0.3,
            borderColor: colors[type],
            label: {
              borderColor: colors[type],
              offsetY: offsetY,
              style: {
                color: "#fff",
                background: colors[type],
              },
              text: type,
            },
          });
        }

        self.$refs.chart.updateOptions({
          annotations: {
            xaxis: xaxis,
          },
        });
      });
    },
    fetchDisavow() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/disavow`;

      let config = {
        params: {
          id_project: this.$store.state.project.active_project,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        let xaxis = self.options.annotations.xaxis;

        for (let i = 0; i < r.data.data.data.length; i++) {
          if (r.data.data.data[i][1] === 0) {
            continue;
          }

          xaxis.push({
            x: r.data.data.data[i][0],
            strokeDashArray: [2, 5],
            opacity: 0.3,
            borderColor: "#a40909",
            label: {
              borderColor: "#a40909",
              offsetY: 40,
              style: {
                color: "#fff",
                background: "#a40909",
              },
              text: "disavow",
            },
          });
        }

        self.$refs.chart.updateOptions({
          annotations: {
            xaxis: xaxis,
          },
        });
      });
    },
    fetchPositions(pos) {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/positions`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedUrl,
          pos: pos,
          type: self.pos_type,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.dirty_series[`top${pos}`] = {
            name: `top ${pos}`,
            data: r.data.data.positions,
          };
          self.updatePositionsChart();
        })
        .finally(() => {
          self.loading = false;
        });
    },
    fetchClicksImpressions(type) {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/clicks_impressions`;

      let config = {
        params: {
          url: self.selectedUrl,
          type: type,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.dirty_series[type] = r.data.data;
        self.updatePositionsChart();
      });
    },
    updatePositionsChart() {
      let series = [];

      series.push(this.dirty_series.top1);
      series.push(this.dirty_series.top3);
      series.push(this.dirty_series.top10);
      series.push(this.dirty_series.top20);
      series.push(this.dirty_series.top50);
      series.push(this.dirty_series.top100);
      series.push(this.dirty_series.clicks);
      series.push(this.dirty_series.impressions);

      this.series = series;

      this.updateChartDates("six_months");
    },
    updatePos(type) {
      this.pos_type = type;

      this.fetchData();
    },
    fetchData() {
      this.fetchPositions(1);
      this.fetchPositions(3);
      this.fetchPositions(10);
      this.fetchPositions(20);
      this.fetchPositions(50);
      this.fetchPositions(100);
      this.fetchClicksImpressions("clicks");
      this.fetchClicksImpressions("impressions");
      this.fetchContent("title");
      this.fetchContent("description");
      this.fetchContent("h1");
      this.fetchContent("text");
      this.fetchDisavow();
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
      self.fetchData();
    });

    events.$on("update-chart-dates", function (start, end) {
      self.$refs.chart.zoomX(start, end);
    });
  },
};
</script>

<style scoped></style>
