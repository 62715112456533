export const BACKGROUND_TASKS_DATA_TABLE_HEADERS = [
  {
    text: "Id",
    value: "id",
  },
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "Status value",
    value: "status_value",
  },
  {
    text: "Created",
    value: "created_at",
  },
  {
    text: "Started",
    value: "start_at",
  },
  {
    text: "Finished",
    value: "finish_at",
  },
  {
    text: "Type",
    value: "type",
  },
];
