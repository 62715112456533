export default {
  state: {
    selectedUrl: null,
    selectedCompetitorUrl: null,
    targetKeys: false,
    selection: "six_months",
    startDate: null,
    endDate: null,
    filter: {
      follow: null,
      hide_disavow: false,
      show_on_page: false,
      googleIndex: null,
      linkTypes: null,
      anchorTypes: null,
      targetKeys: false,
    },
    vision: {},
    sum_visibility: 0,
    vision_median: {},
    top10_median: {},
    common_table_items: [],
    percentage_link_types_to_total: {
      headers: [],
      items: [],
      types: [],
      stat: {
        manual: 0,
        auto: 0,
      },
      charts: {},
    },
    percentage_anchor_types_to_total: {
      headers: [],
      items: [],
    },
    heatmap: {
      ds: {},
      ur: {},
      anchor_links: {},
    },
  },
  actions: {},
};
