export default {
  mounted() {
    window.addEventListener("keydown", this.hkd_handleWindowKeydown.bind(this));
    window.addEventListener("keyup", this.hkd_handleWindowKeyup.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener(
      "keydown",
      this.hkd_handleWindowKeydown.bind(this)
    );
    window.removeEventListener("keyup", this.hkd_handleWindowKeyup.bind(this));
  },
  data: () => ({
    hkd_altPressed: false,
    hkd_KEY_CODES: {
      alt: 18,
      cmd: 91,
    },
  }),
  methods: {
    hkd_handleWindowKeydown(event) {
      this.hkd_handleKeydown(event.keyCode, event);

      const { alt, cmd } = this.hkd_KEY_CODES;

      if (event.keyCode === alt || event.keyCode === cmd) {
        this.hkd_altPressed = true;
      }

      if (this.hkd_altPressed && event.keyCode) {
        event.preventDefault();
        this.hkd_handleAltShortcut(event.keyCode, event);
      }
    },
    hkd_handleWindowKeyup(event) {
      const { alt, cmd } = this.hkd_KEY_CODES;

      if (event.keyCode === alt || event.keyCode === cmd) {
        this.hkd_altPressed = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    hkd_handleKeydown(code, event) {},
    // eslint-disable-next-line no-unused-vars
    hkd_handleAltShortcut(code, event) {},
  },
};
