<template>
  <v-menu
    open-on-hover
    right
    content-class="remove-dialog-shadow pa-0"
    :close-on-content-click="false"
    offset-x
    max-width="320"
  >
    <template #activator="{ on }">
      <v-icon v-on="on" small color="rgba(155,155,155,0.6)"
        >mdi-information-outline</v-icon
      >
    </template>
    <v-card class="styled-card--default pa-0" width="290">
      <v-row dense class="pa-2 ma-0">
        <v-col style="flex: 0 0 60px">
          <small><b>Title</b></small>
        </v-col>
        <v-col style="flex: 1">
          <small>{{ route.title }}</small>
        </v-col>
      </v-row>
      <v-divider />
      <v-row dense class="pa-2 ma-0">
        <v-col style="flex: 0 0 60px">
          <small><b>Path</b></small>
        </v-col>
        <v-col style="flex: 1">
          <small>{{ route.path }}</small>
        </v-col>
      </v-row>
      <v-divider />
      <v-row dense class="pa-2 ma-0">
        <v-col style="flex: 0 0 60px">
          <small><b>Name</b></small>
        </v-col>
        <v-col style="flex: 1">
          <small>{{ route.name }}</small>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    route: {},
  },
};
</script>
