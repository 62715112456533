<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e4" scrollable>
    <v-card class="styled-card--default">
      <v-card-title>
        Activity
        <v-chip
          class="ml-2 flat-chip flat-chip--primary"
          label
          @click="$emit('addToRecalculate')"
        >
          Add to recalculate
          <v-icon small right>mdi-calculator-variant-outline</v-icon></v-chip
        >
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="px-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          hide-default-footer
          class="activity-table"
        >
          <template #body="{ items }">
            <tbody>
              <tr v-for="(row, index) in items" :key="index">
                <td>
                  <v-menu
                    v-if="index !== 0"
                    content-class="my-shadow--e3"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="styled-card--default pa-0">
                      <v-list dense>
                        <v-list-item
                          @click="sendToIgnore(row, IGNORE_TYPES.NICHE)"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-hand-back-left-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            Ignore by Niche
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="sendToIgnore(row, IGNORE_TYPES.PROJECT)"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-hand-back-left-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            Ignore by Project
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="sendToIgnore(row, IGNORE_TYPES.URL)"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-hand-back-left-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            Ignore by URL
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="false" @click="handleUpdate(row)">
                          <v-list-item-icon>
                            <v-icon v-if="!loadings.onUpdate.includes(row.id)"
                              >mdi-sync</v-icon
                            >
                            <v-progress-circular
                              v-else
                              size="22"
                              width="2"
                              indeterminate
                            />
                          </v-list-item-icon>
                          <v-list-item-content> Update </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </td>
                <td
                  v-for="(key, i) in Object.keys(row)"
                  :key="key + i"
                  :class="`${i !== 0 ? 'text-center' : ''}`"
                  :style="`background-color: ${
                    index !== 0
                      ? getRgb(row[key], getMax(items, key), 15, 113, 223)
                      : 'transparent'
                  }`"
                >
                  <template>
                    {{ row[key] }}
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import GetRgb from "@/mixins/GetRgb";
import service from "@/plugins/service";

export default {
  props: {
    headers: Array,
    items: Array,
    selectedUrl: String,
  },
  mixins: [Dialog, GetRgb],
  data: () => ({
    loadings: {
      onUpdate: [],
      ignore: [],
    },
    IGNORE_TYPES: {
      URL: "url",
      PROJECT: "project",
      NICHE: "niche",
    },
  }),
  methods: {
    async sendToIgnore(item, type) {
      try {
        const url = "/tdh1/ignore-domain";

        const payload = {
          project_url: this.selectedUrl, // parent url
          ignore_url: item.url, // item.url
          type,
        };

        this.loadings.ignore.push(item.id);

        const resp = await service.post(url, payload);

        this.loadings.ignore = this.loadings.ignore.filter(
          (v) => v !== item.id
        );

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Success",
            text: "Domain has been successfully added to the ignored list.",
            type: "success",
          });
        }
      } catch {
        this.loadings.ignore = this.loadings.ignore.filter(
          (v) => v !== item.id
        );
      }
    },
    handleUpdate(tableRowItem) {
      this.sendToUpdate(tableRowItem);
    },
    async sendToUpdate(itemOnUpdate) {
      try {
        const url = "/tdh1/action";

        const payload = {
          type: this.type,
          action: "update",
          url_id: itemOnUpdate.id,
        };

        this.loadings.onUpdate.push(itemOnUpdate.id);

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Success",
            text: "Successfully sended to updating row.",
            type: "success",
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.onUpdate = this.loadings.onUpdate.filter(
          (v) => v !== itemOnUpdate.id
        );
      }
    },
    getMax(items = [], key = "") {
      let bigger = 0;

      items.forEach((item, idx) => {
        if (idx === 0) return;

        const value = item[key];

        if (parseInt(value) > bigger) bigger = parseInt(value);
      });

      return bigger;
    },
  },
};
</script>
