<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="12">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NicheAnalyzerHome"
}
</script>

<style scoped>

</style>