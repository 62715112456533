<template>
  <v-dialog v-model="dialog" width="620">
    <v-card class="styled-card--default">
      <v-card-title>
        Upload base keys <v-icon small right>mdi-upload</v-icon>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div>
          <small>Keys</small>
        </div>
        <v-textarea
          v-model="keys"
          filled
          placeholder="Enter keys"
          hide-details
          rows="7"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-chip
          label
          class="flat-chip flat-chip--primary px-6"
          @click="uploadKeys"
          large
          :disabled="loading"
        >
          Upload
          <v-progress-circular
            v-if="loading"
            width="2"
            size="12"
            indeterminate
          />
        </v-chip>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../mixins/Dialog";
import SemanticToolService from "../../services/semantic-tool/SemanticToolService";
import Project from "../../mixins/Project";

export default {
  mixins: [Dialog, Project],
  data: () => ({
    keys: "",
    loading: false,
  }),
  methods: {
    async uploadKeys() {
      try {
        this.loading = true;
        await SemanticToolService.uploadBaseKeys(this.keys, {
          id: this.$route.params.id,
        });
        this.$message.notification({
          title: "Success",
          text: "Keys uploaded successfully",
          type: "success",
        });
      } catch {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
