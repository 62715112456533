<template>
  <v-menu bottom max-width="220" offset-y :close-on-content-click="false">
    <template #activator="{ on }">
      <v-btn v-on="on" color="primary" outlined>{{ mainButtonText }}</v-btn>
    </template>
    <div>
      <v-list dense nav>
        <v-subheader>
          <div
            class="d-flex justify-space-between align-center"
            style="width: 100%"
          >
            <span>Links amount</span>
            <span>
              <info-tooltip>
                <span style="color: red">RED</span> - last update > 60 days ago
                or no date
              </info-tooltip>
            </span>
          </div>
        </v-subheader>
        <v-list-item>
          <c-number-input
            :model-value="value"
            @update:modelValue="value = $event"
            :min="0"
            :max="30"
          />
        </v-list-item>
        <v-list-item>
          <v-btn
            @click="handleUnloadData(data.date)"
            block
            :color="getButtonColor"
            :loading="loading"
            >{{ uploadButtonText }}
            <v-icon class="ml-2">
              {{
                success ? "mdi-checkbox-marked-circle-outline" : "mdi-upload"
              }}
            </v-icon></v-btn
          >
        </v-list-item>
        <v-list-item v-if="dateExist">
          <small
            class="d-flex justify-center align-center"
            style="width: 100%; opacity: 0.5; flex-direction: column"
          >
            <div>LU: {{ data.date }}</div>
            <div>( {{ $moment(data.date).fromNow() }} )</div>
          </small>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import CNumberInput from "@/components/UI/CNumberInput";
import InfoTooltip from "@/components/UI/InfoTooltip";

export default {
  components: { InfoTooltip, CNumberInput },
  emits: ["unload"],
  props: {
    mainButtonText: {},
    uploadButtonText: {},
    urlsAmount: {
      required: true,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateExist() {
      return typeof date === "string" && this.data.date !== "";
    },
    getButtonColor() {
      const date = this.data.date;

      if (this.success) {
        return "success";
      }

      if (!this.dateExist) {
        return "error";
      }

      const currentDate = new Date();
      const moreThen60days = 60;
      const daysDiff = this.$moment(currentDate).diff(
        this.$moment(date),
        "days"
      );

      if (daysDiff === moreThen60days) {
        return "error";
      }

      return "";
    },
    value: {
      get() {
        return this.urlsAmount;
      },
      set(value) {
        this.$emit("update:urlsAmount", value);
      },
    },
  },
  methods: {
    handleUnloadData(date) {
      this.$emit("unload", date);
    },
  },
};
</script>
