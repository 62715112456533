<template>
  <v-expansion-panel ref="panel">
    <v-expansion-panel-header class="pa-0">
      <v-list-item @click="handleClickNav" class="ma-0">
        <v-list-item-action class="d-flex justify-center">
          <v-icon size="18" v-text="item.icon" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-list class="pa-0 my-expansion-panels__nested-list" dense>
        <template v-for="child in item.children">
          <my-expansion-navigation-router-link
            v-if="!child.hidden"
            :key="child.name"
            :child="child"
            :is-nested="true"
          />
        </template>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import MyExpansionNavigationRouterLink from "@/components/UI/MyExpansionNavigationRouterLink.vue";

export default {
  components: { MyExpansionNavigationRouterLink },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    mouseOver: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    handleClickNav() {
      //
    },
  },
};
</script>
