export default {
  state: {
    staticData: null,
    topicsData: null,
    filters: {},
    activeDomain: "",
    successfullyUploadedSections: [],
    sectionsWithEnabledForceUpdate: [],
  },
  mutations: {
    "site-analysis/setSuccessfullyUploadedSections"(state, payload) {
      state.successfullyUploadedSections = payload;
    },
    "site-analysis/setStaticData"(state, payload) {
      state.staticData = payload;
    },
    "site-analysis/setTopicsData"(state, payload) {
      state.topicsData = payload;
    },
    "site-analysis/setFilters"(state, payload) {
      state.filters = payload;
    },
    "site-analysis/setActiveDomain"(state, payload) {
      state.activeDomain = payload;
    },
    "site-analysis/setSectionsWithEnabledForceUpdate"(state, payload) {
      state.sectionsWithEnabledForceUpdate = payload;
    },
  },
  actions: {},
  getters: {
    "site-analysis/staticData": (state) => state.staticData,
    "site-analysis/topicsData": (state) => state.topicsData,
    "site-analysis/activeDomain": (state) => state.activeDomain,
    "site-analysis/filters": (state) => state.filters,
    "site-analysis/sectionsWithEnabledForceUpdate": (state) =>
      state.sectionsWithEnabledForceUpdate,
    "site-analysis/successfullyUploadedSections": (state) =>
      state.successfullyUploadedSections,
  },
};
