<template>
  <div class="ltt-deleted-links">
    <v-row>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          LTT Deleted Links
        </page-name>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <div class="fix-button-height">
          <smart-date-filter
            :model-value="date"
            @update:modelValue="handleUpdateDate"
          />
        </div>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="user_id"
          :items="filtersData.users"
          :loading="loadings.filters"
          label="User Id"
          item-value="id"
          item-text="name"
          multiple
          placeholder="Type to search"
          hide-details
          dense
          clearable
          outlined
          @change="handleFilterChange(FILTERS.user_id, $event)"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="project_id"
          :items="projects"
          :loading="loadings.filters"
          label="Projects"
          item-value="id"
          item-text="name"
          multiple
          placeholder="Type to search"
          hide-details
          dense
          clearable
          outlined
          @change="handleFilterChange(FILTERS.project, $event)"
        >
          <template #prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="project_id.length > 0 ? 'primary darken-4' : ''"
                  >{{ icon }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="statuses"
          outlined
          dense
          hide-details
          :loading="loadings.statuses"
          label="Statuses"
          :items="filtersData.statuses"
          item-text="name"
          item-value="id"
          multiple
          placeholder="Select statuses"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="type"
          :items="filtersData.types"
          :loading="loadings.filters"
          label="Type"
          item-value="id"
          item-text="name"
          multiple
          placeholder="Type to search"
          hide-details
          dense
          clearable
          outlined
          @change="handleFilterChange('type', $event)"
        />
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          color="primary"
          block
          style="height: 40px"
          :loading="loadings.table"
          @click="fetchData"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          v-model="donor"
          label="Search"
          dense
          class="reduce-append-icon-mt"
          hide-details
          outlined
          append-icon="mdi-magnify"
          @click:append="fetchData"
        >
          <template #append>
            <v-btn icon :loading="loadings.table" @click="fetchData">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!loadings.table">
      <v-col v-if="sections.length !== 0" cols="12">
        <div
          v-for="(section, i) in sections"
          :key="section.name + i"
          class="ltt-deleted-links__table-wrapper"
        >
          <h2>{{ section.name }}</h2>
          <div
            v-for="(table, i) in section.items"
            :key="table.name + i"
            class="table__item"
          >
            <div class="d-flex align-center">
              <h3>{{ table.name }}</h3>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="updateAllRows(table.items)"
                    icon
                    class="ml-2"
                  >
                    <v-icon>mdi-sync</v-icon>
                  </v-btn>
                </template>
                <div>Update all rows</div>
              </v-tooltip>
            </div>

            <v-data-table
              :search="globalSearchModel"
              :headers="tableData.headers"
              :items="table.items"
              class="deleted-links-table"
            >
              <template #body="{ items }">
                <tbody>
                  <deleted-links-table-row
                    v-for="(row, index) in items"
                    :model-value="row"
                    @update:modelValue="row = $event"
                    :key="index"
                    :statuses="filtersData.statuses"
                  />
                </tbody>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
      <v-col v-else>
        <div
          class="placeholder-text__wrapper d-flex justify-center align-center mt-4"
        >
          <div class="placeholder">
            <span
              >Can't find anything. <br />
              Change filters and try again. 🤷‍♂</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div style="position: relative">
          <loader :show="true" />
          <v-skeleton-loader type="table-row-divider@10"></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageName from "@/components/DashboardDev/PageName";
import { DEFAULT_LTT_DELETED_LINKS_TABLE_DATA } from "@/utils/defaultData";
import Loader from "@/components/Main/Loader";
import Location from "@/mixins/Location";
import DeletedLinksTableRow from "@/components/Ltt/LTTDeletedLinks/DeletedLinkstableRow";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";

export default {
  components: {
    SmartDateFilter,
    DeletedLinksTableRow,
    Loader,
    PageName,
  },
  mixins: [Location],
  async created() {
    this.fetchFilters();
    this.fetchStatuses();
    const haveParams = this._$collectParams([
      "date",
      "project_id",
      "user_id",
      "type",
    ]);

    if (!this.$route.query.type) {
      const GUEST_POST_ID = 6;
      this.type = [GUEST_POST_ID];
      this.handleFilterChange("type", [GUEST_POST_ID]);
    }

    if (!this.$route.query.date) {
      const date = this.$moment(new Date()).format("YYYY-MM-DD");
      this.handleUpdateDate([date, date]);
    }

    if (haveParams) {
      this.fetchData();
    }
  },
  data: () => ({
    globalSearchModel: "",
    date: [],
    user_id: [],
    project_id: [],
    type: [],
    donor: "",
    statuses: [],
    filtersData: {},
    dateMenu: false,
    FILTERS: {
      date: "date",
      user_id: "user_id",
      project: "project_id",
    },
    tableData: {
      items: DEFAULT_LTT_DELETED_LINKS_TABLE_DATA.items,
      headers: DEFAULT_LTT_DELETED_LINKS_TABLE_DATA.headers,
    },
    sections: [],
    loadings: {
      statuses: false,
      table: false,
      checkQueue: false,
    },
  }),
  computed: {
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    icon() {
      if (this.project_id.length === this.projects.length)
        return "mdi-close-box";
      if (
        this.project_id.length > 0 &&
        this.project_id.length < this.projects.length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    updateAllRows(data = []) {
      this.$message.notification({
        title: "Update rows",
        text: "all rows have been added to the update queue",
        type: "success",
        duration: 6000,
      });
      data.forEach((item) => {
        this.handleUpdateRow(item);
      });
    },
    async handleUpdateRow({ link_id }) {
      const payload = {
        link_id,
        config: {
          params: {
            name: "update_info",
          },
        },
      };

      await this.$store.dispatch("ltt-deleted-links/updateLink", payload);
    },
    async fetchStatuses() {
      this.loadings.statuses = true;
      const resp = await this.$store.dispatch("ltt-deleted-links/fetchStatus");
      if (resp) {
        this.filtersData.statuses = resp;
      }
      this.loadings.statuses = false;
    },
    toggle() {
      if (this.project_id.length === this.projects.length) {
        this.project_id = [];
        this.handleFilterChange(this.FILTERS.project, []);
      } else {
        const newValue = this.projects.slice().map((el) => el.id);
        this.project_id = newValue;
        this.handleFilterChange(this.FILTERS.project, newValue);
      }
    },
    handleFilterChange(type, value) {
      this._$setSingleParam(type, value);
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const resp = await this.$store.dispatch("ltt-deleted-links/fetchFilters");
      if (resp) {
        this.filtersData.users = resp.user.data;
        this.filtersData.types = resp.types;
      }
      this.loadings.filters = false;
    },
    handleUpdateDate(date) {
      this.date = date;
      this._$setSingleParam(this.FILTERS.date, date);
    },
    async fetchData() {
      this.loadings.table = true;

      const { user_id, project_id, date, type, statuses, donor } = this;
      const payload = {
        filter: { user_id, project_id, date, type, statuses, donor },
      };
      const resp = await this.$store.dispatch(
        "ltt-deleted-links/fetchTableData",
        payload
      );
      if (resp) {
        this.sections = this.generateSections(resp.data);
      }
      this.loadings.table = false;
    },
    generateSections(data) {
      const output = [];
      const keys = Object.keys(data);

      keys.forEach((sectionKey) => {
        const itemsKeys = Object.keys(data[sectionKey]);
        const items = [];

        itemsKeys.forEach((key) => {
          items.push({
            name: key,
            items: data[sectionKey][key],
          });
        });

        output.push({
          name: sectionKey,
          items,
        });
      });

      return output;
    },
  },
};
</script>
