<template>
  <v-container style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <h2>Create series of tasks</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
      <v-col cols="12">
        <v-card
          class="styled-card--default d-flex flex-column"
          width="1220"
          min-height="800"
          rounded="lg"
        >
          <v-card-title>
            Create series of tasks
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text style="flex-grow: 1" class="pt-4">
            <series-tasks-form
              :model-value="tasksForm"
              :loadings="loadings.tasksOnLoading"
              :errors="errors"
              :local-storage-id="localStorageSeriesTasksFormId"
              @clearForm="handleClearForm"
              @settings="settings = $event"
              :filters-data="{
                responsible: {
                  items: taskFiltersData.users,
                  value: 'email',
                  text: 'email',
                },
                tags: {
                  items: taskFiltersData.tags,
                  value: 'id',
                  text: 'title',
                },
                projects: {
                  items: taskFiltersData.projects,
                  value: 'id',
                  text: 'name',
                },
              }"
              @update:modelValue="tasksForm = $event"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions class="d-flex justify-center">
            <v-menu
              bottom
              offset-y
              :close-on-content-click="false"
              content-class="my-shadow--e2"
            >
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  large
                  color="primary"
                  :loading="loadings.creating"
                >
                  <v-icon small left>mdi-content-save</v-icon> Create tasks
                  <v-icon small right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="styled-card--default">
                <v-card-text class="pa-0">
                  <v-list dense>
                    <v-list-item @click="handleCreateSeriesSubtasks(false)">
                      Normal saving
                    </v-list-item>
                    <v-list-item @click="handleCreateSeriesSubtasks(true)">
                      Save with postfix
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SeriesTasksForm from "@/components/UI/SeriesTasksForm/index.vue";
import { deepClone } from "@/utils/functions";
import service from "@/plugins/service";

export default {
  components: { SeriesTasksForm },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Choose type",
        to: { name: "similar-task-creating.choose-type" },
      },
      {
        text: "Series",
        disabled: true,
      },
    ],
    settings: {},
    localStorageSeriesTasksFormId: "series-tasks-data",
    tasksForm: [],
    LEVELS: {
      MAIN: 1,
      SUBTASK: 2,
    },
    loadings: {
      creating: false,
      tasksOnLoading: [],
      filters: false,
    },
    errors: [],
    taskFiltersData: {
      tags: [],
      users: [],
      projects: [],
    },
  }),
  created() {
    this.fetchTasksFilters();
    this.collectSeriesTasksDataFromLocalStorage();
  },
  watch: {
    tasksForm: {
      deep: true,
      handler(data) {
        window.localStorage.setItem(
          this.localStorageSeriesTasksFormId,
          JSON.stringify(data)
        );
      },
    },
  },
  methods: {
    handleClearForm() {
      this.errors = [];
    },
    collectSeriesTasksDataFromLocalStorage() {
      try {
        const data = window.localStorage.getItem(
          this.localStorageSeriesTasksFormId
        );

        if (data && data !== "undefined") {
          this.tasksForm = JSON.parse(data);
        }
      } catch (e) {
        console.error(
          'Cant parse from Local Storage in "collectSeriesTasksDataFromLocalStorage()" method'
        );
      }
    },

    async fetchTasksFilters() {
      this.loadings.filters = true;
      const filters = ["tags", "users", "projects"];
      const payload = {
        type: "/similar-task-creating",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        this.taskFiltersData = resp;
      }
      this.loadings.filters = false;
    },
    async handleCreateSubtask(taskData = null, parent_task_id) {
      try {
        const url = "/worksection/task/create";

        const payload = {
          type: "CREATE_TASKS",
          main_task: { ...taskData, parent_task_id: parent_task_id || null },
        };

        const resp = await service.post(url, payload);

        if (resp && resp.data.success) {
          this.createdTaskData = resp.data.task;

          this.$message.notification({
            title: "Successfully created",
            text: `<a
                      href="${
                        "https://ra.worksection.com/" +
                        this.createdTaskData.page
                      }"
                      target="_blank"
                  >${this.createdTaskData.name}</a
                  >`,
            type: "success",
          });
          return { parent_task_id: Number(resp.data.task.id) || null };
        }

        this.errors.push({
          id: taskData.id,
          message: resp.data.message,
        });

        this.$message.notification({
          title: "Something wrong | " + taskData.task_title,
          text: resp.data.message,
          type: "error",
          duration: 0,
        });

        return { parent_task_id: null };
      } catch (e) {
        console.error(e);
        return { parent_task_id: null };
      }
    },
    async handleCreateSeriesSubtasks(usePostfix = false) {
      if (usePostfix && !this.settings.postfix) {
        return this.$message.notification({
          title: "Postfix not filled in",
          text: 'Make sure that the "postfix" field in the settings is filled in.',
          type: "warning",
        });
      }

      try {
        const tasks = deepClone(this.tasksForm);

        tasks.forEach((item) => {
          item.date = [null, item.date];
        });

        this.loadings.creating = true;

        let lastSavedParentTaskId = null;

        for (const i in tasks) {
          const task = tasks[i];

          if (!task.task_title) continue;

          if (usePostfix) task.task_title += ` [${this.settings.postfix}]`;

          this.loadings.tasksOnLoading.push(task.id);

          const parentTaskId = task.level === 2 ? lastSavedParentTaskId : null;

          let resp;

          if (task.level === 2 && !parentTaskId) {
            console.error(
              "Previous main task not saved. Cant save subtask without parent task id"
            );
          } else {
            resp = await this.handleCreateSubtask(task, parentTaskId);
          }

          if (task.level === 1) lastSavedParentTaskId = resp.parent_task_id;

          this.loadings.tasksOnLoading = this.loadings.tasksOnLoading.filter(
            (v) => v !== task.id
          );
        }
      } catch {
        return null;
      } finally {
        this.loadings.creating = false;
      }
    },
  },
};
</script>
