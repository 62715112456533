<template>
  <v-container fluid class="pa-0" style="padding-bottom: 200px">
    <v-row>
      <v-col>
        <h2>{{ project_name }}</h2>
        <v-breadcrumbs class="pa-0" :items="items_breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.main != 0 ? 'info' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>Main:</div>
          <div>
            <strong>{{ summary.main }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.suggestion != 0 ? 'info' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>SGT:</div>
          <div>
            <strong>{{ summary.suggestion }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.related != 0 ? 'info' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>RLT:</div>
          <div>
            <strong>{{ summary.related }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.tr != 0 ? 'info' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>TR:</div>
          <div>
            <strong>{{ summary.tr }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.sw != 0 ? 'info' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>SW:</div>
          <div>
            <strong>{{ summary.sw }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.ah != 0 ? 'info' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>AH:</div>
          <div>
            <strong>{{ summary.ah }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.nrd == 0 ? 'error' : 'info'"
          rounded="lg"
          class="ma-0"
        >
          <div>NRD:</div>
          <div>
            <strong>{{ summary.nrd }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.nrd_left == 0 ? 'success' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>NRD Left:</div>
          <div>
            <strong>{{ summary.nrd_left }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert text color="info" rounded="lg" class="ma-0">
          <div>M NRD:</div>
          <div>
            <strong>{{ summary.min_nrd }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" md="1">
        <v-alert
          text
          :color="summary.good_all != 0 ? 'info' : ''"
          rounded="lg"
          class="ma-0"
        >
          <div>Good:</div>
          <div>
            <strong>{{ summary.good_all }}</strong>
          </div>
        </v-alert>
      </v-col>
      <v-col v-if="!keywordData.ahrefs_compiled">
        <v-alert text rounded="lg" class="text-center ma-0" height="100%">
          <div class="text-body-2 d-flex mb-3">
            Ahrefs queue
            <v-spacer />
            {{ keywordData.ahrefs_queue }}
            /
            {{ keywordData.total_items }}
          </div>
          <v-spacer />
          <v-progress-linear
            :value="
              Math.round(
                (keywordData.ahrefs_queue / keywordData.total_items) * 100
              )
            "
            rounded
            width="4"
            size="48"
          >
          </v-progress-linear>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="alert_block.status">
      <v-col>
        <v-alert text dismissible :type="alert_block.type">
          {{ alert_block.msg }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="pt-0">
        <v-card :loading="loading.data" class="shadow-e1-bordered" rounded="lg">
          <v-card-subtitle>
            <v-row class="justify-space-between">
              <v-col class="col-auto">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  hide-details
                  style="max-width: 340px"
                ></v-text-field>
              </v-col>
              <v-col class="col-auto">
                <v-menu bottom content-class="shadow-e2-bordered" rounded="lg">
                  <template #activator="{ on }">
                    <v-btn v-on="on" class="text-normal">
                      Actions
                      <v-icon small right>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-menu
                      left
                      rounded="lg"
                      content-class="shadow-e2-bordered"
                      offset-x
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-icon>
                            <v-icon> mdi-archive-clock-outline </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            <span v-if="!archive_loading">Archive Keys</span>
                            <span v-else>Loading...</span>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list-item @click="archiveKeys(1)">
                          <v-list-item-title>With good = 0</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="archiveKeys(2)">
                          <v-list-item-title>With NRD = 0</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      left
                      rounded="lg"
                      content-class="shadow-e2-bordered"
                      offset-x
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-icon>
                            <v-icon> mdi-refresh </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            <span v-if="!recollect_loading">
                              Recollect Serp
                            </span>
                            <span v-else>Loading...</span>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list-item @click="recollectSerp(true)">
                          <v-list-item-title>Nrd Only</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="recollectSerp(false)">
                          <v-list-item-title>All Keywords</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu
                      left
                      rounded="lg"
                      offset-x
                      content-class="shadow-e2-bordered"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-icon>
                            <v-icon> mdi-cloud-upload-outline </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Import Categories
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card rounded="lg" class="shadow-e2-bordered">
                        <v-card-title> Import Categories </v-card-title>
                        <v-card-text>
                          <h4>KeyAssort .csv (phrase, category)</h4>
                          <v-file-input
                            v-model="key_assort_file"
                            dense
                            outlined
                            accept=".csv"
                            prepend-icon="mdi-paperclip"
                            label="File"
                            hide-details
                          ></v-file-input>
                          <v-checkbox
                            v-model="reset_old_categories"
                            label="Reset Old Categories"
                          ></v-checkbox>
                          <v-btn
                            block
                            color="info"
                            class="mr-3"
                            @click="uploadKeyAssort"
                          >
                            Upload KeyAssort
                          </v-btn>
                          <v-btn
                            text
                            @click="
                              goTo(
                                'http://api.serpinsider.com/examples/st-key-assort-example.csv'
                              )
                            "
                            class="mt-2"
                            block
                          >
                            <v-icon small class="mr-2"
                              >mdi-cloud-download-outline</v-icon
                            >
                            Example
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                    <v-list-item @click="exportData">
                      <v-list-item-icon>
                        <v-icon>mdi-cloud-download-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        <span v-if="!loadings.export"> Export Keys </span>
                        <span v-else>Loading...</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-menu
                      left
                      rounded="lg"
                      offset-x
                      content-class="shadow-e2-bordered"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <v-list-item
                          v-on="on"
                          :disabled="!keywordData.ahrefs_compiled"
                        >
                          <v-list-item-icon>
                            <v-icon> mdi-update </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Refresh Volume
                            <div
                              v-if="!keywordData.ahrefs_compiled"
                              class="error--text"
                            >
                              Ahrefs not compiled
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card rounded="lg" class="shadow-e2-bordered">
                        <v-card-title> Refresh Volume </v-card-title>
                        <v-card-subtitle>
                          Overwrite data by volume and CD?
                        </v-card-subtitle>
                        <v-card-text>
                          <v-progress-linear
                            v-if="loadings.refresh_volume"
                            indeterminate
                          ></v-progress-linear>
                          <v-checkbox
                            v-model="overwrite_volume"
                            label="Overwrite"
                            hide-details
                            dense
                            class="ma-0"
                          ></v-checkbox>
                          <v-btn
                            class="mt-6"
                            color="info"
                            block
                            @click="refreshVolume(overwrite_volume)"
                            >Save</v-btn
                          >
                        </v-card-text>
                      </v-card>
                    </v-menu>
                    <v-list-item @click="uploadBaseKeysDialog = true">
                      <v-list-item-icon>
                        <v-icon>mdi-upload</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Upload base keys </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider />
          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total_items"
            :items-per-page="100"
            :footer-props="{ itemsPerPageOptions: [100, 200, 300, -1] }"
            dense
          ></v-data-table>
        </v-card>
        <v-snackbar
          v-model="snackbar_import_categories.status"
          timeout="5000"
          :color="snackbar_import_categories.color"
          >{{ snackbar_import_categories.message }}
        </v-snackbar>
      </v-col>
    </v-row>
    <UploadBaseKeysDialog v-model="uploadBaseKeysDialog" />
  </v-container>
</template>

<script>
import axios from "axios";
import CopyComponentId from "@/mixins/copyComponentId";
import service from "@/plugins/service";
import UploadBaseKeysDialog from "./UploadBaseKeysDialog.vue";

export default {
  name: "SemanticToolKeywords",
  components: { UploadBaseKeysDialog },
  mixins: [CopyComponentId],
  data: () => ({
    copyComponentId: "412ou4hjhasdjas104=20921",
    loadings: {
      export: false,
      refresh_volume: false,
    },
    keywordData: {},
    uploadBaseKeysDialog: false,
    overwrite_volume: true,
    items_breadcrumbs: [
      {
        text: "Semantic tool",
        to: "/semantic-tool/index",
        exact: true,
      },
      {
        text: "Keywords",
        disabled: true,
      },
    ],
    snackbar_import_categories: {
      status: false,
      type: "",
      message: "",
    },
    search: "",
    key_assort_file: null,
    headers: [],
    items: [],
    loading: {
      data: false,
    },
    options: {},
    total_items: 0,
    project_name: "",
    summary: {},
    recollect_loading: false,
    archive_loading: false,
    alert_block: {
      status: false,
      type: "",
      msg: "",
    },
    reset_old_categories: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    archiveKeys(type) {
      let self = this;

      self.archive_loading = true;

      let url = `${self.$store.state.server_url}/semantic-tool/keyword/archive-keys`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        taskID: this.$route.params.id,
        type: type,
      };

      axios
        .post(url, data, config)
        .then(() => {
          self.alert_block = {
            status: true,
            type: "success",
            msg: "Keys successfully archived",
          };
        })
        .catch(() => {
          self.alert_block = {
            status: true,
            type: "error",
            msg: "Some error",
          };
        })
        .finally(() => (self.archive_loading = false));
    },
    recollectSerp(nrd_only) {
      let self = this;

      self.recollect_loading = true;

      let url = `${self.$store.state.server_url}/semantic-tool/keyword/recollect-serp`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        taskID: this.$route.params.id,
        nrdOnly: nrd_only,
      };

      axios
        .post(url, data, config)
        .then(() => {
          self.alert_block = {
            status: true,
            type: "success",
            msg: "Serp successfully reset",
          };
        })
        .catch(() => {
          self.alert_block = {
            status: true,
            type: "error",
            msg: "Some error",
          };
        })
        .finally(() => (self.recollect_loading = false));
    },
    goTo(url) {
      window.open(url, "_blank");
    },
    uploadKeyAssort() {
      let self = this;

      let data = new FormData();

      data.append("file", self.key_assort_file);
      data.append("taskID", self.$route.params.id);
      data.append("resetOldCategories", self.reset_old_categories);

      let url = `${self.$store.state.server_url}/semantic-tool/task/upload-key-assort`;

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .post(url, data, config)
        .then(function () {
          self.snackbar_import_categories.status = true;
          self.snackbar_import_categories.message =
            "The file has been uploaded successfully, the categories will be updated in 15 minutes.";
          self.snackbar_import_categories.color = "success";
          self.key_assort_file = null;
        })
        .catch(() => {
          self.snackbar_import_categories.status = true;
          self.snackbar_import_categories.message = "Some error";
          self.snackbar_import_categories.color = "error";
        });
    },
    async exportData() {
      const url = "/export/base/get-hash";

      let payload = {
        taskID: this.$route.params.id,
      };

      this.loadings.export = true;

      const resp = await service.post(url, payload);

      this.loadings.export = false;

      if (!resp.data.success) {
        return this.$message.notification({
          title: "Something wrong",
          text: "Try again later.",
          type: "warning",
        });
      }

      location.href =
        `${this.$store.state.server_url}/export/semantic-tool/keys/0?hash=` +
        resp.data.hash;
    },
    async refreshVolume(value) {
      try {
        const url = "/semantic-tool/keyword/refresh-volume";

        let payload = {
          id: parseInt(this.$route.params.id),
          overwrite_volume: value,
        };

        this.loadings.refresh_volume = true;

        const resp = await service.post(url, payload);

        this.loadings.refresh_volume = false;

        if (!resp.data.success) {
          return this.$message.notification({
            title: "Something wrong",
            text: "Try again later.",
            type: "warning",
          });
        }

        const text = value ? "enabled" : "disabled";

        return this.$message.notification({
          title: "Done",
          text: "Overwrite volume is " + text,
          type: "success",
        });
      } catch {
        this.loadings.refresh_volume = false;
      }
    },
    fetchData() {
      let self = this;

      self.loading.data = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          taskID: this.$route.params.id,
          options: this.options,
          search: this.search,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/semantic-tool/keyword/all`,
          config
        )
        .then((r) => {
          self.headers = r.data.data.headers;
          self.project_name = r.data.data.project_name;
          self.summary = r.data.data.summary;
          self.items = r.data.data.items;
          self.total_items = r.data.data.total_items;
          this.keywordData = r.data.data;
        })
        .finally(() => (self.loading.data = false));
    },
  },
};
</script>

<style scoped></style>
