export const DETAILED_PAGE_CONFIGS = {
  title: "Detailed v2",
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Dev dashboard v2",
      disabled: true,
    },
    {
      text: "Detailed",
      disabled: true,
    },
  ],
};

export const ACTION_DATA_TYPES = [
  {
    text: "All",
    value: 0,
  },
  {
    text: "Only problem",
    value: 1,
  },
  {
    text: "Detailed",
    value: "detailed",
  },
];

export const ACTIONS_DATA_TABLE_HEADERS = [
  { text: "Status", value: "_status" },
  { text: "WS", value: "worksection", align: "center", width: "45px" },
  { text: "Hour", value: "hour" },
  { text: "Day", value: "day" },
  { text: "Service", value: "service" },
  { text: "Name", value: "name" },
  { text: "Queue", value: "queue" },
  { text: "Responsible", value: "responsible", align: "center" },
  { text: "Prior", value: "priority" },
  { text: "Service Prior", value: "service_priority" },
  { text: "Type", value: "type" },
  { text: "Updated", value: "updated_at" },
  { text: "Snooze Until", value: "snooze_until" },
];

export const DETAILED_PAGE_EVENTS = {
  CREATE_TASK: "detailed-page.create-task",
  CREATE_FAST_TASK: "detailed-page.create-fast-task",
  REFRESH_DATA: "detailed-page.refresh-data",
};
