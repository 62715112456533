<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <page-header title="NDC Money plan" :breadcrumbs="breadcrumbs" />
        <div>
          <v-menu
            bottom
            content-class="shadow-e1-bordered"
            nudge-bottom="42px"
            rounded="lg"
          >
            <template #activator="{ on }">
              <v-chip v-on="on" label large color="success">
                <span class="px-2">
                  Upload
                  <v-icon class="pl-1" small right>mdi-tray-arrow-up</v-icon>
                </span>
              </v-chip>
            </template>
            <v-list dense>
              <v-list-item @click="importFactDialogModel = true">
                Fact
              </v-list-item>
              <v-list-item @click="importPlanDialogModel = true">
                Plan
              </v-list-item>
              <v-list-item @click="importExpenseDialogModel = true">
                Expense
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="d-flex" style="gap: 8px">
        <table-search
          :model-value="search"
          @update:modelValue="search = $event"
          placeholder="Search in table"
          style="max-width: 250px"
        />
        <v-chip
          label
          @click="handleDeleteSelected"
          :disabled="selectedRows.length === 0 || loadings.deleteSelected"
        >
          Delete selected
          <my-badge
            v-if="selectedRows?.length > 0"
            class="error white--text ml-1"
            title="Deselect rows"
          >
            {{ selectedRows?.length }}
          </my-badge>
          <v-icon v-if="!loadings.deleteSelected" right small
            >mdi-delete-outline</v-icon
          >
          <v-progress-circular
            v-else
            class="ml-2"
            indeterminate
            size="18"
            width="2"
            color="error"
          />
        </v-chip>
        <vue-json-to-csv
          :json-data="tableData.items"
          csv-title="ndc_manage"
          :separator="'\t'"
        >
          <v-chip
            label
            @click="() => {}"
            :disabled="tableData?.items?.length === 0"
          >
            Export CSV
            <v-icon small right>mdi-export</v-icon>
          </v-chip>
        </vue-json-to-csv>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card class="styled-card--default pa-0">
          <v-progress-linear indeterminate :active="loadings.table" />
          <v-data-table
            v-model="selectedRows"
            show-select
            :headers="tableData.headers"
            :search="search"
            :items="
              tableData.items && tableData.items.filter((v) => !v.deleted)
            "
            multi-sort
            :custom-sort="_$smartCustomTableSort"
            :items-per-page="50"
            class="relative-tr"
            :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="editDialogModel"
      content-class="remove-dialog-shadow"
      max-width="650px"
      scrollable
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Edit row
          <v-spacer />
          <v-btn @click="editDialogModel = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row dense class="d-flex flex-column" style="gap: 8px">
            <v-col
              cols="12"
              v-for="itemKey in Object.keys(contentOnEdit)"
              :key="itemKey"
            >
              <edit-field-manager
                :model-value="contentOnEdit[itemKey]"
                @update:modelValue="contentOnEdit[itemKey] = $event"
                :headers="tableData.headers"
                :data="{ key: itemKey, content: contentOnEdit }"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-chip
            large
            label
            class="px-6"
            color="error"
            @click="deleteRow"
            :outlined="!confirm"
          >
            {{ confirm ? "Are you sure?" : "Delete" }}
          </v-chip>
          <v-chip
            large
            label
            class="px-6"
            color="success"
            @click="saveEditedRow"
            >Save</v-chip
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <import-fact-dialog
      :model-value="importFactDialogModel"
      @update:modelValue="importFactDialogModel = $event"
    />
    <import-plan-dialog
      :model-value="importPlanDialogModel"
      @update:modelValue="importPlanDialogModel = $event"
    />
    <import-expense-dialog
      :model-value="importExpenseDialogModel"
      @update:modelValue="importExpenseDialogModel = $event"
    />
  </v-container>
</template>

<script>
import EditFieldManager from "@/components/NDCManage/UI/EditFieldManager";
import ImportFactDialog from "@/components/NDCManage/UI/ImportFactDialog";
import ImportPlanDialog from "@/components/NDCManage/UI/ImportPlanDialog";
import ImportExpenseDialog from "@/components/NDCManage/UI/ImportExpenseDialog";
import TableSorting from "@/mixins/TableSorting";
import { deepClone } from "@/utils/functions";
import TableSearch from "@/components/UI/TableSearch.vue";
import MyBadge from "@/components/UI/MyBadge.vue";
import VueJsonToCsv from "vue-json-to-csv";
import PageHeader from "@/components/UI/PageHeader.vue";
import service from "@/plugins/service";

export default {
  components: {
    PageHeader,
    VueJsonToCsv,
    MyBadge,
    TableSearch,
    ImportExpenseDialog,
    ImportPlanDialog,
    ImportFactDialog,
    EditFieldManager,
  },
  mixins: [TableSorting],
  data: () => ({
    selectedRows: [],
    breadcrumbs: [
      {
        text: "NDC",
        disabled: true,
      },
      {
        text: "Money",
        disabled: true,
      },
      {
        text: "Plan",
        disabled: true,
      },
    ],
    search: "",
    confirm: false,
    editDialogModel: false,
    importFactDialogModel: false,
    importPlanDialogModel: false,
    importExpenseDialogModel: false,
    contentOnEdit: {},
    uploadFact: {
      headers: "",
      rows: "",
    },
    tableData: {
      items: [],
      headers: [],
    },
    loadings: {
      table: false,
      deleteSelected: false,
      saving: [],
      deletePlan: [],
    },
  }),
  created() {
    this.fetchData();
  },
  watch: {
    editDialogModel(value) {
      if (!value) this.confirm = false;
    },
  },
  methods: {
    async handleDeleteSelected() {
      const idOfRowsOnDelete = this.selectedRows.map((v) => v.id);

      const promises = [];

      this.loadings.deleteSelected = true;

      idOfRowsOnDelete.forEach((id) => {
        promises.push(this.sendIdOnDelete(id));
      });

      const results = await Promise.all(promises);

      results.forEach(({ status: deleted, id }) => {
        if (!deleted) return;

        this.tableData.items = this.tableData.items.filter((v) => v.id !== id);
        this.selectedRows = this.selectedRows.filter((v) => v.id !== id);
      });

      this.$forceUpdate();

      this.loadings.deleteSelected = false;
    },
    async sendIdOnDelete(id) {
      try {
        const url = `/money/delete/plan/${id}`;

        this.loadings.deletePlan.push(id);

        const resp = await service.post(url, {});

        return {
          status: resp.data.success,
          id,
        };
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.deletePlan = this.loadings.deletePlan.filter(
          (v) => v !== id
        );
      }
    },
    async deleteRow() {
      setTimeout(() => {
        this.confirm = false;
      }, 5000);

      if (!this.confirm) return (this.confirm = true);
      this.confirm = false;

      const resp = await this.$store.dispatch("ndc-manage/deleteRow", {
        id: this.contentOnEdit.id,
      });

      if (resp && resp.success) {
        const clone = JSON.parse(JSON.stringify(this.tableData));
        const idx = clone.items.findIndex(
          (v) => v.id === this.contentOnEdit.id
        );

        clone.items.splice(idx, 1);

        this.tableData = clone;

        this.contentOnEdit = {};

        this.$forceUpdate();

        this.editDialogModel = false;

        this.$message.notification({
          title: "Deleted",
          text: "Data successfully deleted 👍",
          type: "success",
          duration: 3500,
        });

        this.selectedRows = this.selectedRows.filter(
          (v) => v.id !== this.contentOnEdit.id
        );

        this.loadings.saving = this.loadings.saving.filter(
          (v) => v !== this.contentOnEdit.id
        );
      }
    },
    async saveEditedRow() {
      const itemIdOnSave = this.contentOnEdit.id;
      try {
        this.loadings.saving.push(itemIdOnSave);

        const resp = await this.$store.dispatch("ndc-manage/updateFact", {
          ...this.contentOnEdit,
        });
        this.loadings.saving = this.loadings.saving.filter(
          (v) => v !== itemIdOnSave
        );

        if (resp.success) {
          const clone = deepClone(this.tableData);

          const idx = clone.items.findIndex(
            (v) => v.id === this.contentOnEdit.id
          );

          clone.items[idx] = this.contentOnEdit;

          this.tableData = clone;

          this.$forceUpdate();

          this.editDialogModel = false;

          this.$message.notification({
            title: "Saved",
            text: "Data successfully saved 👍",
            type: "success",
            duration: 3500,
          });
        }
      } catch (e) {
        console.error(e);
        this.loadings.saving = this.loadings.saving.filter(
          (v) => v !== itemIdOnSave
        );
      }
    },
    expandTableItems(tableData) {
      let fixed = deepClone(tableData);

      fixed.items = fixed.items.map((item) => {
        item.deleted = false;
        return item;
      });

      return fixed;
    },
    async fetchData() {
      try {
        const url = "/money/plan";

        this.loadings.table = true;

        const resp = await service.post(url, {});

        if (resp) this.tableData = this.expandTableItems(resp.data.data);
      } catch (e) {
        console.error("Error while fetching MoneyPlan data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>
