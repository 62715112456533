<template>
  <div>
    <v-card
      flat
      outlined
      rounded="lg"
      style="border-style: dashed"
      class="pa-6 text-center d-flex justify-center align-center flex-column"
      height="320"
    >
      <div class="text-body-2" style="opacity: 0.7">
        No items. Select the appropriate sections in the "actions" menu.
        <div class="mt-5">
          Open menu by clicking on "Action" button in the right bottom angle.
        </div>
        <div>
          Or press
          <span class="keycup">Meta</span>
          <span class="px-1">+</span>
          <span class="keycup">A</span>
          combination.
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>
