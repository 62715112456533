<template>
  <div>
    <v-card
        class="mx-auto mt-4 mb-15"
        :loading="loading"
    >
      <v-card-text class="headline font-weight-bold">
        <v-row>
          <v-col>
            <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                :nudge-right="200"
                v-model="menu_filter"
                :value="menu_filter"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-fab-transition>
                  <v-btn
                      color="info"
                      dark
                      small
                      top
                      right
                      fab
                      absolute
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </v-fab-transition>
              </template>
              <v-card style="width:300px">
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-select
                          dense
                          solo
                          v-model="filter.niche"
                          :items="niches"
                          item-value="id"
                          item-text="name"
                          label="Niche"
                          hide-details
                          clearable
                      ></v-select>
                    </v-list-item>
                  </v-list>
                  <div class="text-center px-4 d-flex justify-space-between">
                    <v-btn
                        @click="fetchData"
                        color="primary">
                      Apply
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col><RadialBarChart v-if="series.optimize_by_top_5_pages >= 0" :series="[series.optimize_by_top_5_pages]" label="Optimized By Top 5"/></v-col>
          <v-col><RadialBarChart v-if="series.planned_top >= 0" :series="[series.planned_top]" label="Planned Top"/></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import RadialBarChart from "@/components/Dashboards/Summary/RadialBarChart";

export default {
  name: "SummaryIndex",
  components: {RadialBarChart},
  data() {
    return {
      loading: false,
      search: '',
      filter: {
        niche: 2
      },
      series: {
        optimize_by_top_5_pages: null,
        planned_top: null
      },
      menu_filter: false,
    }
  },
  computed: {
    niches() {
      return this.$store.state.reuse.niches
    },
  },
  methods: {
    fetchData() {
      let self = this;

      self.loading = true

      let url = `${self.$store.state.server_url}/dashboards/summary/index`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        },
        params: {
          filter: self.filter
        }
      };

      axios.get(url, config)
          .then(function (response) {
            self.series = response.data
            self.loading = false
          })
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>