<template>
  <v-data-table
    :search="presetsTableSearch"
    :headers="getHeaders"
    :items="data.items"
    class="dashboard-dev__presets-table"
  >
    <template v-slot:top>
      <v-text-field
        v-model="presetsTableSearch"
        label="Search by Name"
        prepend-icon="mdi-magnify"
        class="mx-4"
      ></v-text-field>
    </template>
    <template #[`header.chart`]>
      {{ get_current_date }}
    </template>
    <template #[`item.value`]="{ value }">
      {{ value | localeNumber }}
    </template>
    <template #[`item.chart`]="{ value: { series } }">
      <dev-detailer-sparkline :data="series" />
    </template>
  </v-data-table>
</template>

<script>
import { deepClone } from "../../../utils/functions";
import DevDetailerSparkline from "./DevDetailerSparkline.vue";

export default {
  components: { DevDetailerSparkline },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    presetsTableSearch: null,
  }),
  computed: {
    getHeaders() {
      const tmpr = deepClone(this.data.headers) || [];

      tmpr.forEach((header) => {
        if (header.value === "chart") {
          header.width = "140px";
        }
      });

      return tmpr;
    },
    get_current_date() {
      return this.$moment(new Date().getTime()).format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>
