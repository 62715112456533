<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>Check Unique</h2>
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col>
        <v-autocomplete
          dense
          hide-details
          outlined
          label="Projects"
          multiple
          item-value="id"
          item-text="name"
          clearable
          :items="_$getProjects"
          v-model="filters.projects"
          @change="_$handleFilterChange($event, 'projects')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          dense
          hide-details
          outlined
          clearable
          label="Page type"
          multiple
          :items="filtersData.pageType"
          v-model="filters.page_type"
          @change="_$handleFilterChange($event, 'page_type')"
        ></v-autocomplete>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          v-if="filters.projects.length > 0"
          @click="createExport"
          :loading="loadings.export"
          outlined
          class="mr-2"
        >
          Export
        </v-btn>
        <v-btn
          color="primary"
          width="140px"
          :loading="loadings.table"
          @click="fetchData"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="tableData.headers"
          :items="tableData.items"
          :loading="loadings.table"
          :sort-by="['pr_pr', 'need_top', 'pr']"
          :sort-desc="[true, false, false]"
          multi-sort
          :items-per-page="100"
          :footer-props="{ itemsPerPageOptions: [50, 100, 200, 500, -1] }"
          :custom-sort="_$smartCustomTableSort"
        >
          <template v-for="key in formattedCols" #[`item.${key}`]="{ value }">
            {{ String(value) === "0" ? "-" : value }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "../../plugins/service";
import Project from "../../mixins/Project";
import Location from "../../mixins/Location";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import { PAGE_TYPES } from "../../utils/defaultData";
import TableSorting from "../../mixins/TableSorting";

export default {
  mixins: [Project, Location, FiltersHandling, TableSorting],
  data: () => ({
    loadings: {
      table: false,
      export: false,
    },
    formattedCols: [
      "s1_our",
      "s1_serp",
      "s2_our",
      "s2_serp",
      "s3_our",
      "s3_serp",
    ],
    filters: {
      projects: [],
      page_type: [],
    },
    filtersData: {
      pageType: PAGE_TYPES,
    },
    tableData: {
      headers: [],
      items: [],
    },
  }),
  created() {
    this._$collectParams(["projects", "page_type"], "filters");
    this.fetchData();
  },
  methods: {
    async createExport() {
      try {
        this.loadings.export = true;
        let url = "/export/base/create-task";

        let payload = {
          typeID: 11,
          json: {
            projects: this.filters.projects,
          },
        };

        const resp = await service.post(url, payload);
        this.loadings.export = false;

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Done",
            text: "Export successfully created.",
            type: "success",
          });
        }
      } catch {
        this.loadings.export = false;
      }
    },
    async fetchData() {
      try {
        this.loadings.table = true;
        const url = "/unique-check/dashboard";
        const configs = {
          params: {
            p: this.filters.projects,
          },
        };

        const resp = await service.get(url, true, configs);

        this.loadings.table = false;

        if (resp && resp.data) {
          this.tableData = resp.data;
        }
      } catch {
        this.loadings.table = false;
      }
    },
  },
};
</script>
