import Service from "@/plugins/service";

export default {
    state: {
        filtersData: null,
    },
    mutations: {
        'contentChecker/setFilters'(state, payload) {
            state.filtersData = payload
        },
    },
    actions: {
        async 'contentChecker/fetchTableData'(ctx, payload) {
            const url = '/content-checker/all-pages'

            const resp = await Service.post(url, payload,true )
            if(resp && resp.status === 200) {
                return resp.data
            }
            return false
        },
    },
    getters: {
        'contentChecker/getFiltersData': state => state.filtersData
    },
}
