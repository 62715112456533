<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-row>
            <v-col>
              <h2>Project dashboard</h2>
              <v-breadcrumbs class="pa-0" :items="breadcrumbs"></v-breadcrumbs>
            </v-col>
            <v-col class="d-flex justify-end align-end">
              <v-chip
                label
                outlined
                :to="{
                  name: 'niche.analyzer.manage-domain-owner',
                  query: { na_project_id: this.$route.query.na_project_id },
                }"
              >
                <v-icon small left>mdi-cog</v-icon>
                Manage domain owner
              </v-chip>
              <v-chip label class="ml-2" outlined @click="openProjectSettings">
                Edit Project
                <v-progress-circular
                  v-if="loadings.edit"
                  class="ml-2"
                  indeterminate
                  size="18"
                  width="2"
                />
                <v-icon v-else small class="ml-2">mdi-pencil</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-progress-linear indeterminate :active="loadings.mainData" />
        </v-col>
        <v-col cols="12" md="3">
          <v-alert text icon="mdi-tag-outline" type="success">
            Name: <strong>{{ stat.name }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" md="3">
          <v-alert text icon="mdi-list-status" color="primary">
            Status: <strong>{{ stat.status }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" md="2">
          <v-alert text icon="mdi-bullhorn-outline" type="error">
            Phrases: <strong>{{ stat.phrases }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" md="2">
          <v-alert text icon="mdi-format-list-bulleted" type="warning">
            Categories: <strong>{{ stat.categories }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" md="2">
          <v-alert text icon="mdi-account-tie-outline" type="info">
            Owners: <strong>{{ stat.owners }}</strong>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="edit_dialog"
      persistent
      content-class="remove-dialog-shadow"
      scrollable
      max-width="660px"
    >
      <v-card class="styled-card--light" min-height="600">
        <div>
          <v-tabs v-model="tab">
            <v-tab :key="2"> Scheduler </v-tab>
            <v-tab :key="3"> E-Mail Report </v-tab>
            <v-tab :key="4"> E-Mail Report Traffic </v-tab>
          </v-tabs>
        </div>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-tabs-items v-model="tab">
                <v-tab-item :key="2">
                  <v-row>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Status project</h3>
                        </v-col>
                        <v-col cols="8">
                          <v-checkbox
                            v-model="project_data.status_project"
                            class="ma-0"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Sites</h3>
                          <p>Separator - ';'</p>
                        </v-col>
                        <v-col cols="8">
                          <v-textarea
                            v-model="project_data.sites"
                            dense
                            filled
                            placeholder="site1;site2"
                            hint="Separator ';'"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Period</h3>
                        </v-col>
                        <v-col cols="8">
                          <div v-for="day in days" :key="day.value">
                            <v-checkbox
                              v-model="project_data.scheduler"
                              :label="day.text"
                              :value="day.value"
                              class="ma-0"
                              hide-details
                            ></v-checkbox>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :key="3">
                  <v-row>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Sites</h3>
                          <p>Separator - new line</p>
                        </v-col>
                        <v-col cols="8">
                          <v-textarea
                            v-model="project_data.email_report_sites"
                            dense
                            filled
                            :placeholder="`domain\ndomain`"
                            hint="Separator - new line"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :key="4">
                  <v-row>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Count domains</h3>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            type="number"
                            hide-details
                            dense
                            filled
                            label="Amount"
                            placeholder="Enter amount"
                            v-model="project_data.count_domains"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Sites</h3>
                          <p>Separator - ';'</p>
                        </v-col>
                        <v-col cols="8">
                          <v-textarea
                            v-model="project_data.email_report_sites"
                            dense
                            filled
                            hint="Separator ';'"
                            placeholder="site1;site2"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Subscribers (e-mails)</h3>
                          <p>Separator ';'</p>
                        </v-col>
                        <v-col cols="8">
                          <v-textarea
                            v-model="project_data.subscribers"
                            filled
                            hint="Separator ';'"
                            dense
                            placeholder="email1@sub.com;email2@sub.com"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense>
                        <v-col cols="4">
                          <h3>Period</h3>
                        </v-col>
                        <v-col cols="8">
                          <div v-for="day in days" :key="day.value">
                            <v-checkbox
                              v-model="project_data.email_period"
                              :label="day.text"
                              :value="day.value"
                              class="ma-0"
                              hide-details
                            ></v-checkbox>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip
            label
            large
            class="px-6 mr-2"
            outlined
            @click="edit_dialog = false"
          >
            Close
          </v-chip>
          <extended-chip
            large
            label
            color="primary"
            class="px-6"
            :loading="loadings.save"
            @click="updateData"
            :disabled="loadings.save"
          >
            Save
          </extended-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import service from "@/plugins/service";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";

export default {
  name: "Index",
  components: { ExtendedChip },
  data() {
    return {
      tab: 2,
      edit_dialog: false,
      edit_dialog_loading: false,
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Niche Analyzer",
          to: { name: "niche.analyzer.index" },
        },
        {
          text: "Project Dashboard",
        },
      ],
      loadings: {
        edit: false,
        mainData: false,
        save: false,
      },
      stat: {
        name: "",
        status: "",
        phrases: "",
        categories: "",
        owners: "",
      },
      project_data: {
        scheduler: [],
        email_period: [],
        status_project: false,
        sites: null,
        count_domains: null,
        email_report_sites: null,
        subscribers: null,
      },
      days: [
        { value: 1, text: "mon" },
        { value: 2, text: "tue" },
        { value: 3, text: "wed" },
        { value: 4, text: "thu" },
        { value: 5, text: "fri" },
        { value: 6, text: "sat" },
        { value: 7, text: "sun" },
      ],
    };
  },
  computed: {
    idProject() {
      return (
        this.$route.query.na_project_id ||
        this.$store.state.na_project.active_project
      );
    },
  },
  methods: {
    async updateData() {
      try {
        const url = `/niche-analyzer/project/${this.idProject}/update-project`;

        this.loadings.save = true;

        const resp = await service.post(url, this.project_data);

        this.loadings.save = false;

        if (resp) {
          this.edit_dialog = false;
          this.edit_dialog_loading = false;
        }
      } catch (e) {
        this.loadings.save = false;
        console.error(e);
      }
    },
    openProjectSettings() {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.loadings.edit = true;

      axios
        .get(
          `${this.$store.state.server_url}/niche-analyzer/project/${this.idProject}/get-data`,
          config
        )
        .then((r) => {
          this.project_data = r.data;
          this.edit_dialog = true;
        })
        .finally(() => {
          this.dialog_buttons_disable = false;
          this.loadings.edit = false;
        });
    },
    fetchStat() {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.loadings.mainData = true;

      axios
        .get(
          `${this.$store.state.server_url}/niche-analyzer/project/${this.idProject}/project-stat`,
          config
        )
        .then((r) => {
          this.stat = r.data.stat;
        })
        .finally(() => {
          this.loadings.mainData = false;
        });
    },
  },
  created() {
    this.fetchStat();
  },
};
</script>
