<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between align-end">
        <page-header
          title="Background tasks"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Background tasks', disabled: true },
          ]"
        />
        <v-btn
          class="text-normal"
          color="primary"
          @click="isAddTaskDialogVisible = true"
        >
          Add task <v-icon small right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="loadings.tasks" cols="12">
        <v-skeleton-loader type="table" />
      </v-col>
      <v-col v-else cols="12" class="pt-0">
        <v-card flat outlined rounded="lg" class="pa-0">
          <v-data-table :items="tableData.items" :headers="tableData.headers" />
        </v-card>
      </v-col>
    </v-row>
    <add-task-dialog v-model="isAddTaskDialogVisible" @added="handleAdded" />
  </v-container>
</template>

<script>
import PageHeader from "../UI/PageHeader.vue";
import { BackgroundTasksApiService } from "./services/BackgroundTasksApiService";
import AddTaskDialog from "./components/AddTaskDialog.vue";
import { BACKGROUND_TASKS_DATA_TABLE_HEADERS } from "./defaults";
import dataTable from "../Dashboard4DX/components/DataTable.vue";

export default {
  computed: {
    dataTable() {
      return dataTable;
    },
  },
  components: { AddTaskDialog, PageHeader },

  data: () => ({
    isAddTaskDialogVisible: false,
    loadings: {
      tasks: false,
    },
    tableData: {
      items: [],
      headers: BACKGROUND_TASKS_DATA_TABLE_HEADERS,
    },
  }),

  mounted() {
    this.initializeDashboard();
  },

  methods: {
    handleAdded() {
      this.initializeDashboard();
    },
    async initializeDashboard() {
      try {
        this.loadings.tasks = true;

        const { data } = await BackgroundTasksApiService().getTasks();

        this.tableData.items = data?.data || [];
      } catch (e) {
        console.error("Error while loading background tasks.", e);
      } finally {
        this.loadings.tasks = false;
      }
    },
  },
};
</script>
