<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :footer-props="defaultFooterProps"
    :items-per-page="-1"
    hide-default-footer
    :custom-sort="customSort"
    class="centered-content ldd__default-table"
  >
    <template v-for="h in headers" #[`header.${h.value}`]="{ header }">
      <template v-if="header.title">
        <v-tooltip bottom :key="h.value">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ header.text }}</span>
          </template>
          <div style="max-width: 150px">{{ header.title }}</div>
        </v-tooltip>
      </template>
      <template v-else>
        <span :key="h.value">{{ header.text }}</span>
      </template>
    </template>
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="(row, rowIdx) in items" :key="rowIdx">
          <td
            v-for="(key, i) in Object.keys(row)"
            :key="key + i"
            :style="`background-color: ${rowIdx !== 0 ? getRgb(
              row[key].value,
              getMax(items, key),
              50,
              233,
              153,
              key
            ) : ''}`"
          >
            <template v-if="i === 0">
              <span :style="`${row[key].value && row[key].value.length < 30 ? 'white-space:nowrap;' : ''}`">
                {{ row[key].value }}
              </span>
            </template>
            <template v-else>
              <div
                v-if="row[key] && row[key].value"
                class="colored-td colored-td--clickable"
                @click="goToSpiderLink(row[key], activeUrl)"
                style="cursor: pointer"
              >
                {{ row[key].value }}
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import Project from "@/mixins/Project";
import SpiderLink from "@/mixins/components/LinksDynamicDashboard/SpiderLink";

export default {
  mixins: [Project, SpiderLink],
  props: {
    activeUrl: {},
    filterName: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    customSort: {
      type: Function,
      default() {
        return Function;
      },
    },
  },
  data: () => ({
    defaultFooterProps: { "items-per-page-options": [15, 30, -1] },
  }),
  methods: {
    getMax(items, key) {
      let bigger = 0;

      items.forEach((item, idx) => {
        if (idx === 0) return;

        const newValue = parseInt(item[key].value);
        if (newValue > bigger) {
          bigger = newValue;
        }
      });

      return bigger;
    },
    getRgb(inputVal, max, colorYR, colorYG, colorYB, key) {
      const forbiddenKeys = ["none", "0m"];
      if (forbiddenKeys.includes(key)) {
        return "transparent";
      }
      if (!inputVal || isNaN(inputVal)) {
        return "transparent";
      }
      inputVal = parseInt(inputVal);
      max = parseInt(max);
      const n = 100;
      const xr = 255;
      const xg = 255;
      const xb = 255;
      const pos = parseInt(Math.round((inputVal / max) * 100).toFixed(0));
      const red = parseInt((xr + (pos * (colorYR - xr)) / (n - 1)).toFixed(0));
      const green = parseInt(
        (xg + (pos * (colorYG - xg)) / (n - 1)).toFixed(0)
      );
      const blue = parseInt((xb + (pos * (colorYB - xb)) / (n - 1)).toFixed(0));
      return "rgba(" + red + "," + green + "," + blue + ", 0.5)";
    },
  },
};
</script>
