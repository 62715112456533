import { deepClone, set } from "@/utils/functions";

/*eslint-disable*/
class Filters {
  static INPUT_TYPES = {
    text: "text",
    textarea: "textarea",
    number: "number",
    singlePick: "single_pick",
    multiPick: "multi_pick",
    dateRange: "date_range",
    singleDate: "single_date",
    numbersRange: "numbers_range",
  };

  defaultValues = {
    multi_pick: [],
    date_range: [null, null],
    numbers_range: [null, null],
  };

  #processedFilters = null;
  #config = null;
  #processedGroups = null;
  #initialFiltersState = null;

  constructor(filters, config) {
    this.config = config;

    this.processedFilters = this.#initializeFilters(filters);
    this.processedGroups = this.#getProcessedGroups(filters, config);
  }

  get filters() {
    return this.processedFilters;
  }
  get groups() {
    return this.processedGroups;
  }

  #initializeFilters(filters) {
    const output = {};

    filters.forEach((filter) => {
      const path = filter.path || filter.value;
      const value =
        filter.default_value || this.defaultValues[filter.type] || null;

      set(output, path, value);
    });

    return output;
  }

  #setupColumns(filters, config) {
    // This method mutating !!initialFilters!! value.
    try {
      if (!config || !config.columnSetting)
        throw new Error("Cant get configs of this factory.");

      const settings = config.columnSetting;

      Object.entries(filters).forEach(([idx, { value }]) => {
        const currentSetting = settings[value];

        if (currentSetting) {
          filters[idx] = {
            ...filters[idx],
            ...currentSetting,
          };
        }
      });
    } catch (e) {
      console.error("Filters Factory. Error while initializing columns.", e);
    }
  }

  #setupGroups(result, filters, config) {
    // This method mutating !!result!! value.
    try {
      const othersFilters = {
        name: "Others",
        filters: [],
      };

      filters.forEach((filter) => {
        let isFilterContainsInExistedGroups = false;

        Object.values(config.groups).forEach((group, idx) => {
          const isLastGroup = idx === config.groups.length - 1;
          const existedGroupIdx = result.findIndex(
            (g) => g.name === group.name
          );
          const isCurrentGroupAlreadyExist = existedGroupIdx !== -1;

          let groupData;
          const newGroup = {
            name: group.name,
            filters: [],
          };

          if (isCurrentGroupAlreadyExist) {
            groupData = result[existedGroupIdx];
          } else {
            groupData = newGroup;
          }

          if (group.filters.includes(filter.value)) {
            groupData.filters.push(filter);
            isFilterContainsInExistedGroups = true;
          }

          if (isCurrentGroupAlreadyExist) return;

          if (groupData.filters.length > 0) result.push(groupData);
        });

        if (!isFilterContainsInExistedGroups)
          othersFilters.filters.push(filter);
      });

      if (othersFilters.filters.length > 0) result.push(othersFilters);
    } catch (e) {
      console.error("Filters Factory. Error while initializing groups.", e);
    }
  }
  #getProcessedGroups(filtersRawData, config) {
    const filters = deepClone(filtersRawData);
    const result = [];

    if (config.columnSetting) this.#setupColumns(filters, config);

    if (config.groups) {
      this.#setupGroups(result, filters, config);
    }

    return result;
  }
}

export default Filters;
