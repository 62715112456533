<template>
  <v-card flat outlined rounded="xl">
    <v-card-title
      class="text-body-2"
      :style="`${isError ? '' : 'background-color: rgba(155, 155, 155, 0.1)'}`"
    >
      <b
        :class="{
          'error--text': isError,
        }"
      >
        {{ data?.action?.name }}</b
      >
      <v-icon v-if="isError" small @click="handleRefresh" class="ml-2">
        mdi-refresh
      </v-icon>
      <v-spacer />
      <v-tooltip v-if="isError" bottom open-delay="200">
        <template #activator="{ on }">
          <v-icon v-on="on" color="error"> mdi-alert-remove-outline </v-icon>
        </template>
        <template #default>
          The data for this action is not loaded due to an error
        </template>
      </v-tooltip>
    </v-card-title>
    <template v-if="!isError">
      <v-card-text
        class="pa-4 pt-0 d-flex flex-column"
        style="gap: 1rem; background-color: rgba(155, 155, 155, 0.1)"
      >
        <template v-if="data?.pending">
          <div style="width: 100%; height: 200px" class="skeleton" />
        </template>
        <template v-else>
          <template v-for="(section, idx) in data?.sections || []">
            <actions-section
              v-if="section"
              cols="12"
              :key="`detail-${idx}`"
              :data="section"
            />
          </template>
        </template>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import ActionsSection from "./ActionSection.vue";
import eventPipe from "../../../events/eventPipe";
import { DETAILED_PAGE_EVENTS } from "../defaults";

export default {
  components: { ActionsSection },

  provide() {
    return {
      segment: () => this.data,
    };
  },

  inject: ["uploadedSegmentsStatus"],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    isError() {
      return this.uploadedSegmentsStatus()?.[this.data?.action?.id] === "error";
    },
  },

  methods: {
    handleRefresh() {
      eventPipe.$emit(DETAILED_PAGE_EVENTS.REFRESH_DATA, this.data?.action);
    },
  },
};
</script>
