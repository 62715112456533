import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'serp-dynamics/fetchData'(ctx, payload) {
            const url = '/one-page-analyzer/serp-dynamic/main'
            const response  = await Service.post(url, payload)
            if(response) {
                return response.data
            }
            return null
        },
        async 'serp-dynamics/getDomainTableData'(ctx, payload) {
            const url = '/one-page-analyzer/serp-dynamic/domains-vision'
            const response  = await Service.post(url, payload)
            if(response) {
                return response.data
            }
            return null
        },
        async 'serp-dynamics/getKeywordTableData'(ctx, payload) {
            const url = '/one-page-analyzer/serp-dynamic/keyword-serp'
            const response  = await Service.post(url, payload)
            if(response) {
                return response.data
            }
            return null
        },
    },
    getters: {},
}