<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-alert
              text
          >
            FL: <strong>{{ total_items.fl }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" md="4">
          <v-alert
              text
          >
            1 Link: <strong>{{ total_items.link_1 }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" md="4">
          <v-alert
              text
          >
            2 Link: <strong>{{ total_items.link_2 }}</strong>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card :loading="loading">
            <v-card-title>
              <v-menu
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  :nudge-right="200"
                  v-model="modal.shown"
                  :value="modal.shown"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-fab-transition>
                    <v-btn
                        color="info"
                        dark
                        small
                        top
                        right
                        fab
                        absolute
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </template>
                <v-card style="max-width: 500px;min-width:500px">
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <v-dialog
                            ref="dialog"
                            v-model="date_modal"
                            persistent
                            width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filter.date_range"
                                label="Anchor Plan Dates"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="filter.date_range"
                              range
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="date_modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="date_modal = false"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-list-item>
                      <v-list-item>
                        <v-combobox
                            dense
                            clearable
                            outlined
                            v-model="filter.performer"
                            :items="performers"
                            label="Performer"
                            hide-details
                        ></v-combobox>
                      </v-list-item>
                    </v-list>
                    <div class="text-center">
                      <v-btn
                          @click="fetchData"
                          color="primary">
                        Apply
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-card-text>
              <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="items"
                  item-key="done_url"
                  class="elevation-1"
                  :footer-props="{'items-per-page-options': [10, 15, 50, -1]}"
                  :items-per-page="50"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

const dateFormat = require('dateformat');
const date = new Date();

export default {
  name: "GpmGPTotal",
  data(){
    return {
      loading: false,
      date_modal: false,
      modal: {
        shown: false,
      },
      filter: {
        date_range: [
          dateFormat(new Date(date.getFullYear(), date.getMonth(), 1), "yyyy-mm-dd"),
          dateFormat(new Date(date.getFullYear(), date.getMonth() + 1, 0), "yyyy-mm-dd")
        ],
        performer: undefined,
      },
      items: [],
      total_items: [],
      headers: [
        {text: 'Done Url', value: 'done_url'},
        {text: 'Project', value: 'project'},
        {text: 'Type', value: 'type'},
      ],
      performers: [],
    }
  },
  methods: {
    fetchFilterData(){
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/gpm/task-view/get-filter-data`;

      let config = {
        params: {
          dashboardType: 'gp_total'
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.performers = r.data.performers
          })
          .finally(() => self.loading = false)
    },
    fetchData(){
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/gpm/task-view/get-dashboard-data`;

      let config = {
        params: {
          typeID: 1,
          filter: self.filter,
          dashboardType: 'gp_total'
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.items = r.data.items
            self.total_items = r.data.total_items
          })
          .finally(() => self.loading = false)
    }
  },
  created() {
    this.fetchFilterData()
  }
}
</script>

<style scoped>

</style>