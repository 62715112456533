<template>
  <v-badge
    v-if="traffic?.diff"
    :content="String(Number(traffic?.diff)).replace('-', '')"
    :color="traffic?.diff < 0 ? '#ff7f7f' : '#7fca82'"
    overlap
    bordered
  >
    <v-chip label small :color="getPositionColor(traffic?.our)">
      <template v-if="traffic?.diff !== 0">
        {{ Number(traffic?.our) }}
      </template>
      <template v-else>
        {{ traffic?.our }}
      </template>
    </v-chip>
  </v-badge>
  <v-chip v-else label small :color="getPositionColor(traffic?.our)">
    <template v-if="traffic?.diff !== 0">
      {{ Number(traffic?.our) }}
    </template>
    <template v-else>
      {{ traffic?.our }}
    </template>
  </v-chip>
</template>
<script>
export default {
  props: {
    traffic: Object,
  },
  methods: {
    rgba(r, g, b, a = 1) {
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    getPositionColor(value) {
      if (value === null || value === "") return "";

      if (value === "-") {
        return "initial";
      }
      if (value === 101) {
        return "initial";
      }

      if (isNaN(value)) return "transparent";

      value = parseInt(value);

      if (between(value, 1, 10)) {
        // GREEN
        const r = Math.round(value * 6.3);
        const g = Math.round(130 + value * 12.5);
        const b = Math.round(value * 6.3);
        return this.rgba(r, g, b, 0.5);
      }
      if (between(value, 11, 30)) {
        // YELLOW
        const r = 255;
        const g = Math.round(250 - value * 1.5);
        const b = Math.round(255 - value * 15);
        return this.rgba(r, g, b, 0.5);
      }
      if (between(value, 31, 50)) {
        // ORANGE
        const r = 255;
        const g = Math.round(250 - value * 3.4);
        const b = Math.round(140 - value * 2.3);
        return this.rgba(r, g, b, 0.5);
      }
      if (between(value, 51, 100)) {
        // RED
        const r = 255;
        const g = Math.round(120 - value * 1.2);
        const b = Math.round(120 - value * 1.2);
        return this.rgba(r, g, b, 0.5);
      }

      return "transparent";

      function between(value, min, max) {
        const x = value;
        return (x - min) * (x - max) <= 0;
      }
    },
  },
};
</script>
