export default {
  methods: {
    pnm_handleNavigateTo(sectionName, dataPrefix = "data-page-navigation") {
      try {
        const selector = `[${dataPrefix}="${sectionName}"]`;

        const titleElement = document.querySelector(selector);

        this.pnm_navigateToDom(titleElement);
      } catch (e) {
        console.error(e);
      }
    },
    pnm_navigateToDom(domElement) {
      const yOffset = -100;

      const y =
        domElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

      domElement.classList.add("dom-navigation-marked");
      domElement.classList.add("transition");

      setTimeout(() => {
        domElement.classList.remove("dom-navigation-marked");
      }, 4000);
      setTimeout(() => {
        domElement.classList.remove("transition");
      }, 4250);

      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
