import {PROJECT_ID_IDTF} from "@/utils/defaultData";

export default {
  methods: {
    _$collectParams(filtersWithArrayType = [], path = null) {
      const setValue = (value, key) => {
        if (path) {
          this[path][key] = value;
          return;
        }
        this[key] = value;
      };

      const keys = Object.keys(this.$route.query);

      keys.forEach((key) => {
        const value = this.$route.query[key];

        if (filtersWithArrayType.includes(key)) {
          const isNumArray = this.__arrayOfNumbers(value);

          if (isNumArray) {
            const newValue = value.split(",").map((item) => parseInt(item));

            return setValue(newValue, key);
          }

          const newValue = value.split(",");
          return setValue(newValue, key);
        }

        if (value === "true" || value === "false") {
          const newValue = value === "true";
          return setValue(newValue, key);
        }

        if (!isNaN(Number(value))) {
          const newValue = Number(value);
          return setValue(newValue, key);
        }

        setValue(value, key);
      });

      return keys.length > 0;
    },
    _$setSingleParam(type, value) {
      const isArray = Array.isArray(value);
      if (isArray) {
        value = value.map((item) => {
          if (item === null) {
            item = "null";
          }
          return String(item);
        });
      }
      if (isArray && value.length === 0) {
        value = null;
      }
      this._$setQueryParams(type, value);
    },
    _$clearQueryParams() {
      const projectIdValue = `?${PROJECT_ID_IDTF}=${this.$store.getters.active_project}`;
      return history.pushState(null, null, projectIdValue);
    },
    _$setQueryParams(query, newValue, redirect = false) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const haveParams = Object.keys(params).length !== 0;
      const moreThenOneParams = Object.keys(params).length >= 1;
      const haveQuery = params[query];

      if (newValue === null || newValue === "") {
        try {
          const string = window.location.search || "";
          const projectIdValue = params[query];
          let startIdx = string.indexOf(query);
          if (moreThenOneParams) startIdx = string.indexOf(query) - 1;
          const endIdx =
            string.indexOf(query) +
            query.length +
            "=".length +
            projectIdValue.length;

          let fixedValue = this.__replaceAt(startIdx, endIdx, "", string);
          if (!fixedValue) {
            return history.pushState(null, null, window.location.pathname);
          }

          if (fixedValue.indexOf("?") === -1) {
            fixedValue = fixedValue.split("");
            fixedValue[0] = "?";
            fixedValue = fixedValue.join("");
          }

          return this.__setLocation(fixedValue, redirect);
        } catch {
          return;
        }
      }

      if (haveQuery) {
        const string = window.location.search || "";
        const projectIdValue = params[query];
        const startIdx = string.indexOf(query) + query.length + "=".length;
        const endIdx =
          string.indexOf(query) +
          query.length +
          "=".length +
          projectIdValue.length;

        const fixedValue = this.__replaceAt(startIdx, endIdx, newValue, string);

        return this.__setLocation(fixedValue, redirect);
      }

      if (!haveParams) {
        const fixedValue =
          window.location.search + "?" + `${query}=${newValue}`;
        return this.__setLocation(fixedValue, redirect);
      }

      if (moreThenOneParams) {
        const fixedValue = window.location.search + `&${query}=${newValue}`;
        return this.__setLocation(fixedValue, redirect);
      }
    },
    __replaceAt(start = 0, end = 0, replacement = "", string = "") {
      return string.substring(0, start) + replacement + string.substring(end);
    },
    __arrayOfNumbers(items) {
      return items.split(",").reduce((total, curr) => {
        if (total) {
          total = !isNaN(curr);
          return total;
        }
        return false;
      }, true);
    },
    __setLocation(value, redirect) {
      if (redirect) {
        return (window.location.search = value);
      }
      history.pushState(null, null, value);
    },
  },
};
