<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                solo
                class="align-end"
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :search="search"
                :loading="loading"
                :headers="headers"
                :items="items"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn icon @click="editField(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-btn fixed dark fab bottom right color="pink" @click="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title> Field </v-card-title>
        <v-card-text>
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-text-field
              v-model="form.title"
              label="Title"
              required
              solo
            ></v-text-field>
            <v-select
              solo
              label="Type"
              v-model="form.type"
              :items="types"
            ></v-select>
            <v-textarea
              v-model="form.json"
              label="Json"
              solo
              no-resize
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="primary"
            text
            @click="createField()"
            :disabled="dialog_loading"
            :loading="dialog_loading"
          >
            <span v-if="dialog_edit">Save changes</span>
            <span v-else>Create</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FieldManager",
  data() {
    return {
      search: "",
      dialog_edit: false,
      dialog: false,
      dialog_loading: false,
      loading: false,
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: "Type", align: "start", value: "type" },
        { text: "Title", align: "start", value: "title" },
        { text: "Json", align: "start", value: "json" },
        { text: "", align: "start", value: "actions" },
      ],
      items: [],
      form: {
        type: null,
        title: null,
        json: null,
      },
      types: [
        { text: "Text", value: "text" },
        { text: "Num", value: "num" },
        { text: "Textarea", value: "textarea" },
        { text: "Link", value: "link" },
        { text: "Editor", value: "editor" },
        { text: "Checklist", value: "checklist" },
        { text: "Checkbox", value: "checkbox" },
        { text: "Email", value: "email" },
        { text: "Price", value: "price" },
        { text: "Content Price", value: "content_price" },
        { text: "Select", value: "select" },
        { text: "Url Anchor", value: "url_anchor" },
        { text: "Url Anchor All", value: "url_anchor_all" },
        { text: "Url Anchor Simple", value: "url_anchor_simple" },
        {
          text: "Url Anchor Simple Extended",
          value: "url_anchor_simple_extended",
        },
        { text: "Useful Links", value: "useful_links" },
        { text: "Task Price", value: "task_price" },
        { text: "Cost Base", value: "cost_base" },
        { text: "Ah Top Pages", value: "ah_top_pages" },
        { text: "Friendly Themes", value: "friendly_themes" },
        { text: "Similar Articles", value: "similar_articles" },
        { text: "Fin Template", value: "fin_template" },
        { text: "Rating", value: "rating" },
        {
          text: "Date",
          value: "date",
        },
      ],
    };
  },
  methods: {
    editField(item) {
      this.form = item;
      this.dialog = true;
      this.dialog_edit = true;
    },
    fetchData() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/admin/get-fields`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.items = r.data.items;
        })
        .finally(() => (self.loading = false));
    },
    createField() {
      let self = this;

      self.dialog_loading = true;

      let url = `${self.$store.state.server_url}/gpm/admin/create-field`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            form: self.form,
            edit: self.dialog_edit,
          },
          config
        )
        .then(() => {
          self.fetchData();
        })
        .finally(() => {
          self.dialog_loading = false;
          self.dialog_edit = false;
          self.dialog = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
