export default {
  methods: {
    _$smartCustomTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          const string =
            isNaN(Number(a[sortBy[i]])) || isNaN(Number(b[sortBy[i]]));

          if (string) {
            let aVal, bVal;
            if (a[sortBy[i]] === null || a[sortBy[i]] === "") aVal = 0;
            if (b[sortBy[i]] === null || b[sortBy[i]] === "") bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(Number(a[sortBy[i]])) || isNaN(parseInt(a[sortBy[i]])))
            aVal = 0;
          if (isNaN(Number(b[sortBy[i]])) || isNaN(parseInt(b[sortBy[i]])))
            bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (Number(a[sortBy[i]]) > Number(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (Number(a[sortBy[i]]) < Number(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
};
