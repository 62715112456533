import Service from "@/plugins/service";

export default {
  mutations: {},
  actions: {
    async "contentPlaning/fetchTableData"(ctx, payload) {
      const url = '/dashboards/content/planning';

      const resp = await Service.post(url, payload, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
  },
  getters: {},
};
