<template>
  <v-tooltip
    v-if="data && data.executive && data.deadline"
    bottom
    content-class="pa-0"
  >
    <template #activator="{ on }">
      <v-chip
        :color="getColorData.rgba"
        :style="{
          color: getColorData.text,
        }"
        label
        v-on="on"
      >
        {{ data.deadline ? $moment(data.deadline).format("MMM DD") : "" }}
      </v-chip>
    </template>
    <v-card class="pa-2 styled-card--default" max-width="240">
      <v-row dense>
        <v-col cols="12" class="d-flex align-center">
          <v-avatar
            size="32"
            class="mr-2"
            :color="getColorData.rgba"
            :style="{ color: getColorData.text }"
          >
            <v-icon small>mdi-account</v-icon>
          </v-avatar>
          {{ data.executive }}
        </v-col>
        <v-col cols="12">
          <v-icon small class="ml-2 mr-5">mdi-calendar</v-icon
          >{{ data.deadline }}
        </v-col>
      </v-row>
    </v-card>
  </v-tooltip>
</template>

<script>
import { stringToAvatarData } from "@/functions";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getColorData() {
      if (!this.data) return "transparent";

      return stringToAvatarData(
        this.data?.executive,
        0.3,
        this.$vuetify.theme.dark,
        { contrastText: true }
      );
    },
  },
};
</script>
