<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col
          cols="2"
          class="d-flex flex-column justify-center"
          v-if="data.data[0]"
        >
          <div>
            <apexchart
              type="pie"
              width="280"
              :options="getPieOptions(0)"
              :series="data.data[0].series"
            />
          </div>
          <div class="text-center">
            {{ data.data[0].title }}
          </div>
        </v-col>
        <v-col v-else cols="2">
          <no-items-placeholder />
        </v-col>
        <v-col
          cols="2"
          class="d-flex flex-column justify-center"
          v-if="data.data[1]"
        >
          <div>
            <apexchart
              type="pie"
              width="280"
              :options="getPieOptions(1)"
              :series="data.data[1].series"
            />
          </div>
          <div class="text-center">
            {{ data.data[1].title }}
          </div>
        </v-col>
        <v-col v-else cols="2">
          <no-items-placeholder />
        </v-col>
        <v-col cols="8" v-if="data.data[2]">
          <apexchart
            type="line"
            height="380"
            :options="getChartOptions()"
            :series="data.data[2].series"
          />
        </v-col>
        <v-col v-else cols="8">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { deepClone } from "../../../utils/functions";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  methods: {
    getPieOptions(idx) {
      const options = {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          width: 280,
          type: "pie",
        },
        labels: this.data.data[idx].categories,
        legend: {
          show: false,
        },
        stroke: {
          width: 0,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      };

      if (this.data.data[0].colors) {
        options.colors = this.data.data[0].colors;
      }

      return options;
    },
    getChartOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          animations: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "12px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "datetime",
          categories: this.data.data[2].categories,
        },
        tooltip: {
          enabled: true,
          // eslint-disable-next-line no-unused-vars
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let seriesHtml = "";

            // console.log(deepClone(w.config));

            const sortedByNameUsers = deepClone(w.config.series).sort(
              (a, b) => {
                if (a.data[dataPointIndex] > b.data[dataPointIndex]) return -1;
                if (a.data[dataPointIndex] < b.data[dataPointIndex]) return 1;
                return 0;
              }
            );

            sortedByNameUsers
              .filter((v) => v.name !== "Daily KPI")
              .forEach((user) => {
                const currentData = user.data[dataPointIndex];

                if (String(currentData) === "0") return;

                seriesHtml += `<tr><td>${user.name}</td> <td style="font-weight: 600">${currentData}</td></tr>`;
              });

            try {
              seriesHtml += `<tr style="font-weight: 600;"><td style="border-top:1px solid rgba(155,155,155,0.3)">Daily KPI</td><td style="border-top:1px solid rgba(155,155,155,0.3)">${
                sortedByNameUsers.filter((v) => v.name === "Daily KPI")[0].data[
                  dataPointIndex
                ]
              }</td></tr>`;
            } catch {
              // console.error("Cannot contain Daily KPI ", e);
            }

            return `
<div class="main-4dx-dashboard__chart-tooltip">
<table>
<tbody>
${seriesHtml}
</tbody>
</table>
</div>
            `;
          },
        },
        stroke: {
          dashArray: 0,
          lineCap: "butt",
          curve: "straight",
        },
        legend: {
          position: "right",
          offsetY: 40,
          inverseOrder: true,
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>
