<template>
  <v-dialog
    v-model="dialog"
    transition="slide-y-transition"
    width="600"
    content-class="my-shadow--e4"
    scrollable
  >
    <v-card class="styled-card--default">
      <v-card-title>
        Fields history
        <v-spacer />
        <v-btn icon @click="fieldsHistory.dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4 d-flex flex-column" style="gap: 16px">
        <div class="d-flex" v-if="false">
          <v-spacer />
          <v-menu offset-y bottom content-class="my-shadow--e3">
            <template #activator="{ on }">
              <v-chip v-on="on" class="flat-chip flat-chip--primary">
                <v-icon small left>mdi-filter</v-icon>
                {{ currentSortingName || "Sort by" }}
                <v-icon small right>mdi-chevron-down</v-icon>
              </v-chip>
            </template>
            <v-card class="styled-card--light">
              <v-list dense>
                <v-list-item @click="setSorting('by_newest', 'newest')">
                  By newest
                </v-list-item>
                <v-list-item @click="setSorting('by_oldest', 'oldest')">
                  By oldest
                </v-list-item>
                <v-list-item @click="setSorting('by_default', null)">
                  Default
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <template v-if="loading">
          <div
            v-for="idx in [0, 1, 2, 3, 4, 5]"
            :key="idx"
            class="skeleton mb-4"
            style="height: 80px"
          />
        </template>
        <template v-else>
          <div v-if="!hasFieldsHistoryItems">
            <div
              style="min-height: 75px; opacity: 0.5"
              class="d-flex align-center flex-column justify-center"
            >
              <v-icon size="32">mdi-weather-windy</v-icon>
              <div>Nothing here...</div>
            </div>
          </div>
          <fields-history-card
            v-for="(card, idx) in getSortedItems"
            :key="card.id + '' + idx"
            :card="card"
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldsHistoryCard from "@/components/Gpm/ViewEntity/FieldsHistoryCard.vue";

export default {
  components: { FieldsHistoryCard },
  props: {
    modelValue: {},
    fieldsHistory: {},
    loading: Boolean,
  },
  emits: ["update:modelValue"],
  data: () => ({
    currentSorting: (items) => items,
    currentSortingName: null,
  }),
  computed: {
    hasFieldsHistoryItems() {
      return (
        this.fieldsHistory &&
        Array.isArray(this.fieldsHistory.items) &&
        this.fieldsHistory.items.length !== 0
      );
    },
    getSortedItems() {
      try {
        if (!this.fieldsHistory || !Array.isArray(this.fieldsHistory.items))
          return [];

        return this.currentSorting(this.fieldsHistory.items);
      } catch (e) {
        console.error("Error while getting sorted fields history items.", e);
        return [];
      }
    },
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    setSorting(type, name) {
      const sortings = {
        by_newest: this.sortByNewest,
        by_oldest: this.sortByOldest,
        by_default: this.sortByDefault,
      };

      this.currentSortingName = name;

      this.currentSorting =
        sortings[type] ||
        function (items) {
          return items;
        };
    },
    sortByNewest(items) {
      return items.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        return 0;
      });
    },
    sortByOldest(items) {
      return items.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
    },
    sortByDefault(items) {
      return items;
    },
  },
};
</script>
