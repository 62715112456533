<template>
  <div class="expanded-td">
    <div
      class="bg"
      :style="`background-color: ${getRgb(value, max, 50, 233, 153)};`"
    />
    <span style="z-index: 1">{{ value }}</span>
  </div>
</template>

<script>
import GetRgb from "@/mixins/GetRgb";

export default {
  props: ["value", "max"],
  mixins: [GetRgb],
};
</script>
