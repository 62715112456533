import { render, staticRenderFns } from "./ForceUpdateButton.vue?vue&type=template&id=728e2770&scoped=true&"
import script from "./ForceUpdateButton.vue?vue&type=script&lang=js&"
export * from "./ForceUpdateButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728e2770",
  null
  
)

export default component.exports