<template>
  <div class="ldd" style="position: relative">
    <v-row>
      <v-col cols="12">
        <h2>Links Dynamic Dashboard</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-4">
      <v-col cols="12">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title>
            Filters
            <v-btn
              v-if="loadings.filters"
              small
              class="ml-2"
              :loading="loadings.filters"
            ></v-btn>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="1">
                <v-autocomplete
                  v-model="gi"
                  label="GI"
                  disabled
                  :items="filtersData.gi"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  @change="handleFilterChange($event, FILTERS_QUERY_IDS.gi)"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-autocomplete
                  v-model="do_follow"
                  label="Do Follow"
                  :items="filtersData.dofollow"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  @change="
                    handleFilterChange($event, FILTERS_QUERY_IDS.do_follow)
                  "
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="link_type"
                  label="Links Type"
                  :items="filtersData.link_type"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  @change="
                    handleFilterChange($event, FILTERS_QUERY_IDS.link_type)
                  "
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="anchor_type"
                  label="Anchor Type"
                  :items="filtersData.anchor_type"
                  item-text="text"
                  item-value="value"
                  outlined
                  multiple
                  dense
                  clearable
                  :loading="loadings.filters"
                  @change="
                    handleFilterChange($event, FILTERS_QUERY_IDS.anchor_type)
                  "
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <range-filter
                  title="AH DR"
                  :model-value="ah_dr"
                  :max="100"
                  :presets="presets.ahDrPresets"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.ah_dr)
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH UR"
                  :model-value="ah_ur"
                  :max="100"
                  :presets="presets.ahDrPresets"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.ah_ur)
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH Traffic"
                  :model-value="ah_traffic"
                  :max="1000000"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.ah_traffic)
                  "
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="1">
                <v-autocomplete
                  v-model="title_thematic"
                  label="Title Thematic"
                  :items="['yes', 'no']"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  clearable
                  @change="
                    handleFilterChange($event, FILTERS_QUERY_IDS.title_thematic)
                  "
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-autocomplete
                  v-model="gt"
                  label="GT"
                  :items="[2, 1, 0]"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  clearable
                  @change="handleFilterChange($event, FILTERS_QUERY_IDS.gt)"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <range-filter
                  title="AH URL Traffic"
                  :model-value="ah_url_traffic"
                  :max="10000"
                  :presets="presets.ahUrlTrafficPresets"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.ah_url_traffic)
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH 2nd Level"
                  :model-value="ah_2nd_level"
                  :max="1000"
                  :presets="presets.ah2ndLevelPresets"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.ah_2nd_level)
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="SW Rank"
                  :model-value="sw_rank"
                  :max="3000000"
                  :presets="presets.swRankPresets"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.sw_rank)
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="Num of Pages"
                  :model-value="nums_of_pages"
                  :max="10000"
                  :presets="presets.numOfPagesPresets"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.nums_of_pages)
                  "
                />
              </v-col>
              <v-col>
                <range-filter
                  title="AH Tr RD"
                  :model-value="ah_tr_rd"
                  :max="10000"
                  :presets="presets.numOfPagesPresets"
                  @update:modelValue="
                    handleRangeChange($event, FILTERS_QUERY_IDS.ah_tr_rd)
                  "
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-autocomplete
                  v-model="url"
                  label="URL"
                  :items="filtersData.url"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  clearable
                  :loading="loadings.filters"
                  @change="handleFilterChange($event, FILTERS_QUERY_IDS.url)"
                  :menu-props="menuProps"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="filter_type"
                  hide-details
                  dense
                  outlined
                  label="Filter type"
                  placeholder="Select type"
                  item-value="value"
                  item-text="name"
                  :items="[
                    { name: 'all', value: 'all' },
                    { name: 'by Date filter', value: 'by_date_filter' },
                  ]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="link_type_hidden"
                  multiple
                  :items="filtersData.link_type"
                  dense
                  hide-details
                  small-chips
                  deletable-chips
                  outlined
                  label="Hidden Link Types"
                  placeholder="Type to search"
                  @change="
                    handleFilterChange(
                      $event,
                      FILTERS_QUERY_IDS.link_type_hidden
                    )
                  "
                >
                  <template #prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            link_type_hidden.length > 0
                              ? 'primary darken-4'
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template #selection="{ item, index }">
                    <span v-if="index === 0">{{ item.text }}</span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ link_type_hidden.length - 1 }} more)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <div class="fix-button-height">
                  <smart-date-filter
                    :model-value="date"
                    @update:modelValue="handleUpdateDateFilter"
                    :custom-presets="presets.customDatePresets"
                  />
                </div>
              </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <div class="fix-button-height" style="width: 100%">
                  <v-btn
                    block
                    color="primary"
                    @click="handleGetData"
                    :loading="loadings.table"
                  >
                    Get data
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                  v-model="report_type"
                  label="Report Type"
                  hide-details
                  dense
                  clearable
                  :menu-props="menuProps"
                  outlined
                  :items="['by url', 'by top urls']"
                  @change="_$handleFilterChange($event, 'report_type')"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="max_links"
                  label="Max links"
                  type="number"
                  hide-details
                  dense
                  outlined
                  :value="30"
                  @change="_$handleFilterChange($event, 'max_links')"
                ></v-text-field>
              </v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div style="opacity: 0.5" class="mt-2">
          <small>Select filters and press "get data".</small>
        </div>
      </v-col>
    </v-row>
    <template v-if="dataFetched">
      <v-row>
        <v-col v-if="cards[0].table && !loadings['MainTableSection']" cols="12">
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title class="d-flex">
              <div class="d-flex">
                <div>All Links</div>
                <v-text-field
                  v-model="tableSearchModel"
                  dense
                  hide-details
                  label="Search"
                  append-icon="mdi-magnify"
                  style="max-width: 250px"
                  class="ml-4"
                ></v-text-field>
              </div>
              <v-spacer />
              <div class="d-flex">
                <unload-data-button
                  :urls-amount="unloadUrlsAmount"
                  :loading="loadings.unload"
                  :success="successUnload"
                  :data="cards[0].table"
                  main-button-text="Get data from Ahrefs"
                  upload-button-text="Upload"
                  @update:urlsAmount="unloadUrlsAmount = $event"
                  @unload="handleUnloadData($event, 'upload')"
                />
                <div class="ml-2">
                  <unload-data-button
                    :urls-amount="unloadUrlsAmount"
                    :loading="loadings.unload"
                    :success="successUnload"
                    :data="cards[0].table"
                    main-button-text="Collect Parameters"
                    upload-button-text="Collect"
                    @update:urlsAmount="unloadUrlsAmount = $event"
                    @unload="handleUnloadData($event, 'collectParameters')"
                  />
                </div>
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="cards[0].table.headers"
                :items="cards[0].table.items"
                :items-per-page="20"
                :footer-props="{ itemsPerPageOptions: [20, 50, 100, -1] }"
                :custom-sort="customSort"
                :search="tableSearchModel"
                multi-sort
                class="card__table"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.url.value"
                      :class="{
                        current: item.url.value.includes(
                          getSelectedURLFilter.text
                        ),
                      }"
                    >
                      <td class="d-flex align-center">
                        <td-domain
                          :data="item.url"
                          :row="item"
                          :note="true"
                          :filters="getFiltersData()"
                          @note="showIndividualNote"
                          :active-url="getSelectedURLFilter"
                        />
                      </td>
                      <td
                        :style="`background-color: ${getRgb(
                          item.vision,
                          getMax(items, 'vision'),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item.vision }}
                      </td>
                      <td
                        :style="`background-color: ${getRgb(
                          item.ah_dr,
                          getMax(items, 'ah_dr'),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item.ah_dr }}
                      </td>
                      <td
                        :style="`background-color: ${getRgb(
                          item.ah_rd,
                          getMax(items, 'ah_rd'),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item.ah_rd }}
                      </td>
                      <td
                        :style="`background-color: ${getRgb(
                          item.ah_tr,
                          getMax(items, 'ah_re'),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item.ah_tr }}
                      </td>
                      <td>{{ item.wa }}</td>
                      <td
                        :style="`background-color: ${getRgb(
                          item.gi,
                          getMax(items, 'gi'),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item.gi }}
                      </td>
                      <td
                        :style="`background-color: ${getRgb(
                          item.total_all,
                          getMax(items, 'total_all'),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item.total_all }}
                      </td>
                      <td
                        :style="`background-color: ${getRgb(
                          item.total_period,
                          getMax(items, 'total_period'),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item.total_period }}
                      </td>
                      <td
                        v-for="(dateKey, i) in getDates(item)"
                        :key="dateKey + i"
                        :style="`background-color: ${getRgb(
                          item[dateKey],
                          getMax(items, dateKey),
                          50,
                          233,
                          153
                        )}`"
                      >
                        {{ item[dateKey] }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
        >
          All Links loading...
        </v-col>
        <v-col v-if="cards[0].chart" cols="12">
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Link building Dynamics </v-card-title>
            <v-card-text>
              <apexchart
                type="line"
                height="750"
                :options="chartOptions"
                :series="cards[0].chart.series"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="section-name">
          <h4># Referral Link Parameters</h4>
        </v-col>
        <v-col
          v-if="cards[0].urls_link_types && !loadings['UrlsLinkTypesSection']"
          cols="12"
          :id="SECTIONS_IDS.URLS_LINK_TYPES.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Urls * Link Types </v-card-title>
            <v-card-text>
              <links-dynamic-default-table
                :headers="cards[0].urls_link_types.headers"
                :items="cards[0].urls_link_types.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_LINK_TYPES.id"
        >
          Urls * link types loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].urls_anchor_types && !loadings['UrlsAnchorTypesSection']
          "
          cols="12"
          :id="SECTIONS_IDS.ULRS_ANCHOR_TYPES.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Urls * Anchor Types </v-card-title>
            <v-card-text>
              <!--Дефолтная таблица-->
              <links-dynamic-default-table
                :headers="cards[0].urls_anchor_types.headers"
                :items="cards[0].urls_anchor_types.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.ULRS_ANCHOR_TYPES.id"
        >
          Urls * anchor types loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].urls_summary_of_the_page &&
            !loadings['SummaryOfThePageSection']
          "
          cols="12"
          :id="SECTIONS_IDS.URLS_SUM_OF_T_PAGE.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> URLs Summary </v-card-title>
            <v-card-text>
              <custom-simple-table
                :items-per-page="20"
                :data="cards[0].urls_summary_of_the_page"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_SUM_OF_T_PAGE.id"
        >
          Urls summary loading...
        </v-col>
        <!------->
        <v-col
          v-if="cards[0].urls_ur && !loadings['UrlsUrSection']"
          cols="12"
          :id="SECTIONS_IDS.URLS_UR.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Urls * UR </v-card-title>
            <v-card-text>
              <!--Дефолтная таблица-->
              <links-dynamic-default-table
                :headers="cards[0].urls_ur.headers"
                :items="cards[0].urls_ur.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
                filter-name="ah_ur"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_UR.id"
        >
          Urls * UR loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].urls_ah_2nd_level && !loadings['UrlsAh2ndLevelSection']
          "
          cols="12"
          :id="SECTIONS_IDS.URLS_AH_2ND_LEVEL.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> URLs * AH 2nd Level </v-card-title>
            <v-card-text>
              <!--Дефолтная таблица-->
              <links-dynamic-default-table
                :headers="cards[0].urls_ah_2nd_level.headers"
                :items="cards[0].urls_ah_2nd_level.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
                filter-name="ah_2nd_level"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_AH_2ND_LEVEL.id"
        >
          Urls * AH 2nd level loading...
        </v-col>
        <!------->
        <v-col
          v-if="cards[0].urls_ah_traffic && !loadings['UrlsAhTrafficSection']"
          cols="12"
          :id="SECTIONS_IDS.URLS_AH_URL_TRAFFIC.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> URLs * AH URL Traffic </v-card-title>
            <v-card-text>
              <!--Дефолтная таблица-->
              <links-dynamic-default-table
                :headers="cards[0].urls_ah_traffic.headers"
                :items="cards[0].urls_ah_traffic.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
                filter-name="ah_url_traffic"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_AH_URL_TRAFFIC.id"
          >Urls * AH Url * Traffic loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].urls_anchor_links_types &&
            !loadings['UrlsAnchorLinksTypesSection']
          "
          cols="12"
          :id="SECTIONS_IDS.URLS_ANCHOR_TYPES_LINK_TYPES.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Urls * Anchor Types * Link Types </v-card-title>
            <v-card-text>
              <custom-simple-table
                :items-per-page="20"
                :data="cards[0].urls_anchor_links_types"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_ANCHOR_TYPES_LINK_TYPES.id"
        >
          Urls * anchor types * links types loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].urls_link_types_ur && !loadings['UrlsLinkTypesUrSection']
          "
          cols="12"
          :id="SECTIONS_IDS.URLS_LINK_TYPES_UR.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Urls * Link Types * UR </v-card-title>
            <v-card-text>
              <custom-simple-table
                :items-per-page="20"
                :data="cards[0].urls_link_types_ur"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_LINK_TYPES_UR.id"
        >
          Urls * link types * UR loading...
        </v-col>
        <!------->
        <v-col cols="12" class="section-name">
          <h4># Referral Domain Parameters</h4>
        </v-col>
        <v-col
          v-if="cards[0].urls_dr && !loadings['UrlsDrSection']"
          cols="12"
          :id="SECTIONS_IDS.URLS_DR.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Urls * DR </v-card-title>
            <v-card-text>
              <!--Дефолтная таблица-->
              <links-dynamic-default-table
                :headers="cards[0].urls_dr.headers"
                :items="cards[0].urls_dr.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
                filter-name="ah_dr"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_DR.id"
        >
          Urls DR loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].urls_link_types_dr && !loadings['UrlsLinkTypesDrSection']
          "
          cols="12"
          :id="SECTIONS_IDS.URLS_LINK_TYLES_DR.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Urls * Link Types * DR </v-card-title>
            <v-card-text>
              <custom-simple-table
                :items-per-page="20"
                :data="cards[0].urls_link_types_dr"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.URLS_LINK_TYLES_DR.id"
        >
          Urls * link types * DR loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].domains_common_trust_ref_domains &&
            !loadings['DomainsCommonTrustRefDomainsSection']
          "
          cols="12"
          :id="SECTIONS_IDS.GT_SW_RANK_DIST.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> GT & SW Rank distribution </v-card-title>
            <v-card-text>
              <custom-simple-table
                :items-per-page="20"
                :data="cards[0].domains_common_trust_ref_domains"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.GT_SW_RANK_DIST.id"
        >
          GT & SW Rank distribution loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].domains_ah_traffic_ref_domains &&
            !loadings['DomainsAhTrafficRefDomainsSection']
          "
          cols="12"
          :id="SECTIONS_IDS.AH_TRAFF_F_REF_DMNS.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> AH Traffic for Ref Domains </v-card-title>
            <v-card-text>
              <links-dynamic-default-table
                :headers="cards[0].domains_ah_traffic_ref_domains.headers"
                :items="cards[0].domains_ah_traffic_ref_domains.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.AH_TRAFF_F_REF_DMNS.id"
        >
          AH Traffic for Ref Domains loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].top_sw_category_for_url &&
            !loadings['TopSwCategoryForUrlSection']
          "
          cols="12"
          :id="SECTIONS_IDS.TOP_SW_CAT_F_CURR_URLS.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Top SW Category </v-card-title>
            <v-card-text>
              <custom-vertical-headers-table
                :headers="cards[0].top_sw_category_for_url.headers"
                :items="cards[0].top_sw_category_for_url.items"
                :custom-sort="customSort"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.TOP_SW_CAT_F_CURR_URLS.id"
        >
          Top SW Category loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].sw_cateogory_for_niche &&
            !loadings['SwCategoryForNicheSection']
          "
          cols="12"
          :id="SECTIONS_IDS.SW_CAT_F_CURR_NICHE.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> SW Category for current Niche </v-card-title>
            <v-card-text>
              <custom-vertical-headers-table
                :headers="cards[0].sw_cateogory_for_niche.headers"
                :items="cards[0].sw_cateogory_for_niche.items"
                :custom-sort="customSort"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.SW_CAT_F_CURR_NICHE.id"
        >
          SW Category for current Niche loading...
        </v-col>
        <!------->
        <v-col
          cols="12"
          v-if="
            cards[0].tr_theme_for_niche && !loadings['TrThemeForNicheSection']
          "
          :id="SECTIONS_IDS.TR_THEME_F_NICHE.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> TR Theme for current Niche </v-card-title>
            <v-card-text>
              <links-dynamic-default-table
                :headers="cards[0].tr_theme_for_niche.headers"
                :items="cards[0].tr_theme_for_niche.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.TR_THEME_F_NICHE.id"
        >
          TR Theme for current Niche loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].domains_distribution_percentage_traffic_top_country &&
            !loadings['DomainsDistributionPercentageTrafficByTopCountrySection']
          "
          cols="12"
          :id="SECTIONS_IDS.DIST_OF_T_PERC_TRAFF_B_T_COUNTRY_SW_PRO.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title>
              Distribution of the percentage traffic by Top Country - SW Pro
            </v-card-title>
            <v-card-text>
              <custom-simple-table
                :items-per-page="20"
                :data="
                  cards[0].domains_distribution_percentage_traffic_top_country
                "
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.DIST_OF_T_PERC_TRAFF_B_T_COUNTRY_SW_PRO.id"
        >
          Distribution of the percentage traffic by Top Country - SW Pro
          loading...
        </v-col>
        <!------->
        <v-col
          v-if="
            cards[0].domains_top_countries_distribution &&
            !loadings['DomainsTopCountriesDistributionSection']
          "
          cols="12"
          :id="SECTIONS_IDS.TOP_CNTRY_DISTRIBUTION.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> TOP Country Distribution - SW Pro </v-card-title>
            <v-card-text>
              <custom-simple-table
                :items-per-page="20"
                :data="cards[0].domains_top_countries_distribution"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.TOP_CNTRY_DISTRIBUTION.id"
        >
          TOP Country Distribution - SW Pro loading...
        </v-col>
        <!------->
        <v-col
          cols="12"
          v-if="
            cards[0].main_lang_for_domains &&
            !loadings['MainLanguagesForDomainsSection']
          "
          :id="SECTIONS_IDS.MAIN_LANG_FOR_DOMAINS.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Language of Main Page for Domains </v-card-title>
            <v-card-text>
              <links-dynamic-default-table
                :headers="cards[0].main_lang_for_domains.headers"
                :items="cards[0].main_lang_for_domains.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.MAIN_LANG_FOR_DOMAINS.id"
        >
          Language of Main Page for Domains loading...
        </v-col>
        <!------->
        <v-col
          cols="12"
          v-if="
            cards[0].num_of_pages_by_gi_rd &&
            !loadings['NumOfPagesByGiForRefDomainsSection']
          "
          :id="SECTIONS_IDS.NUM_OF_PAGES_BY_GI_RD.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> Num of pages by GI for Ref Domains </v-card-title>
            <v-card-text>
              <links-dynamic-default-table
                :headers="cards[0].num_of_pages_by_gi_rd.headers"
                :items="cards[0].num_of_pages_by_gi_rd.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.NUM_OF_PAGES_BY_GI_RD.id"
        >
          Num of pages by GI for Ref Domains loading...
        </v-col>
        <!------->
        <v-col
          cols="12"
          v-if="cards[0].wa_for_rd && !loadings['WebArchiveRefDomainsSection']"
          :id="SECTIONS_IDS.WA_FOR_RD.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title> WebArchive for Ref Domains </v-card-title>
            <v-card-text>
              <links-dynamic-default-table
                :headers="cards[0].wa_for_rd.headers"
                :items="cards[0].wa_for_rd.items"
                :custom-sort="customSortDeeper"
                :active-url="getSelectedURLFilter"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.WA_FOR_RD.id"
        >
          WebArchive for Ref Domains loading...
        </v-col>
        <!------->
        <v-col
          cols="12"
          v-if="
            cards[0] &&
            cards[0].by_page_type_url &&
            !loadings[SECTIONS_IDS.BY_PAGE_URL.id]
          "
          :id="SECTIONS_IDS.BY_PAGE_URL.id"
        >
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title>
              {{ SECTIONS_IDS.BY_PAGE_URL.name }}
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="cards[0].by_page_type_url.headers"
                :items="cards[0].by_page_type_url.items"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="links-dynamic-dashboard-table-placeholder"
          :id="SECTIONS_IDS.BY_PAGE_URL.id"
        >
          {{ SECTIONS_IDS.BY_PAGE_URL.name }} loading...
        </v-col>
        <!------->
      </v-row>
    </template>
    <v-menu left>
      <template #activator="{ on }">
        <v-btn color="primary" fab fixed right bottom v-model="fab" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list nav dense>
        <v-list-item>
          <fast-travel />
        </v-list-item>
        <v-list-item>
          <v-btn
            outlined
            color="primary"
            block
            @click="allNotesDialogModel = !allNotesDialogModel"
          >
            All notes <v-icon class="ml-2">mdi-note-outline</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            @click="notesDialogModel = !notesDialogModel"
            color="primary"
            :disabled="!url"
            outlined
            block
          >
            Notes
            <v-icon class="ml-2">mdi-note-outline</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn text block @click="scrollToTop">
            To top
            <v-icon class="ml-2">mdi-arrow-up</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <all-notes-dialog
      :model-value="allNotesDialogModel"
      @update:modelValue="allNotesDialogModel = $event"
    />
    <notes-dialog
      :model-value="notesDialogModel"
      :url="url"
      @update:modelValue="notesDialogModel = $event"
      @save="handleSaveNotes"
    />
    <notes-dialog
      :model-value="individualUrlNoteDialogModel"
      :url="url"
      :acceptor="acceptor"
      @update:modelValue="individualUrlNoteDialogModel = $event"
      @save="handleSaveNotes"
    />
  </div>
</template>

<script>
import Location from "@/mixins/Location";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import {
  DEFAULT_MENU_PROPS,
  LINK_DYNAMIC_DASHBOARD_SECTIONS_IDS,
  LINKS_DYNAMIC_CHART_OPTIONS,
  LINKS_DYNAMIC_DASHBOARD_PRESETS,
  LINKS_DYNAMIC_DASHBOARD_SECTION_NAMES,
  SIMPLE_TABLE_MOCK,
} from "@/utils/defaultData";
import RangeFilter from "@/components/PositionsDashboard/Header/RangeFilter";
import VueApexCharts from "vue-apexcharts";
import CustomSimpleTable from "@/components/OnePageAnalyzer/LinksDynamicDashboard/CustomSimpleTable";
import eventPipe from "@/events/eventPipe";
import LinksDynamicDefaultTable from "@/components/OnePageAnalyzer/LinksDynamicDashboard/LinksDynamicDefaultTable";
import TdDomain from "@/components/OnePageAnalyzer/LinksDynamicDashboard/td/TdDomain";
import Project from "@/mixins/Project";
import CustomVerticalHeadersTable from "@/components/OnePageAnalyzer/LinksDynamicDashboard/CustomVerticalHeadersTable";
import FastTravel from "@/components/OnePageAnalyzer/LinksDynamicDashboard/FastTravel";
import NotesDialog from "@/components/OnePageAnalyzer/LinksDynamicDashboard/NotesDialog";
import GetRgb from "@/mixins/GetRgb";
import Notification from "@/mixins/Notification";
import UnloadDataButton from "@/components/OnePageAnalyzer/LinksDynamicDashboard/UnloadDataButton";
import AllNotesDialog from "@/components/OnePageAnalyzer/LinksDynamicDashboard/AllNotesDialog";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";

export default {
  components: {
    SmartDateFilter,
    AllNotesDialog,
    UnloadDataButton,
    NotesDialog,
    FastTravel,
    CustomVerticalHeadersTable,
    TdDomain,
    LinksDynamicDefaultTable,
    CustomSimpleTable,
    RangeFilter,
    // eslint-disable-next-line vue/no-unused-components
    apexchart: VueApexCharts,
  },
  mixins: [Location, FiltersHandling, Project, GetRgb, Notification],
  async created() {
    eventPipe.$on("update-active-project", () => {
      const project_id = this.$store.getters.active_project;
      this._$handleFilterChange(project_id, this.FILTERS_QUERY_IDS.project_id);
      this.fetchFilters();
    });

    this.filter_type = "all";

    const queryProjectId = this.$route.query[this.FILTERS_QUERY_IDS.project_id];
    if (queryProjectId) {
      eventPipe.$emit("replace-project", queryProjectId);
    }

    this.initSelectedProject();

    this.fetchFilters();

    const { haveParams } = this.collectParamsFromQuery();

    if (haveParams) {
      return this.fetchData();
    }
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "One Page Analyzer",
        disabled: true,
      },
      {
        text: "Links Dynamic Dashboard",
        disabled: true,
      },
    ],
    dataFetched: false,
    allNotesDialogModel: false,
    sectionNames: LINKS_DYNAMIC_DASHBOARD_SECTION_NAMES,
    successUnload: false,
    unloadUrlsAmount: 15,
    fab: false,
    tableSearchModel: "",
    acceptor: null,
    individualUrl: "",
    notesDialogModel: false,
    individualUrlNoteDialogModel: false,
    defaultFooterProps: { "items-per-page-options": [15, 30, -1] },
    urlFilter: null,
    content: "",
    project_id: null, // filter
    link_type_hidden: [94, 93, 95], // filter
    gi: [], // filter
    link_type: [], // filter
    anchor_type: [], // filter
    do_follow: [], // filter
    ah_dr: [0, 0], // filter
    ah_traffic: [0, 0], // filter
    title_thematic: null, // filter
    gt: null, // filter
    ah_url_traffic: [0, 0], // filter
    ah_2nd_level: [0, 0], // filter
    sw_rank: [0, 0], // filter
    nums_of_pages: [0, 0], // filter
    ah_tr_rd: [0, 0], // filter
    ah_ur: [0, 0], // filter
    date: [], // filter
    url: null, // filter
    report_type: null, // filter
    max_links: 30, // filter
    filter_type: "", // filter
    SECTIONS_IDS: LINK_DYNAMIC_DASHBOARD_SECTIONS_IDS,
    FILTERS_QUERY_IDS: {
      gi: "gi",
      gt: "gt",
      url: "url",
      link_type: "link_type",
      link_type_hidden: "link_type_hidden",
      project_id: "project_id",
      anchor_type: "anchor_type",
      do_follow: "do_follow",
      ah_dr: "ah_dr",
      ah_ur: "ah_ur",
      ah_traffic: "ah_traffic",
      date: "date",
      title_thematic: "title_thematic",
      ah_url_traffic: "ah_url_traffic",
      ah_2nd_level: "ah_2nd_level",
      sw_rank: "sw_rank",
      nums_of_pages: "nums_of_pages",
      ah_tr_rd: "ah_tr_rd",
      filter_type: "filter_type",
      max_links: "max_links",
      report_type: "report_type",
    },
    filtersData: {
      url: [],
      link_type: [],
      gi: [],
      anchor_type: [],
      dofollow: [],
    },
    presets: LINKS_DYNAMIC_DASHBOARD_PRESETS,
    loadings: {
      filters: false,
      unload: false,
      table: false,
    },
    menuProps: DEFAULT_MENU_PROPS,
    cards: [{}],
    selectedProject: null,
    chartOptions: {},
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    firstEnter: false,
    simpleTableMock: SIMPLE_TABLE_MOCK,
  }),
  watch: {
    report_type(val) {
      this.$store.commit("setLinkDynamicDashbaord/report_type", val);
    },
    url(val) {
      this.urlFilter = val;
    },
  },
  computed: {
    icon() {
      if (this.link_type_hidden.length === this.filtersData.link_type.length)
        return "mdi-close-box";
      if (
        this.link_type_hidden.length > 0 &&
        this.link_type_hidden.length < this.filtersData.link_type.length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getDefaultDate() {
      const start = this.$moment(new Date())
        .subtract(200, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).format("YYYY-MM-DD");
      return [start, end];
    },
    getSelectedURLFilter() {
      let result = this.filtersData.url.find((item) => item.value === this.url);
      if (!result) {
        result = {
          text: "",
          value: 0,
        };
        this.$message.notification({
          title: "Warning",
          text: "Select URL.",
          type: "warning",
          duration: 6000,
        });
      }
      return result;
    },
  },
  methods: {
    toggle() {
      if (this.link_type_hidden.length === this.filtersData.link_type.length) {
        this.link_type_hidden = [];
      } else {
        this.link_type_hidden = this.filtersData.link_type.slice();
      }
    },
    async handleUnloadData(date, type) {
      if (this.unloadUrlsAmount === 0) {
        this.mWarning({
          title: "Unload failure",
          text: "The number of uploaded links must be greater than zero.",
        });
        return;
      }

      this.loadings.unload = true;

      let links = this.cards[0].table.items.slice(0, this.unloadUrlsAmount);

      links = links.map((item) => item.url.value);

      links = links.filter((v, i, a) => a.indexOf(v) === i);

      const selectedLink = this.filtersData.url.find(
        (item) => item.value === this.url
      );

      if (selectedLink !== undefined && !links.includes(selectedLink.text)) {
        links = [...links, selectedLink.text];
      }

      const payload = {
        link_id: this.url,
        links,
        project_id: this.$store.getters.active_project,
      };

      const dispatchPath =
        type === "upload"
          ? "links-dynamic-dashboard/unload"
          : "links-dynamic-dashboard/collectParameters";

      const resp = await this.$store.dispatch(dispatchPath, payload);

      if (resp) {
        this.successUnload = true;
        setTimeout(() => (this.successUnload = false), 3500);
      }

      this.loadings.unload = false;
    },
    showIndividualNote(urlData) {
      this.individualUrl = urlData.value;
      this.acceptor = urlData.id;
      this.individualUrlNoteDialogModel = true;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleSaveNotes(content) {
      this.savePost(content);
    },
    savePost(content) {
      console.log("publish", content);
    },
    getMax(items, key) {
      let bigger = 0;

      items.forEach((item) => {
        const newValue = parseInt(item[key]);
        if (newValue > bigger) {
          bigger = newValue;
        }
      });

      return bigger;
    },
    getDates(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (!isNaN(key[0])) {
          output.push(key);
        }
      });

      return output;
    },
    initSelectedProject() {
      this.selectedProject = this.$store.state.project.allowed_projects;
    },
    customSortDeeper(items, sortBy, sortDesc) {
      const text = ["url"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i].toLowerCase())) {
            let aVal, bVal;
            if (a[sortBy[i]] === null) aVal = 0;
            if (b[sortBy[i]] === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]];
            const val2 = b[sortBy[i]];
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let aVal, bVal;
          if (!a[sortBy[i]].value && a[sortBy[i]].value !== 0) aVal = 0;
          if (!b[sortBy[i]].value && b[sortBy[i]].value !== 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]].value) > parseInt(b[sortBy[i]].value))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]].value) < parseInt(b[sortBy[i]].value))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    customSort(items, sortBy, sortDesc) {
      const text = ["url"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i])) {
            let aVal, bVal;
            if (a[sortBy[i]] === null) aVal = 0;
            if (b[sortBy[i]] === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]];
            const val2 = b[sortBy[i]];
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let aVal, bVal;
          if (!a[sortBy[i]] && a[sortBy[i]] !== 0) aVal = 0;
          if (!b[sortBy[i]] && b[sortBy[i]] !== 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["url", "link_type", "gi", "anchor_type", "dofollow"];
      const payload = {
        type: "/one-page-analyzer/links-dynamic/main",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    collectParamsFromQuery() {
      const keys = Object.keys(this.$route.query);
      const { date, url, project_id } = this.FILTERS_QUERY_IDS;
      const dates = [date];
      const urls = [url, project_id];
      keys.forEach((key) => {
        const value = this.$route.query[key];

        if (dates.includes(key)) {
          const newValue = value.split(",");
          return (this[key] = newValue);
        }
        if (urls.includes(key)) {
          return (this[key] = Number(value));
        }

        const newValue = value.split(",").map((item) => Number(item));
        return (this[key] = newValue);
      });

      if (keys.length > 0) {
        return {
          haveParams: true,
          haveDate: keys.includes(this.FILTERS_QUERY_IDS.date),
        };
      }
      return false;
    },
    handleFilterChange(event, type) {
      this._$handleFilterChange(event, type);
    },
    handleRangeChange(event, type) {
      this._$handleRangeChange(event, type);
    },
    handleUpdateDateFilter(event) {
      this.date = event;
      if (!event.length) {
        event = null;
      }
      this.handleFilterChange(event, this.FILTERS_QUERY_IDS.date);
    },
    getFiltersData() {
      const output = {};
      const filterKeys = Object.keys(this.FILTERS_QUERY_IDS);
      filterKeys.forEach((key) => {
        output[key] = this[key];
      });
      return output;
    },
    validate() {
      let errors = 0;

      if (!this.$store.getters.active_project) {
        this.mWarning({
          title: "Validation",
          text: "Project are required filters.",
        });
        errors++;
      }

      if (this.report_type === null && !this.url) {
        this.mWarning({
          title: "Validation",
          text: "Url are required filters.",
        });
        errors++;
      }

      return errors > 0;
    },
    async fetchSection(sectionName) {
      this.firstEnter = true;
      this.loadings.table = true;
      this.loadings[sectionName] = true;

      const payload = {
        section: sectionName,
        filter: {
          ...this.getFiltersData(),
          project_id: this.project_id || this.$store.getters.active_project,
        },
      };

      const resp = await this.$store.dispatch(
        "links-dynamic-dashboard/fetchData",
        payload
      );

      if (resp) {
        Object.keys(resp.items).forEach((key) => {
          if (key === "chart") {
            return (this.chartOptions = {
              ...LINKS_DYNAMIC_CHART_OPTIONS,
              xaxis: {
                categories: resp.items.chart.categories,
              },
            });
          }

          if (key === "table") {
            return (this.cards[0].table = resp.items.table);
          }

          this.cards[0][key] = resp.items[key];
        });
      }

      this.$forceUpdate();

      setTimeout(() => {
        this.loadings.table = false;
        this.loadings[sectionName] = false;
      }, 500);
    },
    async fetchData() {
      if (this.validate()) return;

      this.dataFetched = true;
      this.sectionNames.forEach((name) => {
        this.fetchSection(name);
      });
    },
    handleGetData() {
      this.fetchData();
    },
  },
};
</script>
