<template>
  <div>
    <template v-if="isDataValid">
      <v-data-table
        :headers="data.headers"
        :items="data.items"
        class="need-top-table"
        :items-per-page="-1"
        dense
        hide-default-footer
      >
        <template v-for="h in data.headers" #[`header.${h.value}`]>
          <div style="white-space: nowrap" :key="h.value">
            <template v-if="h.title">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ h.text }}
                  </span>
                </template>
                <div style="max-width: 150px">{{ h.title }}</div>
              </v-tooltip>
            </template>
            <template v-else>
              <span>{{ h.text }}</span>
            </template>
          </div>
        </template>

        <template #body="{ items }">
          <tbody>
            <tr
              v-for="(row, index) in items"
              :key="index"
              :class="{
                'divider-border': row.name.divider === 2,
                divider: row.name.divider === 1,
              }"
            >
              <template v-for="header in data.headers">
                <td
                  v-if="header.value === 'name'"
                  :key="header.value"
                  :class="{ bold: row[header.value]?.text.includes(',%') }"
                >
                  {{ row.name.text }}
                </td>
                <td v-else-if="header.value === '_dynamic'" :key="header.value">
                  <v-sparkline
                    v-if="!everyZero(row[header.value])"
                    :value="row[header.value]"
                    width="145"
                    height="45"
                    :color="getColor(row[header.value])"
                    smooth
                    auto-draw
                  ></v-sparkline>
                </td>
                <td
                  v-else-if="header.value === 'delta_1_weeks'"
                  :key="header.value"
                >
                  <delta-weeks-value :value="row[header.value]" />
                </td>

                <td v-else-if="header.value === 'status'" :key="header.value">
                  <uo-td-status :value="row[header.value]" :data="row" />
                </td>
                <td v-else-if="header.value === 'sw_sc'" :key="header.value">
                  <uo-td-sw-sc :value="row[header.value]" :data="row" />
                </td>
                <td v-else-if="header.value === 't_sc'" :key="header.value">
                  <uo-td-t-sc :value="row[header.value]" :data="row" />
                </td>
                <td v-else :key="header.value">
                  {{
                    String(row[header.value]) === "0" ? "" : row[header.value]
                  }}
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      <div class="pa-3 warning--text">Data format is invalid</div>
    </template>
  </div>
</template>

<script>
import UoTdStatus from "../../Dashboards/UrlsOverview/Td/UoTdStatus.vue";
import UoTdSwSc from "../../Dashboards/UrlsOverview/Td/UoTdSwSc.vue";
import UoTdTSc from "../../Dashboards/UrlsOverview/Td/UoTdTSc.vue";
import DeltaWeeksValue from "./DeltaWeeksValue.vue";

export default {
  components: { DeltaWeeksValue, UoTdTSc, UoTdSwSc, UoTdStatus },

  props: ["data"],

  computed: {
    isDataValid() {
      return this.data?.items?.length && this.data?.headers?.length;
    },
  },

  methods: {
    everyZero(data) {
      const equalZero = (el) => el === 0;

      if (data.every(equalZero)) {
        return true;
      }
      return false;
    },
    getColor(data) {
      if (data.length === 0) {
        return "black";
      }
      if (data[0] < data[data.length - 1]) {
        return "green";
      }
      return "red";
    },
  },
};
</script>
