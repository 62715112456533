<template>
  <div
    :class="`dashboard-dev ${is_dark_mode ? 'dark-mode' : ''}`"
    style="padding-bottom: 200px"
  >
    <!-- === Section === -->

    <section class="sections__page-title sections__item">
      <v-row dense>
        <v-col cols="12">
          <div class="d-flex">
            <h2>
              <v-btn
                icon
                @click="handleRefreshSections"
                :loading="loadings.default || loadings.monitorActions"
                class="mr-2"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              Dev Dashboard
            </h2>
            <v-spacer />
            <div class="d-flex align-center mr-2">
              <a href="/dashboard-dev/detailed" target="_blank">
                <v-chip outlined @click="() => {}" color="primary">
                  Detailed
                  <v-icon right small>mdi-open-in-new</v-icon>
                </v-chip>
              </a>
            </div>
            <v-btn-toggle
              v-model="only_problem_data"
              @change="handleChangeMode"
            >
              <v-btn
                value="all"
                :class="`btn-toggle ${
                  only_problem_data === 'all' ? 'success--text' : ''
                }`"
                width="100"
                outlined
              >
                All
              </v-btn>
              <v-btn
                value="importantly"
                :class="`btn-toggle ${
                  only_problem_data === 'importantly' ? 'info--text' : ''
                }`"
                width="100"
                outlined
              >
                Importantly
              </v-btn>
              <v-btn
                value="problems"
                :class="`btn-toggle ${
                  only_problem_data === 'problems' ? 'error--text' : ''
                }`"
                width="100"
                outlined
              >
                Problems
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </section>

    <!-- === Section === -->

    <v-col cols="12">
      <template v-if="!loadings.monitorActions">
        <template v-if="monitorActionsData.items.length !== 0">
          <v-card class="shadow-e1-bordered" rounded="lg">
            <v-card-title class="d-flex">
              Actions
              <v-spacer />
              <v-text-field
                v-model="searchActions"
                label="Search"
                hide-details
                dense
                append-icon="mdi-magnify"
                style="max-width: 260px"
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="monitorActionsData.headers"
                :items="monitorActionsData.items"
                :search="searchActions"
                multi-sort
                :sort-by="['priority']"
                :sort-desc="[true]"
                :items-per-page="-1"
                :custom-sort="_$smartCustomTableSort"
              >
                <template #[`item._status`]="{ item }">
                  <actions-table-icon
                    :data="item"
                    @click="handleShowStatus(item)"
                    :loading="loadings.actionsStatus.includes(item.id)"
                  />
                </template>
                <template #[`item.snooze_until`]="{ item }">
                  <v-menu
                    :close-on-content-click="false"
                    left
                    content-class="shadow-e2-bordered"
                    max-width="220px"
                  >
                    <template #activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="pa-2">
                      <v-row dense>
                        <v-col cols="12">
                          <my-calendar
                            :model-value="item.snooze_until"
                            @update:modelValue="item.snooze_until = $event"
                            :outlined="true"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            block
                            color="primary"
                            style="height: 40px"
                            :loading="loadings.snoozeUntil.includes(item.id)"
                            @click="handleSnoozeUntil(item)"
                          >
                            Save
                            <v-icon right>mdi-content-save-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <div class="dashboard-dev__table-placeholder">
            <section-name> <b>Actions</b> section is Empty</section-name>
          </div>
        </template>
      </template>
      <template v-else>
        <section-name :theme="is_dark_mode"> Actions </section-name>
        <v-skeleton-loader type="table-row-divider@9" />
      </template>
    </v-col>

    <!-- === Section === -->

    <section class="sections__presets sections__item">
      <v-row dense>
        <v-col cols="12">
          <section-name :theme="$vuetify.theme.dark"> Presets </section-name>
          <v-row v-if="!loadings.default">
            <v-col cols="12">
              <template v-if="presetsTableData.items.length > 0">
                <v-data-table
                  :search="presetsTableSearch"
                  :headers="presetsTableData.headers"
                  :items="presetsTableData.items"
                  class="dashboard-dev__presets-table"
                >
                  <template v-slot:top>
                    <v-text-field
                      v-model="presetsTableSearch"
                      label="Search by Name"
                      prepend-icon="mdi-magnify"
                      class="mx-4"
                    ></v-text-field>
                  </template>
                  <template #[`header.chart`]>
                    {{ get_current_date }}
                  </template>
                  <template #[`item.value`]="{ value }">
                    {{ value | localeNumber }}
                  </template>
                  <template #[`item.chart`]="{ value: { series } }">
                    <easy-line-chart :data="series" />
                  </template>
                </v-data-table>
              </template>
              <template v-else>
                <div class="dashboard-dev__table-placeholder">
                  <section-name :theme="$vuetify.theme.dark">
                    <b>History</b> section is Empty
                  </section-name>
                </div>
              </template>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col lg="6" md="12" sm="12" xs="12" cols="12">
              <v-skeleton-loader type="heading" />
              <v-skeleton-loader type="table-row-divider@6" />
            </v-col>
            <v-col lg="6" md="12" sm="12" xs="12" cols="12">
              <v-skeleton-loader type="heading" />
              <v-skeleton-loader type="table-row-divider@6" />
            </v-col> </v-row
        ></v-col>
      </v-row>
    </section>

    <!-- === Section === -->

    <!--    <template v-if="false">-->
    <!--      <chart-and-cards-skeleton />-->
    <!--    </template>-->
    <!--    <template v-else>-->
    <!--      <cards-and-graphs-section :items="xSections" />-->
    <!--    </template>-->

    <!-- === Section === -->

    <template v-if="loadings.jsonScoreSections">
      <chart-and-cards-skeleton />
    </template>
    <template v-else>
      <json-score-section :items="jsonScoreSections" />
    </template>
    <v-dialog
      v-model="aparserInfoDialog"
      content-class="remove-dialog-shadow"
      max-width="1250"
    >
      <v-card rounded="lg" :loading="loadings.aparserInfo">
        <v-card-title>
          <span
            :class="`${
              aparserInfoData.status ? 'green--text' : 'red--text'
            } mr-2`"
          >
            ({{ aparserInfoData.name }})
          </span>
          details
        </v-card-title>
        <v-card-text>
          <template v-if="aparserInfoData.items.length > 0">
            <v-data-table
              :headers="aparserInfoData.headers"
              :items="aparserInfoData.items"
              :items-per-page="-1"
              hide-default-footer
            >
              <template #body="{ items }">
                <tbody>
                  <tr v-for="(row, index) in items" :key="index">
                    <td>{{ row.id }} / {{ row.autoincrement_id }}</td>
                    <td>{{ row.parser }}</td>
                    <td>{{ row.queries_all }} / {{ row.failed_queries }}</td>
                    <td>{{ row.status_aparser }}</td>
                    <td>{{ row.status_laravel }}</td>
                    <td class="text-center">
                      <v-icon
                        v-if="row.error !== null"
                        color="error"
                        @click="handleOpenErrorDetails(row)"
                        >mdi-alert</v-icon
                      >
                      <div v-else class="green--text">none</div>
                    </td>
                    <td>{{ row.created_at }}</td>
                    <td class="text-center">
                      <v-menu
                        nudge-left="25"
                        left
                        :close-on-content-click="false"
                        rounded="lg"
                      >
                        <template #activator="{ on }">
                          <v-icon v-on="on"> mdi-server </v-icon>
                        </template>
                        <v-list dense nav>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-tag-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ row.server.name }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-link</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              <a
                                :href="row.server.api_url"
                                target="_blank"
                                style="text-decoration: none"
                              >
                                {{ row.server.api_url }}
                              </a>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-lock-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ row.server.password }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                    <td>
                      <v-menu nudge-left="25" left rounded="lg">
                        <template #activator="{ on }">
                          <v-icon v-on="on"> mdi-dots-vertical </v-icon>
                        </template>
                        <v-list dense nav>
                          <v-list-item @click="requestAction(row, 'resend')">
                            <v-list-item-icon>
                              <v-icon>mdi-email-sync-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title> Resend </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="requestAction(row, 'reinsert')">
                            <v-list-item-icon>
                              <v-icon>mdi-cached</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title> Reinsert </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @click="requestAction(row, 'delete')"
                            class="error--text"
                          >
                            <v-list-item-icon>
                              <v-icon color="error"
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-title> Delete </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
          <template v-else>
            <div class="dashboard-dev__empty-placeholder">
              <empty-placeholder-illustration />
              <h2>Nothing yet</h2>
              <p>Relax and order a pizza.</p>
              🍕
            </div>
          </template>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn text @click="aparserInfoDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="statusDialog"
      content-class="remove-dialog-shadow"
      :width="1400"
    >
      <v-card class="shadow-e1-bordered" rounded="lg">
        <v-card-text class="pa-6">
          <template v-if="statusData.items && statusData.items.length > 0">
            <dashboard-dev-section-block-factory
              v-for="section in statusData.items"
              :key="section.id"
              :card-data="section"
            />
          </template>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-btn width="180" @click="statusDialog = false">Back</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <float-navigation-menu />
  </div>
</template>

<script>
import { parseNumber } from "@/functions";
import {
  DEV_DASHBOARD_APARSER_DETAILS_TABLE_HEADERS,
  DEV_MONITOR_ACTIONS_DATA,
  FAILED_PROXY_CHART_DATA,
  PRESETS_TABLE_CHART_OPTIONS,
  PRESETS_TABLE_DATA,
  TOP100_TABLE_DATA,
} from "@/utils/defaultData";
import SectionName from "@/components/DashboardDev/SectionName";
import ChartAndCardsSkeleton from "@/components/DashboardDev/components/ChartAndCardsSkeleton";
import EasyLineChart from "@/components/DashboardDev/components/EasyLineChart";
import JsonScoreSection from "@/components/DashboardDev/components/JsonScoreSection";
import EmptyPlaceholderIllustration from "@/components/UI/Icons/EmptyPlaceholderIllustration";
import ActionsTableIcon from "@/components/DashboardDev/components/ActionsTableIcon";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import service from "../../plugins/service";
import Location from "../../mixins/Location";
import TableSorting from "@/mixins/TableSorting";
import MyCalendar from "@/components/UI/MyCalendar.vue";
import DashboardDevSectionBlockFactory from "@/components/DashboardDev/components/DashboardDevSectionBlockFactory.vue";
import FloatNavigationMenu from "@/components/DashboardDev/components/FloatNavigationMenu.vue";
export default {
  components: {
    FloatNavigationMenu,
    DashboardDevSectionBlockFactory,
    MyCalendar,
    ActionsTableIcon,
    EmptyPlaceholderIllustration,
    JsonScoreSection,
    EasyLineChart,
    ChartAndCardsSkeleton,
    SectionName,
  },
  mixins: [FiltersHandling, Location, TableSorting],
  data: () => ({
    only_problem_data: "problems",
    high_priority: false,
    searchActions: null,
    searchTop100: null,
    aparserErrorInfoDialog: false,
    aparserErrorInfoData: null,
    presetsTableSearch: "",
    signatureTableSearch: "",
    monitorActionsData: {
      headers: DEV_MONITOR_ACTIONS_DATA.headers,
      items: [],
    },
    presetsTableData: {
      headers: PRESETS_TABLE_DATA.headers,
      items: [],
      chartOptions: PRESETS_TABLE_CHART_OPTIONS,
    },
    statusDialog: false,
    atSections: [],
    jsonScoreSections: [],
    top100TableData: {
      headers: TOP100_TABLE_DATA.headers,
      items: [],
    },
    searchPositions: [],
    copyscape: {},
    cronManagerItems: [],
    countAparserTasksByStatus: [],
    failedProxyChartData: {
      options: FAILED_PROXY_CHART_DATA.options,
      series: [],
    },
    aparserInfoDialog: false,
    aparserInfoData: {
      headers: DEV_DASHBOARD_APARSER_DETAILS_TABLE_HEADERS,
      items: [],
      name: "",
      status: null,
    },
    statusData: {
      items: [],
    },
    loadings: {
      default: false,
      actionsStatus: [],
      snoozeUntil: [],
      searchPositions: false,
      sc: false,
      copyscape: false,
      monitorActions: false,
      aparserInfo: false,
      failedProxy: false,
      ltt: false,
      dbc: false,
      at: false,
      spiderLink: false,
      xSection: false,
      jsonScoreSections: false,
    },
  }),
  created() {
    this._$collectParams();
    this.fetchData();
  },
  computed: {
    payload() {
      return {
        params: {
          only_problem_data: this.only_problem_data,
        },
      };
    },
    get_current_date() {
      return this.$moment(new Date().getTime()).format("YYYY-MM-DD hh:mm");
    },
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    handleChangeMode() {
      this._$handleFilterChange(this.only_problem_data, "only_problem_data");
      this.handleRefreshSections();
    },
    async handleSnoozeUntil(item) {
      try {
        const url = `/dashboards/dev/monitor/action/${item.id}/snooze_until`;

        const payload = {
          date: item.snooze_until,
        };

        this.loadings.snoozeUntil.push(item.id);

        const resp = await service.post(url, payload);

        this.loadings.snoozeUntil = this.loadings.snoozeUntil.filter(
          (v) => v !== item.id
        );

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "Successfully cuddled",
            type: "success",
            duration: 4000,
          });
        } else {
          this.$message.notification({
            title: "Something wrong",
            text: "Try again later or contact manager",
            type: "warning",
            duration: 4000,
          });
        }
      } catch (e) {
        console.error(e);
        this.loadings.snoozeUntil = this.loadings.snoozeUntil.filter(
          (v) => v !== item.id
        );
      }
    },
    async handleShowStatus(item) {
      try {
        const url = "/dashboards/dev/monitor/action/" + item.id;

        const configs = {
          params: {
            only_problem_data: this.only_problem_data,
          },
        };

        this.statusData = {
          items: [],
        };

        this.loadings.actionsStatus.push(item.id);

        const resp = await service.get(url, true, configs);

        if (resp) {
          this.statusData = resp.data;
          this.statusDialog = true;
        }

        this.loadings.actionsStatus = this.loadings.actionsStatus.filter(
          (v) => v !== item.id
        );
      } catch (e) {
        console.error(e);
        this.loadings.actionsStatus = this.loadings.actionsStatus.filter(
          (v) => v !== item.id
        );
      }
    },
    async requestAction({ autoincrement_id }, action) {
      const payload = {
        autoincrement_id,
        action,
      };

      await this.$store.dispatch("dev-dashboard/requestAction", payload);
    },
    handleOpenErrorDetails(data) {
      this.aparserErrorInfoDialog = true;
      this.aparserErrorInfoData = data.error;
    },
    handleRefreshSections() {
      this.updateSection("DefaultSections");
      this.updateSection("MonitorActions");
    },
    updateSection(name) {
      this["fetch" + name]();
    },
    fetchData() {
      this.fetchDefaultSections();
      this.fetchMonitorActions();
    },
    async fetchMonitorActions() {
      this.loadings.monitorActions = true;

      const payload = {
        params: {
          only_problem_data: this.only_problem_data,
        },
      };

      const resp = await this.$store.dispatch(
        "dev-dashboard/monitorActions",
        payload
      );

      if (resp) {
        this.monitorActionsData.items = resp;
      }

      this.loadings.monitorActions = false;
    },
    async fetchDefaultSections() {
      this.loadings.default = true;
      const resp = await this.$store.dispatch(
        "dev-dashboard/fetchFirst",
        this.payload
      );

      if (resp) {
        this.presetsTableData.items = resp.history;
        this.top100TableData.items = resp.top100;
        this.countAparserTasksByStatus = resp.countAparserTasksByStatus;
        this.cronManagerItems = resp.cronManager;
      }

      this.loadings.default = false;
    },
    parseNumber(data) {
      return parseNumber(data);
    },
    generateFailedProxyChartData(data) {
      if (!data) return null;
      const chartData = FAILED_PROXY_CHART_DATA;
      const keys = Object.keys(data);

      chartData.options.labels = [];
      chartData.series = [];

      keys.forEach((key) => {
        chartData.series.push(data[key]);
        chartData.options.labels.push(key);
      });

      return chartData;
    },
  },
};
</script>
