<template>
  <td
    class="text-center"
    @click="$emit('openDialog', data)"
    :style="{ cursor: 'pointer', color: getColor }"
  >
    <span v-if="data.override_deadline === 0">{{ data.deadline }}</span>
    <span
      v-else-if="data.status === 'done'"
      class="grey--text text--lighten-1"
      >{{ data.deadline }}</span
    >
    <span v-else class="error--text">{{ data.deadline }}</span>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      const today = this.$moment(new Date());
      const diffDays = this.$moment(this.data.deadline).diff(today, "days");

      if (diffDays <= 8) {
        return "green";
      }
      if (diffDays <= 15) {
        return "orange";
      }

      return "black";
    },
  },
};
</script>
