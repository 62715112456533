<template>
  <div
    style="white-space: nowrap; cursor: pointer"
    @click="$emit('show', data)"
    class="text-center"
  >
    <template v-if="data">
      <div
        :class="`colored-td colored-td--inline colored-td--clickable ${getBgColor}`"
      >
        <template v-if="!isEmpty(forceValue)">
          {{ forceValue }}
        </template>
        <template v-else>
          {{ mode ? data.no_occurrences : data.deviation }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty } from "../../../utils/functions";

export default {
  methods: { isEmpty },
  props: ["data", "mode", "name", "forceValue", "disableColor"],
  computed: {
    getBgColor() {
      try {
        if (this.disableColor) return "";

        const value = this.forceValue || this.data.deviation;
        if (
          [
            "only_stop_words_1",
            "by_words_2_others",
            "only_stop_words_2",
            "by_words_3_others",
            "only_stop_words_3",
          ].includes(this.name)
        ) {
          if (value >= 6 && value <= 10) return "yellow";
          if (value >= 11) return "red";
          return "";
        }

        if (
          [
            "only_stop_words_1_delta",
            "only_stop_words_2_delta",
            "by_words_2_others_delta",
          ].includes(this.name)
        ) {
          if (value >= 2.5) return "red";
          if (value < 2.5 && value >= 0.8) return "yellow";
          if (value < 0.8) return "";
        }

        if (["main_words_by_vision_delta_positive"].includes(this.name)) {
          if (value > 2) return "red";
          if (value <= 2 && value >= 0.8) return "yellow";
          if (value < 0.8) return "";
        }

        if (["main_words_by_vision_delta_negative"].includes(this.name)) {
          if (value < -1.5) return "red";
          if (value >= -1.5 && value <= -0.6) return "yellow";
          if (value < -0.6) return "";
        }

        if (value >= 4 && value <= 7) return "yellow";
        if (value >= 8) return "red";
        return "";
      } catch {
        return "";
      }
    },
  },
};
</script>
