<template>
  <div>
    <v-list-item-subtitle class="d-flex justify-space-between align-center">
      {{ item.title }}
      <div class="d-flex justify-end align-center" style="gap: 0.25rem">
        <v-btn
          small
          icon
          @click="handleCopy(item.value.value + ' ' + item.value.currency)"
        >
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
        <v-chip
          outlined
          small
          label
          class="px-2"
          @click="showPrices"
          :disabled="pending"
        >
          History
          <v-progress-circular
            v-if="pending"
            indeterminate
            size="12"
            width="1"
          />
        </v-chip>
      </div>
    </v-list-item-subtitle>
    <v-list-item-title class="mb-0">
      <v-chip
        outlined
        small
        label
        class="px-2"
        @click="handleCopy(item.value.value + ' ' + item.value.currency)"
      >
        {{ item.value.value }} {{ item.value.currency }}
      </v-chip>
      {{ item.value.discount }} {{ item.value.period }}
    </v-list-item-title>
    <price-history-dialog v-model="isPriceDialogVisible" :prices="prices" />
  </div>
</template>

<script>
import copy from "copy-to-clipboard";
import PriceHistoryDialog from "./PriceHistoryDialog.vue";
import GpmApiService from "../../../services/gpm/GpmApiService";

export default {
  components: { PriceHistoryDialog },
  props: {
    item: {},
  },

  inject: ["headerData"],

  data: () => ({
    pending: false,
    prices: [],
    isPriceDialogVisible: false,
    gpmApiService: null,
  }),

  created() {
    this.gpmApiService = new GpmApiService();
  },

  methods: {
    async showPrices() {
      try {
        this.pending = true;

        const { data } = await this.gpmApiService.getDomainPrices(
          this.headerData().id_domain
        );

        this.prices = data || [];
        this.isPriceDialogVisible = true;
      } catch (e) {
        console.error("Error while uploading prices.", e);
      } finally {
        this.pending = false;
      }
    },
    handleCopy(data) {
      copy(data);
      this.$message.notification({
        title: "Copied",
        text: `Text <mark>${data}</mark> successfully coppied.`,
        type: "success",
        duration: 10000,
        position: "lb",
      });
    },
  },
};
</script>
