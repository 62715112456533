<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--4"
    width="420"
    persistent
  >
    <v-card class="styled-card--default">
      <v-card-title>
        Close task
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-row dense>
          <v-col cols="12"> Before closing the task </v-col>
          <v-col v-for="checkbox in checkboxes" :key="checkbox.id" cols="12">
            <my-card-checkbox
              :model-value="closeTaskForm.checkboxes"
              @update@modelValue="closeTaskForm.checkboxes = $event"
              multiple
              :value="{
                value: checkbox.id,
                text: checkbox.name,
              }"
              :label="checkbox.name"
              :text-model="closeTaskForm.refactoring_description"
              @update@textModel="closeTaskForm.refactoring_description = $event"
              :show-textfield="
                checkbox.id === CHECKBOXES.IS_BUG ||
                checkbox.id === CHECKBOXES.IS_REFACTORED
              "
            />
          </v-col>
          <v-col
            v-if="
              closeTaskForm &&
              closeTaskForm.checkboxes &&
              closeTaskForm.checkboxes.includes(CHECKBOXES.IS_BUG)
            "
            cols="12"
          >
            <v-card class="styled-card--light">
              <v-card-title>
                <v-icon left small color="error">mdi-alert-outline</v-icon>Bug
                detected
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-textarea
                  v-model="closeTaskForm.bug_cause_description"
                  rows="3"
                  dense
                  placeholder="Write description about bug cause"
                  hide-details="auto"
                  filled
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-menu
          v-if="!closeTaskValidator.isValid"
          bottom
          offset-y
          content-class="my-shadow--e3"
          max-width="320"
        >
          <template #activator="{ on }">
            <v-chip
              v-on="on"
              label
              outlined
              style="height: 44px; width: 54px"
              class="px-0 d-flex justify-center mr-2"
            >
              <v-icon color="error">mdi-alert</v-icon>
            </v-chip>
          </template>
          <v-card class="styled-card--default">
            <v-card-title> Errors </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col
                  v-for="error in closeTaskValidator.messages"
                  :key="error.message"
                  cols="12"
                >
                  <v-icon color="error" small class="mr-2">mdi-alert</v-icon>
                  {{ error.message }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-chip
          :disabled="!closeTaskValidator.isValid"
          label
          style="width: 100%; height: 44px"
          class="d-flex justify-center"
          color="primary"
          @click="handleCloseTask"
        >
          Close task
        </v-chip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MyCardCheckbox from "@/components/UI/MyCardCheckbox.vue";

export default {
  components: { MyCardCheckbox },
  props: {
    modelValue: {},
    form: {},
    checkboxes: Array,
  },
  emits: ["update:modelValue", "update:form"],
  data: () => ({
    CHECKBOXES: {
      WRITE_BASE_TEST_FOR_ENDPOINT: 1,
      TESTED_ON_MY_OWN_DEVICE: 2,
      TESTED_ON_PRE_PROD_SERVER: 3,
      FIXED_CAUSE: 4,
      IS_REFACTORED: 5,
      IS_BUG: 6,
    },
  }),
  computed: {
    closeTaskValidator() {
      const errors = [];

      const checkboxes = this.closeTaskForm.checkboxes;

      const CHECKBOXES = this.CHECKBOXES;

      if (!checkboxes.includes(CHECKBOXES.WRITE_BASE_TEST_FOR_ENDPOINT))
        errors.push({
          message: "Write base test for endpoint",
          key: "isWriteBaseTestForEndpoint",
        });
      if (!checkboxes.includes(CHECKBOXES.TESTED_ON_PRE_PROD_SERVER))
        errors.push({
          message: "Test app on pre-prod server",
          key: "isTestedOnPreProdServer",
        });
      if (!checkboxes.includes(CHECKBOXES.TESTED_ON_MY_OWN_DEVICE))
        errors.push({
          message: "Test app on your own device",
          key: "isTestedOnMyOwnDevice",
        });
      if (!checkboxes.includes(CHECKBOXES.FIXED_CAUSE))
        errors.push({
          message: "Fix cause of bug, not an effect",
          key: "isFixedCause",
        });

      if (
        checkboxes.includes(CHECKBOXES.IS_REFACTORED) &&
        !this.closeTaskForm.refactoring_description
      )
        errors.push({
          message: "Write description about refactoring",
          key: "refactoringDescription",
        });
      if (
        checkboxes.includes(CHECKBOXES.IS_BUG) &&
        !this.closeTaskForm.bug_cause_description
      )
        errors.push({
          message: "Write description about bug cause",
          key: "bugCauseDescription",
        });

      return {
        isValid: errors.length === 0,
        messages: errors,
      };
    },
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    closeTaskForm: {
      get() {
        return this.form;
      },
      set(value) {
        this.$emit("update:form", value);
      },
    },
  },
  methods: {
    resetForm() {
      this.closeTaskForm = {
        rowData: {},
        checkboxes: [],
        bug_cause_description: null,
        refactoring_description: null,
      };
    },
    handleCloseTask() {
      this.$emit("closeTask");
      this.dialog = false;
      this.resetForm();
    },
  },
};
</script>
