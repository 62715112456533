import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'conversion-2/fetchTableData'(ctx, {payload, projectId}) {
            const url = `/analytics/conversion/report2/${projectId}`
            const response = await Service.post(url, payload, true)

            if(response.status === 200){
                return response.data
            }
        },
    },
    getters: {},
}