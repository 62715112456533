<template>
  <v-row>
    <v-col>
      <h2 class="heading">{{ header.status_name }} - {{ header.domain }}
        <v-btn icon :href="'http://' + header.domain" target="_blank">
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
      </h2>
      <h3 class="subtitle-1">{{ header.main_status }} ({{ Math.round(header.stage * 100 / 8) }}%)</h3>
      <h3 class="subtitle-1">{{ header.task_name }}</h3>
      <h5 class="subtitle-1">
        Responsible:
        <v-chip label small
                :color="responsibilities_colors[header.assigned].bg"
                :style="'color: ' + responsibilities_colors[header.assigned].color">
          {{ header.assigned }}
        </v-chip>
      </h5>
      <h5 class="subtitle-1">
        Stage time:
        <v-chip label small :color="header.overdue ? 'error' : 'success'">{{ header.time_on_stage }}</v-chip>
      </h5>
      <h5 class="subtitle-1">Creator: {{ header.creator }}</h5>
      <h5 class="subtitle-1">Performer: {{ header.performer }}</h5>
    </v-col>
    <v-col class="text-right">

      <v-menu
          bottom
          left
          open-on-hover
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs"
                 v-on="on" icon :color="header.outsources.length > 0 ? 'info' : ''">
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, i) in header.outsources"
              :key="i"
          >
            <v-list-item-title><a :href="item.link" target="_blank">{{ item.title }}</a></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon :color="header.connected_entity > 0 ? 'info' : ''" @click="viewWasConnected()">
        <v-icon>mdi-connection</v-icon>
      </v-btn>
      <v-badge
          :content="header.ltt_links"
          :value="header.ltt_links"
          color="pink"
          overlap
      >
        <v-btn icon @click="viewLttLinks">
          <v-icon>mdi-link</v-icon>
        </v-btn>
      </v-badge>
      <v-badge
          :content="header.spiderlink_links"
          :value="header.spiderlink_links"
          color="pink"
          overlap
      >
        <v-btn icon @click="goToSpiderLink">
          <v-icon>mdi-spider</v-icon>
        </v-btn>
      </v-badge>
      <v-btn icon @click="viewAnchorPlan(header.task_id)">
        <v-icon>mdi-calendar-month-outline</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs"
                 v-on="on" icon @click="viewRelevant()">
            <v-icon>mdi-layers</v-icon>
          </v-btn>
        </template>
        <span>Relevant</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs"
                 v-on="on" icon @click="viewInfo(header.domain)">
            <v-icon>mdi-chart-donut</v-icon>
          </v-btn>
        </template>
        <span>View Info By Site</span>
      </v-tooltip>
      <v-dialog v-model="dialog_apt" persistent scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            Anchor Plan
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="apt_headers"
                :items="apt_items"></v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="secondary"
                text
                @click="dialog_apt = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_relevant" persistent scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            Relevant
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="relevant_headers"
                :items="relevant_items">
                <template v-slot:item.domain="{item}">
                  {{ item.domain }}
                  <v-btn icon small link :to="{ name: 'gpm.view-entity', params: { id: item.id }}" target="_blank">
                      <v-icon small>mdi-open-in-new</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="secondary"
                text
                @click="dialog_relevant = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_ltt" persistent scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            Ltt
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="ltt_headers"
                :items="ltt_items"></v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="secondary"
                text
                @click="dialog_ltt = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="connected_dialog" persistent scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            Connected
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="connected_entity_headers"
                :items="connected_entity_items"></v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="secondary"
                text
                @click="connected_dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {RESPONSIBILITIES_COLORS} from "@/utils/selectItems";
import axios from "axios";

export default {
  name: "Header",
  props: ['header'],
  data() {
    return {
      dialog_ltt: false,
      dialog_apt: false,
      dialog_relevant: false,
      connected_dialog: false,
      apt_headers: [
        {text: 'Url', value: 'url'},
        {text: 'Anchor', value: 'anchor'},
        {text: 'Donor', value: 'donor'},
      ],
      apt_items: [],
      relevant_headers: [
        {text: 'Domain', value: 'domain'},
        {text: 'Theme', value: 'theme'},
        {text: 'Task', value: 'task_name'},
      ],
      relevant_items: [],
      ltt_headers: [
        {text: 'Done Url', value: 'done_url'},
        {text: 'Acceptor', value: 'acceptor'},
        {text: 'Author', value: 'author'},
        {text: 'Date', value: 'date'},
      ],
      ltt_items: [],
      connected_entity_headers: [
        {text: 'Field', value: 'title'},
        {text: 'Value', value: 'value'},
        {text: 'Changed At', value: 'created'},
        {text: 'User', value: 'email'},
      ],
      connected_entity_items: [],
      responsibilities_colors: RESPONSIBILITIES_COLORS
    }
  },
  methods: {
    viewWasConnected(){
      let self = this

      self.connected_entity_items = []
      self.connected_dialog = false

      let url = `${self.$store.state.server_url}/gpm/task-view/get-connected-entity`;

      let config = {
        params: {
          entityID: this.$route.params.id,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.connected_entity_items = r.data.connected_entity
            self.connected_dialog = true
          })
    },
    viewLttLinks() {
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/gpm/task-view/get-ltt-links`;

      let config = {
        params: {
          domain_id: self.header.id_domain
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.ltt_items = r.data.items
            self.dialog_ltt = true
          })
          .finally(() => self.loading = false)
    },
    goToSpiderLink() {
      window.open(`http://app.spiderlink.pro/link-analyzer/picker/pre-pre-cleaner/${this.header.id_domain}`, '_blank')
    },
    viewAnchorPlan(aptID) {
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan`;

      let config = {
        params: {
          anchorPlanTaskID: aptID
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.apt_items = r.data.items
            self.dialog_apt = true
          })
          .finally(() => self.loading = false)
    },
    viewRelevant(){
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/gpm/task-view/get-relevant-entity`;

      let config = {
        params: {
          entityID: this.$route.params.id
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.relevant_items = r.data.items
            self.dialog_relevant = true
          })
          .finally(() => self.loading = false)
    },
    viewInfo(domain) {
      window.open(`/view-info-by-site/show?domain=${domain}`, '_blank')
    }
  },
}
</script>

<style scoped>

</style>