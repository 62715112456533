<template>
  <v-dialog
    v-model="dialog"
    content-class="remove-dialog-shadow"
    max-width="660px"
  >
    <v-card rounded="xl">
      <v-card-title>{{title}}</v-card-title>
      <v-card-subtitle>Separator - new line</v-card-subtitle>
      <v-card-text>
        <v-textarea
          v-model="competitorsModel"
          outlined
          label="Competitors"
          placeholder="competitor \n competitor"
          @input="handleChangeCompetitors"
        ></v-textarea>
      </v-card-text>
      <v-card-text v-if="competitors.length > 0">
        <v-simple-table dense style="max-height: 500px; overflow: scroll">
          <thead>
          <tr>
            <th>Name</th>
            <th style="text-align: end">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="competitor in competitors" :key="competitor">
            <td>{{ competitor }}</td>
            <td style="text-align: end">
              <v-btn icon @click="removeCompetitor(competitor)">
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="dialog = false" text width="120px"
        >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import FiltersHandling from "@/mixins/components/FiltersHandling";

export default {
  mixins: [Dialog, FiltersHandling],
  props: {
    competitorsValue: {},
    componentName: {
      type: String,
      default: 'competitors-input'
    },
    competitorName: {
      type: String,
      default: 'competitors'
    },
    title: {
      type: String,
      default: 'competitors'
    },
  },
  data: () => ({
    competitorsModel: '',
    processCompetitorsTimeout: null,
  }),
  created() {
    const localStorage = window.localStorage.getItem(this.componentName);

    try {
      const parsed = JSON.parse(localStorage) || [];
      this.competitors = parsed;
      this.competitorsModel = parsed.join("\n");
    } catch (e){
      console.error(e)
    }
  },
  watch: {
    "competitors"(value) {
      window.localStorage.setItem(this.componentName, JSON.stringify(value));
    },
  },
  computed: {
    competitors: {
      get() {return this.competitorsValue},
      set(value) {this.$emit('update:competitorsValue', value)
        }
    }
  },
  methods: {
    handleChangeCompetitors(value) {
      clearTimeout(this.processCompetitorsTimeout);

      this.processCompetitorsTimeout = setTimeout(() => {
        this.textCompetitorsToObject(value);
      }, 1750);
    },
    textCompetitorsToObject(tmrstr) {
      let fixed = tmrstr;
      let newCompetitors = null

      fixed = fixed.replaceAll("\\n", "\n");

      fixed = fixed.replaceAll(" ", "");

      fixed = fixed.replaceAll(/\s(?:\n)/g, "\n");

      fixed = fixed.trim();

      this.competitorsModel = fixed;

      newCompetitors = fixed.split("\n");

      newCompetitors = [...new Set(newCompetitors)];

      newCompetitors = newCompetitors.filter((v) => v);

      this.competitors = newCompetitors

      this._$handleFilterChange(newCompetitors, this.competitorName);
    },
    removeCompetitor(competitor) {
      /*eslint-disable*/
      const fixedCompetitor = competitor
        .replaceAll(" ", "")
        .replaceAll("\n", "");
      const regex = new RegExp(
        `${fixedCompetitor}(?:\\n|\n|\s+?\\n|\s+?\n|\s|)`,
        "g"
      );
      console.dir(regex);
      this.competitorsModel = this.competitorsModel.replace(regex, "");

      this.competitors = this.competitors.filter(
        (v) => v !== competitor
      );

      this._$handleFilterChange(this.competitors, "competitors");
    },
  },
};
</script>