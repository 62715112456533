<template>
  <v-card rounded="lg" class="shadow-e2-bordered">
    <v-card-title> Settings </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectedUsers"
            dense
            outlined
            :items="filtersData.responsible"
            hide-details
            label="Users"
            placeholder="Select users"
            clearable
            multiple
            return-object
            :loading="loadings.filters"
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0">{{ item.text }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selectedUsers.length - 1 }} more)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="source"
            dense
            outlined
            :items="filtersData.source"
            hide-details
            label="Source"
            placeholder="Select"
            clearable
            :loading="loadings.rHubFilters"
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0">{{ item.text }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ source.length - 1 }} more)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="status"
            dense
            outlined
            :items="filtersData.status"
            hide-details
            label="Status"
            placeholder="Select"
            clearable
            multiple
            :loading="loadings.rHubFilters"
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0">{{ item.text }}</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ status.length - 1 }} more)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row dense class="mt-4">
        <v-col cols="12">
          <v-row dense>
            <v-col>
              Used donors: <b>{{ usedDonorsAmount }}</b>
            </v-col>
            <v-col class="d-flex justify-center">
              Remaining: <b>{{ getRemainingDonors }}</b>
            </v-col>
            <v-col class="d-flex justify-end">
              Limit: <b>{{ filtersData.limit }}</b>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            :color="getProgressColor"
            :value="getProgressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row dense class="mt-4">
        <v-col cols="12">
          <v-simple-table class="show-icons-on-hover">
            <thead>
              <tr>
                <th style="padding-right: 0; padding-left: 5px"></th>
                <th>User</th>
                <th>Limits</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in selectedUsers" :key="user.text">
                <td style="width: 35px; padding-right: 0; padding-left: 5px">
                  <v-btn
                    icon
                    color="error"
                    @click="removeFromSelected(user.text)"
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </td>
                <td>
                  {{ user.text }}
                </td>
                <td style="max-width: 150px">
                  <div class="d-flex align-center">
                    <v-text-field
                      v-model="user.total"
                      style="margin-top: 0"
                      type="number"
                      outlined
                      hide-details
                      dense
                      clearable
                      @input="calculateUsedDonors"
                    >
                    </v-text-field>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        color="success"
        @click="saveSettings"
        :loading="loadings.saving"
        :disabled="isDisabled"
        width="160"
        style="height: 40px"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { deepClone } from "../../../utils/functions";

export default {
  props: {
    fetchData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedUsers: [],
    users: [],
    source: null,
    status: [],
    usedDonorsAmount: 0,
    filtersData: {
      config: [],
      responsible: [],
      limit: [],
      source: [],
      status: [],
    },
    loadings: {
      filters: false,
      rHubFilters: false,
    },
  }),
  created() {
    console.log("created research hub");
    this.fetchFilters();
    this.fetchResearchHubFilters();
  },
  watch: {
    selectedUsers() {
      this.calculateUsedDonors();
    },
    status() {
      this.fetchFilters();
    },
    source() {
      this.fetchFilters();
    },
    filtersData: {
      deep: true,
      immediate: true,
      handler(value) {
        if (
          value.config &&
          value.config.selected_users &&
          this.selectedUsers.length === 0
        ) {
          this.selectedUsers = deepClone(value.config.selected_users);
          this.source = value.config.source;
          this.status = deepClone(value.config.status);
        }
      },
    },
  },
  computed: {
    getProgressValue() {
      let percent = Math.round(
        (this.usedDonorsAmount / this.filtersData.limit) * 100
      );
      if (percent > 100) percent = 100;

      return percent;
    },
    getProgressColor() {
      let perc = 100 - this.getProgressValue;
      var r,
        g,
        b = 0;
      if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
      } else {
        g = 255;
        r = Math.round(510 - 5.1 * perc);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return "#" + ("000000" + h.toString(16)).slice(-6);
    },
    getRemainingDonors() {
      return +this.filtersData.limit - +this.usedDonorsAmount;
    },
    isDisabled() {
      return this.usedDonorsAmount > this.filtersData.limit;
    },
  },
  methods: {
    removeFromSelected(name) {
      this.selectedUsers = this.selectedUsers.filter(
        (user) => user.text !== name
      );
    },
    calculateUsedDonors() {
      this.usedDonorsAmount = this.selectedUsers.reduce((a, c) => {
        const donors = c.total || 0;
        return a + parseInt(donors);
      }, 0);
    },
    async fetchResearchHubFilters() {
      try {
        this.loadings.rHubFilters = true;

        const payload = {
          type: "/gpm/research-hub",
          take: ["source", "status"],
        };

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } finally {
        this.loadings.rHubFilters = false;
      }
    },
    async fetchFilters() {
      console.log("fetch filters");
      try {
        const filters = ["config", "responsible", "limit"];
        const payload = {
          type: "/admin/research-hub",
          take: filters,
          filter: {
            projectID: this.$store.state.project.active_project,
            type: "research_hub",
            source: this.source,
            status: this.status,
          },
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = { ...this.filtersData, ...resp };
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.filters = false;
      }
    },
    async saveSettings() {
      try {
        const payload = {
          type: "research_hub",
          data: {
            selected_users: this.selectedUsers,
            status: this.status,
            source: this.source,
          },
        };

        this.loadings.saving = true;

        const resp = await this.$store.dispatch(
          "global-settings/update",
          payload
        );

        if (resp && resp.success) {
          return this.$message.notification({
            title: "Success",
            text: "Settings successfully saved. 👍",
            type: "success",
            duration: 6000,
          });
        }

        return this.$message.notification({
          title: "Warning",
          text: "Something wrong",
          type: "warning",
          duration: 6000,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.saving = false;
      }
    },
  },
};
</script>
