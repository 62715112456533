export default {
  methods: {
    can(permissionName = "") {
      const permissions = this.$store.getters["base/getUserPermissions"];

      return (
        permissions.find(
          (p) => p.route === this.$route.name && p.permission === permissionName
        ) !== undefined
      );
    },
  },
};
