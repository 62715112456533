<template>
  <div class="mb-2">
    <div class="d-flex">
      <b>{{ data.value ? data.value + "%" : "" }}</b>
      <v-spacer />
      {{ data.text }}
    </div>
    <v-progress-linear
      :value="data.value"
      :color="getProgressColor"
      height="8"
      rounded
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getProgressColor() {
      const percent = this.data.value;

      if (percent >= 100) return "success";
      if (percent >= 80 && percent < 100) return "warning";
      if (percent < 80) return "error";

      return "error";
    },
  },
};
</script>
