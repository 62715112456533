export const REDIRECTS_RESULTS_PAGE_DATA = {
  TITLE: "Redirects results",
  BREADCRUMBS: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Redirects",
      disabled: true,
    },
    {
      text: "Results",
      disabled: true,
    },
  ],
  DATA_TABLE_HEADERS: [
    {
      text: "Domain",
      value: "domain",
      sortable: false,
    },
    {
      text: "New Domain",
      value: "new_domain",
      sortable: false,
    },
    {
      text: "Created",
      value: "created_at",
      sortable: false,
    },
    {
      text: "Updated",
      value: "updated_at",
      sortable: false,
    },
    {
      text: "Redirect",
      value: "redirect_date",
      sortable: false,
    },
  ],
};
