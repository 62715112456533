<template>
  <div id="chart">
    <apexchart type="radialBar" :height="chartOptions.chart.height" :options="chartOptions"
               :series="series"></apexchart>
  </div>
</template>

<script>

import VueApexCharts from "vue-apexcharts";

export default {
  name: "RadialBarChart",
  props: ["series"],
  components: {apexchart: VueApexCharts},
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          },
          height: "200px"
        },
        colors: ["#e7e7e7"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: ['Median Ratio'],
      },
    }
  },
  methods: {},
  created() {
    if (this.series[0] >= 90) {
      this.chartOptions.colors = ['#3aa721']
    } else if (this.series[0] >= 40) {
      this.chartOptions.colors = ['#ffcc00']
    } else {
      this.chartOptions.colors = ['#a72121']
    }
  },
  computed: {}
}
</script>

<style scoped>
.apexcharts-xaxistooltip {
  background: #1B213B;
  color: #fff;
}

</style>