import service from "../../../../plugins/service";
import { GPM_TAGS_ENDPOINTS } from "../defaults";

export const GpmTagsApiService = () => {
  const getTags = () => {
    return service.get(GPM_TAGS_ENDPOINTS.GET_TAGS);
  };
  const deleteTag = (id) => {
    return service.delete(GPM_TAGS_ENDPOINTS.DELETE_TAG(id));
  };
  const updateTag = (id, payload) => {
    return service.put(GPM_TAGS_ENDPOINTS.UPDATE_TAG(id), payload);
  };
  const createTag = (payload) => {
    return service.post(GPM_TAGS_ENDPOINTS.CREATE_TAG, payload);
  };
  return {
    getTags,
    deleteTag,
    updateTag,
    createTag,
  };
};
