<template>
  <v-card>
    <div class="pa-4">
      Acceptor undefined: Show
      <v-switch
        color="primary"
        class="d-inline-block ma-0 ml-2"
        v-model="hide_empty"
        label=""
        dense
        input-value="true"
        hide-details
      ></v-switch>
      Hide
    </div>
    <v-card-text>
      Manual / Auto: <strong>{{ stat.manual }} / {{ stat.auto }}</strong>
      <br /><br />
      <apexcharts
        type="bar"
        height="300"
        :options="options"
        :series="series"
      ></apexcharts>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";

export default {
  name: "LinkTypes",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {
      hide_empty: false,
    };
  },
  computed: {
    stat() {
      return this.$store.state.OnePageAnalyzer.percentage_link_types_to_total
        .stat;
    },
    urls() {
      let self = this;

      let urls = [this.selectedUrl];
      let urls_vision = Object.keys(this.vision);

      urls_vision.forEach(function (url) {
        if (urls.indexOf(url) === -1) {
          urls.push(url);
        }
      });

      return urls.filter((item) => {
        return (
          self.$store.state.OnePageAnalyzer.percentage_link_types_to_total
            .charts[item] !== undefined
        );
      });
    },
    series() {
      let self = this;

      let types =
        this.$store.state.OnePageAnalyzer.percentage_link_types_to_total.types;

      let prepare = {};

      types.forEach(function (type) {
        prepare[type] = [];
      });

      this.urls.forEach(function (url) {
        let item =
          self.$store.state.OnePageAnalyzer.percentage_link_types_to_total
            .charts[url];

        types.forEach(function (type) {
          let value = parseInt(item === undefined ? 0 : item[type]);
          prepare[type].push(isNaN(value) ? 0 : value);
        });
      });

      let result = [];

      types.forEach(function (type) {
        if (
          (type !== "acceptor undefined" && self.hide_empty) ||
          !self.hide_empty
        ) {
          result.push({
            name: type,
            data: prepare[type],
          });
        }
      });

      return result;
    },
    options() {
      let categories = [];

      this.urls.forEach(function (url) {
        categories.push(new URL(url).host);
      });

      let colors =
        this.$store.state.OnePageAnalyzer.percentage_link_types_to_total.colors;

      return {
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: colors,
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Link Types",
        },
        xaxis: {
          categories: categories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>

<style scoped></style>
