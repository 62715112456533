<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mx-auto" v-if="isset_active_project">
            <v-card-text>
              <h3>Anchors Plan LB:</h3>
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                content-class="shadow-e2-bordered"
                rounded="xl"
                min-width="360px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-fab-transition>
                    <v-btn
                      color="info"
                      small
                      absolute
                      style="margin-right: 10px; margin-top: -43px"
                      right
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="31">mdi-filter</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </template>
                <v-card>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="handleDateChange"
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          v-model="filter.user"
                          :items="users"
                          item-text="name"
                          item-value="id"
                          hide-details
                          dense
                          outlined
                          label="Performer"
                          clearable
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          v-model="filter.status"
                          :items="status"
                          item-text="name"
                          item-value="id"
                          label="Status"
                          style="max-width: 300px"
                          chips
                          hide-details
                          small-chips
                          dense
                          outlined
                          full-width
                          multiple
                          deletable-chips
                          clearable
                        ></v-autocomplete>
                      </v-list-item>
                    </v-list>

                    <div class="text-center">
                      <v-btn @click="fetch_data" color="primary" width="130px">
                        Apply
                      </v-btn>
                    </div>

                    <div class="text-center" style="padding-top: 35px">
                      Export
                    </div>
                    <div class="text-center" style="padding-top: 15px">
                      <v-btn @click="export_manager_data" color="primary" text>
                        Progress
                      </v-btn>
                      <v-btn @click="export_data" color="primary" text>
                        Posted links
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>

              <TableAnchorPlanIndex
                :type="2"
                :status="status"
                :items="table.items"
                :performers="users"
              ></TableAnchorPlanIndex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "@/events/eventPipe";
import TableAnchorPlanIndex from "@/components/AnchorPlan/Child/TableAnchorPlanIndex";
import SmartDateFilter from "@/components/UI/SmartDateFilter";

let dateFormat = require("dateformat");

export default {
  name: "LinkbuilderAnchorPlanIndex",
  components: { SmartDateFilter, TableAnchorPlanIndex },
  metaInfo: {
    title: "Anchor Plan LB Tasks - LTT - SI",
  },
  data() {
    return {
      loadings: {},
      date_modal: false,
      date_modal_export: false,
      table: {
        items: [],
      },
      users: [],
      status: [],
      filter: {
        user: false,
        date_range_export: [
          dateFormat(new Date(), "yyyy-mm-dd"),
          dateFormat(new Date(), "yyyy-mm-dd"),
        ],
        date_range: [],
        status: [1, 2, 3, 4, 6],
      },
    };
  },
  methods: {
    handleDateChange(event) {
      this.filter.date_range = event;
      this._$handleFilterChange(event, "date_range");
    },
    fetch_status() {
      let self = this;

      self.update_loading("fetch_status", true);

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/list/status`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, {}, config)
        .then(function (response) {
          self.status = response.data;
          self.update_loading("fetch_status", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_data() {
      let self = this;

      self.update_loading("fetch_data", true);

      let url = `${self.$store.state.server_url}/anchor-plan/tier/tasks/0/1`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        filter: this.filter,
      };

      self.table.items = [];

      axios
        .post(url, data, config)
        .then(function (response) {
          self.table.items = response.data.tasks;
          // self.users = response.data.users
          self.update_loading("fetch_data", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_performers() {
      let self = this;

      self.update_loading("fetch_performers", true);

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/list/performers`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, {}, config)
        .then(function (response) {
          self.users = response.data;
          self.update_loading("fetch_performers", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    export_manager_data() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          location.href =
            `${self.$store.state.server_url}/export/anchor-plan-manager/index/0?hash=` +
            response.data.hash;
        }
      });
    },
    export_data() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          location.href =
            `${self.$store.state.server_url}/export/anchor-plan/index/0?hash=` +
            response.data.hash;
        }
      });
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {
    let self = this;

    eventPipe.$on("update-active-project", () => {
      self.fetch_data();
    });

    self.fetch_status();
    self.fetch_performers();
    self.fetch_data();
  },
};
</script>

<style scoped></style>
