<template>
  <v-container
    class="similar-task-creating"
    fluid
    style="padding-bottom: 200px"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="mb-4 d-flex">
          <span>Task in subtask</span>
          <v-spacer />
          <v-btn
            v-if="results && results.tasks && results.tasks.length > 0"
            color="primary"
            outlined
            @click="resultsDialogModel = true"
            width="180px"
          >
            Show Results
          </v-btn>
        </h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12">
        <div style="max-width: 320px; display: inline-block">
          <v-autocomplete
            v-model="mainTask.project_id"
            dense
            label="Project"
            hide-details
            clearable
            item-text="name"
            item-value="id"
            :loading="loadings.filters"
            :items="filtersData.projects"
            :menu-props="menuProps"
          ></v-autocomplete>
        </div>
        <span class="mx-2">/</span>
        <span style="opacity: 0.5"> New Task</span>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="12" sm="12" md="6">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2" style="height: 64px">
                <b>Main task</b>
              </v-card-title>
              <v-card-text class="pa-0">
                <task-card
                  :form="mainTask"
                  :filters-data="filtersData"
                  :loadings="loadings"
                  :validate="validate"
                  borderless
                  @createTask="handleCreateTask"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2">
                <b>Subtasks</b>
                <v-spacer />
                <v-chip label @click="handleCopyDataFromMainTask">
                  Copy from main task
                  <v-icon small right>mdi-content-copy</v-icon>
                </v-chip>
              </v-card-title>
              <v-card-text class="pa-0">
                <task-card
                  type="subtask"
                  :form="subtaskForm"
                  :filters-data="filtersData"
                  borderless
                  :loadings="loadings"
                  :validate="validate"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card rounded="lg" flat outlined>
          <v-card-title class="d-flex text-body-2">
            <b> Select Performers </b>
            <v-spacer />
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              label="Search"
              append-icon="mdi-magnify"
              style="max-width: 320px"
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12"> Sort table by </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.department"
                  label="Role"
                  :handling="false"
                  filled
                  @update:modelValue="sortTableBy.department = $event"
                  :items="tableFiltersData.department"
                ></smart-autocomplete>
              </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.email"
                  label="Email"
                  filled
                  :handling="false"
                  @update:modelValue="sortTableBy.email = $event"
                  :items="tableFiltersData.email"
                ></smart-autocomplete>
              </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.group"
                  label="WS Team"
                  filled
                  :handling="false"
                  @update:modelValue="sortTableBy.group = $event"
                  :items="tableFiltersData.group"
                ></smart-autocomplete>
              </v-col>
              <v-col>
                <smart-autocomplete
                  :model-value="sortTableBy.name"
                  label="Name"
                  filled
                  :handling="false"
                  @update:modelValue="sortTableBy.name = $event"
                  :items="tableFiltersData.name"
                ></smart-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <v-data-table
              v-model="subtaskForm.performers"
              :search="search"
              :headers="performersTableHeaders"
              :items="getFilteredTableItems"
              :items-per-page="-1"
              :custom-sort="_$smartCustomTableSort"
              :loading="loadings.filters"
              show-select
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="resultsDialogModel"
      content-class="remove-dialog-shadow"
      persistent
      width="800"
    >
      <v-card class="shadow-e1-bordered" rounded="lg">
        <v-card-title> Results </v-card-title>
        <v-card-text>
          <v-card
            v-if="
              results && results.task && Object.keys(results.task).length !== 0
            "
            class="styled-card--default"
          >
            <v-card-title> Task </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col>
                  <div v-if="results.task.name">
                    <v-icon small class="mr-2">mdi-tag-outline</v-icon
                    >{{ results.task.name }}
                  </div>
                  <div v-if="results.task.email" class="mt-2">
                    <v-icon small class="mr-2">mdi-account-outline</v-icon
                    >{{ results.task.email }}
                  </div>
                  <div
                    v-if="
                      results.task.status === 'error' && results.task.content
                    "
                    class="error--text"
                  >
                    {{ results.task.content }}
                  </div>
                </v-col>
                <v-col cols="auto">
                  <a
                    :href="'https://ra.worksection.com/' + results.task.page"
                    target="_blank"
                    class="reset-link-styles"
                  >
                    <v-chip outlined label @click="() => {}"
                      >Open
                      <v-icon small class="ml-2"
                        >mdi-open-in-new</v-icon
                      ></v-chip
                    >
                  </a>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            v-if="results?.subtasks?.length > 0"
            class="styled-card--default mt-4"
          >
            <v-card-title> Subtasks </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(task, idx) in results.subtasks"
                  :key="`task-${idx}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col>
                      <div v-if="task.name">
                        <v-icon small class="mr-2">mdi-tag-outline</v-icon
                        >{{ task.name }}
                      </div>
                      <div v-if="task.email" class="mt-2">
                        <v-icon small class="mr-2">mdi-account-outline</v-icon
                        >{{ task.email }}
                      </div>
                      <div
                        v-if="task.status === 'error' && task.content"
                        class="error--text"
                      >
                        {{ task.content }}
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <a
                        v-if="task.url"
                        :href="task.url"
                        target="_blank"
                        class="reset-link-styles"
                      >
                        <v-chip outlined label @click="() => {}"
                          >Open
                          <v-icon small class="ml-2"
                            >mdi-open-in-new</v-icon
                          ></v-chip
                        >
                      </a>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-btn @click="resultsDialogModel = false" class="mr-2" width="160px"
            >Edit current</v-btn
          >
          <v-btn @click="refreshPage" color="primary" width="220px"
            >Create Another</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import { deepClone } from "@/utils/functions";
import TaskCard from "../UI/TaskCard.vue";
import service from "@/plugins/service";
import TableSorting from "@/mixins/TableSorting";
import SmartAutocomplete from "@/components/UI/SmartAutocomplete.vue";

export default {
  components: { SmartAutocomplete, TaskCard },
  mixins: [TableSorting],
  data: () => ({
    performersTableHeaders: [
      { text: "Name", value: "name" },
      { text: "Role", value: "department" },
      { text: "WS Team", value: "group" },
      { text: "Team", value: "team" },
      { text: "email", value: "email" },
    ],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Choose type",
        to: { name: "similar-task-creating.choose-type" },
      },
      {
        text: "Task in subtask",
        disabled: true,
      },
    ],
    search: null,
    mainTask: {
      project_id: "158979", // required
      reporter: "", // required
      task_title: "", // required
      task_desc: "",
      priority: 8,
      date: [],
      tags: [],
      estimated_time: null,
      estimated_money: null,
    },
    subtaskForm: {
      performers: [],
      task_title: "",
      task_desc: "",
      priority: 8,
      date: [],
      tags: [],
      estimated_time: null,
      estimated_money: null,
    },
    loadings: {
      createTask: false,
      filters: false,
    },
    resultsHeaders: [
      {
        text: "User",
        value: "user_to",
      },
      {
        text: "Task Name",
        value: "name",
      },
    ],
    results: {
      subtasks: [],
      task: {},
    },
    resultsDialogModel: false,
    filtersData: {
      tags: [],
      users: [],
      projects: [],
    },
    menuProps: DEFAULT_MENU_PROPS,
    sortTableBy: {
      department: [],
      group: [],
      email: [],
      name: [],
    },
    tableFiltersData: {
      department: [],
      group: [],
      email: [],
      name: [],
    },
  }),
  created() {
    if (this.$route.query.project_id) {
      this.mainTask.project_id = this.$route.query.project_id;
    }

    this.fetchFilters();
  },
  computed: {
    getFilteredTableItems() {
      const amountOfSelectedFilters = Object.values(this.sortTableBy).filter(
        (v) => v.length > 0
      ).length;

      if (amountOfSelectedFilters <= 0) return this.filtersData.users;

      return this.filtersData.users.filter((v) => {
        let match = 0;

        Object.keys(this.sortTableBy).forEach((key) => {
          if (match >= amountOfSelectedFilters) return;

          if (this.sortTableBy[key].includes(v[key])) match++;
        });

        return match === amountOfSelectedFilters;
      });
    },
    validate() {
      if (!this.mainTask.project_id) return true;
      if (!this.mainTask.reporter) return true;
      if (!this.mainTask.task_title) return true;

      return false;
    },
  },
  methods: {
    initTableFilters(tableItems) {
      const skipThisValues = ["Verona - Maranello", "Boosta", "TS Media"];

      ["department", "group", "email", "name"].forEach((key) => {
        this.tableFiltersData[key] = [
          ...new Set(tableItems.map((obj) => obj[key])),
        ];

        const items = [...this.tableFiltersData[key]].filter(
          (value) => !skipThisValues.includes(value)
        );

        this.sortTableBy[key] = items;
      });
    },
    refreshPage() {
      this.$router.go();
    },
    handleCopyDataFromMainTask() {
      console.log(this.mainTask.tags);
      const main = this.mainTask;
      const tmpr = deepClone(this.subtaskForm);

      tmpr.task_title = main.task_title;
      tmpr.task_desc = main.task_desc;
      tmpr.priority = main.priority;
      tmpr.date = main.date;
      tmpr.tags = main.tags;
      tmpr.estimated_time = main.estimated_time;
      tmpr.estimated_money = main.estimated_money;

      this.subtaskForm = tmpr;

      console.log(this.subtaskForm);
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["tags", "users", "projects"];
      const payload = {
        type: "/similar-task-creating",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        this.filtersData = resp;
        this.initTableFilters(resp.users);
        console.log(this.tableFiltersData);
      }
      this.loadings.filters = false;
    },
    async handleCreateTask() {
      try {
        const url = "/worksection/task/create";

        const payload = {
          type: "CREATE_SUBTASKS",
          main_task: this.mainTask,
          sub_task: {
            ...this.subtaskForm,
            performers: this.subtaskForm.performers.map((v) => v.email),
          },
        };

        this.loadings.createTask = true;

        const resp = await service.post(url, payload);

        this.loadings.createTask = false;
        if (resp) {
          this.results = resp.data;
          this.resultsDialogModel = true;
        }
      } catch (e) {
        console.error(e);
        this.loadings.createTask = false;
      }
    },
    async fetchData() {
      const url = "";

      const payload = {
        filter: this.filters,
      };

      await axios.post(url, payload);
    },
  },
};
</script>

<style lang="scss">
.similar-task-creating {
  .v-select--chips {
    .v-select__selections {
      span {
        margin: 2px 4px;
      }
    }
  }
  &__editor {
    .v-sheet.v-toolbar {
      background-color: transparent !important;
    }

    .v-card {
      box-shadow: none !important;
    }

    .tiptap-vuetify-editor__content {
      min-height: 120px;
    }
  }
}
</style>
