<template>
  <div id="chart">
    <apexchart type="bar" :height="chartOptions.chart.height" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>


import VueApexCharts from "vue-apexcharts";

export default {
  name: "BarChart",
  props: ["series"],
  components: {apexchart: VueApexCharts},
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: '90px',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          x: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          labels: {
            show: false
          },
        },
        yaxis: {
          labels: {
            show: false
          },
        },
        legend: {
          show: false
        },
      },
    }
  },

}
</script>

<style scoped>
.apexcharts-xaxistooltip {
  background: #1B213B;
  color: #fff;
}

</style>