<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    dense
    class="dashboard-dev__signature-table"
    :footer-props="{
      'items-per-page-options': [15, 25, 50, 100, -1],
      showFirstLastPage: true,
    }"
    :items-per-page="15"
  >
    <template
      v-for="header in headers"
      #[`item.${header.value}`]="{ item, value }"
    >
      <div :key="header.value">
        <template v-if="header.value === 'idle'">
          <v-chip
            v-if="checkError(item) > 0"
            small
            label
            outlined
            color="error"
            class="signature-table__idle"
          >
            {{ $moment(item.last_success_launched_at).fromNow() }}
          </v-chip>
        </template>
        <template
          v-else-if="['created_at', 'updated_at'].includes(header.value)"
          :title="value"
        >
          <div>
            {{ value ? $moment(value).format("ll") : "" }}
          </div>
        </template>
        <template v-else-if="header.value === 'last_launch_success'">
          <div class="signature-table__last-succ-launch">
            <div>
              {{
                item.last_success_launched_at
                  ? $moment(item.last_success_launched_at).format("ll")
                  : ""
              }}
            </div>
          </div>
        </template>
        <template v-else-if="header.value === 'status'">
          <div class="signature-table__status">
            <v-icon v-if="checkError(item)" color="red" small>
              mdi-alert-circle-outline
            </v-icon>
            <v-icon v-else color="green" small>
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </div>
        </template>
        <template v-else>
          {{ value }}
        </template>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { PRESETS_TABLE_DATA } from "@/utils/defaultData";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    search: String,
  },
  data: () => ({
    search: "",
    headers: PRESETS_TABLE_DATA.signatureHeaders,
  }),
  methods: {
    checkError(item) {
      return parseInt(item.last_launched_success) === 0;
    },
  },
};
</script>

<style scoped></style>
