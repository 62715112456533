<template>
  <div class="n-ldd" style="position: relative">
    <v-row dense>
      <v-col cols="11">
        <page-name :theme="$vuetify.theme.dark" small="">
          New Links Dynamic Dashboard
        </page-name>
      </v-col>
      <v-col cols="1" class="d-flex justify-end align-center">
        <small style="opacity: 0.5">v 2.0</small>
      </v-col>
    </v-row>
    <v-row dense>
      <links-dynamic-dashboard-filters
        :model-value="filters"
        @update:modelValue="filters = $event"
        :filters-data="filtersData"
        :loadings="loadings"
        @getData="handleGetData"
      />
    </v-row>
    <template>
      <v-row dense>
        <v-col v-for="card in getSortedCards" cols="12" :key="card.key">
          <div :id="cardsData[card.key].id">
            {{ cardsData[card.key].name }}
            <links-dynamic-dashboard-table-manager
              :data="card"
              :active-url-filter="getSelectedURLFilter"
              @note="showIndividualNote"
            />
          </div>
        </v-col>
        <template v-for="key in Object.keys(cardsData)">
          <v-col
            cols="12"
            :key="cardsData[key].id"
            v-if="sectionLoadings.includes(cardsData[key].sectionName)"
            class="n-ldd__section-placeholder"
          >
            Loading {{ cardsData[key].name }} section
          </v-col>
        </template>
      </v-row>
    </template>
    <v-menu left>
      <template #activator="{ on }">
        <v-btn fab fixed right bottom v-model="fab" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list nav dense>
        <v-list-item>
          <fast-travel />
        </v-list-item>
        <v-list-item>
          <v-btn
            @click="notesDialogModel = !notesDialogModel"
            color="primary"
            :disabled="!filters.url"
            outlined
            block
          >
            Notes
            <v-icon class="ml-2">mdi-note-outline</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn text block @click="scrollToTop">
            To top
            <v-icon class="ml-2">mdi-arrow-up</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <notes-dialog
      :model-value="notesDialogModel"
      :url="filters.url"
      @update:modelValue="notesDialogModel = $event"
      @save="handleSaveNotes"
    />
    <notes-dialog
      :model-value="individualUrlNoteDialogModel"
      :url="filters.url"
      :acceptor="acceptor"
      @update:modelValue="individualUrlNoteDialogModel = $event"
      @save="handleSaveNotes"
    />
  </div>
</template>

<script>
import PageName from "@/components/DashboardDev/PageName";
import Location from "@/mixins/Location";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import {
  LINKS_DYNAMIC_CHART_OPTIONS,
  LINKS_DYNAMIC_DASHBOARD_SECTION_NAMES,
  LINKS_DYNAMIC_DASHBOARD_SECTIONS_DATA,
  PROJECT_ID_IDTF,
} from "@/utils/defaultData";
import VueApexCharts from "vue-apexcharts";
import eventPipe from "@/events/eventPipe";
import Project from "@/mixins/Project";
import FastTravel from "@/components/OnePageAnalyzer/LinksDynamicDashboard/FastTravel";
import NotesDialog from "@/components/OnePageAnalyzer/LinksDynamicDashboard/NotesDialog";
import GetRgb from "@/mixins/GetRgb";
import Notification from "@/mixins/Notification";
import LinksDynamicDashboardTableManager from "@/components/OnePageAnalyzer/NewLinksDynamicDashboard/UI/LinksDynamicDashboardTableManager";
import LinksDynamicDashboardFilters from "@/components/OnePageAnalyzer/NewLinksDynamicDashboard/UI/LinksDynamicDashboardFilters";

export default {
  components: {
    LinksDynamicDashboardFilters,
    LinksDynamicDashboardTableManager,
    NotesDialog,
    FastTravel,
    PageName,
    // eslint-disable-next-line vue/no-unused-components
    apexchart: VueApexCharts,
  },
  mixins: [Location, FiltersHandling, Project, GetRgb, Notification],
  async created() {
    eventPipe.$on("update-active-project", () => {
      const project_id = this.$store.getters.active_project;
      this._$handleFilterChange(project_id, PROJECT_ID_IDTF);
      this.fetchFilters();
    });

    this.filter_type = "all";

    const queryProjectId = this.$route.query[PROJECT_ID_IDTF];
    if (queryProjectId) {
      eventPipe.$emit("replace-project", queryProjectId);
    }

    this.initSelectedProject();

    this.fetchFilters();

    const haveParams = this._$collectParams(
      [
        "link_type_hidden",
        "gi",
        "link_type",
        "anchor_type",
        "do_follow",
        "ah_dr",
        "ah_traffic",
        "ah_url_traffic",
        "ah_2nd_level",
        "sw_rank",
        "nums_of_pages",
        "ah_tr_rd",
        "ah_ur",
        "date",
      ],
      "filters"
    );

    if (!this.$route.query.date) {
      this.filters.date = this.getDefaultDate;
    }

    if (haveParams) {
      return this.fetchData();
    }
  },
  data: () => ({
    sectionLoadings: [],
    filters: {
      project_id: null,
      link_type_hidden: [94, 93, 95],
      gi: [],
      link_type: [],
      anchor_type: [],
      do_follow: [],
      ah_dr: [0, 0],
      ah_traffic: [0, 0],
      title_thematic: null,
      gt: null,
      ah_url_traffic: [0, 0],
      ah_2nd_level: [0, 0],
      sw_rank: [0, 0],
      nums_of_pages: [0, 0],
      ah_tr_rd: [0, 0],
      ah_ur: [0, 0],
      date: [],
      url: null,
      filter_type: "",
    },
    filtersData: {
      url: [],
      link_type: [],
      gi: [],
      anchor_type: [],
      dofollow: [],
    },
    cardsData: LINKS_DYNAMIC_DASHBOARD_SECTIONS_DATA,
    sectionNames: LINKS_DYNAMIC_DASHBOARD_SECTION_NAMES,
    // old
    fab: false,
    acceptor: null,
    individualUrl: "",
    notesDialogModel: false,
    individualUrlNoteDialogModel: false,
    urlFilter: null,
    content: "",
    loadings: {
      filters: false,
      unload: false,
      table: false,
    },
    cards: [],
    selectedProject: null,
    chartOptions: {},
  }),
  watch: {
    "filters.url"(val) {
      this.urlFilter = val;
    },
  },
  computed: {
    getSortedCards() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.cards.sort((a, b) => {
        if (a.priority > b.priority) return 1;
        if (a.priority < b.priority) return -1;
        return 0;
      });
    },
    getDefaultDate() {
      const start = this.$moment(new Date())
        .subtract(200, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).format("YYYY-MM-DD");
      return [start, end];
    },
    getSelectedURLFilter() {
      return this.filtersData.url.find(
        (item) => item.value === this.filters.url
      );
    },
  },
  methods: {
    showIndividualNote(urlData) {
      console.log(urlData);
      this.individualUrl = urlData.value;
      this.acceptor = urlData.id;
      this.individualUrlNoteDialogModel = true;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleSaveNotes(content) {
      this.savePost(content);
    },
    savePost(content) {
      console.log("publish", content);
    },
    initSelectedProject() {
      this.selectedProject = this.$store.state.project.allowed_projects;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["url", "link_type", "gi", "anchor_type", "dofollow"];
      const payload = {
        type: "/one-page-analyzer/links-dynamic/main",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    collectParamsFromQuery() {
      const keys = Object.keys(this.$route.query);
      const { date, url, project_id } = this.FILTERS_QUERY_IDS;
      const dates = [date];
      const urls = [url, project_id];
      keys.forEach((key) => {
        const value = this.$route.query[key];

        if (dates.includes(key)) {
          const newValue = value.split(",");
          return (this[key] = newValue);
        }
        if (urls.includes(key)) {
          return (this[key] = Number(value));
        }

        const newValue = value.split(",").map((item) => Number(item));
        return (this[key] = newValue);
      });

      if (keys.length > 0) {
        return {
          haveParams: true,
          haveDate: keys.includes(this.FILTERS_QUERY_IDS.date),
        };
      }
      return false;
    },
    handleFilterChange(event, type) {
      this._$handleFilterChange(event, type);
    },
    handleRangeChange(event, type) {
      this._$handleRangeChange(event, type);
    },
    handleUpdateDateFilter(event) {
      this.date = event;
      if (!event.length) {
        event = null;
      }
      this.handleFilterChange(event, this.FILTERS_QUERY_IDS.date);
    },
    getFiltersData() {
      const output = {};
      const filterKeys = Object.keys(this.FILTERS_QUERY_IDS);
      filterKeys.forEach((key) => {
        output[key] = this[key];
      });
      return output;
    },
    validate() {
      if (!this.$store.getters.active_project || !this.filters.url) {
        return true;
      }
      return false;
    },
    async fetchSection(sectionName) {
      this.firstEnter = true;
      this.loadings.table = true;
      this.sectionLoadings.push(sectionName);

      if (this.validate()) {
        this.loadings.table = false;
        return this.mWarning({
          title: "Links Dynamic Dashboard. Validation. ⚠",
          text: "Url and Project are required filters.",
        });
      }

      const payload = {
        section: sectionName,
        filter: {
          ...this.filters,
          project_id:
            this.filters.project_id || this.$store.getters.active_project,
        },
      };

      const resp = await this.$store.dispatch(
        "links-dynamic-dashboard/fetchData",
        payload
      );

      if (resp) {
        Object.keys(resp.items).forEach((key) => {
          if (key === "chart") {
            return (this.chartOptions = {
              ...LINKS_DYNAMIC_CHART_OPTIONS,
              xaxis: {
                categories: resp.items.chart.categories,
              },
            });
          }

          if (key === "table") {
            const priority = this.cardsData["all_links"].priority;
            return this.cards.push({
              key: "all_links",
              priority,
              ...resp.items.table,
            });
          }
          const priority = this.cardsData[key].priority;

          this.cards.push({ key, priority, ...resp.items[key] });
        });
      }

      this.$forceUpdate();

      this.loadings.table = false;
      this.sectionLoadings = this.sectionLoadings.filter(
        (v) => v !== sectionName
      );
    },
    async fetchData() {
      if (!this.filters.url) return;

      this.sectionNames.forEach((name) => {
        this.fetchSection(name);
      });
    },
    handleGetData() {
      this.fetchData();
    },
  },
};
</script>
