<template>
  <div class="blog-page blog" data-test="home-page">
    <v-container style="padding-bottom: 200px">
      <v-row v-if="allowedEdit">
        <v-col>
          <v-card
            class="styled-card--default mx-auto"
            rounded="rl"
            max-width="900"
          >
            <v-card-title class="d-flex">
              <span>Create notification</span>
              <v-spacer />
              <v-chip
                outlined
                label
                @click="$router.push({ name: 'blog.admin' })"
                >Admin panel
                <v-icon right small>mdi-open-in-new</v-icon></v-chip
              >
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="min-height: 200px">
              <tiptap-vuetify v-model="content" :extensions="extensions" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="pa-4 d-flex justify-end">
              <v-row dense>
                <v-col>
                  <v-autocomplete
                    v-model="module"
                    :items="modules"
                    filled
                    dense
                    clearable
                    label="Title"
                    placeholder="Type to search"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="module"
                    filled
                    hide-details
                    dense
                    clearable
                    label="Write own title"
                    placeholder="Enter title"
                  />
                </v-col>
                <v-col cols="3">
                  <div class="fix-button-height">
                    <v-btn
                      block
                      :disabled="invalid"
                      color="primary"
                      @click="publishPost"
                      class="b-rb-shaped-4"
                    >
                      Publish
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row align-content="center" justify="center">
        <v-col cols="6">
          <v-progress-linear
            v-if="loading"
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-if="posts.length === 0">
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            max-width="360px"
            style="text-align: center"
            rounded="xl"
            class="shadow-e1-bordered no-posts-placeholder"
          >
            <v-card-title class="justify-center pb-0">
              <div style="max-width: 240px">
                <dog-and-newspapper />
              </div>
            </v-card-title>

            <v-card-title style="font-size: 28px" class="justify-center pt-0">
              No Posts
            </v-card-title>
            <v-card-text class="pb-6">
              There are no new posts yet. When they are posted you will see them
              here.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-for="post in posts" :key="post.id">
        <v-col>
          <v-card
            class="mx-auto blog-page__article"
            max-width="900"
            outlined
            rounded="lg"
          >
            <v-card-title>
              {{ post.module }}
              <v-chip
                v-if="post.minutes <= 60 * 20"
                color="info"
                class="ml-2"
                small
              >
                NEW
              </v-chip>
            </v-card-title>
            <v-card-subtitle>
              {{ $moment(post.created_at).fromNow() }}
            </v-card-subtitle>
            <v-card-text
              v-html="post.content"
              class="article__content"
            ></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
import axios from "axios";
import DogAndNewspapper from "../UI/Icons/DogAndNewspapper";

export default {
  name: "HomeIndex",
  data() {
    return {
      test: [
        {
          name: "1",
          childs: [
            {
              name: "2",
            },
            {
              name: "3",
              childs: [
                {
                  name: "4",
                },
              ],
            },
          ],
        },
      ],
      loading: false,
      posts: [],
      module: "",
      modules: [
        "App",
        "Content Checker",
        "Niche Analyzer",
        "Keyword Control",
        "Site Workplace",
        "LTT",
        "Search Console",
        "Analytics",
        "Aparser",
        "Anchors Plan",
        "Donor Base",
        "One Page Analyzer",
        "View Info By Site",
        "Mailer",
        "GP Topic Tool",
        "GPM",
        "DCT",
        "Textrazor",
        "Positions",
      ],
      content: "",
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
        Image,
      ],
      headers: [],
      tour: null,
    };
  },
  components: { DogAndNewspapper, TiptapVuetify },
  computed: {
    invalid() {
      return !(
        this.content.replace(new RegExp("<.+?>|\\s+", "ig"), "").trim().length >
          50 && this.module.length > 0
      );
    },
    allowedEdit() {
      return (
        [100, 24, 35, 28, 40, 98, 971].indexOf(
          this.$store.state.auth.user.id
        ) !== -1
      );
    },
  },
  methods: {
    handleNextStep() {
      this.tour.next();
    },
    publishPost() {
      if (!window.confirm("Post this notification?")) return;

      let self = this;

      let url = `${self.$store.state.server_url}/changelog`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            content: self.content,
            module: self.module,
          },
          config
        )
        .then(() => {
          self.content = "";
          self.module = "";
        });
    },
    getPosts() {
      let self = this;

      let url = `${self.$store.state.server_url}/changelog`;

      self.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.posts = r.data.posts;
        })
        .finally(() => (self.loading = false));
    },
  },
  created() {
    this.getPosts();
  },
};
</script>

<style>
img {
  max-width: 100%;
}
</style>
