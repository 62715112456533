<template>
  <v-container
    fluid
    class="monthly-statistics-page"
    style="padding-bottom: 200px"
  >
    <v-row>
      <v-col cols="12">
        <h2>Monthly statistics</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col>
        <smart-date-filter
          :model-value="filters.created_date"
          @update:modelValue="filters.created_date = $event"
          label="Created"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.period_goals"
          hide-details
          dense
          item-text="title"
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.period_goals"
          outlined
          multiple
          label="Period Goals"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.executive"
          hide-details
          dense
          item-text="email"
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.executives"
          outlined
          clearable
          label="Executive"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.team"
          hide-details
          dense
          :loading="loadings.filters"
          item-text="name"
          item-value="id"
          :items="filtersData.teams"
          outlined
          clearable
          label="Team"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.table_type"
          hide-details
          dense
          :items="[
            {
              text: 'By user',
              value: 'monthly_analytic_by_user',
            },
            {
              text: 'By Month',
              value: 'monthly_analytic_by_month',
            },
          ]"
          outlined
          clearable
          label="Table type"
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          label
          @click="initDashboard"
          color="primary"
          block
          class="text-normal"
          height="40"
          :loading="loadings.dashboard"
          :disabled="loadings.dashboard"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loadings.dashboard">
      <v-col cols="12">
        <div class="d-flex">
          <div class="d-flex" style="gap: 8px">
            <div class="skeleton" style="height: 32px; width: 250px"></div>
            <div class="skeleton" style="height: 32px; width: 250px"></div>
          </div>
          <v-spacer />
          <div class="d-flex align-end" style="gap: 8px">
            <div class="skeleton" style="height: 30px; width: 130px"></div>
            <div class="skeleton" style="height: 30px; width: 90px"></div>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card class="styled-card--default">
          <v-card-title>
            <div class="skeleton" style="width: 150px; height: 24px" />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-skeleton-loader type="table" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loadings.dashboard && !tableData.items?.length">
      <v-col class="d-flex justify-center my-6">
        <nothing-found-by-filters />
      </v-col>
    </v-row>
    <v-row v-if="!loadings.dashboard">
      <v-col>
        <v-data-table
          :class="`border rounded-lg ${currentTableType}`"
          :items="tableData.items"
          :headers="tableData.headers"
        >
          <template
            v-for="key in ['Month_A', 'Month_B', 'Month_C', 'role', 'team']"
            #[`item.${key}`]="{ value }"
          >
            <template v-if="['Month_A', 'Month_B', 'Month_C'].includes(key)">
              <v-chip
                v-if="value !== ''"
                label
                :class="`flat-chip--auto ${getGradientColor(value, '--text')}`"
                :key="key"
              >
                {{ value }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip outlined :key="key">
                <v-icon class="mr-1" small :color="strToColor(value)">
                  mdi-circle
                </v-icon>
                {{ value }}
              </v-chip>
            </template>
          </template>
          <template #[`item.total_done`]="{ value }">
            <v-chip v-if="value !== ''" label :color="getGradientColor(value)">
              {{ value }}
            </v-chip>
          </template>
          <template #[`item.email`]="{ value }">
            <div class="d-flex align-center" style="gap: 0.5rem">
              <user-preview :email="value" :opacity="1" hide-initials />
              {{ value }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="computedStatisticsValid" dense>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="2" style="min-width: 215px">
            <month-overall-card :data="computedOverallMonth" />
          </v-col>
          <v-col>
            <div
              style="
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 0.25rem;
              "
            >
              <div
                v-for="team in computedOverallMonthStatisticTeams"
                :key="team?.name"
              >
                <month-overall-card-by-team :data="team" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/*eslint-disable*/
import { Endpoints, MonthlyMovementByTaskBreadcrumbs } from "../defaults";
import service from "../../../plugins/service";
import TableSorting from "../../../mixins/TableSorting";
import NothingFoundByFilters from "@/components/UI/Placeholders/NothingFoundByFilters.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import VuetifyTableHeadersExtensionMixin from "../../../mixins/data-table/VuetifyTableHeadersExtensionMixin";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import getTableColBgGradientColorMixin from "../../../mixins/getTableColBgGradientColorMixin";
import MonthOverallCard from "../../MonthlyMovementByTask/Components/MonthOverallCard.vue";
import MonthOverallCardByTeam from "../../MonthlyMovementByTask/Components/MonthOverallCardByTeam.vue";
import UserPreview from "../../UI/UserPreview.vue";
import { strToRGB } from "../../../functions";

export default {
  components: {
    UserPreview,
    MonthOverallCardByTeam,
    MonthOverallCard,
    SmartDateFilter,
    NothingFoundByFilters,
  },

  mixins: [
    TableSorting,
    FiltersHandling,
    VuetifyTableHeadersExtensionMixin,
    getTableColBgGradientColorMixin,
  ],

  data: () => ({
    statistic: {},
    breadcrumbs: MonthlyMovementByTaskBreadcrumbs,
    tableData: {
      items: [],
      headers: [],
    },
    currentTableType: "monthly_analytic_by_user",
    filters: {
      created_date: [null, null],
      team: null,
      executive: null,
      period_goals: [],
      table_type: "monthly_analytic_by_user",
    },
    loadings: {
      filers: false,
      dashboard: false,
    },
    filtersData: {
      team: [],
      executive: [],
    },
  }),

  async mounted() {
    this.fetchFilters();
    await this.initializeFiltersWatcher("filters");
    await this.collectParametersFromQuery("filters");

    if (Object.keys(this.$route.query).length > 0) this.initDashboard();
  },

  computed: {
    computedStatisticsValid() {
      return (
        this.statistic &&
        typeof this.statistic === "object" &&
        Object.keys(this.statistic)?.length
      );
    },
    computedOverallMonth() {
      // Month overall statistics card data adapter
      if (
        this.statistic &&
        this.statistic?.Month &&
        (this.statistic?.Month_A ||
          this.statistic?.Month_B ||
          this.statistic?.Month_C)
      ) {
        return {
          total: this.statistic?.Month,
          months: [
            {
              name: "Month A",
              data: this.statistic?.Month_A,
            },
            {
              name: "Month B",
              data: this.statistic?.Month_B,
            },
            {
              name: "Month C",
              data: this.statistic?.Month_C,
            },
          ],
        };
      }

      return {};
    },
    computedOverallMonthStatisticTeams() {
      // Month overall statistics team card data adapter
      if (
        this.statistic?.team &&
        typeof this.statistic.team === "object" &&
        Object.keys(this.statistic.team).length
      ) {
        return [
          this.overallTeamCardAdapter(
            this.statistic?.team?.Management,
            "Management"
          ),
          this.overallTeamCardAdapter(this.statistic?.team?.Jets, "Jets"),
          this.overallTeamCardAdapter(this.statistic?.team?.Enigma, "Enigma"),
          this.overallTeamCardAdapter(this.statistic?.team?.Content, "Content"),
          this.overallTeamCardAdapter(this.statistic?.team?.["LB"], "LB"),
        ];
      }

      return [];
    },
  },

  methods: {
    overallTeamCardAdapter(teamData, name) {
      if (!teamData) return null;

      return {
        name,
        total: {
          all: teamData?.all,
          done: teamData?.done,
          p: teamData?.p,
        },
        months: [
          {
            name: "Month A",
            data: teamData?.Month_A,
          },
          {
            name: "Month B",
            data: teamData?.Month_B,
          },
          {
            name: "Month C",
            data: teamData?.Month_C,
          },
        ],
      };
    },
    strToColor(text) {
      if (!text) return "";

      const { red, green, blue } = strToRGB(text);
      return `rgba(${red},${green},${blue}, 1)`;
    },
    getGradientColor(percent, classPostfix = "") {
      if (percent <= 33) return `error${classPostfix}`;
      if (percent > 33 && percent <= 77) return `warning${classPostfix}`;
      if (percent > 77) return `success${classPostfix}`;

      return "";
    },

    async fetchFilters() {
      this.loadings.filters = true;
      const url = "/worksection/get/monthly/filter";

      const resp = await service.post(url, {});

      if (resp) this.filtersData = resp.data;

      this.loadings.filters = false;
    },

    async initDashboard() {
      try {
        this.loadings.dashboard = true;

        const { data } = await service.post(Endpoints.GetMonthlyStatistics, {
          filter: this.filters,
        });

        this.statistic = data?.statistic || {};
        this.tableData.items = data?.items || [];
        this.tableData.headers = data?.headers || [];

        this.$nextTick(() => {
          this.currentTableType = this.filters.table_type;
        });
      } catch (e) {
        console.error("Something went wrong while initializing dashboard.", e);
      } finally {
        this.loadings.dashboard = false;
      }
    },
  },
};
</script>

<style lang="scss">
.monthly-statistics-page {
  .v-data-table {
    &.monthly_analytic_by_user {
      table {
        tbody {
          tr {
            td:nth-child(1),
            td:nth-child(3),
            td:nth-child(6) {
              border-right: 1px solid rgba(155, 155, 155, 0.5);
            }
          }
        }
      }
    }
    &.monthly_analytic_by_month {
      table {
        tbody {
          tr {
            td:nth-child(1),
            td:nth-child(4) {
              border-right: 1px solid rgba(155, 155, 155, 0.5);
            }
          }
        }
      }
    }
  }
}
</style>
