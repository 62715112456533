<template>
  <v-dialog
    v-model="dialog"
    content-class="remove-dialog-shadow"
    @close="$store.commit('setSelectProjectMenu', false)"
    @click:outside="$store.commit('setSelectProjectMenu', false)"
    max-width="460"
    scrollable
  >
    <v-card rounded="lg" flat outlined>
      <v-card-title class="d-flex text-body-2">
        Select project
        <v-spacer />
        <v-btn icon @click="$store.dispatch('toggle_select_project_menu')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="pa-0">
        <v-text-field
          v-model="search"
          hide-details
          filled
          dense
          label="Type to search"
          prepend-inner-icon="mdi-magnify"
        />
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-3">
        <div
          v-if="!getFilteredProjects?.length"
          class="d-flex flex-column"
          style="gap: 0.25rem"
        >
          <div
            v-for="i in [0, 1, 2, 3, 4]"
            :key="i"
            class="skeleton rounded-lg"
            style="width: 100%; height: 40px"
          />
        </div>
        <div v-else>
          <v-list
            dense
            class="pa-0"
            v-if="
              Array.isArray(getFilteredProjects) &&
              getFilteredProjects.length > 0
            "
          >
            <v-list-item
              v-for="project in getFilteredProjects"
              :key="project.id"
              class="rounded-lg"
              @click="handleSetActiveProject(project.id)"
            >
              {{ project.name }} <v-spacer />
              <v-icon small>mdi-plus-box</v-icon>
            </v-list-item>
          </v-list>
          <template v-else>
            <div class="py-4 d-flex justify-center">
              <span>Nothing found...</span>
            </div>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Project from "@/mixins/Project";
import { deepClone } from "@/utils/functions";

export default {
  mixins: [Project],
  data: () => ({
    search: null,
  }),
  computed: {
    dialog() {
      return this.$store.getters["menu_select_projects"];
    },
    getFilteredProjects() {
      const tmpr = deepClone(this._$getAllowedProjects);

      if (!this.search) return tmpr;

      return tmpr.filter((v) =>
        String(v.name).toLowerCase().includes(String(this.search).toLowerCase())
      );
    },
  },
  methods: {
    handleSetActiveProject(id) {
      this._$setActiveProject(id, true);
      this.$store.dispatch("toggle_select_project_menu");
    },
  },
};
</script>
