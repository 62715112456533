import Service from "@/plugins/service";

export default {
  state: {
    tableData: null,
  },
  mutations: {
    "analyticsPageType/setTableData"(state, payload) {
      state.tableData = payload;
    },
  },
  actions: {
    async "analyticsPageType/fetchDataTable"(
      { commit },
      { filters, projectId }
    ) {
      const url = `/analytics/general-analytics-by-page-type/json/${projectId}`;
      const response = await Service.post(url, filters, true);
      if (response) {
        commit("analyticsPageType/setTableData", response.data);
      }
    },
    async "analyticsPageType/fetchCountries"() {
      const url = "/static/get-countries";
      const response = await Service.get(url);
      if (response) {
        return response.data;
      }
    },
  },
  getters: {
    "analyticsPageType/tableData": (state) => state.tableData,
  },
};
