<template>
  <div
    :class="`conv-7-smart-td ${isPercent ? 'percent' : ''}`"
    :style="{
      background: getColor,
    }"
  >
    <template v-if="data.value !== 0">
      <span>
        {{ data.value }}
      </span>
      <sup v-if="getType === 'diff' && data.diff !== '0%'">
        <span :style="{ color: data.direction === 1 ? 'green' : 'red' }">
          {{ data.diff }}
        </span>
      </sup>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data", "isPercent"],
  data: () => ({
    color: "transparent",
  }),
  computed: {
    getColor() {
      const percent = parseInt(this.data.diff);
      const negativeSValueP =
        this.getSValueP > 0 ? parseInt(`-${this.getSValueP}`) : this.getSValueP;

      if (!percent) {
        return "transparent";
      }

      if (percent > this.getSValueP) {
        return "rgba(107,224,75,0.30)";
      }
      if (percent < negativeSValueP) {
        return "rgba(224,32,32,0.30)";
      }
      return "transparent";
    },
    getSValueP() {
      return this.$store.getters["conversion-7/sValueP"];
    },
    getType() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.data.hasOwnProperty("diff")) {
        return "diff";
      }
      return "default";
    },
  },
};
</script>
