<template>
  <div :class="`${data.subproject !== '' ? 'subproj-td' : ''}`">
    <template v-if="data && data.subproject">
      <v-chip small label class="flat-chip flat-chip--primary">
        <v-icon small left>mdi-laptop</v-icon>
        {{ data.subproject }}
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data"],
  methods: {},
};
</script>
