var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-page-type"},[_c('v-row',{staticClass:"mb-6",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("General Analytics by Page Type")])])],1),_c('v-row',{staticClass:"align-content-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('smart-date-filter',{attrs:{"model-value":_vm.filters.date_range},on:{"update:modelValue":function($event){_vm.filters.date_range = $event}}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.subprojects,"menu-props":{
          maxHeight: '400',
          rounded: 'lg',
          transition: 'slide-x-transition',
        },"label":"Subproject","clearable":"","dense":"","hide-details":"","outlined":"","item-text":"name","item-value":"id"},model:{value:(_vm.filters.subproject_id),callback:function ($$v) {_vm.$set(_vm.filters, "subproject_id", $$v)},expression:"filters.subproject_id"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.filtersData.countries,"menu-props":{
          maxHeight: '400',
          rounded: 'lg',
          transition: 'slide-x-transition',
        },"label":"Country","multiple":"","clearable":"","dense":"","hide-details":"","outlined":"","item-text":"name","item-value":"id"},model:{value:(_vm.filters.country_id),callback:function ($$v) {_vm.$set(_vm.filters, "country_id", $$v)},expression:"filters.country_id"}})],1),(_vm.filters.subproject_id === 17 || _vm.filters.subproject_id === 37)?_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"custom-input-item__wrapper"},[_c('v-autocomplete',{staticClass:"custom-input-item",attrs:{"items":[
            { name: 'category', id: 1 },
            { name: 'samples', id: 3 },
          ],"menu-props":{
            maxHeight: '400',
            rounded: 'lg',
            transition: 'slide-x-transition',
          },"label":"Part","clearable":"","item-text":"name","item-value":"id"},model:{value:(_vm.filters.part_id),callback:function ($$v) {_vm.$set(_vm.filters, "part_id", $$v)},expression:"filters.part_id"}})],1)]):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2",staticStyle:{"height":"100%"},attrs:{"outlined":"","color":"primary","loading":_vm.loadings.export},on:{"click":_vm.handleExportProgress}},[_vm._v(" Export Progress ")]),_c('v-btn',{staticClass:"reset-box-shadow",staticStyle:{"height":"100%"},attrs:{"width":"120px","color":"primary","loading":_vm.loading},on:{"click":_vm.getData}},[_vm._v(" Get Data")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('section',{staticClass:"analytics-page-type__section analytics-page-type__tooltips"},[_c('div',{staticClass:"sections-tooltip__wrapper"},_vm._l((Object.keys(_vm.tableHeaderSections)),function(key,index){return _c('div',{key:index,staticClass:"sections-tooltip",on:{"click":function($event){return _vm.findSection($event, _vm.tableHeaderSections[key])}}},[_c('div',{staticClass:"color-box",style:(`width: 10px;height: 10px;background: ${_vm.tableHeaderSections[key].color}`)}),_vm._v(" "+_vm._s(key)+" ")])}),0)])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('section',{staticClass:"analytics-page-type__section analytics-page-type__table"},[_c('v-row',[_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('Loader',{attrs:{"show":_vm.loading}}),_c('v-data-table',{staticClass:"custom-by-page-type-table",attrs:{"headers":_vm.tableData.headers,"items":_vm.tableData.items,"custom-sort":_vm.customSort,"footer-props":{
                itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
              },"items-per-page":50,"multi-sort":""},scopedSlots:_vm._u([{key:"item.url",fn:function({ item: { url } }){return [_c('td',{staticStyle:{"max-width":"500px"}},[_vm._v(" "+_vm._s(url)+" ")])]}},{key:"item.traffic",fn:function({ item: { traffic } }){return [_c('ByPageTypeDiffTableItem',{attrs:{"item":traffic}})]}},{key:"item.top_1",fn:function({ item: { top_1 } }){return [_c('ByPageTypeDiffTableItem',{attrs:{"item":top_1}})]}},{key:"item.top_3",fn:function({ item: { top_3 } }){return [_c('ByPageTypeDiffTableItem',{attrs:{"item":top_3}})]}},{key:"item.top_10",fn:function({ item: { top_10 } }){return [_c('ByPageTypeDiffTableItem',{attrs:{"item":top_10}})]}}])},[_c('template',{slot:"no-data"},[_vm._v(" Change filters and try again ")])],2)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }