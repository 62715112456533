<template>
  <div class="d-flex" style="gap: 8px; width: 100%">
    <div>{{ title || "# No title" }}</div>
    <template v-if="data.navigate_to && data.project_id !== null">
      <router-link :to="getRouterLink" target="_blank">
        <v-chip label outlined @click="() => {}">
          View details <v-icon small right>mdi-open-in-new</v-icon>
        </v-chip>
      </router-link>
    </template>
    <v-spacer />
    <template v-if="data.download">
      <a
        v-for="item in data.download"
        :href="service.getUrl() + item.path"
        :key="item.path"
        target="_blank"
      >
        <v-chip label outlined class="mr-2" @click="() => {}">
          {{ item.name }} <v-icon small right>mdi-download</v-icon>
        </v-chip>
      </a>
    </template>
    <slot name="append" />
  </div>
</template>

<script>
import QueryBuilder from "@/mixins/QueryBuilder";
import service from "@/plugins/service";

export default {
  props: {
    title: String,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [QueryBuilder],
  data: () => ({
    links: {
      "pages-info": {
        name: "dashboards.pages-info",
      },
      technical: {
        name: "dashboards.technical",
      },
      "contentball-analytic": {
        name: "contentball-analytic",
        params: [["site", "project"], "team", "main_seo", "niche"],
      },
      "top-5-pages-conversion": {
        name: "analytics.conversion.top-5-pages-conversion",
        params: ["project"],
      },
    },
  }),
  computed: {
    service() {
      return service;
    },
    getRouterLink() {
      const mock = {};
      const params = {};
      const pageData = this.links[this.data.navigate_to] || mock;
      const project_id =
        this.data.project_id || this.$store.getters["project/active_project"];
      const filters = this.$store.getters["fourDxMainDashboard/getFilters"];
      const pathName = pageData.name || "home";

      if (pageData.params) {
        pageData.params.forEach((param) => {
          if (Array.isArray(param)) {
            const [name, filtersName] = param;

            return (params[name] = filters[filtersName]);
          }

          params[param] = filters[param];
        });
      }

      const routeData = this.$router.resolve({
        name: pathName,
      });

      const query = this._$buildQuery({ project_id, ...params });

      return routeData.href + query;
    },
  },
  methods: {},
};
</script>
