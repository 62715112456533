<template>
  <tbody>
  <tr
      v-for="(row, index) in items"
      :key="index"
  >
    <td
        v-for="(key, i) in Object.keys(row)"
        :key="key + i"
        :style="{
          'background-color':
          items.length - 1 === index
          ? getRgb(row[key].value, getMax(row, i), 50, 233, 153)
          : null
        }"
    >
      <template v-if="i === 0">
        {{ row[key] }}
      </template>
      <template v-else>
        <conv6-smart-td :data="row[key]"/>
      </template>
    </td>
  </tr>
  </tbody>
</template>

<script>
import Conv6SmartTd from "@/components/Analytics/Conversion/UI/Conversion6/Td/Conv6SmartTd";
import GetRgb from "@/mixins/GetRgb";

export default {
  mixins: [GetRgb],
  components: {Conv6SmartTd},
  props: ['items'],
  data: () => ({
    green: 'rgba(50,136,44,0.21)'
  }),
  methods: {
    getMax(items) {
      let bigger = 0;

      const keys = Object.keys(items)

      keys.forEach(key => {
        const val = items[key].value
        if (typeof val !== 'string') {
          if (parseInt(val) > bigger) bigger = parseInt(val)
        }
      })

      return bigger
    },
  }
}
</script>