<template>
  <div class="app-comments">
    <v-row dense>
      <v-col cols="12">
        <div v-if="data.length > 0" class="app-comments__items">
          <div
            v-for="comment in data"
            :key="comment.id"
            :class="{
              'app-comments__item': 1,
              edited: commentsOnEdit.includes(comment.id),
            }"
          >
            <div
              ref="comment"
              class="item__content"
              :data-id="comment.id"
              :contenteditable="commentsOnEdit.includes(comment.id)"
              v-text="comment.text"
              @keydown.enter.exact.prevent="editComment(comment)"
            />
            <div class="item__after">
              <svg
                width="27"
                height="18"
                viewBox="0 0 27 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 18C18 12 16.8333 6.33333 18 0.5L0 18H27Z"
                  fill="#097EFF"
                />
              </svg>
            </div>
            <div class="item__date">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <div v-on="on">
                    {{
                      !comment.date
                        ? $moment(comment.created_at).fromNow()
                        : $moment(comment.date).format("DD/MM/YYYY")
                    }}
                  </div>
                </template>
                <div>
                  {{
                    !comment.date
                      ? $moment(comment.created_at).format("DD/MM/YYYY")
                      : $moment(comment.date).format("DD/MM/YYYY")
                  }}
                </div>
              </v-tooltip>
            </div>
            <div class="item__edit-btn">
              <v-menu
                bottom
                rounded="lg"
                :close-on-content-click="false"
                content-class="shadow-e1-bordered"
              >
                <template #activator="{ on }">
                  <v-btn text fab x-small v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <div class="comment__actions">
                  <v-list dense nav>
                    <v-list-item dense @click="editComment(comment)">
                      <v-list-item-icon>
                        <v-icon
                          v-if="commentsOnEdit.includes(comment.id)"
                          small
                          right
                          >mdi-content-save-outline</v-icon
                        >
                        <v-icon v-else small right>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <template v-if="commentsOnEdit.includes(comment.id)">
                          Save
                        </template>
                        <template v-else> Edit </template>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense @click="deleteComment(comment)">
                      <v-list-item-icon>
                        <v-icon small right>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content> Remove </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
        <div
          v-else
          class="app-comments__items d-flex justify-center align-center flex-column"
        >
          <div>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M54.9997 0H16.6663C12.0647 0 8.33301 3.73167 8.33301 8.33333V53.3333L19.9413 41.6667H54.9997C59.6013 41.6667 63.333 37.935 63.333 33.3333V8.33333C63.333 3.73167 59.6013 0 54.9997 0Z"
                fill="#1568FF"
                fill-opacity="0.33"
              />
              <g filter="url(#filter0_b_622_423)">
                <path
                  d="M46.6667 10H8.33333C3.73167 10 0 13.7317 0 18.3333V63.3333L11.6083 51.6667H46.6667C51.2683 51.6667 55 47.935 55 43.3333V18.3333C55 13.7317 51.2683 10 46.6667 10Z"
                  fill="#3D81FF"
                  fill-opacity="0.67"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_622_423"
                  x="-5"
                  y="5"
                  width="65"
                  height="63.333"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_622_423"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_622_423"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <div>
            <span style="opacity: 0.5">Write new comment </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex">
      <div style="flex-basis: 100%">
        <div class="app-comments__textarea">
          <textarea
            v-model="content"
            placeholder="Type message..."
            class="textarea__input"
            @keydown.enter.exact.prevent="postComment"
            @keydown.enter.shift.exact.prevent="content += '\n'"
          ></textarea>
          <div class="textarea__actions">
            <v-btn
              class="mr-2"
              icon
              title="Select the date on which the message is to be sent"
              :loading="loading"
              @click="dateModal = true"
            >
              <v-icon small>mdi-calendar-range</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              title="Send message"
              color="primary"
              :loading="loading"
              @click="postComment"
            >
              <v-icon small>mdi-send</v-icon>
            </v-btn>
          </div>
        </div>
        <v-textarea
          v-if="false"
          v-model="content"
          outlined
          dense
          hide-details
          clearable
          label="Enter comment"
          style="border-radius: 12px"
          @click:append="postComment"
          @keydown.enter.exact.prevent="postComment"
          @keydown.enter.shift.exact.prevent="content += '\n'"
          :loading="loading"
        ></v-textarea>
        <div v-if="customDate">
          Selected date : {{ customDate }}
          <v-icon small color="error" @click="customDate = null"
            >mdi-trash-can-outline</v-icon
          >
        </div>
      </div>
      <div v-if="false" style="flex-basis: 68px; padding-left: 8px">
        <v-row dense>
          <v-col cols="12">
            <v-btn
              width="68"
              style="min-height: 68px; min-width: 68px; border-radius: 12px"
              elevation="0"
              class="pa-0"
              @click="dateModal = true"
            >
              <v-icon> mdi-calendar-range </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              width="68"
              style="min-height: 68px; border-radius: 12px"
              elevation="0"
              class="pa-0 send-button"
              :loading="loading"
              @click="postComment"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog
      v-model="dateModal"
      width="450"
      content-class="comment-date-dialog"
    >
      <v-card class="styled-card--default" rounded="lg">
        <v-card-title> Select date </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-row dense>
            <v-col cols="12" class="d-flex justify-center">
              <v-date-picker full-width v-model="customDate" />
            </v-col>
          </v-row>
          <v-row v-if="!customDate" dense>
            <v-col cols="12">
              <v-alert text type="info" dense rounded="lg" class="mb-0">
                If you don't select a date, it will automatically set to today.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row dense>
            <v-col cols="6" class="d-flex justify-center">
              <v-btn text block @click="customDate = null" color="error"
                >Clear</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn block @click="dateModal = false" color="success">Ok</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure? </template>
      <template #default> You want do delete this comment? </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="disagree" text color="grey">Cancel</v-btn>
            <v-btn @click="agree" color="success" class="ml-4 b-rb-shaped-4"
              >Yes</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
  </div>
</template>

<script>
import AgreeDialog from "@/components/UI/AgreeDialog";
import AgreeDialogMixin from "@/mixins/AgreeDialogMixin";

export default {
  components: { AgreeDialog },
  mixins: [AgreeDialogMixin],
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dateModal: false,
    commentsOnEdit: [],
    content: "",
    customDate: null,
  }),
  methods: {
    selectDate() {
      this.dateModal = !this.dateModal;
    },
    toHumanDate(date) {
      return this.$moment(date).format("MM/DD/YY");
    },
    async deleteComment({ id }) {
      if (!(await this.open())) return;
      this.$emit("delete", id);
    },
    editComment(comment) {
      if (this.commentsOnEdit.includes(comment.id)) {
        // save logic
        let commentNewContent = null;

        Array.from(this.$refs.comment).forEach((elem) => {
          if (parseInt(elem.dataset.id) === comment.id) {
            commentNewContent = elem.textContent;
          }
        });

        this.$emit("post", {
          text: commentNewContent,
          id: comment.id,
        });

        this.commentsOnEdit = this.commentsOnEdit.filter(
          (id) => id !== comment.id
        );

        return;
      }

      this.commentsOnEdit.push(comment.id);
    },
    postComment() {
      if (
        this.content === "" ||
        !this.content ||
        (typeof this.content === "string" && !this.content.trim())
      )
        return;

      this.$emit("post", {
        text: this.content,
        date: this.customDate,
      });

      this.customDate = null;
    },
  },
};
</script>

<style scoped></style>
