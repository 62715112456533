<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12">
        <h2>Create article</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="d-flex align-center">
        <v-chip
          label
          outlined
          style="width: 32px"
          class="px-0 d-flex justify-center"
          @click="$router.go(-1)"
        >
          <v-icon small>mdi-arrow-left</v-icon>
        </v-chip>
        <v-spacer />

        <v-menu
          bottom
          offset-y
          content-class="my-shadow--e3"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-chip v-on="on" label color="transparent" @click="() => {}">
              More actions <v-icon small right>mdi-chevron-down</v-icon>
            </v-chip>
          </template>
          <v-card class="styled-card--default">
            <v-list dense>
              <v-list-item @click="handleDeleteArticle">
                <v-list-item-icon>
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content> Delete </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-chip label class="ml-2" outlined @click="() => {}">
          Save <v-icon small right>mdi-content-save-outline</v-icon>
        </v-chip>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="8">
            <v-card class="styled-card--default">
              <v-card-title> Article content </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="article.title"
                      filled
                      hide-details
                      dense
                      label="Write title"
                      placeholder="Enter title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="article.preview_image"
                      filled
                      label="Select preview image"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon=""
                      hide-details
                      dense
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <tiptap-vuetify
                      class="my-tiptap-editor"
                      placeholder="Write article content"
                      v-model="article.content"
                      :extensions="extensions"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card class="styled-card--default">
              <v-card-title> Information </v-card-title>
              <v-divider />
              <v-card-text class="px-0">
                <div class="px-6">
                  <div>
                    <b>Article status</b>
                  </div>
                  <div class="mt-3 d-flex align-center justify-center">
                    <v-autocomplete
                      v-model="article.status"
                      hide-details
                      dense
                      filled
                      :items="statuses"
                    >
                    </v-autocomplete>
                    <div class="px-2">
                      <v-icon v-if="article.status" color="success">
                        mdi-circle
                      </v-icon>
                      <v-icon v-else color="error">mdi-stop</v-icon>
                    </div>
                  </div>
                </div>
                <v-divider class="mt-6 mb-5" />
                <div class="px-6">
                  <div>
                    <b>Additional info</b>
                  </div>
                  <div class="mt-2">
                    <v-list dense class="py-0">
                      <v-list-item class="px-0">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-calendar-plus-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <div>
                            <small> Date of create </small>
                          </div>
                          <div>
                            {{ $moment().calendar() }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-calendar-clock</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <div>
                            <small> Last update </small>
                          </div>
                          <div>
                            {{ $moment().calendar() }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
export default {
  components: { TiptapVuetify },
  data: () => ({
    selected: [],
    article: {
      title: "Introducing Anonymous - Who Am I And Why Should You Follow Me",
      status: 1,
      content: null,
      preview_image: null,
    },
    statuses: [
      {
        text: "Active",
        value: 1,
      },
      {
        text: "Disabled",
        value: 0,
      },
    ],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Blog",
        disabled: true,
      },
      {
        text: "Admin",
        to: { name: "blog.admin" },
      },
      {
        text: "Create",
        disabled: true,
      },
    ],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
  }),
  methods: {
    handleDeleteArticle() {
      console.log(this.$route.params.id);
    },
  },
};
</script>
