<template>
  <svg width="100%" viewBox="0 0 36 11" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.443237 5.47159V4.94318C0.917669 4.94318 1.24863 4.84375 1.43613 4.64489C1.62648 4.44602 1.72165 4.11364 1.72165 3.64773V2.28409C1.72165 1.89205 1.75858 1.55256 1.83244 1.26563C1.90915 0.978694 2.03273 0.741478 2.20318 0.553978C2.37364 0.366478 2.60091 0.227273 2.885 0.136364C3.16909 0.0454547 3.51994 0 3.93756 0V0.835228C3.60801 0.835228 3.34807 0.886364 3.15773 0.988636C2.97023 1.09091 2.8367 1.25 2.75716 1.46591C2.68045 1.67898 2.6421 1.9517 2.6421 2.28409V3.98864C2.6421 4.21023 2.61227 4.41193 2.55261 4.59375C2.49579 4.77557 2.38926 4.93182 2.23301 5.0625C2.07676 5.19318 1.85375 5.29403 1.56398 5.36506C1.27704 5.43608 0.903465 5.47159 0.443237 5.47159ZM3.93756 10.9091C3.51994 10.9091 3.16909 10.8636 2.885 10.7727C2.60091 10.6818 2.37364 10.5426 2.20318 10.3551C2.03273 10.1676 1.90915 9.9304 1.83244 9.64347C1.75858 9.35653 1.72165 9.01705 1.72165 8.625V7.26136C1.72165 6.79545 1.62648 6.46307 1.43613 6.2642C1.24863 6.06534 0.917669 5.96591 0.443237 5.96591V5.4375C0.903465 5.4375 1.27704 5.47301 1.56398 5.54403C1.85375 5.61506 2.07676 5.71591 2.23301 5.84659C2.38926 5.97727 2.49579 6.13352 2.55261 6.31534C2.61227 6.49716 2.6421 6.69886 2.6421 6.92045V8.625C2.6421 8.95739 2.68045 9.23011 2.75716 9.44318C2.8367 9.65625 2.97023 9.81392 3.15773 9.91619C3.34807 10.0213 3.60801 10.0739 3.93756 10.0739V10.9091ZM0.443237 5.96591V4.94318H1.44892V5.96591H0.443237Z"
      :fill="fill"
    />
    <path
      d="M13.7912 4.1442H11.9247C11.8906 3.90272 11.821 3.68823 11.7159 3.50073C11.6108 3.31039 11.4759 3.14846 11.3111 3.01494C11.1463 2.88141 10.956 2.77914 10.7401 2.70812C10.527 2.6371 10.2955 2.60159 10.0455 2.60159C9.59375 2.60159 9.20028 2.7138 8.86506 2.93823C8.52983 3.15982 8.26989 3.48369 8.08523 3.90982C7.90057 4.33312 7.80824 4.84732 7.80824 5.45244C7.80824 6.0746 7.90057 6.59732 8.08523 7.02062C8.27273 7.44391 8.53409 7.76352 8.86932 7.97943C9.20455 8.19533 9.59233 8.30329 10.0327 8.30329C10.2798 8.30329 10.5085 8.27062 10.7188 8.20528C10.9318 8.13994 11.1207 8.04477 11.2855 7.91977C11.4503 7.79193 11.5866 7.6371 11.6946 7.45528C11.8054 7.27346 11.8821 7.06607 11.9247 6.83312L13.7912 6.84164C13.7429 7.24221 13.6222 7.62857 13.429 8.00073C13.2386 8.37005 12.9815 8.70102 12.6577 8.99363C12.3366 9.2834 11.9531 9.51352 11.5071 9.68397C11.0639 9.85159 10.5625 9.93539 10.0028 9.93539C9.22443 9.93539 8.52841 9.75926 7.91477 9.40698C7.30398 9.05471 6.82102 8.54477 6.46591 7.87715C6.11364 7.20954 5.9375 6.4013 5.9375 5.45244C5.9375 4.50073 6.11648 3.69107 6.47443 3.02346C6.83239 2.35585 7.31818 1.84732 7.93182 1.49789C8.54545 1.14562 9.2358 0.969482 10.0028 0.969482C10.5085 0.969482 10.9773 1.04051 11.4091 1.18255C11.8438 1.3246 12.2287 1.53198 12.5639 1.80471C12.8991 2.0746 13.1719 2.40556 13.3821 2.79761C13.5952 3.18965 13.7315 3.63852 13.7912 4.1442Z"
      :fill="fill"
    />
    <path
      d="M19.8487 3.59874C19.8146 3.25499 19.6683 2.98795 19.4098 2.79761C19.1513 2.60727 18.8004 2.5121 18.3572 2.5121C18.0561 2.5121 17.8018 2.55471 17.5945 2.63994C17.3871 2.72232 17.228 2.83738 17.1172 2.98511C17.0092 3.13284 16.9553 3.30045 16.9553 3.48795C16.9496 3.6442 16.9822 3.78056 17.0533 3.89704C17.1271 4.01352 17.228 4.11437 17.3558 4.1996C17.4837 4.28198 17.6314 4.35443 17.799 4.41693C17.9666 4.47658 18.1456 4.52772 18.3359 4.57033L19.12 4.75783C19.5007 4.84306 19.8501 4.9567 20.1683 5.09874C20.4865 5.24079 20.7621 5.41551 20.995 5.62289C21.228 5.83028 21.4084 6.0746 21.5362 6.35585C21.6669 6.6371 21.7337 6.95954 21.7365 7.32318C21.7337 7.85727 21.5973 8.32033 21.3274 8.71238C21.0604 9.10159 20.674 9.40414 20.1683 9.62005C19.6655 9.83312 19.0589 9.93965 18.3487 9.93965C17.6442 9.93965 17.0305 9.8317 16.5078 9.61579C15.9879 9.39988 15.5817 9.08028 15.2891 8.65698C14.9993 8.23085 14.8473 7.70386 14.8331 7.07602H16.6186C16.6385 7.36863 16.7223 7.61295 16.87 7.80897C17.0206 8.00215 17.2209 8.14846 17.4709 8.24789C17.7237 8.34448 18.0092 8.39278 18.3274 8.39278C18.6399 8.39278 18.9112 8.34732 19.1413 8.25641C19.3743 8.16551 19.5547 8.03909 19.6825 7.87715C19.8104 7.71522 19.8743 7.52914 19.8743 7.31891C19.8743 7.12289 19.8161 6.95812 19.6996 6.8246C19.5859 6.69107 19.4183 6.57744 19.1967 6.48369C18.978 6.38994 18.7095 6.30471 18.3913 6.22801L17.4411 5.98937C16.7053 5.81039 16.1243 5.53056 15.6982 5.14988C15.272 4.7692 15.0604 4.25641 15.0632 3.61153C15.0604 3.08312 15.201 2.62147 15.4851 2.22659C15.772 1.8317 16.1655 1.52346 16.6655 1.30187C17.1655 1.08028 17.7337 0.969482 18.37 0.969482C19.0178 0.969482 19.5831 1.08028 20.0661 1.30187C20.5518 1.52346 20.9297 1.8317 21.1996 2.22659C21.4695 2.62147 21.6087 3.07886 21.6172 3.59874H19.8487Z"
      :fill="fill"
    />
    <path
      d="M27.712 3.59874C27.6779 3.25499 27.5316 2.98795 27.2731 2.79761C27.0146 2.60727 26.6637 2.5121 26.2205 2.5121C25.9194 2.5121 25.6651 2.55471 25.4577 2.63994C25.2504 2.72232 25.0913 2.83738 24.9805 2.98511C24.8725 3.13284 24.8185 3.30045 24.8185 3.48795C24.8129 3.6442 24.8455 3.78056 24.9165 3.89704C24.9904 4.01352 25.0913 4.11437 25.2191 4.1996C25.3469 4.28198 25.4947 4.35443 25.6623 4.41693C25.8299 4.47658 26.0089 4.52772 26.1992 4.57033L26.9833 4.75783C27.364 4.84306 27.7134 4.9567 28.0316 5.09874C28.3498 5.24079 28.6254 5.41551 28.8583 5.62289C29.0913 5.83028 29.2717 6.0746 29.3995 6.35585C29.5302 6.6371 29.5969 6.95954 29.5998 7.32318C29.5969 7.85727 29.4606 8.32033 29.1907 8.71238C28.9237 9.10159 28.5373 9.40414 28.0316 9.62005C27.5288 9.83312 26.9222 9.93965 26.212 9.93965C25.5075 9.93965 24.8938 9.8317 24.3711 9.61579C23.8512 9.39988 23.445 9.08028 23.1523 8.65698C22.8626 8.23085 22.7106 7.70386 22.6964 7.07602H24.4819C24.5018 7.36863 24.5856 7.61295 24.7333 7.80897C24.8839 8.00215 25.0842 8.14846 25.3342 8.24789C25.587 8.34448 25.8725 8.39278 26.1907 8.39278C26.5032 8.39278 26.7745 8.34732 27.0046 8.25641C27.2376 8.16551 27.418 8.03909 27.5458 7.87715C27.6736 7.71522 27.7376 7.52914 27.7376 7.31891C27.7376 7.12289 27.6793 6.95812 27.5629 6.8246C27.4492 6.69107 27.2816 6.57744 27.06 6.48369C26.8413 6.38994 26.5728 6.30471 26.2546 6.22801L25.3043 5.98937C24.5685 5.81039 23.9876 5.53056 23.5614 5.14988C23.1353 4.7692 22.9236 4.25641 22.9265 3.61153C22.9236 3.08312 23.0643 2.62147 23.3484 2.22659C23.6353 1.8317 24.0288 1.52346 24.5288 1.30187C25.0288 1.08028 25.5969 0.969482 26.2333 0.969482C26.881 0.969482 27.4464 1.08028 27.9293 1.30187C28.4151 1.52346 28.793 1.8317 29.0629 2.22659C29.3327 2.62147 29.4719 3.07886 29.4805 3.59874H27.712Z"
      :fill="fill"
    />
    <path
      d="M35.0941 5.43747L35.0941 5.96588C34.6197 5.96588 34.2887 6.06531 34.1012 6.26417C33.9109 6.46303 33.8157 6.79542 33.8157 7.26133L33.8157 8.62497C33.8157 9.01701 33.7788 9.3565 33.7049 9.64343C33.6282 9.93036 33.5046 10.1676 33.3342 10.3551C33.1637 10.5426 32.9364 10.6818 32.6524 10.7727C32.3683 10.8636 32.0174 10.9091 31.5998 10.9091L31.5998 10.0738C31.9293 10.0738 32.1893 10.0227 32.3796 9.92042C32.5671 9.81815 32.7007 9.65906 32.7802 9.44315C32.8569 9.23008 32.8953 8.95735 32.8953 8.62497L32.8953 6.92042C32.8953 6.69883 32.9251 6.49713 32.9847 6.31531C33.0416 6.13349 33.1481 5.97724 33.3043 5.84656C33.4606 5.71588 33.6836 5.61502 33.9734 5.544C34.2603 5.47298 34.6339 5.43747 35.0941 5.43747ZM31.5998 -3.36841e-05C32.0174 -3.36476e-05 32.3683 0.0454203 32.6524 0.136329C32.9364 0.227239 33.1637 0.366443 33.3342 0.553943C33.5046 0.741443 33.6282 0.97866 33.7049 1.26559C33.7788 1.55252 33.8157 1.89201 33.8157 2.28406L33.8157 3.64769C33.8157 4.1136 33.9109 4.44599 34.1012 4.64485C34.2887 4.84372 34.6197 4.94315 35.0941 4.94315L35.0941 5.47156C34.6339 5.47156 34.2603 5.43605 33.9734 5.36502C33.6836 5.294 33.4606 5.19315 33.3043 5.06247C33.1481 4.93178 33.0416 4.77553 32.9847 4.59372C32.9251 4.4119 32.8953 4.21019 32.8953 3.9886L32.8953 2.28406C32.8953 1.95167 32.8569 1.67894 32.7802 1.46588C32.7007 1.25281 32.5671 1.09514 32.3796 0.992863C32.1893 0.88775 31.9293 0.835193 31.5998 0.835193L31.5998 -3.36841e-05ZM35.0941 4.94315L35.0941 5.96588L34.0884 5.96588L34.0884 4.94315L35.0941 4.94315Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "black",
    },
  },
};
</script>
