<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col cols="12" v-if="data.data[0]">
          <v-data-table
            v-if="data.data[0]"
            :items="data.data[0].items"
            :headers="data.data[0].headers"
          ></v-data-table>
        </v-col>
        <v-col v-else cols="12">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  components: { SectionTitle, NoItemsPlaceholder },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
