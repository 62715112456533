<template>
  <div class="d-flex align-center" style="flex-wrap: wrap">
    <div>
      NT / Pr:
      <b>{{ data.url.need_top_from_url_control }}</b> /
      <b>{{ data.url.priority_pages }}</b>
    </div>
    <span class="mx-5">|</span>
    <div>
      GSC Cl / Imp:
      <b>
        {{ formateClicks(data.url.sum_gsc_cl_by_28_days) }}
      </b>
      /
      <b>
        {{ formateClicks(data.url.sum_gsc_imp_by_28_days) }}
      </b>
    </div>
    <span class="mx-5">|</span>
    <div>
      Int Uniq A / L:
      <div
        :class="`colored-box ${getAnchorsChipColor(
          data.url.unique_anchors,
          'anchors'
        )}`"
      >
        <b>{{ data.url.unique_anchors }}</b>
      </div>
      /
      <div
        :class="`colored-box ${getAnchorsChipColor(
          data.url.unique_links,
          'links'
        )}`"
      >
        <b>{{ data.url.unique_links }}</b>
      </div>
    </div>
    <span class="mx-5">|</span>
    <div>
      <div :class="`colored-box ${getDepthColor(data.url.depth)}`">
        D: <b>{{ data.url.depth }}</b>
      </div>
    </div>
    <template v-if="parseInt(data.url.links_score) < 100">
      <span class="mx-5">|</span>
      <div :class="`colored-box ${getLinksColor(data.url.links_score)}`">
        Links: <b>{{ data.url.links_score }}</b>
      </div>
    </template>
    <template v-if="parseInt(data.url.tech_score) < 100">
      <span class="mx-5">|</span>
      <div :class="`colored-box ${getLinksColor(data.url.tech_score)}`">
        Technical: <b>{{ data.url.tech_score }}</b>
      </div>
    </template>
    <template v-if="parseInt(data.url.sw_score) < 100">
      <span class="mx-5">|</span>

      <div :class="`colored-box ${getLinksColor(data.url.sw_score)}`">
        SW: <b>{{ data.url.sw_score }}</b>
      </div>
    </template>
    <template v-if="parseInt(data.url.content_score) < 100">
      <span class="mx-5">|</span>
      <div :class="`colored-box ${getLinksColor(data.url.content_score)}`">
        Content: <b>{{ data.url.content_score }}</b>
      </div>
    </template>
    <template v-if="lCrawledOverdue(data.url.l_crawled)">
      <span class="mx-5">|</span>
      <div :class="`colored-box error`">
        L Crawled: <b>{{ $moment(data.url.l_crawled).format("MMM DD") }}</b>
      </div>
    </template>
    <template v-if="String(data.url.code) !== '200'">
      <span class="mx-5">|</span>
      <div :class="`colored-box error`">
        Code: <b>{{ data.url.code }}</b>
      </div>
    </template>

    <v-card class="styled-card--light mt-4">
      <v-card-text class="d-flex px-2 py-0" style="gap: 8px">
        <div class="d-flex align-center" style="gap: 4px">
          <text-indicator-chip
            :data="data?.analytics?.total_words"
            name="total_words"
            prefix="TW"
            title="Total words"
          />
          <text-indicator-chip
            :data="data?.analytics?.average_sentence_length"
            name="average_sentence_length"
            prefix="ASL"
            title="Average sentence length"
          />
          <text-indicator-chip
            :data="data?.analytics?.water_index"
            name="water_index"
            prefix="WI"
            title="Water index"
          />
        </div>

        <v-divider vertical />

        <div class="d-flex my-2" style="gap: 4px">
          <text-indicator-chip
            :data="data?.analytics?.classical_nausea_index"
            name="classical_nausea_index"
            prefix="CNI"
            title="classical nausea index"
          />
          <text-indicator-chip
            :data="data?.analytics?.dale_chall_score"
            name="dale_chall_score"
            prefix="SR"
            title="Dale-chall readability"
          />
          <text-indicator-chip
            :data="data?.analytics?.spache"
            name="spache"
            prefix="SR"
            title="Spache readability"
          />
          <text-indicator-chip
            :data="data?.analytics?.flesch"
            name="flesch"
            prefix="FR"
            title="Flesch readability"
          />
          <text-indicator-chip
            :data="data?.analytics?.automated_index"
            name="automated_index"
            prefix="ARI"
            title="Automated readability index"
          />
        </div>

        <v-divider vertical />

        <div class="d-flex align-center" style="gap: 4px">
          <v-chip color="transparent" label title="stop words 1">
            <span class="mr-1">1SW:</span>
            <b>
              {{ data.analytics?.only_stop_words_1?.deviation }}
            </b>
          </v-chip>
          <v-chip color="transparent" label title="other words 2">
            <span class="mr-1">2O:</span>
            <b>
              {{ data.analytics?.by_words_2_others?.deviation }}
            </b>
          </v-chip>
          <v-chip color="transparent" label title="stop words 2">
            <span class="mr-1">2SW:</span>
            <b>
              {{ data.analytics?.only_stop_words_2?.deviation }}
            </b>
          </v-chip>
          <v-chip color="transparent" label title="First seen">
            <span class="mr-1">First seen:</span>
            <b>
              {{ data.url?.first_seen }}
            </b>
          </v-chip>
        </div>
        <v-divider vertical />
        <div class="d-flex align-center" style="gap: 4px">
          <v-chip
            color="transparent"
            label
            title="Stop words deadline (status)"
          >
            <span class="mr-1">SW D:</span>
            <b>
              {{
                data.url.sw_dl !== "-"
                  ? $moment(data.url.sw_dl).format("MMM DD")
                  : "-"
              }}
            </b>
            <span v-if="data.url.sw_status !== '-'">
              ({{ data.url.sw_status }})
            </span>
          </v-chip>
          <v-chip
            color="transparent"
            label
            title="Content full process (status)"
          >
            <span class="mr-1">CFProc:</span>
            <b>
              {{
                data.url.cfproc_date !== "-"
                  ? $moment(data.url.cfproc_date).format("MMM DD")
                  : "-"
              }}
            </b>
            <span v-if="data.url.cfproc_status !== '-'">
              ({{ data.url.cfproc_status }})
            </span>
          </v-chip>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import TextIndicatorChip from "@/components/OneYearReport/TextIndicatorChip.vue";

export default {
  components: { TextIndicatorChip },
  props: ["data"],
  methods: {
    getLinksColor(data) {
      const v = parseInt(data);

      if (v >= 60 && v <= 80) return "warning";
      if (v < 60) return "error";
      return "";
    },
    getAnchorsChipColor(data, type = "") {
      const v = parseInt(data);

      if (type === "anchors") {
        if (v > 0 && v <= 3) return "error";
        if (v >= 4 && v <= 5) return "warning";
        return "transparent";
      }

      if (v > 0 && v <= 3) return "error";
      if (v >= 4 && v <= 10) return "warning";
      return "transparent";
    },
    formateClicks(data) {
      if (String(data) === "-1") return "-";
      return data;
    },
    getDepthColor(data) {
      const v = parseInt(data);

      if (v === 1) return "";
      if (v >= 2 && v <= 3) return "warning";
      return "error";
    },
    lCrawledOverdue(date) {
      const today = this.$moment(new Date());
      const diff = this.$moment(date).diff(today, "days");

      if (diff >= 2) return true;
      return false;
    },
    getSwDlColor(date) {
      if (!date) return "warning";
      const today = this.$moment(new Date());
      const diffMonths = this.$moment(date).diff(today, "months");

      if (diffMonths <= -2) {
        return "blue";
      }

      return "transparent";
    },
  },
};
</script>
