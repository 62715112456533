import { render, staticRenderFns } from "./GoogleIndex.vue?vue&type=template&id=b0fb8356&scoped=true&"
import script from "./GoogleIndex.vue?vue&type=script&lang=js&"
export * from "./GoogleIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0fb8356",
  null
  
)

export default component.exports