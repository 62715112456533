<template>
  <div :class="{ 'table-wrapper': 1, dark: $vuetify.theme.dark }">
    <div
      v-if="items.length > 0"
      :class="`table-wrapper__content ${transparent ? 'transparent' : ''}`"
    >
      <loader :show="loading" />
      <slot></slot>
    </div>
    <div v-else-if="loading" class="table-wrapper__placeholder">
      <loader :show="loading" />
      <v-skeleton-loader
        type="table-row-divider@6"
        width="100%"
      ></v-skeleton-loader>
    </div>
    <div v-if="firstEnter" class="table-wrapper__placeholder">
      <span v-text="tooltips.firstEnter"></span>
    </div>
    <div
      v-else-if="!loading && items.length === 0"
      class="table-wrapper__placeholder"
    >
      <span v-text="tooltips.itemsPlaceholder"></span>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Main/Loader";

export default {
  components: { Loader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    firstEnter: {
      type: Boolean,
      default: false,
    },
    customTooltips: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (this.customTooltips) {
      this.tooltips = this.customTooltips;
    }
  },
  data: () => ({
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍",
      itemsPlaceholder:
        "Can't find anything. Change filters and try again. 🤷‍",
    },
  }),
};
</script>
