import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "contentDashboard/fetchTableData"(ctx, payload) {
      const url = "/dashboards/content/length-competitors";

      const resp = await Service.post(url, payload, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "contentDashboard/fetchCompetitors"(ctx, payload) {
      const url = "/dashboards/content/length-competitors/competitors";

      const resp = await Service.post(url, payload, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
  },
  getters: {},
};
