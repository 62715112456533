<template>
  <div class="d-flex flex-wrap py-2" style="gap: 2px">
    <v-chip
      v-if="item.is_approved_by_tl"
      label
      small
      class="px-2 flat-chip flat-chip--primary"
      title="result approved by TL"
    >
      TL
      <v-icon small title="result approved by TL" color="info">
        mdi-account-multiple
      </v-icon>
    </v-chip>
    <v-chip
      v-if="!item.is_approved_by_tl && item.is_approved_by_ceo"
      small
      label
      class="px-2 flat-chip flat-chip--success"
      title="approved by CEO"
    >
      CEO
      <v-icon small title="approved by CEO" color="green darken3">
        mdi-account-tie
      </v-icon>
    </v-chip>
    <v-chip
      v-if="!item.is_tl_plan_result"
      small
      label
      class="px-2 flat-chip flat-chip--error"
      title="No TL plan result"
    >
      <v-icon small left> mdi-account-multiple </v-icon>
      TL P
    </v-chip>
    <v-chip
      v-if="!item.is_seo_plan_result"
      small
      label
      class="px-2 flat-chip flat-chip--error"
      title="No Executive plan result"
    >
      <v-icon small left> mdi-account-tie </v-icon>
      Ex P
    </v-chip>
    <v-chip
      v-if="item.being_late"
      small
      label
      class="px-2 flat-chip flat-chip--warning"
      title="Being late"
    >
      Late
      <v-icon small right> mdi-clock-alert-outline </v-icon>
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
  },
};
</script>
