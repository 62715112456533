<template>
  <td>
    <div style="white-space: nowrap" :class="`colored-td ${getColor}`">
      {{ getContent }}
      <sup v-if="getRewrite">
        {{ getRewrite }}
      </sup>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      if (this.data.task_closed) return "";

      if (this.getData.expired) return "red";

      return "";
    },
    getRewrite() {
      return this.data.outreach_manager_approve
        ? this.data.outreach_manager_approve.rewrite
        : null;
    },
    getContent() {
      return this.data.outreach_manager_approve.text
        ? this.$moment(this.data.outreach_manager_approve.text).format("DD/MM")
        : null;
    },
    getData() {
      return this.data.outreach_manager_approve
        ? this.data.outreach_manager_approve
        : {};
    },
  },
};
</script>
