<template>
  <td>
    {{ value }}
  </td>
</template>

<script>
export default {
  props: ["value", "data"],
};
</script>
