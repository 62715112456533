<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col cols="4" v-if="data.data[0]">
          <apexchart
            type="pie"
            height="350"
            :options="getPieOptions()"
            :series="data.data[0].series"
          />
        </v-col>
        <v-col v-else cols="4">
          <no-items-placeholder />
        </v-col>
        <v-col cols="8" v-if="data.data[1]">
          <apexchart
            type="area"
            height="350"
            :options="getChartOptions()"
            :series="data.data[1].series"
          />
        </v-col>
        <v-col v-else cols="8">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  // props: {
  //   data: {
  //     type: Object,
  //     default: () => ({}),
  //   },
  // },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  data: () => ({
    data: {
      title: "CBA PT: Existing Pages",
      type: "extended-pie-chart",
      group: "Main",
      priority: 6,
      data: [
        {
          series: [1, 14, 2, 3],
          categories: ["mindep", "review", "casino", "payment"],
          colors: ["#E5094E", "#B7964A", "#560FB3", "#93C05A"],
        },
        {
          series: [
            { name: "casino", color: "#560FB3", data: [0, 2] },
            { name: "review", color: "#B7964A", data: [0, 14] },
            { name: "payment", color: "#93C05A", data: [0, 3] },
            { name: "mindep", color: "#E5094E", data: [0, 1] },
          ],
          categories: ["2023-07-11", "2023-07-13"],
        },
      ],
    },
  }),
  methods: {
    getPieOptions() {
      return {
        chart: {
          width: 380,
          type: "pie",
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        labels: this.data.data[0].categories,
        colors: this.data.data[0].colors,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    getChartOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          animations: {
            enabled: false,
          },
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: {
          min: 0,
        },
        xaxis: {
          type: "datetime",
          categories: this.data.data[1].categories,
        },
      };
    },
  },
};
</script>
