import copy from "copy-to-clipboard";
export default {
  methods: {
    _$copyUrl(filters) {
      const { origin, pathname } = window.location;
      let url = origin + pathname;

      if (filters.length === 0) {
        copy(url);
        return this.$message.notification({
          title: "Link copied",
          text: `The link copied to the clipboard. Your link: <button><a href='${url}' target="_blank">Go to coppied page</a></button>`,
          duration: 10000,
        });
      }

      const existingFilters = [];

      Object.keys(filters).forEach((key) => {
        if (filters[key] === null) return;
        if (Array.isArray(filters[key]) && filters[key].length === 0) return;
        existingFilters.push({
          key,
          value: filters[key],
        });
      });

      existingFilters.forEach(({ key, value }, idx) => {
        if (value === undefined) return;

        const prefix = idx === 0 ? "?" : "&";

        url += `${prefix}${key}=${value}`;
      });

      copy(url);

      this.$message.notification({
        title: "Link copied",
        text: `The link copied to the clipboard. Your link: <button><a href='${url}' target="_blank">Go to coppied page</a></button>`,
        duration: 10000,
      });
    },
    _$buildQueryByFilters(urlString, filters) {
      let url = urlString;

      if (filters.length === 0) return this.$copyText(url);

      const existingFilters = [];

      Object.keys(filters).forEach((key) => {
        if (filters[key] === null) return;
        if (Array.isArray(filters[key]) && filters[key].length === 0) return;
        existingFilters.push({
          key,
          value: filters[key],
        });
      });

      existingFilters.forEach(({ key, value }, idx) => {
        const prefix = idx === 0 ? "?" : "&";

        url += `${prefix}${key}=${value}`;
      });

      return url;
    },
  },
};
