<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <div v-on="on">
        {{ value }}
      </div>
    </template>
    <template #default>
      <div>
        {{ getHumanReadableHours }}
      </div>
    </template>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    getHumanReadableHours() {
      const hours = this.value;

      if (!hours) return "0:00";

      // Округляем часы до целого значения
      const h = Math.floor(hours);
      // Вычисляем оставшиеся минуты, умножая дробную часть часов на 60 и округляя результат до ближайшего целого числа
      const m = Math.round((hours - h) * 60);
      // Форматируем минуты с ведущим нулем, если они меньше 10
      const formattedMinutes = m < 10 ? "0" + m : m;
      // Возвращаем строку в формате "часы:минуты"
      return `${h}:${formattedMinutes}`;
    },
  },

  methods: {
    //
  },
};
</script>
