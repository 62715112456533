<template>
  <v-card>
    <div :id="'chart-links-type'+ subproject_id">
      <!--            <h2 class="text-h6 mb-3">Links</h2>-->
      <div id="chart-timeline-links-type">
        <apexcharts :ref="'chart_links_type' + subproject_id" height="200" :options="options"
                    :series="series"></apexcharts>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import axios from "axios";

export default {
  name: "Links",
  components: {apexcharts: VueApexCharts},
  props: ['id', 'subproject_id'],
  data() {
    return {
      series: [],
      options: {
        chart: {
          id: 'chart-timeline-type',
          type: 'bar',
          height: 200,
          stacked: true,
          zoom: {
            enabled: true,
            autoScaleYaxis: true
          }
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          show: false,
          axisBorder: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            show: false
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              strokeDashArray: 0,
              borderColor: "#e6e6e6",
              fillColor: "#c2c2c2",
              opacity: 0.8,
              offsetX: 0,
              offsetY: 0
            }
          ]
        },
        xaxis: {
          type: 'datetime',
          min: new Date().setMonth((new Date()).getMonth() - 12),
          tickAmount: 6,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '25%',
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        },
      },
    }
  },
  methods: {
    resetData() {
      this.series = []
    },
    fetchData() {
      let self = this

      let url = `${self.$store.state.server_url}/ltt/types-dynamics/json`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        type: 'types-dynamics',
        project_id: self.id,
        subproject_id: self.subproject_id
      }

      axios
          .post(url, data, config)
          .then((r) => {
            self.series = r.data.data.series
            self.$refs['chart_links_type' + self.subproject_id].updateOptions({
              colors: r.data.data.colors
            })
          })
    },
  },
  created() {
    let self = this

    self.fetchData();
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    getProjects() {
      return this.$store.state.project.allowed_projects
    },
  }
}
</script>

<style scoped>

</style>