<template>
  <v-card class="styled-card--default" width="400">
    <v-card-title> Force update controller </v-card-title>
    <v-card-subtitle>
      Click on the section name to enable
      <v-chip small label>force update</v-chip> for that section.
    </v-card-subtitle>
    <v-divider />
    <v-list dense>
      <template v-for="section in Object.values(sections)">
        <v-list-item
          v-if="section.sectionTitle !== undefined"
          @click="handleToggleForceUpdate(section.sectionName)"
          :key="section.sectionName"
        >
          <v-list-item-icon>
            <v-icon
              v-if="sectionsOnForceUpdate.includes(section.sectionName)"
              color="primary"
              >mdi-checkbox-marked</v-icon
            >
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ section.sectionTitle }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { SITE_ANALYSIS_SECTION_DATA } from "../defaultData";

export default {
  data: () => ({
    sections: SITE_ANALYSIS_SECTION_DATA,
  }),
  computed: {
    sectionsOnForceUpdate() {
      return (
        this.$store.getters["site-analysis/sectionsWithEnabledForceUpdate"] ||
        []
      );
    },
  },
  methods: {
    handleToggleForceUpdate(sectionName) {
      console.log("TOGGLE ", sectionName);
      if (this.sectionsOnForceUpdate.includes(sectionName)) {
        return this.$store.commit(
          "site-analysis/setSectionsWithEnabledForceUpdate",
          this.removeFromForceUpdateList(sectionName) || []
        );
      }

      this.$store.commit(
        "site-analysis/setSectionsWithEnabledForceUpdate",
        this.addToForceUpdateList(sectionName) || []
      );
    },
    removeFromForceUpdateList(sectionName) {
      const sections =
        this.$store.getters[
          "site-analysis/sectionsWithEnabledForceUpdate"
        ].valueOf() || [];

      return sections.filter((v) => v !== sectionName);
    },
    addToForceUpdateList(sectionName) {
      const sections =
        this.$store.getters[
          "site-analysis/sectionsWithEnabledForceUpdate"
        ].valueOf() || [];

      return [...sections, sectionName];
    },
  },
};
</script>
