<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col cols="4" v-if="data.data[0]">
          <apexchart
            type="radialBar"
            height="350"
            :options="getPieOptions()"
            :series="data.data[0].series"
          />
        </v-col>
        <v-col v-else cols="4">
          <no-items-placeholder />
        </v-col>
        <v-col cols="8" v-if="data.data[1]">
          <apexchart
            type="bar"
            height="350"
            :options="getChartOptions()"
            :series="data.data[1].series"
          />
        </v-col>
        <v-col v-else cols="8">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  methods: {
    getPieOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          height: 350,
          type: "radialBar",
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
              },
              value: {
                offsetY: 16,
                fontSize: "22px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 0,
        },
        labels: this.data.data[0].categories,
      };
    },
    getChartOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          animations: {
            enabled: false,
          },
          height: 350,
          type: "bar",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: {
          min: 0,
        },
        xaxis: {
          type: "datetime",
          categories: this.data.data[1].categories,
        },
      };
    },
  },
};
</script>
