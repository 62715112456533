<template>
  <v-card :class="computedClass" rounded="xl">
    <v-card-text class="pa-4">
      <v-row dense>
        <v-col cols="fill">
          <v-text-field
            v-model="form.task_title"
            placeholder="What needs to be done?"
            hide-details="auto"
            outlined
            dense
            :rules="taskTitleRules"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <priority-select
            :model-value="form.priority"
            @update:modelValue="form.priority = $event"
          />
        </v-col>
        <v-col v-if="type === 'default' && !disableCreateButton" cols="auto">
          <v-btn
            @click="handleCreateTask"
            color="primary"
            block
            class="text-normal"
            :loading="loadings.createTask"
            :disabled="validate"
            height="40"
          >
            Create Task
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="type === 'default'" cols="fill">
          <v-autocomplete
            v-model="form.reporter"
            hide-details="auto"
            dense
            outlined
            :items="
              filtersData.users ||
              filtersData.executive ||
              filtersData.executives
            "
            label="Reporter"
            prepend-inner-icon="mdi-account-outline"
            clearable
            item-text="email"
            item-value="email"
            :loading="loadings.filters"
            :rules="reporterRules"
            :menu-props="menuProps"
          ></v-autocomplete>
        </v-col>
        <v-col cols="fill">
          <status-select
            :model-value="form.tags"
            @update:modelValue="form.tags = $event"
            :loading="loadings.filters"
            :items="filtersData.tags || filtersData.labels"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <date-select
            :model-value="form.date"
            @update:modelValue="form.date = $event"
          />
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.estimated_time"
                outlined
                type="numbers"
                hide-details
                dense
                label="Hours"
                prepend-inner-icon="mdi-clock-time-eight-outline"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.estimated_money"
                outlined
                hide-details
                dense
                label="Money"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="similar-task-creating__editor px-4 pt-0 pb-4">
      <v-card flat outlined rounded="lg" class="pa-0">
        <tiptap-vuetify
          v-model="form.task_desc"
          placeholder="Task description"
          :extensions="extensions"
        />
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import PrioritySelect from "../../SimilarTaskCreating/UI/PrioritySelect.vue";
import DateSelect from "@/components/SimilarTaskCreating/UI/DateSelect.vue";
import StatusSelect from "@/components/SimilarTaskCreating/UI/StatusSelect.vue";
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";

export default {
  components: { DateSelect, StatusSelect, PrioritySelect, TiptapVuetify },
  emits: ["createTask"],
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: "default",
    },
    loadings: {
      type: Object,
      default() {
        return {};
      },
    },
    filtersData: {
      type: Object,
      default() {
        return {};
      },
    },
    validate: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    disableCreateButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    reporterRules: [(v) => !!v || "Reporter is required"],
    taskTitleRules: [(v) => !!v || "Title is required"],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
    usersModal: false,
    search: null,
    menuProps: DEFAULT_MENU_PROPS,
  }),
  computed: {
    computedClass() {
      return {
        "shadow-e1-bordered": !this.borderless,
        "remove-dialog-shadow": this.borderless,
      };
    },
  },
  methods: {
    handleCreateTask() {
      this.$emit("createTask");
    },
  },
};
</script>
