<template>
  <v-tooltip bottom content-class="pa-0">
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        icon
        @click="handleUpdateListOfSectionsOnForceFetch"
        :color="isForceUpdateEnabled ? 'success' : ''"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </template>
    <v-card class="shadow-e1-bordered pa-4" width="380">
      <div class="mb-4">
        <span v-if="isForceUpdateEnabled" class="error--text"> Disable </span>
        <span v-else class="success--text"> Enable </span>
        force update for this section. The section will be updated every 1
        minutes.
      </div>
      <div class="d-flex">
        <v-icon color="rgba(155,155,155,0.4)" class="mr-2" small>
          mdi-information-outline
        </v-icon>
        <div>
          Force update will automatically turn off when
          <v-chip small label>last update</v-chip> will be less than 60 days.
        </div>
      </div>
    </v-card>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    sectionName: {
      type: String,
      default: null,
    },
  },
  computed: {
    isForceUpdateEnabled() {
      return (
        this.$store.getters["site-analysis/sectionsWithEnabledForceUpdate"] ||
        []
      ).includes(this.sectionName);
    },
  },
  methods: {
    handleUpdateListOfSectionsOnForceFetch() {
      const sections =
        this.$store.getters[
          "site-analysis/sectionsWithEnabledForceUpdate"
        ].valueOf() || [];

      if (sections.includes(this.sectionName)) {
        const sectionsWithoutCurrent = sections.filter(
          (v) => v !== this.sectionName
        );

        return this.$store.commit(
          "site-analysis/setSectionsWithEnabledForceUpdate",
          sectionsWithoutCurrent
        );
      }

      this.$store.commit("site-analysis/setSectionsWithEnabledForceUpdate", [
        ...sections,
        this.sectionName,
      ]);
    },
  },
};
</script>
