<template>
  <a :href="getLink" target="_blank">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn v-on="on" icon>
          <img
            src="https://ahrefs.com/favicon.ico "
            alt="ahrefs icon"
            width="15"
            height="15"
          />
        </v-btn>
      </template>
      <div>
        {{ getTooltip }}
      </div>
    </v-tooltip>
  </a>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "top-pages",
    },
    domain: {
      type: String,
      default: null,
    },
  },
  computed: {
    getLink() {
      if (this.type === "top-pages")
        return `https://app.ahrefs.com/v2-site-explorer/top-pages?mode=subdomains&target=${this.domain}`;

      if (this.type === "organic-keywords")
        return `https://app.ahrefs.com/v2-site-explorer/organic-keywords?mode=subdomains&target=${this.domain}`;

      if (this.type === "anchors")
        return `https://app.ahrefs.com/v2-site-explorer/anchors?mode=subdomains&target=${this.domain}`;

      if (this.type === "outgoing-anchors")
        return `https://app.ahrefs.com/v2-site-explorer/outgoing-anchors?mode=subdomains&target=${this.domain}`;

      return `https://app.ahrefs.com/v2-site-explorer/top-pages?mode=subdomains&target=${this.domain}`;
    },
    getTooltip() {
      if (this.type === "top-pages") return `Check top pages`;

      if (this.type === "organic-keywords") return `Check organic keywords`;

      if (this.type === "anchors") return `Check anchors`;

      if (this.type === "outgoing-anchors") return `Check outgoing anchors`;

      return `https://app.ahrefs.com/v2-site-explorer/top-pages?mode=subdomains&target=${this.domain}`;
    },
  },
};
</script>
