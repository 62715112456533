<template>
  <div>
    123
  </div>
</template>

<script>
export default {
  name: "Show",
  data() {

  }
}
</script>

<style scoped>

</style>