export default {
  data: () => ({
    aamResolve: null,
    aamReject: null,
    aamIsAwaiting: {},
  }),

  methods: {
    async aamWait(key = "default") {
      this.$set(this.aamIsAwaiting, key, true);

      return await new Promise((res, rej) => {
        this.aamResolve = res;
        this.aamReject = rej;
      });
    },
    aamResolveAwaiting(action, key = "default", payload = {}) {
      if (action === "resolve") {
        this.aamResolve({ ok: true, payload });
      } else {
        this.aamReject({ ok: false, payload });
      }

      this.$set(this.aamIsAwaiting, key, false);
    },
  },
};
