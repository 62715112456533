<template>
  <div class="conversion-2">
    <v-row dense>
      <v-col cols="12">
        <page-name small="" :theme="$vuetify.theme.dark">
          Conversion 2
        </page-name>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
          outlined
          :disabled="!table.items.length"
          class="align-end"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <div class="fix-button-height">
          <div v-if="filter.comparison.length > 0" class="disabled-tooltip">
            <span> When comparison filter enabled - date can't be used. </span>
          </div>
          <date-filter
            :model-value="filter.date_range"
            :disabled="filter.comparison.length > 0"
            @update:modelValue="filter.date_range = $event"
          />
        </div>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="filter.subproject_id"
          :items="subprojects"
          label="Subprojects"
          outlined
          item-text="name"
          item-value="id"
          clearable
          hide-details
          dense
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <comparison-filter
          :model-value="filter.comparison"
          @update:modelValue="handleUpdateComparison"
        />
      </v-col>
      <v-col class="d-flex justify-end">
        <div class="fix-button-height">
          <v-btn @click="fetch_data" color="primary" :loading="loadings.table">
            Get Data
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <table-wrapper
          :first-enter="firstEnter"
          :items="table.items"
          :loading="loadings.table"
        >
          <v-data-table
            :headers="doubledTable ? table.comparisonHeaders : table.headers"
            :items="table.items"
            :search="search"
            :sort-by="['visits']"
            :sort-desc="[true]"
            :footer-props="{ itemsPerPageOptions: [50, -1] }"
            :items-per-page="50"
            class="fixed-table-sw-d"
            dense
          >
            <template v-slot:body="{ items }">
              <tbody v-if="doubledTable">
                <tr v-for="item in items" :key="item.domain">
                  <td style="white-space: nowrap">
                    <span
                      ><a
                        :href="getReferralUrl(null, item.url)"
                        style="color: #000000"
                        target="_blank"
                        >{{ item.url }}</a
                      ></span
                    >
                    <span
                      ><a target="_blank" :href="item.url_full"
                        ><v-icon small>mdi-open-in-new</v-icon></a
                      ></span
                    >
                  </td>
                  <td class="text-right">
                    {{ item.clicks_gsc1 }}
                  </td>
                  <td class="text-right">
                    {{ item.clicks_gsc2 }}
                  </td>
                  <td class="text-right">
                    {{ item.clicks1 }}
                  </td>
                  <td class="text-right">
                    {{ item.clicks2 }}
                  </td>
                  <td
                    class="text-right"
                    :style="'background-color:' + item.c_c_color1"
                  >
                    {{ item.c_c1 }}%
                  </td>
                  <td
                    class="text-right"
                    :style="'background-color:' + item.c_c_color2"
                  >
                    {{ item.c_c2 }}%
                  </td>
                  <td class="text-right">
                    {{ item.clicks_unique1 }}
                  </td>
                  <td class="text-right">
                    {{ item.clicks_unique2 }}
                  </td>
                  <td
                    class="text-right"
                    :style="'background-color:' + item.c_unique_c_gsc_color1"
                  >
                    {{ item.c_unique_c_gsc1 }}%
                  </td>
                  <td
                    class="text-right"
                    :style="'background-color:' + item.c_unique_c_gsc_color2"
                  >
                    {{ item.c_unique_c_gsc2 }}%
                  </td>
                  <td class="text-right">
                    {{ item.lead1 }}
                  </td>
                  <td class="text-right">
                    {{ item.lead2 }}
                  </td>
                  <td class="text-right">
                    {{ item.sale1 }}
                  </td>
                  <td class="text-right">
                    {{ item.sale2 }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="item in items" :key="item.domain">
                  <td style="white-space: nowrap">
                    <span
                      ><a
                        :href="getReferralUrl(null, item.url)"
                        style="color: #000000"
                        target="_blank"
                        >{{ item.url }}</a
                      ></span
                    >
                    <span
                      ><a target="_blank" :href="item.url_full"
                        ><v-icon small>mdi-open-in-new</v-icon></a
                      ></span
                    >
                  </td>
                  <td class="text-right">
                    {{ item.clicks_gsc }}
                  </td>
                  <td class="text-right">
                    {{ item.clicks }}
                  </td>
                  <td
                    class="text-right"
                    :style="'background-color:' + item.c_c_color"
                  >
                    {{ item.c_c }}%
                  </td>
                  <td class="text-right">
                    {{ item.clicks_unique }}
                  </td>
                  <td
                    class="text-right"
                    :style="'background-color:' + item.c_unique_c_gsc_color"
                  >
                    {{ item.c_unique_c_gsc }}%
                  </td>
                  <td class="text-right">
                    {{ item.lead }}
                  </td>
                  <td class="text-right">
                    {{ item.sale }}
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('main_table', item.url)"
                      style="color: #000000"
                      target="_blank"
                      ><span v-if="item.main_table > 0">{{
                        item.main_table
                      }}</span></a
                    >
                  </td>
                  <td
                    class="text-right"
                    :style="'background-color:' + item.main_table_clicks_color"
                  >
                    <a
                      :href="getReferralUrl('main_table', item.url)"
                      style="color: #000000"
                      target="_blank"
                      ><span v-if="item.main_table_clicks > 0">
                        {{ item.main_table_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('casino_review', item.url)"
                      target="_blank"
                      style="color: #000000"
                    >
                      <span v-if="item.casino_review > 0">{{
                        item.casino_review
                      }}</span></a
                    >
                  </td>
                  <td
                    :style="
                      'background-color:' + item.casino_review_clicks_color
                    "
                  >
                    <a
                      :href="getReferralUrl('casino_review', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.casino_review_clicks > 0"
                        >{{ item.casino_review_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('top3_table', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.top3_table > 0">{{
                        item.top3_table
                      }}</span></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('top3_table', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.top3_table_clicks > 0"
                        >{{ item.top3_table_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('bonus_table', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.bonus_table > 0">{{
                        item.bonus_table
                      }}</span></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('bonus_table', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.bonus_table_clicks > 0"
                        >{{ item.bonus_table_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('sidebar', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.sidebar > 0">{{
                        item.sidebar
                      }}</span></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('sidebar', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.sidebar_clicks > 0"
                        >{{ item.sidebar_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('bonus_sidebar_logo', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.bonus_sidebar_logo > 0">{{
                        item.bonus_sidebar_logo
                      }}</span></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('bonus_sidebar_logo', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.bonus_sidebar_logo_clicks > 0"
                        >{{ item.bonus_sidebar_logo_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('related_casino_play', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.related_casino_play > 0">{{
                        item.related_casino_play
                      }}</span></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('related_casino_play', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.related_casino_play_clicks > 0"
                        >{{ item.related_casino_play_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('bonus_poster', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.bonus_poster > 0">{{
                        item.bonus_poster
                      }}</span></a
                    >
                  </td>
                  <td class="text-right">
                    <a
                      :href="getReferralUrl('bonus_poster', item.url)"
                      target="_blank"
                      style="color: #000000"
                      ><span v-if="item.bonus_poster_clicks > 0"
                        >{{ item.bonus_poster_clicks }}%</span
                      ></a
                    >
                  </td>
                  <td class="text-right">
                    {{ item.bonus_header }}
                  </td>
                  <td class="text-right">
                    {{ item.alt_casino_table }}
                  </td>
                  <td class="text-right">
                    {{ item.alt_casino_table_clicks }}
                  </td>
                  <td class="text-right">
                    {{ item.bonus_header_clicks }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </table-wrapper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import eventPipe from "../../../events/eventPipe";
import PageName from "@/components/DashboardDev/PageName";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import {
  CONVERSION_2_TABLE_COMPARISON_HEADERS,
  CONVERSION_2_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import TableWrapper from "@/components/UI/TableWrapper";
import ComparisonFilter from "@/components/UI/ComparisonFilter";

let dateFormat = require("dateformat");

let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
let beforeOneWeek2 = new Date(beforeOneWeek);
let day = beforeOneWeek.getDay();
let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 50));
let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

export default {
  name: "Conversion2Index",
  data: () => ({
    search: "",
    loadings: {
      table: false,
    },
    date_modal: false,
    filter: {
      date_range: [
        dateFormat(lastMonday, "yyyy-mm-dd"),
        dateFormat(lastSunday, "yyyy-mm-dd"),
      ],
      country: false,
      subproject_id: undefined,
      comparison: [],
    },
    doubledTable: false,
    urls: [],
    countries: [],
    table: {
      headers: CONVERSION_2_TABLE_HEADERS,
      comparisonHeaders: CONVERSION_2_TABLE_COMPARISON_HEADERS,
      items: [],
    },
    firstEnter: true,
    menuProps: DEFAULT_MENU_PROPS,
    comparisonLabel: null,
  }),
  components: { ComparisonFilter, TableWrapper, DateFilter, PageName },
  methods: {
    handleUpdateComparison(event) {
      this.filter.comparison = event;
    },
    async fetch_data() {
      try {
        if (this.id_project === null || this.id_project === undefined) {
          return;
        }

        if (this.filter.comparison.length) {
          this.doubledTable = true;
        } else {
          this.doubledTable = false;
        }

        this.firstEnter = false;
        this.loadings.table = true;

        let data = {
          payload: {
            filter: this.filter,
          },
          projectId: this.id_project,
        };

        const resp = await this.$store.dispatch(
          "conversion-2/fetchTableData",
          data
        );
        if (resp) {
          this.table.items = resp;
          this.$forceUpdate();
        }

        this.loadings.table = false;
      } catch (e) {
        this.loadings.table = false;
        this.doubledTable = false;
      }
    },
    getReferralUrl(table, url) {
      let r =
        "/analytics/conversion3/report?date_range=" +
        this.filter.date_range +
        "&url=" +
        url;
      if (table) {
        r += "&table=" + table;
      }
      return r;
    },
    update_setting() {
      this.$store.dispatch("update_ls_serp_setting", this.ls_settings);
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    subprojects() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      );
      if (project && project[0]) return project[0]["subprojects"];
      else return [];
    },
  },
  created() {
    eventPipe.$on("update-active-project", () => this.fetch_data);
  },
};
</script>

<style scoped>
.fixed-table-sw-d thead > tr > th:first-child,
.fixed-table-sw-d td:first-child {
  position: sticky;
  left: 0;
  min-width: 90px;
  z-index: 2;
}
</style>
