<template>
  <div>
    <v-card
        elevation="2"
        v-for="item in items" :key="item.domain"
    >
      <v-card-title>
        {{ item.domain }} - {{ item.primary_key }}
        <v-spacer></v-spacer>
        <v-btn icon @click="viewInfo(item.domain)">
          <v-icon>mdi-chart-donut</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-list-item dense class="pa-0" v-for="line in item.news" :key="line[0]">
                <v-list-item-content>
                  <v-list-item-title>{{ line[1] }}</v-list-item-title>
                  <v-list-item-subtitle><a :href="line[0]" target="_blank">{{ line[0] }}</a></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="5">
              <AhTopPages :custom-domain="item.domain" assignment="gtt"/>
            </v-col>
            <v-col cols="3">
              <TRTopics :custom-domain="item.domain" custom-height="150" class="mb-4"/>
              <SwTop5ReferringCategories :custom-domain="item.domain"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import AhTopPages from "@/components/ViewInfoBySite/Children/AhTopPages";
import TRTopics from "@/components/ViewInfoBySite/Children/TRTopics";
import SwTop5ReferringCategories from "@/components/ViewInfoBySite/Children/SwTop5ReferringCategories";


export default {
  name: "Show",
  components: {
    SwTop5ReferringCategories,
    AhTopPages,
    TRTopics,
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  methods: {
    fetchData() {
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/gtt/${self.$route.params.id}`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.items = r.data.items
          })
          .finally(() => self.loading = false)
    },
    viewInfo(domain) {
      window.open(`/view-info-by-site/show?domain=${domain}`, '_blank')
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>