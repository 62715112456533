export default {
  state: {
    menu_select_projects: false,
    active_project:
      typeof localStorage.getItem("active_na_project") === "string" &&
      localStorage.getItem("active_na_project") !== "undefined"
        ? localStorage.getItem("active_na_project")
        : null,
    loading: false,
  },
  actions: {
    toggle_select_project_menu(ctx) {
      ctx.commit("setSelectProjectMenu", !ctx.getters.menu_select_projects);
    },
    update_active_na_project(ctx, project) {
      ctx.commit("updateActiveNaProject", project);
    },
  },
  mutations: {
    setSelectProjectMenu(state, payload) {
      state.menu_select_projects = payload;
    },
    updateActiveNaProject(state, project) {
      localStorage.setItem("active_na_project", project);

      state.active_project = project;
    },
  },
  getters: {
    menu_select_projects: (state) => state.menu_select_projects,
  },
};
