<template>
  <v-row v-if="filters">
    <v-col>
      <v-autocomplete
        v-model="filters.type"
        :items="['day', 'week']"
        hide-details
        dense
        clearable
        outlined
        label="Type"
      />
    </v-col>
    <v-col>
      <smart-date-filter
        :model-value="filters.date"
        @update:modelValue="filters.date = $event"
      />
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="filters.user_id"
        :items="filtersData.users"
        :loading="filtersDataPending"
        item-value="id"
        item-text="email"
        hide-details
        dense
        clearable
        outlined
        multiple
        label="User"
      />
    </v-col>
    <v-col>
      <v-btn
        @click="$emit('getData', filters)"
        class="text-normal"
        color="primary"
        height="40"
        block
        :loading="loading"
      >
        Get data
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import SmartDateFilter from "../../../components/UI/SmartDateFilter.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

export default {
  components: { SmartDateFilter },

  mixins: [UploadFiltersDataMixin],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {},
  },

  emits: ["getData", "input"],

  data: () => ({
    filtersData: {
      users: [],
    },
  }),

  computed: {
    /** @type {import('../types.js').GetPerformancePayload} */
    filters: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  mounted() {
    this.uploadFiltersData(["users"]);
  },
};
</script>
