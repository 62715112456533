<template>
  <div class="conversion-7">
    <v-row dense>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          Conversion 7
        </page-name>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          label="Region"
          v-model="filters.is_region"
          :items="regionItems"
          hide-details
          dense
          item-text="name"
          item-value="value"
          outlined
          :menu-props="menuProps"
          @change="_$handleFilterChange($event, 'is_region', 'filters')"
        />
      </v-col>
      <v-col>
        <v-text-field
          type="number"
          outlined
          hide-details
          dense
          label="SValueP"
          v-model="filters.sValueP"
          title="SValueP"
          @change="handleChangeSValueP"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          label="Brand"
          v-model="filters.brand"
          :items="brandItems"
          item-text="brand"
          item-value="brand"
          clearable
          outlined
          multiple
          dense
          :loading="loadings.brands"
          hide-details
          :menu-props="menuProps"
          @change="_$handleFilterChange($event, FILTERS_IDS.brand)"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Format"
          v-model="filters.format"
          :items="formatItems"
          item-text="name"
          item-value="id"
          clearable
          outlined
          dense
          hide-details
          :menu-props="menuProps"
          @change="_$handleFilterChange($event, FILTERS_IDS.format)"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <div style="position: relative">
          <div
            v-if="filters.comparison[0].length !== 0"
            class="disabled-tooltip bottom"
          >
            <span> When comparison filter enabled - date can't be used. </span>
          </div>
          <date-filter
            :model-value="filters.date_range"
            :disabled="filters.comparison[0].length !== 0"
            @update:modelValue="handleUpdateDateFilter"
          />
        </div>
      </v-col>
      <v-col>
        <div class="fix-button-height" style="position: relative">
          <comparison-filter
            :model-value="filters.comparison"
            @update:modelValue="handleUpdateComparisonFilter"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          elevation="0"
          color="primary"
          @click="fetchData"
          :loading="loadings.brands || loadings.tables"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model="filters.url"
          :items="urlItems"
          label="Url"
          item-value="id"
          item-text="url"
          placeholder="Enter url"
          :loading="loadings.url"
          dense
          multiple
          clearable
          outlined
          hide-details
          @change="_$handleFilterChange($event, 'url')"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.pageType"
          label="Page type"
          placeholder="Select page type"
          dense
          outlined
          hide-details
          @change="_$handleFilterChange($event, 'pageType')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.subproject"
          label="Subproject"
          placeholder="Select subproject"
          dense
          outlined
          hide-details
          @change="_$handleFilterChange($event, 'subproject')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="rowItems"
          v-model="filters.selected_rows"
          label="Rows"
          placeholder="Select rows"
          dense
          outlined
          multiple
          hide-details
          @change="_$handleFilterChange($event, 'selected_rows')"
        ></v-autocomplete>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <table-wrapper
          :items="tables"
          :loading="loadings.tables"
          :first-enter="firstEnter"
        >
          <template v-for="table in tables">
            <v-data-table
              :key="table.project"
              :headers="table.headers"
              :items="table.items"
              :items-per-page="-1"
              hide-default-footer
              class="conversion-7-table"
            >
              <template #top>
                <div class="project-name">
                  {{ table.project }}
                </div>
              </template>
              <template #body="{ items }">
                <custom-tbody :items="items" />
              </template>
            </v-data-table>
          </template>
        </table-wrapper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import eventPipe from "../../../events/eventPipe";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import PageName from "@/components/DashboardDev/PageName";
import {
  CONV_7_ROW_NAMES,
  CONVERSION_4_CHART_OPTIONS,
  CONVERSION_6_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import ComparisonFilter from "@/components/UI/ComparisonFilter";
import TableWrapper from "@/components/UI/TableWrapper";
import Project from "@/mixins/Project";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import CustomTbody from "@/components/Analytics/Conversion/UI/Conversion7/CustomTBody";
import FetchFilters from "@/mixins/FetchFilters";
import Location from "../../../mixins/Location";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "Chart",
  components: {
    CustomTbody,
    TableWrapper,
    ComparisonFilter,
    DateFilter,
    PageName,
  },
  mixins: [
    OnePageAnalyzerIndex,
    Project,
    FiltersHandling,
    FetchFilters,
    Location,
  ],
  data: () => ({
    urlItems: [],
    brandItems: [],
    firstEnter: true,
    menuProps: DEFAULT_MENU_PROPS,
    loadings: {
      tables: false,
      brands: false,
      url: false,
    },
    series: [],
    projects: [],
    filtersData: {
      pageType: [],
    },
    FILTERS_IDS: {
      projects: "projects",
      date_range: "date_range",
      comparison: "comparison",
      brand: "brand",
      format: "format",
    },
    filters: {
      date_range: [],
      subproject: null,
      url: [],
      pageType: null,
      comparison: [[], []],
      brand: [],
      format: null,
      is_region: true,
      sValueP: 20,
      selected_rows: [],
    },
    regionItems: [
      { name: "Region", value: true },
      { name: "NOT Region", value: false },
    ],
    rowItems: CONV_7_ROW_NAMES,
    tables: [],
    tablesData: {
      headers: CONVERSION_6_TABLE_HEADERS,
    },
    tableHeaders: [],
    tableItems: [],
    date_modal: false,
    formatItems: [
      { id: 3, name: "day" },
      { id: 1, name: "week" },
      { id: 2, name: "month" },
      { id: 4, name: "quarter" },
      { id: 5, name: "half year" },
      { id: 6, name: "year" },
    ],
    options: CONVERSION_4_CHART_OPTIONS,
  }),
  watch: {
    "filters.comparison"(value) {
      if (value) {
        this.filters.date_range = [];
      }
    },
    selectedProjects() {
      //
    },
  },
  methods: {
    handleChangeSValueP(event) {
      this._$handleFilterChange(event, "sValueP", "filters");
      this.$store.commit("conversion-7/setSValueP", event);
    },
    handleUpdateComparisonFilter(event) {
      this.filters.comparison = event;
    },
    handleUpdateDateFilter(event) {
      this.filters.date_range = event;
      this._$handleFilterChange(event, this.FILTERS_IDS.date_range);
    },
    async fetchBrand() {
      this.firstEnter = false;

      if (this.id_project === null || this.id_project === undefined) {
        return;
      }

      this.loadings.brands = true;

      const data = {
        payload: {
          params: this.filters,
        },
        projectId: this.id_project,
      };

      const resp = await this.$store.dispatch("conversion-4/fetchBrands", data);
      if (resp) {
        this.table.items = resp.data;
      }

      this.loadings.brands = false;
    },
    async fetchData() {
      if (this.id_project === null || this.id_project === undefined) {
        return;
      }

      this.loadings.tables = true;
      this.firstEnter = false;

      const data = {
        payload: {
          filters: {
            selected_rows: this.filters.selected_rows,
            date_range: this.filters.date_range,
            comparison: this.filters.comparison,
            brand: this.filters.brand,
            format: this.filters.format,
            is_region: this.filters.is_region,
            url_id: this.filters.url,
          },
        },
        projectId: this.id_project,
      };

      const resp = await this.$store.dispatch("conversion-7/fetchData", data);
      if (resp) {
        this.tables = resp.tables;
        this.$forceUpdate();
      }

      this.loadings.tables = false;
    },
  },
  mounted() {
    const haveParams = this._$collectParams(
      ["projects", "date_range", "brand", "url"],
      "filters"
    );

    this._$fetchUrls();
    this._$fetchBrands();

    eventPipe.$on("update-active-project", () => {
      this.fetchData();
    });

    this.handleChangeSValueP(20);

    this._$setProjectInURL();

    if (!this.$route.query[this.FILTERS_IDS.date_range]) {
      this.filters.date_range = [
        dateFormat(new Date().setMonth(date.getMonth() - 3), "yyyy-mm-dd"),
        dateFormat(date, "yyyy-mm-dd"),
      ];
    }

    if (!this.$route.query[this.FILTERS_IDS.format]) {
      this.filters.format = 1;
    }

    if (haveParams) {
      this.fetchData();
    }
  },
  computed: {
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
};
</script>

<style lang="scss"></style>
