<template>
  <v-card>
    <div id="top-chart-small-link-types"></div>
  </v-card>
</template>

<script>
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";
import axios from "axios";
import events from "../../../../events/components/OnePageAnalyzer/events";

export default {
  name: "LinkTypes",
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {
      options: {
        chart: {
          height: 200,
          type: "donut",
        },
        title: {
          text: "Link Types",
          align: "left",
        },
        labels: [],
        colors: [],
        dataLabels: {
          formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    };
  },
  methods: {
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/chart`;

      let config = {
        params: {
          url: self.selectedUrl,
          type: "link_types_distribution",
          filter: self.filterData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.options.labels = r.data.labels;
        self.options.colors = r.data.colors;

        let options = self.options;
        options.series = r.data.series;

        self.drawChart("top-chart-small-link-types", options);
      });
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.fetchData();
    });

    events.$on("update-main-filter", function () {
      self.fetchData();
    });
  },
};
</script>

<style scoped></style>
