<template>
  <date-td
    :color="getColor"
    :value="value"
    :title="`Days difference: ${daysDiff(value)} | ${value}`"
  />
</template>

<script>
import DateTd from "@/components/Dashboards/ContentPlaning/components/DateTd.vue";

export default {
  components: { DateTd },
  props: {
    value: {},
    data: {},
  },
  computed: {
    getColor() {
      const daysDiff = this.daysDiff(this.value);

      if (this.data.current_status !== "done" && daysDiff < 5) return "warning";

      if (this.data.current_status !== "done" && daysDiff < 10) return "error";

      return "transparent";
    },
  },
  methods: {
    daysDiff(date) {
      const today = this.$moment();

      return this.$moment(date).diff(today, "days");
    },
  },
};
</script>
