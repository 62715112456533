import service from "../../../plugins/service";

export const gpmDonorApiService = () => {
  const ENDPOINTS = {
    DECLINE: "/gpm/entity-view/decline-donor",
    BAD_STATUSES: "/gpm/admin/bad-donor-status",
  };

  const getBadStatuses = async (entityId) => {
    return await service.get(ENDPOINTS.BAD_STATUSES, true, {
      params: {
        entityID: entityId,
      },
    });
  };

  const markAsBad = (tags, entityId) => {
    const config = {
      params: {
        entityID: entityId,
      },
    };

    const payload = {
      tags,
    };

    return service.post(ENDPOINTS.DECLINE, payload, true, config);
  };

  const revive = (entityId) => {
    const config = {
      params: {
        entityID: entityId,
      },
    };

    return service.post(ENDPOINTS.DECLINE, {}, true, config);
  };

  const decline = (reason, entityId) => {
    const config = {
      params: {
        entityID: entityId,
      },
    };

    const payload = {
      reason,
    };

    return service.post(ENDPOINTS.DECLINE, payload, true, config);
  };

  return {
    decline,
    revive,
    getBadStatuses,
    markAsBad,
  };
};
