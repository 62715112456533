/*eslint-disable*/
import { charAt } from "core-js/internals/string-multibyte";
import { deepClone } from "@/utils/functions";

export default {
  data: () => ({
    FFM_TYPES: {
      text: "text",
      textarea: "textarea",
      number: "number",
      singlePick: "single_pick",
      multiPick: "multi_pick",
      dateRange: "date_range",
      singleDate: "single_date",
      numbersRange: "numbers_range",
      checkbox: "checkbox",
    },
    defaultValues: {
      multi_pick: [],
      date_range: [null, null],
      numbers_range: [null, null],
    },
  }),
  methods: {
    filtersFactoryInitFilters(filters) {
      const output = {};

      filters.forEach((filter) => {
        output[filter.value] =
          filter.default_value || this.defaultValues[filter.type] || null;
      });

      return output;
    },
    _$initializeColumns(filters, config) {
      // This method mutating !!filters!! value.
      try {
        const settings = config.columnSetting;

        Object.entries(filters).forEach(([idx, { value }]) => {
          const currentSetting = settings[value];

          if (currentSetting) {
            filters[idx] = { ...filters[idx], ...currentSetting };
          }
        });
      } catch (e) {
        console.error("Filters Factory. Error while initializing columns.", e);
      }
    },
    _$initializeGroups(result, filters, config) {
      // This method mutating !!result!! value.
      try {
        const othersFilters = {
          name: "Others",
          filters: [],
        };

        filters.forEach((filter) => {
          let isFilterContainsInExistedGroups = false;

          Object.values(config.groups).forEach((group, idx) => {
            const isLastGroup = idx === config.groups.length - 1;
            const existedGroupIdx = result.findIndex(
              (g) => g.name === group.name
            );
            const isCurrentGroupAlreadyExist = existedGroupIdx !== -1;

            let groupData;
            const newGroup = {
              name: group.name,
              filters: [],
            };

            if (isCurrentGroupAlreadyExist) {
              groupData = result[existedGroupIdx];
            } else {
              groupData = newGroup;
            }

            if (group.filters.includes(filter.value)) {
              groupData.filters.push(filter);
              isFilterContainsInExistedGroups = true;
            }

            if (isCurrentGroupAlreadyExist) return;

            if (groupData.filters.length > 0) result.push(groupData);
          });

          if (!isFilterContainsInExistedGroups)
            othersFilters.filters.push(filter);
        });

        if (othersFilters.filters.length > 0) result.push(othersFilters);
      } catch (e) {
        console.error("Filters Factory. Error while initializing groups.", e);
      }
    },
    filtersFactoryGetFiltersGroups(filtersRawData, config) {
      const filters = deepClone(filtersRawData);
      const result = [];

      if (config.columnSetting) {
        this._$initializeColumns(filters, config);
      }

      if (config.groups) {
        this._$initializeGroups(result, filters, config);
      }

      return result;
    },
  },
};
