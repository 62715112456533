<template>
  <v-dialog v-model="dialog" scrollable width="420">
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2 pa-3">
        {{ title || "Select reason" }}
        <v-spacer />
        <v-icon @click="dialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-card-subtitle
        v-if="subtitle"
        class="text-body-2 px-3 pt-2 pb-3"
        style="opacity: 0.7"
      >
        {{ subtitle }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text class="pa-3">
        <v-autocomplete
          v-model="reason"
          :items="filtersData?.gpm_reserve_reasons || [1]"
          hide-details
          dense
          outlined
          label="Reason"
          multiple
          placeholder="Type to search"
        />
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn class="text-normal px-6" large text @click="handleReject">
          {{ rejectText || "Cancel" }}
        </v-btn>
        <v-btn
          class="text-normal px-6"
          large
          outlined
          @click="handleConfirm"
          :disabled="!reason?.length"
        >
          {{ confirmText || "Confirm" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";

export default {
  props: ["filtersData", "confirmText", "rejectText", "subtitle", "title"],

  mixins: [Dialog],

  emits: ["confirm", "reject"],

  data: () => ({
    reason: [],
  }),

  methods: {
    handleReject() {
      this.$emit("reject", { reason: this.reason });
      this.reason = [];
    },
    handleConfirm() {
      this.$emit("confirm", { reason: this.reason });
      this.reason = [];
    },
  },
};
</script>
