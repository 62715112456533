<template>
    <v-autocomplete
      v-model="value"
      chips
      small-chips
      label="Tags"
      outlined dense
      hide-details prepend-inner-icon="mdi-tag-outline"
      multiple
      clearable
      deletable-chips
      item-text="title"
      item-value="id"
      :loading="loading"
      :menu-props="menuProps"
      :items="items"
    />
</template>

<script>
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";

export default {
  props: {
    modelValue: {},
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {return[]}
    }
  },
  data: () => ({
    menu: false,
    search: null,
    menuProps: DEFAULT_MENU_PROPS,
  }),
  computed: {
    value: {
      get() {return this.modelValue},
      set(value) {this.$emit('update:modelValue', value)}
    }
  },
};
</script>