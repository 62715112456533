<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col v-if="data.data[1]">
          <apexchart
            type="bubble"
            height="350"
            :options="getOptions()"
            :series="data.data[1].series"
          />
        </v-col>
        <v-col v-else cols="4">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  methods: {
    getOptions() {
      return {
        chart: {
          height: 350,
          type: "bubble",
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0.8,
        },
        xaxis: {
          tickAmount: 12,
          type: "datetime",
        },
        yaxis: {
          max: 105,
        },
      };
    },
  },
};
</script>
