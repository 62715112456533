<template>
  <thead>
    <tr>
      <th v-for="header in props.headers" :key="header.value">
        <span
          v-if="header.value !== 'name' && header.value !== 'all'"
          v-html="header.text"
          @click="goToUserActivity(header.userID || 611)"
          style="cursor: pointer"
        ></span>
        <span v-else>{{ header.text }}</span>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  props: ["props", "filters"],
  methods: {
    goToUserActivity(userID) {
      let query = `?userID=${userID}`;

      if (this.filters.dateRange) {
        query += `&dateRange=${this.filters.dateRange}`;
      }
      if (this.filters.site) {
        query += `&site=${this.filters.site}`;
      }

      window.open(query, "_blank");
    },
  },
};
</script>
