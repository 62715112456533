<template>
  <td>
    <span v-if="data.content_ready_at" class="nowrap">
      {{
        data.content_ready_at.text ? $moment(data.content_ready_at.text).format('DD/MM') : null
      }}
    </span>
  </td>
</template>

<script>
export default {
  props: ['data']
};
</script>