export default {
  data: () => ({
    hem_multiSearch: {},
    hem_gradients: {},
  }),
  computed: {
    hem_filteredData() {
      if (this.hem_multiSearch) {
        const items = this?.items || this.tableData?.items;

        this.hem_getFilteredItems(items);
      } else {
        return this.tableData?.items || [];
      }
    },
  },
  methods: {
    hem_getFilteredItems(items = []) {
      if (this.hem_multiSearch) {
        return items?.filter((item) => {
          return Object.entries(this.hem_multiSearch).every(([key, value]) => {
            if (value.includes("|") && !value.includes("!")) {
              let el = value.split("|");
              return el.some((elem) =>
                (item[key] || "")
                  .toString()
                  .toUpperCase()
                  .startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.substring(0, 1) === "!" && !value.includes("|")) {
              let el = value.split("!");
              return el.some(
                (elem) =>
                  !(item[key] || "")
                    .toString()
                    .toUpperCase()
                    .startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.includes("|") && value.substring(0, 1) === "!") {
              let el = value.split("!")[1].split("|");
              return !el.some((elem) =>
                (item[key] || "")
                  .toString()
                  .toUpperCase()
                  .startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.substring(0, 1) === ">") {
              let el = value.split(">");
              if (item[key] !== " ") {
                return Number(item[key] || "") > el[1];
              }
            }
            if (value.substring(0, 1) === "<") {
              let el = value.split("<");
              if (item[key] !== " ") {
                return Number(item[key] || "") < el[1];
              }
            }
            if (value.substring(0, 1) === "=") {
              let el = value.split("=");
              return (
                (String(item[key]) || "").toString().toUpperCase() ===
                el[1].toString().toUpperCase()
              );
            }
            return (item[key] || "")
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase());
          });
        });
      } else {
        return this.tableData?.items || [];
      }
    },
    hem_removeFromMultiSearch(multisearchKey) {
      this.$delete(this.hem_multiSearch, multisearchKey);
    },
    hem_highlight(header, reverse) {
      const gradients = this.hem_gradients;

      const gradient = gradients[header.value];

      if (!gradient)
        return this.$set(this.hem_gradients, header.value, { reverse });

      if (gradient.reverse === reverse) {
        return this.$delete(this.hem_gradients, header.value);
      }

      this.$set(this.hem_gradients, header.value, { reverse });
    },
  },
};
