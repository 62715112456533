<template>
  <div>
    <dev-dashboard-aparser-task-table :items="componentData()?.items || []" />
  </div>
</template>

<script>
import DevDashboardAparserTaskTable from "../../DashboardDev/components/DevDashboardAparserTaskTable.vue";

export default {
  components: { DevDashboardAparserTaskTable },
  inject: ["componentData"],
};
</script>
