import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "dev-dashboard/fetchAparserInfo"(ctx, payload) {
      const url = "/dashboards/dev/a-parser/tasks?status=" + payload;

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/requestAction"(ctx, payload) {
      const url = "/dashboards/dev/a-parser/action-task";

      const resp = await Service.post(url, payload, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/fetchFirst"(ctx, payload = {}) {
      const url = "/dashboards/dev/index";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/fetchFailedProxyRequests"(ctx, payload = {}) {
      const url = "/dashboards/dev/json/failed-proxy-requests";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/monitorActions"(ctx, payload = {}) {
      const url = "/dashboards/dev/monitor/actions";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/ltt"(ctx, payload = {}) {
      const url = "/dashboards/dev/ltt/json";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/dbc"(ctx, payload = {}) {
      const url = "/dashboards/dev/dbc/json";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/at"(ctx, payload = {}) {
      const url = "/dashboards/dev/at/json";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/sp"(ctx, payload = {}) {
      const url = "/dashboards/dev/spiderlink/json";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/ltt-json-score"(ctx, payload = {}) {
      const url = "/dashboards/dev/ltt/json-score";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "dev-dashboard/x"(ctx, payload = {}) {
      const url = "/dashboards/dev/x/json";

      const resp = await Service.get(url, true, payload);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
  },
  getters: {},
};
