<template>
  <v-data-table
    class="anchor-distribution__table"
    :headers="headers"
    :items="items"
    :custom-sort="customSort"
    :sort-by="['vol']"
    :sort-desc="[true]"
    :footer-props="{ itemsPerPageOptions: [50, 100, 150, 200, -1] }"
    :items-per-page="50"
  >
    <template v-for="h in headers" #[`header.${h.value}`]="{ header }">
      <v-tooltip :key="h.value" bottom>
        <template #activator="{ on }">
          <div
            v-on="on"
            :style="{
              background:
                getSelectedUrl === header.text ? 'rgba(28,90,250,0.35)' : '',
            }"
          >
            {{ fixHeader(header.text) }}
          </div>
        </template>
        <div>{{ header.tooltip ? header.tooltip : header.text }}</div>
      </v-tooltip>
    </template>
    <template #body="{ items }">
      <tbody>
        <tr v-for="(row, index) in items" :key="index">
          <td
            v-for="(key, i) in Object.keys(row)"
            :key="key + i"
            :style="`background-color: ${getRgb(
              getValue(row[key]),
              getMax(items, key),
              50,
              233,
              153
            )}`"
          >
            <v-tooltip v-if="hasHover(row[key])" bottom>
              <template #activator="{ on }">
                <span v-on="on">{{ getValue(row[key]) }}</span>
              </template>
              <div>{{ row[key].hover }}</div>
            </v-tooltip>
            <span v-else>{{ getValue(row[key]) }}</span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import GetRgb from "@/mixins/GetRgb";
import TableSorting from "@/mixins/TableSorting";

export default {
  props: ["headers", "items", "selectedUrlId", "filters"],
  mixins: [GetRgb, TableSorting],
  computed: {
    getSelectedUrl() {
      return (
        this.filters.url.find((i) => i.value === this.selectedUrlId).text || ""
      );
    },
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        try {
          for (let i in sortBy) {
            /*eslint-disable*/
            const value1 = a[sortBy[i]].hasOwnProperty("value")
              ? a[sortBy[i]].value
              : a[sortBy[i]];
            const value2 = b[sortBy[i]].hasOwnProperty("value")
              ? b[sortBy[i]].value
              : b[sortBy[i]];

            const string = isNaN(parseInt(value1)) && isNaN(parseInt(value2));

            if (string) {
              let aVal, bVal;
              if (value1 === null) aVal = 0;
              if (value2 === null) bVal = 0;
              if (aVal === 0 && bVal !== 0) return 1;
              if (bVal === 0 && aVal !== 0) return -1;

              const val1 = value1;
              const val2 = value2;
              if (val1 > val2) return sortDesc[i] ? -1 : 1;
              if (val1 < val2) return sortDesc[i] ? 1 : -1;
              return 0;
            }

            let aVal, bVal;
            if (isNaN(parseInt(value1))) aVal = 0;
            if (isNaN(parseInt(value2))) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (value1 > value2) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < value2) return sortDesc[i] ? 1 : -1;
          }
        } catch {
          //
        }
        return 0;
      });
      return items;
    },
    getMax(items, key) {
      let bigger = 0;

      items.forEach((row) => {
        const val = this.getValue(row[key]);
        if (typeof val !== "string") {
          if (parseInt(val) > bigger) bigger = parseInt(val);
        }
      });

      return bigger;
    },
    fixHeader(string) {
      return string
        .replaceAll("https://", "")
        .replaceAll("http://")
        .split("/")[0];
    },
    hasHover(data) {
      if (!data) {
        return !!data;
      }

      // eslint-disable-next-line no-prototype-builtins
      return data.hasOwnProperty("hover");
    },
    getValue(data) {
      if (!data) {
        return data;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("value")) {
        return data.value;
      }
      return data;
    },
  },
};
</script>

<style scoped></style>
