<template>
  <div class="positions-trend-card">
    <div class="positions-trend-card__title">
      {{ trend.title }}
    </div>
    <div class="positions-trend-card__value">
      <strong>{{ trend.content.value }}{{ trend.content.append }}</strong>
      <template v-if="trend.diff.value > 0">
        <small class="success--text">
          <v-icon size="10" class="success--text">mdi-triangle</v-icon>
          <span>{{ trend.diff.value }}{{ trend.diff.append }}</span>
        </small>
      </template>
      <template v-else-if="trend.diff.value < 0">
        <small class="error--text">
          <v-icon
            size="10"
            class="error--text"
            style="transform: rotate(180deg)"
          >
            mdi-triangle
          </v-icon>
          <span>
            {{ String(trend.diff.value).replace("-", "")
            }}{{ trend.diff.append }}
          </span>
        </small>
      </template>
      <template v-else>
        <small>
          <span>{{ trend.diff.value }}{{ trend.diff.append }}</span>
        </small>
      </template>
    </div>
    <div class="positions-trend-card__chart">
      <vue-apex-charts
        type="area"
        :series="trend.chart.series"
        :options="getOptions"
        height="60"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    trend: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getOptions() {
      return {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 8,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "numeric",
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },

          labels: {
            show: false,
          },
        },
        yaxis: {
          show: true,
        },
        // yaxis: [
        //   {
        //     y: 0,
        //     offsetX: 0,
        //     offsetY: 0,
        //     padding: {
        //       left: 0,
        //       right: 0,
        //     },
        //   },
        // ],
        tooltip: {
          x: {
            show: false,
            format: "dd/MM/yy HH:mm",
          },
        },
      };
    },
  },
};
</script>
