<template>
  <td class="em-conv-td">
    <template v-if="onlyAll">
      {{ getValue(data.all) }}
    </template>
    <template v-else-if="noDate"> </template>
    <template v-else>
      <div class="em-conv-td__header" title="ALL">
        {{ getValue(data.all) }}
        <sup v-if="data.all_p" :style="{ color: perc2color(data.all_p) }">
          {{ data.all_p }}%
        </sup>
      </div>
      <div class="em-conv-td__body">
        <span title="NEW">
          {{ getValue(data.new) }}
          <sup v-if="data.new_p" :style="{ color: perc2color(data.new_p) }">
            {{ data.new_p }}%
          </sup>
        </span>
        <span title="LTT">
          {{ getValue(data.ltt) }}
          <sup v-if="data.ltt_p" :style="{ color: perc2color(data.ltt_p) }">
            {{ data.ltt_p }}%
          </sup>
        </span>
        <span title="RESERVED">
          {{ getValue(data.reserved) }}
          <sup
            v-if="data.reserved_p"
            :style="{ color: perc2color(data.reserved_p) }"
          >
            {{ data.reserved_p }}%
          </sup>
        </span>
      </div>
    </template>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    noDate() {
      const d = this.data;
      return !d.all && !d.new && !d.ltt && !d.reserved;
    },
    onlyAll() {
      const d = this.data;
      return parseInt(d.all) !== 0 && !d.new && !d.ltt && !d.reserved;
    },
  },
  methods: {
    getValue(value) {
      if (value === 0 || value === "0") return "-";
      return value;
    },
    perc2color(perc) {
      if (!perc) return "#7E7E7EFF";

      const val = parseInt(perc);

      if (val >= 0 && val <= 20) return "red";
      if (val >= 21 && val <= 60) return "orange";
      if (val >= 61) return "green";
      return "black";
    },
  },
};
</script>

<style lang="scss">
.em-conv-td {
  font-weight: 600;
  text-align: center;
  &__header {
    white-space: nowrap;
    margin: auto;
    justify-content: center;
    width: 35px;
  }
  &__body {
    white-space: nowrap;
    span {
      min-width: 35px;
      display: inline-block;
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 5px;
      }
      &:nth-child(2) {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        border-left: 1px solid rgba(0, 0, 0, 0.3);
        padding: 0 5px;
      }
    }
  }
}
</style>
