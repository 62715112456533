<template>
  <span :style="{ opacity: value === 0 ? 0.3 : 1 }">
    {{ numberWithCommas }}
  </span>
</template>

<script>
export default {
  props: {
    value: Number,
  },

  computed: {
    numberWithCommas() {
      let x = this.value;

      if (!x) return "";

      x = String(x);
      const pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");

      return x;
    },
  },
};
</script>
