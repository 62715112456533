export const ACCOUNTS_TABLE_HEADERS = [
  {
    text: "id",
    value: "id",
    width: "75px",
  },
  {
    text: "Username",
    value: "name",
  },
  {
    text: "Email",
    value: "email",
  },
  {
    text: "",
    align: "end",
    value: "_actions",
  },
];
