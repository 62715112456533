<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e3" width="420">
    <v-card flat class="rounded-lg border">
      <v-card-title class="text-body-2 pa-4">
        Create role
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2 mb-1">Role name</div>
            <v-text-field v-model="form.name" outlined hide-details dense />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2 mb-1">Priority</div>
            <v-text-field
              v-model="form.priority"
              type="number"
              min="0"
              outlined
              hide-details
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Back
        </v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          @click="createRole"
          :loading="loadings.create"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { RolesApiService } from "../services/RolesApiService";

export default {
  props: {
    afterCreateCallback: {
      type: Function,
      default: () => {},
    },
  },

  mixins: [Dialog],

  data: () => ({
    form: {
      name: "",
      priority: 0,
    },
    loadings: {
      create: false,
    },
  }),

  methods: {
    resetForm() {
      this.form.name = "";
      this.form.priority = 0;
    },
    async createRole() {
      try {
        this.loadings.create = true;

        await RolesApiService().createRole(this.form);

        this.dialog = false;
        this.resetForm();
        this.afterCreateCallback();
      } catch (e) {
        console.error("Error while creating role.", e);
      } finally {
        this.loadings.create = false;
      }
    },
  },
};
</script>
