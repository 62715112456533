<template>
  <v-container class="dashboard-dev-detailed-page-v2">
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfig.title"
          :breadcrumbs="pageConfig.breadcrumbs"
        />
      </v-col>
      <v-col cols="12" class="d-flex flex-column" style="gap: 1rem">
        <template v-if="!segments?.length">
          <no-items-placeholder />
        </template>
        <template v-else>
          <v-row dense>
            <v-col cols="12" class="d-flex flex-column" style="gap: 0.5rem">
              <template v-for="(segment, idx) in segments">
                <action-segment
                  v-if="segment"
                  :data="segment"
                  :key="`segment-${idx}`"
                />
              </template>
            </v-col>
            <!--            <v-col-->
            <!--              cols="12"-->
            <!--              sm="12"-->
            <!--              md="6"-->
            <!--              class="d-flex flex-column"-->
            <!--              style="gap: 0.5rem"-->
            <!--            >-->
            <!--              <template-->
            <!--                v-for="(segment, idx) in segments.filter(-->
            <!--                  (s) => s.location === 1-->
            <!--                )"-->
            <!--              >-->
            <!--                <action-segment-->
            <!--                  v-if="segment"-->
            <!--                  :data="segment"-->
            <!--                  :key="`segment-${idx}`"-->
            <!--                />-->
            <!--              </template>-->
            <!--            </v-col>-->
          </v-row>
        </template>
      </v-col>
    </v-row>
    <div
      class="d-flex align-end"
      style="position: fixed; right: 1rem; bottom: 1rem; gap: 0.5rem"
    >
      <v-card
        height="44"
        flat
        outlined
        class="px-1 d-flex align-center"
        style="gap: 0.25rem"
        rounded="lg"
        v-if="
          computedSectionsOnPending ||
          computedSectionsWithError ||
          computedSuccessfullyUploadedSections
        "
      >
        <v-chip
          v-if="computedSectionsOnPending"
          label
          class="px-2"
          title="In progress"
        >
          {{ computedSectionsOnPending }}
          <v-icon color="info" small>mdi-clock-time-eight-outline</v-icon>
        </v-chip>
        <v-chip
          v-if="computedSectionsWithError"
          label
          class="px-2"
          title="Has error"
        >
          {{ computedSectionsWithError }}
          <v-icon color="error" small>mdi-alert-circle-outline</v-icon>
        </v-chip>
        <v-chip
          v-if="computedSuccessfullyUploadedSections"
          label
          class="px-2"
          title="Successfully uploaded"
        >
          {{ computedSuccessfullyUploadedSections }}
          <v-icon color="success" small>mdi-check-circle-outline</v-icon>
        </v-chip>
      </v-card>
      <v-btn
        large
        class="text-normal px-6 pl-3"
        dark
        @click="isActionsDialogVisible = true"
      >
        <span class="keycup white">Meta</span> <span class="px-1">+</span>
        <span class="keycup white">A</span>
        <span class="pl-3">Actions</span>
        <v-icon small right>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
    <actions-bottom-sheet
      v-model="isActionsDialogVisible"
      :filters="filters"
      @update:filters="filters = $event"
      :table-data="{ items: actions }"
      :uploaded-actions-statuses="uploadedSegmentsStatus"
      :uploaded-actions-details="uploadedSegmentsDetails"
      :loadings="loadings"
      @upload="uploadActionDetails"
      @uploadAll="handleUploadAll"
    />
    <detailed-page-task-creating-dialog v-model="isCreateTaskDialogVisible" />
  </v-container>
</template>

<script>
import { DETAILED_PAGE_CONFIGS, DETAILED_PAGE_EVENTS } from "./defaults";
import PageHeader from "../UI/PageHeader.vue";
import { DevDashboardApiService } from "./services/DevDashboardApiService";
import ActionsBottomSheet from "./components/ActionsBottomSheet.vue";
import ActionSegment from "./components/ActionSegment.vue";
import DetailedPageTaskCreatingDialog from "./components/DetailedPageTaskCreatingDialog.vue";
import eventPipe from "../../events/eventPipe";
import hotkeys from "hotkeys-js";
import NoItemsPlaceholder from "./components/NoItemsPlaceholder.vue";
import { deepClone } from "../../utils/functions";
import { userPreferenceApiService } from "../../services/UserPreferenceApiService";

/**
 * @typedef {import('./types.jsdoc.js').Section} Section
 * @typedef {import('./types.jsdoc.js').SectionItem} SectionItem
 */

// TODO
// 3. Возможность создавать fast task ( скопировать функционал с DashboardDevSectionBlockFactory 244 стрчока )

const USER_PREFERENCES_PAGE_KEY = "dashboard-dev.detailed-v2";

export default {
  components: {
    NoItemsPlaceholder,
    DetailedPageTaskCreatingDialog,
    ActionSegment,
    ActionsBottomSheet,
    PageHeader,
  },

  provide() {
    return {
      filtersData: () => this.filtersData,
      uploadedSegmentsStatus: () => this.uploadedSegmentsStatus,
      lastUpload: () => this.lastUploadData,
    };
  },

  data: () => ({
    pageConfig: DETAILED_PAGE_CONFIGS,
    filtersData: {
      executives: [],
      labels: [],
    },
    loadings: {
      sections: false,
      actions: false,
      allSegmentsAtOnce: false,
      segment: [],
    },
    filters: {
      dataType: "detailed", // 1 | 0 | 'detailed'
      isResetCacheEnabled: null,
    },
    actions: [],
    isActionsDialogVisible: false,
    isCreateTaskDialogVisible: false,
    uploadedSegmentsStatus: {},
    uploadedSegmentsDetails: {},
    lastUploadData: [],
    // segments - список информационных блоков
    segments: [],
    saveUserPreferencesTimeout: null,
  }),

  created() {
    this.loadUserPreferences();

    eventPipe.$on(DETAILED_PAGE_EVENTS.REFRESH_DATA, (actionData) => {
      this.uploadActionDetails(actionData);
    });

    hotkeys("option+a", () => {
      this.isActionsDialogVisible = !this.isActionsDialogVisible;
    });
  },

  beforeDestroy() {
    this.saveUserPreferences();
  },

  watch: {
    uploadedSegmentsDetails: {
      handler(newData) {
        this.lastUploadData = Object.values(newData).map((result) => {
          return {
            action: { id: result.action.id },
            location: result.location,
          };
        });

        clearTimeout(this.saveUserPreferencesTimeout);

        this.saveUserPreferencesTimeout = setTimeout(() => {
          this.saveUserPreferences();
        }, 3000);
      },
      deep: true,
    },
  },

  computed: {
    computedSectionsOnPending() {
      try {
        return Object.values(this.uploadedSegmentsStatus).filter(
          (status) => status === "pending"
        )?.length;
      } catch {
        return 0;
      }
    },
    computedSectionsWithError() {
      try {
        return Object.values(this.uploadedSegmentsStatus).filter(
          (status) => status === "error"
        )?.length;
      } catch {
        return 0;
      }
    },
    computedSuccessfullyUploadedSections() {
      try {
        return Object.values(this.uploadedSegmentsStatus).filter(
          (status) => status === "success"
        )?.length;
      } catch {
        return 0;
      }
    },
  },

  methods: {
    async loadUserPreferences() {
      try {
        const { data } = await userPreferenceApiService().get();

        this.lastUploadData = data[USER_PREFERENCES_PAGE_KEY]?.lastUpload || [];
      } catch (error) {
        console.error("Error while loading user preferences", error);
      }
    },
    saveUserPreferences() {
      userPreferenceApiService().set(USER_PREFERENCES_PAGE_KEY, {
        lastUpload: this.lastUploadData,
      });
    },
    updateSegmentDetails(key, payload) {
      this.$set(this.uploadedSegmentsDetails, key, payload);
    },
    toggleSegmentLoading(id) {
      if (this.loadings.segment.includes(id))
        return (this.loadings.segment = this.loadings.segment.filter(
          (v) => v !== id
        ));

      this.loadings.segment.push(id);
    },
    async handleUploadAll() {
      const promises = [];

      this.loadings.allSegmentsAtOnce = true;

      this.actions.forEach((action) => {
        // Do not load a section if it is currently being loaded
        if (this.loadings.segment.includes(action.id)) return;

        promises.push(this.uploadActionDetails({ action, config: {} }));
      });

      Promise.allSettled(promises).then(() => {
        this.loadings.allSegmentsAtOnce = false;
      });
    },
    async updateExistedSegment({ action, config }) {
      const idxOfExistingSegment = this.segments.findIndex(
        (segment) => segment.action.id === action.id
      );

      const linkToExistingSegment = this.segments[idxOfExistingSegment];

      this.updateSegmentDetails(action.id, linkToExistingSegment);
      this.$set(this.uploadedSegmentsStatus, action.id, "pending");
      this.$set(linkToExistingSegment, "pending", true);
      // if user change location -> move segment
      if (config?.location !== undefined)
        this.$set(linkToExistingSegment, "location", config.location);
      // update applied filters to actual
      this.$set(linkToExistingSegment, "appliedFilters", this.filters);

      this.toggleSegmentLoading(action.id);

      const { items, status } =
        await DevDashboardApiService().getActionDetailsById(action.id, {
          only_problem_data: this.filters.dataType,
          reset_cache: this.filters.isResetCacheEnabled,
        });

      this.toggleSegmentLoading(action.id);
      if (status === "error") {
        this.$set(this.uploadedSegmentsStatus, action.id, "error");
      } else {
        this.$set(this.uploadedSegmentsStatus, action.id, "success");
      }

      // Фикс бага. В массиве items может быть элемент не нужного формата. Фильтруем все неподходящие элементы
      const sections = items?.filter(
        (item) => typeof item === "object" && "items" in item
      );

      // Ссылаясь на созданый экземпляр сегмента с помощью $set реактивно меняем его свойства
      this.$set(linkToExistingSegment, "pending", false);
      this.$set(linkToExistingSegment, "sections", sections);
    },
    async addNewSegment({ action, config }) {
      const segment = {
        action,
        pending: true,
        priority: action.service_priority,
        sections: [],
        location: config?.location ?? 0,
        appliedFilters: deepClone(this.filters),
      };

      // Добавляем в список сегментов новый экземпляр ( с потенциально реактивными свойствами )
      this.segments.push(segment);
      this.segments.sort((a, b) => b.priority - a.priority);

      this.updateSegmentDetails(action.id, segment);
      this.$set(this.uploadedSegmentsStatus, action.id, "pending");

      this.toggleSegmentLoading(action.id);

      const { items, status } =
        await DevDashboardApiService().getActionDetailsById(action.id, {
          only_problem_data: this.filters.dataType,
          reset_cache: this.filters.isResetCacheEnabled,
        });

      this.toggleSegmentLoading(action.id);
      if (status === "error") {
        this.$set(this.uploadedSegmentsStatus, action.id, "error");
      } else {
        this.$set(this.uploadedSegmentsStatus, action.id, "success");
      }

      // Фикс бага. В массиве items может быть элемент не нужного формата. Фильтруем все неподходящие элементы
      const sections = items?.filter(
        (item) => typeof item === "object" && "items" in item
      );

      // Ссылаясь на созданый экземпляр сегмента с помощью $set реактивно меняем его свойства
      this.$set(segment, "pending", false);
      this.$set(segment, "sections", sections);
    },
    async uploadActionDetails({ action, config = {} }) {
      try {
        const isSegmentAlreadyExist = this.segments.findIndex(
          (segment) => segment.action.id === action.id
        );

        if (isSegmentAlreadyExist >= 0) {
          await this.updateExistedSegment({ action, config });
        } else {
          await this.addNewSegment({ action, config });
        }
      } catch (error) {
        console.error("Error while uploading action sections data.", error);
        this.$set(this.uploadedSegmentsStatus, action.id, "error");
      }
    },

    async initializeFilters() {
      this.filtersData = await DevDashboardApiService().getFilters();
    },
    async initializeActions() {
      this.loadings.actions = true;

      this.actions = await DevDashboardApiService().getActions({
        only_problem_data: this.filters.dataType,
      });

      this.loadings.actions = false;
    },
  },

  mounted() {
    this.initializeFilters();
    this.initializeActions();
  },
};
</script>
