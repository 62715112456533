import service from "@/plugins/service";

class TaskService {
  static async createTask(taskData) {
    try {
      const url = "/worksection/task/create";

      const payload = {
        type: "CREATE_TASKS",
        main_task: taskData,
      };

      const resp = await service.post(url, payload);

      let result = {
        status: resp?.data?.success || false,
        data: null,
      };

      if (resp && resp.data?.success) {
        result.data = resp?.data?.task;
      } else {
        result.data = resp?.data?.message;
      }

      return result;
    } catch (e) {
      console.error("<TaskService>. Create.", e);
      throw e;
    }
  }

  static async changeTags(payload) {
    try {
      const url = "/worksection/task/update-tags";

      return await service.post(url, payload);
    } catch (e) {
      console.error("<TaskService>. Change tags.", e);
      throw e;
    }
  }
}

export default TaskService;
