<template>
  <div v-if="value">
    {{ value }}
    <sup :class="getSup < 0 ? 'error--text' : 'success--text'">{{
      String(getSup).replace("-", "")
    }}</sup>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    name: {},
    data: {},
  },
  computed: {
    getSup() {
      return this.data["_" + this.name];
    },
  },
};
</script>
