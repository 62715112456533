import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "ltt-deleted-links/fetchTableData"(ctx, payload) {
      const url = "/ltt/ltt_deleted_links";
      const response = await Service.post(url, payload, true);

      if (response.status === 200) {
        return response.data;
      }
    },
    async "ltt-deleted-links/updateDonorUrl"(ctx, payload) {
      const url = "/ltt/ltt_deleted_links/update_done_url";
      const response = await Service.post(url, payload, true);

      if (response.status === 200) {
        return response.data;
      }
    },
    async "ltt-deleted-links/updateStatus"(ctx, payload) {
      const url = "/ltt/ltt_deleted_links/status_update";
      const response = await Service.post(url, payload, true);

      if (response.status === 200) {
        return response.data;
      }
    },
    async "ltt-deleted-links/updateLink"(ctx, { link_id, config }) {
      const url = `/ltt/update_link_data/link/${link_id}/1`;
      const response = await Service.get(url, true, config);

      if (response.status === 200) {
        return response.data;
      }
    },
    async "ltt-deleted-links/fetchStatus"(ctx, payload) {
      const url = "/ltt/ltt_deleted_links/status";
      const response = await Service.post(url, payload, true);

      if (response.status === 200) {
        return response.data;
      }
    },
    async "ltt-deleted-links/fetchFilters"() {
      const url = "/ltt/ltt_deleted_links/users";
      const url2 = "/ltt/links-type";
      const response = await Service.post(url, {}, true);
      const response2 = await Service.get(url2);

      if (response.status === 200 && response2.status === 200) {
        return { user: response.data, types: response2.data };
      }
    },
    async "ltt-deleted-links/update"(ctx, payload) {
      const url = "/ltt/ltt_deleted_links/update";
      const response = await Service.post(url, payload, true);

      if (response.status === 200) {
        return response.data;
      }
    },
  },
  getters: {},
};
