<template>
  <div>
    <div v-if="label" class="text-body-2 mb-1" style="opacity: 0.7">
      {{ label }}
    </div>
    <div class="d-flex flex-wrap" style="gap: 0.25rem">
      <v-chip v-for="[key, value] in computedFilters" :key="key">
        {{ info[key]?.title || key }}:
        <selected-filter-value
          class="ml-1"
          :filter="{ key, value }"
          :data="data"
          :info="info"
        />
      </v-chip>
    </div>
  </div>
</template>

<script>
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SelectedFilterValue from "./SelectedFilterValue.vue";

export default {
  components: { SelectedFilterValue },
  props: ["filters", "info", "label", "data"],

  mixins: [FiltersHandling],

  computed: {
    computedFilters() {
      if (!this.filters || typeof this.filters !== "object") return [];

      /* eslint-disable */
      return Object.entries(this.filters).filter(
        ([_, value]) => !this.__isEmptyValue(value)
      );
      /* eslint-enable */
    },
  },

  methods: {},
};
</script>
