<template>
  <div class="d-flex align-center">
    <div
      v-if="
        data &&
        data.last_updated &&
        data.last_updated.day_diff !== undefined &&
        data.last_updated.day_diff !== null
      "
    >
      <template v-if="data.last_updated.day_diff > 0">
        Last update<v-chip
          small
          label
          style="padding: 0 4px; margin: 0 2px; font-weight: 600"
          :color="getColor(data.last_updated.day_diff)"
          >{{ data.last_updated.day_diff }}</v-chip
        >
        days ago
      </template>
      <template v-else> Last update - today </template>
    </div>
    <div v-else-if="!successfullyUploaded"></div>
    <div v-else>
      Last update<v-chip
        small
        label
        style="padding: 0 4px; margin: 0 2px; font-weight: 600"
        color="transparent"
        >-</v-chip
      >
      days ago
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    successfullyUploaded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getColor(daysDiff) {
      if (daysDiff > 120 && daysDiff <= 200) return "warning";
      if (daysDiff > 200) return "error";
      return "transparent";
    },
  },
};
</script>
