<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-alert
            icon="mdi-calendar-blank"
            prominent
            text
            type="info"
        >
          <v-row align="center">
            <v-col class="grow">
              <h3 class="headline">
                Keys Uploader
              </h3>
              <div>Nunc nonummy metus. Nunc interdum lacus sit amet orci. Nullam dictum felis eu pede mollis pretium.
                Cras id dui.
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                  :loading="buttons.start_parsing.upload_keys"
                  color="info"
                  outlined
                  @click="keys.dialog = true">
                Upload Keys
                <v-icon
                    right
                    dark
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn
                  :disabled="project_data.status === 'in_progress' || project_data.status === undefined"
                  :loading="buttons.start_parsing.loading"
                  color="info"
                  outlined
                  @click="start_parsing">
                Start Parsing
                <v-icon
                    right
                    dark
                >
                  mdi-play-outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="shrink" v-if="project_data.status === 'in_progress'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>Task in progress</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
            :headers="table.headers"
            :items="table.items"
            :options.sync="table_options"
            :server-items-length="table.total"
            :loading="table.loading"
            class="elevation-1"
        ></v-data-table>
        <!--        <v-data-table-->
        <!--            :headers="table.headers"-->
        <!--            :items="table.items"-->
        <!--            :items-per-page="5"-->
        <!--            class="elevation-1"-->
        <!--        >-->
        <!--        </v-data-table>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-dialog
            v-model="keys.dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Upload Keys</span>
            </v-card-title>
            <v-card-text>
              <v-form
                  ref="form"
                  v-model="keys.valid"
                  lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                          v-model="keys.file"
                          :rules="keys.form_rules.file"
                          counter
                          show-size
                          truncate-length="9"
                          placeholder="Pick an file"
                          prepend-icon="mdi-paperclip"
                          label="File"
                          multiple
                          filled
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row v-for="item in progress_files_computed" :key="item.name">
                    <v-col cols="12" md="12" class="pb-0">
                      <v-subheader class="pa-0">{{ item.name }}</v-subheader>
                    </v-col>
                    <v-col cols="12" md="11" class="pt-0">
                      <v-progress-linear
                          :buffer-value="item.value"
                          stream
                          color="primary"
                          class="mt-3"
                      ></v-progress-linear>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center pt-0">
                      <v-icon color="primary" v-if="item.status === 'done'">mdi-check</v-icon>
                      <v-icon v-else-if="item.status === 'in_progress'">mdi-clock-outline</v-icon>
                      <v-icon v-else-if="item.status === 'error'" if="" color="error">mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <!--                    <v-col cols="12" md="7">-->
                    <!--                      -->
                    <!--                    </v-col>-->
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="keys.dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="upload_keys"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "NicheAnalyzerShow",
  data() {
    return {
      buttons: {
        upload_keys: {
          loading: false
        },
        start_parsing: {
          loading: false
        }
      },
      keys: {
        progress_files_obj: {},
        progress_files: [],
        file: undefined,
        list: undefined,
        valid: true,
        dialog: false,
        form_rules: {
          file: [
            v => !!v || 'File is required',
            v => {
              return !v || v.map((i) => i.size).reduce((a, c) => a + c) < 10000000 || 'File size should be less than 10 MB!'
            },
          ]
        }
      },
      table_options: {},
      table: {
        headers: [
          {text: 'Phrase', value: 'phrase'},
          {text: 'Volume', value: 'volume', align: 'center'},
          {text: 'Difficulty', value: 'difficulty', align: 'center'}
        ],
        items: [],
        loading: true,
        total: 10
      },
      project_data: {
        status: undefined
      }
    }
  },
  watch: {
    table_options: {
      handler() {
        this.get_data_from_api()
      },
      deep: true
    },
  },
  methods: {
    start_parsing() {
      let self = this

      self.buttons.start_parsing.loading = true

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        r_type: 'start_parsing'
      }

      axios
          .put(`${self.$store.state.server_url}/niche-analyzer/project/${self.id_project}`, data, config)
          .then((r) => {
            if (r.data.status === true) {
              self.project_data.status = 'in_progress'
            }
          })
          .finally(() => self.buttons.start_parsing.loading = false)
    },
    get_data_from_api() {
      this.fetch_data()
    },
    format_list(value) {
      return value
          .split('\n')
          .map((v) => v.split(';'))
    },
    upload_keys() {
      let self = this

      if (self.$refs.form.validate()) {
        self.keys.file.forEach((f) => {
          self.keys.progress_files_obj[f.name] = {
            value: 0,
            status: 'new'
          }
          self.refresh_progress()
          self.upload_file(f)
        })
      }
    },
    upload_file(file) {
      let self = this

      let url = `${self.$store.state.server_url}/niche-analyzer/keys/${self.id_project}/upload`

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': self.$store.state.auth.token
        },
        onUploadProgress(event) {
          self.keys.progress_files_obj[file.name] = {
            value: Math.round((event.loaded * 100) / event.total),
            status: 'in_progress'
          }
          self.refresh_progress()
        }
      }

      let form_data = new FormData()

      form_data.append('file', file)

      axios
          .post(url, form_data, config)
          .then(() => {
            self.keys.progress_files_obj[file.name] = {
              value: 100,
              status: 'done'
            }
            self.refresh_progress()
          })
          .catch(() => {
            self.keys.progress_files_obj[file.name] = {
              value: 100,
              status: 'error'
            }
            self.refresh_progress()
          })
    },
    refresh_progress() {
      this.keys.progress_files = []

      for (let i in this.keys.progress_files_obj) {
        this.keys.progress_files.push({
          name: i,
          value: this.keys.progress_files_obj[i].value,
          status: this.keys.progress_files_obj[i].status
        })
      }
    },
    fetch_data() {
      let self = this

      self.table.loading = true

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        },
        params: self.table_options
      }

      axios
          .get(`${self.$store.state.server_url}/niche-analyzer/keys/${self.id_project}`, config)
          .then((r) => {
            self.table.items = r.data.items
            self.table.total = r.data.total
          })
          .finally(() => self.table.loading = false)
    },
    fetch_project() {
      let self = this

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios
          .get(`${self.$store.state.server_url}/niche-analyzer/project/${self.id_project}`, config)
          .then((r) => {
            self.project_data = r.data
          })
    }
  },
  computed: {
    progress_files_computed() {
      return this.keys.progress_files
    },
    id_project() {
      return this.$route.params['id']
    }
  },
  created() {
    let self = this
    self.fetch_project()
  }
}
</script>

<style scoped>

</style>