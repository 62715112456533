<template>
  <div :class="`page-title${theme ? ' dark-mode ' : ''} ${getStyles}`">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: Boolean,
      default: false,
    },
    small:{},
  },
  computed:{
    getStyles() {
      const styles = []

      if(this.small !== undefined) {
        styles.push('small-variant')
      }

      return styles.join(' ')
    }
  },
}
</script>

<style scoped lang="scss">
.page-title {
  font-size: 36px;
  padding: 18px 0;
  &.small-variant {
    font-size: 22px;
    padding: 12px 0;
  }
  &.dark-mode {
  }
}
@media screen and (max-width: 850px){
  .page-title {
    margin-top: 26px;
  }
}
</style>