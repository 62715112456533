<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="400px"
    content-class="my-shadow--e2"
  >
    <v-card rounded="lg" flat>
      <v-card-title class="text-body-2">
        Decline Donor
        <v-spacer />
        <v-icon @click="dialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-form class="mt-2" ref="form" lazy-validation>
          <div class="text-body-2 mb-1">Select the reason for decline</div>
          <my-grouped-autocomplete
            :model-value="decline_reason"
            :items="headerData()?.decline_donor_reasons"
            @update:modelValue="decline_reason = $event"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          large
          color="secondary"
          class="px-4"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          large
          color="error"
          class="px-4"
          :disabled="!decline_reason || decline_reason?.length === 0"
          :loading="pending"
          @click="declineDonor"
        >
          Decline
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import MyGroupedAutocomplete from "@/components/UI/MyGroupedAutocomplete.vue";
import { gpmDonorApiService } from "../services/GpmDonorApiService";

export default {
  mixins: [Dialog],

  emits: ["declined"],

  components: { MyGroupedAutocomplete },

  inject: ["headerData"],

  data: () => ({
    decline_reason: [],
    gpmDonorApiService: null,
    pending: false,
  }),

  created() {
    this.gpmDonorApiService = gpmDonorApiService();
  },

  methods: {
    async declineDonor() {
      try {
        this.pending = true;

        await this.gpmDonorApiService.decline(
          this.decline_reason,
          this.$route.params.id
        );

        this.$emit("declined");
      } catch (e) {
        console.error("Error while declining donor.", e);
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>
