<template>
  <td>
    <div :class="`colored-td full-size ${getWrapperColor}`">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span v-on="on">{{ getContent }}</span>
          <sup
            v-if="data.theme_approve_at.rewrite"
            :class="`colored-sup ${getColor}`"
          >
            <span>{{ data.theme_approve_at.rewrite }}</span>
          </sup>
        </template>
        <div>{{ data.theme_approve_at.theme }}</div>
      </v-tooltip>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getData() {
      return this.data.theme_approve_at ? this.data.theme_approve_at : {};
    },
    getContent() {
      const date = this.data.theme_approve_at.text;
      const hasErrors = this.data.theme_approve_at.rewrite;
      const fixedDate = this.$moment(this.data.theme_approve_at.text).format(
        "DD/MM"
      );
      if (date) {
        return fixedDate;
      }
      if (!date && hasErrors) {
        return "-";
      }
      return "";
    },
    getWrapperColor() {
      if (this.data.task_closed) return "";
      if (this.getData.expired) return "red";

      return "";
    },
    getColor() {
      const value = Number(this.data.theme_approve_at.rewrite);

      if (this.data.task_closed) return "";

      if (value >= 2) {
        return "red";
      }
      return "colored-sup--small";
    },
  },
};
</script>
