import ApexCharts from "apexcharts";
import eventPipe from "@/events/eventPipe";

export const ViewInfoBySiteIndex = {
  computed: {
    domain() {
      if (this.customDomain) {
        return this.customDomain;
      }

      return this.$route.query["domain"];
    },
    isValidRelevance() {
      return this.last_updated.day_diff < this.data_relevance;
    },
  },
  methods: {
    humanFormat(n, precision = 1) {
      let n_format = 0;
      let suffix = "";
      let dotzero = "";
      if (n < 900) {
        // 0 - 900
        n_format = this.number_format(n, precision);
        suffix = "";
      } else if (n < 900000) {
        // 0.9k-850k
        n_format = this.number_format(n / 1000, precision);
        suffix = "K";
      } else if (n < 900000000) {
        // 0.9m-850m
        n_format = this.number_format(n / 1000000, precision);
        suffix = "M";
      } else if (n < 900000000000) {
        // 0.9b-850b
        n_format = this.number_format(n / 1000000000, precision);
        suffix = "B";
      } else {
        // 0.9t+
        n_format = this.number_format(n / 1000000000000, precision);
        suffix = "T";
      }

      // Remove unecessary zeroes after decimal. "1.0" -> "1"; "1.00" -> "1"
      // Intentionally does not affect partials, eg "1.50" -> "1.50"
      if (precision > 0) {
        dotzero = "." + "0".repeat(precision);
        n_format = n_format.replace(dotzero, "");
      }

      return n_format + "" + suffix;
    },
    number_format(number, decimals = 0, dec_point = ".", thousands_sep = ",") {
      let sign = number < 0 ? "-" : "";

      let s_number =
        Math.abs(parseInt((number = (+number || 0).toFixed(decimals)))) + "";
      let len = s_number.length;
      let tchunk = len > 3 ? len % 3 : 0;

      let ch_first = tchunk ? s_number.substr(0, tchunk) + thousands_sep : "";
      let ch_rest = s_number
        .substr(tchunk)
        .replace(/(\d\d\d)(?=\d)/g, "$1" + thousands_sep);
      let ch_last = decimals
        ? dec_point + (Math.abs(number) - s_number).toFixed(decimals).slice(2)
        : "";

      return sign + ch_first + ch_rest + ch_last;
    },
    drawChart(id, options) {
      document.querySelector(`#${id}`).innerHTML = "";

      let chart = new ApexCharts(document.querySelector(`#${id}`), options);

      chart.render();
    },
    refreshData() {
      let self = this;

      self.refresh_loading = true;

      eventPipe.$emit("vibs:send", {
        json: JSON.stringify({
          domain: self.domain,
          type: self.ws_type,
        }),
      });
    },
    createdAt() {
      let self = this;

      eventPipe.$on("vibs:got", (args) => {
        if (
          args.type === self.ws_type &&
          args.success &&
          args.domain === self.domain
        ) {
          self.refresh_loading = false;
          self.fetchData(false);
        }
      });
    },
  },
};
