<template>
  <v-card :loading="loading">
    <v-card-title>Common table</v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        solo
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1"
      :items-per-page="15"
      :custom-sort="sortTables"
    >
      <template v-slot:header="">
        <thead>
          <tr>
            <th :colspan="header_categories.default" class="text-center">
              Default
            </th>
            <th :colspan="header_categories.num_of_links" class="text-center">
              Num Of Links
            </th>
            <th :colspan="header_categories.keyword_entry" class="text-center">
              Keyword Entries
            </th>
            <th :colspan="header_categories.anchor_type" class="text-center">
              Anchor Types
            </th>
            <th :colspan="header_categories.links_type" class="text-center">
              Link Types
            </th>
            <th
              :colspan="header_categories.unique_anchors"
              class="text-center"
            ></th>
            <th
              :colspan="header_categories.words_in_anchors"
              class="text-center"
            >
              Words In Anchors
            </th>
            <th
              :colspan="header_categories.top_distribution"
              class="text-center"
            >
              Top Distribution
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr :class="{ 'indigo lighten-5': item.default_url === selectedUrl }">
          <td v-for="i in headers.length" :key="i">
            {{ item[Object.keys(item)[i - 1]] }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import events from "../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../mixins/Common";

export default {
  name: "CommonTable",
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  data() {
    return {
      loading: false,
      search: "",
      headers: [],
      header_categories: {},
      items: [],
    };
  },
  methods: {
    sortTables(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortDesc) {
          return this.sortTablesCompare(a, b);
        } else {
          return this.sortTablesCompare(b, a);
        }
      });
      return items;
    },
    sortTablesCompare(a, b) {
      let visibility_a, visibility_b;

      if (a.default_url === this.selectedUrl) {
        return 1;
      }

      if (b.default_url === this.selectedUrl) {
        return -1;
      }

      visibility_a = parseInt(a.default_visibility);
      visibility_b = parseInt(b.default_visibility);

      if (visibility_a < visibility_b) return -1;
      if (visibility_a > visibility_b) return 1;

      return 0;
    },
    resetData() {
      this.header_categories = {};
      this.headers = [];
      this.items = [];

      this.$store.state.OnePageAnalyzer.common_table_items = [];
      this.$store.state.OnePageAnalyzer.percentage_link_types_to_total = {
        headers: [],
        items: [],
        types: [],
        stat: {
          manual: 0,
          auto: 0,
        },
        charts: {},
      };
      this.$store.state.OnePageAnalyzer.percentage_anchor_types_to_total = {
        headers: [],
        items: [],
      };

      this.$store.state.OnePageAnalyzer.heatmap = {
        ds: {},
        ur: {},
        anchor_links: {},
      };
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/common-table`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedUrl,
          projectID: self.idProject,
          filter: self.filterData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        urls: Object.keys(self.vision),
        vision: self.vision,
        projectID: self.idProject,
        url: self.selectedUrl,
        sum_visibility: self.sumVisibility,
      };

      axios
        .post(url, data, config)
        .then((r) => {
          self.header_categories = r.data.main.categories;
          self.headers = r.data.main.headers;
          self.items = r.data.main.items;

          self.$store.state.OnePageAnalyzer.common_table_items =
            r.data.main.items;
          self.$store.state.OnePageAnalyzer.percentage_link_types_to_total =
            r.data.link_types;
          self.$store.state.OnePageAnalyzer.percentage_anchor_types_to_total =
            r.data.anchor_types;

          self.$store.state.OnePageAnalyzer.heatmap = r.data.heatmap;

          setTimeout(function () {
            events.$emit("common-table-fetched");
          }, 500);
        })
        .finally(() => {
          self.loading = false;
        });
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
    });

    events.$on("vision-fetched", function () {
      self.fetchData();
    });
  },
};
</script>

<style scoped></style>
