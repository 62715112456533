import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'links-monitor/fetchTableData'(ctx, payload) {
            const url = '/ltt/monitor/json'
            const response = await Service.post(url, payload, true)

            if(response.status === 200){
                return response.data
            }
        },
        async 'links-monitor/save'(ctx, payload) {
            const url = '/ltt/monitor/save'
            const response = await Service.post(url, payload, true)

            if(response.status === 200){
                return response.data
            }
        },
    },
    getters: {},
}