<template>
  <td>
    <div :class="`colored-td ${getColor}`">
      {{data.amount}}
    </div>
  </td>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    getColor() {
      const val = this.data.amount
      if(val >= 1 && val <= 3) {
        return 'green'
      }
      if(val >= 4 && val <= 10) {
        return 'red'
      }
      return ''
    }
  }
}
</script>