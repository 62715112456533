<template>
  <div
    :class="['drop-file', dragging ? 'drop-file--dragover' : '', 'relative']"
    :style="{ height: height + 'px' }"
    @dragenter="dragging = true"
    @dragleave="dragging = false"
    @drag="onChange"
  >
    <div class="absolute" style="top: 4px; right: 4px">
      <v-progress-circular v-if="loading" size="12" width="2" indeterminate />
    </div>
    <div v-if="!file" class="drop-file__wrapper">
      <div class="text-center">
        <v-icon small class="mr-2">mdi-upload</v-icon>
        <span>Drop file or click to upload</span>
        <div>
          <div>Supported extensions: {{ supportedTypes.join(",") }}</div>
          <div v-if="maxFileSize">
            Max file size: {{ formatFileSize(maxFileSize) }}
          </div>
        </div>
      </div>
      <input ref="fileInput" type="file" @change="onChange" />
    </div>
    <div v-else>
      <div class="text-center">
        <div class="mb-2">
          Uploaded
          <v-icon small class="ml-2" color="success">mdi-check</v-icon>
        </div>
        <div class="text-center">
          <div>
            <i>Name:</i> <b>{{ file.name }}</b>
          </div>
          <div>
            <i>Size:</i> <b>{{ formatFileSize(file.size) }}</b>
          </div>
          <div>
            <i>Extension: </i><b>{{ extension }}</b>
          </div>
        </div>
        <v-chip class="mt-4" label outlined @click="removeFile">
          Remove File
          <v-icon small class="ml-2">mdi-trash-can-outline</v-icon>
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
    },
    supportedTypes: {
      type: Array,
      default: () => ["csv", "docx", "txt"],
    },
    maxFileSize: {
      type: Number,
      default: 5242880,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data: () => ({
    dragging: false,
  }),
  methods: {
    formatFileSize(bytes) {
      const sizes = ["bytes", "Kb", "Mb", "Gb", "Tb"];
      if (bytes === 0) return "0 bytes";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      const size = Math.round(bytes / Math.pow(1024, i), 2);
      return size.toLocaleString() + " " + sizes[i];
    },
    onChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match("text.*")) {
        this.$message.notification({
          title: "Wrong format",
          text: `please select txt file`,
          type: "warning",
        });
        this.dragging = false;
        return;
      }

      if (file.size > this.maxFileSize) {
        this.$message.notification({
          title: "File to large",
          text: `please check file size no over ${this.formatFileSize(
            this.maxFileSize
          )}.`,
          type: "warning",
        });
        this.$refs.fileInput.value = null;
        this.dragging = false;
        return;
      }

      this.file = file;
      this.dragging = false;
    },
    removeFile() {
      this.file = "";
    },
  },
  computed: {
    extension() {
      if (!this.file) return "";
      return this.file ? this.file.name.split(".").pop() : "";
    },
    file: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style lang="scss">
.drop-file {
  $primary-color: #1976d2;
  width: 100%;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 8px;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 2px dashed rgba(136, 136, 136, 0.15);
    pointer-events: none;
    transition: 50ms ease-in-out;
  }

  &:hover {
    &:before {
      border: 2px dashed $primary-color;
    }
  }

  &--dragover {
    background-color: rgba(136, 136, 136, 0.15);
    &:before {
      border: 2px dashed rgba(98, 29, 29, 0.15);
    }
  }

  &__wrapper {
    border-radius: 8px;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}
</style>
