export default {
  methods: {
    initializeDefaultFontFromLocalStorage() {
      const savedFontName = localStorage.getItem("selectedFontName");
      const savedFontUrl = localStorage.getItem("selectedFontUrl");
      const savedFontFamilyType = localStorage.getItem(
        "selectedFontFamilyType"
      );

      if (savedFontName && savedFontUrl) {
        // Применяем сохраненный шрифт
        this.applyFont({
          name: savedFontName,
          url: savedFontUrl,
          inscription: savedFontFamilyType,
        });
      }
    },
    parseFontLinks(html, fontFamilyType) {
      // Создаем временный элемент для парсинга HTML
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;

      const fontLinks = [];

      // Ищем все теги <link> в документе
      const links = tempDiv.querySelectorAll("link");

      links.forEach((link) => {
        const href = link.getAttribute("href");

        // Проверяем, является ли ссылка ссылкой на шрифт
        if (href && href.includes("fonts.googleapis.com/css")) {
          // Извлекаем имя шрифта из ссылки
          const fontNameMatch = href.match(/family=([^&]*)/);
          if (fontNameMatch) {
            // Извлекаем имя шрифта
            const fontName = fontNameMatch[1].split(":")[0].replace(/\+/g, " ");
            fontLinks.push({
              href,
              name: fontName,
              inscription: fontFamilyType,
            });
          }
        }
      });

      return fontLinks;
    },
    applyFont(font) {
      // Сохраняем выбранный шрифт в localStorage
      localStorage.setItem("selectedFontName", font.name);
      localStorage.setItem("selectedFontUrl", font.url);
      localStorage.setItem("selectedFontFamilyType", font.inscription);

      // Проверяем, существует ли уже тег <link> с этим URL
      const existingLink = Array.from(
        document.querySelectorAll('link[rel="stylesheet"]')
      ).find((link) => link.href === font.url);

      if (!existingLink) {
        const link = document.createElement("link");
        link.href = font.url;
        link.rel = "stylesheet";
        document.head.appendChild(link);
      }

      // Проверяем, существует ли уже созданный нами тег <style> для изменения шрифта
      let styleTag = document.querySelector("#dynamic-font-style");
      if (!styleTag) {
        styleTag = document.createElement("style");
        styleTag.id = "dynamic-font-style";
        document.head.appendChild(styleTag);
      }

      // Обновляем CSS-правило для селектора *
      styleTag.innerHTML = `* { font-family: '${font.name}', ${
        font.inscription || "sans-serif"
      }; }`;
    },
  },
};
