<template>
  <v-avatar size="38" :color="getIconColor">
    <v-icon dark small>{{ getIconVariant }}</v-icon>
  </v-avatar>
</template>

<script>
export default {
  props: {
    notification: Object,
  },
  computed: {
    getIconVariant() {
      try {
        if (this.notification.content.link) return "mdi-file-check-outline";
        return "mdi-message";
      } catch {
        return "mdi-message";
      }
    },
    getIconColor() {
      try {
        if (this.notification.content.link) return "success";
        return "primary";
      } catch {
        return "primary";
      }
    },
  },
};
</script>
