<template>
  <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="600"
      lazy
  >
    <v-card
        color="primary"
        dark
    >
      <v-card-text>
        <h3 style="color: white;text-align: center">Please wait data is being saved</h3>
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "LoaderSave",
  props: ["loading"],

  data() {
    return {
    }
  },

}
</script>

<style scoped>

</style>