import service from "../../../plugins/service";
import { SEO_STRATEGY_ENDPOINTS } from "../defaults";

export const seoStrategyApiService = () => {
  const getStrategy = async () => {
    return await service.get(SEO_STRATEGY_ENDPOINTS.GET_STRATEGY);
  };

  const putStrategy = async (payload) => {
    return await service.post(SEO_STRATEGY_ENDPOINTS.PUT_STRATEGY, {
      ...payload,
    });
  };

  const getStrategyById = async (id) => {
    return await service.get(SEO_STRATEGY_ENDPOINTS.GET_STRATEGY_BY_ID(id));
  };

  return { getStrategy, putStrategy, getStrategyById };
};
