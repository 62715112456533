<template>
  <td>
    <div>
      <span>
        <v-icon v-if="data.name && data.name !== '%'" small class="mr-2"
          >mdi-tag-outline</v-icon
        >
        {{ data.name }}
        <span v-if="data.subtask_all > 0" class="table__chip">
          [subt: {{ data.subtask_done }}/{{ data.subtask_all }}]
        </span>
        <span v-if="data.repetitions > 1" class="table__chip">
          rep: {{ data.repetitions }}
        </span>
      </span>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {},
};
</script>
