<template>
  <div class="ldd__all-links">
    <v-row dense>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-text-field
          v-model="tableSearchModel"
          dense
          hide-details
          label="Search"
          append-icon="mdi-magnify"
          style="max-width: 250px"
        ></v-text-field>

        <div>buttons</div>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="data.headers"
          :items="data.items"
          :items-per-page="20"
          :footer-props="{ itemsPerPageOptions: [20, 50, 100, -1] }"
          :custom-sort="customSort"
          :search="tableSearchModel"
          multi-sort
          class="card__table"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.url.value"
                :class="{
                  current: item.url.value.includes(activeUrl.text),
                }"
              >
                <td class="d-flex align-center">
                  <td-domain
                    :data="item.url"
                    :row="item"
                    :note="true"
                    @note="$emit('note', $event)"
                    :active-url="activeUrl"
                  />
                </td>
                <td
                  :style="`background-color: ${getRgb(
                    item.vision,
                    getMax(items, 'vision'),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item.vision }}
                </td>
                <td
                  :style="`background-color: ${getRgb(
                    item.ah_dr,
                    getMax(items, 'ah_dr'),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item.ah_dr }}
                </td>
                <td
                  :style="`background-color: ${getRgb(
                    item.ah_rd,
                    getMax(items, 'ah_rd'),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item.ah_rd }}
                </td>
                <td
                  :style="`background-color: ${getRgb(
                    item.ah_tr,
                    getMax(items, 'ah_re'),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item.ah_tr }}
                </td>
                <td>{{ item.wa }}</td>
                <td
                  :style="`background-color: ${getRgb(
                    item.gi,
                    getMax(items, 'gi'),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item.gi }}
                </td>
                <td
                  :style="`background-color: ${getRgb(
                    item.total_all,
                    getMax(items, 'total_all'),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item.total_all }}
                </td>
                <td
                  :style="`background-color: ${getRgb(
                    item.total_period,
                    getMax(items, 'total_period'),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item.total_period }}
                </td>
                <td
                  v-for="(dateKey, i) in getDates(item)"
                  :key="dateKey + i"
                  :style="`background-color: ${getRgb(
                    item[dateKey],
                    getMax(items, dateKey),
                    50,
                    233,
                    153
                  )}`"
                >
                  {{ item[dateKey] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GetRgb from "@/mixins/GetRgb";
import TdDomain from "@/components/OnePageAnalyzer/LinksDynamicDashboard/td/TdDomain";

export default {
  components: { TdDomain },
  mixins: [GetRgb],
  props: {
    activeUrl: {},
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    tableSearchModel: "",
  }),
  methods: {
    getDates(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (!isNaN(key[0])) {
          output.push(key);
        }
      });

      return output;
    },
    getMax(items, key) {
      let bigger = 0;

      items.forEach((item) => {
        const newValue = parseInt(item[key]);
        if (newValue > bigger) {
          bigger = newValue;
        }
      });

      return bigger;
    },
    customSort(items, sortBy, sortDesc) {
      const text = ["url"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i])) {
            let aVal, bVal;
            if (a[sortBy[i]] === null) aVal = 0;
            if (b[sortBy[i]] === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]];
            const val2 = b[sortBy[i]];
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let aVal, bVal;
          if (!a[sortBy[i]] && a[sortBy[i]] !== 0) aVal = 0;
          if (!b[sortBy[i]] && b[sortBy[i]] !== 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
};
</script>

<style scoped></style>
