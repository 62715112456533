<template>
  <v-card class="site-analysis-table-section styled-card--default">
    <v-card-title
      :data-page-navigation="SECTIONS.organic_keywords.sectionTitle"
    >
      <!--      Top keys-->
      {{ SECTIONS.organic_keywords.sectionTitle }}
      <force-update-button :section-name="sectionName" />
      <link-to-ahrefs
        type="organic-keywords"
        :domain="$store.getters['site-analysis/activeDomain']"
      />
      <v-spacer />
      <type-switcher
        :section-name="sectionName"
        :default-prefix="SECTIONS.organic_keywords.prefix"
      />
      <v-spacer />
      <v-autocomplete
        v-if="successfullyUploaded"
        v-model="selectedNicheFilter"
        :items="nicheItems"
        placeholder="Niche"
        hide-details
        dense
        clearable
        filled
        style="max-width: 130px"
      ></v-autocomplete>
    </v-card-title>
    <v-card-subtitle class="d-flex align-center">
      <last-update-info
        :data="data"
        :successfully-uploaded="successfullyUploaded"
      />
      <v-spacer />
      <table-search
        v-if="successfullyUploaded"
        class="mt-2"
        :model-value="search"
        @update:modelValue="search = $event"
        placeholder="Type to search in table"
        style="max-width: 320px"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pa-0">
      <div v-if="loading" class="skeleton loading" style="height: 300px" />
      <template v-else>
        <template v-if="successfullyUploaded">
          <v-data-table
            :search="search"
            :items="filteredByNicheTableItems"
            :headers="data?.headers"
            multi-sort
            dense
            :items-per-page="-1"
            :sort-by="['traffic']"
            :sort-desc="[true]"
            :custom-sort="_$smartCustomTableSort"
          >
            <template
              v-for="col in data.headers"
              #[`item.${col.value}`]="{ item }"
            >
              <template v-if="col.value === 'volume'">
                <span :key="col.value" :style="getTextStyles(item.volume)">
                  {{ item.volume }}
                </span>
              </template>
              <template v-else-if="col.value === 'difficulty'">
                <span :key="col.value" :style="getTextStyles(item.difficulty)">
                  {{ item.difficulty }}
                </span>
              </template>
              <template v-else-if="col.value === 'cpc'">
                <span :key="col.value" :style="getTextStyles(item.cpc)">
                  {{ item.cpc }}
                </span>
              </template>
              <template v-else-if="col.value === 'traffic'">
                <span :key="col.value" :style="getTextStyles(item.traffic)">
                  {{ item.traffic }}
                </span>
              </template>
              <template v-else-if="col.value === 'position'">
                <span :key="col.value" :style="getTextStyles(item.position)">
                  {{ item.position }}
                </span>
              </template>
              <template v-else-if="col.value === 'prepared_url'">
                <a :key="col.value" :href="item.full_url" target="_blank">
                  {{ item.prepared_url }}
                </a>
              </template>
              <template v-else>
                {{ item[col.value] }}
              </template>
            </template>
          </v-data-table>
        </template>
        <template v-else>
          <not-collected-placeholder />
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import TableSorting from "../../../mixins/TableSorting";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import DataTableFilterByNicheMixin from "../../../mixins/components/SiteAnalysis/DataTableFilterByNicheMixin";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";
import LinkToAhrefs from "../UI/LinkToAhrefs.vue";
import TypeSwitcher from "@/components/SiteAnalysis/UI/TypeSwitcher.vue";
import TableSearch from "@/components/UI/TableSearch.vue";

export default {
  components: {
    TableSearch,
    TypeSwitcher,
    LinkToAhrefs,
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
  },
  mixins: [TableSorting, DataTableFilterByNicheMixin, SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.data.data;
    },
  },
  data: () => ({
    search: null,
    headers: [
      {
        text: "Keyword",
        title: "Keyword",
        value: "keyword",
      },
      {
        text: "Volume",
        title: "Volume",
        value: "volume",
        align: "center",
      },
      { text: "KD", title: "KD", value: "difficulty", align: "center" },
      { text: "CPC", title: "CPC", value: "cpc", align: "center" },
      {
        text: "Traffic",
        title: "Traffic",
        value: "traffic",
        align: "center",
      },
      { text: "Pos", title: "Pos", value: "position", align: "center" },
      {
        text: "URL",
        title: "URL",
        value: "prepared_url",
      },
    ],
  }),
  methods: {
    getTextStyles(value) {
      if (Number(value) === 0) return "opacity: 0.3;";
      return "";
    },
  },
};
</script>
