<template>
  <v-card width="320" class="styled-card--default" rounded="lg">
    <v-card-title class="pb-0">
      <span>Page Navigation</span>
      <v-spacer />
      <v-btn icon @click="handleUpdateItems" :loading="isRefreshing">
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle class="mt-0">
      Tap on the section name to move the screen to that section.
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="px-0" style="max-height: 500px; overflow-y: scroll">
      <div v-if="loading" class="d-flex justify-center py-6">
        <v-progress-circular indeterminate size="42" width="3" color="info" />
      </div>
      <div v-else>
        <template v-if="sections && sections.length > 0">
          <v-list class="py-0" dense>
            <v-list-item
              v-for="section in sections"
              :key="section.innerText"
              @click="navigateTo(section)"
            >
              <v-tooltip v-if="hasUploadingError(section)" bottom>
                <template #activator="{ on }">
                  <v-icon v-on="on" color="warning" small class="mr-2"
                    >mdi-alert-remove-outline</v-icon
                  >
                </template>
                <div>Something went wrong when loading this section</div>
              </v-tooltip>
              {{ section.dataset.pageNavigation }}
              <v-icon small>mdi-navigation-variant-outline</v-icon>
            </v-list-item>
          </v-list>
        </template>
        <template v-else>
          <div class="text-center">
            <span style="opacity: 0.8">No items. Refresh list</span>
          </div>
        </template>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <div style="opacity: 0.8" class="d-flex">
        <div>
          <v-icon small class="mr-1">mdi-information-outline</v-icon>
        </div>
        <div>
          <small
            >Sections appear in this window only if you can see them. (
            Collapsed, hidden, deleted sections do not appear).
          </small>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SITE_ANALYSIS_SECTION_DATA } from "../SiteAnalysis/defaultData";
import PageNavigationMixin from "../../mixins/PageNavigationMixin";

export default {
  props: {
    selector: {
      type: String,
      default: ".v-card__title",
    },
    successfullyUploadedSections: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updateSections: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [PageNavigationMixin],
  data: () => ({
    menu: false,
    isRefreshing: false,
    sections: [],
    sectionsData: SITE_ANALYSIS_SECTION_DATA,
  }),
  watch: {
    updateSections: {
      immediate: true,
      handler(value) {
        if (value) this.handleUpdateItems();
      },
    },
  },
  mounted() {
    this.collectSections();
  },
  methods: {
    handleUpdateItems() {
      this.isRefreshing = true;
      this.collectSections();

      setTimeout(() => {
        this.isRefreshing = false;
      }, 1000);
    },
    hasUploadingError(domElement) {
      try {
        const sectionTitle = domElement.dataset.pageNavigation;

        const matchedSection = Object.values(this.sectionsData).find(
          (v) => v.sectionTitle === sectionTitle
        );

        return !this.successfullyUploadedSections.includes(
          matchedSection.sectionName
        );
      } catch {
        return false;
      }
    },
    collectSections() {
      this.sections = Array.from(document.querySelectorAll(this.selector));
    },
    navigateTo(domElement) {
      this.pnm_navigateToDom(domElement);
      this.menu = false;
    },
  },
};
</script>
