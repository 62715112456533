import auth from "./modules/auth/auth";
import base from "./modules/ls/base";
import project from "./modules/ls/project";
import na_project from "./modules/ls/na-project";
import ls_serp_position from "./modules/ls/serp/position";
import reuse from "./modules/ls/reuse";
import OnePageAnalyzer from "./modules/components/OnePageAnalyzer/Index";
import Gpm from "./modules/components/Gpm/GpmIndex";
import one_year_report from "./modules/components/OneYearReport";
import positions_dashboard from "./modules/components/PositionsDashboard";
import analyticsPageType from "@/store/modules/components/AnalyticsPageType";
import ReservedDomains from "@/store/modules/components/Gpm/ReservedDomains";
import error from "./modules/error";
import WorkSectionReportWeek from "./modules/ltt/worksection-report-week/index";
import ContentPlaning from "@/store/modules/components/Dashboards/ContentPlaning";
import ContentChecker from "@/store/modules/components/Dashboards/ContentChecker";
import Technical from "@/store/modules/components/Dashboards/Technical";
import ContentDashboard from "@/store/modules/components/Dashboards/Content";
import Top5PageOptimization from "@/store/modules/components/Dashboards/Top5PageOptimization";
import Conversion2 from "@/store/modules/components/conversion/conversion-2";
import Conversion4 from "@/store/modules/components/conversion/conversion-4";
import Conversion6 from "@/store/modules/components/conversion/conversion-6";
import Conversion7 from "@/store/modules/components/conversion/conversion-7";
import Conversion8 from "@/store/modules/components/conversion/conversion-8";
import ManagersGpm from "@/store/modules/components/Gpm/ManagersGpm";
import DBC from "@/store/modules/components/DBC";
import JPTracker from "@/store/modules/components/Gpm/GPTracker";
import OutreachProblems from "@/store/modules/components/OutreachProblems";
import WorksectionReportDaily from "@/store/modules/ltt/worksection-report-daily";
import LinksDynamicDashboard from "@/store/modules/components/OnePageAnalyzer/LinksDynamicDashboard";
import SERPDynamics from "@/store/modules/components/SERPDynamics";
import LinksMonitor from "@/store/modules/ltt/links-monitor";
import LttDeletedLinks from "@/store/modules/ltt/ltt-deleted-links";
import UrlsOverview from "@/store/modules/components/UrlsOverview";
import DashboardDev from "@/store/modules/components/Dashboards/Dev/index";
import SiteOverview from "@/store/modules/components/SiteOverview";
import AnchorDistribution from "@/store/modules/components/AnchorDistribution";
import ShellLinks from "@/store/modules/components/ShellLinks";
import UrlControl from "@/store/modules/components/UrlControl";
import LinkPlanningByUrls from "@/store/modules/components/LinkPlanningByUrls";
import Top5PagesConversion from "@/store/modules/components/Top5PagesConversion";
import SiteWorksheet from "@/store/modules/components/SiteWorksheet";
import GlobalSettings from "@/store/modules/components/GlobalSettings";
import DailyReportV3 from "@/store/modules/components/DailyReportV3";
import NDCManage from "@/store/modules/components/NDCManage";
import ContentballAnalytics from "@/store/modules/components/ContentballAnalytics";
import DailyOverallReport from "@/store/modules/components/DailyOverallReport";
import GpDonorsFinder from "@/store/modules/components/GpDonorsFinder";
import GSCOneYearReport from "@/store/modules/components/GSCOneYearReport";
import ExportQueue from "@/store/modules/components/ExportQueue";
import SiteAnalysis from "./modules/components/SiteAnalysis";
import Pages from "./modules/components/Pages";
import FourDXMainDashboard from "@/store/modules/components/FourDXMainDashboard";
import ParamsCollecting from "@/store/modules/paramsCollecting";

export default {
  FourDXMainDashboard,
  SiteAnalysis,
  Pages,
  ParamsCollecting,
  ExportQueue,
  GSCOneYearReport,
  GpDonorsFinder,
  DailyOverallReport,
  ContentballAnalytics,
  NDCManage,
  DailyReportV3,
  GlobalSettings,
  SiteWorksheet,
  Top5PagesConversion,
  LinkPlanningByUrls,
  AnchorDistribution,
  UrlControl,
  ShellLinks,
  UrlsOverview,
  SiteOverview,
  DashboardDev,
  DBC,
  auth,
  base,
  project,
  na_project,
  ls_serp_position,
  reuse,
  OnePageAnalyzer,
  Conversion2,
  Conversion6,
  Conversion7,
  Conversion8,
  Gpm,
  one_year_report,
  positions_dashboard,
  analyticsPageType,
  ReservedDomains,
  error,
  WorkSectionReportWeek,
  ContentPlaning,
  ContentChecker,
  Technical,
  ContentDashboard,
  Top5PageOptimization,
  Conversion4,
  ManagersGpm,
  JPTracker,
  OutreachProblems,
  WorksectionReportDaily,
  LinksDynamicDashboard,
  SERPDynamics,
  LinksMonitor,
  LttDeletedLinks,
};
