<template>
  <div>
    <loader :loading="loading"></loader>
    <v-btn
      color="primary"
      v-if="block.value === 'new'"
      dark
      fab
      right
      absolute
      @click="click_upload_donors(task)"
      style="margin-right: 60px; margin-top: -90px"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon size="20" v-bind="attrs" v-on="on">mdi-plus </v-icon>
        </template>
        <span>Add new donors to the task</span>
      </v-tooltip>
    </v-btn>

    <v-data-table
      :search="search_domains"
      :headers="headers"
      :items="items"
      class="elevation-1 fixed-table-sw-d"
      dense
    >
      <template
        v-for="h in headers"
        v-slot:[`header.${h.value}`]="{ header }"
        v-slot:class="{}"
      >
        <v-tooltip bottom :key="h.text">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ header.text }}</span
            ><span v-if="header.icon">
              <v-icon
                v-if="header.icon_name"
                @click="change_size_column(header)"
                >{{ header.icon_name }}</v-icon
              >
              <v-img v-else :src="icon" width="20px" />
            </span>
          </template>
          <span>{{ header.title }}</span>
        </v-tooltip>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td
              class="text-center"
              :style="
                'white-space: nowrap;background-color:' +
                (is_dark() ? '#1E1E1E;' : 'white')
              "
            >
              <v-btn
                v-if="
                  block.value === 'new' ||
                  block.value === 'pending' ||
                  block.value === 'in_progress'
                "
                color="error"
                bottom
                x-small
                max-width="20"
                max-height="20"
                right
                fab
                @click="in_trash(item, 2)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="14" v-bind="attrs" v-on="on"
                      >mdi-delete-empty
                    </v-icon>
                  </template>
                  <span>Move the donor to the trash</span>
                </v-tooltip>
              </v-btn>
              <v-btn
                v-if="block.value === 'delete' || block.value === 'pending'"
                color="success"
                bottom
                x-small
                max-width="20"
                max-height="20"
                right
                fab
                @click="in_trash(item, 0)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon size="14" v-bind="attrs" v-on="on"
                      >mdi-chevron-double-up
                    </v-icon>
                  </template>
                  <span>This is a good donor</span>
                </v-tooltip>
              </v-btn>
              {{ item.id }}
            </td>
            <td
              :style="
                block.value === 'moderation'
                  ? 'white-space: nowrap;background-color:' +
                    (item.islink === 1
                      ? '#32CD32 !important;'
                      : item.islink === 0
                      ? 'rgb(183 34 34 / 88%) !important;'
                      : item.islink === -1
                      ? 'yellow !important;'
                      : 'grey !important;')
                  : 'white-space: nowrap;background-color:' +
                    (is_dark() ? '#1E1E1E;' : 'white')
              "
            >
              {{ item.domain }}
              <a :href="'http://' + item.domain" target="_blank">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on"
                      >mdi-open-in-new
                    </v-icon>
                  </template>
                  <span>open in new</span>
                </v-tooltip>
              </a>
              <router-link
                :to="{
                  name: 'site-analysis',
                  query: { domain: item.domain, country: 200 },
                }"
                target="_blank"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">mdi-eye </v-icon>
                  </template>
                  <span>View info by site</span>
                </v-tooltip>
              </router-link>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span>
                    <v-icon
                      small
                      v-bind="attrs"
                      :color="
                        item.guide && item.guide.length > 0 ? 'green' : ''
                      "
                      @click="clickGuide(item)"
                      v-on="on"
                      >mdi-information-outline
                    </v-icon>
                  </span>
                </template>
                <span>Инструкция с ранкед сео</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span>
                    <v-icon
                      small
                      v-bind="attrs"
                      @click="clickExample(item)"
                      v-on="on"
                      >mdi-file
                    </v-icon>
                  </span>
                </template>
                <span>Actual Example</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    :href="`https://new.spiderlink.pro/dashboards/link-database-management/processing/?domain=${item.domain_id}`"
                    target="_blank"
                    class="reset-link-styles"
                    v-on="on"
                  >
                    <v-chip small outlined @click="() => {}">LDM</v-chip>
                  </a>
                </template>
                <div>Link Database Management</div>
              </v-tooltip>
              <span>
                <span
                  v-if="block.value === 'new' || block.value === 'in_progress'"
                >
                  <v-btn
                    color=""
                    fab
                    icon
                    width="30"
                    height="30"
                    @click="add_moderation(item)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon size="20" v-bind="attrs" v-on="on"
                          >mdi-plus
                        </v-icon>
                      </template>
                      <span>Add activity</span>
                    </v-tooltip>
                  </v-btn>
                </span>
                <span v-else-if="block.value === 'moderation'">
                  <v-icon small @click="mod_to_deleted_links(item)" color="red"
                    >mdi-skull
                  </v-icon>
                  <v-btn
                    color=""
                    fab
                    icon
                    width="30"
                    height="30"
                    @click="add_moderation(item)"
                  >
                    <v-icon size="20">mdi-pencil</v-icon>
                  </v-btn>
                  <div style="font-size: 10px">URL - {{ item.done_url }}</div>
                  <v-icon
                    @click="
                      moderation.show_all === true
                        ? (moderation.show_all = false)
                        : (moderation.show_all = true)
                    "
                    size="20"
                    >mdi-unfold-less-horizontal</v-icon
                  >
                  <div v-if="moderation.show_all === true">
                    <div style="font-size: 10px">L - {{ item.m_login }}</div>
                    <div style="font-size: 10px">P - {{ item.m_password }}</div>
                    <div style="font-size: 10px">E - {{ item.m_email }}</div>
                    <div style="font-size: 10px">
                      DA - {{ item.m_date_add }}
                    </div>
                    <div style="font-size: 10px">
                      DLC - {{ item.m_last_check_on_page }}
                    </div>
                  </div>
                </span>
              </span>
            </td>
            <td class="text-center">
              <div v-if="block.value !== 'deleted_links'">
                <span v-if="!hide_data">
                  <v-select
                    v-model="item.recommended_acceptor"
                    :items="recommended_acceptors"
                    item-text="name"
                    item-value="id"
                    small-chips
                    @change="
                      save_data_domains(
                        'recommended_acceptor',
                        item.recommended_acceptor,
                        item
                      )
                    "
                  >
                    <template #selection="{ item }">
                      <v-chip>{{ item.id }} - {{ item.name }}</v-chip>
                    </template>
                  </v-select>
                </span>
                <span v-else>
                  {{ item.recommended_acceptor }}
                </span>
              </div>
            </td>
            <td
              class="text-center"
              style="white-space: nowrap"
              :style="get_color(item.ltt_on_page, item.ltt_all)"
            >
              <span v-if="item.ltt_all > 0"
                ><span title="all ltt">{{ item.ltt_all }}</span> -
                <span title="is live">{{ item.ltt_on_page }}</span> -
                <span title="can be indexed">{{ item.is_index }}</span> -
                <span title="indexed">{{ item.ltt_gi }}</span></span
              ><sup
                ><span v-if="!item.clean" style="color: #f10707">
                  !DBC
                </span></sup
              >
            </td>
            <td class="text-center" style="white-space: nowrap">
              <span
                v-html="get_date(item.last_posting_in_days)"
                :title="item.last_posting"
              ></span>
            </td>
            <td class="text-center">
              {{ item.domain_rating }}
            </td>
            <td class="text-center">
              {{ item.traffic }}
            </td>
            <td class="text-center">
              {{ item.country_traffic }}
            </td>
            <td class="text-center">
              {{ item.global_rank }}
            </td>
            <td class="text-center">
              {{ item.google_index }}
            </td>
            <td class="text-center">
              {{ item.google_index_niche }}
            </td>
            <td class="text-center">
              {{ item.google_index_path }}
            </td>
            <td class="text-center">
              {{ item.created_at }}
            </td>
            <td class="text-center" style="white-space: nowrap">
              <span :style="item.spam_score < 30 ? 'color:red' : ''">
                {{ item.spam_score }}
              </span>
              <v-icon v-if="block.value === 'delete' && item.is_seo" size="14"
                >mdi-skull-outline</v-icon
              >
              <v-btn
                v-if="block.value === 'new'"
                color="warning"
                bottom
                x-small
                max-width="20"
                max-height="20"
                right
                fab
                @click="in_trash(item, 3)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.is_seo"
                      v-bind="attrs"
                      v-on="on"
                      size="14"
                      >mdi-emoticon-excited-outline
                    </v-icon>
                    <v-icon v-bind="attrs" v-on="on" v-else size="14"
                      >mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>to PENDING DOMAINS</span>
                </v-tooltip>
              </v-btn>
            </td>
            <td class="text-center">
              <v-checkbox
                v-model="item.priority"
                hide-details
                @change="save_data_domains('priority', item.priority, item)"
              >
              </v-checkbox>
            </td>
            <td class="text-center" style="white-space: nowrap">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    v-html="init_thematic_threads(item.domain)"
                  ></span>
                </template>
                <span>See the topic in google</span>
              </v-tooltip>
            </td>
            <td style="min-width: 400px">
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="item.tag"
                    :items="tags"
                    item-text="name"
                    item-value="id"
                    multiple
                    small-chips
                    @change="save_data_domains('tags', item.tag, item)"
                  >
                    <template #selection="{ item }">
                      <v-chip :color="item.color">{{ item.name }}</v-chip>
                    </template>
                  </v-select>
                  <v-btn
                    v-if="item.tag_count > 0"
                    color="primary"
                    depressed
                    x-small
                    fab
                    @click="initTagsHistory(item)"
                  >
                    <span style="font-size: 10px">{{ item.tag_count }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <span
                    style="padding-top: 10px"
                    :key="tag"
                    v-for="tag in item.tag_type"
                  >
                    <v-chip
                      class="ma-2"
                      v-if="get_tag_object(tags, tag).id > 0"
                      :color="get_tag_object(tags, tag).color"
                      >{{ get_tag_object(tags, tag).name }}
                    </v-chip>
                    <v-icon
                      style="padding-left: -10px"
                      v-if="task.admin && get_tag_object(tags, tag).id > 0"
                      @click="hide_tag(item, tag)"
                      size="15"
                      >mdi-close-thick
                    </v-icon>
                  </span>
                </v-col>
              </v-row>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-btn
                v-if="item.count_comment > 0"
                color="primary"
                depressed
                x-small
                fab
                @click="initComments(item)"
              >
                <span style="font-size: 10px">{{ item.count_comment }}</span>
              </v-btn>

              <v-textarea
                :background-color="item.color_workflow"
                hide-details
                @change="save_data_domains('workflow', item.workflow, item)"
                v-model="item.workflow"
                :style="get_theme()"
                solo
                rows="1"
              ></v-textarea>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-textarea
                hide-details
                v-model="item.text"
                @change="save_data_domains('text', item.text, item)"
                solo
                rows="1"
              ></v-textarea>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-textarea
                hide-details
                :background-color="item.color_notes"
                @change="save_data_domains('notes', item.notes, item)"
                v-model="item.notes"
                :style="get_theme()"
                solo
                rows="1"
              ></v-textarea>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-row>
                <v-col md="10">
                  <v-textarea
                    hide-details
                    :background-color="item.color_login"
                    @change="save_data_domains('login', item.login, item)"
                    v-model="item.login"
                    :style="get_theme()"
                    solo
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col md="2">
                  <v-icon small @click="copy_for_gen_text(item.login)"
                    >mdi-content-copy
                  </v-icon>
                </v-col>
              </v-row>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-row>
                <v-col md="10">
                  <v-textarea
                    hide-details
                    :background-color="item.color_pass"
                    @change="save_data_domains('pass', item.pass, item)"
                    v-model="item.pass"
                    :style="get_theme()"
                    solo
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col md="2">
                  <v-icon small @click="copy_for_gen_text(item.pass)"
                    >mdi-content-copy
                  </v-icon>
                </v-col>
              </v-row>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-row>
                <v-col md="10">
                  <v-textarea
                    hide-details
                    :background-color="item.color_email"
                    @change="save_data_domains('email', item.email, item)"
                    v-model="item.email"
                    :style="get_theme()"
                    solo
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col md="2">
                  <v-icon small @click="copy_for_gen_text(item.email)"
                    >mdi-content-copy
                  </v-icon>
                </v-col>
              </v-row>
            </td>
            <td class="text-center">
              {{ item.user }}
            </td>
            <td class="text-center">
              <v-checkbox
                v-model="item.check"
                hide-details
                @change="save_data_domains('check', item.check, item)"
              >
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.moderation" max-width="600">
      <template>
        <v-card color="basil">
          <v-card-title class="text-center justify-center">
            <h4 class="font-weight-bold">
              {{ moderation.domain }}
            </h4>
          </v-card-title>
          <v-card>
            <v-tabs
              v-model="activity_domain.tab"
              background-color="cyan"
              dark
              centered
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#tab-1">
                Activity
                <v-icon>mdi-electron-framework</v-icon>
              </v-tab>

              <v-tab href="#tab-2">
                Moderation
                <v-icon>mdi-heart</v-icon>
              </v-tab>

              <v-tab href="#tab-3">
                History
                <v-icon>mdi-history</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="activity_domain.tab">
              <v-tab-item value="tab-1">
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="activity_domain.activity_id"
                          :items="
                            activity_domain.activity.filter(
                              (v) => v.show === true
                            )
                          "
                          item-text="name"
                          label="Activity"
                          item-value="id"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" v-if="activity_domain.activity_id === 1">
                        <v-text-field
                          class="mx-auto"
                          v-model="activity_domain.login"
                          solo
                          label="Login"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="activity_domain.activity_id === 1">
                        <v-text-field
                          class="mx-auto"
                          v-model="activity_domain.password"
                          solo
                          label="Password"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="activity_domain.activity_id === 1">
                        <v-text-field
                          class="mx-auto"
                          v-model="activity_domain.email"
                          solo
                          label="Email"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        v-else-if="activity_domain.activity_id === 2"
                      >
                        <v-text-field
                          class="mx-auto"
                          v-model="activity_domain.activity_url"
                          solo
                          label="Activity URL"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog.moderation = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn color="error" text @click="save_activity">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="moderation.anchor_plan_id"
                          :items="recommended_acceptors"
                          item-text="name"
                          label="Anchor plan"
                          item-value="id"
                          small-chips
                        >
                          <template #selection="{ item }">
                            <v-chip>{{ item.id }} - {{ item.name }}</v-chip>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          class="mx-auto"
                          v-model="moderation.done_url"
                          solo
                          label="Done url"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          class="mx-auto"
                          v-model="moderation.login"
                          solo
                          label="Login"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          class="mx-auto"
                          v-model="moderation.pass"
                          solo
                          label="Pass"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          class="mx-auto"
                          v-model="moderation.email"
                          solo
                          label="Email"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          class="mx-auto"
                          v-model="moderation.text"
                          solo
                          label="Text"
                          full-width
                          rows="1"
                          hide-details
                          dense
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          class="mx-auto"
                          v-model="moderation.notes"
                          solo
                          label="Notes"
                          full-width
                          rows="1"
                          hide-details
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog.moderation = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn color="error" text @click="save_moderation">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-card>
                  <v-card-text>
                    <template>
                      <v-timeline>
                        <v-timeline-item
                          color="pink"
                          small
                          v-bind:key="h.recommended_domain_id"
                          v-for="h in activity_domain.history"
                        >
                          <v-row>
                            <v-col cols="6">
                              <strong>{{ h.date }}</strong>
                            </v-col>
                            <v-col cols="6">
                              <strong>{{
                                activity_domain.activity.filter(
                                  (v) => v.id === h.activity_id
                                )[0]["name"]
                              }}</strong>
                              <span
                                v-if="
                                  activity_domain.activity.filter(
                                    (v) => v.id === h.activity_id
                                  )[0]['url']
                                "
                              >
                                <a target="_blank" :href="h.url"
                                  ><v-icon>mdi-link</v-icon></a
                                >
                              </span>
                              <span v-if="h.activity_id === 1">
                                <div>L - {{ h.login }}</div>
                                <div>P - {{ h.password }}</div>
                                <div>E - {{ h.email }}</div>
                              </span>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </template>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="dialog.guide" max-width="700">
      <template>
        <v-card color="basil">
          <v-card-title class="text-center justify-center">
            <h4 class="font-weight-bold">Guide</h4>
          </v-card-title>
          <v-card>
            <v-card-text>
              <span v-if="guide.edit">
                <v-card class="mx-auto" max-width="900" elevation="20">
                  <v-card-title>New guide</v-card-title>
                  <v-card-text>
                    <tiptap-vuetify
                      v-model="guide.content"
                      :extensions="guide.extensions"
                      class="mb-4"
                    />
                  </v-card-text>
                  <v-card-actions class="pa-4">
                    <v-btn color="info" outlined @click="publishPost">
                      Publish
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </span>
              <span v-else v-html="guide.content"></span>
            </v-card-text>
          </v-card>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="dialog.example" max-width="700">
      <template>
        <v-card color="basil">
          <v-card-title class="text-center justify-center">
            <h4 class="font-weight-bold">Example</h4>
          </v-card-title>
          <v-card>
            <v-card-text>
              <span>
                <v-card class="mx-auto" max-width="800" elevation="20">
                  <v-card-text>
                    <div>
                      <b>Actual Example</b> -
                      <a :href="example.actual_example" target="_blank">{{
                        example.actual_example
                      }}</a>
                    </div>
                    <div>
                      <b>LA Team Example</b> -
                      <a :href="example.la_team_example" target="_blank">{{
                        example.la_team_example
                      }}</a>
                    </div>
                  </v-card-text>
                </v-card>
              </span>
            </v-card-text>
          </v-card>
        </v-card>
      </template>
    </v-dialog>

    <DonorComments
      v-if="dialog.comments === true"
      v-model="dialog.comments"
      :domain_id="this.comments.domain"
      :type_id="this.comments.type"
    >
    </DonorComments>

    <TagsHistory
      v-if="dialog.tags === true"
      v-model="dialog.tags"
      :domain_id="this.comments.domain"
      :type_id="this.comments.type"
    >
    </TagsHistory>

    <upload-donors
      v-if="dialog.upload_donors === true"
      v-model="dialog.upload_donors"
      :link_type="this.upload_donors.link_type"
      :task="this.upload_donors.task"
      :project="this.upload_donors.project"
      :name="this.upload_donors.name"
      :performer="this.upload_donors.performer"
    ></upload-donors>
  </div>
</template>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
import Loader from "@/components/AnchorPlan/Child/LoaderSave";
import UploadDonors from "@/components/AnchorPlan/Child/UploadDonors";
import DonorComments from "@/components/AnchorPlan/Child/DonorComments";
import TagsHistory from "@/components/AnchorPlan/Child/TagsHistory";
import axios from "axios";

export default {
  name: "TableIndex",
  components: {
    Loader,
    UploadDonors,
    TiptapVuetify,
    DonorComments,
    TagsHistory,
  },
  props: [
    "search_domains",
    "items",
    "headers",
    "block",
    "thematic_threads",
    "icon",
    "tags",
    "recommended_acceptors",
    "task",
    "fetch_data_domains",
  ],
  data() {
    return {
      dialog: {
        moderation: false,
        upload_donors: false,
        guide: false,
        comments: false,
        tags: false,
        example: false,
      },
      guide: {
        domain_id: undefined,
        type_id: undefined,
        value: "",
        edit: false,
        content: "",
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [
            Heading,
            {
              options: {
                levels: [1, 2, 3],
              },
            },
          ],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak,
          Image,
        ],
      },
      loading: false,
      upload_donors: {
        domains: [],
        link_type: undefined,
        task: undefined,
        project: undefined,
        name: undefined,
        performer: undefined,
      },
      alert: {
        data: [],
        shown: false,
      },
      loadings: {},
      hide_data: false,
      comments: {
        items: [],
        type: undefined,
        domain: undefined,
      },
      activity_domain: {
        tab: null,
        activity: [
          { id: 1, name: "Registration", show: true, url: false },
          { id: 2, name: "Post/comment", show: true, url: true },
          { id: 3, name: "Like", show: true, url: false },
          { id: 4, name: "Follow", show: true, url: false },
          { id: 5, name: "Moderation", show: false, url: true },
        ],
        activity_url: null,
        login: null,
        password: null,
        email: null,
        activity_id: null,
        history: [],
      },
      moderation: {
        anchor_plan_id: false,
        recommended_domain_id: false,
        acceptor_url_id: false,
        link_type_id: false,
        project_id: false,
        task_id: false,
        link_id: false,
        done_url: "",
        domain: "",
        login: "",
        pass: "",
        email: "",
        text: "",
        notes: "",
        show_all: false,
      },
      example: {},
    };
  },
  methods: {
    get_tag_object(tags, tag) {
      let val = tags.find((i) => i.id === tag);
      if (val === undefined) {
        return {};
      }
      return val;
    },
    publishPost() {
      let self = this;
      let url = `${self.$store.state.server_url}/donor-base/clean/${self.guide.domain_id}/guide`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            content: self.guide.content,
            domain_id: self.guide.domain_id,
            type_id: self.guide.type_id,
          },
          config
        )
        .then(() => {
          self.guide.content = "";
          self.dialog.guide = false;
        });
    },
    get_date(last_posting_in_days) {
      let days = last_posting_in_days;
      let months = Math.round(days / 30);
      let years = Math.round(days / 365);

      let time_ago = `${days} d`;
      let color = "green";

      if (years > 0) {
        time_ago = `${years} y`;
        color = "red";
      } else if (months > 0) {
        time_ago = `${months} m`;
        color = "#ffcc00";
      }
      return '<span style="color: ' + color + '">' + time_ago + "</span>";
    },
    clickGuide(item) {
      this.dialog.guide = true;
      this.guide.content = item.guide;
      this.guide.domain_id = item.domain_id;
      this.guide.type_id = item.link_type_id;
      this.guide.edit = item.guide_edit;
    },
    clickExample(item) {
      this.dialog.example = true;
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("clickExample", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/get-example`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          domain_id: item.domain_id,
          link_type_id: item.link_type_id,
        };

        axios
          .post(url, data, config)
          .then(function (response) {
            self.example = response.data;
            self.update_loading("clickExample", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    initComments(item) {
      this.comments.domain = item.domain_id;
      this.comments.type = item.link_type_id;
      this.dialog.comments = true;
    },
    initTagsHistory(item) {
      this.comments.domain = item.domain_id;
      this.comments.type = item.link_type_id;
      this.dialog.tags = true;
    },
    get_color(item, all) {
      let percent = item / all;

      let color = ""; //= '#9ACD32'
      if (percent < 0.2) {
        color = "rgba(250,126,126,0.88)";
      } else if (percent < 0.5) {
        color = this.is_dark() ? "#d6b701" : "#fff9b4";
      }
      // console.log(item, all, percent, color)

      return "background-color:" + color;
    },
    add_moderation(item) {
      this.moderation.recommended_domain_id = item.id;
      this.moderation.domain = item.domain;
      this.moderation.link_id = item.link_id;
      this.moderation.anchor_plan_id = item.anchor_plan_id;
      this.moderation.done_url = item.done_url;
      this.moderation.login = item.m_login;
      this.moderation.pass = item.m_password;
      this.moderation.email = item.m_email;
      this.moderation.project_id = item.project_id;
      this.moderation.link_type_id = item.link_type_id;
      this.moderation.acceptor_url_id = item.acceptor_url_id;
      this.moderation.task_id = this.task.id;
      this.moderation.text = item.m_text;
      this.moderation.notes = item.m_notes;
      this.get_activity_history();
      this.dialog.moderation = true;
    },
    add_donors() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("add_donors", true);

        let url = `${self.$store.state.server_url}/anchor-plan/upload-donors`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          items: this.upload_donors,
        };

        axios
          .post(url, data, config)
          .then(function (response) {
            self.alert.shown = true;
            self.alert.data = response.data;
            self.dialog.upload_donors = false;
            self.upload_donors.domains = [];
            self.update_loading("add_donors", false);
          })
          .catch(function (error) {
            self.dialog.upload_donors = false;
            self.upload_donors.domains = [];
            alert(error);
          });
      }
    },
    get_activity_history() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("get_activity_history", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/get-activity-history`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          recommended_domain_id: self.moderation.recommended_domain_id,
        };

        axios
          .post(url, data, config)
          .then(function (response) {
            self.activity_domain.history = response.data;

            if (self.activity_domain.history.length > 0) {
              let registrations = self.activity_domain.history.filter(
                (v) => v.activity_id === 1
              );
              if (registrations.length > 0) {
                let registration = registrations[registrations.length - 1];
                self.moderation.email = registration.email;
                self.moderation.login = registration.login;
                self.moderation.pass = registration.password;
              }
            }

            self.update_loading("get_activity_history", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    copy_for_gen_text(text) {
      this.$clipboard(text);
    },
    save_activity() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("save_activity", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/save-activity`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          id: self.activity_domain.activity_id,
          url: self.activity_domain.activity_url,
          login: self.activity_domain.login,
          password: self.activity_domain.password,
          email: self.activity_domain.email,
          recommended_domain_id: self.moderation.recommended_domain_id,
          task_id: self.moderation.task_id,
        };

        axios
          .post(url, data, config)
          .then(function () {
            self.dialog.moderation = false;
            self.activity_domain.activity_id = false;
            self.activity_domain.login = "";
            self.activity_domain.password = "";
            self.activity_domain.email = "";
            self.activity_domain.activity_url = "";
            self.update_loading("save_activity", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    save_moderation() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("save_moderation", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/save-moderation`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          moderation: self.moderation,
        };

        axios
          .post(url, data, config)
          .then(function () {
            self.dialog.moderation = false;
            self.update_loading("save_moderation", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    init_thematic_threads(domain) {
      let querySite = '<a href="https://www.google.com/search?q=site:' + domain;
      let queryIntitle =
        '<a href="https://www.google.com/search?q=site:' + domain;

      let htmlSite =
        querySite +
        " (" +
        this.thematic_threads +
        ')" target = "_blank"> s </a>';
      let htmlIntitle =
        queryIntitle +
        " -intitle(" +
        this.thematic_threads +
        ')" target = "_blank"> in </a>';

      return htmlSite + " | " + htmlIntitle;
    },
    change_size_column(item) {
      let text;

      if (item.text !== "RA") {
        text = "RA";
        this.hide_data = true;
      } else {
        text = "Recommended acceptor";
        this.hide_data = false;
      }

      this.headers.find((i) => i.value === item.value)["text"] = text;
    },
    hide_tag(item, tag) {
      let tag_type = item.tag_type.filter((v) => v !== tag);
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/tag/save`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          domain: item.domain_id,
          type: item.link_type_id,
          tags: tag_type,
        };

        axios
          .post(url, data, config)
          .then(function () {
            self.items.find((i) => i.id === item.id)["tag_type"] = tag_type;
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    click_upload_donors(item) {
      this.dialog.upload_donors = true;
      this.upload_donors.link_type = item.link_type_id;
      this.upload_donors.task = item.id;
      this.upload_donors.project = item.project_id;
      this.upload_donors.name = this.task.name;
      this.upload_donors.performer = this.task.performer;
    },
    mod_to_deleted_links(item) {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.loading = true;
        self.update_loading("to_deleted_links", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/to-deleted-links`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          item,
          type: "mod",
        };

        axios
          .post(url, data, config)
          .then(function () {
            self.update_loading("to_deleted_links", false);
            self.loading = false;
            self.items = self.items.filter((i) => i.id !== item.id);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    in_trash(item, type) {
      this.items = this.items.filter((v) => v.id !== item.id);
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.loading = true;
        self.update_loading("in_trash", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/save-trash/${item.id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          type: type,
        };

        axios
          .post(url, data, config)
          .then(function () {
            self.update_loading("in_trash", false);
            self.loading = false;
            self.fetch_data_domains();
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    save_data_domains(name, value, item) {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("save_data_domains", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/save`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          name: name,
          value: value,
          domain: item.domain_id,
          type: item.link_type_id,
          task_id: this.$route.params.id,
          project_id: item.project_id,
        };

        axios
          .post(url, data, config)
          .then(function (response) {
            if (response.data) {
              self.items.find((i) => i.id === item.id)["color_" + name] =
                "blue";
            } else {
              self.items.find((i) => i.id === item.id)["color_" + name] = "red";
            }
            self.update_loading("save_data_domains", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    get_theme() {
      return this.is_dark()
        ? "border-color: transparent; border: 1px solid red"
        : "";
    },
    open_url(url) {
      window.open(url, "_blank");
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {},
};
</script>

<style scoped>
.fixed-table-sw-d thead > tr > th:first-child,
.fixed-table-sw-d td:first-child {
  position: sticky;
  left: 0;
  min-width: 90px;
  z-index: 2;
}

.fixed-table-sw-d thead > tr > th:nth-child(2),
.fixed-table-sw-d td:nth-child(2) {
  position: sticky;
  left: 90px;
  z-index: 2;
}
</style>
