<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e4"
    scrollable
    max-width="1220"
  >
    <v-card class="styled-card--default">
      <v-card-title>
        Placement nuances
        <v-spacer />
        <v-btn @click="dialog = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-6">
        <v-row dense>
          <v-col cols="6">
            <placement-nuances-form
              :model-value="form"
              @update:modelValue="form = $event"
              @save="handleSaveNewGuide"
              :loading="loading"
              :themes="themeItems"
            />
          </v-col>
          <v-col cols="6" class="site-analysis__feedback">
            <v-progress-circular
              v-if="loading"
              indeterminate
              size="18"
              width="2"
            />
            <template v-if="comments">
              <comment-item
                v-for="comment in comments"
                :comment="comment"
                :key="comment.id"
                :config="config"
                monochrome-rating="primary"
                can-delete
                :after-remove-callback="handleAfterRemoveGuide"
              />
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import PlacementNuancesForm from "@/components/SiteAnalysis/UI/PlacementNuancesForm.vue";
import CommentItem from "@/components/SiteAnalysis/UI/CommentItem.vue";
import eventPipe from "@/events/eventPipe";

export default {
  props: {
    loading: Boolean,
    comments: Array,
    themeItems: Array,
  },
  components: { CommentItem, PlacementNuancesForm },
  mixins: [Dialog],
  data: () => ({
    config: {
      actions: {
        reaction: "/view-info-by-site/guide/set-reaction",
        remove: (id) => `/view-info-by-site/guide/remove-comment/${id}`,
      },
    },
    form: {
      comment: null,
      rating: 0,
      theme_id: null,
    },
  }),
  methods: {
    handleAfterRemoveGuide() {
      eventPipe.$emit("save-new-guide", this.form);
    },
    handleSaveNewGuide() {
      eventPipe.$emit("save-new-guide", this.form);
    },
  },
};
</script>
