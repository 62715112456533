import service from "../../../../plugins/service";

export const routeApiService = () => {
  const endpoints = {
    sections: "/access/sections",
  };

  const getRoutes = () => {
    return service.get(endpoints.sections);
  };

  const addRoute = (formData) => {
    return service.post(endpoints.sections, formData);
  };

  const updateRoute = (id, formData) => {
    return service.put(endpoints.sections + `/${id}`, formData);
  };

  const getRoute = (id) => {
    return service.get(endpoints.sections + `/${id}`);
  };

  return {
    getRoutes,
    addRoute,
    updateRoute,
    getRoute,
  };
};
