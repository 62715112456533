<template>
  <div>
    <v-container v-if="isset_active_project">

      <h2>Text Generate
        <v-btn
            small
            fab
            icon
            @click="upload.modal=true"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </h2>


      <v-row>
        <v-col cols="9">
          <v-card
              class="mx-auto"
          >
            <v-card-text class="headline font-weight-bold">
              <v-textarea v-model="filter.inputData" solo placeholder="Url <tab> Anchor <tab> Length [<tab> Url Link]">
              </v-textarea>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
              class="mx-auto"
          >
            <v-card-text class="headline font-weight-bold">
              <v-select
                  v-model="filter.source"
                  :items="sources" hide-details label="source"
                  item-value="id"
                  item-text="name"
              ></v-select>
              <v-checkbox v-model="filter.mainPage" hide-details label="for main page"></v-checkbox>
              <v-checkbox v-model="filter.linksOld" hide-details label="for links old"></v-checkbox>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="blue" @click="fetch_text">
                Generation
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-for="item in texts" :key="item.length">
        <v-col cols="12" v-if="item.error">
          <v-alert
              border="top"
              color="red"
              dark
          >
            {{ item.error }}
          </v-alert>
        </v-col>
        <v-col cols="6" md="6" v-if="filter.mainPage || filter.linksOld">
          <v-card
              class="mx-auto"
          >
            <v-card-title>
              Add this to header.php or footer.php or on powerful inner page
            </v-card-title>
            <v-card-text>
              <code>{{ getTextForMainPage(item) }}</code>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" md="6" v-else>
          <v-card
              class="mx-auto"
          >
            <v-card-title>
              HTML - P: {{ item.phrase }} - {{ item.length }}
            </v-card-title>
            <v-card-text>
              <code>{{ getTextWithH1Html(item) }}</code>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" md="6">
          <v-card
              class="mx-auto"
          >
            <v-card-title>
              TEXT - P: {{ item.phrase }} - {{ item.length }}
            </v-card-title>
            <v-card-text>
              <span v-html="getTextWithH1Html(item)"></span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" md="6" v-if="filter.mainPage">
          <v-card
              class="mx-auto"
          >
            <v-card-title>
              Add this to style.css
            </v-card-title>
            <v-card-text>
              <code>{{ item.style }}</code>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-card
              class="mx-auto"
          >
            <v-card-text class="headline font-weight-bold">
              <v-row
                  class="align-end"
              >
                <v-col
                >
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      solo
                      class="align-end"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                  align="center"
                  justify="center"
              >
                <v-col>
                  <div class="section">
                    <v-data-table
                        :headers="table.headers"
                        :items="table.items"
                        class="elevation-1"
                        dense
                    >
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <upload-replacement-phrases
          v-if="upload.modal===true"
          v-model="upload.modal"
      ></upload-replacement-phrases>
    </v-container>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
            project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>

import axios from "axios";
import UploadReplacementPhrases from "@/components/TextGenerate/UploadReplacementPhrases";

export default {
  name: "Index",
  components: {UploadReplacementPhrases},
  data() {
    return {
      search: '',
      loadings: {
        fetch_data: false,
      },
      upload: {modal: false},
      sources: [
        {id: 1, name: 'base'},
        {id: 2, name: 'articleforge: phrase'},
        {id: 3, name: 'articleforge: h1/title'}
      ],
      filter: {
        source: 1,
        inputData: '',
        mainPage: false,
        linksOld: false,
        usePreGeneratedTexts: false
      },
      table: {
        headers: [
          {text: 'id', title: 'id', value: 'id', class: 'text-center'},
          {text: 'phrase', title: 'phrase', value: 'phrase', class: 'text-center'},
          {text: 'url', title: 'url', value: 'url', class: 'text-left'},
          {text: 'length', title: 'length', value: 'length', class: 'text-left'},
          {text: 'created_at', title: 'created_at', value: 'created_at', class: 'text-center'},
          {text: 'used', title: 'used', value: 'used', class: 'text-left'},
          {text: 'date', title: 'date', value: 'date', class: 'text-center'},
        ],
        items: [],
      },
      texts: []
    }
  },
  methods: {
    fetch_data() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading('fetch_data', true)

        let url = `${self.$store.state.server_url}/text-generate/history`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {}

        axios.post(url, data, config)
            .then(function (response) {
              self.table.items = response.data;
              self.update_loading('fetch_data', false)
            }).catch((error) => {
          self.update_loading('fetch_data', false)
          alert(error)
        });
      }
    },
    fetch_text() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading('fetch_text', true)

        let url = `${self.$store.state.server_url}/text-generate/one/file/json`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {
          filter: this.filter
        }

        axios.post(url, data, config)
            .then(function (response) {
              self.texts = response.data.data;
              self.update_loading('fetch_text', false)
            }).catch((error) => {
          self.update_loading('fetch_text', false)
          alert(error)
        });
      }
    },
    getTextWithH1Html(item) {
      return "<h1>" + item.title + "</h1>" + item.text
    },
    getTextForMainPage(item) {
      return item.textDiv
    },
    update_loading(type, value) {
      this.$store.state.loading = false
      this.loadings[type] = value
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    }
  },
  created() {
    let self = this;

    self.fetch_data()
  },
}
</script>

<style scoped>

</style>