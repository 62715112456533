import events from "../../../events/components/OnePageAnalyzer/events";
import ApexCharts from "apexcharts";
import axios from "axios";

export const OnePageAnalyzerIndex = {
  computed: {
    idProject() {
      return this.$store.state.project.active_project;
    },
    selectedUrl() {
      return this.$store.state.OnePageAnalyzer.selectedUrl;
    },
    selectedCompetitorUrl() {
      return this.$store.state.OnePageAnalyzer.selectedCompetitorUrl;
    },
    selection() {
      return this.$store.state.OnePageAnalyzer.selection;
    },
    filterData() {
      return {
        anchor_types: this.$store.state.OnePageAnalyzer.filter.anchorTypes,
        link_types: this.$store.state.OnePageAnalyzer.filter.linkTypes,
        follow: this.$store.state.OnePageAnalyzer.filter.follow,
        google_index: this.$store.state.OnePageAnalyzer.filter.googleIndex,
        target_keys: this.$store.state.OnePageAnalyzer.filter.targetKeys,
        hide_disavow: this.$store.state.OnePageAnalyzer.filter.hide_disavow,
        show_on_page: this.$store.state.OnePageAnalyzer.filter.show_on_page,
      };
    },
    vision() {
      return this.$store.state.OnePageAnalyzer.vision;
    },
    sumVisibility() {
      return this.$store.state.OnePageAnalyzer.sum_visibility;
    },
  },
  methods: {
    getInt(val) {
      if (val == "" || val == undefined) {
        return 0;
      }
      return parseInt(val);
    },
    getVisibilityPercentByURL(url) {
      let visibility = this.vision[url];

      if (visibility === undefined) {
        return 0;
      }

      return Math.round((visibility * 100) / this.sumVisibility);
    },
    updateChartDates(selection) {
      let start;
      let end;

      let date_to = new Date();
      let date_from = new Date();

      end = date_to.setDate(date_from.getDate() - 3);

      switch (selection) {
        case "two_week":
          start = new Date().setDate(date_from.getDate() - 14);
          break;
        case "one_month":
          start = new Date().setMonth(date_from.getMonth() - 1);
          break;
        case "three_months":
          start = new Date().setMonth(date_from.getMonth() - 3);
          break;
        case "six_months":
          start = new Date().setMonth(date_from.getMonth() - 6);
          break;
        case "one_year":
          start = new Date().setMonth(date_from.getMonth() - 12);
          break;
        default:
      }

      this.$store.state.OnePageAnalyzer.startDate = start;
      this.$store.state.OnePageAnalyzer.endDate = end;
      this.$store.state.OnePageAnalyzer.selection = selection;

      events.$emit("update-chart-dates", start, end);
    },
    drawChart(id, options) {
      document.querySelector(`#${id}`).innerHTML = "";

      let chart = new ApexCharts(document.querySelector(`#${id}`), options);

      chart.render();
    },
    fetchVision() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/vision`;

      let config = {
        params: {
          url: self.selectedUrl,
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      this.$store.state.OnePageAnalyzer.vision_median = {};
      this.$store.state.OnePageAnalyzer.top10_median = {};

      axios.get(url, config).then((r) => {
        this.$store.state.OnePageAnalyzer.vision = r.data.vision;
        this.$store.state.OnePageAnalyzer.sum_visibility =
          r.data.sum_visibility;

        events.$emit("vision-fetched");
      });
    },
  },
};
