<template>
  <div class="d-flex align-center">
    <small v-if="isForceUpdateEnabled" class="green--text"
      >Force update enabled.</small
    >
    <v-btn
      icon
      :color="isForceUpdateEnabled ? 'green' : ''"
      :loading="loading"
      @click="$emit('click')"
    >
      <v-icon v-if="isForceUpdateEnabled">mdi-speedometer</v-icon>
      <v-icon v-else>mdi-refresh</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["loading", "isForceUpdateEnabled"],
};
</script>

<style scoped></style>
