<template>
  <div class="d-flex align-center" style="gap: 0.25rem">
    <v-tooltip v-if="tooltip" bottom>
      <template #activator="{ on }">
        <v-icon v-on="on" color="rgba(155,155,155,0.5)" size="16">
          mdi-information-outline
        </v-icon>
      </template>
      <template #default> All / Done / Percent </template>
    </v-tooltip>
    {{ data?.all }}
    <span style="opacity: 0.3">/</span>
    {{ data?.done }}
    <span style="opacity: 0.3">/</span>
    <span :class="`${getGradientColor(data?.p)}`"> {{ data?.p }}% </span>
  </div>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    getGradientColor(percent) {
      if (percent <= 33) return "error--text";
      if (percent > 33 && percent <= 77) return "warning--text";
      if (percent > 77) return "success--text";

      return "";
    },
  },
};
</script>
