<template>
  <td>
    <div
      :class="`colored-td nowrap d-flex justify-center align-center ${getColor}`"
    >
      {{ data.created_at ? $moment(data.created_at).format("DD/MM") : "" }}
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      const currDate = this.$moment(new Date());
      const date = this.$moment(this.data.created_at);
      const diff = currDate.diff(date, "days");

      if (this.data.task_closed) return "";

      if (diff > 30) {
        return "red";
      }
      return "";
    },
  },
};
</script>
