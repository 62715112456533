<template>
  <div>
    <v-card class="styled-card--default">
      <v-card-title
        :data-page-navigation="SECTIONS.traffic_by_countries.sectionTitle"
      >
        <!--        Traffic By Country-->
        {{ SECTIONS.traffic_by_countries.sectionTitle }}
        <force-update-button
          :section-name="sectionName"
          :default-prefix="SECTIONS.traffic_by_countries.prefix"
        />
        <v-spacer />
        <type-switcher
          :section-name="sectionName"
          :default-prefix="SECTIONS.traffic_by_countries.prefix"
        />
      </v-card-title>
      <v-card-subtitle>
        <last-update-info
          :data="data"
          :successfully-uploaded="successfullyUploaded"
        />
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <div
          v-if="loading"
          class="skeleton loading"
          style="height: 300px"
        ></div>
        <template v-else>
          <template v-if="successfullyUploaded">
            <apexcharts
              height="273"
              :options="getOptions"
              :series="(data && data.data && data.data.series) || []"
            ></apexcharts>
          </template>
          <template v-else>
            <not-collected-placeholder />
          </template>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";
import TypeSwitcher from "@/components/SiteAnalysis/UI/TypeSwitcher.vue";

export default {
  components: {
    TypeSwitcher,
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
    apexcharts: VueApexCharts,
  },
  mixins: [SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    //
  }),
  computed: {
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "donut",
        },
        colors: (this.data && this.data.data && this.data.data.colors) || [
          "#d75f5f",
          "#3cccb1",
          "#4695cc",
          "#7339c0",
          "#d236da",
          "#b0b0b0",
        ],
        labels: (this.data && this.data.data && this.data.data.labels) || [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
};
</script>
