<template>
  <div>
    <div class="mb-4 px-4">
      <v-text-field
        v-model="searchQuery"
        placeholder="Type to search"
        hide-details
        dense
        outlined
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </div>
    <dev-dashboard-top100-table
      :search="searchQuery"
      :items="componentData()?.items"
    />
  </div>
</template>

<script>
import DevDashboardTop100Table from "../../DashboardDev/components/DevDashboardTop100Table.vue";

export default {
  components: { DevDashboardTop100Table },

  inject: ["componentData"],

  data: () => ({
    searchQuery: null,
  }),
};
</script>
