<template>
  <v-card>
    <v-card-title>Url Rating</v-card-title>
    <apexcharts
      ref="heatmap_ur"
      height="250"
      :options="options"
      :series="series"
    ></apexcharts>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";
import events from "../../../../events/components/OnePageAnalyzer/events";

export default {
  name: "UrlRating",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {
      series: [],
      options: {
        chart: {
          height: 250,
          type: "heatmap",
          toolbar: false,
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              inverse: true,
            },
          },
        },
        colors: [],
      },
    };
  },
  methods: {
    resetData() {
      this.series = [];
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
    });

    events.$on("update-selected-competitor-url", function () {
      self.$refs.heatmap_ur.updateOptions({
        colors: self.$store.state.OnePageAnalyzer.heatmap.ur.colors,
      });
      self.series =
        self.$store.state.OnePageAnalyzer.heatmap.ur.items[
          self.selectedCompetitorUrl
        ];
    });
  },
};
</script>

<style scoped></style>
