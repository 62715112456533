<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e3"
    width="480"
    scrollable
  >
    <v-card flat rounded="lg">
      <v-card-title class="pa-4 text-body-2">
        Add route
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2">Name</div>
            <v-text-field
              v-model="form.name"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Route</div>
            <v-combobox
              placeholder="Type to search"
              :items="filtersData()?.routes"
              v-model="form.route"
              @focus="isSaveDisabled = true"
              @blur="isSaveDisabled = false"
              item-text="text"
              item-value="value"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          :loading="loadings.save"
          :disabled="isSaveDisabled"
          @click="handleSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import { routeApiService } from "../services/routeApiService";

export default {
  inject: ["filtersData"],

  mixins: [Dialog],

  data: () => ({
    isSaveDisabled: false,
    form: {
      name: "",
      route: "",
    },
    loadings: {
      save: false,
    },
  }),

  computed: {
    getFixedPayload() {
      let routeValue;

      if (this.form.route && typeof this.form.route === "object")
        routeValue = this.form.route?.value;
      if (this.form.route && typeof this.form.route === "string")
        routeValue = this.form.route;

      return {
        ...this.form,
        route: routeValue,
      };
    },
  },

  methods: {
    resetForm() {
      this.form = {
        name: "",
        route: "",
      };
    },
    async handleSave() {
      try {
        await this.$nextTick();

        this.loadings.save = true;

        await routeApiService().addRoute(this.getFixedPayload);

        this.resetForm();
        this.dialog = false;
        this.$emit("saved");
      } catch (e) {
        console.error("Error while saving route.", e);
      } finally {
        this.loadings.save = false;
      }
    },
  },
};
</script>
