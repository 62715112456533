<template>
  <v-card flat outlined class="rounded-lg dx4-step-data-table">
    <v-card-title class="text-body-2 font-weight-bold pa-4">
      {{ data.title }}
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-0">
      <v-data-table
        :items="computedItems"
        :headers="[...computedModifiedHeaders, actionsHeader]"
        dense
        multi-sort
        :items-per-page="-1"
        hide-default-footer
      >
        <template
          v-for="h in [...computedModifiedHeaders, actionsHeader]"
          #[`item.${h.value}`]="{ value, item }"
        >
          <template v-if="h.value === '_actions'">
            <v-btn
              icon
              small
              :key="h.value"
              @click="handleEdit(item)"
              :disabled="loadings.uploadStepItems !== null"
              :loading="loadings.uploadStepItems === item.id"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-else-if="h.value === 'domain'">
            <div v-if="isEveryStepFalse(item)" :key="h.value">
              <span class="error--text">
                {{ value }}

                <v-icon v-if="isEveryStepFalse(item)" color="error" small>
                  mdi-alert
                </v-icon>
              </span>
            </div>
            <template v-else>
              {{ value }}
            </template>
            <v-tooltip v-if="item.outdated" bottom :key="`outdated-${h.value}`">
              <template #activator="{ on }">
                <span
                  v-on="on"
                  class="px-2 py-1 flat-chip--auto warning--text rounded-pill"
                  style="line-height: 100%; font-size: 12px"
                >
                  Data has been updated
                </span>
              </template>
              <template #default>
                Refresh the data on the page to see the current data for this
                table
              </template>
            </v-tooltip>
          </template>
          <template v-else-if="h.value !== 'domain'">
            <div :key="h.value">
              <template v-if="Number(value) === 1">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      :color="processProgressBarColor(item)"
                      label
                      style="width: 100%"
                    />
                  </template>
                  <div>
                    <div>
                      {{ h.text }}
                    </div>
                    <div class="mt-2">
                      {{ item["_" + h.value] }}
                    </div>
                  </div>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      class="flat-chip flat-chip--error"
                      label
                      style="width: 100%"
                    />
                  </template>
                  <div>
                    <div>
                      {{ h.text }}
                    </div>
                    <div class="mt-2">
                      {{ item["_" + h.value] }}
                    </div>
                  </div>
                </v-tooltip>
              </template>
            </div>
          </template>
          <template v-else>
            {{ value }}
          </template>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="isEditDialogVisible"
      content-class="my-shadow--e4"
      scrollable
    >
      <v-card flat rounded="lg" outlined class="dx4-step-data-table">
        <v-card-title class="pa-4 text-body-2">
          Edit
          <v-spacer />
          <v-icon @click="isEditDialogVisible = false"> mdi-close </v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row class="d-flex" style="gap: 0.5rem">
            <v-col v-for="step in stepsData" :key="step.step">
              <v-card flat outlined rounded="lg">
                <v-card-title class="pa-4 text-body-2">
                  {{ step.step }}
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-5">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="step.link"
                        outlined
                        hide-details
                        placeholder="Enter link"
                    /></v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="step.checkbox"
                        label="Done"
                        hide-details
                        dense
                        :true-value="1"
                        :false-value="0"
                        class="ma-0"
                    /></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            large
            class="text-normal px-6"
            color="success"
            :loading="loadings.saving"
            @click="handleSaveChanges(stepsData)"
          >
            Save changes
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { seoStrategyApiService } from "../../../views/seo-strategy/services/seoStrategyApiService";

export default {
  props: {
    data: {},
  },

  data: () => ({
    isEditDialogVisible: false,
    stepsData: [],
    activeEditItem: null,
    seoStrategyApiService: null,
    loadings: {
      saving: false,
      uploadStepItems: null,
    },
    actionsHeader: {
      text: "Actions",
      value: "_actions",
    },
  }),

  created() {
    this.seoStrategyApiService = seoStrategyApiService();
  },

  computed: {
    computedItems() {
      return this.data.data[0].items;
    },
    computedModifiedHeaders() {
      const fixed = this.data.data[0].headers.map((header) => {
        if (header.value === "domain") header.width = "420px";
        else if (header.value !== "_actions") {
          header.width = "160px";
        } else {
          header.width = "auto";
        }

        return header;
      });

      return [...fixed];
    },
  },

  methods: {
    async handleSaveChanges(items) {
      try {
        this.loadings.saving = true;
        const promises = [];

        items.forEach((step) => {
          promises.push(this.seoStrategyApiService.putStrategy(step));
        });

        await Promise.all(promises);

        this.$set(this.activeEditItem, "outdated", true);

        this.isEditDialogVisible = false;
      } catch (e) {
        console.error("Error while saving step data.", e);
      } finally {
        this.loadings.saving = false;
      }
    },
    async getStepsData(tableItem) {
      try {
        this.loadings.uploadStepItems = tableItem.id;

        const { data } = await this.seoStrategyApiService.getStrategyById(
          tableItem.id
        );

        return data.items || [];
      } catch (e) {
        console.error("Error while updating step element.", e);
      } finally {
        this.loadings.uploadStepItems = null;
      }
    },
    async handleEdit(tableItem) {
      this.stepsData = await this.getStepsData(tableItem);
      this.activeEditItem = tableItem;
      this.isEditDialogVisible = true;
    },
    isEveryStepFalse(tableItem) {
      const steps = [];

      Object.entries(tableItem).forEach(([key, value]) => {
        if (key === "domain") return;
        if (key === "id") return;
        if (key[0] === "_") return;

        steps.push(value);
      });

      return steps.every((step) => String(step) === "0");
    },
    processProgressBarColor(tableItem) {
      const keys = Object.keys(tableItem).filter((key) => {
        if (key === "domain") return false;
        if (key === "id") return false;
        if (key[0] === "_") return false;

        return true;
      });

      const stepsProgress = [];

      keys.forEach((key) => {
        stepsProgress.push(tableItem[key]);
      });

      if (stepsProgress.every((value) => String(value) === "1"))
        return "success";

      if (stepsProgress.some((value) => String(value) === "1"))
        return "warning";

      return "transparent";
    },
  },
};
</script>

<style lang="scss">
.dx4-step-data-table {
  .v-data-table {
    .v-chip {
      clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    }
    thead {
      th:not(:first-child):not(:last-child) {
        span {
          writing-mode: vertical-rl;
          text-orientation: mixed;
          white-space: nowrap;
          padding-inline: 0.5rem;
        }
      }
    }
    tbody {
      tr {
        td:not(:first-child):not(:last-child) {
          padding: 0.25rem !important;
          .v-chip {
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}
</style>
