<template>
  <v-card :loading="loading">
    <v-card-title>Compare Phrases</v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        solo
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      v-if="isReady"
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1 v-data-table-small"
      :sort-by="['volume', 'cl', 'imp']"
      :sort-desc="[true, true, true]"
      :items-per-page="15"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.phrase">
            <td>{{ item.phrase }}</td>
            <td class="text-center">{{ item.volume }}</td>
            <td class="text-center">{{ item.pos }}</td>
            <td class="text-center">{{ item.pos_7d }}</td>
            <td class="text-center">{{ item.cl }}</td>
            <td class="text-center">{{ item.imp }}</td>
            <td class="text-center">{{ item.ctr }}</td>
            <td
              class="text-center"
              :style="
                'background-color: ' +
                (item.title === 0 &&
                (getVisionMedian(item.phrase, 'title') > 0 ||
                  getTop10Median(item.phrase, 'title') > 0)
                  ? '#ffeb3b47'
                  : '')
              "
            >
              <div v-html="item.title"></div>
              {{ getVisionMedian(item.phrase, "title") }} /
              {{ getTop10Median(item.phrase, "title") }}
            </td>
            <td
              class="text-center"
              :style="
                'background-color: ' +
                (item.description === 0 &&
                (getVisionMedian(item.phrase, 'description') > 0 ||
                  getTop10Median(item.phrase, 'description') > 0)
                  ? '#ffeb3b47'
                  : '')
              "
            >
              <div v-html="item.description"></div>
              {{ getVisionMedian(item.phrase, "description") }} /
              {{ getTop10Median(item.phrase, "description") }}
            </td>
            <td
              class="text-center"
              :style="
                'background-color: ' +
                (item.h1 === 0 &&
                (getVisionMedian(item.phrase, 'h1') > 0 ||
                  getTop10Median(item.phrase, 'h1') > 0)
                  ? '#ffeb3b47'
                  : '')
              "
            >
              <div v-html="item.h1"></div>
              {{ getVisionMedian(item.phrase, "h1") }} /
              {{ getTop10Median(item.phrase, "h1") }}
            </td>
            <td
              class="text-center"
              :style="
                'background-color: ' +
                (item.text === 0 &&
                (getVisionMedian(item.phrase, 'text') > 0 ||
                  getTop10Median(item.phrase, 'entry') > 0)
                  ? '#ffeb3b47'
                  : '')
              "
            >
              <div v-html="item.text"></div>
              {{ getVisionMedian(item.phrase, "text") }} /
              {{ getTop10Median(item.phrase, "entry") }}
            </td>
            <td class="text-center">{{ item.inner_anchor }}</td>
            <td class="text-center">{{ item.links_anchor }}</td>
            <td class="text-center">{{ item.links_anchor_broad }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../mixins/Common";
import axios from "axios";
import events from "../../../events/components/OnePageAnalyzer/events";

export default {
  name: "PhraseCompare",
  data() {
    return {
      loading: false,
      search: "",
      headers: [],
      items: [],
    };
  },
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  computed: {
    isReady() {
      return (
        Object.keys(this.$store.state.OnePageAnalyzer.vision_median).length !==
          0 &&
        Object.keys(this.$store.state.OnePageAnalyzer.top10_median).length !== 0
      );
    },
  },
  methods: {
    getVisionMedian(phrase, field) {
      let median = this.$store.state.OnePageAnalyzer.vision_median[phrase];
      return median !== undefined ? median[field] : 0;
    },
    getTop10Median(phrase, field) {
      let median = this.$store.state.OnePageAnalyzer.top10_median[phrase];
      return median !== undefined ? median[field] : 0;
    },
    resetData() {
      this.items = [];
      this.headers = [];
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/compare_phrases`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedUrl,
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let post_data = {
        vision: self.vision,
        sum_visibility: self.sumVisibility,
        projectID: self.idProject,
        url: self.selectedUrl,
      };

      axios
        .post(url, post_data, config)
        .then((r) => {
          self.items = r.data.items;
          self.headers = r.data.headers.map((v) => {
            if (v.type !== undefined) {
              if (v.type === "float") {
                v.sort = function (a, b) {
                  a = parseFloat(a);
                  b = parseFloat(b);

                  if (a === b) {
                    return 0;
                  }

                  return a > b ? 1 : -1;
                };
              }
            }

            return v;
          });
        })
        .finally(() => (self.loading = false));
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
    });

    events.$on("vision-fetched", function () {
      self.fetchData();
    });
  },
};
</script>

<style scoped></style>
