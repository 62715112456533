<template>
  <v-card v-if="data" class="styled-card--default">
    <v-card-title>
      Element info
      <v-spacer />
      <v-chip label outlined @click="handleShowElementHistory">
        <v-icon left small>mdi-eye</v-icon> Show history
      </v-chip>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense v-if="data?.new_element?.current">
        <v-col cols="4"> Current info </v-col>
        <v-col cols="8">
          <b>{{ data.new_element.current }} </b></v-col
        >
      </v-row>
      <v-row dense class="mt-4">
        <v-col cols="4"> New element info </v-col>
        <v-col cols="8">
          <v-textarea
            v-model="data.new_element.new"
            filled
            dense
            rows="2"
            hide-details
            label="Write new element info"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="elementHistoryDialogModel"
      content-class="my-shadow--e4"
      max-width="1220"
    >
      <v-card class="styled-card--default">
        <v-card-title> Element info history </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-data-table
            :items="data.new_element.history"
            :headers="headers"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {},
  },
  data: () => ({
    elementHistoryDialogModel: false,
    headers: [
      {
        text: "Block",
        value: "block",
      },
      {
        text: "Date",
        value: "date",
      },
    ],
  }),
  methods: {
    handleShowElementHistory() {
      this.elementHistoryDialogModel = true;
    },
  },
};
</script>
