<template>
  <div class="tribe-dashboard d-flex flex-column pb-16" style="margin: -12px">
    <div class="pa-4 d-flex align-end">
      <page-header
        title="Tribe dashboard"
        :breadcrumbs="pageConfig.breadcrumbs"
      />
      <v-spacer />

      <v-form ref="filters">
        <tribe-dashboard-filters
          :dialog="isFiltersDialogVisible"
          @update:dialog="isFiltersDialogVisible = $event"
          v-model="filters"
          :filters-data="filtersData"
          @getData="handleGetData"
          :loadings="{ ...loadings, ...filtersDataPendingKeys }"
          ref="filtersRef"
        />
      </v-form>
    </div>
    <v-divider />
    <div class="pa-4">
      <selected-filters
        :filters="filters"
        :data="filtersData"
        :info="filtersInfo"
        label="Active filters"
      />
    </div>
    <v-divider />
    <div class="px-4 pt-4" v-if="sections && Object.keys(sections)?.length">
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="searchQuery"
            hide-details
            outlined
            dense
            label="Type to search"
            style="max-width: 320px"
            append-icon="mdi-magnify"
          />
        </v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
      </v-row>
    </div>
    <div class="px-4 pt-3 d-flex flex-column" style="gap: 0.5rem">
      <div v-for="(section, idx) in computedSections" :key="`section-${idx}`">
        <tribe-dashboard-section-factory :section="section" />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../UI/PageHeader.vue";
import { pageConfig } from "./defaults";
import TribeDashboardFilters from "./components/TribeDashboardFilters.vue";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import { Tribe4DxApiService } from "./service/Tribe4DxApiService";
import TribeDashboardSectionFactory from "./components/TribeDashboardSectionFactory.vue";
import UploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";
import { uid } from "../../utils/functions";
import DomAnimatorMixin from "../../mixins/DomAnimatorMixin";
import SelectedFilters from "../UI/SelectedFilters/index.vue";
/*eslint-disable*/

export default {
  components: {
    TribeDashboardSectionFactory,
    TribeDashboardFilters,
    PageHeader,
    SelectedFilters,
  },

  mixins: [FiltersHandling, UploadFiltersDataMixin, DomAnimatorMixin],

  data: () => ({
    filtersData: {
      tribe_sections: [],
      tribe_view_dimensions: [],
      niches: [],
      page_types: [],
      projects: [],
      subprojects: [],
      teams: [],
      users: [],
      seo_users: [],
      link_types: [],
    },
    isFiltersDialogVisible: false,
    searchQuery: null,
    filters: {
      sections: [],
      click_filter: null,
      view_dimension: ["by_subproject"],
      page_types: [],
      need_top: [null, null],
      niche: 2,
      teams: [],
      main_seo: [],
      subprojects: [],
      project: [],
      project_status: [1],
      subproject_status: [1],
    },
    filtersInfo: {
      sections: { title: "Sections", dataKey: "tribe_sections" },
      click_filter: { title: "Click filter" },
      view_dimension: {
        title: "View dimension",
        dataKey: "tribe_view_dimensions",
      },
      page_types: { title: "Page types", dataKey: "page_types" },
      need_top: { title: "Need top" },
      niche: { title: "Niche", dataKey: "niches" },
      teams: { title: "Teams", dataKey: "teams" },
      main_seo: { title: "Main seo", dataKey: "seo_users" },
      subprojects: { title: "Subprojects", dataKey: "subprojects" },
      project: { title: "Projects", dataKey: "projects" },
      project_status: { title: "Project status", dataKey: "project_statuses" },
      subproject_status: {
        title: "Subproject status",
        dataKey: "project_statuses",
      },
    },
    loadings: {
      filters: false,
      allSections: false,
    },
    sections: {},
  }),

  computed: {
    computedSections() {
      if (!this.searchQuery) return this.sections;

      const query = (string) => {
        return String(string)
          .toLowerCase()
          .includes(this.searchQuery?.toLowerCase());
      };

      return Object.entries(this.sections)
        .filter(([key, value]) => query(key))
        .map(([key, value]) => value);
    },
    pageConfig: () => pageConfig,
    computedFiltersDataPayload() {
      const niches = [this.filters.niche];

      const projects = this.filters.project;

      const subprojects = this.filters.subprojects;

      const teams = this.filters.teams;

      const project_statuses = this.filters.project_status;

      const subproject_statuses = this.filters.subproject_status;

      return {
        niches,
        projects,
        subprojects,
        teams,
        project_statuses,
        subproject_statuses,
      };
    },
  },

  async mounted() {
    await this.parseQuery("filters");

    await this.uploadFiltersData(
      [
        "link_types",
        "page_types",
        "niches",
        "project_statuses",
        "projects",
        "subprojects",
        "seo_users",
        "users",
        "teams",
        "tribe_view_dimensions",
        "tribe_sections",
      ],
      this.computedFiltersDataPayload
    );

    this.initializeDefaultFilters();
    this.initializeWatchers();
  },

  methods: {
    initializeWatchers() {
      const refs = this.$refs.filtersRef.$refs;
      const animate = (ref, type, config) => this.animateDom(ref, type, config);

      this.$watch("filters.project", {
        handler() {
          this.filters.subprojects = [];
          console.log("animate");
          animate(refs.subprojectFilterRef, "jello", { id: "11" });
          this.uploadFiltersData(
            ["subprojects"],
            this.computedFiltersDataPayload
          );
        },
      });

      this.$watch("filters.niche", {
        async handler() {
          this.filters.project = [];
          animate(refs.projectFilterRef, "jello");
          this.filters.subprojects = [];
          animate(refs.subprojectFilterRef, "jello", { id: "12" });

          this.uploadFiltersData(
            ["projects", "subprojects"],
            this.computedFiltersDataPayload
          );
        },
      });

      this.$watch("filters.teams", {
        async handler() {
          this.filters.project = [];
          animate(refs.projectFilterRef, "jello", { id: "13" });
          this.filters.subprojects = [];
          animate(refs.subprojectFilterRef, "jello", { id: "14" });

          this.uploadFiltersData(
            ["projects", "subprojects"],
            this.computedFiltersDataPayload
          );
        },
      });

      this.$watch("filters.project_status", {
        async handler() {
          this.filters.project = [];
          animate(refs.projectFilterRef, "jello", { id: "15" });

          this.uploadFiltersData(["projects"], this.computedFiltersDataPayload);
        },
      });

      this.$watch("filters.subproject_status", {
        async handler() {
          this.filters.subprojects = [];
          animate(refs.subprojectFilterRef, "jello", { id: "16" });

          this.uploadFiltersData(
            ["subprojects"],
            this.computedFiltersDataPayload
          );
        },
      });
    },
    initializeDefaultFilters() {
      if (!this.filters.sections?.length) {
        this.filters.sections = [
          ...this.filtersData.tribe_sections.map((v) => v.value),
        ];
      }
    },
    async processGroupData(sectionKey, group) {
      // Similar sections didn't exist
      if (this.sections[group.name] === undefined) this.addNewSection(group);

      // Создать объект айтема секции
      const newSectionItem = {
        _id: uid(),
        _status: null,
        _content: null,
        _info: {
          sectionKey,
          group,
        },
      };

      this.sections[group.name].items.push(newSectionItem);

      this.$set(newSectionItem, "_status", "pending");

      try {
        const sectionData = await this.fetchSection(sectionKey, group);

        this.$set(newSectionItem, "_status", "success");
        this.$set(newSectionItem, "_content", sectionData);
      } catch (e) {
        console.error("Error while processing group data. Group: ", group.name);
        this.$set(newSectionItem, "_status", "error");
      }
    },
    addNewSection(group) {
      const newSection = {
        priority: group.priority,
        name: group.name,
        items: [],
        _reactive: {
          expanded: true,
        },
        _info: {
          group,
        },
      };

      this.$set(this.sections, group.name, newSection);
    },
    async fetchSection(sectionKey, group) {
      try {
        const resp = await Tribe4DxApiService().getTribeContent({
          ...this.filters,
          type: sectionKey,
          ...(group?.filter || {}),
        });

        return resp?.data || {};
      } catch (e) {
        console.error("Error while loading section data.", e);
        throw e;
      }
    },
    async processGroups(groups) {
      const sections = this.filters.sections || [];

      for (const [sIdx, sectionKey] of sections.entries()) {
        for (const group of groups) {
          await this.processGroupData(sectionKey, group, sIdx);
        }
      }

      return { done: true };
    },
    async handleGetData() {
      if (!this.$refs.filters?.validate())
        return (this.isFiltersDialogVisible = true);

      this.$set(this, "sections", {});

      const apiService = Tribe4DxApiService();

      this.loadings.allSections = true;

      const { data } = await apiService.getTribeGroups(this.filters);

      const groups = data?.items || [];

      await this.processGroups(groups);

      this.loadings.allSections = false;
    },
  },
};
</script>
