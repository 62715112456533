<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="text-center">Page type checker</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-text-field
          v-model="url"
          hide-details
          dense
          filled
          label="Enter URL"
          append-icon="mdi-link-variant"
          clearable
          style="max-width: 400px"
        ></v-text-field>
        <v-btn
          class="ml-2"
          color="primary"
          style="height: 52px"
          @click="fetchData"
          :loading="loading"
        >
          Get data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <v-chip
          v-if="urlData?.page_type?.name"
          label
          large
          title="Page type"
          outlined
          class="px-6"
        >
          <v-icon small left>mdi-tag</v-icon>{{ urlData?.page_type?.name }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "@/plugins/service";

export default {
  data: () => ({
    url: null,
    loading: false,
    urlData: null,
  }),
  methods: {
    async fetchData() {
      try {
        const url = `/sl/sandbox/page-type/detector?url=${this.url}`;

        this.loading = true;

        const response = await service.get(url);

        this.urlData = response.data;
      } catch (e) {
        console.error("Error while loading url type data.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
