<template>
  <div :class="`colored-td ${getColor}`" style="white-space: nowrap">
    <template v-if="data">
      <span>{{ data.all !== 0 ? data.all : "" }}</span>
      <sup v-if="data.overdue !== 0" style="color: red">
        {{ data.overdue }}
      </sup>
      <template v-if="data.not_closed !== 0">
        <sub>
          <span>/</span>
          <span>{{ data.not_closed }}</span>
        </sub>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data", "type", "labels", "email"],
  computed: {
    getColor() {
      if (!this.data) return "";

      if (!this.data.all && this.type === "Week_A") {
        return "red";
      }
      if (!this.data.all && this.type === "Week_B") {
        return "red";
      }
      if (!this.data.all && this.type === "Week_C") {
        return "yellow";
      }

      return "";
    },
  },
  methods: {
    goTo() {
      let url = `/worksection/report/daily3?labels=${this.type},`;

      if (this.labels.length > 0) url += this.labels.join(",");

      url += `&executive=${this.email}`;

      window.open(url, "_blank");
    },
  },
};
</script>
