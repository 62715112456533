<template>
  <v-card>
    <apexcharts
      type="bar"
      height="300"
      :options="options"
      :series="series"
    ></apexcharts>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";

export default {
  name: "WordInAnchors",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {};
  },
  computed: {
    series() {
      let a_1 = [];
      let a_2 = [];
      let a_3 = [];
      let a_4 = [];
      let a_5 = [];

      let self = this;

      this.$store.state.OnePageAnalyzer.common_table_items.forEach(function (
        item
      ) {
        a_1.push(self.getInt(item.words_in_anchors_1));
        a_2.push(self.getInt(item.words_in_anchors_2));
        a_3.push(self.getInt(item.words_in_anchors_3));
        a_4.push(self.getInt(item.words_in_anchors_4));
        a_5.push(self.getInt(item["words_in_anchors_5+"]));
      });

      return [
        {
          name: "1",
          data: a_1,
        },
        {
          name: "2",
          data: a_2,
        },
        {
          name: "3",
          data: a_3,
        },
        {
          name: "4",
          data: a_4,
        },
        {
          name: "5+",
          data: a_5,
        },
      ];
    },
    options() {
      let categories = [];

      this.$store.state.OnePageAnalyzer.common_table_items.forEach(function (
        item
      ) {
        let url = new URL(item.default_url);
        categories.push(url.host);
      });

      return {
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Word In Anchors",
        },
        xaxis: {
          categories: categories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>

<style scoped></style>
