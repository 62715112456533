<template>
  <v-row class="GPM-entity__heading">
    <v-col sm="auto" cols="12" class="heading__left">
      <div class="left__wrapper">
        <div class="heading">
          <div class="heading__title">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn v-on="on" @click="goBack" icon>
                  <v-icon>mdi-step-backward</v-icon>
                </v-btn>
              </template>
              <div>Return to previous step</div>
            </v-tooltip>
            <div class="title__status">
              <v-tooltip right>
                <template #activator="{ on }">
                  <span v-on="on">{{ header.status_name }}</span>
                </template>
                <div>Current status</div>
              </v-tooltip>
            </div>
            <div class="title__name">
              <div>
                <span class="name">{{ header.domain }}</span>

                <v-btn
                  icon
                  :href="'http://' + header.domain"
                  target="_blank"
                  small
                  title="Visit domain"
                >
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>

                <v-btn
                  icon
                  :href="`https://www.google.com/search?q=site:${header.domain} intitle:casino|casinos|gambling|poker|roulette|blackjack`"
                  target="_blank"
                  small
                  title="Search in google"
                >
                  <v-icon small>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="title__link"></div>
            <v-chip
              v-if="header.donor_has_fixes_phone_and_mail"
              class="ml-2"
              color="warning"
              @click="viewWasConnected()"
            >
              Outreached

              <v-icon v-if="!loadings.viewConnected" small right class="pr-1">
                mdi-bell-badge-outline
              </v-icon>
              <v-btn v-else x-small icon dark plain loading class="ml-1">
              </v-btn>
            </v-chip>
            <v-chip
              v-if="header.total_pings"
              class="ml-2"
              color="error"
              @click="handleViewPingsHistory"
            >
              <span class="px-1">
                Ping
                <template v-if="header.total_pings > 1"
                  >({{ header.total_pings }})</template
                >
                <v-icon v-if="!loadings.pingsHistory" small class="pl-2"
                  >mdi-chat-alert</v-icon
                >
                <v-progress-circular
                  v-else
                  class="ml-2"
                  indeterminate
                  size="16"
                  width="2"
                />
              </span>
            </v-chip>
          </div>
        </div>
        <div class="heading__status-task">
          <v-row>
            <v-col v-if="header.task_name">
              <div class="heading__input">
                <div class="input__label">Stage time</div>
                <div class="input__content">
                  <div
                    class="info-section__chip"
                    :style="{
                      color: header.overdue ? 'red' : 'green',
                    }"
                  >
                    {{ header.time_on_stage }}
                    <v-icon
                      :color="header.overdue ? 'red' : 'green'"
                      :style="`transform: ${
                        header.overdue ? 'rotateY(180deg)' : ''
                      }`"
                      >mdi-chart-line-variant</v-icon
                    >
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="responsibilities_colors[header.assigned]">
              <div class="heading__input">
                <div class="input__label">Responsible</div>
                <div class="input__content">
                  <v-menu
                    bottom
                    open-on-hover
                    content-class="remove-dialog-shadow pa-1"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on }">
                      <div
                        class="info-section__chip responsible"
                        :style="{
                          color: responsibilities_colors[header.assigned].bg,
                        }"
                        v-on="on"
                      >
                        <div
                          class="d-flex align-center"
                          :style="{
                            color: responsibilities_colors[header.assigned].bg,
                          }"
                        >
                          {{ header.assigned }}
                          {{
                            getUserInitials(
                              responsibilities_colors[header.assigned].bg,
                              header
                            )
                          }}
                          <v-icon small class="ml-1"
                            >mdi-information-outline</v-icon
                          >
                        </div>
                      </div>
                    </template>
                    <v-card
                      class="pa-2 shadow-e1-bordered"
                      rounded="lg"
                      width="240"
                    >
                      <v-row dense>
                        <v-col cols="12">
                          <div>
                            <b style="font-size: 12px">Creator</b>
                          </div>
                          <div style="font-size: 14px">
                            {{ header.creator }}
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div>
                            <b style="font-size: 12px"> Performer </b>
                          </div>
                          <div style="font-size: 14px">
                            {{ header.performer }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </v-col>
            <v-col>
              <div class="heading__input">
                <div class="input__label">Tags</div>
                <div class="input__content">
                  <template v-if="getTags?.length > 0">
                    <v-chip
                      small
                      v-for="tag in getTags"
                      label
                      :key="tag"
                      class="mr-2 px-2 flat-chip flat-chip--auto"
                      :style="{
                        color: getTagColor(tag),
                      }"
                    >
                      {{ tag }}
                    </v-chip>
                  </template>
                  <template v-else>
                    <small class="ml-2" style="opacity: 0.3">
                      No tags...
                    </small>
                  </template>
                </div>
              </div>
            </v-col>
            <v-col>
              <div class="heading__input">
                <div class="input__label">Amount of articles</div>
                <div class="input__content">
                  <template
                    v-if="
                      header.articles_count === null ||
                      (Array.isArray(header.articles_count) &&
                        header.articles_count.length === 0)
                    "
                  >
                    <span style="opacity: 0.3; font-size: 12px">
                      Not collected...</span
                    >
                  </template>
                  <template v-else>
                    <v-chip
                      v-for="item in header.articles_count"
                      :key="item.name"
                      outlined
                      small
                      class="mr-2"
                    >
                      {{ item.name }}: <b class="ml-1">{{ item.value || 0 }}</b>
                    </v-chip>
                  </template>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="heading__input">
                <div class="input__label">Status</div>
                <div class="input__content">
                  {{ header.main_status }} ({{
                    Math.round((header.stage * 100) / 8)
                  }}%)
                </div>
              </div>
            </v-col>
            <v-col v-if="header.main_status">
              <div class="heading__input">
                <div class="input__label">Task Name</div>
                <div class="input__content">
                  {{ header.task_name }}
                </div>
              </div>
            </v-col>
            <v-col>
              <div class="heading__input">
                <div class="input__label">Bad donor statuses</div>
                <div class="input__content">
                  <v-tooltip v-if="header.last_month" bottom>
                    <template #activator="{ on }">
                      <v-chip
                        v-on="on"
                        small
                        outlined
                        color="info"
                        class="mr-1"
                        close-icon="mdi-alert-circle-outline"
                      >
                        <v-icon small class="mr-1">
                          mdi-alert-circle-outline
                        </v-icon>
                        Last month
                      </v-chip>
                    </template>
                    <div>Was assigned a ready reference by this donor</div>
                  </v-tooltip>
                  <template
                    v-if="
                      getSelectedStatuses(header.selected_bad_donor_statuses)
                        .length > 0
                    "
                  >
                    <v-tooltip
                      v-for="status in getSelectedStatuses(
                        header.selected_bad_donor_statuses
                      )"
                      :key="status.tag"
                      bottom
                      content-class="pa-0 shadow-e1-bordered"
                    >
                      <template #activator="{ on }">
                        <v-chip
                          v-on="on"
                          small
                          class="mr-1 mb-1"
                          close
                          @click:close="handleClickRemoveTag(header, status)"
                        >
                          {{ status.tag }}
                        </v-chip>
                      </template>
                      <v-card class="pa-4">
                        <v-row dense>
                          <v-col cols="12" class="d-flex align-center">
                            <v-icon class="mr-2" dark
                              >mdi-account-outline</v-icon
                            >
                            {{ status.user }}
                          </v-col>
                          <v-col cols="12" class="d-flex align-center">
                            <v-icon class="mr-2" dark>mdi-calendar</v-icon>
                            {{ status.date }}
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tooltip>
                  </template>

                  <template v-else>
                    <small style="opacity: 0.3"> No selected statuses...</small>
                  </template>
                </div>
              </div>
            </v-col>
            <v-col></v-col>
          </v-row>
        </div>
      </div>
    </v-col>
    <v-col sm="auto" cols="12" class="GPM-entity__view-entity-actions">
      <div class="view-entity-actions__wrapper d-flex justify-end">
        <v-list dense nav class="border rounded-lg">
          <v-list-item
            v-if="header.user_guide"
            :href="header.user_guide"
            class="flat-chip flat-chip--auto-lighten ma-0"
            target="_blank"
          >
            <v-list-item-title class="pl-3">
              Visit user guide
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-text-box-search</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-if="isPingedStep"
            class="flat-chip flat-chip--auto flat-chip--auto-lighten ma-0"
            @click="pingDonor"
          >
            <v-list-item-title class="pl-3"> Ping Donor </v-list-item-title>
            <v-list-item-icon>
              <v-icon v-if="!loadings.ping" small>mdi-bell-ring-outline</v-icon>
              <v-progress-circular v-else indeterminate size="12" width="2" />
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            @click="viewLttLinks"
            class="flat-chip flat-chip--auto-lighten ma-0"
          >
            <v-list-item-title class="pl-3">
              <v-badge
                :content="header.ltt_links"
                :value="header.ltt_links"
                color="primary"
                inline
                class="ma-0"
              >
                View LTT Links
              </v-badge>
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-link</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            class="flat-chip flat-chip--auto-lighten ma-0"
            @click="goToSpiderLink"
          >
            <v-list-item-title class="pl-3">
              <v-badge
                :content="header.spiderlink_links"
                :value="header.spiderlink_links"
                color="primary"
                inline
                class="ma-0"
              >
                Spiderlink<v-icon size="12" class="ml-1"
                  >mdi-open-in-new</v-icon
                >
              </v-badge>
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-spider</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            class="flat-chip flat-chip--auto-lighten ma-0"
            @click="viewInfo(header)"
          >
            <v-list-item-title class="pl-3">
              Site Analyse<v-icon size="12" class="ml-1"
                >mdi-open-in-new</v-icon
              >
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon small>mdi-chart-donut</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-menu
            bottom
            nudge-bottom="52"
            content-class="my-shadow--e3"
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <v-list-item v-on="on" style="grid-column: span 2">
                <v-list-item-title class="text-center">
                  More actions <v-icon small right>mdi-menu</v-icon>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template #default>
              <v-list dense nav class="border rounded-lg">
                <v-menu
                  left
                  offset-y
                  open-delay="350"
                  nudge-top="4"
                  :close-on-content-click="false"
                  rounded="lg"
                  content-class="remove-dialog-shadow rounded-lg pa-1"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                      <v-list-item-title class="pl-3">
                        <div class="d-flex align-center" style="gap: 0.5rem">
                          <v-badge
                            v-if="header.outsources.length > 0"
                            :content="header.outsources.length"
                            color="primary"
                            inline
                            class="ma-0"
                          >
                            Outsources
                          </v-badge>
                          <div v-else>Outsources</div>
                        </div>
                      </v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small>mdi-chevron-right</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <template #default>
                    <v-card
                      class="my-shadow--e3 styled-card--default"
                      flat
                      outlined
                    >
                      <v-card-text>
                        <v-list
                          v-if="
                            header.outsources && header.outsources.length > 0
                          "
                        >
                          <v-list-item
                            v-for="(item, i) in header.outsources"
                            :key="i"
                          >
                            <v-list-item-title>
                              <a :href="item.link" target="_blank">
                                {{ item.title }}
                              </a>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <div
                          v-else
                          class="text-center text-body-2 py-4"
                          style="opacity: 0.5"
                        >
                          <div>List is empty</div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-menu>
                <v-list-item @click="viewWasConnected">
                  <v-list-item-title class="pl-3">
                    <v-badge
                      v-if="header.connected_entity > 0"
                      :content="header.connected_entity"
                      color="primary"
                      inline
                      class="ma-0"
                    >
                      Connected entities
                    </v-badge>
                    <div v-else>Connected entities</div>
                  </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-connection</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="viewAnchorPlan(header.task_id)">
                  <v-list-item-title class="pl-3">
                    Anchor plan
                  </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-calendar-month-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="viewRelevant">
                  <v-list-item-title class="pl-3"> Relevant </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-layers</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item class="mt-4" @click="toggleLinkInsertionStatus">
                  <v-list-item-title class="pl-3">
                    Mark as
                    <v-chip
                      small
                      label
                      :key="tag"
                      class="ml-1 px-2 flat-chip flat-chip--auto"
                      :style="{
                        color: getTagColor('link insertion'),
                      }"
                    >
                      Link insertion
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-icon>
                    <template v-if="loadings.linkInsertion">
                      <v-progress-circular size="16" width="2" indeterminate />
                    </template>
                    <template v-else>
                      <v-icon
                        v-if="getLinkInsertionValue"
                        class="mr-2"
                        color="primary"
                      >
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else class="mr-2"
                        >mdi-checkbox-blank-outline</v-icon
                      >
                    </template>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </template>
          </v-menu>
        </v-list>
      </div>
    </v-col>
    <v-dialog
      v-model="connected_dialog"
      persistent
      scrollable
      content-class="remove-dialog-shadow"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Connected
          <v-spacer />
          <v-btn icon @click="connected_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-data-table
            :headers="connected_entity_items.headers"
            :items="connected_entity_items.items"
          >
            <template #[`item.value`]="{ value }">
              <template v-if="value && typeof value === 'object'">
                <p v-for="(key, idx) in Object.keys(value)" :key="idx">
                  {{ key }} - {{ value[key] }}
                </p>
              </template>
              <template v-else>
                {{ value }}
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_ltt" persistent scrollable max-width="1000px">
      <v-card class="styled-card--default">
        <v-card-title>
          Ltt
          <v-spacer />
          <v-btn icon @click="dialog_ltt = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-data-table :headers="ltt_headers" :items="ltt_items">
            <template #[`item.done_url`]="{ value }">
              <a :href="value" target="_blank">{{ value }}</a>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_relevant"
      persistent
      scrollable
      max-width="1000px"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          Relevant
          <v-spacer />
          <v-btn icon @click="dialog_relevant = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-data-table :headers="relevant_headers" :items="relevant_items">
            <template v-slot:item.domain="{ item }">
              <div style="white-space: nowrap">
                {{ item.domain }}
                <v-btn
                  small
                  link
                  class="px-1"
                  style="min-width: 28px"
                  :to="{ name: 'gpm.view-entity', params: { id: item.id } }"
                  target="_blank"
                >
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_apt" persistent scrollable max-width="1000px">
      <v-card class="styled-card--default">
        <v-card-title>
          Anchor Plan

          <v-spacer />
          <v-btn icon @click="dialog_apt = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-data-table
            :headers="apt_headers"
            :items="apt_items"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure? </template>
      <template #default> {{ agreeDialogMainText }} </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end" style="gap: 0.5rem">
            <v-btn @click="disagree" text class="px-4 text-normal" large
              >Cancel</v-btn
            >
            <v-btn @click="agree" color="success" class="px-4 text-normal" large
              >Yes</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
    <v-dialog
      v-model="pingsHistoryDialogModel"
      content-class="remove-dialog-shadow"
      width="600"
    >
      <v-card class="shadow-e1-bordered" rounded="lg">
        <v-card-title>Pings history</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="pingsHistoryTable.headers"
            :items="pingsHistoryTable.items"
          >
            <template #[`item.created_at`]="{ value }">
              {{ value ? $moment(value).calendar() : "" }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-btn width="160" @click="pingsHistoryDialogModel = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    :value="showProjectIsntActiveDialog"-->
    <v-dialog v-if="false" max-width="360" content-class="my-shadow--e2">
      <v-card dark class="styled-card--default">
        <v-card-title class="py-4 px-6">
          <v-icon small left color="error">mdi-alert</v-icon> Project is not
          active.
          <v-spacer />
          <v-btn icon @click="showProjectIsntActiveDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { RESPONSIBILITIES_COLORS } from "@/utils/selectItems";
import axios from "axios";
import AgreeDialog from "@/components/UI/AgreeDialog";
import AgreeDialogMixin from "@/mixins/AgreeDialogMixin";
import service from "@/plugins/service";

export default {
  name: "Header",
  components: { AgreeDialog },
  mixins: [AgreeDialogMixin],
  props: ["header"],
  data() {
    return {
      showProjectIsntActiveDialog: false,
      loadings: {
        viewConnected: false,
        pingsHistory: false,
        ping: false,
        linkInsertion: false,
      },
      pingsHistoryDialogModel: false,
      pingsHistoryTable: {
        headers: [
          {
            text: "User",
            value: "name",
          },
          {
            text: "Created At",
            value: "created_at",
            align: "end",
          },
        ],
        items: [],
      },
      GP_COLOR: "#0000ff",
      actionsDialogModel: false,
      dialog_ltt: false,
      dialog_apt: false,
      dialog_relevant: false,
      connected_dialog: false,
      apt_headers: [
        { text: "Url", value: "url" },
        { text: "Anchor", value: "anchor" },
        { text: "Donor", value: "donor" },
      ],
      apt_items: [],
      relevant_headers: [
        { text: "Domain", value: "domain" },
        { text: "Theme", value: "theme" },
        { text: "Task", value: "task_name" },
      ],
      relevant_items: [],
      ltt_headers: [
        { text: "Done Url", value: "done_url" },
        { text: "Acceptor", value: "acceptor" },
        { text: "Author", value: "author" },
        { text: "Date", value: "date" },
      ],
      agreeDialogMainText: "You want to go back to the previous step?",
      ltt_items: [],
      connected_entity_headers: [
        { text: "Field", value: "title" },
        { text: "Value", value: "value" },
        { text: "Changed At", value: "created" },
        { text: "User", value: "email" },
      ],
      connected_entity_items: {
        headers: [],
        items: [],
      },
      responsibilities_colors: RESPONSIBILITIES_COLORS,
    };
  },
  mounted() {
    if (!this.header.is_project_active) this.showProjectIsntActiveDialog = true;
  },
  computed: {
    getLinkInsertionValue() {
      return !!this.header.link_insertion;
    },
    getTags() {
      let result = [];

      if (this.header?.donor_tags?.length > 0)
        result = [...result, ...this.header.donor_tags];

      // eslint-disable-next-line no-constant-condition
      if (this.header?.link_insertion) result.push("Link insertion");

      return result;
    },
    isPingedStep() {
      const h = this.header.config;
      return h && Array.isArray(h) && h.includes("ping");
    },
  },
  methods: {
    async toggleLinkInsertionStatus() {
      try {
        this.loadings.linkInsertion = true;
        const valueBeforeChange = !!this.header?.link_insertion;

        const url = `/gpm/entity-view/mark-link-insertion?entityID=${this.$route.params.id}`;

        const response = await service.post(url);

        if (response?.data?.success)
          this.$set(this.header, "link_insertion", !valueBeforeChange);

        console.log(response);
      } catch (e) {
        console.error("Error while toggling link insertion status", e);
      } finally {
        this.loadings.linkInsertion = false;
      }
    },
    async handleClickRemoveTag(headerData, statusData) {
      this.agreeDialogMainText = "Are you sure you want to delete the status?";
      if (!(await this.open())) return;

      this.removeStatusFromDonorById(statusData.id);
    },
    async removeStatusFromDonorById(id) {
      console.log(id);
      try {
        const url = `/gpm/entity-view/remove-bad-status/${this.$route.params.id}/${id}`;

        const resp = await service.delete(url);

        if (resp && resp.data && resp.data.status === 1) {
          this.header.selected_bad_donor_statuses =
            this.header.selected_bad_donor_statuses.filter((v) => v.id !== id);

          this.$message.notification({
            title: "Done",
            text: "Status removed.",
            type: "success",
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    getTagColor(name) {
      const tmprName = String(name).toLowerCase();

      const colors = {
        warning: "rgb(172,65,0)",
        error: "rgb(172,0,0)",
        info: "rgb(0,146,172)",
        success: "rgb(0,172,3)",
        infoDarken: "rgb(0,100,172)",
      };

      if (tmprName === "used") return colors.warning;
      if (tmprName === "hub") return colors.info;
      if (tmprName === "other") return colors.info;
      if (tmprName === "ltt") return colors.success;
      if (tmprName === "removed") return colors.error;
      if (tmprName === "link insertion") return colors.infoDarken;

      return "";
    },
    async handleViewPingsHistory() {
      try {
        const url = "/gpm/entity-view/get-pings?id=" + this.$route.params.id;

        this.loadings.pingsHistory = true;

        const resp = await service.get(url);

        if (resp && resp.data) {
          this.pingsHistoryTable.items = resp.data.items;
          this.pingsHistoryDialogModel = true;
        }

        this.loadings.pingsHistory = false;
      } catch (e) {
        this.loadings.pingsHistory = false;
        console.error(e);
      }
    },
    async pingDonor() {
      try {
        const url = "/gpm/entity-view/ping";
        const payload = {
          id: this.$route.params.id,
        };

        this.loadings.ping = true;

        const resp = await service.post(url, payload);

        this.loadings.ping = false;

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Done",
            text: "Donor successfully pinged",
            type: "success",
          });
        }
      } catch (e) {
        this.loadings.ping = false;
        console.error(e);
      }
    },
    getSelectedStatuses(statuses) {
      if (!statuses || statuses === "undefined" || statuses === "null") {
        return [];
      }
      return statuses.filter((v) => v !== "No PayPal");
    },
    generateUserInitials(email) {
      const nameSurname = email.replaceAll("@boosta.co", "").split(".");
      const nameFirstLetter = nameSurname[0][0];
      const surnameFirstLetter = nameSurname[1][0];
      return `(${nameFirstLetter.toLocaleUpperCase()}${surnameFirstLetter.toLocaleUpperCase()})`;
    },
    getUserInitials(color, headerData) {
      if (color === this.GP_COLOR) {
        return this.generateUserInitials(headerData.performer);
      }
      return this.generateUserInitials(headerData.creator);
    },
    async goBack() {
      this.agreeDialogMainText = "You want to go back to the previous step?";
      if (!(await this.open())) return;

      let post_data = { entities: [this.$route.params.id] };
      const resp = await this.$store.dispatch(
        "reservedDomains/restoreDonors",
        post_data
      );

      if (resp && resp.success) {
        return location.reload();
      }

      this.$message.notification({
        title: "Something wrong",
        text: "Try again later.",
        type: "error",
        duration: 6000,
      });
    },
    rgbToRgba({ r, g, b }, a = 1) {
      return `rgba(${r},${g},${b},${a})`;
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    viewWasConnected() {
      let self = this;

      self.connected_entity_items = {
        headers: [],
        items: [],
      };
      self.connected_dialog = false;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-connected-entity`;

      let config = {
        params: {
          entityID: this.$route.params.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.loadings.viewConnected = true;

      axios
        .get(url, config)
        .then((r) => {
          self.connected_entity_items = r.data.connected_entity;
          self.connected_dialog = true;
        })
        .finally(() => {
          this.loadings.viewConnected = false;
        });
    },
    viewLttLinks() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-ltt-links`;

      let config = {
        params: {
          domain_id: self.header.id_domain,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.ltt_items = r.data.items;
          self.dialog_ltt = true;
        })
        .finally(() => (self.loading = false));
    },
    goToSpiderLink() {
      window.open(
        `http://new.spiderlink.pro/dashboards/link-database-management/processing/?domain=${this.header.id_domain}`,
        "_blank"
      );
    },
    viewAnchorPlan(aptID) {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan`;

      let config = {
        params: {
          anchorPlanTaskID: aptID,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.apt_items = r.data.items;
          self.dialog_apt = true;
        })
        .finally(() => (self.loading = false));
    },
    viewRelevant() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-relevant-entity`;

      let config = {
        params: {
          entityID: this.$route.params.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.relevant_items = r.data.items;
          self.dialog_relevant = true;
        })
        .finally(() => (self.loading = false));
    },
    viewInfo(headerData) {
      const data = headerData;

      let query = `?domain=${data.domain}`;

      if (data.country_id) query += `&country=${data.country_id}`;
      if (data.niche_id) query += `&niche=${data.niche_id}`;
      if (data.spiderlink_task_id)
        query += `&spiderlink_project_id=${data.spiderlink_task_id}`;
      if (data.link_type_id) query += `&link_type=${data.link_type_id}`;

      window.open(`/site-analysis${query}`, "_blank");
    },
  },
};
</script>

<style scoped></style>
