<template>
  <td >
    <div :class="`colored-td ${getColor}`">
      {{ data.pos_by_key }}
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      const value = parseInt(this.data.pos_by_key)

      if(value >= 1 && value <= 3) return 'red'
      if(value > 3 && value <= 5) return 'yellow'
      if(value > 5 && value <= 9) return 'orange'
      return ''
    },
  }
};
</script>
