<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col class="d-flex">
          <div>
            <div><b>SW Category</b></div>
            <div
              v-if="
                !loadings.section.includes(sections.main_static.sectionName)
              "
              style="font-size: 14px"
            >
              <template
                v-if="
                  sectionsData.main_static &&
                  sectionsData.main_static.similar_web_category
                "
              >
                {{ sectionsData.main_static.similar_web_category }}
              </template>
              <template v-else>
                <span style="opacity: 0.5">nothing...</span>
              </template>

              <v-chip v-if="computedInTop25" small color="success"
                >in top 25</v-chip
              >
            </div>
            <div
              v-else
              style="height: 26px; width: 220px"
              class="loading skeleton"
            />
          </div>
        </v-col>
        <v-col class="d-flex">
          <div class="d-flex">
            <div>
              <div>
                <b>TR Category</b>
                <v-icon
                  small
                  color="primary"
                  class="ml-1"
                  @click="handleNavigateToSection('TR Category')"
                >
                  mdi-navigation-variant-outline
                </v-icon>
              </div>
              <div
                v-if="
                  !loadings.section.includes(sections.tr_categories.sectionName)
                "
                style="font-size: 14px"
              >
                <template v-if="computedTrCategory">
                  {{ computedTrCategory }}
                </template>
                <template v-else>
                  <span style="opacity: 0.5">nothing...</span>
                </template>
              </div>
              <div
                v-else
                style="height: 26px; width: 160px"
                class="loading skeleton"
              />
            </div>
          </div>
        </v-col>
        <v-col class="d-flex">
          <div class="d-flex flex-column">
            <div>
              <b>TR Themes</b>
              <v-icon
                small
                color="primary"
                class="ml-1"
                @click="handleNavigateToSection('TR Site Topics')"
              >
                mdi-navigation-variant-outline
              </v-icon>
            </div>
            <div
              v-if="!loadings.section.includes(sections.tr_topics.sectionName)"
              class="overflow-chip"
            >
              <template
                v-if="
                  Array.isArray(computedTrThemes) && computedTrThemes.length > 0
                "
              >
                <v-chip v-for="theme in computedTrThemes" :key="theme" small>
                  <span>
                    {{ theme }}
                  </span>
                </v-chip>
              </template>
              <template v-else>
                <span style="opacity: 0.5">nothing...</span>
              </template>
            </div>
            <div v-else class="d-flex">
              <div
                v-for="i in [0, 1, 2]"
                :key="i"
                class="skeleton loading mr-2"
                style="width: 85px; height: 26px"
              ></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import eventPipe from "../../../events/eventPipe";

export default {
  props: {
    loadings: {
      type: Object,
      default: () => ({}),
    },
    sections: {
      type: Object,
      default: () => ({}),
    },
    sectionsData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    computedTrThemes() {
      try {
        const items =
          (this.sectionsData.tr_topics && this.sectionsData.tr_topics.data) ||
          [];

        if (!items) return "...";

        return items.slice(0, 5).map((v) => v.topic);
      } catch (e) {
        console.error(e);
        return "...";
      }
    },
    computedTrCategory() {
      try {
        return (
          this.sectionsData.tr_categories &&
          this.sectionsData.tr_categories.data &&
          this.sectionsData.tr_categories.data.length > 0 &&
          this.sectionsData.tr_categories.data[0] &&
          this.sectionsData.tr_categories.data[0].category
        );
      } catch (e) {
        console.error(e);
        return "...";
      }
    },

    computedInTop25() {
      return (
        this.sectionsData &&
        this.sectionsData.main_static &&
        this.sectionsData.main_static.similar_web_category !== null &&
        this.sectionsData.main_static.sw_category_rank !== null &&
        this.sectionsData.main_static.sw_category_rank > 0 &&
        this.sectionsData.main_static.sw_category_rank <= 25
      );
    },
  },
  methods: {
    handleNavigateToSection(sectionName) {
      eventPipe.$emit("site-analytics-navigate-to", sectionName || "start");
    },
  },
};
</script>
