<template>
  <td>
    <div v-if="getContent" :class="`colored-td colored-td--clickable ${getData.expired ? 'red' : ''}`">
      <span @click="goTo" class="nowrap">
        {{ getContent }}
        <sup v-if="getRewrite">
          {{ getRewrite }}
        </sup>
      </span>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getRewrite() {
      return this.data.tech_task_appear
        ? this.data.tech_task_appear.rewrite
        : null;
    },
    getContent() {
      return this.data.tech_task_appear.text
        ? this.$moment(this.data.tech_task_appear.text).format("DD/MM")
        : null;
    },
    getData() {
      return this.data.tech_task_appear ? this.data.tech_task_appear : {};
    },
  },
  methods: {
    goTo() {
      const url = this.data.tech_task_appear.link;
      if (!url) {
        return this.$message.notification({
          title: "Error",
          text: "Link not found",
          type: "error",
          duration: 4000,
        });
      }
      window.open(url, "_blank");
    },
  },
};
</script>
