<template>
  <v-card :loading="loading">
    <v-card-title>By Top 10</v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        solo
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1 v-data-table-small"
      :sort-by="['volume', 'cl', 'imp']"
      :sort-desc="[true, true, true]"
      :items-per-page="15"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.phrase">
            <td>{{ item.phrase }}</td>
            <td class="text-center">{{ item.volume }}</td>
            <td class="text-center">{{ item.cl }}</td>
            <td class="text-center">{{ item.imp }}</td>
            <td>
              text
              <v-divider></v-divider>
              text, %
              <v-divider></v-divider>
              text, size
              <v-divider></v-divider>
              title
              <v-divider></v-divider>
              desc
              <v-divider></v-divider>
              h1
            </td>
            <td class="text-center">
              {{ item.our.entry }}
              <v-divider></v-divider>
              {{ item.our.percent }}%
              <v-divider></v-divider>
              <span v-html="item.our.size"></span>
              <v-divider></v-divider>
              <span v-html="item.our.title"></span>
              <v-divider></v-divider>
              <span v-html="item.our.description"></span>
              <v-divider></v-divider>
              <span v-html="item.our.h1"></span>
            </td>
            <td class="text-center">
              {{ item.med.entry }}
              <v-divider></v-divider>
              {{ item.med.percent }}%
              <v-divider></v-divider>
              {{ item.med.size }}
              <v-divider></v-divider>
              {{ item.med.title }}
              <v-divider></v-divider>
              {{ item.med.description }}
              <v-divider></v-divider>
              {{ item.med.h1 }}
            </td>
            <td class="text-center">{{ item.pos }}</td>
            <td
              v-for="(v, k) in item.child"
              v-bind:key="k"
              :class="{
                'text-center': true,
                'light-green lighten-4': v.url === selectedUrl,
              }"
            >
              <a :href="v.url" target="_blank">{{ v.entry }}</a>
              <v-divider></v-divider>
              {{ v.percent }}%
              <v-divider></v-divider>
              <span v-html="v.size"></span>
              <v-divider></v-divider>
              <span v-html="v.title"></span>
              <v-divider></v-divider>
              <span v-html="v.description"></span>
              <v-divider></v-divider>
              <span v-html="v.h1"></span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import events from "../../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../../mixins/Common";

export default {
  name: "Top10",
  data() {
    return {
      loading: false,
      search: "",
      headers: [],
      items: [],
      childData: [],
      medianLength: 0,
    };
  },
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  methods: {
    resetData() {
      this.items = [];
      this.childData = [];
      this.medianLength = 0;
      this.headers = [];

      this.$store.state.OnePageAnalyzer.top10_median = {};
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/text`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedUrl,
          type: "top10",
          filter: self.filterData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let post_data = {
        vision: self.vision,
        sum_visibility: self.sumVisibility,
        projectID: self.idProject,
        url: self.selectedUrl,
      };

      axios
        .post(url, post_data, config)
        .then((r) => {
          self.items = r.data.items;
          self.childData = r.data.childData;
          self.medianLength = r.data.medianLength;
          self.headers = r.data.headers.map((v) => {
            if (v.type !== undefined) {
              if (v.type === "float") {
                v.sort = function (a, b) {
                  a = parseFloat(a);
                  b = parseFloat(b);

                  if (a === b) {
                    return 0;
                  }

                  return a > b ? 1 : -1;
                };
              }
            }

            return v;
          });

          let median = {};

          self.items.forEach(function (item) {
            median[item.phrase] = item.med;
          });

          self.$store.state.OnePageAnalyzer.top10_median = median;
        })
        .finally(() => (self.loading = false));
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
    });

    events.$on("vision-fetched", function () {
      self.fetchData();
    });
  },
};
</script>

<style scoped></style>
