<template>
  <v-dialog :retain-focus="false" v-model="inputVal" max-width="1100px">
    <v-container>
      <v-card>
        <template>
          <div v-if="alert.shown">
            <v-alert
              v-for="message in alert.messages"
              :key="message"
              outlined
              :type="alert.type"
              prominent
              border="left"
              dismissible
            >
              {{ message }}
            </v-alert>
          </div>
        </template>

        <v-card-title>
          <span class="headline"
            >&#11088;&#11088;&#11088;&#11088;&#11088; New Donors
            &#11088;&#11088;&#11088;&#11088;&#11088;</span
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-card elevation="4">
                <v-card-title> Import from text </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      1. Put the columns to import in the order in which you
                      make the data</v-col
                    >
                    <v-col cols="12">
                      <v-text-field
                        v-model="text.headers"
                        solo
                        dense
                        :placeholder="'address;login;password'"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">2. Insert data (without headers)</v-col>

                    <v-col cols="12">
                      <v-textarea
                        v-model="text.rows"
                        solo
                        :placeholder="'address;login;password\n'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div class="text-center">
                    <v-btn
                      class="text-center align-center justify-center center"
                      color="success"
                      dark
                      rounded
                      @click="upload_data(1)"
                      >Upload
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card elevation="4">
                <v-card-title>
                  Import from file ->
                  <a
                    href="/file/AnchorsPlanExample.csv"
                    target="_blank"
                    style="font-size: 15px"
                  >
                    Download Example</a
                  >
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        v-model="file.names"
                        color="deep-purple accent-4"
                        counter
                        label="File input"
                        multiple
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        accept=".csv"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ file.names.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <div class="text-center">
                    <v-btn
                      class="text-center align-center justify-center center"
                      color="success"
                      dark
                      rounded
                      @click="upload_data(2)"
                      >Upload
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>

              <v-row>
                <v-col cols="12">
                  <v-card elevation="4">
                    <v-card-title> Allowed columns </v-card-title>
                    <v-card-text>
                      <p><b>R</b> - required</p>
                      <p><b>address [R] </b> - https://www.ionos.fr/</p>
                      <p><b>login [R] </b> - wordcamp</p>
                      <p><b>password [R] </b> - sablette2016</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadNewDonors",
  props: ["value"],
  data() {
    return {
      alert: {
        data: [],
        shown: false,
        type: "",
      },
      file: { names: [] },
      text: {
        headers: "address;login;password",
        row: "",
      },
      level: 0,
      domains: [],
    };
  },
  created() {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    userId() {
      return this.$store.state.auth.user.id;
    },
  },
  methods: {
    upload_data(type) {
      let self = this;

      self.alert.messages = [];
      self.alert.type = "";
      self.alert.shown = false;

      let url = `${self.$store.state.server_url}/donor-base/x/upload/${self.id_project}`;
      let data = {};
      if (type === 1) {
        data = {
          type: type,
          headers: self.text.headers,
          data: self.text.rows,
          level: self.level,
          project: self.project,
        };
      } else {
        data = new FormData();
        data.append("file", self.file.names[0]);
        data.append("type", type);
        data.append("level", self.level);
        data.append("project", self.project);
      }

      let config = {
        headers: {
          "Content-Type":
            type === 1 ? "application/json" : "multipart/form-data",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, data, config)
        .then(function (response) {
          self.alert.messages = response.data.messages;
          self.alert.type = response.data.type;
          self.alert.shown = true;
          self.file.names = [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped></style>
