export default {
  methods: {
    getRatingColor(ratingNumber, forceColor) {
      try {
        if(forceColor) return forceColor

        if (ratingNumber >= -10 && ratingNumber <= -4) return "error";
        if (ratingNumber > -4 && ratingNumber <= 4) return "warning";
        if (ratingNumber > 4 && ratingNumber <= 10) return "success";
        return "";
      } catch (e) {
        console.error("Error while calculating rating color.", e);
      }
    },
  },
};
