import service from "../../plugins/service";

class GpmApiService {
  endpoints = {
    getPrices: `/donor-base/clean/guest-post/0/get-domain-prices`,
  };

  getDomainPrices(domainId) {
    return service.post(this.endpoints.getPrices, {
      domain: domainId,
    });
  }
}

export default GpmApiService;
