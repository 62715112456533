<template>
  <div>
    <h1 class="text-center">Projects Dashboard</h1>
    <div>
      <v-banner>
        <template v-slot:actions>
          <v-autocomplete
            v-model="filter.seo"
            style="max-width: 300px"
            label="Seo"
            solo
            item-text="name"
            item-value="id"
            :items="items_seo"
            @change="handleChangeSeo"
            hide-details
            dense
            clearable
          />
          <v-autocomplete
            v-model="filter.project"
            style="max-width: 300px"
            label="Project"
            solo
            multiple
            placeholder="Select a project"
            item-text="name"
            item-value="id"
            :items="projects"
            @change="_$handleFilterChange($event, 'project')"
            hide-details
            dense
            :loading="loadings.filter"
            clearable
          />
        </template>
      </v-banner>
    </div>
    <div v-for="itemProject in projects" :key="itemProject.id">
      <template v-if="filter.project.includes(itemProject.id)">
        <div v-for="subproject in itemProject.subprojects" :key="subproject.id">
          <div v-if="subproject.priority >= 0">
            <br />
            <h2>{{ itemProject.name }} {{ subproject.name }}</h2>
            <v-row>
              <v-col md="9">
                <template>
                  <Links
                    :filter="filter"
                    :id="itemProject.id"
                    :subproject_id="subproject.id"
                  />
                </template>
              </v-col>
              <v-col md="3">
                <template>
                  <AnchorPlanChart
                    :filter="filter"
                    :id="itemProject.id"
                    :subproject_id="subproject.id"
                  />
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <Table
                  :filter="filter"
                  :id="itemProject.id"
                  :subproject_id="subproject.id"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <Worksection
                  :filter="filter"
                  :id="itemProject.id"
                  :subproject_id="subproject.id"
                  :worksection_id="subproject.worksection_id"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="filter.project.length === 0"
      class="d-flex justify-center align-center"
      style="min-height: 200px; font-size: 22px; opacity: 0.6"
    >
      No project selected <v-icon class="ml-4">mdi-arrow-up-right</v-icon>
    </div>
  </div>
</template>

<script>
import Links from "./Children/Links";
import AnchorPlanChart from "./Children/AnchorPlanChart";
import Table from "./Children/Table";
import Worksection from "./Children/Worksection";
import axios from "axios";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Location from "@/mixins/Location";

export default {
  name: "LinkDynamicsDomainIndex",
  components: { Links, AnchorPlanChart, Table, Worksection },
  mixins: [FiltersHandling, Location],
  data() {
    return {
      items_seo: [],
      projects: [],
      loadings: {
        filter: false,
      },
      filter: {
        seo: null,
        project: [],
      },
    };
  },
  computed: {
    projectName() {
      return this.$store.state.project.domain;
    },
    getProjects() {
      return this.$store.state.project.allowed_projects;
    },
  },
  methods: {
    handleChangeSeo(event) {
      this._$handleFilterChange(event, "seo");
      this.updateFilter();
    },
    async fetchData() {
      this.loadings.filter = true;

      let url = `${this.$store.state.server_url}/ltt/types-dynamics/json`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        type: "seo",
      };

      await axios
        .post(url, data, config)
        .then((r) => {
          this.items_seo = r.data.data;

          this.updateFilter();
        })
        .finally(() => {
          this.loadings.filter = false;
        });

      this.loadings.filter = false;
    },
    async updateFilter() {
      this.loadings.filter = true;

      this.projects = [];

      await setTimeout(() => {
        if (this.filter.seo !== null) {
          let projects = [];
          let subprojects;
          this.getProjects.forEach((project) => {
            subprojects = [];
            project.subprojects.forEach((subproject) => {
              if (subproject.responsible_user_id === this.filter.seo) {
                subprojects.push(subproject);
              }
            });

            if (project.seo === this.filter.seo || subprojects.length > 0) {
              if (project.seo !== this.filter.seo) {
                project.subprojects = subprojects;
              }
              projects.push(project);
            }
          });

          this.projects = projects;
          this.loadings.filter = false;
        } else {
          this.projects = this.getProjects;
          this.loadings.filter = false;
        }
      }, 1000);
    },
  },
  created() {
    this._$collectParams(["project"], "filter");
    this.fetchData();
  },
};
</script>

<style scoped></style>
