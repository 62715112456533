<template>

  <section>
    <v-row dense>
      <v-col cols="12">
        <v-skeleton-loader
            type="heading"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-for="(item, i) in Array(4)" :key="i" lg="3" md="3" sm="6" xs="6" cols="3">
        <v-skeleton-loader
            type="button"
            class="dashboard-dev__ltt-cards-skeleton"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <chart-skeleton/>
      </v-col>
      <v-col cols="6">
        <chart-skeleton/>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import ChartSkeleton from "@/assets/svg/chart-skeleton";

export default {
  components: {ChartSkeleton}
}
</script>