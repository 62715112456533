<template>
  <div :class="`one-year-report ${$vuetify.theme.dark ? 'dark-mode' : ''}`">
    <section class="section__page-name mb-6">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between">
          <h2>One Year Report</h2>
          <a @click="copyLink">Copy url</a>
        </v-col>
      </v-row>
    </section>
    <section class="mb-6">
      <v-row dense>
        <v-col>
          <v-autocomplete
            v-model="filters.sub_project"
            :items="filters_data.sub_project_items"
            outlined
            dense
            :loading="loadings.filters"
            item-text="text"
            item-value="value"
            clearable
            label="Sub-project"
            hide-details
            @change="_$handleFilterChange($event, 'sub_project')"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="filters.page_type"
            :items="filters_data.page_type_items"
            outlined
            dense
            :loading="loadings.filters"
            item-text="text"
            item-value="value"
            clearable
            label="Page type"
            hide-details
            @change="_$handleFilterChange($event, 'page_type')"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <smart-date-filter
            label="Date: Compare Positions"
            :model-value="filters.date"
            @update:modelValue="handleChangeDate"
          ></smart-date-filter>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-switch
            v-model="filters.all_url"
            hide-details
            dense
            class="mt-0"
            label="All Url's"
            @change="_$handleFilterChange($event, 'all_url')"
          ></v-switch>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div v-on="on">
                <v-switch
                  v-model="filters.view_links"
                  hide-details
                  dense
                  class="mt-0"
                  label="View links"
                  @change="_$handleFilterChange($event, 'view_links')"
                ></v-switch>
              </div>
            </template>
            <div>Need to update dashboard. Press "GET DATA"</div>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-switch
            v-model="filters.view_conversion"
            hide-details
            dense
            class="mt-0"
            label="View Conv."
            @change="_$handleFilterChange($event, 'view_conversion')"
          >
          </v-switch>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-switch
            v-model="filters.top_tables"
            hide-details
            dense
            class="mt-0"
            label="Top tables"
            @change="_$handleFilterChange($event, 'top_tables')"
          ></v-switch>
        </v-col>
        <v-col>
          <v-btn
            color="primary"
            @click="apply_filters"
            :loading="loadings.tables"
            block
            class="text-normal"
          >
            Get Data
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <easy-range-input
            label="Current Position"
            :model-value="filters.current_position"
            @update:modelValue="filters.current_position = $event"
          />
        </v-col>
        <v-col>
          <easy-range-input
            label="GSC 90d clicks"
            :model-value="filters.gsc_clicks_90d"
            @update:modelValue="filters.gsc_clicks_90d = $event"
          />
        </v-col>
        <v-col>
          <easy-range-input
            label="GSC 90d impression"
            :model-value="filters.gsc_impression_90d"
            @update:modelValue="filters.gsc_impression_90d = $event"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="filters.view_phrases"
            hide-details
            dense
            outlined
            label="View Prases"
            placeholder="Amount"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col></v-col>
        <v-col>
          <v-checkbox
            v-model="filters.is_disavow"
            hide-details
            dense
            class="ma-0"
            label="Disavow"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="filters.is_on_page"
            hide-details
            dense
            class="ma-0"
            label="onPage"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="filters.is_index"
            hide-details
            dense
            class="ma-0"
            label="Index"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <smart-date-filter
            :model-value="filters.cfproc"
            @update:modelValue="filters.cfproc = $event"
            label="CFProc"
          />
        </v-col>
        <v-col>
          <smart-date-filter
            :model-value="filters.first_seen"
            @update:modelValue="filters.first_seen = $event"
            label="First seen"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="filters.take_urls"
            type="Number"
            label="Amount pages to review"
            placeholder="Enter amount"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="filters.redirect_project"
            :items="filtersData.redirect_projects"
            :loading="loadings.filters"
            hide-details
            dense
            outlined
            label="Redirect project"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
        <v-col></v-col>
      </v-row>
    </section>
    <v-row v-if="filters.top_tables">
      <v-col v-if="positionTableItems?.length" cols="12">
        <one-year-report-positions-table
          :data="positionTableItems"
          :loading="loadings.positionTable"
        />
      </v-col>
      <v-col v-if="convDynamicTableItems?.length" cols="12">
        <one-year-report-conversion-dynamics
          :data="convDynamicTableItems"
          :loading="loadings.convDynamic"
          title="Conversion Dynamics"
        />
      </v-col>
      <v-col v-if="convByPageTypeData?.length" cols="12">
        <conversion-by-page-type-table
          :data="convByPageTypeData.items"
          title="Conversion By Page Type"
          :headers="convByPageTypeData.headers"
          :loading="loadings.convByPageType"
        />
      </v-col>
      <v-col v-if="linksByProjectTableItems?.length" cols="12">
        <links-by-project-table
          :data="linksByProjectTableItems"
          :loading="loadings.linksByProject"
          title="Links by Project"
        />
      </v-col>
      <v-col v-if="contentTableItems?.length" cols="12">
        <links-by-project-table
          :data="contentTableItems"
          :loading="loadings.contentTable"
          title="Content"
        />
      </v-col>
    </v-row>
    <template v-if="report_tables && report_tables.length">
      <v-row dense class="mt-12">
        <v-col cols="12">
          <v-text-field
            v-model="globalTableSearch"
            label="Search by URL"
            dense
            outlined
            clearable
            hide-details
            append-icon="mdi-magnify"
            style="max-width: 320px"
          ></v-text-field>
        </v-col>
      </v-row>

      <section class="sections__item mt-2">
        <v-row>
          <v-col cols="12">
            <div class="report-tables__wrapper">
              <template v-if="loadings.tables">
                <v-skeleton-loader
                  :loading="loadings.tables"
                  height="400px"
                  class="mx-auto"
                  type="table-row-divider@8"
                ></v-skeleton-loader>
              </template>
              <template v-else>
                <template v-if="report_tables && report_tables.length > 0">
                  <v-card
                    v-for="table in report_tables"
                    :key="table.id"
                    :class="`styled-card--light report-tables__table ${
                      globalTableSearch &&
                      !table.url.path.includes(globalTableSearch)
                        ? 'display-none'
                        : ''
                    }`"
                  >
                    <v-card-title>
                      <h2>{{ table.url.path }}</h2>
                      <a
                        :href="table.url.name"
                        target="_blank"
                        class="reset-link-styles mx-2"
                      >
                        <v-btn icon>
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </a>

                      <router-link
                        target="_blank"
                        :to="{
                          name: 'one-page-analyzer-show',
                          query: { url: table.url.name },
                        }"
                        class="reset-link-styles"
                        title="One Page Analyzer"
                      >
                        <v-chip
                          @click="() => {}"
                          label
                          class="px-1"
                          small
                          outlined
                        >
                          OPA
                          <v-icon class="ml-1" x-small>mdi-open-in-new</v-icon>
                        </v-chip>
                      </router-link>
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'links-dynamic-data',
                          query: {
                            do_follow: 0,
                            ah_traffic: '100,1000000000',
                            project_id: $store.state.project.active_project,
                            url: table.url.id,
                          },
                        }"
                        class="reset-link-styles mx-2"
                        title="Links Dynamic Dashboard"
                      >
                        <v-chip
                          @click="() => {}"
                          label
                          class="px-1"
                          small
                          outlined
                        >
                          LLD
                          <v-icon class="ml-1" x-small>mdi-open-in-new</v-icon>
                        </v-chip>
                      </router-link>
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'site-workplace.dashboard',
                          query: {
                            url: table.url.path,
                          },
                        }"
                        class="reset-link-styles"
                        title="Site Workplace"
                      >
                        <v-chip
                          @click="() => {}"
                          label
                          class="px-1"
                          small
                          outlined
                        >
                          SW
                          <v-icon class="ml-1" x-small>mdi-open-in-new</v-icon>
                        </v-chip>
                      </router-link>
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'search-console.url',
                          params: {
                            id: table.url.hash,
                          },
                        }"
                        class="reset-link-styles ml-2"
                        title="Search Console"
                      >
                        <v-chip
                          @click="() => {}"
                          label
                          class="px-1"
                          small
                          outlined
                        >
                          SC
                          <v-icon class="ml-1" x-small>mdi-open-in-new</v-icon>
                        </v-chip>
                      </router-link>
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'tdh1-dashboard',
                          query: {
                            url: table.url.id,
                          },
                        }"
                        class="reset-link-styles ml-2"
                        title="Search Console"
                      >
                        <v-chip
                          @click="() => {}"
                          label
                          class="px-1"
                          small
                          outlined
                        >
                          TDH1
                          <v-icon class="ml-1" x-small>mdi-open-in-new</v-icon>
                        </v-chip>
                      </router-link>
                    </v-card-title>
                    <v-card-subtitle
                      class="d-flex mt-1 align-center"
                      style="font-size: 18px"
                    >
                      <oyr-table-subheader :data="table" />
                    </v-card-subtitle>
                    <v-divider />
                    <v-card-text class="pa-0">
                      <v-data-table
                        :sort-by="[
                          'priority',
                          'clicks',
                          'impressions',
                          'volume',
                        ]"
                        :sort-desc="[false, true, true, true]"
                        :headers="report_table_headers"
                        :items="table.items"
                        :hide-default-footer="true"
                        :items-per-page="-1"
                        multi-sort
                        :custom-sort="customTableSort"
                        class="oyr-table"
                      >
                        <template #[`item.phrase`]="{ item }">
                          <div
                            :class="{
                              completed:
                                item.t <= table.url.need_top_from_url_control,
                            }"
                          >
                            {{ item.phrase }}
                            <div class="mark" />
                          </div>
                        </template>
                        <template #[`item.priority`]="{ item }">
                          {{ item.priority }}
                        </template>
                        <template #[`item.comp`]="{ item }">
                          <comp-td :data="item.comp" />
                        </template>
                        <template #[`item.t`]="{ item }">
                          <report-tab-td :value="item.t" />
                        </template>
                        <template #[`item.y`]="{ item }">
                          <report-tab-td :value="item.y" />
                        </template>
                        <template #[`item._1w`]="{ item }">
                          <report-tab-td :value="item._1w" />
                        </template>
                        <template #[`item._2w`]="{ item }">
                          <report-tab-td :value="item._2w" />
                        </template>
                        <template #[`item._3w`]="{ item }">
                          <report-tab-td :value="item._3w" />
                        </template>
                        <template #[`item._1m`]="{ item }">
                          <report-tab-td :value="item._1m" />
                        </template>
                        <template #[`item._2m`]="{ item }">
                          <report-tab-td :value="item._2m" />
                        </template>
                        <template #[`item._3m`]="{ item }">
                          <report-tab-td :value="item._3m" />
                        </template>
                        <template #[`item._6m`]="{ item }">
                          <report-tab-td :value="item._6m" />
                        </template>
                        <template #[`item._9m`]="{ item }">
                          <report-tab-td :value="item._9m" />
                        </template>
                        <template #[`item._12m`]="{ item }">
                          <report-tab-td :value="item._12m" />
                        </template>
                        <template #[`item._18m`]="{ item }">
                          <report-tab-td :value="item._18m" />
                        </template>
                        <template #[`item._24m`]="{ item }">
                          <report-tab-td :value="item._24m" />
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <template v-if="table.links.length > 0">
                      <v-divider />
                      <v-card-text class="px-0">
                        <v-data-table
                          dense
                          :headers="links_table_headers"
                          :items="table.links"
                          class="one-year-report__links-table"
                          :items-per-page="-1"
                          hide-default-footer
                        >
                          <template
                            v-for="h in links_table_headers"
                            #[`header.${h.value}`]
                          >
                            <template v-if="h.title">
                              <v-tooltip bottom :key="h.value">
                                <template v-slot:activator="{ on }">
                                  <div
                                    v-on="on"
                                    style="white-space: nowrap"
                                    class="d-flex align-center"
                                  >
                                    <span>{{ h.text }}</span>
                                    <v-icon
                                      small
                                      color="rgba(0,0,0,0.3)"
                                      class="ml-1"
                                    >
                                      mdi-information-outline
                                    </v-icon>
                                  </div>
                                </template>
                                <div style="max-width: 150px">
                                  {{ h.title }}
                                </div>
                              </v-tooltip>
                            </template>
                            <template v-else>
                              <span :key="h.value">{{ h.text }}</span>
                            </template>
                          </template>
                          <template #body="{ items }">
                            <tbody>
                              <tr v-for="(row, index) in items" :key="index">
                                <td>{{ row.name }}</td>
                                <td>{{ row.pc }}</td>
                                <td>{{ row.pn }}</td>
                                <td>{{ row.ap }}</td>
                                <td class="text-center">
                                  {{ HideZero(row.ca) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.b) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.ga) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.du) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.o) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.a) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.dt) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d1w) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d2w) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d3w) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d1m) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d2m) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d3m) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d6m) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d9m) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d12m) }}
                                </td>
                                <td class="text-center">
                                  {{ HideZero(row.d18m) }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </template>

                    <template v-if="table.conversion.length > 0">
                      <v-divider />
                      <one-year-report-conversion-dynamics
                        :data="table.conversion"
                        :dense="true"
                      />
                    </template>
                  </v-card>
                </template>
                <template v-else>
                  <div class="empty-placeholder">No data</div>
                </template>
              </template>
            </div>
          </v-col>
        </v-row>
      </section>
    </template>
  </div>
</template>

<script>
import {
  O_Y_MOCK,
  ONE_YEAR_DATA_TABLE_HEADERS,
  ONE_YEAR_LINKS_TABLE_HEADERS,
  ONE_YEAR_REPORT_POSITIONS_TABLE_HEADERS,
  PROJECT_ID_IDTF,
  SUBPROJECT_IDTF,
} from "@/utils/defaultData";
import ReportTabTd from "@/components/OneYearReport/ReportTabTd";
import Location from "@/mixins/Location";
import Project from "@/mixins/Project";
import CompTd from "@/components/OneYearReport/CompTd";
import ConversionByPageTypeTable from "@/components/OneYearReport/ConversionByPageTypeTable";
import OyrTableSubheader from "@/components/OneYearReport/OyrTableSubheader";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import EasyRangeInput from "@/components/UI/EasyRangeInput.vue";
import copy from "copy-to-clipboard";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import OneYearReportPositionsTable from "./OneYearReportPositionsTable.vue";
import OneYearReportConversionDynamics from "./OneYearReportConversionDynamics.vue";
import LinksByProjectTable from "./LinksByProjectTable.vue";

export default {
  name: "OneYearReport",
  components: {
    SmartDateFilter,
    EasyRangeInput,
    OyrTableSubheader,
    LinksByProjectTable,
    ConversionByPageTypeTable,
    OneYearReportConversionDynamics,
    OneYearReportPositionsTable,
    CompTd,
    ReportTabTd,
  },
  mixins: [Location, Project, FiltersHandling],
  data: () => ({
    isFiltersInitializedOnce: false,
    globalTableSearch: "",
    filters_data: {},
    mock: O_Y_MOCK,
    filters: {
      redirect_project: null,
      current_position: [null, null],
      gsc_clicks_90d: [null, null],
      gsc_impression_90d: [null, null],
      first_seen: [null, null],
      cfproc: [null, null],
      date: [],
      view_phrases: 15,
      page_type: [],
      sub_project: null,
      all_url: false,
      view_links: false,
      view_conversion: false,
      top_tables: true,
      project_id: null,
      take_urls: 50,
      is_disavow: false,
      is_on_page: false,
      is_index: false,
    },
    positionTableItems: [],
    convDynamicTableItems: [],
    convByPageTypeTableItems: [],
    linksByProjectTableItems: [],
    contentTableItems: [],
    report_tables: [],
    convByPageTypeData: {
      items: [],
      headers: ONE_YEAR_REPORT_POSITIONS_TABLE_HEADERS,
    },
    report_table_headers: ONE_YEAR_DATA_TABLE_HEADERS,
    links_table_headers: ONE_YEAR_LINKS_TABLE_HEADERS,
    filtersData: {
      redirect_projects: [],
    },
    loadings: {
      filters: false,
      tables: false,
      positionTable: false,
      convDynamic: false,
      convByPageType: false,
      linksByProject: false,
      contentTable: false,
    },
    filters_menu: false,
    topTablesAlreadyUploaded: false,
  }),
  mounted() {
    if (!this.computedProjectId) {
      this.$store.dispatch("toggle_select_project_menu");
    }

    this._$collectParams(["date", "first_seen"], "filters");

    if (!this.$route.query.date) this.setDefaultDate();

    const project_id = this.$route.query[PROJECT_ID_IDTF];
    if (project_id) {
      this._$setActiveProject(project_id);
    }

    if (this.computedProjectId) {
      this.fetch_filter_data();
      this.fetchFilters();
      this.update_tables_data();
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(value) {
        this._$handleFiltersChange(value);
      },
    },
    computedProjectId() {
      this.initializeFilters();
    },
    "filters.top_tables"(value) {
      if (this.topTablesAlreadyUploaded) return;
      if (value) this.fetchTopTables();
    },
  },
  computed: {
    computedProjectId() {
      return (
        this.filters.project_id || this.$store.state.project.active_project
      );
    },
  },
  methods: {
    async initializeFilters() {
      if (this.isFiltersInitializedOnce) return;

      console.log("test", this.computedProjectId);

      this.fetch_filter_data();
      this.fetchFilters();

      this.isFiltersInitializedOnce = true;
    },
    async fetchFilters() {
      try {
        const filters = ["redirect_projects"];
        const payload = {
          type: "/one-year-report",
          take: filters,
          filter: {
            projectID: this.computedProjectId,
          },
        };

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = resp;
      } catch (e) {
        console.error(e);
      }
    },

    customTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          const string =
            typeof a[sortBy[i]] === "string" ||
            typeof b[sortBy[i]] === "string";

          if (string) {
            let aVal, bVal;
            1;
            if (a[sortBy[i]] === null || a[sortBy[i]] === "" || !a[sortBy[i]])
              aVal = 0;
            if (b[sortBy[i]] === null || b[sortBy[i]] === "" || !b[sortBy[i]])
              bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(Number(a[sortBy[i]])) || isNaN(parseInt(a[sortBy[i]])))
            aVal = 0;
          if (isNaN(Number(b[sortBy[i]])) || isNaN(parseInt(b[sortBy[i]])))
            bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (Number(a[sortBy[i]]) > Number(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (Number(a[sortBy[i]]) < Number(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    copyLink() {
      const urlParams = new URLSearchParams(window.location.search);

      this.$message.notification({
        title: "Link copied",
        text: "The link copied to the clipboard",
        duration: 3000,
      });

      if (urlParams.has("project_id")) {
        const string = window.location.href + this._$buildQuery(this.filters);
        copy(string);
      }

      const string =
        window.location.href +
        this._$buildQuery({
          ...this.filters,
          project_id: this.$store.getters.active_project,
        });

      copy(string);
    },
    setDefaultDate() {
      const defaultDate = [];

      defaultDate[0] = this.$moment()
        .subtract(7, "day")
        .startOf("day")
        .format("YYYY-MM-DD");

      defaultDate[1] = this.$moment().format("YYYY-MM-DD");

      this.filters.date = defaultDate;
    },
    async fetchPositionsTableData() {
      if (!this.computedProjectId) return;

      try {
        this.loadings.positionTable = true;

        const payload = {
          filter: this.filters,
          project: this.computedProjectId,
        };

        const resp = await this.$store.dispatch(
          "one_year_report/get_positions_table_Data",
          payload
        );

        if (resp) this.positionTableItems = resp.items;
      } catch (e) {
        console.error("Error while loading position table data.", e);
      } finally {
        this.loadings.positionTable = false;
      }
    },
    async fetchConversionDynamicTable() {
      if (!this.computedProjectId) return;

      this.loadings.convDynamic = true;

      try {
        const payload = {
          filter: this.filters,
          project:
            this.filters.project_id || this.$store.state.project.active_project,
        };

        const resp = await this.$store.dispatch(
          "one_year_report/get_conv_dynamic_table_Data",
          payload
        );

        if (resp) this.convDynamicTableItems = resp.items;
      } catch (error) {
        console.error(
          "Error while loading conversion dynamic data table.",
          error
        );
      } finally {
        this.loadings.convDynamic = false;
      }
    },
    async fetchConversionByPageTypeTable() {
      if (!this.computedProjectId) return;

      this.loadings.convByPageType = true;

      try {
        const payload = {
          filter: this.filters,
          project:
            this.filters.project_id || this.$store.state.project.active_project,
        };

        const resp = await this.$store.dispatch(
          "one_year_report/get_conv_by_page_type_table_Data",
          payload
        );

        if (resp) this.convByPageTypeData = resp;
      } catch (error) {
        console.error(
          "Error while loading conversion by page data table.",
          error
        );
      } finally {
        this.loadings.convByPageType = false;
      }
    },
    async fetchLinksByProjectTable() {
      if (!this.computedProjectId) return;

      this.loadings.linksByProject = true;

      try {
        const payload = {
          filter: this.filters,
          project:
            this.filters.project_id || this.$store.state.project.active_project,
        };

        const resp = await this.$store.dispatch(
          "one_year_report/get_link_by_project_table_Data",
          payload
        );

        if (resp) this.linksByProjectTableItems = resp.items;
      } catch (error) {
        console.error(
          "Error while loading links by project data table.",
          error
        );
      } finally {
        this.loadings.linksByProject = false;
      }
    },
    async fetchContentTable() {
      if (!this.computedProjectId) return;

      this.loadings.contentTable = true;

      try {
        const payload = {
          filter: this.filters,
          project:
            this.filters.project_id || this.$store.state.project.active_project,
        };

        const resp = await this.$store.dispatch(
          "one_year_report/get_content_table_Data",
          payload
        );

        if (resp) this.contentTableItems = resp.items;
      } catch (error) {
        console.error("Error while loading content data table.", error);
      } finally {
        this.loadings.contentTable = false;
      }
    },
    HideZero(data) {
      if (data === 0) return "";
      return data;
    },
    handleChangeDate(event) {
      this._$handleFilterChange(event, "date");
      this.filters.date = event;
    },
    async apply_filters() {
      this.filters_menu = false;
      this._$setQueryParams(SUBPROJECT_IDTF, this.filters.sub_project);
      await this.update_tables_data();
    },
    sort_tables(data = []) {
      return data.sort((a, b) => {
        let {
          sub_project: a1,
          name: f1,
          need_top_from_url_control: needTop1,
          priority_pages: priority1,
          sum_gsc_cl_by_27_days: clicks1,
          sum_gsc_imp_by_28_days: impress1,
        } = a.url;
        let {
          sub_project: a2,
          name: f2,
          need_top_from_url_control: needTop2,
          priority_pages: priority2,
          sum_gsc_cl_by_27_days: clicks2,
          sum_gsc_imp_by_28_days: impress2,
        } = b.url;

        // small to big\
        if (a1 > a2) return -1;
        if (a1 < a2) return 1;

        if (priority1 === "" || priority1 === null) priority1 = "none";
        if (priority2 === "" || priority2 === null) priority2 = "none";

        if (priority1 > priority2) return 1;
        if (priority1 < priority2) return -1;

        if (needTop1 > needTop2) return 1;
        if (needTop1 < needTop2) return -1;

        if (clicks1 > clicks2) return -1;
        if (clicks1 < clicks2) return 1;

        if (impress1 > impress2) return -1;
        if (impress1 < impress2) return 1;

        if (f1 > f2) return -1;
        if (f1 < f2) return 1;

        return 0;
      });
    },
    fix_tables(data = []) {
      return data.map((table) => {
        table.items.map((item) => {
          if (
            item.priority === "" ||
            item.priority === " " ||
            item.priority === "null" ||
            item.priority === "none"
          )
            item.priority = null;

          if (item.comp === 0) item.comp = null;
          if (parseInt(item.clicks) === 0) item.clicks = null;
          if (item.kd === 0) item.kd = null;
          return item;
        });
        return table;
      });
    },
    fetchTopTables() {
      this.topTablesAlreadyUploaded = true;
      this.fetchPositionsTableData();
      this.fetchConversionDynamicTable();
      this.fetchConversionByPageTypeTable();
      this.fetchLinksByProjectTable();
      this.fetchContentTable();
    },
    async update_tables_data() {
      try {
        if (this.validate()) return;

        if (this.filters.top_tables) this.fetchTopTables();

        this.loadings.tables = true;
        const payload = {
          filters: {
            params: {
              filter: this.filters,
            },
          },
          projectId: this.computedProjectId,
        };

        const isMock = localStorage.getItem("is_mock") === "1" ? 1 : 0;

        let resp;

        if (isMock) {
          resp = {
            items: [],
          };
        } else {
          resp = await this.$store.dispatch(
            "one_year_report/get_report_tables_data",
            payload
          );
        }

        let fixed = resp.items;
        fixed = this.fix_tables(fixed);
        this.report_tables = fixed;
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.loadings.tables = false;
      }
    },
    validate() {
      if (!this.filters.date.length) return false;

      if (!this.computedProjectId) {
        return this.$message.notification({
          title: "Validation",
          text: "Please select the project",
          type: "warning",
          duration: 6500,
        });
      }

      const today = this.$moment();
      if (this.$moment(this.filters.date[1]).diff(today) >= 0) {
        this.$message.notification({
          title: "Validation",
          text: "The date cannot be later than today ✋",
          type: "warning",
          duration: 6500,
        });
        return true;
      }
      return false;
    },
    async fetch_filter_data() {
      this.loadings.filters = true;

      await this.$store.dispatch(
        "one_year_report/get_filters_data",
        this.computedProjectId
      );
      this.filters_data = this.$store.getters["one_year_report/filters_data"];

      this.loadings.filters = false;
    },
  },
};
</script>
