<template>
  <v-menu
    v-model="menu"
    top
    nudge-top="60px"
    nudge-left="100px"
    transition="slide-x-transition"
    :close-on-content-click="false"
    content-class="remove-dialog-shadow pa-1 z-1000"
  >
    <template #activator="{ on }">
      <v-btn v-on="on" fab fixed class="float-nav-menu" color="primary">
        <v-icon> mdi-radar </v-icon>
      </v-btn>
    </template>
    <v-card width="320" class="shadow-e2-bordered" rounded-lg>
      <v-card-title> Page Navigation </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col v-for="section in sections" :key="section.innerText" cols="12">
            <a @click="navigateTo(section)">
              {{ section.innerText }}
            </a>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions />
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    menu: false,
    sections: [],
  }),
  mounted() {
    this.sections = Array.from(
      document.querySelectorAll("[data-section-name]")
    );
  },
  methods: {
    navigateTo(domElement) {
      const yOffset = -100;

      const y =
        domElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
      this.menu = false;
    },
  },
};
</script>

<style lang="scss">
.float-nav-menu {
  position: fixed;
  right: 25px;
  bottom: 25px;
}
</style>
