<template>
  <div>
    <v-container
      fluid
      v-if="isset_active_project"
      style="padding-bottom: 200px"
    >
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="styled-card--default" v-if="isset_active_project">
            <v-card-title>
              Link Dynamics Report
              <v-spacer />

              <div class="d-flex" style="gap: 8px">
                <download-csv
                  v-if="export_data_status"
                  :data="export_data.items"
                  :name="export_data.dataFile"
                  :labels="export_data.labels"
                  :fields="export_data.fields"
                  :delimiter="'\t'"
                  v-on:export-finished="exported_csv"
                >
                  <v-chip
                    label
                    class="flat-chip flat-chip--success"
                    @click="() => {}"
                  >
                    <v-icon small left>mdi-cloud-download-outline</v-icon>
                    Download CSV
                  </v-chip>
                </download-csv>
                <v-chip
                  v-else
                  label
                  :disabled="table.items?.length === 0"
                  class="flat-chip flat-chip--primary"
                  @click="export_csv"
                >
                  <v-icon small left>mdi-cloud-download-outline</v-icon>
                  Download CSV
                </v-chip>
                <v-menu
                  transition="slide-y-transition"
                  :close-on-content-click="false"
                  :nudge-right="200"
                  v-model="modal.shown"
                  :value="modal.shown"
                  max-width="420"
                  content-class="my-shadow--e3"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip label outlined v-on="on">
                      <v-icon small left>mdi-filter</v-icon> Filters
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </v-chip>
                  </template>
                  <v-card class="styled-card--default">
                    <v-card-title> Filters </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-list>
                        <v-list-item>
                          <smart-date-filter
                            :model-value="filter.date_range"
                            @update:modelValue="filter.date_range = $event"
                          />
                        </v-list-item>
                        <v-list-item>
                          <v-autocomplete
                            dense
                            hide-details
                            v-model="filter.interval"
                            :items="intervals"
                            outlined
                            item-value="id"
                            item-text="name"
                            label="Interval"
                          ></v-autocomplete>
                        </v-list-item>
                        <v-list-item>
                          <v-autocomplete
                            dense
                            hide-details
                            v-model="filter.niche"
                            :items="niches"
                            item-value="id"
                            outlined
                            multiple
                            item-text="name"
                            label="Niche"
                          ></v-autocomplete>
                        </v-list-item>
                        <v-list-item>
                          <v-autocomplete
                            dense
                            hide-details
                            v-model="filter.department"
                            :items="departments"
                            item-value="id"
                            multiple
                            outlined
                            item-text="name"
                            label="Department"
                          ></v-autocomplete>
                        </v-list-item>
                        <v-list-item>
                          <v-autocomplete
                            v-model="filter.seo"
                            :items="seoItems"
                            :loading="loadings.seo"
                            outlined
                            multiple
                            label="Responsible seo"
                            placeholder="Select user"
                            item-text="email"
                            item-value="id"
                            dense
                            hide-details
                          ></v-autocomplete>
                        </v-list-item>
                        <v-list-item>
                          <v-autocomplete
                            v-model="filter.project"
                            :items="_$getProjects"
                            outlined
                            label="Project"
                            placeholder="Select project"
                            hide-details
                            dense
                            multiple
                            item-text="name"
                            item-value="id"
                          ></v-autocomplete>
                        </v-list-item>
                        <v-list-item>
                          <v-autocomplete
                            v-model="filter.link_type"
                            dense
                            outlined
                            multiple
                            hide-details
                            item-text="text"
                            item-value="value"
                            :items="linkTypeItems"
                            label="Link type"
                          ></v-autocomplete>
                        </v-list-item>
                        <v-list-item>
                          <v-autocomplete
                            v-model="filter.team"
                            dense
                            outlined
                            multiple
                            hide-details
                            :items="filtersData.team"
                            :loading="loadings.filters"
                            label="Team"
                          ></v-autocomplete>
                        </v-list-item>
                        <v-list-item></v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-switch v-model="filter.last_month"></v-switch>
                          </v-list-item-action>
                          <v-list-item-title>Last month</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-switch v-model="filter.target_urls"></v-switch>
                          </v-list-item-action>
                          <v-list-item-title>Target URLs</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-switch v-model="filter.action"></v-switch>
                          </v-list-item-action>
                          <v-list-item-title>Action</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <div class="text-center">
                        <v-btn
                          @click="fetch_data"
                          color="primary"
                          rounded
                          width="150px"
                        >
                          Apply
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-data-table
                :search="search"
                :headers="table.headers"
                :items="table.items"
                :sort-by="['priority']"
                :sort-desc="[true]"
                :items-per-page="-1"
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      style="white-space: nowrap"
                      :style="get_style_row(item.lag_on_page)"
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <td :style="get_style_project(item.project)">
                        {{ item.project }}
                      </td>
                      <td class="text-center">
                        {{ item.type }}
                      </td>
                      <td class="text-center">
                        {{ item.plan }}
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            (item.plan !== 0 && filter.department !== 'all') ||
                            filter.department === 'all'
                          "
                        >
                          <span
                            ><a
                              :href="
                                get_link_to_ltt(
                                  item.id,
                                  item.subproject_id,
                                  item.type_id
                                )
                              "
                              target="_blank"
                              >{{ item.fact.all.all }}</a
                            ><sup v-if="item.fact.all.nofollow > 0">{{
                              item.fact.all.nofollow
                            }}</sup></span
                          ><span v-if="item.fact.on_page.all > 0">
                            ({{ item.fact.on_page.all
                            }}<sup v-if="item.fact.on_page.nofollow > 0">{{
                              item.fact.on_page.nofollow
                            }}</sup
                            ><span v-if="item.fact.gi.all > 0"
                              >/{{ item.fact.gi.all
                              }}<sup v-if="item.fact.gi.nofollow > 0">{{
                                item.fact.gi.nofollow
                              }}</sup></span
                            >)</span
                          >
                        </div>
                      </td>
                      <td class="text-center">
                        {{ item.done }}% ({{ item.done_on_page }}%)
                      </td>
                      <!--  <td class="text-center">
                      {{ item.lag }}% ({{ item.lag_on_page }}%)
                    </td>-->
                      <td class="text-center">
                        <span
                          v-if="item.department.toLowerCase().includes('la')"
                        >
                          <span
                            v-if="
                              item.type_id !== 6 &&
                              item.type_id !== 13 &&
                              item.left.performer > 0
                            "
                          >
                            <span v-if="item.task_id > 0"
                              ><a
                                target="_blank"
                                :href="
                                  '/anchors-plan/placement-links/' +
                                  item.task_id
                                "
                                >{{ item.left.plan }}</a
                              ></span
                            ><span v-else>{{ item.left.plan }}</span
                            >- {{ item.left.new_donors
                            }}<sup>{{ item.left.all_donors }}</sup>
                            <span v-if="item.left.plan > item.left.new_donors"
                              ><v-icon style="color: #f10707" size="20"
                                >mdi-alert-circle</v-icon
                              ></span
                            >
                          </span>
                        </span>
                        <span v-else
                          ><v-icon small color="red">mdi-crop</v-icon></span
                        >
                      </td>
                      <td
                        v-for="date in item.dates"
                        :key="date.id"
                        class="text-center"
                      >
                        <div
                          v-if="
                            (item.plan !== 0 && filter.department !== 'all') ||
                            filter.department === 'all'
                          "
                        >
                          <span v-if="date.all.all > 0"
                            ><a
                              :href="
                                get_link_to_ltt(
                                  item.id,
                                  item.subproject_id,
                                  item.type_id,
                                  date.date
                                )
                              "
                              target="_blank"
                              >{{ date.all.all }}</a
                            ><sup v-if="date.all.nofollow > 0">{{
                              date.all.nofollow
                            }}</sup>
                          </span>
                          <span v-if="date.on_page.all > 0"
                            >({{ date.on_page.all
                            }}<sup v-if="date.on_page.nofollow > 0">{{
                              date.on_page.nofollow
                            }}</sup
                            ><span v-if="date.gi.all > 0"
                              >/{{ date.gi.all
                              }}<sup v-if="date.gi.nofollow > 0">{{
                                date.gi.nofollow
                              }}</sup></span
                            >)
                          </span>
                          <div
                            v-if="date.check > 0"
                            style="color: red; font-weight: 600"
                          >
                            {{ date.check }}
                          </div>
                          <span v-if="date.action > 0">
                            --> {{ date.action }}</span
                          >
                        </div>
                      </td>
                      <td class="text-center">
                        {{ item.niche }}
                      </td>
                      <td class="text-center">
                        {{ item.seo }}
                      </td>
                      <td class="text-center">
                        {{ item.priority }}
                      </td>
                      <td class="text-center">
                        <div v-for="i in item.created_at" :key="i">
                          <div :title="i.department">{{ i.date }}</div>
                        </div>
                      </td>
                      <td class="text-center">
                        {{ item.department }}
                      </td>
                      <td class="text-center">
                        <template v-if="item.link_task === 'none'"> </template>
                        <template
                          v-else-if="item.link_task.split(',').length > 1"
                        >
                          <a
                            v-for="(link, index) in item.link_task.split(',')"
                            :key="link"
                            :href="link"
                            target="_blank"
                            style="margin-right: 10px"
                          >
                            LINK {{ index + 1 }}
                          </a>
                        </template>
                        <template v-else>
                          <a :href="item.link_task" target="_blank"> LINK </a>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import JsonCSV from "vue-json-csv";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import service from "@/plugins/service";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../../mixins/Location";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "LinkDynamicsReportIndex",
  metaInfo: {
    title: "Link Dynamics Report - LTT - SI",
  },
  components: { SmartDateFilter, "download-csv": JsonCSV },
  mixins: [FiltersHandling, Project, Location],
  data() {
    return {
      linkTypeItems: [],
      seoItems: [],
      date_modal: false,
      search: "",
      modal: {
        shown: false,
      },
      filtersData: {
        team: [],
      },
      loadings: {
        fetch_data: false,
        seo: false,
        filters: false,
      },
      filter: {
        link_type: [],
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        team: [],
        niche: [],
        last_month: false,
        department: [],
        action: false,
        target_urls: false,
        interval: "day",
        seo: [],
        project: [],
      },
      departments: [
        { id: "la", name: "la" },
        { id: "ha", name: "ha" },
        { id: "fl", name: "fl" },
      ],
      intervals: [
        { id: "day", name: "day" },
        { id: "week", name: "week" },
      ],
      table: {
        headers: [],
        items: [],
      },
      export_data_status: false,
      export_data: {
        items: [],
        dataFile: "serp_export.csv",
        labels: {},
        fields: [],
      },
    };
  },
  async mounted() {
    await this.initializeFiltersWatcher("filter");
    await this.collectParametersFromQuery("filter");

    this.fetchUsers();

    this.getItems();
    this.fetchFilters();

    if (this.niches.length === 0) {
      this.$store.dispatch("fetch_niches_list");
    }

    this.fetch_data();
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    async fetchFilters() {
      const url = "/static/get-link-types";

      const resp = await service.get(url, {});

      if (!resp) return;

      this.linkTypeItems = resp.data.items;
    },
    async getItems() {
      try {
        const payload = {
          type: "/ltt/link-dynamics-report",
          take: ["team"],
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = resp;
      } catch (e) {
        console.error(
          "Error while getting filter items for Link Dynamic Report.",
          e
        );
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchUsers() {
      this.loadings.seo = true;
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.seoItems = resp;
      }

      this.loadings.seo = false;
    },
    exported_csv() {
      this.export_data_status = false;
    },
    export_csv() {
      let self = this;

      self.export_data.items = self.table.items.map((v) => {
        let res = {
          name: v.project,
          type: v.type,
        };

        self.export_data.labels["name"] = "name";
        self.export_data.labels["type"] = "type";
        let dates = Object.keys(v.dates).reverse();
        for (let date in dates) {
          let newDate = new Date(dates[date]);
          let d = newDate.getDate() + "-" + (newDate.getMonth() + 1);
          //     console.log(d, dates[date], newDate.getMonth())
          self.export_data.labels[dates[date]] = d;
          res[dates[date]] = v.dates[dates[date]].all.all;
        }

        return res;
      });

      self.export_data.fields = Object.keys(self.export_data.items[0]);
      self.export_data_status = true;
    },
    fetch_data() {
      let self = this;
      self.modal.shown = false;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/ltt/link-dynamics-report/${self.id_project}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
        };

        self.table.headers = [];
        self.table.items = [];

        axios
          .post(url, data, config)
          .then(function (response) {
            self.table.items = response.data.data;
            if (self.filter.department !== "all") {
              self.table.items = response.data.data.filter((v) => v.plan !== 0);
            } else {
              self.table.items = response.data.data;
            }
            self.table.headers = response.data.headers;
            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    hash_code(str) {
      let hash = 0,
        i,
        chr;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    to_color(num) {
      num >>>= 0;
      var b = num & 0xff,
        g = (num & 0xff00) >>> 8,
        r = (num & 0xff0000) >>> 16,
        a = ((num & 0xff000000) >>> 24) / 10;
      return "rgba(" + [r, g, b, a].join(",") + ")";
    },
    get_style_project(name) {
      let val = this.hash_code(name);
      return (
        "border-left: 3px solid " + this.to_color(val) + "; padding: 7px; "
      );
    },
    get_style_row(val) {
      let color = "";
      if (val > 50) {
        color = "rgba(255,112,112,0.14)";
      } else if (val > 20) {
        color = "rgba(255,244,131,0.14)";
      }
      return "background-color:" + color;
    },
    get_link_to_ltt(project, subproject, type = false, date = false) {
      let url = "/ltt/index?project=" + project + "&subproject=" + subproject;

      if (date) {
        url += "&date_range=" + date + "," + date;
      } else {
        url += "&date_range=" + this.filter.date_range;
      }

      if (type) {
        url += "&type=" + type;
      }
      return url;
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
};
</script>

<style scoped></style>
