<template>
  <v-item-group :class="{ 'my-group': clearable }">
    <v-chip
      v-on="{ ...$listeners }"
      outlined
      label
      @click="$emit('click:filter')"
    >
      <v-icon left small>mdi-filter</v-icon>
      Filters
      <v-tooltip v-if="getAmountOfFilters" top>
        <template #activator="{ on }">
          <div v-on="on">
            <my-badge class="success white--text ml-1">
              {{ getAmountOfFilters }}
            </my-badge>
          </div>
        </template>
        <div>{{ getAmountOfFilters }} active filters</div>
      </v-tooltip>
      <v-icon right small>mdi-chevron-down</v-icon>
    </v-chip>
    <v-tooltip v-if="clearable" top>
      <template #activator="{ on }">
        <v-chip
          v-on="on"
          outlined
          label
          class="error--text px-2"
          @click="$emit('click:clear')"
        >
          <v-icon small>mdi-close</v-icon>
        </v-chip>
      </template>
      <div>Clear all filters</div>
    </v-tooltip>
  </v-item-group>
</template>

<script>
import MyBadge from "@/components/UI/MyBadge.vue";

export default {
  components: { MyBadge },
  emits: ["click:clear", "click:filter"],
  props: {
    watchFilters: Array,
    clearable: Boolean,
    filters: [Array, Object],
  },
  computed: {
    getAmountOfFilters() {
      try {
        const filterNames = this.watchFilters || Object.keys(this.filters);

        let count = 0;

        const emptyRange = JSON.stringify([null, null]);
        const emptyZerosRange = JSON.stringify([0, 0]);
        const emptyArr = JSON.stringify([]);

        Object.keys(this.filters).forEach((key) => {
          if (!filterNames.includes(key)) return;
          const json = JSON.stringify(this.filters[key]);
          const value = this.filters[key];

          if (Array.isArray(value)) {
            if (
              json !== emptyRange &&
              json !== emptyZerosRange &&
              json !== emptyArr
            )
              count++;
          } else {
            if (value) count++;
          }
        });

        return count;
      } catch {
        return null;
      }
    },
  },
  methods: {},
};
</script>
