var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"bottom":"","content-class":`smart-date-tooltip ${
    _vm.value && Array.isArray(_vm.value) && _vm.value[0] && _vm.value[1] ? 'exist' : ''
  }`},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticStyle:{"width":"100%"}},on),[_c('v-menu',{attrs:{"bottom":"","content-class":"my-shadow--e2 rounded-lg","transition":"slide-y-transition","offset-y":"","max-width":"580","min-width":"580","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"value":_vm.value?.every((v) => !v) ? '' : _vm.value,"label":_vm.label,"placeholder":"Select a date","dense":"","readonly":"","hide-details":"auto","rules":_vm.rules,"filled":_vm.filled,"outlined":!_vm.filled,"clearable":"","append-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.value = [null, null]}}})],1)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-text',{staticClass:"pb-1 pt-0 pl-0 pr-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{staticClass:"dense-scrollbar",staticStyle:{"max-height":"448px","overflow-y":"auto","height":"100%"},attrs:{"nav":"","dense":""}},_vm._l(([
                      ...(_vm.customPresets || []),
                      ..._vm.presets,
                    ]),function(date,i){return _c('v-list-item',{key:date.id || i,class:{
                      'v-list-item--active':
                        JSON.stringify(date.range) === JSON.stringify(_vm.value),
                      'primary--text':
                        JSON.stringify(date.range) ===
                          JSON.stringify(_vm.value) && !_vm.$vuetify.theme.dark,
                    },on:{"click":function($event){return _vm.setDate(date)}}},[_c('span',{domProps:{"innerHTML":_vm._s(date.name)}}),_c('v-spacer'),(
                        JSON.stringify(date.range) === JSON.stringify(_vm.value)
                      )?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1)}),1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{staticClass:"pa-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"styled-card--light pa-0"},[_c('v-date-picker',{attrs:{"full-width":"","no-title":"","reactive":"","scrollable":"","first-day-of-week":1,"range":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex",attrs:{"dense":""}},[_c('v-col',[(
                            _vm.value &&
                            Array.isArray(_vm.value) &&
                            _vm.value[0] !== undefined
                          )?[_c('v-text-field',{staticClass:"input-rounded-1",attrs:{"hide-details":"","clearable":"","dense":"","outlined":"","placeholder":"YYYY-MM-DD"},on:{"change":_vm.handleUpdateValue},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticStyle:{"margin-top":"4px"},attrs:{"small":""}},[_vm._v("mdi-calendar-start-outline")])]},proxy:true}],null,true),model:{value:(_vm.value[0]),callback:function ($$v) {_vm.$set(_vm.value, 0, $$v)},expression:"value[0]"}}),(_vm.value[0])?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(_vm.value[0]).format("MMMM Do, YYYY"))+" ")]):_vm._e()]:[_c('div',{staticClass:"skeleton skeleton--placeholder",staticStyle:{"width":"163px","height":"40px"}}),_c('div',{staticClass:"skeleton skeleton--placeholder",staticStyle:{"width":"163px","height":"14px","margin-top":"2px"}})]],2),_c('v-col',{staticClass:"flex-shrink-1 d-flex justify-center align-start",staticStyle:{"max-width":"32px"}},[(
                            _vm.value &&
                            Array.isArray(_vm.value) &&
                            _vm.value[1] !== undefined
                          )?_c('v-icon',{staticStyle:{"margin-top":"8px"}},[_vm._v("mdi-arrow-right")]):[_c('div',{staticClass:"skeleton skeleton--placeholder",staticStyle:{"width":"24px","height":"24px","margin-top":"8px"}})]],2),_c('v-col',[(
                            _vm.value &&
                            Array.isArray(_vm.value) &&
                            _vm.value[1] !== undefined
                          )?[_c('v-text-field',{staticClass:"input-rounded-1",attrs:{"hide-details":"","dense":"","outlined":"","clearable":"","placeholder":"YYYY-MM-DD"},on:{"change":_vm.handleUpdateValue},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticStyle:{"margin-top":"4px"},attrs:{"small":""}},[_vm._v("mdi-calendar-end-outline")])]},proxy:true}],null,true),model:{value:(_vm.value[1]),callback:function ($$v) {_vm.$set(_vm.value, 1, $$v)},expression:"value[1]"}}),(_vm.value[1])?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(_vm.value[1]).format("MMMM Do, YYYY"))+" ")]):_vm._e()]:[_c('div',{staticClass:"skeleton skeleton--placeholder",staticStyle:{"width":"163px","height":"40px"}}),_c('div',{staticClass:"skeleton skeleton--placeholder",staticStyle:{"width":"163px","height":"14px","margin-top":"2px"}})]],2)],1)],1)],1)],1)],1)],1),(false)?_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","outlined":""},on:{"click":function($event){_vm.value = [null, null]}}},[_vm._v(" Clear selected "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-chip',{staticClass:"d-flex justify-center",staticStyle:{"width":"100px"},attrs:{"label":"","color":"success"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Apply ")])],1):_vm._e()],1)],1)],1)]}}])},[_c('div',{staticClass:"smart-date-tooltip__content"},[_vm._v(" "+_vm._s(Array.isArray(_vm.value) && _vm.value[0] ? _vm.$moment(_vm.value[0]).calendar(null, _vm.calendarFormat) : "Select from")+" → "+_vm._s(Array.isArray(_vm.value) && _vm.value[1] ? _vm.$moment(_vm.value[1]).calendar(null, _vm.calendarFormat) : "Select to")+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }