<template>
  <div v-if="data && data?.length" class="progress-values">
    <template v-for="(progress, idx) in data">
      <div v-if="progress" :key="`progress-${idx}`" class="progress-value pa-4">
        <div class="d-flex">
          {{ progress.title }}
          <v-spacer />
          <b>{{ progress.current || 0 }}</b> <span class="px-2">of</span>
          <b>{{ progress.total || 0 }}</b>
          <v-spacer />
          <b> {{ getProgressPercent(progress) }}% </b>
        </div>
        <v-progress-linear
          rounded
          :value="getProgressPercent(progress)"
          height="6"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data"],

  methods: {
    getProgressPercent(progress) {
      const result = Math.round((progress.current / progress.total) * 100);
      return isNaN(result) ? 0 : result;
    },
  },
};
</script>

<style lang="scss">
.tribe-dashboard {
  .progress-values {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
