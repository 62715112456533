<template>
  <div>
    <template v-if="isDataValid">
      <v-data-table
        :headers="data.headers"
        :items="getFixedItems"
        class="links-by-project-table table-with-dividers"
        dense
        :items-per-page="-1"
        :custom-sort="_$smartCustomTableSort"
        hide-default-footer
      >
        <template v-for="h in data.headers" #[`header.${h.value}`]>
          <div style="white-space: nowrap" :key="h.value">
            <template v-if="h.title">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ h.text }}
                  </span>
                </template>
                <div style="max-width: 150px">{{ h.title }}</div>
              </v-tooltip>
            </template>
            <template v-else>
              <span>{{ h.text }}</span>
            </template>
          </div>
        </template>

        <template #body="{ items }">
          <tbody>
            <tr
              v-for="(row, index) in items"
              :key="index"
              :class="{
                'divider-border': row.name.divider === 2,
                divider: row.name.divider === 1,
              }"
            >
              <template v-for="header in data.headers">
                <td
                  v-if="header.value === 'name'"
                  :key="header.value"
                  :class="{ bold: row.name.text.includes(',%') }"
                >
                  {{ row.name.text || row.name }}
                </td>
                <td v-else :key="header.value">
                  {{
                    String(row[header.value]) === "0" ? "" : row[header.value]
                  }}
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      <div class="pa-3 warning--text">Data format is invalid</div>
    </template>
  </div>
</template>

<script>
import { ONE_YEAR_REPORT_LINKS_BY_PROJECT_TABLE_HEADERS } from "../../../utils/defaultData";
import TableSorting from "../../../mixins/TableSorting";

export default {
  props: ["data"],
  mixins: [TableSorting],
  data: () => ({
    headers: ONE_YEAR_REPORT_LINKS_BY_PROJECT_TABLE_HEADERS,
  }),
  computed: {
    isDataValid() {
      return this.data?.items?.length && this.data?.headers?.length;
    },
    getFixedItems() {
      const data = JSON.parse(JSON.stringify(this.data.items));
      return data.map((item) => {
        const keys = Object.keys(item);

        keys.forEach((key) => {
          if (item[key] === 0) item[key] = null;
        });

        return item;
      });
    },
  },
};
</script>
