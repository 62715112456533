<template>
  <div>
    <v-btn
        fixed
        dark
        fab
        bottom
        right
        color="pink"
        @click="dialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title>Create task</v-card-title>
        <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-textarea
                v-model="phrases"
                :rules="phrasesRules"
                label="Phrases"
                solo
                no-resize
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="info"
              text
              @click="upload_data"
              :disabled="status_uploading"
              :loading="status_uploading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateTask",
  data() {
    return {
      valid: false,
      phrases: undefined,
      phrasesRules: [
        v => !!v || 'Phrase is required',
      ],
      dialog: false,
      status_uploading: false
    }
  },
  methods: {
    upload_data() {
      let self = this

      if (!self.$refs.form.validate()) {
        return
      }

      self.status_uploading = true

      let url = `${self.$store.state.server_url}/essay-hub`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      let post_data = {
        phrases: self.phrases,
      }

      axios.post(url, post_data, config)
          .catch(() => self.sheet = true)
          .finally(() => self.status_uploading = false)
    }
  }
}
</script>

<style scoped>

</style>