<template>
  <div>
    <template v-if="data.type === 'pie-chart'">
      <pie-and-graph :data="data" />
    </template>
    <template v-else-if="data.type === 'extended-pie-chart'">
      <extended-pie-chart :data="data" />
    </template>
    <template v-else-if="data.type === 'pie-chart-column-multi-value'">
      <pie-and-graph-column-percent :data="data" />
    </template>
    <template v-else-if="data.type === 'default-bubble-chart'">
      <bubble-chart-default :data="data" />
    </template>
    <template v-else-if="data.type === 'pie-chart-column'">
      <pie-and-graph-column-default :data="data" />
    </template>
    <template v-else-if="data.type === 'chart-line'">
      <column-and-line-graph :data="data" />
    </template>
    <template v-else-if="data.type === 'multi-column-chart'">
      <column-only-graph :data="data" />
    </template>
    <template v-else-if="data.type === 'progress-table'">
      <progress-table :data="data" />
    </template>
    <template v-else-if="data.type === 'user-info-default'">
      <user-details-pie-and-graph :data="data" />
    </template>
    <template v-else-if="data.type === 'pie-score-stacked-chart'">
      <pie-score-stacked-chart :data="data" />
    </template>
    <template v-else-if="data.type === 'vertical-stacked-chart'">
      <vertical-stacked-chart :data="data" />
    </template>
    <template
      v-else-if="data.type === 'outreach-problems-vertical-stacked-chart'"
    >
      <outreach-problems-vertical-stacked-chart :data="data" />
    </template>
    <template v-else-if="data.type === 'vertical-stacked-chart-expanded'">
      <vertical-stacked-chart-expanded :data="data" />
    </template>
    <template
      v-else-if="data.type === 'pie-and-vertical-stacked-chart-expanded'"
    >
      <pie-and-vertical-stacked :data="data" />
    </template>
    <template v-else-if="data.type === 'default-table'">
      <dx4-default-table :data="data" />
    </template>
    <template v-else-if="data.type === 'doubled-circle-chart'">
      <doubled-circle-chart :data="data" />
    </template>
    <template v-else-if="data.type === 'table'">
      <data-table :data="data" />
    </template>
    <template v-else>not supported section</template>
  </div>
</template>

<script>
import PieAndGraph from "./PieAndGraph.vue";
import ColumnAndLineGraph from "./ColumnAndLineGraph.vue";
import ColumnOnlyGraph from "./ColumnOnlyGraph.vue";
import ProgressTable from "./ProgressTable.vue";
import UserDetailsPieAndGraph from "./UserDetailsPieAndGraph.vue";
import VerticalStackedChart from "./VerticalStackedChart.vue";
import VerticalStackedChartExpanded from "./VerticalStackedChartExpanded.vue";
import PieScoreStackedChart from "./PieScoreStackedChart.vue";
import PieAndGraphColumnDefault from "./PieAndGraphColumnDefault.vue";
import PieAndGraphColumnPercent from "./PieAndGraphColumnPercent.vue";
import PieAndVerticalStacked from "./PieAndVerticalStacked.vue";
import ExtendedPieChart from "./ExtendedPieChart.vue";
import Dx4DefaultTable from "./Dx4DefaultTable.vue";
import BubbleChartDefault from "@/components/Dashboard4DX/components/BubbleChartDefault.vue";
import DoubledCircleChart from "@/components/Dashboard4DX/components/DoubledCircleChart.vue";
import DataTable from "./DataTable.vue";
import OutreachProblemsVerticalStackedChart from "./OutreachProblemsVerticalStackedChart.vue";

export default {
  components: {
    OutreachProblemsVerticalStackedChart,
    DataTable,
    DoubledCircleChart,
    BubbleChartDefault,
    Dx4DefaultTable,
    ExtendedPieChart,
    PieAndVerticalStacked,
    PieAndGraphColumnPercent,
    PieAndGraphColumnDefault,
    PieScoreStackedChart,
    VerticalStackedChartExpanded,
    VerticalStackedChart,
    UserDetailsPieAndGraph,
    ProgressTable,
    ColumnOnlyGraph,
    ColumnAndLineGraph,
    PieAndGraph,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
