<template>
  <v-card-text class="pa-0">
    <v-data-table
      v-model="selected"
      item-key="id"
      show-select
      :headers="headers"
      :items="items"
    >
      <template #[`item.preview_image`]="{ value }">
        <div class="d-flex align-center py-2">
          <img
            :src="value"
            alt="preview image"
            width="120px"
            style="aspect-ratio: 16/9; border-radius: 8px; object-fit: cover"
          />
        </div>
      </template>
      <template
        v-for="key in ['created_at', 'updated_at']"
        #[`item.${key}`]="{ value }"
      >
        <div :key="key">
          {{ $moment(value).calendar() }}
        </div>
      </template>
      <template #[`item._actions`]="{ item }">
        <v-menu
          bottom
          offset-y
          :close-on-content-click="false"
          content-class="my-shadow--e3"
          max-width="300"
        >
          <template #activator="{ on }">
            <v-chip
              v-on="on"
              label
              outlined
              style="width: 30px"
              class="d-flex justify-center px-0"
            >
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-chip>
          </template>
          <v-card class="styled-card--default">
            <v-card-text class="pa-0">
              <v-list dense>
                <v-list-item @click="$emit('action:edit', item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content> Edit </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('action:delete', item)">
                  <v-list-item-icon>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content> Delete </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </v-card-text>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    modelValue: Array,
  },
  emits: ["action:edit", "action:delete"],
  computed: {
    selected: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
