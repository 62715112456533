/* eslint-disable */
export default {
  methods: {
    _$buildQuery(params = {}) {
      const query = Object.entries(params)
        .filter(([_, value]) => !this.__isFalsey(value)) // remove falsey values
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");

      return query ? `?${query}` : "";
    },
    __isFalsey(value) {
      const falseyRange = JSON.stringify([null, null]);
      return (
        JSON.stringify(value) === falseyRange ||
        !value ||
        (Array.isArray(value) && value.length === 0)
      );
    },
  },
};
