<template>
  <v-card class="styled-card--default">
    <div
      @click="toggleValue"
      :class="{
        'cursor-pointer': true,
        'py-3': true,
        'px-4': true,
        info: isChecked,
        'white--text': isChecked,
        'd-flex': true,
        'align-center': true,
      }"
    >
      {{ label }}
      <v-spacer />
      <template v-if="isChecked">
        <v-icon style="color: currentColor">mdi-check-circle</v-icon>
      </template>
      <template v-else>
        <v-icon style="color: currentColor; opacity: 0.5">
          mdi-circle-outline
        </v-icon>
      </template>
    </div>
    <v-expand-transition>
      <div v-if="showTextfield && isChecked" class="px-3 py-3">
        <v-textarea
          v-model="text"
          rows="3"
          hide-details="auto"
          dense
          placeholder="Write down exactly what you refactored"
          filled
        ></v-textarea>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    modelValue: {},
    textModel: {},
    value: {},
    label: String,
    multiple: Boolean,
    showTextfield: Boolean,
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
  },
  emits: ["update@modelValue", "update@textModel"],
  computed: {
    isChecked() {
      if (this.multiple) {
        return this.output.includes(this.value.value);
      }

      return this.value === this.trueValue;
    },
    output: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update@modelValue", value);
      },
    },
    text: {
      get() {
        return this.textModel;
      },
      set(value) {
        this.$emit("update@textModel", value);
      },
    },
  },
  methods: {
    toggleValueMultiple() {
      if (
        Array.isArray(this.output) &&
        this.output.includes(this.value.value)
      ) {
        return (this.output = this.output.filter(
          (item) => item !== this.value.value
        ));
      }
      this.output = [...this.output, this.value.value];
    },
    toggleValueSingle() {
      if (this.output === this.trueValue)
        return (this.output = this.falseValue);

      return (this.output = this.trueValue);
    },
    toggleValue() {
      try {
        if (this.multiple) return this.toggleValueMultiple();

        this.toggleValueSingle();
      } catch (e) {
        console.error("Error whiel toggling value in card checkbox.", e);
      }
    },
  },
};
</script>
