import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "ndc-manage/fetchData"(ctx, payload) {
      const url = "/money/fact";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "ndc-manage/uploadFact"(ctx, payload) {
      const url = "/money/import/fact";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "ndc-manage/uploadPlan"(ctx, payload) {
      const url = "/money/import/plan";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "ndc-manage/uploadExpense"(ctx, payload) {
      const url = "/money/import/expense";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "ndc-manage/updateFact"(ctx, payload) {
      const url = "/money/update/fact";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "ndc-manage/deleteRow"(ctx, payload) {
      const url = "/money/delete/fact/" + payload.id;

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "ndc-manage/fetchCharts"(ctx, payload) {
      const url = "/money/dashboard";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
    async "ndc-manage/fetchFilters"(ctx, payload) {
      const url = "/money/filter";

      const resp = await Service.post(url, payload, true);

      if (resp) return resp.data;
    },
  },
  getters: {},
};
