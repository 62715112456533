<template>
  <v-bottom-sheet v-model="dialog" content-class="elevation-0" scrollable>
    <v-card
      flat
      outlined
      rounded="lg"
      class="mx-6 mb-6"
      style="width: calc(100% - 3rem)"
    >
      <v-card-title class="text-body-2">
        <v-row dense class="align-center">
          <v-col class="pa-0">
            <b>Actions list</b>
          </v-col>
          <v-col class="d-flex justify-center pa-0">
            <v-text-field
              v-model="searchQuery"
              style="max-width: 320px"
              outlined
              placeholder="Type to search"
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              clearable
            />
          </v-col>
          <v-col class="d-flex justify-end pa-0">
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-title style="gap: 0.5rem" class="px-4 py-2">
        <v-row dense>
          <v-col>
            <v-card flat outlined rounded="lg" class="pa-0" height="40">
              <v-checkbox
                v-if="filters.isResetCacheEnabled !== undefined"
                v-model="filters.isResetCacheEnabled"
                :true-value="1"
                :false-value="null"
                label="Reset cache"
                hide-details
                dense
                class="pa-2 ma-0"
              />
            </v-card>
          </v-col>
          <v-col>
            <v-autocomplete
              v-if="filters.dataType !== undefined"
              v-model="filters.dataType"
              hide-details
              dense
              outlined
              :items="dataTypes"
              label="Content type" /></v-col
          ><v-col>
            <v-btn
              class="text-normal"
              height="40"
              block
              @click="$emit('uploadAll')"
              :loading="loadings.allSegmentsAtOnce"
            >
              Upload all
              <v-icon small right>mdi-download</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-tooltip bottom max-width="360" open-delay="300">
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  class="text-normal"
                  height="40"
                  block
                  :disabled="!lastUpload()?.length"
                  @click="handleRepeatLastUpload"
                >
                  Repeat last upload
                  <v-icon small right>mdi-repeat-once</v-icon>
                </v-btn>
              </template>
              <template #default>
                Load the set of sections you last loaded saving their location
                and sequence
              </template>
            </v-tooltip>
          </v-col>
          <v-col>
            <!--            <v-tooltip bottom max-width="360" open-delay="300">-->
            <!--              <template #activator="{ on }">-->
            <!--                <v-btn-->
            <!--                  v-on="on"-->
            <!--                  class="text-normal"-->
            <!--                  height="40"-->
            <!--                  block-->
            <!--                  @click="handleShakeActions"-->
            <!--                >-->
            <!--                  shake out-->
            <!--                  <v-icon small right>mdi-arrow-oscillating</v-icon>-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--              <template #default>-->
            <!--                Distributes the loaded sections equally to the left and right-->
            <!--                columns-->
            <!--              </template>-->
            <!--            </v-tooltip>-->
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text class="px-0 pb-0">
        <template v-if="loadings.actions">
          <div style="height: 300px" class="d-flex justify-center align-center">
            <v-progress-circular indeterminate size="24" width="2" />
          </div>
        </template>
        <v-data-table
          v-else
          :search="searchQuery"
          :items="tableData?.items || []"
          :headers="dataTableHeaders"
          :custom-sort="_$smartCustomTableSort"
          :sort-by="['service_priority', 'priority']"
          :sort-desc="[true, true]"
          :items-per-page="25"
          fixed-header
          multi-sort
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, -1],
          }"
        >
          <template #[`item._status`]="{ item }">
            <action-upload-buttons
              @upload="$emit('upload', $event)"
              :item="item"
              :uploaded-actions-statuses="uploadedActionsStatuses"
              :uploaded-actions-details="uploadedActionsDetails"
            />
          </template>
          <template #[`item.worksection`]="{ item }">
            <a
              v-if="item.worksection"
              :href="item.worksection"
              target="_blank"
              :title="item.worksection"
            >
              <v-btn icon>
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </a>
          </template>
          <template #[`item.hour`]="{ value }">
            <simple-numeric-cell :value="value" />
          </template>
          <template #[`item.queue`]="{ value }">
            <simple-numeric-cell :value="value" />
          </template>
          <template #[`item.priority`]="{ value }">
            <simple-numeric-cell :value="value" />
          </template>
          <template #[`item.day`]="{ value }">
            <simple-numeric-cell :value="value" />
          </template>
          <template #[`item.responsible`]="{ value }">
            <user-preview
              :email="value"
              :opacity="1"
              hide-initials
              class="justify-center"
            />
          </template>
          <template #[`item.snooze_until`]="{ value }">
            <v-chip color="transparent" :title="value">
              {{ value ? $moment(value).format("ll") : "" }}
            </v-chip>
          </template>
          <template #[`item.updated_at`]="{ value }">
            <template
              v-if="$moment(value).isBefore($moment().subtract(1, 'days'))"
            >
              <v-chip
                class="flat-chip flat-chip--error"
                :title="`Last update more then 1 day ago | ${value}`"
              >
                {{ $moment(value).format("ll") }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip color="transparent" :title="value">
                {{ value ? $moment(value).format("ll") : "" }}
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { ACTION_DATA_TYPES, ACTIONS_DATA_TABLE_HEADERS } from "../defaults";
import TableSorting from "../../../mixins/TableSorting";
import SimpleNumericCell from "./SimpleNumericCell.vue";
import UserPreview from "../../UI/UserPreview.vue";
import ActionUploadButtons from "./ActionUploadButtons.vue";

export default {
  components: { ActionUploadButtons, UserPreview, SimpleNumericCell },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    tableData: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
    loadings: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
    uploadedActionsStatuses: {
      type: Object,
      default: () => ({}),
    },
    uploadedActionsDetails: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ["upload"],

  inject: ["lastUpload"],

  mixins: [Dialog, TableSorting],

  data: () => ({
    searchQuery: null,
    dataTypes: ACTION_DATA_TYPES,
  }),

  computed: {
    dataTableHeaders: () => ACTIONS_DATA_TABLE_HEADERS,
  },

  methods: {
    handleShakeActions() {
      const ids = Object.keys(this.uploadedActionsStatuses) || [];

      const halfLength = Math.ceil(ids.length / 2);

      ids.forEach((actionId, idx) => {
        if (idx + 1 < halfLength) return;

        return this.$set(this.uploadedActionsDetails[actionId], "location", 1);
      });
    },
    handleRepeatLastUpload() {
      const lastUpload = this.lastUpload();
      const lastUploadActionsIds =
        lastUpload.map((segment) => segment.action.id) || [];

      const actionsOnUpload =
        this.tableData.items.filter((action) =>
          lastUploadActionsIds.includes(action.id)
        ) || [];

      actionsOnUpload.forEach((action) => {
        const location =
          lastUpload.find((v) => v.action.id === action.id)?.location || 0;
        this.$emit("upload", { action, config: { location } });
      });
    },
  },
};
</script>
