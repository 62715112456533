var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.data?.dialog_field_data.field)+" "),_c('v-spacer'),(_vm.data?.dialog_field_data?.url)?_c('a',{staticClass:"reset-link-styles",attrs:{"href":_vm.data?.dialog_field_data?.url,"target":"_blank"}},[_c('v-chip',{staticClass:"mr-2",attrs:{"label":""},on:{"click":() => {}}},[_vm._v(" Visit URL "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1)],1):_vm._e(),(_vm.data?.dialog_field_data.md5)?_c('router-link',{staticClass:"reset-link-styles mr-2",attrs:{"to":{
      name: 'search-console.url',
      params: { id: _vm.data?.dialog_field_data.md5 },
    },"target":"_blank"}},[_c('v-chip',{attrs:{"label":""},on:{"click":() => {}}},[_vm._v(" Search console "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1)],1):_vm._e(),(
      [_vm.types.TITLE, _vm.types.H1, _vm.types.DESCRIPTION].includes(
        _vm.data?.dialog_field_data.field_id
      )
    )?_c('v-chip',{attrs:{"label":""},on:{"click":_vm.visitTdh1Dashboard}},[_vm._v(" TDH1 "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e(),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }