<template>
  <v-dialog v-model="dialog" width="540" content-class="remove-dialog-shadow">
    <v-card class="shadow-e1-bordered" rounded="lg">
      <v-card-title class="text-body-2 pa-4">
        Create new user
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2">Username</div>
            <v-text-field
              v-model="userData.name"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Email</div>
            <v-text-field
              v-model="userData.email"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="9">
                <div class="text-body-2">Password</div>
                <v-text-field
                  v-model="userData.password"
                  :type="isPasswordHidden ? 'password' : 'text'"
                  hide-details
                  outlined
                  dense
                  :append-icon="
                    isPasswordHidden ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  @click:append="isPasswordHidden = !isPasswordHidden"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="d-flex align-end">
                <v-btn
                  label
                  height="40"
                  style="width: 100%"
                  color="primary"
                  class="text-normal"
                  @click="handleGeneratePassword"
                >
                  Generate

                  <v-icon small class="ml-2">mdi-refresh</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false"
          >Back</v-btn
        >
        <v-btn
          large
          class="px-6 text-normal"
          @click="createUser"
          :loading="loading"
          color="success"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { DEFAULT_MENU_PROPS } from "../../../utils/defaultData";
import { UserManageApiService } from "../services/UserManageApiService";

export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    accessItems: {
      type: Array,
      default: () => [],
    },
    afterCreateCallback: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [Dialog],
  data: () => ({
    menuProps: DEFAULT_MENU_PROPS,
    userData: {
      name: null,
      email: null,
      password: null,
    },
    loading: false,
    isPasswordHidden: true,
  }),
  methods: {
    generatePassword() {
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";
      for (let i = 0; i < 15; i++) {
        password += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return password;
    },
    handleGeneratePassword() {
      this.userData.password = this.generatePassword();
      this.isPasswordHidden = false;
    },
    async createUser() {
      try {
        this.loading = true;

        const payload = {
          name: this.userData.name,
          email: this.userData.email,
          password: this.userData.password,
          company: this.$store.getters["auth/user"]?.id_companies,
        };

        await UserManageApiService().createUser(payload);

        this.dialog = false;
        this.afterCreateCallback();
      } catch (e) {
        console.error("Error while updating user data.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
