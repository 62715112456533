<template>
  <div class="data-table-component pt-4 pb-4">
    <v-data-table
      :headers="componentData()?.headers"
      :items="componentData()?.items"
      dense
      :items-per-page="25"
      :footer-props="{ itemsPerPageOptions: [25, 50, 150, 300, -1] }"
    >
      <template
        v-for="header in componentData()?.headers"
        #[`item.${header.value}`]="{ value }"
      >
        <div :key="header.value">
          <template v-if="header.value === 'json'">
            <template v-if="String(value).length > 100">
              <v-chip
                label
                outlined
                small
                @click="showFullData(value)"
                class="pl-2"
              >
                show all
                <v-icon small right style="opacity: 0.5">
                  mdi-information-outline
                </v-icon>
              </v-chip>
            </template>
          </template>
          <template
            v-else-if="['created_at', 'updated_at'].includes(header.value)"
          >
            <div :title="value">
              {{ value ? $moment(value).format("ll") : "" }}
            </div>
          </template>
          <template v-else>
            {{ value }}
          </template>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="isDialogVisible"
      content-class="my-shadow--e2 data-table-component__dialog"
      width="600"
      scrollable
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          <b>Full text preview</b>
          <v-spacer />
          <v-icon @click="isDialogVisible = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-title>
          <v-radio-group
            dense
            v-model="dialogContentFormat"
            hide-details
            class="ma-0 d-flex inline"
          >
            <v-radio label="Text" value="text"></v-radio>
            <v-radio label="Code" value="code"></v-radio>
          </v-radio-group>
        </v-card-title>
        <v-card-text class="pt-3">
          <template v-if="dialogContentFormat === 'text'">
            {{ dialogContent }}
          </template>
          <template v-else-if="dialogContentFormat === 'code'">
            <pre>
              {{ dialogContent }}
            </pre>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  inject: ["componentData"],

  data: () => ({
    isDialogVisible: false,
    dialogContent: null,
    dialogContentFormat: "text",
  }),

  methods: {
    showFullData(text) {
      this.isDialogVisible = true;
      this.dialogContent = text;
    },
  },
};
</script>
