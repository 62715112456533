<template>
  <div :class="getClasses" @click="$emit('click')">
    <!--    <template v-if="doubled">-->
    <!--      <template v-if="data.type || data.subproj || data.page">-->
    <!--        <div class="double-percentage-field">-->
    <!--          <div>-->
    <!--            <v-tooltip bottom>-->
    <!--              <template v-slot:activator="{ on }">-->
    <!--                <div-->
    <!--                  class="percentage-field__progress-bar"-->
    <!--                  v-on="on"-->
    <!--                  :style="`-->
    <!--                    width:${calculateProgressBarW(data, true)[1]} ;-->
    <!--                    background:${calculateProgressBarBg(data, true)[1]};-->
    <!--                    `"-->
    <!--                />-->
    <!--              </template>-->
    <!--              <div style="text-align: center">-->
    <!--                <small>Pages</small>-->
    <!--              </div>-->
    <!--              <div>-->
    <!--                <b style="font-size: 18px"-->
    <!--                  >{{ data.done.pages }} / {{ data.amount.pages }}</b-->
    <!--                >-->
    <!--              </div>-->
    <!--            </v-tooltip>-->
    <!--            <span>{{ data.amount.pages }}</span>-->
    <!--          </div>-->
    <!--          <div>-->
    <!--            <v-tooltip bottom>-->
    <!--              <template v-slot:activator="{ on }">-->
    <!--                <div-->
    <!--                  class="percentage-field__progress-bar"-->
    <!--                  v-on="on"-->
    <!--                  :style="`-->
    <!--                    width:${calculateProgressBarW(data, true)[0]};-->
    <!--                    background:${calculateProgressBarBg(data, true)[0]};-->
    <!--                    `"-->
    <!--                />-->
    <!--              </template>-->
    <!--              <div style="text-align: center">-->
    <!--                <small> Pages with elements </small>-->
    <!--              </div>-->
    <!--              <div style="text-align: center">-->
    <!--                <b style="font-size: 18px"-->
    <!--                  >{{ data.done.pagesWithElems }} /-->
    <!--                  {{ data.amount.pagesWithElems }}</b-->
    <!--                >-->
    <!--              </div>-->
    <!--            </v-tooltip>-->
    <!--            <span>{{ data.amount.pagesWithElems }}</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--      <template v-else>-->
    <!--        {{ data.amount.pagesWithElems }} | {{ data.amount.pages }}-->
    <!--      </template>-->
    <!--    </template>-->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div
          class="percentage-field__progress-bar"
          v-on="on"
          :style="`
              width:${calculateProgressBarW(data)};
              background:${calculateProgressBarBg(data)};
              `"
        />
      </template>
      <span>{{ getDone }} / {{ data.amount }}</span>
    </v-tooltip>
    <span>{{ data.amount }}</span>
  </div>
</template>

<script>
export default {
  props: ["data", "doubled"],
  computed: {
    getDone() {
      /*eslint-disable*/
      if (
        typeof this.data.done === "object" &&
        this.data.done.hasOwnProperty("value")
      ) {
        return this.data.done.value;
      }

      return this.data.done;
    },
    getClasses() {
      const classes = [];
      const data = this.data;

      if (data.amount) classes.push("amount-td");
      if (data.type || data.subproj || data.page)
        classes.push("percentage-field");
      if (data.type) classes.push("left");
      if (data.subproj) classes.push("center");
      if (data.page) classes.push("right");

      return classes.join(" ");
    },
  },
  methods: {
    calculateProgressBarBg(data, doubled = false) {
      const replaceNan = this.replaceNan;
      const blue = "#1976d284";
      const green = "#63bd3d89";
      if (doubled) {
        const pagePercent =
          (replaceNan(data.done.pages) / replaceNan(data.amount.pages)) * 100;
        const pagesWithElemsPercent =
          (replaceNan(data.done.pagesWithElems) /
            replaceNan(data.amount.pagesWithElems)) *
          100;
        return [
          pagesWithElemsPercent === 100 ? green : blue,
          pagePercent === 100 ? green : blue,
        ];
      }
      let color = "rgba(99, 189, 61, 0.54)";
      const percent = (Number(this.getDone) / Number(data.amount)) * 100;
      if (percent !== 100) {
        color = blue;
      }
      return color;
    },
    fixReturnedPercent(percent) {
      if (percent === "-") {
        percent = 0;
      }

      if (percent > 100) {
        return "calc(100% - 6px)";
      }

      if (percent > 0 && percent <= 20) {
        return "10%";
      }

      if (percent) {
        return `calc(${Math.round(percent)}% - 6px)`;
      }

      return 0;
    },
    calculateProgressBarW(data, doubled = false) {
      const replaceNan = this.replaceNan;
      if (doubled) {
        const pagesWithElems = replaceNan(data.amount.pagesWithElems);
        const pagesWithElemsDone = replaceNan(data.done.pagesWithElems);
        const pages = replaceNan(data.amount.pages);
        const pagesDone = replaceNan(data.done.pages);
        const pagePercent = (pagesDone / pages) * 100;
        const pagesWithElemsPercent =
          (pagesWithElemsDone / pagesWithElems) * 100;
        return [
          this.fixReturnedPercent(pagesWithElemsPercent),
          this.fixReturnedPercent(pagePercent),
        ];
      }
      const done = Number(this.getDone);
      const amount = Number(data.amount);
      const percent = (done / amount) * 100;
      return `${this.fixReturnedPercent(percent)}`;
    },
    replaceNan(val) {
      return isNaN(val) ? 0 : Number(val);
    },
  },
};
</script>
