<template>
  <div :style="{ 'white-space': 'nowrap', color: getColor }">
    <v-icon :color="getColor" style="margin-right: -5px">
      {{ getIcon }}
    </v-icon>
    <b>{{ getValue }}</b>
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getValue() {
      if (!this.data && this.data !== 0) return "";
      return String(this.data).replaceAll("-", "");
    },
    getColor() {
      if (this.data === 0 || this.data === null) {
        return "inherit";
      }
      if (this.data > 0) {
        return "green";
      }
      return "red";
    },
    getIcon() {
      if (this.data === 0 || this.data === null) {
        return "";
      }
      if (this.data > 0) {
        return "mdi-triangle-small-up";
      }
      return "mdi-triangle-small-down";
    },
  },
};
</script>
