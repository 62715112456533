<template>
  <v-row dense>
    <v-col cols="12">
      <section-name class="ma-0">
        {{ cardData.sectionName || cardData.section_name }}
        <v-btn @click="handleRefreshSection" icon class="mr-2"
          ><v-icon small>mdi-refresh</v-icon></v-btn
        >
        <v-chip v-if="cardData.queue" outlined color="warning" small
          >Q: <b class="ml-2">{{ cardData.queue }}</b></v-chip
        >
        <v-chip
          v-else-if="String(cardData.queue) === '0'"
          color="success"
          small
          outlined
        >
          All good <v-icon small right>mdi-check</v-icon>
        </v-chip>
      </section-name>
    </v-col>
    <v-col cols="12">
      <v-chip
        @click="handleCreateTask(cardData)"
        outlined
        small
        label
        title="Create worksection task"
        :disabled="loadings.createTask.includes(cardData.id)"
      >
        create task
        <v-progress-circular
          v-if="loadings.createTask.includes(cardData.id)"
          indeterminate
          size="18"
          width="2"
          class="ml-2"
        />
      </v-chip>
      <v-chip
        @click="handleCreateFastTask(cardData)"
        outlined
        small
        label
        class="ml-2"
        title="Create worksection task"
        :disabled="loadings.createTask.includes(cardData.id)"
      >
        fast task
        <v-progress-circular
          v-if="loadings.createTask.includes(cardData.id)"
          indeterminate
          size="18"
          width="2"
          class="ml-2"
        />
        <v-icon small right>mdi-chevron-right</v-icon>
      </v-chip>

      <a
        v-if="cardData.worksection"
        :href="cardData.worksection"
        target="_blank"
        class="ml-2"
        title="Visit worksection"
      >
        <v-chip outlined small @click="handleRefreshSection" label>
          Visit WS
          <v-icon small class="ml-2">mdi-open-in-new</v-icon>
        </v-chip>
      </a>
    </v-col>
    <template v-if="cardData.items.length > 0">
      <template v-for="sectionItem in cardData.items">
        <v-col :key="sectionItem.id" cols="12">
          <template v-if="sectionItem.type === 'graphs'">
            <v-row dense>
              <v-col
                v-for="graph in sectionItem.items"
                :key="graph.id"
                :cols="graph.colspan || 6"
              >
                <time-chart
                  :series="graph.series"
                  :title="graph.name"
                  :categories="graph.categories"
                  :footer-title="graph.legend"
                  :data="graph"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else-if="sectionItem.type === 'cards'">
            <v-row dense>
              <v-col v-for="card in sectionItem.items" :key="card.id" cols="3">
                <info-card :data="card" />
              </v-col>
            </v-row>
          </template>
          <template v-else-if="sectionItem.type === 'signature_table'">
            <cron-table :items="sectionItem.items" />
          </template>
          <template v-else-if="sectionItem.type === 'aparser_task_table'">
            <dev-dashboard-aparser-task-table :items="sectionItem.items" />
          </template>
          <template v-else-if="sectionItem.type === 'top_100_table'">
            <dev-dashboard-top100-table :items="sectionItem.items" />
          </template>
          <template v-else-if="sectionItem.type === 'table'">
            <v-data-table
              :headers="sectionItem.headers"
              :items="sectionItem.items"
              :items-per-page="25"
              :footer-props="{ itemsPerPageOptions: [25, 50, 150, 300, -1] }"
            >
              <template #[`item.json`]="{ value }">
                <template v-if="String(value).length > 300">
                  {{ String(value).slice(0, 300) }}...
                  <v-menu
                    bottom
                    content-class="my-shadow--e3"
                    :close-on-content-click="false"
                    nudge-bottom="18"
                    max-width="1000"
                  >
                    <template #activator="{ on }">
                      <v-icon v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <template #default>
                      <v-card class="pa-4">
                        {{ value }}
                      </v-card>
                    </template>
                  </v-menu>
                </template>
              </template>
            </v-data-table>
          </template>
          <template v-else-if="sectionItem.type === 'tiles'">
            <dev-dashboard-aparser-tyles :items="sectionItem.items" />
          </template>
          <template v-else-if="sectionItem.type === 'progress'">
            <v-row dense>
              <v-col
                v-for="progress in sectionItem.items"
                :key="progress.id"
                cols="12"
              >
                <dev-dashboard-progress-bar :data="progress" />
              </v-col>
            </v-row>
          </template>
          <template v-else-if="sectionItem.type === 'horizontal_column'">
            <v-row dense>
              <v-col
                v-for="graph in sectionItem.items"
                :key="graph.id"
                :cols="graph.colspan || 6"
              >
                <dev-dashboard-horizontal-chart :data="graph" />
              </v-col>
            </v-row>
          </template>
          <template v-else-if="sectionItem.type === 'chart_table'">
            <dev-dashboard-table-chart :data="sectionItem" />
          </template>
        </v-col>
      </template>
    </template>

    <v-col v-if="cardData?.error" cols="12">
      <v-alert type="error" text border="left" dense>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <b>{{ cardData.sectionName }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>{{ cardData.error }}</div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
/*eslint-disable*/
import TimeChart from "../TimeChart.vue";
import InfoCard from "../InfoCard.vue";
import CronTable from "./CronTable.vue";
import DevDashboardAparserTaskTable from "./DevDashboardAparserTaskTable.vue";
import DevDashboardTop100Table from "./DevDashboardTop100Table.vue";
import DevDashboardAparserTyles from "./DevDashboardAparserTiles.vue";
import SectionName from "../SectionName.vue";
import DevDashboardProgressBar from "./DevDashboardProgressBar.vue";
import DevDashboardHorizontalChart from "./DevDashboardHorizontalChart.vue";
import DevDashboardTableChart from "./DevDashboardTableChart.vue";
import service from "@/plugins/service";

export default {
  components: {
    DevDashboardTableChart,
    DevDashboardHorizontalChart,
    DevDashboardProgressBar,
    SectionName,
    DevDashboardAparserTyles,
    DevDashboardTop100Table,
    DevDashboardAparserTaskTable,
    CronTable,
    InfoCard,
    TimeChart,
  },
  props: {
    cardData: {
      type: Object,
      default: () => ({
        id: Number,
        sectionName: String,
        items: Array,
        type: String,
      }),
    },
    sectionData: {
      type: Object,
      default: () => ({}),
    },
    isCard: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createTask", "createFastTask"],
  data: () => ({
    loadings: {
      createTask: [],
    },
  }),
  methods: {
    handleCreateTask(cardData) {
      this.$emit("createTask", cardData);
    },
    async handleCreateFastTask(cardData) {
      try {
        const url = `/dashboards/dev/monitor/action/${this.sectionData.id}/create`;

        this.loadings.createTask.push(cardData.id);

        const resp = await service.get(url);

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Success",
            text: `The task was successfully created. <br/> <a href="${resp.data.worksection}" target="_blank">${resp.data.worksection}</a>`,
            type: "success",
            duration: 20000,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.createTask = this.loadings.createTask.filter(
          (v) => v !== cardData.id
        );
      }
    },
    handleRefreshSection() {
      this.$emit("refresh", this.sectionData.sectionData);
    },
  },
};
</script>
