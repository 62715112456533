<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfigs.title"
          :breadcrumbs="pageConfigs.breadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <div class="mb-2 d-flex">
          <v-spacer />
          <div class="d-flex" style="gap: 0.25rem">
            <v-btn
              height="40"
              class="text-normal"
              @click="fetchRoles"
              outlined
              :loading="loadings.roles"
            >
              Get data
            </v-btn>
            <v-btn
              height="40"
              color="primary"
              class="text-normal"
              @click="isCreateRoleDialogVisible = true"
            >
              Create role <v-icon right small>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <v-data-table
          dense
          :items="dataTable.items"
          :headers="dataTable.headers"
          class="border rounded-lg"
          :loading="loadings.roles"
          :page.sync="pagination.current"
          :items-per-page.sync="pagination.per_page"
          :server-items-length="pagination.total"
          @update:page="onPageChange"
          @update:items-per-page="onRowsPerPageChange"
          :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
        >
          <template #[`item._actions`]="{ item }">
            <v-menu
              bottom
              offset-y
              :close-on-content-click="false"
              content-class="my-shadow--e3"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :loading="loadings.edit === item.id"
                  :disabled="!!loadings.edit"
                >
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card class="styled-card--default pa-0" width="120">
                <v-list dense>
                  <v-list-item @click="handleEditRole(item)">
                    <v-icon small left>mdi-pencil</v-icon> Edit
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>
        </v-data-table>
        <!--3. Edit role-->
      </v-col>
    </v-row>
    <create-role-dialog
      v-model="isCreateRoleDialogVisible"
      :after-create-callback="() => fetchRoles()"
    />
    <edit-role-dialog
      v-model="isEditRoleDialogVisible"
      :data="editData"
      @afterEdit="handleAfterEdit"
      @dialogClosed="handleAfterEdit"
    />
  </v-container>
</template>

<script>
import PageHeader from "../UI/PageHeader.vue";
import { PAGE_CONFIG, ROLES_DATA_TABLE_HEADERS } from "./defaults";
import { RolesApiService } from "./services/RolesApiService";
import CreateRoleDialog from "./components/CreateRoleDialog.vue";
import EditRoleDialog from "./components/EditRoleDialog.vue";

export default {
  components: { EditRoleDialog, CreateRoleDialog, PageHeader },

  data: () => ({
    pageConfigs: PAGE_CONFIG,
    searchQuery: "",
    isCreateRoleDialogVisible: false,
    isEditRoleDialogVisible: false,
    editData: null,
    dataTable: {
      items: [],
      headers: ROLES_DATA_TABLE_HEADERS,
    },
    loadings: {
      roles: false,
      edit: null,
    },
    pagination: {
      current: 1,
      per_page: 50,
      total: 0,
    },
  }),

  mounted() {
    this.fetchRoles();
  },

  methods: {
    handleAfterEdit() {
      this.editData = null;
    },
    async handleEditRole(role) {
      this.editData = await this.fetchRole(role);
      this.isEditRoleDialogVisible = true;
    },
    async fetchRole(role) {
      try {
        this.loadings.edit = role.id;

        const { data } = await RolesApiService().getRole(role.id);

        return data;
      } catch (e) {
        console.error("Error while fetching roles.", e);
      } finally {
        this.loadings.edit = null;
      }
    },
    async fetchRoles() {
      try {
        this.loadings.roles = true;

        const { data } = await RolesApiService().getRoles({
          page: this.pagination.current,
          rowsPerPage: this.pagination.per_page,
        });

        this.dataTable.items = data.data;
        this.pagination.total = data.total;
        this.pagination.current = data.current_page;
        this.pagination.per_page = data.per_page;
      } catch (e) {
        console.error("Error while fetching roles.", e);
      } finally {
        this.loadings.roles = false;
      }
    },
    onPageChange(newPage) {
      this.pagination.current = newPage;
      this.fetchRoles();
    },
    onRowsPerPageChange(newRowsPerPage) {
      this.pagination.per_page = newRowsPerPage;
      this.fetchRoles();
    },
  },
};
</script>
