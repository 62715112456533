<template>
  <v-container class="niche-analyzer-add">
    <v-row>
      <v-col cols="12">
        <h2>Add New Project</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col cols="12">
        <v-card class="styled-card--light">
          <v-stepper v-model="step" elevation="0">
            <v-stepper-header style="box-shadow: none">
              <v-stepper-step :step="1" :complete="step > 1">
                Create Project
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :step="2" :complete="step > 2">
                Search Engine
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :step="3" :complete="step > 3">
                Keywords
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :step="4" :complete="step > 4">
                Domains Owner
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :step="5" :complete="step > 5">
                Scheduler
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :step="6" :complete="step > 6">
                Finish
              </v-stepper-step>
            </v-stepper-header>
            <v-divider />
            <v-stepper-items>
              <v-stepper-content :step="1">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.name"
                      hide-details="auto"
                      dense
                      filled
                      hide-spin-buttons
                      :rules="[(v) => !!v || 'Project name is required']"
                      label="*Name"
                      placeholder="Enter name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <tiptap-vuetify
                      class="my-tiptap-editor"
                      v-model="form.description"
                      placeholder="Enter description"
                      @blur="isDescriptionInputDirty = true"
                      :extensions="extensions"
                    />
                    <small
                      v-if="isDescriptionInputDirty && !form.description"
                      class="error--text mt-1 ml-2"
                    >
                      Description is required
                    </small>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content :step="2">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.country"
                      :items="filtersData.country"
                      hide-details="auto"
                      dense
                      filled
                      :rules="[(v) => !!v || 'Country is required']"
                      label="Country"
                      placeholder="Type to search"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.language"
                      :items="languages"
                      hide-details="auto"
                      dense
                      filled
                      item-text="name"
                      item-value="id"
                      :rules="[(v) => !!v || 'Language is required']"
                      label="Language"
                      placeholder="Type to search"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content :step="3">
                <v-row>
                  <v-col cols="8">
                    <v-row dense>
                      <v-col cols="12"
                        >1. Put the columns to import in the order in which you
                        make the data</v-col
                      >
                      <v-col cols="12">
                        <v-text-field
                          v-model="form.keyword_headers"
                          hide-details
                          filled
                          dense
                          label="Headers"
                          placeholder="Enter headers"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">2. Insert data (without headers)</v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="form.keywords_body"
                          hide-details
                          dense
                          filled
                          label="Insert data"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12"> Or import as file </v-col>
                      <v-col cols="12">
                        <drop-file-zone
                          :model-value="form.file"
                          @update:modelValue="form.file = $event"
                          :supportedTypes="['csv']"
                        />
                      </v-col>
                      <v-col cols="12" class="d-flex">
                        <a
                          :href="`${apiUrl}/examples/niche-analyzer.csv`"
                          target="_blank"
                        >
                          <v-chip color="transparent" label @click="() => {}">
                            Example file
                            <v-icon small right>mdi-download</v-icon></v-chip
                          >
                        </a>
                        <v-spacer />
                        <v-chip
                          style="width: 180px"
                          class="d-flex justify-center"
                          label
                          :color="keywordsUploaded ? 'success' : 'primary'"
                          :disabled="loadings.importKeywords"
                          @click="importKeywords"
                        >
                          Import Keywords
                          <v-progress-circular
                            v-if="loadings.importKeywords"
                            indeterminate
                            size="18"
                            width="2"
                          />
                          <v-icon v-else-if="keywordsUploaded" small right>
                            mdi-check
                          </v-icon>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="styled-card--default">
                      <v-card-title> Instruction </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <ul>
                          <li>Enter one entity per line.</li>
                          <li>
                            You can do it manually or by copying from any text
                            editor.
                          </li>
                          <li>Separator: «;»</li>
                          <li>
                            Steps:
                            <ol>
                              <li>Put the columns to import</li>
                              <li>Insert keywords data</li>
                              <li>Press "Keyword Import" button</li>
                              <li>Press "Next" button</li>
                            </ol>
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>
                    <v-card class="styled-card--default mt-2">
                      <v-card-title> Existed headers </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <div class="d-flex flex-wrap" style="gap: 8px">
                          <v-chip
                            v-for="name in [
                              'phrase',
                              'volume',
                              'category',
                              'sub_category',
                            ]"
                            :key="name"
                            @click="handleCopy(name)"
                            outlined
                            label
                          >
                            {{ name }}
                            <v-icon small right>mdi-content-copy</v-icon>
                          </v-chip>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content :step="4">
                <v-row>
                  <v-col cols="8">
                    <v-row dense>
                      <v-col cols="12"
                        >1. Put the columns to import in the order in which you
                        make the data</v-col
                      >
                      <v-col cols="12">
                        <v-text-field
                          v-model="form.domain_headers"
                          hide-details
                          filled
                          dense
                          label="Headers"
                          placeholder="Enter headers"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">2. Insert data (without headers)</v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="form.domain_body"
                          hide-details
                          dense
                          filled
                          label="Insert data"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" class="d-flex">
                        <v-spacer />
                        <v-chip
                          style="width: 180px"
                          class="d-flex justify-center"
                          label
                          :color="domainsUploaded ? 'success' : 'primary'"
                          :disabled="loadings.importDomains"
                          @click="importDomain"
                        >
                          Import Owners
                          <v-progress-circular
                            v-if="loadings.importDomains"
                            indeterminate
                            size="18"
                            width="2"
                          />
                          <v-icon v-else-if="domainsUploaded" small right>
                            mdi-check
                          </v-icon>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-card class="styled-card--default">
                      <v-card-title> Instruction </v-card-title>
                      <v-divider />
                      <v-card-text>
                        <ul>
                          <li>Enter one entity per line.</li>
                          <li>
                            You can do it manually or by copying from any text
                            editor.
                          </li>
                          <li>Separator: «;»</li>
                          <li>
                            Steps:
                            <ol>
                              <li>Put the columns to import</li>
                              <li>Insert domain and company data</li>
                              <li>Press "Owners Import" button</li>
                              <li>Press "Next" button</li>
                            </ol>
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content :step="5">
                <v-row dense>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-card class="styled-card--default" width="660">
                      <v-card-title> Scheduler </v-card-title>
                      <v-card-subtitle>
                        Select 1-2 days for which data will be updated.
                      </v-card-subtitle>
                      <v-divider />
                      <v-card-text>
                        <v-row dense>
                          <v-col v-for="day in days" :key="day.value">
                            <v-checkbox
                              v-model="form.scheduler"
                              :label="day.text"
                              :value="day.value"
                              class="ma-0"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content :step="6">
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <div v-if="loadings.saveProject" class="py-6">
                      Wait until data processed
                      <v-progress-circular
                        class="ml-2"
                        indeterminate
                        size="16"
                        width="2"
                      />
                    </div>
                    <template
                      v-else-if="
                        !loadings.saveProject && !isProjectSuccessfullyCreated
                      "
                    >
                      <v-card class="styled-card--default">
                        <v-card-title>
                          The data you have filled in
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-simple-table>
                            <tbody>
                              <tr>
                                <td>Project name</td>
                                <td>
                                  <b>
                                    {{ form.name }}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td>Project description</td>
                                <td>
                                  <span v-html="form.description"> </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Country</td>
                                <td>
                                  <b v-if="form.country">
                                    {{
                                      (
                                        filtersData.country.find(
                                          (v) => v.value === form.country
                                        ) || {}
                                      ).text
                                    }}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td>Language</td>
                                <td>
                                  <b v-if="form.language">
                                    {{
                                      (
                                        languages.find(
                                          (v) => v.id === form.language
                                        ) || {}
                                      ).name
                                    }}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td>Scheduler</td>
                                <td>
                                  <v-chip
                                    small
                                    class="mr-1"
                                    v-for="day in form.scheduler"
                                    :key="day"
                                  >
                                    {{ days[day - 1].text }}
                                  </v-chip>
                                </td>
                              </tr>
                              <tr>
                                <td>Keywords</td>
                                <td>
                                  <b>{{ form.keywords_body }}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Owners</td>
                                <td>
                                  <b>{{ form.domain_body }}</b>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </template>
                    <template v-if="isProjectSuccessfullyCreated">
                      <div
                        class="d-flex justify-center flex-column text-center"
                      >
                        <div>
                          <b>Project successfully created</b>
                        </div>
                        <div class="mt-4">
                          <v-chip
                            @click="handleReloadPage"
                            label
                            color="primary"
                          >
                            Create new one <v-icon>mdi-plus</v-icon>
                          </v-chip>
                        </div>
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-chip
              @click="step--"
              :disabled="validation('prev')"
              outlined
              label
              class="mr-2"
            >
              Prev</v-chip
            >
            <v-chip
              :disabled="validation('next') || loadings.saveProject"
              color="primary"
              class="d-flex justify-center"
              style="width: 120px"
              label
              @click="handleClickNextStep"
            >
              <template v-if="step === 6 && !isProjectSuccessfullyCreated">
                Finish
              </template>
              <template v-else> Next </template>
            </v-chip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
import error from "@/store/modules/error";
import service from "@/plugins/service";
import DropFileZone from "@/components/UI/DropFileZone.vue";
import copy from "copy-to-clipboard";
export default {
  computed: {
    apiUrl() {
      return service.getUrl("");
    },
    error() {
      return error;
    },
    languages() {
      return this.$store.state.reuse.languages;
    },
  },
  components: {
    DropFileZone,
    TiptapVuetify,
  },
  data: () => ({
    step: 1,
    isDescriptionInputDirty: false,
    form: {
      name: "",
      file: null,
      description: "",
      country: null,
      language: null,
      keyword_headers: "phrase;volume;",
      domain_headers: "company;domain;",
      keywords_body: "bluehost;10",
      domain_body: "google;google.com",
      scheduler: [1, 5],
    },
    filtersData: {
      country: [],
    },
    isProjectSuccessfullyCreated: false,
    loadings: {
      importKeywords: false,
      importDomains: false,
      filters: false,
      saveProject: false,
    },
    days: [
      { value: 1, text: "mon" },
      { value: 2, text: "tue" },
      { value: 3, text: "wed" },
      { value: 4, text: "thu" },
      { value: 5, text: "fri" },
      { value: 6, text: "sat" },
      { value: 7, text: "sun" },
    ],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Niche Analyzer",
        to: { name: "niche.analyzer.index" },
      },
      {
        text: "Add project",
        disabled: true,
      },
    ],
    projectId: null,
    keywordsUploaded: false,
    domainsUploaded: false,
  }),
  created() {
    this.fetchFilters();
    this.fetchProjectId();
  },
  methods: {
    handleCopy(data) {
      copy(data);
      this.$message.notification({
        title: "Copied",
        text: `Header <mark>${data}</mark> coppied.`,
        type: "success",
        duration: 10000,
        position: "lb",
      });
    },
    handleReloadPage() {
      window.location.reload();
    },
    handleClickNextStep() {
      if (this.step === 6) {
        return this.handleSaveProject();
      }
      this.step++;
    },
    async handleSaveProject() {
      try {
        const url = "/niche-analyzer/store-project";

        const payload = {
          country: this.form.language,
          language: this.form.language,
          scheduler: this.form.scheduler,
          description_project: this.form.description,
          name_project: this.form.name,
          project_id: this.projectId,
        };

        this.loadings.saveProject = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data.status) {
          this.isProjectSuccessfullyCreated = true;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.saveProject = false;
      }
    },
    async fetchProjectId() {
      try {
        const url = "/niche-analyzer/create-project";

        const payload = {};

        const resp = await service.post(url, payload);

        if (resp) {
          this.projectId = resp.data.project_id;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFilters() {
      this.loadings.filters = true;

      const filters = ["country", "language"];

      const payload = {
        type: "/df/donors",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };

      const resp = await this.$store.dispatch("global/getFilters", payload);
      this.loadings.filters = false;

      if (resp) {
        this.filtersData = { ...this.filtersData, ...resp };
      }
    },
    async importKeywords() {
      try {
        const url = "/niche-analyzer/upload-project-keys";

        const formData = new FormData();

        formData.append("file", this.form.file);
        formData.append("headers", this.form.keyword_headers);
        formData.append("country", this.form.country);
        formData.append("project_id", this.projectId);

        if (this.form.keywords_body !== null) {
          const keywords = this.form.keywords_body.split("\n");
          keywords.forEach((keyword) => {
            formData.append(`pack[]`, keyword);
          });
        }

        this.loadings.importKeywords = true;

        const resp = await service.post(url, formData);

        if (resp && resp.data.status) {
          this.keywordsUploaded = true;

          this.$message.notification({
            title: "Success",
            text: `Uploaded <b>${
              this.form.keywords_body.split("\n").length
            }</b> keywords`,
            type: "success",
            duration: 10000,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.importKeywords = false;
      }
    },
    async importDomain() {
      try {
        const url = "/niche-analyzer/upload-domains";

        const payload = {
          headers: this.form.domain_headers,
          pack:
            this.form.domain_body !== null
              ? this.form.domain_body.split("\n")
              : undefined,
          country: this.form.country,
          project_id: this.projectId,
        };

        this.loadings.importDomains = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data.status) {
          this.domainsUploaded = true;

          this.$message.notification({
            title: "Success",
            text: `Uploaded <b>${resp.data.countDomains}</b> domains and <b>${resp.data.countOwners}</b> owners`,
            type: "success",
            duration: 10000,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.importDomains = false;
      }
    },
    validation(type) {
      let errors = 0;
      const step = this.step;
      if (type === "prev") {
        if (step === 1) errors++;
        return errors !== 0;
      }
      if (type === "next") {
        if (step === 1 && (!this.form.name || !this.form.description)) errors++;
        if (step === 2 && (!this.form.country || !this.form.language)) errors++;
        if (step === 3 && !this.keywordsUploaded) errors++;
        if (step === 4 && !this.domainsUploaded) errors++;
        if (step === 5 && this.form.scheduler.length === 0) errors++;
        return errors !== 0;
      }
    },
  },
};
</script>

<style lang="scss">
.niche-analyzer-add {
  .tiptap-vuetify-editor .ProseMirror {
    margin: 0 !important;
    padding: 20px !important;
  }
  .v-stepper {
    &__wrapper {
      padding: 10px !important;
    }
  }
}
</style>
