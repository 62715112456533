<template>
  <span>
    <template
      v-if="containGoogleIndexLink.includes(String(data.name).toLowerCase())"
    >
      {{ data.name }}
      <a
        :href="`https://www.google.com/search?q=site%3A${domain}`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="
        containGoogleTrustLink.includes(String(data.name).toLowerCase())
      "
    >
      {{ data.name }}
      <a :href="`https://www.google.com/search?q=${domain}`" target="_blank">
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="
        containWebArchiveLink.includes(String(data.name).toLowerCase())
      "
    >
      {{ data.name }}
      <a
        :href="`https://web.archive.org/web/20230000000000*/${domain}`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="
        containSimilarWebLink.includes(String(data.name).toLowerCase())
      "
    >
      {{ data.name }}
      <a :href="`https://www.similarweb.com/website/${domain}`" target="_blank">
        <v-icon color="primary" @click="() => {}" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="
        Object.keys(topicsDataPath).includes(String(data.name).toLowerCase())
      "
    >
      {{ data.name }}
      <v-icon
        @click="showTopicItemsInDialog(String(data.name).toLowerCase())"
        color="primary"
        small
        >mdi-information</v-icon
      >
    </template>
    <template
      v-else-if="
        Object.keys(ahrefsLabelToLinkData).includes(
          String(data.name).toLowerCase()
        )
      "
    >
      {{ data.name }}
      <a
        :href="`${
          ahrefsLabelToLinkData[String(data.name).toLowerCase()]
        }${domain}`"
        target="_blank"
      >
        <v-btn x-small icon>
          <svg
            width="8"
            height="12"
            viewBox="0 0 19 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.88758 0V4.96787H13.5637V8.24855C10.6801 8.31104 4.49698 8.73596 2.83301 9.93575C0.75305 11.4355 -0.334203 12.7946 0.0912439 17.2001C0.516691 21.6056 3.40028 22.5429 6.8984 22.9647C9.69689 23.3021 12.8231 21.1369 14.0365 20.0121H14.5092V22.5429H19V0H1.88758ZM6.52022 13.5914C7.61693 12.8415 11.6729 12.5915 13.5637 12.5603V15.8878C13.2328 16.419 11.8998 17.6125 9.21472 18.1374C6.52967 18.6623 5.63781 17.3563 5.52751 16.6377C5.40145 15.9347 5.42351 14.3412 6.52022 13.5914Z"
              fill="#FF8800"
            />
          </svg>
        </v-btn>
      </a>
    </template>
    <template
      v-else-if="
        Object.keys(labelsWithNavigateSource).includes(
          String(data.name).toLowerCase()
        )
      "
    >
      {{ data.name }}
      <v-icon
        @click="navigateTo(String(data.name).toLowerCase())"
        color="primary"
        small
        >mdi-navigation-variant-outline</v-icon
      >
    </template>
    <template v-else-if="String(data.name).toLowerCase() === 'intitle c, num'">
      {{ data.name }}
      <a
        :href="`https://www.google.com/search?q=site%3A${domain} intitle:${casinoPhrases.join(
          '|'
        )}`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template v-else-if="String(data.name).toLowerCase() === 'intext c, num'">
      {{ data.name }}
      <a
        :href="`https://www.google.com/search?q=site%3A${domain} intext:${casinoPhrases.join(
          '|'
        )}`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="
        String(data.name).toLowerCase() === 'sr key 5y' ||
        String(data.name).toLowerCase() === 'sr key 3y' ||
        String(data.name).toLowerCase() === 'sr tr 5y' ||
        String(data.name).toLowerCase() === 'sr tr 3y'
      "
    >
      {{ data.name }}
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon v-on="on" style="opacity: 0.5" small>
            mdi-information-outline
          </v-icon>
        </template>
        <div>
          <div>from 50 to 100 - growing donors</div>
          <div>from 50 to 0 - falling, within the given period</div>
        </div>
      </v-tooltip>
    </template>
    <template
      v-else-if="String(data.name).toLowerCase() === 'intitle 1m c, num'"
    >
      {{ data.name }}
      <a
        :href="`https://www.google.com/search?q=site%3A${domain}%20intitle:casino|casinos|gambling|poker|roulette|blackjack&tbs=qdr:m`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="String(data.name).toLowerCase() === 'intext 1m c, num'"
    >
      {{ data.name }}
      <a
        :href="`https://www.google.com/search?q=site%3A${domain}%20intext:casino|casinos|gambling|poker|roulette|blackjack&tbs=qdr:m`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="String(data.name).toLowerCase() === 'intitle 1m b, num'"
    >
      {{ data.name }}
      <a
        :href="`https://www.google.com/search?q=site%3A${domain}%20intitle:betting|sportsbook|odds|scommesse|bets&tbs=qdr:m`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template
      v-else-if="String(data.name).toLowerCase() === 'intext 1m b, num'"
    >
      {{ data.name }}
      <a
        :href="`https://www.google.com/search?q=site%3A${domain}%20intext:betting|sportsbook|odds|scommesse|bets&tbs=qdr:m`"
        target="_blank"
      >
        <v-icon color="primary" small>mdi-open-in-new</v-icon>
      </a>
    </template>
    <template v-else>
      {{ data.name }}
    </template>
    <v-dialog
      v-model="topicsDialogModel"
      class="remove-dialog-shadow"
      max-width="600"
    >
      <v-card class="shadow-e1-bordered" rounded="lg">
        <v-card-title>✨ Topics ✨</v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :items="topicsDialogItems"
            :headers="topicsDialogHeaders"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [20, 50, 150, 300, -1] }"
          ></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import eventPipe from "../../../events/eventPipe";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    casinoPhrases() {
      return (
        (this.$store.getters["site-analysis/staticData"] || {})
          .casino_phrases || []
      );
    },
    domain() {
      return this.$store.getters["site-analysis/activeDomain"] || "";
    },
  },
  data: () => ({
    topicsDialogModel: false,
    topicsDialogItems: [],
    topicsDialogHeaders: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "v1",
        value: "v1",
      },
      {
        text: "v2",
        value: "v2",
      },
    ],
    topicsDataPath: {
      "tr c i (sw)": "casino_ilya_topics",
      "tr c (sw)": "topics",
      "tr e (sw)": "essay_topics",
    },
    ahrefsLabelToLinkData: {
      "ah traffic":
        "https://app.ahrefs.com/v2-site-explorer/overview?mode=subdomains&target=",
      "ah dr":
        "https://app.ahrefs.com/v2-site-explorer/overview?mode=subdomains&target=",
      "ah pages":
        "https://app.ahrefs.com/v2-site-explorer/overview?mode=subdomains&target=",
      "ah lrd":
        "https://app.ahrefs.com/v2-site-explorer/outgoing-domains?mode=subdomains&target=",
      "ah rd":
        "https://app.ahrefs.com/v2-site-explorer/backlinks?followType=all&grouping=one-per-domain&sort=ur&sortDirection=desc&mode=subdomains&target=",
      "ah top pages":
        "https://app.ahrefs.com/v2-site-explorer/top-pages?mode=subdomains&target=",
      "ah top keys":
        "https://app.ahrefs.com/v2-site-explorer/organic-keywords?mode=subdomains&target=",
    },
    containAhrefsLink: ["ah traffic", "ah dr", "ah pages"],
    containGoogleIndexLink: ["google index"],
    labelsWithNavigateSource: {
      "!top 5p": "AH Top Pages",
      ahtpin10: "AH Top Pages",
      "tr theme >=1, num": "TR Site Topics",
      "tr category, num": "TR Category",
      "sw pro c, uniqkeys": "SW Keys",
      bctr: "AH Detailed by country",
      "sw b country": "AH Detailed by country",
    },
    containGoogleTrustLink: ["google trust"],
    containSimilarWebLink: ["sw global rank"],
    containWebArchiveLink: ["ws creation"],
  }),
  methods: {
    navigateTo(labelName) {
      eventPipe.$emit(
        "site-analytics-navigate-to",
        this.labelsWithNavigateSource[labelName] || "start"
      );
    },
    showTopicItemsInDialog(labelName) {
      const topicsData = this.$store.getters["site-analysis/topicsData"];

      if (!topicsData || !labelName) return [];

      const path = this.topicsDataPath[labelName];
      const result = topicsData.soft_cosine && topicsData.soft_cosine[path];

      if (!result) return [];

      this.topicsDialogItems = result;
      this.topicsDialogModel = true;
    },
  },
};
</script>
