import service from "../../../../plugins/service";

export const promptsApiService = () => {
  const endpoints = {
    prompts: "/cb/prompts",
  };

  const getPrompts = () => {
    return service.get(endpoints.prompts);
  };

  const addPrompt = (formData) => {
    return service.post(endpoints.prompts, formData);
  };

  const getPrompt = (id) => {
    return service.get(endpoints.prompts + `/${id}`);
  };

  const editPrompt = (id, formData) => {
    return service.put(endpoints.prompts + `/${id}`, formData);
  };

  return {
    getPrompts,
    addPrompt,
    getPrompt,
    editPrompt,
  };
};
