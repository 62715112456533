<template>
  <div>
    <template v-if="String(data.name).toLowerCase() === 'dr'">
      <div class="d-flex align-center justify-center">
        {{ data.value }}
        <v-icon class="ml-1" small :color="getStatusColor(data, true)"
          >mdi-circle</v-icon
        >
      </div>
    </template>
    <template
      v-else-if="String(data.name).toLowerCase() === 'ltt gp, num links'"
    >
      <v-chip
        :disabled="loadings.getDetails"
        small
        label
        class="pl-2"
        outlined
        @click="handleGetDetails('links_ltt')"
      >
        {{ data.value }}
        <v-icon x-small right>mdi-information-outline</v-icon>
      </v-chip>
    </template>
    <template v-else-if="matchSlGpNumLinks(String(data.name).toLowerCase())">
      <v-chip
        :disabled="loadings.getDetails"
        small
        class="pl-2"
        label
        outlined
        @click="handleGetDetails('links_sl')"
      >
        {{ data.value }}
        <v-icon x-small right>mdi-information-outline</v-icon>
      </v-chip>
    </template>
    <template v-else>
      <span :style="{ color: getStatusColor(data) }">{{ data.value }}</span>
    </template>
    <v-dialog
      v-model="dialog"
      max-width="1200"
      content-class="remove-dialog-shadow"
    >
      <v-card class="styled-card--default">
        <v-card-title style="font-size: 14px" class="d-flex">
          {{ data.name }} details
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table :headers="detailsHeaders" :items="details">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/plugins/service";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    getStatusColor: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    loadings: {
      getDetails: false,
    },
    details: null,
    dialog: false,
    detailsHeaders: [
      {
        value: "phrase",
        text: "Phrase",
      },
      {
        value: "acceptor_url",
        text: "Acceptor URL",
      },
      {
        value: "donor_url",
        text: "Donor URL",
      },
      {
        value: "link_date",
        text: "Link Date",
      },
    ],
  }),
  methods: {
    matchSlGpNumLinks(string) {
      const regex = /sl \d+ gp, num links/;

      return regex.test(string);
    },
    async handleGetDetails(type) {
      try {
        const url = `/view-info-by-site/get-data`;

        const configs = {
          params: {
            first: this.isfirstFetch,
            force: false,
            type: type,
            source: "view-info",
            ...this.$store.getters["site-analysis/filters"],
            niche_analyzer_id: String(
              this.$store.getters["site-analysis/filters"].niche_analyzer_id
            ).split(","),
          },
        };

        this.loadings.getDetails = true;

        const resp = await service.get(url, true, configs);

        if (resp) {
          this.details = resp.data.data;
          this.dialog = true;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.getDetails = false;
      }
    },
  },
};
</script>
