<template>
  <div>
    <template v-for="(child, i) in childrens">
      <v-expansion-panels
        v-if="child.children"
        :key="i"
        accordion
        hover
        flat
        active-class="primary--text"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              {{ child.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <parent :tree="[child]" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else :key="i" class="text-start py-4">
        — {{ child.name }}
        <v-icon class="ml-2" small @click="handleShowInformationAbout(child)"
          >mdi-information-outline</v-icon
        >
      </div>
    </template>
  </div>
</template>

<script>
import Parent from "@/components/Pages/components/Parent.vue";
export default {
  props: ["childrens"],
  components: { Parent },
  methods: {
    handleShowInformationAbout(item) {
      this.$store.commit("pages/setActiveItem", item);
    },
  },
};
</script>
