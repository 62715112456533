export function checkRole(permissions, route) {
  return permissions.includes(route.name);
}

export function checkRouteAccess(routeName, flatPermissions) {
  try {
    return flatPermissions.includes(routeName);
  } catch {
    return false;
  }
}
