var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center",staticStyle:{"flex-wrap":"wrap"}},[_c('div',[_vm._v(" NT / Pr: "),_c('b',[_vm._v(_vm._s(_vm.data.url.need_top_from_url_control))]),_vm._v(" / "),_c('b',[_vm._v(_vm._s(_vm.data.url.priority_pages))])]),_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',[_vm._v(" GSC Cl / Imp: "),_c('b',[_vm._v(" "+_vm._s(_vm.formateClicks(_vm.data.url.sum_gsc_cl_by_28_days))+" ")]),_vm._v(" / "),_c('b',[_vm._v(" "+_vm._s(_vm.formateClicks(_vm.data.url.sum_gsc_imp_by_28_days))+" ")])]),_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',[_vm._v(" Int Uniq A / L: "),_c('div',{class:`colored-box ${_vm.getAnchorsChipColor(
        _vm.data.url.unique_anchors,
        'anchors'
      )}`},[_c('b',[_vm._v(_vm._s(_vm.data.url.unique_anchors))])]),_vm._v(" / "),_c('div',{class:`colored-box ${_vm.getAnchorsChipColor(
        _vm.data.url.unique_links,
        'links'
      )}`},[_c('b',[_vm._v(_vm._s(_vm.data.url.unique_links))])])]),_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',[_c('div',{class:`colored-box ${_vm.getDepthColor(_vm.data.url.depth)}`},[_vm._v(" D: "),_c('b',[_vm._v(_vm._s(_vm.data.url.depth))])])]),_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',[_c('div',{class:`colored-box ${_vm.getSwDlColor(_vm.data.url.sw_dl)}`},[(!_vm.data.url.sw_dl)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(" SW D: "),_c('b',[_vm._v(_vm._s(_vm.$moment(_vm.data.url.sw_dl).format("MMM DD")))])])])]),(parseInt(_vm.data.url.links_score) < 100)?[_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',{class:`colored-box ${_vm.getLinksColor(_vm.data.url.links_score)}`},[_vm._v(" Links: "),_c('b',[_vm._v(_vm._s(_vm.data.url.links_score))])])]:_vm._e(),(parseInt(_vm.data.url.tech_score) < 100)?[_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',{class:`colored-box ${_vm.getLinksColor(_vm.data.url.tech_score)}`},[_vm._v(" Technical: "),_c('b',[_vm._v(_vm._s(_vm.data.url.tech_score))])])]:_vm._e(),(parseInt(_vm.data.url.sw_score) < 100)?[_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',{class:`colored-box ${_vm.getLinksColor(_vm.data.url.sw_score)}`},[_vm._v(" SW: "),_c('b',[_vm._v(_vm._s(_vm.data.url.sw_score))])])]:_vm._e(),(parseInt(_vm.data.url.content_score) < 100)?[_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',{class:`colored-box ${_vm.getLinksColor(_vm.data.url.content_score)}`},[_vm._v(" Content: "),_c('b',[_vm._v(_vm._s(_vm.data.url.content_score))])])]:_vm._e(),(_vm.lCrawledOverdue(_vm.data.url.l_crawled))?[_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',{class:`colored-box error`},[_vm._v(" L Crawled: "),_c('b',[_vm._v(_vm._s(_vm.$moment(_vm.data.url.l_crawled).format("MMM DD")))])])]:_vm._e(),(_vm.data.url.code !== '200')?[_c('span',{staticClass:"mx-5"},[_vm._v("|")]),_c('div',{class:`colored-box error`},[_vm._v(" Code: "),_c('b',[_vm._v(_vm._s(_vm.data.url.code))])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }