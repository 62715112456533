<template>
  <v-dialog v-model="dialog" max-width="850px" scrollable>
    <v-card class="styled-card--default">
      <v-card-text class="pa-2">
        <v-row dense>
          <v-col cols="7">
            <v-card-title> Import Expense </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  1. Put the columns to import in the order in which you make
                  the data</v-col
                >
                <v-col cols="12">
                  <v-text-field
                    v-model="upload.headers"
                    label="Headers"
                    hide-details
                    filled
                    dense
                    :placeholder="'url;link_type;month;amount'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">2. Insert data (without headers)</v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="upload.rows"
                    dense
                    hide-details
                    filled
                    label="Rows"
                    @keydown.tab.prevent="tabber($event)"
                    :placeholder="'url <tab> link_type <tab> month <tab> amount \n url <tab> link_type <tab> month <tab> amount '"
                  >
                  </v-textarea>
                  <v-card class="mt-4" flat color="gray">
                    <div>
                      <b> Template: </b>
                    </div>
                    <div class="mt-2">
                      site
                      <span class="keycup" title="Press tab">Tab</span>
                      seo
                      <span class="keycup" title="Press tab">Tab</span> month
                      <span class="keycup" title="Press tab">Tab</span> category
                      <span class="keycup" title="Press tab">Tab</span> money
                      <span class="keycup" title="Press Enter">Enter</span>
                    </div>
                    <div class="mt-2">
                      <span style="opacity: 0.5"> Repeat... </span>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <extended-chip
                class="px-6"
                large
                label
                color="success"
                :loading="loading"
                :disabled="loading"
                @click="uploadData(1)"
                >Upload
              </extended-chip>
            </v-card-actions>
          </v-col>
          <v-col cols="5">
            <v-card-title> Example of allowed columns</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>site</td>
                    <td>
                      <b>https://example.co</b>
                    </td>
                  </tr>
                  <tr>
                    <td>seo</td>
                    <td>
                      <b>user email</b>
                    </td>
                  </tr>
                  <tr>
                    <td>month</td>
                    <td>
                      <b>2020-10-04</b>
                    </td>
                  </tr>
                  <tr>
                    <td>category</td>
                    <td>
                      <b>text</b>
                    </td>
                  </tr>
                  <tr>
                    <td>money</td>
                    <td>
                      <b>number</b>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";

export default {
  components: { ExtendedChip },
  mixins: [Dialog],
  data: () => ({
    upload: {
      headers: "site;seo;month;category;money",
      rows: "",
    },
    loading: false,
  }),
  methods: {
    tabber(event) {
      let text = this.upload.rows,
        originalSelectionStart = event.target.selectionStart,
        textStart = text.slice(0, originalSelectionStart),
        textEnd = text.slice(originalSelectionStart);

      this.upload.rows = `${textStart}\t${textEnd}`;
      event.target.value = this.upload.rows; // required to make the cursor stay in place.
      event.target.selectionEnd = event.target.selectionStart =
        originalSelectionStart + 1;
    },
    async uploadData() {
      this.loading = true;

      try {
        const payload = {
          headers: this.upload.headers,
          data: this.upload.rows,
        };

        await this.$store.dispatch("ndc-manage/uploadExpense", payload);

        this.uploadDialogModel = false;

        this.$message.notification({
          title: "Done",
          text: "Data successfully uploaded 👍",
          type: "success",
          duration: 3500,
        });

        this.loading = false;
      } catch {
        this.$message.notification({
          title: "Error",
          text: "Something going wrong",
          type: "error",
          duration: 3500,
        });
        this.loading = false;
      }
    },
  },
};
</script>
