import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "global-settings/researchHub"(ctx, payload) {
      const url = `/admin/get-data`;
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
    async "global-settings/update"(ctx, payload) {
      const url = `/admin/update`;
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
  },
  getters: {},
};
