<template>
  <div class="conversion-4">
    <v-row>
      <v-col cols="12">
        <h2>Conversion 4</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12">
        <v-card class="styled-card--default remove-dialog-shadow" rounded="lg">
          <v-card-title style="font-size: 14px"> Select Filters </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row dense>
              <v-col>
                <div style="position: relative">
                  <div
                    v-if="
                      filters.comparison !== null &&
                      JSON.stringify(filters.comparison) !==
                        JSON.stringify([[], []])
                    "
                    class="disabled-tooltip bottom error--text"
                  >
                    <span>
                      When comparison filter enabled - date can't be used.
                    </span>
                  </div>
                  <smart-date-filter
                    :model-value="filters.date_range"
                    :disabled="!!filters.comparison"
                    filled
                    @update:modelValue="handleUpdateDateFilter"
                  />
                </div>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Brand"
                  v-model="filters.brand"
                  :items="brandItems"
                  item-text="name"
                  item-value="name"
                  clearable
                  multiple
                  filled
                  placeholder="Type to search"
                  dense
                  hide-details
                  :menu-props="menuProps"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Format"
                  v-model="filters.format"
                  :items="formatItems"
                  item-text="name"
                  item-value="id"
                  clearable
                  filled
                  placeholder="Type to search"
                  dense
                  hide-details
                  :menu-props="menuProps"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Page type"
                  v-model="filters.page_type"
                  :items="filtersData.page_type"
                  item-text="text"
                  item-value="value"
                  clearable
                  filled
                  placeholder="Type to search"
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col>
                <div class="fix-button-height">
                  <comparison-filter
                    filled
                    :model-value="filters.comparison"
                    @update:modelValue="filters.comparison = $event"
                  />
                </div>
              </v-col>
              <v-col class="d-flex align-center">
                <v-switch
                  v-model="percentage"
                  label="%"
                  hide-details
                  dense
                  style="margin: 0"
                ></v-switch>
              </v-col>
              <v-col class="d-flex justify-end">
                <div class="fix-button-height">
                  <v-btn
                    color="primary"
                    @click="fetchData"
                    :loading="loadings.brands || loadings.tables"
                    width="160"
                  >
                    Get Data
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
          class="styled-card--default toolbar remove-dialog-shadow"
          rounded="lg"
        >
          <v-card-title class="justify-center">
            <div>
              <v-chip
                id="two_week"
                label
                :color="selection !== 'two_week' ? '' : 'primary'"
                @click="updateChartDates('two_week')"
                :class="{ active: selection === 'two_week', 'ma-1': true }"
              >
                2W
              </v-chip>
              <v-chip
                id="one_month"
                label
                :color="selection !== 'one_month' ? '' : 'primary'"
                @click="updateChartDates('one_month')"
                :class="{ active: selection === 'one_month', 'ma-1': true }"
              >
                1M
              </v-chip>
              <v-chip
                label
                id="three_months"
                :color="selection !== 'three_months' ? '' : 'primary'"
                @click="updateChartDates('three_months')"
                :class="{ active: selection === 'three_months', 'ma-1': true }"
              >
                3M
              </v-chip>
              <v-chip
                id="six_months"
                label
                :color="selection !== 'six_months' ? '' : 'primary'"
                @click="updateChartDates('six_months')"
                :class="{ active: selection === 'six_months', 'ma-1': true }"
              >
                6M
              </v-chip>
              <v-chip
                id="one_year"
                label
                :color="selection !== 'one_year' ? '' : 'primary'"
                @click="updateChartDates('one_year')"
                :class="{ active: selection === 'one_year', 'ma-1': true }"
              >
                1Y
              </v-chip>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <div id="chart-timeline-positions">
              <apexcharts
                ref="chart"
                height="300"
                :options="options"
                :series="series"
              ></apexcharts>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="styled-card--default remove-dialog-shadow">
          <v-card-title style="font-size: 14px"> Visits table </v-card-title>
          <v-divider />
          <v-card-text>
            <v-data-table :headers="tableHeaders" :items="tableItems">
              <template v-if="percentage" #body="{ items }">
                <tbody>
                  <tr v-for="(row, index) in items" :key="index">
                    <td>
                      {{ row.name }}
                    </td>
                    <td
                      v-for="(key, i) in Object.keys(row.data)"
                      :key="key + i"
                    >
                      <span>{{ row.data[key].value }}</span>
                      <span class="ml-2" style="font-size: 12px">
                        {{ row.data[key].diff }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import events from "../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import eventPipe from "../../../events/eventPipe";
import {
  CONVERSION_4_CHART_OPTIONS,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import ComparisonFilter from "@/components/UI/ComparisonFilter";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "Chart",
  components: {
    SmartDateFilter,
    ComparisonFilter,
    apexcharts: VueApexCharts,
  },
  mixins: [OnePageAnalyzerIndex],
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Analyrics",
        disabled: true,
      },
      {
        text: "Conversion 4",
        disabled: true,
      },
    ],
    firstEnter: true,
    menuProps: DEFAULT_MENU_PROPS,
    loadings: {
      tables: false,
      brands: false,
    },
    series: [],
    page_type: null,
    filtersData: {
      pageType: [],
      format: [4, 5, 6],
    },
    filters: {
      page_type: [],
      date_range: [
        dateFormat(new Date().setMonth(date.getMonth() - 12), "yyyy-mm-dd"),
        dateFormat(date, "yyyy-mm-dd"),
      ],
      comparison: null,
      brand: [],
      format: 1,
    },
    percentage: false,
    table: {
      headers: [
        { text: "Page", value: "url", class: "text-left" },
        { text: "clicks", value: "clicks", class: "text-right" },
        { text: "all_clicks", value: "all_clicks", class: "text-right" },
      ],
      items: [],
    },
    tableHeaders: [],
    tableItems: [],
    date_modal: false,
    brandItems: [],
    formatItems: [
      { id: 3, name: "day" },
      { id: 1, name: "week" },
      { id: 2, name: "month" },
      { id: 4, name: "quarter" },
      { id: 5, name: "half year" },
      { id: 6, name: "year" },
    ],
    dirty_series: {
      clicks_keitaro: { name: "clicks_keitaro", data: [] },
      registrations: { name: "registrations", data: [] },
      ndc: { name: "ndc", data: [] },
    },
    options: CONVERSION_4_CHART_OPTIONS,
    tooltips: {
      firstEnter: "Select brands and click 'Get Data' 🏌️‍",
      itemsPlaceholder:
        "Can't find anything. Change filters and try again. 🤷‍",
    },
  }),
  watch: {
    percentage(value) {
      if (value) {
        this.filters.date_range = [];
        this.filters.comparison = null;
      }
    },
    "filters.comparison"(value) {
      if (value) {
        this.percentage = false;
        this.filters.date_range = [];
      }
    },
  },
  methods: {
    handleUpdateDateFilter(event) {
      this.filters.date_range = event;
    },
    async fetchVisits() {
      if (this.id_project === null || this.id_project === undefined) {
        return;
      }

      this.loadings.tables = true;

      const data = {
        payload: this.filters,
        projectId: this.id_project,
      };

      const resp = await this.$store.dispatch("conversion-4/fetchVisits", data);

      if (resp) {
        this.dirty_series = resp.data;
        this.brandItems = resp.data.brands;
        this.tableHeaders = resp.data.table.headers;
        this.tableItems = resp.data.table.data;
        this.updateVisitsChart();
      }

      this.loadings.tables = false;
    },
    updateVisitsChart() {
      let series = [];

      series.push(this.dirty_series.clicks_keitaro);
      series.push(this.dirty_series.registrations);
      series.push(this.dirty_series.ndc);

      this.series = series;

      this.updateChartDates("six_months");
    },
    fetchData() {
      this.fetchVisits();
    },
  },
  created() {
    let self = this;

    self.fetchData();

    eventPipe.$on("update-active-project", () => this.fetchData);

    events.$on("update-chart-dates", function (start, end) {
      self.$refs.chart.zoomX(start, end);
    });
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
};
</script>

<style scoped></style>
