<template>
  <div class="d-flex align-center" style="flex-wrap: wrap">
    <div>
      NT / Pr:
      <b>{{ data.url.need_top_from_url_control }}</b> /
      <b>{{ data.url.priority_pages }}</b>
    </div>
    <span class="mx-5">|</span>
    <div>
      GSC Cl / Imp:
      <b>
        {{ formateClicks(data.url.sum_gsc_cl_by_28_days) }}
      </b>
      /
      <b>
        {{ formateClicks(data.url.sum_gsc_imp_by_28_days) }}
      </b>
    </div>
    <span class="mx-5">|</span>
    <div>
      Int Uniq A / L:
      <div
        :class="`colored-box ${getAnchorsChipColor(
          data.url.unique_anchors,
          'anchors'
        )}`"
      >
        <b>{{ data.url.unique_anchors }}</b>
      </div>
      /
      <div
        :class="`colored-box ${getAnchorsChipColor(
          data.url.unique_links,
          'links'
        )}`"
      >
        <b>{{ data.url.unique_links }}</b>
      </div>
    </div>
    <span class="mx-5">|</span>
    <div>
      <div :class="`colored-box ${getDepthColor(data.url.depth)}`">
        D: <b>{{ data.url.depth }}</b>
      </div>
    </div>
    <span class="mx-5">|</span>
    <div>
      <div :class="`colored-box ${getSwDlColor(data.url.sw_dl)}`">
        <span v-if="!data.url.sw_dl">-</span>
        <span v-else>
          SW D: <b>{{ $moment(data.url.sw_dl).format("MMM DD") }}</b>
        </span>
      </div>
    </div>
    <template v-if="parseInt(data.url.links_score) < 100">
      <span class="mx-5">|</span>
      <div :class="`colored-box ${getLinksColor(data.url.links_score)}`">
        Links: <b>{{ data.url.links_score }}</b>
      </div>
    </template>
    <template v-if="parseInt(data.url.tech_score) < 100">
      <span class="mx-5">|</span>
      <div :class="`colored-box ${getLinksColor(data.url.tech_score)}`">
        Technical: <b>{{ data.url.tech_score }}</b>
      </div>
    </template>
    <template v-if="parseInt(data.url.sw_score) < 100">
      <span class="mx-5">|</span>

      <div :class="`colored-box ${getLinksColor(data.url.sw_score)}`">
        SW: <b>{{ data.url.sw_score }}</b>
      </div>
    </template>
    <template v-if="parseInt(data.url.content_score) < 100">
      <span class="mx-5">|</span>
      <div :class="`colored-box ${getLinksColor(data.url.content_score)}`">
        Content: <b>{{ data.url.content_score }}</b>
      </div>
    </template>
    <template v-if="lCrawledOverdue(data.url.l_crawled)">
      <span class="mx-5">|</span>
      <div :class="`colored-box error`">
        L Crawled: <b>{{ $moment(data.url.l_crawled).format("MMM DD") }}</b>
      </div>
    </template>
    <template v-if="data.url.code !== '200'">
      <span class="mx-5">|</span>
      <div :class="`colored-box error`">
        Code: <b>{{ data.url.code }}</b>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    getLinksColor(data) {
      const v = parseInt(data);

      if (v >= 60 && v <= 80) return "warning";
      if (v < 60) return "error";
      return "";
    },
    getAnchorsChipColor(data, type = "") {
      const v = parseInt(data);

      if (type === "anchors") {
        if (v > 0 && v <= 3) return "error";
        if (v >= 4 && v <= 5) return "warning";
        return "transparent";
      }

      if (v > 0 && v <= 3) return "error";
      if (v >= 4 && v <= 10) return "warning";
      return "transparent";
    },
    formateClicks(data) {
      if (String(data) === "-1") return "-";
      return data;
    },
    getDepthColor(data) {
      const v = parseInt(data);

      if (v === 1) return "";
      if (v >= 2 && v <= 3) return "warning";
      return "error";
    },
    lCrawledOverdue(date) {
      const today = this.$moment(new Date());
      const diff = this.$moment(date).diff(today, "days");

      if (diff >= 2) return true;
      return false;
    },
    getSwDlColor(date) {
      if (!date) return "warning";
      const today = this.$moment(new Date());
      const diffMonths = this.$moment(date).diff(today, "months");

      if (diffMonths <= -2) {
        return "blue";
      }

      return "transparent";
    },
  },
};
</script>
