export const pageConfig = {
  pageTitle: "Prompts",
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Contentball",
      disabled: true,
    },
    {
      text: "Prompts",
      disabled: true,
    },
  ],
};

export const PROMPTS_DATA_TABLE_HEADERS = [
  {
    text: "Category",
    value: "category",
  },
  {
    text: "Category",
    value: "category",
  },
  {
    text: "Intent",
    value: "intent",
  },
  {
    text: "Page type",
    value: "page_type",
  },
  {
    text: "Niche",
    value: "niche",
  },
  {
    text: "Prompt",
    value: "prompt",
  },
  {
    text: "Prompt AI",
    value: "prompt_ai",
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "Structure",
    value: "structure",
  },
  {
    text: "Structure AI",
    value: "structure_ai",
  },
  {
    text: "Created",
    value: "created_at",
  },
  {
    text: "Created by",
    value: "created_by",
  },
  {
    text: "Updated",
    value: "updated_at",
  },
  {
    text: "Updated by",
    value: "updated_by",
  },
  {
    text: "",
    value: "_actions",
  },
];
