var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"styled-card--default"},[_c('div',{class:{
      'cursor-pointer': true,
      'py-3': true,
      'px-4': true,
      info: _vm.isChecked,
      'white--text': _vm.isChecked,
      'd-flex': true,
      'align-center': true,
    },on:{"click":_vm.toggleValue}},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-spacer'),(_vm.isChecked)?[_c('v-icon',{staticStyle:{"color":"currentColor"}},[_vm._v("mdi-check-circle")])]:[_c('v-icon',{staticStyle:{"color":"currentColor","opacity":"0.5"}},[_vm._v(" mdi-circle-outline ")])]],2),_c('v-expand-transition',[(_vm.showTextfield && _vm.isChecked)?_c('div',{staticClass:"px-3 py-3"},[_c('v-textarea',{attrs:{"rows":"3","hide-details":"auto","dense":"","placeholder":"Write down exactly what you refactored","filled":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }