<template>
  <v-dialog v-model="dialog" max-width="1200" content-class="my-shadow--e4">
    <v-card class="styled-card--default">
      <v-card-title>
        Create domain
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-form ref="form">
          <v-row>
            <v-col cols="4"> Main information about this owner. </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="form.name"
                hide-details
                dense
                filled
                label="Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              You can add more details if you want, but it is not required.
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="form.color"
                hide-details
                dense
                filled
                label="Color name of this owner in report"
                placeholder="#000000 ( Hex code )"
              />
              <tip-tap-editor
                class="mt-4"
                :model-value="form.description"
                @update:modelValue="form.description = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"> Assigned domains to this owner. </v-col>
            <v-col cols="8">
              <v-textarea
                v-model="domainsString"
                hide-details
                :rows="5"
                auto-grow
                filled
                label="Domains"
                hint="Divider - new line"
                :placeholder="'example.com \nexample.com'"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-chip
          label
          large
          color="success"
          class="px-6"
          @click="saveDomain"
          :disabled="loading"
        >
          Save
          <v-progress-circular
            v-if="loading"
            class="ml-2"
            size="12"
            width="2"
          />
        </v-chip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import TipTapEditor from "@/components/UI/TipTapEditor.vue";

export default {
  components: { TipTapEditor },
  props: {
    loading: Boolean,
    clearForm: Boolean,
  },
  mixins: [Dialog],
  data: () => ({
    domainsString: "",
    form: {
      name: "",
      description: "",
      color: "",
      domains: [],
    },
  }),
  watch: {
    clearForm(value) {
      if (value) {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: "",
        description: "",
        color: "",
        domains: [],
      };
      this.domainsString = "";
    },
    saveDomain() {
      this.form.domains = this.domainsString.split("\n");
      this.$emit("save", this.form);
    },
  },
};
</script>
