<template>
  <v-dialog
      :retain-focus="false"
      v-model="inputVal"
      max-width="600px"
  >
    <v-container>
      <template>
        <v-card color="basil">
          <v-card-title class="text-center justify-center">
            <h4 class="font-weight-bold">
              Comments
            </h4>
          </v-card-title>
          <v-card>
            <v-card-text>
              <v-data-iterator
                  :items="comments"
                  hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col><b>Comment</b></v-col>
                    <v-col><b>User</b></v-col>
                    <v-col><b>Date</b></v-col>
                  </v-row>
                  <v-row v-for="item in props.items" :key="item.type_id">
                    <v-col
                    >
                      {{ item.workflow }}
                    </v-col>
                    <v-col
                    >
                      {{ item.user }}
                    </v-col>
                    <v-col
                    >
                      {{ item.updated_at }}
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>

            </v-card-text>
          </v-card>
        </v-card>
      </template>
    </v-container>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "Chat",
  props: ["value", 'domain_id', 'type_id'],
  data() {
    return {
      comments: [],
    }
  },
  created() {
    let self = this
    self.getComments()
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    getComments() {

      let self = this

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        },
      }

      let data = {
        domain_id: this.domain_id,
        type_id: this.type_id
      }

      axios
          .post(`${self.$store.state.server_url}/anchor-plan/comments`, data, config)
          .then((r) => {
            self.comments = r.data
          })
    },
  },
}
</script>

<style scoped>

</style>