<template>
  <div :class="`${headerData.align ? `text-${headerData.align}` : ''}`">
    <template v-if="itemKey === 'max_time'">
      {{ humanTime(value) }}
    </template>
    <template v-else-if="itemKey === 'now'">
      <v-chip v-if="getNowColor !== 'transparent'" small :color="getNowColor">
        <span>
          {{ humanTime(value) }}
        </span>
      </v-chip>
      <span v-else>
        {{ humanTime(value) }}
      </span>
    </template>
    <template v-else-if="itemKey === 'time_all'">
      <span>
        {{ humanTime(value) }}
      </span>
    </template>
    <template v-else-if="itemKey === 'labels'">
      <div style="transform: scale(0.85)" class="overflow-chip">
        <v-chip
          v-for="(label, idx) in value"
          :key="label + idx"
          small
          dark
          :color="getChipColor(label)"
        >
          {{ label }}
        </v-chip>
      </div>
    </template>
    <template v-else-if="itemKey === 'last_10_d'">
      <v-sparkline
        :value="value"
        line-width="16"
        :gradient="['#639ee0', '#2b4d93']"
        type="bar"
        :width="300"
      >
      </v-sparkline>
    </template>
    <template v-else-if="itemKey === 'executive'">
      <a v-if="value" :href="value.value" target="_blank">
        {{ value.text }}
      </a>
    </template>
    <template v-else-if="itemKey === 'project'">
      {{ rowData.project }}
    </template>
    <template v-else-if="itemKey === 'task_name'">
      <div
        :class="{
          mark: true,
          'mark--success': rowData.task_done === 1,
          'mark--error': rowData.task_done === 2,
          'mark--lime': isDeadlineMovedAndCurrentGroupIsMeetingOrRegular,
          'mark--yellow': isDeadlineMoved,
        }"
      ></div>
      <div class="d-flex align-center">
        <v-menu bottom offset-x content-class="my-shadow--e3">
          <template #activator="{ on }">
            <v-btn icon v-on="on" small>
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card class="styled-card--default" min-width="180">
            <v-card-text class="pa-0">
              <v-list>
                <v-list-item>
                  <small><b>Create subtask</b></small>
                  <small class="ml-2">(Select preset)</small>
                </v-list-item>

                <v-list-item
                  @click="
                    $emit('createSubtask', {
                      data: rowData,
                      type: 'need_details',
                    })
                  "
                >
                  Need details
                </v-list-item>
                <v-list-item
                  @click="
                    $emit('createSubtask', {
                      data: rowData,
                      type: 'need_review',
                    })
                  "
                >
                  Need review
                </v-list-item>
                <v-list-item
                  @click="
                    $emit('createSubtask', {
                      data: rowData,
                      type: 'testing_dev',
                    })
                  "
                >
                  Testing: Dev
                </v-list-item>
                <v-list-item
                  @click="
                    $emit('createSubtask', {
                      data: rowData,
                      type: 'testing_prod',
                    })
                  "
                >
                  Testing: Prod
                </v-list-item>
                <v-divider />
                <v-list-item
                  @click="
                    $emit('createSubtask', { data: rowData, type: 'empty' })
                  "
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-file-document</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content> Empty template </v-list-item-content>
                </v-list-item>
                <v-list-item @click="handleClickCloseTask">
                  <v-list-item-icon class="mr-2">
                    <v-icon small>mdi-close</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content> Close task </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-icon
          v-if="rowData.project_status && getStatusData.icon"
          :color="getStatusData.color"
          small
          left
          :title="getStatusData.title"
        >
          {{ getStatusData.icon }}
        </v-icon>
        <a :href="value.value" target="_blank">
          <span :style="{ fontWeight: !rowData['weekly'] ? '' : 700 }">
            {{ String(value.text).trim() }}
          </span>
        </a>
      </div>
    </template>
    <template v-else-if="itemKey === 'date_end'">
      <v-chip :color="getDeadlineColor(value)">
        {{ value ? $moment(value).format("D MMM") : "" }}
      </v-chip>
    </template>
    <template v-else-if="itemKey === '_json'"> </template>
    <template v-else>
      {{ value }}
    </template>
  </div>
</template>

<script>
import { DEFAULT_MOMENT_DATE_FORMAT } from "@/utils/defaultData";

export default {
  props: {
    itemKey: {
      type: String,
      default: null,
    },
    rowData: {
      type: Object,
      default: () => ({}),
    },
    tableData: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    data: {},
    headerData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["closeTask", "createSubtask"],
  data: () => ({
    chipColors: {
      conversion: "#5e8792",
      content: "#c6b57e",
      links: "#568bb3",
      strategic: "#819ea5",
      technical: "#746ca1",
      "money $": "#508445",
      "people #": "#7f8c9f",
      docs: "#9e6a87",
    },
  }),
  computed: {
    getStatusData() {
      try {
        const status = this.rowData.project_status;

        if (status === "Pause") {
          return {
            color: "warning",
            title: "Project is paused",
            icon: "mdi-alert-outline",
          };
        }
        if (status === "Disable") {
          return {
            color: "error",
            title: "Project is disabled",
            icon: "mdi-alert",
          };
        }

        return {};
      } catch (e) {
        console.error("Error while getting status data.", e);
        return {};
      }
    },
    isDeadlineMovedAndCurrentGroupIsMeetingOrRegular() {
      const group = this.rowData.group_by;

      return (
        this.isDeadlineMoved &&
        (this.arrIncludes(group, "Meeting") ||
          this.arrIncludes(group, "Regular"))
      );
    },
    isDeadlineEqualFiltersDate() {
      const selectedInFiltersDate = this.filters.date;
      const taskDeadline = this.rowData.date_end;
      const isEqualFiltersDate = this.$moment(taskDeadline).isSame(
        this.$moment(selectedInFiltersDate)
      );

      return isEqualFiltersDate;
    },
    isDeadlineMoved() {
      const selectedInFiltersDate = this.filters.date;
      const todaysDate = this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT);
      const taskDeadline = this.rowData.date_end;

      const isSelectedInFiltersDateIsSameOrBeforeToday = this.$moment(
        selectedInFiltersDate
      ).isSameOrBefore(todaysDate);

      const isTaskDeadlineIsAfterSelectedInFiltersDate = this.$moment(
        taskDeadline
      ).isAfter(selectedInFiltersDate);

      return (
        isSelectedInFiltersDateIsSameOrBeforeToday &&
        isTaskDeadlineIsAfterSelectedInFiltersDate
      );
    },
    value() {
      return this.data;
    },
    getNowColor() {
      const ONE_HOURS = 3600000;

      if (this.value >= ONE_HOURS) {
        return "rgba(255,136,0,0.22)";
      }
      return "transparent";
    },
  },
  methods: {
    handleClickCloseTask() {
      this.$emit("closeTask", this.rowData);
    },
    getDeadlineColor(dateString) {
      if (!this.tableData?.name?.includes("Close to Deadline"))
        return "transparent";

      const date = this.$moment(dateString);
      const today = this.$moment();
      const tomorrow = this.$moment().add(1, "days");
      const afterTomorrow = this.$moment().add(2, "days");

      if (date.isSame(today)) return "transparent";

      if (date.isSame(tomorrow) || date.isSame(afterTomorrow)) return "warning";

      return "transparent";
    },
    arrIncludes(arr, text) {
      try {
        if (!arr) return false;

        return arr.includes(text);
      } catch {
        return false;
      }
    },
    getChipColor(name) {
      const fixedName = String(name).toLowerCase();

      return this.chipColors[fixedName];
    },
    humanTime(x) {
      const d = this.$moment.duration(x, "milliseconds");
      const h = Math.floor(d.asHours());
      const m = Math.floor(d.asMinutes()) - h * 60;

      if (!h && !m) return "";

      return `${h > 0 ? h + "h " : ""}${m > 0 ? m + "m" : ""}`;
    },
  },
};
</script>
