<template>
  <div>
    <template v-if="isLastItemSelected">
      <v-chip
        label
        color="transparent"
        style="width: 32px; height: 32px"
        class="d-flex justify-center align-center pa-0"
      >
        <template v-if="loading">
          <v-progress-circular
            indeterminate
            width="2"
            size="10"
            color="primary"
          />
        </template>
        <template v-else>
          {{ value }}
        </template>
      </v-chip>
    </template>
    <template v-else>
      <v-menu offset-y content-class="my-shadow--e2">
        <template #activator="{ on }">
          <v-chip
            label
            outlined
            v-on="on"
            style="width: 32px; height: 32px"
            class="d-flex justify-center align-center pa-0"
          >
            <template v-if="loading">
              <v-progress-circular
                indeterminate
                width="2"
                size="10"
                color="primary"
              />
            </template>
            <template v-else>
              {{ value }}
            </template>
          </v-chip>
        </template>
        <v-card class="styled-card--default pa-0">
          <v-list dense>
            <v-list-item
              v-for="item in getFilteredItems"
              :key="item"
              @click="$emit('change', item)"
            >
              {{ item }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {},
    loading: Boolean,
  },
  computed: {
    getFilteredItems() {
      return this.items.filter((item) => item >= this.value);
    },
    isLastItemSelected() {
      return this.items[this.items.length - 1] === Number(this.value);
    },
  },
};
</script>
