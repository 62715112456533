<template>
  <v-chip
    :class="{
      'flat-chip': true,
      'flat-chip--warning': getCellType === 'warning',
      'flat-chip--error': getCellType === 'error',
    }"
    :color="getCellType === 'transparent' ? 'transparent' : ''"
  >
    {{ item.hours }}
  </v-chip>
</template>

<script>
export default {
  props: {
    item: {},
  },
  computed: {
    getCellType() {
      try {
        if (!this.item || !this.item.overdue_percent === undefined)
          return "transparent";

        if (this.item.overdue_percent <= 100) return "transparent";

        if (this.item.overdue_percent > 100 && this.item.overdue_percent <= 200)
          return "warning";

        return "error";
      } catch (e) {
        console.error("Error while getting gpm table cell color.", e);
        return "transparent";
      }
    },
  },
};
</script>
