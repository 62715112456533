export const MonthlyMovementByTaskBreadcrumbs = [
  {
    text: "Home",
    to: "/",
  },
  {
    text: "Dashboards",
    disabled: true,
  },
  {
    text: "Monthly statistics",
    disabled: true,
  },
];

export const Endpoints = {
  GetMonthlyStatistics: "/worksection/report/monthly/statistic",
};

export const StatusItemsData = [
  {
    text: "Done",
    value: 1,
  },
  {
    text: "In Work",
    value: 0,
  },
  {
    text: "TL is PlanResult",
    value: 2,
  },
  {
    text: "SEO is Plan Result",
    value: 3,
  },
  {
    text: "Not approved TL",
    value: 4,
  },
  {
    text: "Not approved SEO",
    value: 5,
  },
];
