import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "top-5-pages-conversion/fetchData"(ctx, payload) {
      const url = "/dashboards/summary/top-5-pages-conversation";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
  },
  getters: {},
};
