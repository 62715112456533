<template>
  <div>
    <template v-if="current && data">
      <div v-if="data.pages !== '-' && data.pagesWithElems !== ''" style="white-space: nowrap">
        <v-badge color="transparent" v-if="data.pages && data.pages !== '-'">
          <template v-if="data.pagesDone && data.pagesDone !== '-'" v-slot:badge>
            <span :style="`color: ${data.pagesDone === data.pages ? 'green' : 'black'}`">{{data.pagesDone}}</span>
          </template>
          <span :style="`color:${data.pagesDone === data.pages ? 'green' : 'black'}`">{{data.pages}}</span>
        </v-badge>
        <span v-else>-</span>

        <span style="margin: 0 5px"> | </span>

        <v-badge color="transparent" v-if="data.pagesWithElems && data.pagesWithElems !== '-'">
          <template v-if="data.pagesWithElemsDone && data.pagesWithElemsDone !== '-'" v-slot:badge>
            <span :style="`color: ${data.pagesWithElemsDone === data.pagesWithElems ? 'green' : 'black'}`">{{data.pagesWithElemsDone}}</span>
          </template>
          <span :style="`color:${data.pagesWithElemsDone === data.pagesWithElems ? 'green' : 'black'}`">{{data.pagesWithElems}}</span>
        </v-badge>
        <span v-else>-</span>
      </div>
      <div v-else>-</div>
    </template>
    <template v-else>
      <div v-if="hasDate(data)">
        <template v-if="hasDone(data)">
          <v-badge color="transparent">
            <template v-slot:badge>
              <span style="color: green">{{data.done}}</span>
            </template>
            <span :style="`color:${data.done === data.amount ? 'green' : 'inherit'}`">{{data.amount}}</span>
          </v-badge>
        </template>
        <template v-else>
          <span>{{data.amount}}</span>
        </template>
      </div>
      <div v-else>
        -
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['data', 'current'],
  methods: {
    hasDone(item) {
      if(!item || !item.done) {
        return false
      }
      if(item && item.done === '-') {
        return false
      }
      return true
    },
    hasDate(item) {
      return !!(item && item.amount)
    },
  }
}
</script>