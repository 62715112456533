import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "export-queue/fetchData"(ctx, payload) {
      const url = `/export/queue`;
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
  },
  getters: {},
};
