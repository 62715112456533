<template>
  <div :class="`positions-dashboard ${is_dark_mode ? 'dark-mode' : ''}`">
    <PageName small="" :theme="is_dark_mode">
      <span>Positions Dashboard</span>
      <v-btn icon :loading="loading" @click="updateData">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </PageName>
    <section
      :class="`
        table-filters-wrapper__header
        ${is_dark_mode ? ' dark-mode' : ''}`"
    >
      <transition name="progress-bar">
        <v-progress-linear
          v-if="loading"
          color="primary"
          indeterminate
          height="6"
        ></v-progress-linear>
      </transition>
      <div
        :class="`
        positions-dashboard__section
        header__wrapper
        ${loading ? ' loading-variant' : ''}`"
      >
        <v-row class="align-content-center">
          <v-col>
            <DateFilter
              :model-value="filterDateModel"
              @update:modelValue="filterDateModel = $event"
            />
          </v-col>
          <v-col>
            <CheckboxList
              label="Clusters"
              :items="items.clusters"
              :model-value="clustersModel"
              @update:modelValue="clustersModel = $event"
            />
          </v-col>
          <v-col>
            <CheckboxList
              label="Urls"
              :items="items.urls"
              v-model="urlsModel"
              @update:modelValue="urlsModel = $event"
            />
          </v-col>
          <v-col>
            <CheckboxList
              label="Tags"
              :items="items.tags"
              v-model="tagsModel"
              @update:modelValue="tagsModel = $event"
              :chips="true"
            />
          </v-col>
          <v-col>
            <RangeFilter
              title="Positions"
              :model-value="positionsModel"
              @update:modelValue="positionsModel = $event"
              :presets="positionsPresets"
            />
          </v-col>
          <v-col>
            <RangeFilter
              title="Volume"
              :model-value="volumeModel"
              @update:modelValue="volumeModel = $event"
            />
          </v-col>
          <v-col>
            <RangeFilter
              title="Traffic"
              :model-value="trafficModel"
              @update:modelValue="trafficModel = $event"
            />
          </v-col>
          <v-col>
            <CheckboxList
              label="Priority"
              :items="items.priority"
              v-model="priorityModel"
              @update:modelValue="priorityModel = $event"
            />
          </v-col>
          <v-col>
            <v-text-field
              label="RegEx"
              append-icon="mdi-regex"
              class="reset-vuetify-input"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn color="primary" @click="getData" class="reset-box-shadow"
              >Get Data</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </section>
    <section class="positions-dashboard__section chart chart__wrapper">
      <v-row>
        <v-col cols="2">
          <div class="chart__buttons">
            <div class="buttons__wrapper">
              <h5>Filters</h5>
              <CheckboxList :items="mockItems" label="Aggregation type">
              </CheckboxList>
              <CheckboxList :items="mockItems" label="Cached data">
              </CheckboxList>
              <div class="custom-input-item__wrapper">
                <v-autocomplete
                  v-model="activeHeaders"
                  :items="tableData.headers"
                  item-text="text"
                  item-value="id"
                  label="Columns"
                  clearable
                  multiple
                  class="custom-input-item"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ activeHeaders.length - 1 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </div>
              <CheckboxList :items="mockItems" label="Metrics"> </CheckboxList>
            </div>
          </div>
        </v-col>
        <v-col cols="10">
          <MainChart />
        </v-col>
      </v-row>
    </section>
    <section class="positions-dashboard__section table">
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="fixedActiveHeaders" :items="tableData.items">
          </v-data-table>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import PageName from "@/components/DashboardDev/PageName";
import { MOCK_SELECT_ITEMS, POSITIONS_TABLE_DATA } from "@/utils/defaultData";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import CheckboxList from "@/components/PositionsDashboard/Header/CheckboxList";
import RangeFilter from "@/components/PositionsDashboard/Header/RangeFilter";
import Notification from "@/mixins/Notification";
import MainChart from "@/components/PositionsDashboard/MainChart";

export default {
  components: {
    MainChart,
    RangeFilter,
    CheckboxList,
    DateFilter,
    PageName,
  },
  mixins: [Notification],
  created() {
    const activeHeaderIds = [];
    this.tableData.headers.forEach((item) => {
      if (item.active) activeHeaderIds.push(item.id);
    });
    this.activeHeaders = activeHeaderIds;
  },
  computed: {
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {
    setTimeout(() => (this.loading = false), 2000);
  },
  data: () => ({
    loading: true,
    items: MOCK_SELECT_ITEMS,
    filterDateModel: [],
    clustersModel: [],
    activeHeaders: [],
    fixedActiveHeaders: [],
    urlsModel: [],
    tagsModel: [],
    mockItems: [1, 2, 3, 4, 5],
    positionsModel: [0, 0],
    trafficModel: [0, 0],
    volumeModel: [0, 0],
    positionsPresets: [
      ["0 - 3", [0, 3]],
      ["0 - 5", [0, 5]],
      ["0 - 10", [0, 10]],
    ],
    priorityModel: null,
    previousFilters: null,
    tableData: JSON.parse(JSON.stringify(POSITIONS_TABLE_DATA)),
  }),
  watch: {
    activeHeaders() {
      this.fixedActiveHeaders = this.tableData.headers.filter((item) =>
        this.activeHeaders.includes(item.id)
      );
    },
  },
  methods: {
    saveHandler() {},
    updateData() {
      this.loading = true;
      return setTimeout(() => (this.loading = false), 2000);
    },
    getData() {
      const filters = {
        date: this.filterDateModel,
        clusters: this.clustersModel,
        urls: this.urlsModel,
        tags: this.tagsModel,
        positions: this.positionsModel,
        traffic: this.trafficModel,
        volume: this.volumeModel,
      };

      if (this.isFiltersEmpty(filters)) {
        // validation 1
        return this.emptyMessage();
      }

      const newFilters = JSON.parse(JSON.stringify(filters));
      this.previousFilters = JSON.parse(JSON.stringify(filters));

      if (this.previousFilters === newFilters) {
        // validation 2
        return this.notChangedMessage();
      }
      this.successMessage();

      this.loading = true;
      return setTimeout(() => (this.loading = false), 2000);
    },
    emptyMessage() {
      this.mWarning("Query", "Filters are empty 🙂", 6000);
    },
    notChangedMessage() {
      this.mWarning("Query", "Filters not changed 🙂", 6000);
    },
    successMessage() {
      this.mSuccess("Query", "Check console to find filters 😎", 6000);
    },
    isFiltersEmpty(filters) {
      const { date, clusters, urls, tags, positions } = filters;
      if (
        date.length === 0 &&
        clusters.length === 0 &&
        urls.length === 0 &&
        tags.length === 0 &&
        positions.length === 0
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
