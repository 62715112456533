<template>
  <div>
    <v-container fluid v-if="isset_active_project">

      <v-row>
        <v-col cols="12" md="12">
          <v-card
              class="mx-auto"
              v-if="isset_active_project"
          >
            <v-card-text class="headline font-weight-bold">
              <v-row
                  class="align-end"
              >
                <v-col
                    sm="5"
                >
                   <span style="font-size: small">Completeness of information: {{
                       table.completeness_of_information
                     }}</span>
                </v-col>
                <v-col
                    sm="1"
                >
                  <download-csv
                      v-if="export_data_status"
                      :data="export_data.items"
                      :name="export_data.dataFile"
                      :labels="export_data.labels"
                      :fields="export_data.fields"
                      v-on:export-finished="exported_csv"
                  >
                    <v-btn
                        color="info"
                        dark
                        top
                        small
                        right
                        fab
                    >
                      <v-icon size="30">mdi-download</v-icon>
                    </v-btn>
                  </download-csv>
                  <v-btn
                      v-else
                      small
                      top
                      right
                      fab
                      @click="export_csv"
                  >
                    <v-icon size="30">mdi-download</v-icon>
                  </v-btn>

                </v-col>
                <v-col
                    sm="5"
                >
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      solo
                      class="align-end"
                  ></v-text-field>
                </v-col>
                <v-col
                    sm="1"
                >

                  <v-menu
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :nudge-right="200"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-fab-transition>
                        <v-btn
                            color="info"
                            dark
                            small
                            top
                            right
                            fab
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-list>
                          <v-list-item>
                            <v-dialog
                                ref="dialog"
                                v-model="date_modal"
                                persistent
                                width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter.date_range"
                                    label="Picker in dialog"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="filter.date_range"
                                  range
                                  scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="date_modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="date_modal = false"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-list-item>
                          <v-list-item>
                            <v-autocomplete
                                v-model="filter.country"
                                :items="countries"
                                item-text="ga"
                                item-value="id"
                                label="Country"
                                chips
                                deletable-chips
                                small-chips
                            ></v-autocomplete>
                          </v-list-item>
                        </v-list>
                        <div class="text-center">
                          <v-btn
                              @click="fetch_data"
                              color="primary">
                            Apply
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row
                  align="center"
                  justify="center"
              >
                <v-col>
                  <div class="section">
                    <v-data-table
                        :headers="table.headers"
                        :items="table.items"
                        class="elevation-1"
                        :sort-by="['revenue']"
                        :sort-desc="[true]"
                        height="600"
                        fixed-header
                        dense
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.domain">
                          <td>
                            {{ item.url }}
                          </td>
                          <td>
                            {{ item.sessions }}
                          </td>
                          <td>
                            {{ item.users }}
                          </td>
                          <td>
                            {{ item.users_in_funnel }}
                          </td>
                          <td>
                            {{ item.placed }}
                          </td>
                          <td>
                            {{ item.paid }}
                          </td>
                          <td>
                            {{ item.revenue }}
                          </td>
                          <td>
                            {{ item.conv_to_funnel }}
                          </td>
                          <td>
                            {{ item.conv_by_users }}
                          </td>
                          <td>
                            {{ item.pro }}
                          </td>
                          <td>
                            {{ item.rev_per_user }}
                          </td>
                          <td>
                            {{ item.hire_writer }}
                          </td>
                          <td>
                            {{ item.order_original_essay }}
                          </td>
                          <td>
                            {{ item.order_now }}
                          </td>
                          <td>
                            {{ item.get_started }}
                          </td>
                          <td>
                            {{ item.view_samples_base }}
                          </td>
                          <td>
                            {{ item.try_to_copy }}
                          </td>
                          <td>
                            {{ item.get_sample }}
                          </td>
                          <td>
                            {{ item.give_mail }}
                          </td>
                          <td>
                            {{ item.download }}
                          </td>
                          <td>
                            {{ item.print }}
                          </td>
                        </tr>
                        </tbody>

                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
            project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>

import axios from "axios";
import JsonCSV from "vue-json-csv";

let dateFormat = require('dateformat');

let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
let beforeOneWeek2 = new Date(beforeOneWeek);
let day = beforeOneWeek.getDay()
let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 21))
let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));


export default {
  name: "SamplesPerPage",
  data() {
    return {
      search: '',
      loadings: {
        fetch_data: false,
      },
      date_modal: false,
      filter: {
        date_range: [
          dateFormat(lastMonday, "yyyy-mm-dd"),
          dateFormat(lastSunday, "yyyy-mm-dd")
        ],
        country: 1
      },
      urls: [],
      countries: [],
      export_data_status: false,
      export_data: {
        items: [],
        dataFile: 'export.csv',
        labels: {},
        fields: [],
      },
      table: {
        headers: [],
        items: [],
        dates: [],
        completeness_of_information: ""
      },
    }
  },
  components: {'download-csv': JsonCSV},
  methods: {
    exported_csv() {
      this.export_data_status = false
    },
    export_csv() {
      let self = this

      self.export_data.items = self.table.items.map((v) => {
        return {
          url: v.url,
          sessions: v.sessions,
          users: v.users,
          users_in_funnel: v.users_in_funnel,
          placed: v.placed,
          paid: v.paid,
          revenue: v.revenue,
          conv_to_funnel: v.conv_to_funnel,
          conv_by_users: v.conv_by_users,
          pro: v.pro,
          rev_per_user: v.rev_per_user,
          hire_writer: v.hire_writer,
          order_original_essay: v.order_original_essay,
          order_now: v.order_now,
          get_started: v.get_started,
          view_samples_base: v.view_samples_base,
        }
      })

      self.export_data.fields = Object.keys(self.export_data.items[0])
      self.export_data_status = true
    },
    fetch_countries() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading('fetch_countries', true)

        let url = `${self.$store.state.server_url}/analytics/commercial-pages/countries/json/${self.id_project}`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {}

        axios.post(url, data, config)
            .then(function (response) {
              self.countries = response.data
              self.update_loading('fetch_countries', false)
            }).catch((error) => {
          self.update_loading('fetch_countries', false)
          alert(error)
        });
      }
    },
    fetch_data() {
      let self = this;

      self.table.items = [];
      self.table.dates = [];
      self.table.headers = [];
      self.update_loading('fetch_data', true)

      let url = `${self.$store.state.server_url}/analytics/all-site/samples-per-page/json/${this.$route.params.id}`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      let data = {filter: self.filter}

      axios.post(url, data, config)
          .then(function (response) {
            self.table.items = response.data.data;
            self.table.headers = response.data.headers
            self.table.completeness_of_information = response.data.completeness_of_information
            self.update_loading('fetch_data', false)
          }).catch((error) => {
        self.update_loading('fetch_data', false)
        alert(error)
      });

    },
    update_loading(type, value) {
      this.$store.state.loading = false
      this.loadings[type] = value
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true
        }
      }
    },
    update_setting() {
      this.$store.dispatch('update_ls_serp_setting', this.ls_settings)
    }
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    }
  },
  created() {
    let self = this;

    self.fetch_countries()
    self.fetch_data()
  },
}
</script>

<style scoped>

</style>