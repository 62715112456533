<template>
  <div class="d-flex" style="gap: 0.25rem">
    <v-tooltip bottom content-class="pa-0 rounded-lg">
      <template #activator="{ on }">
        <div v-on="on" class="d-flex" style="gap: 0.25rem">
          <v-progress-circular
            class="ml-5"
            :value="value?.value"
            size="32"
            title="Main value"
            :color="getColor"
          >
            {{ value.value }}
          </v-progress-circular>
        </div>
      </template>
      <v-card flat outlined rounded="lg" class="pa-4">
        <div v-for="(item, i) in value.items" :key="i">
          {{ item }}
        </div>
      </v-card>
    </v-tooltip>
    <v-tooltip
      v-if="isValueAnotherBigger"
      bottom
      content-class="pa-0 rounded-lg"
    >
      <template #activator="{ on }">
        <div v-on="on" class="d-flex" style="gap: 0.25rem">
          <v-progress-circular
            class="ml-5"
            :value="value?.value_another"
            size="32"
            title="Value another"
            color="primary"
          >
            {{ value?.value_another }}
          </v-progress-circular>
        </div>
      </template>
      <v-card flat outlined rounded="lg" class="pa-4">
        <div v-if="value?.url_another" class="mb-3">
          <b>
            {{ value?.url_another }}
          </b>
        </div>
        <div v-for="(item, i) in value.items_another" :key="i">
          {{ item }}
        </div>
      </v-card>
    </v-tooltip>
  </div>
</template>

<script>
import { URL_OVERVIEW_COLORS } from "@/utils/defaultData";

export default {
  props: ["value", "data"],
  computed: {
    isValueAnotherBigger() {
      return this.value?.value_another > this.value?.value;
    },
    getColor() {
      const { green, yellow, red } = URL_OVERVIEW_COLORS;
      const value = this.value.value;
      if (value >= 60 && value <= 100) {
        return green;
      }
      if (value >= 1 && value <= 59) {
        return yellow;
      }
      if (value === 0) {
        return red;
      }

      return "transparent";
    },
  },
};
</script>
