<template>
  <div class="d-flex" style="gap: 8px">
    <v-chip
      v-for="button in buttons"
      :key="button.text"
      label
      :outlined="activeDiff !== button.diff"
      :color="activeDiff !== button.diff ? '' : 'primary'"
      @click="handleZoomChart(button.diff)"
    >
      {{ button.text }}
    </v-chip>
  </div>
</template>

<script>
import eventPipe from "@/events/eventPipe";
import { uid } from "@/utils/functions";

export default {
  props: {
    categories: {},
  },
  data: () => ({
    activeDiff: "all",
    buttons: [
      {
        text: "6 m",
        diff: 6,
      },
      {
        text: "1 y",
        diff: 12,
      },
      {
        text: "2 y",
        diff: 24,
      },
      {
        text: "3 y",
        diff: 36,
      },
      {
        text: "5 y",
        diff: 60,
      },
      {
        text: "all",
        diff: "all",
      },
    ],
    elementId: null,
  }),
  mounted() {
    this.elementId = uid();
    eventPipe.$on("site-analysis-chart-zoom", (data) => {
      if (data.elementId === this.elementId) return;
      this.handleZoomChart(data.diff, true);
    });
  },
  methods: {
    resetZoom() {
      this.activeDiff = "all";

      const end = this.$moment(this.categories[0]).valueOf();
      const start = this.$moment(
        this.categories[this.categories.length - 1],
      ).valueOf();

      this.$emit("zoom", { start, end });
    },
    getDateRange(monthsDifference) {
      const currentDate = new Date();
      const endDate = new Date(currentDate);
      endDate.setMonth(currentDate.getMonth() - monthsDifference);

      return {
        start: this.$moment(currentDate).valueOf(),
        end: this.$moment(endDate).valueOf(),
      };
    },
    handleZoomChart(monthsDiff, blockEvent) {
      if (!blockEvent)
        eventPipe.$emit("site-analysis-chart-zoom", {
          diff: monthsDiff,
          elementId: this.elementId,
        });

      if (this.activeDiff === monthsDiff || monthsDiff === "all") {
        return this.resetZoom();
      }

      this.activeDiff = monthsDiff;

      const { start, end } = this.getDateRange(monthsDiff);

      this.$emit("zoom", { start, end });
    },
  },
};
</script>
