<template>
  <v-dialog
    v-model="dialog"
    persistent
    content-class="my-shadow--e4"
    scrollable
    :max-width="isExpanded ? '1220px' : '840px'"
  >
    <v-card class="styled-card--default">
      <v-card-title>
        Upload domains to {{ projectName }}
        <v-spacer />
        <v-btn icon @click="isExpanded = !isExpanded">
          <v-icon>
            {{
              isExpanded
                ? "mdi-arrow-collapse-horizontal"
                : "mdi-arrow-expand-horizontal"
            }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pb-0">
        <v-stepper v-model="uploadStepper" elevation="0">
          <v-stepper-header style="box-shadow: none">
            <v-divider />
            <v-stepper-step :step="1" :complete="uploadStepper > 1">
              Validation
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="2" :complete="uploadStepper > 2">
              Upload
            </v-stepper-step>
            <v-divider />
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <v-row class="py-2">
                <v-col cols="12">
                  <v-autocomplete
                    filled
                    dense
                    hide-details
                    label="Anchor Plan Task"
                    :loading="loadings.filters"
                    v-model="form.anchor_plan_task"
                    :items="anchor_plan_tasks"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="form.domains"
                    label="Domains"
                    filled
                    hide-details
                    :rows="5"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-spacer />
                  <v-btn text @click="dialog = false" class="mr-2">
                    Close
                  </v-btn>
                  <v-btn
                    color="primary"
                    width="180px"
                    @click="handleNextUploadStep"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="2">
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    v-model="selectedUploadDomains"
                    :headers="uploadTableData.headers"
                    :loading="loadings.uploadTable"
                    :items="uploadTableData.items"
                    :items-per-page="-1"
                    :hide-default-footer="true"
                    item-key="donor"
                    show-select
                  >
                    <template #[`item.bad_status`]="{ value }">
                      <template v-if="Array.isArray(value) && value.length > 0">
                        <template v-for="[title, date] in value">
                          <template v-if="date">
                            <v-tooltip :key="title" bottom>
                              <template #activator="{ on }">
                                <v-chip v-on="on" small class="mr-1 mb-1">
                                  {{ title }}
                                </v-chip>
                              </template>
                              <template #default>
                                {{ date }}
                              </template>
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-chip small :key="title" class="mr-1 mb-1">
                              {{ title }}
                            </v-chip>
                          </template>
                        </template>
                      </template>
                    </template>
                    <template #[`item.decline_reasons`]="{ value }">
                      <template v-if="Array.isArray(value) && value.length > 0">
                        <v-chip
                          v-for="item in value"
                          :key="item"
                          small
                          class="mr-1 mb-1"
                        >
                          {{ item }}
                        </v-chip>
                      </template>
                    </template>
                    <template #[`item.used_in_ltt`]="{ value }">
                      <template v-if="Array.isArray(value) && value.length > 0">
                        <v-chip
                          v-for="item in value"
                          :key="item"
                          small
                          class="mr-1 mb-1"
                        >
                          {{ item }}
                        </v-chip>
                      </template>
                    </template>
                    <template #[`item.removed`]="{ value }">
                      {{ value ? "Yes" : "No" }}
                    </template>
                    <template #[`item.used_in_my_tasks`]="{ value }">
                      <template v-if="Array.isArray(value) && value.length > 0">
                        <div v-for="item in value" :key="item">
                          {{ item.project_name }} - {{ item.status_name }} -
                          {{ item.date }}
                        </div>
                      </template>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-spacer />
                  <v-btn
                    color="secondary"
                    text
                    @click="uploadStepper--"
                    class="mr-2"
                  >
                    Back
                  </v-btn>
                  <v-btn color="success" width="180px" @click="upload">
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import service from "@/plugins/service";
import { GUEST_POST_UPLOAD_TABLE_HEADERS } from "@/utils/defaultData";

export default {
  mixins: [Dialog],
  data: () => ({
    loadings: {
      uploadTable: false,
      filters: false,
    },
    isExpanded: false,
    loading: false,
    anchor_plan_tasks: [],
    uploadStepper: 1,
    selectedUploadDomains: [],
    form: {
      domains: "",
      anchor_plan_task: null,
    },
    uploadTableData: {
      headers: GUEST_POST_UPLOAD_TABLE_HEADERS,
      items: [],
    },
  }),
  computed: {
    projectName() {
      return this.$store.state.project.domain;
    },
    idProject() {
      return this.$store.state.project.active_project;
    },
  },
  watch: {
    dialog(open) {
      if (open) this.openDialog();
    },
  },
  methods: {
    clearUploadForm() {
      this.form.domains = "";
      this.form.anchor_plan_task = null;
      this.selectedUploadDomains = [];
      this.uploadTableData.items = [];
      this.uploadStepper = 1;
    },
    handleNextUploadStep() {
      this.fetchDomainsList();
      this.uploadStepper++;
    },
    async fetchDomainsList() {
      try {
        const url = "/gpm/task-view/upload-validate";

        this.loadings.uploadTable = true;

        const config = {
          params: {
            typeID: 1,
            source: "gpm_guest_post",
            projectID: this.$store.state.project.active_project,
          },
        };

        const payload = {
          domains: this.form.domains
            .split("\n")
            .map((v) => v.trim())
            .filter((v) => v.length > 0),
          // domains: this.selectedUploadDomains,
          anchorPlanTaskID: this.form.anchor_plan_task,
        };

        const resp = await service.post(url, payload, true, config);

        this.loadings.uploadTable = false;

        if (resp) {
          this.uploadTableData.items = resp.data.items;
          this.selectedUploadDomains = resp.data.items;
        }
      } catch {
        this.loadings.uploadTable = false;
      }
    },
    validateProject() {
      if (!this.idProject) {
        this.$message.notification({
          title: "Validation error",
          text: "You dont pick a project.",
          type: "warning",
        });
        return true;
      }
      return false;
    },
    async upload() {
      if (this.validateProject()) return;

      try {
        const url = "/gpm/task-view/upload-domains";
        const config = {
          params: {
            typeID: 1,
            source: "3rd",
            projectID: this.idProject,
          },
        };
        const payload = {
          // domains: this.form.domains
          //   .split("\n")
          //   .map((v) => v.trim())
          //   .filter((v) => v.length > 0),
          domains: this.selectedUploadDomains.map((domain) => domain.donor),
          anchorPlanTaskID: this.form.anchor_plan_task,
        };

        this.loading = true;

        await service.post(url, payload, true, config);

        this.clearUploadForm();

        this.dialog = false;
        this.$emit("done");
      } catch (e) {
        console.error("Error while uploading domains.", e);
      } finally {
        this.loading = false;
      }
    },
    async openDialog() {
      try {
        this.dialog = true;

        const url = "/gpm/task-view/get-anchor-plan-tasks";
        const config = {
          params: {
            projectID: this.$store.state.project.active_project,
            type: 1,
          },
        };

        this.loadings.filters = true;

        const response = await service.get(url, true, config);

        if (response) {
          this.anchor_plan_tasks = response.data.items;
        }
      } catch (e) {
        console.error("Error while opening upload dialog.", e);
      } finally {
        this.loadings.filters = false;
      }
    },
  },
};
</script>
