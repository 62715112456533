<template>
  <v-tooltip bottom content-class="remove-dialog-shadow pa-0" max-width="280">
    <template #activator="{ on }">
      <v-icon
        v-on="on"
        :color="getStatusColor"
        :title="(smStatuses[data.status] || {}).title || data.status"
      >
        {{ getStatusIcon }}
      </v-icon>
    </template>
    <v-card v-if="data.date" class="styled-card--default pa-2">
      <div class="text-center">
        <b>{{ data.date ? $moment(data.date) : "" }}</b>
      </div>
      <div class="text-center">
        {{ data.date ? $moment(data.date).fromNow() : "" }}
      </div>
    </v-card>
  </v-tooltip>
</template>

<script>
import StatusesMixin from "../../../../../mixins/StatusesMixin";

export default {
  props: {
    data: {},
  },
  mixins: [StatusesMixin],
};
</script>
