<template>
  <v-dialog v-model="dialog" v-bind="$attrs" scrollable persistent>
    <v-card class="styled-card--default" flat outlined>
      <v-card-title>
        Collect params by domain
        <v-spacer />
        <v-btn icon @click="handleCloseDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12">
                <div>
                  <div class="text-body-2">Task name</div>
                </div>
                <v-text-field
                  v-model="form.taskName"
                  outlined
                  hide-details
                  dense
                  clearable
                  placeholder="Enter task name"
                />
              </v-col>
              <v-col cols="12">
                <div>
                  <div class="text-body-2">Domains</div>
                </div>
                <v-textarea
                  v-model="form.domainsString"
                  outlined
                  hide-details="auto"
                  dense
                  placeholder="Enter domains, delimiter - newline, coma or semicolon"
                  @blur="handleBlurDomainsTextarea"
                  clearable
                  :error-count="errorMessages['domains']?.length"
                  :error-messages="errorMessages['domains']"
                />
              </v-col>
              <v-col cols="12">
                <v-list-item
                  class="rounded-lg"
                  :class="{
                    'primary--text': form.isOnlyHistoricalData,
                    'current-color-border': true,
                  }"
                  @click="
                    form.isOnlyHistoricalData = !form.isOnlyHistoricalData
                  "
                >
                  <v-list-item-icon>
                    <v-icon v-if="form.isOnlyHistoricalData" color="primary">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Only historical data </v-list-item-title>
                </v-list-item>
              </v-col>
              <v-col cols="12"></v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-treeview
              v-model="form.selectedParams"
              selectable
              @input="handleChangeParam"
              :items="allowedParams"
            >
              <template #label="{ item }">
                {{ item.name }}
                <v-badge
                  v-if="item.status && item.status !== 'work'"
                  color="green"
                  :content="item.status"
                >
                </v-badge>

                <v-tooltip v-if="item.info" max-width="300" bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span v-html="item.info"></span>
                </v-tooltip>
              </template>

              <template #append="{ item }">
                <div v-if="item.id === 5">
                  <template>
                    <v-autocomplete
                      v-model="form.country"
                      :items="this.$store.state.reuse.countries"
                      item-key="id"
                      item-text="name"
                      item-value="id"
                      hide-details
                      dense
                      clearable
                      :label="item.name"
                      small-chips
                    ></v-autocomplete>
                  </template>
                </div>
                <div v-if="item.id === 39">
                  <template>
                    <v-autocomplete
                      v-model="form.niche"
                      :items="niches"
                      item-key="id"
                      item-text="name"
                      item-value="id"
                      hide-details
                      dense
                      :label="item.name"
                    ></v-autocomplete>
                  </template>
                </div>
                <div v-if="item.id === 40">
                  <template>
                    <v-autocomplete
                      v-model="form.region"
                      multiple
                      :items="swCountries"
                      item-key="id"
                      item-text="name"
                      item-value="id"
                      :label="item.name"
                      hide-details
                      clearable
                      deletable-chips
                      dense
                      small-chips
                      style="max-width: 380px"
                    ></v-autocomplete>
                  </template>
                </div>
              </template>
            </v-treeview>
            <template v-if="errorMessages['params']?.length > 0">
              <v-divider class="error" />
              <v-input :error-messages="errorMessages['params']" class="px-3" />
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="startCollection"
          color="primary"
          class="px-5 text-normal"
          large
          :loading="loadings.collection"
        >
          Start collection
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { NicheItems } from "./defaults";
import service from "../../../plugins/service";

export default {
  props: {
    domains: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    taskName: {
      type: String,
      default: "",
    },
  },

  mixins: [Dialog],

  data: () => ({
    form: {
      selectedParams: [],
      domains: [],
      taskName: "",
      isOnlyHistoricalData: false,
      country: null,
      niche: null,
      region: null,
      domainsString: "",
    },
    ignore: false,
    loadings: {
      params: false,
      swCountries: false,
      collection: false,
    },
    validatedDomains: [],
    errorDomains: [],
    errorMessages: {
      domains: [],
      params: [],
    },
  }),

  watch: {
    domains: {
      handler(value) {
        try {
          if (!value || value?.length === 0) return;

          this.overwriteDomains(value);
        } catch {
          //
        }
      },
      deep: true,
      immediate: true,
    },
    dialog: {
      handler(isOpen) {
        if (isOpen) {
          this.initAllowedParams();
          this.initSwCountries();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.form.taskName = this.taskName;

    if (this.dialog) {
      this.initAllowedParams();
      this.initSwCountries();
    }
  },

  computed: {
    niches: () => NicheItems,
    swCountries() {
      return this.$store.getters["paramsCollecting/getSwCountries"];
    },
    allowedParams() {
      return this.$store.getters["paramsCollecting/getAllowedParams"];
    },
  },

  methods: {
    handleCloseDialog() {
      this.dialog = false;
      this.resetErrors();
      this.resetForm();
    },
    handleBlurDomainsTextarea() {
      const domains =
        this.form.domainsString !== undefined
          ? this.form.domainsString
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      this.form.domainsString = domains
        .map((v) => {
          let domain;

          try {
            domain = new URL(v).hostname;
          } catch (e) {
            domain = new URL("http://" + v).hostname;
          }

          return domain.replace("www.", "");
        })
        .join("\n");
    },
    resetErrors() {
      this.errorDomains = [];
      this.errorMessages = {
        domains: [],
        params: [],
      };
    },
    validateForm() {
      this.resetErrors();

      let errors = 0;

      if (!this.form.domains) {
        this.errorMessages["domains"]?.push("Domains is required");
        errors++;
      }

      if (!this.validateDomains()) {
        this.errorMessages["domains"]?.push("Invalid domain found");
        errors++;
      }

      if (this.form.selectedParams?.length === 0) {
        this.errorMessages["params"] = "No params selected";
        errors++;
      }

      return errors === 0;
    },
    validateDomains() {
      let validatedDomains = [];
      let errorDomains = [];

      const domainsArr =
        this.form.domainsString !== undefined
          ? this.form.domainsString
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      domainsArr.forEach((domain) =>
        this.validateDomain(domain)
          ? validatedDomains.push(domain)
          : errorDomains.push(domain)
      );

      this.validatedDomains = validatedDomains;
      this.errorDomains = errorDomains;

      return validatedDomains.length > 0 && errorDomains.length === 0;
    },
    parseDomainsString(domainsString) {
      const result = [];

      const domainsArr =
        domainsString !== undefined
          ? domainsString
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      domainsArr.forEach((domain) =>
        this.validateDomain(domain) ? result.push(domain) : null
      );

      return result;
    },
    validateDomain(domainString) {
      const r = new RegExp("^([a-z0-9-]{1,63}\\.)+[A-Za-z]{2,25}$", "i");
      const m = domainString.toLowerCase().match(r);

      return m && m.length > 0;
    },
    handleChangeParam() {
      this.errorMessages["params"] = null;

      if (this.form.selectedParams.indexOf(20) !== -1) {
        if (this.form.selectedParams.indexOf(12) === -1) {
          this.form.selectedParams.push(12);
        }
        if (this.form.selectedParams.indexOf(13) === -1) {
          this.form.selectedParams.push(13);
        }
        if (this.form.selectedParams.indexOf(15) === -1) {
          this.form.selectedParams.push(15);
        }
      }

      if (this.form.selectedParams.indexOf(27) !== -1) {
        if (this.form.selectedParams.indexOf(11) === -1) {
          this.form.selectedParams.push(11);
        }
      }
    },
    async initAllowedParams() {
      try {
        this.loadings.params = true;

        await this.$store.dispatch("paramsCollecting/initAllowedParams");
      } catch (e) {
        console.error("Error while initializing allowed params.", e);
      } finally {
        this.loadings.params = false;
      }
    },
    async initSwCountries() {
      try {
        this.loadings.swCountries = true;

        await this.$store.dispatch("paramsCollecting/initSWCountries");
      } catch (e) {
        console.error("Error while initializing SW countries.", e);
      } finally {
        this.loadings.swCountries = false;
      }
    },
    // Set value over existed domainsValue
    overwriteDomains(value = []) {
      this.form.domains = value;
      this.form.domainsString = value.join("\n");

      this.$nextTick(() => {
        this.handleBlurDomainsTextarea();
      });
    },
    async resetForm() {
      return new Promise((resolve) => {
        this.form = {
          selectedParams: [],
          domains: [],
          taskName: "",
          isOnlyHistoricalData: false,
          country: null,
          niche: null,
          region: null,
          domainsString: "",
        };

        resolve();
      });
    },
    async startCollection() {
      if (!this.validateForm()) return;

      try {
        const url = "/api/dct/task";

        const formData = {
          name: this.form.taskName,
          domains: this.parseDomainsString(this.form.domainsString),
          params: this.form.selectedParams,
          country: this.form.country,
          niche: this.form.niche,
          region: this.form.region,
          ignore: this.ignore,
          onlyHistoricalData: this.form.isOnlyHistoricalData,
          context: this.context,
        };

        this.loadings.collection = true;

        const response = await service.post(
          url,
          formData,
          true,
          {},
          { prefix: "" }
        );

        if (response?.data?.success) this.handleSuccessCollection();

        console.log("response", response);
      } catch (e) {
        console.error("Error while starting collection.", e);
      } finally {
        this.loadings.collection = false;
      }
    },
    handleSuccessCollection() {
      this.resetForm();
      this.resetErrors();
      this.$message.notification({
        title: "Done",
        text: "The collection has been successfully initiated.",
        type: "success",
        duration: 1500,
      });
    },
  },
};
</script>
