var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{class:{
    'text-center': 1,
    bold: 1,
    'td-red': _vm.date.plan_status === -1,
    'td-orange': _vm.date.plan_status === 0,
    'td-blue': _vm.date.plan_status === 2,
    'edge-cell': _vm.date.edge,
  },staticStyle:{"white-space":"nowrap"}},[(_vm.emptyData)?void 0:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getOnPage)}}),_vm._v(" / "+_vm._s(_vm.getPlan)+" ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }