<template>
  <v-card
    v-if="!loading"
    rounded="lg"
    style="width: 100%"
    class="site-analysis-table-section styled-card--default"
  >
    <v-card-title :data-page-navigation="SECTIONS.backlinks.sectionTitle">
      <!-- Backlinks -->
      {{ SECTIONS.backlinks.sectionTitle }}
      <force-update-button :section-name="sectionName" />
      <v-spacer />
      <vue-json-to-csv
        :json-data="items"
        :csv-title="SECTIONS.backlinks.sectionTitle"
        :separator="'\t'"
      >
        <v-chip @click="() => {}" label :disabled="!items?.length">
          Export CSV <v-icon small right>mdi-export</v-icon>
        </v-chip>
      </vue-json-to-csv>
    </v-card-title>
    <v-card-subtitle class="d-flex align-center">
      <last-update-info :data="data" />
      <v-spacer />

      <table-search
        v-if="successfullyUploaded"
        class="mt-2"
        :model-value="search"
        @update:modelValue="search = $event"
        placeholder="Type to search in table"
        style="max-width: 320px"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pa-0 pt-5">
      <template v-if="successfullyUploaded">
        <v-data-table
          :search="search"
          :items="items"
          :headers="getModifiedHeaders"
          multi-sort
          dense
          :items-per-page="100"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [100, 200, 300, -1],
          }"
          :custom-sort="_$smartCustomTableSort"
        >
          <template
            v-for="key in getModifiedHeaders.map((v) => v.value)"
            #[`item.${key}`]="{ value }"
          >
            <span :key="key" style="max-width: 320px; word-break: break-word"
              >{{ value }}
            </span>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <not-collected-placeholder />
      </template>
    </v-card-text>
  </v-card>
  <div v-else class="skeleton loading" style="height: 300px"></div>
</template>

<script>
import TableSorting from "../../../mixins/TableSorting";
import VueJsonToCsv from "vue-json-to-csv";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import DataTableFilterByNicheMixin from "../../../mixins/components/SiteAnalysis/DataTableFilterByNicheMixin";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";
import TableSearch from "@/components/UI/TableSearch.vue";
import NotCollectedPlaceholder from "@/components/SiteAnalysis/UI/NotCollectedPlaceholder.vue";
import { deepClone } from "@/utils/functions";

export default {
  components: {
    VueJsonToCsv,
    NotCollectedPlaceholder,
    TableSearch,
    ForceUpdateButton,
    LastUpdateInfo,
  },
  mixins: [TableSorting, DataTableFilterByNicheMixin, SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getModifiedHeaders() {
      let headers = deepClone(this.data?.headers);

      headers = headers.map((header) => {
        if (header.value === "page_score") {
          header.width = "80px";
        }
        if (header.value === "source_title") {
          header.width = "280px";
        }
        if (header.value === "source_url") {
          header.width = "280px";
        }
        if (header.value === "target_url") {
          header.width = "280px";
        }
        if (header.value === "anchor") {
          header.width = "280px";
        }

        return header;
      });

      return headers;
    },
    items() {
      return this.data.data;
    },
  },
  data: () => ({
    search: null,
  }),
  methods: {
    getTextStyles(value) {
      if (Number(value) === 0) return "opacity: 0.3;";
      return "";
    },
  },
};
</script>
