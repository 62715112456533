<template>
  <div>
    <v-container class="gpm-reserved-domains">
      <v-row>
        <v-col>
          <h2>GPM Reserved Domains</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="gpm-reserved-domains__card">
            <v-card-title class="card__search-bar">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-card-title>
            <v-card-title>
              <v-btn
                small
                rounded
                class="mr-2"
                color="info"
                outlined
                @click="openDialogSendToTask"
                v-if="selected.length > 0"
              >
                <v-icon small>mdi-arrow-up</v-icon>
                Send To Task
              </v-btn>
              <v-btn
                small
                rounded
                class="mr-2"
                color="error"
                outlined
                @click="remove_donors_dialog = true"
                v-if="selected.length > 0"
              >
                <v-icon small>mdi-delete-outline</v-icon>
                Remove
              </v-btn>
              <v-btn
                small
                rounded
                class="mr-2"
                color="info"
                outlined
                @click="restore_donors_dialog = true"
                v-if="selected.length > 0"
              >
                <v-icon small>mdi-backup-restore</v-icon>
                Restore
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-card elevation="0">
                <v-menu
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  max-width="350"
                  v-model="modal.shown"
                  :value="modal.shown"
                  rounded="lg"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-fab-transition>
                      <v-btn
                        color="info"
                        dark
                        small
                        top
                        right
                        fab
                        absolute
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-tune</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                  <v-list class="gpm-reserved-domains__filter-menu">
                    <v-list-item>
                      <v-autocomplete
                        v-model="filter.type"
                        label="Type"
                        :items="types"
                        multiple
                        dense
                        hide-details
                        outlined
                        :menu-props="{
                          maxHeight: '400',
                          rounded: 'lg',
                          transition: 'slide-x-transition',
                        }"
                      ></v-autocomplete>
                    </v-list-item>
                    <v-list-item>
                      <v-autocomplete
                        v-model="projectModel"
                        :items="_$getAllowedProjects"
                        :menu-props="{
                          maxHeight: '400',
                          rounded: 'lg',
                          transition: 'slide-x-transition',
                        }"
                        label="Project"
                        item-text="name"
                        item-value="id"
                        dense
                        hide-details
                        outlined
                        class="fix-input-height"
                      ></v-autocomplete>
                    </v-list-item>
                    <v-list-item>
                      <DateFilter
                        :model-value="dateModel"
                        @update:modelValue="dateModel = $event"
                      />
                    </v-list-item>
                    <v-list-item>
                      <v-btn
                        block
                        color="primary"
                        @click="handleGetDataButton"
                        :loading="loading"
                        >Get data</v-btn
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-card-text class="card__table">
                  <Loader :show="loading" />
                  <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="tableItems"
                    :search="search"
                    :items-per-page="15"
                    class="elevation-1"
                    show-select
                  >
                    <template v-slot:item.domain="{ item }">
                      {{ item.domain }}
                      <a target="_blank" :href="'http://' + item.domain">
                        <v-icon small title="View Info">mdi-link</v-icon>
                      </a>
                      <a
                        target="_blank"
                        :href="
                          'http://app.serpinsider.com/view-info-by-site/show?domain=' +
                          item.domain
                        "
                      >
                        <v-icon small title="View Info"
                          >mdi-information-outline</v-icon
                        >
                      </a>
                      <v-icon
                        v-if="item.used"
                        small
                        color="success"
                        title="Used"
                        >mdi-check</v-icon
                      >
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog_add" persistent scrollable max-width="600px">
      <v-card class="styled-card--default">
        <v-card-title>
          Upload domains to <u class="pl-1">{{ projectName }}</u>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                filled
                label="Anchor Plan Task"
                v-model="anchor_plan_task"
                :items="anchor_plan_tasks"
                required
                hide-details
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                filled
                label="Upload target"
                v-model="upload_target"
                :items="upload_targets"
                required
                hide-details
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog_add = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="sendToTask()"
            :disabled="dialog_add_loading"
            :loading="dialog_add_loading"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="remove_donors_dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Remove donors </v-card-title>
        <v-card-text> Are you sure? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="remove_donors_dialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="removeDonors()"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="restore_donors_dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Restore donors </v-card-title>
        <v-card-text> Are you sure? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="restore_donors_dialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" text @click="restoreDonors()"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import GetSubproject from "@/mixins/Project";
import Loader from "@/components/Main/Loader";

export default {
  name: "GpmReservedDomains",
  components: { Loader, DateFilter },
  mixins: [GetSubproject],
  data() {
    return {
      loading: false,
      projectModel: null,
      dateModel: [],
      restore_donors_dialog: false,
      remove_donors_dialog: false,
      dialog_add: false,
      dialog_add_loading: false,
      selected: [],
      anchor_plan_task: null,
      anchor_plan_tasks: [],
      upload_target: null,
      upload_targets: [
        {
          text: "New donor",
          value: "new_donor",
        },
        {
          text: "Approving placement by SEO",
          value: "approving_placement_by_seo",
        },
      ],
      search: "",
      modal: {
        shown: false,
      },
      date_modal: false,
      filter: {
        type: ["reserved"],
      },
      types: ["reserved", "silent", "canceled"],
      headers: [
        { text: "Domain", value: "domain" },
        { text: "Task", value: "task_name" },
        { text: "Traffic", value: "ah_traffic_country" },
        { text: "Price", value: "price" },
        { text: "Reserved At", value: "reserved_at" },
        { text: "Type", value: "type" },
        { text: "Comment", value: "comment" },
      ],
      tableItems: [],
    };
  },
  computed: {
    projectName() {
      return this.$store.state.project.domain;
    },
    idProject() {
      return this.$store.state.project.active_project;
    },
    isset_active_project() {
      return this.idProject !== null && this.idProject !== undefined;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    get_project_country_id() {
      if (this.$store.state.project.allowed_projects.length !== 0) {
        let project = this.$store.state.project.allowed_projects.filter(
          (v) => v.id === parseInt(this.$store.state.project.active_project)
        )[0];
        return project.country_id;
      }
      return undefined;
    },
    countryID() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.idProject)
      );
      if (project && project.length > 0) return project;
      else return undefined;
    },
  },
  methods: {
    async openDialogSendToTask() {
      this.dialog_add = true;
      let configs = {
        params: {
          projectID: this.idProject,
          type: 1,
        },
      };
      const data = await this.$store.dispatch(
        "reservedDomains/getAnchorPlanTasks",
        configs
      );
      if (data) {
        this.anchor_plan_tasks = data.items;
      }
    },
    async sendToTask() {
      this.dialog_add_loading = true;

      const payload = {
        config: {
          params: {
            typeID: 1,
            source: "reserved",
            projectID: this.idProject,
          },
        },
        formData: {
          domains: this.selected.map((v) => v.domain),
          entities: this.selected.map((v) => v.id),
          anchorPlanTaskID: this.anchor_plan_task,
          target: this.upload_target,
        },
      };

      const resp = await this.$store.dispatch(
        "reservedDomains/sendToAsk",
        payload
      );

      if (resp && !resp.status) {
        return this.$message.notification({
          title: "Something wrong",
          text: resp.message || "The task was not loaded.",
          type: "error",
          duration: 6000,
        });
      }

      await this.fetchData();
      this.dialog_add_loading = false;
      this.dialog_add = false;
    },
    async removeDonors() {
      let post_data = { entities: this.selected.map((v) => v.id) };
      await this.$store.dispatch("reservedDomains/removeDonors", post_data);
      await this.fetchData();
      this.remove_donors_dialog = false;
    },
    async restoreDonors() {
      let post_data = { entities: this.selected.map((v) => v.id) };
      await this.$store.dispatch("reservedDomains/restoreDonors", post_data);
      await this.fetchData();
      this.restore_donors_dialog = false;
    },
    async fetchData() {
      if (this.idProject !== undefined) {
        const configs = {
          params: {
            typeID: 1,
            filter: {
              countryID: 1,
              dateFrom: this.dateModel[0],
              dateTo: this.dateModel[1],
              projectID: this.projectModel,
              type: this.filter.type,
            },
            dashboardType: "reserved",
            is_mock: localStorage.getItem("is_mock") === "1" ? 1 : 0,
          },
        };
        this.loading = true;

        await this.$store.dispatch("reservedDomains/fetchTableData", configs);
        const tableData = this.$store.getters["reservedDomains/tableData"];
        if (tableData && tableData.items) {
          this.tableItems = tableData.items || [];
        }
        setTimeout(() => (this.loading = false), 500);
      }
    },
    handleGetDataButton() {
      this.fetchData();
      setTimeout(() => (this.modal.shown = false), 500);
    },
  },
};
</script>

<style scoped></style>
