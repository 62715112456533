<template>
  <div ref="avatarGroupWrapper" class="avatar-group">
    <v-tooltip
      v-for="user in getUsers"
      :key="user.id"
      bottom
      content-class="my-shadow--e2 pa-0"
    >
      <template #activator="{ on }">
        <v-avatar v-on="on" :size="avatarSize">
          <img :src="user.avatar" :alt="user.email" />
        </v-avatar>
      </template>
      <v-card class="styled-card--light pa-2">
        <div>
          <b>
            {{ user.name }}
          </b>
        </div>
        <div>
          <small>{{ user.email }}</small>
        </div>
      </v-card>
    </v-tooltip>
    <v-avatar
      v-if="users && users.length > limit"
      :size="avatarSize"
      style="border: none"
      class="avatar-group__limiter"
    >
      <span>+{{ users.length - limit }}</span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 5,
    },
    avatarSize: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    getUsers() {
      try {
        if (this.users && this.users.length > this.limit)
          return this.users.slice(0, this.limit);

        return this.users;
      } catch (e) {
        console.error("Error while computed avatar group users.", e);
        return [];
      }
    },
  },
};
</script>

<style lang="scss">
.avatar-group {
  display: flex;
  .v-avatar {
    margin-right: -6px;
    &:not(&__limiter) {
      border: 2px solid #fff !important;
    }
    &:last-child {
      background-color: #e7e7e7;
    }
  }
}
</style>
