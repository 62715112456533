<template>
  <div class="custom-td-domain d-flex justify-space-between">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <span v-on="on">
          {{ getDomain }}
        </span>
      </template>
      <div>
        {{ data.value }}
      </div>
    </v-tooltip>
    <v-menu bottom content-class="shadow-e2-bordered">
      <template #activator="{ on }">
        <v-icon v-on="on">mdi-dots-vertical</v-icon>
      </template>
      <v-list dense nav>
        <v-list-item @click="goTo(data.value)">
          <v-list-item-content> Visit link </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-link</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="goToAhrefs">
          <v-list-item-content> Visit Ahrefs </v-list-item-content>
          <v-list-item-action>
            <img
              src="https://ahrefs.com/favicon.ico "
              alt="ahrefs icon"
              width="15"
              height="15"
            />
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="gotToCompetitorUrlsDynamic">
          <v-list-item-content>
            Visit Competitor Urls Dynamics
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-link</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="note" @click="$emit('note', data)">
          <v-list-item-content> Write note </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-note</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="row">
          <v-list-item-content> Upload status </v-list-item-content>
          <v-list-item-action>
            <v-tooltip v-if="row.already_uploaded === 1" bottom>
              <template #activator="{ on }">
                <v-icon color="success" v-on="on"
                  >mdi-check-circle-outline</v-icon
                >
              </template>
              <div>Link uploaded</div>
            </v-tooltip>
            <v-tooltip v-else-if="row.already_uploaded === 0" bottom>
              <template #activator="{ on }">
                <v-icon color="warning" v-on="on">mdi-alert-circle</v-icon>
              </template>
              <div>Link uploaded but no data</div>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template #activator="{ on }">
                <v-icon color="" v-on="on">mdi-alert-box-outline</v-icon>
              </template>
              <div>Link not uploaded</div>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import QueryBuilder from "@/mixins/QueryBuilder";

export default {
  props: {
    data: {},
    note: {},
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    activeUrl: {},
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  mixins: [QueryBuilder],
  computed: {
    getDomain() {
      const report_type =
        this.$store.getters["linkDynamicDashbaord/report_type"];
      if (report_type !== "by top urls") {
        return new URL(this.data.value).host;
      }
      return new URL(this.data.value).pathname;
    },
  },
  methods: {
    gotToCompetitorUrlsDynamic() {
      const filters = {
        ...this.filters,
        url: this.data.id,
        parent_url: this.activeUrl.value,
        sl_url: this.data.value,
      };
      const filtersString = this._$buildQuery(filters);
      window.open(
        `/one-page-analyzer/competitor-url-dynamic${filtersString}`,
        "_blank"
      );
    },
    goToAhrefs() {
      const value = this.data.value;
      const url =
        "https://app.ahrefs.com/site-explorer/overview/v2/exact/live?target=";
      window.open(
        url +
          encodeURIComponent(
            value.replace("https://www.", "").replace("http://www.", "")
          ),
        "_blank"
      );
    },
    goTo(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
