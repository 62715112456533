var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('v-menu',{attrs:{"bottom":"","offset-x":"","content-class":"my-shadow--e3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',{staticClass:"styled-card--default",attrs:{"min-width":"180"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',[_c('v-list-item',[_c('small',[_c('b',[_vm._v("Create subtask")])]),_c('small',{staticClass:"ml-2"},[_vm._v("(Select preset)")])]),_c('v-list-item',{on:{"click":() => {
                _vm.handleOpenDialog({
                  data: _vm.rowData,
                  type: 'need_details',
                });
              }}},[_vm._v(" Need details ")]),_c('v-list-item',{on:{"click":() => {
                _vm.handleOpenDialog({
                  data: _vm.rowData,
                  type: 'need_review',
                });
              }}},[_vm._v(" Need review ")]),_c('v-list-item',{on:{"click":() => {
                _vm.handleOpenDialog({
                  data: _vm.rowData,
                  type: 'incorrectly_created_task',
                });
              }}},[_vm._v(" Incorrectly created task ")]),_c('v-list-item',{on:{"click":() => {
                _vm.handleOpenDialog({
                  data: _vm.rowData,
                  type: 'no_final_result_and_plan',
                });
              }}},[_vm._v(" No final result & plan ")]),_c('v-list-item',{on:{"click":() => {
                _vm.handleOpenDialog({
                  data: _vm.rowData,
                  type: 'testing_dev',
                });
              }}},[_vm._v(" Testing: Dev ")]),_c('v-list-item',{on:{"click":() => {
                _vm.handleOpenDialog({
                  data: _vm.rowData,
                  type: 'testing_prod',
                });
              }}},[_vm._v(" Testing: Prod ")]),_c('v-divider'),_c('v-list-item',{on:{"click":() => {
                _vm.handleOpenDialog({ data: _vm.rowData, type: 'empty' });
              }}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_vm._v(" Empty template ")])],1)],1)],1)],1)],1),_c('create-task-dialog',{attrs:{"form":_vm.form,"filters-data":_vm.filtersData,"dialog":_vm.dialog,"loading":_vm.loading},on:{"update:dialog":function($event){_vm.dialog = $event},"createTask":_vm.handleCreateTask}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }