import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'worksectionReportDaily/fetchData'(ctx, payload) {
            const url = '/worksection/report/daily'
            const response = await Service.post(url, payload, true)

            if(response.status === 200){
                return response.data
            }
        },
        async 'worksectionReportDaily/fetchFilters'(ctx, payload = {}) {
            const url = '/worksection/get/daily/filter'
            const response = await Service.post(url, payload, true)

            if(response.status === 200){
                return response.data
            }
        },
    },
    getters: {},
}