<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card
          max-width="320px"
          rounded="xl"
          class="shadow-e1-bordered"
          style="text-align: center"
        >
          <v-card-title class="pb-0 pt-0 justify-center">
            <div style="max-width: 240px">
              <angry-cat-illustration />
            </div>
          </v-card-title>
          <v-card-title class="justify-center pt-0">
            <span style="font-size: 32px; font-weight: 600">Access Denied</span>
          </v-card-title>
          <v-card-text>
            You don't have access. Contact your manager.
          </v-card-text>
          <v-card-actions class="justify-center pb-6">
            <v-btn
              width="160px"
              color="primary"
              rounded
              @click="$router.push('/')"
              >Main page</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AngryCatIllustration from "../UI/Icons/AngryCatIllustration";
export default {
  name: "AccessDeniedIndex",
  components: { AngryCatIllustration },
};
</script>

<style scoped></style>
