<template>
  <td :style="`${getTdBgColor}`">
    <div
      style="white-space: nowrap; height: 100%"
      :class="`d-flex justify-space-between align-center colored-td`"
    >
      <v-badge
        v-if="data.anchor.used > 1"
        color="transparent"
        :class="getColor"
      >
        <template v-slot:badge>
          <span style="color: black">
            {{ data.anchor.used }}
          </span>
        </template>
        <span>{{ data.anchor.text }}</span>
      </v-badge>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <span
            v-on="on"
            style="max-width: 150px; text-overflow: ellipsis; overflow: hidden"
          >
            {{ data.anchor.text }}
          </span>
        </template>
        <span>{{ data.anchor.text }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="goToAnchorControl"
            icon
            class="anchor-button ml-2"
            v-on="on"
          >
            <v-icon>mdi-anchor</v-icon>
          </v-btn>
        </template>
        <span>Link Url Control</span>
      </v-tooltip>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getTdBgColor() {
      let css = 'background-color:'

      if(this.data.task_closed && !this.$vuetify.theme.dark) return (css + '#d9f4d6');
      if(this.data.task_closed && this.$vuetify.theme.dark) return (css + '#223d1f');

      return 'white'
    },
    getColor() {
      const value = Number(this.data.anchor.used);

      if (value >= 2) {
        return "red-badge";
      }
      return "transparent";
    },
  },
  methods: {
    goToAnchorControl() {
      const url = `/ltt/link-url-control/${this.data.url.id}?project_id=${this.data.url.project_id}`;
      window.open(url, "_blank");
    },
  },
};
</script>
