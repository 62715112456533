import service from "@/plugins/service";

/**
 * @class ExportTaskService
 * @param {Object} settings - Settings.
 * @param {string} settings.name - Export name.
 * @param {Object} settings.json - JSON.
 * @param {Number} settings.type - Export type id.
 * @param {Object} settings.context - Vue app Instance.
 */
class ExportTaskService {
  URL = "/export/base/create-task";
  name = null;
  typeId = null;
  jsonData = null;
  context = null;

  constructor(props) {
    this.typeId = props.type;
    this.jsonData = { ...props.json };
    this.context = props.context;
    this.name = props.name;
  }

  set type(value) {
    this.typeId = value;
  }
  set json(value) {
    this.jsonData = { ...value };
  }

  tossSuccessMessage() {
    try {
      if (!this.context) throw new Error("Vue context not initialized.");

      this.context.$message.notification({
        title: "Success",
        text: "Task added to export queue",
        duration: 6000,
        type: "success",
      });
    } catch (e) {
      console.error("Error while tossing success message.", e);
    }
  }
  tossErrorMessage() {
    try {
      if (!this.context) throw new Error("Vue context not initialized.");

      this.context.$message.notification({
        title: "Warning",
        text: "Something went wrong. Contact your manager",
        duration: 6000,
        type: "error",
      });
    } catch (e) {
      console.error("Error while tossing error message.", e);
    }
  }

  async createExport() {
    try {
      const payload = {
        typeID: this.typeId,
        name: this.name,
        json: {
          ...this.jsonData,
        },
      };

      const response = await service.post(this.URL, payload);

      if (response && response.data && response.data.status) {
        this.tossSuccessMessage();
      } else {
        this.tossErrorMessage();
      }

      return { status: true, ...response.data };
    } catch (e) {
      console.error("Error while creating export.", e);

      if (e.response.data) {
        return { status: false, error: e.response.data };
      }

      return { status: false, error: e.response.data };
    }
  }
}

export default ExportTaskService;
