<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col cols="3" v-if="data.data[0]">
          <apexchart
            type="pie"
            height="350"
            :options="getPieOptions()"
            :series="data.data[0].series"
          />
        </v-col>
        <v-col v-else cols="3">
          <no-items-placeholder />
        </v-col>
        <v-col cols="8" v-if="data.data[1]" class="text-end">
          <apexchart
            type="bar"
            height="350"
            :options="getOptions()"
            :series="data.data[1].series"
          />
        </v-col>
        <v-col v-else cols="8">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  methods: {
    getPieOptions() {
      return {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: this.data.data[0].categories,
        colors: this.data.data[0].colors,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          animations: {
            enabled: false,
          },
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: this.data.data[1].categories,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "right",
          offsetX: 0,
          offsetY: 50,
        },
      };
    },
  },
};
</script>
