<template>
  <h2 class="section-name" :data-section-name="name">
    <slot />
  </h2>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    theme: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.section-name {
  font-size: 18px;
  margin-bottom: 8px;
}
</style>
