<template>
  <div
      class="d-inline-flex align-center conv-6-smart-td"
      :style="{justifyContent: getType === 'default' ? 'end' : 'start'}"
  >
    <template v-if="data.value !== 0">
      <span>
      {{data.value}}
    </span>
      <span v-if="getType === 'diff'" class="ml-2">
      <v-icon style="width: 7px" :color="data.direction === 1 ? 'green' : 'error'">
        {{ data.direction === 1 ? 'mdi-triangle-small-up' : 'mdi-triangle-small-down' }}
      </v-icon>
      <span :style="{color: data.direction === 1 ? 'green' : 'red'}">
        {{data.diff}}
      </span>
    </span>
    </template>
  </div>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    getType() {
      // eslint-disable-next-line no-prototype-builtins
      if(this.data.hasOwnProperty('diff')) {
        return 'diff'
      }
      return 'default'
    },
  }
}
</script>