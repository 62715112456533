import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'dbc/fetchTableData'(ctx, payload){
            const url = `/dashboards/base-passage-monitoring/json`
            const response  = await Service.post(url, payload)
            if(response) {
                return response.data
            }
            return null
        },
    },
    getters: {}
}