import service from "../../../plugins/service";
import { REDIRECTS_ENDPOINTS } from "../utils/defaults";

export const redirectsApiService = () => {
  const getResults = ({ page, rowsPerPage }) => {
    return service.get(
      REDIRECTS_ENDPOINTS.GET_RESULTS + `?page=${page}&per_page=${rowsPerPage}`
    );
  };
  const getQueue = ({ page, rowsPerPage }) => {
    return service.get(
      REDIRECTS_ENDPOINTS.GET_QUEUE + `?page=${page}&per_page=${rowsPerPage}`
    );
  };
  const addToQueue = (payload) => {
    return service.post(REDIRECTS_ENDPOINTS.ADD_TO_QUEUE, payload);
  };

  return { getResults, getQueue, addToQueue };
};
