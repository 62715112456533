<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    :sort-by="['links']"
    :sort-desc="[true]"
    hide-default-header
    hide-default-footer
    multi-sort
    :custom-sort="customSort"
    class="top-sw-table"
  >
    <template #header="{ props }">
      <th
        :style="getThStyles"
        v-for="header in props.headers"
        :key="header.value"
      >
        <span v-html="fixHeader(header.text)"></span>
      </th>
    </template>
    <template #body="{ items }">
      <tbody>
        <tr v-for="(row, rowIdx) in items" :key="rowIdx">
          <td
            v-for="(key, i) in Object.keys(row)"
            :key="key + i"
            :style="`background-color: ${rowIdx !== 0 ? getRgb(
              row[key].value,
              getMax(items, key),
              50,
              233,
              153
            ) : ''}`"
          >
            <template v-if="i === 0">
              <span :style="`${row[key].value && row[key].value.length < 30 ? 'white-space:nowrap;' : ''}`">
                {{ row[key].value }}
              </span>
            </template>
            <template v-else>
              <div
                v-if="row[key] && row[key].value"
                class="colored-td colored-td--clickable"
                @click="goToSpiderLink(row[key], activeUrl)"
                style="cursor: pointer"
              >
                {{ row[key].value }}
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import Project from "@/mixins/Project";
import SpiderLink from "@/mixins/components/LinksDynamicDashboard/SpiderLink";
import GetRgb from "@/mixins/GetRgb";

export default {
  mixins: [Project, SpiderLink, GetRgb],
  props: {
    activeUrl: {},
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    customSort: {
      type: Function,
      default() {
        return Function;
      },
    },
  },
  computed: {
    getThStyles() {
      return {
        "vertical-align": "middle",
        height: "auto",
        "writing-mode": "vertical-rl",
        "text-orientation": "mixed",
        "white-space": "nowrap",
        "text-align": "start",
        transform: "rotate(180deg)",
        "padding-top": "10px",
      };
    },
  },
  methods: {
    fixHeader(string) {
      return string.replaceAll("_", " ").replaceAll("/", "<br/>");
    },
    getMax(items, key) {
      let bigger = 0;

      items.forEach((row, idx) => {
        if(idx === 0) return;

        const val = row[key].value;
        if (typeof val !== "string") {
          if (parseInt(val) > bigger) bigger = parseInt(val);
        }
      });

      return bigger;
    },
  },
};
</script>

<style scoped></style>
