<template>
  <td :style="{ color: getColor }">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <span v-on="on">{{ $moment(value).format("YYYY-MM-DD") }}</span>
      </template>
      <div>{{ status }}</div>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: ["value", "status"],
  computed: {
    getColor() {
      const today = this.$moment(new Date());
      const diffDays = this.$moment(this.value).diff(today, "days");

      if (diffDays <= 8) {
        return "green";
      }
      if (diffDays <= 15) {
        return "orange";
      }
      if (diffDays === 0 && this.status !== "done") {
        return "black";
      }

      return "gray";
    },
  },
};
</script>
