<template>
  <router-link
    class="v-list-item ma-0 reset-link-styles"
    :to="{ name: child.name }"
  >
    <v-list-item-action class="d-flex justify-center">
      <v-icon v-if="!isNested" size="18" v-text="child.icon" />
    </v-list-item-action>
    <v-list-item-content :title="child.title">
      <v-list-item-title class="d-flex align-center">
        <span v-html="child.title" />
        <v-chip v-if="child.meta?.new" small class="ml-1">New</v-chip>
      </v-list-item-title>
    </v-list-item-content>
  </router-link>
</template>

<script>
export default {
  props: {
    isNested: {
      type: Boolean,
      default: false,
    },
    child: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
