export const PERFORMANCE_ENDPOINTS = {
  GET_PERFORMANCE: "/dashboards/content/performance",
};

export const PAGE_CONFIG = {
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Performance",
      disabled: true,
    },
  ],
  title: "Performance",
};
