export const pageConfig = {
  pageTitle: "Routes",
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Access",
      disabled: true,
    },
    {
      text: "Sections",
      disabled: true,
    },
  ],
};

export const ROUTES_DATA_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Route",
    value: "route",
  },
  {
    text: "",
    value: "_actions",
    sortable: false,
    width: "80px",
  },
];
