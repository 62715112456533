<template>
  <div id="chart">
    <apexchart type="radialBar" :height="chartOptions.chart.height" :options="chartOptions"
               :series="series"></apexchart>  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "RadialBarChart",
  props: ["series", "label"],
  components: {apexchart: VueApexCharts},
  data() {
    return {
    }
  },
  methods: {},
  created() {
    if (this.series[0] >= 90) {
      this.chartOptions.colors = ['#61ff81']
    } else if (this.series[0] >= 60) {
      this.chartOptions.colors = ['#ffcc00']
    } else {
      this.chartOptions.colors = ['#ff4c4c']
    }
  },
  computed: {
    chartOptions(){
      return {
        chart: {
          type: 'radialBar',
          sparkline: {
            enabled: true
          },
          height: 250
        },
        colors: ['#ff4c4c'],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px'
              },
              value: {
                color: '#111',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: [this.label ?? 'Label'],
      }
    }
  }
}
</script>

<style scoped>

</style>