import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "daily-report-v3/fetchData"(ctx, payload) {
      const url = `/worksection/report/daily3`;
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
  },
  getters: {},
};
