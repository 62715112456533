<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :loading="loading"
              :headers="headers"
              :items="items"
              class="elevation-1"
          >
            <template v-slot:item.status="{ item }">
              <v-chip small :color="getStatusColor(item.status)">
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.progress="{ item }">
              <v-progress-linear dark rounded :value="item.done_queries * 100 / item.queries_all" height="20">
                <template v-slot:default="{ value }">
                  <small>{{ Math.ceil(value) }}%</small>
                </template>
              </v-progress-linear>
            </template>
            <template v-slot:item.stat="{ item }">
              {{ item.queries_all }} / {{ item.done_queries }} / {{ item.failed_queries }}
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.started="{ item }">
              <v-icon v-if="item.started" color="primary">
                mdi-rocket-launch-outline
              </v-icon>
              <v-icon v-else>
                mdi-clock-outline
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                  color="primary"
                  depressed
                  icon
                  @click="download(item)"
              >
                <v-icon small>
                  mdi-cloud-download-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
        fixed
        dark
        fab
        bottom
        right
        color="pink"
        @click="dialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title>
          Create Task
        </v-card-title>
        <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-autocomplete
                v-model="form.parser"
                :items="parser_list"
                :rules="rules.parser"
                filled
                color="blue-grey lighten-2"
                label="Parser"
                item-text="name"
                item-value="name"
                @change="changeParser"
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-textarea
                v-model="form.queries"
                :rules="rules.queries"
                label="Queries"
                required
                filled
                no-resize
            ></v-textarea>
            <div v-for="item in fields" :key="item.name">
              <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="form.options[item.name]"
                  :items="item.items"
                  filled
                  color="blue-grey lighten-2"
                  :label="item.label"
                  :rules="item.rules"
              ></v-autocomplete>
            </div>
            <v-alert text type="warning" v-for="item in requirements" :key="item">
              {{ item }}
            </v-alert>
          </v-form>
          <v-alert type="error" text v-if="error !== null">
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="createTask"
              :disabled="status_importing"
              :loading="status_importing"
          >
            Create Task
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Index",
  data() {
    const language_items = [
      {"text": "аҧсуа бызшәа, аҧсшәа", "value": "ab"},
      {"text": "Afaraf", "value": "aa"},
      {"text": "Afrikaans", "value": "af"},
      {"text": "Akan", "value": "ak"},
      {"text": "Shqip", "value": "sq"},
      {"text": "አማርኛ", "value": "am"},
      {"text": "العربية", "value": "ar"},
      {"text": "aragonés", "value": "an"},
      {"text": "Հայերեն", "value": "hy"},
      {"text": "অসমীয়া", "value": "as"},
      {"text": "авар мацӀ, магӀарул мацӀ", "value": "av"},
      {"text": "avesta", "value": "ae"},
      {"text": "aymar aru", "value": "ay"},
      {"text": "azərbaycan dili", "value": "az"},
      {"text": "bamanankan", "value": "bm"},
      {"text": "башҡорт теле", "value": "ba"},
      {"text": "euskara, euskera", "value": "eu"},
      {"text": "беларуская мова", "value": "be"},
      {"text": "বাংলা", "value": "bn"},
      {"text": "भोजपुरी", "value": "bh"},
      {"text": "Bislama", "value": "bi"},
      {"text": "bosanski jezik", "value": "bs"},
      {"text": "brezhoneg", "value": "br"},
      {"text": "български език", "value": "bg"},
      {"text": "ဗမာစာ", "value": "my"},
      {"text": "català, valencià", "value": "ca"},
      {"text": "Chamoru", "value": "ch"},
      {"text": "нохчийн мотт", "value": "ce"},
      {"text": "chiCheŵa, chinyanja", "value": "ny"},
      {"text": "中文(Zhōngwén), 汉语, 漢語", "value": "zh"},
      {"text": "чӑваш чӗлхи", "value": "cv"},
      {"text": "Kernewek", "value": "kw"},
      {"text": "corsu, lingua corsa", "value": "co"},
      {"text": "ᓀᐦᐃᔭᐍᐏᐣ", "value": "cr"},
      {"text": "hrvatski jezik", "value": "hr"},
      {"text": "čeština, český jazyk", "value": "cs"},
      {"text": "dansk", "value": "da"},
      {"text": "ދިވެހި", "value": "dv"},
      {"text": "Nederlands, Vlaams", "value": "nl"},
      {"text": "རྫོང་ཁ", "value": "dz"},
      {"text": "English", "value": "en"},
      {"text": "Esperanto", "value": "eo"},
      {"text": "eesti, eesti keel", "value": "et"},
      {"text": "Eʋegbe", "value": "ee"},
      {"text": "føroyskt", "value": "fo"},
      {"text": "vosa Vakaviti", "value": "fj"},
      {"text": "suomi, suomen kieli", "value": "fi"},
      {"text": "français, langue française", "value": "fr"},
      {"text": "Fulfulde, Pulaar, Pular", "value": "ff"},
      {"text": "Galego", "value": "gl"},
      {"text": "ქართული", "value": "ka"},
      {"text": "Deutsch", "value": "de"},
      {"text": "ελληνικά", "value": "el"},
      {"text": "Avañe'ẽ", "value": "gn"},
      {"text": "ગુજરાતી", "value": "gu"},
      {"text": "Kreyòl ayisyen", "value": "ht"},
      {"text": "(Hausa) هَوُسَ", "value": "ha"},
      {"text": "עברית", "value": "he"},
      {"text": "Otjiherero", "value": "hz"},
      {"text": "हिन्दी, हिंदी", "value": "hi"},
      {"text": "Hiri Motu", "value": "ho"},
      {"text": "magyar", "value": "hu"},
      {"text": "Interlingua", "value": "ia"},
      {"text": "Bahasa Indonesia", "value": "id"},
      {"text": "Originally called Occidental; then Interlingue aft", "value": "ie"},
      {"text": "Gaeilge", "value": "ga"},
      {"text": "Asụsụ Igbo", "value": "ig"},
      {"text": "Iñupiaq, Iñupiatun", "value": "ik"},
      {"text": "Ido", "value": "io"},
      {"text": "Íslenska", "value": "is"},
      {"text": "Italiano", "value": "it"},
      {"text": "ᐃᓄᒃᑎᑐᑦ", "value": "iu"},
      {"text": "日本語 (にほんご)", "value": "ja"},
      {"text": "ꦧꦱꦗꦮ, Basa Jawa", "value": "jv"},
      {"text": "kalaallisut, kalaallit oqaasii", "value": "kl"},
      {"text": "ಕನ್ನಡ", "value": "kn"},
      {"text": "Kanuri", "value": "kr"},
      {"text": "कश्मीरी, كشميري‎", "value": "ks"},
      {"text": "қазақ тілі", "value": "kk"},
      {"text": "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ", "value": "km"},
      {"text": "Gĩkũyũ", "value": "ki"},
      {"text": "Ikinyarwanda", "value": "rw"},
      {"text": "Кыргызча, Кыргыз тили", "value": "ky"},
      {"text": "коми кыв", "value": "kv"},
      {"text": "Kikongo", "value": "kg"},
      {"text": "한국어", "value": "ko"},
      {"text": "Kurdî, کوردی‎", "value": "ku"},
      {"text": "Kuanyama", "value": "kj"},
      {"text": "latine, lingua latina", "value": "la"},
      {"text": "Lëtzebuergesch", "value": "lb"},
      {"text": "Luganda", "value": "lg"},
      {"text": "Limburgs", "value": "li"},
      {"text": "Lingála", "value": "ln"},
      {"text": "ພາສາລາວ", "value": "lo"},
      {"text": "lietuvių kalba", "value": "lt"},
      {"text": "Kiluba", "value": "lu"},
      {"text": "latviešu valoda", "value": "lv"},
      {"text": "Gaelg, Gailck", "value": "gv"},
      {"text": "македонски јазик", "value": "mk"},
      {"text": "fiteny malagasy", "value": "mg"},
      {"text": "Bahasa Melayu, بهاس ملايو‎", "value": "ms"},
      {"text": "മലയാളം", "value": "ml"},
      {"text": "Malti", "value": "mt"},
      {"text": "te reo Māori", "value": "mi"},
      {"text": "मराठी", "value": "mr"},
      {"text": "Kajin M̧ajeļ", "value": "mh"},
      {"text": "Монгол хэл", "value": "mn"},
      {"text": "Dorerin Naoero", "value": "na"},
      {"text": "Diné bizaad", "value": "nv"},
      {"text": "isiNdebele", "value": "nd"},
      {"text": "नेपाली", "value": "ne"},
      {"text": "Owambo", "value": "ng"},
      {"text": "Norsk Bokmål", "value": "nb"},
      {"text": "Norsk Nynorsk", "value": "nn"},
      {"text": "Norsk", "value": "no"},
      {"text": "ꆈꌠ꒿ Nuosuhxop", "value": "ii"},
      {"text": "isiNdebele", "value": "nr"},
      {"text": "occitan, lenga d'òc", "value": "oc"},
      {"text": "ᐊᓂᔑᓈᐯᒧᐎᓐ", "value": "oj"},
      {"text": "ѩзыкъ словѣньскъ", "value": "cu"},
      {"text": "Afaan Oromoo", "value": "om"},
      {"text": "ଓଡ଼ିଆ", "value": "or"},
      {"text": "ирон æвзаг", "value": "os"},
      {"text": "ਪੰਜਾਬੀ", "value": "pa"},
      {"text": "पाऴि", "value": "pi"},
      {"text": "فارسی", "value": "fa"},
      {"text": "język polski, polszczyzna", "value": "pl"},
      {"text": "پښتو", "value": "ps"},
      {"text": "Português", "value": "pt"},
      {"text": "Runa Simi, Kichwa", "value": "qu"},
      {"text": "Rumantsch Grischun", "value": "rm"},
      {"text": "Ikirundi", "value": "rn"},
      {"text": "Română", "value": "ro"},
      {"text": "русский", "value": "ru"},
      {"text": "संस्कृतम्", "value": "sa"},
      {"text": "sardu", "value": "sc"},
      {"text": "सिन्धी, سنڌي، سندھی‎", "value": "sd"},
      {"text": "Davvisámegiella", "value": "se"},
      {"text": "gagana fa'a Samoa", "value": "sm"},
      {"text": "yângâ tî sängö", "value": "sg"},
      {"text": "српски језик", "value": "sr"},
      {"text": "Gàidhlig", "value": "gd"},
      {"text": "chiShona", "value": "sn"},
      {"text": "සිංහල", "value": "si"},
      {"text": "Slovenčina, Slovenský Jazyk", "value": "sk"},
      {"text": "Slovenski Jezik, Slovenščina", "value": "sl"},
      {"text": "Soomaaliga, af Soomaali", "value": "so"},
      {"text": "Sesotho", "value": "st"},
      {"text": "Español", "value": "es"},
      {"text": "Basa Sunda", "value": "su"},
      {"text": "Kiswahili", "value": "sw"},
      {"text": "SiSwati", "value": "ss"},
      {"text": "Svenska", "value": "sv"},
      {"text": "தமிழ்", "value": "ta"},
      {"text": "తెలుగు", "value": "te"},
      {"text": "тоҷикӣ, toçikī, تاجیکی‎", "value": "tg"},
      {"text": "ไทย", "value": "th"},
      {"text": "ትግርኛ", "value": "ti"},
      {"text": "བོད་ཡིག", "value": "bo"},
      {"text": "Türkmen, Түркмен", "value": "tk"},
      {"text": "Wikang Tagalog", "value": "tl"},
      {"text": "Setswana", "value": "tn"},
      {"text": "Faka Tonga", "value": "to"},
      {"text": "Türkçe", "value": "tr"},
      {"text": "Xitsonga", "value": "ts"},
      {"text": "татар теле, tatar tele", "value": "tt"},
      {"text": "Twi", "value": "tw"},
      {"text": "Reo Tahiti", "value": "ty"},
      {"text": "ئۇيغۇرچە‎, Uyghurche", "value": "ug"},
      {"text": "Українська", "value": "uk"},
      {"text": "اردو", "value": "ur"},
      {"text": "Oʻzbek, Ўзбек, أۇزبېك‎", "value": "uz"},
      {"text": "Tshivenḓa", "value": "ve"},
      {"text": "Tiếng Việt", "value": "vi"},
      {"text": "Volapük", "value": "vo"},
      {"text": "Walon", "value": "wa"},
      {"text": "Cymraeg", "value": "cy"},
      {"text": "Wollof", "value": "wo"},
      {"text": "Frysk", "value": "fy"},
      {"text": "isiXhosa", "value": "xh"},
      {"text": "ייִדיש", "value": "yi"},
      {"text": "Yorùbá", "value": "yo"},
      {"text": "Saɯ cueŋƅ, Saw cuengh", "value": "za"},
      {"text": "isiZulu", "value": "zu"}
    ]

    const search_engine_items = ['com', 'ac', 'ad', 'ae', 'com.af', 'com.ag', 'com.ai', 'al', 'am', 'co.ao', 'com.ar', 'as', 'at', 'com.au', 'az', 'ba', 'com.bd', 'be', 'bf', 'bg', 'com.bh', 'bi', 'bj', 'com.bn', 'com.bo', 'com.br', 'bs', 'co.bw', 'by', 'com.bz', 'ca', 'com.kh', 'cc', 'cd', 'cf', 'cat', 'cg', 'ch', 'ci', 'co.ck', 'cl', 'cm', 'cn', 'cn', 'com.co', 'co.cr', 'com.cu', 'cv', 'com.cy', 'cz', 'de', 'dj', 'dk', 'dm', 'com.do', 'dz', 'com.ec', 'ee', 'com.eg', 'es', 'com.et', 'fi', 'com.fj', 'fm', 'fr', 'ga', 'ge', 'gf', 'gg', 'com.gh', 'com.gi', 'gl', 'gm', 'gp', 'gr', 'com.gt', 'gy', 'com.hk', 'hn', 'hr', 'ht', 'hu', 'co.id', 'iq', 'ie', 'co.il', 'im', 'co.in', 'io', 'is', 'it', 'je', 'com.jm', 'jo', 'co.jp', 'co.ke', 'com.kh', 'ki', 'kg', 'co.kr', 'com.kw', 'kz', 'la', 'com.lb', 'com.lc', 'li', 'lk', 'co.ls', 'lt', 'lu', 'lv', 'com.ly', 'co.ma', 'md', 'me', 'mg', 'mk', 'ml', 'mn', 'ms', 'com.mt', 'mu', 'mv', 'mw', 'com.mx', 'com.my', 'co.mz', 'com.na', 'ne', 'com.nf', 'com.ng', 'com.ni', 'nl', 'no', 'com.np', 'nr', 'nu', 'co.nz', 'com.om', 'com.pa', 'com.pe', 'com.ph', 'com.pk', 'pl', 'com.pg', 'pn', 'com.pr', 'ps', 'pt', 'com.py', 'com.qa', 'ro', 'rs', 'ru', 'rw', 'com.sa', 'com.sb', 'sc', 'se', 'com.sg', 'sh', 'si', 'sk', 'com.sl', 'sn', 'sm', 'so', 'st', 'com.sv', 'td', 'tg', 'co.th', 'com.tj', 'tk', 'tl', 'tm', 'to', 'com.tn', 'com.tr', 'tt', 'com.tw', 'co.tz', 'com.ua', 'co.ug', 'co.uk', 'com.uy', 'co.uz', 'com.vc', 'co.ve', 'vg', 'co.vi', 'com.vn', 'vu', 'ws', 'co.za', 'co.zm', 'co.zw', 'com.mm']

    return {
      dialog: false,
      valid: true,
      error: null,
      parser_list: [
        {header: 'Links'},
        {
          name: 'Backlinks',
          description: 'The parser allows you to check backlinks.',
          example: 'https://papersowl.com/ https://www.facebook.com/papersowlcom',
          requirements: [
            'Separator: only space'
          ]
        },
        {divider: true},
        {header: 'Serp'},
        {
          name: 'GoogleTop',
          description: 'The parser allows you to.',
          example: 'hello dolly',
          options: {
            fields: [
              {
                name: 'related',
                label: 'Related',
                type: 'select',
                rules: [
                  v => !!v || 'Related is required'
                ],
                default: 'No',
                items: ['Yes', 'No'],
              },
              {
                name: 'top',
                label: 'Top',
                type: 'select',
                rules: [
                  v => !!v || 'Top is required'
                ],
                default: 10,
                items: [10, 20, 50, 100],
              },
              {
                name: 'search_engine',
                label: 'Search Engine',
                type: 'select',
                rules: [
                  v => !!v || 'Search Engine is required'
                ],
                default: 'com',
                items: search_engine_items,
              },
              {
                name: 'language',
                label: 'Language',
                type: 'select',
                rules: [
                  v => !!v || 'Language is required'
                ],
                default: 'en',
                items: language_items,
              }
            ]
          }
        },
        {
          name: 'GoogleSuggest',
          description: 'The parser allows you to.',
          example: 'hello dolly',
          options: {
            fields: [
              {
                name: 'search_engine',
                label: 'Search Engine',
                type: 'select',
                rules: [
                  v => !!v || 'Search Engine is required'
                ],
                default: 'com',
                items: search_engine_items,
              },
              {
                name: 'language',
                label: 'Language',
                type: 'select',
                rules: [
                  v => !!v || 'Language is required'
                ],
                default: 'en',
                items: language_items,
              }
            ]
          }
        },
        {
          name: 'GoogleIndexUrl',
          description: 'The parser allows you to.',
          example: 'https://papersowl.com/',
          options: {
            fields: []
          }
        }
      ],
      form: {
        parser: null,
        queries: '',
        options: {}
      },
      rules: {
        parser: [
          v => !!v || 'Parser is required'
        ],
        queries: [
          v => !!v || 'Queries is required'
        ]
      },
      status_importing: false,
      errors: [],
      loading: false,
      headers: [
        {text: 'ID', value: 'id', width: 80, align: 'center'},
        {text: 'Parser', value: 'parser'},
        {text: 'Status', value: 'status', width: 100, align: 'center'},
        {text: 'Progress', value: 'progress', width: 100, align: 'center'},
        {text: 'All/Done/Failed', value: 'stat', width: 100, align: 'center'},
        {text: 'Started', value: 'started', width: 50, align: 'center'},
        {text: 'Created At', value: 'created_at', width: 150, align: 'center'},
        {text: '', value: 'actions', align: 'center', width: 50, sortable: false},
      ],
      items: [],
      requirements: [],
      fields: [],
    }
  },
  methods: {
    formatDate(date) {
      return date.split('T')[0]
    },
    createTask() {
      let self = this

      if (!self.$refs.form.validate()) {
        return
      }

      self.status_importing = true
      self.error = null

      let queries = self.form.queries.split('\n').map((v) => v.trim()).filter((v) => v.length > 0)

      let url = `${self.$store.state.server_url}/aparser/create-task`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      let data = {
        parser: self.form.parser,
        queries: queries,
        options: self.form.options,
      }

      axios
          .post(url, data, config)
          .then(() => {
            self.dialog = false
            self.fetchData()
          })
          .catch(() => self.error = 'Some Error')
          .finally(() => self.status_importing = false)
    },
    changeParser() {
      let self = this

      let parser_data = self.parser_list.filter(v => v.name === self.form.parser)[0]

      self.fields = []
      self.requirements = []
      self.form.queries = []

      if (parser_data.example !== undefined) {
        self.form.queries = parser_data.example
      }

      if (parser_data.requirements !== undefined) {
        self.requirements = parser_data.requirements
      }

      if (parser_data.options !== undefined && parser_data.options.fields !== undefined) {
        self.fields = parser_data.options.fields

        self.fields.forEach(v => {
          self.form.options[v.name] = v.default
        })
      }
    },
    getStatusColor(status) {
      let colors = {
        'stopped': 'secondary',
        'stopping': 'info',
        'paused': 'secondary',
        'pausing': 'info',
        'working': 'secondary',
        'starting': 'info',
        'completed': 'success',
        'error': 'error',
      }

      return colors[status]
    },
    fetchData() {
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/aparser/get-tasks`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            self.items = r.data.items
          })
          .finally(() => self.loading = false)
    },
    download(item) {
      let self = this

      self.loading = true

      let url = `${self.$store.state.server_url}/aparser/get-download-url`;

      let config = {
        params: {
          autoincrementID: item.autoincrement_id
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            this.$message.notification({
              title: 'Done.',
              text: `
                    <u>Google Top</u>
                    Download link: <a href="${r.data.url}" target="_blank">PRESS ME</a>
              `,
              duration: 0,
            })
          })
          .finally(() => self.loading = false)
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>