<template>
  <div v-if="data" class="px-4">
    <v-card class="styled-card--error" elevation="0" flat>
      <v-card-title> GPM: Reasons for Rejection </v-card-title>
      <v-divider />
      <v-card-text>
        <v-menu
          v-for="(after, idx) in data.cons.after"
          :key="after.id || idx"
          content-class="my-shadow--e3"
        >
          <template #activator="{ on }">
            <div class="mb-2 d-flex">
              <div>
                <v-chip x-small class="error white--text mr-1 px-2">
                  {{ after.amount }}
                </v-chip>
                {{ after.text }}
              </div>
              <v-spacer />
              <div class="d-flex">
                <div class="px-2 d-flex">
                  <div
                    @click="handleToggleLike(after)"
                    style="cursor: pointer"
                    :class="{
                      'primary--text': after.is_liked,
                    }"
                  >
                    <v-icon
                      :color="`${after.is_liked ? 'primary' : ''}`"
                      small
                      title="Like this feedback"
                      >mdi-thumb-up</v-icon
                    >
                    <small class="ml-1">{{ after.likes }}</small>
                  </div>
                  <div
                    @click="handleToggleDislike(after)"
                    style="cursor: pointer"
                    :class="{
                      'primary--text': after.is_disliked,
                      'ml-2': true,
                    }"
                  >
                    <v-icon
                      :color="`${after.is_disliked ? 'primary' : ''}`"
                      small
                      title="Dislike this feedback"
                      >mdi-thumb-down</v-icon
                    >
                    <small class="ml-1">{{ after.dislikes }}</small>
                  </div>
                </div>
                <v-divider vertical />
                <a v-on="on" class="px-2"> view details </a>
              </div>
            </div>
          </template>
          <v-card class="styled-card--default">
            <v-card-title>
              {{ after.text }}
            </v-card-title>
            <v-divider />
            <v-card-text class="px-0">
              <v-data-table :headers="headers" :items="after.data">
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SiteAnalysisCommentsMixin from "@/mixins/SiteAnalysisCommentsMixin";
import service from "@/plugins/service";

export default {
  props: {
    data: {},
  },
  mixins: [SiteAnalysisCommentsMixin],
  data: () => ({
    headers: [
      {
        text: "Project",
        value: "project",
      },
      {
        text: "User",
        value: "user",
      },
      {
        text: "Created at",
        value: "created_at",
      },
    ],
  }),
  methods: {
    async setReactionToComment(status, commentData) {
      try {
        const url = "/view-info-by-site/set-reaction-gpm";

        console.log(commentData);

        const payload = {
          status,
          domain: commentData.domain,
          gpm_decline_id: commentData.gpm_decline_id,
          domain_id: commentData.domain_id,
        };

        await service.post(url, payload);
      } catch (e) {
        console.error("Error while setting reaction to comment.", e);
      }
    },
  },
};
</script>
