<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Manage domain owner</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex">
        <v-spacer />
        <v-chip outlined label @click="openCreateDomainOwnerDialog()">
          Create domain owner <v-icon small right>mdi-plus</v-icon>
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card class="styled-card--default">
          <v-card-text class="pa-0">
            <v-data-table
              :loading="loadings.domainOwners"
              :items="items"
              :headers="headers"
            >
              <template #[`item._actions`]="{ item }">
                <v-chip
                  label
                  class="flat-chip flat-chip--primary px-2"
                  @click="handleEdit(item.id)"
                  :disabled="loadings.domainOwnersById"
                >
                  <v-progress-circular
                    v-if="loadings.domainOwnersById"
                    size="12"
                    width="2"
                    color="primary"
                  />
                  <v-icon v-else small>mdi-pen</v-icon>
                </v-chip>
                <v-chip
                  label
                  class="flat-chip flat-chip--error px-2 ml-2"
                  @click="handleRemove(item.id)"
                >
                  <v-icon small>mdi-trash-can</v-icon>
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <domain-dialog
      :model-value="createDomainOwnerDialog"
      @update:modelValue="createDomainOwnerDialog = $event"
      :loading="loadings.saveDomainOwner"
      :clear-form="clearCreateDomainForm"
      @save="handleSaveDomainOwner"
    />
    <edit-domain-dialog
      :model-value="editDomainDialog"
      @update:modelValue="editDomainDialog = $event"
      :form-data="domainDataBuffer"
      :loading="loadings.updateDomainOwner"
      :clear-form="clearCreateDomainForm"
      @update="handleUpdateDomainOwner"
    />
  </v-container>
</template>

<script>
import DomainDialog from "@/components/NicheAnalyzer/ManageDomainOwner/DomainDialog.vue";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";
import EditDomainDialog from "@/components/NicheAnalyzer/ManageDomainOwner/EditDomainDialog.vue";

export default {
  components: { EditDomainDialog, DomainDialog },
  mixins: [Notification],
  data: () => ({
    items: [],
    createDomainOwnerDialog: false,
    mode: null,
    domainDataBuffer: {
      form: null,
      id: null,
    },
    editDomainDialog: false,
    loadings: {
      domainOwners: false,
      saveDomainOwner: false,
      domainOwnersById: false,
      updateDomainOwner: false,
    },
    clearCreateDomainForm: false,
    headers: [
      {
        text: "id",
        value: "id",
        width: "80px",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Actions",
        value: "_actions",
        align: "end",
      },
    ],
  }),
  created() {
    this.initPage();
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Niche Analyzer",
          to: { name: "niche.analyzer.index" },
        },
        {
          text: "Project dashboard",
          to: {
            name: "niche.analyzer.project-dashboard",
            query: { na_project_id: this.$route.query.na_project_id },
          },
        },
        {
          text: "Manage domain owner",
          disabled: true,
        },
      ];
    },
    getProjectId() {
      return this.$route.query.na_project_id;
    },
  },
  methods: {
    handleSaveDomainOwner(formData) {
      this.saveDomainOwner(formData);
    },
    handleUpdateDomainOwner(formData) {
      this.updateDomainOwner(formData);
    },
    initPage() {
      this.fetchDomainOwners();
    },
    async saveDomainOwner(payload) {
      try {
        const url = `/niche-analyzer/project/${this.getProjectId}/domain-owners`;

        this.loadings.saveDomainOwner = true;

        const response = await service.post(url, payload);

        if (response?.data?.status) {
          this.createDomainOwnerDialog = false;
          this.mSuccess({
            title: "Success",
            text: "Domain owner has been saved successfully.",
          });
          this.clearDomainForm();
          this.fetchDomainOwners();
        }
      } catch (e) {
        console.error("Error while saving new domain owner.", e);
      } finally {
        this.loadings.saveDomainOwner = false;
      }
    },
    async updateDomainOwner(payload) {
      try {
        const url = `/niche-analyzer/project/${this.getProjectId}/domain-owners/${this.domainDataBuffer.id}`;

        this.loadings.updateDomainOwner = true;

        const response = await service.put(url, payload);

        if (response?.data?.status) {
          this.mSuccess({
            title: "Success",
            text: "Domain owner has been updated successfully.",
          });
          this.editDomainDialog = false;
          this.fetchDomainOwners();
        }
      } catch (e) {
        console.error("Error while updating new domain owner.", e);
      } finally {
        this.loadings.updateDomainOwner = false;
      }
    },
    async deleteDomainOwner(id) {
      window.confirm("Are you sure you want to delete this domain owner?");

      try {
        const url = `/niche-analyzer/project/${this.getProjectId}/domain-owners/${id}`;

        this.loadings.deleteDomainOwner = true;

        const response = await service.delete(url);

        if (response?.data?.status) {
          this.mSuccess({
            title: "Success",
            text: "Domain owner has been deleted successfully.",
          });
          this.fetchDomainOwners();
        }
      } catch (e) {
        console.error("Error while updating new domain owner.", e);
      } finally {
        this.loadings.deleteDomainOwner = false;
      }
    },
    clearDomainForm() {
      this.clearCreateDomainForm = true;

      setTimeout(() => {
        this.clearCreateDomainForm = false;
      }, 500);
    },
    async fetchDomainOwners() {
      try {
        const url = `/niche-analyzer/project/${this.getProjectId}/domain-owners`;
        this.loadings.domainOwners = true;

        const response = await service.get(url);

        this.items = response.data.items;
      } catch (e) {
        console.error("Error while loading domain owners", e);
      } finally {
        this.loadings.domainOwners = false;
      }
    },
    async fetchDomainDataById(id) {
      try {
        const url = `/niche-analyzer/project/${this.getProjectId}/domain-owners/${id}/edit`;
        this.loadings.domainOwnersById = true;

        const response = await service.get(url);

        return response.data;
      } catch (e) {
        console.error("Error while loading domain owners by id.", e);
      } finally {
        this.loadings.domainOwnersById = false;
      }
    },
    async handleEdit(domainId) {
      const data = await this.fetchDomainDataById(domainId);
      this.domainDataBuffer.form = {
        ...data.owner_data,
        domains: data.domains,
      };
      this.domainDataBuffer.id = domainId;
      this.editDomainDialog = true;
    },
    handleRemove(id) {
      this.deleteDomainOwner(id);
    },
    openCreateDomainOwnerDialog() {
      this.createDomainOwnerDialog = true;
    },
  },
};
</script>
