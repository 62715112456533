<template>
  <v-card class="styled-card--light">
    <template v-if="!hideHeader">
      <v-card-title class="flex-column justify-start align-start">
        <h4 style="font-size: 17px">
          {{ item.theme }}
          <v-icon
            v-if="!disableWriteOnThemeButton"
            small
            @click="handleOpenFeedbackModalWithCurrentTheme(item.theme)"
          >
            mdi-pen
          </v-icon>
        </h4>
        <v-row dense style="width: 100%">
          <v-col
            v-for="(ratingItem, idx) in item.ratings"
            :key="idx"
            cols="3"
            class="d-flex align-center"
          >
            <v-chip
              :color="getRatingColor(ratingItem.rating, monochromeRating)"
              label
              class="px-2 mr-1 d-flex justify-center"
              style="min-width: 38px"
              >{{ ratingItem.rating }}</v-chip
            >
            <feedback-avatars-group
              :users="ratingItem.users"
              :avatar-size="24"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
    </template>
    <v-card-text class="site-analysis__feedback px-0">
      <expansion-block
        :max-height="expansionBlockMaxHeight"
        without-hider
        class="site-analysis__dense-expansion-block"
        :disabled="Array.isArray(item.comments) && item.comments.length === 1"
      >
        <v-row dense>
          <v-col
            v-for="comment in item.comments"
            ref="singleCommentElement"
            :key="comment.id"
            cols="12"
          >
            <comment-item
              class="site-analysis__large-comment site-analysis__dense-comment"
              vote-top
              hide-avatar
              monochrome-rating="primary"
              :comment="comment"
            />
          </v-col>
        </v-row>
        <template #toggle="{ toggle, isExpanded }">
          <div class="d-flex justify-center pt-2">
            <v-chip
              color="transparent"
              class="primary--text"
              label
              @click="toggle"
            >
              {{ isExpanded ? "Hide comments" : "Show more comments" }}
              <v-icon small right>
                {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-chip>
          </div>
        </template>
      </expansion-block>
    </v-card-text>
  </v-card>
</template>

<script>
import FeedbackAvatarsGroup from "@/components/UI/FeedbackAvatarsGroup.vue";
import ExpansionBlock from "@/components/UI/ExpansionBlock.vue";
import CommentItem from "@/components/SiteAnalysis/UI/CommentItem.vue";
import SiteAnalysisFeedbackMixin from "@/mixins/SiteAnalysisFeedbackMixin";
import eventPipe from "@/events/eventPipe";

export default {
  props: {
    item: {},
    monochromeRating: String,
    hideHeader: Boolean,
    disableWriteOnThemeButton: Boolean,
  },
  mixins: [SiteAnalysisFeedbackMixin],
  components: { CommentItem, ExpansionBlock, FeedbackAvatarsGroup },
  data: () => ({
    expansionBlockMaxHeight: "66px",
    initializingMaxHeightTimeout: null,
  }),
  mounted() {
    this.initializeExpansionBlockMaxHeightWhenDomLoaded();
  },
  methods: {
    handleOpenFeedbackModalWithCurrentTheme(themeName) {
      eventPipe.$emit("open-feedback-with-selected-theme", themeName);
    },
    initializeExpansionBlockMaxHeightWhenDomLoaded() {
      try {
        let attempt = 0;

        if (
          Array.isArray(this.item.comments) &&
          this.item.comments.length === 1
        )
          return null;

        this.initializingMaxHeightTimeout = setTimeout(() => {
          check();
        }, 500);

        const check = () => {
          attempt++;

          if (this.$refs.singleCommentElement !== undefined) {
            clearTimeout(this.initializingMaxHeightTimeout);
            return (this.expansionBlockMaxHeight =
              this.$refs.singleCommentElement[0].offsetHeight + "px");
          }

          if (attempt >= 20) {
            return clearTimeout(this.initializingMaxHeightTimeout);
          }

          check();
        };
      } catch (e) {
        console.error(
          "Error while initializing expansion block max height.",
          e
        );
      }
    },
  },
};
</script>
