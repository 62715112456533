<template>
  <div>
    <div v-if="item.type === 'email'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-text-field
            :disabled="!item.is_responsible"
            append-icon="mdi-at"
            v-model="value.value"
            :label="item.title"
            :title="item.id"
            :rules="getRules(item.json_rules)"
            hide-details="auto"
            outlined
          />
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'checkbox'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-checkbox
            :disabled="!item.is_responsible"
            v-model="value.value"
            :label="item.title"
            :title="item.id"
            :rules="getRules(item.json_rules)"
            hide-details="auto"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'editor'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-list-item-title class="mb-3">
            {{ item.title }}
          </v-list-item-title>
          <tiptap-vuetify
            v-if="item.is_responsible"
            v-model="value.value"
            :extensions="extensions"
          />
          <div v-else>
            {{ value.value }}
          </div>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <!--no rules-->
    <div v-else-if="item.type === 'text'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-text-field
            :disabled="!item.is_responsible"
            v-model="value.value"
            :label="item.title"
            :title="item.id"
            outlined
            :rules="getRules(item.json_rules)"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="item.type === 'num'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-text-field
            :disabled="!item.is_responsible"
            append-icon="mdi-counter"
            v-model="value.value"
            type="number"
            :title="item.id"
            :label="item.title"
            outlined
            :rules="getRules(item.json_rules)"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="item.type === 'price'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-text-field
            :disabled="!item.is_responsible"
            :append-icon="formatCurrencyIcon(value.currency)"
            :title="item.id"
            v-model="value.value"
            type="number"
            :label="item.title"
            clearable
            outlined
            :rules="getRules(item.json_rules)"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="2" class="flex-grow-1 flex-shrink-0 pr-3">
          <v-select
            v-model="value.currency"
            :items="item.json.currencies"
            label="Currency"
            outlined
            clearable
            hide-details
            @change="forceUpdate"
          ></v-select>
        </v-col>
        <v-col cols="2" class="flex-grow-1 flex-shrink-0 pr-3">
          <v-select
            v-model="value.period"
            :items="item.json.periods"
            label="Period"
            outlined
            clearable
            hide-details
            @change="forceUpdate"
          ></v-select>
        </v-col>
        <v-col cols="2" class="flex-grow-1 flex-shrink-0 pr-3">
          <v-select
            v-model="value.discount"
            :items="item.json.discount"
            label="Discount"
            outlined
            clearable
            hide-details
            @change="forceUpdate"
          ></v-select>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="item.type === 'content_price'">
      <v-card class="styled-card--default">
        <v-card-title>
          {{ item.title }}
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-chip
                class="px-1"
                outlined
                label
                v-on="on"
                @click="loadFieldHistory(item)"
              >
                <v-icon small :color="item.changed ? 'warning' : ''">
                  mdi-history
                </v-icon>
                <v-icon small> mdi-chevron-down </v-icon>
              </v-chip>
            </template>
            <span>{{ header.updated_email }}</span>
          </v-tooltip>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row dense>
            <v-col class="d-flex" style="gap: 8px">
              <v-text-field
                :disabled="!item.is_responsible"
                :append-icon="formatCurrencyIcon(value.currency)"
                :title="item.id"
                v-model="value.value"
                type="number"
                label="Content price"
                dense
                clearable
                filled
                :rules="getRules(item.json_rules)"
                hide-details="auto"
              />

              <v-autocomplete
                v-model="value.currency"
                :items="item.json.currencies"
                label="Currency"
                filled
                clearable
                style="max-width: 120px"
                dense
                hide-details
                @change="forceUpdate"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="value.discount"
                :items="item.json.discount"
                label="Discount"
                filled
                dense
                clearable
                hide-details
                @change="forceUpdate"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                v-model="value.creator"
                label="Creator"
                :items="item.json.creator"
                hide-details
                filled
                clearable
                dense
                @change="forceUpdate"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                v-model="value.nickname"
                label="Nickname"
                hide-details
                dense
                clearable
                filled
                @change="forceUpdate"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="item.type === 'select'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-combobox
            :disabled="!item.is_responsible"
            append-icon="mdi-playlist-edit"
            v-model="value.value"
            :items="item.json.values"
            :title="item.id"
            :label="item.title"
            outlined
            :rules="getRules(item.json_rules)"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="item.type === 'link'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-text-field
            :disabled="!item.is_responsible"
            append-icon="mdi-link"
            v-model="value.value"
            :title="item.id"
            :label="item.title"
            outlined
            :rules="getRules(item.json_rules)"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="item.type === 'textarea'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-textarea
            :disabled="!item.is_responsible"
            no-resize
            v-model="value.value"
            :label="item.title"
            :title="item.id"
            outlined
            :rules="getRules(item.json_rules)"
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="item.type === 'similar_articles'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-btn
            color="info"
            outlined
            @click="viewSimilarArticles"
            :loading="similar_articles_loading"
          >
            View Similar Articles
            <v-icon class="ml-2">mdi-eye-outline</v-icon>
          </v-btn>
          <v-dialog v-model="similar_articles_dialog">
            <v-card>
              <v-card-title>Similar Articles</v-card-title>
              <v-card-text>
                <v-simple-table fixed-header dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Example URL</th>
                        <th class="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in similar_articles"
                        :key="item.example_url"
                      >
                        <td class="text-left">
                          <a :href="item.example_url" target="_blank">{{
                            item.example_url
                          }}</a>
                        </td>
                        <td class="text-center">
                          <v-icon :title="item.title"
                            >mdi-information-outline</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <!--no rules-->
    <div v-else-if="item.type === 'checklist'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-list-item-title :title="item.id">
            {{ item.title }}
          </v-list-item-title>
          <div v-for="(i, index) in item.json.values" :key="index">
            <v-checkbox
              :disabled="!item.is_responsible"
              v-model="value.value"
              :label="i"
              :value="i"
              :rules="getRules(item.json_rules)"
              hide-details="auto"
              @change="forceUpdate"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'rating'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-list-item-title :title="item.id">
            {{ item.title }}
          </v-list-item-title>
          <div v-for="(i, index) in item.json.values" :key="index">
            {{ i }}
            <v-rating
              :disabled="!item.is_responsible"
              v-model="value.value[index]"
              background-color="grey"
              color="error"
              :length="10"
              hover
              @change="forceUpdate"
            ></v-rating>
          </div>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <!--no rules-->
    <div v-else-if="item.type === 'url_anchor_simple'">
      <v-list-item-title class="mb-4">
        {{ item.title }}
      </v-list-item-title>
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-row dense v-for="i in 5" :key="i" class="sm-mb-4">
            <v-col lg="" md="" sm="" xl="12" cols="12">
              <v-row>
                <v-col class="flex-grow-1 flex-shrink-0">
                  <v-text-field
                    :disabled="!item.is_responsible"
                    append-icon="mdi-link"
                    v-model="value.value.urls[i]"
                    label="Url"
                    outlined
                    dense
                    :rules="getRules(item.json_rules)"
                    hide-details="auto"
                  />
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-0 d-flex pl-0">
                  <div style="display: flex; align-items: center">
                    <v-btn small icon @click="copy(value.value.urls[i])">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col lg="" md="" sm="" xl="12" cols="12">
              <v-row>
                <v-col class="flex-grow-1 flex-shrink-0 d-flex">
                  <v-text-field
                    :disabled="!item.is_responsible"
                    append-icon="mdi-anchor"
                    v-model="value.value.anchors[i]"
                    label="Anchor"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-0 d-flex pl-0">
                  <div style="display: flex; align-items: center">
                    <v-btn small icon @click="copy(value.value.anchors[i])">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'url_anchor_simple_extended'">
      <v-list-item-title class="mb-4">
        {{ item.title }}
      </v-list-item-title>
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-row dense v-for="i in 5" :key="i">
            <v-col cols="1">
              <v-switch
                v-model="mode.additionalLinks[i]"
                dense
                :rules="getRules(item.json_rules)"
                hide-details="auto"
                @change="handleChangeMode($event, i)"
                style="margin: 0"
              ></v-switch>
            </v-col>
            <template v-if="mode.additionalLinks[i]">
              <v-col>
                <v-row>
                  <v-col class="flex-grow-1 flex-shrink-0">
                    <v-text-field
                      :disabled="!item.is_responsible"
                      append-icon="mdi-link"
                      v-model="value.value.urls[i]"
                      @change="handleChangeUrl($event, i)"
                      label="Url"
                      outlined
                      dense
                      :rules="getRules(item.json_rules)"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col class="flex-grow-0 flex-shrink-0 d-flex pl-0">
                    <div style="display: flex; align-items: center">
                      <v-btn small icon @click="copy(value.value.urls[i])">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="flex-grow-1 flex-shrink-0 d-flex">
                    <v-text-field
                      :disabled="!item.is_responsible"
                      append-icon="mdi-anchor"
                      v-model="value.value.anchors[i]"
                      @change="handleChangeAnchor($event, i)"
                      label="Anchor"
                      outlined
                      dense
                      :rules="getRules(item.json_rules)"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col class="flex-grow-0 flex-shrink-0 d-flex pl-0">
                    <div style="display: flex; align-items: center">
                      <v-btn small icon @click="copy(value.value.anchors[i])">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </template>
            <template v-else>
              <v-col class="flex-grow-1 flex-shrink-0">
                <v-autocomplete
                  :disabled="!item.is_responsible"
                  append-icon="mdi-link"
                  v-model="value.value.url_anchors[i]"
                  :items="item.json.url_anchors"
                  outlined
                  dense
                  label="Url (Anchor)"
                  :rules="getRules(item.json_rules)"
                  hide-details="auto"
                  @change="handleChangeUrlAnchor($event, i)"
                >
                  <template #item="{ item }">
                    {{ item.text }}
                    <a
                      :href="item.path"
                      target="_blank"
                      class="reset-link-styles ml-2"
                    >
                      <v-btn icon>
                        <v-icon small color="primary"> mdi-open-in-new </v-icon>
                      </v-btn>
                    </a>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="flex-grow-0 flex-shrink-0 d-flex pl-0">
                <div style="display: flex; align-items: center">
                  <v-btn small icon @click="copy(formatUrlAnchor('url', i))">
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn small icon @click="copy(formatUrlAnchor('anchor', i))">
                    <v-icon small>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'url_anchor'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-list-item-title class="mb-4">
            {{ item.title }}
          </v-list-item-title>
          <v-row dense v-for="i in 5" :key="i">
            <v-col>
              <v-row>
                <v-col class="flex-grow-1 flex-shrink-0">
                  <v-autocomplete
                    :disabled="!item.is_responsible"
                    append-icon="mdi-link"
                    v-model="value.value.url_anchors[i]"
                    :items="item.json.url_anchors"
                    outlined
                    dense
                    label="Url (Anchor)"
                    :rules="getRules(item.json_rules)"
                    hide-details="auto"
                    @change="forceUpdate"
                  >
                    <template #item="{ item }">
                      {{ item.text }}
                      <a
                        :href="item.path"
                        target="_blank"
                        class="reset-link-styles ml-2"
                      >
                        <v-btn icon>
                          <v-icon small color="primary">
                            mdi-open-in-new
                          </v-icon>
                        </v-btn>
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-0 d-flex pl-0">
                  <div style="display: flex; align-items: center">
                    <v-btn small icon @click="copy(formatUrlAnchor('url', i))">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      icon
                      @click="copy(formatUrlAnchor('anchor', i))"
                    >
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'url_anchor_all'">
      <v-list-item-title class="mb-4">
        {{ item.title }}
      </v-list-item-title>
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-row dense v-for="i in 5" :key="i">
            <v-col>
              <v-row>
                <v-col class="flex-grow-1 flex-shrink-0">
                  <v-autocomplete
                    :disabled="!item.is_responsible"
                    append-icon="mdi-link"
                    v-model="value.value.url_anchors[i]"
                    :items="getFixedUrlAnchors"
                    outlined
                    dense
                    label="Url (Anchor)"
                    :rules="getRules(item.json_rules)"
                    hide-details="auto"
                    clearable
                    @change="forceUpdate"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.text }}
                    </template>

                    <template #item="data">
                      <template v-if="data.item.is_subheader">
                        <v-subheader class="pa-0">
                          {{ data.item.text }}
                          <a
                            v-if="data.item.path"
                            :href="data.item.path"
                            target="_blank"
                            class="reset-link-styles ml-2"
                          >
                            <v-btn icon>
                              <v-icon small color="primary">
                                mdi-open-in-new
                              </v-icon>
                            </v-btn>
                          </a>
                          <template
                            v-if="data.item.tags && data.item.tags.length > 0"
                          >
                            <v-chip
                              v-for="tag in data.item.tags"
                              :key="tag"
                              label
                              x-small
                              class="mx-1"
                              :color="getTagColor(tag)"
                            >
                              {{ tag }}
                            </v-chip>
                          </template>
                        </v-subheader>
                      </template>
                      <template v-else>
                        {{ data.item.text }}

                        <a
                          v-if="data.item.path"
                          :href="data.item.path"
                          target="_blank"
                          class="reset-link-styles ml-2"
                        >
                          <v-btn icon>
                            <v-icon small color="primary">
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </a>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-0 d-flex pl-0">
                  <div style="display: flex; align-items: center">
                    <v-btn small icon @click="copy(formatUrlAnchor('url', i))">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      icon
                      @click="copy(formatUrlAnchor('anchor', i))"
                    >
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="item.type === 'date'">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col
          cols="2"
          style="min-width: 100px; max-width: 100%"
          class="flex-grow-1 flex-shrink-0 pr-3"
        >
          <v-menu width="290px" content-class="remove-dialog-shadow">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="value.value"
                :label="item.title"
                prepend-inner-icon="mdi-calendar"
                hide-details
                class="mb-1"
                readonly
                outlined
                v-bind="attrs"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="value.value" class="shadow-e2-bordered">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="1"
          style="max-width: 30px"
          class="flex-grow-0 flex-shrink-0 d-flex"
        >
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.changed ? 'warning' : ''"
                  @click="loadFieldHistory(item)"
                  >mdi-history
                </v-icon>
              </template>
              <span>{{ header.updated_email }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-bottom-sheet v-model="field_history_sheet">
      <div class="white">
        <v-list style="max-height: 250px; overflow: auto">
          <v-list-item v-for="item in field_history" :key="item.id">
            <v-list-item-avatar>
              <v-img :src="item.photo"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.date"></v-list-item-subtitle>
              <v-list-item-subtitle v-text="item.value"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
import axios from "axios";

export default {
  name: "ContentFieldManager",
  props: ["item", "header", "type"],
  components: { TiptapVuetify },
  data() {
    return {
      date_modal: false,
      mode: {
        additionalLinks: [false, false, false, false, false],
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
        Image,
      ],
      field_history: [],
      field_history_sheet: false,
      similar_articles_dialog: false,
      similar_articles_loading: false,
      similar_articles: [],
    };
  },
  computed: {
    getFixedUrlAnchors() {
      return this.item.json.url_anchors.map((item) => {
        if (item.group) {
          item.text = item.header;
          item.is_subheader = true;
          delete item.header;
        }

        delete item.group;

        return item;
      });
    },
    value: {
      set: function (value) {
        this.$store.state.Gpm.field_values[this.type][this.item.id] = value;
      },
      get: function () {
        const value =
          this.$store.state.Gpm.field_values[this.type][this.item.id] ?? null;

        if (this.item.type === "date" && !value) return [];
        return value;
      },
    },
  },
  methods: {
    getRules(types = []) {
      const rules = [];

      if (types.includes("required")) {
        rules.push((v) => !!v || "Field is required.");
      }

      return rules;
    },
    getTagColor(tag) {
      if (tag === "USED") return "warning";
      return "info";
    },
    handleChangeUrlAnchor(event, index) {
      this.value.value.url_anchors[index] = event;
      this.$forceUpdate();
    },
    handleChangeUrl(event, index) {
      this.value.value.urls[index] = event;
    },
    handleChangeAnchor(event, index) {
      this.value.value.anchors[index] = event;
    },
    copy(value) {
      this.$copyText(value);
    },
    viewSimilarArticles() {
      let self = this;

      self.similar_articles = [];
      self.similar_articles_dialog = false;
      self.similar_articles_loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-similar-articles`;

      let config = {
        params: {
          entityID: this.$route.params.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.similar_articles = r.data.similar_articles;
        self.similar_articles_dialog = true;
        self.similar_articles_loading = false;
      });
    },
    loadFieldHistory(item) {
      let self = this;

      self.field_history = [];
      self.field_history_sheet = false;

      let url = `${self.$store.state.server_url}/gpm/entity-view/get-field-history`;

      let config = {
        params: {
          entityID: this.$route.params.id,
          fieldID: item.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.field_history = r.data.history;
        self.field_history_sheet = true;
      });
    },
    formatUrlAnchor(type, i) {
      let urlAnchorID;
      let firstEl;

      urlAnchorID = this.value.value.url_anchors[i];

      firstEl = this.item.json.url_anchors.filter(
        (v) => v.value === urlAnchorID
      )[0];

      let str =
        firstEl !== undefined ? firstEl.text.replace(" (", "\t(") : null;

      return type === "url"
        ? str.split("\t")[0]
        : str.split("\t")[1].replaceAll(/[()]/gi, "");
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    formatCurrencyIcon(currency) {
      switch (currency) {
        case "¥":
          return "mdi-currency-jpy";
        case "$":
          return "mdi-currency-usd";
        case "€":
          return "mdi-currency-eur";
        case "£":
          return "mdi-currency-gbp";
        default:
          return "mdi-flask-empty-outline";
      }
    },
    handleChangeMode(event, i) {
      if (!event) {
        this.value.value.anchors[i] = null;
        this.value.value.urls[i] = null;
        return;
      }
      this.value.value.url_anchors[i] = null;
    },
    updateField() {
      this.$store.state.Gpm.field_values[this.type][this.item.id] = this.value;
    },
    initValueType(force = false) {
      let value = this.item.value;

      if (value === null || value.value === null || force) {
        switch (this.item.type) {
          case "url_anchor":
          case "url_anchor_all":
          case "url_anchor_simple":
          case "url_anchor_simple_extended":
            value = { value: { urls: [], anchors: [], url_anchors: [] } };
            break;
          case "checklist":
            value = { value: [] };
            break;
          case "rating":
            value = { value: [] };
            break;
          case "price":
            value = {
              value: null,
              currency: null,
              period: null,
              discount: null,
            };
            break;
          case "content_price":
            value = { value: null, currency: null };
            break;
          default:
            value = { value: null };
            break;
        }
      }

      this.value = value;
    },
    initRowsMode() {
      if (this.value === null || this.value.value === null) {
        return;
      }

      if (Array.isArray(this.value.value) && this.value.value.length === 0) {
        return;
      }

      if (
        this.mode.additionalLinks === undefined ||
        this.mode.additionalLinks == null
      ) {
        return;
      }

      try {
        for (let i = 0; i < 5; i++) {
          this.mode.additionalLinks[i] =
            this.value.value.urls[i] !== null &&
            this.value.value.urls[i] !== undefined;
        }
      } catch {
        //
      }
    },
  },
  created() {
    this.initValueType();
    this.initRowsMode(); // after init value type !!!
  },
};
</script>

<style scoped></style>
