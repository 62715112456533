<template>
  <v-container fluid class="competitors-positions-dashboard">
    <v-row>
      <v-col cols="12">
        <h2>Competitors Positions</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12" class="mt-2">
        <v-row dense>
          <v-col>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="competitorsDialogModel = !competitorsDialogModel"
                  outlined
                  block
                  style="height: 40px"
                >
                  Enter competitors
                </v-btn>
              </template>
              <div>
                <div v-if="filters.competitors.length === 0">
                  No competitor has been selected
                </div>
                <div v-else>
                  selected <b>{{ filters.competitors.length }}</b> Competitors
                </div>
              </div>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    competitorsNrdDialogModel = !competitorsNrdDialogModel
                  "
                  outlined
                  block
                  style="height: 40px"
                >
                  Enter NRD competitors
                </v-btn>
              </template>
              <div>
                <div v-if="filters.competitors_nrd.length === 0">
                  No competitor has been selected
                </div>
                <div v-else>
                  selected
                  <b>{{ filters.competitors_nrd.length }}</b> Competitors
                </div>
              </div>
            </v-tooltip>
          </v-col>
          <v-col>
            <easy-range-input
              label="Positions"
              :model-value="filters.positions"
              @update:modelValue="
                _$handleRangeChange($event, 'positions', 'filters')
              "
            />
          </v-col>
          <v-col>
            <smart-date-filter
              :model-value="filters.date"
              @update:modelValue="handleChangeDate"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.sub_project"
              hide-spin-buttons
              outlined
              hide-details
              dense
              :menu-props="menuProps"
              label="Sub Projects"
              item-text="name"
              clearable
              item-value="id"
              :items="_$getSubprojects"
              @change="_$handleFilterChange($event, 'sub_project')"
            ></v-autocomplete>
          </v-col>
          <v-col></v-col>
          <v-col class="d-flex justify-end">
            <vue-json-to-csv
              :json-data="getJsonToCsvData()"
              csv-title="Competitios_Positions"
              :separator="'\t'"
            >
              <v-btn
                color="success"
                text
                :disabled="tableData.items.length === 0"
              >
                Export CSV <v-icon class="ml-2">mdi-export-variant</v-icon>
              </v-btn>
            </vue-json-to-csv>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              block
              @click="fetchData"
              :loading="loadings.table"
              color="primary"
              >Get Data</v-btn
            >
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn-toggle v-if="topItems && topItems.length > 0">
              <template v-for="top in topItems">
                <v-btn
                  :key="top.text"
                  outlined
                  width="120px"
                  style="height: 100%; min-height: 85px"
                  :class="`btn-toggle ${
                    isPositionsActive(top.value)
                      ? 'primary--text v-btn--active'
                      : ''
                  }`"
                  @click="handleChangeTop(top.value)"
                >
                  <template #default>
                    <div class="competitors-positions-dashboard__top-button">
                      <div v-if="top.percent" class="top-button__percent">
                        {{ top.percent }}%
                      </div>
                      <div class="top-button__name" style="font-weight: 600">
                        {{ top.text }}
                      </div>
                      <div
                        v-if="top.diff !== undefined && top.diff !== null"
                        class="top-button__diff"
                      >
                        <span>
                          {{ top.amountOfKeys }}
                        </span>
                        <span
                          v-if="top.diff > 0"
                          class="success--text diff__item"
                        >
                          <v-icon color="success"> mdi-menu-up </v-icon>
                          {{ String(top.diff).replaceAll("-", "") }}
                        </span>
                        <span
                          v-else-if="top.diff < 0"
                          class="error--text diff__item"
                        >
                          <v-icon color="error"> mdi-menu-down </v-icon>
                          {{ String(top.diff).replaceAll("-", "") }}
                        </span>
                      </div>
                    </div>
                  </template>
                </v-btn>
              </template>
            </v-btn-toggle>
          </v-col>
          <v-col />
          <v-col />
          <v-col class="d-flex justify-end">
            <v-btn
              v-if="changeItems && changeItems.length > 0"
              color="error"
              style="height: 85px"
              outlined
              @click="filters.report_type = null"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col class="d-flex align-end">
            <change-items-button-group
              :model-value="filters.report_type"
              @update:modelValue="handleUpdateReportType"
              :items="changeItems"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card class="shadow-e1-bordered" rounded="lg">
          <v-card-title>
            <v-text-field
              v-model="search"
              dense
              outlined
              hide-spin-buttons
              label="Search"
              append-icon="mdi-magnify"
              style="max-width: 320px"
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :search="search"
              :loading="loadings.table"
              :headers="tableData.headers"
              :items="tableData.items"
              multi-sort
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
              }"
              :custom-sort="_$smartCustomTableSort"
            >
              <template #[`item.dif_pos`]="{ value }">
                <div :class="`colored-td ${getDiffPosColor(value)}`">
                  {{ value }}
                </div>
              </template>
              <template #[`item.best_position`]="{ value }">
                <div :class="`colored-td ${getBestPosColor(value)}`">
                  {{ value }}
                </div>
              </template>
              <template #[`item.pos_project`]="{ item }">
                <div>
                  {{ item.pos_project }}
                  <sup v-if="item.pos_project_diff">
                    <diff-item :value="item.pos_project_diff" />
                  </sup>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <competitors-dialog
      title="Enter Competitors"
      :model-value="competitorsDialogModel"
      @update:modelValue="competitorsDialogModel = $event"
      :competitors-value="filters.competitors"
      @update:competitorsValue="filters.competitors = $event"
      component-name="competitors-dashboard-items"
      competitor-name="competitors"
    />
    <competitors-dialog
      title="Enter NRD Competitors"
      :model-value="competitorsNrdDialogModel"
      @update:modelValue="competitorsNrdDialogModel = $event"
      :competitors-value="filters.competitors_nrd"
      @update:competitorsValue="filters.competitors_nrd = $event"
      component-name="competitors-nrd-dashboard-items"
      competitor-name="competitors_nrd"
    />
  </v-container>
</template>

<script>
import service from "../../plugins/service";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import Location from "../../mixins/Location";
import EasyRangeInput from "../UI/EasyRangeInput";
import TableSorting from "../../mixins/TableSorting";
import VueJsonToCsv from "vue-json-to-csv";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import Project from "@/mixins/Project";
import CompetitorsDialog from "@/components/CompetitorsPositions/UI/CompetitorsDialog.vue";
import ChangeItemsButtonGroup from "@/components/CompetitorsPositions/UI/ChangeItemsButtonGroup.vue";
import DiffItem from "@/components/CompetitorsPositions/UI/DiffItem.vue";

export default {
  components: {
    DiffItem,
    ChangeItemsButtonGroup,
    CompetitorsDialog,
    SmartDateFilter,
    EasyRangeInput,
    VueJsonToCsv,
  },
  mixins: [FiltersHandling, Location, TableSorting, Project],
  data: () => ({
    competitorsDialogModel: false,
    competitorsNrdDialogModel: false,
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Competitors Positions",
        disabled: true,
      },
    ],
    loadings: {
      table: false,
    },
    search: null,
    filters: {
      sub_project: null,
      competitors: [],
      competitors_nrd: [],
      positions: [null, null],
      report_type: null,
      date: [],
    },
    menuProps: DEFAULT_MENU_PROPS,
    topItems: [],
    changeItems: [],
    tableData: {
      headers: [],
      items: [],
    },
  }),
  created() {
    this._$collectParams(
      ["competitors", "competitors_nrd", "positions", "date"],
      "filters"
    );

    if (this.filters.date.length === 0) {
      this.filters.date = [
        this.$moment().subtract(1, "days").format("YYYY-MM-DD"),
        this.$moment().format("YYYY-MM-DD"),
      ];
    }

    this.fetchData();
  },
  methods: {
    isPositionsActive(value) {
      return JSON.stringify(value) === JSON.stringify(this.filters.positions);
    },
    handleUpdateReportType(event) {
      this._$handleFilterChange(event, "report_type");
      this.filters.report_type = event;
    },
    handleChangeDate(event) {
      this._$handleFilterChange(event, "date");
      this.filters.date = event;
    },
    handleChangeTop(event) {
      this._$handleRangeChange(event, "positions", "filters");
    },
    getDiffPosColor(value) {
      if (value === 0) return "green";
      if (value >= 1 && value <= 3) return "orange";
      if (value > 4) return "red";

      if (value <= -1 && value >= -3) return "orange";
      if (value <= -4) return "red";
      return "";
    },
    getBestPosColor(value) {
      if (value >= 1 && value <= 3) return "green";
      if (value >= 4 && value <= 6) return "yellow";
      if (value >= 7 && value <= 10) return "orange";
      if (value >= 11) return "red";
      return "";
    },
    getJsonToCsvData() {
      return this.tableData.items;
    },
    async fetchData() {
      try {
        this.loadings.table = true;
        const url = `/serp/positions-competitors/${this.$store.getters["active_project"]}`;

        const payload = {
          ...this.filters,
        };

        const resp = await service.post(url, payload);

        this.loadings.table = false;

        if (resp && resp.data) {
          this.tableData = resp.data;
          this.topItems = resp.data.topItems;
          this.changeItems = resp.data.changeItems;
        }
      } catch {
        this.loadings.table = false;
      }
    },
  },
};
</script>
