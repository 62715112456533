<template>
  <div class="ma-4" ref="parent">
    <v-row dense>
      <v-col
        v-for="graph in componentData()?.items"
        :key="graph.id"
        :cols="graph.colspan || 6"
      >
        <dev-dashboard-horizontal-chart :data="graph" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DevDashboardHorizontalChart from "../../DashboardDev/components/DevDashboardHorizontalChart.vue";
import LazyDomElementRenderingMixin from "../../../mixins/LazyDomElementRenderingMixin";

export default {
  components: { DevDashboardHorizontalChart },

  mixins: [LazyDomElementRenderingMixin],

  inject: ["componentData"],

  mounted() {
    this.initializeLazyRender([this.$refs.parent]);
  },
};
</script>
