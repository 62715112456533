<template>
  <span>
    <v-menu bottom offset-x content-class="my-shadow--e3">
      <template #activator="{ on }">
        <v-btn icon v-on="on" small>
          <v-icon small>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card class="styled-card--default" min-width="180">
        <v-card-text class="pa-0">
          <v-list>
            <v-list-item>
              <small><b>Create subtask</b></small>
              <small class="ml-2">(Select preset)</small>
            </v-list-item>

            <v-list-item
              @click="
                () => {
                  handleOpenDialog({
                    data: rowData,
                    type: 'need_details',
                  });
                }
              "
            >
              Need details
            </v-list-item>
            <v-list-item
              @click="
                () => {
                  handleOpenDialog({
                    data: rowData,
                    type: 'need_review',
                  });
                }
              "
            >
              Need review
            </v-list-item>
            <v-list-item
              @click="
                () => {
                  handleOpenDialog({
                    data: rowData,
                    type: 'incorrectly_created_task',
                  });
                }
              "
            >
              Incorrectly created task
            </v-list-item>
            <v-list-item
              @click="
                () => {
                  handleOpenDialog({
                    data: rowData,
                    type: 'no_final_result_and_plan',
                  });
                }
              "
            >
              No final result & plan
            </v-list-item>
            <v-list-item
              @click="
                () => {
                  handleOpenDialog({
                    data: rowData,
                    type: 'testing_dev',
                  });
                }
              "
            >
              Testing: Dev
            </v-list-item>
            <v-list-item
              @click="
                () => {
                  handleOpenDialog({
                    data: rowData,
                    type: 'testing_prod',
                  });
                }
              "
            >
              Testing: Prod
            </v-list-item>
            <v-divider />
            <v-list-item
              @click="
                () => {
                  handleOpenDialog({ data: rowData, type: 'empty' });
                }
              "
            >
              <v-list-item-icon class="mr-2">
                <v-icon small>mdi-file-document</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Empty template </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>

    <create-task-dialog
      :form="form"
      :filters-data="filtersData"
      :dialog="dialog"
      @update:dialog="dialog = $event"
      @createTask="handleCreateTask"
      :loading="loading"
    />
  </span>
</template>

<script>
import { defineComponent } from "vue";
import CreateTaskDialog from "@/components/MonthlyReport/CreateTaskDialog.vue";
import useTaskCreating from "../../../hooks/worksection-daily-report/useTaskCreating";

export default defineComponent({
  components: { CreateTaskDialog },
  props: {
    rowData: {
      type: Object,
      default: () => ({}),
    },
    filtersData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const {
      createTask,
      changeTaskTags,
      form,
      dialog,
      loading,
      handleOpenDialog,
    } = useTaskCreating();

    const handleCreateTask = async () => {
      const success = await createTask();
      if (success) await changeTaskTags({ plus: "wait action", minus: "" });
    };

    return {
      dialog,
      form,
      loading,
      handleCreateTask,
      handleOpenDialog,
    };
  },
});
</script>
