import Service from "@/plugins/service";

export default {
  state: {
    sValueP: null,
  },
  mutations: {
    "conversion-7/setSValueP"(state, payload) {
      state.sValueP = payload;
    },
  },
  actions: {
    async "conversion-8/fetchData"(ctx, { payload, urlId }) {
      const url = "/analytics/conversion/report8/" + urlId;

      const resp = await Service.post(url, payload, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "conversion-8/fetchUrls"(ctx, domainId) {
      const url = "/analytics/conversion/keitaro/urls/" + domainId;

      const resp = await Service.post(url, {}, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
    async "conversion-8/fetchBrands"(ctx, domainId) {
      const url = "/analytics/conversion/keitaro/brands/" + domainId;

      const resp = await Service.post(url, {}, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
  },
  getters: {
    "conversion-8/sValueP": (state) => state.sValueP,
  },
};
