<template>
  <div class="link-url-control">
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mx-auto" v-if="isset_active_project">
            <v-card-text class="headline font-weight-bold">
              <h4>{{ url }} - Link Url Control</h4>
              <v-banner>
                <template v-slot:actions>
                  <div style="max-width: 300px">
                    <v-autocomplete
                      label="Link Types"
                      v-model="linkTypesSelected"
                      :items="linkTypesItems"
                      chips
                      @change="fetch_data"
                      deletable-chips
                      multiple
                      small-chips
                      solo
                      hide-details
                    ></v-autocomplete>
                  </div>
                  <div style="max-width: 300px">
                    <v-autocomplete
                      label="Anchor Types"
                      v-model="anchorTypesSelected"
                      :items="anchorTypesItems"
                      chips
                      @change="fetch_data"
                      deletable-chips
                      multiple
                      small-chips
                      solo
                      hide-details
                    ></v-autocomplete>
                  </div>
                  <div style="max-width: 300px">
                    <v-checkbox
                      v-model="filter.target_keys"
                      @change="fetch_data"
                      label="Target Keys"
                    ></v-checkbox>
                  </div>

                  <div style="max-width: 300px">
                    <v-checkbox
                      label="On Page"
                      @change="fetch_data"
                      v-model="filter.on_page"
                    ></v-checkbox>
                  </div>
                  <div style="max-width: 300px">
                    <v-checkbox
                      label="DoFollow"
                      @change="fetch_data"
                      v-model="filter.follow"
                    ></v-checkbox>
                  </div>
                  <div style="max-width: 300px">
                    <v-checkbox
                      label="Index"
                      @change="fetch_data"
                      v-model="filter.google_index"
                    ></v-checkbox>
                  </div>
                  <div style="max-width: 300px">
                    <v-checkbox
                      :label="get_disallow_label"
                      :title="'Disallow file links declined for this URL'"
                      @change="fetch_data"
                      v-model="filter.disallow"
                    ></v-checkbox>
                  </div>
                  <div style="max-width: 300px">
                    <v-dialog
                      ref="dialog"
                      v-model="date_modal"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filter.date_range"
                          label="Data Range"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.date_range"
                        range
                        @change="fetch_data"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="date_modal = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="date_modal = false">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                </template>
              </v-banner>
              <v-row v-if="!loadings.fetch_data">
                <v-col md="3">
                  <TopSmallGoogleIndex :url_full="url_full" :filter="filter" />
                </v-col>
                <v-col md="3">
                  <TopSmallFollow :url_full="url_full" :filter="filter" />
                </v-col>
                <v-col md="3">
                  <TopSmallLinkTypes :url_full="url_full" :filter="filter" />
                </v-col>
                <v-col md="3">
                  <TopSmallAnchorTypes :url_full="url_full" :filter="filter" />
                </v-col>
              </v-row>
              <v-row class="align-end">
                <v-col sm="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    solo
                    class="align-end"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <v-data-table
                    :search="search"
                    :custom-filter="customSearch"
                    :headers="table.headers"
                    :items="table.items"
                    :sort-by="['volume', 'clicks', 'impressions']"
                    multi-sort
                    :sort-desc="[true, true, true]"
                    class="elevation-1"
                    :items-per-page="15"
                    dense
                  >
                    <template
                      v-for="(h, index) in table.headers"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <v-tooltip bottom :key="h + index">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="text-center">{{
                            header.text
                          }}</span>
                        </template>
                        <span>{{ header.title }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item.id + item.phrase">
                          <td>
                            <div style="min-width: 300px">
                              <span :style="get_color_phrase(item)">
                                <span v-if="item.phrase_html !== null"
                                  ><span v-html="item.phrase_html"></span
                                ></span>
                                <span v-else v-html="item.phrase"></span>
                                <v-icon
                                  color="blue"
                                  small
                                  @click="translate_string(item.phrase_origin)"
                                >
                                  mdi-google-translate
                                </v-icon>
                                <v-icon
                                  small
                                  @click="copy_for_gen_text(item.phrase_origin)"
                                  >mdi-content-copy
                                </v-icon>
                              </span>
                            </div>
                            <div style="min-width: 300px">
                              {{ item.translation }}
                            </div>
                          </td>
                          <td class="pretty-chip">
                            <link-url-smart-td :value="item.difficulty" />
                          </td>
                          <td>
                            {{ item.volume }}
                          </td>
                          <td class="pretty-chip">
                            <link-url-smart-td :value="item.position" />
                          </td>
                          <td>
                            {{ item.clicks }}
                          </td>
                          <td>
                            {{ item.impressions }}
                          </td>
                          <td>
                            <span v-if="item.external_links_normalize > 0">
                              {{ item.all_links }} /
                              {{ item.external_links_normalize }} /
                              {{ item.external_links_commercial_broad }}
                            </span>
                          </td>
                          <td>
                            <span v-if="item.internal_links_normalize > 0">
                              {{ item.internal_links }} /
                              {{ item.internal_links_normalize }}
                            </span>
                          </td>
                          <td
                            v-for="(type, index) in item.types"
                            :key="type.fact + index"
                            class="text-center"
                          >
                            <span v-if="type.fact > 0">
                              <a
                                target="_blank"
                                :href="
                                  get_link_to_ltt(item.project_id, type.id)
                                "
                              >
                                {{ type.fact }}
                              </a>
                            </span>
                            <span v-if="type.plan > 0">
                              ({{ type.plan }})
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col>
                  <v-data-table
                    :search="search"
                    :custom-filter="customSearch"
                    :headers="top_keys.headers"
                    :items="top_keys.items"
                    :sort-by="['volume', 'clicks', 'impressions']"
                    multi-sort
                    :sort-desc="[true, true, true]"
                    class="elevation-1"
                    :items-per-page="15"
                    dense
                  >
                    <template
                      v-for="(h, index) in table.headers"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <v-tooltip bottom :key="h + index">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="text-center">{{
                            header.text
                          }}</span>
                        </template>
                        <span>{{ header.title }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item.id + item.phrase">
                          <td>
                            <div style="min-width: 200px">
                              <span :style="get_color_phrase(item)">
                                <span v-if="item.phrase_html !== null">
                                  <span v-html="item.phrase_html"></span>
                                </span>
                                <span v-else v-html="item.phrase">
                                  <v-icon
                                    color="blue"
                                    small
                                    @click="
                                      translate_string(item.phrase_origin)
                                    "
                                  >
                                    mdi-google-translate
                                  </v-icon>
                                  <v-icon
                                    small
                                    @click="
                                      copy_for_gen_text(item.phrase_origin)
                                    "
                                  >
                                    mdi-content-copy
                                  </v-icon>
                                </span>
                              </span>
                            </div>
                            <div style="min-width: 200px">
                              {{ item.translation }}
                            </div>
                          </td>
                          <td class="pretty-chip">
                            <link-url-smart-td :value="item.difficulty" />
                          </td>
                          <td>
                            {{ item.volume }}
                          </td>
                          <td class="pretty-chip">
                            <link-url-smart-td :value="item.position" />
                          </td>
                          <td>
                            {{ item.clicks }}
                          </td>
                          <td>
                            {{ item.impressions }}
                          </td>
                          <td>
                            <span v-if="item.external_links_normalize > 0"
                              >{{ item.all_links }}/{{
                                item.external_links_normalize
                              }}</span
                            >
                          </td>
                          <td>
                            <span v-if="item.internal_links_normalize > 0"
                              >{{ item.internal_links }}/{{
                                item.internal_links_normalize
                              }}</span
                            >
                          </td>
                          <td
                            v-for="(type, index) in item.types"
                            :key="type + index"
                            class="text-center"
                          >
                            <span v-if="type.fact > 0">{{ type.fact }}</span>
                            <span v-if="type.plan > 0">({{ type.plan }})</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
import axios from "axios";
import TopSmallGoogleIndex from "./Children/TopSmallCharts/GoogleIndex";
import TopSmallFollow from "./Children/TopSmallCharts/Follow";
import TopSmallLinkTypes from "./Children/TopSmallCharts/LinkTypes";
import TopSmallAnchorTypes from "./Children/TopSmallCharts/AnchorTypes";
import eventPipe from "@/events/eventPipe";
import LinkUrlSmartTd from "@/components/UI/LinkUrlSmartTd";

export default {
  name: "LinkUrlControlPhrase",
  metaInfo: {
    title: "Link Url Control - LTT - SI",
  },
  components: {
    LinkUrlSmartTd,
    TopSmallGoogleIndex,
    TopSmallFollow,
    TopSmallLinkTypes,
    TopSmallAnchorTypes,
  },
  data() {
    return {
      date_modal: false,
      search: "",
      modal: {
        shown: false,
      },
      loadings: {
        fetch_data: false,
      },
      filter: {
        date_range: [null, null],
        on_page: true,
        google_index: false,
        follow: false,
        link_types: undefined,
        anchor_types: undefined,
        disallow: true,
        target_keys: false,
      },
      departments: [
        { id: "all", name: "all" },
        { id: "la", name: "la" },
        { id: "ha", name: "ha" },
        { id: "fl", name: "fl" },
      ],
      intervals: [
        { id: "day", name: "day" },
        { id: "week", name: "week" },
      ],
      url: undefined,
      url_full: undefined,
      links_declined_this_url: 0,
      table: {
        headers: [],
        items: [],
      },
      top_keys: {
        headers: [],
        items: [],
      },
      export_data_status: false,
      export_data: {
        items: [],
        dataFile: "serp_export.csv",
        labels: {},
        fields: [],
      },
    };
  },
  methods: {
    get_color_phrase(item) {
      let color = "green";
      if (item.all_plan > 0) {
        color = "blue";
      } else if (item.all_links > 0) {
        color = "grey";
      }
      return "color:" + color;
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) =>
          v &&
          v.toString().toLowerCase().includes(search.toString().toLowerCase())
      );
    },
    translate_string(string) {
      let self = this;

      let index = self.table.items.findIndex(function (item) {
        return item.phrase_origin === string;
      });

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("translate_string", true);

        let url = `${self.$store.state.server_url}/core/get-translation`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          lang_source: "auto",
          lang_target: "en",
          string: string,
        };

        axios.post(url, data, config).then(function (response) {
          self.table.items[index].translation = response.data.translation;
          self.$forceUpdate();
          self.update_loading("translate_string", false);
        });
      }
    },
    fetch_data() {
      let self = this;
      self.modal.shown = false;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/ltt/link-url-control/${self.id_project}/${self.$route.params.id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
        };

        self.table.headers = [];
        self.table.items = [];

        axios
          .post(url, data, config)
          .then(function (response) {
            self.url = response.data.url;
            self.url_full = response.data.url_full;
            self.links_declined_this_url =
              response.data.links_declined_this_url;
            self.top_keys.items = response.data.top_keys.data;
            self.top_keys.headers = response.data.top_keys.headers;
            self.table.items = response.data.url_control.data;
            self.table.headers = response.data.url_control.headers;
            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    copy_for_gen_text(text) {
      this.$clipboard(text);
    },
    get_link_to_ltt(project, type = false) {
      let url = "/ltt/index?project=" + project;

      // if (this.filter.date_range[0] && this.filter.date_range[0]!=null) {
      url +=
        "&date_range=" +
        (this.filter.date_range[0] != null ? this.filter.date_range[0] : "") +
        "," +
        (this.filter.date_range[1] != null ? this.filter.date_range[1] : "");
      //  }

      if (type) {
        url += "&type=" + type;
      }
      return url;
    },
    fetch_types_list() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("types_list", true);

        let url = `${self.$store.state.server_url}/ltt/links-type`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        axios
          .get(url, config)
          .then(function (response) {
            self.types_items = response.data;
            self.update_loading("types_list", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    get_disallow_label() {
      let count_disallow = "";
      if (this.links_declined_this_url > 0) {
        count_disallow = "(" + this.links_declined_this_url + ")";
      }

      return "Disallow" + count_disallow;
    },
    linkTypesItems() {
      return this.$store.state.reuse.link_types;
    },
    anchorTypesItems() {
      return this.$store.state.reuse.anchor_types;
    },
    linkTypesSelected: {
      get: function () {
        return this.filter.link_types;
      },
      set: function (value) {
        this.filter.link_types = value;
      },
    },
    anchorTypesSelected: {
      get: function () {
        return this.filter.anchor_types;
      },
      set: function (value) {
        this.filter.anchor_types = value;
      },
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  created() {
    let self = this;
    const id_project = this.$route.query.project_id;

    if (id_project) {
      this.id_project = id_project;
      this.$store.dispatch("update_active_project", id_project);
      this.$store.dispatch("update_domain");
      eventPipe.$emit("update-active-project");
    }

    if (self.linkTypesItems.length === 0) {
      this.$store.dispatch("fetch_link_types_list");
    }

    if (self.anchorTypesItems.length === 0) {
      self.$store.dispatch("fetch_anchor_types_list");
    }

    self.fetch_data();
  },
};
</script>

<style scoped></style>
