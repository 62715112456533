import service from "../../plugins/service";

class PositionsApiService {
  endpoints = {
    v2: "/serp/v2/positions",
  };

  /**
   * @param payload
   * @param payload.project{number}
   * @param payload.subproject{number}
   * @param payload.date{[string, string]}
   * @returns {Promise<*|undefined>}
   */
  async getV2Dashboard(payload) {
    try {
      const { data } = await service.post(this.endpoints.v2, payload);
      return data;
    } catch (e) {
      console.error("Error while loading data for positions v2 dashboard", e);
    }
  }
}

export default PositionsApiService;
