import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'conversion-6/fetchData'(ctx, {payload, projectId}) {
            const url = '/analytics/conversion/report6/' + projectId

            const resp = await Service.post(url, payload,true )
            if(resp && resp.status === 200) {
                return resp.data
            }
            return false
        },
    },
    getters: {},
}