<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <loader :loading="loading"></loader>
      <chat
        v-model="chat.show"
        @getCountUnreadMessage="getCountUnreadMessage"
      ></chat>

      <v-row>
        <h3 class="d-flex align-center">ANCHORS PLAN: {{ task.name }}</h3>
        <v-btn @click="download_file(null, EXPORT_PATHS.TASK)" icon>
          <v-icon>mdi-arrow-down-bold-circle</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color=""
              fab
              right
              absolute
              style="margin-right: 100px"
              width="40"
              @click="chat.show = true"
              height="40"
              v-bind="attrs"
              v-on="on"
            >
              <v-badge avatar bordered overlap>
                <template v-slot:badge>
                  <v-avatar size="10">
                    {{ chat.count_unread_message }}
                  </v-avatar>
                </template>

                <v-avatar size="40">
                  <v-icon size="36">mdi-chat</v-icon>
                </v-avatar>
              </v-badge>
            </v-btn>
          </template>
          <span>Chat for people to communicate!!!</span>
        </v-tooltip>

        <v-col cols="12" md="12">
          <v-card class="mx-auto" v-if="isset_active_project">
            <v-card-text>
              <v-row class="align-end">
                <v-col sm="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    solo
                    class="align-end"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <v-data-table
                    class="elevation-1"
                    :search="search"
                    :headers="table.headers"
                    :items="table.items"
                    dense
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          style="white-space: nowrap"
                        >
                          <td
                            :style="
                              item.done_url != null && item.done_url !== ''
                                ? 'background-color:' + item.color
                                : ''
                            "
                          >
                            <span
                              ><v-btn
                                :color="item.trouble ? 'red' : ''"
                                fab
                                width="25"
                                height="25"
                                @click="update_link_data(item, 'trouble')"
                              >
                                <v-icon size="20">mdi-check</v-icon>
                              </v-btn>
                            </span>
                            <span
                              v-if="
                                item.islink === 0 ||
                                item.islink === -1 ||
                                item.robotsclose === 1 ||
                                item.noindex === 1 ||
                                item.forced_on_page === 1
                              "
                              ><v-btn
                                :color="item.color_forced_on_page"
                                fab
                                width="20"
                                height="20"
                                @click="
                                  update_link_data(item, 'forced_on_page')
                                "
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on"
                                      >mdi-check-circle-outline</v-icon
                                    >
                                  </template>
                                  <span
                                    >I think the link is good and I will be
                                    fully responsible!!!</span
                                  >
                                </v-tooltip>
                              </v-btn></span
                            >
                            <span v-if="item.id == null || item.manual"
                              ><v-btn
                                color=""
                                fab
                                width="30"
                                height="30"
                                @click="left_new_row(item.id)"
                              >
                                <v-icon size="20">mdi-minus</v-icon>
                              </v-btn>
                            </span>
                            <span>{{ item.id }}</span>
                          </td>
                          <td>
                            <span v-if="item.id"
                              >{{ item.url
                              }}<v-btn
                                color=""
                                fab
                                icon
                                width="30"
                                height="30"
                                @click="item.show_redirect = true"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on"
                                      >mdi-plus</v-icon
                                    >
                                  </template>
                                  <span>Will replace the original URL!!!</span>
                                </v-tooltip> </v-btn
                              ><span v-if="item.show_redirect"
                                ><v-text-field
                                  class="mx-auto"
                                  v-model="item.redirect_url"
                                  @change="table.change_items.push(item.id)"
                                  outlined
                                  :style="get_theme()"
                                  full-width
                                  hide-details
                                  dense
                                ></v-text-field></span
                            ></span>
                            <span v-else>
                              <v-text-field
                                class="mx-auto"
                                v-model="item.url"
                                @change="table.change_items.push(item.id)"
                                solo
                                :style="get_theme()"
                                full-width
                                hide-details
                                dense
                              ></v-text-field>
                            </span>
                          </td>
                          <td>
                            <span v-if="item.id"
                              >{{ item.phrase
                              }}<span v-if="item.show_redirect"
                                ><v-text-field
                                  class="mx-auto"
                                  v-model="item.redirect_phrase"
                                  @change="table.change_items.push(item.id)"
                                  outlined
                                  :style="get_theme()"
                                  full-width
                                  hide-details
                                  dense
                                ></v-text-field></span
                            ></span>
                            <span v-else>
                              <v-text-field
                                class="mx-auto"
                                v-model="item.phrase"
                                @change="table.change_items.push(item.id)"
                                solo
                                :style="get_theme()"
                                full-width
                                hide-details
                                dense
                              ></v-text-field>
                            </span>
                            <span v-if="item.recommended_domain != null">
                              <a
                                :href="'http://' + item.recommended_domain"
                                target="_blank"
                                ><v-icon>mdi-alpha-d-box</v-icon></a
                              >
                            </span>
                          </td>
                          <td>
                            <v-row style="min-width: 300px">
                              <v-col cols="10">
                                <span
                                  v-if="
                                    !item.recommendation &&
                                    item.done_url != null
                                  "
                                >
                                  Not in R
                                </span>
                                <v-text-field
                                  class="mx-auto"
                                  v-model="item.done_url"
                                  @change="table.change_items.push(item.id)"
                                  solo
                                  :style="get_theme()"
                                  full-width
                                  hide-details
                                  dense
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1">
                                <a
                                  v-if="
                                    item.done_url != null &&
                                    item.done_url !== ''
                                  "
                                  :href="item.done_url"
                                  target="_blank"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon small v-bind="attrs" v-on="on"
                                        >mdi-open-in-new
                                      </v-icon>
                                    </template>
                                    <span>open in new</span>
                                  </v-tooltip>
                                </a>
                              </v-col>
                              <v-col cols="1">
                                <v-icon
                                  v-if="item.check_link === 0"
                                  color="#D3D3D3"
                                  >mdi-thumb-down
                                </v-icon>
                                <v-icon v-if="item.check_link === 2" color="red"
                                  >mdi-thumb-down
                                </v-icon>
                                <v-icon
                                  v-if="item.check_link === 1"
                                  color="green"
                                  >mdi-thumb-up-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </td>
                          <td style="min-width: 200px">
                            <v-text-field
                              v-model="item.login"
                              @change="table.change_items.push(item.id)"
                              solo
                              :style="get_theme()"
                              full-width
                              hide-details
                              dense
                            ></v-text-field>
                          </td>
                          <td style="min-width: 200px">
                            <v-text-field
                              v-model="item.password"
                              @change="table.change_items.push(item.id)"
                              solo
                              full-width
                              :style="get_theme()"
                              hide-details
                              dense
                            ></v-text-field>
                          </td>
                          <td style="min-width: 200px">
                            <v-text-field
                              v-model="item.email"
                              @change="table.change_items.push(item.id)"
                              solo
                              full-width
                              :style="get_theme()"
                              hide-details
                              dense
                            ></v-text-field>
                          </td>
                          <td class="text-center">
                            {{ item.dateadd }}
                            <v-btn
                              :color="item.color_previous_month"
                              fab
                              width="25"
                              height="25"
                              @click="update_link_data(item, 'previous_month')"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon size="20" v-bind="attrs" v-on="on"
                                    >mdi-alpha-l-box-outline
                                  </v-icon>
                                </template>
                                <span
                                  >The link refers to the following
                                  location</span
                                >
                              </v-tooltip>
                            </v-btn>
                          </td>
                          <td style="min-width: 200px">
                            <v-textarea
                              hide-details
                              @change="table.change_items.push(item.id)"
                              v-model="item.text"
                              solo
                              :style="get_theme()"
                              rows="1"
                            ></v-textarea>
                          </td>
                          <td style="min-width: 200px">
                            <v-textarea
                              hide-details
                              @change="table.change_items.push(item.id)"
                              v-model="item.notes"
                              solo
                              :style="get_theme()"
                              rows="1"
                            ></v-textarea>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                  <v-btn
                    color=""
                    absolute
                    bottom
                    left
                    width="30"
                    height="30"
                    fab
                    @click="add_new_row"
                    style="margin-left: 16px; margin-bottom: 70px"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon size="20" v-bind="attrs" v-on="on"
                          >mdi-plus
                        </v-icon>
                      </template>
                      <span>Will create a new row in the anchors plan</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn
                    color="success"
                    absolute
                    bottom
                    right
                    fab
                    @click="save_data"
                    style="margin-right: 60px; margin-bottom: 100px"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon size="20" v-bind="attrs" v-on="on"
                          >mdi-content-save
                        </v-icon>
                      </template>
                      <span>Save changes</span>
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="12"
          v-for="block in table_domains.blocks"
          :key="block.name"
        >
          <v-card
            class="mx-auto"
            v-if="
              isset_active_project &&
              ((table_domains[block.js] &&
                table_domains[block.js].length > 0) ||
                block.always_show)
            "
          >
            <v-card-text>
              <v-row class="align-end">
                <v-col sm="3">
                  <v-card-title>
                    <span>{{ block.name }}</span>
                    <span
                      v-if="block.value === 'delete' || block.value === 'new'"
                    >
                      <v-btn
                        @click="download_file(block.used, EXPORT_PATHS.DOMEN)"
                        icon
                      >
                        <v-icon>mdi-arrow-down-bold-circle</v-icon>
                      </v-btn>
                    </span>
                  </v-card-title>
                </v-col>
                <v-col sm="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    solo
                    class="align-end"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <TableIndex
                    :items="table_domains[block.js]"
                    :headers="table_domains.headers"
                    :block="block"
                    :recommended_acceptors="recommended_acceptors"
                    :icon="table_domains.country_icons"
                    :thematic_threads="thematic_threads"
                    :tags="tags"
                    :task="task"
                    :fetch_data_domains="fetch_data_domains"
                  ></TableIndex>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
//import eventPipe from "@/events/eventPipe";
import TableIndex from "@/components/AnchorPlan/PlacementLinks/Other/Child/TableIndex";
import Loader from "@/components/AnchorPlan/Child/LoaderSave";
import Chat from "@/components/AnchorPlan/Child/Chat";

export default {
  name: "PlacementLinksTier2Index",
  components: { TableIndex, Loader, Chat },
  metaInfo: {
    title: "Placement Links - LTT - SI",
  },
  data() {
    return {
      EXPORT_PATHS: {
        TASK: "/export/anchor-plan-task/index/",
        DOMEN: "/export/anchor-plan-donors-x/index/0",
      },
      loading: false,
      search: "",
      scrollSync: {
        top: 0,
        left: 0,
      },
      chat: {
        show: false,
        count_unread_message: 0,
      },
      search_domains: "",
      loadings: {},
      task: {},
      table: {
        headers: [
          { text: "id", title: "id", value: "id", class: "text-center" },
          {
            text: "Acceptor URL",
            title: "url",
            value: "url",
            class: "text-center",
          },
          {
            text: "Anchor",
            title: "phrase",
            value: "phrase",
            class: "text-left",
          },
          {
            text: "Done URL",
            title: "posted_link",
            value: "posted_link",
            class: "text-left",
          },
          { text: "Login", title: "login", value: "login", class: "text-left" },
          {
            text: "Pass",
            title: "password",
            value: "password",
            class: "text-left",
          },
          { text: "Email", title: "email", value: "email", class: "text-left" },
          {
            text: "Date",
            title: "comment",
            value: "comment",
            class: "text-left",
          },
          {
            text: "Text",
            title: "date_finish",
            value: "date_finish",
            class: "text-left",
          },
          {
            text: "Notes",
            title: "date_finish",
            value: "date_finish",
            class: "text-left",
          },
        ],
        items: [],
        change_items: [],
      },
      table_domains: {
        headers: [
          { text: "id", title: "id", value: "id", class: "text-center" },
          {
            text: "Donor",
            title: "domain",
            value: "domain",
            class: "text-center",
          },
          {
            text: "Recommended acceptor",
            title: "Recommended acceptor",
            value: "recommended_acceptor",
            class: "text-center",
            sortable: false,
            icon: true,
            icon_name: "mdi-file-hidden",
          },
          {
            text: "Is live",
            title: "ltt_all",
            value: "ltt_all",
            class: "text-center",
          },
          {
            text: "Last posting",
            title: "last_posting_in_days",
            value: "last_posting_in_days",
            class: "text-center",
          },
          {
            text: "DR",
            title: "domain_rating",
            value: "domain_rating",
            class: "text-center",
          },
          {
            text: "Ahrefs traffic",
            title: "traffic",
            value: "traffic",
            class: "text-center",
          },
          {
            text: "Regional Traff Ahrefs",
            title: "country_traffic",
            value: "country_traffic",
            class: "text-center",
            icon: true,
          },
          {
            text: "SW Rank",
            title: "global_rank",
            value: "global_rank",
            class: "text-center",
          },
          {
            text: "Google Index",
            title: "google_index",
            value: "google_index",
            class: "text-center",
          },
          {
            text: "Relevant pages count",
            title: "google_index_niche",
            value: "google_index_niche",
            class: "text-center",
          },
          {
            text: "Google Index Path",
            title: "google_index_path",
            value: "google_index_path",
            class: "text-center",
          },
          {
            text: "Date Add",
            title: "created_at",
            value: "created_at",
            class: "text-center",
          },
          {
            text: "Spam Index",
            title: "spam_score",
            value: "spam_score",
            class: "text-center",
          },
          {
            text: "P",
            title: "priority",
            value: "priority",
            class: "text-center",
          },
          {
            text: "Thematic threads for comment and forum",
            title: "",
            value: "",
            class: "text-center",
          },
          {
            text: "Donor Tag",
            title: "activity_profile",
            value: "activity_profile",
            align: "center",
            class: "text-center",
          },
          {
            text: "Donor workflow",
            title: "workflow",
            value: "workflow",
            class: "text-center",
          },
          {
            text: "Text",
            title: "text",
            value: "text",
            class: "text-center",
          },
          {
            text: "Notes",
            title: "notes",
            value: "notes",
            class: "text-center",
          },
          {
            text: "Login",
            title: "login",
            value: "login",
            class: "text-center",
          },
          {
            text: "Pass",
            title: "pass",
            value: "pass",
            class: "text-center",
          },
          {
            text: "Email",
            title: "email",
            value: "email",
            class: "text-center",
          },
          {
            text: "Added by",
            title: "user",
            value: "user",
            class: "text-center",
          },
          {
            text: "Check",
            title: "check",
            value: "check",
            class: "text-center",
          },
        ],
        blocks: [
          {
            name: "NEW DOMAINS",
            value: "new",
            js: "items_new",
            always_show: true,
            used: 0,
          },
          {
            name: "USED DOMAINS",
            value: "used",
            js: "items_used",
            always_show: true,
            used: 1,
          },
          {
            name: "IN PROGRESS DOMAINS",
            value: "in_progress",
            js: "items_in_progress",
            always_show: false,
            used: 5,
          },
          {
            name: "MODERATION DOMAINS",
            value: "moderation",
            js: "items_moderation",
            always_show: false,
            used: 4,
          },
          {
            name: "PROBLEMATIC DONORS",
            value: "delete",
            js: "items_delete",
            always_show: false,
            used: 2,
          },
          {
            name: "DELETED LINKS",
            value: "deleted_links",
            js: "items_deleted_links",
            always_show: false,
            used: 6,
          },
          {
            name: "PENDING DOMAINS",
            value: "pending",
            js: "items_pending",
            always_show: false,
            used: 3,
          },
        ],
        items_new: [],
        items_used: [],
        items_delete: [],
        items_moderation: [],
        items_pending: [],
        items_in_progress: [],
        items_deleted_links: [],
        country_icons: "",
      },
      filter: {
        country: false,
        performer: false,
        niche_id: undefined,
        lang_id: undefined,
      },
      thematic_threads: "",
      tags: [],
      recommended_acceptors: [],
    };
  },
  methods: {
    getCountUnreadMessage(data) {
      if (data === undefined) {
        this.chat.count_unread_message = 0;
      } else {
        this.chat.count_unread_message = data.count;
      }
    },
    update_link_data(item, name) {
      let self = this;
      let value = null;
      let color = null;

      if (item[name] !== 1) {
        value = 1;
        color = "green";
      }

      self.loading = true;
      self.update_loading("update_link_data", true);
      let url = `${self.$store.state.server_url}/ltt/update_link_data/link/${item.link_id}/2`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: { name: name, value: value },
      };

      //   let data = {name: name, value: value}

      axios
        .get(url, config)
        .then(function () {
          self.loading = false;
          self.table.items.find((i) => i.id === item.id)["color_" + name] =
            color;
          self.table.items.find((i) => i.id === item.id).name = 1;
          self.update_loading("update_link_data", false);
          self.fetch_data();
        })
        .catch(function (error) {
          alert(error);
        });
    },
    download_file(used, path) {
      const self = this;
      const url = `${self.$store.state.server_url}/export/base/get-hash`;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      const data = {
        level: 2,
        used: used,
        id: self.$route.params.id,
        country: self.filter.country,
        project: self.task.project_id,
      };

      axios.post(url, data, config).then((response) => {
        if (response.data.success) {
          const id =
            path === self.EXPORT_PATHS.TASK ? self.$route.params.id : "";

          location.href =
            `${self.$store.state.server_url}${path}${id}?hash=` +
            response.data.hash;
        }
      });
    },
    left_new_row(id) {
      let self = this;
      self.update_loading("left_new_row", true);
      let url = `${self.$store.state.server_url}/anchor-plan/anchor-plan/delete/manual/${id}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function () {
          self.table.items = self.table.items.filter((x) => x.id !== id);
          self.update_loading("left_new_row", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    add_new_row() {
      let row = {
        url: null,
        phrase: null,
        color: null,
        nf: null,
        date_start: null,
        date_finish: null,
        comment_link_builder: null,
        created_at: null,
        notes: null,
        text: null,
        completed: 0,
        id: null,
        done_url: null,
        link_id: null,
        acceptorurlid: null,
        ltypeid: null,
        projectid: null,
        url_id: null,
        email: null,
        login: null,
        password: null,
        redirect_url: null,
        redirect_phrase: null,
        dateadd: null,
        task_id: this.$route.params.id,
        recommendation: null,
      };
      this.table.items.push(row);
    },
    save_data() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.loading = true;
        self.update_loading("save_data", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/save/2`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let change_items = this.table.change_items.filter(
          (x, i, a) => a.indexOf(x) === i
        );
        let items = this.table.items.filter(function (params) {
          if (change_items.includes(params.id)) {
            return params;
          }
        });

        this.table.change_items = [];

        let data = {
          items: items,
        };

        axios
          .post(url, data, config)
          .then(function () {
            self.update_loading("save_data", false);
            self.fetch_data();
            self.loading = false;
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_tags() {
      let self = this;
      self.update_loading("fetch_task_info", true);

      let url = `${self.$store.state.server_url}/anchor-plan/placement-links/tags`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function (response) {
          self.tags = response.data;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_task_info() {
      const icons = require("rendered-country-flags");
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_task_info", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/task/${this.$route.params.id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {};

        axios
          .post(url, data, config)
          .then(function (response) {
            self.task = response.data;
            self.table_domains.country_icons = icons[self.task.iso];
            self.filter.country = self.task.country_id;
            self.filter.performer = self.task.performer;
            self.filter.niche_id = self.task.niche_id;
            self.filter.lang_id = self.task.lang_id;
            self.update_loading("fetch_task_info", false);
            self.fetch_thematic_threads();
            self.fetch_data_domains();
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_data() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/anchor-plan/anchor-plan/${this.$route.params.id}/2`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
        };

        self.table.items = [];
        self.table.items_base = [];

        axios
          .post(url, data, config)
          .then(function (response) {
            self.table.items = response.data;
            self.table.items_base = response.data;

            response.data.forEach(function (item) {
              self.recommended_acceptors.push({
                id: item.id,
                name: item.url,
              });
            });

            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_data_domains() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/anchor-plan/donors-base/${this.$route.params.id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
          task: this.task,
        };

        this.table_domains.items = [];

        axios
          .post(url, data, config)
          .then(function (response) {
            self.table_domains.items_new = response.data.new;
            self.table_domains.items_used = response.data.used;
            self.table_domains.items_delete = response.data.deleted;
            self.table_domains.items_pending = response.data.pending;
            self.table_domains.items_moderation = response.data.moderation;
            self.table_domains.items_in_progress = response.data.in_progress;
            self.table_domains.items_deleted_links =
              response.data.deleted_links;

            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_thematic_threads() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_thematic_threads", true);

        let url = `${self.$store.state.server_url}/anchor-plan/thematic-threads/${self.task.lang_id}/${self.task.niche_id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        axios
          .post(url, {}, config)
          .then(function (response) {
            self.thematic_threads = response.data;
            self.update_loading("fetch_thematic_threads", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    get_theme() {
      return this.is_dark()
        ? "border-color: transparent; border: 1px solid red"
        : "";
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {
    let self = this;

    /* eventPipe.$on('update-active-project', () => {
       self.fetch_task_info()
       self.fetch_data()
     })*/

    self.fetch_tags();
    self.fetch_task_info();
    self.fetch_data();
  },
};
</script>

<style scoped></style>
