<template>
  <v-col cols="12" style="height: 32px">
    <div class="d-flex flex-column" style="width: 100%; font-size: 16px">
      <div class="d-flex">
        <!--LABEL-->
        <overall-label-row-factory :data="data" />
        <!--/LABEL-->
        <v-spacer />
        <!--VALUE-->
        <template
          v-if="
            showAlertWhenNoValue.includes(String(data.name).toLowerCase()) &&
            !hasValue(data.value)
          "
        >
          <v-icon small color="warning">mdi-alert</v-icon>
        </template>
        <template v-else>
          <template v-if="type === TYPES.PERCENT">
            <template v-if="hasValue(data.value)">
              <span
                :style="{
                  color: getStatusColor(data, true),
                }"
              >
                {{ data.value }}%
              </span>
            </template>
            <template v-else>
              <v-icon color="rgba(155,155,155,0.3)">mdi-minus</v-icon>
            </template>
          </template>
          <template v-else-if="type === TYPES.NUMBER">
            <template v-if="hasValue(data.value)">
              <overall-number-row-factory
                :data="data"
                :get-status-color="getStatusColor"
              />
            </template>
            <template v-else>
              <v-icon color="rgba(155,155,155,0.3)">mdi-minus</v-icon>
            </template>
          </template>
          <template v-else-if="type === TYPES.DATE">
            <template v-if="hasValue(data.value, true)">
              <v-tooltip bottom content-class="pa-0 shadow-e1-bordered">
                <template #activator="{ on }">
                  <v-chip v-on="on" outlined label small>
                    <v-icon left small>mdi-calendar</v-icon>
                    {{
                      data.value ? $moment(data.value).format("YYYY-MM-DD") : ""
                    }}
                    <v-icon
                      v-if="
                        datesWhoHasColorIndicator.includes(
                          String(data.name).toLowerCase()
                        )
                      "
                      small
                      right
                      :color="getStatusColor(data)"
                      >mdi-circle</v-icon
                    >
                  </v-chip>
                </template>
                <v-card class="pa-3">
                  <div>
                    <b>{{ getDateDiff(data.value, "days") }}</b> days ago
                  </div>
                  <div>
                    <b>{{ getDateDiff(data.value, "months") }}</b> months ago
                  </div>
                </v-card>
              </v-tooltip>
            </template>
            <template v-else>
              <v-icon color="rgba(155,155,155,0.3)">mdi-minus</v-icon>
            </template>
          </template>
          <template v-else-if="type === TYPES.BUTTON">
            <template v-if="hasValue(data.value, true)">
              <overall-button-factory :data="data.value" />
            </template>
            <template v-else>
              <v-icon color="rgba(155,155,155,0.3)">mdi-minus</v-icon>
            </template>
          </template>
          <template v-else>
            <span>{{ data.value }}</span>
          </template>
        </template>
        <!--/VALUE-->
      </div>
    </div>
  </v-col>
</template>

<script>
import {
  AHTPIN10_COLORS_RANGE,
  INTITLE_COLORS_RANGE,
  NOT_TOP_5_POS_COLORS_RANGE,
} from "../defaultData";
import OverallNumberRowFactory from "../UI/OverallNumberRowFactory.vue";
import OverallLabelRowFactory from "../UI/OverallLabelRowFactory.vue";
import OverallButtonFactory from "@/components/SiteAnalysis/UI/OverallButtonFactory.vue";

export default {
  components: {
    OverallButtonFactory,
    OverallLabelRowFactory,
    OverallNumberRowFactory,
  },
  props: {
    type: {
      type: String,
      default: "percent",
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ItemsWhohasProgressBar: [
      // "cost, dbc",
      // "cost, last gpm"
    ],
    TYPES: {
      NUMBER: "number",
      PERCENT: "percent",
      DATE: "date",
      BUTTON: "button",
    },
    showAlertWhenNoValue: [
      "ah traffic",
      "google index",
      "ah pages",
      "dr",
      "rd",
      "lrd",
    ],
    dontHaveColor: ["organic, %", "direct, %", "social, %", "referrals, %"],
    datesWhoHasColorIndicator: [
      "ltt all, last posting",
      "ltt gp, last posting",
      "sl gp, last posting",
    ],
    colors: [
      "#FF0000",
      "#FF3300",
      "#ff4d00",
      "#ff7300",
      "#ffae00",
      "#ffdd00",
      "#ffea00",
      "#b7ff00",
      "#70dc0b",
      "#41c916",
      "#40cc13",
    ],
    top5PosColors: NOT_TOP_5_POS_COLORS_RANGE,
    ahtpIn10Colors: AHTPIN10_COLORS_RANGE,
    inTitleColor: INTITLE_COLORS_RANGE,
  }),
  computed: {
    computedReversedColors() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.colors.reverse();
    },
  },
  methods: {
    hasValue(value, includeZero = false) {
      if (includeZero) {
        return value !== null && value !== 0 && value !== "";
      }

      return value !== null && value !== "";
    },
    getGoogleTrustColor(value) {
      if (!value) return "inherit";

      if (Number(value) === 0) return this.colors[0];
      if (Number(value) === 1) return this.colors[4];
      if (Number(value) === 2) return this.colors[10];

      return "inherit";
    },
    getColorFromDefaultColorsRange(value) {
      if (value === null) return "grey";

      const index = Math.floor(value / 10);
      return this.colors[index];
    },
    getTop5PosColor(value) {
      const index = Math.floor(value / 10);
      return this.top5PosColors[index];
    },
    getAhtpIn10Color(value) {
      const index = Math.floor(value / 10);
      return this.ahtpIn10Colors[index];
    },
    getInTitleColor(value) {
      if (Number(value) > 100 || Number(value) === 0) return this.colors[0];

      if (Number(value) > 0 <= 10) return this.colors[10];
      if (Number(value) > 10 <= 20) return this.colors[8];
      if (Number(value) > 20 <= 30) return this.colors[4];

      const index = Math.floor(value / 10);

      return this.computedReversedColors[index];
    },
    getReversedColors(value) {
      const index = Math.floor(value / 10);
      return this.computedReversedColors[index];
    },
    getTrThemeColors(value) {
      if (value <= 1) return this.colors[0];
      if (value > 20) return this.colors[5];
    },
    getTrCategoryNumColors(value) {
      if (value >= 10) return this.colors[0];
    },
    getTrTopCategoryNumColors(value) {
      if (value >= 5) return this.colors[0];
    },
    getCostDbcColors(value) {
      const val = parseInt(value);

      if (typeof value === "string" && value.length === 0) return "grey";

      if (val >= 400) return this.colors[0];
      if (val >= 250 && val < 400) return this.colors[5];
      if (val < 250) return this.colors[10];

      return "initial";
    },
    getDateDiff(date, type) {
      return this.$moment(new Date()).diff(this.$moment(date), type);
    },
    getLastPostingColor(value) {
      if (!value) return "grey";

      const months = this.$moment(new Date()).diff(
        this.$moment(value),
        "months"
      );

      if (months === 0) return this.colors[10];
      if (months === 1) return this.colors[9];
      if (months === 2) return this.colors[8];
      if (months === 3) return this.colors[7];

      if (months === 4) return this.colors[6];
      if (months === 5) return this.colors[6];
      if (months === 6) return this.colors[6];
      if (months === 7) return this.colors[5];
      if (months === 8) return this.colors[5];

      if (months === 9) return this.colors[5];
      if (months === 10) return this.colors[4];
      if (months === 11) return this.colors[3];
      if (months === 11) return this.colors[2];
      if (months >= 12) return this.colors[1];
    },
    getStatusColor({ value, name }, returnDefaultColor = false) {
      const fixedName = String(name).toLowerCase();

      try {
        if (fixedName === "google trust") {
          return this.getGoogleTrustColor(value);
        }

        if (fixedName === "!top 5p") {
          return this.getTop5PosColor(value);
        }

        if (fixedName === "ahtpin10") {
          return this.getAhtpIn10Color(value);
        }

        if (fixedName === "sw b country") {
          return this.getReversedColors(value);
        }

        if (fixedName === "desktop, %") {
          return this.getReversedColors(value);
        }

        if (fixedName === "tr theme >=1, num") {
          return this.getTrThemeColors(value);
        }

        if (fixedName === "tr category, num") {
          return this.getTrCategoryNumColors(value);
        }

        if (fixedName === "tr top category, num") {
          return this.getTrTopCategoryNumColors(value);
        }

        if (["intext c, p", "intitle c, p"].includes(fixedName)) {
          return this.getInTitleColor(value);
        }

        if (
          ["cost, last gpm", "cost, dbc", "cost, casino"].includes(fixedName)
        ) {
          return this.getCostDbcColors(value);
        }

        if (this.datesWhoHasColorIndicator.includes(fixedName)) {
          return this.getLastPostingColor(value);
        }

        if (this.dontHaveColor.includes(fixedName)) {
          return "inherit";
        }

        if (returnDefaultColor)
          return this.getColorFromDefaultColorsRange(value);

        return "inherit";
      } catch (e) {
        console.error(e);
        return "inherit";
      }
    },
  },
};
</script>
