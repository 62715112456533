<template>
  <div class="analytics-page-type">
    <v-row dense class="mb-6">
      <v-col cols="12">
        <h2>General Analytics by Page Type</h2>
      </v-col>
    </v-row>
    <v-row class="align-content-center" dense>
      <v-col cols="2">
        <smart-date-filter
          :model-value="filters.date_range"
          @update:modelValue="filters.date_range = $event"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="filters.subproject_id"
          :items="subprojects"
          :menu-props="{
            maxHeight: '400',
            rounded: 'lg',
            transition: 'slide-x-transition',
          }"
          label="Subproject"
          clearable
          dense
          hide-details
          outlined
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="filters.country_id"
          :items="filtersData.countries"
          :menu-props="{
            maxHeight: '400',
            rounded: 'lg',
            transition: 'slide-x-transition',
          }"
          label="Country"
          multiple
          clearable
          dense
          hide-details
          outlined
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="2"
        v-if="filters.subproject_id === 17 || filters.subproject_id === 37"
      >
        <div class="custom-input-item__wrapper">
          <v-autocomplete
            v-model="filters.part_id"
            :items="[
              { name: 'category', id: 1 },
              { name: 'samples', id: 3 },
            ]"
            :menu-props="{
              maxHeight: '400',
              rounded: 'lg',
              transition: 'slide-x-transition',
            }"
            label="Part"
            clearable
            item-text="name"
            item-value="id"
            class="custom-input-item"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          @click="handleExportProgress"
          outlined
          color="primary"
          class="mr-2"
          :loading="loadings.export"
          style="height: 100%"
        >
          Export Progress
        </v-btn>
        <v-btn
          width="120px"
          color="primary"
          @click="getData"
          class="reset-box-shadow"
          :loading="loading"
          style="height: 100%"
        >
          Get Data</v-btn
        >
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <section
          class="analytics-page-type__section analytics-page-type__tooltips"
        >
          <div class="sections-tooltip__wrapper">
            <div
              class="sections-tooltip"
              v-for="(key, index) in Object.keys(tableHeaderSections)"
              :key="index"
              @click="findSection($event, tableHeaderSections[key])"
            >
              <div
                class="color-box"
                :style="`width: 10px;height: 10px;background: ${tableHeaderSections[key].color}`"
              ></div>
              {{ key }}
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <section
          class="analytics-page-type__section analytics-page-type__table"
        >
          <v-row>
            <v-col cols="12" style="position: relative">
              <Loader :show="loading" />
              <v-data-table
                :headers="tableData.headers"
                :items="tableData.items"
                :custom-sort="customSort"
                :footer-props="{
                  itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                }"
                :items-per-page="50"
                multi-sort
                class="custom-by-page-type-table"
              >
                <template v-slot:item.url="{ item: { url } }">
                  <td style="max-width: 500px">
                    {{ url }}
                  </td>
                </template>
                <template v-slot:item.traffic="{ item: { traffic } }">
                  <ByPageTypeDiffTableItem :item="traffic" />
                </template>
                <template v-slot:item.top_1="{ item: { top_1 } }">
                  <ByPageTypeDiffTableItem :item="top_1" />
                </template>
                <template v-slot:item.top_3="{ item: { top_3 } }">
                  <ByPageTypeDiffTableItem :item="top_3" />
                </template>
                <template v-slot:item.top_10="{ item: { top_10 } }">
                  <ByPageTypeDiffTableItem :item="top_10" />
                </template>
                <template slot="no-data">
                  Change filters and try again
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loader from "@/components/Main/Loader";
import ByPageTypeDiffTableItem from "@/components/Analytics/byPageType/Diff";
import SmartDateFilter from "../../UI/SmartDateFilter";
import service from "../../../plugins/service";
import eventPipe from "../../../events/eventPipe";

export default {
  components: {
    SmartDateFilter,
    ByPageTypeDiffTableItem,
    Loader,
  },
  async created() {
    this.fetchFilterCountries();

    this.filters.date_range = this.defaultDatePreset;

    eventPipe.$on("update-active-project", () => {
      this.filters.subproject_id = this.subprojects[0].id;
      this.fetchTableData();
    });
  },
  data: () => ({
    loadings: {
      countries: false,
      table: false,
      export: false,
    },
    filters: {
      date_range: [],
      subproject_id: [],
      part_id: null,
      country_id: [],
    },
    filtersData: {
      countries: [],
    },
    loading: false,
    tableHeaderSections: [],
    tableData: {
      headers: [],
      items: [],
    },
  }),
  watch: {
    "filters.subproject_id"() {
      this.filters.part_id = null;
    },
  },
  computed: {
    defaultDatePreset() {
      this.$moment.updateLocale(this.$moment.locale(), { week: { dow: 5 } });

      const start = this.$moment(new Date())
        .startOf("week")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).endOf("week").format("YYYY-MM-DD");

      return [start, end];
    },
    subprojects() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      );
      if (project && project[0]) {
        return project[0]["subprojects"];
      }
      return [];
    },
  },
  methods: {
    async handleExportProgress() {
      try {
        const url = "/export/base/get-hash";
        this.loadings.export = true;

        const payload = this.filters;

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.success) {
          const relink =
            `${this.$store.state.server_url}/export/analytics/page-type/11?hash=` +
            resp.data.hash;
          window.open(relink, "_blank");
        }

        this.loadings.export = false;
      } catch {
        this.loadings.export = false;
      }
    },
    getValue(obj) {
      if (!obj) {
        return 0;
      }
      if (obj && obj.value) {
        return isNaN(parseInt(obj.value)) ? 0 : Number(obj.value);
      }
      return 0;
    },
    async fetchFilterCountries() {
      this.loadings.countries = true;

      const resp = await this.$store.dispatch(
        "analyticsPageType/fetchCountries"
      );

      if (resp) {
        this.filtersData.countries = resp;
      }

      this.loadings.countries = false;
    },
    customSort(items, sortBy, sortDesc) {
      const objWithValuesField = ["top_1", "top_3", "top_10", "traffic"];

      items.sort((a, b) => {
        for (let i in sortBy) {
          const isNumbers =
            !isNaN(parseInt(a[sortBy[i]])) && !isNaN(parseInt(b[sortBy[i]]));

          if (objWithValuesField.includes(sortBy[i])) {
            const aFixed = this.getValue(a[sortBy[i]]);
            const bFixed = this.getValue(b[sortBy[i]]);
            if (aFixed === 0 && bFixed !== 0) return 1;
            if (bFixed === 0 && aFixed !== 0) return -1;

            if (aFixed > bFixed) return sortDesc[i] ? -1 : 1;
            if (aFixed < bFixed) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let a_val, b_val;
          if (isNaN(parseInt(a[sortBy[i]]))) a_val = 0;
          if (isNaN(parseInt(b[sortBy[i]]))) b_val = 0;

          if (a_val === 0 && b_val !== 0) return 1;
          if (b_val === 0 && a_val !== 0) return -1;

          if (isNumbers) {
            if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
              return sortDesc[i] ? -1 : 1;
            if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
              return sortDesc[i] ? 1 : -1;
            return 0;
          }

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    findSection(e, section) {
      const elements = document.querySelectorAll(".v-data-table-header tr th");
      const matchElements = [];
      elements.forEach((th) => {
        const textContent = th.textContent;

        th.style.background = "initial";

        if (section.items.includes(textContent)) {
          matchElements.push(th);
        }
      });
      if (!matchElements.length > 0) {
        e.target.classList.add("not-found");
        setTimeout(() => e.target.classList.remove("not-found"), 500);
        return;
      }
      Array.from(matchElements).forEach((elem) => {
        elem.classList.add("highlighted");
        elem.style.background = section.color;
        setTimeout(() => {
          elem.classList.remove("highlighted");
        }, 3000);
      });
      matchElements[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    },
    async fetchTableData() {
      this.loading = true;
      const filters = {
        filter: this.filters,
      };
      const projectId = this.$store.getters.active_project;

      await this.$store.dispatch("analyticsPageType/fetchDataTable", {
        filters,
        projectId,
      });
      const data = this.$store.getters["analyticsPageType/tableData"];
      if (data) {
        const { items, headers, sections } = data;
        this.tableData.items = items;
        this.tableData.headers = headers;
        this.tableHeaderSections = sections;
      }

      this.loading = false;
    },
    getData() {
      this.fetchTableData();
    },
  },
};
</script>
