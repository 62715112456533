<template>
  <div>
    <v-icon v-if="!loading" :color="getColor" @click="$emit('click')">
      {{ getIcon }}
    </v-icon>
    <v-progress-circular v-else indeterminate size="22" width="2" />
  </div>
</template>

<script>
export default {
  props: ["data", "loading"],
  computed: {
    getColor() {
      if (this.hasError) {
        return "error";
      }
      return "success";
    },
    getIcon() {
      if (this.hasError) {
        return "mdi-alert-circle-outline";
      }
      return "mdi-check-circle-outline";
    },
    hasError() {
      const { queue, hour } = this.data;

      if (queue !== 0 && hour === 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
