<template>
  <div class="DBC">
    <v-row>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          DBC - Dashboard
        </page-name>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
          <v-row dense>
            <v-col cols="2">
              <v-autocomplete
                v-model="user"
                label="User"
                :items="filtersData.users"
                item-value="id"
                item-text="email"
                outlined
                multiple
                :loading="loadings.table"
                dense
                clearable
                @change="_$handleFilterChange($event, FILTERS_QUERY_IDS.USERS)"
                hide-details
                :menu-props="menuProps"
              ></v-autocomplete>
            </v-col>
            <v-col cols="1">
              <v-autocomplete
                v-model="link_type"
                label="Type"
                multiple
                :items="filtersData.types"
                item-value="id"
                :loading="loadings.table"
                item-text="name"
                outlined
                dense
                clearable
                @change="_$handleFilterChange($event, FILTERS_QUERY_IDS.TYPE)"
                hide-details
                :menu-props="menuProps"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <smart-date-filter
                :model-value="date_range"
                @update:modelValue="handleUpdateDateFilter"
              />
            </v-col>
            <v-col cols="1">
              <v-text-field
                outlined
                clearable
                dense
                hide-details
                label="Task ID"
                v-model="task_id"
                type="number"
                @change="_$handleFilterChange($event, FILTERS_QUERY_IDS.TASK_ID)"
              />
            </v-col>
            <v-spacer />
            <v-col cols="2" class="d-flex justify-end">
              <v-btn
                block
                color="primary"
                style="height: 40px;"
                @click="handleClickGetData"
                :loading="loadings.table"
              >
                Get Data
              </v-btn>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
    <template v-if="!loadings.table">
      <v-row>
        <v-col cols="12">
          <div class="DBC__table">
            <div
              v-if="tableData.items && tableData.items.length > 0"
              class="DBC__table-wrapper"
            >
              <loader :show="loadings.table" />
              <v-data-table
                :headers="tableData.headers"
                :items="tableData.items"
                :items-per-page="50"
                :footer-props="{ itemsPerPageOptions: [25, 50, 100, 200, -1] }"
              >
              </v-data-table>
            </div>
            <div
              v-else
              class="DBC__table-wrapper d-flex justify-center align-center"
            >
              <div class="placeholder">
                <span
                  v-html="
                    firstEnter ? tooltips.itemsPlaceholder : tooltips.firstEnter
                  "
                ></span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row dense>
        <v-col cols="12">
          <div
            class="table-no-items-placeholders transparent"
            style="position: relative"
          >
            <loader :show="loadings.table" />
            <v-skeleton-loader
              type="table-row-divider@6"
              width="100%"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import {
  DEFAULT_DBC_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import PageName from "@/components/DashboardDev/PageName";
import Location from "@/mixins/Location";
import Loader from "@/components/Main/Loader";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import FiltersHandling from "@/mixins/components/FiltersHandling";

export default {
  components: {
    SmartDateFilter,
    PageName,
    Loader,
  },
  mixins: [Location, FiltersHandling],
  async created() {

    const haveParams = this._$collectParams(['user','link_type','date_range'],'')


    const haveDateInQuery =
      this.$route.query.date_from || this.$route.query.date_to;

    if (!haveDateInQuery) {
      const start = this.$moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).endOf("month").format("YYYY-MM-DD");
      this.handleUpdateDateFilter([start, end]);
    }

    if(haveParams) this.fetchTableData()
  },
  data: () => ({
    user: [],
    link_type: [],
    date_range: [],
    task_id: "",
    filtersData: {
      types: [],
      users: [],
    },
    menuProps: DEFAULT_MENU_PROPS,
    loadings: {
      table: false,
      filters: false,
    },
    tableData: {
      headers: DEFAULT_DBC_TABLE_HEADERS,
      items: [],
    },
    FILTERS_QUERY_IDS: {
      USERS: "user",
      TYPE: "link_type",
      DATE: "date_range",
      TASK_ID: "task_id",
    },
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    firstEnter: false,
  }),
  computed: {},
  methods: {
    handleClickGetData() {
      this.fetchTableData();
    },
    async fetchTableData() {
      this.firstEnter = true;
      this.loadings.table = true;

      const { date_range, link_type, task_id, user } = this;
      const fixedTaskId = this.fixTaskId(task_id);
      const formData = {
        filter: {
          date_range,
          link_type,
          task_id: fixedTaskId,
          user,
        },
      };

      const resp = await this.$store.dispatch("dbc/fetchTableData", formData);

      if (resp) {
        this.tableData.items = this.fixItems(resp.data);
        this.filtersData.types = resp.types;
        this.filtersData.users = resp.users;
      }

      this.loadings.table = false;
    },
    fixTaskId(id) {
      let output = parseInt(id);
      if (output === 0) {
        output = null;
      }
      return output;
    },
    fixItems(items) {
      const defaultArray = JSON.stringify([{ name: "all" }]);
      if (JSON.stringify(items) === defaultArray) {
        return [];
      }
      return items;
    },
    handleUpdateDateFilter(event) {
      this.date_range = event;
      this._$handleFilterChange(event, this.FILTERS_QUERY_IDS.DATE);
    },
  },
};
</script>
