<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col>
          <h2>Detailed Static</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
        <v-col>
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-menu offset-y bottom content-class="shadow-e2-bordered">
                <template #activator="{ on }">
                  <v-chip outlined label v-on="on" class="mr-2">
                    <v-icon left small>mdi-cloud-download-outline</v-icon>
                    Export
                    <v-icon right small>mdi-chevron-down</v-icon>
                  </v-chip>
                </template>
                <v-card class="styled-card--default">
                  <v-card-title> Select type of export </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <v-list dense>
                      <v-list-item @click="createExport(4)"
                        >Full export by keyword
                      </v-list-item>
                      <v-list-item @click="createExport(5)"
                        >Full export by URL
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu
                bottom
                offset-y
                :close-on-content-click="false"
                content-class="remove-dialog-shadow"
              >
                <template #activator="{ on }">
                  <v-chip
                    v-on="on"
                    label
                    color="primary"
                    @click="dialog = true"
                  >
                    <v-icon left small> mdi-filter </v-icon>
                    Filters
                    <v-icon right small> mdi-chevron-down </v-icon>
                  </v-chip>
                </template>
                <v-card width="400" class="styled-card--default">
                  <v-card-title> Filters </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-dialog
                          ref="dialog"
                          v-model="date_modal"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateRangeText"
                              label="Select date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              hide-details
                              filled
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="filter.date_range"
                            range
                            scrollable
                            :allowed-dates="allowedDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="date_modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="date_modal = false"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.owner"
                          :items="owner_items"
                          label="Owner"
                          hide-details
                          multiple
                          chips
                          filled
                          dense
                          small-chips
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-row dense>
                          <v-col cols="4">
                            <v-autocomplete
                              v-model="filter.traffic_operator"
                              :items="number_operators_items"
                              filled
                              hide-details
                              dense
                              label="Operator"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              v-model="filter.traffic"
                              label="( Max ) Traffic In Any Date"
                              filled
                              dense
                              hide-details
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="d-flex justify-end">
                    <v-chip label class="mr-2" @click="dialog = false">
                      Close</v-chip
                    >
                    <v-chip
                      label
                      color="primary"
                      class="d-flex justify-center"
                      style="width: 130px"
                      :disabled="loading"
                      @click="fetchData"
                    >
                      Apply</v-chip
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card class="styled-card--default" :loading="loading">
        <v-card-title>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search in table"
                single-line
                dense
                hide-details
                style="max-width: 260px"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-chip
                v-if="
                  filter.date_range &&
                  filter.date_range[0] &&
                  filter.date_range[1]
                "
                text
                label
              >
                from <b class="mx-2">{{ filter.date_range[0] }}</b> to
                <b class="ml-2">{{ filter.date_range[1] }}</b>
              </v-chip>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-chip
                label
                class="mr-2"
                color="info"
                :disabled="selected.length === 0"
                @click="dialog_add = true"
              >
                <v-icon small>mdi-arrow-up</v-icon>
                Add to SL
              </v-chip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="items"
            :footer-props="{ itemsPerPageOptions: [50, 100, 250, -1] }"
            :items-per-page="50"
            :sort-by="['traffic']"
            :sort-desc="[true]"
            class="niche-analyzer-detailed-static-table"
            show-select
          >
            <template #[`item.comment`]="{ item }">
              <v-btn icon @click="handleOpenCommentsDialog(item)">
                <v-icon small>mdi-comment-edit-outline</v-icon>
              </v-btn>
            </template>
            <template #[`item.domain`]="{ item }">
              <div style="white-space: nowrap">
                {{ item.domain }}
                <a
                  :href="'http://' + item.domain"
                  target="_blank"
                  class="reset-link-styles"
                >
                  <v-icon small>mdi-open-in-new</v-icon>
                </a>
                <v-chip
                  x-small
                  color="info"
                  v-if="item.in_sl"
                  class="ml-1 pa-1"
                  title="Available in SpiderLink"
                  >SL
                </v-chip>
              </div>
            </template>
            <template v-slot:item.delta="{ item }">
              <span :style="{ color: item.delta < 0 ? 'red' : '' }">{{
                item.delta
              }}</span>
            </template>
            <template v-slot:item.delta_percent="{ item }">
              <span :style="{ color: item.delta < 0 ? 'red' : '' }">{{
                item.delta_percent
              }}</span>
            </template>
            <template v-slot:item.owner="{ item }">
              <v-chip
                dark
                v-if="item.owner.name.length > 0"
                :style="{ background: item.owner.color }"
                small
              >
                {{ item.owner.name }}
              </v-chip>
            </template>
            <template v-slot:item.top3_filter="{ item }">
              {{ item.top3[0] }}
              <sup
                v-bind:style="{
                  color: calcDelta(item.top3[1], item.top3[0]).s,
                }"
                >{{ calcDelta(item.top3[1], item.top3[0]).d }}</sup
              >
            </template>
            <template v-slot:item.top10_filter="{ item }">
              {{ item.top10[0] }}
              <sup
                :style="`color: ${calcDelta(item.top10[1], item.top10[0]).s}`"
                >{{ calcDelta(item.top10[1], item.top10[0]).d }}</sup
              >
            </template>
            <template v-slot:item.top20_filter="{ item }">
              {{ item.top20[0] }}
              <sup
                :style="`color: ${calcDelta(item.top20[1], item.top20[0]).s}`"
                >{{ calcDelta(item.top20[1], item.top20[0]).d }}</sup
              >
            </template>
            <template v-slot:item.top50_filter="{ item }">
              {{ item.top50[0] }}
              <sup
                :style="`color: ${calcDelta(item.top50[1], item.top50[0]).s}`"
                >{{ calcDelta(item.top50[1], item.top50[0]).d }}</sup
              >
            </template>
            <template v-slot:item.top100_filter="{ item }">
              {{ item.top100[0] }}
              <sup
                :style="`color: ${calcDelta(item.top100[1], item.top100[0]).s}`"
                >{{ calcDelta(item.top100[1], item.top100[0]).d }}</sup
              >
            </template>
            <template v-slot:item.avg_filter="{ item }">
              {{ item.avg[0] }}
              <sup :style="`color: ${calcDelta(item.avg[1], item.avg[0]).s}`">{{
                calcDelta(item.avg[1], item.avg[0]).d
              }}</sup>
            </template>
            <template v-slot:item.top_percent_filter="{ item }">
              {{ item.top_percent[0] }}
              <sup
                :style="`color: ${
                  calcDelta(item.top_percent[1], item.top_percent[0]).s
                }`"
                >{{
                  calcDelta(item.top_percent[1], item.top_percent[0]).d
                }}</sup
              >
            </template>
            <template v-slot:item.top_urls_percent="{ item }">
              {{ item.top_urls[0] }}
              <sup
                :style="`color: ${
                  calcDelta(item.top_urls[1], item.top_urls[0]).s
                }`"
                >{{ calcDelta(item.top_urls[1], item.top_urls[0]).d }}</sup
              >
            </template>
            <template v-slot:item.google_index_percent="{ value }">
              <span :class="{ 'error--text': value < 0 }"> {{ value }}% </span>
            </template>
            <template v-slot:item.google_index_delta="{ value }">
              <span :class="{ 'error--text': value < 0 }"> {{ value }} </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog
      v-model="commentsDialogModel"
      content-class="remove-dialog-shadow"
      width="460"
    >
      <v-card class="shadow-e2-bordered" rounded="lg">
        <v-card-title>Comments</v-card-title>
        <v-card-subtitle class="pt-4 pb-0">
          <v-text-field
            v-model="commentsSearch"
            hide-details
            dense
            label="Search by comments"
            outlined
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-card-subtitle>
        <div
          v-if="false"
          class="px-6 py-4"
          style="max-height: 600px; overflow: scroll"
        >
          <template v-if="mockComments.length > 0">
            <template v-for="comment in mockComments">
              <div
                v-if="
                  (commentsSearch && commentMatch(comment)) || !commentsSearch
                "
                :key="comment.id"
                class="py-3 px-1"
              >
                <div v-html="comment.text"></div>
                <div style="opacity: 0.5">
                  <small>
                    {{ comment.author }} •
                    {{ $moment(comment.created_at).format("MM/DD") }}
                  </small>
                </div>
              </div>
            </template>
          </template>
        </div>
        <v-card-text>
          <v-row dense>
            <v-col cols="10">
              <v-textarea
                v-model="commentData.comment"
                outlined
                rows="3"
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="2">
              <v-btn block style="height: 100%" @click="handleSaveComment">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_add" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title> Send to SpiderLink</v-card-title>
        <v-card-text>
          <v-autocomplete
            solo
            label="Task"
            v-model="sl_task"
            :items="sl_tasks"
            item-value="id"
            item-text="name"
            required
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog_add = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="sendToSL()"
            :disabled="dialog_add_loading"
            :loading="dialog_add_loading"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import ExportTaskService from "@/services/ExportTaskService";

export default {
  name: "Index",
  mixins: [FiltersHandling],
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Niche Analyzer",
        to: { name: "niche.analyzer.index" },
      },
      {
        text: "Detailed Static",
        disabled: true,
      },
    ],
    mockComments: [],
    search: "",
    dialog_add: false,
    dialog_add_loading: false,
    dialog: false,
    loading: false,
    date_modal: false,
    sl_task: undefined,
    commentsDialogModel: false,
    commentData: {
      comment: null,
      data: {},
    },
    commentsSearch: null,
    sl_tasks: [],
    selected: [],
    allowed_dates: [],
    number_operators_items: [
      {
        text: ">=",
        value: ">=",
      },
      {
        text: "<=",
        value: "<=",
      },
    ],
    owner_items: [],
    filter: {
      traffic_operator: ">=",
      traffic: 100,
      date_range: ["2020-09-30", "2020-10-05"],
      owner: null,
    },
    headers: [
      { text: "Domain", value: "domain" },
      { text: "Owner", value: "owner", align: "center", sortable: false },
      { text: "Traffic", value: "traffic", align: "center" },
      { text: "Traffic Prev", value: "traffic_prev", align: "center" },
      { text: "Δ", value: "delta", align: "center" },
      { text: "Δ%", value: "delta_percent", align: "center" },
      { text: "Top 1-3", value: "top3_filter", align: "center" },
      { text: "Top 4-10", value: "top10_filter", align: "center" },
      { text: "Top 11-20", value: "top20_filter", align: "center" },
      { text: "Top 21-50", value: "top50_filter", align: "center" },
      { text: "Top 51-100", value: "top100_filter", align: "center" },
      { text: "Avg", value: "avg_filter", align: "center" },
      { text: "% phrase", value: "top_percent_filter", align: "center" },
      { text: "Urls in #top100", value: "top_urls_percent", align: "center" },
      { text: "GI", value: "google_index", align: "center" },
      { text: "AH Html Pages", value: "ah_html_pages", align: "center" },
      { text: "AH Traffic", value: "ah_traffic", align: "center" },
      { text: "AH Ref Domains", value: "ah_refdomains", align: "center" },
      { text: "AH Ref Pages", value: "ah_refpages", align: "center" },
      {
        text: "WA",
        value: "first_seen",
        align: "center",
        width: 120,
      },
      { text: "GI from", value: "google_index_from", align: "center" },
      { text: "GI to", value: "google_index_to", align: "center" },
      { text: "Δ", value: "google_index_delta", align: "center" },
      { text: "Δ%", value: "google_index_percent", align: "center" },
      { text: "", value: "comment" },
    ],
    items: [],
    loading_date: false,
  }),
  computed: {
    dateRangeText() {
      if (
        this.filter.date_range &&
        this.filter.date_range[0] &&
        this.filter.date_range[1]
      ) {
        return this.filter.date_range.join(" ~ ");
      }

      return "";
    },
  },
  watch: {
    filter: {
      deep: true,
      handler(filters) {
        this._$handleFiltersChange(filters);
      },
    },
  },
  methods: {
    handleSaveComment() {
      console.log("save comment");
    },
    commentMatch(data) {
      const validation = [data.text.toLowerCase(), data.author.toLowerCase()];
      return validation.find((v) => v.includes(this.commentsSearch));
    },
    handleOpenCommentsDialog(item) {
      this.commentsDialogModel = true;
      this.commentData.data = item;
    },
    async createExport(typeId) {
      try {
        const json = {
          projectID: this.$store.state.na_project.active_project,
          dates: {
            from: this.filter.date_range[0],
            to: this.filter.date_range[1],
          },
        };
        const exportService = new ExportTaskService({
          type: typeId,
          json,
          context: this,
        });

        this.loading = true;

        await exportService.createExport();
      } finally {
        this.loading = false;
      }
    },
    allowedDates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    sendToSL() {
      let self = this;

      self.dialog_add_loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        domains: self.selected.map((v) => v.domain),
        taskID: self.sl_task,
      };

      axios
        .post(
          `${self.$store.state.server_url}/niche-analyzer/project/${
            this.$route.query.project_id ||
            this.$store.state.na_project.active_project
          }/send-to-sl`,
          data,
          config
        )
        .then(() => {
          self.dialog_add = false;
          self.selected = [];
        })
        .finally(() => (self.dialog_add_loading = false));
    },
    calcDelta(a, b) {
      if (b === null) {
        return "";
      }

      let f = Math.round(a);
      let t = Math.round(b);
      let d = Math.round(t - f);

      return {
        d: d !== 0 ? (d > 0 ? "+" : "") + d : "",
        s: d !== 0 ? (d > 0 ? "#4CAF50" : "#F44336") : "",
      };
    },
    fetchData() {
      let self = this;

      self.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        filter: self.filter,
      };

      axios
        .post(
          `${self.$store.state.server_url}/niche-analyzer/project/${
            this.$route.query.project_id ||
            this.$store.state.na_project.active_project
          }/detailed-static`,
          data,
          config
        )
        .then((r) => {
          self.items = r.data.items;
          self.sl_tasks = r.data.tasks;
          self.dialog = false;
          self.owner_items = r.data.owner_items;
        })
        .catch((e) => {
          this.$message.notification({
            title: "Something wrong",
            text: `Try again later. Or contact support. <br/> Error message: ${e}`,
            type: "error",
          });
        })
        .finally(() => (self.loading = false));
    },
    fillDates() {
      let dates_len = this.allowed_dates.length;

      this.filter.date_range = [
        this.allowed_dates[dates_len - 2],
        this.allowed_dates[dates_len - 1],
      ];
    },
    fetchAllowedDates() {
      let self = this;

      self.loading_date = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/niche-analyzer/project/${
            this.$route.query.project_id ||
            this.$store.state.na_project.active_project
          }/allowed-dates`,
          config
        )
        .then((r) => {
          self.allowed_dates = r.data.collected_dates;
          self.fillDates();
          self.fetchData();
          self.loading_date = false;
        });
    },
  },
  created() {
    this._$collectParamsTo(this, "filter", ["date_range"]);

    this.fetchAllowedDates();
  },
};
</script>

<style lang="scss">
.niche-analyzer-detailed-static-table {
  tr {
    th,
    td {
      &:nth-child(3),
      &:nth-child(7),
      &:nth-child(12),
      &:nth-child(15),
      &:nth-child(20) {
        border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
  }
}
</style>
