<template>
  <div>
    <v-row dense>
      <v-col cols="1">
        <v-autocomplete
          v-model="filters.gi"
          label="GI"
          disabled
          :items="filtersData.gi"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          @change="_$handleFilterChange($event, 'gi')"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="1">
        <v-autocomplete
          v-model="filters.do_follow"
          label="Do Follow"
          :items="filtersData.dofollow"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          @change="_$handleFilterChange($event, 'do_follow')"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.link_type"
          label="Links Type"
          :items="filtersData.link_type"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          @change="_$handleFilterChange($event, 'link_type')"
          :menu-props="menuProps"
          hide-details
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.link_type.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.anchor_type"
          label="Anchor Type"
          :items="filtersData.anchor_type"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          @change="_$handleFilterChange($event, 'anchor_type')"
          :menu-props="menuProps"
          hide-details
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.anchor_type.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <range-filter
          label="AH DR"
          :model-value="filters.ah_dr"
          :presets="presets.ahDrPresets"
          @update:modelValue="_$handleRangeChange($event, 'ah_dr', 'filters')"
        />
      </v-col>
      <v-col>
        <range-filter
          label="AH UR"
          :model-value="filters.ah_ur"
          :presets="presets.ahDrPresets"
          @update:modelValue="_$handleRangeChange($event, 'ah_ur', 'filters')"
        />
      </v-col>
      <v-col>
        <range-filter
          label="AH Traffic"
          :model-value="filters.ah_traffic"
          @update:modelValue="
            _$handleRangeChange($event, 'ah_traffic', 'filters')
          "
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="1">
        <v-autocomplete
          v-model="filters.title_thematic"
          label="Title Thematic"
          :items="['yes', 'no']"
          item-text="text"
          item-value="value"
          outlined
          dense
          clearable
          @change="_$handleFilterChange($event, 'title_thematic')"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="1">
        <v-autocomplete
          v-model="filters.gt"
          label="GT"
          :items="[2, 1, 0]"
          item-text="text"
          item-value="value"
          outlined
          dense
          clearable
          @change="_$handleFilterChange($event, 'gt')"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col>
        <range-filter
          label="AH URL Traffic"
          :model-value="filters.ah_url_traffic"
          :presets="presets.ahUrlTrafficPresets"
          @update:modelValue="
            _$handleRangeChange($event, 'ah_url_traffic', 'filters')
          "
        />
      </v-col>
      <v-col>
        <range-filter
          label="AH 2nd Level"
          :model-value="filters.ah_2nd_level"
          :presets="presets.ah2ndLevelPresets"
          @update:modelValue="
            _$handleRangeChange($event, 'ah_2nd_level', 'filters')
          "
        />
      </v-col>
      <v-col>
        <range-filter
          label="SW Rank"
          :model-value="filters.sw_rank"
          :presets="presets.swRankPresets"
          @update:modelValue="_$handleRangeChange($event, 'sw_rank', 'filters')"
        />
      </v-col>
      <v-col>
        <range-filter
          label="Num of Pages"
          :model-value="filters.nums_of_pages"
          :presets="presets.numOfPagesPresets"
          @update:modelValue="
            _$handleRangeChange($event, 'nums_of_pages', 'filters')
          "
        />
      </v-col>
      <v-col>
        <range-filter
          label="AH Tr RD"
          :model-value="filters.ah_tr_rd"
          :presets="presets.numOfPagesPresets"
          @update:modelValue="
            _$handleRangeChange($event, 'ah_tr_rd', 'filters')
          "
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4">
        <v-autocomplete
          v-model="filters.url"
          label="URL"
          :items="filtersData.url"
          item-text="text"
          item-value="value"
          outlined
          dense
          clearable
          :loading="loadings.filters"
          @change="_$handleFilterChange($event, 'url')"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="filters.filter_type"
          hide-details
          dense
          outlined
          label="Filter type"
          placeholder="Select type"
          item-value="value"
          item-text="name"
          :items="[
            { name: 'all', value: 'all' },
            { name: 'by Date filter', value: 'by_date_filter' },
          ]"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="filters.link_type_hidden"
          multiple
          :items="filtersData.link_type"
          dense
          hide-details
          small-chips
          deletable-chips
          outlined
          label="Hidden Link Types"
          placeholder="Type to search"
          @change="_$handleFilterChange($event, 'link_type_hidden')"
        >
          <template #prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="
                    filters.link_type_hidden.length > 0
                      ? 'primary darken-4'
                      : ''
                  "
                  >{{ icon }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.link_type_hidden.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <div class="fix-button-height">
          <date-filter
            :model-value="filters.date"
            @update:modelValue="handleUpdateDateFilter"
            :custom-presets="presets.customDatePresets"
          />
        </div>
      </v-col>
      <v-col cols="2" class="d-flex justify-end">
        <div class="fix-button-height" style="width: 100%">
          <v-btn
            block
            color="primary"
            @click="handleGetData"
            :loading="loadings.table"
          >
            Get data
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FiltersHandling from "@/mixins/components/FiltersHandling";
import easyRangeInput from "@/components/UI/EasyRangeInput";
import {
  DEFAULT_MENU_PROPS,
  NEW_LINKS_DYNAMIC_DASHBOARD_PRESETS,
} from "@/utils/defaultData";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";

export default {
  components: { DateFilter, RangeFilter: easyRangeInput },
  props: {
    modelValue: {},
    filtersData: {},
    loadings: {},
  },
  mixins: [FiltersHandling],
  data: () => ({
    presets: NEW_LINKS_DYNAMIC_DASHBOARD_PRESETS,
    menuProps: DEFAULT_MENU_PROPS,
  }),
  computed: {
    icon() {
      if (
        this.filters.link_type_hidden.length ===
        this.filtersData.link_type.length
      )
        return "mdi-close-box";
      if (
        this.filters.link_type_hidden.length > 0 &&
        this.filters.link_type_hidden.length < this.filtersData.link_type.length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    filters: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    toggle() {
      if (
        this.filters.link_type_hidden.length ===
        this.filtersData.link_type.length
      ) {
        this.filters.link_type_hidden = [];
      } else {
        this.filters.link_type_hidden = this.filtersData.link_type.slice();
      }
    },
    handleUpdateDateFilter(event) {
      this.filters.date = event;
      this._$handleFilterChange(event, "date");
    },
    handleGetData() {
      this.$emit("getData");
    },
  },
};
</script>
