<template>
  <div>
    <template v-for="(item, i) in tree">
      <template v-if="!item.hidden">
        <template v-if="item.children">
          <v-expansion-panels :key="item.name" flat accordion hover>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  {{ item.name }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <children :childrens="item.children" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider :key="item.name + i" />
        </template>
        <template v-else>
          <div :key="item.name" class="text-start py-4 px-6">
            {{ item.name }}
            <v-icon class="ml-2" small @click="handleShowInformationAbout(item)"
              >mdi-information-outline</v-icon
            >
          </div>
          <v-divider :key="item.name + i" />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import Children from "@/components/Pages/components/Children.vue";
export default {
  props: ["tree"],
  components: { Children },
  methods: {
    handleShowInformationAbout(item) {
      this.$store.commit("pages/setActiveItem", item);
    },
  },
};
</script>
