<template>
  <v-dialog v-model="dialog" width="440" content-class="remove-dialog-shadow">
    <v-card class="shadow-e1-bordered" rounded="lg">
      <v-card-title class="text-body-2 pa-4">
        Edit user
        <v-spacer />
        <v-icon @click="dialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="userData.user" class="pt-5">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2">Username</div>
            <v-text-field
              v-model="userData.user.name"
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Email</div>
            <v-text-field
              v-model="userData.user.email"
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Accesses</div>
            <v-autocomplete
              v-model="userData.userRoles"
              multiple
              placeholder="Type to search"
              outlined
              dense
              chips
              deletable-chips
              item-value="id"
              item-text="name"
              hide-details
              :items="userData.roles"
              :menu-props="menuProps"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="dialog = false"
          large
          class="px-6 text-normal"
          :loading="loading"
          >Back</v-btn
        >
        <v-btn
          large
          class="px-6 text-normal"
          @click="updateUser"
          color="success"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UserManageApiService } from "../services/UserManageApiService";
import Dialog from "../../../mixins/Dialog";
import { DEFAULT_MENU_PROPS } from "../../../utils/defaultData";

export default {
  mixins: [Dialog],
  props: {
    userData: {
      type: Object,
      default: () => ({}),
    },
    accessItems: {
      type: Array,
      default: () => [],
    },
    afterEditCallback: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
    menuProps: DEFAULT_MENU_PROPS,
    isPasswordHidden: true,
  }),
  methods: {
    async updateUser() {
      try {
        this.loading = true;

        const payload = {
          id: this.userData?.user?.id,
          name: this.userData?.user?.name,
          email: this.userData?.user?.email,
          roles: this.userData?.userRoles,
        };

        await UserManageApiService().updateUser(payload);

        this.dialog = false;
        this.afterEditCallback();
      } catch (e) {
        console.error("Error while updating user data.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
