<template>
  <v-card>
    <div :id="'chart-anchor-plan'+subproject_id"></div>
  </v-card>
</template>

<script>
import axios from "axios";
import ApexCharts from "apexcharts";

export default {
  name: "AnchorPlanChart",
  props: ['id', 'subproject_id'],
  data() {
    return {
      options: {
        chart: {
          height: 212.9,
          type: 'donut',
        },
        title: {
          text: 'Plan',
          align: 'left'
        },
        labels: [],
        dataLabels: {
          formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex]
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
        }
      },
    }
  },
  methods: {
    fetchData() {
      let self = this

      let url = `${self.$store.state.server_url}/ltt/types-dynamics/json`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        type: 'anchor-plan',
        project_id: self.id,
        subproject_id: self.subproject_id
      }

      axios
          .post(url, data, config)
          .then((r) => {
            let options = self.options

            options.labels = r.data.data.labels
            options.colors = r.data.data.colors
            options.series = r.data.data.series

            self.drawChart('chart-anchor-plan' + self.subproject_id, options)
          })
    },
    drawChart(id, options) {
      document.querySelector(`#${id}`).innerHTML = ''

      let chart = new ApexCharts(document.querySelector(`#${id}`), options);

      chart.render();
    },
  },
  created() {
    let self = this

    self.fetchData()
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    getProjects() {
      return this.$store.state.project.allowed_projects
    },
  }
}
</script>

<style scoped>

</style>