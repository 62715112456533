<template>
  <v-card v-if="data" class="styled-card--default">
    <v-card-title> Configuration </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="data.dialog_field_data.status_id"
            :items="statuses"
            auto-select-first
            clearable
            filled
            hide-details
            label="Status"
            :prepend-inner-icon="
              smStatuses[getStatusNameById(data.dialog_field_data.status_id)]
                ?.icon
            "
            @change="$emit('resetCheckboxes')"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="data.dialog_field_data.executives"
            :items="filtersData?.executive || []"
            clearable
            filled
            hide-details
            label="Executive"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <single-date-picker
            :model-value="data.dialog_field_data.deadline"
            @update:modelValue="data.dialog_field_data.deadline = $event"
            :min="$moment().format(DEFAULT_MOMENT_DATE_FORMAT())"
            filled
          />
          {{ computedDeadlineByPageText }}
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="data.dialog_field_data.comment"
            filled
            label="Comment"
            hide-details
            rows="3"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SingleDatePicker from "@/components/UI/SingleDatePicker.vue";
import StatusesMixin from "@/mixins/StatusesMixin";
import { DEFAULT_MOMENT_DATE_FORMAT } from "@/utils/defaultData";

export default {
  components: { SingleDatePicker },
  mixins: [StatusesMixin],
  emits: ["resetCheckboxes"],
  props: {
    data: {},
    filtersData: {
      type: Object,
      default: () => ({}),
    },
    statuses: Array,
  },
  computed: {
    computedDeadlineByPageText() {
      try {
        if (!this.data.dialog_field_data.deadline) return "";
        return `DL by page: ${this.data.dialog_field_data.deadline}`;
      } catch {
        return "";
      }
    },
  },
  methods: {
    DEFAULT_MOMENT_DATE_FORMAT() {
      return DEFAULT_MOMENT_DATE_FORMAT;
    },
    getStatusNameById(id) {
      return this.statuses.find((status) => status.value === id)?.text;
    },
  },
};
</script>
