<template>
  <td :style="`${getTdBgColor}`">
    <div
      :class="`d-flex align-center justify-space-between colored-td ${getColor} gradient`"
      style="text-align: left; height: 100%"
    >
      <span style="word-break: break-word">
        {{ data.url.text }}
        <v-tooltip v-if="data.pos_by_key" bottom>
          <template #activator="{ on }">
            <sup v-on="on">{{ data.pos_by_key }}</sup>
          </template>
          <div>Top Key Position</div>
        </v-tooltip>
      </span>
      <div
        v-if="data.url.used > 1"
        class="d-flex align-center justify-center"
        :style="`${badgeStyles + getBadgeColor}`"
      >
        {{ data.url.used }}
      </div>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  data: () => ({
    badgeStyles:
      "min-width: 18px;min-height:18px;border-radius: 100%;font-size: 12px;",
  }),
  computed: {
    getTdBgColor() {
      let css = 'background-color:'

      if(this.data.task_closed && !this.$vuetify.theme.dark) return (css + '#d9f4d6');
      if(this.data.task_closed && this.$vuetify.theme.dark) return (css + '#223d1f');

      return 'white'
    },
    getColor() {
      const needTop = this.data.need_top;

      if (this.data.task_closed) return "";

      if (!needTop || needTop >= 100) {
        return "red";
      }
      return "";
    },
    getBadgeColor() {
      const usedCount = parseInt(this.data.url.used);

      if (this.data.task_closed) return "";

      if (usedCount === 2) {
        return "background: rgba(255,164,0,0.21); color: rgba(255,154,0)";
      }

      if (usedCount === 3) {
        return "background: rgba(192,77,0,0.2); color: rgba(192,77,0)";
      }

      if (usedCount >= 4) {
        return "background: rgba(161,0,0,0.2); color: rgba(161,0,0)";
      }

      return "";
    },
  },
};
</script>
