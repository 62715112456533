import eventPipe from "@/events/eventPipe";
import service from "@/plugins/service";
import { CookieHandler } from "../../../utils/functions";

export default {
  state: {
    token:
      typeof localStorage.getItem("token") === "string" &&
      localStorage.getItem("token") !== "undefined undefined"
        ? localStorage.getItem("token")
        : null,
    user:
      typeof localStorage.getItem("user") === "string" &&
      localStorage.getItem("user") !== "undefined"
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    response: {
      msg: null,
      code: null,
    },
    response_register: {
      msg: null,
      code: null,
    },
    loading: false,
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    "auth/token": (state) => state.token,
    "auth/user": (state) => state.user,
  },
  actions: {
    checkRole(ctx, role) {
      return ctx.state.user.role === role;
    },
    /**
     * @param ctx - Vuex context
     * @param payload - Payload
     * @param payload.formData - Form data
     * @param payload.formData.token {string} - Google token
     * @param payload.options {object} - Options
     * @param payload.options.then {Function} - Method to call after success
     * @param payload.options.catch {Function} - Method to call after error
     * @returns {Promise<void>}
     */
    async googleAuth(ctx, payload) {
      const url = "/login/google";

      const response = await service.post(
        url,
        payload.formData,
        false,
        {},
        { prefix: "/api" }
      );

      ctx.dispatch("processLoginResponse", {
        response,
        options: payload.options || {},
      });
    },
    processLoginResponse(ctx, payload) {
      console.log("PROCESSING LOGIN RESPONSE", payload);
      const { response, options } = payload;

      try {
        const token = `Bearer ${response.data.token}`;
        localStorage.setItem("token", token);
        ctx.rootState.auth.token = token;

        eventPipe.$emit("user-is-authorized");

        ctx.dispatch("fetchPermission", options.then);
        ctx.commit("updateAuthUser", response.data.user);
      } catch (error) {
        if (error.response)
          ctx.state.response.msg = error.response.data.message;
        ctx.rootState.sheet = true;
        options.catch();
      }
    },
    async login(ctx, options) {
      try {
        const url = "/login";

        const payload = {
          email: options.email,
          password: options.password,
        };

        const response = await service.post(
          url,
          payload,
          false,
          {},
          { prefix: "/api" }
        );

        const cookie = new CookieHandler();
        const token = `Bearer ${response.data.token}`;
        cookie.set("token", token, 31);
        localStorage.setItem("token", token);
        ctx.rootState.auth.token = token;

        eventPipe.$emit("user-is-authorized");

        ctx.dispatch("fetchPermission", options.then);
        ctx.commit("updateAuthUser", response.data.user);
      } catch (error) {
        if (error.response)
          ctx.state.response.msg = error.response.data.message;
        ctx.rootState.sheet = true;
        options.catch();
      } finally {
        ctx.state.loading = false;
      }
    },
    async register(ctx, options) {
      try {
        const url = "/register";
        const payload = {
          name: options.name,
          email: options.email,
          password: options.password,
        };

        await service.post(url, payload);

        options.then();
      } catch (error) {
        if (error.response) {
          ctx.state.response_register.msg = error.response.data.message;
        }
        ctx.rootState.sheet = true;
        options.catch();
      }
    },
    logout(ctx, callback) {
      const cookie = new CookieHandler();
      ctx.rootState.auth.token = null;
      ctx.rootState.base.flat_permissions = null;
      ctx.rootState.base.permissions = null;
      ctx.rootState.base.menu_items_base = null;

      localStorage.clear();
      cookie.removeSingle("token");
      callback();
    },
  },
  mutations: {
    setAuthToken(state, payload) {
      state.token = payload;
    },
    updateAuthUser(state, data) {
      let user = JSON.stringify(data);
      localStorage.setItem("user", user);

      state.user = data;
    },
  },
};
