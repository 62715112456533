<template>
  <div>
    <v-card :loading="loading" elevation="5">
      <v-card-actions>
        <div>
          <span class="text-h6">SW Top Referring Categories</span><br />
          <small class="grey--text text--darken-1"
            >Updated {{ last_updated.day_diff }} days ago</small
          >
        </div>
        <v-spacer></v-spacer>

        <force-update-button
          :loading="loading"
          @click="_$forceUpdateData"
          :is-force-update-enabled="dataRelevance === 7"
        />
      </v-card-actions>
      <v-card-text class="pt-0">
        <v-list-item
          dense
          class="pa-0"
          v-for="item in items"
          :key="item.category"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.category }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ViewInfoBySiteIndex } from "../../../mixins/components/ViewInfoBySite/Index";
import axios from "axios";
import updateButtonMixin from "@/mixins/components/ViewInfoBySite/updateButtonMixin";
import ForceUpdateButton from "@/components/ViewInfoBySite/UI/ForceUpdateButton";

export default {
  name: "SwTop5ReferringCategories",
  components: { ForceUpdateButton },
  mixins: [ViewInfoBySiteIndex, updateButtonMixin],
  props: ["custom-domain"],
  data() {
    return {
      loading: false,
      refresh_loading: false,
      isFirstFetch: true,
      last_updated: {
        day_diff: 365,
        updated_at: "",
      },
      items: [],
    };
  },
  methods: {
    fetchData(_, force = false) {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/view-info-by-site/get-data`;

      let config = {
        params: {
          force,
          domain: self.domain,
          type: "sw_top_5_referring_categories",
          country: self.$route.query["country"] ?? 200,
          source: "view-info",
          first: this.isFirstFetch,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.last_updated = r.data.data.last_updated;
          self.items = r.data.data.data;
        })
        .finally(() => {
          if (this.last_updated.day_diff >= this.dataRelevance) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.fetchData(true, force);
            }, 30000);
          }

          this.loading = false;
        });

      this.isFirstFetch = false;
    },
    forceRefreshData() {
      this.data_relevance = 7;
      this.fetchData(true);
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
