var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.headerData.align ? `text-${_vm.headerData.align}` : ''}`},[(_vm.itemKey === 'max_time')?[_vm._v(" "+_vm._s(_vm.humanTime(_vm.value))+" ")]:(_vm.itemKey === 'now')?[(_vm.getNowColor !== 'transparent')?_c('v-chip',{attrs:{"small":"","color":_vm.getNowColor}},[_c('span',[_vm._v(" "+_vm._s(_vm.humanTime(_vm.value))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.humanTime(_vm.value))+" ")])]:(_vm.itemKey === 'time_all')?[_c('span',[_vm._v(" "+_vm._s(_vm.humanTime(_vm.value))+" ")])]:(_vm.itemKey === 'labels')?[_c('div',{staticClass:"overflow-chip",staticStyle:{"transform":"scale(0.85)"}},_vm._l((_vm.value),function(label,idx){return _c('v-chip',{key:label + idx,attrs:{"small":"","dark":"","color":_vm.getChipColor(label)}},[_vm._v(" "+_vm._s(label)+" ")])}),1)]:(_vm.itemKey === 'last_10_d')?[_c('v-sparkline',{attrs:{"value":_vm.value,"line-width":"16","gradient":['#639ee0', '#2b4d93'],"type":"bar","width":300}})]:(_vm.itemKey === 'executive')?[(_vm.value)?_c('a',{attrs:{"href":_vm.value.value,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.value.text)+" ")]):_vm._e()]:(_vm.itemKey === 'project')?[_vm._v(" "+_vm._s(_vm.rowData.project)+" ")]:(_vm.itemKey === 'task_name')?[_c('div',{class:{
        mark: true,
        'mark--success': _vm.rowData.task_done === 1,
        'mark--error': _vm.rowData.task_done === 2,
        'mark--lime': _vm.isDeadlineMovedAndCurrentGroupIsMeetingOrRegular,
        'mark--yellow': _vm.isDeadlineMoved,
      }}),_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"bottom":"","offset-x":"","content-class":"my-shadow--e3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',{staticClass:"styled-card--default",attrs:{"min-width":"180"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',[_c('v-list-item',[_c('small',[_c('b',[_vm._v("Create subtask")])]),_c('small',{staticClass:"ml-2"},[_vm._v("(Select preset)")])]),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('createSubtask', {
                    data: _vm.rowData,
                    type: 'need_details',
                  })}}},[_vm._v(" Need details ")]),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('createSubtask', {
                    data: _vm.rowData,
                    type: 'need_review',
                  })}}},[_vm._v(" Need review ")]),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('createSubtask', {
                    data: _vm.rowData,
                    type: 'testing_dev',
                  })}}},[_vm._v(" Testing: Dev ")]),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('createSubtask', {
                    data: _vm.rowData,
                    type: 'testing_prod',
                  })}}},[_vm._v(" Testing: Prod ")]),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('createSubtask', { data: _vm.rowData, type: 'empty' })}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_vm._v(" Empty template ")])],1),_c('v-list-item',{on:{"click":_vm.handleClickCloseTask}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('v-list-item-content',[_vm._v(" Close task ")])],1)],1)],1)],1)],1),(_vm.rowData.project_status && _vm.getStatusData.icon)?_c('v-icon',{attrs:{"color":_vm.getStatusData.color,"small":"","left":"","title":_vm.getStatusData.title}},[_vm._v(" "+_vm._s(_vm.getStatusData.icon)+" ")]):_vm._e(),_c('a',{attrs:{"href":_vm.value.value,"target":"_blank"}},[_c('span',{style:({ fontWeight: !_vm.rowData['weekly'] ? '' : 700 })},[_vm._v(" "+_vm._s(String(_vm.value.text).trim())+" ")])])],1)]:(_vm.itemKey === 'date_end')?[_c('v-chip',{attrs:{"color":_vm.getDeadlineColor(_vm.value)}},[_vm._v(" "+_vm._s(_vm.value ? _vm.$moment(_vm.value).format("D MMM") : "")+" ")])]:(_vm.itemKey === '_json')?void 0:[_vm._v(" "+_vm._s(_vm.value)+" ")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }