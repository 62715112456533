<template>
  <div>
    <v-card :loading="loading" elevation="5">
      <v-card-actions>
        <div>
          <span class="text-h6">AH Top Pages</span><br />
          <small class="grey--text text--darken-1"
            >Updated {{ last_updated.day_diff }} days ago</small
          >
        </div>
        <v-spacer></v-spacer>
        <span style="margin-right: 70px">
          <v-text-field
            v-model="search"
            style="max-width: 400px; width: 300px"
            append-icon="mdi-magnify"
            label="Search"
            dense
            single-line
            full-width
            hide-details
            solo
            class="align-end"
          ></v-text-field>
        </span>
        <v-select
          style="max-width: 150px"
          v-model="niche_selected"
          label="Niche"
          :items="niches"
          solo
          hide-details
          dense
        ></v-select>
        <force-update-button
          :loading="loading"
          @click="_$forceUpdateData"
          :is-force-update-enabled="dataRelevance === 7"
        />
      </v-card-actions>
      <v-card-text>
        <v-data-table
          class="elevation-1"
          :headers="table.headers"
          :items="series_filtered"
          hide-default-footer
          :items-per-page="-1"
          :search="search"
          fixed-header
          height="440px"
          dense
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.country">
                <td class="text-center text-no-wrap">
                  {{ item.traffic }}
                  <span style="color: grey">{{ item.traffic_percent }}%</span>
                </td>
                <td v-if="!isGtt" class="text-center text-no-wrap">
                  {{ item.traffic_value }}
                </td>
                <td v-if="!isGtt" class="text-center text-no-wrap">
                  {{ item.keywords_count }}
                </td>
                <td v-if="!isGtt" class="text-center text-no-wrap">
                  {{ item.difficulty }}
                </td>
                <td class="text-wrap" style="word-break: break-all">
                  <a :href="item.url" target="_blank">{{ item.path }}</a>
                </td>
                <td>
                  {{ item.top_keyword }}
                  <v-icon
                    color="blue"
                    small
                    @click="translate_string(item.top_keyword)"
                  >
                    mdi-google-translate
                  </v-icon>
                </td>
                <td class="text-center text-no-wrap">
                  {{ item.top_keyword_volume }}
                </td>
                <td class="text-center text-no-wrap">
                  {{ item.top_keyword_position }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-bottom-sheet v-model="translate.show" inset>
      <v-alert prominent icon="mdi-google-translate" color="info" dark>
        <v-row align="center">
          <v-col class="grow">
            <b>Original</b>: {{ translate.original }}
            <br />
            <b>Translation</b>: {{ translate.translation }}
          </v-col>
          <v-col class="shrink">
            <v-btn
              color="warning"
              outlined
              depressed
              dark
              @click="translate.show = !translate.show"
            >
              OKAY
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-bottom-sheet>
  </div>
</template>

<script>
import axios from "axios";
import { ViewInfoBySiteIndex } from "../../../mixins/components/ViewInfoBySite/Index";
import updateButtonMixin from "@/mixins/components/ViewInfoBySite/updateButtonMixin";
import ForceUpdateButton from "@/components/ViewInfoBySite/UI/ForceUpdateButton";

export default {
  name: "AhTopPages",
  components: { ForceUpdateButton },
  mixins: [ViewInfoBySiteIndex, updateButtonMixin],
  props: ["custom-domain", "assignment"],
  data() {
    return {
      search: undefined,
      niche_selected: "all",
      niches: ["all", "essay", "casino"],
      data_relevance: 42,
      loading: false,
      refresh_loading: false,
      ws_type: "ah:top_pages",
      table: {
        headers: [
          {
            text: "Traffic",
            title: "Traffic",
            value: "traffic",
            class: "text-center",
          },
          {
            text: "Value",
            title: "Value",
            value: "traffic_value",
            class: "text-left",
          },
          {
            text: "Keywords",
            title: "Keywords",
            value: "keywords_count",
            class: "text-left",
          },
          {
            text: "KD",
            title: "Keywords difficulty",
            value: "difficulty",
            class: "text-left",
          },
          {
            text: "Page URL",
            title: "Page URL",
            value: "url",
            class: "text-center",
          },
          {
            text: "Top keyword",
            title: "Top keyword",
            value: "top_keyword",
            class: "text-center",
          },
          {
            text: "Its volume",
            title: "Its volume",
            value: "top_keyword_volume",
            class: "text-left",
          },
          {
            text: "Pos",
            title: "Pos",
            value: "top_keyword_position",
            class: "text-left",
          },
        ],
        items: [],
      },
      series: [],
      last_updated: {
        day_diff: 365,
        updated_at: "",
      },
      translate: {
        show: false,
        original: "",
        translation: "",
      },
      isFirstFetch: true,
    };
  },
  watch: {
    niche_selected() {
      this.getCountAhTop5Pages();
    },
  },
  computed: {
    isGtt() {
      return this.assignment === "gtt";
    },
    series_filtered() {
      let self = this;

      if (self.niche_selected === "all") {
        return self.table.items;
      }

      return self.table.items.filter((v) => v.niche === self.niche_selected);
    },
  },
  methods: {
    getCountAhTop5Pages() {
      let items = this.table.items;

      if (this.niche_selected !== "all") {
        items = items.filter((v) => v.niche === this.niche_selected);
      }

      this.$emit("getCountAhTop5Pages", {
        count: this.getIndicatorsValue(
          items
            .slice(0, 5)
            .reduce((a, b) => a + (b["traffic_percent"] || 0), 0),
        ),
      });
    },
    getIndicatorsValue(value) {
      if (value > 100) {
        return 100;
      } else return value;
    },
    translate_string(string) {
      let self = this;

      self.translate.original = string;

      let url = `${self.$store.state.server_url}/core/get-translation`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        lang_source: "auto",
        lang_target: "en",
        string: string,
      };

      axios.post(url, data, config).then(function (response) {
        self.translate.translation = response.data.translation;
        self.translate.show = true;
      });
    },
    fetchData(refresh_data, force = false) {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/view-info-by-site/get-data`;

      let config = {
        params: {
          force,
          domain: self.domain,
          type: "ah_top_pages",
          country: self.$route.query["country"] ?? 200,
          source: "view-info",
          first: this.isFirstFetch,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.table.items = r.data.data.data;
          self.last_updated = r.data.data.last_updated;
          if (!self.isValidRelevance && refresh_data) {
            self.refreshData();
          }
          self.getCountAhTop5Pages();
        })
        .finally(() => {
          if (this.last_updated.day_diff > this.dataRelevance) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.fetchData(true, force);
            }, 30000);
          }

          this.loading = false;
        });

      this.isFirstFetch = false;
    },
    forceRefreshData() {
      this.data_relevance = 7;
      this.fetchData(true);
    },
  },
  created() {
    let self = this;

    self.fetchData(true);
    self.createdAt();
  },
};
</script>

<style scoped></style>
