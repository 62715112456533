<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col
          :class="is_dark_mode ? '' : 'grey lighten-5'"
          cols="12"
          md="5"
          offset="2"
      >
        <v-card
            elevation="0"
            :color="is_dark_mode ? '' : 'grey lighten-5'"
        >
          <v-card-title>
            UCT - Collect params simply
          </v-card-title>
          <v-card-text>
            <v-text-field
                v-model="project_name"
                :error="errors.name.status"
                :error-messages="errors.name.messages"
                @focusin="resetErrors"
                solo
                label="Task name"
                required
                hide-details
                class="mb-4"
            ></v-text-field>
            <v-textarea
                v-model="domains"
                :error="errors.domains.status"
                :error-messages="errors.domains.messages"
                @focusin="resetErrors"
                solo
                name="input-7-1"
                label="Enter urls, delimiter - newline"
                no-resize
                hide-details
            ></v-textarea>
            <v-row>
              <v-col cols="12" class="pa-3">
                <div class="d-flex flex-row align-center">
                  <div style="width: 100%" class="text-center">
                    <v-tooltip
                        max-width="300"
                        bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <small style="cursor:pointer;" v-bind="attrs"
                               v-on="on"><strong>Used limits: {{ limits.month_used }} / {{
                            limits.month_limit
                          }}</strong></small>
                      </template>
                      <span>
                            <strong>Limits</strong><br>
                            Monthly: {{ limits.month_limit }}<br>
                            Used: {{ limits.month_used }}<br>
                            Left: {{ limits.month_left }}
                          </span>
                    </v-tooltip>
                    <v-progress-linear :value="progress_limit" rounded color="info"></v-progress-linear>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
          :class="is_dark_mode ? '' : 'grey lighten-5'"
          cols="12"
          md="3"
      >
        <v-card
            elevation="0"
            :color="is_dark_mode ? '' : 'grey lighten-5'"
            class="overflow-auto"
            min-height="246"
        >
          <v-card-title>
            Select need params
          </v-card-title>
          <v-card-text>
            <v-treeview
                v-model="selected_params"
                selectable
                @input="change_params"
                :items="params_items"
            >
              <template v-slot:append="{ item }">
                <v-tooltip
                    max-width="300"
                    bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-if="item.id===5">
                      <template>
                        <v-autocomplete
                            v-model="country"
                            :items="countries"
                            item-key="id"
                            item-text="name"
                            item-value="id"
                            :label=item.name
                            small-chips
                        ></v-autocomplete>
                      </template>
                    </div>
                    <v-icon
                        v-if="item.info"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span v-html="item.info"></span>
                </v-tooltip>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="8"
          offset="2"
          :class="is_dark_mode ? '' : 'grey lighten-5'"
      >
        <div class="text-center">
          <v-btn
              outlined
              color="info"
              :loading="start_btn_loading"
              @click="start_collection"
          >
            Start Collection
            <v-icon
                right
                dark
            >
              mdi-play-outline
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="errors_messages.length > 0 || error_domains.length > 0">
      <v-col
          cols="12"
          md="8"
          offset="2"
          :class="is_dark_mode ? '' : 'grey lighten-5'"
      >
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col
          cols="12"
          md="8"
          offset="2"
          :class="is_dark_mode ? '' : 'grey lighten-5'"
      >
        <v-card
            elevation="0"
            :class="is_dark_mode ? '' : 'grey lighten-5'"
            class="overflow-auto"
        >
          <v-card-title>
            Latest tasks
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="table_headers"
                :items="table_items"
                :items-per-page="10"
                class="elevation-1"
                hide-default-footer
            >
              <template v-slot:item.name="{ item }">
                <span class="text-subtitle-1">{{ item.name }}</span><br>
                <small>{{ formatDate(item.created_at) }}</small>
              </template>
              <template v-slot:item.status="{ item }">
                <v-tooltip bottom>
                  <template
                      v-slot:activator="{ on, attrs }"
                  >
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :color="get_status_data(item.status)[1]"
                        v-html="get_status_data(item.status)[0]"
                    ></v-icon>
                  </template>
                  <div>
                    Collected
                    <strong>
                      {{
                        item.await_collection === null ? item.number_of_domains : item.number_of_domains - item.await_collection
                      }}
                      of
                      {{ item.number_of_domains }}
                    </strong>
                    domains
                  </div>
                </v-tooltip>
              </template>
              <template v-slot:item.progress="{ item }">
                <div class="d-flex flex-row align-center">
                  <v-progress-linear
                      v-if="get_progress(item.await_collection, item.number_of_domains) < 100"
                      color="info"
                      indeterminate
                      rounded
                  ></v-progress-linear>
                  <v-progress-linear
                      rounded
                      v-else
                      :value="get_progress(item.await_collection, item.number_of_domains)"
                      color="success"
                  ></v-progress-linear>
                  <v-btn
                      icon
                      @click="open_info_dialog(item.id)">
                    <v-icon size="20" color="grey">
                      mdi-information-outline
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="export_handler(item)">
                      <v-icon size="20" :color="get_status_data(item.status)[1]">
                        mdi-cloud-download-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-if="get_status_data(item.status)[1] === 'success'">Export All data</span>
                  <span v-else>Export data for NOW</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="delete_id = item.id; dialog_delete = true">
                  <v-icon size="20" color="warning">
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog
            v-model="info_dialog"
            max-width="500"
        >
          <v-card>
            <v-card-title>
              Collection progress
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item
                    v-for="item in info_dialog_data"
                    :key="item.name"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <div v-if="item.time>0" title="Collection time">{{ item.time }}m</div>

                  <v-list-item-action>
                    <v-progress-linear
                        :color="parseInt(item.percent) === 100 ? 'success' : 'info'"
                        style="width: 100px;"
                        :value="item.percent"
                        rounded
                    ></v-progress-linear>
                  </v-list-item-action>

                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  color="info"
                  @click="info_dialog = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_delete"
            max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              Are you sure?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  color="grey"
                  @click="dialog_delete = false"
              >
                Cancel
              </v-btn>

              <v-btn
                  color="info"
                  text
                  @click="delete_task"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_export"
            max-width="500"
        >
          <v-card>
            <v-card-title class="headline">
              Select parameters for export
            </v-card-title>

            <v-card-text>

              <v-radio-group v-model="export_type">
                <template>
                  <div :key="item.key" v-for="item in export_types">
                    <v-radio
                        :key=item.key
                        :label=item.label
                        :value=item.key
                        v-if="item.id === 0 || task.selected_params.indexOf(item.id) !== -1"
                    ></v-radio>
                  </div>
                </template>
              </v-radio-group>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  @click="dialog_export = false"
              >
                Cancel
              </v-btn>

              <v-btn
                  color="green darken-1"
                  text
                  @click="export_file"
              >
                Export
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Index",
  data() {
    return {
      project_name: undefined,
      domains: undefined,
      table_headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: false, width: 50},
        {text: 'Name', value: 'name',},
        {text: 'Progress', value: 'progress', align: 'center', sortable: false, width: 150},
        {text: 'Status', value: 'status', align: 'center', sortable: false, width: 100},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false, width: 150},
      ],
      table_items: [],
      alert_dct: true,
      start_btn_loading: false,
      params: [],
      params_items: [],
      error_domains: [],
      validated_domains: [],
      errors: {
        name: {
          status: false,
          messages: []
        },
        domains: {
          status: false,
          messages: []
        }
      },
      selected_params: [],
      countries: [],
      country: 1,
      selected_export_params: [],
      errors_messages: [],
      dialog_export: false,
      dialog_delete: false,
      delete_id: undefined,
      export_id: undefined,
      limits: {},
      export_types: [
        {key: "main", label: "Main data", id: 0},
        {key: "sw_themes", label: "Sw themes", id: 11},
        {key: "sw_counties", label: "Sw countries", id: 11},
        {key: "sw_keywords_statistics", label: "Sw keywords statistics", id: 11},
        {key: "sw_keywords", label: "Sw keywords", id: 11},
        {key: "textrazor_topics", label: "Textrazor topics", id: 19},
      ],
      task: {
        selected_params: []
      },
      export_type: 'main',
      info_dialog: false,
      info_dialog_data: []
    }
  },
  methods: {
    formatDate(date) {
      return (new Date(date)).toDateString()
    },
    open_info_dialog(id_task) {
      let self = this

      let url = `${self.$store.state.server_url_clear}/api/uct/task/${id_task}`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .then(function (response) {
            self.info_dialog_data = response.data.params
            self.info_dialog = true
          });
    },
    get_progress(await_collection, number_of_domains) {
      if (number_of_domains === 0) {
        return 0
      }

      if (await_collection == null) {
        await_collection = number_of_domains
      }

      let collected = number_of_domains - await_collection

      let percent = Math.round(100 * collected / number_of_domains)

      return percent === 100 && collected !== number_of_domains ? 99 : percent
    },
    change_params() {
      if (this.selected_params.indexOf(20) !== -1) {
        if (this.selected_params.indexOf(12) === -1) {
          this.selected_params.push(12)
        }
        if (this.selected_params.indexOf(13) === -1) {
          this.selected_params.push(13)
        }
        if (this.selected_params.indexOf(15) === -1) {
          this.selected_params.push(15)
        }

      }

      if (this.selected_params.indexOf(27) !== -1) {
        if (this.selected_params.indexOf(11) === -1) {
          this.selected_params.push(11)
        }
      }
    },
    get_status_data(status) {
      let arr = {
        3: ['mdi-check-all', 'success'],
      }

      return arr[status] !== undefined ? arr[status] : ['mdi-clock-outline', 'grey']
    },
    open_export_dialog() {
      let self = this

      self.dialog_export = true
    },
    export_handler(item) {
      this.task.selected_params = item.selected_params

      if ((item.selected_params.indexOf(19) === -1) && (item.selected_params.indexOf(11) === -1)) {
        this.export_id = item.id
        this.export_file()
      } else {
        this.export_id = item.id
        this.open_export_dialog()
      }
    },
    export_file() {
      let self = this
      let id_task = self.export_id

      let url = `${self.$store.state.server_url_clear}/api/uct/export/result`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let post_data = {
        id_task: id_task,
        type: self.export_type
      }

      axios.post(url, post_data, config)
          .then((response) => {
            if (response.data.success) {
              self.dialog_export = false
              location.href = `${self.$store.state.server_url_clear}/api/uct/export/result?hash=` + response.data.hash + '&type=' + self.export_type
            }
          })
    },
    delete_task() {
      let self = this
      let id = self.delete_id

      let url = `${self.$store.state.server_url_clear}/api/uct/task/${id}`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.delete(url, config)
          .finally(() => {
            self.dialog_delete = false
            self.fetch_latest_tasks()
          })
    },
    fetch_limits() {
      let self = this

      let url = `${self.$store.state.server_url_clear}/api/uct/limits`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .then(function (response) {
            self.limits = response.data
          })
    },
    fetch_countries() {
      let self = this

      let url = `${self.$store.state.server_url_clear}/api/uct/countries`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .then(function (response) {
            self.countries = response.data
          }).catch(() => self.sheet = true);
    },
    fetch_allowed_params() {
      let self = this

      let url = `${self.$store.state.server_url_clear}/api/uct/params`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .then(function (response) {
            if (response.data.success) {
              self.params_items = response.data.params
            }
          }).catch(() => self.sheet = true);
    },
    fetch_latest_tasks() {
      let self = this

      let url = `${self.$store.state.server_url_clear}/api/uct/task`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .then(function (response) {
            if (response.data.success) {
              self.table_items = response.data.items
            }
          }).catch(() => self.sheet = true);
    },
    start_collection() {
      let self = this

      self.start_btn_loading = true

      let url = `${self.$store.state.server_url_clear}/api/uct/task`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      };

      let data = {
        name: self.project_name,
        domains: self.domains.split("\n"),
        params: self.selected_params,
        country: self.country
      }

      axios.post(url, data, config)
          .then(function (response) {
            if (response.data.success) {
              self.project_name = undefined
              self.domains = undefined
              self.selected_params = []

              self.fetch_latest_tasks()
              self.fetch_limits()
            }
          })
          .finally(() => {
            self.start_btn_loading = false
          })
    },
    resetErrors() {
      let self = this

      self.errors.name.status = false
      self.errors.name.messages = []
      self.errors.domains.status = false
      self.errors.domains.messages = []

      self.errors_messages = []

      self.error_domains = []
    },
  },
  computed: {
    is_dark_mode() {
      return this.$vuetify.theme.dark
    },
    progress_limit() {
      return 100 * this.limits.month_used / this.limits.month_limit
    },
    get_battery_icon() {
      let self = this

      let percent = 100 - 100 * self.limits.used / self.limits.month_limit

      if (percent === 0) {
        return 'mdi-battery-outline'
      } else if (percent < 40) {
        return 'mdi-battery-low'
      } else if (percent < 80) {
        return 'mdi-battery-medium'
      }

      return 'mdi-battery-high'
    },
  },
  watch: {
    alert_dct() {
    }
  },
  created() {
    this.fetch_limits()
    this.fetch_latest_tasks()
    this.fetch_allowed_params()
    this.fetch_countries()

    setInterval(this.fetch_latest_tasks, 10000)
  }
}
</script>

<style scoped>

</style>