import Vue from "vue";
import Vuex from "vuex";
import Clipboard from "v-clipboard";
import modules from "@/store/modules";

Vue.use(Vuex);
Vue.use(Clipboard);

const store = new Vuex.Store({
  modules: { ...modules },
  state: {
    beta: true,
    sheet: false,
    sheet_message: "An error has occurred, we are working on a fix.",
    enable_test_data: false,
    count: 0,
    currentTab: 1,
    redirect_to_after_login: "",
    loading: false,
    loading_indeterminate: true,
    temp: {},
    server_url_clear: process.env.VUE_APP_BASE_URL,
    server_url: process.env.VUE_APP_BASE_URL + "/api/s",
    startLocation: null,
  },
  mutations: {
    increment(state) {
      state.count += 2;
    },
    setStartLocation(state, payload) {
      state.startLocation = payload;
    },
  },
  getters: {
    getStartLocation: (state) => state.startLocation,
  },
});

export default store;
export const useStore = () => store;
