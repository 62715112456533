<template>
    <v-btn-toggle v-if="items && items.length > 0">
      <template v-for="(item, idx) in items">
        <v-btn
          :key="idx"
          width="120"
          :style="`height: 85px;`"
          outlined
          :class="`btn-toggle ${isActive(idx) ? 'primary--text v-btn--active' : ''}`"
          @click="mode = (idx === 0 ? 'rise' : 'drop')"
        >
          <template #default>
            <div class="d-flex flex-column">
            <span
              v-if="item.text > 0"
              class="success--text diff__item"
            >
          <v-icon color="success"> mdi-menu-up </v-icon>
          {{ String(item.text).replaceAll("-", "") }}
          </span>
              <span
                v-else-if="item.text < 0"
                class="error--text diff__item"
              >
            <v-icon color="error"> mdi-menu-down </v-icon>
            {{ String(item.text).replaceAll("-", "") }}
          </span>
              <v-divider class="my-2" v-if="String(item.percent) !== '0'"/>
              <b v-if="String(item.percent) !== '0'" :key="idx">
                {{item.percent}}%
              </b>
            </div>
          </template>
        </v-btn>
      </template>
    </v-btn-toggle>
</template>

<script>
export default {
  props: {
    items: {},
    modelValue: {},
  },
  data: () => ({
    RISE_TYPE: 0,
    DROP_TYPE:1
  }),
  computed: {
    mode: {
      get() {return this.modelValue},
      set(value) {this.$emit('update:modelValue', value)}
    },
  },
  methods: {
    isActive(btnType) {
      if(btnType === this.RISE_TYPE && this.mode === 'rise') return true
      if(btnType === this.DROP_TYPE && this.mode === 'drop') return true
    },
  },
};
</script>