<template>
  <div>
    <h2>{{ title }}</h2>
    <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Page title",
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
