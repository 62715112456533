export default {
  methods: {
    getRgb(inputVal, max, colorYR, colorYG, colorYB, opacity = '0.5') {
      if (!inputVal || isNaN(inputVal)) {
        return "transparent";
      }

      inputVal = parseInt(inputVal);
      max = parseInt(max);

      const n = 100;
      const xr = 255;
      const xg = 255;
      const xb = 255;

      const pos = parseInt(Math.round((inputVal / max) * 100).toFixed(0));
      const red = parseInt((xr + (pos * (colorYR - xr)) / (n - 1)).toFixed(0));
      const green = parseInt(
        (xg + (pos * (colorYG - xg)) / (n - 1)).toFixed(0)
      );
      const blue = parseInt((xb + (pos * (colorYB - xb)) / (n - 1)).toFixed(0));

      return "rgba(" + red + "," + green + "," + blue + `, ${opacity})`;
    },
  },
};
