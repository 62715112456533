<template>
    <div>
        <v-container fluid v-if="isset_active_project">
            <v-row>
                <v-col cols="12" md="12">
                    <v-card
                            class="mx-auto"
                            v-if="isset_active_project"
                    >
                        <v-card-text class="headline font-weight-bold">
                            <v-row
                                    class="align-end"
                            >
                                <v-col
                                        sm="5"
                                >
                                    <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                            solo
                                            class="align-end"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        sm="1"
                                >

                                    <v-menu
                                            transition="slide-x-transition"
                                            :close-on-content-click="false"
                                            :nudge-right="200"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-fab-transition>
                                                <v-btn
                                                        color="info"
                                                        dark
                                                        small
                                                        top
                                                        right
                                                        fab
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >
                                                    <v-icon>mdi-filter</v-icon>
                                                </v-btn>
                                            </v-fab-transition>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-dialog
                                                                ref="dialog"
                                                                v-model="date_modal"
                                                                persistent
                                                                width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                        v-model="filter.date_range"
                                                                        label="Picker in dialog"
                                                                        prepend-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                    v-model="filter.date_range"
                                                                    range
                                                                    scrollable
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="date_modal = false"
                                                                >
                                                                    Cancel
                                                                </v-btn>
                                                                <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="date_modal = false"
                                                                >
                                                                    OK
                                                                </v-btn>
                                                            </v-date-picker>
                                                        </v-dialog>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-autocomplete
                                                                v-model="filter.country"
                                                                :items="countries"
                                                                item-text="ga"
                                                                item-value="id"
                                                                label="Country"
                                                                chips
                                                                deletable-chips
                                                                small-chips
                                                        ></v-autocomplete>
                                                    </v-list-item>
                                                </v-list>
                                                <div class="text-center">
                                                    <v-btn
                                                            @click="fetch_data"
                                                            color="primary">
                                                        Apply
                                                    </v-btn>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row
                                    align="center"
                                    justify="center"
                            >
                                <v-col>
                                    <div class="section">
                                        <v-data-table
                                                :headers="table.headers"
                                                :items="table.items"
                                                class="elevation-1"
                                                dense
                                        >
                                            <template v-slot:body="{ items }">
                                                <tbody>
                                                <tr v-for="item in items" :key="item.domain">
                                                    <td>
                                                        {{ item.sub_id_4 }}
                                                    </td>
                                                    <td>
                                                        {{ item.imp }}
                                                    </td>
                                                    <td>
                                                        {{ item.clicks }}
                                                    </td>
                                                    <td>
                                                        {{ item.sub_id_1 }}
                                                    </td>
                                                    <td>
                                                        {{ item.sub_id_2 }}
                                                    </td>
                                                    <td>
                                                        {{ item.sub_id_5 }}
                                                    </td>
                                                    <td>
                                                        {{ item.sum_of_clicks }}
                                                    </td>
                                                    <td>
                                                        {{ item.sum_of_clicks_unique }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-alert
                icon="mdi-shield-lock-outline"
                prominent
                text
                type="info"
                dark
                v-else
        >
            <v-row align="center">
                <v-col class="grow">
                    To view the page, you must select a project.
                </v-col>
                <v-col class="shrink">
                    <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
                        project
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>

import axios from "axios";

let dateFormat = require('dateformat');

    let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
    let beforeOneWeek2 = new Date(beforeOneWeek);
    let day = beforeOneWeek.getDay()
    let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
    let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 50))
    let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

    export default {
        name: "ConversionReport1Index",
        data() {
            return {
                search: '',
                loadings: {
                    fetch_data: false,
                },
                date_modal: false,
                filter: {
                    date_range: [
                        dateFormat(lastMonday, "yyyy-mm-dd"),
                        dateFormat(lastSunday, "yyyy-mm-dd")
                    ],
                    country: false
                },
                urls: [],
                countries: [],
                table: {
                    headers: [
                        {text: 'Sub ID 4', value: 'sub_id_4', class: 'text-left'},
                        {text: 'imp', value: 'imp', class: 'text-left'},
                        {text: 'clicks', value: 'clicks', class: 'text-left'},
                        {text: 'Sub ID 1', value: 'sub_id_1', class: 'text-left'},
                        {text: 'Sub ID 2', value: 'sub_id_2', class: 'text-left'},
                        {text: 'Sub ID 5', value: 'sub_id_5', class: 'text-left'},
                        {text: 'SUM of clicks', value: 'sum_of_clicks', class: 'text-center'},
                        {text: 'SUM of clicks (unique)', value: 'sum_of_clicks_unique', class: 'text-center'},
                    ],
                    items: [],
                },

            }
        },
        components: {},
        methods: {
            fetch_countries() {
                let self = this;

                if (self.id_project !== null && self.id_project !== undefined) {
                    self.update_loading('fetch_countries', true)

                    let url = `${self.$store.state.server_url}/analytics/commercial-pages/countries/json/${self.id_project}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': this.$store.state.auth.token
                        }
                    };

                    let data = {}

                    axios.post(url, data, config)
                        .then(function (response) {
                            self.countries = response.data
                            self.update_loading('fetch_countries', false)
                        }).catch((error) => {
                        self.update_loading('fetch_countries', false)
                        alert(error)
                    });
                }
            },
            fetch_data() {
                let self = this;

                if (self.id_project !== null && self.id_project !== undefined) {
                    self.update_loading('fetch_data', true)

                    let url = `${self.$store.state.server_url}/analytics/conversion/report1/${self.id_project}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': this.$store.state.auth.token
                        }
                    };

                    let data = {filter: self.filter}

                    axios.post(url, data, config)
                        .then(function (response) {
                            self.table.items = response.data;
                            self.update_loading('fetch_data', false)
                        }).catch((error) => {
                        self.update_loading('fetch_data', false)
                        alert(error)
                    });
                }
            },
            update_loading(type, value) {
                this.$store.state.loading = false
                this.loadings[type] = value
                for (let i in this.loadings) {
                    if (this.loadings[i] === true) {
                        this.$store.state.loading = true
                    }
                }
            },
            update_setting() {
                this.$store.dispatch('update_ls_serp_setting', this.ls_settings)
            }
        },
        computed: {
            isset_active_project() {
                return this.id_project !== null && this.id_project !== undefined
            },
            id_project() {
                return this.$store.state.project.active_project
            }
        },
        created() {
            let self = this;

            self.fetch_countries()
            self.fetch_data()
        },
    }
</script>

<style scoped>

</style>