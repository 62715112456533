<template>
  <td>
    <div :class="`colored-td ${getColor}`">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            {{ getValue }}
          </span>
        </template>
        <div>
          <div
            v-for="(item, idx) in data.main_keywords.items"
            :key="item + idx"
          >
            {{ item }}
          </div>
        </div>
      </v-tooltip>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  data: () => ({
    CREATING_CW_TASK: "creating cw task",
  }),
  computed: {
    getValue() {
      const keyword = String(this.data.main_keywords.text);

      if (this.data.creating_cw_task_passed && keyword === "0") {
        return "0";
      }
      return keyword === '0' ? '' : keyword;
    },
    getColor() {
      const keyword = String(this.data.main_keywords.text);

      // красный - если прошли степ CREATING CW TASK

      if (this.data.creating_cw_task_passed && keyword === "0") return "red";

      return "transparent";
    },
  },
};
</script>
