<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text v-if="data.data[0]">
      <apexchart
        type="bar"
        height="350"
        :options="getOptions()"
        :series="data.data[0].series"
      />
    </v-card-text>
    <v-card-text v-else>
      <no-items-placeholder />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  methods: {
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          animations: {
            enabled: false,
          },
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: this.data.data[0].categories,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "right",
          offsetX: 0,
          offsetY: 50,
        },
      };
    },
  },
};
</script>
