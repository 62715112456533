<template>
  <v-card>
    <div id="chart-gi"></div>
  </v-card>
</template>

<script>
import axios from "axios";
import ApexCharts from "apexcharts";


export default {
  name: "GoogleIndex",
// mixins: [OnePageAnalyzerIndex],
  props: ['filter', 'url_full'],
  data() {
    return {
      options: {
        chart: {
          height: 200,
          type: 'donut',
        },
        title: {
          text: 'GI',
          align: 'left'
        },
        labels: [],
        dataLabels: {
          formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex]
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
        }
      },
    }
  },
  methods: {
    drawChart(id, options) {
      document.querySelector(`#${id}`).innerHTML = ''

      let chart = new ApexCharts(document.querySelector(`#${id}`), options);

      chart.render();
    },
    fetchData() {
      let self = this

      let url = `${self.$store.state.server_url}/ltt/link-url-control/chart`

      let config = {
        params: {
          url: self.url_full,
          type: 'gi',
          filter: self.filter
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios
          .get(url, config)
          .then((r) => {
            let options = self.options

            options.labels = r.data.labels
            options.colors = r.data.colors
            options.series = r.data.series

            self.drawChart('chart-gi', options)
          })
    },
  },
  created() {
    let self = this

    self.fetchData()

  },
}
</script>

<style scoped>

</style>