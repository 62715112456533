<template>
  <td>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <router-link
            :to="{ name: 'site-analysis', query: { domain: data.donor.text } }"
            target="_blank"
          >
            {{ data.ah_dr }}
          </router-link>
        </div>
      </template>
      <span>View info by site</span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: ["data"],
};
</script>
