<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfig.pageTitle"
          :breadcrumbs="pageConfig.breadcrumbs"
        ></page-header>
      </v-col>
      <v-col cols="12">
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            hide-details
            dense
            outlined
            placeholder="Type to search"
            append-icon="mdi-magnify"
            style="max-width: 320px"
          />
          <v-spacer />
          <div class="d-flex" style="gap: 0.25rem">
            <v-btn
              color="primary"
              class="text-normal"
              height="40"
              @click="isAddPromptDialogVisible = true"
            >
              Add prompt <v-icon right small>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <v-data-table
          class="mt-2 border rounded-lg"
          :loading="loadings.dataTable"
          :items="dataTable.items"
          :headers="dataTable.headers"
          :search="searchQuery"
        >
          <template
            v-for="key in dataTable.headers.map((v) => v.value)"
            #[`item.${key}`]="{ value, item }"
          >
            <template v-if="['created_by', 'updated_by'].includes(key)">
              <user-preview
                :email="value.email"
                hide-initials
                :opacity="1"
                :name="value.name"
                :key="key"
              />
            </template>
            <template v-else-if="['niche', 'page_type'].includes(key)">
              {{ value.name }}
            </template>
            <template v-else-if="['created_at', 'updated_at'].includes(key)">
              {{ value ? $moment(value).format("lll") : "" }}
            </template>
            <template v-else-if="key === '_actions'">
              <div :key="key">
                <v-btn
                  icon
                  :loading="loadings.edit === item.id"
                  :disabled="!!loadings.edit"
                  @click="handleEdit(item)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-prompt-dialog
      v-model="isAddPromptDialogVisible"
      @saved="initializeDataTable"
    />
    <edit-prompt-dialog
      :data="editData"
      v-model="isEditDialogVisible"
      @saved="initializeDataTable"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader.vue";
import { pageConfig, PROMPTS_DATA_TABLE_HEADERS } from "./defaults";
import { promptsApiService } from "./services/promptsApiService";
import AddPromptDialog from "./components/AddPromptDialog.vue";
import UserPreview from "../../../components/UI/UserPreview.vue";
import EditPromptDialog from "./components/EditPromptDialog.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

export default {
  components: { EditPromptDialog, UserPreview, AddPromptDialog, PageHeader },

  mixins: [UploadFiltersDataMixin],

  provide() {
    return {
      filtersData: () => this.filtersData,
    };
  },

  data: () => ({
    loadings: {
      dataTable: false,
    },
    filtersData: {
      niches: [],
      page_types: [],
      categories: [],
      intents: [],
    },
    isAddPromptDialogVisible: false,
    isEditDialogVisible: false,
    searchQuery: "",
    dataTable: {
      items: [],
      headers: PROMPTS_DATA_TABLE_HEADERS,
    },
    editData: null,
  }),

  computed: {
    pageConfig: () => pageConfig,
  },

  mounted() {
    this.initializeDataTable();
    this.uploadFiltersData(["niches", "page_types", "categories", "intents"]);
  },

  methods: {
    async handleEdit(tableItem) {
      try {
        this.loadings.edit = tableItem.id;

        const { data } = await promptsApiService().getPrompt(tableItem.id);

        this.editData = data?.data || {};
        this.isEditDialogVisible = true;

        this.$nextTick(() => {
          this.editData = null;
        });
      } catch (e) {
        console.error("Error while loading data table content.", e);
      } finally {
        this.loadings.edit = null;
      }
    },
    async initializeDataTable() {
      try {
        this.loadings.dataTable = true;

        const { data } = await promptsApiService().getPrompts();

        this.dataTable.items = data?.data || [];
      } catch (e) {
        console.error("Error while loading data table content.", e);
      } finally {
        this.loadings.dataTable = false;
      }
    },
  },
};
</script>
