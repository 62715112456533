<template>
  <v-row class="series-tasks-form">
    <v-col cols="12" class="d-flex justify-end align-end">
      <template v-for="settingKey in Object.keys(settings)">
        <v-chip
          v-if="getSettingDescription(settingKey) && settings[settingKey]"
          :key="settingKey"
          close
          label
          class="mr-2"
          title="Enabled setting"
          @click:close="handleClickCloseSettingChip(settingKey)"
        >
          {{ getSettingDescription(settingKey) }}
        </v-chip>
      </template>
      <v-spacer />
      <v-chip
        label
        class="d-flex justify-center mr-2 error--text"
        outlined
        @click="handleClearForm"
      >
        Clear form <v-icon right small>mdi-trash-can-outline</v-icon>
      </v-chip>
      <v-menu left offset-y :close-on-content-click="false">
        <template #activator="{ on }">
          <v-chip v-on="on" outlined label class="px-2">
            <v-icon small>mdi-cog</v-icon>
            <v-icon small>mdi-chevron-down</v-icon>
          </v-chip>
        </template>
        <v-card width="360" class="styled-card--default">
          <v-card-title class="d-flex"> Settings </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  label="Dense list"
                  v-model="settings.isDenseList"
                  hide-details
                  dense
                  class="ma-0"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  label="Use tags preset"
                  v-model="settings.isUseTagsPreset"
                  hide-details
                  dense
                  class="ma-0"
                />
              </v-col>
              <v-slide-y-transition>
                <v-col v-if="settings.isUseTagsPreset" cols="12">
                  <v-autocomplete
                    v-model="settings.tagsPreset"
                    multiple
                    :items="filtersData.tags && filtersData.tags.items"
                    :item-value="
                      (filtersData.tags && filtersData.tags.value) || 'value'
                    "
                    :item-text="
                      (filtersData.tags && filtersData.tags.text) || 'text'
                    "
                    label="Tags"
                    chips
                    deletable-chips
                    clearable
                    hide-details
                    prepend-inner-icon="mdi-tag-outline"
                    dense
                    filled
                  />
                </v-col>
              </v-slide-y-transition>
              <v-col cols="12">
                <v-checkbox
                  label="Use deadline preset"
                  v-model="settings.isUseDeadlinePreset"
                  hide-details
                  dense
                  class="ma-0"
                />
              </v-col>
              <v-slide-y-transition>
                <v-col v-if="settings.isUseDeadlinePreset" cols="12">
                  <v-menu open-on-hover max-width="290">
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="settings.deadlinePreset"
                        label="Deadline"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        filled
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="settings.deadlinePreset">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-slide-y-transition>
              <v-col cols="12" class="d-flex align-center">
                <v-checkbox
                  label="Use postfix"
                  v-model="settings.isUsePostfix"
                  hide-details
                  dense
                  class="ma-0"
                />
                <v-menu
                  bottom
                  offset-y
                  content-class="my-shadow--e2"
                  :close-on-content-click="false"
                >
                  <template #activator="{ on }">
                    <v-icon v-on="on" class="ml-2"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <v-card width="520" class="styled-card--default pa-4">
                    After you save the tasks, their titles will change.

                    <v-card class="styled-card mt-2">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th>Values</th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div>Task name: <b>My task title</b></div>
                              <div>Postfix: <b>My postfix</b></div>
                            </td>
                            <td>My task title [My postfix]</td>
                          </tr>
                          <tr>
                            <td>
                              <div>Task name: <b>Meeting with Daniel</b></div>
                              <div>Postfix: <b>1-to-1</b></div>
                            </td>
                            <td>Meeting with Daniel [1-to-1]</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                    <v-alert
                      border="left"
                      text
                      dense
                      icon="mdi-information-outline"
                      type="info"
                      class="mt-2 mb-0"
                    >
                      The titles are changed after the tasks are saved.
                    </v-alert>
                  </v-card>
                </v-menu>
              </v-col>
              <v-slide-y-transition>
                <v-col v-if="settings.isUsePostfix" cols="12">
                  <v-text-field
                    v-model="settings.postfix"
                    label="Postfix"
                    filled
                    clearable
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-slide-y-transition>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-col>

    <template v-for="(task, i) in form">
      <v-col :key="task.id" cols="12" class="form-col">
        <v-row
          dense
          :class="`task-form flex-nowrap ${
            activeTaskId === task.id ? 'active-task' : ''
          }`"
        >
          <v-col
            :style="`flex-basis: ${50 * task.level || 1}px`"
            class="d-flex align-center justify-end"
          >
            <v-btn
              v-if="i !== 0 && task.level === 1"
              icon
              @click="handleIncreaseSubtaskLevelLevel(task, i)"
              title="Make subtask"
              class="form-level-btn"
            >
              <v-icon>mdi-format-horizontal-align-right</v-icon>
            </v-btn>
            <v-btn
              v-if="i !== 0 && task.level === 2"
              icon
              @click="handleDecreaseSubtaskLevelLevel(task, i)"
              title="Make main task"
              class="form-level-btn"
            >
              <v-icon>mdi-format-horizontal-align-left</v-icon>
            </v-btn>
          </v-col>
          <v-col style="flex-basis: 100%" class="task-form__body">
            <v-row dense>
              <v-col cols="4" @click="setActiveTask(task.id)">
                <v-autocomplete
                  v-model="task.project_id"
                  :items="filtersData.projects && filtersData.projects.items"
                  :item-value="
                    (filtersData.projects && filtersData.projects.value) ||
                    'value'
                  "
                  :item-text="
                    (filtersData.projects && filtersData.projects.text) ||
                    'text'
                  "
                  clearable
                  placeholder="WS Project"
                  hide-details
                  prepend-inner-icon="mdi-folder-outline"
                  dense
                  filled
                />
              </v-col>
              <v-col cols="8" class="d-flex" @click="setActiveTask(task.id)">
                <div style="width: 100%" class="d-flex">
                  <v-text-field
                    v-model="task.task_title"
                    placeholder="Task name"
                    hide-details
                    dense
                    filled
                    @change="handleChangeTaskName($event, task)"
                  >
                  </v-text-field>

                  <div
                    v-if="validate(task).length > 0"
                    class="d-flex justify-center align-center"
                    style="width: 52px"
                  >
                    <v-menu
                      bottom
                      offset-y
                      content-class="my-shadow--e3"
                      max-width="360"
                    >
                      <template #activator="{ on }">
                        <v-icon v-on="on" color="error">
                          mdi-alert-outline
                        </v-icon>
                      </template>
                      <v-card class="styled-card--default">
                        <v-card-title> Errors </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row>
                            <v-col
                              v-for="(error, i) in validate(task)"
                              :key="i"
                              cols="12"
                            >
                              <v-icon small color="error" class="mr-2"
                                >mdi-alert-outline</v-icon
                              >
                              {{ error.message }}
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <v-menu bottom offset-y>
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      label
                      large
                      dark
                      :color="getPriorityColor(task.priority)"
                      class="d-flex justify-center ml-1"
                      style="width: 40px; height: 40px"
                      @click="setActiveTask(task.id)"
                    >
                      <template v-if="task.priority === 0">
                        <v-icon small> mdi-pause </v-icon>
                      </template>
                      <template v-else>
                        {{ task.priority }}
                      </template>
                    </v-chip>
                  </template>
                  <v-card class="styled-card--default" width="400">
                    <v-card-title> Select priority </v-card-title>
                    <v-divider />
                    <v-card-text
                      class="d-flex"
                      style="gap: 8px; flex-wrap: wrap"
                    >
                      <v-chip
                        v-for="priority in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                        :key="priority"
                        large
                        style="width: 52px; flex-basis: 52px"
                        :color="getPriorityColor(priority)"
                        class="d-flex justify-center align-center"
                        label
                        dark
                        @click="task.priority = priority"
                      >
                        <template v-if="priority === 0">
                          <v-icon small> mdi-pause </v-icon>
                        </template>
                        <template v-else>
                          {{ priority }}
                        </template>
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
            <v-slide-y-transition>
              <v-row
                v-if="!settings.isDenseList || activeTaskId === task.id"
                dense
              >
                <v-col @click="setActiveTask(task.id)">
                  <v-autocomplete
                    v-model="task.reporter"
                    placeholder="Responsible"
                    :items="
                      filtersData.responsible && filtersData.responsible.items
                    "
                    :item-value="
                      (filtersData.responsible &&
                        filtersData.responsible.value) ||
                      'value'
                    "
                    :item-text="
                      (filtersData.responsible &&
                        filtersData.responsible.text) ||
                      'text'
                    "
                    clearable
                    hide-details
                    prepend-inner-icon="mdi-account-outline"
                    dense
                    filled
                  />
                </v-col>
                <v-col @click="setActiveTask(task.id)">
                  <v-autocomplete
                    v-model="task.tags"
                    :items="filtersData.tags && filtersData.tags.items"
                    :item-value="
                      (filtersData.tags && filtersData.tags.value) || 'value'
                    "
                    :item-text="
                      (filtersData.tags && filtersData.tags.text) || 'text'
                    "
                    placeholder="Tags"
                    multiple
                    clearable
                    hide-details
                    prepend-inner-icon="mdi-tag-outline"
                    dense
                    filled
                  >
                    <template #prepend-item>
                      <v-list-item>
                        <small><b>Selected labels</b></small>
                      </v-list-item>
                      <div
                        v-if="task.tags.length > 0"
                        class="d-flex flex-wrap px-4 pb-4"
                        style="gap: 8px; max-width: 260px"
                      >
                        <v-chip
                          v-for="tag in task.tags"
                          small
                          close
                          :key="tag"
                          @click:close="removeTagFrom(task, tag)"
                        >
                          {{ getTagData(tag).title }}
                        </v-chip>
                      </div>
                      <div v-else class="px-4 pb-2">
                        <small style="opacity: 0.5">No selected labels</small>
                      </div>
                      <v-divider />
                    </template>
                    <template #selection="{ item, items, index }">
                      <span v-if="index === 0">{{
                        getLastSelectedTag(task.tags).title
                      }}</span>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption ml-1"
                      >
                        (+{{ task.tags.length - 1 }} more)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col @click="setActiveTask(task.id)">
                  <v-menu width="290px" content-class="my-shadow--e3">
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-text-field
                          v-model="task.date"
                          placeholder="Deadline"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          filled
                          clearable
                          hide-details
                          dense
                        ></v-text-field>
                      </div>
                    </template>
                    <v-date-picker v-model="task.date" no-title>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-row dense>
                    <v-col @click="setActiveTask(task.id)">
                      <v-text-field
                        v-model="task.estimated_time"
                        placeholder="Time"
                        clearable
                        hide-details
                        prepend-inner-icon="mdi-clock-time-eight-outline"
                        dense
                        filled
                      />
                    </v-col>
                    <v-col @click="setActiveTask(task.id)">
                      <v-text-field
                        v-model="task.estimated_money"
                        placeholder="Money"
                        clearable
                        hide-details
                        prepend-inner-icon="mdi-currency-usd"
                        dense
                        filled
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-slide-y-transition>
            <v-slide-y-transition>
              <v-row v-if="activeTaskId === task.id" dense>
                <v-col cols="12">
                  <tiptap-vuetify
                    class="my-tiptap-editor"
                    v-model="task.task_desc"
                    placeholder="Task description"
                    :extensions="extensions"
                  />
                </v-col>
                <v-col v-if="false" cols="12" class="d-flex justify-end">
                  <v-chip
                    label
                    class="flat-chip--error"
                    @click="handleRemoveSelectedTask"
                  >
                    Remove selected task
                    <v-icon small right>mdi-trash-can-outline</v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </v-slide-y-transition>
          </v-col>
          <v-col style="flex-basis: 50px" class="d-flex align-center">
            <v-btn icon @click="handleRemoveTaskById(task.id)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!settings.isDenseList" :key="task.id + i" cols="12">
        <v-divider />
      </v-col>
    </template>

    <v-col v-if="false" cols="12" class="d-flex justify-center">
      <v-chip label class="flat-chip--primary" @click="handleClickAddNewTask">
        Add new task <v-icon right small>mdi-plus</v-icon>
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
/*eslint-disable*/
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";

import { deepClone, uid } from "@/utils/functions";
import { DEFAULT_MOMENT_DATE_FORMAT } from "@/utils/defaultData";

export default {
  components: { TiptapVuetify },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    loadings: Array,
    errors: Array,
    filtersData: {
      type: Object,
      default: () => ({}),
    },
    localStorageId: {
      type: String,
      default: "series-tasks-data",
    },
    parentId: {},
  },
  emits: ["update:modelValue", "clearForm"],
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],

    settingDescriptions: {
      isUseTagsPreset: "Use tags preset",
      isUseDeadlinePreset: "Use deadline preset",
      isDenseList: "Dense list",
      deadlinePreset: "",
      responsiblePreset: "",
      tagsPreset: "",
    },
    settings: {
      isUseTagsPreset: true,
      isUsePostfix: false,
      isUseDeadlinePreset: true,
      isDenseList: true,
      deadlinePreset: null,
      responsiblePreset: null,
      tagsPreset: [],
      postfix: null,
    },
    lsSettingsId: "series-task-form-settings",
    activeTaskId: null,
    keywords: {
      ссылк: "Links",
      ссылок: "Links",
      link: "Links",
      p200: "Links",
      "project 200": "Links",
      project200: "Links",
      донор: "Links",
      donor: "Links",
      pbn: "Links",
      пбн: "Links",
      "guest post": "Links",
      gp: "Links",
      forum: "Links",
      форум: "Links",
      comments: "Links",
      спорнсор: "Links",
      морд: "Links",
      "main page": "Links",
      гест: "Links",
      sponsor: "Links",

      воркплейс: "Content",
      workplace: "Content",
      "meta tag": "Content",
      "мета тег": "Content",
      onpage: "Content",
      "on page": "Content",
      content: "Content",
      вывод: "Content",
      висновок: "Content",
      контент: "Content",
      текст: "Content",
      text: "Content",
      semantic: "Content",
      семант: "Content",
      snippet: "Content",
      снипет: "Content",
      сніпет: "Content",
      introduction: "Content",
      вступ: "Content",
      нтродакш: "Content",

      грош: "Money $",
      деньг: "Money $",
      money: "Money $",
      financ: "Money $",

      стратег: "Strategic",
      анализ: "Strategic",
      аналіз: "Strategic",
      analyz: "Strategic",
      analys: "Strategic",
      шаблон: "Strategic",
      template: "Strategic",
      strateg: "Strategic",
      план: "Strategic",
      plan: "Strategic",
      learn: "Strategic",
      изучит: "Strategic",
      вивч: "Strategic",

      "pages info": "Technical",
      redirect: "Technical",
      редирект: "Technical",
      редірект: "Technical",

      301: "Technical",
      302: "Technical",
      404: "Technical",
      500: "Technical",
      403: "Technical",
      техн: "Technical",
      tech: "Technical",

      конверс: "Conversion",
      conversion: "Conversion",
      partner: "Conversion",
      партнер: "Conversion",

      offer: "Conversion",
      офер: "Conversion",
      конверш: "Conversion",
      бренд: "Conversion",
      brand: "Conversion",
      "main table": "Conversion",
      bonus: "Conversion",
      бонус: "Conversion",
      affiliate: "Conversion",
      афили: "Conversion",
      афілі: "Conversion",

      follow: "Docs",
      фолоу: "Docs",

      митинг: "Meeting",
      мітинг: "Meeting",
      meeting: "Meeting",

      срочно: "Critical",
      термінов: "Critical",
      критичн: "Critical",
      critical: "Critical",
      важно: "Critical",
      важливо: "Critical",

      bug: "Bug",
      баг: "Bug",

      "need review": "⇵ need review",
      фидбек: "⇵ need review",
      фідбек: "⇵ need review",
      feedback: "⇵ need review",
      check: "⇵ need review",
      проверить: "⇵ need review",
      перевірит: "⇵ need review",

      "need details": "! need details",
      детал: "! need details",
      уточн: "! need details",

      кпи: "People #",
      кпі: "People #",
      kpi: "People #",
    },
  }),
  created() {
    try {
      const lStorageSettings = window.localStorage.getItem(this.lsSettingsId);

      if (lStorageSettings && lStorageSettings !== "undefined") {
        this.settings = JSON.parse(lStorageSettings);
      }
    } catch {
      console.error(
        "cant parse settings from local storage in series-task-form"
      );
    }

    this.settings.deadlinePreset = this.$moment().format(
      DEFAULT_MOMENT_DATE_FORMAT
    );

    if (this.form && this.form.length === 0) {
      this.form = [deepClone(this.getTaskPreset())];
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        window.localStorage.setItem(this.lsSettingsId, JSON.stringify(value));
        this.$emit("settings", value);
      },
    },
  },
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    validate(task) {
      let errors = [];

      if (!task.date) {
        errors.push({
          message: "Deadline not assigned",
        });
      }

      if (!task.reporter) {
        errors.push({
          message: "Responsible not assigned",
        });
      }

      if (!task.project_id) {
        errors.push({
          message: "Project not assigned",
        });
      }

      return errors;
    },
    handleIncreaseSubtaskLevelLevel(taskData, idxOfCurrentTask) {
      if (taskData.level >= 2) {
        taskData.level = 2;
      } else {
        taskData.level++;
      }

      for (let i = idxOfCurrentTask; i < this.form.length; i++) {
        const formItem = this.form[i];

        if (!formItem.task_title) {
          this.form[i].level = taskData.level;
        } else {
          const isCurrentTask = idxOfCurrentTask === i;
          if (isCurrentTask) {
            continue;
          } else {
            break;
          }
        }
      }
    },
    handleDecreaseSubtaskLevelLevel(taskData, idxOfCurrentTask) {
      if (taskData.level <= 0) {
        taskData.level = 0;
      } else {
        taskData.level--;
      }

      for (let i = idxOfCurrentTask; i < this.form.length; i++) {
        const formItem = this.form[i];

        if (!formItem.task_title) {
          this.form[i].level = taskData.level;
        } else {
          const isCurrentTask = idxOfCurrentTask === i;
          if (isCurrentTask) {
            continue;
          } else {
            break;
          }
        }
      }
    },
    getLastSelectedTag(tags) {
      if (!tags || (Array.isArray(tags) && tags.length === 0)) return {};

      const lastSelectedTagId = tags[tags.length - 1];

      return this.getTagData(lastSelectedTagId);
    },
    handleClickCloseSettingChip(settingKey) {
      if (settingKey === "isUseTagsPreset")
        this.settings.isUseTagsPreset = false;
      if (settingKey === "isUseDeadlinePreset")
        this.settings.isUseDeadlinePreset = false;
      if (settingKey === "isDenseList") this.settings.isDenseList = false;
    },
    getSettingDescription(settingKey) {
      return this.settingDescriptions[settingKey];
    },
    removeTagFrom(taskData, tagId) {
      try {
        taskData.tags = taskData.tags.filter((v) => v !== tagId);
      } catch {
        return null;
      }
    },
    getTagData(tagId) {
      try {
        return this.filtersData.tags.items.find((v) => v.id === tagId) || {};
      } catch {
        return {};
      }
    },
    getTaskPreset(reset = false) {
      const lastSelectedResponsible =
        this.form[this.form.length - 1] &&
        this.form[this.form.length - 1].reporter;
      const lastSelectedPriority =
        this.form[this.form.length - 1] &&
        this.form[this.form.length - 1].priority;
      const lastSelectedProject =
        this.form[this.form.length - 1] &&
        this.form[this.form.length - 1].project_id;
      const lastSelectedLevel = reset
        ? 1
        : this.form[this.form.length - 1] &&
          this.form[this.form.length - 1].level;

      return {
        id: uid(),
        parent_task_id: 0,
        level: lastSelectedLevel || 1,
        project_id: lastSelectedProject || null,
        task_title: null,
        task_desc: null,
        priority: lastSelectedPriority || 8,
        tags:
          this.settings.isUseTagsPreset && this.settings.tagsPreset.length > 0
            ? this.settings.tagsPreset
            : [],
        reporter:
          this.settings.responsiblePreset || lastSelectedResponsible || null,
        date:
          (this.settings.isUseDeadlinePreset && this.settings.deadlinePreset) ||
          null,
        estimated_time: null,
        estimated_money: null,
      };
    },
    handleRemoveSelectedTask() {
      this.form = this.form.filter((v) => {
        return v.id !== this.activeTaskId;
      });
    },
    handleRemoveTaskById(id) {
      this.form = this.form.filter((v) => {
        return v.id !== id;
      });

      if (this.form.length === 0) this.addToFormItems(1);
    },
    handleChangeTaskName(event, task) {
      if (!event) return;
      const matches = [];

      const words = event.match(/[\wа-яёА-ЯЁ]+/gi);

      Object.entries(this.keywords).forEach(([key, value]) => {
        const regex = new RegExp(`(${key}[(a-zA-Z)|(а-яёА-ЯЁ)]*)`, "gi");

        for (const word of words) {
          if (word.match(regex)) {
            matches.push(value);
            break;
          }
        }
      });

      const tags =
        (this.filtersData &&
          this.filtersData.tags &&
          this.filtersData.tags.items) ||
        [];

      matches.forEach((tagName) => {
        tags.forEach((tagData) => {
          if (!tagData) return;
          const fixedName = String(tagData.title).toLocaleLowerCase();

          if (fixedName === tagName.toLowerCase()) {
            task.tags = [...task.tags, tagData.id];
            task.tags = [...new Set(task.tags)];
          }
        });
      });
    },
    handleClearForm() {
      if (!window.confirm("Are you sure you want to clear the form?")) return;

      this.$emit("clearForm");

      this.form = [deepClone(this.getTaskPreset(true))];
    },
    getPriorityColor(value) {
      const darkTheme = this.$vuetify.theme.dark;
      if (value === 0 && darkTheme) return "#566472";
      if (value === 1 && darkTheme) return "#425161";
      if (value === 2 && darkTheme) return "#7d96ad";
      if (value === 3 && darkTheme) return "#659cac";
      if (value === 4 && darkTheme) return "#4f9691";
      if (value === 5 && darkTheme) return "#8ea018";
      if (value === 6 && darkTheme) return "#c0a018";
      if (value === 7 && darkTheme) return "#c08418";
      if (value === 8 && darkTheme) return "#c06318";
      if (value === 9 && darkTheme) return "#c04718";
      if (value === 10 && darkTheme) return "#b72218";
      if (value === 0) return "#5c85b0";
      if (value === 1) return "#4477ad";
      if (value === 2) return "#6cabe3";
      if (value === 3) return "#5bc2e1";
      if (value === 4) return "#52d3c9";
      if (value === 5) return "#c0da10";
      if (value === 6) return "#ecc20b";
      if (value === 7) return "#e59a0b";
      if (value === 8) return "#d56810";
      if (value === 9) return "#d5440d";
      if (value === 10) return "#da1d10";
      return "";
    },
    addToFormItems(amount) {
      for (let i = 0; i < amount; i++) {
        this.form.push(this.getTaskPreset());
      }
    },
    handleClickAddNewTask() {
      this.addToFormItems(1);
    },
    checkIsTaskDidntFilledUp(taskId) {
      const taskData = this.form.find((v) => v.id === taskId);
      const currentTaskIdx = this.form.findIndex((v) => v.id === taskId);

      if (!currentTaskIdx) return;

      if (!taskData.project_id) {
        taskData.project_id = this.form[currentTaskIdx - 1].project_id;
      }
      if (!taskData.reporter) {
        taskData.reporter = this.form[currentTaskIdx - 1].reporter;
      }
    },
    setActiveTask(taskId) {
      const isLastItem =
        this.form.findIndex((v) => v.id === taskId) === this.form.length - 1;

      if (isLastItem) this.addToFormItems(1);

      this.checkIsTaskDidntFilledUp(taskId);

      this.activeTaskId = taskId;
    },
  },
};
</script>

<style lang="scss">
.series-tasks-form {
  & .task-form {
    &__body {
      transition: 150ms ease-in-out;
      border: 2px solid transparent;
      border-radius: 8px;
    }
    &.active-task {
      .task-form__body {
        border: 2px solid rgba(0, 139, 255, 0.58);
      }
    }
  }
}
.form-col {
  .form-level-btn {
    transition: 150ms ease-in-out;
    opacity: 0;
  }
  &:hover {
    .form-level-btn {
      opacity: 1;
    }
  }
}
</style>
