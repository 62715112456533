<template>
  <div>
    <v-container
      fluid
      class="pa-0 user-activity-static"
      style="padding-bottom: 200px"
    >
      <v-row dense>
        <v-col cols="12">
          <h2>User Activity - Static</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card elevation="0">
            <v-card-title class="pa-0">
              <v-row dense>
                <v-col>
                  <smart-date-filter
                    :model-value="filter.dateRange"
                    @update:modelValue="handleDateChange"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    dense
                    outlined
                    v-model="filter.userID"
                    :items="user_items"
                    label="User"
                    hide-details
                    multiple
                    clearable
                    @change="_$handleFilterChange($event, 'userID')"
                  >
                    <template #selection="{ item, index }">
                      <span v-if="index === 0">{{ item.text }}</span>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.userID.length - 1 }} more)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="filter.site"
                    color="gray"
                    label="Site"
                    outlined
                    :items="_$getProjects"
                    item-value="id"
                    item-text="name"
                    dense
                    multiple
                    clearable
                    hide-details
                    @change="_$handleFilterChange($event, 'site')"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col></v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    block
                    @click="fetchData"
                    color="primary"
                    style="height: 40px"
                    :loading="loadings.tables"
                  >
                    Apply
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        dense
        class="user-activity__tables"
        style="position: relative; min-height: 100px"
      >
        <loader :show="loadings.tables" />
        <v-col cols="12">
          <v-row dense v-if="gpm_mistakes.length > 0">
            <v-col>
              <v-card :loading="loading">
                <v-card-title> GPM Mistakes </v-card-title>
                <v-card-text style="position: relative">
                  <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="gpm_mistakes"
                    class="elevation-1"
                    style="position: relative"
                    hide-default-footer
                    hide-default-header
                    :items-per-page="-1"
                  >
                    <template #header="{ props }">
                      <custom-header :props="props" :filters="filter" />
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td v-for="key in Object.keys(item)" :key="key">
                            <template v-if="key === 'name' || key === 'all'">
                              {{ item[key] }}
                            </template>
                            <template v-else>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn text v-on="on">
                                    {{ item[key][0] }}
                                  </v-btn>
                                </template>
                                <span>
                                  <div
                                    v-for="(errorKey, index) in Object.keys(
                                      item[key][1]
                                    )"
                                    :key="index"
                                  >
                                    {{ errorKey }} —
                                    {{ item[key][1][errorKey] }}
                                  </div>
                                </span>
                              </v-tooltip>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-if="links_problem_items.length > 0">
            <v-col>
              <v-card :loading="loading">
                <v-card-title> Anchors plan (mistakes) </v-card-title>
                <v-card-text>
                  <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="links_problem_items"
                    class="elevation-1"
                    hide-default-footer
                    hide-default-header
                    :items-per-page="-1"
                  >
                    <template #header="{ props }">
                      <custom-header :props="props" :filters="filter" />
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item">
                          <td
                            v-for="(h, idx) in headers"
                            :key="h"
                            :class="`${
                              ![0, 1].includes(idx) ? 'text-center' : ''
                            }`"
                          >
                            <span v-if="item['p' + h.value.replace('d', '')]">
                              <span
                                @click="
                                  openProblems(
                                    item['p' + h.value.replace('d', '')]
                                  )
                                "
                                >{{ item[h.value] }}</span
                              >
                            </span>
                            <span v-else>{{ item[h.value] }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <section>
            <h2 v-if="haveContentData">Content</h2>
            <v-row dense v-if="cb_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> ContentBall </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="cb_items"
                      class="elevation-1"
                      hide-default-footer
                      :items-per-page="-1"
                      hide-default-header
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="site_workplace_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Site Workplace </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="site_workplace_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                      <template #body="{ items }">
                        <tbody>
                          <tr v-for="(row, index) in items" :key="index">
                            <td class="text-start">{{ row.name }}</td>
                            <td class="text-center">{{ row.all }}</td>
                            <td
                              v-for="(td, i) in getDateItems(row)"
                              :key="i"
                              class="text-center"
                            >
                              {{ td.value }}<sup>{{ td.degree }}</sup>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="sl_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> SpiderLink </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="sl_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                      <template
                        v-for="key in headers.map((v) => v.value)"
                        #[`item.${key}`]="{ value }"
                      >
                        <span
                          :title="
                            String(value).includes('/') ? 'domains/rows' : ''
                          "
                          :key="key"
                        >
                          {{ value }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <section>
            <h2 v-if="haveLinksData">Links</h2>
            <v-row dense v-if="gpm_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> GPM </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="gpm_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="ltt_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> LTT </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="ltt_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                      <template #body="{ items }">
                        <tbody>
                          <tr
                            v-for="(row, index) in items"
                            :key="index"
                            :class="`${row.name === '%' ? 'marked' : ''}`"
                          >
                            <td>{{ row.name }}</td>
                            <td>
                              <span
                                v-if="row.name.includes('%')"
                                :class="`colored-td bold colored-td--contrast colored-td--inline ${perc2color(
                                  row.all
                                )}`"
                              >
                                {{
                                  row.all || String(row.all) === "0"
                                    ? row.all + " %"
                                    : ""
                                }}
                              </span>
                              <span v-else>
                                {{ row.all }}
                              </span>
                            </td>
                            <td
                              v-for="(td, idx) in getUsers(row)"
                              :key="idx"
                              class="text-center"
                              :style="getBgColor(td, getUsers(row))"
                            >
                              <span
                                v-if="row.name.includes('%')"
                                :class="`colored-td bold colored-td--contrast colored-td--inline ${perc2color(
                                  td
                                )}`"
                              >
                                {{ td || String(td) === "0" ? td + " %" : "" }}
                              </span>
                              <span v-else>
                                {{ td }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="check_link_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Check link </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="check_link_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="anchor_plan_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Anchors plan </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="anchor_plan_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="anchor_plan_x_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> Anchors plan (Х links) </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="anchor_plan_x_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="no_answer_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title>
                    GP Emails Conversion
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-icon v-on="on" color="gray" class="ml-2"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <div>
                        <div class="gp-em-conv-tooltip">
                          <div>ALL</div>
                          <div>
                            <span>NEW</span>
                            <span>LTT</span>
                            <span>RESERVED</span>
                          </div>
                        </div>
                      </div>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="no_answer_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                      <template #body="{ items }">
                        <tbody>
                          <tr v-for="(row, index) in items" :key="index">
                            <td>{{ row.name }}</td>
                            <user-activity-smart-td :data="row.all" />
                            <user-activity-smart-td
                              v-for="(td, idx) in getUsers(row)"
                              :key="idx"
                              :data="td"
                            >
                            </user-activity-smart-td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row dense v-if="tables?.site_analysis_feedback?.length > 0">
              <v-col>
                <v-card class="styled-card--default">
                  <v-card-title> Site analysis feedback </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <v-data-table
                      :items="tables.site_analysis_feedback"
                      :headers="headers"
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense v-if="tables?.site_analysis_likes?.length > 0">
              <v-col>
                <v-card class="styled-card--default">
                  <v-card-title> Site analysis likes </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <v-data-table
                      :items="tables.site_analysis_likes"
                      :headers="headers"
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <section>
            <h2 v-if="haveOthersData">Others</h2>
            <v-row dense v-if="dct_items.length > 0">
              <v-col>
                <v-card :loading="loading">
                  <v-card-title> DCT </v-card-title>
                  <v-card-text>
                    <v-data-table
                      :loading="loading"
                      :headers="headers"
                      :items="dct_items"
                      class="elevation-1"
                      hide-default-footer
                      hide-default-header
                      :items-per-page="-1"
                    >
                      <template #header="{ props }">
                        <custom-header :props="props" :filters="filter" />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <v-row dense v-if="project_200_items.length > 0">
            <v-col>
              <v-card :loading="loading">
                <v-card-title> Project 200 </v-card-title>
                <v-card-text>
                  <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="project_200_items"
                    class="elevation-1"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template
                      v-for="h in headers"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <span :key="h" :class="+header.class">{{
                        header.text
                      }}</span>
                    </template>
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item">
                          <td
                            v-for="(h, idx) in headers"
                            :key="h"
                            :class="`${
                              ![0, 1].includes(idx) ? 'text-center' : ''
                            }`"
                          >
                            <span v-if="item['p' + h.value.replace('d', '')]">
                              <span
                                @click="
                                  openProblems(
                                    item['p' + h.value.replace('d', '')]
                                  )
                                "
                                >{{ item[h.value] }}</span
                              >
                            </span>
                            <span v-else>{{ item[h.value] }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog_problems" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Mistakes</span>
          </v-card-title>
          <v-card-text>
            <p v-for="problem in problems" :key="problem">{{ problem }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_problems = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import Loader from "@/components/Main/Loader";
import CustomHeader from "@/components/UserActivityStatic/CustomHeader";
import UserActivitySmartTd from "@/components/UserActivityStatic/UI/UserActivitySmartTd";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../mixins/Location";
import getRgb from "@/mixins/GetRgb";

const dateFormat = require("dateformat");

export default {
  name: "Index",
  components: {
    SmartDateFilter,
    UserActivitySmartTd,
    Loader,
    CustomHeader,
  },
  mixins: [FiltersHandling, Project, Location, getRgb],
  data() {
    return {
      menu_filter: false,
      dialog_problems: false,
      problems: undefined,
      loading: false,
      loadings: {
        tables: false,
      },
      headers: [],
      tables: {},
      no_answer_items: [],
      gpm_items: [],
      gpm_mistakes: [],
      site_workplace_items: [],
      cb_items: [],
      sl_items: [],
      dct_items: [],
      ltt_items: [],
      anchor_plan_items: [],
      anchor_plan_x_items: [],
      links_problem_items: [],
      check_link_items: [],
      project_200_items: [],
      user_items: [],
      date_modal: false,
      filter: {
        site: null,
        dateRange: [
          dateFormat(
            new Date().setDate(new Date().getDate() - 7),
            "yyyy-mm-dd"
          ),
          dateFormat(new Date(), "yyyy-mm-dd"),
        ],
        userID: undefined,
      },
    };
  },
  computed: {
    haveLinksData() {
      return (
        this.gpm_items.length > 0 ||
        this.ltt_items.length > 0 ||
        this.check_link_items.length > 0 ||
        this.anchor_plan_items.length > 0 ||
        this.anchor_plan_x_items.length > 0 ||
        this.no_answer_items.length > 0
      );
    },
    haveOthersData() {
      return this.dct_items.length > 0;
    },
    haveContentData() {
      return (
        this.cb_items.length > 0 ||
        this.site_workplace_items.length > 0 ||
        this.sl_items.length > 0
      );
    },
  },
  methods: {
    getMax(items = []) {
      let bigger = 0;

      items.forEach((item, idx) => {
        if (idx === 0) return;

        let value = parseInt(item);

        if (typeof item === "string" && item.includes("/"))
          value = item.split("/")[0];

        if (value > bigger) bigger = value;
      });

      return bigger;
    },
    getBgColor(value, items) {
      let output = "background-color:";
      let fixedValue = value;

      if (typeof value === "string" && value.includes("/"))
        fixedValue = value.split("/")[0];

      const color = this.getRgb(fixedValue, this.getMax(items), 50, 233, 153);

      if (color === "transparent") return null;

      return (output += color);
    },
    handleDateChange(event) {
      this.filter.dateRange = event;
      this._$handleFilterChange(event, "dateRange");
    },
    perc2color(perc) {
      if (perc === null || perc === undefined) return "";

      const val = parseInt(perc);

      if (val >= 0 && val <= 20) return "red";
      if (val >= 21 && val <= 60) return "orange";
      if (val >= 61) return "green";
      return "";
    },
    getUsers(data) {
      const output = [];

      Object.keys(data).forEach((key) => {
        if (key !== "name" && key !== "all") output.push(data[key]);
      });

      return output;
    },
    getDateItems(items = {}) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (key !== "name" && key !== "all") {
          let value, degree;

          if (items[key] !== null && items[key] !== undefined) {
            const splitted = items[key].split(" ");
            value = splitted[0];
            degree = splitted[1]
              ? splitted[1].replaceAll("(", "").replaceAll(")", "")
              : "";
          }

          output.push({
            value,
            degree,
          });
        }
      });

      return output;
    },
    openProblems(item) {
      this.problems = item.split(",");
      this.dialog_problems = true;
    },
    fetchUsers() {
      let self = this;

      this.loading = true;

      let url = `${self.$store.state.server_url}/user-activity/get-users`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.user_items = r.data.items;
        })
        .finally(() => (self.loading = false));
    },
    fetchData() {
      let self = this;

      this.loadings.tables = true;

      let url = `${self.$store.state.server_url}/user-activity/index-all`;

      let config = {
        params: {
          userID: self.filter.userID,
          dates: self.filter.dateRange,
          site: self.filter.site,
          is_mock: localStorage.getItem("is_mock") === "1" ? 1 : 0,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.no_answer_items = r.data.data.no_answer;
          self.gpm_items = r.data.data.gpm;
          self.gpm_mistakes = r.data.data.gpm_mistakes;
          self.site_workplace_items = r.data.data.site_workplace;
          self.cb_items = r.data.data.cb;
          self.dct_items = r.data.data.dct;
          self.sl_items = r.data.data.sl;
          self.ltt_items = r.data.data.ltt;
          self.anchor_plan_items = r.data.data.ap;
          self.anchor_plan_x_items = r.data.data.ap_x;
          self.check_link_items = r.data.data.cl;
          self.links_problem_items = r.data.data.links_problem;
          self.project_200_items = r.data.data.project200;
          self.tables = r.data.data;
          self.headers = this.fixHeaders(r.data.data.headers);
        })
        .finally(() => (self.loadings.tables = false));
    },
    fixHeaders(headers) {
      const NAME = 0;

      headers[NAME].width = "150px";

      headers.forEach((item) => {
        if (item.value !== "name") {
          item.align = "center";
          item.sortable = false;
        }
      });

      return headers;
    },
  },
  created() {
    const haveParams = this._$collectParams(
      ["dateRange", "site", "userID"],
      "filter"
    );

    this.fetchUsers();

    if (haveParams) {
      this.fetchData();
    }
  },
};
</script>

<style lang="scss">
.weekend {
  font-weight: 600;
  color: rgba(255, 0, 0, 0.49) !important;
}
.user-activity-static {
  &__tables {
    min-height: 250px;
    position: relative;
    h2 {
      text-align: left;
      font-size: 2em;
    }
    .v-card {
      box-shadow: none !important;
      &__title {
        justify-content: flex-start;
        opacity: 0.8;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      &__text {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
  .v-data-table {
    tr {
      th {
        writing-mode: vertical-lr;
        height: auto !important;
        text-align: start !important;
        &:nth-child(1),
        &:nth-child(2) {
          vertical-align: bottom;
          writing-mode: initial;
        }
      }
    }
    tbody {
      tr {
        &.marked {
          background-color: rgba(141, 162, 241, 0.19);
          font-weight: 600;
          td {
            &:first-child {
              background-color: rgba(141, 162, 241, 0.19) !important;
            }
          }
        }
        td {
          &:nth-child(1) {
            position: sticky;
            left: 0;
            background-color: #fff !important;
            z-index: 1;
          }
          &:nth-child(1),
          &:nth-child(2) {
            width: 150px !important;
            border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
          }
        }
      }
    }
  }
}

.v-application.theme--dark {
  .user-activity-static {
    .v-data-table {
      tbody {
        tr {
          td {
            &:nth-child(1) {
              background-color: #1e1e1e !important;
            }
          }
        }
      }
    }
  }
}
</style>
