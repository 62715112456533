export const NicheItems = [
  { id: 1, name: "essay" },
  { id: 2, name: "casino" },
  { id: 3, name: "porn" },
  { id: 4, name: "replica" },
  { id: 5, name: "review" },
  { id: 6, name: "loan" },
  { id: 7, name: "dating" },
  { id: 8, name: "fortuneteller" },
  { id: 9, name: "sample" },
  { id: 10, name: "automotive" },
];
