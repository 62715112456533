<template>
  <div
    :class="`app-page-navigate ${
      isMenuOpen && (!isNoRecent || !isNoSearchString)
        ? 'app-page-navigate--is-focus'
        : ''
    }`"
  >
    <v-menu
      v-model="isMenuOpen"
      content-class="my-shadow--e2 app-page-navigate__menu"
      bottom
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <div
          v-on="on"
          :class="{
            'app-page-navigate__input': true,
            'my-shadow--e2': isMenuOpen && (!isNoRecent || !isNoSearchString),
          }"
          @click="isMenuOpen = true"
        >
          <v-icon class="magnify-icon">mdi-magnify</v-icon>
          <input
            ref="searchInput"
            v-model="searchString"
            type="text"
            placeholder="Search pages by name, title or path"
          />
          <v-icon
            v-if="!!searchString"
            class="close-icon"
            @click="handleClearSearchText"
            >mdi-close</v-icon
          >
          <v-slide-x-transition>
            <div v-if="!searchString" class="tooltip">
              <div class="keycup">ctrl</div>
              <div class="keycup ml-1">/</div>
            </div>
          </v-slide-x-transition>
        </div>
      </template>
      <v-list
        v-if="!isNoRecent || !isNoSearchString"
        dense
        outlined
        :class="`shadow-e1-bordered app-page-navigate__list${
          isMenuOpen ? ' focused' : ''
        }${isNoRecent && isNoSearchString ? ' pa-0' : ''}`"
      >
        <template
          v-if="
            (typeof searchString === 'string' && searchString.length === 0) ||
            searchString === null
          "
        >
          <v-list-item
            v-for="(item, idx) in recentItems"
            :key="item.title + '' + idx"
            @click="handleGoToPage(item)"
            @click.middle="handleGoToPage(item, true)"
          >
            <v-list-item-icon>
              <v-icon>mdi-history</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="d-flex align-center">
                <div>{{ item.title }}</div>
                <v-spacer />
                <div>
                  <v-icon
                    color="rgba(155,155,155,0.8)"
                    @click="handleDeleteRecent(item)"
                    title="Remove from recent"
                  >
                    mdi-delete-outline
                  </v-icon>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-if="!!searchString && filteredRoutes.length === 0">
          <span style="opacity: 0.5">Not a single match :(</span>
        </v-list-item>

        <v-list-item
          v-for="(route, idx) in filteredRoutes"
          :key="route.name + '' + idx"
          @click="handleGoToPage(route)"
          @click.middle="handleGoToPage(route, true)"
        >
          <template v-if="route.redirectRoute">
            <v-card class="styled-card--default pa-0 my-2" style="width: 100%">
              <div class="pa-2 d-flex">
                <v-chip small class="mr-2">outdated</v-chip>
                <div>
                  {{ route.title || route.name || route.path }}
                </div>
                <v-spacer />
                <route-info-tooltip :route="route" />
              </div>
              <v-divider />
              <div class="pa-2 d-flex">
                <v-chip color="success" small class="mr-2">new</v-chip>
                <div>
                  {{
                    route.redirectRoute.title ||
                    route.redirectRoute.name ||
                    route.redirectRoute.path
                  }}
                </div>
                <v-spacer />
                <route-info-tooltip :route="route.redirectRoute" />
              </div>
            </v-card>
          </template>
          <template v-else>
            <div>
              {{ route.title || route.name || route.path }}
            </div>
          </template>
          <template v-if="!route.redirectRoute">
            <v-spacer />
            <route-info-tooltip :route="route" />
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { CookieHandler, deepClone, isJsonValid } from "../../utils/functions";
import { PAGE_PRIORITY } from "../../utils/defaultData";
import hotkeys from "hotkeys-js";
import routes from "@/utils/routes";
import RouteInfoTooltip from "@/components/UI/RouteInfoTooltip.vue";

export default {
  components: { RouteInfoTooltip },
  data: () => ({
    searchString: null,
    isMenuOpen: false,
    cookiesId: "recent-app-navigate-data",
    recentItems: [],
    routes: [],
    menuProps: {
      maxHeight: "400",
      rounded: "lg",
      transition: "slide-x-transition",
      contentClass: "shadow-e2-bordered remove-scrollbar",
    },
  }),
  created() {
    hotkeys("ctrl+/", () => {
      this.$refs.searchInput.focus();
      this.isMenuOpen = true;
    });

    this.routes = this.getAllRoutes(routes);

    this.updateRecentItems();
  },
  watch: {
    searchString(value) {
      if (!value) return;

      this.isMenuOpen = true;
    },
  },
  computed: {
    isNoSearchString() {
      return !this.searchString;
    },
    isNoRecent() {
      return Array.isArray(this.recentItems) && this.recentItems.length === 0;
    },
    filteredRoutes() {
      if (!this.searchString) return [];

      return this.routes.filter((v) => {
        const text = v.title + v.name + v.path;
        const searchString = String(text).toLowerCase();

        const matchSearchString = searchString.includes(
          String(this.searchString).toLowerCase()
        );

        return matchSearchString;
      });
    },
  },
  methods: {
    handleDeleteRecent(recentItem) {
      this.recentItems = this.recentItems.filter(
        (v) => v.title !== recentItem.title
      );

      this.handleSaveRecentToCookies(this.recentItems);

      this.$message.notification({
        title: "Removed",
        text: "Item successfully removed from recent",
        type: "success",
      });
    },
    updateRecentItems() {
      const cookies = new CookieHandler();

      const recent = cookies.get(this.cookiesId);

      if (recent === undefined || recent === null) return;

      if (!isJsonValid(recent)) return;

      this.recentItems = JSON.parse(recent);
    },
    handleClearSearchText() {
      this.searchString = null;
    },
    fixPriority(page) {
      if (!page) {
        return null;
      }

      if (typeof page.priority === "string" && page.priority.includes(",")) {
        return page.priority.replaceAll(",", "");
      }

      return page.priority;
    },
    async handleGoToPage(route, middleClick = false) {
      try {
        this.handleSaveUsed(route);

        if (middleClick) {
          window.open(route.path, "_blank");
        } else {
          await this.$router.push({ name: route.name });
        }

        this.searchString = null; // String(route.title).trim();
        this.$refs.searchInput.blur();
      } catch (e) {
        console.error(e);
      } finally {
        this.isMenuOpen = false;
      }
    },
    fixTitle(page) {
      try {
        if (!page.meta) return "Page without title";

        const title = page.meta.title;
        if (title && typeof title === "string" && title.includes("|")) {
          return title.split("|")[0];
        }
        return title;
      } catch (e) {
        console.error(
          `Error while getting fixed title. Route data: ${JSON.stringify(
            page,
            null,
            2
          )}`,
          e
        );
      }
    },
    getDataAboutRedirectRoute(route, routes) {
      try {
        const matchString = route.name ? route.name : route;

        return routes.find((route) => route.name === matchString);
      } catch (e) {
        console.error("Error while getting data about redirect route.", e);
      }
    },
    getAllRoutes(routes = []) {
      try {
        const routesArr = [];
        const output = [];

        // collect all routes
        routes.forEach((route) => {
          if (Array.isArray(route.children) && route.children.length > 0) {
            return route.children.forEach((childRoute) => {
              routesArr.push(childRoute);
            });
          }
          routesArr.push(route);
        });

        routesArr.forEach((route) => {
          if (!route.name) return;

          const match = PAGE_PRIORITY.find((item) => item.name === route.name);
          const priority = this.fixPriority(match);
          const title = this.fixTitle(route);

          let redirectRoute;

          if (route.redirect !== undefined) {
            redirectRoute = this.getDataAboutRedirectRoute(
              route.redirect,
              routesArr
            );

            redirectRoute = {
              name: redirectRoute.name,
              path: redirectRoute.path,
              title: this.fixTitle(redirectRoute),
            };
          }

          output.push({
            name: route.name,
            path: route.path,
            title,
            priority: Number(priority),
            redirectRoute,
          });
        });

        output.sort((a, b) => {
          if (a.priority < b.priority) return 1;
          if (a.priority > b.priority) return -1;
          return 0;
        });

        return output;
      } catch (e) {
        console.error("Error while getting fixed routes.", e);
        return [];
      }
    },
    handleSaveRecentToCookies(recentItems) {
      const cookies = new CookieHandler();

      let tmpr = deepClone(recentItems);
      if (recentItems.length > 7) tmpr.pop();

      cookies.set(this.cookiesId, JSON.stringify(tmpr));

      this.updateRecentItems();
    },
    handleSaveUsed(routeItem) {
      const cookies = new CookieHandler();

      const { name, title, path } = routeItem;

      if (typeof title === "string" && title.length === 0) return;

      let recent = cookies.get(this.cookiesId);

      if (recent === undefined || recent === null) {
        recent = [{ title, name, path }];
        return this.handleSaveRecentToCookies(recent);
      }

      recent = isJsonValid(recent) ? JSON.parse(recent) : [];

      const matchIdx = recent.findIndex((v) => v.title === title);
      if (matchIdx !== -1) {
        let element = recent.splice(matchIdx, 1)[0];
        recent.unshift(element);
        return this.handleSaveRecentToCookies(recent);
      }

      if (Array.isArray(recent)) recent.unshift({ title, name, path });
      this.handleSaveRecentToCookies(recent);
    },
  },
};
</script>

<style lang="scss">
.app-page-navigate {
  $class: &;
  & .tooltip {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
  &__menu {
    top: 44px !important;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .v-icon {
    position: absolute !important;
    &.magnify-icon {
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
    &.close-icon {
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }
  &__input {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 420px;
    input {
      display: inline-block;
      height: 40px;
      width: 100%;
      border-radius: 8px;
      padding: 4px 32px 4px 48px;
      border: 1px solid rgba(155, 155, 155, 0.15);
      background-color: #f6f6f6;
      transition: 150ms ease-in-out;
      &::placeholder {
        opacity: 0.5;
        transition: 150ms ease-in-out;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__list {
    border-radius: 8px !important;
    transition: 150ms ease-in-out !important;
    border: 1px solid rgba(155, 155, 155, 0.15) !important;
    max-height: 800px;
    overflow-y: scroll;
    &.focused {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
  &--is-focus {
    #{$class}__input {
      input {
        background-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-color: transparent;
      }
    }
  }
}
.v-application.theme--dark {
  .app-page-navigate {
    $class: &;
    &__input {
      input {
        background-color: #1e1e1e;
        color: #fff;
      }
    }
  }
}
</style>
