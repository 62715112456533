export const NOT_TOP_5_POS_COLORS_RANGE = [
  "#FF0000",
  "#FF0000",
  "#FF0000",
  "#FF0000",
  "#FF0000",
  "#ff5900",
  "#ff6200",
  "#ffae00",
  "#d0ff00",
  "#72c916",
  "#40cc13",
];

export const AHTPIN10_COLORS_RANGE = [
  "#FF0000",
  "#FF3300",
  "#ff6a00",
  "#ffa600",
  "#ffdd00",
  "#aaff00",
  "#b7ff00",
  "#b7ff00",
  "#70dc0b",
  "#41c916",
  "#40cc13",
];

export const INTITLE_COLORS_RANGE = [
  "#FF0000",
  "#40cc13",
  "#b7ff00",
  "#ff7300",
  "#ff5900",
  "#ff4400",
  "#FF3300",
  "#FF0000",
  "#FF0000",
  "#FF0000",
  "#FF0000",
];

export const SITE_ANALYSIS_SECTION_DATA = {
  score: {
    sectionName: "score",
  },
  score2: {
    sectionName: "score2",
  },
  ah_detailed_by_countries: {
    sectionName: "ah_detailed_by_countries",
    sectionTitle: "AH Detailed by country",
  },
  ah_dynamic_traffic_keywords: {
    sectionName: "ah_dynamic_traffic_keywords",
    sectionTitle: "AH Dynamic Traffic Keywords",
  },
  sw_traffic_by_countries: {
    sectionName: "sw_traffic_by_countries",
    sectionTitle: "SW PRO Traffic By Country",
  },
  traffic_by_countries: {
    sectionName: "traffic_by_countries",
    prefix: "sr_",
    sectionTitle: "Traffic By Country",
  },
  sw_traffic_sources: {
    sectionName: "sw_traffic_sources",
    sectionTitle: "SW Traffic Sources",
  },
  sw_total_visits: {
    sectionName: "sw_total_visits",
    sectionTitle: "SW Total Visits",
  },
  sr_dynamic_traffic_keywords: {
    sectionName: "sr_dynamic_traffic_keywords",
    sectionTitle: "SR Dynamic Traffic Keywords",
  },
  dynamic_referring_domains_pages: {
    sectionName: "dynamic_referring_domains_pages",
    prefix: "sr_",
    sectionTitle: "Referring Domains / Pages",
  },
  waiting: {
    sectionName: "waiting",
  },
  top_pages: {
    sectionName: "top_pages",
    prefix: "sr_",
    sectionTitle: "Top Pages",
  },
  sw_keys: {
    sectionName: "sw_keys",
    sectionTitle: "SW Keys",
  },
  tr_topics: {
    sectionName: "tr_topics",
    sectionTitle: "TR Site Topics",
  },
  organic_keywords: {
    sectionName: "organic_keywords",
    prefix: "sr_",
    sectionTitle: "Top keys",
  },
  main_static: {
    sectionName: "main_static",
  },
  tr_categories: {
    sectionName: "tr_categories",
    sectionTitle: "TR Category",
  },
  ah_anchors: {
    sectionName: "ah_anchors",
    sectionTitle: "AH Anchors",
  },
  ah_linked_anchors: {
    sectionName: "ah_linked_anchors",
    sectionTitle: "AH Linked Anchors",
  },
  links_profile: {
    prefix: "sr_",
    sectionName: "links_profile",
    sectionTitle: "SR Links Profile",
  },
  backlinks: {
    prefix: "sr_",
    sectionName: "backlinks",
    sectionTitle: "SR Backlinks",
  },
  age_distribution: {
    prefix: "sw_",
    sectionName: "age_distribution",
    sectionTitle: "Age distribution",
  },
  gender: {
    prefix: "sw_",
    sectionName: "gender",
    sectionTitle: "Gender distribution",
  },
};

export const SITE_ANALYSIS_SECTIONS_INTERFACE = {
  score: [],
  score2: [],
  ah_detailed_by_countries: {},
  ah_dynamic_traffic_keywords: {},
  sw_traffic_by_countries: {},
  traffic_by_countries: {},
  sw_traffic_sources: {},
  sw_total_visits: {},
  sr_dynamic_traffic_keywords: {},
  ah_dynamic_referring_domains_pages: {},
  waiting: {},
  top_pages: {},
  sw_keys: {},
  tr_topics: {},
  ah_organic_keywords: {},
  main_static: {},
  tr_categories: {},
  ah_anchors: {},
  ah_linked_anchors: {},
  sr_links_profile: {},
  sr_backlink: {},
  age_distribution: {},
  gender: {},
};
