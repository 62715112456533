<template>
  <div class="n-ldd__table-manager">
    <template v-if="getType === 'all_links'">
      <new-all-links-table
        :data="data"
        :active-url="activeUrlFilter"
        @note="handleNote"
      />
    </template>
    <template v-else-if="getType === 'default'">
      <div class="n-ldd__table default-table">
        <new-links-dynamic-default-table
          :headers="data.headers"
          :items="data.items"
          :custom-sort="customSortDeeper"
          :active-url="activeUrlFilter"
          :name="cardsData[data.key].name"
        />
      </div>
    </template>
    <template v-else-if="getType === 'vertical'">
      <div class="n-ldd__table vertical-table">
        <new-links-dynamic-vertical-table
          :headers="data.headers"
          :items="data.items"
          :custom-sort="customSort"
          :active-url="activeUrlFilter"
        />
      </div>
    </template>
    <template v-else-if="getType === 'simple'">
      <div class="n-ldd__table simple-table">
        <new-dynamic-dashnoard-simple-table
          :items-per-page="20"
          :data="data"
          :name="cardsData[data.key].name"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { LINKS_DYNAMIC_DASHBOARD_SECTIONS_DATA } from "@/utils/defaultData";
import NewLinksDynamicDefaultTable from "@/components/OnePageAnalyzer/NewLinksDynamicDashboard/UI/NewLinksDynamicDefaultTable";
import NewLinksDynamicVerticalTable from "@/components/OnePageAnalyzer/NewLinksDynamicDashboard/UI/NewLinksDynamicVerticalTable";
import NewDynamicDashnoardSimpleTable from "@/components/OnePageAnalyzer/NewLinksDynamicDashboard/UI/NewDynamicDashnoardSimpleTable";
import NewAllLinksTable from "@/components/OnePageAnalyzer/NewLinksDynamicDashboard/UI/NewAllLinksTable";

export default {
  components: {
    NewAllLinksTable,
    NewDynamicDashnoardSimpleTable,
    NewLinksDynamicVerticalTable,
    NewLinksDynamicDefaultTable,
  },
  props: {
    activeUrlFilter: {},
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    cardsData: LINKS_DYNAMIC_DASHBOARD_SECTIONS_DATA,
  }),
  computed: {
    getType() {
      return this.cardsData[this.data.key].type;
    },
  },
  methods: {
    handleNote(data) {
      this.$emit("note", data);
    },
    customSort(items, sortBy, sortDesc) {
      const text = ["url"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i])) {
            let aVal, bVal;
            if (a[sortBy[i]] === null) aVal = 0;
            if (b[sortBy[i]] === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]];
            const val2 = b[sortBy[i]];
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let aVal, bVal;
          if (!a[sortBy[i]] && a[sortBy[i]] !== 0) aVal = 0;
          if (!b[sortBy[i]] && b[sortBy[i]] !== 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    customSortDeeper(items, sortBy, sortDesc) {
      const text = ["url"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i].toLowerCase())) {
            let aVal, bVal;
            if (a[sortBy[i]] === null) aVal = 0;
            if (b[sortBy[i]] === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]];
            const val2 = b[sortBy[i]];
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          let aVal, bVal;
          if (!a[sortBy[i]].value && a[sortBy[i]].value !== 0) aVal = 0;
          if (!b[sortBy[i]].value && b[sortBy[i]].value !== 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]].value) > parseInt(b[sortBy[i]].value))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]].value) < parseInt(b[sortBy[i]].value))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
};
</script>
