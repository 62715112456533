<template>
  <div v-if="type !== 'none'">
    <template v-if="type === 'text'">
      <v-text-field
        v-model="value"
        type="text"
        filled
        dense
        hide-details
        placeholder="Enter text"
        :label="getLabel"
      ></v-text-field>
    </template>
    <template v-else-if="type === 'link'">
      <v-card-subtitle class="pl-0 pt-0 pb-0">{{ getLabel }}</v-card-subtitle>
      <v-card-text class="pl-0">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="value.text"
              type="text"
              dense
              filled
              hide-details
              placeholder="Enter text"
              label="Text"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="value.link"
              type="text"
              dense
              filled
              hide-details
              placeholder="Enter link"
              label="Link"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template v-else-if="type === 'number'">
      <v-text-field
        v-model="value"
        type="number"
        filled
        dense
        hide-details
        placeholder="Enter number"
        :label="getLabel"
      ></v-text-field>
    </template>
    <template v-else-if="type === 'date'">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="value"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="value"
            label="Picker in menu"
            prepend-inner-icon="mdi-calendar"
            readonly
            filled
            dense
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="value" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(value)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </template>
    <template v-else>
      Not supported type: <b>{{ type }}</b>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {},
  },
  data: () => ({
    type: "none",
    menu: false,
  }),
  computed: {
    getLabel() {
      return this.headers.find((v) => v.value === this.data.key).text;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  created() {
    this.initType();
  },
  methods: {
    initType() {
      const match = this.headers.find((v) => v.value === this.data.key);

      if (!match) return (this.type = "none");

      this.type = match.type;
    },
  },
};
</script>
