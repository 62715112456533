<template>
  <v-card
    v-if="data"
    :class="`styled-card--default ${isChecklistReset ? 'shaking' : ''}`"
  >
    <v-card-title>
      Checklist
      <v-spacer />
      <template v-if="data?.field_data?.manual_links?.length > 0">
        <template v-for="(link, idx) in data?.field_data?.manual_links">
          <a
            :href="link"
            :key="`${link}${idx}`"
            target="_blank"
            class="reset-link-styles mr-2"
          >
            <v-chip label outlined @click="() => {}">
              <v-icon small left>mdi-file-document</v-icon>
              Manual
              <v-icon right x-small>mdi-open-in-new</v-icon>
            </v-chip>
          </a>
        </template>
      </template>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense v-if="Array.isArray(checkboxes) && checkboxes.length > 0">
        <v-col cols="12">
          <v-banner v-model="showBanner" class="pa-0">
            <template #default>
              <div class="d-flex">
                <v-icon left color="info" class="mr-2">
                  mdi-information-box
                </v-icon>
                <div>The list has been reset due to a status change.</div>
              </div>
            </template>

            <template #actions="{ dismiss }">
              <v-chip @click="dismiss" label color="transparent">
                close
              </v-chip>
              <v-chip
                color="transparent"
                class="blue--text"
                label
                @click="handleRestoreListByHistory"
              >
                Revert
              </v-chip>
            </template>
          </v-banner>
        </v-col>
        <v-col v-for="checkbox in checkboxes" :key="checkbox.id" cols="12">
          <v-checkbox
            v-model="data.dialog_field_data.checkboxes"
            class="ma-0"
            hide-details
            dense
            :value="checkbox.id"
            :label="checkbox.name"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="d-flex justify-center align-center">
          <span style="opacity: 0.5">
            This item does not have a checklist.
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { deepClone } from "@/utils/functions";

export default {
  props: {
    data: {},
    isChecklistReset: Boolean,
    checkboxes: Array,
    checkboxesHistory: Array,
  },
  data: () => ({
    showBanner: false,
  }),
  watch: {
    isChecklistReset(value) {
      if (value) this.showBanner = true;
    },
  },
  methods: {
    handleRestoreListByHistory() {
      this.data.dialog_field_data.checkboxes = deepClone(
        this.checkboxesHistory,
      );
      this.showBanner = false;
    },
  },
};
</script>
