<template>
  <v-container
    fluid
    class="monthly-movement-by-task-page"
    style="padding-bottom: 200px"
  >
    <v-row>
      <v-col cols="12">
        <h2>Monthly movement by tasks</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col>
        <v-menu
          content-class="my-shadow--e2"
          bottom
          nudge-bottom="52"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn outlined class="text-normal" v-on="on" block height="40">
              Dates
              <v-icon small>mdi-chevron-down</v-icon>
              <v-badge
                v-if="
                  [filters.completed_date, filters.created_date].some(
                    (v) => !__isEmptyRange(v)
                  )
                "
                color="info"
                inline
                :content="
                  [filters.completed_date, filters.created_date].filter(
                    (v) => !__isEmptyRange(v)
                  )?.length
                "
              />
            </v-btn>
          </template>
          <template #default>
            <v-card flat outlined>
              <v-card-title class="text-body-2">
                Select date filters
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <smart-date-filter
                      :model-value="filters.created_date"
                      @update:modelValue="filters.created_date = $event"
                      label="Created"
                      filled
                    />
                  </v-col>
                  <v-col cols="12">
                    <smart-date-filter
                      :model-value="filters.completed_date"
                      @update:modelValue="filters.completed_date = $event"
                      label="Completed"
                      filled
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-menu>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.role"
          hide-details
          dense
          item-text="value"
          item-value="id"
          :loading="loadings.filters"
          :items="filtersData.roles"
          outlined
          clearable
          label="Role"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.team"
          hide-details
          dense
          :loading="loadings.filters"
          item-text="name"
          item-value="id"
          :items="filtersData.teams"
          outlined
          clearable
          label="Team"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.executive"
          hide-details
          dense
          item-text="email"
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.executives"
          outlined
          clearable
          label="Executive"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.period_goals"
          hide-details
          dense
          item-text="title"
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.period_goals"
          outlined
          multiple
          label="Period Goals"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <extended-autocomplete
          :model-value="filters.labels"
          @update:modelValue="filters.labels = $event"
          :items="filtersData.labels"
          outlined
          limit-slot
          item-text="title"
          item-value="id"
          label="Label"
        />
      </v-col>
      <v-col>
        <smart-date-filter
          :model-value="filters.deadline"
          @update:modelValue="filters.deadline = $event"
          label="Deadline"
          outlined
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.status"
          label="Status"
          :items="statusItems"
          hide-details
          dense
          multiple
          item-text="text"
          item-value="value"
          outlined
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.type"
          label="Type"
          :items="['week', 'month', 'custom']"
          hide-details
          dense
          outlined
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          label
          @click="initDashboard"
          color="primary"
          block
          class="text-normal"
          height="40"
          :loading="loadings.dashboard"
          :disabled="loadings.dashboard"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loadings.dashboard">
      <v-col cols="12">
        <div class="d-flex">
          <div class="d-flex" style="gap: 8px">
            <div class="skeleton" style="height: 32px; width: 250px"></div>
            <div class="skeleton" style="height: 32px; width: 250px"></div>
          </div>
          <v-spacer />
          <div class="d-flex align-end" style="gap: 8px">
            <div class="skeleton" style="height: 30px; width: 130px"></div>
            <div class="skeleton" style="height: 30px; width: 90px"></div>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card class="styled-card--default">
          <v-card-title>
            <div class="skeleton" style="width: 150px; height: 24px" />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-skeleton-loader type="table" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loadings.dashboard && dirty && sections?.length === 0">
      <v-col class="d-flex justify-center my-6">
        <nothing-found-by-filters />
      </v-col>
    </v-row>
    <v-row v-if="!loadings.dashboard">
      <template v-for="(card, idx) in sections">
        <v-col :key="`${card.name}${idx}col`" cols="12">
          <v-card
            :class="`styled-card--${
              card.items?.length !== 0 ? 'dark' : 'light'
            }`"
            rounded="lg"
            :disabled="card.items?.length === 0"
          >
            <v-card-title>
              {{ String(card.name) === "0" ? "Without project" : card.name }}
              <v-spacer />
              <template v-if="card.items?.length === 0">
                No items...
                <v-spacer />
              </template>
              <v-menu
                left
                offset-y
                :close-on-content-click="false"
                content-class="my-shadow--e2"
              >
                <template #activator="{ on }">
                  <v-chip v-on="on" label>
                    <v-icon small left>mdi-cog</v-icon>
                    Table settings
                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-chip>
                </template>
                <v-card width="280" class="styled-card--default">
                  <v-card-title class="d-flex"> Settings</v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="8"> Fill row with color</v-col>
                      <v-col cols="4" class="d-flex justify-end">
                        <v-switch
                          v-model="settings.isMarkFixed"
                          hide-details
                          dense
                          class="ma-0"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
            <template v-if="card.items?.length !== 0">
              <v-divider />
              <v-card-text class="pa-2 px-0">
                <v-data-table
                  ref="dataTables"
                  item-key="id"
                  @scroll="handleTableScroll"
                  :items="hem_getFilteredItems(card.items)"
                  :headers="modifyHeaders(card.headers)"
                  multi-sort
                  :sort-by="['team_priority', 'p', 'priority']"
                  :sort-desc="[true, true, true]"
                  :custom-sort="_$smartCustomTableSort"
                  class="monthly-movement-by-task-table relative-tr"
                  :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
                  :items-per-page="25"
                >
                  <template
                    v-for="(header, i) in modifyHeaders(card.headers)"
                    #[`header.${header.value}`]
                  >
                    <vuetify-data-table-extended-headers
                      :header="header"
                      :highlight="hem_highlight"
                      :gradients-model="hem_gradients"
                      @update:gradientsModel="hem_gradients = $event"
                      :multi-search-model="hem_multiSearch"
                      @update:multiSearchModel="hem_multiSearch = $event"
                      :key="`header-${i}`"
                    />
                  </template>
                  <!--                  <template v-for="h in card.headers" #[`header.${h.value}`]>-->
                  <!--                    <div :key="h.value">-->
                  <!--                      <template v-if="h.value.match(/\bd_\d+\b/)">-->
                  <!--                        <template v-if="h.weekend">-->
                  <!--                            <span class="error&#45;&#45;text">-->
                  <!--                              {{ h.text }}-->
                  <!--                            </span>-->
                  <!--                        </template>-->
                  <!--                        <template v-else>-->
                  <!--                          {{ h.text }}-->
                  <!--                        </template>-->
                  <!--                      </template>-->
                  <!--                      <template v-else>-->
                  <!--                        {{ h.text }}-->
                  <!--                      </template>-->
                  <!--                    </div>-->
                  <!--                  </template>-->
                  <template #[`group.header`]="{ group, toggle, isOpen }">
                    <td :colspan="card.headers.length + 1">
                      <div class="d-flex align-center">
                        <v-btn icon @click="toggle" class="mr-2">
                          <v-icon>
                            {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                          </v-icon>
                        </v-btn>

                        <h4>{{ group }}</h4>
                      </div>
                    </td>
                  </template>
                  <template
                    v-for="h in card.headers"
                    #[`item.${h.value}`]="{ item, value }"
                  >
                    <template v-if="h.value === 'name'">
                      <div
                        class="d-flex align-center"
                        style="
                          gap: 4px;
                          position: relative;
                          height: 100%;
                          width: 100%;
                        "
                        :key="h.value"
                      >
                        <create-task-menu
                          :filters-data="filtersData"
                          :row-data="item"
                        />
                        <div
                          v-if="
                            item.is_has_incorrectly_created_subtask ||
                            item.is_has_no_final_result_and_plan_subtask
                          "
                          :class="`mark ${
                            settings.isMarkFixed ? '' : 'fixed left'
                          } mark--info`"
                          title="Has incorrectly created subtask | has no final result and plan subtask"
                        />
                        <div
                          v-if="item.status === 'done'"
                          :class="`mark ${
                            settings.isMarkFixed ? '' : 'fixed left'
                          } mark--success`"
                          title="Status ! Critical"
                        />
                        <div
                          v-else-if="String(item.status) === '1'"
                          :class="`mark ${
                            settings.isMarkFixed ? '' : 'fixed left'
                          } mark--success`"
                          title="Status success"
                        />
                        <div
                          v-else-if="
                            getDays(item.date_end) >= 2 &&
                            getDays(item.date_end) < 5
                          "
                          :class="`mark ${
                            settings.isMarkFixed ? '' : 'fixed left'
                          } mark--yellow`"
                          title="Status ⊘ in risk!"
                        />
                        <div
                          v-else-if="getDays(item.date_end) <= 1"
                          :class="`mark ${
                            settings.isMarkFixed ? '' : 'fixed left'
                          } mark--error`"
                          title="Status ! Critical"
                        />
                        <div
                          class="d-flex justify-space-between align-center"
                          style="gap: 8px; width: 100%"
                        >
                          <div>
                            <div v-if="item?.parent">
                              <small class="d-flex align-center">
                                <a
                                  :href="item.parent?.url"
                                  target="_blank"
                                  title="Parent task"
                                  style="
                                    max-width: 420px;
                                    display: inline-block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    color: inherit;
                                  "
                                >
                                  {{ item.parent?.name }}
                                </a>
                              </small>
                            </div>
                            <div v-if="item?.name_old" title="Old name">
                              <small>
                                <s> {{ item.name_old }}</s>
                              </small>
                            </div>
                            <div>
                              <a :href="item.task_url" target="_blank">
                                {{ item.name }}
                              </a>
                            </div>
                          </div>
                          <div class="d-flex justify-end">
                            <template v-if="item.name_history?.length > 0">
                              <name-history :data="item">
                                <v-chip
                                  label
                                  small
                                  color="transparent"
                                  class="px-1"
                                  @click="() => {}"
                                  title="Names history"
                                >
                                  <v-icon small>mdi-history</v-icon>
                                </v-chip>
                              </name-history>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template
                      v-else-if="
                        ['tl_approved', 'tl_plan_result'].includes(h.value)
                      "
                    >
                      <div class="text-center" :key="h.value">
                        <v-icon :color="value ? 'success' : 'error'" small>
                          {{ value ? "mdi-check" : "mdi-close" }}
                        </v-icon>
                      </div>
                    </template>
                    <template v-else-if="h.value === 'total_time'">
                      <div
                        :key="h.value"
                        :style="{
                          opacity:
                            String(item.total_time) === '0' ? '0.3' : '1',
                        }"
                      >
                        <time-value :value="value" />
                      </div>
                    </template>
                    <template v-else-if="h.value === 'executive'">
                      <v-menu
                        open-on-hover
                        :close-on-content-click="false"
                        :key="h.value"
                        bottom
                        nudge-bottom="36"
                        open-delay="350"
                        content-class="my-shadow--e2"
                      >
                        <template #activator="{ on }">
                          <div v-on="on">
                            <user-preview
                              :name="value"
                              hide-initials
                              :opacity="1"
                            />
                          </div>
                        </template>
                        <template #default>
                          <v-card class="pa-2">
                            {{ value }}
                          </v-card>
                        </template>
                      </v-menu>
                    </template>
                    <template v-else-if="h.value === 'max_time'">
                      <div
                        :key="h.value"
                        class="d-flex align-center"
                        style="gap: 0.5rem"
                        :style="{
                          opacity:
                            String(value) === '0' || value === undefined
                              ? '0.3'
                              : '1',
                        }"
                      >
                        <div>
                          <div style="min-width: 90px">
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-progress-linear
                                  v-on="on"
                                  color="#996699"
                                  rounded
                                  height="8"
                                  :value="value"
                                />
                              </template>
                              <template #default>
                                <div class="text-body-2">Estimation</div>
                                <div>
                                  {{ value ? value + "%" : "0%" }}
                                </div>
                              </template>
                            </v-tooltip>
                          </div>
                          <div style="min-width: 90px; margin-top: 4px">
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-progress-linear
                                  v-on="on"
                                  color="#c05834"
                                  rounded
                                  height="8"
                                  :value="item['_' + h.value]?.deadline"
                                />
                              </template>
                              <template #default>
                                <div class="text-body-2">Deadline progress</div>
                                <div>
                                  {{
                                    item["_" + h.value]?.deadline
                                      ? item["_" + h.value].deadline + "%"
                                      : "0%"
                                  }}
                                </div>
                              </template>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="h.value === 'statuses'">
                      <row-statuses-list :item="item" :key="h.value" />
                    </template>
                    <template v-else-if="h.value === 'sprint'">
                      <div
                        class="d-flex flex-wrap py-2"
                        style="gap: 2px"
                        :key="h.value"
                      >
                        <v-chip
                          v-for="(item, idx) in value"
                          :key="`${item.name}${idx}item`"
                          style="font-size: 12px"
                          label
                          small
                        >
                          {{ item.sprint }}:
                          <b
                            v-for="type in item.data"
                            :key="`${type.name}${idx}type`"
                            style="margin-left: 2px"
                          >
                            {{ type.name }}
                            <sup v-if="Number(type.priority) > 1">
                              {{ type.priority }}
                            </sup>
                          </b>
                        </v-chip>
                      </div>
                    </template>
                    <template v-else-if="h.value === 'status'">
                      {{ String(value) === "1" ? "Yes" : "No" }}
                    </template>
                    <template v-else-if="h.value === 'deadline'">
                      <table-deadline-tooltip :data="item" :key="h.value" />
                    </template>
                    <template v-else-if="h.value === 'estimate'">
                      <template v-if="!value">
                        <div
                          class="colored-td red"
                          title="Without estimation"
                          :key="h.value"
                        >
                          <span style="font-size: 10px"></span>
                        </div>
                      </template>
                      <template v-else>
                        {{ value ? parseInt(value).toFixed(0) : null }}
                      </template>
                    </template>
                    <template v-else-if="h.value === 'dynamic'">
                      <v-card
                        v-if="
                          item.dynamic &&
                          item.dynamic?.length > 0 &&
                          notZeros(item.dynamic)
                        "
                        class="pa-1 styled-card--default"
                        :key="h.value"
                      >
                        <v-sparkline
                          :value="item.dynamic"
                          line-width="16"
                          :gradient="['#639ee0', '#2b4d93']"
                          type="bar"
                          :width="300"
                        >
                        </v-sparkline>
                      </v-card>
                    </template>
                    <template v-else-if="h.value === 'metric'">
                      <div :key="h.value">
                        <div
                          v-if="value?.length > 0"
                          class="d-flex py-1 flex-wrap"
                          style="gap: 0.25rem; min-width: 220px"
                        >
                          <v-chip
                            v-for="title in value"
                            small
                            :key="title"
                            :style="{
                              color: getMetricColor(
                                String(title).toLowerCase()
                              ),
                            }"
                            class="flat-chip--auto"
                          >
                            <v-icon
                              left
                              size="12"
                              :color="
                                getMetricColor(String(title).toLowerCase())
                              "
                              >mdi-circle</v-icon
                            >
                            {{ title }}
                          </v-chip>
                        </div>
                        <div v-else class="px-3">
                          <v-icon color="error" small>
                            mdi-alert-outline
                          </v-icon>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="h.value.match(/\bd_\d+\b/)">
                      <div :key="h.value">
                        <!-- 📢 Has deadline AND Task wasn't closed -->
                        <template
                          v-if="
                            item['_' + h.value]?.deadline &&
                            item.status !== 'done'
                          "
                        >
                          <div
                            :class="{
                              'text-center':
                                item['_' + h.value]?.deadline &&
                                (value === '00:00' || value === 0),
                            }"
                            title="Has deadline and task wasn't closed."
                          >
                            <v-chip
                              class="flat-chip flat-chip--auto primary--text text-center"
                            >
                              <span
                                v-if="value === '00:00' || value === 0"
                                style="opacity: 0.3"
                              >
                                -
                              </span>
                              <span v-else>
                                <time-value :value="value" />
                              </span>
                            </v-chip>
                          </div>
                        </template>
                        <!-- 📢 Has deadline -->
                        <template v-else-if="item['_' + h.value]?.deadline">
                          <div
                            :class="{
                              'text-center':
                                item['_' + h.value]?.deadline &&
                                (value === '00:00' || value === 0),
                            }"
                            title="Has deadline"
                          >
                            <v-chip
                              class="flat-chip flat-chip--auto warning--text text-center"
                            >
                              <span
                                v-if="value === '00:00' || value === 0"
                                style="opacity: 0.3"
                              >
                                -
                              </span>
                              <span v-else>
                                <time-value :value="value" />
                              </span>
                            </v-chip>
                          </div>
                        </template>
                        <!-- 📢 Task done -->
                        <template v-else-if="false && item.status === 'done'">
                          <div
                            :class="{
                              'text-center': value === '00:00' || value === 0,
                            }"
                            title="Task is done"
                          >
                            <v-chip
                              class="flat-chip flat-chip--auto success--text text-center"
                            >
                              <span
                                v-if="value === '00:00' || value === 0"
                                style="opacity: 0.3"
                              >
                                -
                              </span>
                              <span v-else>
                                <time-value :value="value" />
                              </span>
                            </v-chip>
                          </div>
                        </template>
                        <!-- 📢 Empty value -->
                        <template v-else>
                          <div class="text-center" title="No data">
                            <span
                              v-if="value === '00:00' || value === 0"
                              style="opacity: 0.3"
                            >
                              -
                            </span>
                            <span v-else>
                              <time-value :value="value" />
                            </span>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      {{ value }}
                    </template>
                  </template>
                </v-data-table>
              </v-card-text>
            </template>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="computedStatisticsValid" dense>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="2" style="min-width: 215px">
            <month-overall-card :data="computedOverallMonth" />
          </v-col>
          <v-col>
            <div
              style="
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 0.25rem;
              "
            >
              <div
                v-for="team in computedOverallMonthStatisticTeams"
                :key="team?.name"
              >
                <month-overall-card-by-team :data="team" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  Endpoints,
  MonthlyMovementByTaskBreadcrumbs,
  StatusItemsData,
} from "../defaults";
import service from "../../../plugins/service";
import TimeValue from "@/components/MonthlyMovementByTask/TimeValue/index.vue";
import NameHistory from "@/components/MonthlyReport/NameHistory.vue";
import RowStatusesList from "@/components/MonthlyReport/RowStatusesList.vue";
import TableDeadlineTooltip from "@/components/MonthlyReport/TableDeadlineTooltip.vue";
import TableSorting from "../../../mixins/TableSorting";
import CreateTaskMenu from "@/components/MonthlyReport/CreateTaskMenu.vue";
import ExtendedAutocomplete from "../../UI/ExtendedAutocomplete.vue";
import NothingFoundByFilters from "@/components/UI/Placeholders/NothingFoundByFilters.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import UserPreview from "@/components/UI/UserPreview.vue";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";
import VuetifyTableHeadersExtensionMixin from "../../../mixins/data-table/VuetifyTableHeadersExtensionMixin";
import VuetifyDataTableExtendedHeaders from "../../Vuetify/DataTable/VuetifyDataTableExtendedHeaders.vue";
import MonthOverallCard from "../Components/MonthOverallCard.vue";
import MonthOverallCardByTeam from "../Components/MonthOverallCardByTeam.vue";

export default {
  components: {
    MonthOverallCardByTeam,
    MonthOverallCard,
    VuetifyDataTableExtendedHeaders,
    SmartDateFilter,
    UserPreview,
    TimeValue,
    NothingFoundByFilters,
    ExtendedAutocomplete,
    CreateTaskMenu,
    NameHistory,
    RowStatusesList,
    TableDeadlineTooltip,
  },

  mixins: [TableSorting, FiltersHandling, VuetifyTableHeadersExtensionMixin],

  data: () => ({
    breadcrumbs: MonthlyMovementByTaskBreadcrumbs,
    sections: [],
    statistic: {},
    statusItems: StatusItemsData,
    settings: {
      isMarkFixed: true,
    },
    filters: {
      team: null,
      role: null,
      executive: null,
      deadline: [null, null],
      label: null,
      period_goals: [],
      dynamic_date: [null, null],
      created_date: [null, null],
      completed_date: [null, null],
      report_date: [null, null],
      date_added: [null, null],
      labels: [],
      type: "month",
    },
    dirty: false,
    loadings: {
      filers: false,
      dashboard: false,
    },
    filtersData: {
      team: [],
      executive: [],
      labels: [],
    },
    sharedScroll: null,
    isDataTablesScrollAlreadySynchronized: false,
  }),

  async mounted() {
    this.tryToSynchronizeDataTablesScroll();

    this.fetchFilters();
    await this.initializeFiltersWatcher("filters");
    await this.collectParametersFromQuery("filters");

    if (!this.$route.query.created_date)
      this.filters.created_date = [
        this.$moment().startOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
        this.$moment().endOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
      ];

    if (Object.keys(this.$route.query).length > 0) this.initDashboard();
  },

  computed: {
    computedStatisticsValid() {
      return (
        this.statistic &&
        typeof this.statistic === "object" &&
        Object.keys(this.statistic)?.length
      );
    },
    computedOverallMonth() {
      // Month overall statistics card data adapter
      if (
        this.statistic &&
        this.statistic?.Month &&
        (this.statistic?.Month_A ||
          this.statistic?.Month_B ||
          this.statistic?.Month_C)
      ) {
        return {
          total: this.statistic?.Month,
          months: [
            {
              name: "Month A",
              data: this.statistic?.Month_A,
            },
            {
              name: "Month B",
              data: this.statistic?.Month_B,
            },
            {
              name: "Month C",
              data: this.statistic?.Month_C,
            },
          ],
        };
      }

      return {};
    },
    computedOverallMonthStatisticTeams() {
      // Month overall statistics team card data adapter
      if (
        this.statistic?.team &&
        typeof this.statistic?.team === "object" &&
        Object.keys(this.statistic?.team)?.length
      ) {
        return [
          this.overallTeamCardAdapter(
            this.statistic?.team?.Management,
            "Management"
          ),
          this.overallTeamCardAdapter(this.statistic?.team?.Jets, "Jets"),
          this.overallTeamCardAdapter(this.statistic?.team?.Enigma, "Enigma"),
          this.overallTeamCardAdapter(this.statistic?.team?.Content, "Content"),
          this.overallTeamCardAdapter(this.statistic?.team?.["LB"], "LB"),
        ];
      }

      return [];
    },
  },

  methods: {
    overallTeamCardAdapter(teamData, name) {
      if (!teamData) return null;

      return {
        name,
        total: {
          all: teamData?.all,
          done: teamData?.done,
          p: teamData?.p,
        },
        months: [
          {
            name: "Month A",
            data: teamData?.Month_A,
          },
          {
            name: "Month B",
            data: teamData?.Month_B,
          },
          {
            name: "Month C",
            data: teamData?.Month_C,
          },
        ],
      };
    },
    getDays(date) {
      return Number(
        String(this.$moment().diff(this.$moment(date), "days")).replace("-", "")
      );
    },
    getMetricColor(metricName) {
      const colorsData = {
        content: {
          light: "#8f741f",
          dark: "#e4cc71",
        },
        strategic: {
          light: "#819ea5",
          dark: "#9fd4ea",
        },
        links: {
          light: "#568bb3",
          dark: "#7dbaea",
        },
        money: {
          light: "#508445",
          dark: "#8cd57d",
        },
        conversion: {
          light: "#5e8792",
          dark: "#8bd4e6",
        },
        base: {
          light: "#929d6a",
          dark: "#d6e893",
        },
        technical: {
          light: "#746ca1",
          dark: "#a89be6",
        },
        "people #": {
          light: "#7f8c9f",
          dark: "#b0c8e6",
        },
        pc: {
          light: "#504789",
          dark: "#9387e3",
        },
      };

      return colorsData[metricName][
        this.$vuetify.theme.dark ? "dark" : "light"
      ];
    },
    tryToSynchronizeDataTablesScroll() {
      try {
        if (this.isDataTablesScrollAlreadySynchronized) return;

        const elements = this.$refs.dataTables;

        if (!elements || elements?.length === 0)
          return setTimeout(() => {
            this.tryToSynchronizeDataTablesScroll();
          }, 1000);

        elements?.forEach((element, initiatorIdx) => {
          const scrollableWrapper = element.$el.querySelector(
            ".v-data-table__wrapper"
          );

          scrollableWrapper.addEventListener("scroll", (event) => {
            elements.forEach((table, tableIdx) => {
              // Current table
              if (tableIdx === initiatorIdx) return;

              const scrollableChildWrapper = elements[
                tableIdx
              ].$el.querySelector(".v-data-table__wrapper");

              scrollableChildWrapper.scrollLeft = event?.target?.scrollLeft;
            });
          });
        });

        this.isDataTablesScrollAlreadySynchronized = true;
      } catch (e) {
        console.error("Error while synchronize data tables search", e);
      }
    },

    handleTableScroll() {
      // scroll
    },

    modifyHeaders(headersApp) {
      if (!headersApp || !Array.isArray(headersApp)) return [];

      return headersApp.map((header) => {
        if (header.value === "name") header.width = "480px";

        return header;
      });
    },

    notZeros(arr) {
      return !arr?.every((element) => element === 0);
    },

    async fetchFilters() {
      this.loadings.filters = true;
      const url = "/worksection/get/monthly/filter";

      const resp = await service.post(url, {});

      if (resp) this.filtersData = resp.data;

      this.loadings.filters = false;
    },

    hasTag(items, tag) {
      return (
        String(items.status) !== "1" &&
        String(items.stage).trim() === String(tag).trim()
      );
    },

    async initDashboard() {
      try {
        this.dirty = true;
        this.loadings.dashboard = true;

        const { data } = await service.post(Endpoints.GetMovementTasksData, {
          filter: this.filters,
        });

        this.sections = [
          data?.["Month_A"],
          data?.["Month_B"],
          data?.["Month_C"],
        ];
        this.statistic = data?.statistic;
        this.isDataTablesScrollAlreadySynchronized = false;

        this.$nextTick(() => {
          this.tryToSynchronizeDataTablesScroll();
        });
      } catch (e) {
        console.error("Something went wrong while initializing dashboard.", e);
      } finally {
        this.loadings.dashboard = false;
      }
    },
  },
};
</script>

<style lang="scss">
.monthly-movement-by-task-page {
  .mark {
    width: 100% !important;
  }
  .monthly-movement-by-task-table {
    table {
      tr {
        td:first-child {
          padding: 0;
          & > div {
            padding: 0 0.5rem;
          }
        }
        td {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(7) {
            border-right: 1px solid var(--card-border);
          }
        }
      }
    }
  }
}
</style>
