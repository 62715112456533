<template>
  <v-card>
    <div :id="`chart-links-${propType}`">
      <!--            <h2 class="text-h6 mb-3">Links</h2>-->
      <div :id="`chart-timeline-links-${propType}`">
        <apexcharts
          :ref="`chart_links_${propType}`"
          height="200"
          :options="options"
          :series="series"
        ></apexcharts>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import axios from "axios";
import events from "../../../events/components/OnePageAnalyzer/events";

export default {
  name: "Links",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  props: ["propType"],
  data() {
    return {
      series: [],
      options: {
        chart: {
          id: "chart-timeline-links",
          type: "bar",
          height: 200,
          stacked: true,
          zoom: {
            enabled: true,
            //  autoScaleYaxis: true
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: true,
          },
          crosshairs: {
            show: true,
          },
          labels: {
            show: true,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              strokeDashArray: 0,
              borderColor: "#e6e6e6",
              fillColor: "#c2c2c2",
              opacity: 0.8,
              offsetX: 0,
              offsetY: 0,
            },
          ],
        },
        xaxis: {
          type: "datetime",
          min: new Date().setMonth(new Date().getMonth() - 12),
          tickAmount: 6,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "25%",
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
      },
    };
  },
  methods: {
    resetData() {
      this.series = [];
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/links`;

      let config = {
        params: {
          url:
            self.propType === "default"
              ? self.selectedUrl
              : self.selectedCompetitorUrl,
          type: self.propType,
          projectID: self.idProject,
          filter: self.filterData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.series = r.data.links;
        self.$refs[`chart_links_${self.propType}`].updateOptions({
          colors: r.data.colors,
        });
        setTimeout(function () {
          self.updateChartDates("six_months");
        }, 1000);
      });
    },
  },
  created() {
    let self = this;

    let event =
      self.propType === "default"
        ? "update-url"
        : "update-selected-competitor-url";

    events.$on(event, function () {
      self.resetData();
      self.fetchData();
    });

    events.$on("update-main-filter", function () {
      self.resetData();
      self.fetchData();
    });

    events.$on("update-chart-dates", function (start, end) {
      if (self.series.length > 0) {
        self.$refs[`chart_links_${self.propType}`].zoomX(start, end);
      }
    });
  },
};
</script>

<style scoped></style>
