<template>
  <div>
    <v-card v-if="!loading" class="styled-card--default">
      <v-card-title
        :data-page-navigation="SECTIONS.sw_traffic_by_countries.sectionTitle"
      >
        <!--        SW PRO Traffic By Country-->
        {{ SECTIONS.sw_traffic_by_countries.sectionTitle }}
        <force-update-button :section-name="sectionName" />
      </v-card-title>
      <v-card-subtitle>
        <last-update-info
          :data="data"
          :successfully-uploaded="successfullyUploaded"
        />
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <template v-if="successfullyUploaded">
          <apexcharts
            height="273"
            :options="getOptions"
            :series="data.series"
          ></apexcharts>
        </template>
        <template v-else>
          <not-collected-placeholder />
        </template>
      </v-card-text>
    </v-card>
    <div v-else class="skeleton loading" style="height: 300px"></div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";

export default {
  components: {
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
    apexcharts: VueApexCharts,
  },
  mixins: [SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    //
  }),
  computed: {
    getOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          type: "donut",
        },
        labels: this.data.labels || [],
        colors: this.data.colors || [
          "#d75f5f",
          "#3cccb1",
          "#4695cc",
          "#7339c0",
          "#d236da",
          "#b0b0b0",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
};
</script>
