<template>
  <div v-if="traffic?.our" style="white-space: nowrap">
    {{ Number(traffic?.our) }}
    <sup :class="traffic?.diff < 0 ? 'error--text' : 'success--text'">
      {{ String(Number(traffic?.diff)).replace("-", "") }}
    </sup>
  </div>
</template>
<script>
export default {
  props: {
    traffic: Object,
  },
};
</script>
