import Service from "@/plugins/service";

export default {
  state: {
    tableData: null,
  },
  mutations: {
    "reservedDomains/setTableData"(state, payload) {
      state.tableData = payload;
    },
  },
  actions: {
    async "reservedDomains/fetchTableData"({ commit }, payload) {
      let url = "/gpm/task-view/get-dashboard-data";
      const response = await Service.get(url, true, payload);
      if (response) {
        commit("reservedDomains/setTableData", response.data);
      }
    },
    async "reservedDomains/restoreDonors"(ctx, payload) {
      let url = "/gpm/entity-view/cancel-reserve-donors";
      const resp = await Service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "reservedDomains/removeDonors"(ctx, payload) {
      let url = "/gpm/entity-view/remove-donors";
      await Service.post(url, payload, true);
    },
    async "reservedDomains/sendToAsk"(ctx, payload) {
      const { formData, config } = payload;
      let url = "/gpm/task-view/upload-domains";
      const resp = await Service.post(url, formData, true, config);
      if (resp) return resp.data;
    },
    async "reservedDomains/getAnchorPlanTasks"(ctx, payload) {
      let url = "/gpm/task-view/get-anchor-plan-tasks";
      const response = await Service.get(url, true, payload);
      if (response) {
        return response.data;
      }
    },
    async "reservedDomains/moveTask"(ctx, payload) {
      let url = "/gpm/entity-view/move";
      const response = await Service.post(url, payload, true);
      if (response) {
        return response;
      }
    },
  },
  getters: {
    "reservedDomains/tableData": (state) => state.tableData,
  },
};
