<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>Choose type</h2>
        <p>Select the type of tasks to be supplied</p>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <v-card outlined rounded="lg">
              <v-card-title> Task in subtask </v-card-title>
              <v-card-subtitle>
                Mass formulation of the same type of task in a subtask
              </v-card-subtitle>
              <v-card-actions class="d-flex">
                <v-spacer />
                <v-btn
                  color="info"
                  width="130px"
                  @click="
                    $router.push('/similar-task-creating/task-in-subtask')
                  "
                  >Create</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined rounded="lg">
              <v-card-title> To those responsible </v-card-title>
              <v-card-subtitle>
                Mass assignment of tasks to those responsible for the project
              </v-card-subtitle>
              <v-card-actions class="d-flex">
                <v-spacer />
                <v-btn
                  color="info"
                  width="130px"
                  @click="
                    $router.push('/similar-task-creating/to-those-responsible')
                  "
                  >Create</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined rounded="lg">
              <v-card-title> From File </v-card-title>
              <v-card-subtitle>
                Create a task by uploading the appropriate file to the system
              </v-card-subtitle>
              <v-card-actions class="d-flex">
                <v-spacer />
                <v-btn
                  color="info"
                  width="130px"
                  @click="$router.push('/similar-task-creating/by-file')"
                  >Upload</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined rounded="lg">
              <v-card-title> Task Series </v-card-title>
              <v-card-subtitle>
                A quick way to create a series of tasks
              </v-card-subtitle>
              <v-card-actions class="d-flex">
                <v-spacer />
                <v-btn
                  color="info"
                  width="130px"
                  @click="
                    $router.push({ name: 'similar-task-creating.series' })
                  "
                  >Create</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        to: "/",
      },
      {
        text: "Choose Type",
        disabled: true,
      },
    ],
  }),
};
</script>
