import service from "../plugins/service";

export default {
  data: () => ({
    filtersData: {},
    filtersDataPending: false,
    filtersDataPendingKeys: {},
    ufd_filtersDataEndpoint: "/filter/v2",
  }),

  methods: {
    /**
     * @param {string[]} take - keys for filters which need to upload
     * @param {?Record<string, any>} filter - Object with additional filters for the taken
     */
    async uploadFiltersData(take = [], filter) {
      try {
        this.filtersDataPending = true;

        take?.forEach((key) =>
          this.$set(this.filtersDataPendingKeys, key, true)
        );

        const response = await service.post(this.ufd_filtersDataEndpoint, {
          take,
          filter: filter || {},
        });

        this.filtersData = { ...this.filtersData, ...response.data } || {};
      } catch (e) {
        console.error("Error while uploading filters data.", e);
      } finally {
        this.filtersDataPending = false;
        take?.forEach((key) =>
          this.$set(this.filtersDataPendingKeys, key, false)
        );
      }
    },
  },
};
