<template>
  <v-card v-if="!loading" class="styled-card--default">
    <v-card-title
      :data-page-navigation="SECTIONS.ah_detailed_by_countries.sectionTitle"
    >
      <!--      AH Detailed by country-->
      {{ SECTIONS.ah_detailed_by_countries.sectionTitle }}
      <force-update-button :section-name="sectionName" />
    </v-card-title>
    <v-card-subtitle>
      <last-update-info
        :data="data"
        :successfully-uploaded="successfullyUploaded"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <template v-if="successfullyUploaded">
        <v-data-table
          dense
          :items="data.data"
          :headers="headers"
          hide-default-footer
          :items-per-page="-1"
        ></v-data-table>
      </template>
      <template v-else>
        <not-collected-placeholder />
      </template>
    </v-card-text>
  </v-card>
  <div v-else class="skeleton loading" style="height: 300px"></div>
</template>

<script>
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";

export default {
  components: { ForceUpdateButton, NotCollectedPlaceholder, LastUpdateInfo },
  mixins: [SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Country",
        value: "country",
      },
      {
        text: "Keywords",
        value: "positions",
      },
      {
        text: "Traffic",
        value: "traffic",
      },
    ],
  }),
};
</script>
