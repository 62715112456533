export default {
  state: {
    menu_select_projects: false,
    active_project:
      typeof localStorage.getItem("active_project") === "string" &&
      localStorage.getItem("active_project") !== "undefined"
        ? localStorage.getItem("active_project")
        : null,
    domain: null,
    allowed_projects: [],
    loading: false,
  },
  actions: {
    update_active_project(ctx, project) {
      if (
        typeof project === "string" &&
        (project === "null" || project === "undefined")
      ) {
        return ctx.commit("updateActiveProject", null);
      }

      ctx.commit("updateActiveProject", project);
    },
    update_domain(ctx) {
      ctx.commit("updateDomain");
    },
  },
  mutations: {
    updateActiveProject(state, project) {
      if (
        !project ||
        (typeof project === "string" &&
          (project === "null" || project === "undefined"))
      ) {
        state.active_project = null;
        return localStorage.removeItem("active_project");
      }

      localStorage.setItem("active_project", project);
      state.active_project = project;
    },
    updateDomain(state) {
      let projectData = state.allowed_projects.filter(
        (v) => v.id === parseInt(state.active_project)
      )[0];

      if (projectData !== undefined) {
        state.domain = projectData.domain;
      } else {
        state.domain = null;
      }
    },
  },
  getters: {
    active_project: (state) => state.active_project,
    "project/active_project": (state) => state.active_project,
  },
};
