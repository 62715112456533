var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding-bottom":"200px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Create series of tasks")]),_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"styled-card--default d-flex flex-column",attrs:{"width":"1220","min-height":"800","rounded":"lg"}},[_c('v-card-title',[_vm._v(" Create series of tasks "),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4",staticStyle:{"flex-grow":"1"}},[_c('series-tasks-form',{attrs:{"model-value":_vm.tasksForm,"loadings":_vm.loadings.tasksOnLoading,"errors":_vm.errors,"local-storage-id":_vm.localStorageSeriesTasksFormId,"filters-data":{
              responsible: {
                items: _vm.taskFiltersData.users,
                value: 'email',
                text: 'email',
              },
              tags: {
                items: _vm.taskFiltersData.tags,
                value: 'id',
                text: 'title',
              },
              projects: {
                items: _vm.taskFiltersData.projects,
                value: 'id',
                text: 'name',
              },
            }},on:{"clearForm":_vm.handleClearForm,"settings":function($event){_vm.settings = $event},"update:modelValue":function($event){_vm.tasksForm = $event}}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false,"content-class":"my-shadow--e2"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"large":"","color":"primary","loading":_vm.loadings.creating}},on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Create tasks "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-card',{staticClass:"styled-card--default"},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.handleCreateSeriesSubtasks(false)}}},[_vm._v(" Normal saving ")]),_c('v-list-item',{on:{"click":function($event){return _vm.handleCreateSeriesSubtasks(true)}}},[_vm._v(" Save with postfix ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }