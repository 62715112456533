<template>
  <td>
    <div :class="`colored-td${getColor} td-content center`">
      {{ data.need_top }}
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      const need_top = this.data.need_top;

      if (this.data.task_closed) return "";

      if (need_top >= 1 && need_top <= 3) {
        return " green";
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
