<template>
  <div class="managers-dashboard">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h2>Managers Dashboard</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="styled-card--default" rounded="lg">
            <v-card-title class="d-flex pa-4" style="gap: 0.25rem">
              <v-menu content-class="remove-dialog-shadow">
                <template #activator="{ on }">
                  <v-chip
                    style="min-width: 154px"
                    class="d-flex justify-center"
                    label
                    outlined
                    v-on="on"
                  >
                    Acceptors Overdue
                    <v-icon small right>mdi-content-copy</v-icon>
                  </v-chip>
                </template>
                <v-card class="styled-card--default">
                  <v-card-title> Select format type </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <v-list dense>
                      <v-list-item
                        @click="handleCopyTableToClipboard('markdown')"
                      >
                        Markdown
                      </v-list-item>
                      <v-list-item @click="handleCopyTableToClipboard('html')">
                        HTML Link
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu content-class="remove-dialog-shadow">
                <template #activator="{ on }">
                  <v-chip
                    style="min-width: 154px"
                    class="ml-2 d-flex justify-center"
                    label
                    outlined
                    :disabled="loadings.aggregate"
                    v-on="on"
                  >
                    <template v-if="!loadings.aggregate">
                      Acceptors Unnasigned
                      <v-icon small right>mdi-content-copy</v-icon>
                    </template>
                    <template v-else>
                      <v-progress-circular indeterminate size="18" width="2" />
                    </template>
                  </v-chip>
                </template>
                <v-card class="styled-card--default">
                  <v-card-title> Select format type </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <v-list dense>
                      <v-list-item @click="fetchAggregateData('markdown')">
                        Markdown
                      </v-list-item>
                      <v-list-item @click="fetchAggregateData('html')">
                        HTML Link
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-spacer />
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                v-model="modal.shown"
                :value="modal.shown"
                max-width="350px"
                content-class="shadow-e2-bordered"
              >
                <template #activator="{ on }">
                  <v-chip class="flat-chip flat-chip--primary" label v-on="on">
                    <v-icon small left>mdi-filter</v-icon> Filters
                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-chip>
                </template>
                <v-card width="460px" class="styled-card--light" rounded="lg">
                  <v-card-title> Filters </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <smart-date-filter
                          filled
                          :model-value="filter.date_range"
                          @update:modelValue="filter.date_range = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          dense
                          filled
                          v-model="filter.projects"
                          :items="_$getProjects"
                          item-value="id"
                          item-text="name"
                          label="Project"
                          :menu-props="menuProps"
                          hide-details
                          chips
                          small-chips
                          deletable-chips
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          dense
                          filled
                          v-model="filter.subproject"
                          :items="_$getSubprojectsByProjectId(filter.projects)"
                          item-value="id"
                          item-text="name"
                          label="Subproject"
                          :menu-props="menuProps"
                          hide-details
                          chips
                          small-chips
                          deletable-chips
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          dense
                          clearable
                          filled
                          v-model="filter.niche"
                          :items="filtersData.niches"
                          :menu-props="menuProps"
                          item-value="id"
                          item-text="name"
                          label="Niche"
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          dense
                          clearable
                          filled
                          v-model="filter.type"
                          :items="types_items"
                          :menu-props="menuProps"
                          label="Type"
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.main_seo"
                          :items="filtersData.main_seo"
                          hide-details
                          multiple
                          label="Main Seo"
                          dense
                          filled
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.team"
                          :items="filtersData.team"
                          hide-details
                          multiple
                          label="Team"
                          placeholder="Type to search"
                          dense
                          filled
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-btn
                      :loading="loadings.table"
                      @click="fetchData"
                      class="flat-chip--primary"
                      block
                      color="primary"
                    >
                      Apply
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-simple-table
                v-if="!loadings.table && isFetchedOnce && items.length > 0"
                class="fixed-table-gpm-d managers__table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="managers__colored-th"
                        v-for="(header, index) in headers"
                        :style="
                          header.type === 'status'
                            ? 'height: auto; writing-mode: vertical-lr; text-orientation: mixed; white-space: nowrap; background-color: ' +
                              header.color
                            : ''
                        "
                        :key="header.value + index"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" :key="item.name">
                      <td
                        v-for="(header, index) in headers"
                        :key="header.value + index"
                        class="text-center"
                      >
                        <a
                          v-if="item[header.value].value !== undefined"
                          :href="
                            getLinkToDonor(
                              item[header.value].status_id,
                              item.id
                            )
                          "
                          target="_blank"
                          style="white-space: nowrap; text-decoration: none"
                        >
                          {{ item[header.value].value }}
                          <sup
                            title="last 24 hours"
                            v-if="
                              ['-', 0].indexOf(item[header.value].new) === -1
                            "
                            >{{ item[header.value].new }}</sup
                          >
                          <span
                            v-if="
                              ['-', 0].indexOf(item[header.value].overdue) ===
                              -1
                            "
                            >({{ item[header.value].overdue }})</span
                          >
                        </a>
                        <span
                          v-else-if="header.value === 'name'"
                          class="d-flex"
                          style="gap: 0.25rem"
                        >
                          {{ item[header.value] }}
                          <v-btn small icon @click="viewAnchorPlan(item.id)">
                            <v-icon small>mdi-calendar-month-outline</v-icon>
                          </v-btn>
                          <div class="d-flex">
                            <v-chip
                              v-if="item.problems"
                              class="flat-chip--auto error--text px-2"
                              style="
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                                border-right: 1px solid rgba(155, 155, 155, 0.3);
                              "
                              small
                            >
                              {{ item.problems }} errors
                            </v-chip>
                            <v-chip
                              v-if="item.percentage_errors"
                              small
                              style="
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                              "
                              :class="{
                                'px-1': true,
                                'flat-chip--auto': true,
                                ...getErrorPercentageColor(
                                  item.percentage_errors
                                ),
                              }"
                              title="Error percentage"
                            >
                              {{ item.percentage_errors }} e%
                            </v-chip>
                          </div>
                        </span>
                        <span v-else-if="header.value === 'in_work'">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{
                                item[header.value][0]
                              }}</span>
                            </template>
                            <span>Donors used in the current task</span>
                          </v-tooltip>
                          /
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{
                                item[header.value][1]
                              }}</span>
                            </template>
                            <span
                              >Acceptors used in the current task, and in others
                              as friendly links
                            </span>
                          </v-tooltip>
                        </span>
                        <span v-else>{{ item[header.value] }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div
                v-else-if="
                  !loadings.table && isFetchedOnce && items.length === 0
                "
                class="py-4 d-flex justify-center"
              >
                <span>No data for this filters...</span>
              </div>
              <div
                v-else-if="
                  !loadings.table && !isFetchedOnce && items.length === 0
                "
                class="py-4 d-flex justify-center"
              >
                <span>Select filters and press "get data"</span>
              </div>
              <v-skeleton-loader
                v-if="loadings.table"
                type="table"
                style="width: 100%"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog_apt"
      scrollable
      width="1440"
      content-class="my-shadow--e4"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="pa-4 text-body-2">
          Anchor Plan
          <v-spacer />
          <v-icon @click="dialog_apt = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-data-table
            group-by="group"
            :headers="apt_headers"
            :items="apt_items"
            :items-per-page="-1"
            hide-default-footer
            class="table-overflow-hidden relative-tr border rounded-lg"
          >
            <template #[`group.header`]="{ isOpen, toggle, group }">
              <td>
                <v-btn icon @click="toggle">
                  <v-icon>
                    {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
                  </v-icon>
                </v-btn>
                {{ group }}
              </td>
              <td :colspan="apt_headers.length - 1"></td>
            </template>
            <template #[`item.url`]="{ item }">
              <div
                class="mark"
                :style="{ 'background-color': item.main_color }"
              />
              {{ item.url }}
            </template>
            <template #[`item.anchor`]="{ value }">
              {{ value }}
            </template>
            <template #[`item.donor`]="{ item }">
              <a :href="'/gpm/view-entity/' + item.entity_id" target="_blank">{{
                item.donor
              }}</a>
              <v-chip
                v-if="item.friendly_link === 1"
                color="warning"
                title="Friendly Link"
                small
                class="ml-3"
                >FL
              </v-chip>
            </template>
            <template #[`item.problems`]="{ value }">
              <div
                v-if="value?.length"
                class="d-flex flex-wrap py-2"
                style="gap: 0.25rem"
              >
                <v-tooltip
                  bottom
                  v-for="problem in value"
                  :key="problem?.entity_id"
                >
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      label
                      class="flat-chip--auto error--text"
                      small
                    >
                      {{ problem?.recheck_problem }}
                    </v-chip>
                  </template>
                  <template #default>
                    <div
                      class="d-flex flex-wrap flex-column"
                      style="gap: 0.5rem"
                    >
                      <div>
                        {{ problem?.created_at }}
                      </div>
                    </div>
                  </template>
                </v-tooltip>
              </div>
            </template>
            <template #[`item.status_name`]="{ value }">
              {{ value }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import CopyComponentId from "../../../mixins/copyComponentId";
import QueryBuilder from "@/mixins/QueryBuilder";
import copy from "copy-to-clipboard";
import { deepClone } from "../../../utils/functions";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import Project from "../../../mixins/Project";
import service from "@/plugins/service";

const dateFormat = require("dateformat");
const date = new Date();

export default {
  name: "Manger",
  components: { SmartDateFilter },
  mixins: [CopyComponentId, QueryBuilder, FiltersHandling, Project],
  data() {
    return {
      menuProps: DEFAULT_MENU_PROPS,
      copyComponentId: "h12i41y2u-9eisa-0das",
      date_modal: false,
      search: "",
      loading: false,
      dialog_apt: false,
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "GPM",
          disabled: true,
        },
        {
          text: "Managers dashboard",
          disabled: true,
        },
      ],
      filtersData: {
        niches: [],
        performers: [],
        main_seo: [],
        projects: [],
      },
      isFetchedOnce: false,
      loadings: {
        filters: false,
        aggregate: false,
        table: false,
      },
      apt_headers: [
        { text: "Url", value: "url" },
        { text: "Anchor", value: "anchor" },
        { text: "Donor", value: "donor" },
        { text: "", value: "group" },
        { text: "Status", value: "status_name" },
        { text: "Problems", value: "problems" },
      ],
      apt_items: [],
      modal: {
        shown: false,
      },
      filter: {
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        main_seo: [],
        team: [],
        projects: null,
        subproject: null,
        statuses: [],
        performer: undefined,
        niche: undefined,
        type: 1,
      },
      items: [],
      headers: [],
      types_items: [
        { text: "GP", value: 1 },
        { text: "3rd", value: 2 },
        { text: "Research Hub", value: 3 },
      ],
    };
  },
  computed: {
    projects() {
      return this.$store.state.project.allowed_projects;
    },
  },
  methods: {
    getErrorPercentageColor(percent) {
      if (percent >= 0 && percent <= 25) return { "success--text": true };
      if (percent > 25 && percent <= 50) return { "warning--text": true };
      if (percent > 50) return { "error--text": true };
      return {};
    },
    handleCopyAggregateData(itemsData, linkType) {
      try {
        let result = "";

        const items = deepClone(itemsData);

        items.forEach((item) => {
          const pathPrefix = "https://app.serpinsider.com/gpm/managers-gpm";
          const pathQueries = `?is_gp=1&hide_closed=1&project=${item.project_id}&donor_not_assigned=1&date=${this.filter.date_range[0]},${this.filter.date_range[1]}`;

          result += `===== ${item.name} ===== \n`;

          if (linkType === "markdown") {
            result += `[${item.total} — Project Unassigned](${
              pathPrefix + pathQueries
            }) \n`;
          }

          if (linkType === "html") {
            result += `<a href="${pathPrefix + pathQueries}" target="_blank">${
              item.total
            } — Project Unnasigned</a> \n`;
          }

          result += "\n";
        });

        copy(result);

        this.$message.notification({
          title: "Copied",
          text: `Aggregate data copied to the clipboard.`,
          type: "success",
        });
      } catch (e) {
        console.error(e);
      }
    },
    async fetchAggregateData(type) {
      try {
        const url = "/dashboards/gpm/manager-outreach/aggregate";

        const payload = {
          date: this.filter.date_range,
        };

        this.loadings.aggregate = true;

        const resp = await service.post(url, payload);

        if (resp) {
          this.handleCopyAggregateData(resp.data.items, type);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.aggregate = false;
      }
    },
    handleCopyTableToClipboard(type) {
      try {
        const bannedHeaders = ["id", "in_work"];

        let result = "";

        const items = deepClone(this.items);

        const headers = {};

        this.headers.forEach((header) => {
          headers[header.value] = header;
        });

        items.forEach((item) => {
          Object.entries(item).forEach(([key, value]) => {
            if (bannedHeaders.includes(key)) return;

            const hasOverdue =
              Object.values(item).filter((v) => {
                return (
                  typeof v === "object" &&
                  !Array.isArray(v) &&
                  v.overdue !== "-" &&
                  v.overdue !== 0
                );
              }).length > 0;

            if (key === "name" && hasOverdue)
              return (result += `===== ${value} ===== \n`);

            const header = headers[key];

            if (value && (!value.overdue || value.overdue === "-")) return;

            result += `${
              type === "markdown"
                ? this.formateDonorLink(header, value, item.project_id).markdown
                : this.formateDonorLink(header, value, item.project_id).link
            }\n`;
          });

          result += "\n";
        });

        copy(result);

        this.$message.notification({
          title: "Copied",
          text: `Table copied to the clipboard.`,
          type: "success",
        });
      } catch (e) {
        console.error(e);
      }
    },
    formateDonorLink(tableHeaderItem, tableRowItem, projectId) {
      const path = this.getLinkToDonor(
        tableRowItem.status_id,
        tableRowItem.id,
        projectId
      );

      const overdue =
        tableRowItem.overdue && tableRowItem.overdue !== "-"
          ? tableRowItem.overdue + " - "
          : "";

      return {
        link: `<a href="${path}" target="_blank">${tableHeaderItem.text} ${overdue}</a>`,
        markdown: `[${overdue}${tableHeaderItem.text}](https://app.serpinsider.com${path})`,
      };
    },
    handleCopyLink(header, row) {
      const data = this.formateDonorLink(header, row);

      copy(data.markdown);

      this.$message.notification({
        title: "Copied",
        text: `${data.link}`,
        type: "success",
        duration: 10000,
      });
    },
    viewAnchorPlan(aptID) {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan`;

      let config = {
        params: {
          anchorPlanTaskID: aptID,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.apt_items = this.fixAptItems(r.data.items);
          self.dialog_apt = true;
        })
        .finally(() => (self.loading = false));
    },
    getLinkToDonor(status_id, task_id, project_id) {
      const query = this._$buildQuery({
        status: status_id,
        task: task_id,
        project: project_id,
        date: this.filter.date_range,
      });

      return "/gpm/gpm-main-dashboard" + query;
    },
    viewDonors(status_id, task_id, project_id) {
      const path = this.getLinkToDonor(status_id, task_id, project_id);

      window.open(path, "_blank");
    },
    hexToRgbA(hex, opacity = 0.5) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          `,${opacity})`
        );
      }
    },
    fixAptItems(items) {
      const result = items.map((item) => {
        if (item.main_color !== undefined)
          item.main_color = this.hexToRgbA(item.main_color, 0.15);

        if (items.filter((v) => v.donor === item.donor).length > 1) {
          item.group = item.donor;
        } else {
          item.group = "1. Unique";
        }

        return item;
      });
      return result;
    },
    fixHeaders(headers) {
      return headers.map((header) => {
        if (header.color !== undefined)
          header.color = this.hexToRgbA(header.color);

        return header;
      });
    },
    async fetchFilters() {
      const filters = [
        "main_seo",
        "niches",
        "performers",
        "main_statuses",
        "projects",
        "team",
      ];
      const payload = {
        type: "/gpm/task-view/get-dashboard-data",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };

      this.loadings.filters = true;

      const resp = await this.$store.dispatch("global/getFilters", payload);

      this.loadings.filters = false;
      if (resp) {
        this.filtersData = resp;
      }
    },
    fetchData() {
      const url = `${this.$store.state.server_url}/gpm/task-view/get-dashboard-data`;

      const config = {
        params: {
          typeID: this.filter.type,
          filter: this.filter,
          dashboardType: "manager",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.loadings.table = true;

      axios
        .get(url, config)
        .then((r) => {
          this.headers = this.fixHeaders(r.data.headers);
          this.items = r.data.items;
        })
        .finally(() => {
          this.loadings.table = false;
          this.isFetchedOnce = true;
        });
    },
  },
  watch: {
    "filter.projects"() {
      this.filter.subproject = null;
    },
  },
  async mounted() {
    await this.initializeFiltersWatcher("filter");
    this.collectParametersFromQuery("filter");

    this.fetchFilters();
    this.fetchData();
  },
};
</script>

<style lang="scss">
.managers-dashboard {
  .v-data-table {
    thead {
      th {
        font-weight: 600;
        color: #1a1a1a !important;
        font-size: 14px !important;
      }
    }
  }
}
.theme--dark {
  .managers-dashboard {
    .v-data-table {
      thead {
        th {
          color: #ffffff !important;
        }
      }
    }
  }
}
.managers {
  &__colored-th {
    padding: 10px !important;
  }

  &__table {
    tbody {
      tr {
        td {
          &:first-child {
            white-space: nowrap;
            text-align: left !important;
          }
        }
      }
    }
  }
}

.fixed-table-gpm-d thead > tr > th:first-child,
.fixed-table-gpm-d thead > tr > th:nth-child(2),
.fixed-table-gpm-d td:first-child,
.fixed-table-gpm-d td:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
}

.fixed-table-gpm-d td:nth-child(2),
.fixed-table-gpm-d thead > tr > th:nth-child(2) {
  left: 56px;
  background-color: #fff;
}

.v-application.theme--dark {
  .fixed-table-gpm-d thead > tr > th:first-child,
  .fixed-table-gpm-d thead > tr > th:nth-child(2),
  .fixed-table-gpm-d td:first-child,
  .fixed-table-gpm-d td:nth-child(2) {
    background-color: #1e1e1e;
  }

  .fixed-table-gpm-d td:nth-child(2),
  .fixed-table-gpm-d thead > tr > th:nth-child(2) {
    background-color: #1e1e1e;
  }
}
</style>
