export const SEO_STRATEGY_ENDPOINTS = {
  GET_STRATEGY: "/fdx/seo-strategy",
  GET_STRATEGY_BY_ID: (id) => `/fdx/seo-strategy/${id}`,
  PUT_STRATEGY: "/fdx/seo-strategy/update",
};

// {
//   "id": 1,
//   "step": "SEMANTIC",
//   "link": null,
//   "checkbox": 1,
//   "project_id": 917,
//   "subproject_id": 4592,
//   "created_at": "2024-07-30T09:08:10.000000Z",
//   "updated_at": null,
//   "project_name": "testcasinos.org",
//   "subproject_name": "CA-EN"
// }

export const SEO_STRATEGY_DATA_TABLE_HEADERS = [
  {
    text: "Step",
    value: "step",
  },
  {
    text: "Project",
    value: "project_name",
  },
  {
    text: "Subproject",
    value: "subproject_name",
  },
  {
    text: "Link",
    value: "link",
  },
];

export const SEO_STRATEGY_PAGE_CONFIG = {
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "SEO Strategy",
      disabled: true,
    },
  ],
  title: "SEO Strategy",
};
