export default {
    state: {
        settings: typeof localStorage.getItem('serp.position.settings') === 'string' && localStorage.getItem('serp.position.settings') !== 'undefined' ? JSON.parse(localStorage.getItem('serp.position.settings')) : null
    },
    actions: {
        update_ls_serp_setting(ctx, value) {
            ctx.commit('updateLsSerpSetting', value)
        }
    },
    mutations: {
        updateLsSerpSetting(state, value) {
            localStorage.setItem('serp.position.settings', JSON.stringify(value));
            state.settings = value;
        }
    }
}