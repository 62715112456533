<template>
  <v-menu
    v-model="menuModel"
    offset-y
    bottom
    origin="center center"
    transition="slide-x-transition"
    :close-on-content-click="false"
    rounded="lg"
    content-class="shadow-e2-bordered"
  >
    <template v-slot:activator="{ on, attrs }">
      <template v-if="!defaultType">
        <v-btn
          v-on="on"
          v-bind="attrs"
          outlined
          color="transparent"
          :class="`custom-date-button${menuModel ? ' focused' : ''}`"
          :disabled="disabled"
          block
        >
          <label
            :class="`custom-date-button__label${
              dateModel.length !== 0 ? ' active' : ''
            }`"
          >
            <v-icon x-small>mdi-calendar-range</v-icon>
            {{ title ? title : "Date" }}
          </label>
          <v-icon v-if="dateModel.length === 0" small class="mr-2"
            >mdi-calendar-range</v-icon
          >
          <span>
            <template v-if="dateModel.length > 0">
              <template v-if="!range">
                {{ $moment(dateModel[0]).format("YY-MM-DD") }}
                <v-icon x-small color="rgba(77,77,77,0.47)">
                  mdi-arrow-right
                </v-icon>
                {{ $moment(dateModel[1]).format("YY-MM-DD") }}
              </template>
              <template v-else>
                {{ $moment(dateModel[0]).format("YY-MM-DD") }}
              </template>
            </template>
            <template v-else>
              {{ title ? title : "Date" }}
            </template>
          </span>
        </v-btn>
      </template>
      <template v-else>
        <v-text-field
          v-on="on"
          label="Date range"
          v-model="dateModel"
          dense
          hide-details
        ></v-text-field>
      </template>
    </template>
    <div class="positions-dropdown__wrapper">
      <div class="calendar-wrapper section">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="slide-y-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateModel"
              label="Select date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateModel"
            :reactive="true"
            :range="!range"
            @change="saveHandler"
          ></v-date-picker>
        </v-menu>
      </div>
      <v-divider></v-divider>
      <div class="presets-wrapper section">
        <v-row>
          <v-col cols="7">
            <v-menu offset-y rounded>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small v-bind="attrs" v-on="on" class="preset-btn">
                  Select preset
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <div class="month-select-menu">
                <template v-if="customPresets.length > 0">
                  <v-list>
                    <v-subheader>Custom</v-subheader>
                    <v-list-item
                      v-for="item in customPresets"
                      :key="item.id"
                      @click="setCustomDate(item.options)"
                    >
                      {{ item.name }}
                    </v-list-item>
                  </v-list>
                  <v-divider />
                </template>
                <v-list>
                  <v-subheader>Current</v-subheader>
                  <v-list-item @click="setDate('curr', ['month'])">
                    Month
                  </v-list-item>
                  <v-list-item @click="setDate('curr', ['Q'])">
                    Quarter
                  </v-list-item>
                  <v-list-item @click="setDate('curr', ['years'])">
                    Year
                  </v-list-item>
                </v-list>
                <v-divider />
                <v-list>
                  <v-subheader>Previous</v-subheader>
                  <v-list-item @click="setDate('prev', ['months', 'month'])">
                    Month
                  </v-list-item>
                  <v-list-item @click="setDate('prev', ['Q', 'Q'])">
                    Quarter
                  </v-list-item>
                  <v-list-item @click="setDate('prev', ['years', 'years'])">
                    Year
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </v-col>
          <v-col cols="5">
            <v-btn plain small color="error" @click="clearDate"
              >Clear date</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <template v-if="singleOption">
        <v-divider></v-divider>
        <div class="presets-wrapper section">
          <v-switch
            v-model="range"
            label="Single"
            dense
            hide-details
            style="margin: 0; padding: 0"
          ></v-switch>
        </div>
      </template>
      <v-divider></v-divider>
      <div class="from-to-wrapper section">
        <v-row>
          <v-col offset="1" cols="4" class="flex-start"> From </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              label="Date"
              placeholder="YYYY-MM-DD"
              v-model="fromDateModel"
              dense
              class="from-to__input"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col offset="1" cols="4" class="flex-start"> To </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              label="Date"
              placeholder="YYYY-MM-DD"
              v-model="toDateModel"
              dense
              class="from-to__input"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <template v-if="dateModel.length > 1">
        <v-divider></v-divider>
        <div class="date-tooltip-wrapper section">
          <span>{{ $moment(dateModel[0]).format("dddd MMM DD YYYY") }}</span>
          <v-icon x-small color="rgba(0, 0, 0, 0.20)">mdi-arrow-right</v-icon>
          <span>{{ $moment(dateModel[1]).format("dddd MMM DD YYYY") }}</span>
        </div>
      </template>
    </div>
  </v-menu>
</template>

<script>
import { MOCK_SELECT_ITEMS } from "@/utils/defaultData";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    defaultType: {
      type: Boolean,
      default: false,
    },
    singleOption: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customPresets: {
      type: Array,
      default() {
        return [];
      },
    },
    presetSingle: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    range() {
      this.dateModel = [];
    },
    fromDateModel(string) {
      if (!this.dateModel) {
        this.dateModel = [];
      }
      this.dateModel = [string, this.dateModel[1] ? this.dateModel[1] : ""];
    },
    toDateModel(string) {
      if (!this.dateModel) {
        this.dateModel = [];
      }
      this.dateModel = [this.dateModel[0] ? this.dateModel[0] : "", string];
    },
  },
  computed: {
    is_dark_mode() {
      return this.$vuetify.theme.dark;
    },
    dateModel: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  created() {
    this.range = this.presetSingle;
  },
  data: () => ({
    menuModel: false,
    fromString: "",
    range: false,
    menu: false,
    loading: false,
    items: MOCK_SELECT_ITEMS,
    fromDateModel: "",
    toDateModel: "",
  }),
  methods: {
    setCustomDate(options) {
      const { action, from, type, amount } = options;

      let date;
      if (from === "today") {
        date = new Date();
      } else {
        date = new Date(from);
      }

      const mDate = this.$moment(date);
      const start = mDate[action](amount, type)
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(date).format("YYYY-MM-DD");
      return (this.dateModel = [start, end]);
    },
    apply() {
      this.dateModel = [
        this.fromString ? this.fromString : this.dateModel[0],
        this.dateModel[1],
      ];
    },
    saveHandler() {},
    clearDate() {
      this.dateModel = [];
    },
    setDate(period, options) {
      this.menuModel = false;
      const [pos, subtractName] = options;
      if (period === "prev") {
        const start = this.$moment(new Date())
          .subtract(1, subtractName)
          .startOf(pos)
          .format("YYYY-MM-DD");
        const end = this.$moment(new Date())
          .subtract(1, subtractName)
          .endOf(pos)
          .format("YYYY-MM-DD");
        return (this.dateModel = [start, end]);
      }
      const start = this.$moment(new Date()).startOf(pos).format("YYYY-MM-DD");
      const end = this.$moment(new Date()).endOf(pos).format("YYYY-MM-DD");
      return (this.dateModel = [start, end]);
    },
  },
};
</script>
