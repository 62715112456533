<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <Chart/>
    </v-container>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
            project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

  </div>
</template>

<script>
import Chart from "./Chart";

let dateFormat = require('dateformat');

let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
let beforeOneWeek2 = new Date(beforeOneWeek);
let day = beforeOneWeek.getDay()
let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 50))
let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

export default {
  name: "Conversion4Index",
  components: {Chart},
  data: () => ({
    search: '',
    loadings: {
      fetch_data: false,
      table: false,
    },
    date_modal: false,
    filter: {
      date_range: [
        dateFormat(lastMonday, "yyyy-mm-dd"),
        dateFormat(lastSunday, "yyyy-mm-dd")
      ],
      url: undefined,
      table: undefined,
      brand: undefined,
      position: undefined,
    },
    urls: [],
    tables_name: [],
    brands: [],
    positions: [],
  }),
  methods: {
    fetchTableData() {
      this.loadings.table = true
      setTimeout(() => this.loadings.table = false, 2000)
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    }
  },
  created() {
    this.fetchTableData()
  },
}
</script>

<style scoped>

</style>