<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    bottom
    offset-y
    content-class="my-shadow--e3"
    transition="slide-y-transition"
    max-width="300"
  >
    <template #activator="{ on }">
      <div v-on="on">
        <v-text-field
          ref="input"
          hide-details="auto"
          :error-messages="errorMessages"
          dense
          :outlined="!filled"
          :filled="filled"
          :label="label"
          :value="getTextValue"
          clearable
          @blur="handleFixValues"
          @keydown.enter="blurInput"
          @click:clear="handleClear"
          append-icon="mdi-arrow-left-right"
          v-bind="$attrs"
        ></v-text-field>
      </div>
    </template>
    <v-card class="styled-card--default">
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model="value[0]"
              hide-details
              dense
              outlined
              label="from"
              type="number"
              class="input-rounded-1"
              @blur="handleBlurFrom"
            ></v-text-field>
          </v-col>
          <v-col
            style="max-width: 38px"
            class="d-flex justify-center align-center"
          >
            <v-icon>mdi-arrow-left-right</v-icon>
          </v-col>
          <v-col>
            <v-text-field
              v-model="value[1]"
              hide-details
              dense
              outlined
              class="input-rounded-1"
              label="to"
              type="number"
              @blur="handleBlurTo"
            ></v-text-field>
          </v-col>

          <v-col
            style="max-width: 38px"
            class="d-flex justify-center align-center"
          >
            <v-icon @click="dialog = true" title="Shortcuts guide"
              >mdi-information-outline</v-icon
            >
          </v-col>
        </v-row>
        <v-row dense v-if="presets.length > 0">
          <v-col>
            <div class="range-presets">
              <v-list dense>
                <v-subheader>Presets</v-subheader>
                <v-list-item dense>
                  <v-radio-group
                    hide-details
                    style="margin-top: 0"
                    dense
                    v-model="value"
                  >
                    <v-radio
                      v-for="preset in presets"
                      :key="preset[0]"
                      :label="preset[0]"
                      :value="preset[1]"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-dialog
        v-model="dialog"
        max-width="375"
        content-class="remove-dialog-shadow pa-3"
      >
        <v-card class="styled-card--default">
          <v-card-title>Shortcuts</v-card-title>
          <v-divider />
          <v-card-text class="px-0 pb-0">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Shortcut</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>10 20</div>
                    <div>10,20</div>
                    <div>10~20</div>
                  </td>
                  <td>
                    <v-chip label small class="mr-2">from 10</v-chip>
                    <v-chip label small>to 20</v-chip>
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>
                    <v-chip label small class="mr-2">from 10</v-chip>
                    <v-chip label small>to 10</v-chip>
                  </td>
                </tr>
                <tr>
                  <td>10 -1</td>
                  <td>
                    <v-chip label small class="mr-2">from 10</v-chip>
                    <v-chip label small>to infinity</v-chip>
                  </td>
                </tr>
                <tr>
                  <td>10+</td>
                  <td>
                    <v-chip label small class="mr-2">from 10</v-chip>
                    <v-chip label small>to infinity</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-divider />
          <v-card-actions class="d-flex justify-end">
            <v-chip @click="dialog = false" color="success" label width="90px">
              gotcha <v-icon small right>mdi-thumb-up</v-icon>
            </v-chip>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {},
    filled: Boolean,
    errorMessages: Array,
    presets: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["change"],
  data: () => ({
    dialog: false,
    menu: false,
  }),
  created() {
    if (!Array.isArray(this.modelValue)) this.modelValue = [0, 0];
    // if (Array.isArray(this.modelValue) && this.modelValue.length < 2)
    //   this.modelValue = [0, 0];
  },
  computed: {
    getTextValue() {
      const empty = JSON.stringify([null, null]);
      const newValue = JSON.stringify(this.value);

      if (newValue === empty) return "";

      if (this.value[1] === -1) {
        return this.value[0] + "+";
      }

      return this.value[0] + " ~ " + this.value[1];
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
  },
  watch: {
    menu(value) {
      if (!value) {
        const event = {
          target: {
            value: this.getTextValue,
          },
        };
        this.handleFixValues(event);
      }
    },
    value(value) {
      const exist = this.exist;

      if (Array.isArray(value) && parseInt(value[1]) === -1) {
        return;
      }

      if (
        Array.isArray(value) &&
        parseInt(value[1]) !== -1 &&
        parseInt(value[0]) > parseInt(value[1])
      ) {
        this.value = [parseInt(value[0]), parseInt(value[0])];
      }

      if (!exist(this.value[1]) && exist(this.value[0])) {
        this.value[1] = this.value[0];
      }

      if (!exist(this.value[0]) && exist(this.value[1])) {
        this.value[0] = 0;
      }

      this.$forceUpdate();
    },
  },
  methods: {
    exist(data) {
      return (
        data !== null &&
        data !== undefined &&
        data !== "" &&
        data !== "null" &&
        data !== "undefined"
      );
    },
    localForceUpdate() {
      const fakeEvent = {
        target: { value: this.getTextValue },
      };

      this.handleFixValues(fakeEvent);
      this.$forceUpdate();
    },
    handleBlurFrom({ target: { value } }) {
      const exist = this.exist;
      if (!exist(this.value[0]) && exist(this.value[1])) {
        this.value[0] = 0;
        return this.localForceUpdate();
      }

      if (!value) {
        this.value[0] = null;
      }
    },
    handleBlurTo({ target: { value } }) {
      const exist = this.exist;

      if (!exist(this.value[1]) && exist(this.value[0])) {
        this.value[1] = this.value[0];
        return this.localForceUpdate();
      }

      if (!value) {
        this.value[1] = null;
      }
    },
    blurInput() {
      this.$refs.input.blur();
    },
    handleFixValues({ target: { value } }) {
      value = value.replace(/\s+/g, " "); // replace multiple space to single
      value = value.trim();

      if (typeof value === "string" && value === "") {
        return (this.value = [null, null]);
      }

      if (typeof value === "string" && value === " ") {
        return (this.value = [null, null]);
      }

      if (value.includes("+")) {
        return (this.value = [value.split("+")[0], -1]);
      }

      if (value.includes("~")) {
        return (this.value = value
          .split("~")
          .map((v, idx, arr) => this.stringToInt(v, idx, arr)));
      }

      if (value.includes(",")) {
        return (this.value = value
          .split(",")
          .map((v, idx, arr) => this.stringToInt(v, idx, arr)));
      }

      if (value.includes(" ")) {
        return (this.value = value
          .split(" ")
          .map((v, idx, arr) => this.stringToInt(v, idx, arr)));
      }

      this.value = [parseInt(value), parseInt(value)];
    },
    stringToInt(v, idx, arr) {
      const exist = this.exist;
      if (idx === 0 && !exist(arr[0].trim()) && exist(arr[1].trim())) {
        return 0;
      }

      if (idx === 1 && exist(arr[0].trim()) && !exist(arr[1].trim())) {
        return 0;
      }

      if (isNaN(v) || v === "") {
        return null;
      }
      return parseInt(v);
    },
    handleClear(e) {
      e.preventDefault();
      this.value = [null, null];
    },
  },
};
</script>
