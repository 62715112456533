<template>
  <v-chip :color="getColor">
    {{ value ? $moment(value).format("MMM DD") : "" }}
  </v-chip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    color: {},
    details: {},
  },
  computed: {
    getColor() {
      if (this.color) return this.color;

      if (this.details?.overdue) return "error";

      return "transparent";
    },
  },
};
</script>
