<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12" md="12">
          <v-card
              class="mx-auto"
              v-if="isset_active_project"
          >
            <v-card-text class="headline font-weight-bold">
              <v-row
                  class="align-end"
              >
                <v-col
                    sm="10"
                >
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      solo
                      class="align-end"
                  ></v-text-field>
                </v-col>
                <v-col
                    sm="1"
                >

                  <v-menu
                      transition="slide-x-transition"
                      :close-on-content-click="false"
                      :nudge-left="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-fab-transition>
                        <v-btn
                            color="info"
                            dark
                            small
                            top
                            right
                            fab
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>mdi-filter</v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-list>
                          <v-list-item>
                            <v-dialog
                                ref="dialog"
                                v-model="date_modal"
                                max-width="290"
                                persistent

                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter.date_range"
                                    label="Picker in dialog"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="filter.date_range"
                                  range
                                  scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="date_modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="date_modal = false"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-list-item>
                          <v-list-item>
                            <v-autocomplete
                                v-model="filter.brand"
                                :items="brands"
                                chips
                                small-chips
                                label="Brand"
                                solo
                                item-text="brand"
                                item-value="brand"
                                clearable
                                deletable-chips
                            ></v-autocomplete>
                          </v-list-item>
                        </v-list>
                        <div class="text-center">
                          <v-btn
                              @click="fetch_data"
                              color="primary">
                            Apply
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row
                  align="center"
                  justify="center"
              >
                <v-col>
                  <div class="section">
                    <v-data-table
                        :headers="table.headers"
                        :items="table.items"
                        :search="search"
                        :sort-by="['visits']"
                        :sort-desc="[true]"
                        :footer-props="{itemsPerPageOptions: [ 50, -1]}"
                        :items-per-page="50"
                        class="elevation-1 fixed-table-sw-d"
                        dense
                    >
                      <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.domain">
                          <td>
                            {{ item.name }}
                          </td>
                          <td class="text-right">
                            {{ item.visits }}
                          </td>
                          <td class="text-right">
                            {{ item.registrations }}
                          </td>
                          <td class="text-right">
                            {{ item.ndc }}
                          </td>
                          <td class="text-right" :style="'background-color:'+item.earnings_color">
                            {{ item.earnings }}
                          </td>
                          <td class="text-right" :style="'background-color:'+item.r_v_color">
                            {{ item.r_v }}%
                          </td>
                          <td class="text-right">
                            {{ item.v_n }}
                          </td>
                          <td class="text-right" :style="'background-color:'+item.r_n_color">
                            {{ item.r_n }}%
                          </td>

                        </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
            project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>

import axios from "axios";
import eventPipe from "../../../events/eventPipe";


let dateFormat = require('dateformat');

let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
let beforeOneWeek2 = new Date(beforeOneWeek);
let day = beforeOneWeek.getDay()
let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 6))
let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

export default {
  name: "Conversion5Index",
  data() {
    return {
      search: '',
      loadings: {
        fetch_data: false,
      },
      date_modal: false,
      filter: {
        date_range: [
          dateFormat(lastMonday, "yyyy-mm-dd"),
          dateFormat(lastSunday, "yyyy-mm-dd")
        ],
        subproject_id: undefined,
        brand:undefined
      },
      upload: {modal: false},
      urls: [],
      countries: [],
      brands: [],
      table: {
        headers: [
          {text: 'Brand name', value: 'name', class: 'text-left'},
          {text: 'Visits', value: 'visits', class: 'text-right'},
          {text: 'Regs', value: 'registrations', class: 'text-right'},
          {text: 'Ndc', value: 'ndc', class: 'text-right'},
          {text: 'Pub revenue', value: 'earnings', class: 'text-right'},
          {text: 'R/V %', value: 'r_v', class: 'text-right'},
          {text: 'V / N', value: 'v_n', class: 'text-right'},
          {text: 'N/R, %', value: 'r_n', class: 'text-right'},
        ],
        items: [],
      },

    }
  },
  methods: {
    fetch_data() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {

        if (self.filter.subproject_id === undefined) {
          self.filter.subproject_id = self.subprojects[0]['id']
        }

        self.update_loading('fetch_data', true)

        let url = `${self.$store.state.server_url}/analytics/conversion/report5/${self.id_project}`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {filter: self.filter}

        axios.post(url, data, config)
            .then(function (response) {
              self.table.items = response.data;
              self.update_loading('fetch_data', false)
            }).catch((error) => {
          self.update_loading('fetch_data', false)
          alert(error)
        });
      }
    },
    fetch_filter() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {


        self.update_loading('fetch_filter', true)

        let url = `${self.$store.state.server_url}/analytics/conversion/report5/filter/${self.id_project}`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {filter: self.filter}

        axios.post(url, data, config)
            .then(function (response) {
              self.brands = response.data;
              self.update_loading('fetch_filter', false)
            }).catch((error) => {
          self.update_loading('fetch_filter', false)
          alert(error)
        });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false
      this.loadings[type] = value
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true
        }
      }
    },
    update_setting() {
      this.$store.dispatch('update_ls_serp_setting', this.ls_settings)
    }
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    subprojects() {
      let project = this.$store.state.project.allowed_projects.filter((v) => v.id === parseInt(this.$store.state.project.active_project))
      if (project && project[0])
        return project[0]['subprojects']
      else return []
    }
  },
  created() {
    let self = this;
    self.fetch_filter()

    eventPipe.$on('update-active-project', () => {
      self.fetch_data()
    })

  },
}
</script>

<style scoped>
.fixed-table-sw-d thead > tr > th:first-child,
.fixed-table-sw-d td:first-child {
  position: sticky;
  left: 0;
  min-width: 90px;
  z-index: 2;
}
</style>