<template>
  <div>
    <div v-if="isset_active_project">
      <div class="d-flex justify-lg-space-between px-3">
        <div>
          <v-subheader class="pa-0"> Deleted links Report </v-subheader>
        </div>
        <div>
          <v-menu
            transition="slide-x-transition"
            :close-on-content-click="false"
            :nudge-right="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-fab-transition>
                <v-btn color="info" dark fab v-bind="attrs" v-on="on">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
            <v-card>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-dialog
                      ref="dialog"
                      v-model="date_modal"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filter.date_range"
                          label="Date add"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.date_range"
                        range
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="date_modal = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="date_modal = false">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-list-item>
                  <v-list-item>
                    <v-dialog
                      ref="dialog"
                      v-model="date_modal_last"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filter.last_on_page"
                          label="Last on page"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.last_on_page"
                        range
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="date_modal_last = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="date_modal_last = false"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-list-item>
                  <v-list-item>
                    <v-select
                      v-model="filter.project"
                      :items="getProjects"
                      item-text="name"
                      item-value="id"
                      label="Project"
                      solo
                      multiple
                      clearable
                    ></v-select>
                  </v-list-item>
                  <v-list-item>
                    <v-select
                      v-model="filter.type"
                      :items="data.types_items"
                      item-text="name"
                      item-value="id"
                      label="Type"
                      solo
                      clearable
                    ></v-select>
                  </v-list-item>
                </v-list>
                <div class="text-center">
                  <v-btn @click="fetch_data" color="primary"> Apply </v-btn>
                  <v-btn class="ml-2" @click="export_data" icon>
                    <v-icon> mdi-cloud-download-outline </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <v-container fluid>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          solo
          class="mb-4"
        ></v-text-field>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="items"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100, 250, 500, -1] }"
          :items-per-page="50"
          class="elevation-1"
          :item-class="row_classes"
          dense
        >
          <template v-slot:item.check_link="{ item }">
            <v-switch
              hide-details
              dense
              @change="update_check_link(item.id, item.check_link)"
              v-model="item.check_link"
            ></v-switch>
          </template>

          <template v-slot:item.thematic="{ item }">
            <span v-html="get_thematic(item)"></span>
          </template>

          <template v-slot:item.server_response="{ item }">
            <span>{{ item.server_response }}</span>
            <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"
                    >mdi-information-outline
                  </v-icon>
                </template>
                <div>
                  <div>Last On Page: {{ item.last_on_page }}</div>
                  <div>Last Check On Page: {{ item.last_check_on_page }}</div>
                </div>
              </v-tooltip>
            </span>
          </template>

          <template v-slot:item.all_percent="{ item }">
            <span
              :title="`${item.all_percent}% - ${item.all_acceptor} of acceptor url topics ${item.all_donor}`"
              >{{ item.all_percent }}%
            </span>
          </template>

          <template v-slot:item.external_link="{ item }">
            <span v-html="get_spam(item)"></span>
          </template>

          <template v-slot:item.price="{ item }">
            <span v-html="get_price(item.price)"></span>
          </template>

          <template v-slot:item.email="{ item }">
            <p style="margin: 0px; font-size: 10px">{{ item.email }}</p>
            <p style="margin: 0px; font-size: 10px">{{ item.login }}</p>
            <p style="margin: 0px; font-size: 10px">{{ item.password }}</p>
          </template>

          <template v-slot:item.forced_on_page="{ item }">
            <v-menu top offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ma-2" v-bind="attrs" v-on="on">mdi-cog </v-icon>
              </template>
              <v-list>
                <v-list-item
                  @change="
                    update_link_data(
                      'forced_on_page',
                      item.id,
                      item.forced_on_page
                    )
                  "
                >
                  <v-list-item-action>
                    <v-switch v-model="item.forced_on_page"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Forced On Page </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @change="
                    update_link_data(
                      'previous_month',
                      item.id,
                      item.previous_month
                    )
                  "
                >
                  <v-list-item-action>
                    <v-switch v-model="item.previous_month"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Previous Month </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action
                    @click="update_link_data('update_info', item.id)"
                  >
                    <v-btn icon>
                      <v-icon>mdi-reload </v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-title>Update Info </v-list-item-title>
                </v-list-item>

                <v-list-item
                  @change="
                    update_link_data('trouble', item.id, item.is_trouble)
                  "
                >
                  <v-list-item-action>
                    <v-switch v-model="item.is_trouble"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title
                    >Trouble
                    <span v-if="item.status_id > 0">
                      -> "{{ trouble_status[item.status_id] }}"</span
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-container>
    </div>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "../../../events/eventPipe";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "DeletedLinks",
  created() {
    let self = this;

    self.fetch_types_list();

    eventPipe.$on("update-active-project", () => {
      self.fetch_data();
    });

    self.filter.project = [parseInt(self.id_project)];
  },
  data() {
    return {
      search: undefined,
      loading: false,
      loadings: {
        fetch_data: false,
      },
      date_modal: false,
      date_modal_last: false,
      data: {
        types_items: [],
      },
      filter: {
        date_range: [
          dateFormat(new Date().setMonth(date.getMonth() - 2), "yyyy-mm-dd"),
          dateFormat(date, "yyyy-mm-dd"),
        ],
        last_on_page: [],
        project: [],
        type: undefined,
      },
      items: [],
      trouble_status: {
        1: "new",
        2: "fix",
        3: "not fix",
      },
      currency: { USD: 1, EUR: 1.8 },
      headers: [
        { text: "Date", title: "Date", value: "date", class: "text-center" },
        {
          text: "Acceptor Url",
          title: "Date",
          value: "acceptor",
          class: "text-center",
        },
        {
          text: "Phrase",
          title: "Phrase",
          value: "phrase",
          class: "text-left",
        },
        {
          text: "Anchor (in plan)",
          title: "Anchor (in plan)",
          value: "phrase_plan",
          class: "text-left",
        },
        {
          text: "Anchor Type",
          title: "Anchor Type",
          value: "anchor_type",
          class: "text-left",
        },
        {
          text: "CL",
          title: "Check Link",
          value: "check_link",
          class: "text-left",
        },
        {
          text: "Donor Url",
          title: "Date",
          value: "donor",
          class: "text-center",
        },
        { text: "Type", title: "Date", value: "type", class: "text-center" },
        {
          text: "Server Response",
          title: "Date",
          value: "server_response",
          class: "text-center",
        },
        {
          text: "Th",
          title: "Thematic",
          value: "thematic",
          class: "text-center",
        },
        {
          text: "Common theme",
          title: "Common theme",
          value: "all_percent",
          class: "text-center",
        },
        {
          text: "Spam",
          title: "Spam",
          value: "external_link",
          class: "text-center",
        },
        {
          text: "GI",
          title: "GI",
          value: "donorUrIActualGI",
          class: "text-center",
        },
        { text: "NF", title: "NF", value: "nofollow", class: "text-center" },
        { text: "NI", title: "NI", value: "noindex", class: "text-center" },
        {
          text: "Tags S U",
          title: "Tags_s_u",
          value: "tags_s_u",
          class: "text-left",
        },
        {
          text: "Url Equal Canonical",
          title: "url_equal_canonical",
          value: "url_equal_canonical",
          align: "left",
        },
        { text: "DR", title: "ah_dr", value: "ah_dr", align: "left" },
        {
          text: "GT",
          title: "google_trust",
          value: "google_trust",
          align: "left",
        },
        {
          text: "Top Country",
          title: "top_country",
          value: "top_country",
          align: "left",
        },
        { text: "Traffic", title: "traffic", value: "traffic", align: "left" },
        { text: "Lang", title: "lang", value: "lang", align: "left" },
        {
          text: "Google Index",
          title: "google_index",
          value: "google_index",
          align: "left",
        },
        {
          text: "Sw Visits",
          title: "sw_visits",
          value: "sw_visits",
          align: "left",
        },
        {
          text: "Al GlobalRank",
          title: "al_GlobalRank",
          value: "al_GlobalRank",
          align: "left",
        },
        {
          text: "Sw GlobalRank",
          title: "sw_GlobalRank",
          value: "sw_GlobalRank",
          align: "left",
        },
        { text: "Score", title: "score", value: "score", align: "left" },
        {
          text: "Spam Score",
          title: "spam_score",
          value: "spam_score",
          align: "left",
        },
        {
          text: "User",
          title: "Date",
          value: "performer",
          class: "text-center",
        },
        { text: "Fl Name", title: "FL name", value: "fl_name", align: "left" },
        { text: "Price", title: "Price", value: "price", align: "left" },
        { text: "Email", title: "Email", value: "email", align: "left" },
        {
          text: "Date Add",
          title: "Date",
          value: "dateadd",
          class: "text-center",
        },
        {
          text: "Last check on page",
          title: "Last check on page",
          value: "last_check_on_page",
          class: "text-center",
        },
        {
          text: "Setting",
          title: "Setting",
          value: "forced_on_page",
          align: "left",
        },
      ],
    };
  },
  methods: {
    fetch_types_list() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("types_list", true);

        let url = `${self.$store.state.server_url}/ltt/links-type`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        axios
          .get(url, config)
          .then(function (response) {
            self.data.types_items = response.data;
            self.update_loading("types_list", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    export_data() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          self.dialog_export = false;
          location.href =
            `${self.$store.state.server_url}/export/ltt/delete/index/${self.id_project}?hash=` +
            response.data.hash;
        }
      });

      // let params = new URLSearchParams(self.filter)

      // location.href = `${self.$store.state.server_url}/ltt/export/index/${self.id_project}?${params}`
    },
    fetch_data() {
      let self = this;
      self.update_loading("fetch_data", true);

      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/ltt/deleted_links`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
        };

        axios
          .post(url, data, config)
          .then(function (response) {
            self.items = response.data;
            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    row_classes(item) {
      if (item.donorUrIActualGI === 1) {
        return "green";
      }
    },
    update_link_data(name, link_id, value = null) {
      let self = this;
      self.update_loading("update_link_data", true);
      let url = `${self.$store.state.server_url}/ltt/update_link_data/link/${link_id}/1`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: { name: name, value: value },
      };

      //  let data = {name: name, value: value}

      axios
        .get(url, config)
        .then(function (response) {
          self.anchors_plan = response.data;
          self.update_loading("update_link_data", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    get_price(price) {
      let json = JSON.parse(price);
      if (json !== null) {
        let all = 0;
        let title = "";

        if (json["price"] && json["price"] != null) {
          let rate = 1;

          if (this.currency[json["currency_p"]] === undefined) {
            //  this.fetch_currency(json['currency_p'])
          }

          rate = this.currency[json["currency_p"]];

          all += parseInt(json["price"]) * rate;
          title +=
            "Domain price - " + json["price"] + json["currency_p"] + "\n";
        }
        if (json["content_price"] && json["content_price"] > 0) {
          all += parseInt(json["content_price"]);
          title +=
            "Content - " + json["content_price"] + json["currency_cp"] + "\n";
        }
        if (json["lb_work_price"] && json["lb_work_price"] != null) {
          all += parseInt(json["lb_work_price"]);
          title +=
            "LB Work - " + json["lb_work_price"] + json["currency_lwp"] + "\n";
        }

        return '<span title="' + title + '">' + all + "$</span>";
      } else {
        return "";
      }
    },
    get_spam(item) {
      let spamExternalLink = "?",
        spamEssay = "?",
        spamPorn = "?",
        spamReplica = "?",
        externalLink = "?",
        spamCasino = "?";

      if (item.spam_essay != null) {
        spamEssay = item.spam_essay;
        spamPorn = item.spam_porn;
        spamReplica = item.spam_replica;
        spamCasino = item.spam_casino;
        externalLink = item.external_link;
        spamExternalLink =
          item.spam_replica +
          item.spam_porn +
          item.spam_essay +
          item.spam_casino;
      }

      return (
        "<span title='Number of competitors' >" +
        "C<sup " +
        this.check_value_and_return_color(
          item.number_of_competitors,
          "#d5bc03"
        ) +
        "><b>" +
        item.number_of_competitors +
        "</b></sup></span>" +
        "<span title='essay - " +
        item.spam_date +
        "'>e<sup " +
        this.check_value_and_return_color(item.spam_essay, "red") +
        ">" +
        spamEssay +
        "</sup></span>," +
        "<span title='casino - " +
        item.spam_date +
        "'>c<sup " +
        this.check_value_and_return_color(item.spam_casino, "red") +
        ">" +
        spamCasino +
        "</sup></span>," +
        "<span title='porn - " +
        item.spam_date +
        "'>p<sup " +
        this.check_value_and_return_color(item.spam_porn, "red") +
        ">" +
        spamPorn +
        "</sup></span>," +
        "<span title='replica - " +
        item.spam_date +
        "'>r<sup " +
        this.check_value_and_return_color(item.spam_replica, "red") +
        ">" +
        spamReplica +
        "</sup></span>" +
        "=<span title='spam external link'>" +
        spamExternalLink +
        "</span>/" +
        "<span title='external link'>" +
        externalLink +
        "</span>"
      );
    },
    check_value_and_return_color(val, color) {
      if (val > 0) return "style='color:" + color + "'";
      else return "";
    },
    update_check_link(link_id, value) {
      let self = this;
      self.update_loading("update_check_link", true);

      let url = `${
        self.$store.state.server_url
      }/ltt/check_link/link/${link_id}/${value ? 1 : 0}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("update_check_link", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    get_thematic(item) {
      if (item.thematic === 1)
        return (
          '<i style="color: green" class="mdi mdi-arm-flex" title="title: ' +
          item.title +
          '"></i>'
        );
      if (item.thematic === 0)
        return (
          '<i style="color: red" class="mdi mdi-crop" title="title: ' +
          item.title +
          '"></i>'
        );
      if (item.thematic == null)
        return (
          '<i style="color: grey" class="mdi mdi-progress-question" title="title: ' +
          item.title +
          '"></i>'
        );
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    getProjects() {
      return this.$store.state.project.allowed_projects;
    },
  },
};
</script>

<style scoped>
.red {
  color: red;
}
</style>
