A
<template>
  <td>
    <div v-if="getContent !== null" :class="`colored-td colored-td--clickable ${getColor}`">
      <span v-if="!loading" @click="goTo" class="nowrap">
        {{ getContent }}
        <sup v-if="getRewrite">
          {{ getRewrite }}
        </sup>
      </span>
      <v-btn v-else icon loading></v-btn>
    </div>
  </td>
</template>

<script>
import axios from "axios";

export default {
  props: ["data"],
  data: () => ({
    loading: false,
  }),
  computed: {
    getRewrite() {
      return this.data.content_appear_at
        ? this.data.content_appear_at.rewrite
        : null;
    },
    getData() {
      return this.data.content_appear_at ? this.data.content_appear_at : {};
    },
    getColor() {
      if (this.data.task_closed) return "";
      if (this.getData.expired) return "red";

      return "";
    },
    config() {
      return {
        params: {
          entityID: this.data.gpm_task_id,
          type: "all",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };
    },
    getContent() {
      const date = this.data.content_appear_at.text;
      const hasErrors = this.data.content_appear_at.rewrite;
      const fixedDate = this.$moment(this.data.content_appear_at.text).format(
        "DD/MM"
      );
      if (date) {
        return fixedDate;
      }
      if (!date && hasErrors) {
        return "-";
      }
      return null;
    },
  },
  methods: {
    doErrorMessage() {
      this.$message.notification({
        title: "Error",
        text: "Link not found",
        type: "error",
        duration: 4000,
      });
    },
    async goTo() {
      try {
        this.loading = true;
        const url = `${this.$store.state.server_url}/gpm/entity-view/get-fields`;

        const resp = await axios.get(url, this.config);

        if (!resp) this.doErrorMessage();

        const finalUrl = resp.data.data.fields.find((v) => v.id === 63).value
          .value;

        if (!finalUrl) this.doErrorMessage();

        this.loading = false;

        window.open(finalUrl, "_blank");
      } catch {
        this.loading = false;
        this.doErrorMessage();
      }
    },
  },
};
</script>
