import { render, staticRenderFns } from "./UploadNewDonors.vue?vue&type=template&id=5bf5b414&scoped=true&"
import script from "./UploadNewDonors.vue?vue&type=script&lang=js&"
export * from "./UploadNewDonors.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bf5b414",
  null
  
)

export default component.exports