<template>
  <v-container fluid>
    <div
      class="d-flex flex-column justify-center ma-auto"
      style="max-width: 660px"
    >
      <v-row>
        <v-col cols="12" class="d-flex justify-center align-center">
          <h2>Relevant phrases</h2>
          <a
            href="https://docs.google.com/document/d/1icWXfFNfuQBVk1Aa4W6oU7-W7UfYIK6q8bOYghDjgdU/edit#heading=h.xr5o65t9omwy"
            target="_blank"
          >
            <v-chip class="ml-2" outlined small @click="() => {}">
              <v-icon x-small left>mdi-information</v-icon> guide
              <v-icon x-small right>mdi-open-in-new</v-icon>
            </v-chip>
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="flex: 1" class="d-flex justify-center">
          <v-text-field
            v-model="url"
            hide-details
            filled
            type="relevant_phrases_url"
            label="URL"
            prepend-inner-icon="mdi-link-variant"
            placeholder="Enter URL"
            style="max-width: 560px"
          >
          </v-text-field>
        </v-col>
        <v-col style="flex: 0" class="pl-0">
          <v-chip
            label
            color="primary"
            class="px-6 d-flex justify-center"
            large
            @click="handleFetchKeys"
            style="min-width: 124px"
          >
            <template v-if="loadings.keys">
              <v-progress-circular indeterminate size="18" width="2" />
            </template>
            <template v-else> View keys </template>
          </v-chip>
        </v-col>
      </v-row>
      <v-row v-if="!loadings.keys && successfullyLoaded">
        <v-col>
          <v-card class="styled-card--default">
            <v-card-title> Main phrases for use</v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <template
                v-for="(phrase, idx) in Object.keys(main_phrases_for_use || {})"
              >
                <div :key="`${phrase}-${idx}`" class="px-4 py-2 d-flex">
                  <div>
                    {{ phrase }}
                  </div>
                  <v-spacer />
                  <v-icon small @click="handleCopy(phrase)">
                    mdi-content-copy
                  </v-icon>
                </div>
                <v-divider
                  v-if="
                    idx !== Object.keys(main_phrases_for_use || {}).length - 1
                  "
                  :key="`${phrase}-${idx}-divider`"
                />
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="styled-card--default">
            <v-card-title> Keywords for using near URL</v-card-title>
            <v-divider />
            <v-card-text class="d-flex flex-wrap" style="gap: 8px">
              <template v-for="(keyword, idx) in keywords_for_using_near_url">
                <v-chip
                  :outlined="idx > 2"
                  :color="idx <= 2 ? 'success' : ''"
                  :key="keyword"
                  @click="handleCopy(keyword)"
                >
                  {{ keyword }}
                  <v-icon
                    :style="{ opacity: idx > 2 ? 0.5 : 0.7 }"
                    x-small
                    right
                  >
                    mdi-content-copy
                  </v-icon>
                </v-chip>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="loadings.keys">
        <v-col>
          <div class="skeleton" style="height: 200px"></div>
        </v-col>
        <v-col>
          <div class="skeleton" style="height: 200px"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="styled-card--primary-header">
            <v-card-title>
              <v-icon left color="info" small>mdi-information-outline</v-icon>
              <span class="info--text"> Recomendations </span>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <div v-html="RECOMENDATIONS_HTML"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import copy from "copy-to-clipboard";
import Notification from "@/mixins/Notification";
import service from "@/plugins/service";

export default {
  mixins: [Notification],
  data: () => ({
    RECOMENDATIONS_HTML: `
    <ol>
                <li>
                  If the anchor contains key phrases, it is difficult to write
                  thematic phrases and words near the anchor naturally.
                  Therefore, it is better NOT to write (duplicate) them exactly
                  near the anchor, and use such words, for example, in another
                  sentence.
                </li>
                <li>
                  If we have:
                  <ul>
                    <li>
                      anchor like Papersowl, papersowl.com, click here, read
                      more, etc., it is better to just use these phrases and
                      words closer to the anchor;
                    </li>
                    <li>
                      anchorless like
                      https://papersowl.com/term-paper-writing-service, then it
                      is desirable to naturally enter these phrases, separating
                      the url and these phrases 3-4 words.
                    </li>
                  </ul>
                </li>
                <li>
                  It is desirable not to use the same anchor (key phrase) many
                  times.
                </li>
                <li>
                  If in the list of words Keywords for using near URLs are
                  consecutive words in singular and plural, the singular and
                  plural are considered as one and the same word, and as the
                  third take the next after it. Translated with
                  www.DeepL.com/Translator (free version)
                </li>
              </ol>
    `,
    successfullyLoaded: false,
    url: null,
    keywords_for_using_near_url: null,
    main_phrases_for_use: null,
    loadings: {
      keys: false,
    },
  }),
  methods: {
    handleCopy(data) {
      copy(data);
      this.mSuccess({
        title: "Copied",
        text: `<b class="success--text">${data}</b> copied to clipboard.`,
      });
    },
    async handleFetchKeys() {
      try {
        const url = "/relevant-phrases/get-phrases";
        const payload = {
          url: this.url,
        };

        this.loadings.keys = true;

        const response = await service.post(
          url,
          payload,
          true,
          {},
          { prefix: "/api" },
        );

        this.successfullyLoaded = !!response?.data?.status;

        if (response) {
          this.main_phrases_for_use = response.data.main_phrases_for_use;
          this.processKeywords(response.data.keywords_for_using_near_url);
        }
      } catch (e) {
        console.error("Error while loading relevant phrases.", e);
      } finally {
        this.loadings.keys = false;
      }
    },
    processKeywords(keywords) {
      let tmpr = { ...keywords[1], ...keywords[0] };

      this.keywords_for_using_near_url = Object.keys(tmpr).sort(
        (a, b) => tmpr[a] - tmpr[b],
      );

      console.log(this.keywords_for_using_near_url);
    },
  },
};
</script>
