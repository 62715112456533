<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <h2>GPM Guest Post</h2>
          <v-breadcrumbs class="pa-0" :items="items_breadcrumbs" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            :loading="loading"
            class="shadow-e1-bordered"
            style="overflow: visible"
          >
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
                solo
                class="align-end"
              ></v-text-field>
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                :nudge-right="200"
                v-model="modal.shown"
                :value="modal.shown"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-fab-transition>
                    <v-btn
                      color="info"
                      dark
                      small
                      top
                      right
                      fab
                      absolute
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </template>
                <v-card style="max-width: 500px; min-width: 500px">
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="handleDateChange"
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          dense
                          outlined
                          v-model="filter.projects"
                          :items="projects"
                          item-value="id"
                          item-text="name"
                          label="Projects"
                          chips
                          small-chips
                          deletable-chips
                          clearable
                          multiple
                          hide-details
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          dense
                          outlined
                          v-model="filter.statuses"
                          :items="statuses.statuses"
                          label="Statuses"
                          hide-details
                          chips
                          small-chips
                          deletable-chips
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          dense
                          outlined
                          v-model="filter.responsible"
                          :items="responsibilities"
                          label="Responsible"
                          hide-details
                          clearable
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          dense
                          outlined
                          v-model="filter.niche"
                          :items="niches"
                          item-value="id"
                          item-text="name"
                          label="Niche"
                          hide-details
                          clearable
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          dense
                          outlined
                          v-model="filter.performer"
                          :items="performers"
                          item-value="id"
                          item-text="name"
                          label="Performer"
                          hide-details
                          clearable
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-checkbox
                          v-model="filter.critical_estimate"
                          label="Critical Estimate"
                        ></v-checkbox>
                      </v-list-item>
                    </v-list>
                    <div class="text-center px-4 d-flex justify-space-between">
                      <v-btn
                        @click="saveUserSettings"
                        width="180px"
                        color="primary"
                      >
                        Apply
                      </v-btn>
                      <v-menu open-on-hover offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            class="ml-2"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :loading="export_loading"
                          >
                            <v-icon small>mdi-cloud-download-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="exportPostedLinks">
                            <v-list-item-title>Posted Links</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="dialog_filtered_domains = true">
                            <v-list-item-title
                              >Filtered Domains</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
          </v-card>
          <div v-for="cat_project in cat_projects" :key="cat_project">
            <v-card class="mt-4" v-if="items[cat_project].length > 0">
              <v-card-subtitle>
                <v-row no-gutters style="flex-wrap: nowrap">
                  <v-col
                    cols="2"
                    style="min-width: 100px; max-width: 100%"
                    class="flex-grow-1 flex-shrink-0 pr-3"
                  >
                    <span>
                      {{ cat_projects_data[cat_project] }}
                    </span>
                    <v-progress-circular
                      class="ml-5"
                      :value="
                        (stat[cat_project].c_done * 100) /
                        stat[cat_project].c_all
                      "
                      size="40"
                      >{{ stat[cat_project].c_done }}/{{
                        stat[cat_project].c_all
                      }}
                    </v-progress-circular>
                  </v-col>
                  <v-col
                    cols="1"
                    style="max-width: 30px"
                    class="flex-grow-0 flex-shrink-0 d-flex"
                  >
                    <v-btn icon @click="viewAnchorPlan(cat_project)">
                      <v-icon>mdi-calendar-month-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="1"
                    style="max-width: 30px"
                    class="flex-grow-0 flex-shrink-0 d-flex"
                  >
                    <v-btn icon @click="exportMainKeywords(cat_project)">
                      <v-icon>mdi-cloud-download-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="items[cat_project]"
                  :search="search"
                  :custom-sort="customSort"
                  item-key="name"
                  class="elevation-1"
                  :footer-props="{ 'items-per-page-options': [10, 15, 50, -1] }"
                  :items-per-page="50"
                  hide-default-header
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        :style="
                          item.diff_in_hour >= item.critical_estimate
                            ? 'background-color:#ff00001c;'
                            : ''
                        "
                        v-for="item in items"
                        :key="item.domain"
                      >
                        <td>
                          <div style="white-space: nowrap">
                            <a
                              :href="`http://${item.domain}`"
                              target="_blank"
                              >{{ item.domain }}</a
                            >
                            <v-btn
                              icon
                              :to="`/gpm/view-entity/${item.id}`"
                              link
                              target="_blank"
                            >
                              <v-icon>mdi-login-variant</v-icon>
                            </v-btn>
                            <v-badge
                              v-if="item.messages.unread > 0"
                              :content="item.messages.unread"
                              :value="item.messages.unread > 0"
                              color="error"
                              overlap
                            >
                              <v-icon>mdi-email-outline</v-icon>
                            </v-badge>
                            <v-chip
                              v-if="item.ltt_used"
                              color="warning"
                              title="Ltt Used"
                              class="pa-1 ml-2"
                              x-small
                              label
                              >USED</v-chip
                            >
                            <v-chip
                              v-if="item.has_in_work"
                              @click="showOtherTasks(item.tasks_in_work)"
                              color="info"
                              title="In Work"
                              class="pa-1 ml-2"
                              x-small
                              label
                              >IN WORK</v-chip
                            >
                            <v-chip
                              v-if="item.has_in_other"
                              @click="showOtherTasks(item.tasks_in_other)"
                              color="info"
                              title="Has in other task"
                              class="pa-1 ml-2"
                              x-small
                              label
                              >OTHER</v-chip
                            >
                            <v-chip
                              v-if="item.has_in_hub"
                              color="info"
                              title="Has in Research HUB"
                              class="pa-1 ml-2"
                              x-small
                              label
                              >HUB</v-chip
                            >
                          </div>
                        </td>
                        <td>{{ item.url }}</td>
                        <td>{{ item.phrase }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on">{{ item.cost_task }}</span>
                            </template>
                            <span>Price</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-chip
                            v-if="
                              item.cost_ah_score !== '' &&
                              item.cost_ah_score >= 0 &&
                              item.cost_ah_score <= 50
                            "
                            color="warning"
                            label
                            small
                          >
                            {{ item.cost_ah_score }}
                          </v-chip>
                          <v-chip
                            v-else-if="
                              item.cost_ah_score > 50 &&
                              item.cost_ah_score <= 200
                            "
                            color="info"
                            label
                            small
                          >
                            {{ item.cost_ah_score }}
                          </v-chip>
                          <v-chip
                            v-else-if="item.cost_ah_score > 200"
                            color="success"
                            label
                            small
                          >
                            {{ item.cost_ah_score }}
                          </v-chip>
                        </td>
                        <td>{{ item.status }}{{ item.status_additional }}</td>
                        <td>{{ item.add_datetime }}</td>
                        <td>{{ item.time_in_work_stage }}</td>
                        <td>
                          <v-chip
                            label
                            small
                            :color="
                              responsibilities_colors[item.responsible.role].bg
                            "
                            :style="
                              'color: ' +
                              responsibilities_colors[item.responsible.role]
                                .color
                            "
                          >
                            {{ item.responsible.role }}
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn fixed dark fab bottom right color="pink" @click="openDialog">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      content-class="shadow-e2-bordered"
      scrollable
      max-width="840px"
    >
      <v-card rounded="xl">
        <v-card-title> Upload domains to {{ projectName }} </v-card-title>
        <v-card-text>
          <v-stepper v-model="uploadStepper" elevation="0">
            <v-stepper-header style="box-shadow: none">
              <v-stepper-step :step="1" :complete="uploadStepper > 1">
                Validation
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :step="2" :complete="uploadStepper > 2">
                Upload
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content :step="1">
                <v-row class="py-2">
                  <v-col cols="12">
                    <v-autocomplete
                      outlined
                      dense
                      hide-details
                      label="Anchor Plan Task"
                      v-model="form.anchor_plan_task"
                      :items="anchor_plan_tasks"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="form.domains"
                      label="Domains"
                      outlined
                      hide-details
                      no-resize
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-spacer />
                    <v-btn
                      color="secondary"
                      text
                      @click="dialog = false"
                      class="mr-2"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="primary"
                      width="120px"
                      @click="handleNextUploadStep"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content :step="2">
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      v-model="selectedUploadDomains"
                      :headers="uploadTableData.headers"
                      :loading="loadings.uploadTable"
                      :items="uploadTableData.items"
                      :items-per-page="-1"
                      :hide-default-footer="true"
                      item-key="donor"
                      show-select
                    >
                      <template #[`item.bad_status`]="{ value }">
                        <template
                          v-if="Array.isArray(value) && value.length > 0"
                        >
                          <v-chip
                            v-for="item in value"
                            :key="item"
                            small
                            class="mr-1"
                          >
                            {{ item }}
                          </v-chip>
                        </template>
                      </template>
                      <template #[`item.used_in_my_tasks`]="{ value }">
                        <template
                          v-if="Array.isArray(value) && value.length > 0"
                        >
                          <div v-for="item in value" :key="item">
                            {{ item.project_name }} - {{ item.status_name }} -
                            {{ item.date }}
                          </div>
                        </template>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-spacer />
                    <v-btn
                      color="secondary"
                      text
                      @click="uploadStepper--"
                      class="mr-2"
                    >
                      Back
                    </v-btn>
                    <v-btn color="success" width="120px" @click="upload">
                      Upload
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_apt" persistent scrollable max-width="1000px">
      <v-card>
        <v-card-title> Anchor Plan </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="(header, idx) in apt_headers" :key="idx">
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, idx) in apt_items"
                  :key="idx"
                  :style="'background-color: ' + item.main_color"
                >
                  <td>{{ item.url }}</td>
                  <td>{{ item.anchor }}</td>
                  <td>
                    <a
                      :href="'/gpm/view-entity/' + item.entity_id"
                      target="_blank"
                      >{{ item.donor }}</a
                    >
                  </td>
                  <td>{{ item.status_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog_apt = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="other_tasks_dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title> Tasks </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Task Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in other_tasks" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    <a :href="'/gpm/view-entity/' + item.id" target="_blank">{{
                      item.name
                    }}</a>
                  </td>
                  <td>{{ item.status_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="other_tasks_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_filtered_domains"
      persistent
      scrollable
      max-width="400px"
    >
      <v-card>
        <v-card-title> Export Filtered Domains </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-dialog
                ref="dialog"
                v-model="date_modal"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter_filtered_domains.date_range"
                    label="Picker in dialog"
                    prepend-inner-icon="mdi-calendar"
                    hide-details
                    class="mb-1"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter_filtered_domains.date_range"
                  range
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="date_modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="date_modal = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-list-item>
            <v-list-item>
              <v-autocomplete
                dense
                outlined
                v-model="filter_filtered_domains.projects"
                :items="projects"
                item-value="id"
                item-text="name"
                label="Projects"
                chips
                small-chips
                deletable-chips
                clearable
                multiple
                hide-details
              ></v-autocomplete>
            </v-list-item>
            <v-list-item>
              <v-autocomplete
                dense
                outlined
                v-model="filter_filtered_domains.statuses"
                :items="statuses.statuses"
                label="Statuses"
                hide-details
                chips
                small-chips
                deletable-chips
                clearable
                multiple
              ></v-autocomplete>
            </v-list-item>
            <v-list-item>
              <v-container fluid>
                <v-checkbox
                  v-model="filter_filtered_domains.columns"
                  label="Email GP"
                  value="email_gp"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="filter_filtered_domains.columns"
                  label="Email WM"
                  value="email_wm"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="filter_filtered_domains.columns"
                  label="Price"
                  value="price"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="filter_filtered_domains.columns"
                  label="Currency"
                  value="currency"
                  hide-details
                ></v-checkbox>
              </v-container>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog_filtered_domains = false"
          >
            Close
          </v-btn>
          <v-btn color="primary" text @click="exportFilteredDomains">
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  RESPONSIBILITIES_COLORS,
  RESPONSIBILITIES_ITEMS,
} from "@/utils/selectItems";
import axios from "axios";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import {
  DEFAULT_MENU_PROPS,
  GUEST_POST_UPLOAD_TABLE_HEADERS,
} from "@/utils/defaultData";
import service from "../../plugins/service";
import Location from "../../mixins/Location";
import FiltersHandling from "../../mixins/components/FiltersHandling";

const dateFormat = require("dateformat");
const date = new Date();

export default {
  name: "GpmGuestPost",
  components: { SmartDateFilter },
  mixins: [Location, FiltersHandling, FiltersHandling],
  data() {
    return {
      uploadStepper: 1,
      dialog_apt: false,
      routeParams: {},
      selectedUploadDomains: [],
      apt_headers: [
        { text: "Url", value: "url" },
        { text: "Anchor", value: "anchor" },
        { text: "Donor", value: "donor" },
        { text: "Status", value: "status_name" },
      ],
      apt_items: [],
      uploadTableData: {
        headers: GUEST_POST_UPLOAD_TABLE_HEADERS,
        items: [],
      },
      menuProps: DEFAULT_MENU_PROPS,
      date_modal: false,
      search: "",
      modal: {
        shown: false,
      },
      filter_filtered_domains: {
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        projects: [],
        statuses: [],
        columns: [],
      },
      filter: {
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        projects: [],
        statuses: [],
        niche: undefined,
        performer: undefined,
      },
      dialog: false,
      dialog_loading: false,
      dialog_filtered_domains: false,
      loading: false,
      loadings: {
        uploadTable: false,
      },
      export_loading: false,
      form: {
        domains: "",
        anchor_plan_task: null,
      },
      anchor_plan_tasks: [],
      items_breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "GPM",
          disabled: true,
        },
        {
          text: "Guest Post",
          disabled: true,
        },
      ],
      headers: [
        // {text: '', align: 'center', value: 'login'},
        // {text: 'Project', align: 'start', value: 'project'},
        { text: "Domain", value: "domain" },
        { text: "Url", value: "url" },
        { text: "Phrase", value: "phrase" },
        // {text: 'CostBase', value: 'cost_base', align: 'center'},
        { text: "CostTask", value: "cost_task", align: "center" },
        { text: "CostScore", value: "cost_ah_score", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "CreatedAt", value: "add_datetime", align: "center" },
        { text: "StageTime", value: "time_in_work_stage", align: "center" },
        { text: "Responsible", value: "responsible", align: "center" },
        // {text: 'Messages', value: 'messages', align: 'center'},
      ],
      other_tasks: [],
      other_tasks_dialog: false,
      items: [],
      items_filtered: [],
      cat_projects: [],
      cat_projects_data: {},
      responsibilities: RESPONSIBILITIES_ITEMS,
      responsibilities_colors: RESPONSIBILITIES_COLORS,
      performers: [],
      niches: [],
    };
  },
  watch: {
    filter: {
      handler(newValue) {
        this._$handleFiltersChange(newValue);
      },
      deep: true,
    },
  },
  computed: {
    projectName() {
      return this.$store.state.project.domain;
    },
    idProject() {
      return this.$store.state.project.active_project;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    statuses() {
      let self = this;

      if (self.items.length === 0) {
        return [];
      }

      let statuses = [];
      let used_statuses = [];

      self.cat_projects.forEach((v) => {
        self.items[v].forEach((i) => {
          if (used_statuses.indexOf(i.status) === -1) {
            used_statuses.push(i.status);
            statuses.push({
              text: i.status,
              value: i.status_id,
            });
          }
        });
      });

      return {
        statuses: statuses,
        used: used_statuses,
      };
    },
  },
  methods: {
    handleNextUploadStep() {
      this.fetchDomainsList();
      this.uploadStepper++;
    },
    async fetchDomainsList() {
      try {
        const url = "/gpm/task-view/upload-validate";

        this.loadings.uploadTable = true;

        const config = {
          params: {
            typeID: 1,
            source: "gpm_guest_post",
            projectID: this.$store.state.project.active_project,
          },
        };

        const payload = {
          domains: this.form.domains
            .split("\n")
            .map((v) => v.trim())
            .filter((v) => v.length > 0),
          anchorPlanTaskID: this.form.anchor_plan_task,
        };

        const resp = await service.post(url, payload, true, config);

        this.loadings.uploadTable = false;

        if (resp) {
          this.uploadTableData.items = resp.data.items;
          this.selectedUploadDomains = resp.data.items;
        }
      } catch {
        this.loadings.uploadTable = false;
      }
    },
    handleDateChange(event) {
      this.filter.date_range = event;
      this._$handleFilterChange(event, "date_range");
    },
    showOtherTasks(tasks) {
      this.other_tasks = tasks;
      this.other_tasks_dialog = true;
    },
    exportPostedLinks() {
      let self = this;

      self.export_loading = true;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .post(url, self.filter, config)
        .then((response) => {
          if (response.data.success) {
            location.href =
              `${self.$store.state.server_url}/export/gpm/posted-links/0?hash=` +
              response.data.hash;
          }
        })
        .finally(() => {
          self.export_loading = false;
        });
    },
    exportFilteredDomains() {
      let self = this;

      self.export_loading = true;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .post(url, self.filter_filtered_domains, config)
        .then((response) => {
          if (response.data.success) {
            location.href =
              `${self.$store.state.server_url}/export/gpm/filtered-domains/0?hash=` +
              response.data.hash;
          }
        })
        .finally(() => {
          self.export_loading = false;
        });
    },
    exportMainKeywords(aptID) {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/create-task`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        typeID: 6,
        json: { taskID: aptID },
      };

      axios
        .post(url, data, config)
        .catch((e) => alert(e))
        .finally(() => {
          this.$message.notification({
            title: "Export",
            text: "Main keywords successfully exported! 👍",
            type: "success",
            duration: 6000,
          });
        });
    },
    viewAnchorPlan(aptID) {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan`;

      let config = {
        params: {
          anchorPlanTaskID: aptID,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.apt_items = r.data.items;
          self.dialog_apt = true;
        })
        .finally(() => (self.loading = false));
    },
    updateFilter() {
      let self = this;

      if (self.items.length === 0) {
        return [];
      }

      let result = {};

      let selected_statuses = self.filter.statuses;

      self.cat_projects.forEach((v) => {
        result[v] = self.items[v].filter((i) => {
          let status_filter = true;
          let status_responsible = true;
          let status_critical_estimate = true;

          if (selected_statuses.length > 0) {
            status_filter = selected_statuses.indexOf(i.status) !== -1;
          }

          if (
            self.filter.responsible !== undefined &&
            self.filter.responsible !== null
          ) {
            if (self.filter.responsible === "ME")
              status_responsible = i.responsible.status;
            else if (self.filter.responsible === "ALL")
              status_responsible = true;
            else
              status_responsible =
                self.filter.responsible === i.responsible.role;
          }

          if (
            self.filter.critical_estimate === true &&
            self.filter.critical_estimate !== undefined &&
            self.filter.critical_estimate !== null
          ) {
            status_critical_estimate = i.diff_in_hour >= i.critical_estimate;
          }

          return (
            status_filter && status_responsible && status_critical_estimate
          );
        });
      });

      self.items_filtered = result;
    },
    customSort(items) {
      return items.sort((a, b) => {
        let status_priority_a,
          status_priority_b,
          min_on_stage_a,
          min_on_stage_b;

        status_priority_a = parseInt(a.status_priority);
        status_priority_b = parseInt(b.status_priority);

        min_on_stage_a = parseInt(a.minutes_in_work_stage);
        min_on_stage_b = parseInt(b.minutes_in_work_stage);

        if (status_priority_a > status_priority_b) return -1;
        if (status_priority_a < status_priority_b) return 1;

        if (min_on_stage_a > min_on_stage_b) return -1;
        if (min_on_stage_a < min_on_stage_b) return 1;

        return 0;
      });
    },
    openDialog() {
      let self = this;

      self.dialog = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan-tasks`;

      let config = {
        params: {
          projectID: self.idProject,
          type: 1,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.anchor_plan_tasks = r.data.items;
      });
    },
    upload() {
      let self = this;

      self.dialog_loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/upload-domains`;

      let config = {
        params: {
          typeID: 1,
          source: "gpm_guest_post",
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let post_data = {
        domains: this.selectedUploadDomains.map((v) => v.donor),
        anchorPlanTaskID: self.form.anchor_plan_task,
      };

      axios.post(url, post_data, config).finally(() => {
        this.uploadStepper = 1;
        this.form.domains = "";
        this.form.anchor_plan_task = null;
        self.dialog_loading = false;
        self.dialog = false;
        self.fetchData();
      });
    },
    openUrl(id) {
      this.$router.push({ name: "gpm.view-entity", params: { id: id } });
    },
    saveUserSettings() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/user/save-settings`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let post_data = {
        type: "gpm",
        data: {
          dashboard: {
            filter: self.filter,
          },
        },
      };

      axios
        .post(url, post_data, config)
        .then(() => {
          self.fetchData();
        })
        .finally(() => (self.loading = false));
    },
    fetchUserSettings() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/user/get-settings`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        if (r.data.gpm !== undefined && r.data.gpm.dashboard !== undefined) {
          self.filter = r.data.gpm.dashboard.filter;
        }
        self.fetchData();
      });
    },
    fetchData() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-dashboard-data`;

      this._$collectParams(
        ["date_range", "projects", "statuses"],
        "routeParams"
      );

      let config = {
        params: {
          typeID: 1,
          filter: self.filter,
          filter_header: this.routeParams,
          dashboardType: "default",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.cat_projects = r.data.projects;
          self.cat_projects_data = r.data.projects_data;
          self.items = r.data.items;
          self.items_filtered = r.data.items;
          self.stat = r.data.stat;
          self.filter.critical_estimate = false;
          self.performers = r.data.performers;
          self.niches = r.data.niches;
          self.updateFilter();
        })
        .finally(() => (self.loading = false));
    },
  },
  created() {
    this._$collectParamsTo(this, "filter", [
      "date_range",
      "projects",
      "statuses",
    ]);

    // if (!haveParams) return this.fetchUserSettings();

    this.fetchData();
  },
};
</script>

<style scoped></style>
