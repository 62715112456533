import service from "../../../plugins/service";

export const RolesApiService = () => {
  const ENDPOINTS = {
    GET_ROLES: "/access/roles",
    GET_ROLE: (id) => `/access/roles/${id}/edit`,
    CREATE_ROLE: "/access/roles",
    UPDATE_ROLE: (id) => `/access/roles/${id}`,
  };

  const getRoles = ({ page, rowsPerPage }) => {
    return service.get(
      ENDPOINTS.GET_ROLES + `?c=21&page=${page}&per_page=${rowsPerPage}`
    );
  };

  const getRole = (id) => {
    return service.get(ENDPOINTS.GET_ROLE(id));
  };

  const createRole = (payload) => {
    return service.post(ENDPOINTS.CREATE_ROLE, payload);
  };

  const updateRole = (id, payload) => {
    return service.put(ENDPOINTS.UPDATE_ROLE(id), payload);
  };

  return {
    getRole,
    getRoles,
    createRole,
    updateRole,
  };
};
