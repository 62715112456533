<template>
  <td>
    <div>
      <v-badge color="transparent">
        <template v-slot:badge>
          <span :style="`color: ${getColor}`">{{ data.diff }}</span>
        </template>
        <span>{{ data.value }}</span>
      </v-badge>
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    getColor() {
      const prev = this.data.prev;
      const curr = this.data.value;
      if (curr < prev) {
        return "red";
      }
      if (curr > prev) {
        return "green";
      }
      return "black";
    },
  },
};
</script>
