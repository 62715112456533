<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <page-name :theme="$vuetify.theme.dark" small="">
          Link Planning by Urls
        </page-name>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn color="success" fab small @click="uploadDialogModel = true">
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-model="filters.seo"
          multiple
          label="Seo"
          :items="filtersData.seo"
          item-value="id"
          item-text="email"
          dense
          outlined
          hide-details
          @change="_$handleFilterChange($event, 'seo')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.projects"
          multiple
          label="Project"
          item-text="name"
          item-value="id"
          :items="_$getProjects"
          dense
          outlined
          hide-details
          @change="_$handleFilterChange($event, 'projects')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <easy-range-input
          label="Need top"
          :model-value="filters.need_top"
          @update:modelValue="
            _$handleRangeChange($event, 'need_top', 'filters')
          "
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.niche"
          multiple
          label="Niche"
          item-value="id"
          item-text="name"
          :items="getNiches"
          dense
          outlined
          hide-details
          @change="_$handleFilterChange($event, 'niche')"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <date-filter
          :model-value="filters.date"
          @update:modelValue="handleDateChange"
        ></date-filter>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.last_month"
          hide-details
          dense
          :items="filtersData.last_month"
          outlined
          label="Last month"
          @change="_$handleFilterChange($event, 'last_month')"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-btn
          @click="fetchData"
          :loading="loadings.table"
          color="primary"
          block
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="mt-8">
      <v-col cols="12">
        <v-text-field
          v-model="search"
          dense
          hide-details
          label="Search"
          append-icon="mdi-magnify"
          style="max-width: 350px"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense style="padding-bottom: 150px">
      <v-col cols="12">
        <v-data-table
          :search="search"
          :sort-desc="[true, false, false]"
          :sort-by="['pr_pr', 'need_top', 'url_priority']"
          multi-sort
          :headers="headers"
          :items="tableData"
          :footer-props="{ itemsPerPageOptions: [50, 150, 200, -1] }"
          class="fixed-first-td"
        >
          <template #body="{ items }">
            <tbody>
              <tr
                v-for="(row, index) in items"
                :key="index"
                :class="{ first: index === 0 }"
              >
                <td class="edge-cell">{{ row.url }}</td>
                <link-planning-by-url-smart-td
                  v-for="(date, idx) in getDates(row)"
                  :key="idx"
                  :date="date"
                />
                <td class="text-center">{{ row.need_top }}</td>
                <td class="text-center">{{ row.url_priority }}</td>
                <td class="text-center">{{ row.pr_pr }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="uploadDialogModel"
      content-class="remove-dialog-shadow"
      max-width="850px"
    >
      <v-card rounded="lg" style="overflow: hidden">
        <v-row dense>
          <v-col cols="7">
            <v-card-title> Import from text</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  1. Put the columns to import in the order in which you make
                  the data
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="upload.headers"
                    label="Headers"
                    hide-details
                    outlined
                    dense
                    :placeholder="'url;link_type;month;amount'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">2. Insert data (without headers)</v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="upload.rows"
                    dense
                    hide-details
                    outlined
                    label="Rows"
                    :placeholder="'url <tab> link_type <tab> month <tab> amount \nurl <tab> link_type <tab> month <tab> amount '"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                class="text-center align-center justify-center center"
                color="success"
                dark
                :loading="loadings.upload"
                @click="uploadData(1)"
                >Upload
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="5">
            <v-card-title> Example of allowed columns</v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>url</td>
                    <td>
                      <b>https://example.com/page/1</b>
                    </td>
                  </tr>
                  <tr>
                    <td>link_type</td>
                    <td>
                      <b>guest post</b>
                    </td>
                  </tr>
                  <tr>
                    <td>month</td>
                    <td>
                      <b>2022-10-01</b>
                    </td>
                  </tr>
                  <tr>
                    <td>amount</td>
                    <td>
                      <b>3</b>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageName from "@/components/DashboardDev/PageName";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import EasyRangeInput from "@/components/UI/EasyRangeInput";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import Project from "@/mixins/Project";
import LinkPlanningByUrlSmartTd from "@/components/Dashboards/LinkPlanningByUrls/UI/linkPlanningByUrlSmartTd";
import Location from "../../../mixins/Location";

export default {
  components: {
    LinkPlanningByUrlSmartTd,
    DateFilter,
    EasyRangeInput,
    PageName,
  },
  mixins: [FiltersHandling, Project, Location],
  data: () => ({
    search: "",
    headers: [],
    loadings: {
      table: false,
      upload: false,
    },
    uploadDialogModel: false,
    filtersData: {
      seo: [],
      last_month: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "Not",
          value: 0,
        },
      ],
    },
    upload: {
      headers: "url;link_type;month;amount",
      rows: "",
    },
    filters: {
      seo: [],
      projects: [],
      need_top: [0, 0],
      niche: [],
      date: [],
      last_month: null,
    },
    tableData: [],
  }),
  created() {
    this.fetchUsers();
    this._$collectParams(
      ["date", "seo", "need_top", "projects", "niche"],
      "filters"
    );

    if (!this.$route.query.date) {
      const start = this.$moment(new Date())
        .subtract(1, "Q")
        .startOf("Q")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date())
        .subtract(1, "Q")
        .endOf("Q")
        .format("YYYY-MM-DD");
      this.filters.date = [start, end];
    }

    this.fetchData();
  },
  computed: {
    getNiches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    async uploadData() {
      this.loadings.upload = true;

      try {
        const payload = {
          headers: this.upload.headers,
          data: this.upload.rows,
        };

        await this.$store.dispatch(
          "link-planning-by-urls/uploadUrlsPlaning",
          payload
        );

        this.uploadDialogModel = false;

        this.$message.notification({
          title: "Done",
          text: "Data successfully uploaded 👍",
          type: "success",
          duration: 3500,
        });

        this.loadings.upload = false;
      } catch {
        this.$message.notification({
          title: "Error",
          text: "Something going wrong",
          type: "error",
          duration: 3500,
        });
        this.loadings.upload = false;
      }
    },
    getDates(items) {
      const output = [];

      Object.keys(items).forEach((key) => {
        if (key[0] === "$") output.push(items[key]);
      });

      return output;
    },
    handleDateChange(event) {
      this._$handleFilterChange(event, "date");
      this.filters.date = event;
    },
    async fetchUsers() {
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.filtersData.seo = resp;
      }
    },
    async fetchData() {
      this.loadings.table = true;

      const payload = {
        filter: this.filters,
      };

      const resp = await this.$store.dispatch(
        "link-planning-by-urls/fetchData",
        payload
      );

      if (resp) {
        this.headers = resp.headers;
        this.tableData = resp.items;
      }

      this.loadings.table = false;
    },
  },
};
</script>
