<template>
  <v-card-title v-if="data">
    {{ data?.dialog_field_data.field }}
    <v-spacer />
    <a
      v-if="data?.dialog_field_data?.url"
      :href="data?.dialog_field_data?.url"
      target="_blank"
      class="reset-link-styles"
    >
      <v-chip label class="mr-2" @click="() => {}">
        Visit URL
        <v-icon small right>mdi-open-in-new</v-icon>
      </v-chip>
    </a>
    <router-link
      v-if="data?.dialog_field_data.md5"
      :to="{
        name: 'search-console.url',
        params: { id: data?.dialog_field_data.md5 },
      }"
      target="_blank"
      class="reset-link-styles mr-2"
    >
      <v-chip label @click="() => {}">
        Search console
        <v-icon small right>mdi-open-in-new</v-icon>
      </v-chip>
    </router-link>
    <v-chip
      v-if="
        [types.TITLE, types.H1, types.DESCRIPTION].includes(
          data?.dialog_field_data.field_id
        )
      "
      label
      @click="visitTdh1Dashboard"
    >
      TDH1
      <v-icon small right>mdi-open-in-new</v-icon>
    </v-chip>
    <v-btn class="ml-4" icon @click="$emit('closeDialog')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  props: {
    data: {},
    types: {},
  },

  methods: {
    visitTdh1Dashboard() {
      try {
        const routeData = this.$router.resolve({
          name: "tdh1-dashboard",
          query: {
            project_id: this.$store.state.project.active_project,
            url: this.data.dialog_field_data.urlID,
          },
        });
        window.open(routeData.href, "_blank");
      } catch (e) {
        console.error("Cant visit TDH1.", e);
      }
    },
  },
};
</script>
