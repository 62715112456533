var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isset_active_project)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.isset_active_project)?_c('v-card',{staticClass:"mx-auto"},[_c('v-card-text',{staticClass:"headline font-weight-bold"},[_c('v-row',{staticClass:"align-end"},[_c('v-col',{attrs:{"sm":"11"}},[_c('v-text-field',{staticClass:"align-end",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-menu',{attrs:{"transition":"slide-x-transition","close-on-content-click":false,"nudge-right":200},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","dark":"","small":"","top":"","right":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)],1)]}}],null,false,1086487504)},[_c('v-card',[_c('v-card-text',[_c('v-list',[_c('v-list-item',[_c('v-select',{attrs:{"items":_vm.types_of_association,"item-text":"name","item-value":"id","label":"Types of association","solo":"","clearable":""},model:{value:(_vm.filter.type_of_association),callback:function ($$v) {_vm.$set(_vm.filter, "type_of_association", $$v)},expression:"filter.type_of_association"}})],1)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetch_data}},[_vm._v(" Apply ")])],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.table.headers,"items":_vm.table.items,"sort-by":['value'],"sort-desc":[true],"footer-props":{
                    itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                  }},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.url},[_c('td',[_c('a',{on:{"click":function($event){return _vm.open_url('/search-console/url/' + item.md5)}}},[_vm._v(_vm._s(item.url_path))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.impressions)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.clicks)+" ")]),_vm._l((_vm.table.headers.length - 3),function(n){return _c('td',{key:n,staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.data[n - 1] !== undefined)?_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","small":"","color":_vm.get_pos_td_class(item.data[n - 1])}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.data[n - 1].pos)+" ")]):_vm._e(),(
                                  item.data[n - 1] !== undefined &&
                                  item.data[n - 1].pos !== null
                                )?_c('sup',{style:({
                                  position: 'static',
                                  right: '-22px',
                                  top: '-3px',
                                  'font-size': '90%',
                                  color: _vm.get_pos_td(item.data[n - 1])[1],
                                }),domProps:{"innerHTML":_vm._s(_vm.get_pos_td(item.data[n - 1])[0])}}):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.url))])])],1)})],2)}),0)]}}],null,false,1620049263)})],1)],1)],1)],1):_vm._e()],1)],1)],1):_c('v-alert',{attrs:{"icon":"mdi-shield-lock-outline","prominent":"","text":"","type":"info","dark":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" To view the page, you must select a project. ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.$store.state.project.menu_select_projects = true}}},[_vm._v("Select project")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }