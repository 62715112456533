import Service from "@/plugins/service";
import service from "@/plugins/service";

export default {
  state: {
    report_tables_data: null,
    filters: null,
  },
  mutations: {
    "one_year_report/set_tables_data"(state, payload) {
      state.report_tables_data = payload;
    },
    "one_year_report/set_filters_data"(state, payload) {
      state.filters = payload;
    },
  },
  actions: {
    async "one_year_report/get_positions_table_Data"(ctx, payload) {
      const url = "/serp/1-year-report/positions";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "one_year_report/get_conv_dynamic_table_Data"(ctx, payload) {
      const url = "/serp/1-year-report/conversion-dynamics";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "one_year_report/get_conv_by_page_type_table_Data"(ctx, payload) {
      const url = "/serp/1-year-report/conversion-by-page-types";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "one_year_report/get_link_by_project_table_Data"(ctx, payload) {
      const url = "/serp/1-year-report/links-by-project";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "one_year_report/get_content_table_Data"(ctx, payload) {
      const url = "/serp/1-year-report/content";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "one_year_report/get_filters_data"({ commit }, projectId) {
      const url = `/site-workplace/${projectId}/get-filter-data`;
      const response = await Service.get(url, true);

      commit("one_year_report/set_filters_data", response.data);
    },
    async "one_year_report/get_report_tables_data"(
      ctx,
      { filters, projectId }
    ) {
      const url = `/serp/1-year-report?project_id=${projectId}`;

      const response = await Service.get(url, true, filters);

      if (response) {
        return response.data;
      }
    },
  },
  getters: {
    "one_year_report/report_tables_data": (state) => state.report_tables_data,
    "one_year_report/filters_data": (state) => state.filters,
  },
};
