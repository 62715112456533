import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "anchor-distribution/mainTable"(ctx, payload) {
      const url = "/one-page-analyzer/anchor-distribution/main";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
    async "anchor-distribution/linksTable"(ctx, payload) {
      const url = "/one-page-analyzer/anchor-distribution/links";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
    async "anchor-distribution/anchorsTable"(ctx, payload) {
      const url = "/one-page-analyzer/anchor-distribution/anchors";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
  },
  getters: {},
};
