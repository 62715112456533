export const CommonIndex = {
    computed: {
        idProject() {
            return this.$store.state.project.active_project
        },
    },
    methods: {
        goTo(url, target = '_blank') {
            window.open(url, target)
        }
    }
}