<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>Outreach Lead Conversion</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <v-autocomplete
              v-model="filters.seo"
              :loading="loadings.users"
              :items="filtersData.users"
              hide-details
              dense
              item-value="id"
              item-text="name"
              outlined
              placeholder="Select"
              label="Seo"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.outreach"
              :loading="loadings.users"
              :items="filtersData.users"
              hide-details
              item-value="id"
              item-text="name"
              dense
              outlined
              placeholder="Select"
              label="Outreach"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.project_id"
              :items="_$getProjects"
              item-value="id"
              item-text="name"
              hide-details
              dense
              outlined
              placeholder="Select"
              label="Project"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <smart-date-filter
              :model-value="filters.date"
              @update:modelValue="filters.date = $event"
            />
          </v-col>
          <v-col></v-col>
          <v-col>
            <v-btn
              @click="fetchData"
              color="primary"
              block
              style="height: 40px"
              :loading="loadings.table"
              >Get Data</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :items="tableData.items"
          :headers="tableData.headers"
          :loading="loadings.table"
          :items-per-page="-1"
          hide-default-footer
        >
          <template
            v-for="header in tableData.headers"
            #[`item.${header.value}`]="{ value }"
          >
            <template
              v-if="
                header.value !== 'name' && value !== null && value !== undefined
              "
            >
              <div
                :key="header.value"
                :class="`colored-td colored-td--inline ${getPercentColor(
                  value
                )}`"
              >
                <template v-if="String(value) === '0'">
                  <span style="opacity: 0.4">
                    {{ value }}
                  </span>
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </div>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "../../../plugins/service";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import Project from "../../../mixins/Project";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";

export default {
  components: { SmartDateFilter },
  mixins: [FiltersHandling, Project],
  data: () => ({
    filters: {
      date: [null, null],
      outreach: null,
      project_id: null,
      seo: null,
    },
    filtersData: {
      users: [],
    },
    tableData: {
      items: [],
      headers: [],
    },
    loadings: {
      table: false,
      users: false,
    },
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "GPM",
        disabled: true,
      },
      {
        text: "Outreach Lead Conversion",
        disabled: true,
      },
    ],
  }),
  watch: {
    filters: {
      deep: true,
      handler(value) {
        this._$handleFiltersChange(value);
      },
    },
  },
  created() {
    this.fetchUsers();

    const haveParams = this._$collectParamsTo(this, "filters", ["date"]);

    if (!this.$route.query.date) {
      const monthStart = this.$moment(new Date())
        .startOf("month")
        .format(DEFAULT_MOMENT_DATE_FORMAT);
      const monthEnd = this.$moment(new Date())
        .endOf("month")
        .format(DEFAULT_MOMENT_DATE_FORMAT);

      this.filters.date = [monthStart, monthEnd];
    }

    if (haveParams) this.fetchData();
  },
  methods: {
    getPercentColor(percent) {
      if (!percent) return "";

      const fixedPercent = Number(percent);

      if (fixedPercent >= 0 && fixedPercent <= 20) return "red";
      if (fixedPercent > 20 && fixedPercent <= 50) return "orange";
      if (fixedPercent > 50 && fixedPercent <= 80) return "yellow";
      if (fixedPercent > 80) return "green";

      return "";
    },
    async fetchUsers() {
      try {
        const projectId = this.$store.state.project.active_project;

        this.loadings.users = true;

        const resp = await this.$store.dispatch("global/getUsers", {
          projectId,
        });

        if (resp) {
          this.filtersData.users = resp;
        }
      } finally {
        this.loadings.users = false;
      }
    },
    async fetchData() {
      try {
        const url = "/gpm/admin/outreach-lead-conversion";

        const payload = {
          filter: {
            ...this.filters,
          },
        };

        this.loadings.table = true;

        const resp = await service.post(url, payload);

        if (resp) {
          this.tableData = resp.data;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>
