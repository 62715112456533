<template>
  <div class="d-flex align-center">
    <v-btn icon @click="isAddFontDialogVisible = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-autocomplete
      v-model="font"
      :items="fonts"
      @change="handleApplyFont"
      hide-details
      dense
      item-text="name"
      return-object
      outlined
      v-bind="$attrs"
      :loading="loadings.fonts"
    />
    <v-dialog v-model="isAddFontDialogVisible" width="460" scrollable>
      <v-card flat rounded="lg" outlined>
        <v-card-title class="text-body-2">
          Edit custom fonts
          <v-spacer />
          <v-icon @click="isAddFontDialogVisible = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12"> Added fonts ( max 5 ) </v-col>
            <v-col v-if="!userFonts?.length" cols="12">
              <span style="opacity: 0.5">No custom fonts...</span>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-row dense>
                <template v-for="(font, idx) in userFonts">
                  <v-col :key="`${idx}-font`" cols="12">
                    <v-card class="pl-4 pr-2 py-2" flat outlined rounded="lg">
                      <v-row>
                        <v-col class="d-flex align-center">
                          <b>
                            {{ font?.name }}
                            <span v-if="font?.inscription">
                              , {{ font.inscription }}
                            </span></b
                          >
                        </v-col>
                        <v-col class="d-flex justify-end">
                          <v-btn icon @click="handleRemoveUserFont(idx)">
                            <v-icon size="16">mdi-trash-can</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-divider
                    v-if="idx !== userFonts?.length - 1"
                    :key="`${idx}-font-d`"
                  />
                </template>
              </v-row>
            </v-col>
            <v-col cols="12"> Add new font </v-col>
            <v-col cols="12" class="pt-0">
              <v-textarea
                v-model="fontHtml"
                hide-details
                dense
                outlined
                label="Embed code"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="fontFamilyType"
                label="Font style"
                hide-details
                dense
                outlined
                clearable
                :items="fontStyles"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            class="text-normal px-6"
            text
            @click="isAddFontDialogVisible = false"
          >
            Back
          </v-btn>
          <v-btn
            large
            class="text-normal px-6"
            color="success"
            @click="processFontHtml"
            :disabled="userFonts?.length >= 5"
          >
            Add <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FontMixin from "../../../mixins/FontMixin";
import { userPreferenceApiService } from "../../../services/UserPreferenceApiService";
import { DEFAULT_FONTS } from "./defaults";

export default {
  mixins: [FontMixin],

  data: () => ({
    isAddFontDialogVisible: false,
    fontValue: null,
    currentFont: null,
    fontHtml: null,
    fontFamilyType: "sans-serif",
    FONTS_PREFERENCES_KEY: "user_fonts",
    loadings: {
      fonts: false,
    },
    fontStyles: [
      "sans-serif",
      "serif",
      "monospace",
      "cursive",
      "fantasy",
      "system-ui",
    ],
    defaultFonts: DEFAULT_FONTS,
    userFonts: [],
  }),

  computed: {
    font: {
      get() {
        return this.fontValue;
      },
      set(newFont) {
        this.fontValue = newFont;
      },
    },
    fonts() {
      return [...this.defaultFonts, ...this.userFonts];
    },
    defaultFont() {
      return this.fonts.find((f) => f.name === "Inter");
    },
  },

  async mounted() {
    await this.initializeUserFonts();

    // Если шрифт не сохранен, берем текущий шрифт с <body>
    const computedStyle = window.getComputedStyle(document.body);
    const fontFamily = computedStyle.fontFamily?.split(",")[0];

    const match = this.fonts.find((f) => f.name === fontFamily);

    if (match) return (this.font = match);

    this.font = this.defaultFont;
  },

  watch: {
    userFonts: {
      handler(newFonts) {
        if (Array.isArray(newFonts) && newFonts.length <= 5) {
          userPreferenceApiService().set(this.FONTS_PREFERENCES_KEY, newFonts);
        }
      },
      deep: true,
    },
  },

  methods: {
    async handleRemoveUserFont(fontIdx) {
      if (this.font?.name === this.userFonts[fontIdx]?.name)
        this.font = this.defaultFont;

      await this.$nextTick();

      this.userFonts = this.userFonts.filter((f, idx) => idx !== fontIdx);
    },
    async initializeUserFonts() {
      try {
        this.loadings.fonts = true;

        const { data } = await userPreferenceApiService().get();

        this.userFonts = data[this.FONTS_PREFERENCES_KEY] || [];
      } catch (e) {
        console.error("Error while initializing user fonts.", e);
      } finally {
        this.loadings.fonts = false;
      }
    },
    processFontHtml() {
      const fonts = this.parseFontLinks(this.fontHtml, this.fontFamilyType);

      if (this.userFonts.length >= 5)
        return console.error(
          "Cant add another font because it's limit. Max 5 custom fonts."
        );

      this.userFonts.push(...fonts);

      this.$nextTick(() => {
        this.fontHtml = null;
        this.fontFamilyType = "sans-serif";
        this.font = fonts[0];
        this.applyFont(fonts[0]);
      });
    },
    handleApplyFont(font) {
      this.font = font;

      this.applyFont(font);
    },
  },
};
</script>
