<template>
  <v-card
    v-if="!loading"
    rounded="lg"
    style="width: 100%"
    class="site-analysis-table-section styled-card--default"
  >
    <v-card-title
      :data-page-navigation="SECTIONS.ah_linked_anchors.sectionTitle"
    >
      <!--      AH Linked Anchors-->
      {{ SECTIONS.ah_linked_anchors.sectionTitle }}
      <force-update-button :section-name="sectionName" />
      <link-to-ahrefs
        type="outgoing-anchors"
        :domain="$store.getters['site-analysis/activeDomain']"
      />
      <v-spacer />
      <v-autocomplete
        v-if="successfullyUploaded"
        v-model="selectedNicheFilter"
        :items="nicheItems"
        placeholder="Niche"
        hide-details
        dense
        clearable
        filled
        style="max-width: 130px"
      ></v-autocomplete>
    </v-card-title>
    <v-card-subtitle class="d-flex align-center">
      <last-update-info :data="data" />
      <v-spacer />

      <table-search
        v-if="successfullyUploaded"
        class="mt-2"
        :model-value="search"
        @update:modelValue="search = $event"
        placeholder="Type to search in table"
        style="max-width: 320px"
      />
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pa-0">
      <template v-if="successfullyUploaded">
        <v-data-table
          :search="search"
          :items="filteredByNicheTableItems"
          :headers="headers"
          multi-sort
          dense
          :items-per-page="-1"
          :custom-sort="_$smartCustomTableSort"
        >
          <template #[`item.links_external`]="{ value }">
            <span :style="getTextStyles(value)">{{ value }}</span>
          </template>
          <template #[`item.links_internal`]="{ value }">
            <span :style="getTextStyles(value)">{{ value }}</span>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <not-collected-placeholder />
      </template>
    </v-card-text>
  </v-card>
  <div v-else class="skeleton loading" style="height: 300px"></div>
</template>

<script>
import TableSorting from "../../../mixins/TableSorting";
import LastUpdateInfo from "../UI/LastUpdateInfo.vue";
import DataTableFilterByNicheMixin from "../../../mixins/components/SiteAnalysis/DataTableFilterByNicheMixin";
import SectionLoadingStateMixin from "../../../mixins/components/SiteAnalysis/SectionLoadingStateMixin";
import NotCollectedPlaceholder from "../UI/NotCollectedPlaceholder.vue";
import ForceUpdateButton from "../UI/ForceUpdateButton.vue";
import LinkToAhrefs from "../UI/LinkToAhrefs.vue";
import TableSearch from "@/components/UI/TableSearch.vue";

export default {
  components: {
    TableSearch,
    LinkToAhrefs,
    ForceUpdateButton,
    NotCollectedPlaceholder,
    LastUpdateInfo,
  },
  mixins: [TableSorting, DataTableFilterByNicheMixin, SectionLoadingStateMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.data.data;
    },
  },
  data: () => ({
    search: null,
    headers: [
      {
        text: "Anchor",
        value: "anchor",
      },
      {
        text: "Links External",
        value: "links_external",
      },
      {
        text: "Links Internal",
        value: "links_internal",
      },
    ],
  }),
  methods: {
    getTextStyles(value) {
      if (Number(value) === 0) return "opacity: 0.3;";
      return "";
    },
  },
};
</script>
