import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'worksectionReportWeek/fetchData'(ctx, payload) {
            const url = '/worksection/report/week'
            const response = await Service.post(url, payload, true)

            if(response.status === 200){
                return response.data
            }
        },
        async 'worksectionReportWeek/fetchFilters'() {
            const url = '/worksection/get/filter'
            const response = await Service.post(url, {}, true)
            if(response.status === 200) {
                return response.data
            }
        },
        async 'worksectionReportWeek/fetchReportTask'(ctx, payload) {
            const url = '/worksection/report/task'
            const response = await Service.post(url, payload, true)
            if(response.status === 200) {
                return response.data
            }
        },
        async 'worksectionReportWeek/fetchReportComments'(ctx, payload) {
            const url = '/worksection/report/comments'
            const response = await Service.post(url, payload, true)
            if(response.status === 200) {
                return response.data
            }
        },
    },
    getters: {},
}