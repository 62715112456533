import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'technical/fetchTableData'(ctx, payload) {
            const url = '/dashboards/technical/pages'

            const resp = await Service.post(url, payload, true)
            if(resp && resp.status === 200) {
                return resp.data
            }
            return false
        },
    },
    getters: {},
}