<template>
  <v-row v-if="Array.isArray(groups) && data !== null">
    <v-col v-for="group in groups" :key="group.name" cols="12">
      <v-card class="styled-card--default">
        <v-card-title>
          {{ group.name }}
        </v-card-title>
        <v-divider />
        <v-card-text v-if="data">
          <v-row>
            <v-col
              v-for="filter in group.filters"
              :key="filter.value"
              :cols="filter.col || 12"
            >
              <filters-factory
                :model-value="get(data, filter.path || filter.value)"
                :context="data"
                :items="filter.items"
                :type="filter.type"
                :filter="filter"
                :errors="(errors && errors[filter.value]) || []"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-chip label>
            <v-icon left small>mdi-alert</v-icon>
            Filters not initialized</v-chip
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FiltersFactory from "@/components/FiltersFactory/components/FiltersFactory.vue";
import { get } from "@/utils/functions";

export default {
  methods: { get },
  components: { FiltersFactory },
  props: {
    groups: {},
    errors: Array,
    modelValue: {},
  },
  computed: {
    data: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
