<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <h2>Honor Board</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(item, index) in photos"
          :key="`${index}-photo`"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="`${item}`"
            aspect-ratio="1"
            cover
            class="bg-grey-lighten-2"
          >
            <template #placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey-lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import service from "@/plugins/service";

export default {
  components: {},
  data() {
    return {
      photos: [],
      loadings: {
        tables: false,
      },
    };
  },
  computed: {},
  methods: {
    async fetchData() {
      try {
        const url = "/dashboards/honor-board/index";

        this.loadings.tables = true;

        const response = await service.get(url);

        if (response) {
          this.photos = response.data.reverse();
        }
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.loadings.tables = false;
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss"></style>
