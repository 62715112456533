<template>
  <v-data-table
    :items="data?.items"
    :headers="data?.headers"
    :items-per-page="50"
    :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
  >
    <template
      v-for="{ value: key } in data?.headers"
      #[`item.${key}`]="{ value, item }"
    >
      <template v-if="key.split('_')[0] === 'diff'">
        <div class="d-flex" style="gap: 0.5rem" :key="key">
          <diff-column :value="item['_' + key]?.growth" />
          <diff-column :value="item['_' + key]?.fallen" />
        </div>
      </template>
      <template v-else-if="key.split('_')[0] === 'range'">
        <range-column :value="value" :data="item" :name="key" :key="key" />
      </template>
      <template v-else>
        {{ value }}
      </template>
    </template>
  </v-data-table>
</template>

<script>
import DiffColumn from "./DiffColumn/index.vue";
import RangeColumn from "./RangeColumn/index.vue";

export default {
  components: {
    DiffColumn,
    RangeColumn,
  },
  props: {
    data: Object,
  },
};
</script>
