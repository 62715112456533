<template>
  <div>
    <v-container
      fluid
      v-if="isset_active_project"
      style="padding-bottom: 200px"
    >
      <v-row>
        <v-col cols="12">
          <h2>Info by URL</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
      </v-row>
      <v-row dense class="mt-6">
        <v-col cols="12" class="d-flex">
          <div class="d-flex" style="gap: 8px">
            <template v-for="settingKey in Object.keys(filter)">
              <v-chip
                v-if="getSettingsLabel(settingKey) && filter[settingKey]"
                :key="settingKey"
                title="Active setting"
                close
                @click:close="
                  () => {
                    filter[settingKey] = null;
                    $forceUpdate();
                  }
                "
              >
                {{ getSettingsLabel(settingKey) }}
              </v-chip>
            </template>
          </div>
          <v-spacer />
          <v-menu
            bottom
            offset-y
            :close-on-content-click="false"
            content-class="my-shadow--e3"
          >
            <template #activator="{ on }">
              <filters-chip
                v-on="on"
                clearable
                :filters="filter"
                @click:clear="handleClearFilters"
              />
            </template>
            <v-card width="360" class="styled-card--default">
              <v-card-title> Settings </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row class="d-flex align-center">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filter.type_of_association"
                      :items="types_of_association"
                      item-text="name"
                      item-value="id"
                      label="Types of association"
                      filled
                      clearable
                      :menu-props="menuProps"
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_domain"
                      label="Hide domain"
                      :false-value="null"
                      :true-value="1"
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_url"
                      label="Hide url"
                      :false-value="null"
                      :true-value="1"
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_freeze"
                      label="Hide freeze"
                      :false-value="null"
                      :true-value="1"
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_new"
                      hide-details
                      :false-value="null"
                      :true-value="1"
                      class="ma-0"
                      label="Hide new"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      @click="fetch_data"
                      :loading="loadings.fetch_data"
                      >Get data</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu
            v-if="false"
            :close-on-content-click="false"
            bottom
            offset-y
            content-class="my-shadow--e3"
          >
            <template #activator="{ on }">
              <v-chip v-on="on" outlined label>
                <v-icon small left>mdi-cog</v-icon>
                Settings
                <v-icon small right>mdi-chevron-down</v-icon>
              </v-chip>
            </template>
            <v-card width="360" class="styled-card--default">
              <v-card-title> Settings </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row class="d-flex align-center">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filter.type_of_association"
                      :items="types_of_association"
                      item-text="name"
                      item-value="id"
                      label="Types of association"
                      filled
                      clearable
                      :menu-props="menuProps"
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_domain"
                      label="Hide domain"
                      :false-value="0"
                      :true-value="1"
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_url"
                      label="Hide url"
                      :false-value="0"
                      :true-value="1"
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_freeze"
                      label="Hide freeze"
                      :false-value="0"
                      :true-value="1"
                      hide-details
                      class="ma-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="filter.hide_new"
                      hide-details
                      :false-value="0"
                      :true-value="1"
                      class="ma-0"
                      label="Hide new"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      @click="fetch_data"
                      :loading="loadings.fetch_data"
                      >Get data</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-chip
            color="primary"
            label
            class="ml-2"
            @click="fetch_data"
            title="Refresh table data"
          >
            <v-icon v-if="!loadings.fetch_data" small>mdi-refresh</v-icon>
            <v-progress-circular v-else indeterminate size="16" width="2" />
          </v-chip>
        </v-col>
        <v-col cols="12">
          <v-row dense class="mb-1 mt-4 align-center">
            <v-col>
              <v-menu
                bottom
                offset-y
                content-class="my-shadow--e3"
                max-width="310"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <div>
                    <v-chip
                      v-on="on"
                      label
                      class="flat-chip flat-chip--primary"
                      @click="() => {}"
                    >
                      <v-icon small left>mdi-gesture-tap</v-icon>
                      Actions
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </v-chip>
                    <v-chip
                      v-if="table.checked_rows.length > 0"
                      color="success"
                      class="ml-1"
                      outlined
                      label
                      dark
                    >
                      Selected:
                      <b class="ml-2">
                        {{ table.checked_rows.length }}
                      </b>
                    </v-chip>
                  </div>
                </template>
                <v-card class="styled-card--default">
                  <v-card-text class="pa-0">
                    <v-list dense>
                      <v-list-item @click="add_phrases_to_ka">
                        <v-list-item-icon>
                          <v-icon dark color="blue">mdi-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Add to KA </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="hide_phrases">
                        <v-list-item-icon>
                          <v-icon dark color="red"> mdi-cancel </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Hide phrases from url
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="hide_phrases_project">
                        <v-list-item-icon>
                          <v-icon color="red"> mdi-minus-circle </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Hide phrases rom domain
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="add_phrases_to_freeze">
                        <v-list-item-icon>
                          <v-icon dark color="#a9a9a9"> mdi-snowflake </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Freeze on GSC from url
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col> </v-col>
            <v-col class="d-flex justify-end">
              <v-chip label outlined style="border-style: dashed">
                <v-row
                  dense
                  class="d-flex align-center"
                  style="font-size: 12px"
                >
                  <v-col
                    v-if="table.dates && table.dates[0]"
                    :title="$moment(table.dates[0]).calendar()"
                    class="mr-2"
                  >
                    from
                    <b>{{ table.dates[0] }}</b>
                  </v-col>
                  <v-col
                    v-if="table.dates && table.dates[1]"
                    :title="$moment(table.dates[1]).calendar()"
                    class="d-flex justify-end align-center"
                  >
                    to
                    <b class="ml-1">{{ table.dates[1] }}</b>
                  </v-col>
                </v-row>
              </v-chip>
            </v-col>
          </v-row>
          <v-card class="styled-card--light">
            <v-card-text class="d-flex pb-0 pt-2">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-link-variant</v-icon>
                <span title="Selected URL"> {{ table.url.url }}</span>
              </div>
              <v-spacer />
              <v-text-field
                v-model="search"
                dense
                prepend-inner-icon="mdi-magnify"
                placeholder="Search in table"
                filled
                hide-details
                style="max-width: 330px"
              ></v-text-field>
            </v-card-text>
            <v-card-text class="px-0 pt-2">
              <template v-if="loadings.fetch_data">
                <v-skeleton-loader type="table" />
              </template>
              <template v-else>
                <v-data-table
                  :search="search"
                  :headers="table.headers"
                  :items="table.items"
                  :page="table.page"
                  :loading="loadings.fetch_data"
                  :items-per-page="table.items_per_page"
                  :sort-by="['in_keyword_control', 'impressions']"
                  :sort-desc="[true, true]"
                >
                  <template
                    v-for="h in table.headers"
                    v-slot:[`header.${h.value}`]="{ header }"
                  >
                    <v-tooltip bottom :key="h.text">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ header.text }}</span>
                      </template>
                      <span>{{ header.title }}</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.phrase">
                        <td>
                          <v-checkbox
                            v-if="!item.in_keyword_control"
                            v-model="table.checked_rows"
                            :value="item.phrase_id"
                          ></v-checkbox>
                        </td>
                        <td>
                          <div :style="get_phrase_class(item)">
                            <v-icon
                              v-if="!loadings.translate.includes(item.phrase)"
                              color="blue"
                              small
                              class="mr-2"
                              @click="translate_string(item.phrase)"
                            >
                              mdi-google-translate
                            </v-icon>
                            <v-progress-circular
                              v-else
                              size="18"
                              width="2"
                              class="mr-2"
                              indeterminate
                            />
                            <span v-if="item.phrase_html">
                              <span v-html="item.phrase_html"></span>
                              <v-icon
                                color="error"
                                title="Contains Russian characters"
                                >mdi-information</v-icon
                              ></span
                            >
                            <span v-else>{{ item.phrase }}</span>
                            <span v-if="item.translation">
                              {{ item.translation }}
                            </span>
                          </div>
                        </td>
                        <td class="text-center">
                          {{ item.priority }}
                        </td>
                        <td class="text-center">
                          {{ item.impressions }}
                        </td>
                        <td class="text-center">
                          {{ item.clicks }}
                        </td>
                        <td class="text-center">
                          {{ item.equally }}
                        </td>
                        <td class="text-center">
                          {{ item.entry }}
                        </td>
                        <td
                          v-for="n in table.headers.length - 6"
                          :key="n"
                          class="text-center"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                label
                                small
                                v-bind="attrs"
                                v-on="on"
                                :color="get_pos_td_class(item.data[n - 1])"
                                v-if="item.data[n - 1] !== undefined"
                              >
                                {{ item.data[n - 1].pos }}
                              </v-chip>
                              <sup
                                v-html="get_pos_td(item.data[n - 1])[0]"
                                :style="{
                                  position: 'static',
                                  right: '-22px',
                                  top: '-3px',
                                  'font-size': '90%',
                                  color: get_pos_td(item.data[n - 1])[1],
                                }"
                                v-if="
                                  item.data[n - 1] !== undefined &&
                                  item.data[n - 1].pos !== null
                                "
                              >
                              </sup>
                            </template>
                            <span>{{ item.phrase }}</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "@/events/eventPipe";
import CopyComponentId from "../../../mixins/copyComponentId";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import FiltersChip from "@/components/UI/FiltersChip.vue";

export default {
  name: "SearchConsoleUrlIndex",
  components: { FiltersChip },
  mixins: [CopyComponentId, FiltersHandling],
  data() {
    return {
      copyComponentId: "1iu24g12-0as9=-das=kd9123",
      alert: {
        active: false,
        message: "",
      },
      search: "",
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Search Console",
          to: { name: "search-console.index" },
        },
        {
          text: "Info by URL",
          disabled: true,
        },
      ],
      menuProps: DEFAULT_MENU_PROPS,
      loadings: {
        fetch_data: false,
        translate: [],
        hide_phrases: false,
      },
      types_of_association: [
        { id: 1, name: "day" },
        { id: 2, name: "week" },
        { id: 3, name: "month" },
      ],
      filter: {
        type_of_association: 1,
        day: 10,
        hide_freeze: null,
        hide_new: null,
        hide_url: 1,
        hide_domain: 1,
      },
      table: {
        headers: [],
        dates: [],
        items: [],
        url: [],
        checked_rows: [],
        page: 1,
        items_per_page: 10,
      },
    };
  },
  //components: {MultipleLineChart},
  // components: {SimpleLineChart, MultipleLineChart},
  methods: {
    handleClearFilters() {
      Object.keys(this.filter).forEach((filterKey) => {
        this.filter[filterKey] = null;
      });
    },
    getSettingsLabel(key) {
      const data = {
        type_of_association:
          "Association: " +
          (
            this.types_of_association.find((v) => v.id === this.filter[key]) ||
            {}
          ).name,
        hide_freeze: "Hide freeze",
        hide_new: "Hide new",
        hide_url: "Hide URL",
        hide_domain: "Hide domain",
      };

      return data[key];
    },
    fetch_data() {
      if (this.id_project !== null && this.id_project !== undefined) {
        let url = `${this.$store.state.server_url}/search-console/url/phrases/${this.id_project}/${this.$route.params.id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          format: this.filter.type_of_association,
          ...this.filter,
        };

        this.loadings.fetch_data = true;

        axios
          .post(url, data, config)
          .then((response) => {
            this.table.items = response.data.data;
            this.table.url = response.data.url;
            this.table.headers = response.data.headers;
            this.table.dates = response.data.dates;
          })
          .finally(() => {
            this.loadings.fetch_data = false;
          });
      }
    },
    translate_string(string) {
      let index = this.table.items.findIndex(function (item) {
        return item.phrase === string;
      });

      if (this.id_project !== null && this.id_project !== undefined) {
        this.update_loading("translate_string", true);

        let url = `${this.$store.state.server_url}/core/get-translation`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          lang_source: "auto",
          lang_target: "en",
          string: string,
        };

        this.loadings.translate.push(string);

        axios
          .post(url, data, config)
          .then((response) => {
            this.table.items[index].translation = response.data.translation;
            this.$forceUpdate();
            this.update_loading("translate_string", false);
          })
          .catch(() => {
            this.$message.notification({
              title: "Something's wrong",
              text: "It is impossible to translate the phrase.",
              type: "error",
              duration: 8000,
            });
          })
          .finally(() => {
            this.loadings.translate = this.loadings.translate.filter(
              (v) => v !== string
            );
          });
      }
    },
    add_phrases_to_freeze() {
      let self = this;
      if (self.table.checked_rows.length === 0) {
        this.$message.notification({
          title: "Something wrong",
          text: "Please select a key! To be human!",
          type: "warning",
          duration: 8000,
        });
      } else {
        if (self.id_project !== null && self.id_project !== undefined) {
          self.update_loading("add_phrases_to_freeze", true);
          let url = `${self.$store.state.server_url}/search-console/actions/freeze-phrases`;
          let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$store.state.auth.token,
            },
          };

          let data = {
            url_id: self.table.url.id,
            phrases_id: self.table.checked_rows,
          };

          axios.post(url, data, config).then(function (response) {
            self.alert.active = true;
            if (response.data === true) {
              self.alert.message = "Added freeze phrases";
              self.table.checked_rows = [];
              self.fetch_data();
            } else {
              self.alert.message = response.data;
            }
            self.update_loading("add_phrases_to_freeze", false);
          });
        }
      }
    },
    hide_phrases_project() {
      let self = this;
      if (self.table.checked_rows.length === 0) {
        this.$message.notification({
          title: "Something wrong",
          text: "Please select a key! To be human!",
          type: "warning",
          duration: 8000,
        });
      } else {
        if (self.id_project !== null && self.id_project !== undefined) {
          self.update_loading("hide_phrases_project", true);
          let url = `${self.$store.state.server_url}/search-console/actions/hide-domains-phrases`;
          let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$store.state.auth.token,
            },
          };

          let data = {
            project_id: self.id_project,
            phrases_id: self.table.checked_rows,
          };

          axios.post(url, data, config).then(function (response) {
            self.alert.active = true;
            if (response.data === true) {
              self.alert.message = "Added hide domains phrases";
              self.table.checked_rows = [];
              self.fetch_data();
            } else {
              self.alert.message = response.data;
            }
            self.update_loading("hide_phrases_project", false);
          });
        }
      }
    },
    add_phrases_to_ka() {
      let self = this;
      if (self.table.checked_rows.length === 0) {
        this.$message.notification({
          title: "Something wrong",
          text: "Please select a key! To be human!",
          type: "warning",
          duration: 8000,
        });
      } else {
        if (self.id_project !== null && self.id_project !== undefined) {
          self.update_loading("add_phrases_to_ka", true);
          let url = `${self.$store.state.server_url}/search-console/actions/add-phrases-to-ka`;
          let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$store.state.auth.token,
            },
          };

          let data = {
            project_id: self.id_project,
            target_url_id: self.table.url.id,
            phrases_id: self.table.checked_rows,
          };

          axios.post(url, data, config).then(function (response) {
            self.alert.active = true;
            if (response.data === true) {
              self.alert.message = "Added phrases to KA";
              self.table.checked_rows = [];
              self.fetch_data();
            } else {
              self.alert.message = response.data;
            }
            self.update_loading("add_phrases_to_ka", false);
          });
        }
      }
    },
    hide_phrases() {
      let self = this;

      if (self.table.checked_rows.length === 0) {
        this.$message.notification({
          title: "Something wrong",
          text: "Please select a key! To be human!",
          type: "warning",
          duration: 8000,
        });
      } else {
        if (self.id_project !== null && self.id_project !== undefined) {
          self.update_loading("hide_phrases", true);
          let url = `${self.$store.state.server_url}/search-console/actions/hide-phrases`;
          let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$store.state.auth.token,
            },
          };

          let data = {
            url_id: self.table.url.id,
            phrases_id: self.table.checked_rows,
          };

          axios.post(url, data, config).then(function (response) {
            self.alert.active = true;
            if (response.data === true) {
              self.alert.message = "Added hide urls phrases";
              self.table.checked_rows = [];
              self.fetch_data();
            } else {
              self.alert.message = response.data;
            }
            self.update_loading("hide_phrases", false);
          });
        }
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
    get_sparkline(item) {
      let res = [];

      for (let i = this.table.count_days; i > 0; i--) {
        // for (let i = 1; i <= this.table.count_days; i++) {
        let pos = item["p" + (i - 1)].pos;

        if (pos !== undefined) {
          res.push(pos);
        }
      }

      return res;
    },
    get_sparkline_color(item) {
      let res = 0;

      for (let i = 1; i <= this.table.count_days; i++) {
        if (item["p" + (i - 1)].diff !== undefined) {
          res += item["p" + (i - 1)].diff;
        }
      }

      if (res === 0) {
        return "grey";
      }

      return res > 0 ? "success" : "error";
    },
    get_phrase_class(item) {
      let style = { color: "" };

      if (!item.in_keyword_control) {
        style["color"] = "#e04f1a !important";
      }
      if (item.freeze) {
        style["color"] = "#1aa1e0 !important";
      }

      return style;
    },
    get_pos_td_class(item) {
      let css = {
        "background-color": "",
      };
      let tableGradient = true;

      if (item !== null && item !== undefined) {
        // if (item.another !== undefined && cellData.another !== null && cellData.another == true) {
        //   $(td).addClass('td-another-url');
        // }
        if (tableGradient === true) {
          if (item.diff !== undefined) {
            let diff = item.diff;
            let color = "";

            if (diff > 0) {
              color = "#82b54b";
            } else if (diff < 0) {
              color = "#ffb119";
            }
            css["background-color"] = color;
          }
        } else if (item.pos !== undefined && item.pos !== null) {
          css["background-color"] = this.get_position_color(item.pos);
        }
      }

      return css["background-color"];
      // return {}
    },
    get_pos_td(item) {
      if (item !== undefined && item.pos !== undefined) {
        var sup = "";
        let diff = 0;
        let diff_color = "";
        // var classSup = '';

        if (item.diff !== undefined) {
          diff = item.diff;
          var diffEl = diff;

          if (diff > 0) {
            // classSup = 'text-black';
            diff_color = "#7fca82";
            // diff_color = 'grey'
            diffEl = "+" + diff;
            // diffEl = '<i class="v-icon notranslate mdi mdi-arrow-up-thick theme--light success--text" style="font-size: 14px;"></i>' + diff;
          } else if (diffEl < 0) {
            diff_color = "#ff7f7f";
            // diff_color = 'grey'
            // classSup = 'text-black';
            // diffEl = '<i class="v-icon notranslate mdi mdi-arrow-down-thick theme--light error--text" style="font-size: 14px;"></i>' + Math.abs(diff);
          } else {
            // classSup = 'text-black';
            diffEl = "";
          }

          // sup = '<sup class="' + classSup + '" style="margin-left: 5px;">' + diffEl + '</sup>';
          sup = diffEl;
        }

        // return '<a href="" class="text-black" data-toggle="tooltip" data-position="bottom" title="">' + parseInt(item.pos) + sup + '</a>';
        // return parseInt(item.pos) + sup;
        return [sup, diff_color, diff_color];
      }

      return "";

      // $(td).css(css);
      // $(td).attr('data-order', pos);
    },
    get_position_color(value) {
      value = parseInt(value);
      if (value === null) return "";
      else if (value > 50)
        return (
          "rgba(255, " +
          Math.round(this.return_color(value, 100) / 2) +
          ", " +
          this.return_color(value, 100) +
          ", 0.3)"
        );
      else if (value > 10 && value <= 50)
        return "rgba(255, 220, " + this.return_color(value, 50) + ", 0.3)";
      else if (value >= 0 && value <= 10)
        return (
          "rgba(" +
          this.return_color(value, 10) +
          ", 240, " +
          this.return_color(value, 10) +
          ", 0.3)"
        );
      else return "";
    },
    return_color(val1, val2) {
      var percent = val1 / val2;
      return Math.round(200 * percent);
    },
    update_setting() {
      this.$store.dispatch("update_ls_serp_setting", this.ls_settings);
    },
  },
  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler(filters) {
        this._$handleFiltersChange(filters);
      },
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {
    this._$collectParamsTo(this, "filter", []);

    eventPipe.$on("update-active-project", () => {
      this.fetch_data();
    });

    this.fetch_data();
  },
};
</script>

<style scoped></style>
