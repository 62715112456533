import Service from "@/plugins/service";

export default {
    state: {},
    mutations: {},
    actions: {
        async 'site-overview/fetchData'(ctx, payload) {
            const url = '/dashboards/summary/site-overview'
            const response  = await Service.post(url, payload)
            if(response) {
                return response.data
            }
            return null
        },
        async 'site-overview/fetchFilters'(ctx, payload) {
            const url = '/dashboards/summary/site-overview'
            const response  = await Service.post(url, payload)
            if(response) {
                return response.data
            }
            return null
        },
    },
    getters: {},
}