import { render, staticRenderFns } from "./Ltt.vue?vue&type=template&id=d21c6ef2&scoped=true&"
import script from "./Ltt.vue?vue&type=script&lang=js&"
export * from "./Ltt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d21c6ef2",
  null
  
)

export default component.exports