<template>
  <div>
    <v-container fluid v-if="isset_active_project">

      <v-row>
        <v-col cols="12" md="12">
          <v-card
              class="mx-auto"
              v-if="isset_active_project"
          >
            <v-card-text>
              <h3>Anchors Plan Tier2: </h3>
              <v-btn
                  color="success"
                  dark
                  right
                  small
                  absolute
                  style="margin-right: 60px;margin-top: -43px"
                  fab
                  @click="upload.modal=true"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <v-menu
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  max-width="300px"

              >
                <template v-slot:activator="{ on, attrs }">
                  <v-fab-transition>
                    <v-btn
                        color="info"
                        small
                        absolute
                        style="margin-right: 10px;margin-top: -43px"
                        right
                        fab
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon size="31">mdi-filter</v-icon>
                    </v-btn>
                  </v-fab-transition>

                </template>
                <v-card>
                  <v-card-text>
                    <div class="text-center" style="padding-top: 10px">
                      <h3>Filter</h3>
                    </div>
                    <v-list>
                      <v-list-item>
                        <v-dialog
                            ref="dialog"
                            v-model="date_modal"
                            persistent
                            width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filter.date_range"
                                label="Picker in dialog"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="filter.date_range"
                              range
                              scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="date_modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="date_modal = false"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                            v-model="filter.status"
                            :items="status"
                            item-text="name"
                            item-value="id"
                            label="Status"
                            chips
                            solo
                            multiple
                            deletable-chips
                            clearable
                        ></v-select>
                      </v-list-item>
                    </v-list>

                    <div class="text-center">
                      <v-btn
                          @click="fetch_data"
                          color="primary">
                        Apply
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
              <TableAnchorPlanIndex
                  :type="1"
                  :status="status"
                  :level="2"
                  :items=table.items
                  :performers="performers"
                  v-on:fetch_data="function() {
                            fetch_data()
                        }"
              ></TableAnchorPlanIndex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select
            project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <upload-anchor-plan
        v-if="upload.modal===true"
        v-model="upload.modal"
        level="2"
    ></upload-anchor-plan>


  </div>
</template>

<script>

import axios from "axios";
import eventPipe from "@/events/eventPipe";
import TableAnchorPlanIndex from "@/components/AnchorPlan/Child/TableAnchorPlanIndex";
import UploadAnchorPlan from "@/components/AnchorPlan/Child/UploadAnchorPlan";

export default {
  name: "AnchorPlanTier2Index",
  components: {TableAnchorPlanIndex, UploadAnchorPlan},
  metaInfo: {
    title: 'Anchor Plan Tasks - LTT - SI'
  },
  data() {
    return {
      loadings: {},
      upload: {
        modal: false,
      },
      performers: [],
      date_modal: false,
      status: [],
      table: {
        items: [],
      },
      filter: {
        date_range: [],
        status: [1, 2, 3, 4, 6]
      }
    }
  },
  methods: {
    fetch_data() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading('fetch_data', true)

        let url = `${self.$store.state.server_url}/anchor-plan/tier/tasks/${self.id_project}/2`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {
          filter: this.filter
        }

        self.table.items = []

        axios.post(url, data, config)
            .then(function (response) {
              self.table.items = response.data.tasks
              self.update_loading('fetch_data', false)
            }).catch(function (error) {
          alert(error);
        })
      }
    },
    fetch_status() {
      let self = this;

      self.update_loading('fetch_status', true)

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/list/status`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.post(url, {}, config)
          .then(function (response) {
            self.status = response.data
            self.update_loading('fetch_status', false)
          }).catch(function (error) {
        alert(error);
      })
    },
    fetch_performers() {
      let self = this;

      self.update_loading('fetch_performers', true)

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/list/performers`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.post(url, {}, config)
          .then(function (response) {
            self.performers = response.data
            self.performers.push({id: null, name: "not selected"})
            self.update_loading('fetch_performers', false)
          }).catch(function (error) {
        alert(error);
      })
    },
    update_loading(type, value) {
      this.$store.state.loading = false
      this.loadings[type] = value
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
  },
  created() {
    let self = this;

    eventPipe.$on('update-active-project', () => {
      self.fetch_data()
    })
    self.fetch_status()
    self.fetch_performers()
    self.fetch_data()
  },
}
</script>

<style scoped>

</style>