<template>
  <div id="chart">
    <apexchart type="bar" :height="chartOptions.chart.height" :options="chartOptions"
               :series="series"></apexchart>
  </div>
</template>

<script>

import VueApexCharts from "vue-apexcharts";

export default {
  name: "StackedBarChart",
  props: ["series", "categories"],
  components: {apexchart: VueApexCharts},
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ["#ff5252","#ff9100","#3460b6"],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Users Week'
        },
        xaxis: {
          categories: this.categories,
          labels: {
            formatter: function (val) {
              return val + "%"
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%"
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    }
  },
  methods: {},
  created() {

  },
  computed: {}

}
</script>

