<template>
  <v-card>
    <div :id="`link-small-chart-${propRef}}`">
      <div :id="`link-small-chart-timeline-${propRef}}`">
        <apexcharts
          :ref="`link_small_chart_${propRef}`"
          height="150"
          :options="options"
          :series="series"
        ></apexcharts>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import events from "../../../events/components/OnePageAnalyzer/events";

export default {
  name: "LinkSmallChart",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 200,
          stacked: true,
          stackType: "100%",
          toolbar: false,
          zoom: {
            enabled: true,
          },
        },
        title: {
          text: this.propTitle,
          align: "left",
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        xaxis: {
          type: "datetime",
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          show: false,
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
    };
  },
  props: ["propRef", "propType", "propTitle"],
  computed: {},
  methods: {
    resetData() {
      this.series = [];
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/small_columns`;

      let urlAnalyze =
        self.propType === "default"
          ? self.selectedUrl
          : self.selectedCompetitorUrl;

      let config = {
        params: {
          url: urlAnalyze,
          type: self.propRef,
          filter: self.filterData,
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.series = r.data.items;
        self.$refs[`link_small_chart_${self.propRef}`].updateOptions({
          colors: r.data.colors,
        });
      });
    },
  },
  created() {
    let self = this;

    let event =
      self.propType === "default"
        ? "update-url"
        : "update-selected-competitor-url";

    events.$on(event, function () {
      self.resetData();
      self.fetchData();
    });

    events.$on("update-main-filter", function () {
      self.resetData();
      self.fetchData();
    });

    events.$on("update-chart-dates", function (start, end) {
      if (self.series.length > 0) {
        self.$refs[`link_small_chart_${self.propRef}`].zoomX(start, end);
      }
    });
  },
};
</script>

<style scoped></style>
