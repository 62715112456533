var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(false)?_c('span',{staticClass:"d-inline-flex",staticStyle:{"gap":"4px"}},[_c('span',{attrs:{"title":"Number of competitors"}},[_vm._v(" C"),_c('sup',[_c('b',[_vm._v(_vm._s(_vm.item.number_of_competitors))])])]),_c('span',{attrs:{"title":`${_vm.getValueByName('spam_essay')} Essay links found - ${
        _vm.item.spam_date
      }`}},[_vm._v(" e"),_c('sup',[_vm._v(_vm._s(_vm.getValueByName("spam_essay")))])]),_c('span',{attrs:{"title":`${_vm.getValueByName('spam_casino')} Casino links found - ${
        _vm.item.spam_date
      }`}},[_vm._v(" c"),_c('sup',[_vm._v(_vm._s(_vm.getValueByName("spam_casino")))])]),_c('span',{attrs:{"title":`porn - ${_vm.item.spam_date}`}},[_vm._v(" p"),_c('sup',[_vm._v(_vm._s(_vm.getValueByName("spam_porn")))])]),_c('span',{attrs:{"title":`${_vm.getValueByName('spam_replica')} Replica links found - ${
        _vm.item.spam_date
      }`}},[_vm._v(" r"),_c('sup',[_vm._v(_vm._s(_vm.getValueByName("spam_replica")))])]),_vm._v(" = "),_c('div',[_c('span',{attrs:{"title":"spam external link"}},[_vm._v(_vm._s(_vm.getSpamExternalLink))]),_vm._v("/"),_c('span',{attrs:{"title":"external link"}},[_vm._v(_vm._s(_vm.getValueByName("external_link"))+" ")])])]):_vm._e(),_c('div',{staticClass:"d-inline-flex align-center",staticStyle:{"gap":"2px"}},[_c('spam-cell-item',{attrs:{"label":_vm.labels.competitors,"value":_vm.item.number_of_competitors,"found-message":"$v competitors found"}}),_c('spam-cell-item',{attrs:{"label":_vm.labels.essay,"value":_vm.getValueByName('spam_essay'),"found-message":"$v essay links found"}}),_c('spam-cell-item',{attrs:{"label":_vm.labels.casino,"value":_vm.getValueByName('spam_casino'),"found-message":"$v casino links found"}}),_c('spam-cell-item',{attrs:{"label":_vm.labels.porn,"value":_vm.getValueByName('spam_porn'),"found-message":"$v porn links found"}}),_c('spam-cell-item',{attrs:{"label":_vm.labels.replica,"value":_vm.getValueByName('spam_replica'),"found-message":"$v replica links found"}}),_c('span',{staticClass:"px-1"},[_vm._v("=")]),_c('spam-cell-item',{attrs:{"label":_vm.labels.result,"hide-abb":"","flat":"","found-message":"$v","value":`${_vm.getSpamExternalLink}/${_vm.getValueByName('external_link')}`},scopedSlots:_vm._u([{key:"foundMessage",fn:function(){return [_vm._v(" "+_vm._s(_vm.getSpamExternalLink)+" spam external links "),_c('br'),_vm._v(" "+_vm._s(_vm.getValueByName("external_link"))+" external links ")]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }