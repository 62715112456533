<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-card class="styled-card--default" width="660">
          <v-card-title> Insert</v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="url"
                    :loading="loading"
                    filled
                    hide-details
                    dense
                    placeholder="URL"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                    v-model="html"
                    :loading="loading"
                    filled
                    hide-details
                    dense
                    placeholder="HTML string"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
                @click="send"
                large
                class="px-8"
                color="primary"
                :loading="loading"
            >Insert
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-card class="styled-card--default" width="660">
          <v-card-title> Generate</v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                    v-model="lang"
                    :loading="loading"
                    :items="languages"
                    label="Language"
                    dense
                    filled
                    hide-details
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="our_url"
                    :loading="loading"
                    filled
                    hide-details
                    dense
                    placeholder="Our URL"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                    v-model="semantic"
                    :loading="loading"
                    filled
                    hide-details
                    dense
                    placeholder="Semantic"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                    v-model="competitors"
                    :loading="loading"
                    filled
                    hide-details
                    dense
                    placeholder="Competitors"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                    v-model="generate_html"
                    :loading="loading"
                    filled
                    hide-details
                    dense
                    placeholder="HTML"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
                @click="generate"
                large
                class="px-8"
                color="primary"
                :loading="loading"
            >Generate
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import service from "@/plugins/service";

export default {
  data: () => ({
    url: "",
    html: "",
    generate_html: "",
    lang: "en",
    our_url: "",
    semantic: "",
    competitors: "",
    loading: false,
    languages: [
      {value: 'en', text: 'EN'},
      {value: 'fr', text: 'FR'},
      {value: 'it', text: 'IT'},
    ],
  }),
  methods: {
    async send() {
      try {
        const url = "/content-analysis/set-html";

        this.loading = true;

        const resp = await service.post(url, {
          url: this.url,
          html: this.html,
        });

        if (resp?.data?.success) {
          this.$message.notification({
            title: "Done",
            text: "Inserted.",
            type: "success",
          });
          this.html = "";
          this.url = "";
        }
      } catch (e) {
        console.error("Erorr while inserting HTML to URL.", e);
      } finally {
        this.loading = false;
      }
    },
    async generate() {
      try {
        const url = "/content-analysis/generate-html";

        this.loading = true;

        const {our_url, generate_html, semantic, competitors, lang} = this;

        const resp = await service.post(url, {
          our_url,
          html: generate_html,
          semantic,
          lang,
          competitors,
        });

        if (resp?.data?.success) {
          this.$message.notification({
            title: "Done",
            text: "Generated.",
            type: "success",
          });
          this.our_url = "";
          this.generate_html = "";
          this.semantic = "";
          this.competitors = "";
        }
      } catch (e) {
        console.error("Erorr while inserting HTML to URL.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
