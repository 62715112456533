<template>
  <vue-apex-charts
    v-if="data"
    :series="data.series || []"
    :options="computedChartSettings"
    height="280"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  components: { VueApexCharts },

  computed: {
    computedChartSettings() {
      if (!this.data) return {};

      return {
        chart: {
          type: "line",
        },
        xaxis: {
          categories: this.data.categories,
        },
      };
    },
  },
};
</script>
