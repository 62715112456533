<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e3"
    width="680"
    scrollable
  >
    <v-card flat rounded="lg">
      <v-card-title class="pa-4 text-body-2">
        Add prompt
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2">Category</div>
            <v-text-field
              v-model="form.category"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>

          <v-col cols="12">
            <div class="text-body-2">Intent</div>
            <v-combobox
              placeholder="Type to search"
              :items="filtersData()?.intents"
              v-model="form.intent"
              @focus="isSaveDisabled = true"
              @blur="isSaveDisabled = false"
              item-text="text"
              item-value="value"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Page type</div>
            <v-autocomplete
              placeholder="Type to search"
              :items="filtersData()?.page_types"
              v-model="form.page_type_id"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Niche</div>
            <v-autocomplete
              placeholder="Type to search"
              :items="filtersData()?.niches"
              v-model="form.niche_id"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Structure</div>
            <v-textarea
              v-model="form.structure"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Structure AI</div>
            <v-textarea
              v-model="form.structure_ai"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Prompt</div>
            <v-textarea
              v-model="form.prompt"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">Prompt AI</div>
            <v-textarea
              v-model="form.prompt_ai"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          :loading="loadings.save"
          :disabled="isSaveDisabled"
          @click="handleSavePrompt"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import { promptsApiService } from "../services/promptsApiService";

export default {
  inject: ["filtersData"],

  mixins: [Dialog],

  data: () => ({
    isSaveDisabled: false,
    form: {
      id: null,
      category: "",
      intent: null,
      page_type_id: null,
      niche_id: null,
      structure: "",
      structure_ai: "",
      prompt: "",
      prompt_ai: "",
    },
    loadings: {
      save: false,
    },
  }),

  computed: {
    getFixedPayload() {
      let intentValue;

      if (this.form.intent && typeof this.form.intent === "object")
        intentValue = this.form.intent?.value;
      if (this.form.intent && typeof this.form.intent === "string")
        intentValue = this.form.intent;

      return {
        ...this.form,
        intent: intentValue,
      };
    },
  },

  methods: {
    resetForm() {
      this.form = {
        id: null,
        category: "",
        intent: "",
        page_type: null,
        niche: null,
        structure: "",
        structure_ai: "",
        prompt: "",
        prompt_ai: "",
      };
    },
    async handleSavePrompt() {
      try {
        await this.$nextTick();

        this.loadings.save = true;

        await promptsApiService().addPrompt(this.getFixedPayload);

        this.resetForm();
        this.dialog = false;
        this.$emit("saved");
      } catch (e) {
        console.error("Error while saving prompt.", e);
      } finally {
        this.loadings.save = false;
      }
    },
  },
};
</script>
