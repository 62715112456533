import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "managersGpm/fetchTableData"(ctx, payload) {
      const url = `/dashboards/gpm/manager-outreach?is_mock=${payload.isMock}`;
      const response = await Service.post(url, payload.body);
      if (response) {
        return response.data;
      }
      return null;
    },
  },
  getters: {},
};
