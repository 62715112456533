<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Admin fields</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
        <v-col cols="12">
          <v-card class="styled-card--light" rounded="lg">
            <v-card-title>
              <span>Admin fields </span>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                clearable
                hide-details
                style="max-width: 240px"
                dense
                filled
                class="align-end"
              ></v-text-field>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-data-table
                :search="search"
                :loading="loading"
                :headers="headers"
                :items="items"
                :items-per-page="-1"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn icon @click="editField(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteField(item)">
                    <v-icon small color="error"> mdi-delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-btn fixed dark fab bottom right color="pink" @click="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="800px">
      <v-card class="styled-card--default">
        <v-card-title> Field </v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Name"
                  dense
                  clearable
                  hide-details
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  dense
                  hide-details
                  clearable
                  filled
                  label="Niche"
                  prepend-inner-icon="mdi-bullseye-arrow"
                  v-model="form.niche_id"
                  :items="niches"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  dense
                  hide-details
                  filled
                  clearable
                  label="Page Type"
                  prepend-inner-icon="mdi-tag-outline"
                  v-model="form.id_page_type"
                  :items="page_types"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  hide-details
                  clearable
                  prepend-inner-icon="mdi-priority-high"
                  v-model="form.priority"
                  label="Priority"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  dense
                  clearable
                  v-model="form.manual_link"
                  label="Manual Link"
                  prepend-inner-icon="mdi-link-variant"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="form.hidden"
                  hide-details
                  dense
                  style="margin: 0"
                  label="Hidden"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-card class="styled-card--light">
                  <v-card-title class="d-flex">
                    Checkboxes
                    <v-spacer />
                    <v-chip
                      class="flat-chip--primary"
                      label
                      @click="addCheckbox()"
                    >
                      Add checkbox <v-icon small right>mdi-plus</v-icon>
                    </v-chip>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <template v-if="form.checkboxes.length > 0">
                      <v-row>
                        <transition-group name="slide">
                          <v-col
                            v-for="i in getSortedByPriority(form.checkboxes)"
                            cols="12"
                            :key="i.tmpr_id"
                          >
                            <v-row dense>
                              <v-col>
                                <v-row dense>
                                  <v-col cols="6">
                                    <v-text-field
                                      dense
                                      v-model="i.short_name"
                                      placeholder="Shortname"
                                      prepend-inner-icon="mdi-text-short"
                                      filled
                                      clearable
                                      hide-details
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="6">
                                    <v-text-field
                                      dense
                                      v-model="i.priority"
                                      placeholder="Sequence"
                                      type="number"
                                      prepend-inner-icon="mdi-view-sequential-outline"
                                      filled
                                      clearable
                                      hide-details
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-textarea
                                      v-model="i.name"
                                      hide-details
                                      dense
                                      placeholder="Name"
                                      filled
                                      rows="2"
                                    />
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col
                                class="flex-shrink-0 d-flex align-center"
                                style="max-width: 38px"
                              >
                                <v-btn icon @click="deleteCheckbox(i.tmpr_id)">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </transition-group>
                      </v-row>
                    </template>
                    <template v-else>
                      <div class="py-4 d-flex justify-center">
                        <span style="opacity: 0.5"
                          >Empty. Let's add new checkboxes</span
                        >
                      </div>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip label class="mr-2 flat-chip--error" @click="closeDialog" text>
            Close
          </v-chip>
          <v-chip
            color="success"
            label
            @click="createField()"
            :disabled="dialog_loading"
          >
            <span v-if="dialog_edit">Save changes</span>
            <span v-else>Create</span>
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { uid } from "@/utils/functions";

export default {
  name: "FieldManager",
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Site Workplace",
          disabled: true,
        },
        {
          text: "Admin fields",
          disabled: true,
        },
      ],
      hidden: false,
      search: "",
      dialog_edit: false,
      dialog: false,
      dialog_loading: false,
      loading: false,
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: "Name", align: "start", value: "name" },
        { text: "Niche", align: "start", value: "niche_name" },
        { text: "Page Type", align: "start", value: "page_type_name" },
        { text: "", align: "start", value: "actions", width: 120 },
      ],
      items: [],
      form: {
        name: null,
        niche_id: null,
        id_page_type: null,
        priority: null,
        manual_link: null,
        hidden: false,
        checkboxes: [],
      },
      niches: [],
      page_types: [],
    };
  },
  methods: {
    getSortedByPriority(items) {
      return items.sort((a, b) => {
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return 1;
        return 0;
      });
    },
    addCheckbox() {
      this.form.checkboxes.push({
        short_name: null,
        id: null,
        tmpr_id: uid(),
        name: null,
        priority: 0,
      });
      this.$forceUpdate();
    },
    deleteCheckbox(id) {
      this.form.checkboxes = this.form.checkboxes.filter(
        (v) => v.tmpr_id !== id
      );
      this.$forceUpdate();
    },
    resetData() {
      this.form = {
        name: null,
        niche_id: null,
        id_page_type: null,
        priority: null,
        manual_link: null,
        hidden: false,
        checkboxes: [],
      };
    },
    closeDialog() {
      this.dialog = false;
      this.dialog_edit = false;
      this.resetData();
    },
    editField(item) {
      this.form = item;
      this.dialog = true;
      this.dialog_edit = true;
      this.form.checkboxes = [];

      this.fetchCheckboxes(item.id);
    },
    deleteField(item) {
      if (!confirm("Are you sure?")) {
        return;
      }

      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/site-workplace/admin/delete-field`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.post(url, item, config).then(() => {
        self.fetchData();
      });
    },
    fetchCheckboxes(id) {
      this.loading = true;

      let url = `${this.$store.state.server_url}/site-workplace/admin/get-checkboxes`;

      let config = {
        params: {
          fieldID: id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          this.form.checkboxes = this.formCheckboxesAdapter(r.data.items);
        })
        .finally(() => (this.loading = false));
    },
    formCheckboxesAdapter(items) {
      return items.map((v) => {
        v.tmpr_id = uid();
        return v;
      });
    },
    fetchData() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/site-workplace/admin/get-fields`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.niches = r.data.niches;
          self.page_types = r.data.page_types;
          self.items = r.data.items;
        })
        .finally(() => (self.loading = false));
    },
    createField() {
      let self = this;

      self.dialog_loading = true;

      let url = `${self.$store.state.server_url}/site-workplace/admin/create-field`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            form: self.form,
            edit: self.dialog_edit,
          },
          config
        )
        .then(() => {
          self.fetchData();
        })
        .finally(() => {
          self.dialog_loading = false;
          self.dialog_edit = false;
          self.closeDialog();
        });
    },
  },
  created() {
    this.resetData();
    this.fetchData();
  },
};
</script>
