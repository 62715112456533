<template>
  <div class="simple-table">
    <div class="simple-table__wrapper" @scroll="handleScroll">
      <table class="simple-table__table centered-content">
        <template v-if="!haveIndividualSubheaders">
          <tr class="simple-table__headers">
            <th class="simple-table__placeholder-cell"></th>
            <th
              v-for="header in getMainHeaders"
              :key="header.id"
              :colspan="calculateColspan"
              class="simple-table__headers"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr v-if="haveSubheaders" class="simple-table__sub-headers">
            <th
              class="simple-table__placeholder-cell url"
              style="text-align: left"
            >
              Url
            </th>
            <template v-for="subheaders in getSubheaders">
              <th v-for="header in subheaders" :key="header.id">
                {{ header.text }}
              </th>
            </template>
          </tr>
        </template>
        <template v-else>
          <tr class="simple-table__headers borderless">
            <th class="simple-table__placeholder-cell"></th>
            <th
              v-for="header in data.headers"
              :key="header.value"
              :colspan="header.colspan"
              class="simple-table__headers"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr v-if="haveSubheaders" class="simple-table__sub-headers">
            <th
              class="simple-table__placeholder-cell url"
              style="text-align: left"
            >
              Url
            </th>
            <th v-for="header in data.subheaders" :key="header.id">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <tr
          v-for="items in getItems"
          :key="items.id"
          :class="`simple-table__items ${scrolledX ? 'scrolled-x' : ''}`"
        >
          <td
            v-for="(item, i) in items.items"
            :key="item.id"
            :class="
              i % calculateColspan === 0 && !haveIndividualSubheaders
                ? 'bordered'
                : ''
            "
            :style="`background-color: ${getRgb(
              item.value.value,
              getMax(getItems, i),
              50,
              233,
              153
            )}`"
          >
            <template v-if="i === 0">
              <td-domain :data="item.value" />
            </template>
            <template v-else>
              <div
                v-if="item.value && item.value.value"
                style="cursor: pointer"
                class="colored-td colored-td--clickable"
                @click="goToSpiderLink(item.value, items.items[0].value)"
              >
                {{ item.value.value }}
              </div>
            </template>
          </td>
        </tr>
      </table>
    </div>
    <div class="simple-table__pagination">
      <v-pagination v-model="page" :length="items.length"></v-pagination>
    </div>
  </div>
</template>

<script>
import TdDomain from "@/components/OnePageAnalyzer/LinksDynamicDashboard/td/TdDomain";
import Project from "@/mixins/Project";
import SpiderLink from "@/mixins/components/LinksDynamicDashboard/SpiderLink";
import GetRgb from "@/mixins/GetRgb";

export default {
  components: { TdDomain },
  mixins: [Project, SpiderLink, GetRgb],
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  created() {
    let page = [];
    this.data.items.forEach((item, index) => {
      if (index === this.data.items.length - 1) {
        this.items.push(page);
      }
      if (page.length >= this.itemsPerPage) {
        this.items.push(page);
        return (page = []);
      }
      page.push(item);
    });
  },
  data: () => ({
    scrolledX: false,
    page: 1,
    items: [],
  }),
  computed: {
    haveIndividualSubheaders() {
      // eslint-disable-next-line no-prototype-builtins
      return this.data.headers[0].hasOwnProperty("colspan");
    },
    getMainHeaders() {
      return this.fixMainHeaders(this.data.headers);
    },
    pageCount() {
      return this.items.length;
    },
    getItems() {
      // console.log('FIXED ITEMS : ', this.fixItems(this.items[this.page - 1]))
      return this.fixItems(this.items[this.page - 1]);
    },
    calculateColspan() {
      return (this.data.items[0].length - 1) / this.data.headers.length;
    },
    haveSubheaders() {
      if (!this.data.subheaders) {
        return false;
      }
      return this.data.subheaders.length > 0;
    },
    getSubheaders() {
      const amountOfHeaders = this.data.headers.length;
      const output = [];

      for (let i = 0; i < amountOfHeaders; i++) {
        const fixedSubheaders = this.data.subheaders.map((subheader) => {
          const { value, text } = subheader;
          return (subheader = { value, text, id: this.uniqueid() });
        });
        output.push(fixedSubheaders);
      }
      return output;
    },
  },
  methods: {
    fixMainHeaders(headers) {
      return (headers = headers.map((header) => {
        const { text, value } = header;
        return (header = { text, value, id: this.uniqueid() });
      }));
    },
    uniqueid() {
      return String(
        Date.now().toString(32) + Math.random().toString(16)
      ).replace(/\./g, "");
    },
    fixItems(items) {
      items = items.map((row) => {
        const fixedRow = row.map((col) => {
          return (col = { value: col, id: this.uniqueid() });
        });
        return (row = { items: fixedRow, id: this.uniqueid() });
      });
      return items;
    },
    getMax(items, key) {
      let bigger = 0;

      items.forEach((row) => {
        const val = row.items[key].value.value;
        if (typeof val !== "string") {
          if (parseInt(val) > bigger) bigger = parseInt(val);
        }
      });

      return bigger;
    },
    handleScroll(e) {
      const left = e.target
        .querySelector(".simple-table__items")
        .getBoundingClientRect().left;
      if (left < 0) {
        return (this.scrolledX = true);
      }
      this.scrolledX = false;
    },
  },
};
</script>
