<template>
  <td>
    <div class="d-flex align-center">
      <div v-if="data.donor.used" style="white-space: nowrap">
        <v-btn
          v-if="data.gpm_task_id"
          small
          class="mr-1"
          icon
          @click="goToViewEntity"
          title="edit domain"
        >
          <v-icon small>mdi-note-edit-outline</v-icon>
        </v-btn>
        <v-badge color="transparent">
          <template v-slot:badge>
            <div
              :style="`color:${getUsedDonorColor};height: 100%;`"
              :class="`colored-td d-flex align-center ${getColor}`"
            >
              {{ data.donor.used }}
            </div>
          </template>
          <span>{{ data.donor.text }}</span>
        </v-badge>
      </div>
      <div
        v-else
        :style="`color:${getUsedDonorColor};height: 100%;white-space:nowrap;`"
        :class="`colored-td d-flex align-center ${getColor}`"
      >
        <v-btn
          v-if="data.gpm_task_id"
          small
          class="mr-1"
          icon
          @click="goToViewEntity"
          title="Edit domain"
        >
          <v-icon small>mdi-note-edit-outline</v-icon>
        </v-btn>
        {{ data.donor.text }}
      </div>
      <v-chip v-if="haveFl" color="orange" title="Has friendly link/s" x-small>
        FL
      </v-chip>
      <v-chip
        v-if="data.donor.links && String(data.donor.links) !== '0'"
        color="info"
        x-small
        title="Amount of used links"
      >
        {{ data.donor.links }}
      </v-chip>
      <v-chip
        class="ml-1"
        v-if="data.friendly_links"
        title="Amount of friendly links"
        color="success"
        x-small
        >FL</v-chip
      >
    </div>
  </td>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    haveFl() {
      try {
        // eslint-disable-next-line no-prototype-builtins
        return this.data.donor.hasOwnProperty("fl") && this.data.donor.fl;
      } catch (e) {
        return false;
      }
    },
    getColor() {
      const currDate = this.$moment(new Date());
      const date = this.$moment(this.data.created_at);
      const diff = currDate.diff(date, "days");

      const haveDonor = this.data.donor.text !== "";

      if (!haveDonor && diff >= 14 && diff <= 21) return " yellow";
      if (!haveDonor && diff > 21) return " red";
      return "";
    },
    getUsedDonorColor() {
      const usedCases = this.data.donor.used;
      if (usedCases === 1) {
        return "orange";
      }
      if (usedCases > 1) {
        return "red";
      }
      return "";
    },
  },
  methods: {
    goToViewEntity() {
      if (!this.data.gpm_task_id) return;
      const url = "/gpm/view-entity/" + this.data.gpm_task_id;
      window.open(url, "_blank");
    },
  },
};
</script>
