import Service from "@/plugins/service";
import service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "gscOneYearReport/fetchData"(ctx, { filters, projectId }) {
      const url = `/search-console/1-year-report?project_id=${projectId}`;

      const response = await Service.get(url, true, filters);

      if (response) {
        return response.data;
      }
    },
    async "gscOneYearReport/get_positions_table_Data"(ctx, payload) {
      const url = "/search-console/1-year-report/positions";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "gscOneYearReport/get_conv_dynamic_table_Data"(ctx, payload) {
      const url = "/search-console/1-year-report/conversion-dynamics";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "gscOneYearReport/get_conv_by_page_type_table_Data"(ctx, payload) {
      const url = "/search-console/1-year-report/conversion-by-page-types";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "gscOneYearReport/get_link_by_project_table_Data"(ctx, payload) {
      const url = "/search-console/1-year-report/links-by-project";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "gscOneYearReport/get_content_table_Data"(ctx, payload) {
      const url = "/search-console/1-year-report/content";
      const resp = await service.post(url, payload, true);
      if (resp) return resp.data;
    },
    async "gscOneYearReport/get_filters_data"({ rootState, commit }) {
      const project_id = rootState.project.active_project;
      const url = `/site-workplace/${project_id}/get-filter-data`;
      const response = await Service.get(url, true);

      commit("one_year_report/set_filters_data", response.data);
    },
  },
  getters: {},
};
