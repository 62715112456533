<template>
  <div class="text-center" @click="handleShow">
    <div
      :class="`colored-td colored-td--inline colored-td--clickable ${getBgColor}`"
    >
      {{ data[valueKey] }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {},
    content: {},
    name: {},
    valueKey: {
      type: String,
      default: "percent",
    },
  },
  data: () => ({
    listOfDetailsCols: {
      content_score: { name: "Content Score" },
      total_words: { name: "Total Words" },
      total_sentences: { name: "Total Sentences" },
      average_sentence_length: { name: "Average sentence length" },
      semantic_vocabulary: { name: "Semantic Vocabulary" },
      vocabulary: { name: "Vocabulary" },
      water_index: { name: "Water Index" },
      zipfs_law: { name: "Naturalness" },
      classical_nausea_index: { name: "Classical Nausea Index" },
      dale_chall_score: { name: "Dale–Chall Readability" },
      spache: { name: "Spache Readability" },
      flesch: { name: "Flesch Readability" },
      automated_index: { name: "Automated readability index Readabilit" },
    },
  }),
  computed: {
    getBgColor() {
      try {
        const isPositive = ["water_index"].includes(this.name);

        const isFlex = ["flesch", "average_sentence_length"].includes(
          this.name
        );

        const value = this.data?.percent;

        if (isFlex) {
          if (value >= 6 && value <= 15) return "yellow";
          if (value >= 16) return "red";
          if (value <= -6 && value >= -15) return "yellow";
          if (value <= -16) return "red";

          return "";
        }

        if (isPositive) {
          if (value >= 6 && value <= 15) return "yellow";
          if (value >= 16) return "red";

          return "";
        }

        if (value <= -30 && value >= -10) return "yellow";
        if (value <= -30) return "red";

        return "";
      } catch {
        return "";
      }
    },
  },
  methods: {
    handleShow() {
      const data = {};
      const deviation = {};

      Object.keys(this.listOfDetailsCols).forEach((key) => {
        data[key] = this.content[key].value;
        deviation[key] = this.content[key]?.percent;
      });

      const amountItems = [
        {
          url: "",
          amount: this.data?.percent,
          ...deviation,
        },
        {
          url: this.content.url,
          amount: this.data.items[0],
          ...data,
        },
      ];

      this.data.items.forEach((item, idx) => {
        if (idx === 0) return;

        const currentUrl = this.content.urls[idx - 1];

        const data = {};

        Object.keys(this.listOfDetailsCols).forEach((key) => {
          data[key] = this.content[key].items[idx];
        });

        amountItems.push({
          url: currentUrl,
          amount: this.data.items[idx],
          ...data,
        });
      });

      this.$emit("show", { amountItems });
    },
  },
};
</script>
