<template>
  <div class="conversion-6">
    <v-row dense>
      <v-col cols="12">
        <page-name :theme="$vuetify.theme.dark" small="">
          Conversion 6
        </page-name>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <div style="position: relative">
          <div
            v-if="filters.comparison[0].length !== 0"
            class="disabled-tooltip bottom"
          >
            <span> When comparison filter enabled - date can't be used. </span>
          </div>
          <date-filter
            :model-value="filters.date_range"
            :disabled="filters.comparison[0].length !== 0"
            @update:modelValue="handleUpdateDateFilter"
          />
        </div>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Brand"
          v-model="filters.brand"
          :items="brandItems"
          item-text="name"
          item-value="name"
          clearable
          outlined
          multiple
          dense
          hide-details
          :menu-props="menuProps"
          @change="_$handleFilterChange($event, FILTERS_IDS.brand)"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Format"
          v-model="filters.format"
          :items="formatItems"
          item-text="name"
          item-value="id"
          clearable
          outlined
          dense
          hide-details
          :menu-props="menuProps"
          @change="_$handleFilterChange($event, FILTERS_IDS.format)"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          label="Projects"
          v-model="filters.projects"
          :items="_$getProjects"
          item-text="name"
          item-value="id"
          multiple
          clearable
          outlined
          dense
          hide-details
          @change="_$handleFilterChange($event, FILTERS_IDS.projects)"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <div class="fix-button-height" style="position: relative">
          <comparison-filter
            :model-value="filters.comparison"
            @update:modelValue="handleUpdateComparisonFilter"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          elevation="0"
          color="primary"
          @click="fetchData"
          :loading="loadings.brands || loadings.tables"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <table-wrapper
          :items="tables"
          :loading="loadings.tables"
          :first-enter="firstEnter"
        >
          <v-data-table
            v-for="(table, i) in tables"
            :key="i"
            :headers="table.headers"
            :items="table.items"
          >
            <template #top>
              <div class="project-name">
                {{ table.project }}
              </div>
            </template>
            <template #body="{ items }">
              <custom-tbody :items="items" />
            </template>
          </v-data-table>
        </table-wrapper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import eventPipe from "../../../events/eventPipe";
import DateFilter from "@/components/PositionsDashboard/Header/DateFilter";
import PageName from "@/components/DashboardDev/PageName";
import {
  CONVERSION_4_CHART_OPTIONS,
  CONVERSION_6_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import ComparisonFilter from "@/components/UI/ComparisonFilter";
import TableWrapper from "@/components/UI/TableWrapper";
import Project from "@/mixins/Project";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import CustomTbody from "@/components/Analytics/Conversion/UI/Conversion6/CustomTbody";
import Location from "../../../mixins/Location";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "Chart",
  components: {
    CustomTbody,
    TableWrapper,
    ComparisonFilter,
    DateFilter,
    PageName,
  },
  mixins: [OnePageAnalyzerIndex, Project, FiltersHandling, Location],
  data: () => ({
    firstEnter: true,
    menuProps: DEFAULT_MENU_PROPS,
    loadings: {
      tables: false,
      brands: false,
    },
    series: [],
    projects: [],
    filtersData: {
      pageType: [],
    },
    FILTERS_IDS: {
      projects: "projects",
      date_range: "date_range",
      comparison: "comparison",
      brand: "brand",
      format: "format",
    },
    filters: {
      projects: [],
      date_range: [],
      comparison: [[], []],
      brand: [],
      format: null,
    },
    tables: [],
    tablesData: {
      headers: CONVERSION_6_TABLE_HEADERS,
    },
    tableHeaders: [],
    tableItems: [],
    date_modal: false,
    brandItems: [],
    formatItems: [
      { id: 3, name: "day" },
      { id: 1, name: "week" },
      { id: 2, name: "month" },
      { id: 4, name: "quarter" },
      { id: 5, name: "half year" },
      { id: 6, name: "year" },
    ],
    options: CONVERSION_4_CHART_OPTIONS,
  }),
  watch: {
    "filters.comparison"(value) {
      if (value) {
        this.filters.date_range = [];
      }
    },
  },
  methods: {
    handleUpdateComparisonFilter(event) {
      this.filters.comparison = event;
    },
    handleUpdateDateFilter(event) {
      this.filters.date_range = event;
      this._$handleFilterChange(event, this.FILTERS_IDS.date_range);
    },
    async fetchBrand() {
      this.firstEnter = false;

      if (this.id_project === null || this.id_project === undefined) {
        return;
      }

      this.loadings.brands = true;

      const data = {
        payload: {
          params: this.filters,
        },
        projectId: this.id_project,
      };

      const resp = await this.$store.dispatch("conversion-4/fetchBrands", data);
      if (resp) {
        this.table.items = resp.data;
      }

      this.loadings.brands = false;
    },
    async fetchData() {
      if (this.id_project === null || this.id_project === undefined) {
        return;
      }

      this.loadings.tables = true;
      this.firstEnter = false;

      const data = {
        payload: {
          filters: {
            projects: this.filters.projects,
            date_range: this.filters.date_range,
            comparison: this.filters.comparison,
            brand: this.filters.brand,
            format: this.filters.format,
          },
        },
        projectId: this.id_project,
      };

      const resp = await this.$store.dispatch("conversion-6/fetchData", data);
      if (resp) {
        this.tables = resp.tables;
        this.brandItems = resp.brands;
        this.$forceUpdate();
      }

      this.loadings.tables = false;
    },
  },
  mounted() {
    this._$collectParams(["projects", "date_range", "brand"], "filters");

    if (!this.$route.query[this.FILTERS_IDS.date_range]) {
      this.filters.date_range = [
        dateFormat(new Date().setMonth(date.getMonth() - 12), "yyyy-mm-dd"),
        dateFormat(date, "yyyy-mm-dd"),
      ];
    }

    if (!this.$route.query[this.FILTERS_IDS.format]) {
      this.filters.format = 1;
    }

    eventPipe.$on("update-active-project", () => this.fetchData);
  },
  computed: {
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
};
</script>

<style scoped></style>
