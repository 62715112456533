import service from "../../../plugins/service";

const storeName = "<paramsCollecting>";

export default {
  state: {
    swCountries: [],
    allowedParams: [],
  },
  mutations: {
    "paramsCollecting/setSwCountries"(state, payload) {
      state.swCountries = payload;
    },
    "paramsCollecting/setAllowedParams"(state, payload) {
      state.allowedParams = payload;
    },
  },
  actions: {
    async "paramsCollecting/initAllowedParams"({ commit, getters }) {
      if (getters["paramsCollecting/getAllowedParams"]?.length > 0) return;

      try {
        const response = await service.get(
          "/api/dct/params",
          true,
          {},
          {
            prefix: "",
          }
        );

        commit(
          "paramsCollecting/setAllowedParams",
          response?.data?.params || []
        );
      } catch (e) {
        console.error(
          `${storeName} Error while initializing allowed params.`,
          e
        );
      }
    },
    async "paramsCollecting/initSWCountries"({ commit, getters }) {
      if (getters["paramsCollecting/getSwCountries"]?.length > 0) return;

      try {
        const response = await service.get(
          "/api/dct/countries",
          true,
          {},
          {
            prefix: "",
          }
        );

        commit("paramsCollecting/setSwCountries", response?.data || []);
      } catch (e) {
        console.error(`${storeName} Error while initializing countries.`, e);
      }
    },
  },
  getters: {
    "paramsCollecting/getAllowedParams": (state) => state.allowedParams,
    "paramsCollecting/getSwCountries": (state) => state.swCountries,
  },
};

// 1. Потестить стор ( реюзабельность данных )
// 2. Потестить логику "start collection"
// 3. Проверить все ли данные улетают на бек
