<template>
    <div>
        <v-container fluid>

            <v-row>
                <v-col cols="12" md="12">
                    <v-card
                            class="mx-auto"
                    >
                        <v-card-text>
                            <v-subheader>
                                <v-chip
                                        class="ma-2"
                                        label
                                >
                                    <v-icon left>
                                        mdi-label
                                    </v-icon>
                                    Aparser
                                </v-chip>
                                <v-chip
                                        class="ma-2"
                                        color="pink"
                                        label
                                        text-color="white"
                                >

                                     BackLinks
                                </v-chip>
                            </v-subheader>

                            <v-row
                                    class="align-end"
                            >
                                <v-col
                                        cols="4">
                                    Query:
                                    <v-chip
                                            class="ma-2"
                                            color="secondary"
                                    >
                                        donor_url
                                    </v-chip>
                                    <v-chip
                                            class="ma-2"
                                            color="secondary"
                                    >
                                        acceptor_url
                                    </v-chip>

                                </v-col>
                                <v-col
                                        cols="8"
                                >
                                    <div class="text-sm-left">
                                        Result:
                                        <v-chip
                                                class="ma-2"
                                                color="secondary"
                                        >
                                            donor_url
                                        </v-chip>
                                        <v-chip
                                                class="ma-2"
                                                color="secondary"
                                        >
                                            acceptor_url
                                        </v-chip>
                                        <v-chip
                                                class="ma-2"
                                                color="secondary"
                                        >
                                            anchor
                                        </v-chip>
                                        <v-chip
                                                class="ma-2"
                                                color="secondary"
                                        >
                                            donor_actual_url
                                        </v-chip>
                                        <v-chip
                                                class="ma-2"
                                                color="secondary"
                                        >
                                            islink
                                        </v-chip>
                                        <v-chip
                                                class="ma-2"
                                                color="secondary"
                                        >
                                            is_noindex
                                        </v-chip>
                                    </div>
                                </v-col>
                                <v-col
                                        cols="12">
                                    <template>
                                        <v-container fluid>
                                            <v-row>
                                                <v-col
                                                        cols="12"
                                                        sm="4"
                                                >
                                                    <v-textarea
                                                            v-model="textarea.value"
                                                            :label=textarea.label
                                                            auto-grow
                                                            outlined
                                                            :placeholder=textarea.placeholder
                                                            rows="14"
                                                            row-height="25"
                                                            shaped
                                                    ></v-textarea>
                                                    <div class="text-center">
                                                        <v-btn
                                                                depressed
                                                                color="primary"
                                                                @click="fetch_data"
                                                        >
                                                            Add task
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                                <v-col
                                                        cols="12"
                                                        sm="8"
                                                >
                                                    <v-textarea
                                                            v-model="result.message"
                                                            filled
                                                            readonly
                                                            auto-grow
                                                            :label=result.label
                                                            rows=13
                                                            row-height="25"
                                                            shaped
                                                    ></v-textarea>
                                                </v-col>

                                            </v-row>
                                        </v-container>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import axios from "axios";

export default {
        name: "BackLinksIndex",
        data() {
            return {
                loadings: {
                    fetch_data: false,
                },
                textarea: {
                    placeholder: "donor_url acceptor_url\ndonor_url acceptor_url\ndonor_url acceptor_url",
                    label: "Links",
                    value: "https://www.hipsthetic.com/ https://finance.yahoo.com/"
                },
                result: {
                    label: "Result",
                    message: ""
                }
            }
        },
        //components: {MultipleLineChart},
        // components: {SimpleLineChart, MultipleLineChart},
        methods: {
            fetch_data() {
                let self = this;

                self.update_loading('fetch_data', true)

                let url = `${self.$store.state.server_url}/aparser/back-links`;

                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.$store.state.auth.token
                    }
                };

                let data = {query: self.textarea.value}

                axios.post(url, data, config)
                    .then(function (response) {
                        self.result.message = response.data.message;
                        self.update_loading('fetch_data', false)
                    }).catch(function (error) {
                    self.result.message = error;
                    self.update_loading('fetch_data', false)
                });

            },
            update_loading(type, value) {
                this.$store.state.loading = false
                this.loadings[type] = value
                for (let i in this.loadings) {
                    if (this.loadings[i] === true) {
                        this.$store.state.loading = true
                    }
                }
            },
            update_setting() {
                this.$store.dispatch('update_ls_serp_setting', this.ls_settings)
            }
        },
        computed: {
            isset_active_project() {
                return this.id_project !== null && this.id_project !== undefined
            },
            id_project() {
                return this.$store.state.project.active_project
            }
        },
        created() {

        },
    }
</script>

<style scoped>

</style>