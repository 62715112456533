<template>
  <div @click="$emit('push')" style="cursor: pointer" class="clicked">
    <v-progress-circular
      class="ml-5"
      :value="value"
      size="32"
      :color="getColor"
    >
      {{ value }}
    </v-progress-circular>
  </div>
</template>

<script>
import { URL_OVERVIEW_COLORS } from "@/utils/defaultData";

export default {
  props: ["value"],
  computed: {
    getColor() {
      const { green, yellow, red } = URL_OVERVIEW_COLORS;
      if (this.value === 100) {
        return green;
      }
      if (this.value >= 60 && this.value <= 99) {
        return yellow;
      }
      if (this.value >= 0 && this.value <= 59) {
        return red;
      }

      return "transparent";
    },
  },
};
</script>
