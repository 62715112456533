<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h2>3rd Blog</h2>
          <v-breadcrumbs
            class="pa-0"
            :items="items_breadcrumbs"
          ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card :loading="loading">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                solo
                class="align-end"
              ></v-text-field>
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                :nudge-right="200"
                v-model="modal.shown"
                :value="modal.shown"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-fab-transition>
                    <v-btn
                      color="info"
                      dark
                      small
                      top
                      right
                      fab
                      absolute
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </template>
                <v-card style="max-width: 500px; min-width: 500px">
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="filter.date_range = $event"
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          dense
                          outlined
                          v-model="filter.projects"
                          :items="projects"
                          item-value="id"
                          item-text="name"
                          label="Projects"
                          chips
                          small-chips
                          deletable-chips
                          clearable
                          multiple
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          dense
                          outlined
                          v-model="filter.statuses"
                          :items="statuses.statuses"
                          label="Statuses"
                          hide-details
                          chips
                          small-chips
                          deletable-chips
                          clearable
                          multiple
                          @change="updateFilter"
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-autocomplete
                          dense
                          outlined
                          v-model="filter.responsible"
                          :items="responsibilities"
                          label="Responsible"
                          hide-details
                          clearable
                          @change="updateFilter"
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          dense
                          outlined
                          v-model="filter.niche"
                          :items="niches"
                          item-value="id"
                          item-text="name"
                          label="Niche"
                          hide-details
                        ></v-select>
                      </v-list-item>
                      <v-list-item>
                        <v-combobox
                          dense
                          outlined
                          v-model="filter.performer"
                          :items="performers"
                          item-value="id"
                          item-text="name"
                          label="Performer"
                          hide-details
                        ></v-combobox>
                      </v-list-item>
                      <v-list-item>
                        <v-checkbox
                          v-model="filter.critical_estimate"
                          label="Critical Estimate"
                          @change="updateFilter"
                        ></v-checkbox>
                      </v-list-item>
                    </v-list>
                    <div class="text-center px-4 d-flex justify-space-between">
                      <v-btn @click="saveUserSettings" color="primary">
                        Apply
                      </v-btn>
                      <v-btn
                        @click="exportPostedLinks"
                        outlined
                        class="ml-2"
                        color="primary"
                      >
                        <v-icon small class="mr-2"
                          >mdi-cloud-download-outline</v-icon
                        >
                        Posted Links
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
          </v-card>
          <div v-for="cat_project in cat_projects" :key="cat_project">
            <v-card class="mt-4" v-if="items[cat_project].length > 0">
              <v-card-subtitle>
                <v-row no-gutters style="flex-wrap: nowrap">
                  <v-col
                    cols="2"
                    style="min-width: 100px; max-width: 100%"
                    class="flex-grow-1 flex-shrink-0 pr-3"
                  >
                    <span>
                      {{ cat_projects_data[cat_project] }}
                    </span>
                    <v-progress-circular
                      class="ml-5"
                      :value="
                        (stat[cat_project].c_done * 100) /
                        stat[cat_project].c_all
                      "
                      size="40"
                      >{{ stat[cat_project].c_done }}/{{
                        stat[cat_project].c_all
                      }}
                    </v-progress-circular>
                  </v-col>
                  <v-col
                    cols="1"
                    style="max-width: 30px"
                    class="flex-grow-0 flex-shrink-0 d-flex"
                  >
                    <v-btn icon @click="viewAnchorPlan(cat_project)">
                      <v-icon>mdi-calendar-month-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="items[cat_project]"
                  :search="search"
                  :custom-sort="customSort"
                  item-key="name"
                  class="elevation-1"
                  :footer-props="{ 'items-per-page-options': [10, 15, 50, -1] }"
                  :items-per-page="50"
                  hide-default-header
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        :style="
                          item.diff_in_hour >= item.critical_estimate
                            ? 'background-color:#ff00001c;'
                            : ''
                        "
                        v-for="item in items"
                        :key="item.domain"
                      >
                        <td>
                          <div style="white-space: nowrap">
                            <a
                              :href="`http://${item.domain}`"
                              target="_blank"
                              >{{ item.domain }}</a
                            >
                            <v-btn
                              icon
                              :to="`/gpm/view-entity/${item.id}`"
                              link
                              target="_blank"
                            >
                              <v-icon>mdi-login-variant</v-icon>
                            </v-btn>
                            <v-badge
                              v-if="item.messages.unread > 0"
                              :content="item.messages.unread"
                              :value="item.messages.unread > 0"
                              color="error"
                              overlap
                            >
                              <v-icon>mdi-email-outline</v-icon>
                            </v-badge>
                            <v-chip
                              small
                              v-if="item.ltt_used"
                              color="warning"
                              title="Ltt Used"
                              >USED</v-chip
                            >
                          </div>
                        </td>
                        <td>{{ item.url }}</td>
                        <td>{{ item.phrase }}</td>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on">{{ item.cost_task }}</span>
                            </template>
                            <span>Price</span>
                          </v-tooltip>
                        </td>
                        <td>{{ item.status }}{{ item.status_additional }}</td>
                        <td>{{ item.add_datetime }}</td>
                        <td>{{ item.time_in_work_stage }}</td>
                        <td>
                          <v-chip
                            label
                            small
                            :color="
                              responsibilities_colors[item.responsible.role].bg
                            "
                            :style="
                              'color: ' +
                              responsibilities_colors[item.responsible.role]
                                .color
                            "
                          >
                            {{ item.responsible.role }}
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn fixed dark fab bottom right color="pink" @click="openDialog">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title> Upload domains to {{ projectName }} </v-card-title>
        <v-card-text>
          <v-autocomplete
            solo
            label="Anchor Plan Task"
            v-model="form.anchor_plan_task"
            :items="anchor_plan_tasks"
            required
          ></v-autocomplete>
          <v-textarea
            v-model="form.domains"
            label="Domains"
            solo
            no-resize
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Close </v-btn>
          <v-btn
            color="primary"
            text
            @click="upload()"
            :disabled="dialog_loading"
            :loading="dialog_loading"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_apt" persistent scrollable max-width="1000px">
      <v-card>
        <v-card-title> Anchor Plan </v-card-title>
        <v-card-text>
          <v-data-table :headers="apt_headers" :items="apt_items">
            <template v-slot:item.donor="{ item }">
              <a :href="'/gpm/view-entity/' + item.entity_id" target="_blank">{{
                item.donor
              }}</a>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog_apt = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  RESPONSIBILITIES_COLORS,
  RESPONSIBILITIES_ITEMS,
} from "@/utils/selectItems";
import axios from "axios";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";

const dateFormat = require("dateformat");
const date = new Date();

export default {
  name: "Gpm3rdBlog",
  components: { SmartDateFilter },
  data() {
    return {
      dialog_apt: false,
      apt_headers: [
        { text: "Url", value: "url" },
        { text: "Anchor", value: "anchor" },
        { text: "Donor", value: "donor" },
      ],
      apt_items: [],
      date_modal: false,
      search: "",
      modal: {
        shown: false,
      },
      filter: {
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        projects: [],
        statuses: [],
        niche: undefined,
        performer: undefined,
      },
      dialog: false,
      dialog_loading: false,
      loading: false,
      form: {
        domains: "",
        anchor_plan_task: null,
      },
      anchor_plan_tasks: [],
      items_breadcrumbs: [
        {
          text: "Gpm",
          disabled: true,
        },
        {
          text: "3rd Blog",
          disabled: true,
        },
      ],
      headers: [
        // {text: '', align: 'center', value: 'login'},
        // {text: 'Project', align: 'start', value: 'project'},
        { text: "Domain", value: "domain" },
        { text: "Url", value: "url" },
        { text: "Phrase", value: "phrase" },
        // {text: 'CostBase', value: 'cost_base', align: 'center'},
        { text: "Status", value: "status", align: "center" },
        { text: "CreatedAt", value: "add_datetime", align: "center" },
        { text: "StageTime", value: "time_in_work_stage", align: "center" },
        { text: "Responsible", value: "responsible", align: "center" },
        // {text: 'Messages', value: 'messages', align: 'center'},
      ],
      items: [],
      items_filtered: [],
      cat_projects: [],
      cat_projects_data: {},
      responsibilities: RESPONSIBILITIES_ITEMS,
      responsibilities_colors: RESPONSIBILITIES_COLORS,
      performers: [],
      niches: [],
    };
  },
  computed: {
    projectName() {
      return this.$store.state.project.domain;
    },
    idProject() {
      return this.$store.state.project.active_project;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    statuses() {
      let self = this;

      if (self.items.length === 0) {
        return [];
      }

      let statuses = [];
      let used_statuses = [];

      self.cat_projects.forEach((v) => {
        self.items[v].forEach((i) => {
          if (used_statuses.indexOf(i.status) === -1) {
            used_statuses.push(i.status);
            statuses.push({
              text: i.status,
              value: i.status,
            });
          }
        });
      });

      return {
        statuses: statuses,
        used: used_statuses,
      };
    },
  },
  methods: {
    exportPostedLinks() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          location.href =
            `${self.$store.state.server_url}/export/gpm/posted-links/0?hash=` +
            response.data.hash;
        }
      });
    },
    viewAnchorPlan(aptID) {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan`;

      let config = {
        params: {
          anchorPlanTaskID: aptID,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.apt_items = r.data.items;
          self.dialog_apt = true;
        })
        .finally(() => (self.loading = false));
    },
    updateFilter() {
      let self = this;

      if (self.items.length === 0) {
        return [];
      }

      let result = {};

      let selected_statuses = self.filter.statuses;

      self.cat_projects.forEach((v) => {
        result[v] = self.items[v].filter((i) => {
          let status_filter = true;
          let status_responsible = true;
          let status_critical_estimate = true;

          if (selected_statuses.length > 0) {
            status_filter = selected_statuses.indexOf(i.status) !== -1;
          }

          if (self.filter.responsible) {
            if (self.filter.responsible === "ME")
              status_responsible = i.responsible.status;
            else if (self.filter.responsible === "ALL")
              status_responsible = true;
            else
              status_responsible =
                self.filter.responsible === i.responsible.role;
          }

          if (self.filter.critical_estimate) {
            status_critical_estimate = i.diff_in_hour >= i.critical_estimate;
          }

          return (
            status_filter && status_responsible && status_critical_estimate
          );
        });
      });

      self.items_filtered = result;
    },
    customSort(items) {
      return items.sort((a, b) => {
        let status_priority_a,
          status_priority_b,
          min_on_stage_a,
          min_on_stage_b;

        status_priority_a = parseInt(a.status_priority);
        status_priority_b = parseInt(b.status_priority);

        min_on_stage_a = parseInt(a.minutes_in_work_stage);
        min_on_stage_b = parseInt(b.minutes_in_work_stage);

        if (status_priority_a > status_priority_b) return -1;
        if (status_priority_a < status_priority_b) return 1;

        if (min_on_stage_a > min_on_stage_b) return -1;
        if (min_on_stage_a < min_on_stage_b) return 1;

        return 0;
      });
    },
    openDialog() {
      let self = this;

      self.dialog = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan-tasks`;

      let config = {
        params: {
          projectID: self.idProject,
          type: 2,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.anchor_plan_tasks = r.data.items;
      });
    },
    upload() {
      let self = this;

      self.dialog_loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/upload-domains`;

      let config = {
        params: {
          typeID: 2,
          source: "3rd",
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let post_data = {
        domains: self.form.domains
          .split("\n")
          .map((v) => v.trim())
          .filter((v) => v.length > 0),
        anchorPlanTaskID: self.form.anchor_plan_task,
      };

      axios.post(url, post_data, config).finally(() => {
        self.dialog_loading = false;
        self.dialog = false;
        self.fetchData();
      });
    },
    openUrl(id) {
      this.$router.push({ name: "gpm.view-entity", params: { id: id } });
    },
    saveUserSettings() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/user/save-settings`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let post_data = {
        type: "gpm",
        data: {
          dashboard: {
            filter: self.filter,
          },
        },
      };

      axios
        .post(url, post_data, config)
        .then(() => {
          self.fetchData();
        })
        .finally(() => (self.loading = false));
    },
    fetchUserSettings() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/user/get-settings`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        if (r.data.gpm !== undefined && r.data.gpm.dashboard !== undefined) {
          self.filter = r.data.gpm.dashboard.filter;
        }
        self.fetchData();
      });
    },
    fetchData() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-dashboard-data`;

      let config = {
        params: {
          typeID: 2,
          filter: self.filter,
          filter_header: self.$route.query,
          dashboardType: "default",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.cat_projects = r.data.projects;
          self.cat_projects_data = r.data.projects_data;
          self.items = r.data.items;
          self.items_filtered = r.data.items;
          self.stat = r.data.stat;
          // self.filter.statuses = self.statuses.used
          self.filter.statuses = [];
          self.filter.critical_estimate = false;
          self.performers = r.data.performers;
          self.niches = r.data.niches;
          // self.updateFilter()
        })
        .finally(() => (self.loading = false));
    },
  },
  created() {
    this.fetchUserSettings();
  },
};
</script>

<style scoped></style>
