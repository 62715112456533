<template>
  <div class="my-group">
    <v-chip
      v-for="{ value: itemValue, text, id } in values"
      :key="id"
      label
      :outlined="itemValue !== activeValue"
      :color="itemValue === activeValue ? 'primary' : ''"
      @click="handleUpdateSectionData(itemValue)"
    >
      {{ text }}
    </v-chip>
  </div>
</template>

<script>
import eventPipe from "@/events/eventPipe";

export default {
  props: {
    sectionName: {
      type: String,
      default: "",
    },
    defaultPrefix: String,
  },
  data() {
    return {
      activeValue: this.defaultPrefix || "ah_",
      values: [
        {
          id: 0,
          value: "ah_",
          text: "ah",
        },
        {
          id: 1,
          value: "sr_",
          text: "sr",
        },
      ],
    };
  },
  methods: {
    handleUpdateSectionData(newValue) {
      this.activeValue = newValue;

      eventPipe.$emit("update-section-data", {
        name: this.sectionName,
        prefix: newValue,
      });
    },
  },
};
</script>
