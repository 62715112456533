<template>
  <v-card class="tribe-section" flat outlined rounded="lg">
    <v-card-title class="pa-3 text-normal d-flex align-center">
      <div class="d-flex align-center" style="gap: 0.5rem">
        <b style="font-size: 1rem">
          <v-icon left small color="primary">mdi-text-box-search</v-icon>
          {{ section.name }}
        </b>
        <v-progress-circular
          v-if="computedSectionsWithPending?.length"
          indeterminate
          size="12"
          width="1"
        />
        <div class="d-flex" style="gap: 0.25rem">
          <v-tooltip v-if="computedSectionsWithoutData?.length" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" small color="warning"> mdi-alert </v-icon>
            </template>
            Has section(s) without data. (
            {{ computedSectionsWithoutData?.length }} )
          </v-tooltip>
          <v-tooltip v-if="computedSectionsWithError?.length" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" small color="error"> mdi-alert-box </v-icon>
            </template>
            Has section(s) with error. (
            {{ computedSectionsWithError?.length }} )
          </v-tooltip>
        </div>
      </div>
      <v-spacer />
      <v-chip
        label
        color="transparent"
        class="text-body-2 tribe-section__expand-button"
        v-if="section?._reactive?.expanded !== undefined"
        @click="section._reactive.expanded = !section._reactive.expanded"
      >
        {{ section?._reactive?.expanded ? "Hide" : "Expand" }}
        <v-icon small right>
          {{ section?._reactive?.expanded ? "mdi-minus" : "mdi-plus" }}
        </v-icon>
      </v-chip>
    </v-card-title>
    <div v-show="section?._reactive?.expanded">
      <v-divider />
      <v-card-text
        class="pa-3 d-flex flex-column"
        style="background: rgba(0, 0, 0, 0.05); gap: 0.5rem"
        ref="cardText"
      >
        <template v-for="(item, idx) in section.items.sort(prioritySort)">
          <v-alert
            v-if="(item?._status === 'success') === isNoData(item?._content)"
            :key="`section-item-${idx}`"
            flat
            text
            color="warning"
            class="pa-3 ma-0"
          >
            <div class="warning--text text-body-2">
              No data for
              <u>{{ item?._info?.sectionKey }}</u> section
            </div>
          </v-alert>
          <v-card
            v-else-if="item && item._status === 'success'"
            :key="`section-item-${idx}`"
            flat
            outlined
          >
            <v-card-title class="text-body-2">
              <b>{{ item._content?.title }}</b>
            </v-card-title>
            <v-divider />
            <template v-if="item._content?.progress">
              <v-card-text class="pa-0">
                <dashboard-section-progress-bars
                  :data="item._content.progress"
                />
              </v-card-text>
              <v-divider />
            </template>
            <v-card-text class="pa-0">
              <template v-if="item._content?.type === types.CONVERSION">
                <conversion-dynamic-table :data="item._content?.data" />
              </template>
              <template v-else-if="item._content?.type === types.POSITIONS">
                <positions-table :data="item._content?.data" />
              </template>
              <template v-else-if="item._content?.type === types.LINKS">
                <links-by-project-table :data="item._content?.data" />
              </template>
              <template v-else-if="item._content?.type === types.CONTENT">
                <content-table :data="item._content?.data" />
              </template>
              <template v-else-if="item._content?.type === types.NEED_TOP">
                <need-top-table :data="item._content?.data" />
              </template>
              <template v-else-if="item._content?.type === types.PAGES_INFO">
                <pages-info-table :data="item._content?.data" />
              </template>
              <template v-else>
                <div class="pa-3">
                  Unsupported section type <u>{{ item._content?.type }}</u>
                </div>
              </template>
            </v-card-text>
          </v-card>
          <v-card
            v-if="item && item._status === 'pending'"
            :key="`section-item-${idx}`"
            flat
            outlined
            class="pa-3"
          >
            <div class="skeleton" style="width: 100%; height: 75px" />
            <div class="mt-1" style="opacity: 0.5">
              {{ item?._info?.sectionKey }}
            </div>
          </v-card>
          <v-alert
            v-if="item && item._status === 'error'"
            :key="`section-item-${idx}`"
            flat
            text
            color="error"
            class="pa-3 ma-0"
          >
            <div class="error--text text-body-2">
              Error occurred while loading
              <u>{{ item?._info?.sectionKey }}</u> data
            </div>
          </v-alert>
        </template>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import ConversionDynamicTable from "./ConversionDynamicTable.vue";
import PositionsTable from "./PositionsTable.vue";
import LinksByProjectTable from "./LinksByProjectTable.vue";
import { Tribe4DxApiService } from "../service/Tribe4DxApiService";
import LazyDomElementRenderingMixin from "../../../mixins/LazyDomElementRenderingMixin";
import ContentTable from "./ContentTable.vue";
import DashboardSectionProgressBars from "./DashboardSectionProgressBars.vue";
import NeedTopTable from "./NeedTopTable.vue";
import PagesInfoTable from "./PagesInfoTable.vue";

export default {
  components: {
    PagesInfoTable,
    NeedTopTable,
    DashboardSectionProgressBars,
    ContentTable,
    LinksByProjectTable,
    PositionsTable,
    ConversionDynamicTable,
  },

  mixins: [LazyDomElementRenderingMixin],

  props: ["section"],

  data: () => ({
    types: Tribe4DxApiService().TRIBE_TYPES,
  }),

  computed: {
    computedSectionsWithoutData() {
      return this.section.items.filter(
        (item) => item?._status === "success" && this.isNoData(item?._content)
      );
    },
    computedSectionsWithError() {
      return this.section.items.filter((item) => item?._status === "error");
    },
    computedSectionsWithPending() {
      return this.section.items.filter((item) => item._status === "pending");
    },
  },

  methods: {
    prioritySort(aData, bData) {
      const a = aData._content.priority || 0;
      const b = bData._content.priority || 0;

      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    isNoData(data) {
      let error = 0;

      if (data === undefined || data === null) error++;

      if (data && typeof data === "object" && !Object.keys(data)?.length)
        error++;

      return error > 0;
    },
  },

  mounted() {
    this.initializeLazyRender([this.$refs.cardText]);
  },
};
</script>

<style lang="scss">
.tribe-dashboard {
  .tribe-section {
    .table-with-dividers {
      tbody {
        tr.divider {
          background-color: rgba(155, 155, 155, 0.15);
          font-weight: 600;
          &:hover {
            background-color: rgba(155, 155, 155, 0.3) !important;
          }
        }
        tr.divider-border {
          td {
            border-bottom: 2px solid rgba(155, 155, 155, 0.5) !important;
          }
        }
      }
    }

    &__expand-button {
      opacity: 0;
    }

    &:hover {
      .tribe-section__expand-button {
        opacity: 1;
      }
    }
  }
}
</style>
