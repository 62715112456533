<template>
  <v-tooltip
    bottom
    content-class="my-shadow--2 pa-0"
    transition="slide-y-transition"
  >
    <template #activator="{ on, attrs }">
      <div
        v-on="on"
        v-bind="attrs"
        :class="{
          'stc-cell': true,
          flat: flat,
        }"
      >
        <span v-if="!hideAbb" class="stc-label"> {{ label.abb }} </span>
        <span class="stc-value" :style="{ opacity: getValueOpacity }">
          {{ value }}
        </span>
      </div>
    </template>
    <div>
      <v-card class="styled-card--light pa-2">
        <div>
          <small>
            <b>
              {{ label.full }}
            </b>
          </small>
        </div>
        <template v-if="value === '?'">
          <div class="error--text">The data has not yet been processed</div>
        </template>
        <template v-else-if="!$slots.foundMessage">
          <div>{{ foundMessage.replaceAll("$v", value) }}</div>
        </template>
        <template v-else>
          <slot name="foundMessage" />
        </template>
      </v-card>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    hideAbb: Boolean,
    flat: Boolean,
    foundMessage: {
      type: String,
      default: "Found $v",
    },
  },
  computed: {
    getValueOpacity() {
      if (this.value === "?") return "0.5";
      return "1";
    },
  },
};
</script>

<style lang="scss">
.stc-cell {
  min-width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid rgba(155, 155, 155, 0.15);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  &.flat {
    border: none;
  }
  & .stc-label {
    position: absolute;
    right: 2px;
    top: 1px;
    font-size: 10px;
    line-height: 100%;
  }
  & .stc-value {
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    margin-right: 2px;
  }
  &:hover {
    background-color: rgba(155, 155, 155, 0.15);
  }
}
</style>
