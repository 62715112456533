<template>
  <v-container style="padding-bottom: 200px">
    <div style="max-width: 885px; margin: auto">
      <v-row>
        <v-col cols="12">
          <h2>Donors Finder</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-breadcrumbs class="pa-0" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row dense class="mt-4">
        <v-col>
          <v-row dense>
            <v-col cols="12" class="d-flex justify-space-between">
              <h3># Source Data</h3>
              <v-spacer />
              <span>
                <a
                  href="https://docs.google.com/document/d/14rInWCIAgsxHqYKfpFNUdT5adfnMz1rJVE9-mL-97XI/edit#heading=h.zdvd3taszeh4"
                  target="_blank"
                  class="reset-link-styles"
                >
                  <v-chip @click="() => {}" outlined
                    >Documentation
                    <v-icon small class="ml-2">mdi-open-in-new</v-icon></v-chip
                  >
                </a>
              </span>
            </v-col>
            <v-col cols="12">
              <h4>Domains</h4>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="domains"
                hide-details
                clearable
                outlined
                placeholder="Enter domains"
                label="Domains"
                @change="handleChangeDomains"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="urls"
                hide-details
                clearable
                outlined
                placeholder="Enter Urls"
                label="Urls"
                @change="handleChangeUrls"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <h4>LTT Links by Project</h4>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.ltt.task"
                outlined
                item-text="name"
                item-value="id"
                :items="_$getAllowedProjects"
                dense
                hide-details
                clearable
                label="Project"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.ltt.type"
                outlined
                dense
                :items="filtersData.link_types"
                hide-details
                clearable
                multiple
                label="Link Type"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="filters.ltt.min_ah_traffic"
                type="number"
                outlined
                clearable
                dense
                hide-details
                label="AH Traffic min"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-menu content-class="remove-dialog-shadow pa-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.ltt.link_date"
                    label="Link Date"
                    readonly
                    outlined
                    hide-details
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <div class="shadow-e1-bordered">
                  <v-date-picker v-model="filters.ltt.link_date" scrollable>
                  </v-date-picker>
                </div>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <h4>Spiderlink Donor Domains</h4>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.sl.task"
                outlined
                :items="filtersData.sl_tasks"
                dense
                deletable-chips
                small-chips
                multiple
                hide-details
                clearable
                label="Project"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.sl.type"
                outlined
                dense
                :items="filtersData.link_types"
                hide-details
                clearable
                multiple
                label="Link Type"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="filters.sl.min_ah_traffic"
                type="number"
                outlined
                clearable
                dense
                hide-details
                label="AH Traffic min"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-menu width="290px" content-class="remove-dialog-shadow pa-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.sl.first_seen"
                    label="First seen"
                    readonly
                    outlined
                    hide-details
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <div class="shadow-e1-bordered">
                  <v-date-picker v-model="filters.sl.first_seen" scrollable>
                  </v-date-picker>
                </div>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <h4>LTT Domains by Type</h4>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.ltt_domains.source"
                outlined
                :items="[
                  { text: 'LTT Pro (our sites)', value: 'our' },
                  { text: 'LTT All', value: 'company' },
                ]"
                dense
                hide-details
                clearable
                label="Source"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.ltt_domains.type"
                outlined
                dense
                :items="filtersData.link_types"
                hide-details
                clearable
                multiple
                label="Link Type"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="filters.ltt_domains.min_ah_traffic"
                type="number"
                outlined
                clearable
                dense
                hide-details
                label="AH Traffic min"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-menu content-class="remove-dialog-shadow pa-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.ltt_domains.link_date"
                    label="Link Date"
                    readonly
                    outlined
                    hide-details
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <div class="shadow-e1-bordered">
                  <v-date-picker
                    v-model="filters.ltt_domains.link_date"
                    scrollable
                  >
                  </v-date-picker>
                </div>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <h3># Export Setting</h3>
            </v-col>
            <v-col cols="12">
              <h4>Settings</h4>
            </v-col>
            <v-col cols="12" class="d-flex align-start">
              <v-text-field
                v-model="filters.our_site"
                outlined
                dense
                clearable
                hide-details="auto"
                multiple
                label="Our Site"
                hint="format: domain.com"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-text-field>
              <v-icon color="error" small title="required" class="ml-2"
                >mdi-asterisk</v-icon
              >
            </v-col>
            <v-col cols="12" class="d-flex align-start">
              <v-autocomplete
                v-model="filters.link_type"
                outlined
                clearable
                :items="filtersData.link_types"
                dense
                hide-details="auto"
                label="Required Link Type"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-autocomplete>
              <v-icon color="error" small title="required" class="ml-2"
                >mdi-asterisk</v-icon
              >
            </v-col>
            <v-col cols="12" class="d-flex align-start">
              <v-autocomplete
                v-model="filters.niche"
                outlined
                :items="filtersData.niches"
                dense
                clearable
                hide-details="auto"
                label="Niche"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-autocomplete>
              <v-icon color="error" small title="required" class="ml-2"
                >mdi-asterisk</v-icon
              >
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.lang"
                outlined
                dense
                clearable
                disabled
                :items="filtersData.languages"
                hide-details="auto"
                label="Lang"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="d-flex align-start">
              <v-autocomplete
                v-model="filters.ah_country"
                outlined
                :items="filtersData.ah_countries"
                dense
                clearable
                hide-details="auto"
                label="AH Traffic Country"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-autocomplete>

              <v-icon color="error" small title="required" class="ml-2"
                >mdi-asterisk</v-icon
              >
            </v-col>
            <v-col cols="12" class="d-flex align-start">
              <v-autocomplete
                v-model="filters.sw_country"
                outlined
                clearable
                dense
                :items="filtersData.sw_countries"
                hide-details="auto"
                label="SW Pro Country"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-autocomplete>

              <v-icon color="error" small title="required" class="ml-2"
                >mdi-asterisk</v-icon
              >
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <h4>Spiderlink Data</h4>
            </v-col>
            <v-col cols="12" class="d-flex align-start">
              <v-autocomplete
                v-model="filters.sl_link_data.task"
                outlined
                dense
                hide-details="auto"
                clearable
                :items="filtersData.sl_tasks"
                multiple
                label="Project"
                :rules="[(v) => !!v || 'Item is required']"
              ></v-autocomplete>

              <v-icon color="error" small title="required" class="ml-2"
                >mdi-asterisk</v-icon
              >
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="domainsStopList"
                outlined
                clearable
                hide-details
                label="Domains Stop List"
                @change="handleChangeDomainsStopList"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <h4>Position Analyzer Data</h4>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.vision_data.na"
                outlined
                clearable
                dense
                :items="filtersData.na_projects"
                hide-details
                multiple
                label="By Niche Analyzer"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.vision_data.project"
                outlined
                :items="filtersData.projects"
                dense
                clearable
                hide-details
                multiple
                label="By Projects"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <h4>Export</h4>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="number"
                v-model="filters.export.min_ah_traffic"
                outlined
                clearable
                dense
                hide-details
                multiple
                label="Min AH Traffic"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="filters.components"
                multiple
                :items="componentItems"
                label="Components"
                outlined
                hide-details
                dense
                placeholder="Select"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense class="mt-12">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                @click="createTask"
                color="success"
                elevation="0"
                :loading="loading"
                :disabled="validate"
                rounded
              >
                Create Task +
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { GP_DONORS_FINDER_TABLE_HEADERS } from "@/utils/defaultData";
import Project from "../../../mixins/Project";

export default {
  mixins: [Project],
  data: () => ({
    filters: {
      sl: {
        task: [],
        type: [],
        min_ah_traffic: 1000,
        first_seen: null,
      },
      export: {
        min_ah_traffic: 1000,
      },
      ltt: {
        task: null,
        type: [],
        min_ah_traffic: 1000,
        link_date: null,
      },
      ltt_domains: {
        source: null,
        type: [],
        min_ah_traffic: 1000,
        link_date: null,
      },
      hand: "",
      urls: "",
      link_type: null,
      ah_traffic_min: 1000,
      our_site: "",
      niche: 2,
      lang: "en",
      project: null,
      ah_country: null,
      sw_country: null,
      components: [],
      sl_link_data: {
        task: [],
        domains: [],
      },
      vision_data: {
        na: [],
        project: [],
      },
    },
    domains: "",
    urls: "",
    domainsStopList: "",
    loading: false,
    loadings: {
      filters: false,
    },
    dateModel: false,
    headers: GP_DONORS_FINDER_TABLE_HEADERS,
    filtersData: {
      sl_tasks: [],
      projects: [],
      na_projects: [],
      sw_countries: [],
      ah_countries: [],
      link_types: [],
      niches: [],
      languages: [],
    },
    componentItems: [
      {
        text: "Disavow",
        value: "disavow",
      },
      {
        text: "GP Serp Ranking",
        value: "gp_serp_ranking",
      },
    ],
    breadcrumbs: [
      {
        text: "Donors Finder",
        disabled: false,
        to: "/donors-finder",
        exact: true,
      },
      {
        text: "Create Task",
        disabled: true,
      },
    ],
  }),
  created() {
    this.fetchFilters();
  },
  computed: {
    validate() {
      let errors = 0;

      const isAtLeastOne =
        [
          this.domains,
          this.filters.ltt.task,
          this.filters.sl.task.length,
          this.filters.sl.first_seen,
        ].filter((v) => !!v).length > 0;

      if (!isAtLeastOne) errors++;

      console.log(isAtLeastOne, [
        this.domains,
        this.filters.ltt.task,
        this.filters.sl.task.length,
        this.filters.sl.first_seen,
      ]);

      if (!this.filters.our_site) errors++;
      if (!this.filters.link_type) errors++;
      if (!this.filters.niche) errors++;
      if (!this.filters.ah_country) errors++;
      if (!this.filters.sw_country) errors++;
      if (!this.filters.sl_link_data.task.length) errors++;

      return errors > 0;
    },
  },
  methods: {
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = [
        "sl_tasks",
        "projects",
        "na_projects",
        "sw_countries",
        "ah_countries",
        "link_types",
        "niches",
        "languages",
      ];
      const payload = {
        type: "/gp-finder",
        take: filters,
        filter: {
          projectID: this.$store.state.project.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    handleChangeDomainsStopList() {
      let domains =
        this.domainsStopList !== undefined
          ? this.domainsStopList
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      this.domainsStopList = domains
        .map((v) => this.domainFormatter(v))
        .join("\n");

      this.filters.sl_link_data.domains = domains.map((v) =>
        this.domainFormatter(v)
      );
    },
    handleChangeDomains() {
      let domains =
        this.domains !== undefined
          ? this.domains
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      this.domains = domains.map((v) => this.domainFormatter(v)).join("\n");

      this.filters.hand = domains.map((v) => this.domainFormatter(v));
    },
    handleChangeUrls() {
      let urls =
        this.urls !== undefined
          ? this.urls
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      this.urls = urls.join("\n");

      this.filters.urls = urls;
    },
    domainFormatter(v) {
      let domain;

      try {
        domain = new URL(v).hostname;
      } catch (e) {
        domain = new URL("http://" + v).hostname;
      }

      return domain.replace("www.", "");
    },
    async createTask() {
      try {
        this.loading = true;
        const resp = await this.$store.dispatch(
          "donors-finder/fetchData",
          this.filters
        );
        this.loading = false;
        if (resp && resp.success) {
          this.$message.notification({
            title: "Success",
            text: "The task was successfully added",
            duration: 6000,
            type: "success",
          });
          this.$router.push({ name: "donors-finder" });
        }
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },
  },
};
</script>
