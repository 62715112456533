<template>
  <div ref="parent">
    <v-data-table
      :search="searchQuery"
      :headers="computedHeaders"
      :items="componentData()?.items"
      dense
      class="dashboard-dev__presets-table"
      :items-per-page="25"
      :footer-props="{ itemsPerPageOptions: [25, 50, 150, 300, -1] }"
    >
      <template #[`header.chart`]>
        {{ getCurrentData }}
      </template>
      <template #[`item.value`]="{ value }">
        {{ value | localeNumber }}
      </template>
      <template #[`item.chart`]="{ value }">
        <dev-detailer-sparkline :data="value?.series || []" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { deepClone } from "../../../utils/functions";
import DevDetailerSparkline from "../../DashboardDev/components/DevDetailerSparkline.vue";
import LazyDomElementRenderingMixin from "../../../mixins/LazyDomElementRenderingMixin";

export default {
  components: { DevDetailerSparkline },

  inject: ["componentData"],

  mixins: [LazyDomElementRenderingMixin],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    searchQuery: null,
  }),

  mounted() {
    this.initializeLazyRender([this.$refs.parent]);
  },

  computed: {
    computedHeaders() {
      const tmpr = deepClone(this.componentData()?.headers) || [];

      tmpr.forEach((header) => {
        if (header.value === "chart") {
          header.width = "140px";
        }
      });

      return tmpr;
    },
    getCurrentData() {
      return this.$moment(new Date().getTime()).format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>
