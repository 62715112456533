<template>
  <a :href="value" target="_blank">
    {{ getUrl }}
  </a>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    getUrl() {
      const output = this.value
        .replaceAll("https://", "")
        .replaceAll("http://")
        .split("/");
      output.shift();
      return "/" + output.join("/");
    },
  },
};
</script>
