<template>
  <span v-if="text" :style="getStyles">
    {{ value.value }}
    <slot />
  </span>
  <v-chip v-else :color="getPositionColor(value)">
    {{ value.value }}
    <slot />
  </v-chip>
</template>

<script>
export default {
  props: {
    text: {
      type: Boolean,
      default: false,
    },
    value: {},
    opacity: {
      type: Number,
      default: 0.75,
    },
  },
  computed: {
    getStyles() {
      return {
        color: this.getPositionColor(this.value),
        fontWeight: 600,
        fontSize: "16px",
      };
    },
  },
  methods: {
    getPositionColor(data) {
      let value = data.value.valueOf();

      if (data.another) return "rgba(139,185,255,0.53)";

      if (value === null || value === "") return "";

      if (value === "-") {
        // DARKGREY
        return this.rgba(170, 170, 170);
      }
      if (value === 101) {
        // LIGHTGREY
        return this.rgba(200, 200, 200);
      }

      if (isNaN(value)) return "";

      value = parseInt(value);

      if (between(value, 1, 10)) {
        // GREEN
        const r = Math.round(value * 6.3);
        const g = Math.round(130 + value * 12.5);
        const b = Math.round(value * 6.3);
        return this.rgba(r, g, b, 0.5);
      }
      if (between(value, 11, 30)) {
        // YELLOW
        const r = 255;
        const g = Math.round(250 - value * 1.5);
        const b = Math.round(255 - value * 15);
        return this.rgba(r, g, b);
      }
      if (between(value, 31, 50)) {
        // ORANGE
        const r = 255;
        const g = Math.round(250 - value * 3.4);
        const b = Math.round(140 - value * 2.3);
        return this.rgba(r, g, b);
      }
      if (between(value, 51, 100)) {
        // RED
        const r = 255;
        const g = Math.round(120 - value * 1.2);
        const b = Math.round(120 - value * 1.2);
        return this.rgba(r, g, b);
      }

      return this.rgba(152, 152, 152); // if nothing else

      function between(value, min, max) {
        const x = value;
        return (x - min) * (x - max) <= 0;
      }
    },
    rgba(r, g, b, a = this.opacity) {
      return `rgba(${r}, ${g}, ${b}, ${a}`;
    },
    get_color(val1, val2) {
      const percent = val1 / val2;
      return Math.round(200 * percent);
    },
  },
};
</script>
