export default [
    [
        'localeNumber',
        function (value) {
            try {
                return Number(value).toLocaleString('en-US', {maximumFractionDigits: 2})
            } catch (e) {
                console.error('filters.js / parseInt')
            }
        }
    ],
]