<template>
  <div :class="`${data.page_type !== '' ? 'page-td' : ''}`">
    {{ data.page_type }}
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>
