<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    multiple
    placeholder="Type to search"
    dense
    hide-details
    chips
    small-chips
    deletable-chips
    clearable
    :menu-props="menuProps"
    v-bind="$attrs"
  >
    <template #prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="selected.length > 0 ? 'primary' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Select All </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-if="limitSlot" #selection="{ item, index }">
      <span v-if="index === 0">{{ item[$attrs["item-text"]] || item }}</span>
      <span v-if="index === 1" class="grey--text text-caption">
        (+{{ selected.length - 1 }} more)
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import { DEFAULT_MENU_PROPS } from "../../utils/defaultData";

export default {
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    limitSlot: Boolean,
    items: Array,
  },
  data: () => ({
    menuProps: DEFAULT_MENU_PROPS,
  }),
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAll) return (this.selected = []);

        if (this.$attrs.returnObject) return (this.selected = this.items);

        this.selected = this.items.map((v) => {
          if (typeof v === "object" && v !== null)
            return v[this.$attrs["item-value"]];
          return v;
        });
      });
    },
  },
  computed: {
    selectedAll() {
      return this.selected.length === this.items?.length;
    },
    selectedSomething() {
      return this.selected.length > 0 && !this.selectedAll;
    },
    icon() {
      if (this.selectedAll) return "mdi-close-box";
      if (this.selectedSomething) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selected: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
