export const PAGE_CONFIG = {
  title: "Roles management",
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "Roles",
      disabled: true,
    },
  ],
};

export const ROLES_DATA_TABLE_HEADERS = [
  {
    text: "Priority",
    value: "priority",
    width: "80px",
  },
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Actions",
    value: "_actions",
    width: "80px",
  },
];
