import service from "../../../plugins/service";

export const DevDashboardApiService = () => {
  const ENDPOINTS = {
    GET_FILTERS: "/worksection/get/daily/filter",
    GET_ACTIONS: "/dashboards/dev/monitor/actions",
    CREATE_FAST_TASK: (id) => `/dashboards/dev/monitor/action/${id}/create`,
    GET_ACTION_DETAILS: (id) => `/dashboards/dev/monitor/action/${id}`,
  };

  const getFilters = async () => {
    try {
      const { data } = await service.post(ENDPOINTS.GET_FILTERS);

      return data;
    } catch (error) {
      console.error("Error while fetching filters", error);
    }
  };

  const createFastTask = async (id) => {
    try {
      const { data } = await service.get(ENDPOINTS.CREATE_FAST_TASK(id));

      return data;
    } catch (error) {
      console.error("Error while fetching actions", error);
      return [];
    }
  };

  const getActions = async (params) => {
    try {
      const { data } = await service.get(ENDPOINTS.GET_ACTIONS, true, {
        params,
      });

      return data;
    } catch (error) {
      console.error("Error while fetching actions", error);
      return [];
    }
  };

  const getActionDetailsById = async (id, params) => {
    try {
      const { data } = await service.get(
        ENDPOINTS.GET_ACTION_DETAILS(id),
        true,
        { params }
      );

      return data;
    } catch (error) {
      console.error("Error while fetching actions", error);
      return { items: [], status: "error" };
    }
  };

  return { getFilters, getActions, getActionDetailsById, createFastTask };
};
