<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-row align="center">
        <v-col class="grow"> Semantic </v-col>
        <v-col class="shrink">
          <v-select v-model="report_type" :items="report_types" solo></v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        solo
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1 v-data-table-small"
      :sort-by="['cl', 'imp']"
      :sort-desc="[true, true]"
      :items-per-page="15"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.phrase">
            <td>{{ item.phrase }}</td>
            <td class="text-center">{{ item.volume }}</td>
            <td class="text-center">{{ item.cl }}</td>
            <td class="text-center">{{ item.imp }}</td>
            <td class="text-center">{{ item.title }}</td>
            <td class="text-center">{{ item.description }}</td>
            <td class="text-center">{{ item.h1 }}</td>
            <td class="text-center">{{ item.popularity }}</td>
            <td class="text-center" :style="getChildStyle(item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }"
                  ><span v-on="on">{{ item.our_num }}</span
                  ><br
                /></template>
                <span> Text Length: {{ childData.our.length }} </span>
              </v-tooltip>
            </td>
            <td class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }"
                  ><span v-on="on">{{ item.med_num }}</span
                  ><br
                /></template>
                <span> Text Length: {{ medianLength }} </span>
              </v-tooltip>
            </td>
            <td class="text-center" :style="getChildPercentStyle(item)">
              {{ item.our_p }}
            </td>
            <td class="text-center">{{ item.med_p }}</td>
            <td
              class="text-center"
              :style="
                item.our_num < item.rwc[0] || item.our_num > item.rwc[1]
                  ? 'background-color: #ffeb3b47'
                  : ''
              "
            >
              {{ item.rwc[0] }} - {{ item.rwc[1] }}
            </td>
            <td v-for="(v, k) in item.child" v-bind:key="k" class="text-center">
              <div v-if="report_type !== 'text'">
                {{ item.t[k][report_type] }}<br />
                {{ item.t[k][`${report_type}_percent`] }}
              </div>
              <div v-else-if="v[0] > 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }"
                    ><span v-on="on">{{ v[0] }}</span
                    ><br
                  /></template>
                  <span>
                    Url: {{ childData[k].url }}<br />
                    Text Length: {{ childData[k].length }}<br />
                    Parsed At: {{ childData[k].parsed_at }}
                  </span>
                </v-tooltip>
                {{ v[1] }}%
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import events from "../../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../../mixins/Common";

export default {
  name: "Semantic",
  data() {
    return {
      loading: false,
      search: "",
      headers: [],
      items: [],
      childData: [],
      medianLength: 0,
      report_type: "text",
      report_types: ["text", "title", "description", "h1"],
    };
  },
  mixins: [OnePageAnalyzerIndex, CommonIndex],
  methods: {
    changeReportType() {},
    getChildStyle(item) {
      let med_color =
        item.our_num === 0 && item.med_num > 0
          ? "background-color: #ffeb3b47"
          : false;

      if (med_color !== false) {
        return med_color;
      }

      let delta = Math.abs(item.our_num - item.med_num);

      if (delta > 10) {
        return "background-color: #ff0000";
      } else if (delta > 7) {
        return "background-color: #ff0000cf";
      } else if (delta > 5) {
        return "background-color: #ff0000a3";
      } else if (delta > 3) {
        return "background-color: #ff00006b";
      } else if (delta > 0) {
        return "background-color: #ff000042";
      }

      return "";
    },
    getChildPercentStyle(item) {
      let delta = Math.round(Math.abs(item.our_p - item.med_p) * 10) / 10;

      if (delta > 0.8) {
        return "background-color: #ff0000";
      } else if (delta > 0.5) {
        return "background-color: #ff0000cf";
      } else if (delta > 0.3) {
        return "background-color: #ff0000a3";
      } else if (delta > 0.1) {
        return "background-color: #ff00006b";
      } else if (delta > 0) {
        return "background-color: #ff000042";
      }

      return "";
    },
    resetData() {
      this.items = [];
      this.childData = [];
      this.medianLength = 0;
      this.headers = [];

      this.$store.state.OnePageAnalyzer.vision_median = {};
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/text`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedUrl,
          type: "semantic",
          filter: self.filterData,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let post_data = {
        vision: self.vision,
        sum_visibility: self.sumVisibility,
        projectID: self.idProject,
        url: self.selectedUrl,
      };

      axios
        .post(url, post_data, config)
        .then((r) => {
          self.items = r.data.items;
          self.childData = r.data.childData;
          self.medianLength = r.data.medianLength;
          self.headers = r.data.headers.map((v) => {
            if (v.type !== undefined) {
              if (v.type === "float") {
                v.sort = function (a, b) {
                  a = parseFloat(a);
                  b = parseFloat(b);

                  if (a === b) {
                    return 0;
                  }

                  return a > b ? 1 : -1;
                };
              }
            }

            return v;
          });

          let median = {};

          self.items.forEach(function (item) {
            median[item.phrase] = item.med_full;
          });

          self.$store.state.OnePageAnalyzer.vision_median = median;
        })
        .finally(() => (self.loading = false));
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
    });

    events.$on("vision-fetched", function () {
      self.fetchData();
    });
  },
};
</script>

<style scoped></style>
