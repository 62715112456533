export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "input"],
  watch: {
    dialog(isOpen) {
      if (!isOpen) return this.$emit("dialogClosed");
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value || this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("input", value);
      },
    },
  },
};
