import service from "../../../plugins/service";

export const UserManageApiService = () => {
  const ENDPOINTS = {
    GET_USERS: "/access/users?c=21",
    GET_USER: (id) => `/access/users/${id}/edit`,
    UPDATE_USER: (id) => `/access/users/${id}`,
    CREATE_USER: "/access/users",
  };

  const getUsers = async () => {
    return await service.get(ENDPOINTS.GET_USERS);
  };

  const getUser = async (id) => {
    return await service.get(ENDPOINTS.GET_USER(id));
  };

  const createUser = async (payload) => {
    return await service.post(ENDPOINTS.CREATE_USER, payload);
  };

  const updateUser = async (payload) => {
    return await service.put(ENDPOINTS.UPDATE_USER(payload.id), payload);
  };

  return {
    getUsers,
    getUser,
    updateUser,
    createUser,
  };
};
