<template>
  <v-dialog :retain-focus="false" v-model="inputVal" max-width="600">
    <v-card class="elevation-16 mx-auto" width="600">
      <v-card-title class="headline"> Chat </v-card-title>
      <v-card-text>
        <div class="white">
          <v-list id="chat-list" style="max-height: 270px; overflow: auto">
            <v-list-item v-for="message in chat.messages" :key="message.id">
              <v-list-item-content>
                <div v-if="message.user === userId" class="text-right">
                  <div
                    class="d-inline-block grey lighten-3 rounded-xl pa-2"
                    style="max-width: 500px; min-width: 500px"
                  >
                    <div>
                      <v-avatar size="30">
                        <v-img
                          :src="message.photo"
                          :title="message.name"
                        ></v-img>
                      </v-avatar>
                      <b :style="is_dark() ? 'color: red' : ''"
                        >{{ message.name }}
                      </b>
                    </div>
                    <span :style="is_dark() ? 'color: red' : ''" v-nl2br>{{
                      message.message
                    }}</span>
                    <v-icon
                      :style="is_dark() ? 'color: red' : ''"
                      v-if="message.read === 1"
                      >mdi-check-all</v-icon
                    >
                    <v-icon :style="is_dark() ? 'color: red' : ''" v-else
                      >mdi-check</v-icon
                    >
                  </div>

                  <br />
                  <small>{{ message.date }}</small>
                </div>
                <div v-else class="text-left">
                  <div
                    class="d-inline-block grey lighten-3 rounded-xl pa-2"
                    style="max-width: 500px; min-width: 500px"
                  >
                    <div>
                      <v-avatar size="30">
                        <v-img
                          :src="message.photo"
                          :title="message.name"
                        ></v-img>
                      </v-avatar>
                      <b :style="is_dark() ? 'color: red' : ''"
                        >{{ message.name }}
                      </b>
                    </div>
                    <span :style="is_dark() ? 'color: red' : ''" v-nl2br>{{
                      message.message
                    }}</span>
                    <v-icon
                      :style="is_dark() ? 'color: red' : ''"
                      v-if="message.read === 1"
                      >mdi-check-all</v-icon
                    >
                    <v-icon :style="is_dark() ? 'color: red' : ''" v-else
                      >mdi-check</v-icon
                    >
                  </div>

                  <br />
                  <small>{{ message.date }}</small>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div
            class="px-4 pt-2"
            :style="is_dark() ? 'background-color: #1e1e1e' : ''"
          >
            <v-textarea
              v-model="chat.message"
              outlined
              rows="1"
              autofocus
              dense
              auto-grow
              append-icon="mdi-send"
              @click:append="sendMessage"
              @keydown.enter="sendMessageEnter"
            ></v-textarea>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "Chat",
  props: ["value"],
  data() {
    return {
      chat: {
        show: false,
        loading: false,
        message: "",
        messages: [],
      },
    };
  },
  created() {
    this.getMessages();
  },
  directives: {
    nl2br: {
      inserted(el) {
        // simplified example,
        // works only for nodes without any child elements
        el.innerHTML = el.textContent.replace(/\n/g, "<br />");
      },
    },
  },
  watch: {
    inputVal: {
      handler() {
        if (this.chat.messages.filter((v) => v.read === 0).length > 0) {
          this.readMessage();
        }
      },
      deep: true,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    userId() {
      return this.$store.state.auth.user.id;
    },
  },
  methods: {
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    getMessages() {
      let self = this;

      let url = `${self.$store.state.server_url}/anchor-plan/chat/get-messages`;

      let config = {
        params: {
          taskID: this.$route.params.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.chat.messages = r.data.items;
        self.getCountUnreadMessage();
        self.scrollBottom("#chat-list");
      });
    },
    getCountUnreadMessage() {
      this.$emit("getCountUnreadMessage", {
        count: this.chat.messages.filter(
          (v) => v.read === 0 && this.$store.state.auth.user.id !== v.user
        ).length,
      });
    },
    sendMessageEnter(event) {
      if (event.ctrlKey) {
        this.chat.message += "\n";
      } else {
        this.sendMessage();
      }
    },
    scrollBottom(selector) {
      setTimeout(function () {
        let container = document.querySelector(selector);
        if (container != null) container.scrollTop = container.scrollHeight;
      }, 100);
    },
    sendMessage() {
      let self = this;

      let url = `${self.$store.state.server_url}/anchor-plan/chat/send-message`;

      self.chat.loading = true;

      let config = {
        params: {
          taskID: this.$route.params.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            message: self.chat.message,
          },
          config
        )
        .then(() => {
          self.getMessages();
          self.chat.message = "";
          // self.fetchFields()
          // self.fetchButtons()
          // self.fetchHeader()
        })
        .finally(() => (self.chat.loading = true));
    },
    readMessage() {
      let self = this;

      let url = `${self.$store.state.server_url}/anchor-plan/chat/read-message`;

      self.chat.loading = true;

      let config = {
        params: {
          taskID: this.$route.params.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            message: self.chat.message,
          },
          config
        )
        .then(() => {
          self.getMessages();
        })
        .finally(() => (self.chat.loading = true));
    },
  },
};
</script>

<style scoped></style>
