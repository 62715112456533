export const BriefLowImpressionsPagesAnalyzeBreadcrumbs = [
    {
        text: "Home",
        to: "/",
    },
    {
        text: "Dashboards",
        disabled: true,
    },
    {
        text: "Brief Low Impressions Pages Analyze",
        disabled: true,
    },
];

export const Endpoints = {
    Datatable: "/dashboards/summary/brief-low-impressions-pages-analyze",
};
