import service from "@/plugins/service";

export default {
  data: () => ({
    STATUSES: {
      NONE: "NONE",
      LIKED: "LIKED",
      DISLIKED: "DISLIKED",
    },
  }),
  methods: {
    async setReactionToComment(status, commentData) {
      try {
        const configPath = this.config?.actions?.reaction;
        const url = configPath || "/view-info-by-site/set-reaction";

        const payload = {
          id: commentData.id,
          status,
        };

        await service.post(url, payload);
      } catch (e) {
        console.error("Error while setting reaction to comment.", e);
      }
    },
    async removeComment(commentData) {
      try {
        console.log(this.config);
        const configPath =
          this.config && this.config.actions && this.config.actions.remove;
        const url = configPath
          ? configPath(commentData.id)
          : `/view-info-by-site/remove-comment/${commentData.id}`;

        this.loadings.removing = true;

        await service.delete(url);
      } catch (e) {
        console.error("Error while removing comment.", e);
      } finally {
        this.loadings.removing = false;
      }
    },
    handleToggleDislike(commentData) {
      if (commentData.is_liked) {
        commentData.likes--;
        commentData.is_liked = false;
      }

      if (commentData.is_disliked) {
        commentData.dislikes--;
        this.setReactionToComment(this.STATUSES.NONE, commentData);
        return (commentData.is_disliked = false);
      }

      commentData.dislikes++;
      this.setReactionToComment(this.STATUSES.DISLIKED, commentData);
      return (commentData.is_disliked = true);
    },
    handleToggleLike(commentData) {
      if (commentData.is_disliked) {
        commentData.dislikes--;
        commentData.is_disliked = false;
      }

      if (commentData.is_liked) {
        commentData.likes--;
        this.setReactionToComment(this.STATUSES.NONE, commentData);
        return (commentData.is_liked = false);
      }

      commentData.likes++;
      this.setReactionToComment(this.STATUSES.LIKED, commentData);
      return (commentData.is_liked = true);
    },
  },
};
