export const DEFAULT_FONTS = [
  {
    name: "Roboto",
    url: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
  },
  {
    name: "Lato",
    url: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet',
  },
  {
    name: "Montserrat",
    url: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet',
  },
  {
    name: "Inter",
    url: "https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap",
  },
  {
    name: "Poppins",
    url: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
  },
  {
    name: "Fraunces",
    url: 'https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap" rel="stylesheet',
  },
  {
    name: "Playfair Display",
    url: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap" rel="stylesheet',
    inscription: "serif",
  },
  {
    name: "Anton",
    url: "https://fonts.googleapis.com/css2?family=Anton&display=swap",
  },
  {
    name: "Outfit",
    url: "https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap",
  },
  {
    name: "Open Sans",
    url: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet',
  },
  {
    name: "Radley",
    url: "https://fonts.googleapis.com/css2?family=Radley:ital@0;1&display=swap",
  },
  {
    name: "Arimo",
    url: "https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap",
  },
  {
    name: "Josefin Sans",
    url: "https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap",
  },
  {
    name: "Merriweather",
    url: "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap",
    inscription: "serif",
  },
  {
    name: "Oxygen",
    url: "https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap",
  },
];
