<template>
  <td
    style="white-space: nowrap"
    :class="{
      'text-center': 1,
      bold: 1,
      'td-red': date.plan_status === -1,
      'td-orange': date.plan_status === 0,
      'td-blue': date.plan_status === 2,
      'edge-cell': date.edge,
    }"
  >
    <template v-if="emptyData"> </template>
    <template v-else>
      <span v-html="getOnPage"></span> / {{ getPlan }}
    </template>
  </td>
</template>

<script>
export default {
  props: ["date"],
  computed: {
    getOnPage() {
      if (this.date.on_page === 0 && this.date.noindex === 0) return "-";

      const onPage = this.date.on_page === 0 ? "-" : this.date.on_page;
      const noIndex = this.date.noindex === 0 ? "" : this.date.noindex;

      return `${onPage}<sup>${noIndex}</sup>`;
    },
    getPlan() {
      if (this.date.plan === 0) return " - ";

      return this.date.plan;
    },
    emptyData() {
      const d = this.date;
      return d.on_page === 0 && d.noindex === 0 && d.plan === 0;
    },
  },
};
</script>
