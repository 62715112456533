<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e1" width="660">
    <v-card class="styled-card--default">
      <v-card-title>
        Url history
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <template v-if="loading">
          <v-skeleton-loader type="table" />
        </template>
        <template v-else>
          <v-data-table
            :items="data.items"
            :headers="data.headers"
            :sort-by="['date']"
            :sort-desc="[true]"
          >
            <template #[`item.date`]="{ value }">
              <div v-if="value">
                <v-chip class="mr-1">
                  {{ value.split(" ")[0] }}
                </v-chip>
                {{ value.split(" ")[1] }}
              </div>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";

export default {
  props: {
    data: {},
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [Dialog],
};
</script>
