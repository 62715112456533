<template>
  <v-dialog v-model="dialog" max-width="1200">
    <v-card class="mx-auto ldd__notes" elevation="0" height="700">
      <div class="notes__action">
        <div class="notes__tooltip">
          <v-icon small color="rgba(0,0,0,0.3)">mdi-information-outline</v-icon>
          Notes are automatically saved every 15 seconds or on a textfield blur
        </div>
        <div v-if="loadings.save" class="notes__saving-loader">
          <v-btn x-small :loading="true" text></v-btn>
          saving...
        </div>
        <div class="notes__actions">
          <v-menu>
            <template #activator="{ on }">
              <v-btn small text v-on="on"> Other notes </v-btn>
            </template>
            <v-list nav dense>
              <v-list-item
                v-if="otherNotes.length === 0"
                dense
                class="d-flex align-center justify-center"
              >
                Nothing...
              </v-list-item>
              <v-subheader>
                Users <small class="ml-2">( name )</small>
              </v-subheader>
              <v-list-item
                v-for="note in otherNotes"
                dense
                :key="note.id"
                @click="watchPreview(note)"
              >
                {{ note.user_name }}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="notes__editor">
        <tiptap-vuetify
          v-model="content"
          :extensions="extensions"
          @blur="save"
        />
      </div>
    </v-card>
    <v-dialog v-model="previewDialog">
      <v-card style="position: relative">
        <v-card-title class="d-flex justify-space-between">
          <div>
            Last update:
            <small>
              {{
                $moment(previewContent.updated_at).format("DD/MM/YYYY HH:mm:ss")
              }}
            </small>
          </div>
          <v-btn icon @click="previewDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text v-html="previewContent.notes"></v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from "tiptap-vuetify";
import Project from "@/mixins/Project";
import eventPipe from "@/events/eventPipe";

export default {
  components: { TiptapVuetify },
  mixins: [Project],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    acceptor: {
      type: Number,
      default: undefined,
    },
    url: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    timeout: null,
    content: "",
    otherNotes: [],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
    previewContent: "",
    previewDialog: false,
    loadings: {
      content: false,
      save: false,
    },
  }),
  mounted() {
    this.fetchContent();
    eventPipe.$on("update-active-project", () => {
      this.fetchContent();
    });
    setInterval(() => {
      if (!this.dialog) {
        return;
      }
      this.save(true);
    }, 15000);
  },
  watch: {
    url() {
      this.fetchContent();
    },
    dialog() {
      this.fetchContent();
    },
    acceptor() {
      this.fetchContent();
    },
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    watchPreview(data) {
      this.previewContent = data;
      this.previewDialog = true;
    },
    async fetchContent() {
      if (!this.url) {
        return;
      }
      const payload = {
        params: {
          url: this.url,
          project: parseInt(this.$store.state.project.active_project),
          acceptor: this.acceptor,
        },
      };
      const resp = await this.$store.dispatch(
        "links-dynamic-dashboard/getNotes",
        payload
      );
      if (resp) {
        this.content = resp.my === null ? "" : resp.my.notes;
        this.otherNotes = resp.guys === null ? [] : resp.guys;
      }
    },
    async save() {
      try {
        this.loadings.save = true;
        const c = this.content.trim();
        if (c === "" || c === "<p></p>" || c === "<p> </p>") {
          return;
        }
        if (!this.url) {
          return;
        }
        const payload = {
          url: this.url,
          project: parseInt(this.$store.state.project.active_project),
          acceptor: this.acceptor,
          notes: this.content,
        };
        const resp = await this.$store.dispatch(
          "links-dynamic-dashboard/saveNotes",
          payload
        );
        if (resp) {
          console.log(resp.status ? "Notes: Saved" : "Notes: Error");
          console.log("Saved: ", this.content);
          return;
        }
        console.log("Save error");
      } finally {
        setTimeout(() => (this.loadings.save = false), 500);
      }
    },
  },
};
</script>

<style scoped></style>
