var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',[(!_vm.hideAvatar)?_c('v-list-item-icon',{staticClass:"mr-2"},[(_vm.comment.user && _vm.comment.user.avatar)?_c('v-avatar',{attrs:{"size":"32"}},[_c('img',{attrs:{"src":_vm.comment.user.avatar,"alt":"User avatar"}})]):_c('v-avatar',{staticClass:"white--text",attrs:{"size":"32","color":"primary"}},[_vm._v(" "+_vm._s(_vm.getInitials(_vm.comment.user.name))+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('div',{staticClass:"d-flex align-end"},[_c('b',[_vm._v(" "+_vm._s(_vm.comment.user.name || _vm.comment.user.email)+" ")]),_c('v-chip',{staticClass:"ml-2 px-2",attrs:{"x-small":"","color":_vm.getRatingColor(_vm.comment.rating, _vm.monochromeRating),"title":"Feedback rating"}},[_vm._v(" "+_vm._s(_vm.comment.rating)+" ")]),(_vm.comment.created_at)?_c('small',{staticClass:"ml-2",staticStyle:{"opacity":"0.5"},attrs:{"title":_vm.comment.created_at}},[_vm._v(" "+_vm._s(_vm.$moment(_vm.comment.created_at).calendar())+" ")]):_vm._e(),(_vm.canDelete && _vm.isUserHasAccessToDeleteComment)?_c('div',[(!_vm.loadings.removing)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"error","small":""},on:{"click":_vm.handleRemove}},[_vm._v(" mdi-trash-can ")]):_c('v-progress-circular',{attrs:{"indeterminate":"","size":"18","width":"2"}})],1):_vm._e(),_c('v-spacer'),(_vm.voteTop)?_c('div',{staticClass:"mt-2 d-flex"},[_c('div',{class:{
            'primary--text': _vm.comment.is_liked,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleToggleLike(_vm.comment)}}},[_c('v-icon',{attrs:{"color":`${_vm.comment.is_liked ? 'primary' : ''}`,"small":"","title":"Like this feedback"}},[_vm._v("mdi-thumb-up")]),_c('small',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.comment.likes))])],1),_c('v-divider',{staticClass:"mx-1",attrs:{"vertical":""}}),_c('div',{class:{
            'primary--text': _vm.comment.is_disliked,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleToggleDislike(_vm.comment)}}},[_c('v-icon',{attrs:{"color":`${_vm.comment.is_disliked ? 'primary' : ''}`,"small":"","title":"Dislike this feedback"}},[_vm._v("mdi-thumb-down")]),_c('small',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.comment.dislikes))])],1)],1):_vm._e(),(false)?_c('div',[_c('v-chip',{attrs:{"small":"","color":_vm.getRatingColor(_vm.comment.rating, _vm.monochromeRating),"title":"Feedback rating"}},[_vm._v(" "+_vm._s(_vm.comment.rating)+" ")])],1):_vm._e()],1),_c('div',{staticClass:"mt-1",staticStyle:{"line-height":"145%"}},[_c('div',{staticClass:"feedback__body",domProps:{"innerHTML":_vm._s(_vm.comment.text)}})]),(!_vm.voteTop)?_c('div',{staticClass:"mt-2 d-flex"},[_c('div',{class:{
          'primary--text': _vm.comment.is_liked,
        },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleToggleLike(_vm.comment)}}},[_c('v-icon',{attrs:{"color":`${_vm.comment.is_liked ? 'primary' : ''}`,"small":"","title":"Like this feedback"}},[_vm._v("mdi-thumb-up")]),_c('small',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.comment.likes))])],1),_c('v-divider',{staticClass:"mx-1",attrs:{"vertical":""}}),_c('div',{class:{
          'primary--text': _vm.comment.is_disliked,
        },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleToggleDislike(_vm.comment)}}},[_c('v-icon',{attrs:{"color":`${_vm.comment.is_disliked ? 'primary' : ''}`,"small":"","title":"Dislike this feedback"}},[_vm._v("mdi-thumb-down")]),_c('small',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.comment.dislikes))])],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }