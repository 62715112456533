<template>
  <span>
    <span
      v-if="value > 0"
      class="success--text diff__item"
    >
      <span style="margin-right: -3px;">{{ String(value).replaceAll("-", "") }}</span>
      <v-icon style="margin-top: -2px;" small color="success"> mdi-menu-up </v-icon>
    </span>
    <span
      v-else-if="value < 0"
      class="error--text diff__item"
    >
      <span style="margin-right: -3px;">{{ String(value).replaceAll("-", "") }}</span>
      <v-icon style="margin-top: -2px;" small color="error"> mdi-menu-down </v-icon>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: {}
  }
};
</script>