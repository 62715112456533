export const pageConfig = {
  breadcrumbs: [
    {
      text: "Home",
      to: "/",
    },
    {
      text: "4DX",
      disabled: true,
    },
    {
      text: "Tribe dashboard",
      disabled: true,
    },
  ],
};
