<template>
  <v-card>
    <apexcharts
      type="bar"
      height="300"
      :options="options"
      :series="series"
    ></apexcharts>
  </v-card>
</template>

<script>
// import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { OnePageAnalyzerIndex } from "../../../../mixins/components/OnePageAnalyzer/Index";
// import events from "../../../events/components/OnePageAnalyzer/events";

export default {
  name: "AnchorTypes",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {};
  },
  computed: {
    series() {
      let type_brand = [];
      let type_commercial = [];
      let type_domain_url = [];
      let type_generic = [];
      let type_other = [];
      let type_undefined = [];

      let self = this;

      this.$store.state.OnePageAnalyzer.common_table_items.forEach(function (
        item
      ) {
        type_brand.push(self.getInt(item.anchor_type_brand));
        type_commercial.push(self.getInt(item.anchor_type_commercial));
        type_domain_url.push(self.getInt(item["anchor_type_domain / url"]));
        type_generic.push(self.getInt(item["anchor_type_generic anchor"]));
        type_other.push(self.getInt(item.anchor_type_other));
        type_undefined.push(self.getInt(item.anchor_type_undefined));
      });

      return [
        {
          name: "Brand",
          data: type_brand,
        },
        {
          name: "Commercial",
          data: type_commercial,
        },
        {
          name: "Domain / Url",
          data: type_domain_url,
        },
        {
          name: "Generic Anchor",
          data: type_generic,
        },
        {
          name: "Other",
          data: type_other,
        },
        {
          name: "Undefined",
          data: type_undefined,
        },
      ];
    },
    options() {
      let categories = [];

      this.$store.state.OnePageAnalyzer.common_table_items.forEach(function (
        item
      ) {
        let url = new URL(item.default_url);
        categories.push(url.host);
      });

      return {
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Anchor Types",
        },
        xaxis: {
          categories: categories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>

<style scoped></style>
