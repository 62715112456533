<template>
  <div>
    <v-card
        class="mx-auto"
        :loading="loading"
    >
      <v-card-text class="headline font-weight-bold">
        <v-row
            align="center"
            justify="center"
        >
          <v-col class="text-center">
            <v-data-table
                :headers="headers"
                :items="items"
                :sort-by="['priority']"
                :sort-desc="[true]"
                multi-sort
                class="elevation-1"
            >
              <template v-slot:item.status="{ item }">
                <v-chip small :color="item.status === 'texts' ? 'success' : 'secondary' ">{{ item.status }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                    color="primary"
                    depressed
                    icon
                    link
                    :to="{ name: 'essay.hub.show', params: { id: item.id }}"
                >
                  <v-icon>
                    mdi-login-variant
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <CreateTask/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import CreateTask from "@/components/EssayHub/Dialogs/CreateTask";

export default {
  name: "EssayHubIndex",
  components: {CreateTask},
  data() {
    return {
      loading: false,
      headers: [
        {text: 'Name', value: 'name', align: 'left', sortable: false},
        {text: 'Status', value: 'status', align: 'center', width: 100, sortable: false},
        {text: 'Created At', value: 'created_at', align: 'center', width: 200, sortable: false},
        {text: '', value: 'actions', align: 'center', width: 50, sortable: false},
      ],
      items: [],
    }
  },
  created() {
    let self = this;

    self.load_data()
  },
  methods: {
    load_data() {
      let self = this;

      self.loading = true

      let url = `${self.$store.state.server_url}/essay-hub`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.get(url, config)
          .then(function (response) {
            self.items = response.data
            self.loading = false
          }).catch(() => self.sheet = true);
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>