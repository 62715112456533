<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex align-end">
        <h2>Acceptor Domains</h2>
        <v-spacer />
        <v-col class="d-flex justify-end">
          <v-btn
            @click="uploadDialogModel = !uploadDialogModel"
            color="primary"
            outlined
          >
            <v-icon class="mr-2">mdi-tray-arrow-up</v-icon>Upload
          </v-btn>
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12" class="mt-6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
          dense
          style="max-width: 300px"
        ></v-text-field>
        <v-data-table
          :search="search"
          :loading="loadings.table"
          :headers="tableData.headers"
          :items="tableData.items"
          multi-sort
          item-key="id"
          :items-per-page="50"
          :footer-props="{ itemsPerPageOptions: [50, 100, 200, 500 - 1] }"
          :custom-sort="_$smartCustomTableSort"
        >
          <template #[`item.hide`]="{ item }">
            <v-btn icon @click="handleHideDomain(item)">
              <v-icon>
                {{ item.hide ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="uploadDialogModel"
      content-class="remove-dialog-shadow"
      transition="slide-x-transition"
      max-width="1250px"
    >
      <div style="overflow: hidden">
        <v-row>
          <v-col cols="7">
            <v-card rounded="lg">
              <v-card-title> Import from text </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    1. Put the columns to import in the order in which you make
                    the data</v-col
                  >
                  <v-col cols="12">
                    <v-text-field
                      v-model="upload.headers"
                      label="Headers"
                      hide-details
                      outlined
                      dense
                      :placeholder="'url;link_type;month;amount'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">2. Insert data (without headers)</v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="upload.rows"
                      dense
                      ref="uploadInput"
                      hide-details
                      outlined
                      label="Rows"
                      @keydown.tab.prevent="tabber($event)"
                      :placeholder="'domain <tab> country <tab> country_lang <tab> niche'"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn
                  class="text-center align-center justify-center center"
                  color="success"
                  dark
                  large
                  width="140px"
                  :loading="loadings.upload"
                  @click="uploadData"
                  >Upload
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card rounded="lg">
              <v-card-title> Example of allowed columns</v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>domain</td>
                      <td>
                        <b>edubirdie.com</b>
                      </td>
                    </tr>
                    <tr>
                      <td>country</td>
                      <td>
                        <b>US</b>
                      </td>
                    </tr>
                    <tr>
                      <td>country_lang</td>
                      <td>
                        <b>en</b>
                      </td>
                    </tr>
                    <tr>
                      <td>niche</td>
                      <td>
                        <b>essay</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        blacklist <small style="opacity: 0.5">(optional)</small>
                      </td>
                      <td>
                        <b>1</b>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
              <v-card-title> Example of allowed data</v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="countryExample"
                      :items="uploadExampleData.countries"
                      item-value="iso_alpha_2"
                      item-text="name"
                      dense
                      :menu-props="menuProps"
                      label="Countries"
                      outlined
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-center">
                    <div
                      v-if="countryExample"
                      @click="handleCopyText(countryExample)"
                      class="clickable-content clickable-content--expanded"
                    >
                      <span style="font-size: 18px">{{ countryExample }}</span>
                      <v-icon class="ml-2" small>mdi-content-copy</v-icon>
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="countryLangExample"
                      :items="uploadExampleData.countries_lang"
                      item-value="iso"
                      item-text="language"
                      dense
                      :menu-props="menuProps"
                      label="Languages"
                      outlined
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-center">
                    <div
                      v-if="countryLangExample"
                      @click="handleCopyText(countryLangExample)"
                      class="clickable-content clickable-content--expanded"
                    >
                      <span style="font-size: 18px">{{
                        countryLangExample
                      }}</span>
                      <v-icon class="ml-2" small>mdi-content-copy</v-icon>
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="nichesExample"
                      :items="uploadExampleData.niches"
                      item-value="name"
                      item-text="name"
                      dense
                      :menu-props="menuProps"
                      label="Niches"
                      outlined
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-center">
                    <div
                      v-if="nichesExample"
                      @click="handleCopyText(nichesExample)"
                      class="clickable-content clickable-content--expanded"
                    >
                      <span style="font-size: 18px">{{ nichesExample }}</span>
                      <v-icon class="ml-2" small>mdi-content-copy</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import service from "../../../plugins/service";
import {
  ACCEPTOR_DOMAINS_HEADERS,
  DEFAULT_MENU_PROPS,
} from "../../../utils/defaultData";
import copy from "copy-to-clipboard";
import TableSorting from "../../../mixins/TableSorting";

export default {
  mixins: [TableSorting],
  data: () => ({
    menuProps: DEFAULT_MENU_PROPS,
    uploadExampleData: {
      countries: [],
      countries_lang: [],
      niches: [],
    },
    countryLangExample: null,
    uploadDialogModel: false,
    nichesExample: null,
    countryExample: null,
    search: null,
    upload: {
      headers: "domain;country;country_lang;niche",
      rows: "",
    },
    tableData: {
      headers: ACCEPTOR_DOMAINS_HEADERS,
      items: [],
    },
    loadings: {
      table: false,
    },
    breadcrumbs: [
      {
        text: "Flex Donors Finder",
        to: "/flex-donors-finder",
        exact: true,
      },
      {
        text: "Acceptor Domains",
        disabled: true,
      },
    ],
  }),
  created() {
    this.fetchLangExampleData();
    this.fetchData();
  },
  methods: {
    async handleHideDomain(item) {
      const url = "/df/acceptors_country/hide/" + item.id;

      const resp = await service.post(url);

      if (resp) {
        item.hide = !item.hide;
        console.log(resp);
      }
    },
    tabber(event) {
      let text = this.upload.rows,
        originalSelectionStart = event.target.selectionStart,
        textStart = text.slice(0, originalSelectionStart),
        textEnd = text.slice(originalSelectionStart);

      this.upload.rows = `${textStart}\t${textEnd}`;
      event.target.value = this.upload.rows; // required to make the cursor stay in place.
      event.target.selectionEnd = event.target.selectionStart =
        originalSelectionStart + 1;
    },
    async uploadData() {
      try {
        this.loadings.upload = true;
        const url = "/df/upload/projects";

        const payload = {
          ...this.upload,
        };

        const resp = await service.post(url, payload);

        if (resp) {
          console.log(resp);
          this.upload = {
            headers: "domain;country;country_lang;niche",
            rows: "",
          };
        }
      } catch {
        //
      }
      this.loadings.upload = false;
    },
    async fetchLangExampleData() {
      const url = "/df/info";

      const resp = await service.get(url);

      if (resp) this.uploadExampleData = resp.data;
    },
    async handleCopyText(data) {
      copy(data);
      this.$refs.uploadInput.focus();
      this.$message.notification({
        title: "Copy",
        text: `Text <mark>${data}</mark> successfully coppied.`,
        type: "success",
      });
    },
    async fetchData() {
      try {
        this.loadings.table = true;
        const url = "/df/acceptors_country";

        const resp = await service.post(url);

        this.loadings.table = false;

        if (resp && resp.data) {
          this.tableData.items = resp.data.items;
        }
      } catch {
        this.loadings.table = false;
      }
    },
  },
};
</script>
