<template>
  <div>
    <extended-chip outlined label @click="handleClick" :loading="loading">
      {{ data.text }}
    </extended-chip>
    <v-dialog v-model="dialog" content-class="my-shadow--e3">
      <v-card class="styled-card--default">
        <v-card-title>
          Details
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4 px-0">
          <v-data-table
            dense
            :items="tableData.items"
            :headers="tableData.headers"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/plugins/service";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";

export default {
  components: { ExtendedChip },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    dialog: false,
    tableData: {},
  }),
  methods: {
    async handleClick() {
      try {
        this.loading = true;

        const url = this.data.url;

        const resp = await service.get(url);

        this.tableData = resp.data.data;
        this.dialog = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
