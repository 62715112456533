<template>
  <v-card
    :class="`${
      hasProblem ? 'styled-card--error' : 'styled-card--primary-header'
    } `"
  >
    <v-card-title>
      <v-chip
        small
        label
        v-if="card.second_time"
        class="flat-chip flat-chip--error mr-2"
        title="User for the second time at the 'New donor' step"
      >
        Second time
      </v-chip>
      {{ card.name }}
    </v-card-title>
    <v-card-subtitle>
      {{ card.email }} •
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ card.date }}</span>
        </template>
        <span>{{ getFromNowDate(card.date) }}</span>
      </v-tooltip>
    </v-card-subtitle>
    <template
      v-if="card && Array.isArray(card.fields) && card.fields.length > 0"
    >
      <v-divider />
      <v-card-text>
        <div class="d-flex flex-column" style="gap: 16px">
          <div v-for="(field, idx) in card.fields" :key="field.id + '' + idx">
            <div>
              <b class="field__title">{{ field.title }}</b>
            </div>
            <template v-if="Array.isArray(field.value)">
              <div
                v-for="(value, idx) in field.value"
                :key="value.id + '' + idx"
                :class="`field__value ${
                  field.value.length > 1 ? 'list-item' : ''
                }`"
              >
                — {{ value }}
              </div>
            </template>
            <div v-else>{{ field.value }}</div>
          </div>
        </div>
      </v-card-text>
    </template>
    <template v-if="hasProblem">
      <v-divider />
      <v-card-text>
        <div>
          <v-chip label small color="error" class="mr-2">
            <v-icon small left>mdi-alert</v-icon>
            has problem
          </v-chip>
          <b>{{ card.problems.comment }}</b>
        </div>
        <div class="d-flex flex-column mt-2" style="gap: 4px">
          <ol>
            <li
              v-for="(message, idx) in card.problems.items"
              :key="message + '' + idx"
            >
              {{ message }}
            </li>
          </ol>
        </div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasProblem() {
      try {
        return (
          this.card &&
          this.card.problems &&
          Array.isArray(this.card.problems.items) &&
          this.card.problems.items.length > 0
        );
      } catch (e) {
        console.error(
          `Error while detecting fields history card problem ${JSON.stringify(
            this.card,
            null,
            2
          )}.`
        );
        return false;
      }
    },
  },
  methods: {
    getFromNowDate(date) {
      try {
        const fixDate =
          date.split(" ")[0].split("/").reverse().join("-") +
          "-" +
          this.$moment().format("YYYY");
        return this.$moment(fixDate).fromNow();
      } catch (e) {
        console.error("Error while getting from now date.", e);
      }
    },
  },
};
</script>
