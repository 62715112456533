import Service from "@/plugins/service";

export default {
  state: {},
  mutations: {},
  actions: {
    async "top5PageOptimization/fetchTableData"(ctx, payload) {
      const url = "/dashboards/summary/top-5-page-optimization";

      const resp = await Service.post(url, payload, true);
      if (resp && resp.status === 200) {
        return resp.data;
      }
      return false;
    },
  },
  getters: {},
};
