<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mb-12 mt-2 mx-3" raised>
            <v-card-subtitle>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                solo
                single-line
                hide-details
              ></v-text-field>
            </v-card-subtitle>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                item-key="donor_domain_id"
                :items-per-page="50"
                class="elevation-1"
                :sort-by="['ah_traffic']"
                :footer-props="{
                  itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                }"
                :sort-desc="[true]"
                fixed-header
              >
                <template v-slot:item.manual_check_id="{ item }">
                  <v-autocomplete
                    v-model="item.manual_check_id"
                    :items="manual_check_status"
                    item-text="name"
                    item-value="id"
                    hide-details
                    @change="update_error_type(item)"
                    style="min-width: 200px"
                    label="Manual check"
                    solo
                    clearable
                  ></v-autocomplete>
                </template>
              </v-data-table>
              <v-fab-transition>
                <v-btn
                  color="info"
                  small
                  dark
                  absolute
                  top
                  right
                  fab
                  class="mr-15"
                  @click="dialog_add_new = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-fab-transition>
                <v-btn
                  color="info"
                  dark
                  small
                  absolute
                  top
                  right
                  fab
                  @click="dialog = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" scrollable max-width="1000px">
        <v-card>
          <v-card-text class="py-10">
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>Main</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                      v-model="filter.working_donors"
                      inset
                      label="Works"
                    ></v-switch>
                  </v-list-item>
                  <!--  <v-list-item>
                    <v-switch
                        v-model="filter.take_auto_check"
                        inset
                        label="Take auto check"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                        v-model="filter.take_manual_check_good"
                        inset
                        label="Take manual check good"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                        v-model="filter.take_manual_check_bad"
                        inset
                        label="Take manual check bad"
                    ></v-switch>
                  </v-list-item>-->
                  <v-list-item>
                    <v-switch
                      v-model="filter.check_failed"
                      inset
                      label="Check failed"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                      v-model="filter.does_not_work"
                      inset
                      label="Doesn't work"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.manual_check"
                      :items="manual_check_status"
                      item-text="name"
                      item-value="id"
                      chips
                      small-chips
                      label="Manual check"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.hide_subdomains"
                      inset
                      label="Hide subdomains"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.has_ltt_link"
                      inset
                      label="Has LTT link"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.has_alp"
                      inset
                      label="Has ALP"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.show"
                      inset
                      label="Hide used donors"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.can_add_post"
                      inset
                      label="Can add post"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.can_add_page"
                      inset
                      label="Can add page"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.can_edit_theme"
                      inset
                      label="Can edit theme"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.type_cms"
                      :items="type_cms"
                      item-text="name"
                      item-value="id"
                      chips
                      small-chips
                      label="CMS"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.google_bad_domain"
                      inset
                      label="Hide Google SB/Compr"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Domains search</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-textarea
                      v-model="filter.domains_search"
                      solo
                      label="Domains search"
                      hint="Separation - new line"
                      no-resize
                    ></v-textarea>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-switch
                      v-model="filter.blank_manual"
                      inset
                      label="Blank manual status"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.country"
                      :items="countries"
                      item-text="name"
                      item-value="id"
                      chips
                      small-chips
                      label="Country"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.lang"
                      :items="languages"
                      item-text="name"
                      item-value="id"
                      chips
                      small-chips
                      label="Language"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>SW Country %</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.country_percent_operator"
                        :items="operators"
                        item-text="name"
                        item-value="id"
                        label=""
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.country_percent"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>Ahrefs DR</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.ahrefs_dr_min"
                        label="Min"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.ahrefs_dr_max"
                        label="Max"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>Ahrefs Traffic</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.ahrefs_traffic_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.ahrefs_traffic"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>SW Global rank</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.sw_global_rank_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.sw_global_rank"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>SW Country rank</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.sw_country_rank_operator"
                        :items="operators"
                        item-text="name"
                        item-value="id"
                        label=""
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.sw_country_rank"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>GI/AH P,%</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.google_index_ahrefs_pages_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.google_index_ahrefs_pages"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>Spam Score</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.spam_score_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.spam_score"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Google index</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.google_index_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.google_index"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Date Added</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.date_added_operator"
                        :items="operators"
                        item-text="name"
                        item-value="id"
                        label=""
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-menu
                        ref="date_added_menu"
                        v-model="date_added_menu"
                        :close-on-content-click="false"
                        :return-value.sync="filter.date_added"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            v-model="filter.date_added"
                            label="Date added"
                            readonly
                            solo
                            v-bind="attrs"
                            v-on="on"
                          ></v-combobox>
                        </template>
                        <v-date-picker
                          v-model="filter.date_added"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="date_added_menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.date_added_menu.save(filter.date_added)
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn color="primary" @click="fetch_data"> Apply </v-btn>
              <v-btn class="ml-2" @click="export_data" icon>
                <v-icon> mdi-cloud-download-outline </v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <upload-new-donors
        v-if="dialog_add_new === true"
        v-model="dialog_add_new"
      ></upload-new-donors>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import UploadNewDonors from "../Child/UploadNewDonors";

export default {
  components: { UploadNewDonors },
  name: "DonorBaseCleanIndex",
  data() {
    return {
      search: "",
      dialog: false,
      dialog_add_new: false,
      headers: [
        { text: "domain", value: "domain", align: "left", sortable: false },
        { text: "login", value: "login", align: "center", sortable: false },
        {
          text: "password",
          value: "password",
          align: "center",
          sortable: false,
        },
        { text: "address", value: "address", align: "center", sortable: false },
        {
          text: "Manual check",
          value: "manual_check_id",
          align: "center",
          sortable: true,
        },
        {
          text: "Statistics",
          value: "domain_use",
          align: "center",
          sortable: true,
        },
        { text: "GT", value: "google_trust", align: "center", sortable: true },
        { text: "GI", value: "google_index", align: "center", sortable: true },
        {
          text: "AH HP",
          value: "ah_html_pages",
          align: "center",
          sortable: true,
        },
        {
          text: "SW GR",
          value: "global_rank",
          align: "center",
          sortable: true,
        },
        { text: "SW", value: "rank", align: "center", sortable: true },
        { text: "SW", value: "percent", align: "center", sortable: true },
        { text: "AH DR", value: "ah_dr", align: "center", sortable: true },
        {
          text: "AH Rating",
          value: "ah_rating",
          align: "center",
          sortable: true,
        },
        {
          text: "AH RD",
          value: "ah_refdomains",
          align: "center",
          sortable: true,
        },
        {
          text: "AH LRD",
          value: "ah_linked_root_domains",
          align: "center",
          sortable: true,
        },
        {
          text: "AH RP",
          value: "ah_refpages",
          align: "center",
          sortable: true,
        },
        { text: "AH TR", value: "ah_traffic", align: "center", sortable: true },
        {
          text: "GI/AH P,%",
          value: "google_index_ahrefs_pages",
          align: "center",
          sortable: true,
        },
        {
          text: "C OP",
          value: "count_onpage",
          align: "center",
          sortable: true,
        },
        {
          text: "C ERR",
          value: "count_error",
          align: "center",
          sortable: true,
        },
        { text: "DS", value: "ds", align: "center", sortable: true },
        { text: "Lang", value: "lang", align: "center", sortable: true },
        {
          text: "GSB",
          value: "google_safebrowsing",
          align: "center",
          sortable: true,
        },
        {
          text: "GCM",
          value: "google_compromised",
          align: "center",
          sortable: true,
        },
        {
          text: "Post",
          value: "can_add_post",
          align: "center",
          sortable: true,
        },
        { text: "Theme", value: "edit_theme", align: "center", sortable: true },
        { text: "cms", value: "cms_actual", align: "center", sortable: true },
        { text: "id", value: "id", align: "center", sortable: true },
        {
          text: "dAdd",
          value: "dateAddToBase",
          align: "center",
          sortable: true,
        },
        { text: "dCh", value: "checkedDate", align: "center", sortable: true },
        {
          text: "Spam Score",
          value: "spam_score",
          align: "center",
          sortable: true,
        },
      ],
      items: [],
      operators: [
        { id: ">=", name: ">=" },
        { id: "<=", name: "<=" },
      ],
      type_cms: [
        { id: "0", name: "All" },
        { id: "1", name: "WordPress" },
        { id: "2", name: "Other" },
      ],
      date_added_menu: [],
      filter: {
        company_id: 21,
        country_id: 1,
        working_donors: 1,
        check_failed: undefined,
        type_cms: undefined,
        show: undefined,
        blank_manual: undefined,
        idProject: undefined,
        can_add_post: undefined,
        hide_subdomains: undefined,
        manual_check: [7, 15, 18],
        can_add_page: undefined,
        can_edit_theme: undefined,
        does_not_work: undefined,
        lang: undefined,
        has_alp: undefined,
        has_ltt_link: undefined,
        google_bad_domain: undefined,
        date_added_operator: ">=",
        date_added: undefined,
        ahrefs_dr_min: undefined,
        ahrefs_dr_max: undefined,
        ahrefs_traffic: undefined,
        ahrefs_traffic_operator: ">=",
        spam_score: undefined,
        domains_search: undefined,
        google_index_operator: ">=",
        google_index: undefined,
        spam_score_operator: ">=",
        sw_global_rank: undefined,
        sw_global_rank_operator: ">=",
        sw_country_rank: undefined,
        sw_country_rank_operator: ">=",
        country_percent: undefined,
        country_percent_operator: ">=",
        google_index_ahrefs_pages_operator: ">=",
        google_index_ahrefs_pages: undefined,
        // take_auto_check: true,
        // take_manual_check_good: true,
        // take_manual_check_bad: true
      },
      index_items: [
        {
          text: "index",
          value: 0,
        },
        {
          text: "noindex",
          value: 1,
        },
      ],
      manual_check_status: [],
      languages: [],
      countries: [],
    };
  },
  methods: {
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    fetch_data() {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        filter: self.filter,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/${self.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.items = r.data;
        })
        .finally(() => {
          self.dialog = false;
          self.$store.state.loading = false;
        });
    },
    export_data() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          self.dialog_export = false;
          location.href =
            `${self.$store.state.server_url}/export/donor-base/x/index/${self.id_project}?hash=` +
            response.data.hash;
        }
      });
    },
    update_error_type(item) {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let type = self.manual_check_status.find(
        (i) => i.id === item.manual_check_id
      )["type"];
      let data = {
        id: item.id,
        domain_id: item.domain_id,
        errorTypeId: item.manual_check_id,
        type: type,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/update-error-type/${self.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.items = r.data;
          if (type === "domain") {
            self.items
              .filter((i) => i.domain_id === item.domain_id)
              .forEach((v) => {
                v.manual_check_id = item.manual_check_id;
              });
          }
        })
        .finally(() => {
          self.$store.state.loading = false;
        });
    },
    fetch_filter() {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/filter/${self.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.manual_check_status = r.data.manualCheckStatus;
          self.countries = r.data.country;
          self.languages = r.data.languages;
        })
        .finally(() => {
          self.dialog = false;
          self.$store.state.loading = false;
        });
    },
  },
  computed: {
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  mounted() {},
  created() {
    let self = this;
    self.fetch_filter();
    self.fetch_data();
  },
};
</script>

<style></style>
