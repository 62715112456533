var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"top-sw-table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"sort-by":['links'],"sort-desc":[true],"hide-default-header":"","hide-default-footer":"","multi-sort":"","custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return _vm._l((props.headers),function(header){return _c('th',{key:header.value,style:(_vm.getThStyles)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.fixHeader(header.text))}})])})}},{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(row,rowIdx){return _c('tr',{key:rowIdx},_vm._l((Object.keys(row)),function(key,i){return _c('td',{key:key + i,style:(`background-color: ${rowIdx !== 0 ? _vm.getRgb(
            row[key].value,
            _vm.getMax(items, key),
            50,
            233,
            153
          ) : ''}`)},[(i === 0)?[_c('span',{style:(`${row[key].value && row[key].value.length < 30 ? 'white-space:nowrap;' : ''}`)},[_vm._v(" "+_vm._s(row[key].value)+" ")])]:[(row[key] && row[key].value)?_c('div',{staticClass:"colored-td colored-td--clickable",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToSpiderLink(row[key], _vm.activeUrl)}}},[_vm._v(" "+_vm._s(row[key].value)+" ")]):_vm._e()]],2)}),0)}),0)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }