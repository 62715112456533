<template>
  <v-list-item>
    <v-list-item-icon v-if="!hideAvatar" class="mr-2">
      <v-avatar v-if="comment.user && comment.user.avatar" size="32">
        <img :src="comment.user.avatar" alt="User avatar" />
      </v-avatar>
      <v-avatar v-else size="32" color="primary" class="white--text">
        {{ getInitials(comment.user.name) }}
      </v-avatar>
    </v-list-item-icon>
    <v-list-item-content>
      <div class="d-flex align-end">
        <b>
          {{ comment.user.name || comment.user.email }}
        </b>
        <v-chip
          class="ml-2 px-2"
          x-small
          :color="getRatingColor(comment.rating, monochromeRating)"
          title="Feedback rating"
        >
          {{ comment.rating }}
        </v-chip>

        <small
          v-if="comment.created_at"
          class="ml-2"
          style="opacity: 0.5"
          :title="comment.created_at"
        >
          {{ $moment(comment.created_at).calendar() }}
        </small>

        <div v-if="canDelete && isUserHasAccessToDeleteComment">
          <v-icon
            v-if="!loadings.removing"
            @click="handleRemove"
            color="error"
            class="ml-2"
            small
          >
            mdi-trash-can
          </v-icon>
          <v-progress-circular v-else indeterminate size="18" width="2" />
        </div>
        <v-spacer />

        <div v-if="voteTop" class="mt-2 d-flex">
          <div
            @click="handleToggleLike(comment)"
            style="cursor: pointer"
            :class="{
              'primary--text': comment.is_liked,
            }"
          >
            <v-icon
              :color="`${comment.is_liked ? 'primary' : ''}`"
              small
              title="Like this feedback"
              >mdi-thumb-up</v-icon
            >
            <small class="ml-1">{{ comment.likes }}</small>
          </div>
          <v-divider vertical class="mx-1" />
          <div
            @click="handleToggleDislike(comment)"
            style="cursor: pointer"
            :class="{
              'primary--text': comment.is_disliked,
            }"
          >
            <v-icon
              :color="`${comment.is_disliked ? 'primary' : ''}`"
              small
              title="Dislike this feedback"
              >mdi-thumb-down</v-icon
            >
            <small class="ml-1">{{ comment.dislikes }}</small>
          </div>
        </div>
        <div v-if="false">
          <v-chip
            small
            :color="getRatingColor(comment.rating, monochromeRating)"
            title="Feedback rating"
          >
            {{ comment.rating }}
          </v-chip>
        </div>
      </div>
      <div class="mt-1" style="line-height: 145%">
        <div class="feedback__body" v-html="comment.text"></div>
      </div>
      <div v-if="!voteTop" class="mt-2 d-flex">
        <div
          @click="handleToggleLike(comment)"
          style="cursor: pointer"
          :class="{
            'primary--text': comment.is_liked,
          }"
        >
          <v-icon
            :color="`${comment.is_liked ? 'primary' : ''}`"
            small
            title="Like this feedback"
            >mdi-thumb-up</v-icon
          >
          <small class="ml-1">{{ comment.likes }}</small>
        </div>
        <v-divider vertical class="mx-1" />
        <div
          @click="handleToggleDislike(comment)"
          style="cursor: pointer"
          :class="{
            'primary--text': comment.is_disliked,
          }"
        >
          <v-icon
            :color="`${comment.is_disliked ? 'primary' : ''}`"
            small
            title="Dislike this feedback"
            >mdi-thumb-down</v-icon
          >
          <small class="ml-1">{{ comment.dislikes }}</small>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { getInitials } from "@/utils/functions";
import service from "@/plugins/service";
import SiteAnalysisFeedbackMixin from "@/mixins/SiteAnalysisFeedbackMixin";

export default {
  props: {
    comment: {},
    right: Boolean,
    voteTop: Boolean,
    canDelete: Boolean,
    hideAvatar: Boolean,
    monochromeRating: String,
    config: Object,
    afterRemoveCallback: Function,
  },
  mixins: [SiteAnalysisFeedbackMixin],
  data: () => ({
    STATUSES: {
      NONE: "NONE",
      LIKED: "LIKED",
      DISLIKED: "DISLIKED",
    },
    loadings: {
      removing: false,
    },
  }),
  computed: {
    isUserHasAccessToDeleteComment() {
      try {
        const userPermissions = this.$store.getters["base/getUserPermissions"];
        const PERMISSIONS = this.$store.getters["base/PERMISSIONS"];

        const currentPagePermissions = userPermissions?.filter(
          (page) => page.route === this.$route.name
        );
        const flatPermissions = currentPagePermissions.map(
          (item) => item.permission
        );

        const isAdminOnCurrentPage = flatPermissions.includes(
          PERMISSIONS.ADMIN
        );

        return (
          this.comment.user.email === this.$store.getters["auth/user"].email ||
          isAdminOnCurrentPage
        );
      } catch (e) {
        console.error("Error while detecting if user own comment.", e);
        return false;
      }
    },
  },
  methods: {
    async setReactionToComment(status, commentData) {
      try {
        const configPath = this.config?.actions?.reaction;
        const url = configPath || "/view-info-by-site/set-reaction";

        const payload = {
          id: commentData.id,
          status,
        };

        await service.post(url, payload);
      } catch (e) {
        console.error("Error while setting reaction to comment.", e);
      }
    },
    async removeComment(commentData) {
      try {
        console.log(this.config);
        const configPath =
          this.config && this.config.actions && this.config.actions.remove;
        const url = configPath
          ? configPath(commentData.id)
          : `/view-info-by-site/remove-comment/${commentData.id}`;

        this.loadings.removing = true;

        await service.delete(url);
      } catch (e) {
        console.error("Error while removing comment.", e);
      } finally {
        this.loadings.removing = false;
      }
    },
    handleToggleDislike(commentData) {
      if (commentData.is_liked) {
        commentData.likes--;
        commentData.is_liked = false;
      }

      if (commentData.is_disliked) {
        commentData.dislikes--;
        this.setReactionToComment(this.STATUSES.NONE, commentData);
        return (commentData.is_disliked = false);
      }

      commentData.dislikes++;
      this.setReactionToComment(this.STATUSES.DISLIKED, commentData);
      return (commentData.is_disliked = true);
    },
    handleToggleLike(commentData) {
      if (commentData.is_disliked) {
        commentData.dislikes--;
        commentData.is_disliked = false;
      }

      if (commentData.is_liked) {
        commentData.likes--;
        this.setReactionToComment(this.STATUSES.NONE, commentData);
        return (commentData.is_liked = false);
      }

      commentData.likes++;
      this.setReactionToComment(this.STATUSES.LIKED, commentData);
      return (commentData.is_liked = true);
    },
    getInitials(nameString) {
      return getInitials(nameString);
    },
    async handleRemove() {
      await this.removeComment(this.comment);
      if (this.afterRemoveCallback) this.afterRemoveCallback();
    },
  },
};
</script>
