<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="computedPageConfig.title"
          :breadcrumbs="breadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <filters-form
          v-model="filters"
          @getData="initializePerformance"
          :loading="pending.performance"
        />
      </v-col>
      <v-col cols="12">
        <template v-if="pending.performance">
          <v-card class="pa-4 border rounded-lg elevation-0">
            <v-skeleton-loader type="table" />
          </v-card>
        </template>
        <template v-else>
          <v-card class="pa-4 border rounded-lg elevation-0">
            <performance-chart v-if="chart" :data="chart" />
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "../../components/UI/PageHeader.vue";
import { PAGE_CONFIG } from "./defaults";
import { performanceApiServices } from "./services/performanceApiServices";
import FiltersForm from "./components/FiltersForm.vue";
import PerformanceChart from "./components/PerformanceChart.vue";
import moment from "moment/moment";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../utils/defaultData";
import FiltersHandling from "../../mixins/components/FiltersHandling";

export default {
  components: {
    PerformanceChart,
    FiltersForm,
    PageHeader,
  },

  mixins: [FiltersHandling],

  data: () => ({
    breadcrumbs: PAGE_CONFIG.breadcrumbs,
    chart: null,
    pending: {
      performance: false,
    },
    filters: {
      type: "day",
      date: [
        moment().format(DEFAULT_MOMENT_DATE_FORMAT),
        moment().format(DEFAULT_MOMENT_DATE_FORMAT),
      ],
      user_id: [],
    },
  }),

  async mounted() {
    await this.parseQuery("filters");

    if (Object.keys(this.$route.query).length > 0)
      this.initializePerformance(this.filters);
  },

  computed: {
    computedPageConfig: () => PAGE_CONFIG,
  },

  methods: {
    async initializePerformance(payload = {}) {
      try {
        this.pending.performance = true;

        const { data } = await performanceApiServices().getPerformance(payload);

        this.chart = data;
      } catch (e) {
        console.error("Error while initializing performance.", e);
      } finally {
        this.pending.performance = false;
      }
    },
  },
};
</script>
