<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="text-body-2 py-2">
      <v-row dense class="align-center">
        <v-col>
          <b>{{ getSectionName }}</b>
        </v-col>
        <v-col class="d-flex justify-center">
          <v-chip
            v-if="data?.queue"
            class="flat-chip flat-chip--warning"
            title="Queue"
          >
            {{ data?.queue }}
            <v-icon small right>mdi-queue-first-in-last-out</v-icon>
          </v-chip>
          <v-chip
            v-else-if="String(data?.queue) === '0'"
            class="flat-chip flat-chip--success"
          >
            All good <v-icon small right>mdi-check</v-icon>
          </v-chip>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-card-title>
    <v-card-title class="py-0" style="gap: 0.25rem">
      <v-btn class="text-normal" @click="handleCreateTask">
        Create task <v-icon small right>mdi-plus</v-icon>
      </v-btn>
      <v-tooltip open-delay="300" bottom>
        <template #activator="{ on }">
          <v-btn
            v-on="on"
            @click="handleCreateFastTask"
            class="text-normal px-0"
            style="min-width: 36px"
          >
            <v-icon small> mdi-fire </v-icon>
          </v-btn>
        </template>
        <template #default> Create fast task by using template </template>
      </v-tooltip>
      <v-spacer />
      <v-menu
        bottom
        nudge-bottom="42"
        :close-on-content-click="false"
        content-class="my-shadow--e2 border rounded-lg"
      >
        <template #activator="{ on }">
          <v-btn v-on="on" class="text-normal px-0" style="min-width: 36px">
            <v-icon small> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>
        <template #default>
          <v-list dense>
            <v-list-item @click="handleRefresh">
              <v-list-item-icon>
                <v-icon>mdi-refresh</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Refresh data for <u>{{ segment()?.action?.name }}</u>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-card-title>
    <template v-for="(componentData, idx) in data?.items">
      <action-section-component-factory
        v-if="componentData"
        :component="componentData"
        :key="`${idx}-component`"
      />
    </template>
    <template v-if="data?.error">
      <div class="px-4">
        <v-alert type="error" text dense rounded="lg">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <b>{{ getSectionName }}</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>{{ data.error }}</div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-alert>
      </div>
    </template>
  </v-card>
</template>

<script>
import ActionSectionComponentFactory from "./ActionSectionComponentFactory.vue";
import eventPipe from "../../../events/eventPipe";
import { DETAILED_PAGE_EVENTS } from "../defaults";
import { DevDashboardApiService } from "../services/DevDashboardApiService";

export default {
  components: { ActionSectionComponentFactory },

  inject: ["segment"],

  props: {
    /** @type {import('../types.jsdoc.js').Section} */
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    loadings: {
      fastTask: false,
    },
  }),

  computed: {
    getSectionName() {
      return this.data?.section_name || this.data?.sectionName;
    },
  },

  methods: {
    handleRefresh() {
      eventPipe.$emit(
        DETAILED_PAGE_EVENTS.REFRESH_DATA,
        this.segment()?.action
      );
    },
    handleCreateTask() {
      eventPipe.$emit(DETAILED_PAGE_EVENTS.CREATE_TASK, this.data);
    },
    async handleCreateFastTask() {
      try {
        this.loadings.fastTask = true;

        const response = await DevDashboardApiService().createFastTask(
          this.data?.id
        );

        if (response.success) {
          this.$message.notification({
            title: "Success",
            text: `The task was successfully created. <br/> <a href="${response.worksection}" target="_blank">${response.worksection}</a>`,
            type: "success",
            duration: 20000,
          });
        }
      } catch (error) {
        console.error("Error while creating fast task.", error);
      } finally {
        this.loadings.fastTask = false;
      }
    },
  },
};
</script>
