<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfig.pageTitle"
          :breadcrumbs="pageConfig.breadcrumbs"
        ></page-header>
      </v-col>
      <v-col cols="12">
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            hide-details
            dense
            outlined
            placeholder="Type to search"
            append-icon="mdi-magnify"
            style="max-width: 320px"
          />
          <v-spacer />
          <div class="d-flex" style="gap: 0.25rem">
            <v-btn
              color="primary"
              class="text-normal"
              height="40"
              @click="isAddRouteDialogVisible = true"
            >
              Add route <v-icon right small>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <v-data-table
          class="mt-2 border rounded-lg"
          :loading="loadings.dataTable"
          :items="dataTable.items"
          :headers="dataTable.headers"
          :search="searchQuery"
          :items-per-page="25"
          dense
          :footer-props="{ itemsPerPageOptions: [25, 50, 150, 300, -1] }"
        >
          <template
            v-for="key in dataTable.headers.map((v) => v.value)"
            #[`item.${key}`]="{ value, item }"
          >
            <template v-if="key === '_actions'">
              <v-btn
                icon
                @click="handleEdit(item)"
                :loading="loadings.edit === item.id"
                :disabled="!!loadings.edit"
                :key="key"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-route-dialog
      @saved="initializeDataTable"
      v-model="isAddRouteDialogVisible"
    />
    <edit-route-dialog
      v-model="isEditRouteDialogVisible"
      :data="editData"
      @saved="initializeDataTable"
    />
  </v-container>
</template>

<script>
import PageHeader from "../../../components/UI/PageHeader.vue";
import { pageConfig, ROUTES_DATA_TABLE_HEADERS } from "./defaults";
import { routeApiService } from "./services/routeApiService";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import AddRouteDialog from "./components/AddRouteDialog.vue";
import routes from "../../../utils/routes";
import EditRouteDialog from "./components/EditRouteDialog.vue";

export default {
  components: { EditRouteDialog, AddRouteDialog, PageHeader },

  mixins: [UploadFiltersDataMixin],

  data: () => ({
    searchQuery: "",
    isAddRouteDialogVisible: false,
    isEditRouteDialogVisible: false,
    editData: null,
    dataTable: {
      items: [],
      headers: ROUTES_DATA_TABLE_HEADERS,
    },
    loadings: {
      edit: null,
      dataTable: false,
    },
    filtersData: {
      routes: [],
    },
  }),

  provide() {
    return {
      filtersData: () => this.filtersData,
    };
  },

  computed: {
    pageConfig: () => pageConfig,
  },

  mounted() {
    this.initializeDataTable();
    this.initializeFiltersData();
  },

  methods: {
    flatRoutes(routes) {
      let result = [];

      routes.forEach((obj) => {
        if (obj.name) {
          result.push(obj.name);
        }
        if (obj.children && Array.isArray(obj.children)) {
          result = result.concat(this.flatRoutes(obj.children));
        }
      });

      return result;
    },
    initializeFiltersData() {
      this.filtersData.routes = this.flatRoutes(routes);
    },
    async handleEdit(tableData) {
      try {
        this.loadings.edit = tableData.id;

        const { data } = await routeApiService().getRoute(tableData.id);

        this.editData = data;

        this.$nextTick(() => {
          this.editData = null;
          this.isEditRouteDialogVisible = true;
        });
      } catch (e) {
        console.error("Error while loading single route data.", e);
      } finally {
        this.loadings.edit = null;
      }
    },
    async initializeDataTable() {
      try {
        this.loadings.dataTable = true;

        const { data } = await routeApiService().getRoutes();

        this.dataTable.items = data;
      } catch (e) {
        console.error("Error while loading routes data.", e);
      } finally {
        this.loadings.dataTable = false;
      }
    },
  },
};
</script>
