<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mb-12 mt-2 mx-3" raised>
            <v-card-subtitle>
              <h2>X Base</h2>
            </v-card-subtitle>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                item-key="donor_domain_id"
                :items-per-page="50"
                class="elevation-1"
                height="calc(100vh - 300px)"
                fixed-header
              >
                <template v-slot:item.manual_check_id="{ item }">
                  <v-autocomplete
                    v-model="item.manual_check_id"
                    :items="manual_check_status"
                    item-text="name"
                    item-value="id"
                    hide-details
                    style="min-width: 200px"
                    label="Manual check"
                    solo
                    clearable
                  ></v-autocomplete>
                </template>
              </v-data-table>
              <div class="text-right">
                <v-btn color="primary" @click="update_error_type">
                  <v-icon>mdi-content-save</v-icon>
                  Save
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "DonorBaseCleanIndex",
  data() {
    return {
      search: "",
      dialog: false,
      dialog_add_new: false,
      headers: [
        { text: "domain", value: "domain", align: "left", sortable: false },
        { text: "login", value: "login", align: "center", sortable: false },
        {
          text: "password",
          value: "password",
          align: "center",
          sortable: false,
        },
        { text: "address", value: "address", align: "center", sortable: false },
        {
          text: "Manual check",
          value: "manual_check_id",
          align: "center",
          sortable: false,
        },
        {
          text: "LTT Stat",
          value: "count_onpage",
          align: "center",
          sortable: false,
        },
        {
          text: "AH DR",
          value: "domain_rating",
          align: "center",
          sortable: false,
        },
        {
          text: "AH TR",
          value: "ah_traffic",
          align: "center",
          sortable: false,
        },
        { text: "GT", value: "google_trust", align: "center", sortable: false },
        {
          text: "Alexa",
          value: "al_GlobalRank",
          align: "center",
          sortable: false,
        },
        {
          text: "Date",
          value: "dateAddToBase",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      filter: {
        company_id: 21,
        country_id: 1,
      },
      manual_check_status: [],
      countries: [],
    };
  },
  methods: {
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    fetch_data() {
      let self = this;

      this.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        filter: self.filter,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/research/${self.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.items = r.data;
        })
        .finally(() => {
          self.dialog = false;
          this.$store.state.loading = false;
        });
    },
    update_error_type() {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        items: self.items,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/research-update-error-type/${self.id_project}`,
          data,
          config
        )
        .then(() => {
          self.fetch_data();
        })
        .finally(() => {
          self.$store.state.loading = false;
        });
    },
    fetch_filter() {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/filter/${self.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.manual_check_status = r.data.manualCheckStatus;
          self.countries = r.data.country;
          self.languages = r.data.languages;
        })
        .finally(() => {
          self.dialog = false;
          self.$store.state.loading = false;
        });
    },
  },
  computed: {
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  mounted() {},
  created() {
    let self = this;
    self.fetch_filter();
    self.fetch_data();
  },
};
</script>

<style></style>
