<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col cols="4" v-if="data.data[0]">
          <apexchart
            type="radialBar"
            height="350"
            :options="getPieOptions()"
            :series="data.data[0].series"
          />
          <div
            class="text-center d-flex flex-column text-body-1"
            style="gap: 0.25rem"
          >
            <div
              v-for="(additionalItem, idx) in data.data[0]?.additional || []"
              :key="`additm-${idx}`"
            >
              <b>{{ additionalItem?.value }}</b> {{ additionalItem?.label }}
            </div>
          </div>
        </v-col>
        <v-col v-else cols="4">
          <no-items-placeholder />
        </v-col>
        <v-col cols="8" v-if="data.data[1]">
          <apexchart
            type="area"
            height="350"
            :options="getChartOptions()"
            :series="data.data[1].series"
          />
        </v-col>
        <v-col v-else cols="8">
          <no-items-placeholder />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NoItemsPlaceholder from "./NoItemsPlaceholder.vue";
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    SectionTitle,
    NoItemsPlaceholder,
    apexchart: VueApexCharts,
  },
  data: () => ({
    tooltipConfig: {
      locked: false,
      title: "",
      elements: [
        {
          markerColor: "",
          label: "",
          value: "",
          link: "",
        },
      ],
    },
  }),
  methods: {
    getPieOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          height: 350,
          type: "radialBar",
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
              },
              value: {
                offsetY: 16,
                fontSize: "22px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 0,
        },
        labels: this.data.data[0].categories,
      };
    },
    buildTooltipList(hoverIndexes, series, w) {
      let result = "";

      hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
        if (hoverIndex >= 0) {
          const additional =
            w.globals.initialSeries[seriesEachIndex]?.additional?.[hoverIndex];
          const color = w.globals.markers.colors[seriesEachIndex];
          const name = w.globals.seriesNames[seriesEachIndex];
          const value = w.globals.yLabelFormatters[0](
            series[seriesEachIndex][hoverIndex]
          );
          const valueAppend = additional?.defaultValueConfig?.append || "";

          const additionalValueHtml = additional
            ? `<span class="apexcharts-tooltip-text-y-value">${
                additional?.value || ""
              } ${additional?.label || ""}</span>`
            : "";

          result += `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: ${color};"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">${name}: </span>
                                    <span class="apexcharts-tooltip-text-y-value">${value}${valueAppend}</span>
                                    ${additionalValueHtml}
                                </div>
                            </div>
                        </div>`;
        }
      });

      return result;
    },
    buildTooltipTitle(hoverXAxis) {
      const parsedDate = new Date(hoverXAxis)
        .toDateString()
        .split(" ")
        .slice(1);
      const DAY = 1;
      const MONTH = 0;
      const YEAR = 2;

      return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
        parsedDate[DAY] + " " + parsedDate[MONTH] + " " + parsedDate[YEAR]
      }</div>`;
    },
    customSharedTooltip({ series, seriesIndex, dataPointIndex, w }) {
      const hoverXAxis = w.globals.seriesX[seriesIndex][dataPointIndex];
      const hoverIndexes = w.globals.seriesX.map((seriesX) => {
        return seriesX.findIndex((xData) => xData === hoverXAxis);
      });

      const listHtml = this.buildTooltipList(hoverIndexes, series, w);
      const titleHtml = this.buildTooltipTitle(hoverXAxis);

      return `${titleHtml}${listHtml}`;
    },
    getChartOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        chart: {
          animations: {
            enabled: false,
          },
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: {
          min: 0,
        },
        xaxis: {
          type: "datetime",
          categories: this.data.data[1].categories,
        },
        tooltip: {
          shared: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return this.customSharedTooltip({
              series,
              seriesIndex,
              dataPointIndex,
              w,
            });
          },
        },
        colors: [],
      };
    },
  },
};
</script>
