<template>
  <div>
    <v-alert
        border="right"
        colored-border
        type="error"
        elevation="2"
        v-if="urls_with_bad_performance.length > 0"
    >
      You have {{ urls_with_bad_performance.length }} urls with performance less than 50%.
    </v-alert>
    <v-card
        class="mx-auto"
        :loading="loading"
        v-if="isset_active_project"
    >
      <v-card-text class="headline font-weight-bold">
        <v-row
            align="center"
            justify="center"
        >
          <v-col class="text-center">
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              {{ snackText }}

              <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
              </template>
            </v-snackbar>
            <v-data-table
                :headers="headers"
                :items="items"
                :sort-by="['priority']"
                :sort-desc="[true]"
                multi-sort
                class="elevation-1"
            >
              <template v-slot:item.url="{ item }">
                <div style="white-space: nowrap">
                  {{ item.url }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-if="!item.has_in_kc"
                          small
                          v-bind="attrs"
                          v-on="on"
                          color=error>mdi-close-circle
                      </v-icon>
                    </template>
                    <span>Not in Keywords Control</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.priority="props">
                <v-edit-dialog
                    :return-value.sync="props.item.priority"
                    large
                    persistent
                    @save="save_priority(props.item)"
                >
                  <div>{{ props.item.priority }}</div>
                  <template v-slot:input>
                    <div class="mt-4 title">Update Priority</div>
                  </template>
                  <template v-slot:input>
                    <v-text-field
                        v-model="props.item.priority"
                        label="Edit priority"
                        single-line
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.gsc_clicks="{ item }">
                <span style="position:relative;">
                  {{ filter_null(item.gsc_clicks[1]) }}
                  <sup class="ml-n2" style="position:absolute;" v-if="item.gsc_clicks[1] !== null">
                    {{ calc_delta(item.gsc_clicks[0], item.gsc_clicks[1]).d }}</sup>
                </span>
              </template>
              <template v-slot:item.gsc_impressions="{ item }">
                <span style="position:relative;">
                  {{ filter_null(item.gsc_impressions[1]) }}
                  <sup class="ml-n2" style="position:absolute;" v-if="item.gsc_impressions[1] !== null">
                    {{ calc_delta(item.gsc_impressions[0], item.gsc_impressions[1]).d }}
                  </sup>
                </span>
              </template>
              <template v-slot:item.gsc_ctr="{ item }">
                <span style="position:relative;">
                  {{ Math.round(item.gsc_ctr[1]) }}%
                  <sup class="ml-n2" style="position:absolute;" v-if="item.gsc_ctr[1] !== null">
                    {{ calc_delta(item.gsc_ctr[0], item.gsc_ctr[1]).d }}
                  </sup>
                </span>
              </template>
              <template v-slot:item.gsc_avg_pos="{ item }">
                <span style="position:relative;">
                  {{ Math.round(item.gsc_avg_pos[1]) }}
                  <sup class="ml-n2"
                       style="position:absolute;">{{ calc_delta(item.gsc_avg_pos[0], item.gsc_avg_pos[1]).d }}</sup>
                </span>
              </template>
              <template v-slot:item.gsc_keys_all="{ item }">
                <span style="position:relative;">
                  {{ filter_null(item.gsc_keys.top_all[1]) }}
                  <sup class="ml-n2" style="position:absolute;">
                    {{ calc_delta(item.gsc_keys.top_all[0], item.gsc_keys.top_all[1]).d }}
                  </sup>
                </span>
              </template>
              <template v-slot:item.gsc_keys_top_3="{ item }">
                <span style="position:relative;">
                  {{ item.gsc_keys.top_3[1] == null ? '-' : item.gsc_keys.top_3[1] }}
                  <sup class="ml-n2" style="position:absolute;">
                    {{ calc_delta(item.gsc_keys.top_3[0], item.gsc_keys.top_3[1]).d }}
                  </sup>
                </span>
              </template>
              <template v-slot:item.gsc_keys_top_10="{ item }">
                <span style="position:relative;">
                  {{ item.gsc_keys.top_10[1] == null ? '-' : item.gsc_keys.top_10[1] }}
                  <sup class="ml-n2" style="position:absolute;">
                    {{ calc_delta(item.gsc_keys.top_10[0], item.gsc_keys.top_10[1]).d }}
                  </sup>
                </span>
              </template>
              <template v-slot:item.gsc_keys_top_20="{ item }">
                <span style="position:relative;">
                  {{ item.gsc_keys.top_20[1] == null ? '-' : item.gsc_keys.top_20[1] }}
                  <sup class="ml-n2" style="position:absolute;">
                    {{ calc_delta(item.gsc_keys.top_20[0], item.gsc_keys.top_20[1]).d }}
                  </sup>
                </span>
              </template>
              <template v-slot:item.pos_avg="{ item }">
                {{ Math.round(item.pos_avg[1]) }}
                <sup>{{ calc_delta(item.pos_avg[0], item.pos_avg[1]).d }}</sup>
              </template>
              <template v-slot:item.pos_main_key="{ item }">
                {{ Math.round(item.pos_main_key[1]) }}
                <sup>{{ calc_delta(item.pos_main_key[0], item.pos_main_key[1]).d }}</sup>
              </template>
              <template v-slot:item.pos_trend="{ item }">
                <div
                    style="width: 100px"
                    @click="open_pos_trend_dialog(item)"
                >
                  <v-sparkline
                      :value="item.pos_trend"
                      line-width="6"
                      padding="16"
                      stroke-linecap="round"
                  ></v-sparkline>
                </div>
              </template>
              <template v-slot:item.ltt="{ item }">
                <div style="white-space: nowrap" v-if="ltt_cell(item.ltt).all !== null">
                  {{ ltt_cell(item.ltt).all }} / {{ ltt_cell(item.ltt).index }}
                  <sup>{{ ltt_cell(item.ltt).all_range }} / {{ ltt_cell(item.ltt).index_range }}</sup>
                </div>
                <span v-else>-</span>
              </template>
              <template v-slot:item.ltt_guest_post="{ item }">
                <div style="white-space: nowrap" v-if="ltt_cell_child(item.ltt, 'guest post').all !== null">
                  {{ ltt_cell_child(item.ltt, 'guest post').all }} / {{ ltt_cell_child(item.ltt, 'guest post').index }}
                  <sup>{{ ltt_cell_child(item.ltt, 'guest post').all_range }} /
                    {{ ltt_cell_child(item.ltt, 'guest post').index_range }}</sup>
                </div>
                <span v-else>-</span>
              </template>
              <template v-slot:item.ltt_3rd_blog="{ item }">
                <div style="white-space: nowrap" v-if="ltt_cell_child(item.ltt, '3rd blog').all !== null">
                  {{ ltt_cell_child(item.ltt, '3rd blog').all }} / {{ ltt_cell_child(item.ltt, '3rd blog').index }}
                  <sup>{{ ltt_cell_child(item.ltt, '3rd blog').all_range }} /
                    {{ ltt_cell_child(item.ltt, '3rd blog').index_range }}</sup>
                </div>
                <span v-else>-</span>
              </template>
              <template v-slot:item.ec_sales="{ item }">
                {{ item.ga[0] }} / {{ item.ga[1] }}
              </template>
              <template v-slot:item.ec_sales="{ item }">
                <div style="white-space: nowrap" v-if="item.ga[1] !== null">
                  {{ item.ga[1] }}
                </div>
                <span v-else>-</span>
              </template>
              <template v-slot:item.ec_ctr="{ item }">
                <div style="white-space: nowrap" v-if="item.ga[1] !== null">
                  {{ item.ga[0] === 0 ? 0 : (Math.round(item.ga[1] * 100 * 100 / item.ga[0]) / 100) }}%
                </div>
                <span v-else>-</span>
              </template>
              <template v-slot:item.ls_performance="{ item }">
                <v-progress-circular
                    v-if="item.ls[1] !== undefined"
                    class="mr-2"
                    :size="30"
                    :value="item.ls[1][0].performance * 100"
                    :width="3"
                    :color="get_performance_color(item.ls[1][0].performance)"
                >{{ Math.round(item.ls[1][0].performance * 100) }}
                </v-progress-circular>
              </template>
              <template v-slot:item.ls_seo="{ item }">
                <v-progress-circular
                    v-if="item.ls[1] !== undefined"
                    class="mr-2"
                    :size="30"
                    :value="item.ls[1][0].seo * 100"
                    :width="3"
                    :color="get_performance_color(item.ls[1][0].seo)"
                >{{ Math.round(item.ls[1][0].seo * 100) }}
                </v-progress-circular>
              </template>
              <template v-slot:item.ls_accessibility="{ item }">
                <v-progress-circular
                    v-if="item.ls[1] !== undefined"
                    class="mr-2"
                    :size="30"
                    :value="item.ls[1][0].accessibility * 100"
                    :width="3"
                    :color="get_performance_color(item.ls[1][0].accessibility)"
                >{{ Math.round(item.ls[1][0].accessibility * 100) }}
                </v-progress-circular>
              </template>
              <template v-slot:item.text_main_in_title="{ item }">
                <v-icon v-if="item.text_main_in_title" color="primary">mdi-check-circle</v-icon>
                <v-icon
                    v-else-if="item.text_main_in_title === false"
                    color="error">mdi-close-circle
                </v-icon>
                <span v-else>-</span>
              </template>
              <template v-slot:item.text_keys_in_content="{ item }">
                <v-chip
                    small
                    class="ma-2"
                    :color="item.text_keys_in_content < 50 ? 'error' : 'success'"
                    text-color="white"
                    v-if="item.text_keys_in_content !== null"
                >
                  {{ item.text_keys_in_content }}%
                </v-chip>
                <span v-else>-</span>
              </template>
              <template v-slot:item.text_focus_in_headings="{ item }">
                <v-chip
                    small
                    class="ma-2"
                    :color="item.text_focus_in_headings < 90 ? 'error' : 'success'"
                    text-color="white"
                    v-if="item.text_focus_in_headings !== null"
                >
                  {{ item.text_focus_in_headings }}%
                </v-chip>
                <span v-else>-</span>
              </template>
              <template v-slot:item.text_water="{ item }">
                <v-chip
                    small
                    class="ma-2"
                    :color="item.text_water < 50 ? 'error' : 'success'"
                    text-color="white"
                >
                  {{ item.text_water }}%
                </v-chip>
              </template>
              <!--              <template v-slot:body="{ items }">-->
              <!--                <tbody>-->
              <!--                <tr v-for="item in items" :key="item.id">-->
              <!--                  <td>-->
              <!--                    {{ item.url }}-->
              <!--                  </td>-->
              <!--                  <td>-->
              <!--                    0-->
              <!--                  </td>-->
              <!--                  <td style="min-width: 100px">{{ item.gsc_clicks[1] }}-->
              <!--                    <sup>{{ calc_delta(item.gsc_clicks[0], item.gsc_clicks[1]).d }}</sup>-->
              <!--                  </td>-->
              <!--                  <td style="min-width: 120px">{{ item.gsc_impressions[1] }}-->
              <!--                    <sup>{{ calc_delta(item.gsc_impressions[0], item.gsc_impressions[1]).d }}</sup></td>-->
              <!--                  <td style="min-width: 100px">{{ Math.round(item.gsc_ctr[1] * 100) / 100 }}-->
              <!--                    <sup>{{ calc_delta(item.gsc_ctr[0], item.gsc_ctr[1]).d }}</sup></td>-->
              <!--                  <td style="min-width: 100px">{{ Math.round(item.gsc_pos[1] * 100) / 100 }}-->
              <!--                    <sup>{{ calc_delta(item.gsc_pos[0], item.gsc_pos[1]).d }}</sup></td>-->
              <!--                  <td style="min-width: 100px">{{ Math.round(item.pos_avg[1] * 100) / 100 }}-->
              <!--                    <sup>{{ calc_delta(item.pos_avg[0], item.pos_avg[1]).d }}</sup></td>-->
              <!--                  <td style="min-width: 100px">{{ Math.round(item.pos_main_key[1] * 100) / 100 }}-->
              <!--                    <sup>{{ calc_delta(item.pos_main_key[0], item.pos_main_key[1]).d }}</sup></td>-->
              <!--                  <td>-->
              <!--                          <div-->
              <!--                              style="width: 200px"-->
              <!--                              @click="open_pos_trend_dialog(item)"-->
              <!--                          >-->
              <!--                            <v-sparkline-->
              <!--                                :value="item.pos_trend"-->
              <!--                                line-width="3"-->
              <!--                                padding="16"-->
              <!--                                stroke-linecap="round"-->
              <!--                            ></v-sparkline>-->
              <!--                          </div>-->
              <!--                  </td>-->
              <!--                  <td style="min-width: 110px">{{ ltt_cell(item.ltt).all }} / {{ ltt_cell(item.ltt).index }}-->
              <!--                    <sup>{{ ltt_cell(item.all_range).index }} / {{ ltt_cell(item.all_range).index_range }}</sup></td>-->
              <!--                  <td>-->
              <!--                    <v-progress-circular-->
              <!--                        class="mr-2"-->
              <!--                        :size="30"-->
              <!--                        :value="item.ls[1][0].performance * 100"-->
              <!--                        :width="2"-->
              <!--                        :color="get_performance_color(item.ls[1][0].performance)"-->
              <!--                    >{{ Math.round(item.ls[1][0].performance * 100) }}-->
              <!--                    </v-progress-circular>-->
              <!--&lt;!&ndash;                    <v-progress-circular&ndash;&gt;-->
              <!--&lt;!&ndash;                        class="mr-2"&ndash;&gt;-->
              <!--&lt;!&ndash;                        :size="30"&ndash;&gt;-->
              <!--&lt;!&ndash;                        :value="item.ls[1][0].seo * 100"&ndash;&gt;-->
              <!--&lt;!&ndash;                        :width="2"&ndash;&gt;-->
              <!--&lt;!&ndash;                        :color="get_performance_color(item.ls[1][0].seo)"&ndash;&gt;-->
              <!--&lt;!&ndash;                    >{{ Math.round(item.ls[1][0].seo * 100) }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </v-progress-circular>&ndash;&gt;-->
              <!--&lt;!&ndash;                    <v-progress-circular&ndash;&gt;-->
              <!--&lt;!&ndash;                        :size="30"&ndash;&gt;-->
              <!--&lt;!&ndash;                        :value="item.ls[1][0].accessibility * 100"&ndash;&gt;-->
              <!--&lt;!&ndash;                        :width="2"&ndash;&gt;-->
              <!--&lt;!&ndash;                        :color="get_performance_color(item.ls[1][0].accessibility)"&ndash;&gt;-->
              <!--&lt;!&ndash;                    >{{ Math.round(item.ls[1][0].accessibility * 100) }}&ndash;&gt;-->
              <!--&lt;!&ndash;                    </v-progress-circular>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                    <v-progress-circular&ndash;&gt;-->
              <!--                    &lt;!&ndash;                        :size="35"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                        :value="item.ls[0][0].pwa * 100"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                        :width="2"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                        :color="get_performance_color(item.ls[0][0].pwa)"&ndash;&gt;-->
              <!--                    &lt;!&ndash;                    >{{ Math.round(item.ls[0][0].pwa * 100)}}</v-progress-circular>&ndash;&gt;-->
              <!--                  </td>-->
              <!--                </tr>-->
              <!--                </tbody>-->
              <!--              </template>-->
            </v-data-table>
          </v-col>
        </v-row>
        <v-menu
            transition="slide-x-transition"
            :close-on-content-click="false"
            :nudge-right="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fixed
                dark
                fab
                bottom
                right
                color="pink"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>

          <v-card>
            <!--            <v-list>-->
            <!--              <v-list-item>-->
            <!--                <v-list-item-avatar>-->
            <!--                  <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">-->
            <!--                </v-list-item-avatar>-->

            <!--                <v-list-item-content>-->
            <!--                  <v-list-item-title>John Leider</v-list-item-title>-->
            <!--                  <v-list-item-subtitle>Founder of Vuetify.js</v-list-item-subtitle>-->
            <!--                </v-list-item-content>-->

            <!--                <v-list-item-action>-->
            <!--                  <v-btn-->
            <!--                      :class="filter_headers.main_key ? 'red&#45;&#45;text' : ''"-->
            <!--                      icon-->
            <!--                      @click="filter_headers.main_key = !filter_headers.main_key"-->
            <!--                  >-->
            <!--                    <v-icon>mdi-heart</v-icon>-->
            <!--                  </v-btn>-->
            <!--                </v-list-item-action>-->
            <!--              </v-list-item>-->
            <!--            </v-list>-->

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="filter_headers.ltt_hide"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Ltt</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="filter_headers.search_console_keys_hide"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Search Console</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="filter_headers.ec_hide"></v-switch>
                </v-list-item-action>
                <v-list-item-title>E-commerce</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="filter_headers.text_hide"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Text</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="filter_headers.ls_hide"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Tech</v-list-item-title>
              </v-list-item>
            </v-list>

            <!--            <v-card-actions>-->
            <!--              <v-spacer></v-spacer>-->

            <!--              <v-btn text @click="filter_headers.main_key = false">Cancel</v-btn>-->
            <!--              <v-btn color="primary" text @click="filter_headers.main_key = false">Save</v-btn>-->
            <!--            </v-card-actions>-->
          </v-card>
        </v-menu>
        <!--        <v-btn-->
        <!--            absolute-->
        <!--            dark-->
        <!--            fab-->
        <!--            top-->
        <!--            right-->
        <!--            small-->
        <!--            color="pink"-->
        <!--        >-->
        <!--          <v-icon>mdi-filter</v-icon>-->
        <!--        </v-btn>-->
      </v-card-text>
    </v-card>
    <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
        dark
        v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="$store.state.project.menu_select_projects = true">Select project</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-row justify="center">
      <v-dialog v-model="pos_trend_dialog" max-width="900">
        <v-card>
          <!--          <v-card-title class="headline">Use Google's location service? {{ pos_trend_item_selected.url }}</v-card-title>-->
          <v-card-text>
            <v-sparkline
                :value="pos_trend_item_selected.pos_trend !== undefined ? pos_trend_item_selected.pos_trend : []"
                :labels="pos_trend_item_selected.pos_trend !== undefined ? pos_trend_item_selected.pos_trend.map((v) => Math.round(v * 10) / 10) : []"
                line-width="1"
                padding="10"
                stroke-linecap="round"
            ></v-sparkline>
          </v-card-text>
          <!--          <v-card-actions>-->
          <!--            <v-spacer></v-spacer>-->
          <!--            <v-btn color="green darken-1" text @click="pos_trend_dialog = false">Disagree</v-btn>-->
          <!--            <v-btn color="green darken-1" text @click="pos_trend_dialog = false">Agree</v-btn>-->
          <!--          </v-card-actions>-->
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from '../../events/eventPipe';

export default {
  name: "LinksPlanIndex",
  created() {
    let self = this;

    eventPipe.$on('update-active-project', () => {
      self.load_data()
    })

    self.load_data()
  },
  methods: {
    load_data() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/links-plan/main-dashboard/json/${self.id_project}?df=2020-08-01&dt=2020-08-12`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        axios.get(url, config)
            .then(function (response) {
              self.items = response.data
              self.loading = false
            }).catch(() => self.sheet = true);
      }
    },
    filter_null(value) {
      return value == null ? '-' : value
    },
    open_pos_trend_dialog(item) {
      this.pos_trend_item_selected = item
      this.pos_trend_dialog = true
    },
    get_performance_color(value) {
      if (value < 0.2) {
        return 'red'
      } else if (value < 0.4) {
        return 'deep-orange'
      } else if (value < 0.6) {
        return 'orange'
      } else if (value < 0.8) {
        return 'yellow darken-2'
      } else {
        return 'light-green'
      }
    },
    ltt_cell_child(data, type) {
      if (data === undefined || data[type] === undefined) {
        return {
          index: null,
          all: null,
          index_range: null,
          all_range: null
        }
      }
      let c = data[type]

      return {
        index: c[0],
        all: c[1],
        index_range: c[2],
        all_range: c[3]
      }
    },
    ltt_cell(data) {
      let count_obj = {
        index: 0,
        all: 0,
        index_range: 0,
        all_range: 0
      }

      for (let i in data) {
        count_obj.index += isNaN(parseInt(data[i][0])) ? 0 : parseInt(data[i][0])
        count_obj.all += isNaN(parseInt(data[i][1])) ? 0 : parseInt(data[i][1])
        count_obj.index_range += isNaN(parseInt(data[i][2])) ? 0 : parseInt(data[i][2])
        count_obj.all_range += isNaN(parseInt(data[i][3])) ? 0 : parseInt(data[i][3])
      }

      return count_obj
    },
    calc_delta(a, b) {
      if (b === null) {
        return ''
      }

      let f = Math.round(a);
      let t = Math.round(b);
      let d = Math.round(t - f);

      return {
        d: d !== 0 ? (d > 0 ? '+' : '') + d : ''
      }
    },
    save_priority(item) {
      let self = this

      let url = `${self.$store.state.server_url}/links-plan/main-dashboard/update`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.post(url, {
        project: self.id_project,
        priority: item.priority,
        url: item.id_url
      }, config)
          .then(() => {
            self.snack = true
            self.snackColor = 'success'
            self.snackText = 'Data saved'
          })
          .catch(() => {
            self.snack = true
            self.snackColor = 'error'
            self.snackText = 'Some error'
          })
    }
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined
    },
    id_project() {
      return this.$store.state.project.active_project
    },
    urls_with_bad_performance() {
      return this.items.filter((v) => {
        return v.ls[1] !== undefined ? v.ls[1][0].performance <= 0.5 : false
      })
    },
    headers() {
      let self = this
      Object.filter = function (obj, predicate) {
        let result = {}, key;

        for (key in obj) {
          if (!predicate(obj[key])) {
            result[key] = obj[key];
          }
        }
        return result;
      };
      let filter_headers_groups = Object.keys(Object.filter(self.filter_headers, (item) => {
        return !item
      }))
      return this.base_headers.filter((v) => filter_headers_groups.includes(v.group))
      // return this.base_headers.filter((v) => v.value !== (self.filter_headers.main_key ? '' : 'pos_main_key'))
    }
  },
  data: () => ({
    snack: false,
    snackColor: '',
    snackText: '',
    loading: true,
    filter_headers: {
      sheet: false,
      main: true,
      main_key: true,
      search_console: true,
      search_console_keys_hide: true,
      positions: true,
      ltt: true,
      ltt_hide: true,
      anchors: true,
      ec: true,
      ec_hide: false,
      text: true,
      text_hide: false,
      ls: true,
      ls_hide: true
    },
    pos_trend_item_selected: {},
    pos_trend_dialog: false,
    base_headers: [
      {text: 'Url', value: 'url', align: 'left', group: 'main'},
      {text: 'Priority', value: 'priority', align: 'center', group: 'main'},
      {text: 'Main Key Volume', value: 'pos_main_key', align: 'center', group: 'positions'},
      {text: 'Pos Avg', value: 'pos_avg', align: 'center', group: 'positions'},
      {text: 'Trend', value: 'pos_trend', align: 'center', group: 'positions'},
      {text: 'Clicks', value: 'gsc_clicks', align: 'center', group: 'search_console'},
      {text: 'Impr', value: 'gsc_impressions', align: 'center', group: 'search_console'},
      {text: 'Ctr', value: 'gsc_ctr', align: 'center', group: 'search_console'},
      {text: 'Pos Avg', value: 'gsc_avg_pos', align: 'center', group: 'search_console'},
      {text: 'All', value: 'gsc_keys_all', align: 'center', group: 'search_console_keys_hide'},
      {text: 'Top 3', value: 'gsc_keys_top_3', align: 'center', group: 'search_console_keys_hide'},
      {text: 'Top 10', value: 'gsc_keys_top_10', align: 'center', group: 'search_console_keys_hide'},
      {text: 'Top 20', value: 'gsc_keys_top_20', align: 'center', group: 'search_console_keys_hide'},
      {text: 'All', value: 'ltt', align: 'center', group: 'ltt'},
      {text: 'Guest Post', value: 'ltt_guest_post', align: 'center', group: 'ltt_hide'},
      {text: '3rd Blog', value: 'ltt_3rd_blog', align: 'center', group: 'ltt_hide'},
      {text: 'Anchors', value: 'anchors', align: 'center', group: 'anchors'},
      {text: 'Sales', value: 'ec_sales', align: 'center', group: 'ec'},
      {text: 'Step 1', value: 'ec_step_1', align: 'center', group: 'ec_hide'},
      {text: 'Ctr', value: 'ec_ctr', align: 'center', group: 'ec'},
      {text: 'Visit Duration', value: 'ga_visit_duration', align: 'center', group: 'ga'},
      {text: 'Pages / Visit', value: 'ga_pages_visit', align: 'center', group: 'ga'},
      {text: 'Main Key In Title', value: 'text_main_in_title', align: 'center', group: 'text'},
      {text: 'Focus Keys In Headings', value: 'text_focus_in_headings', align: 'center', group: 'text'},
      {text: 'Keys In Content', value: 'text_keys_in_content', align: 'center', group: 'text_hide'},
      {text: 'Word Count', value: 'text_word_count', align: 'center', group: 'text_hide'},
      {text: 'Water', value: 'text_water', align: 'center', group: 'text_hide'},
      {text: 'Page Features', value: 'pf', align: 'center', group: 'pf'},
      {text: 'P', value: 'ls_performance', align: 'center', group: 'ls'},
      {text: 'S', value: 'ls_seo', align: 'center', group: 'ls_hide'},
      {text: 'A', value: 'ls_accessibility', align: 'center', group: 'ls_hide'},
    ],
    items: []
  }),
}
</script>

<style>
.elevation-1 th {
  white-space: nowrap !important;
}
</style>
