<template>
  <v-dialog
    v-model="dialog"
    max-width="385"
    transition="dialog-bottom-transition"
    content-class="remove-dialog-shadow pa-1"
  >
    <v-card rounded="lg" class="shadow-e2-bordered">
      <v-card-title>
        Page Navigate
        <v-btn
          elevation="0"
          icon
          @click="contacts = !contacts"
          style="padding: 0; min-width: 38px; margin-left: 5px"
        >
          <v-icon color="gray" style="width: 15px">
            mdi-information-outline
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div>
            <v-autocomplete
              v-model="selected"
              hide-details
              dense
              outlined
              label="Page name / title"
              placeholder="Type to find a page"
              :items="data"
              item-text="title"
              return-object
              autofocus
              ref="searchInput"
              :menu-props="menuProps"
              @change="handleSelectedChange"
            >
              <template #item="{ item }">
                <div class="find-page-items">
                  <div class="find-page-items__title">
                    {{ getSplittedTitle(item.title) }}
                  </div>
                  <div class="find-page-items__head">
                    {{ item.path }}
                  </div>
                  <div class="find-page-items__icon">
                    <v-icon large> mdi-chevron-right </v-icon>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </div>
          <div style="width: 45px">
            <v-btn
              block
              style="height: 40px; width: 40px; margin-left: 5px"
              text
              class="ml-2"
              color="error"
              @click="selected = null"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <transition name="slide-y">
      <v-card v-if="contacts" class="mt-4 shadow-e2-bordered" rounded="lg">
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <span>{{ getTranslate.title }}</span>
            <div class="d-flex align-center">
              <v-btn-toggle dense v-model="language">
                <v-btn x-small value="eng">EN</v-btn>
                <v-btn x-small value="ua">UA</v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            {{ getTranslate.text }}
          </p>
          <div class="d-flex justify-center">
            <v-card width="100%" rounded="lg" class="shadow-e1-bordered">
              <v-card-text>
                <div class="d-flex align-center justify-center">
                  <img
                    width="55"
                    style="border-radius: 100%"
                    src="https://ca.slack-edge.com/T04B6PEDQ-U039MJZTE5S-f77baa12df66-512"
                    alt="Rodion"
                  />
                  <div
                    style="font-size: 18px"
                    class="ml-2"
                    v-html="getTranslate.developer"
                  ></div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn block text color="info" @click="goToSlack" rounded>{{
                  getTranslate.button
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </transition>
  </v-dialog>
</template>

<script>
import { APP_ROUTES } from "@/plugins/router";
import { PAGE_PRIORITY } from "@/utils/defaultData";

export default {
  props: ["modelValue"],
  data: () => ({
    menuProps: {
      contentClass: "page-navigate-select-menu shadow-e2-bordered",
      maxHeight: "750",
      rounded: "lg",
      transition: "slide-x-transition",
      offsetX: true,
    },
    selected: null,
    data: null,
    contacts: false,
    language: "eng",
    textContent: {
      ua: {
        title: "Зверни увагу",
        text: "Якщо ви вписали в пошук назву сторінки і віджет нічого не знайшов, але ви впевнені, що на ваш запит віджет повинен був знайти відповідну сторінку - напишіть мені.",
        developer: "Гордієнко <br/> Родіон",
        button: "Зв'язатися",
      },
      eng: {
        title: "Pay attention",
        text: "If you entered the name of the page in the search and the widget did not find anything, but you are sure that the widget should have found the corresponding page at your request - write to me.",
        developer: "Hordiienko <br/> Rodion",
        button: "Contact me",
      },
    },
  }),
  created() {
    this.data = this.fixPageRoutes(APP_ROUTES);
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.searchInput.blur();
      }
    },
  },
  methods: {
    handleSelectedChange(selected) {
      this.goToPage(selected);
    },
    getSplittedTitle(title) {
      try {
        return title.split("|")[0];
      } catch {
        return title;
      }
    },
    goToSlack() {
      const url = "slack://user?team=T04B6PEDQ&id=U039MJZTE5S";
      window.open(url, "_blank");
    },
    goToPage(item) {
      try {
        this.$router.push({ name: item.name });
        this.dialog = false;
      } catch (e) {
        console.error(e);
      }
    },
    fixPriority(page) {
      if (!page) {
        return null;
      }

      if (typeof page.priority === "string" && page.priority.includes(",")) {
        return page.priority.replaceAll(",", "");
      }

      return page.priority;
    },
    fixTitle(page) {
      const title = page.meta.title;
      if (title && typeof title === "string" && title.includes("|")) {
        return title.split("|")[0];
      }
      return title;
    },
    fixPageRoutes(routesArr = []) {
      const output = [];

      routesArr.forEach((route) => {
        const match = PAGE_PRIORITY.find((item) => item.name === route.name);
        const priority = this.fixPriority(match);
        const title = this.fixTitle(route);

        output.push({
          name: route.name,
          path: route.path,
          title,
          priority,
        });
      });

      output.sort((a, b) => {
        if (a.priority < b.priority) return 1;
        if (a.priority > b.priority) return -1;
        return 0;
      });

      return output;
    },
  },
  computed: {
    getTranslate() {
      return this.textContent[this.language];
    },
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
<style lang="scss">
.page-navigate-select-menu {
  &::-webkit-scrollbar {
    display: none;
  }
  .v-list-item {
    padding: 0;
    justify-content: center;
    margin: 0 8px;
    border-radius: 16px;
    .find-page-items {
      border-radius: 8px;
      position: relative;
      padding: 8px 16px;
      &__head {
        font-size: 14px;
        opacity: 0.8;
      }
      &__title {
        font-weight: 600;
        font-size: 18px;
      }
      &__icon {
        transition: 150ms ease-in-out;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%) translateX(-15px);
        opacity: 0;
      }
      &:hover {
        .find-page-items__icon {
          transform: translateY(-50%) translateX(0);
          opacity: 1;
        }
      }
    }
    &::before {
      border-radius: 4px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.slide-y-enter-active {
  animation: bounce-in ease-in-out 250ms;
}

.slide-y-leave-active {
  animation: bounce-in ease-in-out 250ms reverse;
}

@keyframes bounce-in {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
