<template>
  <v-card :loading="loading">
    <div id="chart-positions">
      <div class="toolbar text-center pt-3">
        <div class="d-flex justify-center">
          <div>
            <v-btn
              id="two_week"
              small
              :outlined="selection !== 'two_week'"
              color="primary"
              @click="updateChartDates('two_week')"
              :class="{ active: selection === 'two_week', 'ma-1': true }"
            >
              2W
            </v-btn>
            <v-btn
              id="one_month"
              small
              :outlined="selection !== 'one_month'"
              color="primary"
              @click="updateChartDates('one_month')"
              :class="{ active: selection === 'one_month', 'ma-1': true }"
            >
              1M
            </v-btn>
            <v-btn
              id="three_months"
              small
              :outlined="selection !== 'three_months'"
              color="primary"
              @click="updateChartDates('three_months')"
              :class="{ active: selection === 'three_months', 'ma-1': true }"
            >
              3M
            </v-btn>
            <v-btn
              id="six_months"
              small
              :outlined="selection !== 'six_months'"
              color="primary"
              @click="updateChartDates('six_months')"
              :class="{ active: selection === 'six_months', 'ma-1': true }"
            >
              6M
            </v-btn>
            <v-btn
              id="one_year"
              small
              :outlined="selection !== 'one_year'"
              color="primary"
              @click="updateChartDates('one_year')"
              :class="{ active: selection === 'one_year', 'ma-1': true }"
            >
              1Y
            </v-btn>
          </div>
          <div></div>
        </div>
      </div>
      <div id="chart-timeline-positions">
        <apexcharts
          ref="chart"
          height="300"
          :options="options"
          :series="series"
        ></apexcharts>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import events from "../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";

export default {
  name: "CompetitorPositions",
  components: { apexcharts: VueApexCharts },
  mixins: [OnePageAnalyzerIndex],
  data() {
    return {
      pos_type: "gsc",
      loading: false,
      series: [],
      dirty_series: {
        top1: { name: "top 1", data: [] },
        top3: { name: "top 3", data: [] },
        top10: { name: "top 10", data: [] },
        top20: { name: "top 20", data: [] },
        top50: { name: "top 50", data: [] },
        top100: { name: "top 100", data: [] },
      },
      options: {
        chart: {
          id: "chart-timeline-comp-positions",
          type: "area",
          height: 300,
          zoom: {
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        colors: ["#e91e63", "#ff9800", "#1976d2", "#42a5f5", "#90caf9", "#ccc"],
        annotations: {
          xaxis: [],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min: new Date().setMonth(new Date().getMonth() - 12),
          tickAmount: 6,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: [
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
          {
            seriesName: "top 1",
            show: false,
          },
        ],
        stroke: {
          width: [3, 3, 3, 3, 3, 3],
          dashArray: [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
          ],
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
      },
    };
  },
  methods: {
    resetData() {
      this.dirty_series = {
        top1: { name: "top 1", data: [] },
        top3: { name: "top 3", data: [] },
        top10: { name: "top 10", data: [] },
        top20: { name: "top 20", data: [] },
        top50: { name: "top 50", data: [] },
        top100: { name: "top 100", data: [] },
      };

      this.series = [];
    },
    fetchPositions(pos) {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/positions`;

      self.loading = true;

      let config = {
        params: {
          url: self.selectedCompetitorUrl,
          pos: pos,
          type: "positions",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.dirty_series[`top${pos}`] = {
            name: `top ${pos}`,
            data: r.data.data.positions,
          };
          self.updatePositionsChart();
        })
        .finally(() => {
          self.loading = false;
        });
    },
    updatePositionsChart() {
      let series = [];

      series.push(this.dirty_series.top1);
      series.push(this.dirty_series.top3);
      series.push(this.dirty_series.top10);
      series.push(this.dirty_series.top20);
      series.push(this.dirty_series.top50);
      series.push(this.dirty_series.top100);

      this.series = series;

      this.updateChartDates("six_months");
    },
    updatePos(type) {
      this.pos_type = type;

      this.fetchData();
    },
    fetchData() {
      this.fetchPositions(1);
      this.fetchPositions(3);
      this.fetchPositions(10);
      this.fetchPositions(20);
      this.fetchPositions(50);
      this.fetchPositions(100);
    },
  },
  created() {
    let self = this;

    events.$on("update-url", function () {
      self.resetData();
    });

    events.$on("update-selected-competitor-url", function () {
      self.fetchData();
    });

    events.$on("update-chart-dates", function (start, end) {
      self.$refs.chart.zoomX(start, end);
    });
  },
};
</script>

<style scoped></style>
