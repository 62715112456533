<template>
  <div :class="`serp-dynamics__domains-tables ${sccClass}`">
    <div class="domains-table__search">
      <v-text-field
          label="Search"
          append-icon="mdi-magnify"
          v-model="textSearch"
          style="max-width: 350px; margin: auto"
      ></v-text-field>
    </div>
    <div class="domains-table__wrapper">
      <div class="domains-table__card">
        <div class="card__wrapper">
          <div class="card__filter">
            <v-menu
                v-model="date1Dialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date1Model"
                    placeholder="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date1Model"
                  @input="date1Dialog = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div v-if="tablesData[date1Model]" class="card__table">
            <loader :show="loading"/>
            <v-data-table
                :headers="tablesHeaders"
                :items="tablesData[date1Model]"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                
                hide-default-footer
                :search="textSearch"
                :class="getTableClass"
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="index"
                    @mouseenter="markSame(item)"
                    @mouseleave="demarkSame"
                >
                  <slot v-bind:items="item" name="items"></slot>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div v-else class="card__empty-placeholder">
            <template v-if="loading">
              <loader :show="loading"/>
              Updating...
            </template>
            <template v-else>
              Empty
            </template>
          </div>
        </div>
      </div>
      <div class="domains-table__card">
        <div class="card__wrapper">
          <div class="card__filter">
            <v-menu
                v-model="date2Dialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date2Model"
                    placeholder="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date2Model"
                  @input="date2Dialog = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div v-if="tablesData[date2Model]" class="card__table">
            <loader :show="loading"/>
            <v-data-table
                :headers="tablesHeaders"
                :items="tablesData[date2Model]"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                
                :class="getTableClass"
                :search="textSearch"
                hide-default-footer
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="index"
                    @mouseenter="markSame(item)"
                    @mouseleave="demarkSame"
                >
                  <slot v-bind:items="item" name="items"></slot>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div v-else class="card__empty-placeholder">
            <template v-if="loading">
              <loader :show="loading"/>
              Updating...
            </template>
            <template v-else>
              Empty
            </template>
          </div>
        </div>
      </div>
      <div class="domains-table__card">
        <div class="card__wrapper">
          <div class="card__filter">
            <v-menu
                v-model="date3Dialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date3Model"
                    placeholder="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date3Model"
                  @input="date3Dialog = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div v-if="tablesData[date3Model]" class="card__table">
            <loader :show="loading"/>
            <v-data-table
                :headers="tablesHeaders"
                :items="tablesData[date3Model]"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                
                :class="getTableClass"
                :search="textSearch"
                hide-default-footer
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="index"
                    @mouseenter="markSame(item)"
                    @mouseleave="demarkSame"
                >
                  <slot v-bind:items="item" name="items"></slot>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div v-else class="card__empty-placeholder">
            <template v-if="loading">
              <loader :show="loading"/>
              Updating...
            </template>
            <template v-else>
              Empty
            </template>
          </div>
        </div>
      </div>
      <div class="domains-table__card">
        <div class="card__wrapper">
          <div class="card__filter">
            <v-menu
                v-model="date4Dialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date4Model"
                    placeholder="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date4Model"
                  @input="date4Dialog = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div v-if="tablesData[date4Model]" class="card__table">
            <loader :show="loading"/>
            <v-data-table
                :headers="tablesHeaders"
                :items="tablesData[date4Model]"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                
                :class="getTableClass"
                :search="textSearch"
                hide-default-footer
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="index"
                    @mouseenter="markSame(item)"
                    @mouseleave="demarkSame"
                >
                  <slot v-bind:items="item" name="items"></slot>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div v-else class="card__empty-placeholder">
            <template v-if="loading">
              <loader :show="loading"/>
              Updating...
            </template>
            <template v-else>
              Empty
            </template>
          </div>
        </div>
      </div>
      <div class="domains-table__card">
        <div class="card__wrapper">
          <div class="card__filter">
            <v-menu
                v-model="date5Dialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date5Model"
                    placeholder="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date5Model"
                  @input="date5Dialog = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div v-if="tablesData[date5Model]" class="card__table">
            <loader :show="loading"/>
            <v-data-table
                :headers="tablesHeaders"
                :items="tablesData[date5Model]"
                :page.sync="page"
                
                :items-per-page="itemsPerPage"
                :class="getTableClass"
                :search="textSearch"
                hide-default-footer
            >
              <template v-slot:body="{ items }">
                <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="index"
                    @mouseenter="markSame(item)"
                    @mouseleave="demarkSame"
                >
                  <slot v-bind:items="item" name="items"></slot>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <div v-else class="card__empty-placeholder">
            <template v-if="loading">
              <loader :show="loading"/>
              Updating...
            </template>
            <template v-else>
              Empty
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="domains-table__pagination">
      <v-pagination
          v-model="page"
          :length="pageCount">
      </v-pagination>
      <v-autocomplete
          v-model="itemsPerPage"
          label="Items per page"
          :items="[20,50,100]"
          dense
          hide-details
          outlined
          style="max-width: 250px; margin: 10px auto auto;"
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/Main/Loader";
import {SERP_DYNAMIC_MOCK} from "@/utils/defaultData";

export default {
  components: {Loader},
  props: {
    sccClass: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default() {
        return []
      },
    },
    tablesData: {
      type: Object,
      default() {
        return {}
      },
    },
    tablesHeaders: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data: () => ({
    textSearch: '',
    date1Model: null,
    date2Model: null,
    date3Model: null,
    date4Model: null,
    date5Model: null,
    loadings: {
      date1: false,
      date2: false,
      date3: false,
      date4: false,
      date5: false,
    },
    date1Timeout: null,
    date2Timeout: null,
    date3Timeout: null,
    date4Timeout: null,
    date5Timeout: null,
    date1Dialog: false,
    date2Dialog: false,
    date3Dialog: false,
    date4Dialog: false,
    date5Dialog: false,
    fetchTimeoutMS: 1000,
    itemsPerPage: 20,
    page: 1,
    data: SERP_DYNAMIC_MOCK,
    firstRender: true,
    componentID: null,
  }),
  mounted() {
    this.componentID = 'id-' + this.uniqueid
    this.date1Model = this.modelValue[0]
    this.date2Model = this.modelValue[1]
    this.date3Model = this.modelValue[2]
    this.date4Model = this.modelValue[3]
    this.date5Model = this.modelValue[4]
    this.firstRender = false
  },
  computed: {
    getTableClass() {
      return 'domain__table ' + this.componentID
    },
    uniqueid(){
      return String(
          Date.now().toString(32) +
          Math.random().toString(16)
      ).replace(/\./g, '')
    },
    pageCount() {
      const firstKey = Object.keys(this.tablesData)[0]
      const itemsCount = this.tablesData[firstKey] !== undefined ? this.tablesData[firstKey].length : 0
      return Math.ceil(itemsCount / this.itemsPerPage)
    },
    dates: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    }
  },
  watch: {
    modelValue(val) {
      this.date1Model = val[0]
      this.date2Model = val[1]
      this.date3Model = val[2]
      this.date4Model = val[3]
      this.date5Model = val[4]
    },
    date1Model(val) {
      if (!val.length || this.firstRender) return
      this.dates = [val, this.dates[1], this.dates[2], this.dates[3], this.dates[4]]
    },
    date2Model(val) {
      if (!val.length || this.firstRender) return
      this.dates = [this.dates[0], val, this.dates[2], this.dates[3], this.dates[4]]
    },
    date3Model(val) {
      if (!val.length || this.firstRender) return
      this.dates = [this.dates[0], this.dates[1], val, this.dates[3], this.dates[4]]
    },
    date4Model(val) {
      if (!val.length || this.firstRender) return
      this.dates = [this.dates[0], this.dates[1], this.dates[2], val, this.dates[4]]
    },
    date5Model(val) {
      if (!val.length || this.firstRender) return
      this.dates = [this.dates[0], this.dates[1], this.dates[2], this.dates[3], val]
    },
  },
  methods: {
    markSame(data) {
      const url = data.url.trim()
      const query = '.domain__table.' + this.componentID
      const tables = document.querySelectorAll(query)
      Array.from(tables).forEach(table => {
        const tds = table.querySelectorAll('td:first-child')
        Array.from(tds).forEach(td => {
          if (td.textContent.trim() === url) {
            td.parentNode.classList.add('marked')
          }
        })
      })
    },
    demarkSame() {
      const query = '.domain__table.' + this.componentID
      const tables = document.querySelectorAll(query)
      Array.from(tables).forEach(table => {
        const trs = table.querySelectorAll('tr')
        Array.from(trs).forEach(tr => {
          tr.classList.remove('marked')
        })
      })
    },
  }
}
</script>