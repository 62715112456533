<template>
  <div class="ma-4">
    <v-row dense>
      <v-col
        v-for="(progress, idx) in componentData()?.items"
        :key="`progress-${idx}`"
        cols="12"
      >
        <dev-dashboard-progress-bar :data="progress" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DevDashboardProgressBar from "../../DashboardDev/components/DevDashboardProgressBar.vue";

export default {
  components: { DevDashboardProgressBar },

  inject: ["componentData"],
};
</script>
