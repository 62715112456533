<template>
  <td>
    <v-badge v-if="data.first_msg_at.ping" color="transparent">
      <template v-slot:badge>
        <span style="color:black">
          {{ data.first_msg_at.ping }}
        </span>
      </template>
      <span>
        {{
          (
          data.first_msg_at.text
              ?$moment(data.first_msg_at.text).format('DD/MM')
              : ''
          )
        }}
      </span>
    </v-badge>
    <span v-else>
        {{
        (
            data.first_msg_at.text
                ?$moment(data.first_msg_at.text).format('DD/MM')
                : ''
        )
      }}
    </span>
  </td>
</template>

<script>
export default {
  props: ['data'],
}
</script>